import { modules, resources } from "@rivial-security/role-utils";

import { ASSESSMENT_STATUS } from "../../../../../typedefs/Testing/Assessment/Assessment";
import { GENERIC_FIELD_TYPES } from "../../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import GenericEditFieldV3 from "../../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";
import React from "react";
import { enumToDropdownData } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 *
 * @param item
 * @return {JSX.Element}
 * @constructor
 */
const AssessmentDetailsAssessmentStatus = ({ item }) => {
  const module = modules.VULNERABILITIES;
  const resource = resources.ASSESSMENT;

  const { updateMutation: updateAssessment } = generateGraphql("Assessment", ["status"]);

  return (
    <GenericEditFieldV3
      item={item}
      module={module}
      resource={resource}
      field="status"
      mutation={updateAssessment}
      inputType={GENERIC_FIELD_TYPES.DROPDOWN}
      inputConfig={{
        data: enumToDropdownData({ ENUM: ASSESSMENT_STATUS }),
      }}
    />
  );
};

export default AssessmentDetailsAssessmentStatus;
