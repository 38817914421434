import { ErrorLogger } from "@utils/EventLogger";
import { ItemMutation } from "@rivial-security/appsync-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { isNonEmptyArray } from "@rivial-security/func-utils";

/**
 * Function to add new points of contacts to a meeting, does not spawn tasks, does not check for existing points of contact
 * @param {string} organizationID - the organization of the meeting to which to attach the points of contact
 * @param {string} meetingID - the meeting to which to attach the points of contact
 * @param {object} pointsOfContact - the points of contact to attach to the questionnaire
 * @returns {Promise<void>}
 */
export const addPointsOfContactToMeeting = async ({ organizationID, meetingID, pointsOfContact }) => {
  if (!organizationID || !meetingID || !isNonEmptyArray(pointsOfContact)) {
    ErrorLogger("[addPointsOfContactToMeeting]", "Invalid arguments to add points of contact to meeting");
    return;
  }

  const { createMutation } = generateGraphql("PointOfContactMeetingLink");

  const createRequests = [];
  for (const pointOfContact of pointsOfContact) {
    const pointOfContactID = pointOfContact?.id;
    if (pointOfContactID) {
      createRequests.push(
        ItemMutation({
          mutation: createMutation,
          input: {
            ownerGroup: organizationID,
            pointOfContactMeetingLinkMeetingId: meetingID,
            pointOfContactMeetingLinkPointOfContactId: pointOfContactID,
          },
        }),
      );
    }
  }

  if (isNonEmptyArray(createRequests)) {
    await Promise.allSettled(createRequests);
  }
};
