import { CircularProgress, List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { API } from "@aws-amplify/api";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WorkIcon from "@mui/icons-material/Work";
import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { onJobUpdateSubscription } from "./graphql/onJobUpdateSubscription";

/**
 * Track multiple jobs occurring at once (automatically unsubscribes on unmount or completion of a job)
 * @returns {{display: JSX.Element, addJob: ((function({job: *}): Promise<void>)|*)}}
 */
export const useJobsTracker = () => {
  /**
   * Keep track of all jobs
   */
  const [jobs, setJobs] = useState([]);
  /**
   * Keep track of all subscriptions
   */
  const [jobSubscriptions, setJobSubscriptions] = useState(null);

  /**
   * Handel a single job event
   * @param {object} job - the job data
   * @returns {Promise<void>}
   */
  const addJob = async ({ job }) => {
    setJobs((jobs) => jobs?.concat(job));

    /**
     * Subscribe to any new events
     */
    const activeSubscription = await API.graphql({
      query: onJobUpdateSubscription,
      variables: {
        id: job?.id,
      },
    }).subscribe({
      next: async (eventData) => {
        const job = eventData?.value?.data?.onJobUpdate;
        /**
         * Update the job from the subscription
         */
        setJobs((prevJobs) => {
          const jobIndex = prevJobs.findIndex((j) => j.id === job.id);
          if (jobIndex !== -1) {
            const newJobs = [...prevJobs];
            newJobs[jobIndex] = job;
            return newJobs;
          }
          return prevJobs;
        });
      },
    });

    /**
     * Add the subscription to the list of subscriptions
     */
    setJobSubscriptions((jobSubscriptions) => jobSubscriptions?.concat(activeSubscription));
  };

  /**
   * Unsubscribe from all subscriptions on unmount
   */
  useEffect(() => {
    return () => {
      jobSubscriptions?.forEach((subscription) => {
        subscription?.unsubscribe();
      });
    };
  }, []);

  const display = Array.isArray(jobs) && jobs?.length > 0 && (
    <div style={{ maxHeight: "80vh", overflow: "auto" }}>
      <h5
        style={{
          textAlign: "center",
          marginTop: "0.5em",
          marginBottom: "0.5em",
        }}
      >
        Please wait for jobs to complete
      </h5>
      <Paper elevation={3}>
        <List>
          {Array.isArray(jobs) &&
            jobs?.map((job) => (
              <ListItem key={`job-${job?.id}`}>
                <ListItemIcon>
                  <WorkIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography>{job?.name}</Typography>
                  <Typography variant="subtitle1">
                    <span style={{ marginRight: "0.5em" }}>
                      Status: {(job?.status && convertCamelCaseToSentence(job?.status)) || "Started"}
                    </span>
                    {(job?.status === "started" || job?.status === "progress") && <CircularProgress size={25} />}
                    {job?.status === "completed" && <CheckCircleIcon style={{ color: "green" }} />}
                    {job?.status === "failed" && <ErrorOutlineIcon style={{ color: "red" }} />}
                  </Typography>
                </ListItemText>
              </ListItem>
            ))}
        </List>
      </Paper>
    </div>
  );

  return {
    display,
    addJob,
    jobs,
  };
};
