import React from "react";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { CsvImporterModalButton } from "../../../../hooks/views/useCsvImporter";
import { IMPORTER_FIELD_FORMATS as IMPORTER_FIELDS } from "../../../../hooks/views/useCsvImporter/enums/IMPORTER_FIELD_FORMATS";
import { useVendorSubControlDataGrid } from "../hooks/useVendorSubControlDataGrid";
import useListQuery from "../../../../hooks/graphql/useListQuery";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 * CSV Importer modal button for Vendor Sub Controls
 * @param {string} organizationID - organization id
 * @param {string} vendorControlCategoryID - vendor control category id
 * @returns {JSX.Element}
 * @constructor
 */
const VendorSubControlImporterModalButton = ({ organizationID, vendorControlCategoryID }) => {
  const categories = useListQuery({
    query: generateGraphql("VendorControlCategory", ["id", "name"]).listQuery,
    organizationID,
    disableRoleChecking: true,
  });

  const typename = "VendorSubControl";

  const fields = [
    {
      field: "name",
      name: "Name",
      required: true,
      width: 400,
    },
    {
      field: "statementNumber",
      name: "Statement Number",
      required: true,
      width: 200,
    },
    {
      field: "vendorControlCategoryID",
      name: "Vendor Control Category",
      required: true,
      width: 400,
      format: IMPORTER_FIELDS.SELECT,
      connectionItemField: "vendorControlCategoryID",
      connectionItemList: categories.list,
      defaultValue: vendorControlCategoryID,
    },
  ];

  return (
    <CsvImporterModalButton
      fields={fields}
      organizationID={organizationID}
      typename={typename}
      connectionIDField={vendorControlCategoryID}
      resultGrid={useVendorSubControlDataGrid}
    />
  );
};

export default withOrganizationCheck(VendorSubControlImporterModalButton);
