import React from "react";
import { withRouter } from "react-router-dom";
import OrganizationCheck from "../../../../utils/Context/OrganizationCheck/OrganizationCheck";
import { useRoleDetails } from "../hooks/useRoleDetails/useRoleDetails";

const RoleDetailsCard = (props) => {
  const itemId = (props.item && props.item.id) || props.match.params.id;

  return (
    <OrganizationCheck {...props}>
      <RoleDetailsCardBody itemId={itemId} />
    </OrganizationCheck>
  );
};

/**
 * NOTE: Still in development
 * @param itemId
 * @param organizationID
 * @param tableDisplay
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const RoleDetailsCardBody = ({ itemId, organizationID, tableDisplay, ...props }) => {
  const roleDetailsHook = useRoleDetails({
    organizationID,
    itemId,
    tableDisplay,
    ...props,
  });

  return <>{roleDetailsHook.display}</>;
};

export default withRouter(RoleDetailsCard);
