import React, { useEffect } from "react";
import { useVendorSubControlDataGrid } from "../../VendorSubControls/hooks/useVendorSubControlDataGrid";

/**
 * Vendor Sub Control custom field list for a Vendor Control Category
 * @param vendorControlCategory
 * @param subControls
 * @param organizationID
 * @param resetFunction
 * @returns {JSX.Element}
 * @constructor
 */
const VendorSubControls = ({ item, subControls, organizationID, resetFunction, disableRoleChecking }) => {
  const grid = useVendorSubControlDataGrid({
    data: subControls?.items,
    organizationID,
    queryConfig: {
      query: null,
    },
    vendorControlCategory: item,
    resetFunction,
    disableRoleChecking,
  });

  useEffect(() => {
    if (subControls?.items && Array.isArray(subControls.items)) {
      grid.setData([...subControls.items]);
    }
  }, [subControls?.items]);

  return (
    <div
      style={{
        height: "50vh",
      }}
    >
      {grid.display}
    </div>
  );
};

export default VendorSubControls;
