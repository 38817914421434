import DataLoader from "../../../../utils/LoadingComponents/DataLoader";
import EvidenceActivityItem from "./EvidenceActivityItem";
import React from "react";
import { Timeline } from "@mui/lab";

/**
 * Displays a timeline representing an Evidence activity
 * @param {Evidence} item - the evidence item
 * @param {function} resetFunction - callback to get updated "item" after a change
 * @param {boolean} isLoading - if true, shows a loading UI
 * @param {EvidenceActivity[]} item.activities - the evidence activities (expected to already be sorted)
 * @returns {JSX.Element}
 */
const EvidenceActivity = ({ item, resetFunction, isLoading = false }) => {
  const isLastItem = (index) => {
    if (Array.isArray(item?.activity?.items)) {
      const length = item.activity.items.length;
      return index >= length - 1;
    } else {
      return false;
    }
  };

  return (
    <DataLoader
      isEnoughData={item?.activity?.items?.length > 0}
      isLoading={isLoading}
      dataMessage={"There hasn't been any Evidence Activity yet"}
      children={
        <Timeline align={"left"}>
          {(item?.activity?.items ?? []).map((item, index) => (
            <EvidenceActivityItem
              index={index}
              item={item}
              {...item}
              isLastItem={isLastItem(index)}
              resetFunction={resetFunction}
            />
          ))}
        </Timeline>
      }
    />
  );
};

export default EvidenceActivity;
