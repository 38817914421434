import React, { useEffect, useState } from "react";

import CreateChange from "../../CreateChange";
import OrganizationCheck from "../../../../../../utils/Context/OrganizationCheck/OrganizationCheck";
import { QueryGetItem } from "../../../../../../hooks/graphql/useQueryGetItem";
import { gql_createSystemChangeLink } from "../../../graphql/gql_createSystemChangeLink";
import { handleRiskChangePromises } from "../../../functions/handleRiskChangePromises";
import { realTimeRiskGQL } from "@rivial-security/risk-calc-utils";
import { updateOrganization } from "../../../graphql/gql_updateOrganization";
import { updateRiskChange } from "../../../../../../graphql/mutations";
import { useAdjustAssetSize } from "../hooks/useAdjustAssetSize";
import { useLoadingAndOutdated } from "../../../hooks/useLoadingAndOutdated";
import { useMutation } from "../../../../../../hooks/graphql/useMutation/useMutation";
import { useOverallChanges } from "../../../hooks/useOverallChanges";
import { useRiskChangeForm } from "../../../hooks/useRiskChangeForm";
import { useRiskChangeSystemsTable } from "../../../hooks/useRiskChangeSystemsTable";
import { useStateEffect } from "../../../../../../hooks/functional/useStateEffect";
import { useSystemList } from "../../../../Systems/hooks/useSystemList";
import { useUpdateFormHook } from "../../../hooks/useUpdateFormHook";
import { useUpdateSystemsTable } from "../../../hooks/useUpdateSystemsTable";

const CreateAssetSizeChangeBody = ({ item, organizationID, resetFunction, toggleModal, ...props }) => {
  const [changeItem, setChangeItem] = useState({
    assetSizeChange: parseFloat(0),
  });

  const loadingAndOutdated = useLoadingAndOutdated(changeItem);

  const associatedSystemsHook = useSystemList({ organizationID });

  const overallChanges = useOverallChanges();

  const [oldAssetSize, setOldAssetSize] = useStateEffect(0);
  const [newAssetSize] = useStateEffect(0, [changeItem], () => {
    return oldAssetSize + changeItem.assetSizeChange;
  });

  useEffect(() => {
    QueryGetItem({
      query: realTimeRiskGQL.assetSizeGQL,
      itemId: organizationID,
      disableRoleChecking: true,
    }).then((org) => {
      setOldAssetSize(org.assetSize);
    });
  }, []);

  const systemsTable = useRiskChangeSystemsTable({
    tooltip: "All systems in this Organization",
  });

  /**
   * This function adapts the formHook input object to match object needed for the riskChange
   * @param input
   * @returns {{change: string, type: string}}
   */
  const updateInputFunction = (input) => {
    return {
      ...input,
      type: "asset_size_change",
      change: JSON.stringify({
        ...overallChanges,
        assetSizeChange: changeItem.assetSizeChange,
        oldAssetSize: oldAssetSize,
        newAssetSize: newAssetSize,
      }),
    };
  };

  const createSystemChangeLink = useMutation({
    mutation: gql_createSystemChangeLink,
    disableRoleChecking: true,
    disableToast: true,
  });
  const updateAssetSize = useMutation({
    mutation: updateOrganization,
    disableRoleChecking: true,
    disableToast: true,
  });

  /**
   * This callback updates the Organization AssetSize after the RiskChange gets created.
   * @param riskChange
   */
  const callback = async (riskChange) => {
    if (riskChange) {
      await updateAssetSize.editItem({
        id: organizationID,
        assetSize: changeItem.assetSizeChange ? oldAssetSize + changeItem.assetSizeChange : undefined,
      });

      await handleRiskChangePromises(
        associatedSystemsHook.data,
        createSystemChangeLink.createItem,
        organizationID,
        riskChange,
      );

      !props.getNewItem && resetFunction && resetFunction();
    }
  };

  /**
   * This is used when converting from a Manual Change to a new type of Change
   * @type {{mutateItemLegacy: (function(*): Promise<*|{}>), roleConfig: *, CheckFieldPermissionsRead: (function(*=): boolean), editItem: (function(*=): Promise<*|{}>), resource: *, module: *, mutateItem: (function(*=): Promise<*>), CheckFieldPermissionsUpdate: (function(*=): boolean), field: *, deleteItem: (function(*=): Promise<*|{}>), function: *, createItem: (function(*=): Promise<*|{}>), CheckFuncPermissions: (function(*=): boolean)}}
   */
  const updateRiskChangeHook = useMutation({
    mutation: updateRiskChange,
    disableRoleChecking: true,
  });

  const submitFunction = async (input) => {
    delete input["riskControlLinks"];
    delete input["systemLinks"];

    return await updateRiskChangeHook.editItem({
      id: item && item.id,
      ...input,
    });
  };

  const formHook = useRiskChangeForm({
    item,
    typename: "Asset Size Change",
    organizationID,
    updateInputFunction,
    callback,
    submitFunction,
    toggleModal,
    ...props,
  });

  useUpdateFormHook(item, formHook);
  useUpdateSystemsTable(systemsTable, overallChanges);

  return (
    <CreateChange
      organizationID={organizationID}
      changeItem={changeItem}
      loadingAndOutdated={loadingAndOutdated}
      overallChanges={overallChanges}
      formHook={formHook}
      systemsTable={systemsTable}
      adjustComponent={
        <AdjustAssetSize
          organizationID={organizationID}
          assetSize={oldAssetSize}
          changeItem={changeItem}
          setChangeItem={setChangeItem}
        />
      }
    />
  );
};

const AdjustAssetSize = ({ assetSize, changeItem, setChangeItem }) => {
  const adjustAssetSizeHook = useAdjustAssetSize({ assetSize });

  // Create the temp item for monte carlo
  useEffect(() => {
    if (adjustAssetSizeHook.assetSizeChange) {
      setChangeItem({
        assetSizeChange: parseFloat(adjustAssetSizeHook.assetSizeChange),
      });
    }
  }, [assetSize, adjustAssetSizeHook.assetSizeChange]);

  return <div>{adjustAssetSizeHook.display}</div>;
};

const CreateAssetSizeChange = (props) => {
  return (
    <OrganizationCheck {...props}>
      <CreateAssetSizeChangeBody />
    </OrganizationCheck>
  );
};

export default CreateAssetSizeChange;
