import { Badge, Button, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import React, { useContext, useEffect, useState } from "react";

import Styles from "./css/NotificationsCard.module.css";
import { UIContext } from "../../utils/Context/UIContext";
import { eventType } from "./enums/eventType";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/** EventsCard - displays the current list of toasts (activities & reminders)
 *  @returns {*}
 */
const EventsCard = ({ onOpenTabCallback, toggleModal }) => {
  const { ...layoutToastsHook } = useContext(UIContext);
  const [activeTab, setActiveTab] = useState(eventType.NOTIFICATION); //Defaults to activity tab

  //A helpful message to use when no notifications exist
  const zeroEvents = () => {
    return (
      <div style={{ padding: "1.5em .5em" }}>
        <h5 style={{ textAlign: "center" }}> Nothing new here! </h5>
        {activeTab === eventType.ACTIVITY ? (
          <p style={{ textAlign: "center", margin: "0px" }}>
            {" "}
            When you will interact with the app, updates will appear here.
          </p>
        ) : (
          <p style={{ textAlign: "center", margin: "0px" }}>
            {" "}
            Due dates, upcoming events, and general information will appear here.
          </p>
        )}
      </div>
    );
  };

  const onTabPress = (tab) => {
    onOpenTabCallback && onOpenTabCallback(tab);

    //Set activity notifications as seen if explicitly pressed on a different tab
    layoutToastsHook.markAllToastsAsSeen(activeTab);

    //Update notification content only if a new one is pressed
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const onClearAllPress = () => {
    layoutToastsHook.clearToasts(activeTab);
  };

  //On start set the last opened tab callback to active default tab
  useEffect(() => {
    onOpenTabCallback && onOpenTabCallback(activeTab);
  }, []);

  return (
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={
              activeTab === eventType.NOTIFICATION ? `${Styles["tab-title-active"]} active` : Styles["tab-title"]
            }
            onClick={() => {
              onTabPress(eventType.NOTIFICATION);
            }}
          >
            Notifications{" "}
            {!isNullOrUndefined(layoutToastsHook?.newNotificationAmount) &&
              layoutToastsHook.newNotificationAmount !== 0 && (
                <Badge color="danger" styles={{ marginLeft: "1.5em" }}>
                  {layoutToastsHook.newNotificationAmount}
                </Badge>
              )}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === eventType.ACTIVITY ? `${Styles["tab-title-active"]} active` : Styles["tab-title"]}
            onClick={() => {
              onTabPress(eventType.ACTIVITY);
            }}
          >
            Activity{" "}
            {!isNullOrUndefined(layoutToastsHook?.newActivityAmount) && layoutToastsHook.newActivityAmount !== 0 && (
              <Badge color="danger" styles={{ marginLeft: "1.5em" }}>
                {layoutToastsHook.newActivityAmount}
              </Badge>
            )}
          </NavLink>
        </NavItem>
        <div style={{ padding: ".3em", marginLeft: "auto" }}>
          <Button
            size="sm"
            color="danger"
            onClick={() => {
              onClearAllPress();
            }}
          >
            Clear All
          </Button>
        </div>
      </Nav>
      <TabContent style={{ borderTop: "2px solid rgba(0, 0, 0, 0.3)" }} activeTab={activeTab}>
        <TabPane style={{ padding: "0em .05em" }} tabId={eventType.ACTIVITY}>
          {layoutToastsHook && layoutToastsHook.display && layoutToastsHook.totalActivityAmount
            ? layoutToastsHook.display(true, eventType.ACTIVITY, toggleModal)
            : zeroEvents()}
        </TabPane>
        <TabPane style={{ padding: "0em 0em" }} tabId={eventType.NOTIFICATION}>
          {layoutToastsHook && layoutToastsHook.display && layoutToastsHook.totalNotificationAmount
            ? layoutToastsHook.display(true, eventType.NOTIFICATION, toggleModal)
            : zeroEvents()}
        </TabPane>
      </TabContent>
    </div>
  );
};
export default EventsCard;
