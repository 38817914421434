import { useDocumentEditorModal } from "../hooks/useDocumentEditorModal";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * Helper component for quick access to a Document Editor modal instance
 * @param {object} props - the props for this component
 * @returns {JSX.Element}
 * @constructor
 */
const DocumentEditorModalButton = (props) => useDocumentEditorModal(props).modalButton;

export default withOrganizationCheck(DocumentEditorModalButton);
