import Avatar from "@mui/material/Avatar";
import DataLoader from "../../../../../utils/LoadingComponents/DataLoader";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import { getPointOfContactName } from "../../../../Reports/ComplianceReport/functions/getPointOfContactName";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { makeStyles } from "@mui/styles";
import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme?.palette?.background?.paper,
  },
}));

/**
 * Displays a List of Points of Contact.
 * Does not handle any queries itself, only displays a list from the 'items' array
 * @param {object[]} items - the PointOfContacts or Users to display in the list
 * @param {boolean} [isLoading = false] - an isLoading boolean to use for the DataLoader
 * @param {string} [dataMessage = "No assigned Points of Contact"] - message to show for the DataLoader
 * @returns {JSX.Element}
 * @constructor
 */
const PointOfContactList = ({ items, isLoading = false, dataMessage = "No assigned Points of Contact" }) => {
  const classes = useStyles();

  const isEnoughData = !isNullOrUndefined(items) && Array.isArray(items) && items.length > 0;

  return (
    <DataLoader isEnoughData={isEnoughData} isLoading={isLoading} dataMessage={dataMessage}>
      <List dense className={classes.root}>
        {items.map((item) => (
          <PointOfContactListItem key={`point-of-contact-${item?.id}`} item={item} />
        ))}
      </List>
    </DataLoader>
  );
};

/**
 * A single Point of Contact or User item for the list
 * @param {object} item - the Point of Contact or User item
 * @param {string} item.id - the ID for Point of Contact or User item
 * @param {string} item.email - the email for Point of Contact or User item
 * @param {string} [item.name] - the name of the User Item (if showing Users)
 * @param {string} [item.firstName] - the first name of Point of Contact item (if showing PointOfContacts)
 * @param {string} [item.lastName] - the last name of Point of Contact item (if showing PointOfContacts)
 * @returns {JSX.Element}
 * @constructor
 */
const PointOfContactListItem = ({ item }) => {
  const labelId = `pointofcontact-list-label-${item?.id}`;

  return (
    <ListItem key={item?.id} button>
      <ListItemAvatar>
        <Avatar alt={`Avatar ${item?.id}`} src={"./assets/img/avatars/person.png"} />
      </ListItemAvatar>
      <ListItemText id={labelId} primary={`${item?.name || getPointOfContactName(item)} | ${item?.email}`} />
    </ListItem>
  );
};

export default withOrganizationCheck(PointOfContactList);
