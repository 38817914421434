import { useNestedDataCard } from "../../../../hooks/views/useNestedDataCard/useNestedDataCard";
import { modules, resources } from "@rivial-security/role-utils";
import AutomationsGrid from "../../../OrganizationManager/Automations/components/AutomationsGrid";
import { useAutomationsGrid } from "../../../OrganizationManager/Automations/hooks/useAutomationsGrid";
import CreateAutomation from "../../../OrganizationManager/Automations/components/CreateAutomation";
import React from "react";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * A data grid of Automations that are attached to a Tool.
 * @returns {JSX.Element}
 * @constructor
 */
const ToolAutomations = ({ item, organizationID }) => {
  const dataGrid = useNestedDataCard({
    item,
    title: "Tool Automations",
    module: modules.TOOLS,
    resource: resources.AUTOMATION,
    field: "automations",
    connectionIDField: "toolID",
    typename: resources.AUTOMATION,
    parentTypename: "Tool",
    grid: <AutomationsGrid />,
    gridHook: useAutomationsGrid,
    form: <CreateAutomation />,
    fields: ["name", "description", "automations"],
    nestedFields: {
      automations: `(limit: 1000) { items { id name description enabled toolID ownerGroup } }`,
    },
    organizationID,
  });

  return <div style={{ height: "100%" }}>{dataGrid.display}</div>;
};

export default withOrganizationCheck(ToolAutomations);
