import { useEffect, useState } from "react";

import { Alert } from "reactstrap";
import FileViewer from "../../../../utils/FilePreviews/FileViewer";
import Loader from "../../../../utils/LoadingComponents/Loader";
import { getUrl } from "../functions/getUrl";

export interface DocumentPreviewProps {
  document?: object;
  url: string;
  organizationID: string;
}
/**
 * Helper component to preview a document
 * @param document - document object
 * @param externalUrl - alternative document url source
 * @param organizationID - selected organization
 */
export const DocumentPreview = ({ document, url: externalUrl, organizationID }: DocumentPreviewProps): JSX.Element => {
  const [url, setUrl] = useState(externalUrl);
  const [error, setError] = useState<JSX.Element>();

  useEffect(() => {
    if (document) {
      getUrl({
        document,
        setUrl,
        organizationID,
        onError: () =>
          setError(
            <div style={{ width: "100%", textAlign: "center" }}>
              <h3>File can not be found</h3>
              <p>Please contact a Rivial administrator</p>
            </div>,
          ),
      });
    }
  }, [document]);

  const x = url ? (
    <FileViewer url={url} />
  ) : (
    <div style={{ margin: "auto", textAlign: "center" }}>
      <Loader style={{ width: "10em", height: "10em" }} />
    </div>
  );

  return <div>{error ? <Alert color="danger">{error}</Alert> : x}</div>;
};
