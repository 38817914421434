import { modules as MODULES, modules, resources } from "@rivial-security/role-utils";

import AccessControl from "../../../../utils/AccessControl/components/AccessControl";
import AccessControlStatus from "../components/AccessControlStatus";
import AddAccessControl from "../../../../utils/AccessControl/components/AddAccessControl";
import AddMeetingParticipants from "../components/AddMeetingParticipants";
import CreateTask from "../../Tasks/components/CreateTask";
import Dashboard from "../../../../utils/GenericComponents/Dashboard";
import DashboardCard from "../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import FindingsCard from "../../Findings/components/FindingsCard/FindingsCard";
import { GENERIC_FIELD_TYPES } from "../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import GenerateReportModal from "../../../../utils/GenericComponents/GenerateReportModal";
import Grid from "@mui/material/Grid";
import MeetingAgendaWrapper from "../components/Agenda/MeetingAgendaWrapper";
import MeetingDocuments from "../components/MeetingDocuments";
import { MeetingIsAllDay } from "../customFields/MeetingIsAllDay";
import MeetingParticipants from "../components/MeetingParticipants";
import MeetingSideBar from "../components/MeetingSideBar";
import MeetingStatus from "../customFields/MeetingStatus";
import { MeetingTime } from "../customFields/MeetingTime";
import { REPORT_TEMPLATES } from "@rivial-security/report-utils";
import React from "react";
import ReportsGridByItemID from "../../../Reports/ReportsGridByItemID";
import SendMeetingSummary from "../components/SendMeetingSummary/SendMeetingSummary";
import StyledWrapper from "../../../../utils/GenericComponents/StyledWrapper";
import TagsField from "../../../../utils/Tags/customFields/TagsField";
import TaskDataGrid from "../../Tasks/components/TaskDataGrid";
import { createMeetingTagLink } from "../functions/createMeetingTagLink";
import { generateGraphql } from "@rivial-security/generategraphql";
import { nestedFieldsMeetingDetails } from "../graphql/__meetingsGQL";
import { useAccordion } from "../../../../hooks/views/useAccordion/useAccordion";
import { useDetailsCard } from "../../../../hooks/views/useDetailsCardV2";
import { useNestedDataCard } from "../../../../hooks/views/useNestedDataCard/useNestedDataCard";
import { useTaskDataGrid } from "../../Tasks/hooks/useTaskDataGrid";

/**
 * Meeting details hook
 * @param {string} organizationID
 * @param {string} itemId
 * @param {function} toggleModal
 * @returns {object}
 */
export const useMeetingDetails = ({ organizationID, itemId, toggleModal, queryFields = [], ...props }) => {
  const module = modules.GOVERNANCE;
  const resource = resources.MEETING;

  const { getQuery, updateMutation } = generateGraphql(
    "Meeting",
    [
      "name",
      "description",
      "status",
      "awsChimeMeeting",
      "organizer",
      "location",
      "isAllDay",
      "startTime",
      "endTime",
      "agendaItems",
      "agenda",
      "documents",
      "linkedResources",
      "accessControl",
      "notes",
      "tags",
      "module",
      "pointOfContacts",
    ],
    nestedFieldsMeetingDetails,
  );

  const customFields = [
    {
      field: "status",
      component: <MeetingStatus {...props} />,
    },
    {
      field: "startTime",
      component: <MeetingTime timeFieldName={"startTime"} {...props} />,
    },
    {
      field: "endTime",
      component: <MeetingTime timeFieldName={"endTime"} {...props} />,
    },
    {
      field: "isAllDay",
      component: <MeetingIsAllDay {...props} />,
    },
    {
      field: "tags",
      component: (
        <TagsField
          module={module}
          resource={resource}
          createLinkFunction={createMeetingTagLink}
          organizationID={organizationID}
        />
      ),
    },
    {
      field: "module",
      inputType: GENERIC_FIELD_TYPES.MODULE,
    },
    {
      field: "accessControl",
      component: <AccessControlStatus {...props} />,
    },
  ];

  const fields = [
    "name",
    "description",
    "status",
    "startTime",
    "endTime",
    "isAllDay",
    "organizer",
    "location",
    "tags",
    "module",
    "accessControl",
  ];

  const queryConfig = {
    query: getQuery,
    itemId,
  };

  const detailsConfig = {
    fields,
    nestedFields: nestedFieldsMeetingDetails,
    customFields,
    updateMutation,
  };

  const detailsHook = useDetailsCard({
    queryConfig,
    detailsConfig,
    enableNotes: true,
    customFields,
    fields,
    fieldNameDictionary: {
      name: "Name",
      description: "Description",
      startTime: "Start Time",
      endTime: "End Time",
      isAllDay: "Is All Day",
      organizer: "Organizer",
      location: "Location",
      status: "Status",
      accessControl: "Access Control Status",
    },
    module,
    resource,
    updateMutation,
    config: {
      enableNotes: true,
      observationConnectionField: "meetingID",
    },
    ...props,
  });
  const dataCard = useNestedDataCard({
    item: detailsHook?.item,
    organizationID,
    field: "tasks",
    connectionIDField: "meetingID",
    typename: resources.TASK,
    parentTypename: resources.MEETING,
    grid: <TaskDataGrid />,
    gridHook: useTaskDataGrid,
    gridProps: {
      module,
      resource,
      bulkEdit: true,
    },
    form: <CreateTask />,
  });

  const accordion = useAccordion({
    items: [
      {
        id: "meeting-general-information",
        title: "General Information",
        subTitle: (
          <MeetingSideBar
            item={detailsHook?.item}
            resetFunction={detailsHook?.resetFunction}
            toggleModal={toggleModal}
          />
        ),
        icon: "icon-list",
        component: detailsHook.tableDisplay,
        defaultExpanded: true,
      },
      {
        id: "meeting-findings",
        title: "Findings",
        subTitle: "Observations, Recommendations, and Action Items",
        component: (
          <FindingsCard
            organizationID={organizationID}
            meeting={{
              id: itemId,
            }}
            sx={{ height: "75vh" }}
          />
        ),
        icon: "icon-eye",
      },
      {
        id: "meeting-documents",
        title: "Documents",
        subTitle: "Documents related to this Meeting",
        component: <MeetingDocuments item={detailsHook?.item} organizationID={organizationID} />,
        icon: "icon-docs",
      },
      {
        id: "meeting-tasks",
        title: "Tasks",
        subTitle: "Tasks related to this Meeting",
        component: <StyledWrapper wrapperStyle={{ height: "50vh" }}>{dataCard?.gridDisplay}</StyledWrapper>,
        icon: "icon-check",
      },
      {
        id: "meeting-reports",
        title: "Reports",
        subTitle: "Reports related to this Meeting",
        component: <ReportsGridByItemID itemId={itemId} />,
        icon: "icon-docs",
      },
    ],
  });

  const display = (
    <AccessControl item={detailsHook?.item} resetFunction={detailsHook?.resetFunction} typename={"Meeting"}>
      <Dashboard
        item={detailsHook?.item}
        subTitle={detailsHook?.item?.name}
        headerButtons={[
          <SendMeetingSummary key={0} item={detailsHook?.item} organizationID={organizationID} />,
          <GenerateReportModal
            key={1}
            itemId={itemId}
            organizationID={organizationID}
            type={REPORT_TEMPLATES.MEETING_TEMPLATE}
            module={MODULES.GOVERNANCE}
          />,
          <AddAccessControl
            key={2}
            resetFunction={detailsHook?.reset}
            typename={"Meeting"}
            accessControlEnabled={true}
          />,
        ]}
        resetFunction={detailsHook.reset}
      >
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}>
            <Grid item xs={12} lg={12} style={{ marginTop: "1.5em" }}>
              {accordion?.display}
            </Grid>
          </Grid>

          <Grid item lg={6} xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <DashboardCard
                  style={{ overflowY: "auto", height: "40em" }}
                  title="Participants"
                  icon={"icon-people"}
                  headerButtons={[
                    <AddMeetingParticipants
                      item={detailsHook?.item}
                      resetFunction={detailsHook?.resetFunction}
                      organizationID={organizationID}
                    />,
                  ]}
                >
                  <MeetingParticipants item={detailsHook?.item} resetFunction={detailsHook?.resetFunction} />
                </DashboardCard>
              </Grid>
              <Grid item xs={12} lg={12}>
                <MeetingAgendaWrapper
                  organizationID={organizationID}
                  detailsHook={detailsHook}
                  meeting={detailsHook?.item}
                  resetFunction={detailsHook?.resetFunction}
                  sx={{
                    dashboardCard: {
                      overflowY: "auto",
                      height: "50em",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dashboard>
    </AccessControl>
  );

  return {
    ...detailsHook,
    display,
  };
};
