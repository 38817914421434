import AuditControlGeneralInformation from "../components/AuditControlGeneralInformation";
import ControlAudits from "../../Controls/Controls/customFields/ControlAudits/ControlAudits";
import Dashboard from "../../../../utils/GenericComponents/Dashboard";
import DashboardCard from "../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import EvidenceField from "../components/EvidenceField";
import FindingsCard from "../../../Program/Findings/components/FindingsCard/FindingsCard";
import Grid from "@mui/material/Grid";
import KeyPerformanceIndicators from "../../../Risk/RiskConfig/ControlCategories/components/SubControls/customFields/KeyPerformanceIndicators";
import React from "react";
import { getIcon } from "../../../../utils/Functions/Icon/getIcon";
import { useAccordion } from "../../../../hooks/views/useAccordion/useAccordion";
import { useControlDetails } from "../../Controls/Controls/hooks/useControlDetails/useControlDetails";

/**
 * Displays a Control Details page specific to an Audit
 * @param {object} item - the audit control grid item (not an actual schema type but merged from two compliance Control and AuditControlLink)
 * @param {string} organizationID - the currently selected organization
 * @param {object} audit - the audit data that this control is part of
 * @param {function} optimisticUpdate - callback to perform mutations
 * @param {function} updateItemById - callback to update the grid data
 * @returns {{isLoading, setInputId, inputId, item, display: JSX.Element, reset, setItem}}
 */
export const useAuditControlDetails = ({
  item,
  organizationID,
  audit,
  updateItemById,
  optimisticUpdate: externalOptimisticUpdate,
}) => {
  /**
   * A wrapper around the passed in optimistic update capable of
   * @param change
   */
  const optimisticUpdate = (change) => {
    const { field, value } = change || {};

    if (typeof updateItemById === "function" && item?.id && field && value) {
      updateItemById({
        id: item?.id,
        [field]: value,
      });
    }

    if (typeof externalOptimisticUpdate === "function") {
      externalOptimisticUpdate(change);
    }
  };

  /**
   * Details of the Underlying control
   * @type {{isLoading, setInputId, inputId, item, display: *, reset, setItem}}
   */
  const parentControlDetails = useControlDetails({
    itemId: item?.id,
    organizationID,
    hiddenFields: ["inPlace", "isDisabled"],
    updateItemById: (newItem) => {
      //Create an object with all the fields shown in the audit grid
      let newControl = {
        id: item?.id,
        statementNumber: newItem?.statementNumber,
        name: newItem?.name,
        controlSet: newItem?.controlSet,
        evidences: newItem?.evidences,
        customFieldData: newItem?.customFieldData,
        changes: newItem?.changes,
        tags: newItem?.tags,
        notes: newItem?.notes,
      };

      //Remove any undefined values
      //REFERENCE: https://stackoverflow.com/questions/286141/remove-blank-attributes-from-an-object-in-javascript
      newControl = Object.fromEntries(Object.entries(newControl).filter(([_, v]) => v != undefined));

      //Update the parent through a callback
      updateItemById && updateItemById(newControl);
    },
  });

  const accordion = useAccordion({
    items: [
      {
        title: "Findings",
        icon: "icon-rocket",
        subTitle: "Observations, Recommendations, and Action Items specific to this Audit",
        component: <FindingsCard audit={audit} control={item} />,
        defaultExpanded: true,
      },
      {
        title: "Key Performance Indicators",
        subTitle: "Key Performance Indicators associated with this Control via associated Evidence",
        icon: getIcon("entypo:gauge"),
        component: (
          <div>
            <KeyPerformanceIndicators organizationID={organizationID} control={parentControlDetails?.item} />
          </div>
        ),
      },
      {
        title: "Associated Audits",
        icon: "icon-film",
        subTitle: "Other Audits that have assessed this Control",
        component: (
          <div>
            <ControlAudits item={parentControlDetails.item} audit={audit} />
          </div>
        ),
      },
    ],
  });

  const display = (
    <Dashboard>
      <Grid container spacing={2}>
        <Grid item lg={6}>
          <DashboardCard title={"General Information"} style={{ height: "100%" }} icon="icon-list">
            {parentControlDetails.tableDisplay}
          </DashboardCard>
        </Grid>

        <Grid item container spacing={2} lg={6}>
          <Grid item lg={12} sm={12}>
            <DashboardCard title={"Audit Specifics"} style={{ height: "100%" }}>
              <AuditControlGeneralInformation optimisticUpdate={optimisticUpdate} item={item} audit={audit} />
            </DashboardCard>
          </Grid>
          <Grid item lg={12} sm={12}>
            <DashboardCard title={"Evidence"} style={{ height: "100%" }}>
              <EvidenceField item={item} optimisticUpdate={optimisticUpdate} audit={audit} />
            </DashboardCard>
          </Grid>
        </Grid>
        <Grid item lg={12} xs={12}>
          {accordion.display}
        </Grid>
      </Grid>
    </Dashboard>
  );

  return {
    ...parentControlDetails,
    display,
  };
};
