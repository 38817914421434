import { usePointOfContactDataGrid } from "../../PointOfContacts/hooks/usePointOfContactDataGrid";
import { useNestedDataCard } from "../../../../hooks/views/useNestedDataCard/useNestedDataCard";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import AddSinglePointOfContact from "../../PointOfContacts/components/AddSinglePointOfContact";
import PointOfContactDataGrid from "../../PointOfContacts/components/PointOfContactDataGrid";

/**
 * Displays a list of Points of Contact for a Department
 * @param {DEPARTMENT} item - the Department object to display Members for
 * @param {string} organizationID - the ID of the Organization that the Department belongs to
 * @returns {JSX.Element}
 * @constructor
 */
const DepartmentMembers = ({ item, organizationID }) => {
  const nestedDataGrid = useNestedDataCard({
    item,
    organizationID,
    field: "departmentMembers",
    connectionIDField: "departmentID",
    typename: "PointOfContact",
    parentTypename: "Department",
    grid: <PointOfContactDataGrid organizationID={organizationID} />,
    gridHook: usePointOfContactDataGrid,
    form: <AddSinglePointOfContact />,

    // query
    fields: ["departmentMembers"],
    nestedFields: {
      departmentMembers: `
        (limit: 1000) {
          items {
            id
            firstName
            lastName
            phone
            email
            title
            ownerGroup
          }
        }
      `,
    },
  });

  return <div style={{ height: "50vh" }}>{nestedDataGrid.display}</div>;
};

export default withOrganizationCheck(DepartmentMembers);
