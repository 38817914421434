import { CreateTagInput, Questionnaire } from "@rivial-security/schema-types";

import { ItemMutation } from "../../../Functions/Graphql/ItemMutation";
import { generateGraphql } from "@rivial-security/generategraphql";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Creates a QuestionnaireTagLink object
 * @param questionnaire - the questionnaire to which to attach the tag
 * @param tag - the tag that needs to be attached to the questionnaire
 * @param organizationID - the organization identifier of the questionnaire in the database
 * @returns {Promise<*>}
 */
export const createQuestionnaireTagLink = (
  questionnaire: Questionnaire,
  tag: CreateTagInput,
  organizationID: string,
): Promise<unknown> => {
  if (isNullOrUndefined(tag) || isNullOrUndefined(questionnaire?.id)) {
    throw Error("Invalid input!");
  }

  const { createMutation: createQuestionnaireTagLink } = generateGraphql("QuestionnaireTagLink", [
    "questionnaireID",
    "tagID",
    "__typename",
  ]);

  return ItemMutation(createQuestionnaireTagLink, {
    questionnaireID: questionnaire.id,
    tagID: tag.id,
    ownerGroup: organizationID,
  });
};
