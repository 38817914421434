import TimelineUnderlinedDetails from "./TimelineUnderlinedDetails";
import { resources } from "@rivial-security/role-utils";
import EllipsisText from "../../../../../../utils/Text/components/EllipsisText";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import InitialStatusTextWithTimelineDetails from "./InitialStatusTextWithTimelineDetails";

/**
 * Timeline content to display when a vulnerability is first found
 * @param {object} item - the TargetVulnerabilityHistory item
 * @returns {JSX.Element}
 */
const InitialStatus = ({ item }) => {
  const isFindingAttached = !!item?.vulnerabilityName;
  const getInitialStatusText = () => {
    const baseText = " was initially found to have this ";
    const targetOrVulnerabilityText = isFindingAttached ? "target" : "vulnerability";
    const assessmentText = isNullOrUndefined(item?.assessmentID) ? "" : " in ";
    return baseText + targetOrVulnerabilityText + assessmentText;
  };

  if (isFindingAttached) {
    return (
      <span>
        {"Vulnerability "}
        <TimelineUnderlinedDetails modalType={resources.VULNERABILITY} item={item}>
          <EllipsisText text={item?.vulnerabilityName} maxLength={15} />
        </TimelineUnderlinedDetails>
        <InitialStatusTextWithTimelineDetails initialStatusText={getInitialStatusText()} item={item} />
      </span>
    );
  } else {
    return (
      <span>
        {"Target "}
        <TimelineUnderlinedDetails tooltipText="Click to view Target Details" modalType={resources.TARGET} item={item}>
          <EllipsisText text={item?.targetName} maxLength={15} />
        </TimelineUnderlinedDetails>
        <InitialStatusTextWithTimelineDetails initialStatusText={getInitialStatusText()} item={item} />
      </span>
    );
  }
};

export default InitialStatus;
