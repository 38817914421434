import { CONTROL_MAPPING_TYPES } from "./CONTROL_MAPPING_TYPES";
import { controlControlMapping } from "../functions/controlControlMapping";
import { controlRiskControlMapping } from "../functions/controlRiskControlMapping";
import { controlEvidenceMapping } from "../functions/controlEvidenceMapping";

/**
 * The configuration for the Control Mapping Export Data Grid.
 * Holds the dataGrid fields and the mapping function to use for each mapping type
 */
export const CONTROL_MAPPING_CONFIG = {
  [CONTROL_MAPPING_TYPES.CONTROL_EVIDENCE_MAPPING]: {
    fields: [
      {
        name: "controlStatementNumber",
        label: "Control Statement Number",
        flex: 0.5,
        format: "number",
      },
      {
        name: "controlName",
        label: "Control Name",
        flex: 1,
      },
      {
        name: "evidenceItemNumber",
        label: "Evidence Item Number",
        flex: 0.5,
      },
      {
        name: "evidenceName",
        label: "Evidence Name",
        flex: 1,
      },
    ],
    mappingFunction: controlEvidenceMapping,
    tooltip:
      "This will show all Evidence Items mapped to the selected Controls. Each row will show a Control and the Evidence Item mapped to it.",
  },
  [CONTROL_MAPPING_TYPES.CONTROL_CONTROL_MAPPING]: {
    fields: [
      {
        name: "controlStatementNumber",
        label: "Control Statement Number",
        flex: 0.5,
        format: "number",
      },
      {
        name: "controlName",
        label: "Control Name",
        flex: 1,
      },
      {
        name: "mappedControlStatementNumber",
        label: "Mapped Control Statement Number",
        flex: 0.5,
        format: "number",
      },
      {
        name: "mappedControlName",
        label: "Mapped Control Name",
        flex: 1,
      },
      {
        name: "mappedControlFrameworkName",
        label: "Mapped Control Framework Name",
        flex: 0.8,
      },
    ],
    mappingFunction: controlControlMapping,
    tooltip:
      "This will show the mapping from selected Controls to other Compliance Controls, using shared Evidence as the intermediary. Each row will show a Control and the other Control mapped to it. Duplicate mappings and self-mappings are removed.",
  },
  [CONTROL_MAPPING_TYPES.CONTROL_RISK_CONTROL_MAPPING]: {
    fields: [
      {
        name: "controlStatementNumber",
        label: "Control Statement Number",
        format: "number",
        flex: 0.5,
      },
      {
        name: "controlName",
        label: "Control Name",
        flex: 1,
      },
      {
        name: "riskControlStatementNumber",
        label: "Risk Control Statement Number",
        format: "number",
        flex: 0.5,
      },
      {
        name: "riskControlName",
        label: "Risk Control Name",
        flex: 1,
      },
    ],
    mappingFunction: controlRiskControlMapping,
    tooltip:
      "This will show the mapping from selected Controls to Risk Controls, using shared Evidence as the intermediary. Each row will show a Control and the Risk Control mapped to it. Duplicate mappings are removed.",
  },
};
