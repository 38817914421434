import React, { useEffect, useState } from "react";
import { Chip } from "@mui/material";

/**
 * Hook displaying a set of selectable categories (chips)
 * @param {object} [chipOptions={}] - enum type object where the values are the chip labels
 * @param {object[]} [items=[]] - items from which to filter (must have an array of "chips" to be useful)
 * @param {boolean} [oneAtATime=true] - if true will allow only one chip to selected at a time
 * @param {boolean} [showCounts=true] - if true will show the amount of items matching each chip
 * @param {function} [valueFormatter] - function to format the chip label based on its assigned value
 * @param {object} [sx={}] - css inline styles for different parts of the component
 * @param {object} [sx.chipsContainer] - css inline styles for the container of the chips
 * @return {{display: JSX.Element, selected: *[]}}
 */
export const useFilteringChips = ({
  chipOptions = {},
  items = [],
  oneAtATime = true,
  showCounts = true,
  valueFormatter,
  sx = {},
} = {}) => {
  const getOptions = () => {
    const options = [];
    if (chipOptions) {
      for (const option in chipOptions) {
        const value = chipOptions[option];
        const fullOption = { value };

        let amountMatching = 0;
        for (const item of items) {
          if (Array.isArray(item?.chips) && item.chips.includes(value) && item?.visible !== false) {
            amountMatching++;
          }
        }
        fullOption.amount = amountMatching;

        options.push(fullOption);
      }
    }

    return options;
  };

  const [options, setOptions] = useState(getOptions());
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    const currentOptions = options;
    const newOptions = getOptions();

    if (JSON.stringify(currentOptions) !== JSON.stringify(newOptions)) {
      setOptions(newOptions);
    }
  }, [chipOptions, items]);

  const onSelected = (option) => {
    if (oneAtATime) {
      if (selected.includes(option)) {
        setSelected([]);
      } else {
        setSelected([option]);
      }
    } else {
      const selectedIndex = selected.findIndex((searchedItem) => searchedItem === option);
      if (selectedIndex !== -1) {
        setSelected(selected.filter((searchedItem) => searchedItem !== option));
      } else {
        setSelected([...selected, option]);
      }
    }
  };

  const display = (
    <div style={{ lineHeight: "1.5em", ...(sx?.chipsContainer || {}) }}>
      {Array.isArray(options) &&
        options.length > 0 &&
        options.map((item) => {
          //check if selected
          const isSelected =
            Array.isArray(selected) && selected.findIndex((searchedItem) => searchedItem === item?.value) !== -1;

          return (
            <Chip
              size={"small"}
              sx={{
                margin: ".5em .5em",
                padding: "0em",
                "& .MuiChip-label": {
                  lineHeight: "2em",
                },
              }}
              label={`${typeof valueFormatter === "function" ? valueFormatter(item?.value) : item?.value}${
                showCounts ? ` - ${item?.amount}` : ""
              }`}
              onClick={() => onSelected(item?.value)}
              color={isSelected ? "primary" : "default"}
            />
          );
        })}
    </div>
  );

  return {
    display,
    selected,
  };
};
