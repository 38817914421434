import { Col, Input, Label } from "reactstrap";
import { ErrorLogger, InfoLogger } from "../../EventLogger";
import React, { useState } from "react";

import { Auth } from "@aws-amplify/auth";
import LoginButton from "./LoginButton";
import { useNewPassword } from "../hooks/useNewPassword";

/**
 * @description This component is used to reset the password for the user.
 * @param {function} setIsForgotPassword - function to set the forgot password state.
 * @returns {JSX.Element} - The forgot password component.
 * @constructor
 */
const ForgotPassword = ({ setIsForgotPassword }) => {
  const passwordHook = useNewPassword({ enableResetButton: false });

  const [username, setUsername] = useState("");
  const [code, setCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const isSubmitEnabled =
    username && code && passwordHook.isPasswordMatched && passwordHook.newPassword && passwordHook.reEnterPassword;

  const onForgotPassword = async () => {
    if (username && code && passwordHook && passwordHook.newPassword && passwordHook.isPasswordMatched) {
      setIsLoading(true);
      await Auth.forgotPasswordSubmit(username, code, passwordHook.newPassword)
        .then((data) => {
          InfoLogger(data);
          alert("Password was successfully reset");
          window.location.reload();
        })
        .catch((err) => {
          ErrorLogger(`Error! Reset password for user: ${username}`, err);
          const errorMessage = err?.message;
          if (errorMessage.includes("Invalid verification code")) {
            alert("Invalid verification code");
          } else {
            alert("Error resetting password");
          }
        });
      setIsLoading(false);
    }
  };

  return (
    <>
      <Col md={12}>
        <Label htmlFor="username">Email</Label>
        <span> </span>
        <Input
          className="appearance-none border rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline"
          id="username"
          key="username"
          name="username"
          type="email"
          autoFocus={true}
          onChange={(e) => setUsername(e.target.value)}
        />
      </Col>
      <p> </p>
      <Col md={12}>
        <Label htmlFor="forgetPasswordCode">Code</Label>
        <span> </span>
        <Input
          className="appearance-none border rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline"
          id="forgetPasswordCode"
          key="forgetPasswordCode"
          name="forgetPasswordCode"
          type="text"
          autoComplete="off"
          onChange={(e) => setCode(e.target.value)}
          required
        />
      </Col>
      <p> </p>
      {passwordHook.display}
      <div style={{ padding: "0px 15px" }}>
        <LoginButton
          text={"Reset Password"}
          disabled={!isSubmitEnabled || isLoading}
          buttonColor={"#83C6E3"}
          textColor={"black"}
          title={"Change your password to the one above"}
          onClick={() => onForgotPassword()}
        />
        <LoginButton
          text={"Cancel"}
          buttonColor={"#CED4DA"}
          buttonBorder={"1px solid #CED4DA"}
          textColor={"black"}
          variant={"outlined"}
          title={"Cancel changing the password"}
          onClick={async () => {
            if (typeof setIsForgotPassword === "function") {
              setIsForgotPassword(false);
            }
          }}
        />
      </div>
    </>
  );
};

export default ForgotPassword;
