import { OperationQuantity } from "../constants/operationQuantity";
import { OperationType } from "../constants/OperationType";
import { getResourceAttribute } from "./getResourceAttribute";
import { getResourceDisplayName } from "./getResourceDisplayName";
import pluralize from "pluralize";

/**
 * Retrieves a confirmation note to show to the user prior to deleting a resource
 * @param {string} typename - the resource name
 * @param {string} operationQuantity - the OperationQuantity value based on how many resources are being deleted
 * @return {*|string} - the confirmation note (can be a component)
 */
export const getResourceConfirmDeleteNote = ({ typename, operationQuantity = OperationQuantity.SINGLE }) => {
  const displayTypename = getResourceDisplayName({ typename });

  let defaultConfirmDeleteNote = `When you delete a ${displayTypename}, all of its information will be removed immediately. This can't be undone.`;
  if (operationQuantity === OperationQuantity.BULK) {
    defaultConfirmDeleteNote = `When you delete ${pluralize(
      displayTypename,
    )}, all of their information will be removed immediately. This can't be undone.`;
  }

  return (
    getResourceAttribute({
      typename,
      attribute: `operations.${OperationType.DELETE}.${operationQuantity}.confirmDeleteNote`,
    }) || defaultConfirmDeleteNote
  );
};
