import { convertCamelCaseToSentence, isNullOrUndefined } from "@rivial-security/func-utils";
import { useEffect, useState } from "react";

/**
 * Helper hook allowing to keep count of the amount of selected items in grids
 * @param {object} grids - mapping object that maps item type to grid object returned by useGrid
 * @return {{resourceAmounts: {}, resourceAmountStrings: {}}}
 */
export const useSelectedItemCounter = ({ grids }) => {
  const [resourceAmounts, setResourceAmounts] = useState({});
  const [resourceAmountStrings, setResourceAmountStrings] = useState({});

  //Used to see if count values have changed
  const getAllCounts = () => {
    const result = [];
    for (const type in grids) {
      result.push(grids[type]?.data?.length);

      //If grid was created, the items to check would be at 0
      let selectedItemsLength = grids[type]?.itemsToCheck?.length || grids[type]?.selectedIDs?.length;
      if (!selectedItemsLength) {
        selectedItemsLength = grids[type]?.selectedItems?.length;
      }

      result.push(selectedItemsLength);
    }
    return JSON.stringify(result);
  };
  //Updates state values to reflect current counts from grids
  useEffect(() => {
    const newResourceAmounts = {};
    for (const type in grids) {
      //If grid was created, the items to check would be at 0
      let selectedItemsLength = grids[type]?.itemsToCheck?.length || grids[type]?.selectedIDs?.length;
      if (!selectedItemsLength) {
        selectedItemsLength = grids[type]?.selectedItems?.length;
      }

      newResourceAmounts[type] = {
        total: grids[type]?.data?.length ?? 0,
        selected: selectedItemsLength ?? 0,
      };
    }

    setResourceAmounts(newResourceAmounts);

    //Using the new resource amounts populate the string values for the counts
    const newResourceAmountStrings = {};
    for (const type in grids) {
      newResourceAmountStrings[type] = getAmountSelectedString({
        type,
        resourceAmounts: newResourceAmounts,
      });
    }
    setResourceAmountStrings(newResourceAmountStrings);
  }, [getAllCounts()]);

  return {
    resourceAmounts,
    resourceAmountStrings,
  };
};

/**
 * Returns a formatted string with the amount
 * @param {string} type - the grid type declared in the 'grids' prop
 */
const getAmountSelectedString = ({ type, resourceAmounts }) => {
  //if type of grid doesnt exist in the tracked grids return empty string
  if (!resourceAmounts.hasOwnProperty(type) || isNullOrUndefined(resourceAmounts[type])) {
    return "";
  }

  const total = resourceAmounts[type].total;
  const selected = resourceAmounts[type].selected;
  const typeString = convertCamelCaseToSentence(type).toLowerCase();

  //If there are no items to select then there are 0 selected items
  if (!total) {
    return `No ${typeString} to select`;
  }

  //if 0 items are selected display a custom message
  if (!selected) {
    return `No ${typeString} selected`;
  }

  //If have just the selected item count available display the selected count alon
  if (isNullOrUndefined(total) && !isNullOrUndefined(selected)) {
    return `${selected} ${typeString} selected`;
  }

  //If have both values return "X out of Z" style message
  if (!isNullOrUndefined(total) && !isNullOrUndefined(selected)) {
    return `${selected} out of ${total} ${typeString} selected`;
  }

  return "";
};
