import UnderlinedTooltip from "../../../../utils/GenericComponents/Tooltips/UnderlinedTooltip";
import React from "react";
import { formattedDollar, isNullOrUndefined } from "@rivial-security/func-utils";
import NumberChange from "../../../../utils/CustomFields/NumberChange";

/**
 * Displays residualRisk field for a system with a tooltip
 * @param {object} item - system object
 * @param {number} item.inherentRisk
 * @param {number} item.residualRisk
 * @returns {JSX.Element}
 * @constructor
 */
const ResidualRisk = ({ item }) => {
  return (
    <UnderlinedTooltip
      tooltip={
        <span>
          Residual Risk represents the potential annual loss of a System after all Security Controls are evaluated
          <br />
          <br />
          Inherent Risk: {!isNullOrUndefined(item?.inherentRisk) ? formattedDollar(item.inherentRisk) : "N/A"}
          <br />
          Risk Mitigated:{" "}
          {!isNullOrUndefined(item?.inherentRisk) ? (
            <NumberChange value={formattedDollar(item.residualRisk - item.inherentRisk)} />
          ) : (
            "N/A"
          )}
        </span>
      }
    >
      <span>{!isNullOrUndefined(item?.residualRisk) ? formattedDollar(item.residualRisk) : "N/A"}</span>
    </UnderlinedTooltip>
  );
};

export default ResidualRisk;
