import React, { useContext, useEffect, useState } from "react";

import { Alert } from "@mui/material";
import { LoadingSpinner } from "../../LoadingComponents/LoadingSpinner";
import NodejsCipher from "../../Functions/NodejsCipher";
import { OrganizationContext } from "../../Context/OrganizationContext";
import { WarningLogger } from "../../EventLogger";
import { useForm } from "../../../hooks/views/useForm/useForm";

/**
 * @description Access Control for protecting items
 * @param {object} item - database object with an accessControl field
 * @param component - child to be rendered after pass validation
 * @param children - child to be rendered after pass validation
 * @param {function} resetFunction - reset state of the item
 * @param {string} typename - schema type name with a capital first letter
 * @returns {{display: JSX.Element}}
 */
export const useAccessControl = ({ item, component, children, resetFunction, typename = "item" }) => {
  const context = useContext(OrganizationContext);

  const [password, setPassword] = useState("");
  const [passedValidation, setPassedValidation] = useState(false);
  const [accessDenied, setAccessDenied] = useState(false);
  const [error, setError] = useState();
  const [firstRender, setFirstRender] = useState(false);

  useEffect(() => {
    if (item?.accessControl && !firstRender) {
      setFirstRender(true);

      try {
        const accessControl = item?.accessControl;

        /**
         * Check if the user has role permissions
         */
        if (accessControl?.roles && Array.isArray(accessControl?.roles) && accessControl?.roles.length > 0) {
          const foundRole = accessControl?.roles.find((id) => id === context?.role?.id);
          if (foundRole) {
            setPassedValidation(true);
          } else {
            setAccessDenied(true);
          }
        }

        /**
         * Check if the user has pointOfContact permissions
         */
        if (
          accessControl?.pointOfContacts &&
          Array.isArray(accessControl?.pointOfContacts) &&
          accessControl?.pointOfContacts.length > 0
        ) {
          const foundContact = accessControl?.pointOfContacts.find((id) => id === context?.loggedInPointOfContactId);
          if (foundContact) {
            setPassedValidation(true);
          } else {
            setAccessDenied(true);
          }
        }
        /**
         * Check if the user is an admin or operation team member, if so, allow access
         */
        if (context?.isAdmin || context?.isInOperationTeamGroup) {
          setPassedValidation(true);
          setAccessDenied(false);
        }

        /**
         * Check if item password protected
         */
        if (accessControl?.password) {
          setPassword(accessControl?.password);
          setPassedValidation(false);
        } else {
          setPassedValidation(true);
        }
      } catch (e) {
        WarningLogger(`Can not parse ${typename} access control`);
      }
    } else if (item && !item?.accessControl) {
      setPassedValidation(true);
      setFirstRender(true);
    }
  }, [item]);

  const form = useForm({
    fieldConfig: {
      password: {
        required: true,
        inputType: "password",
        label: "Enter Password: ",
        tooltip: `This ${typename} is protected by a password.`,
      },
    },
    submitFunction: async (input) => {
      /**
       * TODO: Move this check to a lambda
       */
      const decryptedPassword = await NodejsCipher({
        operation: "decrypt",
        dataToDecrypt: password,
      });

      if (input?.password === decryptedPassword) {
        setPassedValidation(true);
        setError(undefined);
      } else {
        setError("Invalid Password");
      }
    },
    disableResetButton: true,
  });

  const display =
    /**
     * Check if user passed Access Control validation
     */
    !firstRender ? (
      <LoadingSpinner loadingMessage="Please wait.. " />
    ) : accessDenied ? (
      <Alert severity="error">Sorry, access denied.</Alert>
    ) : passedValidation ? (
      component || children ? (
        <div>{React.cloneElement(component || children)}</div>
      ) : (
        <div>Error: No component or children provided.</div>
      )
    ) : (
      /**
       * Show Access Control UI if item is protected
       */
      <div style={{ margin: "auto", width: "25%" }}>
        {error && (
          <Alert severity="error" style={{ marginBottom: "0.5em" }}>
            {error}
          </Alert>
        )}
        <div>{form?.display}</div>
      </div>
    );

  return {
    display,
    accessDenied,
  };
};
