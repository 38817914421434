import { LogoData } from "@application/Layout/hooks/types/useGetLogo";
import React from "react";

export interface OrganizationContextValues {
  accountType: string;
  appLogo: LogoData;
  getUserGroups: () => Promise<string[]>;
  isAdmin: boolean;
  isDev: boolean;
  isInOperationTeamGroup: boolean;
  loggedInPointOfContactId: string;
  loggedInUserId: string;
  operationTeamID: string;
  organizationName: string;
  organizationShortName: string;
  orgLogo: LogoData;
  preferences: {
    testing: {
      showInfoVulnerabilities: boolean;
    };
  };
  resetRoleConfig: () => void;
  role: string;
  roleConfig: Record<string, unknown>;
  selectedHighlightColor: string;
  selectedOrganization: string;
  selectOrganization: (organizationId: string) => void;
  selectedOrganizationObjectMinimal: {
    id: string;
    name: string;
    shortName: string;
  };
  sentryTrace: string | null;
  setHighlightColor: VoidFunction;
  setPreferences: (preferences: Record<string, unknown>) => void;
  setRoleConfig: (config: Record<string, unknown>) => void;
  setSideBar: (element: React.ReactElement | null) => void | null;
  setTimeoutDuration: (duration: number) => void;
  sideBar: React.ReactElement | null;
  timeoutDuration: number;
  toggleOrganization: VoidFunction;
  userCognitoGroups: unknown[];
  userEmail: string;
}

export const emptyOrganizationContext: OrganizationContextValues = {
  accountType: "",
  appLogo: { url: "", isLoading: true },
  getUserGroups: async () => await Promise.resolve([]),
  isAdmin: false,
  isDev: false,
  isInOperationTeamGroup: false,
  loggedInPointOfContactId: "",
  loggedInUserId: "",
  operationTeamID: "",
  organizationName: "",
  organizationShortName: "",
  orgLogo: { url: "", isLoading: true },
  preferences: {
    testing: {
      showInfoVulnerabilities: false,
    },
  },
  resetRoleConfig: () => undefined,
  role: "",
  roleConfig: {},
  selectedHighlightColor: "rgba(255,190,0,0.08)",
  selectedOrganization: "No Organization Selected",
  selectOrganization: () => undefined,
  selectedOrganizationObjectMinimal: {
    id: "",
    name: "",
    shortName: "",
  },
  sentryTrace: null,
  setHighlightColor: () => undefined,
  setPreferences: () => undefined,
  setRoleConfig: () => undefined,
  setSideBar: () => null,
  setTimeoutDuration: () => undefined,
  sideBar: null,
  timeoutDuration: 0,
  toggleOrganization: () => undefined,
  userCognitoGroups: [],
  userEmail: "",
};

export const OrganizationContext = React.createContext<OrganizationContextValues>(emptyOrganizationContext);
export const OrganizationProvider = OrganizationContext.Provider;
