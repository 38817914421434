import { ErrorLogger } from "@utils/EventLogger";
import { cognitoRoleGroups } from "../../Users/functions/cognitoRoleGroups";
import { getMany } from "../../../../utils/Functions/getMany";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Creates the lambda input for un-linking users to a role for calling the cognitoRoleGroups lambda
 * @param {object} item - the Role item to unlink users from
 * @param {string} item.id - the ID of the Role item to unlink users from
 * @param {object[]} itemsToUnLink - user objects to unlink from a role
 * @param {object[]} itemsToUnLink[].id - id of user objects to unlink from a role
 * @returns {Promise<void>}
 */
export const handleUserRoleUnlinking = async (item, itemsToUnLink) => {
  // The lambda event type
  const type = "unAssignUsers";

  // The array of userIDs to send to the lambda
  const userIDs = [];

  // The role object for the lambda
  const role = {
    id: item.id,
    ownerGroup: item.ownerGroup,
  };

  // itemsToLink will contain ALL users that are linked, not just new users.
  // parse this to only grab NEW users to link
  const currentUsersForRole = getMany(item, "userLinks", "user");

  // go through itemToLink and currentUsersForRole. Any itemToLink that is NOT in currentUsersForRole, keep it
  for (const itemToUnLink of itemsToUnLink) {
    const userAlreadyLinked = currentUsersForRole.some((currentUser) => currentUser.id === itemToUnLink.id);

    if (userAlreadyLinked) {
      // user is already linked, add to the userID array to unlink
      userIDs.push(itemToUnLink.id);
    } else {
      // user is not already linked, do nothing
    }
  }

  // Only call the lambda if there are inputs
  if (!isNullOrUndefined(userIDs) && Array.isArray(userIDs) && userIDs.length > 0) {
    ErrorLogger(`Sending users to unlink to lambda..${JSON.stringify(userIDs)}`);
    return cognitoRoleGroups({
      type,
      userIDs,
      role,
    });
  }
};
