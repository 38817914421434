import { CsvImporterModalButton } from "../../../../hooks/views/useCsvImporter";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import React from "react";
import { useEvidenceDataGrid } from "../hooks/useEvidenceGrid/useEvidenceDataGrid";
import { IMPORTER_FIELD_FORMATS } from "../../../../hooks/views/useCsvImporter/enums/IMPORTER_FIELD_FORMATS";
import {
  EVIDENCE_FREQUENCIES,
  EVIDENCE_STATUS,
  EVIDENCE_TYPES,
} from "../../../../typedefs/Compliance/Evidence/Evidence";

/**
 * Displays a Modal Button that opens the Evidence CSV Importer
 * @param {string} organizationID - selected organization ID to create Evidence for
 * @returns {JSX.Element}
 * @constructor
 */
const EvidenceImporterModalButton = ({ organizationID }) => {
  const typename = "Evidence";

  const fields = [
    {
      field: "name",
      name: "Name",
      required: true,
      width: 400,
      keywords: ["evidence", "artifact"],
      format: IMPORTER_FIELD_FORMATS.TEXT,
    },
    {
      field: "itemNumber",
      name: "Item Number",
      width: 150,
      keywords: ["ID"],
      format: IMPORTER_FIELD_FORMATS.TEXT,
    },
    {
      field: "type",
      name: "Type",
      width: 150,
      format: IMPORTER_FIELD_FORMATS.SELECT,
      options: Object.values(EVIDENCE_TYPES),
      defaultValue: "document",
    },
    {
      field: "frequency",
      name: "Frequency",
      width: 150,
      format: IMPORTER_FIELD_FORMATS.SELECT,
      options: EVIDENCE_FREQUENCIES,
      defaultValue: "0 9 1 * *", // Monthly
    },
    {
      field: "task",
      name: "Task",
      width: 200,
      format: IMPORTER_FIELD_FORMATS.TEXT,
    },
    {
      field: "status",
      name: "Status",
      width: 150,
      format: IMPORTER_FIELD_FORMATS.SELECT,
      options: Object.values(EVIDENCE_STATUS),
      defaultValue: "expired",
    },
    {
      field: "enabled",
      name: "Enabled",
      width: 100,
      format: IMPORTER_FIELD_FORMATS.BOOLEAN,
      defaultValue: false,
    },
    {
      field: "allowUnauthenticatedSubmissions",
      name: "Guest Uploads",
      width: 100,
      format: IMPORTER_FIELD_FORMATS.BOOLEAN,
      defaultValue: false,
    },
    {
      field: "notes",
      name: "Notes",
      width: 250,
      format: IMPORTER_FIELD_FORMATS.NOTES,
    },
  ];

  return (
    <CsvImporterModalButton
      fields={fields}
      organizationID={organizationID}
      typename={typename}
      resultGrid={useEvidenceDataGrid}
    />
  );
};

export default withOrganizationCheck(EvidenceImporterModalButton);
