import { ErrorLogger } from "../../EventLogger";
import { ItemMutation } from "../../Functions/Graphql/ItemMutation";
import { OrganizationContext } from "../../Context/OrganizationContext";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useContext } from "react";
import { useRichTextEditor } from "../../../hooks/editors/useRichTextEditor";
import { v4 as uuid } from "uuid";

/**
 * @description Add single note for an item
 * @param {string} typename - item type name from the schema
 * @param {string} organizationID - selected organization
 * @param {object[]} notes - add existing notes
 * @returns {object} {{submitFunction: (function(*): Promise<*>), getValue: (function(): (string|*)), ref: string, display: JSX.Element, setValue: setValue}}
 */
export const useAddSingleNote = ({ typename, organizationID, notes = [] }) => {
  const context = useContext(OrganizationContext);

  const richEditor = useRichTextEditor({
    editorStyle: {
      height: "15em",
    },
    ignoreFocus: true,
  });

  /**
   * The mutation used for the form hook
   */
  const { updateMutation } = generateGraphql(typename, ["notes"], {
    notes: "{ id type ownerGroup author timeStamp content tag observationID }",
  });

  /**
   * Submit function to attach notes
   */
  const submitFunction = async (item) => {
    const content = richEditor?.getValue();

    if (!content) {
      ErrorLogger("Content of the note is empty");
      return;
    }

    return await ItemMutation(updateMutation, {
      id: item?.id,
      notes: [
        ...notes,
        {
          id: uuid(),
          content: content,
          author: context?.userEmail ?? "Platform User",
          ownerGroup: organizationID,
          timeStamp: new Date(),
        },
      ],
    });
  };

  return {
    ...richEditor,
    submitFunction,
  };
};
