import { useDataGridStyles } from "../../../../../../../hooks/views/useDataGrid/hooks/useDataGridStyles";
import { useModal } from "../../../../../../../hooks/views/useModal";
import ControlMappingExportBody from "./ControlMappingExportBody";
import { Button } from "@mui/material";
import FileDownload from "@mui/icons-material/FileDownload";
import React from "react";
import { isNonEmptyArray } from "@rivial-security/func-utils";

/**
 * Modal and Button for exporting a Control Mapping from the Control Data Grid toolbar.
 * This is the primary means to export a Control Mapping to CSV
 * The button is hidden when there are no selected Controls
 *
 * @param {string[]} selectedIDs - IDs of selected Controls to generate a mapping for
 * @returns {JSX.Element}
 * @constructor
 */
const ControlMappingExportModalButton = ({ selectedIDs = [] }) => {
  const classes = useDataGridStyles();

  const disabled = !isNonEmptyArray(selectedIDs);

  const modal = useModal(
    `Export Compliance Control Mapping (${selectedIDs?.length} Controls selected)`,
    <ControlMappingExportBody selectedIDs={selectedIDs} />,
    <Button
      className={classes.toolbar}
      style={{ fontSize: "0.8125rem", display: disabled ? "none" : undefined }}
      title={disabled ? "Select one or more Controls to export Mappings" : "Export Compliance Control Mapping"}
      startIcon={<FileDownload />}
      disabled={disabled}
    >
      Mapping Export
    </Button>,
    {
      width: "95vw",
    },
  );

  return modal.modalButton;
};

export default ControlMappingExportModalButton;
