import { InfoLogger } from "@utils/EventLogger";
import { checkKeywords } from "./checkKeywords";

/**
 * Auto-detects and applies CSV header to Resource field mapping
 * Uses a 'detection' function that returns true or false depending on the header name
 *
 * @param {ImporterField[]} fields - fields that are available for the importer
 * @param {string[]} currentHeaders - the current CSV headers from the input file
 * @param {object} columnMap - mapping between CSV Headers and Available Fields
 * @param {function} setColumnMapping - state setter function for column mapping
 * @param {function} resetColumnMapping - reset the current column mapping to have no columns link to fields
 */
export const autoDetectColumns = (fields, currentHeaders, columnMap, setColumnMapping, resetColumnMapping) => {
  InfoLogger("Auto-Detecting Columns..");

  // Remove any existing mapping
  resetColumnMapping?.();

  // Find a new mapping from the available fields to the current headers
  if (Array.isArray(fields)) {
    for (const field of fields) {
      for (const header of currentHeaders) {
        const keywords = [field.name];

        if (Array.isArray(field.keywords)) {
          keywords.push(...field.keywords);
        }

        if (checkKeywords(header, columnMap, [...keywords])) {
          setColumnMapping(field.name, header);
        }
      }
    }
  }
};
