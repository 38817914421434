import { createCustomFieldColumns } from "../../createCustomFieldColumns";
import { isNonEmptyArray } from "@rivial-security/func-utils";
import { EnhancedCustomField } from "../../../../../../typedefs/Utility/customFields";
import { DataGridField } from "../../../types";

export interface ProcessCustomFieldsInput {
  allFields: DataGridField[];
  customFields: EnhancedCustomField[];
  customFieldsReadOnly: boolean;
  module: string;
  resource: string;
}

export const processCustomFields = ({
  allFields,
  customFields,
  customFieldsReadOnly,
  module,
  resource,
}: ProcessCustomFieldsInput): DataGridField[] => {
  // If 'customFields' have been passed in, add these for processing
  if (!isNonEmptyArray(customFields)) {
    return allFields;
  }

  const customFieldConfigs = createCustomFieldColumns({
    customFields,
    customFieldsReadOnly,
    module,
    resource,
  });

  // Built-in fields take priority and duplicate fields are not allowed
  for (const customFieldConfig of customFieldConfigs) {
    const foundField = allFields.find(
      (field) => field?.name === customFieldConfig?.name || field?.field === customFieldConfig?.name,
    );
    if (!foundField) {
      allFields.push(customFieldConfig);
    }
  }

  return allFields;
};
