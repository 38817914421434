import React from "react";
import PropTypes from "prop-types";
import { formattedPercent, numberWithCommas } from "@rivial-security/func-utils";

/**
 * Author: Anatoli Railean
 * Created: 08/22/2019
 * Last Edit:
 *
 * Description: This component Displays a Risk or System of an Organization
 *
 * TODO:
 *
 */
const RiskCalculatorROIResults = (props) => {
  return (
    <>
      {props.item ? (
        <>
          {props.type === "snapshot" && "Snapshot Results"}
          {props.item.name ? <p>Key Risk Indicator: {props.item.name}</p> : "No Name"}
          {props.item.inherentRisk ? (
            <p>Inherent Loss: $ {numberWithCommas(props.item.inherentRisk.toFixed(0))}</p>
          ) : null}
          {props.item.residualRisk ? (
            <p>Residual Risk: $ {numberWithCommas(props.item.residualRisk.toFixed(0))}</p>
          ) : null}
          {
            <p>
              Risk Mitigated: ${numberWithCommas((props.item.inherentRisk - props.item.residualRisk).toFixed(0))}
              {((props.item.inherentRisk - props.item.residualRisk) / props.item.inherentRisk) * 100 > 0 ? (
                <span style={{ color: "green" }}>
                  {` [ -${(
                    ((props.item.inherentRisk - props.item.residualRisk) / props.item.inherentRisk) *
                    100
                  ).toFixed(0)}% ]`}
                </span>
              ) : (
                <span style={{ color: "red" }}>
                  {` [ +${(
                    ((props.item.inherentRisk - props.item.residualRisk) / props.item.inherentRisk) *
                    100
                  ).toFixed(0)}% ]`}
                </span>
              )}
            </p>
          }
          {<p>Cost of Controls: ${numberWithCommas(props.item.currentCostOfControls)}</p>}
          {<p>Control Effectiveness: {formattedPercent(props.item.currentControlEffectiveness)}</p>}
          {props.item.currentROI ? (
            <p>
              Return on Investment:{" "}
              {props.item.currentROI * 100 > 0 ? (
                <span style={{ color: "green" }}>
                  {`$${numberWithCommas(props.item.currentNetProfit.toFixed(2))}`} [ +
                  {(props.item.currentROI * 100).toFixed(2)}% ]
                </span>
              ) : (
                <span style={{ color: "red" }}>
                  {`-$${numberWithCommas(props.item.currentNetProfit.toFixed(2) * -1)}`} [{" "}
                  {(props.item.currentROI * 100).toFixed(2)}% ]
                </span>
              )}
            </p>
          ) : null}
        </>
      ) : null}
    </>
  );
};

RiskCalculatorROIResults.propTypes = {
  title: PropTypes.string,
  item: PropTypes.object,
};

RiskCalculatorROIResults.defaultProps = {
  item: {},
};

export default RiskCalculatorROIResults;
