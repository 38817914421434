import { ChangeEvent, Dispatch, ReactElement, SetStateAction } from "react";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

export interface CustomCheckboxesProps {
  onChange: (selectedValues: string[]) => void;
  value: string[];
  setValue: Dispatch<SetStateAction<string[]>>;
  inputConfig: {
    data: {
      key: string;
      label: string;
    }[];
  };
}

const CustomCheckboxes = ({
  inputConfig = { data: [] },
  onChange,
  value,
  setValue,
}: CustomCheckboxesProps): ReactElement => {
  const { data } = inputConfig;

  const handleChange =
    (option: string) =>
    (event: ChangeEvent<HTMLInputElement>): void => {
      const isChecked = event.target.checked;
      const currentValue = value || [];

      const valueWhenChecked = [...currentValue, option];
      const valueWhenUnchecked = currentValue.filter((v) => v !== option);
      const newValue = isChecked ? valueWhenChecked : valueWhenUnchecked;

      setValue?.(newValue);
      onChange?.(newValue);
    };

  return (
    <FormGroup>
      {data?.map((option) => {
        const checked = value?.includes(option.key);
        return (
          <FormControlLabel
            key={`${option.key}_${checked}`}
            control={<Checkbox checked={checked} onChange={handleChange(option.key)} />}
            label={option.label}
          />
        );
      })}
    </FormGroup>
  );
};

export default CustomCheckboxes;
