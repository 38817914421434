import { listDataGridViews } from "./listDataGridViews";
import { getDefaultDataGridViewsData } from "./getDefaultDataGridViewsData";

/**
 * Deletes a data grid view from preferences
 * @param {string} gridID - the grid under which the view to delete  can be found
 * @param {string} viewID - the view to delete
 * @param {object} preferencesHook - instance of the usePreferences hook
 * @return {Promise<void>}
 */
export const deleteDataGridView = async ({ gridID, viewID, preferencesHook }) => {
  //Check arguments
  const { setPreference, getNetworkPreference } = preferencesHook || {};
  if (!gridID || typeof setPreference !== "function" || typeof getNetworkPreference !== "function") {
    throw new Error("deleteDataGridView: invalid arguments", {
      gridID,
      viewID,
      setPreference,
      getNetworkPreference,
    });
  }

  //List the views from preferences and remove the view if found
  const views = await listDataGridViews({ gridID, preferencesHook });
  const foundIndex = views.findIndex((view) => view.id === viewID);
  if (foundIndex >= 0) {
    views.splice(foundIndex, 1);
  }

  //Create the data grid views data object
  let dataGridViewsData = await getNetworkPreference("dataGridViews", gridID);
  if (!dataGridViewsData) {
    dataGridViewsData = getDefaultDataGridViewsData();
  } else {
    dataGridViewsData.views = views;
  }

  //Save the view object to preferences
  setPreference("dataGridViews", { [gridID]: dataGridViewsData });
};
