import React from "react";
import {
  getRiskChange_riskChangeDetails,
  updateRiskChange_riskChangeDetails,
} from "../../../graphql/getRiskChange_riskChangeDetails";
import SystemsAffectedList from "../../../customFields/SystemAffectedList";
import RiskControlAffectedList from "../../../customFields/RiskControlAffectedList";
import OverallROIChange from "../../../customFields/OverallROIChange";
import OverallResidualChange from "../../../customFields/OverallResidualChange";
import ChangeType from "../../../customFields/ChangeType";
import { useDetailsCard } from "../../../../../../hooks/views/useDetailsCardV2";
import { useAccordion } from "../../../../../../hooks/views/useAccordion/useAccordion";
import Grid from "@mui/material/Grid";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import RiskControlEvidence from "../../../../RiskConfig/ControlCategories/components/SubControls/components/RiskControlEvidence";
import { GENERIC_FIELD_TYPES } from "../../../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import { OverallEffectOfChangeCard } from "../../../customFields/OverallEffectOfChange";
import RiskChangeTable from "../../../../Recommendations/components/RiskChangeTable/RiskChangeTable";

/**
 * Displays details for a specific Risk Control change
 * @param itemId
 * @param queryConfig
 * @param detailsConfig
 * @param module
 * @param resource
 * @param disableRoleChecking
 * @param tableDisplay
 */
export const useRiskControlChangeDetails = ({
  itemId,
  queryConfig,
  detailsConfig,
  module,
  resource,
  disableRoleChecking,
  tableDisplay,
}) => {
  const queryConfigInitial = {
    query: getRiskChange_riskChangeDetails,
    itemId,
  };

  const fields = [
    "type",
    "name",
    "description",
    "date",
    "userEmail",
    "changeOwner",
    "reason",
    "overallROIChange",
    "overallResidualChange",
  ];

  const fieldNameDictionary = {
    type: "Type of Change",
    name: "Name of Change",
    description: "Description of Change",
    date: "Date of Change",
    userEmail: "Person who Entered Change",
    changeOwner: "Person responsible for Change",
    reason: "Reason for Change",
    systemLinks: "Systems affected by Change",
    riskControlLinks: "Risk Controls affected by Change",
    overallResidualChange: "Change in Residual Risk",
    overallROIChange: "Return on Investment",
  };

  const customFields = [
    {
      field: "systemLinks",
      component: <SystemsAffectedList />,
    },
    {
      field: "riskControlLinks",
      component: <RiskControlAffectedList />,
    },
    {
      field: "overallROIChange",
      component: <OverallROIChange />,
    },
    {
      field: "overallResidualChange",
      component: <OverallResidualChange />,
    },
    {
      field: "type",
      component: <ChangeType />,
    },
    {
      field: "date",
      inputType: GENERIC_FIELD_TYPES.DATE,
    },
  ];

  const detailsConfigInitial = {
    fields,
    customFields,
    fieldNameDictionary,
  };

  const detailsCard = useDetailsCard({
    queryConfig: { ...queryConfigInitial, ...queryConfig },
    detailsConfig: { ...detailsConfigInitial, ...detailsConfig },
    config: {
      header: "Risk Change Details",
    },
    disableRoleChecking: true,
    tableDisplay,
    updateMutation: updateRiskChange_riskChangeDetails,
  });

  const parseJson = (change) => {
    if (!isNullOrUndefined(change)) {
      return JSON.parse(change);
    } else {
      return {};
    }
  };

  const getAssociatedRiskControl = (item) => {
    if (!isNullOrUndefined(item)) {
      const change = parseJson(item.change);
      const associatedControl = change.associatedControl;
      return associatedControl;
    } else {
      return {};
    }
  };

  const accordion = useAccordion({
    item: detailsCard.item,
    items: [
      {
        title: "General Information",
        subTitle: "Details related to this Risk Change",
        icon: "icon-list",
        component: detailsCard.tableDisplay,
        defaultExpanded: true,
      },
      {
        title: "Affected Controls",
        subTitle: "Risk Controls impacted by this Risk Change",
        icon: "icon-shield",
        component: <RiskControlAffectedList item={detailsCard?.item} />,
      },
      {
        title: "Affected Systems",
        subTitle: "Information Systems impacted by this Risk Change",
        icon: "icon-screen-desktop",
        component: <RiskChangeTable systemChanges={parseJson(detailsCard?.item?.change).systemChanges} />,
      },
      {
        title: "Affected Evidence",
        subTitle: "Evidences involved with triggering this Risk Change",
        icon: "icon-badge",
        component: <RiskControlEvidence item={getAssociatedRiskControl(detailsCard?.item)} />,
      },
    ],
  });

  const display = (
    <Grid container spacing={2}>
      <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
        {accordion.display}
      </Grid>
      <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
        <OverallEffectOfChangeCard item={detailsCard?.item} />
      </Grid>
    </Grid>
  );

  return {
    ...detailsCard,
    display,
  };
};
