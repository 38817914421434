import React, { ReactElement } from "react";
import { convertCamelCaseToSentence, isNullOrUndefined } from "@rivial-security/func-utils";

import RequestPermissionButton from "../Permissions/components/RequestPermissionButton";
import Tooltip from "@mui/material/Tooltip";
import { roleConfigDefault } from "@rivial-security/role-utils";
import { useCheckPermissions } from "../../hooks/permissions/useCheckPermissions/useCheckPermissions";

export interface PermissionsOverlayProps {
  module?: string;
  resource?: string;
  field?: string;
  operationType: string;
  disableRoleChecking?: boolean;
  children: JSX.Element;
  toggleModal?: () => void;
  isTooltipWarning?: boolean;
  tooltipText?: string;
}

/**
 * Does a permission check for module, resource, and or field.
 * Displays a default text indicating that the user doesn't have the proper permissions,
 * or passes through the children component
 *
 * @example
 *
 * const Foo = (props) => {
 *   return (
 *     <PermissionsOverlay module="compliance" resource="foobar" >
 *       <div> Cool Stuff! </div>
 *     </PermissionsOverlay>
 *   )
 * }
 */
const PermissionsOverlay = ({
  module,
  resource,
  field,
  operationType,
  disableRoleChecking,
  children,
  toggleModal,
  isTooltipWarning = false,
  tooltipText,
}: PermissionsOverlayProps): JSX.Element => {
  const checkPermissions = useCheckPermissions({
    module,
    resource,
    field,
    disableRoleChecking,
  });

  return (
    <>
      {((): ReactElement => {
        let resourceReadPermissionGranted = checkPermissions?.resource?.read;
        //Check if read permission is hidden in default config (always granted)
        if (!isNullOrUndefined(resource)) {
          const defaultResourceConfigOperations = roleConfigDefault?.resources?.[resource ?? ""]?.operations;
          if (Array.isArray(defaultResourceConfigOperations) && !defaultResourceConfigOperations.includes("read")) {
            resourceReadPermissionGranted = true;
          }
        }

        //Checks if resource read permission are allowed, read permission check overridden by passed in operation type
        let requestPermissionComponent: JSX.Element | null = null;
        if (!isNullOrUndefined(resource) && !resourceReadPermissionGranted) {
          requestPermissionComponent = <NoResource module={module} resource={resource} />;
        } else if (!isNullOrUndefined(module) && !checkPermissions.module?.isEnabled) {
          requestPermissionComponent = <NoModule module={module} />;
        } else if (
          !isNullOrUndefined(module) &&
          !isNullOrUndefined(resource) &&
          !isNullOrUndefined(operationType) &&
          !checkPermissions?.resource?.[operationType]
        ) {
          requestPermissionComponent = (
            <NoOperationType resource={resource} module={module} field={field} operationType={operationType} />
          );
        }

        if (requestPermissionComponent) {
          if (isTooltipWarning) {
            return (
              <Tooltip title={tooltipText ?? noPermissionMessage({ resource, operationType })}>
                <div>
                  <span
                    style={{
                      height: "100%",
                      filter: "grayscale(100%)",
                      pointerEvents: "none",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {React.cloneElement(children, { toggleModal })}
                  </span>
                </div>
              </Tooltip>
            );
          } else {
            return requestPermissionComponent;
          }
        } else {
          return React.cloneElement(children, { toggleModal });
        }
      })()}
    </>
  );
};

interface NoPermissionMessage {
  resource?: string;
  operationType?: string;
}

/**
 * Gets the user facing message to display when the user doesn't have permission to perform an operation
 * @param {string} resource
 * @param {string} operationType
 * @return {string} - the no permission message formatted for the resource and operation type
 */
const noPermissionMessage = ({ resource, operationType }: NoPermissionMessage): string => {
  return `Sorry, you do not have permission to ${operationType ?? "access"} ${resource ? "the" : "this"} ${
    resource ? convertCamelCaseToSentence(resource) : ""
  } resource`;
};

interface No {
  module?: string;
  resource?: string;
}

interface NoOperation extends No {
  field?: string;
  operationType: string;
}

/**
 * Displays text indicating to the user that they don't have resource access for certain operation type
 */
const NoOperationType = ({ module, resource, field, operationType }: NoOperation): ReactElement => {
  return (
    <div className="e-card">
      <div className="e-card-header">
        <div className="e-card-header-caption">
          <div className="e-card-title"></div>
        </div>
      </div>
      <div
        className="e-card-content"
        style={{ justifyContent: "center" }}
        data-testid={`no-permission-${field ?? "field"}`}
      >
        <h5>
          {noPermissionMessage({ resource, operationType })}
          <RequestPermissionButton module={module} resource={resource} />
        </h5>
      </div>
    </div>
  );
};

/**
 * Displays text indicating to the user that they don't have resource: read permission
 */
const NoResource = ({ module, resource }: No): ReactElement => {
  return (
    <div className="e-card">
      <div className="e-card-header">
        <div className="e-card-header-caption">
          <div className="e-card-title"></div>
        </div>
      </div>
      <div
        className="e-card-content"
        style={{ justifyContent: "center" }}
        data-testid={`no-permission-${resource ?? "resource"}`}
      >
        <h5>
          Sorry, you do not have permission to view {resource ? "the" : "this"}{" "}
          {resource ? convertCamelCaseToSentence(resource) : ""} resource.
          <RequestPermissionButton module={module} resource={resource} />
        </h5>
      </div>
    </div>
  );
};

/**
 * Displays text indicating to the user that they don't have module: isEnabled permission
 */
const NoModule = ({ module }: No): ReactElement => {
  return (
    <div className="e-card">
      <div className="e-card-header">
        <div className="e-card-header-caption">
          <div className="e-card-title"></div>
        </div>
      </div>
      <div className="e-card-content" data-testid={`no-permission-${module ?? "module"}`}>
        <h5>
          Sorry, you do not have permission to view {module ? "the" : "this"}{" "}
          {module ? convertCamelCaseToSentence(module) : ""} module.
          <RequestPermissionButton module={module} style={{ flex: 1 }} />
        </h5>
      </div>
    </div>
  );
};

export default PermissionsOverlay;
