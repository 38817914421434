import { useThreatGrid } from "../hooks/useThreatGrid";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * @deprecated - use ThreatDataGrid instead
 * @param props
 * @returns {*}
 * @constructor
 */
const ThreatGrid = (props) => {
  return useThreatGrid(props).display;
};

export default withOrganizationCheck(ThreatGrid);
