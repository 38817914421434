import { GENERIC_FIELD_TYPES } from "../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import { generateGraphql } from "@rivial-security/generategraphql";
import React from "react";
import { modules, resources } from "@rivial-security/role-utils";
import GenericEditFieldV3 from "../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";
import { useAccessControl } from "../../../../utils/AccessControl/hooks/useAccessControl";

const IncidentWhen = ({
  item,
  module = modules.INCIDENT_RESPONSE,
  resource = resources.INCIDENT,
  field = "when",
  updateItemById,
}) => {
  const { accessDenied } = useAccessControl({ item });
  return (
    <div key={`incident-when-${item?.id}`}>
      <GenericEditFieldV3
        item={item}
        updateItemById={updateItemById}
        module={module}
        resource={resource}
        field={field}
        inputType={GENERIC_FIELD_TYPES.DATE}
        mutation={generateGraphql("Incident", ["when"]).updateMutation}
        disableEdits={accessDenied}
      />
    </div>
  );
};

export default IncidentWhen;
