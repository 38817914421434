import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useEmailTemplateGrid } from "../../EmailTemplates/hooks/useEmailTemplateGrid";
import React from "react";
import { updateObjectInArray } from "@rivial-security/func-utils";
import { useEmailAutomationTour } from "../tours/automationTours";
import { resources } from "@rivial-security/role-utils";
import { useForm } from "../../../../hooks/views/useForm";
import { useGUID } from "../../../../hooks/functional/useGUID";
import { SelectItemGrid } from "../../../../utils/GenericComponents/SelectItemGrid";
import { tryFunction } from "../../../../utils/Functions/tryFunction";

/**
 * UI for configuring an Email Notification Automation
 * @param {string} organizationID - the selected organization id
 * @param {function} toggleModal - function to close the edit/create modal
 * @param {object} automations - the current automation step info for the parent node
 * @param {function} setAutomations - function to update the automation with new data
 * @param {object} fieldConfig - the initial field config passed through to the use form
 * @param {AutomationStep} automationStep - the automation step that is being edited
 * @param {object} formConfig - form configurations to merge into the main form
 * @returns {JSX.Element}
 */
const ConfigureNotificationAutomation = ({
  organizationID,
  toggleModal,
  automations,
  setAutomations,
  fieldConfig,
  automationStep,
  formConfig,
}) => {
  const submitFunction = async (input) => {
    const newAutomations = [...(automations || [])];

    const name = input?.name;
    const emailTemplate = input?.emailTemplate;

    updateObjectInArray(newAutomations, {
      id: automationStep.id,
      name,
      description: emailTemplate?.name,
      config: {
        ...automationStep.config,
        emailTemplateID: emailTemplate?.id,
        type: "email",
      },
    });
    setAutomations(newAutomations);
    tryFunction(toggleModal);
  };

  // Welcome Tour
  useEmailAutomationTour();

  fieldConfig = {
    ...fieldConfig,
    emailTemplate: {
      inputType: "item-select",
      label: "Select an Email Template",
      required: true,
      itemSelectConfig: {
        grid: (
          <SelectItemGrid
            gridHook={useEmailTemplateGrid}
            enableCreate={true}
            organizationID={organizationID}
            height={"100%"}
          />
        ),
        typename: resources.EMAIL,
        formatSelectedItem: (emailTemplate) => emailTemplate?.name,
        outputAsObject: true,
        modal: {
          header: "Select an Email Template",
        },
      },
    },
  };

  const [guid] = useGUID();
  const form = useForm({
    id: guid,
    item: automationStep,
    submitFunction,
    disableRoleChecking: true,
    disableResetButton: true,
    fieldConfig,
    ...formConfig,
  });

  return <div data-tourid={"configure-email-automation-container"}>{form.display}</div>;
};

export default withOrganizationCheck(ConfigureNotificationAutomation);
