import { useEffect, useState } from "react";
import ResultFieldPath from "./useResultFieldBuilder/components/ResultFieldPath";
import { ResourceFieldMultiSelect } from "../components/ResourceFieldMultiSelect";
import FinalizeFunctionForm from "./useResultFieldBuilder/components/FinalizeFunctionForm";
import React from "react";
import { getFieldsAtDepth } from "../functions/getFieldsAtDepth";
import ResourceFunctionsMultiSelect from "./useResultFieldBuilder/components/ResourceFunctionsMultiSelect";
import { getFunctionsAtDepth } from "../functions/getFunctionsAtDepth";
import { addLeafFieldsToResultFields } from "../functions/addLeafFieldsToResultFields";
import { addLeafFunctionsToResultFields } from "../functions/addLeafFunctionsToResultFields";
import { lastPathElementIsAFunction } from "../functions/lastPathElementIsAFunction";
import { getFunctionFieldName } from "@rivial-security/widget-utils";
import ResourceFilterForm from "./useResultFieldBuilder/components/ResourceFilterForm";
import { updateLeafResultFieldFilter } from "../functions/updateLeafResultFieldFilter";
import { deRootifyQueryObject } from "@rivial-security/widget-utils";
import { genericResources } from "../../../definitions/constants/genericResources";

/**
 * Responsible for building out the result field tree and also allowing the user
 * to configure all post query transformation functions
 * @param {object} rootResource - the root resource of the query, only the definition from schema.json is needed
 * @param {object} loadedQuery - the query object loaded from the database
 * @return {{display: JSX.Element, query: *, setPath: *}}
 */
export const useResultFieldBuilder = ({ rootResource, loadedQuery }) => {
  const [query, setQuery] = useState({});
  const [path, setPath] = useState([]);

  //When root resource changes, reset the result fields and path
  useEffect(() => {
    let typename = "";
    let fields = {};
    let functions = {};
    let filters = {};

    let deRootifiedQuery = {};
    if (loadedQuery?.id) {
      typename = loadedQuery?.typename;
      fields = loadedQuery?.queryConfig?.fields;
      functions = loadedQuery?.queryConfig?.functions;
      filters = loadedQuery?.queryConfig?.filters;

      deRootifiedQuery = deRootifyQueryObject({
        query: loadedQuery?.queryConfig,
      });
      if (!typename) {
        typename = deRootifiedQuery?.typename;
      }
    } else if (rootResource?.typename) {
      typename = rootResource?.typename;
    }

    setQuery({
      typename,
      fields,
      functions,
      filters,
    });
    setPath([
      {
        name: `${typename || "Resource"}`,
        typename,
        type: "OBJECT",
        isNested: true,
        isRoot: true,
        isModel: true,
        hasMany: true,
        hasIdField: true,
        hasOwnerGroupField: true,
        ...deRootifiedQuery,
      },
    ]);
  }, [rootResource, loadedQuery?.id]);

  /**
   * Returns the typename of at the current level of nesting, whether no fields
   * are selected or just some
   * @return {null|*}
   */
  const getCurrentTypename = () => {
    if (!Array.isArray(path)) {
      return null;
    } else if (path.length === 0) {
      return genericResources.OBJECT;
    } else {
      const lastResource = path[path.length - 1];
      return lastResource?.typename;
    }
  };

  const display = (
    <div>
      {/*Show current nesting represented by the path*/}
      <ResultFieldPath path={path} setPath={setPath} resultFields={query?.fields} resultFunctions={query?.functions} />
      {/*Select the next fields from the leaf resource in the path*/}
      {Array.isArray(path) && path.length > 0 && (
        <ResourceFieldMultiSelect
          dropdownContainerStyle={{ marginTop: "1em" }}
          typename={getCurrentTypename()}
          path={path}
          resultFields={query?.fields}
          selectedFields={getFieldsAtDepth({
            path,
            resultFields: query?.fields,
            depth: path.length,
          })}
          onSelectFields={(newLeafFields) => {
            const newResultFields = { ...(query?.fields || {}) };
            addLeafFieldsToResultFields({
              path,
              newResultFields,
              newLeafFields,
            });

            setQuery({ ...query, fields: newResultFields });
          }}
          onClickNestedField={(field) => {
            setPath([...path, field]);
          }}
        />
      )}
      {/*Available functions to apply on current nesting */}
      <ResourceFunctionsMultiSelect
        typename={getCurrentTypename()}
        path={path}
        containerStyle={{ marginTop: "1em" }}
        selectedFunctions={getFunctionsAtDepth({
          path,
          resultFields: query?.fields,
          resultFunctions: query?.functions,
          depth: path.length,
        })}
        onSelectFunctions={(newLeafFunctions) => {
          const newResultFields = { ...(query?.fields || {}) };
          const newResultFunctions = { ...(query?.functions || {}) };

          addLeafFunctionsToResultFields({
            path,
            newResultFields,
            newResultFunctions,
            newLeafFunctions,
          });

          setQuery({
            ...query,
            fields: newResultFields,
            functions: newResultFunctions,
          });
        }}
        onClickNestedFunction={(func) => {
          setPath([...path, func]);
        }}
      />
      {/* Function edit form */}
      {lastPathElementIsAFunction({ path }) && (
        <FinalizeFunctionForm
          path={path}
          resultFields={query?.fields}
          resultFunctions={query?.functions}
          functionBeingEdited={path[path.length - 1]}
          isNested={true}
          onFinishEdit={(input) => {
            const newFunctions =
              getFunctionsAtDepth({
                path,
                resultFields: query?.fields,
                resultFunctions: query?.functions,
                depth: path.length - 1,
              }) || [];

            const editedIndex = newFunctions.findIndex((func) => {
              const functionName = getFunctionFieldName({ pathElement: func });

              const lastPathElement = path[path.length - 1];
              const editedFunctionName = getFunctionFieldName({
                pathElement: lastPathElement,
              });

              return functionName === editedFunctionName;
            });

            if (editedIndex !== -1) {
              newFunctions[editedIndex].config = input;
              const newResultFields = { ...(query?.fields || {}) };
              const newResultFunctions = { ...(query?.functions || {}) };
              addLeafFunctionsToResultFields({
                path: path.slice(0, path.length - 1),
                newResultFields,
                newResultFunctions,
                newLeafFunctions: newFunctions,
              });
              setPath(path.slice(0, path.length - 1));
              setQuery({
                ...query,
                fields: newResultFields,
                functions: newResultFunctions,
              });
            }
          }}
        />
      )}
      {/* Filter form for creating rules which determine the subset of all matching items that are returned */}
      <ResourceFilterForm
        path={path}
        onFilterChange={(newFilter) => {
          if (path.length === 0) {
            const newQuery = { ...query, filters: newFilter };
            setQuery(newQuery);
          } else {
            const newResultFields = { ...(query?.fields || {}) };
            updateLeafResultFieldFilter({
              path,
              newResultFields,
              newFilter,
            });
            const newQuery = { ...query, fields: newResultFields };

            setQuery(newQuery);
          }
        }}
      />
    </div>
  );

  return { display, query, setPath };
};
