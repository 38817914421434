import { getMany } from "../../../../../../utils/Functions/getMany";
import { removeDuplicateObjects } from "@rivial-security/func-utils";

/**
 * Parses the list of Observations to get all associated recommendations and action items
 * @param obs
 * @returns {{actionItems: unknown[], observations: unknown[], recommendations: unknown[]}}
 */
export const handleObservationsData = (obs) => {
  const observations = [...obs];
  const recommendations = [];
  const actionItems = [];

  for (const observation of observations) {
    const manyRecommendations = getMany(observation, "recommendations", "recommendation");

    recommendations.push(...manyRecommendations);

    for (const recommendation of recommendations) {
      const manyActions = getMany(recommendation, "actionItems", "action");

      actionItems.push(...manyActions);
    }
  }

  return {
    observations: removeDuplicateObjects(observations),
    recommendations: removeDuplicateObjects(recommendations),
    actionItems: removeDuplicateObjects(actionItems),
  };
};
