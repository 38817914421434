import { modules, resources } from "@rivial-security/role-utils";

import PlaybookProcedureTabs from "../../../../Plan/Playbooks/components/PlaybookProcedureTabs";
import React from "react";
import { useCheckPermissions } from "../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";

/**
 * Displays procedures/responsibilities for a response team using the common playbook UI
 * @param {object} item - the response team object from the database containing procedure info
 * @param {function} resetFunction - method to call to re-query the data
 * @param {string} orientation - either `vertical` or `horizontal` layout
 * @param {boolean} [enableCheckbox=false] - if true will show checkboxes next to each step
 * @param {boolean} [disableEdits=false] - if true will hide all step edit options for a cleaner look
 * @param {boolean} [enableNotes=false] - adds a notes field at the bottom of the checklist if true
 */
const ResponseTeamProcedureTabs = ({
  item,
  resetFunction,
  orientation = "horizontal",
  enableCheckbox = false,
  disableEdits = false,
  enableNotes = false,
}) => {
  const module = modules.INCIDENT_RESPONSE;
  const resource = resources.RESPONSE_TEAM;
  const checkPermissionsHook = useCheckPermissions({ module, resource });

  return (
    <PlaybookProcedureTabs
      disableEdits={!checkPermissionsHook.resource.update}
      resetFunction={resetFunction}
      enableCheckbox={enableCheckbox}
      enableProcedureNotes={true}
      orientation={orientation}
      item={item}
      typename={resources.RESPONSE_TEAM}
      tabContentStyle={{ height: "30em" }}
    />
  );
};

export default ResponseTeamProcedureTabs;
