import { ErrorLogger } from "@utils/EventLogger";
import { ItemMutation } from "@rivial-security/appsync-utils";
import { PointOfContact } from "@rivial-security/schema-types";
import { generateGraphql } from "@rivial-security/generategraphql";
import { getQuestionnaireAssignedPointsOfContact } from "@views/OrganizationManager/Questionnaires/functions/getQuestionnaireAssignedPointsOfContact";

export interface AutoAssignQuestionnaireToSystemContactsParams {
  questionnaireID: string;
  systemAdmin?: Partial<PointOfContact> | null;
  systemOwner?: Partial<PointOfContact> | null;
  organizationID: string;
}

export const autoAssignQuestionnaireToSystemContacts = async ({
  questionnaireID,
  systemAdmin,
  systemOwner,
  organizationID,
}: AutoAssignQuestionnaireToSystemContactsParams): Promise<void> => {
  const isSystemContactsAvailable = !!systemAdmin || !!systemOwner;

  if (isSystemContactsAvailable && questionnaireID) {
    const { createMutation: createQuestionnaireAssignee } = generateGraphql("QuestionnaireAssignee", [
      "id",
      "ownerGroup",
      "questionnaireID",
      "pointOfContactID",
    ]);

    const existingAssignees = await getQuestionnaireAssignedPointsOfContact({ questionnaireID });
    const existingAssigneeIDs = existingAssignees.map((assignee) => assignee.pointOfContactID);

    const assignPromises: Promise<void>[] = [];
    const isOwnerAndAdminSame = systemAdmin?.id === systemOwner?.id;

    if (systemOwner?.id && !existingAssigneeIDs.includes(systemOwner.id)) {
      assignPromises.push(
        ItemMutation({
          mutation: createQuestionnaireAssignee,
          input: {
            questionnaireID,
            pointOfContactID: systemOwner.id,
            ownerGroup: organizationID,
          },
        }).then(() => {
          ErrorLogger(`Questionnaire assigned to system owner: ${systemOwner.firstName} ${systemOwner.lastName}`);
        }),
      );
    }

    if (systemAdmin?.id && !isOwnerAndAdminSame && !existingAssigneeIDs.includes(systemAdmin.id)) {
      assignPromises.push(
        ItemMutation({
          mutation: createQuestionnaireAssignee,
          input: {
            questionnaireID,
            pointOfContactID: systemAdmin.id,
            ownerGroup: organizationID,
          },
        }).then(() => {
          ErrorLogger(`Questionnaire assigned to system admin: ${systemAdmin.firstName} ${systemAdmin.lastName}`);
        }),
      );
    }

    try {
      await Promise.allSettled(assignPromises);
    } catch (error) {
      ErrorLogger(`Failed to assign questionnaire to system contacts:`, error);
      throw error;
    }
  } else {
    ErrorLogger("No system contacts available or questionnaireID is missing. Skipping assignment.");
  }
};
