import { modules, resources } from "@rivial-security/role-utils";

import PointOfContactField from "@utils/CustomFields/PointOfContactField/PointOfContactField";
import { PointOfContactFieldProps } from "@views/Testing/types";
import { ReactElement } from "react";

const TargetPointOfContact = ({
  item,
  module: initModule,
  resource: initResource,
}: PointOfContactFieldProps): ReactElement => {
  const module = initModule || modules.VULNERABILITIES;
  const resource = initResource || resources.TARGET;
  const typename = resources.TARGET;

  return (
    <PointOfContactField
      item={item}
      module={module}
      resource={resource}
      typename={typename}
      field={"pointOfContact"}
      idField={"pointOfContactID"}
    />
  );
};

export default TargetPointOfContact;
