import React, { useEffect } from "react";
import { useTable } from "../../../../../hooks/views/useTable";

/**
 * A component that shows the compliance history of a particular Control
 * @param props
 * @returns {*}
 * @constructor
 */
const ComplianceHistory = (props) => {
  const fieldNameDictionary = {
    userEmail: "User",
    "change.text": "Change",
  };

  const tableHook = useTable({
    data: [],
    fields: ["change.text", "userEmail", "date"],
    rowComponent: <ComplianceHistoryRow />,
    fieldNameDictionary: fieldNameDictionary,
  });

  useEffect(() => {
    const controlChanges =
      props.control && props.control.changes && props.control.changes.items ? props.control.changes.items : [];

    tableHook.setData(controlChanges);
    tableHook.setSortField("date", "asc");
  }, [props.control && props.control.changes && props.control.changes.items]);

  return (
    <>
      {props.control &&
      props.control.changes &&
      props.control.changes.items &&
      Array.isArray(props.control.changes.items) &&
      props.control.changes.items.length > 0
        ? tableHook.display
        : "No Changes"}
    </>
  );
};

export default ComplianceHistory;

const ComplianceHistoryRow = (props) => {
  const fields = props.fields;
  const item = props.item;

  return (
    <tr>
      {fields.map((field, index) => (
        <td key={index}>
          {(() => {
            switch (field) {
              case "change.text":
                return JSON.parse(item.change).text;
              case "date":
                return new Date(item.date).toLocaleString();
              default:
                return item[field];
            }
          })()}
        </td>
      ))}
    </tr>
  );
};
