import { useCsvImporter } from "@hooks/views/useCsvImporter";
import { ReactElement, useEffect, useState } from "react";
import { useForm } from "@hooks/views/useForm";
import { capitalizeFirstLetter } from "@rivial-security/func-utils";
import { Scanner, scannerFields } from "@rivial-security/integration-utils";
import { ImporterStep } from "@hooks/views/useCsvImporter/hooks/useCsvImporter";

export interface UseVulnerabilitiesImporterParams {
  organizationID: string;
  files: File[];
  scanner?: string;
}

export interface UseVulnerabilitiesImporterResult {
  display: ReactElement;
  files: File[];
  scanner: string;
  columnMap: Record<string, string>;
}

export const useVulnerabilitiesImporter = ({
  organizationID,
  files,
  scanner: scannerInit,
}: UseVulnerabilitiesImporterParams): UseVulnerabilitiesImporterResult => {
  const form = useForm({
    fieldConfig: {
      scanner: {
        label: "Scanner Name",
        tooltip: "Select a scanner name",
        inputType: "dropdown",
        defaultValue: scannerInit ?? Scanner.NESSUS,
        required: true,
        dropdownConfig: {
          data: Object.values(Scanner).map((value) => {
            return {
              value: value,
              text: capitalizeFirstLetter(value),
            };
          }),
        },
      },
    },
    disableSubmitButton: true,
    disableResetButton: true,
    organizationID: organizationID,
  });

  const [fields, setFields] = useState([]);
  const csvImporter = useCsvImporter({
    organizationID,
    fields,
    allowMultiSelect: true,
    files,
    startSteps: [
      {
        id: "scanner_selection",
        text: "Select Scanner",
        component: form?.display,
      },
    ],
    hideSteps: [ImporterStep.FINISH_IMPORT],
    sizeLimitMessage:
      "This is a very large file, and may take some time to upload. " +
      "If you are experiencing issues with uploading, please try removing " +
      "all 'Informational' Vulnerabilities, and / or split the file " +
      "into multiple smaller chunks and upload one at a time.",
  });

  const formInput = form?.input as { scanner: string | undefined };
  useEffect(() => {
    let newFields = [];
    const selectedScanner = formInput?.scanner || scannerInit || Scanner.NESSUS;
    const selectedScannerFields = scannerFields[selectedScanner];
    if (selectedScannerFields) {
      newFields = selectedScannerFields.map((field) => {
        return {
          name: field.displayName,
          field: field.resourceFieldName,
          keywords: field.defaultCSVFieldNames,
          interpreterDescription: field.interpreterDescription,
        };
      });
    }
    setFields(newFields);
  }, [scannerInit, formInput?.scanner]);

  return {
    ...csvImporter,
    //@ts-expect-error: need to convert useForm to typescript for correct return typing
    scanner: form?.input?.scanner,
  };
};
