import { generateGraphql } from "@rivial-security/generategraphql";
import { deleteItem } from "../../../../utils/Functions/Graphql/deleteItem";

/**
 * Deletes a single Automation from the database
 * @param {Automation} item - automation to delete
 * @param {string} item.id - ID of automation to delete
 * @returns {Promise<null|*>}
 */
export const deleteAutomation = async (item) => {
  const { getQuery } = generateGraphql("Automation", ["__typename"]);

  return deleteItem(getQuery, item);
};
