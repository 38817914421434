import { useContext, useEffect } from "react";

import { ErrorLogger } from "@utils/EventLogger";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { ListQuery } from "../../../../utils/Functions/Graphql/ListQuery";
import { UIContext } from "../../../../utils/Context/UIContext";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useCsvImporter } from "../../../CsvImporters/hooks/useCsvImporter";
import { useMutation } from "../../../../hooks/graphql/useMutation/useMutation";

const { createMutation: createTarget } = generateGraphql("Target");

/**
 * Import Targets from CSV file
 * @param organizationID {string} currently selected organization id
 * @param toggleModal {function} close the modal
 * @param resetFunction {function} will be called on finish of CSV import
 * @returns {{setModalIsOpen: (function(*=): void), modalButton: *, modalIsOpen: boolean, setDataJSONReady: React.Dispatch<React.SetStateAction<unknown>>, dataJSONReady: unknown, display: *, reset: reset, modal: *}}
 */

export const useUploadTargets = ({ organizationID, toggleModal, resetFunction }) => {
  const { addToast, updateToast } = useContext(UIContext);

  const headersProps = ["ip", "macAddress", "hostName"];

  const targetFields = ["id", "ip", "hostName", "macAddress"];
  const { listQuery, updateMutation } = generateGraphql("Target", targetFields);
  const csvImporter = useCsvImporter({
    headersProps,
    title: "Import Targets",
    passThroughDuplicateScanner: true,
    getDataCallback: async (data) => {
      if (data) {
        await createItemsFromCsv(data);
      }
    },
  });

  useEffect(() => {
    csvImporter.initSourceQuery(listQuery, organizationID);
  }, []);

  const mutationHook = useMutation({
    disableRoleChecking: true,
    mutation: createTarget,
    typename: "Target",
    disableToast: true,
  });

  const getFieldValue = (object, field) => {
    return object && object[field] ? object[field] : undefined;
  };

  const createItemsFromCsv = async (operations) => {
    if (
      operations &&
      operations.add &&
      Array.isArray(operations.add) &&
      operations.update &&
      Array.isArray(operations.update) &&
      operations.delete &&
      Array.isArray(operations.delete)
    ) {
      //Update GUI
      toggleModal?.();
      const totalOperations = operations.add.length + operations.update.length + operations.delete.length;
      const toastId = addToast({
        icon: "spinner",
        header: `Updating Targets from CSV.. (0/${totalOperations})`,
      });

      const existingTargets = await ListQuery({
        query: listQuery,
        organizationID,
      });

      let curOperation = 0;

      //Perform all target updates
      for (const operationItem of operations.update) {
        //Check if item to mutate exists
        const itemFound = existingTargets.some(
          (originalItem) => originalItem.id != null && operationItem.id != null && operationItem.id === originalItem.id,
        );

        if (itemFound) {
          await ItemMutation(updateMutation, operationItem);
        }

        updateToast({
          id: toastId,
          icon: "spinner",
          header: `Uploading Targets from CSV.. (${++curOperation}/${totalOperations})`,
        });
      }

      //TODO: Correctly perform all target deletions (delete any links)

      //Perform all target additions
      for (const operationItem of operations.add) {
        if (operationItem.ip) {
          const targetFound = existingTargets.some(
            (target) => target.ip && operationItem.ip && target.ip === operationItem.ip,
          );

          if (!targetFound) {
            updateToast({
              id: toastId,
              icon: "spinner",
              header: `Uploading Targets from CSV.. (${++curOperation}/${totalOperations})`,
            });

            // set up input
            const input = {};
            input.ip = getFieldValue(operationItem, "ip");
            input.macAddress = getFieldValue(operationItem, "macAddress");
            input.hostName = getFieldValue(operationItem, "hostName");
            input.ownerGroup = organizationID;

            await mutationHook.createItem(input);
          }
        } else {
          ErrorLogger("Error: Target need an ip!");
        }
      }

      updateToast({
        id: toastId,
        icon: "success",
        header: `Success! Uploaded Targets`,
      });

      resetFunction?.();
    }
  };

  return {
    ...csvImporter,
  };
};
