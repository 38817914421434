import React, { useState } from "react";
import { convertCamelCaseToSentence, intersection } from "@rivial-security/func-utils";

import Button from "@mui/material/Button";
import CheckboxList from "./components/CheckboxList";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { handleCheckedLeft } from "./functions/handleCheckedLeft";
import { handleCheckedRight } from "./functions/handleCheckedRight";
import makeStyles from "@mui/styles/makeStyles";
import { useModal } from "../useModal";
import { useStateEffect } from "../../functional/useStateEffect";

/**
 * Allows the user to move items from one side to another.
 * Useful for one-many connection selections.
 * @param {Object[]} [rightItems = []] - initial array for the right side
 * @param {Object[]} [leftItems = []] - initial array for the left side
 * @param {function} [handleSubmit] - function handler for the 'Submit' button once items have been moved
 * @param {string} [rightTitle = "Right Items"] - text that goes at the top of the Right hand list
 * @param {string} [leftTitle = "Left Items"] - text that goes at the top of the Left hand list
 * @param {JSX.Element} [createResourceComponent] - optional component for creating a new resource on the fly
 * @param {function} [listTitleAdapter] - a function that updates the text in the list item
 */
export const useTransferList = ({
  rightItems: initialRight = [],
  leftItems: initialLeft = [],
  handleSubmit,
  leftTitle,
  rightTitle,
  createResourceComponent,
  listTitleAdapter,
  typename = "Resource",
}) => {
  const classes = useStyles();

  const [checkedItems, setCheckedItems] = React.useState([]);
  const [leftItems, setLeftItems] = React.useState(initialLeft || []);
  const [rightItems, setRightItems] = React.useState(initialRight || []);

  const [searchInput, setSearchInput] = useState("");

  const [isSubmitEnabled, setIsSubmitEnabled] = useStateEffect(false);

  const leftChecked = intersection(checkedItems, leftItems);
  const rightChecked = intersection(checkedItems, rightItems);

  const onTransferLeftClick = () => {
    handleCheckedLeft(leftItems, setLeftItems, rightItems, setRightItems, rightChecked, checkedItems, setCheckedItems);
    setIsSubmitEnabled(true);
  };

  const onTransferRightClick = () => {
    handleCheckedRight(rightItems, setRightItems, leftItems, setLeftItems, leftChecked, checkedItems, setCheckedItems);
    setIsSubmitEnabled(true);
  };

  const onSubmitClick = () => (async () => handleSubmit && (await handleSubmit()))();

  const createResourceModal = useModal(
    `Create a new ${typename}`,
    createResourceComponent || <div>Sorry, nothing to see here</div>,
    <Button
      variant="outlined"
      size="small"
      className={classes.button}
      aria-label="create a new resource"
      title={`Create a new ${convertCamelCaseToSentence(typename)}`}
    >
      +
    </Button>,
    {
      width: "70vw",
    },
  );

  const display = (
    <span>
      <Grid container justifyContent="center" alignItems="center" style={{ marginBottom: "1em" }}>
        <Grid item lg={9} md={9} sm={12} xs={12} justifyContent="center" alignItems="center">
          <TextField
            className={classes.searchbar}
            id="outlined-search"
            label="Search"
            type="search"
            variant="outlined"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            size="small"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent="center" alignItems="top" className={classes.root}>
        <Grid item lg={5} md={5} sm={10} xs={10}>
          <CheckboxList
            title={leftTitle || "Left Items"}
            items={leftItems}
            classes={classes}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
            searchInput={searchInput}
            listTitleAdapter={listTitleAdapter}
          />
        </Grid>
        <Grid item lg={1} md={1} sm={2} xs={2}>
          <Grid container direction="column" alignItems="center">
            {createResourceComponent && createResourceModal.modalButton}
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={onTransferRightClick}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
              title={`Unlink Selected ${convertCamelCaseToSentence(typename)}(s)`}
            >
              &gt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={onTransferLeftClick}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
              title={`Link Selected ${convertCamelCaseToSentence(typename)}(s)`}
            >
              &lt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={onSubmitClick}
              aria-label="handle submit"
              style={{
                marginTop: "2em",
                display: !handleSubmit ? "none" : undefined,
              }}
              disabled={!isSubmitEnabled}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CheckboxList
            title={rightTitle || "Right Items"}
            items={rightItems}
            classes={classes}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
            searchInput={searchInput}
            listTitleAdapter={listTitleAdapter}
          />
        </Grid>
      </Grid>
    </span>
  );

  return {
    display,
    rightItems,
    setRightItems,
    leftItems,
    setLeftItems,
    checkedItems,
    setCheckedItems,
    createResourceModal,
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
    width: "100%",
  },
  searchbar: {
    width: "100%",
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: "100%",
    minHeight: 230,
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
    flex: 1,
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));
