import React, { useEffect, useState } from "react";
import OrganizationCheck from "../../../../../../../../../utils/Context/OrganizationCheck/OrganizationCheck";
import NonStandardInput from "./NonStandardInput";
import { formattedDollar } from "@rivial-security/func-utils";

const CostOfControlBody = ({ item, isAdmin, system, resetFunction, style, isNonStandard, override }) => {
  const [formattedAmount, setFormattedAmount] = useState(formattedDollar(item && item.costOfControl));

  useEffect(() => {
    setFormattedAmount(formattedDollar(item && item.costOfControl));
  }, [item]);

  return (
    <div style={style.div}>
      <strong>
        Cost of Control
        <i
          className="icon-question"
          title="Maximum dollar amount to implement this control per year, if 100% implemented. Can be overridden."
          style={style.icon}
        />
      </strong>
      <div style={style.field}>
        {isNonStandard ? (
          <NonStandardInput
            system={system}
            riskControl={item}
            field="costOfControl"
            resetFunction={resetFunction}
            override={override}
          />
        ) : (
          formattedAmount
        )}
      </div>
    </div>
  );
};

const MaximumCostOfControl = (props) => {
  return (
    <OrganizationCheck {...props}>
      <CostOfControlBody />
    </OrganizationCheck>
  );
};

export default MaximumCostOfControl;
