import { Button, Grid } from "@mui/material";
import Delete from "@mui/icons-material/Delete";
import DocumentScanner from "@mui/icons-material/DocumentScanner";
import Edit from "@mui/icons-material/Edit";
import SaveAs from "@mui/icons-material/SaveAs";
import React, { useContext, useState } from "react";
import { modules, resources } from "@rivial-security/role-utils";

import AddchartIcon from "@mui/icons-material/Addchart";
import CustomDashboardNameForm from "../components/CustomDashboardNameForm";
import Stack from "@mui/material/Stack";
import { UIContext } from "../../../utils/Context/UIContext";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { performToastOperation } from "../../../utils/Toasts/functions/toastOperation";
import { useCheckPermissions } from "../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useForm } from "../../../hooks/views/useForm";
import { useModal } from "../../../hooks/views/useModal";
import { useWidgetSelector } from "../../CustomWidgets/WidgetSelector/hooks/useWidgetSelector";

/**
 * Custom hook that handles the logic and UI for the Custom Dashboard toolbar buttons.
 *
 * This hook manages the button state and handler functions for operations such as:
 *
 * - Edit mode for current CustomDashboard
 * - Save current CustomDashboard (overwrites the current 'layouts' field)
 * - Save As current CustomDashboard as a new CustomDashboard (creates a new CustomDashboard object)
 * - Reset current CustomDashboard to the default layout
 *
 * @param {function} save - function that saves the current CustomDashboard object
 * @param {function} saveAs - function that saves the current CustomDashboard object as a new CustomDashboard object
 * @param {function} addPage - function that adds a new page to the current CustomDashboard object
 * @param {function} addWidget - function that adds a new widget to the current CustomDashboard layout object
 * @param {function} deleteDashboard - function that deletes the current CustomDashboard object
 * @param {string} organizationID - the organizationID of the current CustomDashboard object
 * @param {boolean} isEditMode - the current edit mode state of the CustomDashboard
 * @param {function} setIsEditMode - the setIsEditMode function from the useCustomDashboardEditMode hook
 * @param {object} item - the current CustomDashboard object
 * @param {function} editDashboardDetails - function that edits the details of a dashboard
 * @returns {{setShowResetButton: (value: (((prevState: boolean) => boolean) | boolean)) => void, showResetButton: boolean, display: JSX.Element, showEditButton: boolean, showAddPageButton: boolean, setShowEditButton: (value: (((prevState: boolean) => boolean) | boolean)) => void, showSaveAsButton: boolean, showSaveButton: boolean, setShowSaveAsButton: (value: (((prevState: boolean) => boolean) | boolean)) => void, setShowSaveButton: (value: (((prevState: boolean) => boolean) | boolean)) => void, setShowAddPageButton: (value: (((prevState: boolean) => boolean) | boolean)) => void}}
 */
export const useCustomDashboardButtons = ({
  saveAs,
  addPage,
  addWidget,
  deleteDashboard,
  organizationID,
  isEditMode,
  setIsEditMode,
  item,
  editDashboardDetails,
  isTemplate,
}) => {
  const [showSaveAsButton, setShowSaveAsButton] = useState(false);
  const [showEditButton, setShowEditButton] = useState(true);
  const [showAddPageButton, setShowAddPageButton] = useState(false);
  const [showAddWidgetButton, setShowAddWidgetButton] = useState(false);
  const [showCancelButton, setShowCancelButton] = useState(false);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [showEditNameButton, setShowEditNameButton] = useState(false);

  const { addToast, updateToast } = useContext(UIContext);

  const module = modules.ORGANIZATION_MANAGER;
  const resource = resources.CUSTOM_DASHBOARD;

  const checkPermissions = useCheckPermissions({
    module,
    resource,
  });

  const onSaveAsButtonClick = async () => {
    try {
      if (typeof saveAs === "function") {
        if (!window.confirm("Continue to save a copy of this Custom Dashboard?")) {
          return;
        } else {
          await performToastOperation({
            addToast,
            updateToast,
            operation: saveAs,
            inProgressText: "Copying Dashboard...",
            successText: "Dashboard Copied successfully",
            errorText: "Error copying dashboard",
          });
        }
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  const onDeleteButtonClick = async () => {
    try {
      if (typeof deleteDashboard === "function") {
        if (!window.confirm("Are you sure you want to delete this Custom Dashboard?")) {
          return;
        } else {
          await performToastOperation({
            addToast,
            updateToast,
            operation: deleteDashboard,
            inProgressText: "Deleting Dashboard...",
            successText: "Dashboard Deleted successfully",
            errorText: "Error deleting dashboard",
          });
        }
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  /**
   * Switch input for toggle edit mode on or off for a CustomDashboard
   * @type {{display: *, input: {}}}
   */
  const editModeForm = useForm({
    fieldConfig: {
      editMode: {
        inputType: "switch",
        // label: "Edit Mode",
        onChangeFunction: (input) => {
          if (input.editMode === true) {
            setShowSaveAsButton(true);
            setShowAddPageButton(true);
            setShowAddWidgetButton(true);
            setIsEditMode(true);
            setShowDeleteButton(true);
            setShowEditNameButton(true);
          } else {
            setShowSaveAsButton(false);
            setShowAddPageButton(false);
            setShowAddWidgetButton(false);
            setIsEditMode(false);
            setShowDeleteButton(false);
            setShowEditNameButton(false);
          }
        },
      },
    },
    disableResetButton: true,
    disableSubmitButton: true,
  });

  const onSelectWidget = async (item) => {
    await addWidget(item.id);
    addWidgetModal.setModalIsOpen(false);
  };

  const widgetSelector = useWidgetSelector({
    organizationID,
    onSelect: onSelectWidget,
    isTemplate,
  });

  const addWidgetModal = useModal(
    "Select a Widget to Add",
    <div>{widgetSelector.display}</div>,
    <Button variant="outlined" startIcon={<AddchartIcon />}>
      Add Widget
    </Button>,
    {
      width: "75vw",
    },
  );

  const addPageForm = useForm({
    fieldConfig: {
      name: {
        required: true,
        label: "Page Name",
        inputType: "text",
      },
    },
    disableResetButton: true,
    submitFunction: addPage,
  });

  const addPageModal = useModal(
    "Add a Page",
    <div>{addPageForm.display}</div>,
    <Button variant={"outlined"} startIcon={<DocumentScanner />}>
      Add Page
    </Button>,
  );

  const editDashboardNameModal = useModal(
    "Edit Dashboard Name",
    <CustomDashboardNameForm editDashboardDetails={editDashboardDetails} item={item} />,
    <Button variant={"outlined"} startIcon={<Edit />}>
      Edit Name
    </Button>,
  );

  const editButton = (
    <span
      style={{
        justifyContent: "center",
        marginLeft: "1em",
        paddingTop: "0.75em",
      }}
    >
      <Grid container spacing={1} style={{ width: "100px" }}>
        <Grid item lg={6} xs={6}>
          {editModeForm.display}
          {addWidgetModal.modal}
        </Grid>
        <Grid item lg={6} xs={6}>
          Edit
        </Grid>
      </Grid>
    </span>
  );

  const display = (
    <Stack direction="row" style={{ marginTop: "-1em" }} spacing={1}>
      {showAddWidgetButton && checkPermissions.resource.create && addWidgetModal.modalButton}

      {showAddPageButton && checkPermissions.resource.update && addPageModal.modalButton}

      {showSaveAsButton && checkPermissions.resource.create && (
        <Button
          variant="outlined"
          onClick={onSaveAsButtonClick}
          startIcon={<SaveAs />}
          hidden={isNullOrUndefined(item?.layouts)}
        >
          Save Copy
        </Button>
      )}

      {showEditNameButton && checkPermissions.resource.update && editDashboardNameModal.modalButton}

      {showDeleteButton && checkPermissions.resource.delete && (
        <Button
          variant="outlined"
          color={"error"}
          onClick={onDeleteButtonClick}
          startIcon={<Delete />}
          hidden={isNullOrUndefined(item)}
        >
          Delete Dashboard
        </Button>
      )}

      {showEditButton && (checkPermissions.resource.update || checkPermissions.resource.delete) && editButton}
    </Stack>
  );

  return {
    display,
    isEditMode,
    showEditButton,
    showSaveAsButton,
    showAddPageButton,
    showAddWidgetButton,
    showCancelButton,
    setShowCancelButton,
    setShowSaveAsButton,
    setShowEditButton,
    setShowAddPageButton,
    setShowAddWidgetButton,
    onSaveAsButtonClick,
    onDeleteButtonClick,
    addWidgetModal,
    editButton,
  };
};
