import React from "react";
import CreateSystemTemplate from "../components/CreateSystemTemplate";
import SystemTemplateDetails from "../components/SystemTemplateDetails";
import { modules, resources } from "@rivial-security/role-utils";
import { useSystemDataGrid } from "../../../Risk/Systems/hooks/useSystemDataGrid";

/**
 * Displays a list of Information System Templates
 *
 * @param organizationID
 * @param dataCardConfig
 * @param queryConfig
 * @param module
 * @param resource
 * @param disableRoleChecking
 * @returns {{setQueryFilters: function(*): void, tableDisplay: JSX.Element, data: Object[], setOtherFilters: function(*): void, clearFilters: function(): void, searchResults: Object[], setSelectionType: (value: (((prevState: *) => *) | *)) => void, setEnableQuery: (value: unknown) => void, selectionType: *, showSelectBoxes: unknown, enableQuery: unknown, setPaginatedData: function(*): void, resetFunction: function(*): void, setItems: function(*): void, options: string[], limit: unknown, sortFields: [], paginatedData: [], setSortField: function(*, *): void, setMiniLoading: (value: (((prevState: boolean) => boolean) | boolean)) => void, setData: function(*): void, display: JSX.Element, miniLoading: boolean, setFields: (value: unknown) => void, tableData: Object[], filters: [], loading: boolean, list: [], setList: (value: (((prevState: []) => []) | [])) => void, listPageArray: [], isLoading: boolean, setSelectedItems: (value: (((prevState: []) => []) | [])) => void, setShowSelectBoxes: (value: unknown) => void, setIsLoading: (value: (((prevState: boolean) => boolean) | boolean)) => void, setLoading: (value: (((prevState: boolean) => boolean) | boolean)) => void, setLimit: (value: unknown) => void, setFilters: (value: (((prevState: []) => []) | [])) => void, nextToken: unknown, addFilter: function(*): void, reset: function(*): void, setFullData: (value: (((prevState: []) => []) | [])) => void, fields: unknown, setOrganizationId: function(*): void, selectedItems: []}}
 */
export const useSystemTemplateDataGrid = ({
  organizationID,
  module = modules.ADMINISTRATOR,
  resource = resources.INFORMATION_SYSTEM,
  disableRoleChecking = false,
  queryConfig = {},
  gridConfig = {},
  cardConfig = {},
  additionalFields = [],
  ...props
}) => {
  const visibleFields = ["name", "description", "hosting"];
  if (Array.isArray(additionalFields)) {
    additionalFields.forEach((field) => {
      if (field?.name) {
        visibleFields.push(field.name);
      }
    });
  }

  const systemListHook = useSystemDataGrid({
    module,
    resource,
    disableRoleChecking,
    organizationID,
    otherFiltersInitial: {
      ownerGroup: {
        eq: organizationID,
      },
    },
    header: "System Templates",
    createResourceComponent: <CreateSystemTemplate organizationID={organizationID} />,
    detailsComponent: <SystemTemplateDetails organizationID={organizationID} />,
    detailsTitle: "Information System Template Details",
    createItemModalHeader: "Create an Information System Template",
    route: null,
    isTemplate: true,
    visibleFields,
    additionalFields,
    ...queryConfig,
    ...gridConfig,
    ...cardConfig,
    ...props,
  });

  return { ...systemListHook };
};
