import { ErrorLogger } from "@utils/EventLogger";
import { deepDelete } from "../../../../utils/Functions/deepDelete";

/**
 * @author: Anatoli Railean
 * @description: Deletes a new Target resource in the DB with all linked resources.
 * @param {object} item - The target to delete
 * @param {object} config - configuration object
 * @param {function} onJobStarted - callback function to get a job
 * @returns {Promise<void>}
 * @constructor
 */

export const deleteTarget = async (item, config = {}, onJobStarted) => {
  if (!item?.id) {
    ErrorLogger("[deleteTarget.js] Missing Target ID");
    return;
  }

  /**
   * Delete Target in Fargate
   */
  return await deepDelete({
    typename: "Target",
    item,
    config,
    onJobStarted,
  });
};
