import { useControlCategoriesGrid } from "../hooks/useControlCategoriesGrid";
import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";

/**
 * @deprecated - use ControlCategoriesDataGrid instead
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ControlCategoriesGrid = (props) => useControlCategoriesGrid(props).display;

export default withOrganizationCheck(ControlCategoriesGrid);
