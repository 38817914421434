import React from "react";
import { generateGraphql } from "@rivial-security/generategraphql";
import GenericFieldDropdown from "../../../../utils/GenericComponents/GenericFieldDropdown";
import { modules, resources } from "@rivial-security/role-utils";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Displays the isFinding field for an Observation.
 * Allows the user to adjust this field
 * @param {object} item - The Observation to display the isFinding field for.
 * @param {function} updateItemById - The function to update the Observation in the parent component.
 * @param {object} ...props - The props to pass to GenericFieldDropdown.
 * @returns {JSX.Element}
 * @constructor
 */
const IsFinding = ({ item, updateItemById, ...props }) => {
  const module = modules.GOVERNANCE;
  const resource = resources.OBSERVATION;
  const field = "isFinding";

  const options = [
    {
      text: "Finding",
      value: true,
    },
    {
      text: "Observation",
      value: false,
    },
  ];

  const customFormat = (bool) => {
    if (bool === true) {
      return "finding";
    } else if (bool === false) {
      return "observation";
    } else if (isNullOrUndefined(bool)) {
      return "observation";
    } else {
      return "unknown";
    }
  };

  return (
    <GenericFieldDropdown
      item={item}
      module={module}
      resource={resource}
      field={field}
      mutation={generateGraphql("Observation", [field]).updateMutation}
      options={options}
      format="sentence"
      customFormat={customFormat}
      updateItemById={updateItemById}
      {...props}
    />
  );
};

export default IsFinding;
