import CustomInput from "./CustomInput";
import CustomLabel from "./CustomLabel";
import { FormGroup } from "reactstrap";
import { fieldContexts } from "../../../../enums/fieldContexts";
import { getResourceFieldAttribute } from "../../../../definitions/functions/getResourceFieldAttribute";
import { handleLoader } from "../../../../utils/Functions/Views/handleLoader";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import parse from "html-react-parser";

/**
 * Collection of different types of input fields, represents a single step in useForm
 * @param {string} id - the step id for this group of input fields
 * @param {boolean} isShown - TRUE if this step's container needs to be displayed (not hidden with css)
 * @param {object} fieldConfig - all field settings for the inputs in this form
 * @param {string} typename - the main resource that is created from completing this form
 * @param {string[]} fields - list of fields that are shown, optional
 * @param {string} formID - the id uniquely identifying this form and all of its steps
 * @param {object} input - the current user's data in the form
 * @param {function} setInput - state update function for the users data in the form
 * @param {function} checkValid - function to check if a field input is valid based on its settings
 * @param {function} checkInvalid - function to check if a field input is invalid based on its settings
 * @param {boolean} showOverridesButton - if TRUE enables an alternative way to enter data as text into non text input types
 * @param {boolean} showIDField - if TRUE will show the built-in ID field
 * @param {boolean} disableItemSelect - only applicable if 'showIDField' is true, this allows the field to display but disables the item selection itself
 * @param {function} onIDFieldChange - called when the ID field is changed
 * @param {function} setInternalValidationResults - function to set the state of the internal validation results
 * @param {JSXElement} grid - the grid to be used with the ID field form to select an item to edit
 * @returns {JSX.Element}
 */
const FormStep = ({
  id,
  isShown = true,
  fieldConfig,
  typename,
  fields,
  formID,
  input,
  setInput,
  checkValid,
  checkInvalid,
  showOverridesButton,
  showIDField = false,
  disableItemSelect,
  onIDFieldChange,
  setInternalValidationResults,
  grid,
}) => {
  const checkIsFieldHidden = (isHidden) => {
    if (typeof isHidden === "function") {
      return isHidden({ input });
    } else return isHidden;
  };

  return (
    <div id={`form-step-${id}`} style={{ display: isShown ? "block" : "none" }}>
      {showIDField && (
        <FormGroup key={`custom_input${id}`}>
          <CustomLabel
            label={"ID"}
            required={false}
            tooltip={"The ID of the item being updated"}
            targetId={"ID"}
            fieldName={"id"}
          />
          {handleLoader({
            isLoading: false,
            component: (
              <CustomInput
                input={input}
                setInput={setInput}
                fieldName={"id"}
                inputType={showIDField && grid ? "item-select" : undefined}
                itemSelectConfig={
                  showIDField && grid && typename
                    ? {
                        typename,
                        grid,
                        callback: onIDFieldChange || undefined,
                      }
                    : undefined
                }
                isValid={checkValid("id")}
                isInvalid={checkInvalid("id")}
                label={"The ID of the item being updated"}
                tooltip={"The ID of the item being updated"}
                typename={typename}
                showOverridesButton={showOverridesButton}
                required={true}
                disabled={disableItemSelect}
              />
            ),
          })}
        </FormGroup>
      )}

      {Object.entries(fieldConfig).map(([fieldName, properties]) => {
        if (!isNullOrUndefined(fieldName) && !isNullOrUndefined(properties)) {
          const {
            tooltip: tooltipInit,
            tooltipPlacement,
            label,
            sublabel,
            isHidden = false,
            required = false,
            createItemComponent,
            isLoading,
          } = properties;

          const tooltip = getResourceFieldAttribute({
            override: tooltipInit,
            typename,
            fieldContext: fieldContexts.FORM,
            fieldName,
            attribute: "description",
          });

          // if there isn't an explicit "fields" param, show all fields.
          if (!checkIsFieldHidden(isHidden) && (!fields || (fields && fields.includes(fieldName)))) {
            return (
              <FormGroup key={`custom_input${fieldName}`}>
                <CustomLabel
                  label={label}
                  required={required}
                  tooltip={tooltip}
                  tooltipPlacement={tooltipPlacement}
                  targetId={fieldName + (formID ? `_${formID}` : "")}
                  fieldName={fieldName}
                  createItemComponent={createItemComponent}
                />

                {sublabel && <div style={{ color: "grey", overflowX: "auto" }}>{parse(sublabel)}</div>}
                {sublabel && <div style={{ height: ".5em" }} />}

                {handleLoader({
                  isLoading,
                  component: (
                    <CustomInput
                      {...properties}
                      input={input}
                      setInput={setInput}
                      fieldName={fieldName}
                      isValid={checkValid(fieldName)}
                      isInvalid={checkInvalid(fieldName)}
                      label={label}
                      tooltip={tooltip}
                      typename={typename}
                      showOverridesButton={showOverridesButton}
                      onInputIsValidChange={({ isValid }) => {
                        setInternalValidationResults((results) => {
                          return {
                            ...results,
                            [fieldName]: isValid,
                          };
                        });
                      }}
                    />
                  ),
                })}
              </FormGroup>
            );
          }
        }
      })}
    </div>
  );
};

export default FormStep;
