import CheckTool from "../../../Tool/components/CheckTool";
import { TOOL_TYPES } from "@rivial-security/integration-utils";
import { useKnowBe4PhishingDataGrid } from "../hooks/useKnowBe4PhishingDataGrid";
import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";

/**
 * @description Display list of KnowBe4 Phishing Tests component
 * @param {object} props
 * @constructor
 */
const KnowBe4PhishingDataGrid = (props) => {
  const toolType = TOOL_TYPES.KNOW_BE_4;
  return useKnowBe4PhishingDataGrid({ toolType, ...props }).dashboardDisplay;
};

const KnowBe4PhishingDataGridCheck = (props) => {
  const toolType = TOOL_TYPES.KNOW_BE_4;

  return (
    <CheckTool toolType={toolType} organizationID={props?.organizationID}>
      <KnowBe4PhishingDataGrid {...props} />
    </CheckTool>
  );
};

export default withOrganizationCheck(KnowBe4PhishingDataGridCheck);
