"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createRule = void 0;
const createLogic_1 = require("./createLogic");
const getMostRecentMetric_1 = require("./getMostRecentMetric");
const convertOperator_1 = require("./convertOperator");
const formatMetricData_1 = require("./formatMetricData");
/**
 *  Evaluates boolean logic according to jsonLogic rules.
 *  Adapts sync fusion operators into jsonLogic
 */
const createRule = (metricTypeLink) => {
    if (!metricTypeLink?.logic?.operator || !metricTypeLink?.logic?.data) {
        throw new Error(`Invalid logic object provided to createRule: ${JSON.stringify(metricTypeLink, null, 2)}`);
    }
    if (!metricTypeLink?.metricType) {
        throw new Error(`No metric type provided to createRule: ${JSON.stringify(metricTypeLink, null, 2)}`);
    }
    const mostRecentMetric = (0, getMostRecentMetric_1.getMostRecentMetric)(metricTypeLink.metricType);
    if (!mostRecentMetric?.data) {
        throw new Error(`No recent metrics exist: ${JSON.stringify(metricTypeLink.metricType, null, 2)}`);
    }
    const operator = (0, convertOperator_1.convertOperator)(metricTypeLink?.logic?.operator);
    const var1 = (0, formatMetricData_1.formatMetricData)(mostRecentMetric?.data);
    const var2 = (0, formatMetricData_1.formatMetricData)(metricTypeLink?.logic?.data);
    if (var1 === undefined || var1 === null) {
        throw new Error("[createRule] Invalid formatted metric data from most recent metric");
    }
    if (var2 === undefined || var2 === null) {
        throw new Error("[createRule] Invalid formatted metric data from logic data");
    }
    return (0, createLogic_1.createLogic)(operator, var1, var2);
};
exports.createRule = createRule;
