/**
 * Author: Jacob Blazina
 * Created At: 10/3/19
 * Edits:
 *  - 10/18/19 JB: passing through disableDuplicate props to SelectMaturityMatrix component
 *
 * Description: Performs the API Get request for a Program Blueprint.
 *              The "SelectMaturityMatrix" component performs the List Query and triggers
 *                a callback to notify this Component what Program Blueprint Object to display.
 *              This component performs all subscriptions based on the Get query.
 */

import { Button, ButtonGroup, Collapse, Nav, Navbar, NavbarToggler } from "reactstrap";
import React, { useEffect, useState } from "react";

import MaturityMatrixHorizontal from "../components/MaturityMatrix/deprecated/MaturityMatrixHorizontal";
import MaturityMatrixVertical from "../components/MaturityMatrix/deprecated/MaturityMatrixVertical";
import OrganizationCheck from "../../../../utils/Context/OrganizationCheck/OrganizationCheck";
import PropTypes from "prop-types";
import SelectMaturityMatrix from "../components/MaturityMatrix/deprecated/SelectMaturityMatrix";
import { buildSubscription } from "aws-appsync";
import gql from "graphql-tag";
import { graphql } from "react-apollo";

export const getMaturityMatrix = /* GraphQL */ `
  query GetMaturityMatrix($id: ID!) {
    getMaturityMatrix(id: $id) {
      id
      name
      description
      ownerGroup
      defaultMatrix
      programElements {
        items {
          id
          name
          description
          programSections {
            items {
              id
              name
              description
              implementationLevels {
                items {
                  id
                  description
                  date
                  level
                  indicator
                  frequency
                  accountable
                  consulted
                  informed
                  programSectionID
                  ownerGroup
                  createdAt
                  updatedAt
                }
                nextToken
              }
              currentLevel {
                id
                description
                date
                level
                indicator
                frequency
                accountable
                consulted
                informed
                programSection {
                  id
                  name
                  description
                  programElementID
                  ownerGroup
                  createdAt
                  updatedAt
                }
                programSectionID
                ownerGroup
                createdAt
                updatedAt
                pointOfContact {
                  id
                  firstName
                  lastName
                  title
                  email
                  phone
                  ownerGroup
                  createdAt
                  updatedAt
                }
              }
              goalLevel {
                id
                description
                date
                level
                indicator
                frequency
                accountable
                consulted
                informed
                programSection {
                  id
                  name
                  description
                  programElementID
                  ownerGroup
                  createdAt
                  updatedAt
                }
                programSectionID
                ownerGroup
                createdAt
                updatedAt
                pointOfContact {
                  id
                  firstName
                  lastName
                  title
                  email
                  phone
                  ownerGroup
                  createdAt
                  updatedAt
                }
              }
              programElement {
                id
                name
                description
                programSections {
                  nextToken
                }
                maturityMatrixID
                ownerGroup
                createdAt
                updatedAt
              }
              programElementID
              ownerGroup
              createdAt
              updatedAt
            }
            nextToken
          }
          maturityMatrixID
          ownerGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

const onDeleteMaturityColumn = /* GraphQL */ `
  subscription OnDeleteMaturityColumn {
    onDeleteMaturityColumn {
      id
      name
      ownerGroup
      notes {
        items {
          id
          type
          ownerGroup
          author
          timeStamp
          content
          itemId
          controlNotesId
          riskControlNotesId
          assessmentNotesId
          createdAt
          updatedAt
          observationID
        }
        nextToken
      }
      maturityMatrixLevels {
        items {
          id
          name
          ownerGroup
          maturityColumn {
            id
            name
            ownerGroup
            notes {
              items {
                id
                type
                ownerGroup
                author
                timeStamp
                content
                itemId
                controlNotesId
                riskControlNotesId
                assessmentNotesId
                createdAt
                updatedAt
                observationID
              }
              nextToken
            }
            maturityMatrixLevels {
              items {
                id
                name
                ownerGroup
                maturityColumn {
                  id
                  name
                  ownerGroup
                  maturityGoal
                  createdAt
                  updatedAt
                }
                maturityIndicators {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            maturityGoal
            createdAt
            updatedAt
          }
          maturityIndicators {
            items {
              id
              name
              isTrue
              ownerGroup
              maturityMatrixLevel {
                id
                name
                ownerGroup
                maturityColumn {
                  id
                  name
                  ownerGroup
                  maturityGoal
                  createdAt
                  updatedAt
                }
                maturityIndicators {
                  nextToken
                }
                createdAt
                updatedAt
              }
              actions {
                items {
                  id
                  name
                  dateCreated
                  dueDate
                  globalStatus
                  status
                  description
                  type
                  ownerGroup
                  priority
                  module
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      maturityGoal
      createdAt
      updatedAt
    }
  }
`;

const onDeleteMaturityIndicator = /* GraphQL */ `
  subscription OnDeleteMaturityIndicator {
    onDeleteMaturityIndicator {
      id
      name
      isTrue
      ownerGroup
      maturityMatrixLevel {
        id
        name
        ownerGroup
        maturityColumn {
          id
          name
          ownerGroup
          notes {
            items {
              id
              type
              ownerGroup
              author
              timeStamp
              content
              itemId
              controlNotesId
              riskControlNotesId
              assessmentNotesId
              createdAt
              updatedAt
              observationID
            }
            nextToken
          }
          maturityMatrixLevels {
            items {
              id
              name
              ownerGroup
              maturityColumn {
                id
                name
                ownerGroup
                notes {
                  nextToken
                  observationID
                }
                maturityMatrixLevels {
                  nextToken
                }
                maturityGoal
                createdAt
                updatedAt
              }
              maturityIndicators {
                items {
                  id
                  name
                  isTrue
                  ownerGroup
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          maturityGoal
          createdAt
          updatedAt
        }
        maturityIndicators {
          items {
            id
            name
            isTrue
            ownerGroup
            maturityMatrixLevel {
              id
              name
              ownerGroup
              maturityColumn {
                id
                name
                ownerGroup
                notes {
                  nextToken
                  observationID
                }
                maturityMatrixLevels {
                  nextToken
                }
                maturityGoal
                createdAt
                updatedAt
              }
              maturityIndicators {
                items {
                  id
                  name
                  isTrue
                  ownerGroup
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            actions {
              items {
                id
                name
                dateCreated
                dueDate
                globalStatus
                status
                description
                type
                ownerGroup
                priority
                maturityIndicator {
                  id
                  name
                  isTrue
                  ownerGroup
                  createdAt
                  updatedAt
                }
                module
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      actions {
        items {
          id
          name
          dateCreated
          dueDate
          globalStatus
          status
          description
          type
          ownerGroup
          priority
          maturityIndicator {
            id
            name
            isTrue
            ownerGroup
            maturityMatrixLevel {
              id
              name
              ownerGroup
              maturityColumn {
                id
                name
                ownerGroup
                notes {
                  nextToken
                  observationID
                }
                maturityMatrixLevels {
                  nextToken
                }
                maturityGoal
                createdAt
                updatedAt
              }
              maturityIndicators {
                items {
                  id
                  name
                  isTrue
                  ownerGroup
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            actions {
              items {
                id
                name
                dateCreated
                dueDate
                globalStatus
                status
                description
                type
                ownerGroup
                priority
                maturityIndicator {
                  id
                  name
                  isTrue
                  ownerGroup
                  createdAt
                  updatedAt
                }
                module
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          module
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

const onDeleteMaturityMatrix = /* GraphQL */ `
  subscription OnDeleteMaturityMatrix {
    onDeleteMaturityMatrix {
      id
      name
      description
      ownerGroup
      defaultMatrix
      programElements {
        items {
          id
          name
          description
          programSections {
            items {
              id
              name
              description
              implementationLevels {
                items {
                  id
                  description
                  date
                  level
                  indicator
                  frequency
                  accountable
                  consulted
                  informed
                  programSectionID
                  ownerGroup
                  createdAt
                  updatedAt
                }
                nextToken
              }
              currentLevel {
                id
                description
                date
                level
                indicator
                frequency
                accountable
                consulted
                informed
                programSection {
                  id
                  name
                  description
                  programElementID
                  ownerGroup
                  createdAt
                  updatedAt
                }
                programSectionID
                ownerGroup
                createdAt
                updatedAt
                pointOfContact {
                  id
                  firstName
                  lastName
                  title
                  email
                  phone
                  ownerGroup
                  createdAt
                  updatedAt
                }
              }
              goalLevel {
                id
                description
                date
                level
                indicator
                frequency
                accountable
                consulted
                informed
                programSection {
                  id
                  name
                  description
                  programElementID
                  ownerGroup
                  createdAt
                  updatedAt
                }
                programSectionID
                ownerGroup
                createdAt
                updatedAt
                pointOfContact {
                  id
                  firstName
                  lastName
                  title
                  email
                  phone
                  ownerGroup
                  createdAt
                  updatedAt
                }
              }
              programElement {
                id
                name
                description
                programSections {
                  nextToken
                }
                maturityMatrixID
                ownerGroup
                createdAt
                updatedAt
              }
              programElementID
              ownerGroup
              createdAt
              updatedAt
            }
            nextToken
          }
          maturityMatrixID
          ownerGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

const onDeleteMaturityMatrixLevel = /* GraphQL */ `
  subscription OnDeleteMaturityMatrixLevel {
    onDeleteMaturityMatrixLevel {
      id
      name
      ownerGroup
      maturityColumn {
        id
        name
        ownerGroup
        notes {
          items {
            id
            type
            ownerGroup
            author
            timeStamp
            content
            itemId
            controlNotesId
            riskControlNotesId
            assessmentNotesId
            createdAt
            updatedAt
            observationID
          }
          nextToken
        }
        maturityMatrixLevels {
          items {
            id
            name
            ownerGroup
            maturityColumn {
              id
              name
              ownerGroup
              notes {
                items {
                  id
                  type
                  ownerGroup
                  author
                  timeStamp
                  content
                  itemId
                  controlNotesId
                  riskControlNotesId
                  assessmentNotesId
                  createdAt
                  updatedAt
                  observationID
                }
                nextToken
              }
              maturityMatrixLevels {
                items {
                  id
                  name
                  ownerGroup
                  createdAt
                  updatedAt
                }
                nextToken
              }
              maturityGoal
              createdAt
              updatedAt
            }
            maturityIndicators {
              items {
                id
                name
                isTrue
                ownerGroup
                maturityMatrixLevel {
                  id
                  name
                  ownerGroup
                  createdAt
                  updatedAt
                }
                actions {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        maturityGoal
        createdAt
        updatedAt
      }
      maturityIndicators {
        items {
          id
          name
          isTrue
          ownerGroup
          maturityMatrixLevel {
            id
            name
            ownerGroup
            maturityColumn {
              id
              name
              ownerGroup
              notes {
                items {
                  id
                  type
                  ownerGroup
                  author
                  timeStamp
                  content
                  itemId
                  controlNotesId
                  riskControlNotesId
                  assessmentNotesId
                  createdAt
                  updatedAt
                  observationID
                }
                nextToken
              }
              maturityMatrixLevels {
                items {
                  id
                  name
                  ownerGroup
                  createdAt
                  updatedAt
                }
                nextToken
              }
              maturityGoal
              createdAt
              updatedAt
            }
            maturityIndicators {
              items {
                id
                name
                isTrue
                ownerGroup
                maturityMatrixLevel {
                  id
                  name
                  ownerGroup
                  createdAt
                  updatedAt
                }
                actions {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          actions {
            items {
              id
              name
              dateCreated
              dueDate
              globalStatus
              status
              description
              type
              ownerGroup
              priority
              maturityIndicator {
                id
                name
                isTrue
                ownerGroup
                maturityMatrixLevel {
                  id
                  name
                  ownerGroup
                  createdAt
                  updatedAt
                }
                actions {
                  nextToken
                }
                createdAt
                updatedAt
              }
              module
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

const VCISOMaturityMatrixBody = (props) => {
  const [selectedMaturityMatrix, setSelectedMaturityMatrix] = useState({});
  const [enableEdits, setEnableEdits] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [columnWidth, setColumnWidth] = useState(3);

  const [settings, setSettings] = useState({
    layout: "horizontal",
    enableEdits: false,
    selectedMaturityMatrix,
    columnWidth: columnWidth,
    showRadarChart: false,
  });

  useEffect(() => {
    setSettings({
      ...settings,
      columnWidth,
    });
  }, [columnWidth]);

  const toggleLayout = () => {
    if (settings.layout === "horizontal") {
      setSettings({
        ...settings,
        layout: "vertical",
      });
    } else if (settings.layout === "vertical") {
      setSettings({
        ...settings,
        layout: "horizontal",
      });
    }
  };

  const toggleChart = () => {
    setSettings({
      ...settings,
      showRadarChart: !settings.showRadarChart,
    });
  };

  return (
    <div>
      <Navbar
        style={{
          position: "relative",
          top: 0,
          right: 0,
          zIndex: 2,
          marginTop: "-10px",
          marginLeft: "-40px",
          marginRight: "-40px",
        }}
        // color="faded"
        className="float-right"
        light
      >
        <NavbarToggler onClick={() => setOpenSettings(!openSettings)} className="mr-2">
          <i className="icon-settings" />
        </NavbarToggler>
        <Collapse isOpen={openSettings} navbar>
          <Nav navbar>
            <SelectMaturityMatrix
              disableDuplicate={props.disableDuplicate}
              disableCreation={true}
              organizationID={props.organizationID}
              setSelectedMatrix={setSelectedMaturityMatrix}
              enableEdits={enableEdits}
              setEnableEdits={setEnableEdits}
            />
            <Button
              className="shadow appearance-none border rounded text-grey-darker leading-tight focus:outline-none focus:shadow-outline  btn-pill "
              style={{ background: "white" }}
              size="sm"
              onClick={() => toggleChart()}
              title="Show Radar Chart"
            >
              <small>{settings.showRadarChart ? "Hide " : "Show "} Radar Chart</small>
            </Button>
            <br />
            <Button
              className="shadow appearance-none border rounded text-grey-darker leading-tight focus:outline-none focus:shadow-outline  btn-pill "
              style={{ background: "white" }}
              size="sm"
              onClick={() => toggleLayout()}
              title={`Current Layout: ${settings.layout}`}
            >
              <small>Change Layout</small>
              {"    "}
              <i
                className="icon-list"
                style={settings.layout !== "horizontal" ? { transform: "rotate(90deg)" } : undefined}
              />
            </Button>
            {settings.layout === "vertical" && (
              <ButtonGroup
                className="shadow appearance-none border rounded text-grey-darker leading-tight focus:outline-none focus:shadow-outline  btn-pill "
                style={{ background: "white" }}
                size="sm"
              >
                <Button
                  style={{ background: "white" }}
                  title="Reduce Column Width"
                  onClick={() => (columnWidth > -1 ? setColumnWidth(columnWidth - 1) : null)}
                  disabled={columnWidth < 0}
                >
                  -
                </Button>
                <Button
                  style={{ background: "white" }}
                  disabled
                  title={`Current Column Width: ${
                    columnWidth === 0
                      ? "Auto Fill"
                      : columnWidth === -1 || columnWidth === 13
                        ? "Small Fit"
                        : columnWidth
                  }`}
                >
                  {columnWidth === 0
                    ? "Auto Fill"
                    : columnWidth === -1 || columnWidth === 13
                      ? "Small Fit"
                      : columnWidth}
                </Button>
                <Button
                  style={{ background: "white" }}
                  title="Increase Column Width"
                  onClick={() => (columnWidth < 13 ? setColumnWidth(columnWidth + 1) : null)}
                  disabled={columnWidth > 12}
                >
                  +
                </Button>
              </ButtonGroup>
            )}
          </Nav>
        </Collapse>
      </Navbar>
      <SelectedMaturityMatrixAPI
        settings={settings}
        enableEdits={enableEdits}
        organizationID={props.organizationID}
        selectedMaturityMatrix={selectedMaturityMatrix}
      />
    </div>
  );
};

const SelectedMaturityMatrix = (props) => {
  useEffect(() => {
    props.data.subscribeToMore(buildSubscription(gql(onDeleteMaturityMatrix), gql(getMaturityMatrix)));
    props.data.subscribeToMore(buildSubscription(gql(onDeleteMaturityColumn), gql(getMaturityMatrix)));
    props.data.subscribeToMore(buildSubscription(gql(onDeleteMaturityMatrixLevel), gql(getMaturityMatrix)));
    props.data.subscribeToMore(buildSubscription(gql(onDeleteMaturityIndicator), gql(getMaturityMatrix)));
  }, []);

  return (
    <>
      {props.settings.layout === "horizontal" ? (
        <MaturityMatrixHorizontal
          className="fadeIn"
          settings={props.settings}
          disableCreation={props.disableCreation}
          enableEdits={props.enableEdits}
          maturityMatrix={props.maturityMatrix}
        />
      ) : (
        <MaturityMatrixVertical
          className="fadeIn"
          settings={props.settings}
          disableCreation={props.disableCreation}
          enableEdits={props.enableEdits}
          maturityMatrix={props.maturityMatrix}
        />
      )}
    </>
  );
};

const SelectedMaturityMatrixAPI = graphql(gql(getMaturityMatrix), {
  options: (props) => ({
    fetchPolicy: "cache-and-network",
    variables: {
      id: props.selectedMaturityMatrix && props.selectedMaturityMatrix.id,
    },
  }),
  props: (props) => ({
    maturityMatrix: props.data.getMaturityMatrix ? props.data.getMaturityMatrix : null,
    data: props.data,
  }),
})(SelectedMaturityMatrix);

const VCISOMaturityMatrix = (props) => {
  return (
    <OrganizationCheck {...props}>
      <VCISOMaturityMatrixBody />
    </OrganizationCheck>
  );
};

VCISOMaturityMatrix.propTypes = {
  disableCreation: PropTypes.bool,
  organizationID: PropTypes.string,
  enableEdits: PropTypes.bool,
  disableDuplicate: PropTypes.bool,
};

export default VCISOMaturityMatrix;
