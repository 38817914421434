import React, { useState } from "react";
import { useSetAsyncData } from "../../../hooks/functional/useSetAsyncData";
import { isNonEmptyArray } from "@rivial-security/func-utils";
import DataLoader from "../../LoadingComponents/DataLoader";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { tryFunction } from "../../Functions/tryFunction";
import { MuiSubmitButton } from "../../GenericComponents/buttons/MuiSubmitButton";

/**
 * Utility to select multiple items to attach to another resource
 * @param {object} props - props to pass to the component
 * @param {JSX.Element} GridComponent - the grid to use to display the items
 * @param {function} getFilteredItems - function used to retrieve all item
 * @param {function} filterItems - function used to filter the items based on current and filtered items
 * @param {function} submitFunction - function used to submit the selected items
 * @param {function} [toggleModal] - function used to toggle the modal if the UI is part of a modal
 * @param {function} [resetFunction] - reset function to update parent component state
 * @param {object} [submitButtonProps] - props to pass to the submit button
 * @returns {JSX.Element}
 */
const SelectNewItemsDataGrid = ({
  GridComponent,
  getFilteredItems,
  filterItems,
  submitFunction,
  toggleModal,
  resetFunction,
  submitButtonProps,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState(null);

  const { isLoading } = useSetAsyncData({
    getData: async () => {
      if (typeof getFilteredItems !== "function") {
        return [];
      }

      const newFilteredItems = await getFilteredItems();
      if (!isNonEmptyArray(newFilteredItems)) {
        return [];
      }

      return newFilteredItems;
    },
    setData: (newFilteredItems) => {
      setFilteredItems(newFilteredItems);
    },
    dependencies: [],
  });

  return (
    <DataLoader isLoading={isLoading || filteredItems === null} isEnoughData={!isNullOrUndefined(GridComponent)}>
      <div
        style={{
          height: "70vh",
          display: "flex",
          flexDirection: "column",
          gap: ".5em",
        }}
      >
        <div style={{ flex: 1 }}>
          <GridComponent
            normalizeData={(items) => {
              if (typeof filterItems === "function") {
                return filterItems({ filteredItems, allItems: items });
              } else {
                return items;
              }
            }}
            enableSelection={true}
            onSelectedDataChange={(selectedItems) => {
              setSelectedItems(selectedItems);
            }}
          />
        </div>
        <div style={{ width: "100%", display: "flex", justifyContent: "right" }}>
          <MuiSubmitButton
            title={"Link items"}
            dataTestId={"link-items-submit-button"}
            {...(submitButtonProps || {})}
            onClick={async () => {
              if (!isNonEmptyArray(selectedItems)) {
                return;
              }

              const newSelectedItems = [];
              for (const selectedItem of selectedItems) {
                const found = newSelectedItems.find((item) => {
                  return item?.id === selectedItem?.id;
                });

                if (!found) {
                  newSelectedItems.push(selectedItem);
                }
              }
              if (typeof submitFunction === "function") {
                await submitFunction({ selectedItems: newSelectedItems });
              }
              setSelectedItems([]);
              tryFunction(toggleModal, resetFunction);
            }}
          />
        </div>
      </div>
    </DataLoader>
  );
};

export default SelectNewItemsDataGrid;
