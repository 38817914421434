import { generateGraphql } from "@rivial-security/generategraphql";
import { useForm } from "../../../../../../hooks/views/useForm/useForm";
import { modules, resources } from "@rivial-security/role-utils";

/**
 * Custom hook for creating a program section (mainly used through the component instance CreateProgramSection.js)
 * @param {string} organizationID
 * @param {function} toggleModal
 * @param {function} resetFunction
 * @param {object} programElement
 * @param {object} props - all other props passed to useForm hook
 * @returns {{input: {}, display: *}}
 */
const useCreateProgramSection = ({ organizationID, toggleModal, resetFunction, programElement, ...props }) => {
  const module = modules.GOVERNANCE;
  const resource = resources.PROGRAM_SECTION;

  const { createMutation } = generateGraphql("ProgramSection", ["name", "description", "programElementID"]);

  const formHook = useForm({
    mutation: createMutation,
    organizationID,
    toggleModal,
    module,
    resource,
    resetFunction,
    fieldConfig: {
      name: {
        label: "Name",
        inputType: "text",
        required: true,
      },
      description: {
        label: "Description",
        inputType: "text",
      },
      programElementID: {
        defaultValue: programElement?.id,
        isHidden: true,
        inputType: "text",
      },
    },
    ...props,
  });

  return { ...formHook };
};

export default useCreateProgramSection;
