import React from "react";
import { withOrganizationCheck } from "@utils/Context/withOrganizationCheck";
import InterfaceNotifications from "./InterfaceNotifications";
import InterfaceDefaultRoute from "./InterfaceDefaultRoute";
import InterfaceEvidenceEmail from "./InterfaceEvidenceEmail";
import { useOperationTeamCheck } from "@utils/Context/useOperationTeamCheck";

/**
 * Author: Cole Rowell
 * Created At: 3/27/20
 * Edits:
 * - 3/30/20 CR: Improved the layout of each and made them more visually appealing.
 * - 6/22/20 CR: Deleted unused imports to remediate console warnings
 * - 7/13/20 KM: Added the default toast timeout setting
 *
 * @description Interface Options Component - hosts all user preference settings adjustment components for the GUI of the app
 *
 * Note: does not show Default Route option for Operation Team users
 *
 * @param {object} props
 * @return {JSX.Element}
 * @constructor
 */
const InterfaceOptions = (props) => {
  const operationTeamCheck = useOperationTeamCheck();

  return (
    <div className="animated fadeIn">
      <InterfaceNotifications />
      <br />
      {!operationTeamCheck.isOperationTeamUser() && (
        <>
          <InterfaceDefaultRoute />
          <br />
        </>
      )}
      <InterfaceEvidenceEmail />
      {/*TODO: implement for dark theme*/}
      {/*<InterfaceTheme />*/}
    </div>
  );
};

export default withOrganizationCheck(InterfaceOptions);
