import { useModal } from "../../../../../../../hooks/views/useModal";
import { useCustomQueryNestedFieldDataGrid } from "../hooks/useCustomQueryDataGrid/useCustomQueryNestedFieldDataGrid";
import { Button } from "@mui/material";
import { Icon } from "@iconify/react";

/**
 * A button that opens a modal with the list of nested items for a field from a custom query
 * @param {object} item - the entire parent object containing the field to be displayed
 * @param {string} field - the field name of the property in item containing the list data
 * @param {object} query - the part of custom query object from query builder that defines the nested field
 * @return {JSX.Element}
 */
const CustomQueryNestedFieldGrid = ({ item, field, query }) => {
  const data = Array.isArray(item?.[field]) ? item?.[field] : item?.[field]?.items;
  const grid = useCustomQueryNestedFieldDataGrid({
    query,
    data,
  });

  const modal = useModal(
    "Nested Resources",
    <div style={{ height: "70vh" }}>{grid?.display}</div>,
    <Button
      disabled={!data?.length}
      style={{
        fontSize: "0.8125rem",
      }}
      startIcon={<Icon icon={"clarity:info-standard-line"} />}
    >
      {`${data?.length || 0} Items`}
    </Button>,
    {
      width: "80vw",
    },
  );

  return modal?.modalButton;
};

export default CustomQueryNestedFieldGrid;
