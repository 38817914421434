import React from "react";
import { Col, Row } from "reactstrap";
import { DETAILS_TYPES } from "../../../../../../hooks/views/useGrid/enums/DETAILS_TYPES";
import { EVIDENCE_ACTIVITY_TYPES } from "../../../../../../typedefs/Compliance/Evidence/Evidence";
import { usePolicyDataGrid } from "../../../../../Program/Policies/hooks/usePolicyDataGrid";
import { withOrganizationCheck } from "../../../../../../utils/Context/withOrganizationCheck";
import { generateGraphql } from "@rivial-security/generategraphql";
import { Alert } from "@mui/material";

/**
 * Allows the user to select a policy to upload for Evidence
 * @param organizationID
 * @param onSubmit
 * @returns {JSX.Element}
 * @constructor
 */
const PolicyEvidenceForm = ({ organizationID, onSubmit }) => {
  const { listQuery: listPolicies } = generateGraphql("Policy", ["name", "policyVersions"], {
    policyVersions: `(limit: 100) { items { id status approvalDate  } }`,
  });

  const gridConfig = {
    gridHeight: "60vh",
    allowPaging: false,
    createResourceComponentWidth: "80vw",
    detailsType: DETAILS_TYPES.MODAL,
    enableQuickDetails: true,
    query: listPolicies,
    normalizeData: (data) => {
      return data?.filter((policy) =>
        policy?.policyVersions?.items?.some(
          (policyVersion) => policyVersion.status === "approved" && policyVersion.approvalDate,
        ),
      );
    },
    selectionSettings: {
      type: "Single",
    },
    enableSelectButton: true,
    onSelectCallback: (value) => {
      const activityInput = {
        type: EVIDENCE_ACTIVITY_TYPES.POLICY,
        policy: {
          id: value?.id,
          name: value?.name,
        },
      };
      onSubmit && onSubmit(activityInput);
    },
  };

  const cardConfig = {
    title: "Select a Policy",
    enableSticky: false,
  };

  const grid = usePolicyDataGrid({
    organizationID,
    gridConfig,
    cardConfig,
    formProps: {
      forceInitialApproval: true,
      forceInitialApprovalTooltip:
        "Only policies with accepted versions are allowed to be used as evidence. Therefore, new policies created from this screen must be initially accepted.",
    },
  });

  return (
    <div>
      <Row>
        <Col>
          <Alert severity="info">
            Only those policies which have approved versions can be selected as an artifact.
          </Alert>
          <div style={{ marginBottom: "1em", height: "30em" }}>{grid.display}</div>
        </Col>
      </Row>
    </div>
  );
};

export default withOrganizationCheck(PolicyEvidenceForm);
