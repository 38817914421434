import { convertCamelCaseToSentence, formattedName } from "@rivial-security/func-utils";

import { AV_STATUS } from "@enums/AV_STATUS";
import { EVIDENCE_ACTIVITY_TYPES } from "../../../../typedefs/Compliance/Evidence/Evidence";
import { v4 as uuid } from "uuid";

/**
 * @typedef {object} getComplianceActivityParams
 * @param {object[]} items - Array of items to generate the data from
 * @param {string} [rootName] - Name of the root folder
 * @param {object} dateRange - Date range for the data filter
 * @param {Date} dateRange.startDate - Start date for the data filter
 * @param {Date} dateRange.endDate - End date for the data filter
 */

/**
 * Generate the data for the Compliance Activity Download
 * @param {getComplianceActivityParams} input
 * @returns {{children: *, name: string, id: string, type: string}}
 */
export const getComplianceActivityData = ({ items, rootName = "", dateRange }) => {
  return {
    nodeId: "root",
    id: "root",
    name: rootName,
    type: "folder",
    children: items?.map((item) => {
      return {
        nodeId: uuid(),
        id: item?.id,
        name: item?.name,
        type: "folder",
        children: item?.activity?.items
          ?.filter((activity) => {
            /**
             * Only include supported to download activity types
             */
            const isSupportedActivity = SUPPORTED_DOWNLOAD_ACTIVITY_TYPES.includes(activity?.type);
            const isArtifactExists = activity?.artifact?.id;
            const isDocumentExists = activity?.documents?.items.length > 0;

            /**
             * Check if activity is within the date range
             */
            const inDateRange = isActivityInDateRange({ activity, dateRange });

            return isSupportedActivity && (isArtifactExists || isDocumentExists) && inDateRange;
          })
          ?.sort((a, b) => new Date(a?.createdAt) - new Date(b?.createdAt))
          ?.map((activity) => {
            /**
             * Get the point of contact name
             */
            let pointOfContactName = "";
            if (activity?.pointOfContact) {
              pointOfContactName = formattedName({
                pointOfContact: activity?.pointOfContact,
              });
            }
            return {
              nodeId: uuid(),
              id: activity?.id,
              name: `${new Date(activity?.createdAt).toLocaleDateString()} - ${convertCamelCaseToSentence(
                activity?.type,
              )}${pointOfContactName ? ` - ${pointOfContactName}` : ""}`,
              type: "folder",
              children: getDocumentsForActivity(activity),
            };
          }),
      };
    }),
  };
};

/**
 * Get the document name from the document object
 * @param {object} doc - Document object to get the name from
 * @returns {*}
 */
const getDocumentName = (doc) => {
  if (!doc) return;

  let name = doc?.name;

  /**
   * If the document name is not exists, get the name from the file key
   */
  if (!name) {
    name = doc?.file?.key?.split("/").pop();
  } else {
    /**
     * If the document name does not have an extension, get the extension from the file key
     */
    if (!/\.[^./\\]+$/.test(name)) {
      const fileExtension = doc?.file?.key?.split(".")?.pop()?.trim()?.toLowerCase();
      name = `${name}.${fileExtension}`;
    }
  }

  return name;
};

/**
 * Get the artifact and uploaded documents for the activity
 * @param {object} activity - Activity object to get the documents from
 * @returns {*[]}
 */
const getDocumentsForActivity = (activity) => {
  const documents = filterDocuments(activity?.documents?.items);
  const hasDocuments = documents?.length > 0;
  if (hasDocuments) {
    return documents.map((document) => {
      return {
        nodeId: uuid(),
        ...document,
        name: getDocumentName(document),
        type: "file",
      };
    });
  }

  const artifact = activity?.artifact;
  const hasArtifact = artifact?.id && artifact?.file;
  if (hasArtifact) {
    return [
      {
        nodeId: uuid(),
        ...artifact,
        name: getDocumentName(artifact),
        type: "pdf",
      },
    ];
  }

  return [];
};

/**
 * Filter out the infected documents and documents without a file
 * @param {object[]} documents - Array of documents to filter
 * @returns {*|*[]}
 */
const filterDocuments = (documents) => {
  if (!Array.isArray(documents)) {
    return [];
  }
  return documents
    ?.filter((doc) => doc?.avStatus !== AV_STATUS.INFECTED) // Filter out the infected documents
    ?.filter((doc) => doc?.file); // Filter out the documents without a file
};

/**
 * Check if the activity is within the date range
 * @param {object} activity - Activity object to check
 * @param {object} dateRange - Date range to check
 * @returns {boolean}
 */
const isActivityInDateRange = ({ activity, dateRange }) => {
  let isActivityDateInRange = true;
  const activityDate = new Date(activity?.createdAt);

  if (dateRange?.startDate) {
    isActivityDateInRange = activityDate >= new Date(dateRange?.startDate);
  }

  if (dateRange?.endDate) {
    isActivityDateInRange = activityDate <= new Date(dateRange?.endDate);
  }

  if (dateRange?.startDate && dateRange?.endDate) {
    isActivityDateInRange =
      activityDate >= new Date(dateRange?.startDate) && activityDate <= new Date(dateRange?.endDate);
  }

  return isActivityDateInRange;
};

/**
 * Supported activity types to download
 */
const SUPPORTED_DOWNLOAD_ACTIVITY_TYPES = [
  EVIDENCE_ACTIVITY_TYPES.DOCUMENT_UPLOAD,
  EVIDENCE_ACTIVITY_TYPES.ATTESTED,
  EVIDENCE_ACTIVITY_TYPES.MEETING,
  EVIDENCE_ACTIVITY_TYPES.POLICY,
  EVIDENCE_ACTIVITY_TYPES.REPORT_UPLOAD,
  EVIDENCE_ACTIVITY_TYPES.TRAINING,
  EVIDENCE_ACTIVITY_TYPES.PHISHING,
  EVIDENCE_ACTIVITY_TYPES.EXTERNAL_URL,
  EVIDENCE_ACTIVITY_TYPES.SCREENSHOT,
];
