import { Button } from "reactstrap";
import React from "react";
import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { useModal } from "../../useModal";

/**
 * @description Create new item hook
 * @param {object} ref - reference of the grid component
 * @param {string} createItemModalHeader - create modal header text
 * @param {JSX} createResourceComponent - create item component
 * @param {function} [formatNewItem] - before getNewItem is called and a new item from the form is added to the grid this optional function can be used to format the new item
 * @param {function} getNewItem - get created item
 * @param {function} resetFunction - re-fetch data from the database
 * @param {string} createResourceComponentWidth - width of the create modal
 * @param {string} typename - the database resource name for the item being created
 * @return {object} {{createItemModal: {setModalIsOpen: function(*=): void, modalButton: JSX.Element, modalIsOpen: boolean | undefined, modal: JSX.Element}}}
 */
export const useDataGridForm = ({
  createResourceComponent,
  formatNewItem,
  getNewItem,
  resetFunction,
  createResourceComponentWidth,
  typename = "Item",
  addItem,
  createItemModalHeader = `Create a new ${convertCamelCaseToSentence(typename)}`,
  toggleModal,
  module,
  resource,
  disableRoleChecking,
}) => {
  const handleNewItem = (item) => {
    // Optionally pass the item through a format function
    if (typeof formatNewItem === "function") {
      item = formatNewItem(item);
      if (!item) return; //formatting function decided not to add the item
    }

    // External handler for new items
    if (typeof getNewItem === "function") {
      getNewItem(item);
    }

    // Internal handler for adding new item to grid
    addItem(item);
  };

  /**
   * Create new item modal
   */
  const createItemModal = useModal(
    createItemModalHeader,
    createResourceComponent
      ? React.cloneElement(createResourceComponent, {
          resetFunction,
          getNewItem: handleNewItem,
          toggleModal,
          typename,
          module,
          resource,
          disableRoleChecking,
        })
      : "Missing Form Component",
    <Button
      id={"button-create-item-grid"}
      data-testid={"button-create-item-grid"}
      size="sm"
      className="btn-pill"
      style={{
        boxShadow: "none",
        color: "white",
        backgroundColor: "transparent",
        border: "none",
      }}
      title={`Create new ${convertCamelCaseToSentence(typename)}`}
    >
      <i className="icon-plus" />
    </Button>,
    {
      width: createResourceComponentWidth || "60vw",
    },
  );

  return {
    modal: createItemModal,
  };
};
