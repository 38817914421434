import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";
import React from "react";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useGetManyEffect } from "../../../../../hooks/functional/useGetManyEffect";
import { useRiskControlDataGrid } from "./SubControls/hooks/useRiskControlDataGrid";
import CreateControlCategorySubControl from "./SubControls/components/CreateControlCategorySubControl";

/**
 * Displays Risk Controls that are linked to a particular control category
 * @param {string} organizationID - ownerGroup ID of the Organization, Template, or OperationTeam
 * @param {object} item - the parent Risk Control Category
 * @param {string} controlCategoryID - the ID of the Risk Control Category
 * @param {function} resetFunction - function to reset the parent component
 */
const ControlCategorySubControls = ({ organizationID, item, controlCategoryID, resetFunction }) => {
  const { getQuery } = generateGraphql("RiskControlCategory", ["subControls"], {
    subControls: `(limit: 1000) {
      items {
        id
        statementNumber
        name
        costOfControl
        implementationRating
        strengthRating
        keyControl
        notes {
          id
          type
          ownerGroup
          author
          timeStamp
          content
          tag
          observationID
        }
      }
    }`,
  });

  const queryConfig = {
    query: null,
  };

  const gridConfig = {
    createResourceComponent: (
      <CreateControlCategorySubControl
        controlCategoryID={controlCategoryID || item?.id}
        organizationID={organizationID}
      />
    ),
  };

  const subControlGrid = useRiskControlDataGrid({
    organizationID,
    ...queryConfig,
    ...gridConfig,
    resetFunction,
  });

  useGetManyEffect({
    query: getQuery,
    item,
    field: "subControls",
    callback: subControlGrid.setData,
    setIsLoading: subControlGrid.setIsLoading,
  });

  return <div style={{ height: "70vh" }}>{subControlGrid.gridDisplay}</div>;
};

export default withOrganizationCheck(ControlCategorySubControls);
