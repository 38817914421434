import { generateGraphql } from "@rivial-security/generategraphql";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";

/**
 * Simple mutation for creating a single Observation object
 * @param input
 */
export const createObservation = (input) => {
  const { createMutation } = generateGraphql(
    "Observation",
    [
      "name",
      "isFinding",
      "module",
      "recommendations",
      "risk",
      "formalResponse",
      "decision",
      "needsDecision",
      "status",
      "insurancePolicyValidated",
      "estimatedCompletionDate",
    ],
    {
      recommendations: `(limit: 100) { items { id recommendation { id } } }`,
    },
  );

  //Default isFinding field to false
  if (input && (input?.isFinding === undefined || input?.isFinding === null)) {
    input.isFinding = false;
  }

  return ItemMutation(createMutation, {
    ...input,
  });
};
