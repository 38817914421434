import { useContext, useEffect } from "react";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useTags } from "../../../../utils/Tags/hooks/useTags";
import { OrganizationContext } from "../../../../utils/Context/OrganizationContext";
import { TEMPLATE } from "../../../../enums/TEMPLATE";

const DataGridTagFilterMenu = ({ item, applyValue, focusElementRef, organizationID }) => {
  //Use the origin organization for template pages
  const context = useContext(OrganizationContext);
  if (organizationID === TEMPLATE) {
    organizationID = context?.role?.ownerGroup;
  }

  const tags = useTags({
    organizationID,
    initSelectedTags: item?.tags,
    showTagsInitially: true,
    showHideTagsOption: false,
    showCreateTagsOption: false,
  });

  useEffect(() => {
    applyValue({ ...item, tags: tags?.selectedTags });
  }, [tags?.selectedTags]);

  return tags.display;
};

export default withOrganizationCheck(DataGridTagFilterMenu);
