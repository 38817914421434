import React, { useContext } from "react";
import { UIContext } from "../../../../../utils/Context/UIContext";
import { usePreferences } from "../../../../../hooks/views/usePreferences/usePreferences";
import { useForm } from "../../../../../hooks/views/useForm/useForm";
import { useCard } from "../../../../../hooks/views/useCard";

/**
 * @description Interface Show Info Vulnerabilities for Testing module user preference switch
 * @return {JSX.Element}
 * @constructor
 */
const InterfaceInfoVulnerabilities = () => {
  const { addToast } = useContext(UIContext);

  const preferences = usePreferences();

  const form = useForm({
    disableSubmitButton: true,
    disableResetButton: true,
    fieldConfig: {
      showInfoVulnerabilities: {
        tooltip:
          "When enabled, informational vulnerabilities will be displayed. Note: this can affect loading vulnerability lists",
        inputType: "switch",
        label: "Show Informational Vulnerabilities",
        defaultValue: preferences?.getPreference("testing", "showInfoVulnerabilities") || false,
        onChangeFunction: ({ showInfoVulnerabilities }) => {
          preferences?.setPreference("testing", { showInfoVulnerabilities });
          addToast({
            header: `Show Informational Vulnerabilities was changed to ${
              showInfoVulnerabilities ? "enabled" : "disabled"
            }`,
            icon: "success",
          });
        },
      },
    },
  });

  const card = useCard({
    id: "show-info-vulnerabilities",
    title: (
      <>
        <h4 style={{ display: "inline" }}>Testing Module</h4>
      </>
    ),
    body: form.display,
  });

  return card?.display;
};

export default InterfaceInfoVulnerabilities;
