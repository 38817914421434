import React from "react";
import { formatPhishingNotes } from "../functions/formatPhishingNotes";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Displays the Phishing Recipient notes for the Recipient grid
 * @param item
 * @param item.opened_at
 * @param item.clicked_at
 * @param item.reported_at
 * @returns {JSX.Element}
 * @constructor
 */
const RecipientNotes = ({ item }) => {
  return <span>{!isNullOrUndefined(item) && formatPhishingNotes(item)}</span>;
};

export default RecipientNotes;
