import { deRootifyQueryObject, runCustomQueryWithAppSync } from "@rivial-security/widget-utils";
import { useEffect, useState } from "react";

import { Button } from "@mui/material";
import { ErrorLogger } from "@utils/EventLogger";
import { Icon } from "@iconify/react";
import { runCustomQueryOnLambda } from "../../functions/runCustomQueryOnLambda";

export const QUERY_RUN_TYPE = {
  LAMBDA: "lambda",
  LOCAL: "local",
};

/**
 * Responsible for providing the currently visible runner buttons and
 * generates alerts for the user to act upon when an error occurs
 * @param {object} query - the query configuration built with the QueryBuilder
 * @param {string} organizationID - the organization for which to run the query
 * @param {string} customQueryID - the custom query ID to use when running the query
 * @param {string} runType - the type of query to run (lambda or local)
 * @param {boolean} autoRender - whether to automatically render the query results on start
 * @return {{isLoading: *, toolbarOptions: [{component: JSX.Element, layoutPriority: number}], data: *}}
 */
export const useCustomQueryRunnerExecutionWorkflow = ({
  query,
  organizationID,
  customQueryID,
  runType = QUERY_RUN_TYPE.LAMBDA,
  autoRender,
}) => {
  const [listData, setListData] = useState([]);
  const [rootData, setRootData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleRunQuery = async () => {
    try {
      const cleanedQuery = deRootifyQueryObject({ query });
      const rootTypename = cleanedQuery?.typename;
      if (!rootTypename) {
        return {};
      }

      setIsLoading(true);
      let newRootData;

      if (runType === QUERY_RUN_TYPE.LAMBDA) {
        newRootData = await runCustomQueryOnLambda({ customQueryID });
      } else {
        newRootData = await runCustomQueryWithAppSync({ query, organizationID });
      }
      const newListData = newRootData?.[rootTypename]?.items || [];

      setListData(newListData);
      setRootData(newRootData);
    } catch (e) {
      ErrorLogger("Could not finish running custom query", e);
    } finally {
      setIsLoading(false);
    }
  };

  //Clear data when the root type changes
  useEffect(() => {
    setListData([]);
    setRootData({});
  }, [query?.typename]);

  //When auto render is turned on update the data when custom query ID becomes available
  const isValidQuery = !!deRootifyQueryObject({ query })?.typename;
  useEffect(() => {
    if (autoRender === true && isValidQuery === true) {
      handleRunQuery();
    }
  }, [autoRender, isValidQuery]);

  const toolbarOptions = [
    {
      layoutPriority: 1,
      component: (
        <Button
          data-testid={"run-query-button"}
          disabled={!isValidQuery}
          style={{
            fontSize: "0.8125rem",
            color: isValidQuery ? "#28A745" : "grey",
          }}
          startIcon={<Icon icon={"mdi:database-search"} />}
          onClick={() => handleRunQuery()}
        >
          {"Run Query"}
        </Button>
      ),
    },
  ];

  return {
    toolbarOptions,
    listData,
    rootData,
    isLoading,
    runQuery: handleRunQuery,
  };
};
