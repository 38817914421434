import { useCustomResourceTypeDetails } from "../hooks/useCustomResourceTypeDetails";
import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";

/**
 * A component to display the details of a CustomResourceType
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CustomResourceTypeDetails = (props) => useCustomResourceTypeDetails(props).display;

export default withDetailsPage(CustomResourceTypeDetails, {
  title: "Custom Resource Type Details",
});
