import { BUILT_IN_WIDGETS } from "../constants/BUILT_IN_WIDGETS";
import React from "react";

/**
 * Renders a built-in widget based on the BUILT_IN_WIDGETS configuration
 *
 * @param {string} itemId - the custom widget ID
 * @param {string} organizationID - the organization ID
 * @constructor
 */
const RenderBuiltInWidget = ({ itemId, organizationID }) => {
  const widgetId = itemId;

  const widgetProps = {
    organizationID,
    gridDisplay: true,
    persistenceUUID: `built-in-widget-${itemId}`,
  };

  const findWidget = (widgetId) => {
    return Object.values(BUILT_IN_WIDGETS).find((widget) => widget.id === widgetId);
  };

  const foundWidget = findWidget(widgetId);

  if (foundWidget) {
    if (typeof foundWidget.component === "function") {
      const DynamicComponent = foundWidget.component;
      return <DynamicComponent {...widgetProps} />;
    } else {
      return React.cloneElement(foundWidget.gridComponent || foundWidget.component, {
        ...widgetProps,
      });
    }
  } else {
    return <div>Widget not found</div>;
  }
};

export default RenderBuiltInWidget;
