import { modules, resources } from "@rivial-security/role-utils";

import CustomFieldsTable from "../../../Compliance/Controls/ControlSets/components/CustomFieldsTable";
import CustomResourceEntryDataGrid from "../../CustomResourceEntries/components/CustomResourceEntryDataGrid";
import Dashboard from "@utils/GenericComponents/Dashboard";
import Grid from "@mui/material/Grid";
import React from "react";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useAccordion } from "@hooks/views/useAccordion/useAccordion";
import { useDetailsCard } from "@hooks/views/useDetailsCardV2";

/**
 * CustomResourceType details hook

 * @param {string} organizationID - the organization of the CustomResourceType to view
 * @param {string} module=modules.ADMINISTRATOR - platform module for role checking
 * @param {string} resource=resources.CONTROL_TEMPLATE - platform resource for role checking
 * @param {boolean} disableRoleChecking=false - if TRUE will disable role checking
 * @param {boolean} isTemplate=false - if TRUE will hide non template parts of the resource
 * @param {string} itemId - CustomResourceType id
 * @param {boolean} tableDisplay - if true will display the details as a table instead of a card
 * @param {function} updateItemById - function to get updated item
 * @param {object} props - additional properties passed through to useDetails hook
 */
export const useCustomResourceTypeDetails = ({
  organizationID,
  module = modules.TOOLS,
  resource = resources.TOOL,
  disableRoleChecking = false,
  isTemplate = false,

  itemId,
  tableDisplay,
  updateItemById,
  ...props
}) => {
  const typename = "CustomResourceType";
  const fields = ["name", "customFields"];
  const queryFields = ["name", "customFields", "customResourceEntries"];

  const fieldNameDictionary = {
    name: "Name",
    customFields: "Custom Fields",
  };

  const nestedFields = {
    customFields: `{name description type options { label value } multipleSelect { label value } numberSettings { min max step format } }`,
    customResourceEntries: `{items {id}}`,
  };

  const { updateMutation } = generateGraphql(typename, queryFields, nestedFields);

  const queryConfig = {
    itemId,
  };

  const customFields = [
    {
      field: "customFields",
      component: <CustomFieldsTable showAddButtonInit={true} typename={"CustomResourceType"} />,
    },
  ];

  const details = useDetailsCard({
    queryConfig,
    module,
    resource,
    disableRoleChecking,
    fields,
    detailsConfig: {
      header: `Custom Resource Type ${isTemplate ? "Template " : ""} Details`,
      updateMutation,
      customFields,
      fieldNameDictionary,
    },
    typename,
    nestedFields,
    tableDisplay,
    updateItemById,
    ...props,
  });

  const accordion = useAccordion({
    items: [
      {
        title: "General Information",
        component: details.tableDisplay,
        icon: "icon-list",
        defaultExpanded: true,
      },
      {
        title: "Resources",
        component: (
          <CustomResourceEntryDataGrid
            customResourceTypeID={details?.item?.id}
            organizationID={organizationID}
            isTemplate={isTemplate}
            wrapperStyle={{ height: "50vh" }}
          />
        ),
        icon: "icon-grid",
        defaultExpanded: true,
      },
    ],
  });

  const display = (
    <Dashboard resetFunction={details.reset} subTitle={details?.item?.name}>
      <div>
        <Grid item lg={12} xs={12} container spacing={2}>
          {accordion.display}
        </Grid>
      </div>
    </Dashboard>
  );

  return {
    ...details,
    display,
  };
};
