import { generateGraphql } from "@rivial-security/generategraphql";
import { QueryWithNextToken } from "../../../../utils/Functions/Graphql/QueryWithNextToken";

const { listQuery } = generateGraphql("ControlEvidenceLink", ["controlID", "evidenceID"]);

/**
 * Queries all ControlEvidenceLinks for an Org and creates a Map() of the controlID: evidenceID
 * Used to prevent duplicates when importing ControlEvidenceLinks from CSV.
 */
export const MapControlIDsToEvidenceIDs = async (organizationID) => {
  const dictionary = new Map();

  const controlEvidenceLinks = await QueryWithNextToken({
    query: listQuery,
    organizationID,
  });

  for (const link of controlEvidenceLinks) {
    dictionary.set(link.controlID, link.evidenceID);
  }

  return dictionary;
};
