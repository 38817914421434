import { modules, resources } from "@rivial-security/role-utils";

import AssociatedEvidence from "../../customFields/AssociatedEvidence/AssociatedEvidence";
import ControlChanges from "../../customFields/ControlChanges";
import ControlSet from "../../customFields/ControlSet";
import EnabledDisabledButtonGroupField from "@utils/CustomFields/EnabledDisabledButtonGroupField";
import IsDisabled from "../../customFields/IsDisabled";
import React from "react";
import TagsField from "@utils/Tags/customFields/TagsField";
import { createControlTagLink } from "@utils/Tags/functions/createTagLinks/createControlTagLink";

// The following are CONTROL DETAILS constants

export const controlFieldNameDictionary = {
  statementNumber: "Statement Number",
  name: "Declarative Statement",
  controlSet: "Control Framework",
  inPlace: "Status",
  isDisabled: "Disabled",
  evidences: "Evidence",
  tags: "Tags",
};

export const controlFields = [
  "statementNumber",
  "name",
  "controlSet",
  "inPlace",
  "isDisabled",
  "evidences",
  "customFieldData",
  "changes",
  "tags",
  "notes",
  "audits",
];

export const controlCustomFields = ({
  module = modules.COMPLIANCE,
  resource = resources.CONTROL,
  disableRoleChecking = false,
  isTemplate = false,
  originOrganizationID,
  organizationID,
}) => {
  return [
    {
      field: "controlSet",
      component: <ControlSet module={module} resource={resource} disableRoleChecking={disableRoleChecking} />,
    },
    {
      field: "inPlace",
      component: (
        <EnabledDisabledButtonGroupField
          config={{
            module,
            resource,
            disableRoleChecking,
            typename: resources.CONTROL,
            field: "inPlace",
            rightOffText: "Not in Place",
            leftOnText: "In Place",
          }}
        />
      ),
    },
    {
      field: "isDisabled",
      friendlyName: "Disabled",
      component: <IsDisabled module={module} resource={resource} disableRoleChecking={disableRoleChecking} />,
    },
    {
      field: "evidences",
      component: <AssociatedEvidence />,
      isHidden: true,
    },
    {
      field: "tags",
      isHidden: isTemplate,
      component: (
        <TagsField
          module={module}
          resource={resource}
          createLinkFunction={createControlTagLink}
          organizationID={isTemplate ? originOrganizationID : organizationID}
        />
      ),
    },
    {
      field: "changes",
      component: <ControlChanges />,
    },
    {
      field: "customFieldData",
      isHidden: true,
    },
    {
      field: "notes",
      isHidden: true,
    },
  ];
};

export const controlNestedFields = {
  tags: `(limit: 100) { items { __typename id tag { id name description fontColor backgroundColor } } }`,
  audits: `(limit: 1000) { items { id isCompliant notes {id author timeStamp content observationID } audit { id name auditDate controlSet { id name } } } }`,
  evidences: `(limit: 100) {
    items {
      id
      evidence {
        id
        ownerGroup
        name
        status
        frequency
        type
        allowUnauthenticatedSubmissions
        pointOfContacts (limit: 100) {
          items {
            id
            pointOfContact {
              id
              ownerGroup
              firstName
              lastName
              user {
                id
              }
              actions(limit: 100) { items { id name status createdAt data } }
            }
          }
        }
        tags (limit: 100) { items { __typename id tag { id name description fontColor backgroundColor } } }
      }
    }
  }`,
  controlSet: `{ id name customFields { name type options { label value } multipleSelect { label value } numberSettings { min max step format } } }`,
  notes: `{ id type ownerGroup author timeStamp content tag observationID }`,
  changes: `(limit: 100) { items { id date userEmail change } }`,
};
