import { createTable } from "@rivial-security/report-utils";
import { createBlankLine } from "@rivial-security/report-utils";

export const FILTER_FIELDS = {
  all: "all",
  inPlace: "inPlace",
  notInPlace: "notInPlace",
  audited: "audited",
  notAudited: "notAudited",
};

/**
 * @description Generate Vendor Controls Table
 * @param {object} reportContext - vendor review report context
 * @param {FILTER_FIELDS} filterField - the type of filtering to be performed
 * @returns {object} {({tableFormat: {shading: {backgroundColor: string, foregroundColor: string, textureStyle: string}, preferredWidth, leftMargin: number, bidi: boolean, borders: {diagonalDown: {hasNoneStyle: boolean, color: string, lineStyle: string, shadow: boolean, lineWidth: number, space: number}, horizontal: {hasNoneStyle: boolean, color: string, lineStyle: string, shadow: boolean, lineWidth: number, space: number}, top: {hasNoneStyle: boolean, color: string, lineStyle: string, shadow: boolean, lineWidth: number, space: number}, left: {hasNoneStyle: boolean, color: string, lineStyle: string, shadow: boolean, lineWidth: number, space: number}, bottom: {hasNoneStyle: boolean, color: string, lineStyle: string, shadow: boolean, lineWidth: number, space: number}, vertical: {hasNoneStyle: boolean, color: string, lineStyle: string, shadow: boolean, lineWidth: number, space: number}, right: {hasNoneStyle: boolean, color: string, lineStyle: string, shadow: boolean, lineWidth: number, space: number}, diagonalUp: {hasNoneStyle: boolean, color: string, lineStyle: string, shadow: boolean, lineWidth: number, space: number}}, leftIndent: number, allowAutoFit: boolean, preferredWidthType: string, cellSpacing: number, topMargin: number, rightMargin: number, bottomMargin: number, tableAlignment: string}, grid: number[], description: null, rows: [{cells: [], rowFormat: {allowBreakAcrossPages: boolean, gridBeforeWidthType: string, gridAfter: number, gridAfterWidth: number, heightType: string, borders: {diagonalDown: {}, horizontal: {}, top: {}, left: {}, bottom: {}, vertical: {}, right: {}, diagonalUp: {}}, isHeader: boolean, leftIndent: number, gridAfterWidthType: string, gridBefore: number, gridBeforeWidth: number, height: number}}], title: null}|{paragraphFormat: {listFormat: {}, styleName: string}, characterFormat: {}, inlines: [{characterFormat: {bidi: boolean}, text: string}]}|{paragraphFormat: {listFormat: {}, styleName: string}, characterFormat: {}, inlines})[]}
 */
export const generateVendorControlsTable = (reportContext, filterField) => {
  const vendorControls = reportContext?.config?.vendorControls || [];

  const filteredControls = vendorControls.filter((control) => {
    switch (filterField) {
      case FILTER_FIELDS.inPlace:
        return control.inPlace === true;
      case FILTER_FIELDS.notInPlace:
        return control.inPlace === false;
      case FILTER_FIELDS.audited:
        return control.audited === true;
      case FILTER_FIELDS.notAudited:
        return control.audited === false;
      case FILTER_FIELDS.all:
        return true;
      default:
        return true;
    }
  });

  //Generate table components
  return [
    createBlankLine(),
    //Table
    createTable({
      fields: ["controlCategory", "statementNumber", "name", "inPlace", "audited"],
      fieldNameDictionary: {
        controlCategory: "Control Category",
        statementNumber: "Statement Number",
        name: "Control Name",
        inPlace: "In Place",
        audited: "Audited",
      },
      items: filteredControls,
      headerConfig: {
        textAlignment: "Center",
        bold: true,
      },
      customFields: [
        {
          field: "inPlace",
          format: "function",
          function: (item, fieldValue) => (fieldValue && fieldValue === true ? "In place" : "Not in place"),
        },
        {
          field: "audited",
          format: "function",
          function: (item, fieldValue) => (fieldValue && fieldValue === true ? "Audited" : "Not Audited"),
        },
      ],
    }),
  ];
};
