import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, ButtonGroup } from "reactstrap";
import { compose } from "react-apollo";
import { graphqlMutation } from "aws-appsync-react";
import gql from "graphql-tag";
import { useCheckPermissions } from "../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

const { getQuery: getMaturityMatrix } = generateGraphql("MaturityMatrix");
const { updateMutation: updateMaturityIndicator } = generateGraphql("MaturityIndicator");

/**
 * Author: Jacob Blazina
 * Created At: N/A
 * Edits:
 *  - 3/30/20 JB: Added permission checking
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const SatisfyMaturityIndicatorButtons = (props) => {
  const module = modules.GOVERNANCE;
  const resource = resources.BLUEPRINT;

  const checkPermissionsHook = useCheckPermissions({ module, resource });

  const [isTrue, setIsTrue] = useState(props.maturityIndicator && props.maturityIndicator.isTrue === "true");

  const updateMaturityIndicator = (value) => {
    setIsTrue(value);

    props.updateMaturityIndicator({
      input: {
        id: props.maturityIndicator.id,
        isTrue: value,
      },
    });
  };

  const disabled = !checkPermissionsHook.resource.update;

  return (
    <>
      {isTrue === "true" || isTrue === true ? (
        <span className="float-right" style={{ color: "green" }}>
          &#10003;
        </span>
      ) : (
        <span className="float-right" style={{ color: "red" }}>
          &#10005;
        </span>
      )}
      {!disabled && (
        <ButtonGroup>
          <Button
            active={isTrue === "true"}
            color="success"
            onClick={() => updateMaturityIndicator("true")}
            disabled={disabled}
          >
            Yes
          </Button>
          <Button
            active={isTrue === "false"}
            color="danger"
            onClick={() => updateMaturityIndicator("false")}
            disabled={disabled}
          >
            No
          </Button>
        </ButtonGroup>
      )}
    </>
  );
};

SatisfyMaturityIndicatorButtons.propTypes = {
  maturityIndicator: PropTypes.object.isRequired,
};

export default compose(
  graphqlMutation(gql(updateMaturityIndicator), gql(getMaturityMatrix), "MaturityIndicatorHorizontal"),
)(SatisfyMaturityIndicatorButtons);
