import { Popover, PopoverBody, PopoverHeader } from "reactstrap";
import React, { useEffect, useRef, useState } from "react";

import CreateButton from "../../GenericComponents/CreateButton";
import { InfoLogger } from "../../EventLogger";
import OutsideAlerter from "../../../hooks/functional/useOutsideAlerter/components/OutsideAlerter";
import QuickPickTag from "../components/QuickPickTag";
import Tag from "../components/Tag";
import { cloneDeep } from "lodash";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useCheckPermissions } from "@hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useCreateTag } from "../hooks/useCreateTag";
import useDidMountEffect from "@hooks/functional/useDidMountEffect";
import { v4 as uuid } from "uuid";
import { withOrganizationCheck } from "../../Context/withOrganizationCheck";

/**
 * Displays a list of Tags for an item, and lets the user create a new tag or select an existing one to attach
 * @param {object} item - the parent object for tags
 * @param {object} tags - the tagLinks field from the parent object.
 * @param {function} [updateItemById] - a function for updating the parent item when a tag gets added or removed
 * @param {function} [onChangeLinksCallback] - callback for getting a new set of tag links, can be used for updating
 * the parent item that acts as a source of truth for a grid, such as with in Audit Details
 * @param {string} organizationID - the org ID
 * @param {string} module - the module to use for role checking
 * @param {string} resource - the resource used for role checking
 * @param {function} [getItemTagsQueryFunction] - optional override for the query function for getting the tags for the item
 * @param {boolean} disableRoleChecking - disables role checking
 * @param {function} createLinkFunction - custom function that takes in item, tag, orgId to create a new tag M-M link
 * @param {function} [deleteLinkFunction] - optional function to override the built-in delete function
 * @param {boolean} [isDisabled] - optional external flag for disabling the UI from a parent
 */
const TagsField = ({
  item,
  tags,
  updateItemById,
  onChangeLinksCallback,
  organizationID,
  module,
  resource,
  getItemTagsQueryFunction,
  disableRoleChecking = false,
  createLinkFunction,
  deleteLinkFunction,
  isDisabled,
}) => {
  const checkPermissions = useCheckPermissions({
    module,
    resource,
    disableRoleChecking,
  });

  /**
   * Array of tags, handles the Link traversal at start
   */
  const getTagItems = () => {
    const tagLinks = item?.tags?.items || tags?.items || [];
    const newTagItems = [];

    for (const tagLink of tagLinks) {
      newTagItems.push({
        ...tagLink.tag,
        tagLinkID: tagLink?.id,
        tagLinkTypename: tagLink?.__typename,
      });
    }

    return newTagItems;
  };

  const [tagItems, setTagItems] = useState(getTagItems());
  const tagItemsRef = useRef(null);
  useEffect(() => {
    if (tagItems) {
      tagItemsRef.current = tagItems;
    }
  }, [tagItems]);

  useDidMountEffect(() => {
    setTagItems(getTagItems());
  }, [tags]);

  /**
   * Convert an array from tag types to tag link types that are displayed in UI
   */
  const convertTagsToTagLink = (tags) => {
    const tagLinks = [];
    if (Array.isArray(tags)) {
      const currentTags = cloneDeep(tags);
      return currentTags.map((tag) => ({
        tag,
        id: tag?.tagLinkID,
        __typename: tag?.tagLinkTypename,
      }));
    }
    return tagLinks;
  };

  /**
   * Handles the UI update when a TagLink is deleted
   * @param {object} tag - the tag to delete should have the id property
   */
  const deleteCallback = (tag) => {
    let tempTags = [];
    if (Array.isArray(tagItems)) {
      tempTags = cloneDeep(tagItems);
    }

    const foundIndex = tempTags.findIndex((item) => item.id === tag.id);

    /**
     * Updates the parent list when a tag is deleted
     */
    if (foundIndex !== null && foundIndex !== undefined && foundIndex !== -1) {
      tempTags.splice(foundIndex, 1);
      setTagItems(tempTags);

      onChangeLinksCallback?.({
        gridItem: item,
        tagLinks: {
          items: convertTagsToTagLink(tempTags),
        },
      });

      updateItemById?.(
        {
          id: item.id,
          tags: {
            items: convertTagsToTagLink(tempTags),
          },
        },
        true, // save grid scroll position
      );
    }
  };

  /**
   * Handles the UI update when a TagLink is added
   * @param {object} tag - tag to add should have id property
   */
  const addCallback = (tag, isLastOne = false) => {
    if (!tag?.tagLinkID) {
      InfoLogger("Provided tag cannot be added to the list!");
      return;
    }

    let tempTags = [];
    if (Array.isArray(tagItems)) {
      tempTags = cloneDeep(tagItems);
    }
    const foundIndex = tempTags.findIndex((item) => item.id === tag.id);

    //Add a tag only if it's not already present
    if (foundIndex !== null && foundIndex !== undefined && foundIndex === -1) {
      if (!isNullOrUndefined(tag)) {
        tempTags.push(tag);
      }

      //NOTE: Needs to be above closing the popover
      setTagItems(tempTags);

      //Closes popover if the last tag was added (for convenience)
      if (isLastOne) {
        setPopoverIsOpen(false, tempTags);
      }
    }
  };

  const createCallback = () => {
    setPopoverIsOpen(false);
    createTag.modalHook.setModalIsOpen(true);
  };

  const [popoverIsOpen, setPopoverIsOpenNow] = useState(false);

  /**
   * State setter override designed so that the popper doesn't update grid before closing itself
   * @param isOpen - TRUE if opening the popper, FALSE if closing
   * @param tempTags - if provided will use this array to update the grid
   */
  const setPopoverIsOpen = (isOpen, tempTags) => {
    if (!isOpen) {
      let tagLinks = convertTagsToTagLink(tagItemsRef?.current);
      if (tempTags) {
        tagLinks = convertTagsToTagLink(tempTags);
      }

      onChangeLinksCallback?.({
        gridItem: item,
        tagLinks: {
          items: tagLinks,
        },
      });

      updateItemById?.(
        {
          id: item?.id,
          tags: {
            items: tagLinks,
          },
        },
        true, // save grid scroll position
      );
    }

    setPopoverIsOpenNow(isOpen);
  };

  const [id] = useState(uuid());

  const linkRef = useRef();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (linkRef.current) {
      setReady(true);
    }
  }, [linkRef.current]);

  const createTag = useCreateTag({
    organizationID,
  });

  const isUIDisabled = isDisabled || !checkPermissions.resource.update;

  return (
    <div style={{ overflow: "hidden" }}>
      {/*below is a placeholder div required for popover to appear in correct spot even after tag deletion*/}
      <div id={`popoverDiv${id}`} style={{ position: "absolute", height: "3em" }} ref={linkRef} />

      <div
        style={{ cursor: !isUIDisabled && "pointer" }}
        onClick={() => !isUIDisabled && setPopoverIsOpen((popoverIsOpen) => !popoverIsOpen)}
      >
        <TagsFieldList
          items={tagItems}
          deleteLinkFunction={deleteLinkFunction}
          deleteCallback={deleteCallback}
          isDisabled={isUIDisabled}
        />
      </div>
      {ready && (
        <Popover
          trigger="legacy"
          placement="left"
          isOpen={popoverIsOpen}
          toggle={() => popoverIsOpen && setPopoverIsOpen((popoverIsOpen) => !popoverIsOpen)}
          target={linkRef.current}
        >
          <OutsideAlerter onClickOutside={popoverIsOpen ? () => setPopoverIsOpen(false) : null}>
            <PopoverHeader>
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex", alignItems: "center" }}>Add a tag</div>
                <div style={{ flex: 1, textAlign: "right" }}>
                  <CreateButton onClick={createCallback} typename={"Tag"} />
                </div>
              </div>
            </PopoverHeader>
            <PopoverBody
              style={{
                maxHeight: "400px",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <QuickPickTag
                item={item}
                typename={resource}
                updateCallback={addCallback}
                createCallback={createCallback}
                itemTags={{ tags: item.tags }}
                createLinkFunction={createLinkFunction}
                getItemTagsQueryFunction={getItemTagsQueryFunction}
                organizationID={organizationID}
              />
            </PopoverBody>
          </OutsideAlerter>
        </Popover>
      )}
      {createTag.modalHook.modal}
    </div>
  );
};

/**
 * Displays the list of Tags for this item field
 * @param {object[]} items - current tag items to display in the list
 * @param {function} [deleteLinkFunction] - optional function to override the built-in delete function
 * @param {function} [deleteCallback] - optional function to be called after a tag is deleted
 * @param {boolean} isDisabled - disables tag selection and editing
 * @returns {JSX.Element}
 */
const TagsFieldList = ({ items, deleteLinkFunction, deleteCallback, isDisabled }) => {
  return (
    <div>
      {Array.isArray(items) && items?.length > 0 ? (
        items.map((tag) => (
          <Tag
            key={JSON.stringify(tag)}
            tag={tag}
            isSelected={true}
            enableDelete={true}
            deleteLinkFunction={deleteLinkFunction}
            deleteCallback={deleteCallback}
            isDisabled={isDisabled}
          />
        ))
      ) : (
        <p
          style={{ fontStyle: "italic", fontSize: "0.9em" }}
          title={isDisabled ? "You don't have permissions to update this resource" : "Click to Add a Tag"}
        >
          No Tags
        </p>
      )}
    </div>
  );
};

export default withOrganizationCheck(TagsField);
