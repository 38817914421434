import { ErrorLogger } from "@utils/EventLogger";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";

export const updateSystem = `mutation UpdateSystem($input: UpdateSystemInput!) {
  updateSystem(input: $input) {
    id
    monteCarloInputS3ObjectKey
  }
}
`;

export const clearCachedCalculation = async (system) => {
  try {
    return await ItemMutation(updateSystem, {
      id: system.id,
      monteCarloInputS3ObjectKey: null,
    });
  } catch (e) {
    ErrorLogger("Error: Could not clear cached calculation. ", e);
  }
};
