import { useModal } from "../../../../hooks/views/useModal";
import { useClassificationGrid } from "../../Plan/Classifications/hooks/useClassificationGrid";
import React, { useEffect, useState } from "react";
import { useForm } from "../../../../hooks/views/useForm/useForm";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";
import EditButton from "../../../../utils/GenericComponents/buttons/EditButton";

/**
 * Used to modify the an incident's classification tier
 * @param {string} organizationID - the organization id of the incident
 * @param {object} item - the incident object from the database (must include id)
 * @param {function} resetFunction - function to call to update UI once mutation takes place
 * @return {{setModalIsOpen: function(*=): void, modalButton: *, modalIsOpen: boolean, modal: *}}
 */
export const useEditIncidentClassification = ({ organizationID, item, resetFunction }) => {
  const [classificationID, setClassificationID] = useState(item?.classificationTierID);
  const module = modules.INCIDENT_RESPONSE;
  const resource = resources.INCIDENT;

  const classifications = useClassificationGrid({ organizationID });
  const { updateMutation } = generateGraphql("Incident");

  useEffect(() => {
    setClassificationID(item?.classificationTierID);
    editForm.setInput({ classificationTierID: item?.classificationTierID });
  }, [item]);

  const editForm = useForm({
    item,
    module,
    resource,
    mutation: updateMutation,
    organizationID,
    toggleModal: () => editModal.setModalIsOpen(false),
    resetFunction,

    fieldConfig: {
      classificationTierID: {
        label: "Classification Tier",
        tooltip: "How severe is this Incident? This determines which Response Teams should be involved",
        inputType: "dropdown",
        defaultValue: classificationID,
        dropdownConfig: {
          data: classifications?.data?.map((item) => {
            return {
              text: `${item.level} - ${item.name}`,
              value: item.id,
            };
          }),
        },
      },
    },
  });

  const editModal = useModal(
    "Edit Selected Classification Tier",
    editForm.display,
    <EditButton
      data-testid={"button-edit-field-classification-tier"}
      color="ghost-warning"
      title={`Edit classification tier`}
    />,
  );

  return { ...editModal };
};
