import { getIcon, IconName } from "@components/Ions/icons/components/Icons";
import { ReactElement } from "react";
import Tooltip, { ArrowPosition, TooltipPlacement } from "@components/Atoms/Tooltip/Tooltip";

export interface TooltipIconProps {
  icon: ReactElement | undefined;
  message: string;
  className?: string;
  tooltipContainerClassName?: string;
  placement?: TooltipPlacement;
  arrowPosition?: ArrowPosition;
}

/**
 * Displays an icon with a tooltip when it is hovered over
 */
export const TooltipIcon = ({
  icon = getIcon(IconName.INFO),
  message,
  className,
  tooltipContainerClassName,
  placement = TooltipPlacement.TOP,
  arrowPosition = ArrowPosition.MIDDLE,
}: TooltipIconProps): ReactElement => {
  return (
    <Tooltip
      containerClassname={tooltipContainerClassName}
      tooltipContent={message}
      placement={placement}
      arrowPosition={arrowPosition}
    >
      <span className={className}>{icon}</span>
    </Tooltip>
  );
};
