import React, { useEffect, useState } from "react";
import { useRoleList } from "../../Roles/hooks/useRoleList";
import { useControlSetSelection } from "../../../Compliance/Controls/ControlSets/functions/useControlSetSelection";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import { createUser } from "../functions/createUser";
import { CheckPhoneNumberRegex } from "../../../../utils/Regex/Regex";

const AddSingleUser = (props) => {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [validPhoneNumber, setValidPhoneNumber] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [accountType, setAccountType] = useState("standard");
  const [selectedControlSet, setSelectedControlSet] = useState(null);
  const [startDate, setStartDate] = useState(new Date().setDate(new Date().getDate() - 365));

  const roleList = useRoleList({
    organizationID: props.organizationID,
    isAdminRole: false,
  });

  useEffect(() => {
    roleList.setShowSelectBoxes(true);
  }, []);

  useEffect(() => {
    setValidPhoneNumber(CheckPhoneNumberRegex(phoneNumber));
  }, [phoneNumber]);

  useEffect(() => {
    const emailRegex = new RegExp(
      '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
    );
    const results = emailRegex.test(email);
    setValidEmail(results);
  }, [email]);

  const controlSetSelection = useControlSetSelection(props.organizationID);

  useEffect(() => {
    setSelectedControlSet(controlSetSelection.selectedControlSet);
  }, [controlSetSelection.selectedControlSet]);

  const config = {
    startDate,
    controlSet: selectedControlSet,
  };

  const isSubmitEnabled =
    name && password && password.length > 7 && email && validEmail && phoneNumber && validPhoneNumber;

  return (
    <Container>
      <Row>
        <Col sm={4}>
          <Label for="name">
            Account Type: <span style={{ color: "red" }}>*</span>
          </Label>
        </Col>
        <Col sm={8}>
          <Input
            type="select"
            id="accountType"
            value={accountType}
            onChange={(data) => setAccountType(data && data.target && data.target.value)}
            required
          >
            <option value="standard">Standard</option>
            <option value="examiner">Examiner</option>
          </Input>
        </Col>
      </Row>
      <br />
      {accountType === "examiner" && (
        <>
          <Row>
            <Col sm={4}>
              <Label for="name">
                Control Set: <span style={{ color: "red" }}>*</span>
              </Label>
            </Col>
            <Col sm={8}>{controlSetSelection.display}</Col>
          </Row>
          <br />
          <Row>
            <Col sm={4}>
              <Label for="name">
                Document Start Date: <span style={{ color: "red" }}>*</span>
              </Label>
            </Col>
            <Col sm={8}>
              <Input
                type="date"
                id="startDate"
                value={startDate}
                onChange={(data) => setStartDate(data && data.target && data.target.value)}
                required
              />
            </Col>
          </Row>
          <br />
        </>
      )}
      <Row>
        <Col sm={4}>
          <Label for="name">
            Name: <span style={{ color: "red" }}>*</span>
          </Label>
        </Col>
        <Col sm={8}>
          <Input
            type="text"
            id="name"
            placeholder="First and Last Name"
            value={name}
            onChange={(data) => setName(data && data.target && data.target.value)}
            required
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col sm={4}>
          <Label for="password">
            Password: <span style={{ color: "red" }}>*</span>
          </Label>
        </Col>
        <Col sm={8}>
          <Input
            type="password"
            id="password"
            placeholder="Minimum length 8"
            value={password}
            onChange={(data) => setPassword(data && data.target && data.target.value)}
            required
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col sm={4}>
          <Label for="email">
            Email: <span style={{ color: "red" }}>*</span>
          </Label>
        </Col>
        <Col sm={8}>
          <Input
            type="email"
            id="email"
            value={email}
            onChange={(data) => setEmail(data && data.target && data.target.value)}
            required
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col sm={4}>
          <Label for="phone_number">
            Phone Number: <span style={{ color: "red" }}>*</span>
          </Label>
        </Col>
        <Col sm={8}>
          <Input
            type="text"
            id="phone_number"
            placeholder="+1XXXXXXXXXX"
            value={phoneNumber}
            onChange={(data) => setPhoneNumber(data && data.target && data.target.value)}
            required
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col sm={4}>Select One Role:</Col>
        <Col sm={8}>{roleList.display}</Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Button
            disabled={!isSubmitEnabled}
            className="float-sm-right"
            onClick={() =>
              createUser({
                name,
                password,
                email,
                phoneNumber,
                organizationID: props.organizationID,
                accountType,
                config,
                role: roleList.selectedItems[0] ? roleList.selectedItems[0] : undefined,
              })
            }
            size="sm"
            color="primary"
          >
            <i className="fa fa-dot-circle-o" /> Submit
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default AddSingleUser;
