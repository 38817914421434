import { useControlDetails } from "../../Compliance/Controls/Controls/hooks/useControlDetails/useControlDetails";
import { withDetailsPage } from "../../../utils/Context/withDetailsPage";
import { modules, resources } from "@rivial-security/role-utils";

/**
 * Displays the details component of a Control Template
 * @param {string} organizationID - the organization/operation team that the control is a part of
 * @param {string} module=modules.ADMINISTRATOR  - platform module for role checking
 * @param {string} resource=resources.CONTROL_TEMPLATE - platform resource for role checking
 * @param {boolean} disableRoleChecking=false - if TRUE will disable role checking
 * @param {object} [item] - the control object (with id property)
 * @param {string} itemId - the control id
 * @return {JSX.Element}
 */
const ControlTemplateDetails = ({
  organizationID,
  module = modules.ADMINISTRATOR,
  resource = resources.CONTROL_TEMPLATE,
  disableRoleChecking = false,
  item,
  itemId,
}) => {
  const detailsHook = useControlDetails({
    organizationID,
    module,
    resource,
    disableRoleChecking,
    isTemplate: true,

    itemId: itemId || item?.id,
    hiddenFields: ["changes", "tags"],
  });

  return detailsHook.display;
};

export default withDetailsPage(ControlTemplateDetails);
