import { useInformationAssetDetails } from "../hooks/useInformationAssetDetails";
import { withDetailsPage } from "../../../../../utils/Context/withDetailsPage";

/**
 * Component instance of the useInformationAssetDetails hook
 * @param props
 * @returns {*}
 * @constructor
 */
const InformationAssetDetails = (props) =>
  useInformationAssetDetails(props)[props.tableDisplay ? "tableDisplay" : "display"];

export default withDetailsPage(InformationAssetDetails);
