import React from "react";
import { Input } from "reactstrap";
import GenericEditField from "../../../../../utils/GenericComponents/GenericEditFieldV2";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

/**
 *
 * @param item
 * @return {JSX.Element}
 * @constructor
 */
const AssessmentDetailsAssessmentScanner = ({ item }) => {
  const module = modules.VULNERABILITIES;
  const resource = resources.ASSESSMENT;

  const { updateMutation: updateAssessment } = generateGraphql("Assessment", ["scanner"]);

  return (
    <GenericEditField
      item={item}
      module={module}
      resource={resource}
      field="scanner"
      mutation={updateAssessment}
      inputType="dropdown"
      dropdownInput={
        <Input type="select" value={item.scanner}>
          <option value={""}>None</option>
          <option value="nessus">Nessus</option>
          <option value="openvas">Openvas</option>
          <option value="rapid7">Rapid7</option>
          <option value="qualys">Qualys</option>
          <option value="other">Other</option>
        </Input>
      }
    />
  );
};

export default AssessmentDetailsAssessmentScanner;
