import { useCreateAutomationStep } from "../hooks/useCreateAutomationStep";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * Component instance of the useCreateAutomationStep hook
 * @param props
 * @returns {*}
 * @constructor
 */
const CreateAutomationStep = (props) => {
  return useCreateAutomationStep(props).display;
};

export default withOrganizationCheck(CreateAutomationStep);
