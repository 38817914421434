import { GetQuery, ItemMutation, ListQueryBy } from "@rivial-security/appsync-utils";

import { ErrorLogger } from "@utils/EventLogger";
import { SystemTag } from "@rivial-security/definition-utils";
import { TargetVulnerabilityStatus } from "@rivial-security/schema-types";
import { generateGraphql } from "@rivial-security/generategraphql";
import { isNonEmptyArray } from "@rivial-security/func-utils";

/**
 * Checks if the status of target vulnerability link should change, adds/removes manual fix system tag, updates history
 * @param {object} oldItem - item with status and targetFindingLinkID fields of the link before the change
 * @param {object} newItem - item with status and targetFindingLinkID fields of the link after the change
 * @return {Promise<void>}
 */
export const updateManualFixTagConnection = async ({ oldItem, newItem }) => {
  //Check for the existence of the tag in the organization (create one if it's missing)
  const organizationID = oldItem?.ownerGroup || newItem?.ownerGroup;
  const targetVulnerabilityLinkID = oldItem?.targetFindingLinkID;
  let manualFixTag;
  try {
    const organizationTags = await ListQueryBy({
      query: tagsByOwnerGroupSystemTag,
      variables: {
        ownerGroup: organizationID,
        systemTagID: { eq: SystemTag.MANUAL_FIX },
      },
    });

    if (!isNonEmptyArray(organizationTags)) {
      throw Error("Cannot find any manual fix system tags!");
    }

    manualFixTag = organizationTags?.find((organizationTag) => {
      return organizationTag?.systemTagID === SystemTag.MANUAL_FIX;
    });
  } catch (e) {
    ErrorLogger("Unable to retrieve a required system tag, will try to create one");
  }
  if (!manualFixTag?.id) {
    manualFixTag = await ItemMutation({
      mutation: createTagMutation,
      input: {
        name: "Manually Fixed",
        systemTagID: SystemTag.MANUAL_FIX,
        ownerGroup: organizationID,
        type: "system",
      },
    });
  }

  //Check for the existence of the tag on the target vulnerability link
  const itemWithTags = await GetQuery({
    query: getTargetFindingLinkQuery,
    variables: { id: targetVulnerabilityLinkID },
  });
  const itemManualFixTagLink = itemWithTags?.tags?.items.find((tagLink) => {
    return tagLink?.tagID === manualFixTag.id;
  });

  //Determine if we need to make changes
  const oldStatus = oldItem?.status;
  const newStatus = newItem?.status;
  const needToAddTag =
    oldStatus !== TargetVulnerabilityStatus.FIXED &&
    newStatus === TargetVulnerabilityStatus.FIXED &&
    !itemManualFixTagLink;
  const needToRemoveTag =
    oldStatus === TargetVulnerabilityStatus.FIXED &&
    newStatus !== TargetVulnerabilityStatus.FIXED &&
    itemManualFixTagLink;

  //Perform operations to add or remove the tag
  if (needToAddTag && manualFixTag?.id) {
    await ItemMutation({
      mutation: createTargetFindingTagLink,
      input: {
        tagID: manualFixTag?.id,
        targetVulnerabilityLinkID,
        ownerGroup: organizationID,
      },
    });
  } else if (needToRemoveTag && itemManualFixTagLink?.id) {
    await ItemMutation({
      mutation: deleteTargetFindingTagLink,
      input: {
        id: itemManualFixTagLink?.id,
      },
    });
  }
};

const { getQuery: getTargetFindingLinkQuery } = generateGraphql("TargetFindingLink", ["tags"], {
  tags: `(limit: 100) {items { id ownerGroup tagID }}`,
});
const { createMutation: createTagMutation } = generateGraphql("Tag", ["systemTagID"]);

const { createMutation: createTargetFindingTagLink, deleteMutation: deleteTargetFindingTagLink } =
  generateGraphql("TargetVulnerabilityTagLink");

const { createMutation: createTargetVulnerabilityHistory } = generateGraphql("TargetVulnerabilityHistory", [
  "id",
  "type",
  "date",
  "tagID",
  "comment",
  "targetVulnerabilityLinkID",
  "oldStatus",
  "newStatus",
]);

const tagsByOwnerGroupSystemTag = /* GraphQL */ `
  query TagsByOwnerGroupSystemTag(
    $ownerGroup: String
    $systemTagID: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tagsByOwnerGroupSystemTag(
      ownerGroup: $ownerGroup
      systemTagID: $systemTagID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerGroup
        systemTagID
      }
      nextToken
    }
  }
`;
