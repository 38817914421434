import { ButtonGroup } from "reactstrap";
import React from "react";

/**
 * @description Grid header buttons
 * @param {JSX[]} headerButtons - JSX buttons array to display on top of the grid
 * @return {JSX.Element}
 * @constructor
 */
const GridHeaderButtons = ({ headerButtons = [] }) => {
  return (
    <>
      {headerButtons && Array.isArray(headerButtons) && headerButtons.length > 0 && (
        <div style={{ height: "2em" }}>
          <ButtonGroup className="float-right">
            {headerButtons.map((button, index) => React.cloneElement(button, { key: index }))}
          </ButtonGroup>
        </div>
      )}
    </>
  );
};

export default GridHeaderButtons;
