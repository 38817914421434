import FindingsCard from "../../../Program/Findings/components/FindingsCard/FindingsCard";
import React from "react";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * Displays Observations, Recommendations, and Action Items for an Evidence
 * @param item
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const EvidenceFindingsCard = ({ item, ...props }) => {
  return <FindingsCard evidence={item} {...props} />;
};

export default withOrganizationCheck(EvidenceFindingsCard);
