import { useState } from "react";
import { NavigationBarPage } from "@components/Molecules/NavigationBar/types";
import { useSetAsyncData } from "@hooks/functional/useSetAsyncData";
import { ListQueryBy } from "@rivial-security/appsync-utils";
import { Tool } from "@rivial-security/schema-types";
import { generateGraphql } from "@rivial-security/generategraphql";
import { getCustomToolNavigationBarName } from "@application/Layout/hooks/useNavigationLinks/functions/getCustomToolNavigationBarName";

export interface UseCustomToolLinksParams {
  selectedOrganization?: string;
}

export interface UseCustomToolLinksResult {
  customToolLinks: NavigationBarPage[];
}

export const useCustomToolLinks = ({ selectedOrganization }: UseCustomToolLinksParams): UseCustomToolLinksResult => {
  const [customToolLinks, setCustomToolLinks] = useState<NavigationBarPage[]>([]);
  useSetAsyncData({
    getData: async () => {
      if (!selectedOrganization) {
        return [];
      }

      const customTools = await ListQueryBy<Tool>({
        query: generateGraphql(
          "Tool",
          ["type", "name", "description"],
          {},
          {
            indexName: "toolsByOwnerGroupByType",
            partitionKey: "ownerGroup",
            partitionKeyType: "String",
            sortKey: "type",
            sortKeyType: "ModelStringKeyConditionInput",
          },
        ).listByQuery,
        variables: {
          ownerGroup: selectedOrganization,
          type: { eq: "custom" },
        },
      });
      if (!customTools) {
        return [];
      }

      const newCustomToolLinks: NavigationBarPage[] = [];
      for (const customTool of customTools) {
        if (!customTool?.id || !customTool?.name) {
          continue;
        }

        newCustomToolLinks.push({
          link: `/integrations/dashboard/${customTool?.id}`,
          name: getCustomToolNavigationBarName(customTool?.name),
        });
      }
      return newCustomToolLinks;
    },
    setData: setCustomToolLinks,
    dependencies: [selectedOrganization],
    defaultValue: [],
  });

  return {
    customToolLinks,
  };
};
