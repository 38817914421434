import { enumToDropdownData } from "@rivial-security/func-utils";
import { numberFormatTypes } from "../constants/numberFormatTypes";
import { useForm } from "../../../../hooks/views/useForm";

/**
 * A form for number settings for numeric custom fields
 * @param {function} onChangeCallback - callback when form input changes
 * @param {object} item - the initial state of the form
 * @returns {*}
 */
const NumberSettingsInputs = ({ onChangeCallback, item }) => {
  const form = useForm({
    item: item?.numberSettings,
    disableRoleChecking: true,
    disableSubmitButton: true,
    disableResetButton: true,

    fieldConfig: {
      min: {
        label: "Minimum value",
        tooltip: "The lowest value allowed for this numeric field",
        inputType: "number",
      },
      max: {
        label: "Maximum value",
        tooltip: "The highest value allowed for this numeric fields",
        inputType: "number",
      },
      step: {
        label: "Step",
        tooltip: "The increment between values allowed for this numeric field",
        inputType: "number",
      },
      format: {
        label: "Format",
        tooltip: "Visual style to apply onto the provided value",
        inputType: "dropdown",
        defaultValue: numberFormatTypes.NUMBER,
        dropdownConfig: {
          data: enumToDropdownData({ ENUM: numberFormatTypes }),
        },
        required: true,
      },
    },
    onChange: (input) => {
      if (input?.min === "") {
        input.min = undefined;
      }

      if (input?.max === "") {
        input.max = undefined;
      }

      if (input?.step === "") {
        input.step = undefined;
      }

      if (typeof onChangeCallback === "function") {
        onChangeCallback(input);
      }
    },
  });

  return form.display;
};

export default NumberSettingsInputs;
