import React, { useMemo } from "react";
import RGL, { WidthProvider } from "react-grid-layout";
import RenderWidget from "../../CustomWidgets/WidgetRenderer";
import CustomDashboardWidgetButtons from "../components/CustomDashboardWidgetButtons";
import { CONTEXT_TYPE } from "@rivial-security/widget-utils";

// Instantiate the RGL provider
const ReactGridLayout = WidthProvider(RGL);

// Default props to pass to RGL
const defaultProps = {
  className: "layout",
  items: 20,
  rowHeight: 30,
  onLayoutChange: () => {},
  cols: 12,
};

/**
 * Displays a single layout of widgets using the dashboard builder
 *
 * @param {object} layout - the layout object from the CustomDashboard schema
 * @param {string} organizationID - the organizationID of the current CustomDashboard object
 * @param {function} removeWidget - the removeWidget function from the useCustomDashboardData hook
 * @param {boolean} isEditMode - whether or not the dashboard is in edit mode
 * @param {boolean} disableAutoSave - whether or not to disable auto-saving
 * @param {object} addWidgetModal - reference to useModal hook for adding widgets
 * @param {function} save - the save function from the useCustomDashboardData hook
 * @param {object[]} layouts - the layouts array from the CustomDashboard schema. Used as source of truth for state
 * @param {boolean} isTemplate - whether the tool is a template
 * @param {string} layout.id - the ID of the layout
 * @param {string} layout.name - the name of the layout
 * @param {object[]} layout.elements - the elements of the layout
 * @param {string} layout.elements[].id - the ID of the element
 * @param {int} layout.elements[].x - the x position of the element
 * @param {int} layout.elements[].y - the y position of the element
 * @param {int} layout.elements[].width - the width of the element
 * @param {int} layout.elements[].height - the height of the element
 * @param {string} layout.elements[].widgetID - the ID of the widget related to the element
 *
 * @returns {{display: JSX.Element, currentDashboardLayout: *[]}}}
 * @constructor
 */
export const useCustomDashboardLayoutDisplay = ({
  layout,
  organizationID,
  removeWidget,
  isEditMode,
  disableAutoSave = false,
  addWidgetModal,
  save,
  layouts,
  isTemplate,
}) => {
  const [dataKey, setDataKey] = React.useState(0);
  // memoize the current layout so we don't have to re-render the layout unless the layouts array changes
  const currentLayout = useMemo(() => layouts?.find((l) => l.id === layout?.id), [layouts, layout?.id]);

  const { items, onLayoutChange, ...restProps } = { ...defaultProps };

  /**
   * Handles the layout change event from the react-grid-layout
   * @param {object[]} updatedElements
   */
  const handleLayoutChange = (updatedElements) => {
    if (JSON.stringify(currentLayout.elements) !== JSON.stringify(updatedElements) && !disableAutoSave) {
      // auto-saves the layout to the backend (note: the save function itself checks for equality before saving)
      save({ layoutID: layout.id, elements: updatedElements });

      // callback function with updated elements
      onLayoutChange(updatedElements);

      // increment the dataKey to force a re-render of the widgets when resizing
      setDataKey(dataKey + 1);
    }
  };

  /**
   * Maps the backend layout elements to the expected react-grid-layout format (i.e. i, w, h, x, y)
   * @param items
   * @returns {*[]}
   */
  const mapBackendItemsToLayoutItems = (items) => {
    return items.map((item) => ({
      // properties for RGL
      i: item.id,
      w: item.width || 12,
      h: item.height || 8,
      x: item.x,
      y: item.y,

      widgetID: item.widgetID,
    }));
  };

  const mapLayoutItemsToBackendItems = (items) => {
    return items.map((item) => ({
      id: item.i,
      width: item.w,
      height: item.h,
      x: item.x,
      y: item.y,

      // custom properties for widget renderer
      widgetID: currentLayout?.elements?.find((e) => e.id === item.i)?.widgetID || item.i,
    }));
  };

  const display = (
    <>
      <div style={{ height: "100%" }}>
        {Array.isArray(currentLayout?.elements) && currentLayout?.elements?.length > 0 ? (
          <ReactGridLayout
            layout={mapBackendItemsToLayoutItems(currentLayout.elements)}
            onLayoutChange={(items) => handleLayoutChange(mapLayoutItemsToBackendItems(items))}
            isDraggable={isEditMode}
            isResizable={isEditMode}
            verticalCompact={true}
            preventCollision={false}
            autoSize={true}
            margin={[10, 10]}
            resizeHandles={["s", "w", "e", "n", "sw", "nw", "se", "ne"]}
            {...restProps}
          >
            {mapBackendItemsToLayoutItems(currentLayout?.elements)?.map((item) => {
              return (
                <div key={item.i} data-grid={item}>
                  <div
                    className="e-card-content"
                    style={{
                      height: "100%",
                      padding: "0.5rem",
                      border: isEditMode ? "2px dashed #ccc" : undefined,
                    }}
                  >
                    {isEditMode && (
                      <CustomDashboardWidgetButtons
                        onRemoveWidget={removeWidget}
                        item={item}
                        className={"float-right"}
                      />
                    )}
                    <RenderWidget
                      itemId={item?.widgetID}
                      organizationID={organizationID}
                      autoRender={true}
                      singleViewType={CONTEXT_TYPE.WEB}
                      persistenceUUID={`w-${item.i}${layout?.id}`}
                      style={{ height: "100%", width: "100%" }}
                      key={dataKey}
                    />
                  </div>
                </div>
              );
            })}
          </ReactGridLayout>
        ) : (
          <h6>
            This page is empty. Turn on <strong>Edit Mode</strong> and click the <strong>Add Widget</strong> button to
            get started.
          </h6>
        )}
      </div>
    </>
  );
  return {
    display,
  };
};
