import { useEffect, useState } from "react";

import { realTimeRiskGQL } from "@rivial-security/risk-calc-utils";
import { useQueryGetItem } from "../../../../../../hooks/graphql/useQueryGetItem";

/**
 * Queries system details and sets some handler functions
 * @param itemId
 * @param module
 * @param resource
 * @param setQueryRef
 * @param setIsLoading
 * @param updateItemByIdFunc
 */
export const useSystemDetailsQuery = ({ itemId, module, resource, setQueryRef, setIsLoading, updateItemByIdFunc }) => {
  const systemDetails = useQueryGetItem({
    query: realTimeRiskGQL.getSystem_systemDetails,
    itemId: itemId,
    module,
    resource,
  });

  const [resetKey, setResetKey] = useState(0);

  useEffect(() => {
    setQueryRef && setQueryRef(systemDetails);
  }, []);

  useEffect(() => {
    setIsLoading && setIsLoading(systemDetails.isLoading);
  }, [systemDetails.isLoading]);

  const updateItemById = (item) => {
    systemDetails.setItem((system) => {
      return {
        ...system,
        ...item,
      };
    });

    updateItemByIdFunc && updateItemByIdFunc(item);
  };

  const resetFunction = (resetKey = true) => {
    systemDetails.reset && systemDetails.reset();
    if (resetKey) {
      setResetKey((resetKey) => resetKey + 1);
    }
  };

  return {
    updateItemById,
    resetKey,
    setResetKey,
    systemDetails,
    isLoading: systemDetails?.isLoading,
    resetFunction,
  };
};
