import React from "react";
import PropTypes from "prop-types";

/**
 * @description The ProgressText component is used to display the progress of the long process.
 * @param {object} props - The props of the component.
 * @returns {JSX.Element}
 * @constructor
 */
export const ProgressText = (props) => (
  <ul>
    {props.progressText.map((text, index) => (
      <li key={index}>{text}</li>
    ))}
  </ul>
);

ProgressText.propTypes = {
  progressText: PropTypes.array.isRequired,
};
