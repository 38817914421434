import { ErrorLogger } from "@utils/EventLogger";
import { GetQuery } from "../../../../../../utils/Functions/Graphql/GetQuery";

/**
 * @description Get operation teams for an organization
 * @param {object} input - input for operationTeamsForOrganization lambda
 * @returns {Promise<Array>}
 */
export const getOperationTeamsForOrganization = async ({ input }) => {
  if (!input) {
    ErrorLogger("Missing input for getOperationTeamsForOrganization.js");
    return [];
  }

  const operationTeamsForOrganization = /* GraphQL */ `
    query OperationTeamsForOrganization($input: AWSJSON) {
      operationTeamsForOrganization(input: $input)
    }
  `;

  return await GetQuery({
    query: operationTeamsForOrganization,
    variables: {
      input: JSON.stringify(input),
    },
  })
    .then((data) => {
      try {
        const results = JSON.parse(data);

        if (Array.isArray(results)) {
          return results;
        } else {
          ErrorLogger("Can not get operation teams list", results);
        }
      } catch (e) {
        ErrorLogger("Can not parse operation Teams for the lambda", e);
      }
    })
    .catch((e) => ErrorLogger("Error in getOperationTeamsForOrganization.js", e));
};
