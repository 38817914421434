import { InfoLogger } from "@utils/EventLogger";
import { autoDetectColumns } from "../functions/autoDetectColumns";
import { useEffect } from "react";

/**
 * Hook that runs the auto-detect function when the current CSV headers change
 * @param {ImporterField[]} fields - the Available Fields for a particular import
 * @param {string[]} currentHeaders - the current CSV headers from the input file
 * @param {object} columnMap - mapping between CSV Headers and Available Fields
 * @param {function} setColumnMapping - state setter function for column mapping
 * @param {function} resetColumnMapping - reset the current column mapping to have no columns link to fields
 */
export const useAutoDetectColumns = (fields, currentHeaders, columnMap, setColumnMapping, resetColumnMapping) => {
  // When the CSV input file headers get added, auto-detect columns
  useEffect(() => {
    InfoLogger("CSV File Headers detected, auto-detecting columns..");

    autoDetectColumns(fields, currentHeaders, columnMap, setColumnMapping, resetColumnMapping);
  }, [currentHeaders]);
};
