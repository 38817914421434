import React, { useState } from "react";
import { tryParse, updateObjectInArray } from "@rivial-security/func-utils";

import CustomQueryDataGrid from "../../../CustomQueries/components/CustomQueryDataGrid";
import { ItemQuery } from "../../../../utils/Functions/Graphql/ItemQuery";
import { generateGraphql } from "@rivial-security/generategraphql";
import { tryFunction } from "../../../../utils/Functions/tryFunction";
import { useForm } from "../../../../hooks/views/useForm";
import { useResultFieldViewer } from "../../../CustomQueries/hooks/useResultFieldViewer";
import { useSetAsyncData } from "../../../../hooks/functional/useSetAsyncData";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * UI for configuring the AutomationStep of a 'customQuery'
 *
 * Allows the user to select a Custom Query, this query will be run as an Automation step and the results will be used
 * in subsequent steps.
 *
 * Custom Queries are primarily designed to be used with loop steps, but can be used with any step.
 *
 * @param {function} toggleModal - function callback to close the containing modal
 * @param {object} automations - the current automation step info for the parent node
 * @param {function} setAutomations - function to update automation data in the parent when its updated here
 * @param {AutomationStep} item - the automation data to edit/create
 * @param {object} fieldConfig - the generic fields that are used by all automation steps
 * @param {string} organizationID - the ID of the organization
 * @param {AutomationStep} automationStep - the automationStep that is being edited
 * @returns {JSX.Element}
 */
const ConfigureCustomQueryAutomation = ({
  toggleModal,
  automations,
  setAutomations,
  automationStep,
  fieldConfig,
  organizationID,
}) => {
  const genericFieldsForm = useForm({
    disableResetButton: true,
    disableSubmitButton: true,
    disableRoleChecking: true,
    fieldConfig,
    formID: "a395a619-8f88-4c97-84cf-111a3a5b7642",
    item: automationStep,
  });

  const [customQueryPreview, setCustomQueryPreview] = useState(null);

  const onFormSubmit = async (formInput) => {
    const newAutomations = [...(automations || [])];
    updateObjectInArray(automations, {
      id: automationStep.id,
      name: genericFieldsForm?.input.name,
      description: formInput?.customQueryName,
      config: {
        ...automationStep.config,
        customQueryID: formInput.customQueryID,
      },
      isNew: false,
    });
    setAutomations(newAutomations);
    tryFunction(toggleModal);
  };

  // - UI for viewing and selecting out the created result fields
  const resultFieldViewer = useResultFieldViewer({
    resultFields: customQueryPreview?.queryConfig?.fields,
    resultFunctions: customQueryPreview?.queryConfig?.functions,
  });

  const form = useForm({
    disableResetButton: true,
    disableRoleChecking: true,
    fieldConfig: {
      customQueryID: {
        label: "Choose a Custom Query",
        inputType: "item-select",
        itemSelectConfig: {
          typename: "CustomQuery",
          grid: <CustomQueryDataGrid organizationID={organizationID} />,
        },
        defaultValue: automationStep?.config?.customQueryID,
      },
      customQueryName: {
        label: "Custom Query Name",
        inputType: "text",
        disabled: true,
      },
      preview: {
        label: "Preview",
        inputType: "custom",
        removeFromInput: true,
        customConfig: {
          component: (
            <div>
              Custom Query Preview
              {resultFieldViewer.display}
            </div>
          ),
        },
      },
    },
    submitFunction: onFormSubmit,
    organizationID,
  });

  useSetAsyncData({
    getData: async () => {
      const { getQuery } = generateGraphql("CustomQuery", ["name", "description", "graphql", "fields", "queryConfig"]);

      const data = await ItemQuery(getQuery, form.input.customQueryID);
      return data;
    },
    setData: (data) => {
      setCustomQueryPreview({
        name: data.name,
        description: data.description,
        fields: data.fields,
        graphql: data.graphql,
        queryConfig: tryParse(data?.queryConfig),
      });

      form.setInput((input) => ({
        ...input,
        customQueryName: data.name,
      }));
    },

    dependencies: [form.input.customQueryID],
  });

  return (
    <div>
      {genericFieldsForm.display}
      {form.display}
    </div>
  );
};

export default withOrganizationCheck(ConfigureCustomQueryAutomation);
