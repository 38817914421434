import { ErrorLogger, InfoLogger } from "@utils/EventLogger";

import { ItemMutation } from "../../../../../utils/Functions/Graphql/ItemMutation";
import { QueryGetItem } from "../../../../../hooks/graphql/useQueryGetItem";
import { UPDATE_STEP_OPERATION } from "../../../../../hooks/views/useChecklist/useChecklistV2";
import { cloneDeep } from "lodash";
import { generateGraphql } from "@rivial-security/generategraphql";
import { getProceduresNestedFields } from "../../../PlaybookBuilder/functions/getProceduresNestedFields";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { locationType } from "../../../../../analytics/constants/locationType";
import { operationType } from "../../../../../analytics/constants/operationType";
import { reasonType } from "../../../../../analytics/constants/reasonType";

/**
 * Performs changes on the linked parent when a procedure in incident or exercise is modified
 * @param {string} parentID - database id of the item holding the procedure groups data
 * @param {string} parentTypename - the typename of the item holding procedure group data
 * @param {string} procedureName - the procedure type within a procedure group that the steps is in (analyze, detect, etc.)
 * @param {object} updatedStep - all of the new step data, null for when there's a delete operation
 * @param {string} updatedStepID - the steps unique id that can be used to match steps from child to parent
 * @param {string} updateOperation - the type of operation "add, edit, delete" that was performed on the step
 */
export const handleUpstreamProcedureChanges = async ({
  parentID,
  parentTypename,
  procedureName,
  updatedStep,
  updatedStepID,
  updateOperation,
}) => {
  //Check arguments
  if (!parentID || !parentTypename || !procedureName || !updatedStepID || !updateOperation) {
    ErrorLogger("Invalid arguments when trying to handle upstream procedure changes!", {
      location: locationType.FUNCTION,
      operation: operationType.UPDATE,
      reason: reasonType.INVALID_PARAM,
    });
    return;
  }

  //Query the parent for its response steps
  const { getQuery, updateMutation } = generateGraphql(parentTypename, ["procedures"], getProceduresNestedFields());
  const parentItem = await QueryGetItem({
    query: getQuery,
    itemId: parentID,
  });

  //Finds the step on the parent
  let groupIndex = -1;
  let stepIndex = -1;
  if (Array.isArray(parentItem?.procedures)) {
    const procedures = parentItem?.procedures;
    let groupCount = 0;
    for (const group of procedures) {
      if (group?.hasOwnProperty(procedureName) && Array.isArray(group[procedureName].responseSteps)) {
        const steps = group[procedureName].responseSteps;
        let stepCount = 0;
        for (const step of steps) {
          if (step?.id === updatedStepID) {
            groupIndex = groupCount;
            stepIndex = stepCount;
            break;
          }
          stepCount++;
        }
      }
      groupCount++;
    }
  }
  const stepIsFound = groupIndex !== -1 && stepIndex !== -1;

  //check if a step with the same id already exists (no need to add a new step if so)
  if (updateOperation === UPDATE_STEP_OPERATION.ADD && stepIsFound) {
    return;
  }
  //check if a step with the same id exists on the parent (no need to update if doesn't exist)
  if (
    (updateOperation === UPDATE_STEP_OPERATION.EDIT || updateOperation === UPDATE_STEP_OPERATION.DELETE) &&
    !stepIsFound
  ) {
    return;
  }

  //Setup the update input
  // - making deep copies of data
  const newProcedures = cloneDeep(parentItem?.procedures);
  const newStep = cloneDeep(updatedStep || { id: updatedStepID });

  //Perform different modifications for different operation types
  if (updateOperation === UPDATE_STEP_OPERATION.EDIT) {
    // - making sure completion state doesn't get copied
    const oldCompletionState = newProcedures[groupIndex][procedureName].responseSteps[stepIndex];
    newStep.completed = isNullOrUndefined(oldCompletionState) ? false : oldCompletionState;

    // - writing new step data into the old procedure data
    newProcedures[groupIndex][procedureName].responseSteps[stepIndex] = updatedStep;
  } else if (updateOperation === UPDATE_STEP_OPERATION?.ADD) {
    /**
     * NOTE: assuming the first procedure group is the general procedure group where the new step should go
     */
    newProcedures[0][procedureName].responseSteps.push(updatedStep);
  } else if (updateOperation === UPDATE_STEP_OPERATION?.DELETE) {
    newProcedures[groupIndex][procedureName].responseSteps.splice(stepIndex, 1);
  } else {
    InfoLogger(`Step update type not supported ${updateOperation}`, {
      location: locationType.FUNCTION,
      operation: operationType.UPDATE,
      reason: reasonType.INVALID_PARAM,
    });
    return;
  }

  // - creating the input object for the mutation
  const input = {
    id: parentItem?.id,
    procedures: newProcedures,
  };

  //Perform the item mutation
  await ItemMutation(updateMutation, input);
};
