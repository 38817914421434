import { Button, Collapse, ListGroup, ListGroupItem, ListGroupItemText } from "reactstrap";
import React, { useEffect, useRef, useState } from "react";

import AccordionIcon from "../../../../utils/GenericComponents/AccordionIcon";
import DataLoader from "../../../../utils/LoadingComponents/DataLoader";
import KPIStatus from "./KPIStatus";
import Logic from "./Logic";
import MetricTypeDetails from "../../MetricTypes/components/MetricTypeDetails";
import { emptyPlaceholder } from "../../../../utils/GenericComponents/Placeholders";
import { evaluateLogic } from "../functions/evaluateLogic";
import { formatMetricData } from "@rivial-security/metric-utils";
import { getMostRecentMetric } from "@rivial-security/metric-utils";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useBoolean } from "../../../../hooks/functional/useBoolean";
import { useElementSize } from "../../../../hooks/functional/useElementSize";
import { useModal } from "../../../../hooks/views/useModal";

/**
 * Displays a list of MetricTypes. Used as a CustomField for KPIs
 *
   enum MetricDataFormat {
    decimal # ex. "0.1234".
    string  # for non-numerical data
    dollar  # USD form (with or without the commas and/or dollar sign). ex. "$123.50", "$1,234.00", "12356.50"
    percent # if input is already in percentage form (with or without the percent sign). ex. "75%", "36.0"
    json    # JSON.stringified, will be JSON.parsed
  }
 * @param {object} item - the parent KPI object
 * @param {object[]} metricTypes - the MetricTypeLinks field from the KPI object
 * @returns {*}
 * @constructor
 */
const MetricTypes = ({ item, metricTypes }) => {
  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useBoolean();

  useEffect(() => {
    setIsLoading(true);
    const metricTypes = item?.metricTypes?.items;
    if (Array.isArray(metricTypes)) {
      const arr = [];
      for (const metricTypeLink of metricTypes) {
        const mostRecentMetric = getMostRecentMetric(metricTypeLink.metricType);
        const data = mostRecentMetric && mostRecentMetric.data;

        arr.push({
          id: metricTypeLink.id,
          logic: metricTypeLink.logic,
          metricType: metricTypeLink.metricType,
          date: mostRecentMetric?.date || "N/A",
          name: metricTypeLink.metricType.name,
          value: data ? formatMetricData(data) : undefined,
        });
      }
      setData([...arr]);
    }
    setIsLoading(false);
  }, [item?.metricTypes?.items]);

  return (
    <div>
      <DataLoader
        isEnoughData={Array.isArray(data) && data.length > 0}
        isLoading={isLoading}
        dataMessage={"One or more Metrics does not have enough data to evaluate this KPI"}
      >
        {data && Array.isArray(data) && data.length > 0 ? (
          <ListGroup>
            {data.map((metricType) => (
              <MetricTypesItem metricType={metricType} />
            ))}
          </ListGroup>
        ) : (
          emptyPlaceholder
        )}
      </DataLoader>
    </div>
  );
};

export default MetricTypes;

/**
 * A single MetricType component to be rendered in the above list
 *
 * @author Jacob Blazina
 * @class
 * @param {object} metricType - the MetricType link object from the database
 * @returns {*}
 * @constructor
 */
const MetricTypesItem = ({ metricType }) => {
  const [showLogic, setShowLogic] = useState(false);

  const ref = useRef();
  const { width } = useElementSize({ ref });

  //Details modal
  const detailsModal = useModal("Metric Type Details", <MetricTypeDetails item={metricType?.metricType} />, null, {
    width: "60vw",
  });

  return (
    <ListGroupItem key={JSON.stringify(metricType)} onClick={() => setShowLogic(!showLogic)}>
      <ListGroupItemText>
        <KPIStatus style={{ marginRight: "1em" }} status={evaluateLogic(metricType)} />
        <MetricTypeValue metricType={metricType} />
        <AccordionIcon isOpen={showLogic} toggleFunction={() => setShowLogic(!showLogic)} enableOnClick={true} />
        <div ref={ref}>
          {detailsModal.modal}
          <Collapse isOpen={showLogic} onClick={(e) => e.stopPropagation()}>
            <hr />
            {showLogic && width >= 400 && (
              <div>
                <MetricTypeDetails item={metricType?.metricType} tableDisplay={true} />
                <Logic metricType={metricType} />
              </div>
            )}
            {showLogic && width < 400 && (
              <Button
                onClick={() => {
                  detailsModal.setModalIsOpen(true);
                }}
                color={"link"}
              >
                <i className="icon-share-alt" />
                <p style={{ marginLeft: ".5em", display: "inline" }}>Open Metric Type Details</p>
              </Button>
            )}
          </Collapse>
        </div>
      </ListGroupItemText>
    </ListGroupItem>
  );
};

const MetricTypeValue = ({ metricType }) => {
  return (
    <span>
      <strong>{metricType.name}</strong>:{" "}
      {(() => {
        if (isNullOrUndefined(metricType?.value)) {
          return "N/A";
        } else {
          switch (metricType.format) {
            case "decimal":
              return metricType.value.toFixed(2);
            default:
              return metricType.value;
          }
        }
      })()}
    </span>
  );
};
