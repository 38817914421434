import React from "react";
import { withRouter } from "react-router-dom";
import OrganizationCheck from "./OrganizationCheck/OrganizationCheck";
import Dashboard from "../GenericComponents/Dashboard";

/**
 * @description A HOC for automatically adding withRouter and finding itemId for resource details components
 * @param {function(*): JSX.Element | JSX.Element | React.Component} Component - The component to wrap with the HOC
 * @param {object} config - The config object for the component
 * @returns {function(*)} - The wrapped component
 */
export const withDetailsPage = (Component, config = {}) => {
  return withRouter((props) => {
    const itemId = (props.item && props.item.id) || props.match.params.id;
    const newProps = {
      ...props,
      itemId,
    };

    if (!props.item?.id && config?.title) {
      return (
        <Dashboard title={config?.title}>
          <OrganizationCheck {...newProps}>
            <Component />
          </OrganizationCheck>
        </Dashboard>
      );
    } else {
      return (
        <OrganizationCheck {...newProps}>
          <Component />
        </OrganizationCheck>
      );
    }
  });
};
