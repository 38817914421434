import { useAssessmentDataGrid } from "../hooks/useAssessmentDataGrid";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * @description: A Card components
 *              Displays list of Assessments for an OrganizationID.
 *              Limit set to 10.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const AssessmentGrid = (props) => useAssessmentDataGrid({ dashboardDisplay: true, ...props }).display;

export default withOrganizationCheck(AssessmentGrid);
