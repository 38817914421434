/**
 * Author: Anatoli Railean
 * Created At: 08/30/19
 * Edits:
 * TODO: Perform input value checks
 */

import { API, graphqlOperation } from "@aws-amplify/api";

import { InfoLogger } from "../../EventLogger";
import { generateGraphql } from "@rivial-security/generategraphql";

const { deleteMutation: deleteNotes } = generateGraphql("Note", ["content"]);
/**
 * @description An Async Function, deletes a Note from the database.
 * @param {object} note - The note to delete
 * @returns {Promise<*>}
 * @constructor
 */
const DeleteNotes = async (note) => {
  let res;

  await API.graphql(
    graphqlOperation(deleteNotes, {
      input: {
        id: note.id,
      },
    }),
  ).then(({ data }) => {
    res = data.deleteNotes;
    InfoLogger(`Notes: ${data.deleteNotes.content} was Successfully Deleted`);
  });

  return res;
};

export default DeleteNotes;
