import { modules, resources } from "@rivial-security/role-utils";
import { useForm } from "../../../hooks/views/useForm";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 * Custom hook that handles the logic and UI for the Custom Dashboard form.
 *
 * @param {string} organizationID - the ID of the organization to use for the form
 * @param {string} module - the module to use for the form
 * @param {string} resource - the resource to use for the form
 * @param {boolean} [isTemplate = false] - whether or not the dashboard is a template
 * @param {boolean} [disableRoleChecking = false] - whether or not to disable role checking
 * @param {object[]} defaultWidgets - the default widgets to use for the dashboard layout
 * @param {function} getNewItem - the function to get a new item
 * @param {object} formConfig - the config for the form
 * @param {function} toggleModal - the function to toggle the modal
 * @param {string} toolID - the ID of the tool to save the dashboard to
 * @param {string} pageID - the ID of the page to save the dashboard to
 * @param {object} ...props - any other props to pass to the useForm hook
 * @returns {{display: *, input: {}}}
 */
export const useCustomDashboardForm = ({
  organizationID,
  module = modules.TOOLS,
  resource = resources.TOOL,
  isTemplate = false,
  disableRoleChecking = false,
  defaultWidgets,

  getNewItem,
  formConfig = {},
  toggleModal,

  toolID,
  pageID,
  ...props
}) => {
  /**
   * Modifies the input from the main form to include Tool connection
   * and Page connection
   * @param {object} input - object from the main form
   * @returns {object} - the full input object with ToolID
   */
  const updateInputFunction = (input) => {
    if (toolID) {
      input.toolID = toolID;
    }

    if (pageID) {
      input.pageID = pageID;
    }

    // generate the first page
    input.layouts = {
      id: "",
      name: input.name,
      elements: defaultWidgets || [],
    };

    return input;
  };

  const fieldConfig = {
    name: {
      required: true,
      label: "Name",
      tooltip: "A short name for this dashboard. This will be used to identify the dashboard in the UI.",
    },
    description: {
      label: "Description",
    },
  };

  const formHook = useForm({
    organizationID,
    module,
    resource,
    disableRoleChecking,

    fieldConfig,
    mutation: generateGraphql("CustomDashboard", ["name", "toolID", "pageID"]).createMutation,
    typename: "CustomDashboard",
    toggleModal,
    updateInputFunction,
    ...formConfig,
    ...props,
  });

  return formHook;
};
