"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItemMutation = void 0;
const handleItemMutation_1 = require("./handleItemMutation");
const getEnvironment_1 = require("../getEnvironment");
const handleGraphqlClient_1 = require("../handleGraphqlClient");
/**
 * Mutate an item in appsync endpoint
 * @param appsyncEndpoint - appsync endpoint
 * @param mutation - graphql mutation
 * @param input - input for the mutation
 */
const ItemMutation = async ({ appsyncEndpoint, mutation, input }) => {
    const env = (0, getEnvironment_1.getEnvironment)({ appsyncEndpoint });
    const mutationFunction = (0, handleGraphqlClient_1.handleGraphqlClient)({ env, appsyncEndpoint });
    return await (0, handleItemMutation_1.handleItemMutation)({ mutationFunction, mutation, input });
};
exports.ItemMutation = ItemMutation;
