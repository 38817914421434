import React, { useEffect } from "react";
import { modules, resources } from "@rivial-security/role-utils";

import ActionItemAssociatedResources from "../components/ActionItemAssociatedResources";
import ActionItemCompletionDate from "../customFields/ActionItemCompletionDate";
import ActionItemCustomData from "../customFields/ActionItemCustomData";
import ActionItemEnableNotifications from "../customFields/ActionItemEnableNotifications";
import ActionItemNotes from "../customFields/ActionItemNotes";
import ActionItemObservations from "../components/ActionItemObservations";
import ActionItemRecommendations from "../components/ActionItemRecommendations";
import ActionPointOfContact from "../customFields/ActionPointOfContact";
import CustomDateField from "../../Email/customFields/CustomDateField";
import Dashboard from "../../../../utils/GenericComponents/Dashboard";
import DashboardCard from "../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import DataLoader from "../../../../utils/LoadingComponents/DataLoader";
import DueDate from "../customFields/DueDate";
import Grid from "@mui/material/Grid";
import LandingPageUrl from "../customFields/LandingPageUrl";
import Module from "../customFields/Module";
import Priority from "../customFields/Priority";
import PriorityHistory from "../customFields/PriorityHistory";
import Status from "../customFields/Status";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useDetailsCard } from "../../../../hooks/views/useDetailsCardV2";

/**
 * Action Item details
 * @param itemId
 * @param module
 * @param resource
 * @param config
 */
export const useActionItemDetails = ({
  itemId,
  module = modules.GOVERNANCE,
  resource = resources.ACTION_ITEM,
  ...config
}) => {
  const route = "#/governance/action_items/";

  const fields = [
    "name",
    "description",
    "status",
    "module",
    "pointOfContact",
    "landingPageUrl",
    "dueDate",
    "priority",
    "completionDate",
    "enableNotifications",
    "createdAt",
  ];

  const queryFields = [...fields, "priorityHistory", "recommendations", "notes", "data"];

  const nestedFields = {
    pointOfContact: `{firstName lastName email id user { id }}`,
    priorityHistory: `{ value reason date }`,
    recommendations: `(limit: 1000) { items { recommendation { name observations (limit: 1000) { items { observation { name module } } } } } }`,
    notes: `{ id type ownerGroup author timeStamp content tag observationID }`,
  };

  const customFields = [
    {
      field: "priority",
      component: <Priority />,
    },
    {
      field: "priorityHistory",
      component: <PriorityHistory />,
    },
    {
      field: "pointOfContact",
      component: <ActionPointOfContact />,
    },
    {
      field: "status",
      component: <Status />,
    },
    {
      field: "completionDate",
      component: <ActionItemCompletionDate />,
    },
    {
      field: "module",
      component: <Module />,
    },
    {
      field: "dueDate",
      component: <DueDate />,
    },
    {
      field: "landingPageUrl",
      component: <LandingPageUrl />,
    },
    {
      field: "data",
      component: <ActionItemCustomData />,
    },
    {
      field: "createdAt",
      component: <CustomDateField />,
    },
    {
      field: "enableNotifications",
      component: <ActionItemEnableNotifications />,
    },
    {
      field: "notes",
      component: <ActionItemNotes />,
    },
    {
      field: "name",
      inputType: "textarea",
    },
    {
      field: "description",
      inputType: "textarea",
    },
  ];

  const fieldNameDictionary = {
    landingPageUrl: "Landing Page",
    data: "Linked Resource",
  };

  const { getQuery, updateMutation, onUpdate } = generateGraphql("GenericActionItem", queryFields, nestedFields);

  const queryConfig = {
    query: getQuery,
    itemId,
    onUpdateItemSubscription: onUpdate,
  };

  const detailsConfig = {
    fields,
    nestedFields,
    customFields,
    fieldNameDictionary,
    updateMutation,
  };

  const configs = {
    route,
    header: "Action Item Details",
  };

  const detailsCard = useDetailsCard({
    queryConfig,
    detailsConfig,
    config: configs,
    module,
    resource,
    route,
    ...config,
  });

  //when organization changes re-query the action item (useful for users that got redirected from the guest page)
  useEffect(() => {
    if (typeof detailsCard?.resetFunction === "function") {
      detailsCard.resetFunction();
    }
  }, [config?.organizationID]);

  const display = (
    <Dashboard resetFunction={detailsCard?.reset}>
      <Grid container spacing={2}>
        <Grid container spacing={2} item lg={4} md={4} xs={12}>
          <Grid item lg={12} xs={12}>
            <DashboardCard title={"General Information"} style={{ height: "100%" }} icon={"icon-list"}>
              {detailsCard.tableDisplay}
            </DashboardCard>
          </Grid>
          <Grid item lg={12} xs={12}>
            <DashboardCard title={"Notes"} style={{ height: "100%" }} icon={"icon-note"}>
              <DataLoader isEnoughData={detailsCard?.item}>
                <ActionItemNotes item={detailsCard?.item} disableTitle={true} />
              </DataLoader>
            </DashboardCard>
          </Grid>
        </Grid>
        <Grid container spacing={2} item lg={8} md={8} xs={12}>
          <Grid item lg={12} xs={12}>
            <DashboardCard title={"Recommendations"} icon={"icon-check"} style={{ height: "100%" }}>
              <ActionItemRecommendations item={detailsCard?.item} />
            </DashboardCard>
          </Grid>
          <Grid item lg={12} xs={12}>
            <DashboardCard title={"Observations"} icon={"icon-eye"} style={{ height: "100%" }}>
              <ActionItemObservations item={detailsCard?.item} />
            </DashboardCard>
          </Grid>
          <Grid item lg={12} xs={12}>
            <DashboardCard title={"Associated Resources"} icon={"icon-link"} style={{ height: "100%" }}>
              <ActionItemAssociatedResources item={detailsCard?.item} />
            </DashboardCard>
          </Grid>
        </Grid>
      </Grid>
    </Dashboard>
  );

  return {
    updateMutation,
    ...detailsCard,
    display,
  };
};
