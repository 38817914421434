import { useForm } from "../../../../hooks/views/useForm";
import { generateGraphql } from "@rivial-security/generategraphql";
import { DEPARTMENT } from "../constants/DEPARTMENT";

/**
 * Provides a form for creating a Department for an Organization
 *
 * @param {string} organizationID - the ID of the Organization that the Department belongs to
 * @param {boolean} disableTemplates - disables template button for this form
 * @param {boolean} isTemplate - if true, will create this resource as a template
 * @param {object} props - all props passed to the useForm hook
 * @returns {{display: *, input: {}}}
 *
 * @see DEPARTMENT
 */
export const useDepartmentForm = ({ organizationID, disableTemplates, isTemplate, ...props }) => {
  const typename = DEPARTMENT.typename;
  const module = DEPARTMENT.module;
  const resource = DEPARTMENT.resource;

  const fieldConfig = {
    name: {
      ...DEPARTMENT.fieldConfig.name,
    },
    description: {
      ...DEPARTMENT.fieldConfig.description,
    },

    // or alternatively:
    // ...DEPARTMENT.fieldConfig
  };

  const { createMutation } = generateGraphql(DEPARTMENT.typename, [
    DEPARTMENT.fields.name,
    DEPARTMENT.fields.description,
  ]);

  const form = useForm({
    typename,
    organizationID,
    module,
    resource,
    fieldConfig,
    mutation: createMutation,
    enableTemplates: !disableTemplates && !isTemplate,
    ...props,
  });

  return form;
};
