import React, { useEffect, useState } from "react";
import { modules, resources } from "@rivial-security/role-utils";

import { Button } from "reactstrap";
import { ErrorLogger } from "@utils/EventLogger";
import { GetQuery } from "../../../utils/Functions/Graphql/GetQuery";
import PermissionsOverlay from "../../../utils/Overlays/PermissionsOverlay";
import ShownFieldsSelector from "./components/ShownFieldsSelector";
import { TEMPLATE_TYPES } from "../DocumentEditor/enums/TEMPLATE_TYPES";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useDocumentEditor } from "../DocumentEditor/hooks/useDocumentEditor";
import { useModal } from "../../../hooks/views/useModal";
import { withOrganizationCheck } from "../../../utils/Context/withOrganizationCheck";

const AuditReport = ({ organizationID, auditID }) => {
  const module = modules.COMPLIANCE;
  const reportType = "IT Audit Report";

  const [availableFields, setAvailableFields] = useState([]);
  const [shownFields, setShownFields] = useState([]);

  //Gets the list of available fields for the control details table
  const getAvailableFields = async () => {
    const { getQuery } = generateGraphql("Audit", ["controlSet"], {
      controlSet: "{customFields {name}}",
    });
    const auditData = await GetQuery({
      query: getQuery,
      variables: { id: auditID },
    });

    const newAvailableFields = [];
    if (auditData?.controlSet?.customFields && Array.isArray(auditData.controlSet.customFields)) {
      for (const customField of auditData.controlSet.customFields) {
        if (customField?.name) {
          newAvailableFields.push(customField.name);
        }
      }
    }
    newAvailableFields.push("notes");
    setAvailableFields(newAvailableFields);
  };
  useEffect(() => {
    getAvailableFields();
  }, []);

  const { ref, display, menuOnClick } = useDocumentEditor({
    organizationID,
    module,
    type: reportType,
  });

  const genReport = () => {
    if (!ref || !ref.documentEditor) {
      const errMsg = "Cannot create an audit report, " + "no reference to document editor!";
      ErrorLogger(errMsg);
      throw new Error(errMsg);
    }

    menuOnClick(TEMPLATE_TYPES.AUDIT_TEMPLATE, {
      module,
      type: reportType,
      auditID,
      shownFields,
    });
  };

  const onUpdateShownFields = (newShownFields) => {
    setShownFields(newShownFields);
  };

  const defaultReportModal = useModal(
    "Do you want to generate an IT Audit Report?",
    <div>
      <Button
        color="primary"
        onClick={() => {
          if (ref && ref.documentEditor) {
            genReport();
          }
          defaultReportModal.setModalIsOpen(false);
        }}
      >
        Yes, Generate an IT Audit Report
      </Button>
      <br />
      <br />
      <Button onClick={() => defaultReportModal.setModalIsOpen(false)}>No, not right now</Button>
      <div style={{ height: "1em" }} />
      <hr />
      <div style={{ height: "1em" }} />
      <p style={{ fontWeight: "bold", fontSize: "15px" }}>Report Configuration</p>
      <label>Shown Control Fields</label>
      <ShownFieldsSelector availableFields={availableFields} onChangeCallback={onUpdateShownFields} />
    </div>,
  );

  useEffect(() => {
    defaultReportModal.setModalIsOpen(true);
  }, []);

  return (
    <PermissionsOverlay module={modules.REPORTS} resource={resources.REPORT} operationType={"create"}>
      <div>
        {defaultReportModal.modal}
        {display}
      </div>
    </PermissionsOverlay>
  );
};

export default withOrganizationCheck(AuditReport);
