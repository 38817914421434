import { useCustomDashboardForm } from "../hooks/useCustomDashboardForm";
import { withOrganizationCheck } from "../../../utils/Context/withOrganizationCheck";

/**
 * Displays a form for Custom Dashboards
 * @param props - all props passed to the useCustomDashboardForm hook
 * @returns {*}
 * @constructor
 */
const CustomDashboardForm = (props) => useCustomDashboardForm(props).display;

export default withOrganizationCheck(CustomDashboardForm);
