import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useCustomWidgetDataGrid } from "./useCustomWidgetDataGrid";

/**
 * Widget List with the selection button enabled
 * @param {string} organizationID - currently selected organization ID
 * @param {function} insertWidgetCallback - function callback upon user selecting a widget
 * @param {boolean} showHeader - true if need to show the widget grid header title
 */
export const useSelectWidget = ({ organizationID, insertWidgetCallback, showHeader }) => {
  const onSelectedWidget = (item) => {
    if (!isNullOrUndefined(item)) {
      insertWidgetCallback && insertWidgetCallback(item);
    }
  };

  const queryCard = useCustomWidgetDataGrid({
    showHeader,
    organizationID,
    enableSelectButton: true,
    onSelectCallback: onSelectedWidget,
  });
  return {
    ...queryCard,
  };
};
