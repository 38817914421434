import { ReactElement } from "react";
import { useActionDataGrid } from "@views/Program/Actions/hooks/useActionDataGrid";
import { useNestedDataCard } from "@hooks/views/useNestedDataCard/useNestedDataCard";
import { withOrganizationCheck } from "@utils/Context/withOrganizationCheck";
import { useGetTargetVulnerabilityActions } from "@views/Testing/Vulnerabilities/hooks/useGetTargetVulnerabilityActions";

export interface VulnerabilityActionItemsProps {
  item: { id: string };
  organizationID: string;
}

const VulnerabilityActionItems = ({ item, organizationID }: VulnerabilityActionItemsProps): ReactElement => {
  const { data, setData, resetFunction, isLoading } = useGetTargetVulnerabilityActions({
    itemID: item.id,
    indexName: "listTargetFindingLinksByVulnerabilityID",
    partitionKey: "vulnerabilityID",
  });

  const nestedDataCard = useNestedDataCard({
    parentTypename: "Vulnerability",
    typename: "GenericActionItem",
    externalItems: data,
    setExternalItems: setData,
    resetExternalItems: resetFunction,
    isLoadingExternalItems: isLoading,
    gridHook: useActionDataGrid,
    organizationID,
    disableLinking: true,
    gridHookGridConfig: {
      hideCompleted: true,
    },
  });

  return <div style={{ height: "40em" }}>{nestedDataCard.gridDisplay}</div>;
};

export default withOrganizationCheck(VulnerabilityActionItems);
