import { modules, resources } from "@rivial-security/role-utils";
import React from "react";
import ToolDetails from "../components/ToolDetails";
import { toolsByOwnerGroup } from "../graphql/toolsByOwnerGroup";
import CreateTool from "../components/CreateTool";
import { deleteTool } from "../functions/deleteTool";
import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";
import { mergeAdditionalFields } from "../../../../hooks/views/useGrid/functions/mergeAdditionalFields";

/**
 * @description Display list of tools hook
 * @param {string} organizationID - organization ID
 * @param {object} cardConfig - card configuration
 * @param {object} queryConfig - query configuration
 * @param {object} gridConfig - grid configuration
 * @param {object[]} additionalFields - additional fields to add to the grid fields config
 * @param {object} props - props
 * @returns {{gridDisplay: JSX.Element, data: unknown, lastSelectedItem: string, setData: (value: unknown) => void, display: JSX.Element, isLoading: boolean, createResourceComponent: JSX, ref: string, setSelectedItems: (value: (((prevState: []) => []) | [])) => void, setIsLoading: (value: (((prevState: boolean) => boolean) | boolean)) => void, setLastSelectedItem: (value: (((prevState: string) => string) | string)) => void, itemsToCheck: unknown, resetFunction: function(): void, fields: Object[], setItemsToCheck: (value: unknown) => void, selectedItems: []}}
 */
export const useToolDataGrid = ({
  organizationID,
  cardConfig = {},
  queryConfig = {},
  gridConfig = {},
  additionalFields = [],
  ...props
}) => {
  const module = modules.TOOLS;
  const resource = resources.TOOL;
  const route = "#/integrations/configuration/";

  cardConfig = {
    title: "Integrations",
    headerIcon: "icon-puzzle",
    enableSticky: true,
    ...cardConfig,
  };

  queryConfig = {
    query: toolsByOwnerGroup,
    limit: 1000,
    variables: {
      ownerGroup: organizationID,
    },
    ...queryConfig,
  };

  const roleConfig = {
    module,
    resource,
    disableRoleChecking: true,
  };

  const fields = [
    {
      name: "name",
      flex: 1,
    },
    {
      name: "description",
      flex: 1,
    },
  ];

  mergeAdditionalFields({ fields, additionalFields });

  gridConfig = {
    fields,
    route,
    createResourceComponent: <CreateTool />,
    detailsComponent: <ToolDetails />,
    detailsTitle: "Integration Details",
    typename: "Integration",
    deleteFunction: deleteTool,
    options: ["delete"],
    organizationID,
    persistenceUUID: "aac00067-2639-4552-a972-b6e7cc124224",
    ...props,
    ...gridConfig,
  };

  const grid = useDataGrid({
    ...queryConfig,
    ...cardConfig,
    ...roleConfig,
    ...gridConfig,
  });

  return { ...grid };
};
