import { modules, resources } from "@rivial-security/role-utils";

import Dashboard from "../../../../utils/GenericComponents/Dashboard";
import DashboardCard from "../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import Grid from "@mui/material/Grid";
import React from "react";
import ResponseTeam from "../customFields/ResponseTeam";
import SystemRiskLinks from "../customFields/SystemRiskLinks";
import ThreatKeyRiskIndicators from "../components/ThreatKeyRiskIndicators";
import ThreatPlaybook from "../customFields/ThreatPlaybook";
import ThreatTechnique from "../components/ThreatTechnique";
import { generateGraphql } from "@rivial-security/generategraphql";
import { getProceduresNestedFieldsString } from "../../PlaybookBuilder/functions/getProceduresNestedFields";
import { useAccordion } from "../../../../hooks/views/useAccordion/useAccordion";
import { useDetailsCard } from "../../../../hooks/views/useDetailsCardV2";

/**
 * Displays details for a single Threat
 * @param {string} itemId - id of the Threat
 * @param {string} organizationID - id of the organization
 * @param {object} props - props object
 */
export const useThreatDetails = ({ itemId, organizationID, ...props }) => {
  const module = modules.INCIDENT_RESPONSE;
  const resource = resources.THREAT;

  const { getQuery, updateMutation } = generateGraphql(
    "Threat",
    ["name", "playbook", "systemRiskLinks", "responseTeam", "responseTeamID", "techniqueID"],
    {
      systemRiskLinks: `(limit: 1000) {
      items {
      id systemRiskLink {
      id probabilityModifier system {
      id name description hosting inherentRisk residualRisk riskRating greatestEnterpriseRisk greatestBusinessRisk greatestKeyRiskIndicator tags (limit: 100) {
      items {
      __typename id tag {
      id name description fontColor backgroundColor } } } adminPointOfContact {
      id firstName lastName email title phone } pointOfContact {
      id firstName lastName email title phone } } risk {
      id name description  } } } }`,

      playbook: `{ id name ${getProceduresNestedFieldsString()} }`,
      responseTeam: `{id name}`,
    },
  );

  const fields = ["name", "responseTeam"];

  const fieldNameDictionary = {
    systemRiskLinks: "Systems / Key Risk Indicators",
    techniqueID: "Mitre Technique",
  };

  const customFields = [
    {
      field: "responseTeam",
      component: <ResponseTeam />,
    },
  ];

  const queryConfig = {
    query: getQuery,
    itemId,
  };

  const detailsConfig = {
    fields,
    customFields,
    updateMutation,
    fieldNameDictionary,
  };

  const detailsHook = useDetailsCard({
    queryConfig,
    detailsConfig,
    module,
    resource,
    ...props,
  });

  const accordion = useAccordion({
    item: detailsHook.item,
    items: [
      {
        title: "Systems",
        subTitle: "Information Systems that are affected by this Threat",
        component: (
          <SystemRiskLinks
            item={detailsHook.item}
            resetFunction={detailsHook.resetFunction}
            isLoading={detailsHook.isLoading}
          />
        ),
        icon: "icon-screen-desktop",
        defaultExpanded: true,
      },
      {
        title: "Key Risk Indicators",
        subTitle: "Enterprise mapping between Threats and Key Risk Indicators",
        component: (
          <div style={{ height: "30em" }}>
            <ThreatKeyRiskIndicators item={detailsHook.item} />
          </div>
        ),
        icon: "icon-fire",
        defaultExpanded: true,
      },
      {
        title: "Technique",
        subTitle: "Mitre Att&ck Technique",
        component: <ThreatTechnique item={detailsHook?.item} resetFunction={detailsHook?.reset} />,
        icon: "icon-notebook",
        defaultExpanded: true,
      },
    ],
  });

  const display = (
    <Dashboard>
      <Grid container spacing={2}>
        <Grid item container spacing={2} lg={4} sm={12}>
          <Grid item lg={12} sm={12}>
            <DashboardCard title={"General Information"} icon={"icon-list"} style={{ height: "100%" }}>
              {detailsHook.tableDisplay}
            </DashboardCard>
          </Grid>
          <Grid item lg={12} sm={12}>
            <DashboardCard title={"Response Playbook"} icon={"icon-book-open"} style={{ height: "100%" }}>
              <ThreatPlaybook item={detailsHook.item} resetFunction={detailsHook.reset} />
            </DashboardCard>
          </Grid>
        </Grid>
        <Grid item lg={8} sm={12}>
          {accordion.display}
        </Grid>
      </Grid>
    </Dashboard>
  );

  return {
    ...detailsHook,
    display,
  };
};
