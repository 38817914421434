import { modules, resources } from "@rivial-security/role-utils";

import KnowBe4ModuleDetails from "../components/KnowBe4ModuleDetails";
import { useDataGrid } from "../../../../../hooks/views/useDataGrid/useDataGrid";
import { useEffect } from "react";

/**
 * @description Displays a Table of knowbe4 modules
 * @param {object} item - The item to display the table for
 * @returns {{data: Object[], setData: (function(*=): void), display: JSX.Element, setFields: React.Dispatch<React.SetStateAction<unknown>>, setSelectionType: React.Dispatch<React.SetStateAction<unknown>>, setSelectedItems: React.Dispatch<React.SetStateAction<*[]>>, selectionType: unknown, setShowSelectBoxes: React.Dispatch<React.SetStateAction<Object.showSelectBoxes|boolean>>, showSelectBoxes: (Object.showSelectBoxes|boolean), setItems: setItems, options: string[], setFullData: React.Dispatch<React.SetStateAction<*[]>>, sortFields: *[], fields: unknown, selectedItems: *[], setSortField: setSorting}}
 */
export const useKnowBe4ModuleDataGrid = ({
  item,
  organizationID,
  cardConfig = {},
  queryConfig = {},
  gridConfig = {},
  fields = [],
  ...props
}) => {
  const module = modules.TOOLS;
  const resource = resources.KNOW_BE_4_MODULE;

  queryConfig = {
    organizationID,
    query: null,
  };

  fields = [
    {
      name: "name",
      flex: 1,
      minWidth: 200,
      sort: {
        direction: "desc",
        priority: 1,
      },
    },
    {
      name: "description",
      width: 400,
    },
    {
      name: "duration",
      width: 100,
    },
  ];

  const roleConfig = {
    module,
    resource,
  };

  gridConfig = {
    fields,
    disableRoleChecking: true,
    detailsComponent: <KnowBe4ModuleDetails />,
    detailsTitle: "KnowBe4 Module Details",
    typename: "knowBe4Training",
    persistenceUUID: "fa6018dd-77db-473b-8b67-d3387e40de03",
    organizationID,
    ...props,
    ...gridConfig,
  };
  const grid = useDataGrid({
    ...queryConfig,
    ...cardConfig,
    ...roleConfig,
    ...gridConfig,
  });

  useEffect(() => {
    if (Array.isArray(item?.content)) {
      grid.setData(
        item?.content.map((training) => {
          return {
            ...training,
            id: training?.store_purchase_id,
          };
        }),
      );
    }
  }, [item]);

  return {
    ...grid,
  };
};
