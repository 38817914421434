import React from "react";
import DepartmentHead from "../components/DepartmentHead";
import { modules, resources } from "@rivial-security/role-utils";
import { ICON_MAP } from "../../../../enums/ICON_MAP";
import { deleteDepartment } from "../functions/deleteDepartment";
import { formattedName } from "@rivial-security/func-utils";

/**
 * Provides common configurations for the Department type
 * @type {{disableRoleChecking: boolean, queryFields: string[], resource: (*|string), module: (*|string), description: string, fieldConfig: {name: {sorting: {priority: number}, name: string, tooltip: string, label: string, required: boolean}, departmentHead: {name: string, tooltip: string, inputType: string, label: string}, description: {name: string, tooltip: string, inputType: string, label: string}}, typename: string, nestedFields: {departmentHead: string}}}
 */
export const DEPARTMENT = {
  module: modules.ORGANIZATION_MANAGER,
  resource: resources.DEPARTMENT,
  route: "#/organization_manager/departments/",
  icon: ICON_MAP.RESOURCES[resources.DEPARTMENT],
  typename: "Department",
  fieldConfig: {
    name: {
      name: "name",
      required: true,
      label: "Name",
      description: "The name of the department",
      sorting: {
        priority: 1,
      },
      bulkEdit: true,
    },
    description: {
      name: "description",
      inputType: "textarea",
      label: "Description",
      description: "A description of the department",
      bulkEdit: true,
    },
    departmentHead: {
      name: "departmentHead",
      inputType: "select",
      label: "Department Head",
      tooltip: "A Point of Contact that is the head of the department",
      component: <DepartmentHead />,
      description: "The Point of Contact that is in charge of the Department",
      searchKeys: ["departmentHead.firstName", "departmentHead.lastName"],
      sortComparator: (aName, bName) => {
        if (aName && bName) {
          return aName.localeCompare(bName, undefined, {
            numeric: true,
            sensitivity: "base",
          });
        }
      },
      valueGetter: (_value, row) => formattedName({ pointOfContact: row?.departmentHead }),
    },
  },
  fields: {
    id: "id",
    name: "name",
    description: "description",
    ownerGroup: "ownerGroup",
    departmentHead: "departmentHead",
    departmentHeadID: "departmentHeadID",
  },
  nestedFields: {
    departmentHead: `
        {
          id
          firstName
          lastName
          email
          title
          ownerGroup
        }
      `,
  },
  duplicationSettings: {
    // Provides a description to the user about how this duplication works
    description:
      "Duplicates a Department. Copies over the name and description. Does not copy over the department head or members because it would remove them from their current Department.",
    enabled: true,
    fields: ["name", "description"],
    primaryField: "name",
    fieldAdaptor: {
      name: (item) => `${item.name}-duplicated`,
    },
  },
  deleteFunction: deleteDepartment,
};
