import { Col, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from "reactstrap";
import React, { useEffect, useState } from "react";

import ChangeInCost from "../../../customFields/ChangeInCost";
import DataLoader from "../../../../../../utils/LoadingComponents/DataLoader";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useStateEffect } from "../../../../../../hooks/functional/useStateEffect";

/**
 * Input for the 'Change in Cost' field for a Risk Recommendation
 * @param selectedRiskControlID
 * @param riskControls
 * @param onChangeCallback
 * @param defaultValue
 * @returns {JSX.Element}
 * @constructor
 */
const ChangeInCostField = ({ selectedRiskControlID, riskControls, onChangeCallback, defaultValue }) => {
  const [changeInCost, setChangeInCost] = useState(defaultValue || 0);
  const [newCost, setNewCost] = useState(0);

  const [riskControl] = useStateEffect(null, [riskControls, selectedRiskControlID], () => {
    if (!isNullOrUndefined(riskControls) && !isNullOrUndefined(selectedRiskControlID)) {
      return riskControls.find((item) => item.id === selectedRiskControlID);
    }
  });

  // Calculate the change in cost
  useEffect(() => {
    let result = 0;
    if (!isNullOrUndefined(riskControl?.costOfControl)) {
      if (!isNullOrUndefined(changeInCost)) {
        result = parseFloat(riskControl?.costOfControl) + parseFloat(changeInCost);
      } else {
        result = riskControl?.costOfControl;
      }
    }

    setNewCost(result);
  }, [selectedRiskControlID, changeInCost, riskControls, defaultValue, riskControl]);

  useEffect(() => {
    onChangeCallback && onChangeCallback(changeInCost);
  }, [changeInCost]);

  return (
    <FormGroup>
      <DataLoader isLoading={isNullOrUndefined(riskControl)} isEnoughData={selectedRiskControlID}>
        <Row>
          <Col md={4}>
            <ChangeInCost newCost={newCost} oldCost={riskControl?.costOfControl} />
          </Col>
          <Col>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>$</InputGroupText>
              </InputGroupAddon>
              <Input
                type="number"
                id="costChange"
                value={changeInCost}
                onChange={(e) => setChangeInCost(e.target.value)}
              />
            </InputGroup>
          </Col>
        </Row>
      </DataLoader>
    </FormGroup>
  );
};

export default ChangeInCostField;
