import { Col, Row } from "reactstrap";
import React, { useState } from "react";
import { modules, resources } from "@rivial-security/role-utils";

import DashboardCard from "../../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import DataLoader from "../../../../../utils/LoadingComponents/DataLoader";
import { MonteCarloContext } from "../../context/MonteCarloContext";
import RiskCurveWarning from "../../components/RiskCurveWarning";
import RiskCurveWarningAtResidualLoss from "../../components/RiskCurveWarningAtResidualLoss";
import SystemAccordion from "../../components/SystemAccordion/SystemAccordion";
import SystemInfoCard from "./components/SystemInfoCard";
import SystemLoadingAlert from "./components/SystemLoadingAlert";
import SystemTemplateAlert from "./components/SystemTemplateAlert";
import { useMonteCarloEffect } from "./hooks/useMonteCarloEffect";
import { usePreferences } from "../../../../../hooks/views/usePreferences/usePreferences";
import { useSystemDetailsQuery } from "./hooks/useSystemDetailsQuery";
import { useSystemSettings } from "./hooks/useSystemSettings";

/**
 * Displays details of a specific Information System in the Risk module.
 * Performs Monte Carlo calculations and passes results to all children through Context
 * @param {string} itemId - the itemId of the system to query
 * @param {string} organizationID - ownerGroup of the system
 * @param {function} [updateItemById] - callback function to update an external state when this item updates
 * @param {function} [updateItemByIdFunc] - (deprecated use 'updateItemById' directly) callback function to update an external state when this item updates
 * @param {function} [setQueryRef] - callback function for passing the reference to the query hook
 * @param {string} [module = RISK] - module for role checking. can be overwritten
 * @param {string} [resource = INFORMATION_SYSTEM] - resource for role checking. can be overwritten
 * @param {boolean} disableRoleChecking - disables role checking for this component
 * @returns {JSX.Element}
 * @constructor
 */
export const useSystemDetails = ({
  organizationID,
  itemId,
  updateItemByIdFunc,
  updateItemById,
  setQueryRef,
  module = modules.RISK,
  resource = resources.INFORMATION_SYSTEM,
  disableRoleChecking = false,
}) => {
  /**
   * Fetches System Details information
   */
  const {
    systemDetails,
    resetFunction,
    isLoading: systemDetailsIsLoading,
    updateItemById: updateItemByIdInternal,
  } = useSystemDetailsQuery({
    itemId,
    module,
    resource,
    disableRoleChecking,
    updateItemByIdFunc: updateItemByIdFunc || updateItemById,
    setQueryRef,
  });

  /**
   * Holds the current Risk Scores state for the system
   */
  const [riskScore, setRiskScore] = useState(null);

  /**
   * Handles the monte carlo calculation functionality
   */
  const {
    isLoading: isLoadingMonteCarlo,
    isOutdated,
    setIsOutdated,
    monteCarloResults,
    monteCarloInput,
  } = useMonteCarloEffect({
    item: systemDetails.item,
    updateItemById: updateItemByIdInternal,
    setRiskScore,
    organizationID,
  });

  /**
   * Sets the local state to 'outdated', and optionally
   * resets the system query with options for forcing a UI reset of all children as well
   * @param forceReset
   * @param resetKey
   */
  const resetSystem = (forceReset = autoCalc, resetKey = true) => {
    setIsOutdated(true);
    if (forceReset) {
      resetFunction(resetKey);
    }
  };

  /**
   * Construct the context object
   */
  const contextValue = {
    monteCarloInput,
    monteCarloResults,
    riskScore,
    setRiskScore,
    isLoading: systemDetails.isLoading,
    isLoadingMonteCarlo,
  };

  /**
   * Handles the System settings UI and functionality
   */
  const { autoCalc, viewType, ...settingsHook } = useSystemSettings({
    isLoading: systemDetails.isLoading,
    isOutdated,
    resetFunction,
    item: systemDetails.item,
  });

  /**
   * Check if beta risk calculations are enabled
   */
  const preferences = usePreferences();
  const betaRiskCalculationsEnabled = preferences?.getPreference("general", "beta_risk_calculations") || false;

  /**
   * Constructs a ContextProvider that can be used externally through a hook or internally through this component
   * @param children
   * @returns {JSX.Element}
   * @constructor
   */
  const Context = ({ children }) => (
    <MonteCarloContext.Provider value={{ ...contextValue }}>{children}</MonteCarloContext.Provider>
  );

  const display = (
    <div>
      <DataLoader isEnoughData={true} isLoading={false}>
        {systemDetails?.item?.ownerGroup === "system-template" ? (
          <SystemTemplateAlert />
        ) : (
          <div>
            <DashboardCard
              title={systemDetails?.item?.name}
              subTitle={systemDetails?.item?.description}
              style={{ height: "100%" }}
              headerButtons={[settingsHook.display]}
            >
              <Row style={{ overflow: "visible" }}>
                <Col lg={8}>
                  <SystemAccordion
                    Context={Context}
                    updateItemById={updateItemByIdInternal}
                    organizationID={organizationID}
                    item={systemDetails?.item}
                    resetFunction={resetFunction}
                    isLoading={systemDetailsIsLoading}
                    resetSystem={resetSystem}
                    viewType={viewType}
                    context={contextValue}
                  />
                </Col>
                <Col lg={4}>
                  <div>
                    <SystemLoadingAlert
                      resetFunction={resetFunction}
                      isOutdated={isOutdated}
                      isLoading={isLoadingMonteCarlo}
                    />
                    <SystemInfoCard Context={Context} item={systemDetails.item} settingsHook={settingsHook} />
                    {betaRiskCalculationsEnabled
                      ? !isLoadingMonteCarlo && (
                          <div style={{ marginTop: "1rem" }}>
                            <RiskCurveWarning organizationID={organizationID} monteCarloContext={contextValue} />
                          </div>
                        )
                      : !isLoadingMonteCarlo && (
                          <div style={{ marginTop: "1rem" }}>
                            <RiskCurveWarningAtResidualLoss
                              organizationID={organizationID}
                              monteCarloContext={contextValue}
                            />
                          </div>
                        )}
                  </div>
                </Col>
              </Row>
            </DashboardCard>
          </div>
        )}
      </DataLoader>
    </div>
  );

  return {
    display,
    Context,
    item: systemDetails.item,
    monteCarloResults,
    monteCarloInput,
  };
};
