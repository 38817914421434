import { deleteItem } from "../../../../utils/Functions/Graphql/deleteItem";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 * Deletes a single Recommendation Action Link object
 * @param recommendationActionLink
 */
export const deleteRecommendationActionLink = async (recommendationActionLink) => {
  const { getQuery } = generateGraphql("RecommendationActionLink", ["__typename"]);

  return await deleteItem(getQuery, recommendationActionLink);
};
