import { isNullOrUndefined } from "@rivial-security/func-utils";
import { DocumentPreview } from "../../../../../hooks/views/useDocumentGrid/customFields/DocumentPreview";
import { Alert } from "@mui/material";

/**
 * The normal document viewer configured to display evidence documents from an evidence activity
 * @param {string} organizationID - the currently selected organization ID
 * @param {object} document - the document portion of evidence activity
 * @returns {JSX.Element}
 */
const EvidenceDocumentViewer = ({ organizationID, document }) => {
  if (!isNullOrUndefined(document?.file)) {
    return <DocumentPreview document={document} organizationID={organizationID} />;
  } else {
    return <Alert severity="warning">Unsupported document type found</Alert>;
  }
};

export default EvidenceDocumentViewer;
