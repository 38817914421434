import { createText } from "@rivial-security/report-utils";
import { createTable } from "@rivial-security/report-utils";
import { createBlankLine } from "@rivial-security/report-utils";
import { createInlineText } from "@rivial-security/report-utils";

export const generateControlCategoriesTable = (controlCategories) => {
  // Generate custom text for custom fields of the table

  // same sorting algorithm that's used in fargate > reports > VendorReviewControlsReport > functions > generateControlCategoryTable.js
  const sortedCategories = controlCategories.sort((a, b) => parseInt(a.name) - parseInt(b.name));

  const getText = (field) => {
    switch (field) {
      case "X":
        return createText("X", "Normal", {
          fontSize: 14,
          fontColor: "#FF0000FF",
          bold: true,
          fontFamily: "Segoe UI Symbol",
          textAlignment: "Center",
        });
      case "✓":
        return createText("✓", "Normal", {
          fontSize: 14,
          fontColor: "#008000FF",
          bold: true,
          fontFamily: "Segoe UI Symbol",
          textAlignment: "Center",
        });
      case "-":
        return createText("-", "Normal", {
          fontSize: 14,
          fontColor: "#F79646FF",
          bold: true,
          fontFamily: "Segoe UI Symbol",
          textAlignment: "Center",
        });
    }
  };

  //Generate table components
  return [
    //Table
    createTable({
      fields: ["name", "inPlace", "audited", "notes", "details"],
      fieldNameDictionary: {
        name: "Control Category",
        inPlace: "In Place",
        audited: "Audited",
        notes: "Notes",
        details: "Details",
      },
      items: sortedCategories,
      customFields: [
        {
          field: "inPlace",
          format: "sfdt",
          function: ({ inPlace }) => getText(inPlace),
        },
        {
          field: "audited",
          format: "sfdt",
          function: ({ audited }) => getText(audited),
        },
      ],
      headerConfig: {
        textAlignment: "Center",
        bold: true,
      },
    }),
    createBlankLine(),

    //Legend
    createText("Legend", "Normal", { underline: true }),
    createText([
      createInlineText("✓", "Normal", {
        fontSize: 18,
        fontColor: "#008000FF",
        bold: true,
        fontFamily: "Segoe UI Symbol",
      }),
      createInlineText("     in place / audited"),
    ]),
    createText([
      createInlineText("X", "Normal", {
        fontSize: 18,
        fontColor: "#FF0000FF",
        bold: true,
        fontFamily: "Segoe UI Symbol",
      }),
      createInlineText("     not in place / not audited"),
    ]),
    createText([
      createInlineText("-", "Normal", {
        fontSize: 18,
        fontColor: "#F79646FF",
        bold: true,
        fontFamily: "Segoe UI Symbol",
      }),
      createInlineText("      partially in place"),
    ]),
  ];
};
