import { ErrorLogger } from "@utils/EventLogger";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { generateGraphql } from "@rivial-security/generategraphql";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 *
 * @param {object[]} contacts
 * @param {object} newMeeting
 * @param {string} organizationID
 * @return {object} {Promise<void>}
 */
export const attachContactsToMeeting = async ({ contacts = [], newMeeting, organizationID }) => {
  if (
    isNullOrUndefined(contacts) ||
    isNullOrUndefined(newMeeting) ||
    isNullOrUndefined(newMeeting.id) ||
    organizationID === ""
  ) {
    throw new Error("[attachContactsToMeeting.js] Invalid function input");
  }

  const { createMutation: createPointOfContactMeetingLink } = generateGraphql("PointOfContactMeetingLink");

  for (const contact of contacts) {
    await ItemMutation(createPointOfContactMeetingLink, {
      ownerGroup: organizationID,
      pointOfContactMeetingLinkMeetingId: newMeeting.id,
      pointOfContactMeetingLinkPointOfContactId: contact.id,
    }).catch((err) => {
      ErrorLogger("[attachContactsToMeeting.js] Cannot create PointOfContactMeetingLink", err);
    });
  }
};
