import { NotFixedStatusChangeTrend } from "../../../../../../typedefs/Testing/Vulnerability/Vulnerability";
import React from "react";
import { TargetVulnerabilityStatus } from "@rivial-security/schema-types";
import { convertCamelCaseToSentence } from "@rivial-security/func-utils";

/**
 * Component to display the status counts trend in the timeline content when the timeline is grouped by assessment
 * @param {object} statusCounts - the status counts
 * @param {boolean} isFindingAttached - TRUE if history is viewed from the target side (such as target details
 * @returns {JSX.Element|null}
 */
const StatusCountsTrend = ({ statusCounts, isFindingAttached }) => {
  const fixedCount = statusCounts[TargetVulnerabilityStatus.FIXED]?.size || 0;
  const exceptionCount = statusCounts[TargetVulnerabilityStatus.EXCEPTION]?.size || 0;
  const notFixedCount = statusCounts[TargetVulnerabilityStatus.NOT_FIXED]?.size || 0;

  const fixedTotal = fixedCount + exceptionCount;
  let trend = NotFixedStatusChangeTrend.NOT_CHANGED;
  if (notFixedCount > fixedTotal) {
    trend = NotFixedStatusChangeTrend.INCREASED;
  } else if (notFixedCount < fixedTotal) {
    trend = NotFixedStatusChangeTrend.DECREASED;
  }

  const getCountElements = () => {
    const countElements = [];
    if (fixedCount > 0) {
      countElements.push(
        <span key="fixed" style={{ color: "green" }}>
          {fixedCount} Fixed
        </span>,
      );
    }
    if (exceptionCount > 0) {
      countElements.push(
        <span key="exception" style={{ color: "orange" }}>
          {exceptionCount} Exception
        </span>,
      );
    }
    if (notFixedCount > 0) {
      countElements.push(
        <span key="notFixed" style={{ color: "red" }}>
          {notFixedCount} Not Fixed
        </span>,
      );
    }

    if (countElements.length === 0) {
      return null;
    }

    return (
      <span>
        (
        {countElements.map((countElement, index) => {
          return (
            <span key={index}>
              {countElement}
              {index < countElements.length - 1 ? ", " : ""}
            </span>
          );
        })}
        )
      </span>
    );
  };

  const getTrendColor = () => {
    switch (trend) {
      case NotFixedStatusChangeTrend.DECREASED:
        return "green";
      case NotFixedStatusChangeTrend.INCREASED:
        return "red";
      default:
        return "orange";
    }
  };

  return (
    <>
      and{" "}
      <span
        style={{
          color: getTrendColor(),
        }}
      >
        {trend === NotFixedStatusChangeTrend.NOT_CHANGED
          ? "hasn't changed"
          : convertCamelCaseToSentence(trend).toLowerCase()}
      </span>{" "}
      the number of not fixed {isFindingAttached ? " Vulnerabilities " : " Targets "} {getCountElements()}
    </>
  );
};

export default StatusCountsTrend;
