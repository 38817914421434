import { ErrorLogger } from "@utils/EventLogger";
import { getResourceAttribute } from "../getResourceAttribute";

/**
 * Generates a object that can be passed into generateGraphql nestedFields argument, a map of query fields to their nested fields
 * @param {string} typename - the root typename of the query
 * @param {string[]} queryFields - the fields to include in the query and for which to get nested fields
 * @param {string} fieldContext - location of where query is used (grid, detail, etc)
 * @returns {{}|null}
 */
export const getResourceNestedFields = ({ typename, queryFields, fieldContext }) => {
  //Check arguments
  if (!typename || !Array.isArray(queryFields) || queryFields.length === 0 || !fieldContext) {
    ErrorLogger("Invalid arguments passed to getResourceNestedFields", {
      typename,
      queryFields,
      fieldContext,
    });
    return null;
  }

  const resourceFields = getResourceAttribute({
    typename,
    attribute: "fields",
  });

  const nestedFields = {};
  for (const queryField of queryFields) {
    // - get the default nested fields
    const fieldDefinition = resourceFields[queryField];
    let currentNestedFields = fieldDefinition?.default?.nestedFields;

    // - check if there are any context specific nested fields
    const contextNestedFields = fieldDefinition?.[fieldContext]?.nestedFields;
    if (contextNestedFields) {
      currentNestedFields = contextNestedFields;
    }

    // - if nested fields exists add them to the nestedFields object
    if (currentNestedFields) {
      nestedFields[queryField] = currentNestedFields;
    }
  }

  return nestedFields;
};
