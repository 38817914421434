import { useCustomQueryDetails } from "../hooks/useCustomQueryDetails";
import { withDetailsPage } from "../../../utils/Context/withDetailsPage";

/**
 * A component instance of the useCustomQueryDetails.js hook
 * @param props
 */
const CustomQueryDetails = (props) => {
  return useCustomQueryDetails(props).display;
};

export default withDetailsPage(CustomQueryDetails, {
  title: "Custom Query Details",
});
