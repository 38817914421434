import { GenericDeleteGQL } from "../../../../utils/Functions/Graphql/GenericDeleteGQL";
import { InfoLogger } from "@utils/EventLogger";
import { QueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 * Deletes an exercise object from the database.
 * Also removes responseTeam many to many connections
 * @param {object} exercise - the exercise to remove
 * @param {string} exercise.id - the ID  of the exercise to remove
 * @returns {Promise<null|void>}
 */
export const deleteExercise = async (exercise) => {
  if (!exercise || !exercise.id) {
    return null;
  }

  // query to gather all resources that need deletion
  const { getQuery: resourcesToDelete } = generateGraphql("Exercise", ["__typename", "responseTeams"], {
    responseTeams: `(limit: 1000) {
        items {
          id
          __typename
        }
     }`,
  });

  return await QueryGetItem({
    query: resourcesToDelete,
    itemId: exercise.id,
  }).then(async (item) => {
    InfoLogger(`Deleting exercise - ${item?.id}`);
    await GenericDeleteGQL({ objectToDelete: item });
  });
};
