import { modules, resources } from "@rivial-security/role-utils";

import { EVIDENCE_STATUS } from "../../../../../typedefs/Compliance/Evidence/Evidence";
import EvidenceStatusBadge from "../EvidenceStatusBadge";
import GenericEditFieldV3 from "../../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";
import React from "react";
import { enumToDropdownData } from "@rivial-security/func-utils";
import { onEvidenceStatusChange } from "../../functions/onEvidenceStatusChange";
import { updateEvidence } from "../../graphql/updateEvidence";
import { useOrganizationContext } from "../../../../AdminPanel/Organizations/hooks/useOrganizationContext";
import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";

/**
 * Custom field for displaying Evidence Status data
 * @param {string} organizationID - selected organization id
 * @param {string} module - the module to use for role checking
 * @param {string} resource - the resource to use for role checking
 * @param {boolean} disableRoleChecking - if TRUE will disable role checking
 * @param {object} item - object with other info pertaining to this evidence
 * @param {EvidenceStatus} status - current evidence status
 * @param {function} resetFunction - function to re-fetch data from database
 * @param {function} setActivityResetIndex - triggers a re-fetch of the evidence activities
 * @param {function} updateItemById - function to update item in the grid
 * @param {object} ...props - other props to pass to the GenericEditFieldV3 component
 */
const EvidenceStatusDropdown = ({
  organizationID,
  module = modules.COMPLIANCE,
  resource = resources.EVIDENCE,
  disableRoleChecking = false,
  item,
  status,
  resetFunction,
  setActivityResetIndex,
  updateItemById,
  ...props
}) => {
  const { loggedInUserId, loggedInPointOfContactId } = useOrganizationContext();

  const callback = async (updatedItem) => {
    /**
     * Create an Evidence Activity item when an Evidence status changes
     */
    if (
      updatedItem?.status !== EVIDENCE_STATUS.EXPIRED ||
      updatedItem?.status !== EVIDENCE_STATUS.EXPIRING_SOON ||
      !updatedItem?.enabled
    ) {
      await onEvidenceStatusChange({
        evidenceID: item?.id,
        oldStatus: item?.status,
        newStatus: updatedItem?.status,
        pointOfContactID: loggedInPointOfContactId,
        userID: loggedInUserId,
        organizationID,
      }).then(
        () =>
          typeof setActivityResetIndex === "function" &&
          setActivityResetIndex((activityResetIndex) => activityResetIndex + 1),
      );
    }

    if (typeof updateItemById === "function") {
      updateItemById(updatedItem);
    } else if (typeof resetFunction === "function") {
      resetFunction();
    }
  };

  /**
   * Check if user wants to send a notification
   * Only prompts if Automations are enabled
   */
  const updateInput = async (input) => {
    if (item?.enabled && (input === EVIDENCE_STATUS.EXPIRING_SOON || input === EVIDENCE_STATUS.EXPIRED)) {
      if (
        window.confirm(
          `This status change will send a notification for each point of contact to upload evidence. Do you want to continue?`,
        )
      ) {
        return input;
      }
    } else {
      return input;
    }
  };

  return (
    <GenericEditFieldV3
      item={item}
      module={module}
      resource={resource}
      disableRoleChecking={disableRoleChecking}
      field={"status"}
      inputType={"dropdown"}
      inputConfig={{
        data: enumToDropdownData({
          ENUM: EVIDENCE_STATUS,
          overrides: {
            [EVIDENCE_STATUS.EXPIRED]: "Not In Place",
          },
        }),
      }}
      mutation={updateEvidence}
      customDisplayComponent={<EvidenceStatusBadge item={item} />}
      updateItemById={callback}
      updateInputFunction={updateInput}
      {...props}
    />
  );
};

export default withOrganizationCheck(EvidenceStatusDropdown);
