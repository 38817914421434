import { isNonEmptyArray, isNullOrUndefined } from "@rivial-security/func-utils";
import { useContext, useState } from "react";

import DataLoader from "../../../../utils/LoadingComponents/DataLoader";
import { ListQueryBy } from "../../../../utils/Functions/Graphql/ListQueryBy";
import { OrganizationContext } from "../../../../utils/Context/OrganizationContext";
import { VULNERABILITY_SEVERITY_LEVEL } from "../../../../typedefs/Testing/Vulnerability/Vulnerability";
import { cloneDeep } from "lodash";
import { useBarChart } from "../../../../hooks/charts/useBarChart";
import { useSetAsyncData } from "../../../../hooks/functional/useSetAsyncData";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 * Display bar chart of Findings by the severity level
 * @param {string} organizationID - selected organization
 * @param {object[]} externalVulnerabilities - array of vulnerabilities to use instead of performing a query
 * @param {object} [queryConfig = {}]- query config
 * @param {boolean} [disableAnimations = false] - disable animations for the chart if TRUE
 * @param {boolean} [isLoading = false] - TRUE if data is loading and should display loading components
 * @returns {JSX.Element}
 */
const TestingVulnerabilitiesBySeverityLevel = ({
  organizationID,
  vulnerabilities: externalVulnerabilities,
  queryConfig,
  disableAnimations = false,
  isLoading = false,
}) => {
  const [graphData, setGraphData] = useState([]);

  const context = useContext(OrganizationContext);
  const showInfoVulnerabilities = context?.preferences?.testing?.showInfoVulnerabilities;

  const chart = useBarChart(graphData, ["count"], "severityLevel", {
    isCustomColors: true,
    disableAnimations,
    disableLegends: true,
    axisBottom: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "Severity Level",
      legendPosition: "middle",
      legendOffset: 32,
      truncateTickAt: 0,
    },
    axisLeft: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "Count",
      legendPosition: "middle",
      legendOffset: -40,
      truncateTickAt: 0,
    },
  });

  const staticGraphData = [
    {
      type: VULNERABILITY_SEVERITY_LEVEL.HIGH,
      severityLevel: "High",
      count: 0,
      color: "hsl(0,100%,60%)",
    },
    {
      type: VULNERABILITY_SEVERITY_LEVEL.MEDIUM,
      severityLevel: "Medium",
      count: 0,
      color: "hsl(24,97%,57%)",
    },
    {
      type: VULNERABILITY_SEVERITY_LEVEL.LOW,
      severityLevel: "Low",
      count: 0,
      color: "hsl(139,100%,36%)",
    },
    {
      type: VULNERABILITY_SEVERITY_LEVEL.INFO,
      severityLevel: "Info",
      count: 0,
      color: "hsl(217,100%,61%)",
    },
  ];

  useSetAsyncData({
    getData: async () => {
      let vulnerabilities = externalVulnerabilities;
      if (!externalVulnerabilities) {
        vulnerabilities = await ListQueryBy({
          query: listVulnerabilitiesByOwnerGroup,
          limit: 1000,
          variables: {
            ownerGroup: organizationID,
          },
          ...(!showInfoVulnerabilities &&
            !queryConfig && {
              filter: {
                severityLevel: { ne: VULNERABILITY_SEVERITY_LEVEL.INFO },
              },
            }),
          ...queryConfig,
        });
      }

      return vulnerabilities;
    },
    setData: (vulnerabilities) => {
      const severityCounts = {
        [VULNERABILITY_SEVERITY_LEVEL.HIGH]: 0,
        [VULNERABILITY_SEVERITY_LEVEL.MEDIUM]: 0,
        [VULNERABILITY_SEVERITY_LEVEL.LOW]: 0,
        [VULNERABILITY_SEVERITY_LEVEL.INFO]: 0,
      };

      if (!isNonEmptyArray(vulnerabilities)) {
        return;
      }

      vulnerabilities.map((vulnerability) => {
        const severityLevel = vulnerability.severityLevel;
        if (!isNullOrUndefined(severityCounts[severityLevel])) {
          severityCounts[severityLevel]++;
        }
      });

      const newGraphData = cloneDeep(staticGraphData);
      for (const graphEntry in newGraphData) {
        const entrySeverityLevel = newGraphData[graphEntry].type;
        if (severityCounts[entrySeverityLevel]) {
          newGraphData[graphEntry].count = severityCounts[entrySeverityLevel];
        }
      }

      //Remove info entry if info severity is not shown
      if (!showInfoVulnerabilities) {
        const infoIndex = newGraphData.findIndex((entry) => entry.type === VULNERABILITY_SEVERITY_LEVEL.INFO);
        if (infoIndex !== -1) {
          newGraphData.splice(infoIndex, 1);
        }
      }

      setGraphData(newGraphData);
    },
    dependencies: [externalVulnerabilities],
  });

  return (
    <DataLoader isEnoughData={true} isLoading={isLoading}>
      {chart.display}
    </DataLoader>
  );
};

const { listQueryBy: listVulnerabilitiesByOwnerGroup } = generateGraphql(
  "Vulnerability",
  ["severityLevel"],
  {},
  {
    indexName: "vulnerabilitiesByOwnerGroup",
  },
);

export default withOrganizationCheck(TestingVulnerabilitiesBySeverityLevel);
