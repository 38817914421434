import { modules, resources } from "@rivial-security/role-utils";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import React from "react";
import NestedDocumentsCard from "../../../Compliance/Documents/components/NestedDocumentsCard";

/**
 * Displays Documents for an Action Item
 * @param {object} item - the action item to show documents for
 * @param {string} organizationID - current organization ID
 * @returns {JSX.Element}
 */
const ActionItemDocuments = ({ item, organizationID }) => {
  return (
    <NestedDocumentsCard
      item={item}
      module={modules.GOVERNANCE}
      resource={resources.ACTION_ITEM}
      parentTypename={resources.ACTION_ITEM}
      connectionIDField={"actionItemID"}
      organizationID={organizationID}
    />
  );
};

export default withOrganizationCheck(ActionItemDocuments);
