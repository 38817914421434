import React, { useEffect } from "react";
import { useState } from "react";
import { useSetAsyncData } from "../../../../../hooks/functional/useSetAsyncData";
import ArtifactFileCustomField from "../components/ArtifactFileCustomField";
import ArtifactPointOfContactCustomField from "../components/ArtifactPointOfContactCustomField";
import ArtifactTypeCustomField from "../components/ArtifactTypeCustomField";
import DateField from "../../../../../utils/CustomFields/DateField";
import { getArtifact } from "../functions/getArtifact";
import { useDetailsCard } from "../../../../../hooks/views/useDetailsCardV2";
import { isNonEmptyArray } from "@rivial-security/func-utils";
import { cloneDeep } from "lodash";
import { modules, resources } from "@rivial-security/role-utils";

/**
 * Displays the general details of a single artifact
 * @param {string} itemId - the id of the artifact to display
 * @param {string} module - compliance module for role checking
 * @param {string} resource - artifact resource for role checking
 * @returns {{display: *}}
 */
export const useArtifactDetails = ({ itemId, module = modules.COMPLIANCE, resource = resources.ARTIFACT }) => {
  const [databaseItem, setDatabaseItem] = useState(null);
  const [item, setItem] = useState(null);
  const [hideAIBookmarks, setHideAIBookmarks] = useState(true);

  const fields = ["pointOfContact", "uploadedAt", "type"];
  const fieldNameDictionary = {
    pointOfContact: "Point of Contact",
    uploadedAt: "Upload Time",
    type: "Type",
    file: "File Info",
  };

  const customFields = [
    {
      field: "file",
      component: <ArtifactFileCustomField />,
    },
    {
      field: "pointOfContact",
      component: <ArtifactPointOfContactCustomField />,
    },
    {
      field: "type",
      component: <ArtifactTypeCustomField />,
    },
    {
      field: "uploadedAt",
      component: <DateField fieldName={"uploadedAt"} />,
    },
  ];

  const details = useDetailsCard({
    item,
    module,
    resource,
    fields,
    customFields,
    fieldNameDictionary,
    tableDisplay: true,
  });

  //Retrieve data on page open
  const { isLoading, resetFunction } = useSetAsyncData({
    getData: async () => {
      return await getArtifact({ id: itemId });
    },
    setData: (artifact) => {
      const newItem = {
        pointOfContact: artifact?.evidenceActivity?.pointOfContact,
        uploadedAt: artifact?.evidenceActivity?.createdAt,
        type: artifact?.evidenceActivity?.type,
        ...artifact,
      };
      details.setItem(newItem);
      setDatabaseItem(newItem);
    },
    dependencies: [itemId],
  });

  //On database item or filter change apply filters and update the displayed item
  useEffect(() => {
    const newItem = cloneDeep(databaseItem);

    //Hide all AI generated bookmarks if they are disabled
    let newLabelLinks = newItem?.labels?.items;
    if (hideAIBookmarks) {
      newLabelLinks = newItem?.labels?.items;

      if (isNonEmptyArray(newLabelLinks)) {
        for (const labelLink of newLabelLinks) {
          const bookmarks = labelLink?.bookmarks;
          if (isNonEmptyArray(bookmarks)) {
            labelLink.bookmarks = bookmarks.filter((bookmark) => bookmark?.createdByAI !== true);
          }
        }
      }
    }

    setItem(newItem);
    details.setItem(newItem);
  }, [databaseItem, hideAIBookmarks]);

  return {
    ...details,
    hideAIBookmarks,
    setHideAIBookmarks,
    item,
    setItem: setDatabaseItem,
    isLoading: isLoading,
    resetFunction: resetFunction,
  };
};
