import { useGroupedRiskChangeDetails } from "../hooks/useGroupedRiskChangeDetails/useGroupedRiskChangeDetails";
import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";

/**
 * A component instance of the useGroupedRiskChangeDetails.js hook
 * @param {object} props
 */
const GroupedRiskChangeDetails = (props) => {
  return useGroupedRiskChangeDetails(props).display;
};

export default withDetailsPage(GroupedRiskChangeDetails);
