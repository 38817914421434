import React, { useContext, useEffect } from "react";
import useListQuery from "../graphql/useListQuery";
import { useDataCard } from "./useDataCard";
import { useCheckPermissions } from "../permissions/useCheckPermissions/useCheckPermissions";
import { DontHavePermission } from "../../utils/AccessControl/components/DontHavePermission";
import { OrganizationContext } from "../../utils/Context/OrganizationContext";
import { accessResourcesByRole } from "./useDataGrid/functions/accessResourcesByRole";

/**
 * Author: Jacob Blazina
 *
 * Description: Combines the useDataCard and useListQuery hooks to create a
 *              comprehensive component that covers:
 *              - Table Display
 *              - Pagination
 *              - Searching
 *              - Filtering
 *              - Sorting
 *              - Querying
 *              - Query "refresh"
 *              - Subscriptions
 *              - Select boxes
 *
 *              These hooks are connected using Composition patterns and each one can be fully customized.
 *
 * @param queryConfig
 *          Config object passed through to useListQuery hook. See [useListQuery.js] documentation.
 * @param dataCardConfig
 *          Config object passed through to useDataCard hook. See [useDataCard.js] documentation.
 * @param {object} config -
 *          Misc. Config object used for this hook.
 *          Note: queryConfig and dataCardConfig contain their own respective config objects.
 *          Current Settings: {
 *            showMiniLoading,    // use this to default the loading UI behavior to the mini loader
 *            showFullLoading     // use this to default the loading UI behavior to the full loader
 *          }
 * @param module
 * @param resource
 * @param fields
 * @param typename
 * @param nestedFields
 * @param disableRoleChecking
 * @returns
 * NOTE: see useDataCard.js and useListQuery.js documentation
 * {{
 *    display: *,
 *    paginatedData,
 *    setLimit,
 *    setSortField: *,
 *    organizationId: *,
 *    setOrganizationId:
 *    setOrganizationId,
 *    reset: *,
 *    setData: *,
 *    setPaginatedData: *,
 *    loading,
 *    setLoading,
 *    miniLoading,
 *    setMiniLoading,
 *    limit,
 *    data,
 *    tableData: *,
 *    selectedItems: *,
 *    setSelectedItems: *,
 *    setItems: *,
 *    showSelectBoxes: *,
 *    setShowSelectBoxes: *,
 *    addFilter: *,
 *    filters: *,
 *    setFilters: *,
 *    setQueryFilters: (setOtherFilters|Function),
 *    isLoading
 * }}
 */

export const useQueryCard = ({
  queryConfig,
  dataCardConfig,
  config = {},
  module,
  resource,
  fields,
  typename,
  nestedFields,
  disableRoleChecking = false,
}) => {
  const checkPermissionsHook = useCheckPermissions({
    module: module,
    resource: resource,
    disableRoleChecking,
  });

  const { showMiniLoading = true, showFullLoading = false } = config;

  const context = useContext(OrganizationContext);

  // Temporarily disabling Auto-Subscriptions. This needs to be an optional setting
  // const subscription = useSubscription({
  //   module,
  //   resource,
  //   organizationID: queryConfig.organizationID,
  //   onUpdateSubscription: generateGraphql(typename, fields, nestedFields).onUpdate,
  //   onCreateSubscription: generateGraphql(typename, fields, nestedFields).onCreate,
  //   onDeleteSubscription: generateGraphql(typename, fields, nestedFields).onDelete
  // });

  const queryHook = useListQuery({
    module,
    resource,
    fields,
    typename,
    nestedFields,
    disableRoleChecking,
    ...queryConfig,
  });
  const dataCardHook = useDataCard({
    disableRoleChecking,
    resetFunction: queryHook.reset,
    module,
    resource,
    fields: fields || dataCardConfig.fields,
    typename,
    organizationID: queryConfig.organizationID,
    ...dataCardConfig,
  });

  useEffect(() => {
    /**
     * Check if the array from DB is not empty
     */
    if (queryHook?.list && Array.isArray(queryHook.list) && queryHook.list.length > 0) {
      /**
       * Check if a user have restrictions to view only allowed resources
       */
      const array = accessResourcesByRole({
        array: queryHook?.list,
        resource,
        role: context?.role,
      });
      dataCardHook.setData([...array]);
    }
  }, [queryHook?.list]);

  useEffect(() => {
    showFullLoading && dataCardHook.setLoading(queryHook.isLoading);
    showMiniLoading && dataCardHook.setMiniLoading(queryHook.isLoading);
  }, [queryHook.isLoading]);

  const display =
    checkPermissionsHook.module.isEnabled && checkPermissionsHook.resource.read ? (
      dataCardHook.display
    ) : (
      <DontHavePermission module={module} resource={resource} />
    );

  return {
    ...dataCardHook,
    ...queryHook,
    display,
    resetFunction: queryHook.reset,
    paginatedData: queryHook.listPageArray,
    setOrganizationId: queryHook.setOrganizationId,
    loading: dataCardHook.loading,
    setLoading: dataCardHook.setLoading,
    data: dataCardHook.searchResults, //DEPRECATED use searchResults instead
    searchResults: dataCardHook.searchResults,
    tableData: dataCardHook.data,
    selectedItems: dataCardHook.selectedItems,
    setSelectedItems: dataCardHook.setSelectedItems,
    setItems: dataCardHook.setItems,
    showSelectBoxes: dataCardHook.showSelectBoxes, // bool
    setShowSelectBoxes: dataCardHook.setShowSelectBoxes, // func
    addFilter: dataCardHook.addFilter,
    filters: dataCardHook.filters,
    setFilters: dataCardHook.setFilters,
    setQueryFilters: queryHook.setOtherFilters, //deprecated. use setOtherFilters instead
  };
};
