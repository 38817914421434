import IconArrow from "../../../../assets/svg/others/icon-arrow.svg";
import { ReactElement } from "react";
import styles from "../Icons.module.scss";

export const IconArrowUp = (): ReactElement => {
  return <IconArrow />;
};

export const IconArrowRight = (): ReactElement => {
  return <IconArrow className={styles.right} />;
};

export const IconArrowDown = (): ReactElement => {
  return <IconArrow className={styles.down} />;
};

export const IconArrowLeft = (): ReactElement => {
  return <IconArrow className={styles.left} />;
};
