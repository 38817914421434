import { CustomInput } from "reactstrap";
import React from "react";
import { useGUID } from "../../../../hooks/functional/useGUID";

/**
 * @description Custom switch component for boolean values
 * @param {string} field - field name
 * @param {boolean} value - current value
 * @param {function} setValue - function to set new value
 * @param {object} inputConfig - configuration for switch
 * @returns {{display: JSX.Element}}
 */
export const useCustomSwitch = ({ field = "", value, setValue, inputConfig = {} }) => {
  //Setup
  const [guid] = useGUID();

  //Destructuring config
  const { label, disabled, type } = inputConfig;

  const display = (
    <CustomInput
      disabled={disabled}
      id={guid}
      data-testid={`genericEditField-custom-switch-${field}`}
      checked={value}
      onClick={() => setValue(!value)}
      type={type || "switch"}
      name={`customSwitch${guid}`}
      label={label}
    />
  );

  return {
    display,
  };
};
