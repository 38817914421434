import { useAutomationDetails } from "../../OrganizationManager/Automations/hooks/useAutomationDetails/useAutomationDetails";
import { withDetailsPage } from "../../../utils/Context/withDetailsPage";

/**
 * @description Component to display details for a single Automation Template
 * @param {object} props - any additional props passed through to the details card
 * @return {JSX.Element}
 */
const AutomationTemplateDetails = (props) => useAutomationDetails(props).display;

export default withDetailsPage(AutomationTemplateDetails);
