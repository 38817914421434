import { usePreferences } from "@hooks/views/usePreferences/usePreferences";
import { UIContext } from "@utils/Context/UIContext";
import { useContext } from "react";
export interface SubmitEvidenceEmailProps {
  evidenceEmailOptIn: boolean;
}

export interface EvidenceEmailReturnType {
  getValue: () => null | boolean;
  setValue: (evidenceEmailOptIn: boolean) => Promise<void>;
}

export const useEvidenceEmailPreference = (): EvidenceEmailReturnType => {
  const preferences = usePreferences();
  const { layoutToastsHook } = useContext(UIContext);

  const getValue = (): null | boolean => {
    return preferences.getPreference("interfaceOptions", "evidenceEmailOptIn") as null | boolean;
  };
  const setValue = async (evidenceEmailOptIn: boolean): Promise<void> => {
    try {
      await preferences.setPreference("interfaceOptions", { evidenceEmailOptIn });

      layoutToastsHook.addToast({
        header: `You ${evidenceEmailOptIn ? "will" : "won't"} receive emails when evidence is due`,
        icon: "success",
      });
    } catch (e) {
      layoutToastsHook.addToast({
        header: `Error! Email Preference was NOT changed`,
        icon: "danger",
      });

      throw new Error("[useEvidenceEmailPreference.tsx] Can not set Preferences");
    }
  };

  return {
    getValue,
    setValue,
  };
};
