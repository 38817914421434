import React, { useEffect, useState } from "react";
import { useModal } from "../../useModal";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { Button } from "reactstrap";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { IconButton } from "@mui/material";
/**
 * Used to select an item from a grid component
 * @param {string} value - the id of selected item
 * @param {boolean} disabled - if true won't enable the select button
 * @param {function} onChange - function that's called when a new item is selected
 * @param {object} itemSelectConfig - configurations for this input
 * @param {JSX.Element} itemSelectConfig.grid - Select[Resource]Grid.js component with overridable grid configuration
 * @param {string} itemSelectConfig.typename - the graphql model name of the item being selected
 * @param {function} itemSelectConfig.formatSelectedItem - a function that formats the name for display in the form
 * @param {boolean} itemSelectConfig.hideSelectButton - if TRUE will hide select button when an item is already selected (for a cleaner look)
 * @return {JSX.Element}
 */
const CustomItemSelect = ({ value = null, disabled, onChange, itemSelectConfig = {} } = {}) => {
  const [currentItem, setCurrentItem] = useState(value ? { id: value } : null);
  const [currentFullItem, setCurrentFullItem] = useState(null);
  const [currentItemName, setCurrentItemName] = useState("");

  //Update the item text in the form
  useEffect(() => {
    let correctCurrentItem = currentFullItem;
    if (currentItem?.id !== currentFullItem?.id) {
      correctCurrentItem = currentItem;
    }

    if (correctCurrentItem) {
      if (itemSelectConfig?.formatSelectedItem) {
        setCurrentItemName(itemSelectConfig?.formatSelectedItem(correctCurrentItem));
      } else {
        setCurrentItemName(correctCurrentItem?.name || correctCurrentItem?.id || "An Unknown Item");
      }
    } else {
      setCurrentItemName(null);
    }
  }, [currentItem]);

  // Makes sure the form input is updated with default values if existing
  useEffect(() => {
    if (typeof value === "string" && value !== "") {
      setCurrentItem({ id: value });
    } else if (typeof value === "object") {
      setCurrentItem(value);
    }
  }, [JSON.stringify(value)]);

  //Modal with grid of items that can be selected
  const selectModal = useModal(
    itemSelectConfig?.modal?.header || "Select an item",
    !isNullOrUndefined(itemSelectConfig?.grid) && (
      <div style={{ height: "50em" }}>
        {React.cloneElement(itemSelectConfig?.grid, {
          // useGrid hook (legacy)
          enableSelectButtons: true,
          selectButtonCallback: (item) => {
            setCurrentFullItem(item);
            if (itemSelectConfig?.outputAsObject) {
              onChange && onChange(item);
            } else {
              onChange && onChange(item?.id);
            }
            setCurrentItem(item);
            selectModal.setModalIsOpen(false);
          },

          // useDataGrid hook (current)
          enableSelectButton: true,
          onSelectCallback: (item) => {
            setCurrentFullItem(item);
            if (itemSelectConfig?.outputAsObject) {
              onChange && onChange(item);
            } else {
              onChange && onChange(item?.id);
            }

            if (typeof itemSelectConfig?.callback === "function") {
              itemSelectConfig.callback(item);
            }

            setCurrentItem(item);
            selectModal.setModalIsOpen(false);
          },
        })}
      </div>
    ),
    <Button size={"sm"} color={"primary"} disabled={disabled} style={{ width: "15em" }}>
      {!value ? "Select an Item" : "Select a new Item"}
    </Button>,
    {
      width: "70vw",
    },
  );

  //Return the modal button and text of current item
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {currentItemName && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: ".5em",
          }}
        >
          <p style={{ margin: 0 }}>
            <span style={{ fontWeight: "bold" }}>Selected: </span>
            <span>{currentItemName}</span>
          </p>
          <IconButton
            size={"small"}
            color={"error"}
            onClick={() => {
              onChange(null);
              setCurrentItem(null);
            }}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </div>
      )}
      {(!itemSelectConfig?.hideSelectButton || !currentItemName) && selectModal.modalButton}
    </div>
  );
};

export default CustomItemSelect;
