import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useRadarChart } from "../../../hooks/charts/useRadarChart";
import { round } from "../../../utils/Functions/Number/round";
import { getMaturityLevel } from "./functions/getMaturityLevel";

const MaturityLevelRadarChart = (props) => {
  const [maturityMatrix, setMaturityMatrix] = useState(null);
  const [data, setData] = useState([]);
  const [keys, setKeys] = useState([]);
  const [indexBy, setIndexBy] = useState("governance");

  useEffect(() => {
    if (props.maturityMatrix && props.maturityMatrix.id) {
      setMaturityMatrix(props.maturityMatrix);
    }
  }, [props.maturityMatrix]);

  useEffect(() => {
    if (maturityMatrix) {
      const [data, keys] = getChartData(maturityMatrix);

      setData(data);
      setKeys(keys);
    }
  }, [maturityMatrix]);

  const getChartData = (maturityMatrix) => {
    if (!maturityMatrix || !maturityMatrix.programElements || !maturityMatrix.programElements.items) {
      return null;
    }

    const data = [];
    const keys = [];
    keys.push("Current Program Blueprint Level");
    keys.push("Program Blueprint Level Goal");

    for (const element of maturityMatrix.programElements.items) {
      const obj = {};

      const [current, goal] = getMaturityLevel(element);

      obj["governance"] = element.name;
      obj["Current Program Blueprint Level"] = isNaN(current) ? 0 : round(current, 2);
      obj["Program Blueprint Level Goal"] = isNaN(goal) ? 0 : round(goal, 2);

      data.push(obj);
    }

    return [data, keys];
  };

  const radarChartHook = useRadarChart(data, keys, indexBy);

  return <>{radarChartHook.display}</>;
};

MaturityLevelRadarChart.propTypes = {
  maturityMatrix: PropTypes.object.isRequired,
};

export default MaturityLevelRadarChart;
