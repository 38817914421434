import React, { useContext, useEffect, useState } from "react";
import { WIDGET_TYPE, formatWidgetData, getWidgetData } from "@rivial-security/widget-utils";

import { CreateWidgetContext } from "../../../../../useCreateWidget";
import Loader from "../../../../../../../../../../utils/LoadingComponents/Loader";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { runCustomQueryOnLambda } from "../../../../../../../../../CustomQueries/hooks/useCustomQueryRunner/functions/runCustomQueryOnLambda";
import { tryParse } from "@rivial-security/func-utils";
import { useSetAsyncData } from "../../../../../../../../../../hooks/functional/useSetAsyncData";

/**
 * A component that displays the data that will be used in the widget as a table
 * @returns {JSX.Element}
 */
const OutputTableNode = () => {
  const { widget } = useContext(CreateWidgetContext);
  const [series, setSeries] = useState(null);
  useEffect(() => {
    const config = tryParse(widget?.config);
    setSeries(config?.series);
  }, [widget?.config]);

  const [customQueryCache, setCustomQueryCache] = useState({});
  const [data, setData] = useState([]);

  const { isLoading } = useSetAsyncData({
    getData: async () => {
      //Check if cache has the query data use that instead of performing the query
      const widgetData = await getWidgetData({
        widget,
        existingData: {
          customQueries: customQueryCache,
        },
        runCustomQuery: ({ customQueryId }) => runCustomQueryOnLambda({ customQueryID: customQueryId }),
      });
      const newCustomQueryCache = { ...(widgetData?.customQueries || {}) };

      const dataSource = formatWidgetData({
        ...widget,
        //NOTE: using pie chart data source for all widgets output table, until diverging functionality is needed
        type: WIDGET_TYPE.PIE_CHART,
        data: {
          customQueries: newCustomQueryCache,
        },
      });

      return { dataSource, newCustomQueryCache };
    },
    setData: ({ dataSource, newCustomQueryCache }) => {
      setCustomQueryCache(newCustomQueryCache);
      setData(dataSource);
    },
    dependencies: [series],
  });

  return (
    <TableContainer
      component={Paper}
      style={{
        borderRadius: "1em",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        backgroundColor: "white",
        padding: ".5em",
      }}
    >
      {/* A table with columns for series name and Series Value*/}
      <Table style={{ width: "100%" }}>
        <TableHead>
          <TableRow>
            <TableCell>Category</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        {isLoading ? (
          <div style={{ margin: ".5em" }}>
            <Loader text={"Refreshing Data..."} />
          </div>
        ) : (
          <TableBody>
            {Array.isArray(data) &&
              data?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {row?.id}
                  </TableCell>
                  <TableCell>{row?.value}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default OutputTableNode;
