import React from "react";
import { Button } from "@mui/material";
import { useModal } from "../../../hooks/views/useModal";
import CreateWidget from "../WidgetBuilder/components/CreateWidget";

/**
 * A custom modal which allows to edit the config portion of the widget
 * @param {object} widget - the entire widget object
 * @param {object} config - the config portion of the widget
 * @param {function} updateItemById - callback with item o update parents without a full refresh
 * @param {function} resetFunction - function to call to reset the parent component after editing is complete
 * @param {string} organizationID - the organization ID to use when running the query
 * @returns {JSX.Element}
 */
const CustomWidgetConfigField = ({ item: widget, config, updateItemById, resetFunction, organizationID }) => {
  const modal = useModal(
    "Edit Widget",
    <CreateWidget
      widget={widget}
      getNewItem={updateItemById}
      resetFunction={resetFunction}
      organizationID={organizationID}
    />,
    <Button variant={"contained"}>Edit Widget</Button>,
    { width: "80vw" },
  );

  return modal.modalButton;
};

export default CustomWidgetConfigField;
