"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatWidgetConfig = void 0;
const formatWidgetData_1 = require("../../formatData/formatWidgetData");
const getDefaultWidgetConfig_1 = require("./getDefaultWidgetConfig");
const func_utils_1 = require("@rivial-security/func-utils");
const getWidgetType_1 = require("../getWidgetType");
/**
 * Creates a widget config file out of all available information
 * @param {object} config - existing settings for the widget
 * @param {object} data - metric data for each metric type that is in the config (key is id of metric type)
 * @param {string} contextType - where the widget is located
 * @return {object} - a formatted widget config with default settings applied based on the context
 */
const formatWidgetConfig = async ({ widget, data, contextType }) => {
    /// [SETUP]
    // - Determine the widget type
    const type = (0, getWidgetType_1.getWidgetType)({ widget });
    // - Get the new config
    // get the original config
    const originalConfig = (0, func_utils_1.tryParse)(widget?.config ?? "{}");
    // get the default config
    const defaultConfig = (0, getDefaultWidgetConfig_1.getDefaultWidgetConfig)({ widgetType: type, contextType }) ?? {};
    // deep merge the two configs together (user config takes priority)
    let config = (0, func_utils_1.deepMergeTwoObjects)(defaultConfig, originalConfig);
    config.type = type;
    /// [DATA]
    // - Copy all built-in widget data into the config object (useful for built-in widgets)
    if (data.hasOwnProperty(type) && !(0, func_utils_1.isNullOrUndefined)(data[type])) {
        config = { ...config, ...data[type] };
    }
    const hasOriginalConfigData = !!originalConfig?.dataSource;
    // - Using available raw data and settings, format the widget to have proper dataSource property for the widget type
    // Only use formatWidgetData if originalConfig doesn't have the required data
    if (!hasOriginalConfigData) {
        config.dataSource = await (0, formatWidgetData_1.formatWidgetData)({ type, config, data });
    }
    else {
        // If originalConfig has data, use it directly
        config.dataSource = originalConfig?.dataSource ? originalConfig.dataSource : config.dataSource;
    }
    console.log("formatWidgetConfig result - ", { config });
    /// [RETURN]
    return config;
};
exports.formatWidgetConfig = formatWidgetConfig;
