import React, { useEffect, useState } from "react";
import OrganizationCheck from "../../../../../../../../../utils/Context/OrganizationCheck/OrganizationCheck";
import { Button, Input, InputGroup, InputGroupAddon } from "reactstrap";
import UpdateFieldAddon from "../../../../../../../../../utils/GenericComponents/UpdateFieldAddon";
import { useCheckPermissions } from "../../../../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { formattedDollar, formattedPercent } from "@rivial-security/func-utils";
import { updateRiskControlOverride } from "../../../../../Overrides/functions/OverrideFunctions";
import { useMutation } from "../../../../../../../../../hooks/graphql/useMutation/useMutation";

export const updateSystem = /* GraphQL */ `
  mutation UpdateSystem($input: UpdateSystemInput!) {
    updateSystem(input: $input) {
      id
      name
      ownerGroup
      riskControlOverrides {
        enabledFields
        riskControlId
        costOfControl
        controlEffectiveness
        strengthRating
        implementationRating
        outsourced
        implementationDetails
      }
    }
  }
`;

const NonStandardInputBody = ({ system, riskControl, field, resetFunction, override }) => {
  const [value, setValue] = useState(
    override && override[field] ? override[field] : field === "implementationDetails" ? null : 0.0,
  );
  const [initialValue, setInitialValue] = useState(value);
  const [enableEdit, setEnableEdit] = useState(false);

  useEffect(() => {
    if (override && override[field] !== null) {
      setValue(override[field]);
      setInitialValue(override[field]);
    }
  }, [override]);

  const mutationHook = useMutation({
    mutation: updateSystem,
    disableRoleChecking: true,
  });

  const updateSystemCallback = async ({ input }) => {
    const data = mutationHook.editItem(input);
    setTimeout(() => {
      resetFunction?.();
    }, 1000);
    return data;
  };

  const handleSubmit = () => {
    updateRiskControlOverride(system, riskControl, field, value ?? 0, updateSystemCallback);
    setEnableEdit(false);
    setInitialValue(value);
  };

  const checkPermissionsHook = useCheckPermissions({
    disableRoleChecking: true,
  });

  const handleCancel = () => {
    setEnableEdit(false);
    setValue(initialValue);
  };

  const handleOnChange = (e) => {
    const newValueString = e.target.value;
    if (newValueString === "") {
      setValue(null);
      return;
    }

    let newValue = parseFloat(newValueString);
    if (!Number.isFinite(newValue)) {
      newValue = 0;
    }

    if (newValue < 0) {
      newValue = 0;
    } else if (field === "implementationRating" && newValue > 1) {
      newValue = 1;
    }
    setValue(newValue);
  };

  const getFormattedValue = () => {
    const nonNullValue = value ?? 0;

    if (field === "costOfControl") {
      return formattedDollar(nonNullValue);
    }

    if (field === "implementationRating") {
      return formattedPercent(nonNullValue);
    }

    if (field === "implementationDetails") {
      return enableEdit ? null : value;
    }

    return nonNullValue;
  };

  return (
    <>
      <InputGroup>
        {getFormattedValue()}
        {!enableEdit && (
          <Button size="sm" className="btn-pill" color="ghost-secondary" onClick={() => setEnableEdit(true)}>
            <i className="fa fa-edit" />
          </Button>
        )}
        {enableEdit && (
          <>
            {field === "costOfControl" && <InputGroupAddon addonType="prepend">$</InputGroupAddon>}
            {field === "implementationDetails" ? (
              <Input type="textarea" value={value} onChange={(e) => setValue(e.target.value)} />
            ) : (
              <Input
                type="number"
                min={0}
                max={field === "implementationRating" ? 1 : undefined}
                step={field === "implementationRating" ? 0.01 : 1}
                disabled={!enableEdit}
                value={value}
                onChange={handleOnChange}
              />
            )}
            <UpdateFieldAddon
              enableEdit={enableEdit}
              setEnableEdit={setEnableEdit}
              handleSubmit={handleSubmit}
              handleCancel={handleCancel}
              checkPermissionsHook={checkPermissionsHook}
              fieldName={
                field === "costOfControl"
                  ? "Cost of Control"
                  : field === "implementationRating"
                    ? "Implementation Rating"
                    : "Implementation Details"
              }
            />
          </>
        )}
      </InputGroup>
    </>
  );
};

const NonStandardInput = (props) => {
  return (
    <OrganizationCheck {...props}>
      <NonStandardInputBody />
    </OrganizationCheck>
  );
};

export default NonStandardInput;
