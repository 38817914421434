import React from "react";
import parse from "html-react-parser";

const RoleChangedNotificationBody = ({ html }) => {
  return (
    <div>
      <p>The following changes have been applied to your role:</p>
      <div style={{ backgroundColor: "AliceBlue", padding: "1em" }}>{html && parse(html)}</div>
    </div>
  );
};

export default RoleChangedNotificationBody;
