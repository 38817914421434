import { useEffect } from "react";
import { useControlDataGrid } from "../../Controls/Controls/hooks/useControlGrid/useControlDataGrid";

/**
 * Populates the list of all controls for an org without including ones that are linked to an evidence
 *
 * @param {Evidence} evidence - the evidence item
 * @param {function} setItems - handler for setting the list of controls
 * @param {string} organizationID - organization ID for the query
 * @param {string} controlSetID - control set id
 */
export const usePopulateExistingControls = (evidence, setItems, organizationID, controlSetID) => {
  const controlList = useControlDataGrid({
    organizationID: organizationID,
    frameworkOrganizationID: organizationID,
    controlFrameworkID: controlSetID,
  });

  return useEffect(() => {
    const existingControls = evidence?.controls?.items.map((controlLink) => controlLink.control) || [];

    if (controlList.data && existingControls) {
      const d = [];

      for (const item of controlList.data) {
        if (existingControls?.some((a) => a?.id === item?.id)) {
          // do not add to list
        } else {
          // only show controls from the selected control set
          if (item.controlControlSetId === controlSetID) {
            d.push(item);
          }
        }
      }

      setItems(d);
      // setNotLinked(d)
    }
  }, [controlList.data, evidence, controlSetID]);
};
