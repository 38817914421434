import React, { useEffect, useState } from "react";

import ConfigureScheduleTrigger from "./ConfigureScheduleTrigger";
import ConfigureWebhookTrigger from "./ConfigureWebhookTrigger";
import { DisabledWrapper } from "../../../../utils/GenericComponents/DisabledWrapper";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useForm } from "../../../../hooks/views/useForm";
import { getAutomationTriggerType } from "../hooks/useAutomationDetails/functions/getAutomationTriggerType";

/**
 * A dropdown UI for selecting the type of Trigger for an Automation
 * @param {function} onChangeCallback - useForm field callback function
 * @param {Automation} item - an existing Automation resource, to be edited using this UI
 * @param {string} automationID - custom passed automation id
 * @param {string} itemId - item ID from a resource that will be used as the trigger for this Automation
 * @param {boolean} disableEdits - TRUE if need to disallow modifying the trigger settings
 * @param {boolean} isTemplate - if TRUE will display only templatable fields
 * @returns {*}
 */
const ConfigureTrigger = ({ onChangeCallback, item, automationID, itemId, disableEdits, isTemplate = false }) => {
  const options = [
    {
      text: "Manual Trigger",
      value: "manual",
    },
    {
      text: "Scheduled",
      value: "scheduled",
    },
    {
      text: "Resource Created",
      value: "resourceCreated",
    },
    {
      text: "Resource Updated",
      value: "resourceUpdated",
    },
    {
      text: "Webhook",
      value: "webhook",
    },
  ];

  const disabledWithTemplates = [];
  const [showWebhookForm, setShowWebhookForm] = useState(false);
  const [showSchedulerForm, setShowSchedulerForm] = useState(false);

  const onChange = (input) => {
    if (typeof onChangeCallback === "function") {
      const newInput = { ...input };
      delete newInput.id;
      onChangeCallback(newInput);
    }
  };

  const form = useForm({
    item,
    disableSubmitButton: true,
    disableRoleChecking: true,
    disableResetButton: true,
    fieldConfig: {
      type: {
        disabled: disableEdits || !isNullOrUndefined(itemId),
        inputType: "dropdown",
        defaultValue: itemId ? "resourceUpdated" : getAutomationTriggerType(item?.triggers),
        tooltip: "Select the method that will Trigger this Automation",
        onChangeFunction: onChange,
        dropdownConfig: {
          data: options.filter((option) => {
            if (isTemplate) {
              return !disabledWithTemplates.includes(option.value);
            } else {
              return true;
            }
          }),
        },
      },
      webhookUrl: {
        isHidden: !showWebhookForm || isTemplate,
        inputType: "custom",
        onChangeFunction: onChange,
        customConfig: {
          component: (
            <ConfigureWebhookTrigger item={item} automationID={item?.id || automationID} disableEdits={disableEdits} />
          ),
        },
      },
      schedule: {
        isHidden: !showSchedulerForm,
        inputType: "custom",
        onChangeFunction: onChange,
        defaultValue: item?.triggers?.[0]?.schedule,
        disabled: disableEdits,
        customConfig: {
          component: (
            <ConfigureScheduleTrigger disableEdits={disableEdits} defaultValue={item?.triggers?.[0]?.schedule} />
          ),
        },
      },
    },
  });

  useEffect(() => {
    const triggerType = form?.input?.type;
    setShowWebhookForm(triggerType === "webhook");
    setShowSchedulerForm(triggerType === "scheduled");
  }, [form.input]);

  return (
    <DisabledWrapper
      dataTestID="configure-trigger-wrapper"
      isDisabled={item?.enabled === true}
      message={"Cannot edit trigger while the automation is enabled!"}
    >
      {form.display}
    </DisabledWrapper>
  );
};

export default ConfigureTrigger;
