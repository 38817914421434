import { useWelcomeTour } from "../../../../hooks/views/useWelcomeTour/useWelcomeTour";
import React from "react";

/**
 * Welcome tour for the 'Report Details' page
 */
export const useCreateAutomationTour = () => {
  const steps = [
    {
      selector: "[data-tourid=create-automation-container]",
      title: "Automation Builder",
      content:
        "This page allows you to create custom Automations in the Rivial Platform. Automations are behind-the-scenes routines that consist of Triggers, Conditions, and Automation Steps",
    },
    {
      selector: "[data-tourid=create-automation-container]",
      title: "Enabled Automations",
      content:
        "This switch must be turned on for Automations to be able to run on their own. If the Platform detects any funky behaviour, it may automatically disable an Automation to prevent issues",
    },
    {
      selector: "[data-tourid=create-automation-container]",
      title: "Automation Triggers",
      content: (
        <span>
          Automations may be triggered in various ways: <br />
          <br />
          <strong>Manual Trigger:</strong> Gives access to a Button that will kick of Automation Steps. This ignores
          conditions, and may not be compatible with certain types of Automation Steps.
          <br />
          <br />
          <strong>Scheduled:</strong> Configure a reoccurring schedule that will be used to run Automations.
          <br />
          <br />
          <strong>Resource Updated:</strong> Trigger the Automation based on a Resource Field condition. The Automation
          will only be ran if at least one of the fields in the condition has been updated, and passes conditional
          checks.
          <br />
          <br />
          <strong>Resource Created:</strong> Just like Resource Updated, but will only run when a Resource is initially
          created.
          <br />
          <br />
          <strong>Webhook Trigger:</strong> The Platform supplies a unique Webhook URL and an Authentication Token. This
          can be used to trigger automations from external tools using HTTPS requests.
        </span>
      ),
    },
    {
      selector: "[data-tourid=create-automation-container]",
      title: "Automation Steps",
      content:
        "When an Automation is triggered and passes all conditions, it will attempt to fire one or multiple configured Automation Steps, such as Sending Notifications, Generating Reports, Creating Resources, or even firing outbound HTTPS requests to other tools",
    },
  ];

  return useWelcomeTour({
    steps,
    optionKey: "createAutomationTour",
  });
};

export const useReportAutomationTour = () => {
  const steps = [
    {
      selector: "[data-tourid=configure-report-automation-container]",
      title: "Configure Report Automation",
      content:
        "This Automation causes a Report to be generated and saved into the Rivial Platform. This can be done for Built-In Platform Templates, or you can create your own Custom Report Templates",
    },
    {
      selector: "[data-tourid=configure-report-automation-container]",
      title: "Applicable Evidence",
      content:
        "Select one or more Evidence items that this Report will be automatically applied to. This may be used to Automate Continuous Compliance data gathering. For instance, configure an Automation to run once a quarter that generates a Board Report and uploads it as Evidence.",
    },
  ];

  return useWelcomeTour({
    steps,
    optionKey: "configureReportAutomationTour",
  });
};

export const useEmailAutomationTour = () => {
  const steps = [
    {
      selector: "[data-tourid=configure-email-automation-container]",
      title: "Configure Email Automation",
      content:
        "Select or create an Email Template. Email Templates may be filled out dynamically using Smart Values, and configured with Widget Placeholders. For instance, you may create an Email Template that sends a Notification whenever an Information System exceeds a certain amount of Residual Risk",
    },
  ];

  return useWelcomeTour({
    steps,
    optionKey: "configureEmailAutomationTour",
  });
};

export const useHTTPAutomationTour = () => {
  const steps = [
    {
      selector: "[data-tourid=configure-http-automation-container]",
      title: "Configure Outgoing Webhook Automation",
      content:
        "This is an Advanced Automation that allows you to send an external HTTP request. Select a HTTP request type, configure a Custom Request Body (use Smart Values to dynamically fill in data at request time), and add headers such as Auth Tokens. This may be used to configure advanced integrations with tools like Jira, Slack, or Monday.com",
    },
  ];

  return useWelcomeTour({
    steps,
    optionKey: "configureHTTPAutomationTour",
  });
};
