import { generateGraphql } from "@rivial-security/generategraphql";
import { deleteItem } from "../../../../../utils/Functions/Graphql/deleteItem";

/**
 * Deletes a RiskThreatLink m-m connection in the database
 * @param riskThreatLink
 * @returns {Promise<*>}
 */
export const deleteRiskThreatLink = async (riskThreatLink) => {
  const { getQuery } = generateGraphql("RiskThreatLink", ["__typename"]);

  return await deleteItem(getQuery, riskThreatLink);
};
