import { useNestedDataCard } from "../../../../../../../hooks/views/useNestedDataCard/useNestedDataCard";
import React from "react";
import ObservationsDataGrid from "../../../../../../Program/Observations/components/ObservationsDataGrid";
import { useObservationDataGrid } from "../../../../../../Program/Observations/hooks/useObservationDataGrid";
import CreateObservation from "../../../../../../Program/Observations/components/CreateObservation";
import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";

/**
 * Displays Observations for Risk Control Details
 * @param {object} item - the Risk Control to show Observations for
 * @param {string} organizationID - current organization ID
 * @returns {JSX.Element}
 * @constructor
 */
const RiskControlObservations = ({ item, organizationID, resetFunction }) => {
  const fields = ["observations"];
  const nestedFields = {
    observations: `(limit: 500) {
      items {
         id
        name
        description
        module
        isFinding
        ownerGroup
        risk
        formalResponse
        externalSource {
          id
          name
        }
        department {
          id
          name
        }
        decision
        needsDecision
        status
        insurancePolicyValidated
        externalSourceID
        }
    }`,
  };

  const nestedDataCard = useNestedDataCard({
    item,
    organizationID,
    field: "observations",
    typename: "Observation",
    connectionIDField: "riskControlID",
    fields,
    nestedFields,
    resetFunction,
    parentTypename: "RiskControl",
    grid: <ObservationsDataGrid />,
    gridHook: useObservationDataGrid,
    form: <CreateObservation />,
  });

  return <div style={{ height: "30em" }}>{nestedDataCard.gridDisplay}</div>;
};

export default withOrganizationCheck(RiskControlObservations);
