import { GetQuery } from "../Graphql/GetQuery";
import { tryParse } from "@rivial-security/func-utils";

/**
 * Calls backend function which generates presigned urls for s3 operations or deletes s3 files but only when there is
 * enough permissions and the input is correct for the s3 operation
 * @param {string} route - complete route to operation type seperated with forward slashes (see lambda for details)
 * @param {object[]} files - list of files to be processed, each object must contain name property
 * @param {string} operationType - the type of operation to be performed on the s3 files
 * @param {object} operationData - parameters to pass through to the final operation handler in the lambda
 * @return {Promise<string|object>}
 */
export const generatePresignedUrlsLambda = async ({ route, files, operationType, operationData }) => {
  const generatePresignedUrls = `
    query generatePresignedUrlsLambda($input: AWSJSON) {
      generatePresignedUrls(input: $input)
    }
  `;

  const result = await GetQuery({
    query: generatePresignedUrls,
    variables: {
      input: JSON.stringify({
        route,
        files,
        operationType,
        operationData,
      }),
    },
  });

  return tryParse(result);
};
