import { useCustomResourceEntryDataGrid } from "../hooks/useCustomResourceEntryDataGrid";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * Component for displaying the data grid of Custom Resource Entries
 * @param props
 * @returns {*}
 * @constructor
 */
const CustomResourceEntryDataGrid = (props) => useCustomResourceEntryDataGrid(props).display;

export default withOrganizationCheck(CustomResourceEntryDataGrid);
