import useListQuery from "../../../../hooks/graphql/useListQuery";
import { generateGraphql } from "@rivial-security/generategraphql";
import { IMPORTER_FIELD_FORMATS } from "../../../../hooks/views/useCsvImporter/enums/IMPORTER_FIELD_FORMATS";
import { CsvImporterModalButton } from "../../../../hooks/views/useCsvImporter/hooks/useCsvImporter";
import { useVendorSolutionDataGrid } from "../hooks/useVendorSolutionDataGrid";
import React from "react";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * Displays a Modal Button that opens the Vendor Solution CSV IMporter
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const VendorSolutionImporterModalButton = (props) => {
  const vendors = useListQuery({
    query: generateGraphql("Vendor", ["name"]).listQuery,
    organizationID: props.organizationID,
    disableRoleChecking: true,
  });

  const fields = () => [
    {
      field: "name",
      name: "Name",
      required: true,
      width: 400,
    },
    {
      field: "description",
      name: "Description",
      width: 400,
    },
    {
      field: "contractStatus",
      name: "Contract Status",
      width: 200,
      defaultValue: "none",
      format: IMPORTER_FIELD_FORMATS.SELECT,
      autoComplete: true,
      options: ["none", "current", "expired", "expiringSoon"],
    },
    {
      field: "contractDetails",
      name: "Contract Details",
      width: 400,
    },
    {
      field: "vendorID",
      name: "Vendor",
      connectionItemList: vendors.list,
      connectionItemField: "vendorID",
      connectionItemID: props.vendorID,
      width: 400,
      format: IMPORTER_FIELD_FORMATS.SELECT,
    },
  ];

  return (
    <CsvImporterModalButton
      {...{
        organizationID: props.organizationID,
        fields: fields(),
        nestedFields: {
          vendor: `{id name ownerGroup}`,
        },
        typename: "VendorSolution",
        connectionIDField: "vendorID",
        connectionID: props.vendorID,
        resultGrid: useVendorSolutionDataGrid,
        isLoading: vendors.isLoading,
      }}
    />
  );
};

export default withOrganizationCheck(VendorSolutionImporterModalButton);
