import React, { useEffect } from "react";
import { modules, resources } from "@rivial-security/role-utils";

import MitreAttackTechniqueDetails from "../components/MitreAttackTechniqueDetails";
import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { getTechniquesList } from "../functions/getTechniquesList";
import { useDataGrid } from "../../../../../hooks/views/useDataGrid/useDataGrid";

/**
 * @description Display list of Mitre Attack techniques hook
 * @param {string} organizationID - selected organization ID
 * @param {object[]} fields - fields to display
 * @param {object} props - other props for the hook
 */
export const useMitreAttackTechniquesDataGrid = ({ organizationID, fields = [], ...props }) => {
  const module = modules.TOOLS;
  const resource = resources.MITRE_ATTACK_TECHNIQUE;
  const route = "#/integrations/mitre_attack/technique/";

  fields = [
    {
      name: "id",
      width: "100",
      valueGetter: (_value, row) => row?.workspace?.attack_id,
      searchKeys: ["workspace?.attack_id"],
    },
    {
      name: "name",
      width: "200",
      valueGetter: (_value, row) => row?.stix?.name,
      searchKeys: ["stix.name"],
    },
    {
      name: "description",
      flex: 1,
      valueGetter: (_value, row) => row?.stix?.description,
      searchKeys: ["stix.description"],
    },
    {
      name: "domain",
      width: "200",
      valueGetter: (_value, row) =>
        row?.stix?.x_mitre_domains && convertCamelCaseToSentence(row?.stix?.x_mitre_domains.join(", ")),
      searchKeys: ["stix.x_mitre_domains"],
    },
    ...fields,
  ];

  const grid = useDataGrid({
    module,
    resource,
    detailsTitle: "Technique Details",
    detailsComponent: <MitreAttackTechniqueDetails />,
    typename: "MitreAttackTechnique",
    title: "Mitre Att&ck Techniques",
    headerIcon: "icon-notebook",
    persistenceUUID: "c1741303-b25d-45c3-85ff-1c21c98fedb2",
    fields,
    route,
    organizationID,
    ...props,
  });

  useEffect(() => {
    grid?.setIsLoading(true);
    getTechniquesList()
      .then((data) => {
        grid?.setData(data);
      })
      .finally(() => grid?.setIsLoading(false));
  }, []);

  return grid;
};
