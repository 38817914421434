import React from "react";
import { useAccordion } from "../../../../hooks/views/useAccordion/useAccordion";
import { useForm } from "../../../../hooks/views/useForm/useForm";

export const useAssessmentReportSettings = () => {
  const configForm = useForm({
    disableSubmitButton: true,
    disableResetButton: true,
    fieldConfig: {
      generateReport: {
        label: "Generate Security Assessment Report",
        tooltip: "Automatically generates a report",
        defaultValue: true,
        inputType: "switch",
      },
      includeInfoVulnerabilities: {
        label: "Include Informational Vulnerabilities",
        tooltip: "Include Informational Vulnerabilities in the report",
        defaultValue: false,
        inputType: "switch",
      },
      sortByPriority: {
        label: "Sort By Priority",
        tooltip:
          "Sort vulnerabilities by priority in the report. If disabled, vulnerabilities will be sorted by severity.",
        defaultValue: true,
        inputType: "switch",
      },
      includeTargetsList: {
        label: "Include Appendix: List of Targets",
        tooltip: "Include Appendix: List of Targets in the report",
        defaultValue: false,
        inputType: "switch",
      },
      includeFalsePositives: {
        label: "Include False Positives",
        tooltip: "Include False Positives in the Appendix and list Affected Assets in the report",
        defaultValue: false,
        inputType: "switch",
      },
      includeExceptions: {
        label: "Include Exceptions",
        tooltip: "Include Exceptions in the Appendix and list Affected Assets in the report",
        defaultValue: false,
        inputType: "switch",
      },
    },
  });

  const accordion = useAccordion({
    items: [
      {
        title: "Report Settings",
        component: <div>{configForm.display}</div>,
        icon: "icon-list",
        defaultExpanded: true,
      },
    ],
  });

  return {
    display: accordion.display,
    reportConfig: configForm?.input,
  };
};
