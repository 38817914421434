/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query controlFrameworkDashboards($limit: Float, $nextToken: String) {\n    controlFrameworkDashboards(limit: $limit, nextToken: $nextToken) {\n      items {\n        id\n        name\n        description\n        controlFrameworkID\n        type\n        controlFramework {\n          id\n          name\n          customName\n          dashboardLogo\n        }\n      }\n      nextToken\n    }\n  }\n": types.ControlFrameworkDashboardsDocument,
    "\n  mutation createControlFrameworkDashboard($name: String!, $controlFrameworkId: ID!, $description: String) {\n    createControlFrameworkDashboard(name: $name, controlFrameworkId: $controlFrameworkId, description: $description)\n  }\n": types.CreateControlFrameworkDashboardDocument,
    "\n  query GetCustomDashboardById($id: ID!) {\n    getCustomDashboardById(id: $id) {\n      name\n    }\n  }\n": types.GetCustomDashboardByIdDocument,
    "\n   query ComplianceStatistics($dashboardID: ID) {\n    complianceStatistics(dashboardID: $dashboardID) {\n      artifactsUploaded\n      auditsPerformed\n      compliancePercentage\n      controlsNotInPlace\n      frameworksAdded\n      numberOfControls\n      evidence {\n        expired\n        expiringSoon\n        inPlace\n        pendingValidation\n      }\n    }\n  }\n": types.ComplianceStatisticsDocument,
    "\n  query userCreateableFrameworkTemplates {\n    userCreateableFrameworkTemplates {\n      id\n      name\n    }\n  }\n": types.UserCreateableFrameworkTemplatesDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query controlFrameworkDashboards($limit: Float, $nextToken: String) {\n    controlFrameworkDashboards(limit: $limit, nextToken: $nextToken) {\n      items {\n        id\n        name\n        description\n        controlFrameworkID\n        type\n        controlFramework {\n          id\n          name\n          customName\n          dashboardLogo\n        }\n      }\n      nextToken\n    }\n  }\n"): (typeof documents)["\n  query controlFrameworkDashboards($limit: Float, $nextToken: String) {\n    controlFrameworkDashboards(limit: $limit, nextToken: $nextToken) {\n      items {\n        id\n        name\n        description\n        controlFrameworkID\n        type\n        controlFramework {\n          id\n          name\n          customName\n          dashboardLogo\n        }\n      }\n      nextToken\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation createControlFrameworkDashboard($name: String!, $controlFrameworkId: ID!, $description: String) {\n    createControlFrameworkDashboard(name: $name, controlFrameworkId: $controlFrameworkId, description: $description)\n  }\n"): (typeof documents)["\n  mutation createControlFrameworkDashboard($name: String!, $controlFrameworkId: ID!, $description: String) {\n    createControlFrameworkDashboard(name: $name, controlFrameworkId: $controlFrameworkId, description: $description)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetCustomDashboardById($id: ID!) {\n    getCustomDashboardById(id: $id) {\n      name\n    }\n  }\n"): (typeof documents)["\n  query GetCustomDashboardById($id: ID!) {\n    getCustomDashboardById(id: $id) {\n      name\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n   query ComplianceStatistics($dashboardID: ID) {\n    complianceStatistics(dashboardID: $dashboardID) {\n      artifactsUploaded\n      auditsPerformed\n      compliancePercentage\n      controlsNotInPlace\n      frameworksAdded\n      numberOfControls\n      evidence {\n        expired\n        expiringSoon\n        inPlace\n        pendingValidation\n      }\n    }\n  }\n"): (typeof documents)["\n   query ComplianceStatistics($dashboardID: ID) {\n    complianceStatistics(dashboardID: $dashboardID) {\n      artifactsUploaded\n      auditsPerformed\n      compliancePercentage\n      controlsNotInPlace\n      frameworksAdded\n      numberOfControls\n      evidence {\n        expired\n        expiringSoon\n        inPlace\n        pendingValidation\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query userCreateableFrameworkTemplates {\n    userCreateableFrameworkTemplates {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query userCreateableFrameworkTemplates {\n    userCreateableFrameworkTemplates {\n      id\n      name\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;