import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 * Function for removing a vendor solution link from a particular information system
 * @param {object} system - the system data from which to remove the link (must include the 'id' property)
 * @return {Promise<*>} - result of the mutation
 */
export const unlinkSystemFromVendorSolution = async (system) => {
  const { updateMutation } = generateGraphql("System");

  return await ItemMutation(updateMutation, {
    id: system?.id,
    vendorSolutionID: null,
  });
};
