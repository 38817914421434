import React from "react";
import StatusCountsTrend from "./StatusCountsTrend";
import { TargetVulnerabilityHistoryType } from "../../../../../../typedefs/Testing/Vulnerability/Vulnerability";
import { TargetVulnerabilityStatus } from "@rivial-security/schema-types";
import TimelineUnderlinedDetails from "./TimelineUnderlinedDetails";
import { isNonEmptyArray } from "@rivial-security/func-utils";
import pluralize from "pluralize";
import { resources } from "@rivial-security/role-utils";
import { useAssessmentModals } from "../hooks/useAssessmentModals";

/**
 * Timeline content to display when a vulnerability history item is an assessment status
 * @param {object} item - the TargetVulnerabilityHistory item
 * @param {boolean} isGrouped - is the timeline grouped by assessment
 * @param {object} [vulnerability] - the vulnerability object
 * @param {object} [target] - the target object
 * @returns {JSX.Element}
 */
const AssessmentStatus = ({ item, isGrouped, vulnerability, target }) => {
  const isFindingAttached = !!item?.vulnerabilityName;

  let processedLinkIds = new Set();
  const nestedHistoryEntries = item?.history;
  if (isNonEmptyArray(nestedHistoryEntries)) {
    for (const historyEntry of nestedHistoryEntries) {
      const targetVulnerabilityLinkID = historyEntry?.targetVulnerabilityLinkID;
      processedLinkIds.add(targetVulnerabilityLinkID);
    }
  } else {
    processedLinkIds = new Set(item?.associatedTargetFindingLinks || []);
  }

  const statusCounts = {
    [TargetVulnerabilityStatus.NOT_FIXED]: new Set(),
    [TargetVulnerabilityStatus.FIXED]: new Set(),
    [TargetVulnerabilityStatus.EXCEPTION]: new Set(),
  };
  const historyEntriesByType = {};
  if (isNonEmptyArray(nestedHistoryEntries)) {
    for (const historyEntry of nestedHistoryEntries) {
      const type = historyEntry?.type;
      if (!historyEntriesByType[type]) {
        historyEntriesByType[type] = [];
      }

      if (type === TargetVulnerabilityHistoryType.STATUS) {
        const newStatus = historyEntry?.newStatus;
        const targetVulnerabilityLinkID = historyEntry?.targetVulnerabilityLinkID;
        if (statusCounts[newStatus] !== undefined && targetVulnerabilityLinkID) {
          statusCounts[newStatus].add(targetVulnerabilityLinkID);
        }
      }

      historyEntriesByType[type].push(historyEntry);
    }
  }

  //When no status history entry exists treat the initially created entry as a not-fixed status entry
  const initialHistoryEntries = historyEntriesByType[TargetVulnerabilityHistoryType.INITIAL];
  if (isNonEmptyArray(initialHistoryEntries)) {
    for (const historyEntry of initialHistoryEntries) {
      const targetVulnerabilityLinkID = historyEntry?.targetVulnerabilityLinkID;
      const noStatusCountSetsContainLinkID = Object.values(statusCounts).every((statusCountSet) => {
        return !statusCountSet.has(historyEntry?.targetVulnerabilityLinkID);
      });

      if (targetVulnerabilityLinkID && noStatusCountSetsContainLinkID) {
        statusCounts[TargetVulnerabilityStatus.NOT_FIXED].add(targetVulnerabilityLinkID);
      }
    }
  }

  const { assessmentLinksGridModal, handleClick, determineTooltipText } = useAssessmentModals({
    assessmentID: item?.assessmentID,
    vulnerability,
    target,
    processedLinkIds,
    isFindingAttached,
  });

  return (
    <span style={{ margin: "0" }}>
      {assessmentLinksGridModal.modal}
      An{" "}
      <TimelineUnderlinedDetails
        tooltipText={"Click to view Assessment Details"}
        modalType={resources.ASSESSMENT}
        item={item}
      >
        {"Assessment "}
      </TimelineUnderlinedDetails>
      has processed{" "}
      <TimelineUnderlinedDetails tooltipText={determineTooltipText()} onClick={handleClick}>
        {processedLinkIds.size}{" "}
        {isFindingAttached
          ? pluralize("Vulnerability", processedLinkIds.size)
          : pluralize("Target", processedLinkIds.size)}
      </TimelineUnderlinedDetails>
      {isGrouped && " "}
      {isGrouped && (
        <StatusCountsTrend
          isFindingAttached={isFindingAttached}
          processedLinkIds={processedLinkIds}
          statusCounts={statusCounts}
        />
      )}
    </span>
  );
};

export default AssessmentStatus;
