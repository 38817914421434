import { NavigationBarModulesBySection, Route } from "@application/Layout/hooks/types/Navigation";
import { useEffect, useState } from "react";

import { actionCenterNavigation } from "@application/Navigation/modules/original/actionCenterNavigation";
import { addNavigationModule } from "@application/Layout/hooks/useNavigationLinks/functions/addNavigationModule";
import { administratorPanelNavigation } from "@application/Navigation/modules/original/administratorPanelNavigation";
import { appendCustomToolLinks } from "@application/Layout/hooks/useNavigationLinks/functions/appendCustomToolLinks";
import { complianceNavigation as astraComplianceNavigation } from "@application/Navigation/modules/astra/complianceNavigation";
import { complianceNavigation } from "@application/Navigation/modules/original/complianceNavigation";
import { developerNavigation } from "@application/Navigation/modules/original/developerNavigation";
import { generalRoutes } from "@application/Routes/GeneralRoutes";
import { governanceNavigation } from "@application/Navigation/modules/original/governanceNavigation";
import { incidentResponseNavigation } from "@application/Navigation/modules/original/incidentResponseNavigation";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { mainRoutes } from "@application/Routes/MainRoutes";
import { metricsNavigation } from "@application/Navigation/modules/original/metricsNavigation";
import { operationPanelNavigation } from "@application/Navigation/modules/original/operationPanelNavigation";
import { organizationManagerNavigation } from "@application/Navigation/modules/original/organizationManagerNavigation";
import { reportsNavigation } from "@application/Navigation/modules/original/reportsNavigation";
import { riskNavigation } from "@application/Navigation/modules/original/riskNavigation";
import { settingsRoutes } from "@application/Routes/SettingsRoutes";
import { toolsNavigation } from "@application/Navigation/modules/original/toolsNavigation";
import { useCustomToolLinks } from "@application/Layout/hooks/useCustomToolLinks";
import { useFeatureFlagsContext } from "../../../../featureFlags/FeatureFlagsProvider";
import { useOrganizationContext } from "@views/AdminPanel/Organizations/hooks/useOrganizationContext";
import { vendorNavigation } from "@application/Navigation/modules/original/vendorNavigation";
import { vulnerabilitiesNavigation } from "@application/Navigation/modules/original/vulnerabilitiesNavigation";

export interface UseNavigationLinksResult {
  routes: Route[];
  navigationBarSectionModules?: NavigationBarModulesBySection;
  roleAccountType: string;
}

export const useNavigationLinks = (): UseNavigationLinksResult => {
  const {
    userCognitoGroups,
    selectedOrganization,
    roleConfig,
    accountType,
    operationTeamID,
    loggedInPointOfContactId,
  } = useOrganizationContext();
  const { flags } = useFeatureFlagsContext();

  const [navigationBarSectionModules, setNavigationBarSectionModules] = useState({});
  const [roleAccountType, setRoleAccountType] = useState("standard");
  const [routes, setRoutes] = useState([] as Route[]);
  const { customToolLinks } = useCustomToolLinks({ selectedOrganization });

  useEffect(() => {
    let newRoutes: Route[] = [];
    const newNavigation: NavigationBarModulesBySection = {} as NavigationBarModulesBySection;

    newRoutes = newRoutes.concat(mainRoutes);
    newRoutes = newRoutes.concat(settingsRoutes);
    if (loggedInPointOfContactId) {
      newRoutes = newRoutes.concat(generalRoutes);
    }

    // [Services]
    addNavigationModule({ navigationModule: governanceNavigation, newRoutes, newNavigation, roleConfig });
    addNavigationModule({ navigationModule: riskNavigation, newRoutes, newNavigation, roleConfig });
    addNavigationModule({
      navigationModule: flags?.isNewComplianceModuleEnabled ? astraComplianceNavigation : complianceNavigation,
      newRoutes,
      newNavigation,
      roleConfig,
    });
    addNavigationModule({ navigationModule: vulnerabilitiesNavigation, newRoutes, newNavigation, roleConfig });
    addNavigationModule({ navigationModule: vendorNavigation, newRoutes, newNavigation, roleConfig });
    addNavigationModule({ navigationModule: incidentResponseNavigation, newRoutes, newNavigation, roleConfig });

    // [Tools]
    addNavigationModule({ navigationModule: actionCenterNavigation, newRoutes, newNavigation, roleConfig });
    addNavigationModule({ navigationModule: reportsNavigation, newRoutes, newNavigation, roleConfig });
    const toolsNavigationBarModule = addNavigationModule({
      navigationModule: toolsNavigation,
      newRoutes,
      newNavigation,
      roleConfig,
    });
    appendCustomToolLinks({ toolsNavigationBarModule, customToolLinks });
    addNavigationModule({ navigationModule: metricsNavigation, newRoutes, newNavigation, roleConfig });

    // [Management]
    addNavigationModule({ navigationModule: organizationManagerNavigation, newRoutes, newNavigation, roleConfig });
    if (!isNullOrUndefined(operationTeamID) && accountType === "operationTeamMember") {
      addNavigationModule({ navigationModule: operationPanelNavigation, newRoutes, newNavigation, roleConfig });
      setRoleAccountType("Operation Team Member");
    }

    if (userCognitoGroups?.includes("Admin")) {
      addNavigationModule({ navigationModule: administratorPanelNavigation, newRoutes, newNavigation, roleConfig });
      setRoleAccountType("administrator");
    }

    if (userCognitoGroups?.includes("Developer")) {
      addNavigationModule({ navigationModule: developerNavigation, newRoutes, newNavigation, roleConfig });
      setRoleAccountType("Developer");
    }

    setRoutes(newRoutes);
    setNavigationBarSectionModules(newNavigation);
  }, [roleConfig, flags?.isNewComplianceModuleEnabled, customToolLinks]);

  return {
    routes,
    navigationBarSectionModules: navigationBarSectionModules as NavigationBarModulesBySection,
    roleAccountType,
  };
};
