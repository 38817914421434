import React, { useEffect, useState } from "react";
import { formattedPercent } from "@rivial-security/func-utils";

/**
 * Displays the strength of a control category (min, max, average)
 * @param {object} item - the control category data
 * @returns {JSX.Element}
 */
const ControlCategoryEffectiveness = ({ item }) => {
  const [effectiveness, setEffectiveness] = useState();
  useEffect(() => {
    let minEffectiveness = 10000000;
    let maxEffectiveness = 0;
    let totalEffectiveness = 0;
    let averageEffectiveness = 0;
    const subControls = item?.subControls?.items;
    if (Array.isArray(subControls)) {
      for (const subControl of subControls) {
        const implementationRating = subControl?.implementationRating || 0;
        const strengthRating = subControl?.strengthRating || 0;
        const effectivenessRating = implementationRating * strengthRating;

        if (effectivenessRating < minEffectiveness) {
          minEffectiveness = effectivenessRating;
        }
        if (effectivenessRating > maxEffectiveness) {
          maxEffectiveness = effectivenessRating;
        }

        totalEffectiveness += effectivenessRating;
      }
      averageEffectiveness = totalEffectiveness / subControls.length;
    }

    setEffectiveness({
      min: minEffectiveness,
      max: maxEffectiveness,
      average: averageEffectiveness,
      total: totalEffectiveness,
    });
  }, [item]);

  return (
    <div>
      <b>Lowest:</b> {formattedPercent(effectiveness?.min)}
      {" - "}
      <b>Average</b>: {formattedPercent(effectiveness?.average)}
      {" - "}
      <b>Highest:</b> {formattedPercent(effectiveness?.max)}
      {" - "}
      <b>Total:</b> {formattedPercent(effectiveness?.total)}
    </div>
  );
};

export default ControlCategoryEffectiveness;
