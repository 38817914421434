import { Modal } from "reactstrap";
import Styles from "../../../css/useModal.module.css";
import { useUIContext } from "@utils/Context/UIContext";

export interface ModalWrapperType {
  isOpen: boolean;
  toggle: () => void;
  dataTestId?: string;
  onClosed?: () => void;
  clearBackdrop?: boolean;
  cornerModal?: boolean;
  isMobileConfig?: boolean;
  width?: string;
  header?: string | JSX.Element;
  body?: string | JSX.Element;
  footer?: string | JSX.Element;
}

interface ModalWrapperProps {
  isMobile: boolean;
}

/**
 * A component wrapper for display part of useModal hook. This was implemented
 * for correct updating of state when screen switches from desktop to mobile view
 * the component separates settings parsing logic from handling of mobile screens.
 */
const ModalWrapper = ({
  isOpen,
  toggle,
  dataTestId,
  onClosed,
  clearBackdrop = false,
  cornerModal = false,
  isMobileConfig,
  width,
  header,
  body,
  footer,
}: ModalWrapperType): JSX.Element => {
  const { isMobile } = useUIContext();

  const getMinWidth = ({ isMobile }: ModalWrapperProps) => {
    if (isMobile || isMobileConfig) {
      return "95vw";
    }
    if (width) {
      return width;
    }

    return undefined;
  };

  const getTop = ({ isMobile }: ModalWrapperProps) => {
    if (cornerModal) {
      return "64px";
    }

    if (isMobile || isMobileConfig) {
      return "calc(64px + 1em)";
    } else {
      return undefined;
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      data-testid={dataTestId}
      onClosed={onClosed}
      toggle={toggle}
      backdropClassName={clearBackdrop ? Styles["clear-backdrop"] : ""}
      className={clearBackdrop ? Styles["shadow-modal"] : ""}
      style={{
        minWidth: getMinWidth({ isMobile }),
        width: getMinWidth({ isMobile }),
        top: getTop({ isMobile }),
        right: cornerModal ? "0px" : undefined,
        position: cornerModal ? "absolute" : undefined,
      }}
    >
      {header}
      {body}
      {footer}
    </Modal>
  );
};

export default ModalWrapper;
