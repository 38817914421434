import { useDetailsCard } from "../../../../hooks/views/useDetailsCardV2";
import React from "react";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";
import VendorSubControlRiskControl from "../components/VendorSubControlRiskControl";

/**
 * Custom hook for displaying details of a VendorSubControl
 * @param itemId
 */
export const useVendorSubControlDetails = ({ itemId, organizationID }) => {
  const module = modules.VENDORS;
  const resource = resources.VENDOR_CONTROL;
  const typename = "VendorSubControl";
  const fields = ["statementNumber", "name", "riskControl"];

  const { getQuery, updateMutation } = generateGraphql(typename, fields, {
    riskControl: `{ id name statementNumber statementNumber costOfControl strengthRating implementationRating outsourced keyControl}`,
  });

  const queryConfig = {
    query: getQuery,
    itemId,
    organizationID,
  };

  const detailsCardHook = useDetailsCard({
    queryConfig,
    module,
    resource,
    detailsConfig: {
      fields,
      header: "Vendor Sub Control Details",
      updateMutation,
      customFields: [
        {
          field: "riskControl",
          component: <VendorSubControlRiskControl />,
        },
      ],
    },
    typename,
  });

  return {
    ...detailsCardHook,
  };
};
