import { Button } from "reactstrap";
import React from "react";
import { useCheckPermissions } from "../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { modules } from "@rivial-security/role-utils";

/**
 * Field allowing to display changes between source and incoming content
 * @see made to use as custom field in {@link useTable}
 * @param {function(string)} fieldInfo.resolve - allows to change the resolution type to one of - {none, ignore, update, replace}
 * @param {string} fieldInfo.resolution - the action taken on the change (used for proper formatting)
 * @returns {HTMLElement} a div with three buttons for the three different resolution choices
 */
export const CompareActionsField = ({ item, fieldInfo }) => {
  const checkPermissionsHook = useCheckPermissions({
    module: modules.ADMINISTRATOR,
    resource: "duplicateScanner",
    disableRoleChecking: true,
  });
  const ignoreIsEnabled = checkPermissionsHook.CheckFieldPermissionsRead("ignoreAction");
  const updateIsEnabled = checkPermissionsHook.CheckFieldPermissionsRead("updateAction");
  const replaceIsEnabled = checkPermissionsHook.CheckFieldPermissionsRead("replaceAction");
  const deleteIsEnabled = checkPermissionsHook.CheckFieldPermissionsRead("deleteAction");

  return (
    <>
      <Button
        disabled={!ignoreIsEnabled}
        title={
          ignoreIsEnabled
            ? "Ignore Incoming Item"
            : "No permissions to use ignore (enable read for Administrator -> DuplicateScanner -> Ignore)"
        }
        size="sm"
        color="secondary"
        className="btn-pill"
        active={fieldInfo && fieldInfo.resolution && fieldInfo.resolution === "ignore"}
        onClick={() => {
          if (fieldInfo && fieldInfo.resolve) {
            fieldInfo.resolve("ignore");
          }
        }}
      >
        <i className="icon-minus" />
      </Button>

      <Button
        disabled={!updateIsEnabled}
        title={
          updateIsEnabled
            ? "Update Source Item"
            : "No permissions to use update (enable read for Administrator -> DuplicateScanner -> Update)"
        }
        style={{ marginLeft: ".5em" }}
        size="sm"
        color="success"
        className="btn-pill"
        active={fieldInfo && fieldInfo.resolution && fieldInfo.resolution === "update"}
        onClick={() => {
          if (fieldInfo && fieldInfo.resolve) {
            fieldInfo.resolve("update");
          }
        }}
      >
        <i className="icon-plus" />
      </Button>

      <Button
        disabled={!replaceIsEnabled}
        title={
          replaceIsEnabled
            ? "Replace Source Item"
            : "No permissions to use replace (enable read for Administrator -> DuplicateScanner -> Replace)"
        }
        style={{ marginLeft: ".5em" }}
        size="sm"
        color="danger"
        className="btn-pill"
        active={fieldInfo && fieldInfo.resolution && fieldInfo.resolution === "replace"}
        onClick={() => {
          if (fieldInfo && fieldInfo.resolve) {
            fieldInfo.resolve("replace");
          }
        }}
      >
        <i className="icon-close" />
      </Button>

      <Button
        disabled={!deleteIsEnabled}
        title={
          deleteIsEnabled
            ? "Delete Source Item"
            : "No permissions to use delete (enable read for Administrator -> DuplicateScanner -> Delete)"
        }
        style={{ marginLeft: ".5em" }}
        size="sm"
        color="danger"
        className="btn-pill"
        active={fieldInfo && fieldInfo.resolution && fieldInfo.resolution === "delete"}
        onClick={() => {
          if (fieldInfo && fieldInfo.resolve) {
            fieldInfo.resolve("delete");
          }
        }}
      >
        <i className="icon-trash" />
      </Button>
    </>
  );
};
