import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import React, { useEffect } from "react";
import { listEvidenceSystemRiskControlOverrides } from "../../EvidenceRiskControlLinks/functions/listEvidenceSystemRiskControlOverrides";
import { unlinkEvidenceNonStandardRiskControl } from "../../EvidenceRiskControlLinks/functions/unlinkEvidenceNonStandardRiskControl";
import { submitRiskComplianceSyncJob } from "../functions/submitRiskComplianceSyncJob";
import { updateControlOverrideType } from "../enums/updateControlOverrideType";
import { useNonStandardControlsAccordion } from "../../../Risk/RiskControls/hooks/useNonStandardControlsAccordion";
import NotEnoughData from "../../../../utils/GenericComponents/NotEnoughData";
import Typography from "@mui/material/Typography";
import RiskControlDetails from "../../../Risk/RiskConfig/ControlCategories/components/SubControls/components/RiskControlDetails";

/**
 * Grid for non-standard controls that are linked to an evidence
 * @param {object} evidence - the evidence item being viewed with id property
 * @param {string} organizationID - the organization identifier of the evidence item
 * @param {function} resetFunction - the callback to call to update evidence item state
 * @return {JSX.Element}
 */
const EvidenceNonStandardRiskControls = ({ item: evidence, organizationID, resetFunction }) => {
  const gridConfig = {
    deleteFunction: async (item) => {
      await unlinkEvidenceNonStandardRiskControl({ evidence, item });

      if (item?.control) {
        await submitRiskComplianceSyncJob({
          evidenceID: evidence?.id,
          organizationID,
          riskControlOverrides: [
            {
              ...item?.control,
              system: item?.system,
            },
          ],
          overrideType: updateControlOverrideType.EVIDENCE_UNLINKED,
        });
      }

      resetFunction && resetFunction();
    },
    detailsComponent: <RiskControlDetails />,
    enableContextMenu: true,
    deleteButtonText: "Unlink",
    options: ["delete"],
    resetFunction,
  };

  const queryConfig = {
    query: null,
  };
  const nonStandardRiskControls = useNonStandardControlsAccordion({
    organizationID,
    gridConfig,
    queryConfig,
    item: evidence,
    resetFunction,
  });
  const { accordion } = nonStandardRiskControls;

  //Retrieve data for grid
  useEffect(() => {
    //TODO: remove timeout when nested grid in accordion and tab card fix is implemented
    setTimeout(() => {
      const data = listEvidenceSystemRiskControlOverrides({ evidence });
      nonStandardRiskControls.setData(data);
    }, 1000);
  }, [evidence]);

  //Grid UI display
  return (
    <span>
      {nonStandardRiskControls.data.length > 0 ? (
        <div>
          <Typography variant="h6"> Non Standard Risk Controls by System</Typography>
          {accordion.display}
        </div>
      ) : (
        <NotEnoughData message={"This Evidence is not currently linked to any non Standard Controls."} />
      )}
    </span>
  );
};

export default withOrganizationCheck(EvidenceNonStandardRiskControls);
