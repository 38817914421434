import React, { useEffect, useState } from "react";
import { getColumnDataFromTypes } from "../../../functions/getColumnDataFromTypes";
import { parseSchemaTypes } from "../../../functions/parseSchemaTypes";
import schema from "../../../../../graphql/schema.json";
import { useLogicBuilderV2 } from "../../../../../utils/LogicBuilder/hooks/useLogicBuilderV2";

/**
 * Form which appears when the field in the current query builder path can be filtered
 * @param {object} path - the current field node path in the query builder
 * @param {function} onFilterChange - callback when information in the form changes
 * @param {boolean} isShownOverride - override for whether or not the form should be shown
 * @param {boolean} freeSoloFieldInput - if true will allow the user to enter a field name that's not one of the ones defined for the type
 * @returns {JSX.Element}
 */
const ResourceFilterForm = ({ path, onFilterChange, isShownOverride, freeSoloFieldInput, sx }) => {
  /// [STATE]
  const [isShown, setIsShown] = useState(false);
  const [typename, setTypename] = useState(null);
  const [types, setTypes] = useState(parseSchemaTypes({ schema, showConnections: false }));

  const getIsShown = () => {
    if (typeof isShownOverride === "boolean") {
      return isShownOverride;
    }
    return isShown;
  };

  /// [HOOKS]
  //Initialize the Logic Builder for AND/OR Operations
  const logicBuilder = useLogicBuilderV2({
    onChange: (newFilter) => {
      if (typeof onFilterChange === "function") {
        onFilterChange(newFilter);
      }
    },
    freeSoloFieldInput,
    readonly: false,
    label: "Filters",
    disabledOperators: ["isnull", "isnotnull"],
  });

  /// [FUNCTIONS]
  const resetLogicBuilder = () => {
    setTypename(null);
    setIsShown(false);
    logicBuilder.setColumnData([]);
    logicBuilder.reset();
  };

  /// [EFFECTS]
  //Update the QueryBuilder when the Column Data changes
  useEffect(() => {
    if (Array.isArray(path) && path.length > 0) {
      const lastPathElement = path[path.length - 1];
      let newFilter = lastPathElement?.filters;
      if (!newFilter && typeof newFilter !== "object") {
        newFilter = {
          combinator: "and",
          rules: [],
        };
      }
      logicBuilder.setQuery(newFilter);

      const newTypename = lastPathElement?.typename;
      const newIsRoot = lastPathElement?.isRoot;

      if ((!newTypename || !newIsRoot) && !freeSoloFieldInput) {
        resetLogicBuilder();
      } else if (newTypename !== typename) {
        const newColumnData = getColumnDataFromTypes(newTypename, types);
        logicBuilder.setColumnData(newColumnData);
        setTypename(newTypename);
        setIsShown(true);
      }
    } else {
      resetLogicBuilder();
    }
  }, [
    Array.isArray(path) && path.length > 0 && path[path.length - 1]?.filters,
    Array.isArray(path) && path.length > 0 && path[path.length - 1]?.typename,
  ]);

  return <div style={{ ...(sx?.container ?? {}) }}>{getIsShown() && logicBuilder.display}</div>;
};

export default ResourceFilterForm;
