import { convertCamelCaseToSentence, isNullOrUndefined } from "@rivial-security/func-utils";
import { modules, resources } from "@rivial-security/role-utils";

import ArtifactValidation from "../../../Compliance/Artifact/ArtifactValidation/ArtifactValidation";
import AssessmentDataGrid from "../../../Testing/Assessments/components/AssessmentDataGrid";
import AssessmentDetails from "../../../Testing/Assessments/components/AssessmentDetails";
import AuditDataGrid from "../../../Compliance/Audit/components/AuditDataGrid";
import AuditDetails from "../../../Compliance/Audit/components/AuditDetails";
import ControlDetails from "../../../Compliance/Controls/Controls/components/ControlDetails";
import CreateAudit from "../../../Compliance/Audit/components/CreateAudit";
import CreateControl from "../../../Compliance/Controls/Controls/components/CreateControl";
import CreateEvidence from "../../../Compliance/Evidence/components/CreateEvidence";
import CreateExercise from "../../../Response/Exercises/components/CreateExercise";
import CreateIncident from "../../../Response/Incidents/components/CreateIncident";
import CreateMeeting from "../../Meetings/components/MeetingForm";
import CreateVulnerability from "../../../Testing/Vulnerabilities/components/CreateVulnerability";
import DataLoader from "../../../../utils/LoadingComponents/DataLoader";
import DepartmentDetails from "../../../OrganizationManager/Departments/components/DepartmentDetails";
import DepartmentForm from "../../../OrganizationManager/Departments/components/DepartmentForm";
import DepartmentGrid from "../../../OrganizationManager/Departments/components/DepartmentGrid";
import EvidenceDataGrid from "../../../Compliance/Evidence/components/EvidenceDataGrid";
import EvidenceDetails from "../../../Compliance/Evidence/components/EvidenceDetails";
import ExerciseDetails from "../../../Response/Exercises/components/ExerciseDetails";
import ExerciseGrid from "../../../Response/Exercises/components/ExerciseGrid";
import { ICON_MAP } from "../../../../enums/ICON_MAP";
import IncidentDetails from "../../../Response/Incidents/components/IncidentDetails";
import IncidentGrid from "../../../Response/Incidents/components/IncidentGrid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MeetingDetails from "../../Meetings/components/MeetingDetails";
import MeetingsDataGrid from "../../Meetings/components/MeetingsDataGrid";
import NestedField from "../../../../utils/GenericComponents/NestedField/NestedField";
import React from "react";
import RiskControlDetails from "../../../Risk/RiskConfig/ControlCategories/components/SubControls/components/RiskControlDetails";
import RiskControlGrid from "../../../Risk/RiskConfig/ControlCategories/components/SubControls/components/RiskControlGrid";
import SelectControls from "../../../Compliance/Controls/Controls/components/SelectControls";
import VulnerabilityDataGrid from "../../../Testing/Vulnerabilities/components/VulnerabilityDataGrid";
import VulnerabilityDetails from "../../../Testing/Vulnerabilities/components/VulnerabilityDetails";
import { handleIcon } from "../../../../utils/Functions/Icon/getIcon";

/**
 * Displays a list of the various resources that can be connected to observations:
 *
 * - riskControl
 * - complianceControl
 * - vulnerability
 * - evidence
 * - exercises
 * - assessment
 * - incident
 * - audit
 * - department
 * - meeting
 * - artifact
 *
 * @param {object} item - the observation item with all associated resources data
 * @param {function} resetFunction - function to re query item
 */
export const useObservationAssociatedResources = ({ item, resetFunction }) => {
  const fields = [
    {
      icon: "icon-shield",
      typename: "RiskControl",
      field: "riskControl",
      idField: "riskControlID",
      detailsComponent: <RiskControlDetails />,
      grid: <RiskControlGrid />,
    },
    {
      icon: "icon-list",
      typename: "Control",
      field: "complianceControl",
      idField: "complianceControlID",
      form: <CreateControl />,
      grid: <SelectControls />,
      detailsComponent: <ControlDetails />,
    },
    {
      icon: "icon-badge",
      typename: "Evidence",
      field: "evidence",
      idField: "evidenceID",
      form: <CreateEvidence />,
      grid: <EvidenceDataGrid />,
      detailsComponent: <EvidenceDetails />,
    },
    {
      icon: "icon-screen-tablet",
      typename: "Exercise",
      field: "exercise",
      idField: "exerciseID",
      form: <CreateExercise />,
      grid: <ExerciseGrid />,
      detailsComponent: <ExerciseDetails />,
    },
    {
      icon: "icon-fire",
      parentTypename: "Vulnerability",
      typename: "vulnerabilities",
      useMany: true,
      field: "vulnerabilityItems",
      idField: "observationID",
      form: <CreateVulnerability />,
      grid: <VulnerabilityDataGrid />,
      detailsComponent: <VulnerabilityDetails />,
      displayField: "name",
    },
    {
      icon: "icon-book-open",
      typename: "Vulnerability Assessment",
      field: "assessment",
      idField: "assessmentID",
      grid: <AssessmentDataGrid />,
      detailsComponent: <AssessmentDetails />,
      displayField: "name",
    },
    {
      icon: "icon-target",
      typename: "Incident",
      field: "incident",
      idField: "incidentID",
      form: <CreateIncident />,
      grid: <IncidentGrid />,
      detailsComponent: <IncidentDetails />,
      displayField: "what",
    },
    {
      icon: "icon-film",
      typename: "Audit",
      field: "audit",
      idField: "auditID",
      form: <CreateAudit disableRedirectOnCreate={true} />,
      grid: <AuditDataGrid />,
      detailsComponent: <AuditDetails />,
      displayField: "name",
    },
    {
      icon: ICON_MAP.RESOURCES[resources.DEPARTMENT],
      typename: "Department",
      field: "department",
      idField: "departmentID",
      form: <DepartmentForm />,
      grid: <DepartmentGrid />,
      detailsComponent: <DepartmentDetails />,
      displayField: "name",
    },
    {
      icon: "icon-people",
      typename: "Meeting",
      field: "meeting",
      idField: "meetingID",
      form: <CreateMeeting />,
      grid: <MeetingsDataGrid />,
      detailsComponent: <MeetingDetails />,
      displayField: "name",
    },
    {
      icon: "icon-docs",
      typename: "Artifact",
      field: "artifact",
      idField: "artifactID",
      form: null,
      grid: null,
      disableEdits: true,
      detailsComponent: <ArtifactValidation module={modules.GOVERNANCE} resource={resources.OBSERVATION} />,
      displayField: "name",
    },
  ];

  const display = (
    <div>
      <DataLoader isLoading={false} isEnoughData={!isNullOrUndefined(item)}>
        <List>
          {fields.map((field) => (
            <ListItem style={{ display: "block" }}>
              <strong>
                <span style={{ marginRight: "1em" }}>{handleIcon(field.icon)}</span>
                {convertCamelCaseToSentence(field.typename)}
              </strong>
              <div>
                <NestedField item={item} parentTypename={"Observation"} resetFunction={resetFunction} {...field} />
              </div>
            </ListItem>
          ))}
        </List>
      </DataLoader>
    </div>
  );

  return {
    display,
  };
};
