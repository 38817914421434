import React, { useContext } from "react";
import { modules, resources } from "@rivial-security/role-utils";

import { Button } from "reactstrap";
import { ErrorLogger } from "@utils/EventLogger";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import PlaybookProcedureTabs from "../../Plan/Playbooks/components/PlaybookProcedureTabs";
import { UIContext } from "../../../../utils/Context/UIContext";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useModal } from "../../../../hooks/views/useModal";
import { usePlaybookGrid } from "../../Plan/Playbooks/hooks/usePlaybookGrid";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * @description Display and attach a playbook to a threat
 * @param {string} organizationID - selected organization
 * @param {object} item - threat
 * @param {function} resetFunction - re-fetch parent data from the database
 * @returns {JSX.Element}
 * @constructor
 */
const ThreatPlaybook = ({ organizationID, item, resetFunction }) => {
  const module = modules.INCIDENT_RESPONSE;
  const resource = resources.THREAT;

  const checkPermissions = useCheckPermissions({ module, resource });

  const { addToast, updateToast } = useContext(UIContext);

  const gridConfig = {
    enableSelectButtons: true,
    selectButtonCallback: async (playbook) => {
      const toastId = addToast({
        header: `Attaching a playbook to the threat..`,
        icon: "spinner",
        color: "success",
      });

      if (item?.id && playbook?.id) {
        await ItemMutation(generateGraphql("Threat").updateMutation, {
          id: item?.id,
          playbookID: playbook?.id,
        }).then(() => {
          resetFunction?.();
          updateToast({
            id: toastId,
            header: `${playbook?.name} playbook was attached to the threat`,
            icon: "success",
          });
          modal.setModalIsOpen(false);
        });
      } else {
        ErrorLogger("Missing Threat or Playbook ID");
        updateToast({
          id: toastId,
          header: `Error! Can not attach selected playbook to the threat`,
          icon: "danger",
        });
      }
    },
  };

  const grid = usePlaybookGrid({ organizationID, gridConfig });

  const modal = useModal(
    "Select a Playbook",
    <div>{grid?.display}</div>,
    <Button size="sm" color="ghost-warning" className="btn-pill" title="Select a Playbook to attach to the threat">
      <i className="icon-pencil" />
    </Button>,
    {
      width: "70vw",
    },
  );

  /**
   * @description Remove a playbook from a threat
   * @returns {Promise<void>}
   */
  const removePlaybook = async () => {
    if (!window.confirm("Are you sure you want to remove the playbook from the threat?")) return;

    const toastId = addToast({
      header: `Removing playbook from threat..`,
      icon: "spinner",
      color: "success",
    });

    if (item?.id) {
      await ItemMutation(generateGraphql("Threat").updateMutation, {
        id: item?.id,
        playbookID: null,
      }).then(() => {
        resetFunction?.();
        updateToast({
          id: toastId,
          header: `Playbook was removed from the threat`,
          icon: "success",
        });
      });
    } else {
      ErrorLogger("Missing Threat ID");
      updateToast({
        id: toastId,
        header: `Error! Can not remove playbook from the threat`,
        icon: "danger",
      });
    }
  };

  return (
    <div>
      {item?.playbook?.name ? item?.playbook?.name : <i>No Response Playbook Selected</i>}{" "}
      {checkPermissions.resource.update && (
        <span>
          {modal?.modalButton}
          {item?.playbook?.id && (
            <Button
              id={"button-remove-playbook-from-threat"}
              color="ghost-danger"
              className={"btn-pill"}
              onClick={() => removePlaybook()}
              size="sm"
              title={"Remove Response Playbook from the threat"}
            >
              <i className="icon-trash" />
            </Button>
          )}
        </span>
      )}
      <hr />
      {item?.playbook?.id && (
        <PlaybookProcedureTabs
          item={item?.playbook}
          orientation={"vertical"}
          typename={"Playbook"}
          manageEditsSwitch={true}
          disableEdits={true}
        />
      )}
    </div>
  );
};

export default withOrganizationCheck(ThreatPlaybook);
