import { API, graphqlOperation } from "@aws-amplify/api";
import { ErrorLogger, InfoLogger } from "@utils/EventLogger";

import { generateGraphql } from "@rivial-security/generategraphql";

const { deleteMutation: deleteImplementationLevel } = generateGraphql("ImplementationLevel");

export const DeleteImplementationLevel = async (item) => {
  return await API.graphql(
    graphqlOperation(deleteImplementationLevel, {
      input: {
        id: item.id,
      },
    }),
  )
    .then(({ data }) => {
      if (data && data.deleteImplementationLevel) {
        InfoLogger(`Implementation Level ${data.deleteImplementationLevel.id} was Successfully Deleted`);
      }
    })
    .catch((err) => ErrorLogger("Error! Implementation Level delete function: ", err));
};
