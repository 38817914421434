import { useModal } from "../../../../hooks/views/useModal";
import PlaybookBuilder from "../../PlaybookBuilder/components/PlaybookBuilder";
import Button from "@mui/material/Button";
import BuildIcon from "@mui/icons-material/Build";
import React from "react";

/**
 * Button that allows to open a playbook builder modal
 * @param {object} item - the parent resource data
 * @param {string} organizationID - selected organization id
 * @param {function} resetFunction - callback for triggering a refresh of the parent resource
 * @param {string} updateMutation - the graphql update mutation to use when updating the ite's playbook
 */
const useConfigurePlaybookButton = ({ item, organizationID, resetFunction, typename }) => {
  const incidentPlaybookModal = useModal(
    `${typename || "Resource"} Playbook Builder`,
    <PlaybookBuilder item={item} typename={typename} organizationID={organizationID} resetFunction={resetFunction} />,
    <Button title={`Configure this ${typename}'s Playbook`}>
      <BuildIcon />
    </Button>,
    {
      width: "70vw",
    },
  );

  return {
    configurePlaybookModalButton: incidentPlaybookModal?.modalButton,
    setPlaybookBuilderModalIsOpen: incidentPlaybookModal?.setModalIsOpen,
  };
};

export default useConfigurePlaybookButton;
