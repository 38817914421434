import { useEffect, useState } from "react";

import { ItemQuery } from "../../../../utils/Functions/Graphql/ItemQuery";
import { UserActivityListItem } from "./UserActivityListItem";
import { eventType } from "../../../Notifications/enums/eventType";
import { generateGraphql } from "@rivial-security/generategraphql";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { listUserEvents } from "../../../../application/Toasts/functions/listUserEvents";

/**
 * Displays a list of activity items for the current user, sorted by updated date
 * @param {object} item - the point of contact db object
 */
const UserActivityList = ({ item, activity: activityParam }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [activity, setActivity] = useState(activityParam || []);

  //Get activity data on first loading the component
  const retrieveActivityData = async () => {
    if (!item) {
      setActivity([]);
      return;
    }

    setIsLoading(true);

    const { getQuery } = generateGraphql("PointOfContact", ["user"], {
      user: `{ id }`,
    });

    const pointOfContact = await ItemQuery(getQuery, item.id);

    let activityResult = [];
    if (pointOfContact?.user?.id) {
      activityResult = await listUserEvents({
        userID: pointOfContact.user.id,
        eventType: eventType.ACTIVITY,
        fields: ["id", "updatedAt", "header", "data"],
      });
    }

    setActivity(activityResult);
    setIsLoading(false);
  };
  useEffect(() => {
    if (isNullOrUndefined(activityParam)) {
      retrieveActivityData();
    }
  }, [item]);

  return (
    <div>
      {isLoading ? (
        <i> Point of Contact History is Loading... </i>
      ) : activity && Array.isArray(activity) && activity.length > 0 ? (
        activity.map((item, index) => {
          return (
            <div
              style={{
                ...(index % 2 === 0 && { backgroundColor: "whiteSmoke" }),
                padding: ".5em",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <UserActivityListItem data={JSON.parse(item.data)} header={item.header} />
              <div
                style={{
                  minWidth: "13em",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <p>{item?.updatedAt ? new Date(item.updatedAt).toLocaleString() : ""}</p>
              </div>
            </div>
          );
        })
      ) : (
        <i> There is no history available. </i>
      )}
    </div>
  );
};

export default UserActivityList;
