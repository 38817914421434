import React, { useEffect } from "react";
import { Allotment } from "allotment";
import "allotment/dist/style.css";
import { useCustomQueryBuilder } from "./useCustomQueryBuilder";
import { useCustomQueryRunner } from "./useCustomQueryRunner/useCustomQueryRunner";
import { QUERY_RUN_TYPE } from "./useCustomQueryRunner/hooks/useCustomQueryRunnerExecutionLogic/useCustomQueryRunnerExecutionLogic";
import SubmitCreateCustomQueryButton from "../components/SubmitCreateCustomQueryButton";

/**
 * Allows the user to perform a GraphQL query based on the current schema
 * @param {string} organizationID - the currently selected organization id
 * @param {function} externalSetQueryConfig - function that sets the current query configuration
 * @param {object} loadedQuery - an existing query that was loaded in by the user
 * @param {boolean} enableSubmitButton - whether to show the submit button
 * @param {function} [toggleModal] - function to toggle the modal container (if any)
 * @param {function} [getNewItem] - function to get the new item to parent after its creation
 * @param {function} [enableQueryRunner = true] - if TRUE will enable the query runner
 * @returns {{display: JSX.Element, builder: {display: JSX.Element, query: *, graphql: string}}}
 */
export const useCreateCustomQuery = ({
  organizationID = undefined,
  setQueryConfig: externalSetQueryConfig,
  loadedQuery,
  enableSubmitButton = false,
  toggleModal,
  getNewItem,
  enableQueryRunner = organizationID === "TEMPLATE" ? false : true,
} = {}) => {
  const customQueryBuilder = useCustomQueryBuilder({ loadedQuery });
  const customQueryRunner = useCustomQueryRunner({
    query: customQueryBuilder?.query,
    organizationID,
    persistenceUUID: "create-custom-query-runner",
    runType: QUERY_RUN_TYPE.LOCAL,
  });

  //If external setters were provided update them when the internal state changes
  useEffect(() => {
    if (typeof externalSetQueryConfig === "function") {
      externalSetQueryConfig(customQueryBuilder?.query);
    }
  }, [customQueryBuilder?.query]);

  const display = (
    <div
      style={{
        height: "100%",
        width: "100%",
        border: "1px solid #e0e0e0",
        borderRadius: "4px",
        padding: ".5em",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          gap: ".5em",
        }}
      >
        <div style={{ flex: 1 }}>
          <Allotment vertical>
            <Allotment.Pane minSize={200}>{customQueryBuilder.display}</Allotment.Pane>
            {enableQueryRunner && <Allotment.Pane minSize={200}>{customQueryRunner.display}</Allotment.Pane>}
          </Allotment>
        </div>
        {enableSubmitButton && (
          <SubmitCreateCustomQueryButton
            organizationID={organizationID}
            queryConfig={customQueryBuilder?.query}
            graphql={customQueryBuilder?.graphql}
            toggleModal={toggleModal}
            getNewItem={getNewItem}
          />
        )}
      </div>
    </div>
  );

  return { display, builder: customQueryBuilder };
};
