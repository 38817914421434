import GridCreateModalButton from "../customFields/GridCreateModalButton";
import GridToolbarMenu from "../customFields/GridToolbarMenu";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * @description Grid toolbar items
 * @param {boolean} enableEdit - enable edit functionality for the grid
 * @param {boolean} enableColumnChooser - display column chooser for the grid
 * @param {JSX} createResourceComponent - create item component
 * @param {object} checkPermissionsHook - checks permissions for the grid component
 * @param {boolean} enableSearch - display search bar for the grid
 * @return {object} {(string|boolean|{tooltipText: string, text: string, id: string, prefixIcon: string, align: string})[]}
 */
export const gridToolbarOptionsInit = ({
  enableEdit,
  enableColumnChooser,
  createResourceComponent,
  checkPermissionsHook,
  enableSearch,
}) => {
  return [
    {
      template: GridToolbarMenu,
    },
    ...(!isNullOrUndefined(createResourceComponent) && checkPermissionsHook?.resource?.create
      ? [{ template: GridCreateModalButton }]
      : []),
    ...(enableEdit ? ["Edit"] : []),
    ...(enableColumnChooser ? ["ColumnChooser"] : []),
    ...(enableSearch ? ["Search"] : []),
  ];
};
