import { ErrorLogger } from "../EventLogger";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * For calling simple functions when you're not sure if the function is null.
 * Cleaner way than writing 'toggleModal && toggleModal()'
 *
 * @param functions - an unspecified amount of parameters
 * @example tryFunction(toggleModal, resetFunction, callback);
 */
export const tryFunction = (...functions) => {
  for (const func of functions) {
    if (!isNullOrUndefined(func) && typeof func === "function") {
      func();
    }
  }
};

/**
 * For calling simple functions when you're not sure if the function is null.
 * This variant passes down item into the function as its only positional parameter.
 * @param {object} item - the item to pass into the functions
 * @param {boolean} ignoreOnNull - if true will ignore running the functions if item is null
 * @param {object} functions - remaining functions to try to run with the item input
 */
export const tryItemFunction = ({ item, ignoreOnNull = true, ...functions }) => {
  if (isNullOrUndefined(item) && ignoreOnNull) {
    return;
  }

  if (typeof functions !== "object") {
    ErrorLogger("[tryItemFunction] functions not an object");
    return;
  }

  for (const funcName in functions) {
    const func = functions[funcName];
    if (!isNullOrUndefined(func) && typeof func === "function") {
      func(item);
    }
  }
};
