/**
 * Author: Jacob Blazina
 * Created At: 10/3/19
 * Edits:
 *  - 3/30/20 JB: Added permissions checking to setGoal
 *
 * Description: Displays a Program Blueprint Level component.
 *              Edits are turned off by default, but may be switched on via the `enableEdits` prop.
 *              This component displays a list of Maturity Indicators as it's children.
 */

import { Button, ButtonGroup, CardBody, Col, ListGroup, Row } from "reactstrap";
import React, { useEffect, useState } from "react";
import { modules, resources } from "@rivial-security/role-utils";

import CreateMaturityIndicator from "../MaturityIndicator/CreateMaturityIndicator";
import DeleteMaturityMatrixLevel from "../../../functions/DeleteMaturityMatrixLevel";
import { GenericDeleteButton } from "../../../../../../utils/GenericComponents/GenericDeleteButton";
import GenericEditField from "../../../../../../utils/GenericComponents/GenericEditField";
import { GenericModalWithButton } from "../../../../../../utils/GenericComponents/GenericModalWithButton";
import MaturityIndicator from "../MaturityIndicator/MaturityIndicator";
import PropTypes from "prop-types";
import { useCheckPermissions } from "../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";

const MaturityMatrixLevelHorizontal = (props) => {
  const module = modules.GOVERNANCE;
  const resource = resources.BLUEPRINT;

  const checkPermissionsHook = useCheckPermissions({ module, resource });

  const [state, setState] = useState("none");

  useEffect(() => {
    const status = getStatus(props.maturityMatrixLevel);

    setState(status);
  }, [props.maturityMatrixLevel]);

  const getStatus = (matrixLevel) => {
    if (props.goal === props.maturityMatrixLevel.id) {
      setState(props.goal);
    }

    let status = "current";

    for (const indicator of matrixLevel.maturityIndicators.items) {
      if (indicator.isTrue !== "true") {
        status = "none";
      }
    }

    if (matrixLevel.maturityIndicators.items.length < 1) {
      status = "none";
    }

    return status;
  };

  return (
    <Col
      className="shadow appearance-none border rounded text-grey-darker leading-tight focus:outline-none focus:shadow-outline"
      style={{
        display: "inline-block",
        float: "none",
        width: "300px",
        minHeight: "100%",
        padding: "5px",
        background: state === "current" ? "#d6f5d6" : props.goal === props.maturityMatrixLevel.id ? "#99e6ff" : "white",
      }}
    >
      {props.maturityMatrixLevel ? (
        <>
          <Row>
            <Col>
              <h5>
                <GenericEditField
                  item={props.maturityMatrixLevel}
                  field="name"
                  mutation="updateMaturityMatrixLevel"
                  disableEdits={!props.enableEdits}
                />
              </h5>
            </Col>
            <CardBody>
              {props.enableEdits ? (
                <ButtonGroup size="sm">
                  <GenericModalWithButton
                    title={`Create Maturity Indicator for: ${props.maturityMatrixLevel.name}`}
                    body={
                      <CreateMaturityIndicator
                        enableEdits={props.enableEdits}
                        maturityMatrixLevel={props.maturityMatrixLevel}
                      />
                    }
                    modalButton={
                      <Button size="sm" title="Create Maturity Indicator" className="float-right" color="ghost-success">
                        <i className="icon-plus" />
                      </Button>
                    }
                  />
                  <GenericDeleteButton
                    data-test="button-delete-pointOfContact"
                    id="button-delete-pointOfContact"
                    deleteFunction={DeleteMaturityMatrixLevel}
                    item={props.maturityMatrixLevel}
                    className="btn-sm"
                  />
                </ButtonGroup>
              ) : null}
            </CardBody>
          </Row>
          <ListGroup>
            {props.maturityMatrixLevel.maturityIndicators &&
            props.maturityMatrixLevel.maturityIndicators.items &&
            props.maturityMatrixLevel.maturityIndicators.items.length > 0
              ? props.maturityMatrixLevel.maturityIndicators.items.map((indicator, index) => (
                  <MaturityIndicator
                    key={`maturityIndicator${indicator.id}${index}`}
                    maturityIndicator={indicator}
                    setModalBody={props.setModalBody}
                    enableEdits={props.enableEdits}
                  />
                ))
              : "No Maturity Indicators"}
          </ListGroup>
        </>
      ) : (
        "Error: No Maturity Level Found"
      )}
    </Col>
  );
};

MaturityMatrixLevelHorizontal.propTypes = {
  maturityMatrixLevel: PropTypes.object.isRequired,
  enableEdits: PropTypes.bool,
  setModalBody: PropTypes.func,
};

MaturityMatrixLevelHorizontal.defaultProps = {
  enableEdits: false,
};

export default MaturityMatrixLevelHorizontal;
