import React, { useContext, useEffect, useState } from "react";
import { isNonEmptyArray, tryParse } from "@rivial-security/func-utils";

import Add from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import CreateCustomQuery from "../../../../../../../CustomQueries/components/CreateCustomQuery";
import { CreateWidgetContext } from "../../../useCreateWidget";
import FeatureAlert from "../../../../../../../../utils/GenericComponents/FeatureAlert";
import { ListQuery } from "@rivial-security/appsync-utils";
import Loader from "../../../../../../../../utils/LoadingComponents/Loader";
import { QUERY_RUN_TYPE } from "../../../../../../../CustomQueries/hooks/useCustomQueryRunner/hooks/useCustomQueryRunnerExecutionLogic/useCustomQueryRunnerExecutionLogic";
import StyledWrapper from "../../../../../../../../utils/GenericComponents/StyledWrapper";
import { TemplateQueryAlert } from "../../TemplateQueryAlert";
import { createWidgetActions } from "../../../reducers/createWidgetActions";
import { generateGraphql } from "@rivial-security/generategraphql";
import { getInitialTableSettingsFormInput } from "../../CustomizeVisualsStep/functions/getInitialTableSettingsFormInput";
import { useCustomQueryRunner } from "../../../../../../../CustomQueries/hooks/useCustomQueryRunner/useCustomQueryRunner";
import { useForm } from "../../../../../../../../hooks/views/useForm";
import { useModal } from "../../../../../../../../hooks/views/useModal";
import { useOrganizationContext } from "../../../../../../../AdminPanel/Organizations/hooks/useOrganizationContext";
import { useResultFieldViewer } from "../../../../../../../CustomQueries/hooks/useResultFieldViewer";
import { useSetAsyncData } from "../../../../../../../../hooks/functional/useSetAsyncData";
import { v4 as uuid } from "uuid";
import { withOrganizationCheck } from "../../../../../../../../utils/Context/withOrganizationCheck";

/**
 * Component for selecting the custom queries to populate a table widget
 * //NOTE: currently only one series is supported for table widgets, top level function data also unsupported
 * @param {string} organizationID - the currently selected organization ID
 * @returns {JSX.Element}
 */
const TableWidgetDataSelection = ({ organizationID }) => {
  const { widget, dispatch } = useContext(CreateWidgetContext);

  const [isInitialized, setIsInitialized] = useState(false);
  const [editedSeriesItem, setEditedSeriesItem] = useState(null);
  const [customQueries, setCustomQueries] = useState(null);
  const [queryConfig, setQueryConfig] = useState({});

  // Updates the shown preview state based on the series settings
  useEffect(() => {
    let currentSeriesItem = null;
    const series = widget?.config?.series;
    if (isNonEmptyArray(series)) {
      currentSeriesItem = series[0];
    }

    if (!currentSeriesItem) {
      currentSeriesItem = {
        id: uuid(),
      };
    }

    setEditedSeriesItem(currentSeriesItem);

    const customQuery = (customQueries || []).find((item) => {
      return item.id === currentSeriesItem?.customQueryId;
    });

    if (customQuery?.queryConfig) {
      const tableSettingsInput = getInitialTableSettingsFormInput({
        editedSeries: currentSeriesItem,
        queryConfig: customQuery?.queryConfig || {},
        forceDefault: currentSeriesItem?.customQueryId !== editedSeriesItem?.customQueryId,
      });

      dispatch({
        type: createWidgetActions.UPSERT_WIDGET_SERIES_INPUT,
        seriesInput: {
          ...currentSeriesItem,
          tableSettings: {
            ...tableSettingsInput,
          },
        },
      });
    }

    setQueryConfig(customQuery?.queryConfig || {});
  }, [JSON.stringify(widget.config?.series), customQueries]);

  const { isLoading } = useSetAsyncData({
    getData: async () => {
      const { listQuery } = generateGraphql("CustomQuery", ["name", "description", "fields", "queryConfig"]);
      let allQueries = await ListQuery({
        query: listQuery,
        organizationID: organizationID,
      });

      if (Array.isArray(allQueries)) {
        allQueries = allQueries.map((query) => {
          return {
            ...query,
            queryConfig: tryParse(query?.queryConfig || "{}"),
          };
        });
      }
      return allQueries;
    },
    setData: (newCustomQueries) => {
      setCustomQueries(newCustomQueries);
    },
    dependencies: [organizationID],
  });

  const createQueryModal = useModal(
    "Create Custom Query",
    <StyledWrapper
      wrapperStyle={{
        height: "90vh",
      }}
    >
      <CreateCustomQuery
        organizationID={organizationID}
        enableSubmitButton={true}
        getNewItem={(item) => {
          if (item) {
            item.queryConfig = tryParse(item.queryConfig);
            setCustomQueries((customQueries) => [...customQueries, item]);
            querySelectionForm.setInput({ customQueryId: item.id });
          }
        }}
      />
    </StyledWrapper>,
    <Button startIcon={<Add />} color={"success"} className={"float-right"}>
      Create
    </Button>,
    {
      width: "75vw",
    },
  );

  const querySelectionForm = useForm({
    fieldConfig: {
      customQueryId: {
        label: `Choose a Custom Query${organizationID === "TEMPLATE" ? " Template" : ""}`,
        tooltip: "Select a query with which to populate this widget ",
        inputType: "dropdown",
        createItemComponent: createQueryModal.modalButton,
        dropdownConfig: {
          data: (customQueries || []).map((customQuery) => {
            return {
              value: customQuery.id,
              text: customQuery.name,
            };
          }),
        },
      },
    },
    disableSubmitButton: true,
    disableResetButton: true,
  });

  const runner = useCustomQueryRunner({
    query: queryConfig,
    organizationID,
    runType: QUERY_RUN_TYPE.LOCAL,
    title: "Data Preview",
    disableTopLevelFunctions: true,
  });

  const viewer = useResultFieldViewer({
    resultFields: queryConfig?.fields,
    resultFunctions: queryConfig?.functions,
  });

  //Change value of widget series when form changes after initialization
  useEffect(() => {
    if (!isInitialized) {
      return;
    }

    const newSeriesItem = {
      ...editedSeriesItem,
      customQueryId: querySelectionForm?.input?.customQueryId,
    };

    dispatch({
      type: createWidgetActions.UPSERT_WIDGET_SERIES_INPUT,
      seriesInput: newSeriesItem,
    });
  }, [querySelectionForm?.input?.customQueryId]);

  //Wait for form to be initialized before allowing it to dispatch changes
  useEffect(() => {
    //The edited series item has not been loaded yet
    if (!editedSeriesItem) {
      return;
    }

    //Check if there is anything to initialize
    if (!editedSeriesItem?.customQueryId) {
      setIsInitialized(true);
      return;
    }

    //Form not yet set to the currently edited value
    if (querySelectionForm?.input?.customQueryId !== editedSeriesItem?.customQueryId) {
      return;
    }

    setIsInitialized(true);
  }, [editedSeriesItem, querySelectionForm?.input?.customQueryId]);

  useEffect(() => {
    if (!isInitialized && editedSeriesItem && customQueries) {
      querySelectionForm.setInput({
        customQueryId: editedSeriesItem?.customQueryId,
      });
    }
  }, [customQueries, editedSeriesItem]);

  const { organizationName } = useOrganizationContext();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: ".5em" }}>
        {isLoading ? <Loader text={"Loading Tree"} /> : querySelectionForm.display}
        {viewer.display}

        <div style={{ maxWidth: "20em" }}>
          <FeatureAlert
            header={"Tip!"}
            text={
              "All table widget row data comes from Custom Queries. You can preview the data by pressing 'Run Query' in the data grid toolbar."
            }
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flex: 1,
          height: "70vh",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: ".5em",
        }}
      >
        {organizationID === "TEMPLATE" && <TemplateQueryAlert />}
        {runner.display}
      </div>
    </div>
  );
};

export default withOrganizationCheck(TableWidgetDataSelection);
