import MuiItemPreviewButton from "@utils/GenericComponents/buttons/MuiItemPreviewButton";
import MuiItemDownloadButton from "@utils/GenericComponents/buttons/MuiItemDownloadButton";
import AVStatus from "@utils/CustomFields/AVStatus";
import { AV_STATUS } from "@enums/AV_STATUS";
import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { DataGridField } from "@hooks/views/useDataGrid/types";

export const getFileDataGridFields = (): DataGridField[] => {
  return [
    {
      name: "preview",
      // @ts-expect-error - item prop passed in by useDataGrid
      component: <MuiItemPreviewButton size={"medium"} />,
      disablePropagation: true,
      sortable: false,
      filterable: false,
      exportable: false,
      width: 200,
    },
    {
      name: "download",
      // @ts-expect-error - item prop passed in by useDataGrid
      component: <MuiItemDownloadButton size={"medium"} />,
      disablePropagation: true,
      sortable: false,
      filterable: false,
      exportable: false,
      width: 200,
    },
    {
      name: "avStatus",
      friendlyName: "Anti-Virus Status",
      // @ts-expect-error - item prop passed in by useDataGrid
      component: <AVStatus />,
      valueGetter: (value) => {
        if (!value) {
          return "notScanned";
        }
        return value;
      },
      flex: 1,
      minWidth: 100,
      type: "singleSelect",
      valueOptions: [
        {
          value: "notScanned",
          label: "Not Scanned",
        },
        ...(Object.values(AV_STATUS)?.map((status) => {
          return {
            value: status,
            label: convertCamelCaseToSentence(status),
          };
        }) ?? {}),
      ],
    },
    {
      name: "lastAVCheck",
      friendlyName: "Last Anti-Virus Check",
      type: "date",
      flex: 1,
      minWidth: 200,
    },
  ];
};
