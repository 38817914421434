import { useForm } from "../../../../../../hooks/views/useForm";
import { withOrganizationCheck } from "../../../../../../utils/Context/withOrganizationCheck";
import { useContext, useEffect } from "react";
import { createWidgetActions } from "../reducers/createWidgetActions";
import { CreateWidgetContext } from "../useCreateWidget";

/**
 * Custom component responsible for letting the users select the data needed to populate their widgets with information
 * @param {string} organizationID
 * @returns {*}
 */
const AddDetailsStep = ({ organizationID }) => {
  const { widget, dispatch } = useContext(CreateWidgetContext);

  const form = useForm({
    item: widget,
    organizationID,
    disableRoleChecking: true,
    disableSubmitButton: true,
    disableResetButton: true,
    fields: ["name", "description"],
    fieldConfig: {
      name: {
        label: "Name",
        tooltip: "Provide a friendly name for this Widget",
        required: true,
      },
      description: {
        label: "Description",
        tooltip: "Optionally provide a more detailed description for this Widget",
      },
    },
  });

  useEffect(() => {
    dispatch({
      type: createWidgetActions.UPDATE_WIDGET_INPUT,
      newWidget: {
        ...form.input,
      },
    });
  }, [JSON.stringify(form.input)]);

  return form.display;
};

export default withOrganizationCheck(AddDetailsStep);
