import { convertCamelCaseToSentence, removeObjectFromArray } from "@rivial-security/func-utils";

import Avatar from "@mui/material/Avatar";
import EditAutomation from "./EditAutomation";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import { useModal } from "../../../../hooks/views/useModal";
import { useOpenArticle } from "../../../HelpCenter/hooks/useOpenArticle";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * Displays a single Step in the list of automations steps
 * @param {AutomationStep} automationStep - the automation step to display
 * @param {object} automation - the parent automation item itself, with trigger and condition info
 * @param {object[]} automations - the current automation step info for the parent node
 * @param {function} setAutomations - function to set the automations
 * @param {string} organizationID - the organization ID
 * @param {object} trigger - the trigger for the automation
 * @param {boolean} isTemplate - if TRUE will create the automation as a template
 * @param {object[]} ancestorSteps - the preceding step data of the current automation steps (if any)
 * @param {boolean} readOnly - if TRUE will disable the editing and deletion of the step
 * @param {boolean} showLoopSmartValues - if TRUE will show the smart values for the loop step
 * @returns {JSX.Element}
 */
const AutomationListItem = ({
  automationStep,
  automation,
  automations,
  setAutomations,
  organizationID,
  trigger,
  isTemplate = false,
  ancestorSteps,
  readOnly = false,
  showLoopSmartValues,
}) => {
  const openArticle = useOpenArticle({
    helpCenterRoute: "",
    overrideUrl: automationStep?.helpCenterUrl,
  });
  const onDeleteClick = ({ step }) => {
    const name = step?.name;
    const type = step?.type;
    //Confirm with the user that they want to delete this step
    const confirmDelete = window.confirm(
      `Are you sure you want to delete ${
        name ? `the step '${name}'` : `the ${convertCamelCaseToSentence(type || "generic")} step`
      }?`,
    );
    if (confirmDelete) {
      const newAutomations = [...(automations || [])];
      removeObjectFromArray(newAutomations, automationStep);
      setAutomations(newAutomations);
    }
  };

  const modal = useModal(
    `Edit Step: ${convertCamelCaseToSentence(automationStep.type)}`,
    <EditAutomation
      automationStep={automationStep}
      automation={automation}
      automations={automations}
      ancestorSteps={ancestorSteps}
      setAutomations={setAutomations}
      organizationID={organizationID}
      trigger={trigger}
      isTemplate={isTemplate}
      readOnly={readOnly}
      showLoopSmartValues={showLoopSmartValues}
    />,
    null,
    {
      width: "75vw",
    },
  );

  const primaryText = automationStep.text + (automationStep.name ? ` (${automationStep.name})` : "");
  return (
    <ListItem
      title={`Automation Step: ${automationStep.text} - ${automationStep.description}`}
      secondaryAction={
        <span>
          {readOnly === true && (
            <IconButton
              title={"View Automation Step"}
              edge="end"
              aria-label="view"
              onClick={() => modal.setModalIsOpen(true)}
              size="large"
            >
              <i className={"icon-info"} />
            </IconButton>
          )}
          {readOnly !== true && (
            <IconButton
              title={"Edit Automation Step"}
              edge="end"
              aria-label="edit"
              onClick={() => modal.setModalIsOpen(true)}
              size="large"
            >
              <i className={"icon-pencil"} />
            </IconButton>
          )}
          {readOnly !== true && (
            <IconButton
              title={"Remove Automation Step"}
              edge="end"
              aria-label="delete"
              onClick={() => onDeleteClick({ step: automationStep })}
              size="large"
            >
              <i className="icon-trash" />
            </IconButton>
          )}
          <IconButton
            title={"Open the Help Center for Information about Automation Step"}
            edge="end"
            aria-label="edit"
            onClick={openArticle}
            size="large"
          >
            <i className="icon-question" />
          </IconButton>
        </span>
      }
    >
      {modal.modal}
      <div
        style={{
          height: "100%",
          fontSize: "0.75em",
          marginRight: "1em",
          fontColor: "grey",
        }}
      >
        {automationStep?.executionOrder}
      </div>
      <ListItemAvatar>
        <Avatar>{automationStep.icon}</Avatar>
      </ListItemAvatar>
      <ListItemText primary={primaryText} secondary={automationStep.description} />
    </ListItem>
  );
};

export default withOrganizationCheck(AutomationListItem);
