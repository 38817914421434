import { modules, resources } from "@rivial-security/role-utils";

import EmailRecipients from "../components/EmailRecipients";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import React from "react";
import { generateGraphql } from "@rivial-security/generategraphql";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useEmailEditor } from "./useEmailEditor";
import { useForm } from "../../../../hooks/views/useForm";

/**
 * Allows the user to customize an Email Template
 * @param {string} organizationID - The ID of the organization
 * @param {function} toggleModal - The function to toggle the modal
 * @param {boolean} disableResetButton - Disable the reset button
 * @param {boolean} isTemplate - TRUE if this an (email template) template
 * @param {boolean} disableTemplates - TRUE if need to not show create from template button
 * @param {object} props - the props to pass through to the useForm
 * @returns {{display: *, input: {}}}
 */
export const useCreateEmailTemplate = ({
  item,
  organizationID,
  toggleModal,
  disableResetButton,
  isTemplate = false,
  disableTemplates = false,
  contentHTML,
  ...props
}) => {
  const module = modules.ORGANIZATION_MANAGER;
  const resource = resources.EMAIL_TEMPLATE;
  const typename = "Email";

  const emailEditor = useEmailEditor({
    item,
    organizationID,
    contentHTML,
    editorOptions: {
      contentWidth: 600,
    },
  });

  const { createMutation, updateMutation } = generateGraphql("Email", [
    "name",
    "subject",
    "recipients",
    "body",
    "design",
  ]);

  // Adds the body HTML before the mutation fires
  const submitFunction = async (input) => {
    const body = await emailEditor.exportHtml();

    const design = await emailEditor.exportDesign();

    const recipients = [];
    const replyTo = [];

    for (const item of input.recipients) {
      if (!isNullOrUndefined(item) && item !== "") {
        recipients.push(item);
      }
    }

    for (const item of input.replyTo) {
      if (!isNullOrUndefined(item) && item !== "") {
        replyTo.push(item);
      }
    }

    const res = {
      ...input,
      recipients,
      replyTo,
      body,
      design: JSON.stringify(design),
      ownerGroup: organizationID,
    };

    return await ItemMutation(item ? updateMutation : createMutation, res);
  };

  const form = useForm({
    item,
    toggleModal,
    disableResetButton,
    mutation: createMutation,
    organizationID,
    module,
    resource,
    typename,
    submitFunction,
    externalFormInvalid: emailEditor?.isReady === false,
    enableTemplates: !disableTemplates && !isTemplate && props?.enableTemplates !== false,
    fieldConfig: {
      name: {
        label: "Name",
        required: true,
        tooltip: "Choose a descriptive name for this Email Template. Recipients will not see this field",
      },
      recipients: {
        label: "Recipients",
        inputType: "custom",
        tooltip:
          "Select one or more email addresses. Choose from existing point of contact emails or manually add emails",
        customConfig: {
          component: <EmailRecipients key={"recipients"} item={item} organizationID={organizationID} />,
        },
      },
      replyTo: {
        label: "Reply-To",
        inputType: "custom",
        tooltip:
          "You may optionally select one or more emails to use as the 'reply-to' address. All replies go to 'support@rivialsecurity' by default",
        customConfig: {
          component: <EmailRecipients key={"replyTo"} item={item} field={"replyTo"} organizationID={organizationID} />,
        },
      },
      subject: {
        label: "Subject",
        tooltip: "The subject line for the sent emails. Supports smart values like {{item.name}}.",
        defaultValue: "Rivial Platform - Generic Email",
      },
      body: {
        label: "Body",
        tooltip:
          "Configure a custom Email Template body. Embed widgets or smart values in the body to be populated dynamically when the email is sent",
        inputType: "custom",
        removeFromInput: true,
        customConfig: {
          component: emailEditor.display,
        },
      },
    },
    ...props,
  });

  return {
    ...form,
    emailEditor,
  };
};
