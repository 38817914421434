import React, { useEffect } from "react";
import { Button } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useSaveCustomQueryModal } from "../hooks/useSaveCustomQueryModal";
import { tryFunction, tryItemFunction } from "../../../utils/Functions/tryFunction";

/**
 * Button allowing to save the current state of the query builder as a custom query
 * @param {string} organizationID - the organization under which query is to be saved
 * @param {object} queryConfig - the current query configuration of the query builder
 * @param {string} graphql - the current graph ql of the query builder
 * @param {function} [toggleModal] - function to toggle the modal container (if any)
 * @param {function} [getNewItem] - function to get the new item to parent after its creation
 * @returns {JSX.Element}
 */
const SubmitCreateCustomQueryButton = ({ organizationID, queryConfig, graphql, toggleModal, getNewItem }) => {
  const {
    modal: saveModal,
    setQueryConfig,
    setGraphql,
    onClickSave,
  } = useSaveCustomQueryModal({
    organizationID,
    callback: (item) => {
      tryFunction(toggleModal);
      tryItemFunction({ item, getNewItem });
    },
  });

  useEffect(() => {
    if (typeof setQueryConfig === "function") {
      setQueryConfig(queryConfig);
    }
  }, [queryConfig]);
  useEffect(() => {
    if (typeof setGraphql === "function") {
      setGraphql(graphql);
    }
  }, [graphql]);

  const onPressSubmit = () => {
    tryFunction(onClickSave);
  };

  return (
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "right" }}>
      {saveModal}
      <Button variant={"contained"} color={"success"} startIcon={<AddCircleOutlineIcon />} onClick={onPressSubmit}>
        Submit
      </Button>
    </div>
  );
};

export default SubmitCreateCustomQueryButton;
