import React from "react";
import TaskDataGrid from "../../../../../Program/Tasks/components/TaskDataGrid";

/**
 * Shows all tasks that relate to a particular point of contact
 * @param {object} pointOfContact - the point of contact for which to show tasks, must have id
 * @returns {JSX.Element}
 */
const PointOfContactTasks = ({ item: pointOfContact }) => {
  return (
    <div style={{ height: "50vh" }}>
      <TaskDataGrid
        queryConfig={{
          indexName: "taskByPointOfContact",
          indexQueryField: "pointOfContactID",
          indexQueryFieldTypename: "ID",
          limit: 1000,
          variables: {
            pointOfContactID: pointOfContact?.id,
          },
        }}
        createFormProps={{
          item: {
            pointOfContactID: pointOfContact?.id,
          },
        }}
      />
    </div>
  );
};

export default PointOfContactTasks;
