import { usePolicyDataGrid } from "../hooks/usePolicyDataGrid";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * @description Displays a list of policies for an org
 * @param {object} props - The props object for the component
 */
const PolicyDataGrid = (props) => {
  return usePolicyDataGrid(props).dashboardDisplay;
};

export default withOrganizationCheck(PolicyDataGrid);
