import { generateGraphql } from "@rivial-security/generategraphql";
import { GetQuery } from "../../../../utils/Functions/Graphql/GetQuery";
import { createEvidenceActivity } from "./createEvidenceActivity";
import { EVIDENCE_ACTIVITY_TYPES, EVIDENCE_STATUS } from "../../../../typedefs/Compliance/Evidence/Evidence";
import { formattedName } from "@rivial-security/func-utils";
import { generateValidatedBy } from "./generateValidatedBy";

/**
 * Creates an Evidence Activity item when an Evidence status changes
 * @param {string} evidenceID - the db identifier of the evidence
 * @param {string} oldStatus - the previous status of the evidence
 * @param {string} newStatus - the new status of the evidence
 * @param {string} organizationID - the owner group of the evidence
 * @param {string} pointOfContactID - the db identifier of the pont of contact
 * @param {string} userID - the db identifier of the user
 * @param {string} comment - comment or reason for the status switch (displayed in the UI)
 * @returns {Promise<void>}
 */
export const onEvidenceStatusChange = async ({
  evidenceID,
  oldStatus,
  newStatus,
  organizationID,
  pointOfContactID,
  userID,
  comment,
}) => {
  const { getQuery: getPointOfContact } = generateGraphql("PointOfContact", ["firstName", "lastName"]);
  const pointOfContact = await GetQuery({
    query: getPointOfContact,
    variables: { id: pointOfContactID },
  });

  /**
   * Evidence Activity input to be created
   */
  const input = {
    type: EVIDENCE_ACTIVITY_TYPES.STATUS_CHANGED_MANUALLY,
    comment,
    data: JSON.stringify({
      userID,
      pointOfContactName: formattedName({ pointOfContact }),
      oldStatus,
      newStatus,
    }),
  };

  /**
   * If the evidence is marked as 'inPlace',
   * we need to add the 'validatedBy' field to the input
   */
  if (newStatus === EVIDENCE_STATUS.IN_PLACE) {
    const validatedBy = generateValidatedBy({ pointOfContact });
    input.validatedBy = validatedBy ? validatedBy : "Someone";
  }

  return await createEvidenceActivity(input, evidenceID, organizationID);
};
