import React, { useEffect, useState } from "react";

import CreateChange from "../../CreateChange";
import OrganizationCheck from "../../../../../../utils/Context/OrganizationCheck/OrganizationCheck";
import { QueryGetItem } from "../../../../../../hooks/graphql/useQueryGetItem";
import { gql_createSystemChangeLink } from "../../../graphql/gql_createSystemChangeLink";
import { handleRiskChangePromises } from "../../../functions/handleRiskChangePromises";
import { realTimeRiskGQL } from "@rivial-security/risk-calc-utils";
import { updateOrganization } from "../../../graphql/gql_updateOrganization";
import { useAdjustNumberOfCustomers } from "../hooks/useAdjustNumberOfCustomers";
import { useLoadingAndOutdated } from "../../../hooks/useLoadingAndOutdated";
import { useMutation } from "../../../../../../hooks/graphql/useMutation/useMutation";
import { useOverallChanges } from "../../../hooks/useOverallChanges";
import { useRiskChangeForm } from "../../../hooks/useRiskChangeForm";
import { useRiskChangeSystemsTable } from "../../../hooks/useRiskChangeSystemsTable";
import { useStateEffect } from "../../../../../../hooks/functional/useStateEffect";
import { useSystemList } from "../../../../Systems/hooks/useSystemList";
import { useUpdateFormHook } from "../../../hooks/useUpdateFormHook";
import { useUpdateSystemsTable } from "../../../hooks/useUpdateSystemsTable";

const CreateNumberOfCustomersChangeBody = ({ item, organizationID, resetFunction, toggleModal, ...props }) => {
  const [changeItem, setChangeItem] = useState({
    numberOfCustomersChange: parseFloat(0),
  });

  const loadingAndOutdated = useLoadingAndOutdated(changeItem);

  const associatedSystemsHook = useSystemList({ organizationID });

  const overallChanges = useOverallChanges();

  const [oldNumberOfCustomers, setOldNumberOfCustomers] = useState(0);
  const [newNumberOfCustomers] = useStateEffect(0, [changeItem], () => {
    return oldNumberOfCustomers + changeItem.numberOfCustomersChange;
  });

  useEffect(() => {
    QueryGetItem({
      query: realTimeRiskGQL.numberOfCustomersGQL,
      itemId: organizationID,
      disableRoleChecking: true,
    }).then((org) => {
      setOldNumberOfCustomers(org.numberOfCustomers);
    });
  }, []);

  const systemsTable = useRiskChangeSystemsTable({
    tooltip: "All systems in this Organization",
  });

  /**
   * This function adapts the formHook input object to match object needed for the riskChange
   * @param input
   * @returns {{change: string, type: string}}
   */
  const updateInputFunction = (input) => {
    return {
      ...input,
      type: "number_of_customers_change",
      change: JSON.stringify({
        ...overallChanges,
        numberOfCustomersChange: changeItem.numberOfCustomersChange,
        oldNumberOfCustomers: oldNumberOfCustomers,
        newNumberOfCustomers: newNumberOfCustomers,
      }),
    };
  };

  const createSystemChangeLink = useMutation({
    mutation: gql_createSystemChangeLink,
    disableRoleChecking: true,
    disableToast: true,
  });
  const updateNumberOfCustomers = useMutation({
    mutation: updateOrganization,
    disableRoleChecking: true,
    disableToast: true,
  });

  /**
   * This callback updates the Organization AssetSize after the RiskChange gets created.
   * @param riskChange
   */
  const callback = async (riskChange) => {
    if (riskChange) {
      await updateNumberOfCustomers.editItem({
        id: organizationID,
        numberOfCustomers: changeItem.numberOfCustomersChange
          ? oldNumberOfCustomers + changeItem.numberOfCustomersChange
          : undefined,
      });

      await handleRiskChangePromises(
        associatedSystemsHook.data,
        createSystemChangeLink.createItem,
        organizationID,
        riskChange,
      );

      !props.getNewItem && resetFunction && resetFunction();
    }
  };

  const formHook = useRiskChangeForm({
    item,
    typename: "Number of Customers/Members Change",
    organizationID,
    updateInputFunction,
    callback,
    resetFunction,
    toggleModal,
    ...props,
  });

  useUpdateFormHook(item, formHook);
  useUpdateSystemsTable(systemsTable, overallChanges);

  return (
    <CreateChange
      organizationID={organizationID}
      changeItem={changeItem}
      loadingAndOutdated={loadingAndOutdated}
      overallChanges={overallChanges}
      formHook={formHook}
      systemsTable={systemsTable}
      adjustComponent={
        <AdjustNumberOfCustomers
          numberOfCustomers={oldNumberOfCustomers}
          changeItem={changeItem}
          setChangeItem={setChangeItem}
        />
      }
    />
  );
};

const AdjustNumberOfCustomers = ({ numberOfCustomers, changeItem, setChangeItem }) => {
  const adjustNumberOfCustomersHook = useAdjustNumberOfCustomers({
    numberOfCustomers,
  });

  // Create the temp item for monte carlo
  useEffect(() => {
    if (adjustNumberOfCustomersHook.numberOfCustomersChange) {
      setChangeItem({
        numberOfCustomersChange: parseFloat(adjustNumberOfCustomersHook.numberOfCustomersChange),
      });
    }
  }, [numberOfCustomers, adjustNumberOfCustomersHook.numberOfCustomersChange]);

  return <div>{adjustNumberOfCustomersHook.display}</div>;
};

const CreateNumberOfCustomersChange = (props) => {
  return (
    <OrganizationCheck {...props}>
      <CreateNumberOfCustomersChangeBody />
    </OrganizationCheck>
  );
};

export default CreateNumberOfCustomersChange;
