import { modules, resources } from "@rivial-security/role-utils";

import CreateControlCategorySubControl from "../components/CreateControlCategorySubControl";
import React from "react";
import { realTimeRiskGQL } from "@rivial-security/risk-calc-utils";
import { useQueryCard } from "../../../../../../../hooks/views/useQueryCard";

export const useListRiskControls = ({
  controlCategoryId,
  organizationID,
  controlCategories,
  queryConfig,
  dataCardConfig,
}) => {
  const module = modules.RISK;
  const resource = resources.RISK_CONTROL_CATEGORY;

  const queryConfigs = {
    query: realTimeRiskGQL.listRiskControls_riskControlList,
    organizationID: organizationID,
    ...queryConfig,
  };

  const dataCardConfigs = {
    header: "Risk Controls",
    fields: ["statementNumber", "name"],
    createResourceComponent: (
      <CreateControlCategorySubControl
        controlCategoryID={controlCategoryId}
        controlCategories={controlCategories}
        organizationID={organizationID}
      />
    ),
    ...dataCardConfig,
  };

  const config = {};

  const queryCard = useQueryCard({
    queryConfig: queryConfigs,
    dataCardConfig: dataCardConfigs,
    config,
    module,
    resource,
  });

  return { ...queryCard };
};
