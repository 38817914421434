import { useControlSetDetails } from "../hooks/useControlSetDetails";
import { withDetailsPage } from "../../../../../utils/Context/withDetailsPage";

/**
 * A component instance of the useControlSetDetails.js hook
 * @param props
 */
const ControlSetDetails = (props) => {
  return useControlSetDetails(props).display;
};

export default withDetailsPage(ControlSetDetails);
