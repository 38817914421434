import React, { useEffect, useState } from "react";

import { ErrorLogger } from "../../EventLogger";
import { SpreadsheetComponent } from "@syncfusion/ej2-react-spreadsheet";

/**
 * @description Display a spreadsheet using Syncfusion Spreadsheet editor
 * @param {string} url - url of the .xlsx document
 * @returns {JSX.Element}
 * @constructor
 */
const SpreadsheetViewer = ({ url }) => {
  const [ref, setRef] = useState();

  useEffect(() => {
    if (url && ref) {
      fetch(url)
        .then((response) => {
          response.blob().then((fileBlob) => {
            const file = new File([fileBlob], "sample.xlsx");
            ref?.open({ file: file });
          });
        })
        .catch((err) => ErrorLogger("Cannot open Spreadsheet", err));
    }
  }, [url && ref]);

  return (
    <SpreadsheetComponent
      id="syncfusion-spreadsheet"
      ref={(r) => setRef(r)}
      openUrl="https://ej2services.syncfusion.com/production/web-services/api/spreadsheet/open"
      allowEditing={false}
      height="85vh"
    ></SpreadsheetComponent>
  );
};

export default SpreadsheetViewer;
