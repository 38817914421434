import { Label, UncontrolledTooltip } from "reactstrap";
import React from "react";

/**
 * Custom Label component for use in the useForm hook to display a label
 * @param {string} label - The label to display
 * @param {boolean} required - Whether or not the field is required
 * @param {string} tooltip - The tooltip to display on hover
 * @param {string} tooltipPlacement - The placement of the tooltip
 * @param {string} targetId - The id of the target element to attach the tooltip to
 * @param {string} fieldName - The name of the field
 * @param {JSX.Element} createItemComponent - The component to display when creating an item (e.g. a button)
 * @return {JSX.Element}
 * @constructor
 */
const CustomLabel = ({
  label,
  required,
  tooltip,
  tooltipPlacement = "auto",
  targetId,
  fieldName,
  createItemComponent,
}) => {
  //Replacing all spaces with underscores to prevent id from having errors
  targetId = targetId && targetId.replace(/ /g, "_");
  //targetId = targetId.replaceAll(" ", "_"); (doesn't work with tests)

  return (
    <>
      {label && (
        <Label for={targetId}>
          {label || fieldName}
          {required && <span style={{ color: "red" }}> *</span>}
          {tooltip && (
            <>
              <i
                style={{
                  marginRight: "1em",
                  marginLeft: "1em",
                  cursor: "pointer",
                }}
                id={`${targetId}info`}
                className="icon-question"
              />
              <UncontrolledTooltip placement={tooltipPlacement} target={`${targetId}info`}>
                {tooltip}
              </UncontrolledTooltip>
            </>
          )}
          {createItemComponent}
        </Label>
      )}
    </>
  );
};

export default CustomLabel;
