import React, { useEffect } from "react";

import ChangeInCostField from "../components/ChangeInCostField";
import ChangeInImplementationField from "../components/ChangeInImplementationField";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { listToDropdownData } from "../../../../../../utils/GenericComponents/GenericEditFieldV3/functions/listToDropdownData";
import { useForm } from "../../../../../../hooks/views/useForm/useForm";

/**
 * A custom form for Selecting a System / Risk Control combo and setting Implementation and Cost fields for a Risk Recommendation
 *
 * @param {object[]} systems - list of systems avaliable for the risk change
 * @param {boolean} isNonStandardOnly - if true will not show the system dropdown, system needs to be preselected
 * @param {function} setSelectedSystemID -state setter for the selected system ID
 * @param {string} selectedSystemID - The ID of the currently selected System in the dropdown
 * @param {function} setSelectedRiskControlID - state setter for selected risk control
 * @param {string} selectedRiskControlID - the id of risk control selected in the dropdown
 * @param {object[]} riskControls - risk controls available for selection with the current system
 * @param {function} setChangeInImplementation - state setter for changeInImplementation
 * @param {function} setChangeInCost - state setter for changeInCost
 * @param {boolean} isLoadingSystems - TRUE if the systems are still loading (shows loading UI)
 * @param {boolean} isLoadingRiskControls - TRUE if the controls are still loading (shows loading UI)
 * @param {number} changeInImplementation - the percentage change in implementation
 * @param {number} changeInCost - number of dollar change in risk control cost
 * @param {function} onInvalidInputChange - callback for when the form input is invalid
 * @param {boolean} showOverridesButton - TRUE if you want to show the overrides buttons for the fields
 * @returns {{display: *, input: {}}}
 */
const useSystemRecommendationForm = ({
  systems,
  isNonStandardOnly,
  setSelectedSystemID,
  selectedSystemID,
  setSelectedRiskControlID,
  selectedRiskControlID,
  riskControls,
  setChangeInImplementation,
  setChangeInCost,
  isLoadingSystems,
  isLoadingRiskControls,
  changeInImplementation,
  changeInCost,
  onInvalidInputChange,
  showOverridesButton,
}) => {
  const systemPlaceholder = `N/A - Global Risk Control`;

  const form = useForm({
    disableRoleChecking: true,
    disableSubmitButton: true,
    disableResetButton: true,
    fieldConfig: {
      system: {
        label: "Information System",
        tooltip:
          "A Risk Recommendation may be applied to a Non-Standard Control on a single System, or a Standard Control across multiple Systems",
        inputType: "dropdown",
        placeholder: systemPlaceholder,
        isHidden: isNonStandardOnly,
        dropdownConfig: {
          data: [{ value: null, text: systemPlaceholder }, ...listToDropdownData(systems)],
          showClearButton: true,
        },
        defaultValue: selectedSystemID,
        onChangeFunction: (input) => setSelectedSystemID(input?.system),
        isLoading: isLoadingSystems,
      },
      riskControl: {
        label: `Risk Control ${selectedSystemID ? "(Non-Standard)" : ""}`,
        tooltip: selectedSystemID
          ? "Select a Risk Control that is Non-Standard for this specific System"
          : "Select a Risk Control that is Standard across multiple Systems",
        inputType: "dropdown",
        required: true,
        dropdownConfig: {
          data: listToDropdownData(riskControls, "id", (item) => `${item?.statementNumber}: ${item?.name}`, "desc"),
          showClearButton: true,
        },
        onChangeFunction: (input) => setSelectedRiskControlID(input?.riskControl),
        isLoading: isLoadingRiskControls,
        defaultValue: selectedRiskControlID,
      },
      changeInImplementation: {
        isHidden: isNullOrUndefined(selectedRiskControlID),
        label: "Change in Implementation",
        inputType: "custom",
        onChangeFunction: (input) => setChangeInImplementation(input.changeInImplementation),
        customConfig: {
          component: (
            <ChangeInImplementationField
              selectedRiskControlID={selectedRiskControlID}
              riskControls={riskControls}
              defaultValue={changeInImplementation * 100}
            />
          ),
        },
        defaultValue: changeInImplementation,
      },
      changeInCost: {
        isHidden: isNullOrUndefined(selectedRiskControlID),
        label: "Change in Cost",
        inputType: "custom",
        onChangeFunction: (input) => setChangeInCost(input.changeInCost),
        customConfig: {
          component: (
            <ChangeInCostField
              selectedRiskControlID={selectedRiskControlID}
              riskControls={riskControls}
              defaultValue={changeInCost}
            />
          ),
        },
        defaultValue: changeInCost,
      },
    },
    showOverridesButton,
  });

  useEffect(() => {
    onInvalidInputChange?.(form?.submitDisabled);
  }, [form?.submitDisabled]);

  return {
    ...form,
  };
};

export const SystemRecommendationForm = (props) => useSystemRecommendationForm(props).display;
