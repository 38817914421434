import { useArtifactDetailsModal } from "../../../../Artifact/General/hooks/useArtifactDetailsModal";
import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { Badge, Button as MuiButton } from "@mui/material";
import React from "react";

/**
 * A button and modal for opening the Evidence Artifact details page.
 * The button has a badge indicating the status of the Artifact.
 * The badge color and icon are determined by the status of the Artifact.
 * The badge title contains the status and reason for the status.
 * The modal contains the Artifact details where users can view and validate artifacts
 *
 * @param {object} artifact - the Artifact object
 * @param {string} artifact.id - the Artifact object
 * @param {string} artifact.name - the Artifact name
 * @param {string} artifact.status - the Artifact status
 * @param {string} artifact.reason - the Artifact status reason
 * @returns {JSX.Element|null}
 * @constructor
 */
const EvidenceActivityArtifactButton = ({ artifact }) => {
  const artifactModal = useArtifactDetailsModal({ artifact });

  /**
   * Gets the badge icon representing the status of the Artifact
   * @param {object} artifact - the Artifact object
   * @param {string} artifact.status - the Artifact status
   * @returns {JSX.Element|null}
   */
  const getBadgeContent = ({ artifact }) => {
    if (artifact?.status === "valid") {
      return <i className={"icon-check"} />;
    }

    if (artifact?.status === "notValid") {
      return <i className={"icon-close"} />;
    }

    if (artifact?.status === "pendingValidation") {
      return <i className={"icon-clock"} />;
    }

    return null;
  };

  /**
   * Gets the badge color representing the status of the Artifact
   * @param {object} artifact - the Artifact object
   * @param {string} artifact.status - the Artifact status
   * @returns {string}
   */
  const getBadgeColor = ({ artifact }) => {
    if (artifact?.status === "valid") {
      return "success";
    }

    if (artifact?.status === "notValid") {
      return "error";
    }

    return "warning";
  };

  /**
   * Formats a text string to use as the badge title
   * @param {object} artifact - the Artifact object
   * @param {string} artifact.status - the Artifact status
   * @param {string} artifact.reason - the Artifact status reason
   * @returns {string}
   */
  const getBadgeTitle = ({ artifact }) => {
    let title = `Artifact Status: ${convertCamelCaseToSentence(artifact?.status)}`;

    if (artifact?.reason) {
      title += `\n\nReason: ${artifact?.reason}`;
    }

    return title;
  };

  // If no Artifact exists, don't render anything
  if (!artifact) {
    return null;
  }

  return (
    <Badge
      badgeContent={getBadgeContent({ artifact })}
      color={getBadgeColor({ artifact })}
      style={{ marginTop: "0.5em", width: "100%" }}
      title={getBadgeTitle({ artifact })}
    >
      <MuiButton
        title={"Click to view Artifact Details"}
        onClick={() => artifactModal.setModalIsOpen(true)}
        color={"primary"}
        style={{ fontSize: "0.6em", padding: "0.2em", width: "100%" }}
        startIcon={<i style={{ fontSize: "1.3em" }} className={"icon-info"} />}
        variant={"outlined"}
      >
        {artifact && artifactModal.modal}
        View Artifact
      </MuiButton>
    </Badge>
  );
};

export default EvidenceActivityArtifactButton;
