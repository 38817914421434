import { ErrorLogger } from "@utils/EventLogger";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { generateGraphql } from "@rivial-security/generategraphql";
import { isNullOrUndefined } from "@rivial-security/func-utils";

export const createPointOfContactResponseTeamLink = async (responseTeam, pointOfContact, organizationID) => {
  if (isNullOrUndefined(responseTeam)) {
    ErrorLogger("Error [createPointOfContactResponseTeamLink.js]: invalid responseTeam input");
    return null;
  }

  if (isNullOrUndefined(pointOfContact)) {
    ErrorLogger("Error [createPointOfContactResponseTeamLink.js]: invalid pointOfContact input");
    return null;
  }

  if (isNullOrUndefined(organizationID)) {
    ErrorLogger("Error [createPointOfContactResponseTeamLink.js]: invalid organizationID input");
    return null;
  }

  const { createMutation } = generateGraphql("PointOfContactResponseTeamLink");

  return await ItemMutation(createMutation, {
    pointOfContactID: pointOfContact.id,
    responseTeamID: responseTeam.id,
    ownerGroup: organizationID,
  });
};
