import UnderlinedTooltip from "../../../../utils/GenericComponents/Tooltips/UnderlinedTooltip";
import React from "react";
import { formattedDollar, isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Displays the 'inherentRisk' field for a system with a tooltip
 * @param {object} item - system object
 * @param {number} item.inherentRisk
 * @returns {JSX.Element}
 * @constructor
 */
const InherentRisk = ({ item }) => {
  return (
    <UnderlinedTooltip
      tooltip={
        "Inherent Risk represents the potential annual loss of a System before any Security Controls are evaluated"
      }
    >
      <span>{!isNullOrUndefined(item?.inherentRisk) ? formattedDollar(item.inherentRisk) : "N/A"}</span>
    </UnderlinedTooltip>
  );
};

export default InherentRisk;
