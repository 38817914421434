import { DETAILS_TYPES } from "../enums/DETAILS_TYPES";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useEffect } from "react";

/**
 * @description Set details column size based on number of fields in the Grid table
 * @param {object[]} fields - mapping configuration for a field in the grid with passed data
 * @param {DETAILS_TYPES} detailsType - details component display style
 * @param {number} columnSize - details panel size
 * @param {JSX} detailsBar - Get details bar for UI context
 */
export const useGridDetailsPanelSize = ({ fields, detailsType, columnSize, detailsBar }) => {
  useEffect(() => {
    if (detailsType && detailsType === DETAILS_TYPES.PANEL) {
      if (!isNullOrUndefined(columnSize)) {
        /**
         * Set details bar width based on passed size
         */
        detailsBar?.setColumnSize(columnSize);
      } else if (fields?.length <= 3) {
        /**
         * If number of fields less or equal to 3 set the column size to 8 this will make it wider
         */
        detailsBar?.setColumnSize(8);
      } else {
        /**
         * Default behavior if size not specified
         */
        detailsBar?.setColumnSize(6);
      }
    }

    return () => {
      if (detailsType && detailsType === DETAILS_TYPES.PANEL) {
        detailsBar?.setPinned((pinned) => {
          if (pinned === false) {
            detailsBar?.setIsOpen(false);
          }
          return pinned;
        });
      }
    };
  }, []);
};
