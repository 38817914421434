import { modules, resources } from "@rivial-security/role-utils";

import AssessmentDate from "../customFields/AssessmentDate";
import AssessmentDetailsAssessmentScanner from "../customFields/AssessmentDetails/AssessmentDetailsAssessmentScanner";
import AssessmentDetailsAssessmentStatus from "../customFields/AssessmentDetails/AssessmentDetailsAssessmentStatus";
import AssessmentDetailsAssessmentType from "../customFields/AssessmentDetails/AssessmentDetailsAssessmentType";
import AssessmentDetailsUploadVulnerabilities from "../customFields/AssessmentHeaderButtons/AssessmentDetailsUploadVulnerabilities";
import AssessmentDocuments from "../components/AssessmentDocuments";
import AssessmentFalsePositives from "../components/AssessmentFalsePositives";
import AssessmentFindingsCard from "../components/AssessmentFindingsCard";
import AssessmentJobsGrid from "../components/AssessmentJobsGrid";
import AssessmentPointOfContact from "../customFields/AssessmentPointOfContact";
import AssessmentTargets from "../components/AssessmentTargets";
import AssessmentVulnerabilities from "../components/AssessmentVulnerabilities";
import AssessmentVulnerabilitiesBySeverityLevel from "../components/AssessmnetVulnerabilitiesBySeverityLevel";
import AssessmentVulnerabilitiesStatusChart from "../components/AssessmentVulnerabilitiesStatusChart";
import AutomationsButton from "../../../OrganizationManager/Automations/components/AutomationsButton";
import Dashboard from "../../../../utils/GenericComponents/Dashboard";
import DashboardCard from "../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import GenerateReportModal from "../../../../utils/GenericComponents/GenerateReportModal";
import Grid from "@mui/material/Grid";
import { REPORT_TEMPLATES } from "@rivial-security/report-utils";
import React from "react";
import ReportsGridByItemID from "../../../Reports/ReportsGridByItemID";
import { generateGraphql } from "@rivial-security/generategraphql";
import { getIcon } from "../../../../utils/Functions/Icon/getIcon";
import { useAccordion } from "../../../../hooks/views/useAccordion/useAccordion";
import { useDetailsCard } from "../../../../hooks/views/useDetailsCardV2";

/**
 * Hook for displaying Security Assessment details
 * @param {string} itemId - the ID of the Assessment object
 * @param getUpdatedItem
 * @param {string} organizationID - selected organization ID
 * @param {object} props
 * @returns {{display: JSX.Element}}
 */
export const useAssessmentDetailsAccordion = ({ itemId, getUpdatedItem, organizationID, ...props }) => {
  const module = modules.VULNERABILITIES;
  const resource = resources.ASSESSMENT;

  const nestedFields = {
    pointOfContact: `{
        id
        firstName
        lastName
        title
        email
        phone
        ownerGroup
      }`,
    notes: `{
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
        observationID
      }`,
    securityAppliance: `{
        id
        applianceID
        ip
        port
        openVasUsername
        openVasPassword
        ownerGroup
       }`,
  };

  const { getQuery, updateMutation } = generateGraphql(
    "Assessment",
    [
      "name",
      "createdAt",
      "assessmentDate",
      "scheduledDate", // deprecated, used to identify old assessments
      "includedListTargets",
      "excludedListTargets",
      "pointOfContact",
      "notes",
      "assessmentPointOfContactId",
      "penTester",
      "penTesterEmail",
      "type",
      "scanner",
      "status",
      "executionARN",
      "scanStatus",
      "taskId",
      "scoreCVSS",
    ],
    nestedFields,
  );

  const fields = [
    "name",
    "status",
    "type",
    "assessmentDate",
    "scanner",
    "pointOfContact",
    "includedListTargets",
    "findings",
    "penTester",
    "penTesterEmail",
  ];

  const customFields = [
    {
      field: "pointOfContact",
      component: <AssessmentPointOfContact />,
    },
    {
      field: "assessmentDate",
      component: <AssessmentDate />,
    },
    {
      field: "status",
      component: <AssessmentDetailsAssessmentStatus />,
    },
    {
      field: "type",
      component: <AssessmentDetailsAssessmentType />,
    },
    {
      field: "scanner",
      component: <AssessmentDetailsAssessmentScanner />,
    },
  ];

  const queryConfig = {
    query: getQuery,
    itemId,
  };

  const detailsConfig = {
    fields,
    nestedFields,
    customFields,
    updateMutation,
  };

  const configs = {
    header: "Assessment Details",
    disableRoute: true,
    enableNotes: true,
  };

  const details = useDetailsCard({
    queryConfig,
    detailsConfig,
    config: configs,
    module,
    resource,
    updateItemById: getUpdatedItem,
    ...props,
  });

  const accordion = useAccordion({
    items: [
      {
        title: "General Information",
        subTitle: "Assessment scan configurations, scheduling, and other settings",
        component: details?.tableDisplay,
        icon: "icon-list",
        defaultExpanded: true,
      },
      {
        title: "Vulnerabilities",
        subTitle: "Scan results found during the Assessment",
        component: <AssessmentVulnerabilities item={{ id: itemId }} />,
        icon: "icon-fire",
      },
      {
        title: "Targets",
        subTitle: "Individual devices that were engaged during the Assessment",
        component: <AssessmentTargets item={{ id: itemId }} />,
        icon: "icon-target",
      },
      {
        title: "False Positives",
        subTitle: "Vulnerabilities found during the scan that are not applicable",
        component: <AssessmentFalsePositives item={{ id: itemId }} module={module} resource={resource} />,
        icon: getIcon("ic:baseline-browser-not-supported"),
      },
      {
        title: "Documents",
        subTitle: "Reports, scan results, and other related documents",
        component: (
          <div style={{ height: "30em" }}>
            <AssessmentDocuments item={{ id: itemId }} />
          </div>
        ),
        icon: "icon-docs",
      },
      {
        title: "Reports",
        subTitle: "Security Assessment Reports",
        component: <ReportsGridByItemID itemId={itemId} />,
        icon: "icon-list",
      },
      {
        title: "Findings",
        subTitle: "Observations, Recommendations, and Action Items",
        component: <AssessmentFindingsCard item={{ id: itemId }} />,
        icon: "icon-eye",
      },
    ],
  });

  const display = (
    <Dashboard
      item={details?.item}
      resetFunction={details.reset}
      subTitle={details?.item?.name}
      headerButtons={[
        <AssessmentDetailsUploadVulnerabilities />,
        <GenerateReportModal
          itemId={itemId}
          organizationID={organizationID}
          type={REPORT_TEMPLATES.SECURITY_TESTING_TEMPLATE}
          module={modules.VULNERABILITIES}
          disableReportButton={!!details?.item?.scheduledDate}
          buttonTooltipText={
            details?.item?.scheduledDate &&
            "This is an old security assessment, please re-upload the CSV file to generate a report"
          }
        />,
        <AutomationsButton itemId={itemId} typename={"Assessment"} />,
      ]}
    >
      <Grid container spacing={2}>
        <Grid item lg={8} xs={12}>
          {accordion.display}
        </Grid>
        <Grid item lg={4} xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={12}>
              <DashboardCard
                style={{ height: "24em", overflowY: "auto" }}
                title="Vulnerability Severity Levels"
                icon={"icon-chart"}
              >
                <AssessmentVulnerabilitiesBySeverityLevel item={{ id: itemId }} />
              </DashboardCard>
            </Grid>
            <Grid item xs={12} lg={12}>
              <DashboardCard style={{ overflowY: "auto" }} title="Vulnerability Statuses" icon={"icon-pie-chart"}>
                <AssessmentVulnerabilitiesStatusChart item={{ id: itemId }} />
              </DashboardCard>
            </Grid>
            <Grid item xs={12} lg={12}>
              <DashboardCard title={"Import History"} style={{ overflowY: "auto", height: "30em" }} icon={"icon-clock"}>
                <AssessmentJobsGrid itemId={itemId} module={module} resource={resource} />
              </DashboardCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dashboard>
  );

  return {
    display,
  };
};
