import React from "react";
import { generateGraphql } from "@rivial-security/generategraphql";
import { resources } from "@rivial-security/role-utils";
import InformationAssetDetails from "../components/InformationAssetDetails";
import CreateInformationAsset from "../components/InformationAssets/CreateInformationAsset";
import { useGridCard } from "../../../../../hooks/views/useGridCard";
import { mergeAdditionalFields } from "../../../../../hooks/views/useGrid/functions/mergeAdditionalFields";

/**
 * Displays a list of information assets from the risk module
 * @param {string} organizationID
 * @param {object[]} additionalFields
 * @param {object} gridConfig
 * @param {object} queryConfig
 * @param {object} cardConfig
 */
export const useInformationAssetGrid = ({
  organizationID,
  additionalFields = [],
  gridConfig = {},
  queryConfig = {},
  cardConfig = {},
}) => {
  const resource = resources.INFORMATION_ASSET;

  const queryFields = ["name"];

  const fields = [
    {
      name: "name",
    },
  ];
  mergeAdditionalFields({ additionalFields, fields });

  const { listQuery, updateMutation } = generateGraphql(resource, queryFields);

  const roleConfig = {
    module,
    resource,
  };

  queryConfig = {
    query: listQuery,
    organizationID,
    ...queryConfig,
  };

  cardConfig = {
    title: "Information Assets",
    headerIcon: "icon-check",
    ...cardConfig,
  };

  gridConfig = {
    fields,
    typename: resource,
    createResourceComponent: <CreateInformationAsset />,
    options: ["details"],
    deleteFunction: null,
    detailsComponent: <InformationAssetDetails tableDisplay={true} />,
    route: null,
    updateMutation,
    allowFiltering: true,
    enableSearch: true,
    enableContextMenu: true,
    enablePrint: true,
    enableMenu: true,
    detailsTitle: "Information Asset Details",
    persistenceUUID: "89572ed7-350b-4cf5-94bb-5e7b8d6274ff",
    enablePersistence: true,
    ...gridConfig,
  };

  const gridCard = useGridCard({
    queryConfig,
    gridConfig,
    cardConfig,
    roleConfig,
  });

  return {
    ...gridCard,
  };
};
