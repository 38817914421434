import { useContext, useState } from "react";

import { ErrorLogger } from "@utils/EventLogger";
import { GetQuery } from "../../../../utils/Functions/Graphql/GetQuery";
import { OrganizationContext } from "../../../../utils/Context/OrganizationContext";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useSetAsyncData } from "../../../../hooks/functional/useSetAsyncData";

/**
 * Used to determine if the current point of contact has an active action item for the current evidence
 * @param {object} evidence - the evidence to check for action items for the currently logged in point of contact
 * @return {{isLoading: boolean, route: string|null, priority: number|null}}
 */
export const useEvidenceLandingPageRoute = ({ evidence }) => {
  const context = useContext(OrganizationContext);
  const [route, setRoute] = useState(null);
  const [priority, setPriority] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useSetAsyncData({
    getData: async () => {
      let route;
      let priority;

      const currentPointOfContactID = context?.loggedInPointOfContactId;
      if (currentPointOfContactID && evidence?.id) {
        try {
          const pointOfContact = await GetQuery({
            query: getPointOfContact,
            variables: {
              id: currentPointOfContactID,
            },
          });

          if (!pointOfContact?.id) {
            throw Error("Could not retrieve full point of contact info!");
          }

          const pointOfContactActionItems = pointOfContact?.actions?.items;
          if (Array.isArray(pointOfContactActionItems)) {
            for (const pointOfContactActionItem of pointOfContactActionItems) {
              // Check for action item to have an id
              if (!pointOfContactActionItem?.id) {
                continue;
              }

              //Check that the action item is active
              const status = pointOfContactActionItem?.status;
              if (status !== "inProgress" && status !== "pastDue") {
                continue;
              }

              //Check that the evidence id matches the currently requested evidence
              let actionItemData;
              try {
                actionItemData = JSON.parse(pointOfContactActionItem?.data);
              } catch (e) {
                ErrorLogger("Could not parse action item data", e);
              }
              const actionItemEvidenceID = actionItemData?.evidenceID;
              if (actionItemEvidenceID !== evidence.id) {
                continue;
              }

              //If there is no landing page url but the type of action is an "Evidence Upload" we can assume the route
              let finalLandingPageRoutePrefix = pointOfContactActionItem?.landingPageUrl;
              if (!finalLandingPageRoutePrefix && pointOfContactActionItem?.type === "evidenceUpload") {
                finalLandingPageRoutePrefix = "/continuous_compliance/evidence_response/";
              }

              //Check that the route for the landing page has been found
              if (finalLandingPageRoutePrefix) {
                route = finalLandingPageRoutePrefix + pointOfContactActionItem.id;
                priority = pointOfContactActionItem?.priority;
                break;
              }
            }
          }
        } catch (e) {
          ErrorLogger("Failed to get action item route for current point of contact!", e);
        }
      }

      return { route, priority };
    },
    setData: ({ route, priority } = {}) => {
      setRoute(route);
      setPriority(priority);
    },
    setIsLoading,
    dependencies: [evidence],
  });

  return {
    isLoading,
    priority,
    route,
  };
};

const { getQuery: getPointOfContact } = generateGraphql("PointOfContact", ["actions", "evidences"], {
  actions: `(limit: 1000) {
        items {
          id
          type
          ownerGroup
          data
          landingPageUrl
          priority
          status
        }
      }`,
  evidences: `(limit: 1000) {
        items {
          id
          ownerGroup
        }
      }`,
});
