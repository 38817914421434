import React, { useState } from "react";
import { ListItemIcon, MenuItem, MenuList } from "@mui/material";
import { getIcon } from "../../../utils/Functions/Icon/getIcon";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import ListItemText from "@mui/material/ListItemText";

/**
 * Utility for displaying a dropdown menu with various options
 *
 * @param {object[]} items - the configuration items for the dropdown
 * @param {string} items[].label - the text label that displays as the menu button
 * @param {string} items[].icon - the icon that displays for a menu button
 * @param {function} items[].onClick - the onClick handler function for a menu button
 * @param {boolean} items[].disabled - disables the menu button
 * @param {string} items[].title - the HTML title attribute for the button
 *
 * @param {string} title - the HTML title attribute for the hamburger button itself
 */
export const useHamburgerMenu = ({ items, title }) => {
  const [optionsOpen, setOptionsOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const display = (
    <span>
      <IconButton
        aria-label="more"
        id="long-button"
        size={"small"}
        sx={{ height: "1.5em" }}
        className={"float-right"}
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          setOptionsOpen((optionsOpen) => !optionsOpen);
          e.stopPropagation();
        }}
        title={title || "Open Menu"}
      >
        {getIcon("carbon:overflow-menu-vertical")}
      </IconButton>
      <Menu
        id={"template-menu"}
        variant="menu"
        anchorEl={anchorEl}
        onClose={(e) => {
          setAnchorEl(null);
          setOptionsOpen(false);
        }}
        open={optionsOpen}
      >
        <MenuList>
          {items?.map((item) => {
            return (
              <MenuItem
                key={item.label}
                onClick={() => {
                  item?.onClick && item?.onClick();
                  setOptionsOpen(false);
                  setAnchorEl(null);
                }}
                disabled={item.disabled}
                title={item.title || item.label}
              >
                {item?.component ? (
                  item?.component
                ) : (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <ListItemIcon style={{ fontSize: "1.3em", margin: "auto" }}>{item?.icon}</ListItemIcon>
                    <ListItemText>{item?.label}</ListItemText>
                  </div>
                )}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </span>
  );

  return {
    display,
    optionsOpen,
    setOptionsOpen,
  };
};
