import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";
import GenericEditFieldV3 from "../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";
import { GENERIC_FIELD_TYPES } from "../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";

/**
 * Displays the Due Date for an Action Item
 * @param item
 * @param updateItemById
 * @returns {*|string|string}
 * @constructor
 */
const DueDate = ({ item, updateItemById }) => {
  const module = modules.GOVERNANCE;
  const resource = resources.ACTION_ITEM;
  const field = "dueDate";

  return (
    <GenericEditFieldV3
      module={module}
      resource={resource}
      field={field}
      item={item}
      mutation={generateGraphql("GenericActionItem", ["dueDate"]).updateMutation}
      inputType={GENERIC_FIELD_TYPES.DATE}
      updateItemById={updateItemById}
      inputConfig={{
        dateOnly: true, //removes time from the date picker
      }}
      displayConfig={{
        dateOnly: true,
      }}
    />
  );
};

export default DueDate;
