/**
 * Author: Jacob Blazina
 * Created At: 10/3/19
 * Edits:
 *  *  - 10/18/19 JB: Added better styling
 *
 * Description: Displays a Program Blueprint Indicator
 *              Edits are turned off by default. May be switched on via the "enableEdits" prop.
 *
 * TODO: Add Notes UI
 */

import React from "react";
import PropTypes from "prop-types";
import { ButtonGroup, ListGroupItem } from "reactstrap";
import { GenericDeleteButton } from "../../../../../../utils/GenericComponents/GenericDeleteButton";
import DeleteMaturityIndicator from "../../../functions/DeleteMaturityIndicator";
import MaturityIndicatorDetails from "./MaturityIndicatorDetails";

const MaturityIndicator = (props) => {
  return (
    <>
      <ListGroupItem
        draggable="true"
        onDragStart={(e) => e.dataTransfer.setData("data", JSON.stringify(props.maturityIndicator))}
        className="shadow appearance-none border rounded text-grey-darker leading-tight focus:outline-none focus:shadow-outline"
        tag="button"
        onClick={() =>
          props.setModalBody &&
          props.setModalBody(<MaturityIndicatorDetails maturityIndicator={props.maturityIndicator} />)
        }
        action
      >
        {props.maturityIndicator ? (
          <div style={{ whiteSpace: "normal" }}>
            {props.maturityIndicator.name}
            {props.maturityIndicator.isTrue === "true" || props.maturityIndicator.isTrue === true ? (
              <span className="float-right" style={{ color: "green" }}>
                &#10003;
              </span>
            ) : (
              <span className="float-right" style={{ color: "red" }}>
                &#10005;
              </span>
            )}
          </div>
        ) : (
          "Error: No Maturity Indicator Found"
        )}
      </ListGroupItem>
      {props.enableEdits ? (
        <ButtonGroup className="float-right" size="sm">
          <GenericDeleteButton
            data-test="button-delete-maturityIndicator"
            id="button-delete-maturityIndicator"
            deleteFunction={DeleteMaturityIndicator}
            item={props.maturityIndicator}
            className="float-right"
          />
        </ButtonGroup>
      ) : null}
    </>
  );
};

MaturityIndicator.propTypes = {
  maturityIndicator: PropTypes.object.isRequired,
  enableEdits: PropTypes.bool,
};

MaturityIndicator.defaultProps = {
  enableEdits: false,
};

export default MaturityIndicator;
