import { InfoLogger } from "@utils/EventLogger";
import { createJob } from "../../Jobs/functions/createJob";
import { fargateApi } from "../../../../utils/Functions/FargateApi/fargateApi";
import { getAutomation } from "./getAutomation";

/**
 * Used to manually fire an automation through fargate
 */
export const fireAutomation = async ({ id, name, organizationID, userEmail, sentryTrace }) => {
  InfoLogger("Firing Automation: ", id);

  const fullAutomation = await getAutomation({ id });

  InfoLogger("Got Full Automation: ", fullAutomation);

  const job = await createJob({
    name: `Automation Triggered: ${name}`,
    sourceID: id,
    source: "Automation",
    logs: JSON.stringify([
      {
        type: "info",
        title: `Automation Manually Triggered by: ${userEmail}`,
        timestamp: new Date().toISOString(),
      },
    ]),
    status: "started",
    ownerGroup: organizationID,
  });

  return await fargateApi({
    input: {
      route: "automation",
      organizationID,
      automations: fullAutomation.automations,
      job,
      automationID: id,
      sentryTrace,
    },
  });
};
