import { useModal } from "./useModal";
import CreateOrSelect from "../../utils/GenericComponents/CreateOrSelect";
import React from "react";

/**
 * A modal allowing users to create or select a new item
 * @param {string} typename - the type that is being created or selected
 * @param {object} form - form passed down to CreateOrSelect
 * @param {object} grid - grid passed down to CreateOrSelect
 * @param {JSX.Element} [button] - button passed down to CreateOrSelect to open the modal
 * @param {function} callback - the callback function to call on submit of the form with the selected item
 * @param {object} prepareSelectionForm - a form to display before a new item can created or selected
 * @param {string} [organizationID] - the organizationID to pass to the grid and create hook
 * @returns {{setModalIsOpen: function(*): void, modalButton: *, modalIsOpen: boolean, modal: *, toggleModal: function(): void}}
 */
export const useCreateOrSelectModal = ({
  typename,
  form,
  grid,
  button,
  callback,
  prepareSelectionForm,
  organizationID,
}) => {
  const modal = useModal(
    `Create or Select ${typename}`,
    <CreateOrSelect
      form={form}
      grid={grid}
      prepareSelectionForm={prepareSelectionForm}
      callback={callback}
      typename={typename}
      organizationID={organizationID}
    />,
    button || null,
    {
      width: "80vw",
      onClosed: () => {
        //Reset the inputs into the selection preparation form if it exists on exit from create or select modal
        prepareSelectionForm?.getInitialState?.();
      },
    },
  );

  return {
    ...modal,
  };
};
