import React, { useContext, useEffect, useState } from "react";
import { MonteCarloContext } from "../../../../context/MonteCarloContext";
import { useModal } from "../../../../../../../hooks/views/useModal";
import MonteCarloSummary from "../../MonteCarlo/components/MonteCarloSummary";
import { isNullOrUndefined, numberWithCommas } from "@rivial-security/func-utils";
import DataLoader from "../../../../../../../utils/LoadingComponents/DataLoader";
import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";

/**
 * Displays the Inherent Risk for a KRI from a system Monte Carlo calculation
 * @param risk
 * @param printView
 * @returns {JSX.Element}
 * @constructor
 */
const InherentRiskMonteCarlo = ({ risk, printView }) => {
  const monteCarloContext = useContext(MonteCarloContext);

  const [inherentRiskMonteCarlo, setInherentRiskMonteCarlo] = useState(0.0);

  useEffect(() => {
    if (monteCarloContext.monteCarloResults && risk) {
      const riskStats = monteCarloContext.monteCarloResults.riskStats;

      const findRiskStat = riskStats && riskStats.find((item) => item.name === risk.name);

      if (findRiskStat) {
        setInherentRiskMonteCarlo(findRiskStat.inherentRisk);
      }
    }
  }, [monteCarloContext.monteCarloResults, monteCarloContext.monteCarloInput]);

  const monteCarloSummaryModal = useModal(
    "Monte Carlo Summary",
    <MonteCarloSummary
      monteCarloInput={monteCarloContext.monteCarloInput}
      monteCarloResults={monteCarloContext.monteCarloResults}
    />,
    null,
    {
      width: "80vw",
    },
  );

  return (
    <>
      <DataLoader
        isLoading={monteCarloContext?.isLoading}
        isEnoughData={!isNullOrUndefined(inherentRiskMonteCarlo)}
        dataMessage={"Could not calculate Monte Carlo Inherent Risk"}
      >
        {!printView && (
          <strong>
            Inherent Risk{" "}
            <i
              className="icon-question"
              title="Click to view Monte Carlo Details"
              onClick={() => monteCarloSummaryModal.setModalIsOpen(true)}
              style={{ cursor: "pointer" }}
            />
            {monteCarloSummaryModal.modal}
          </strong>
        )}
        <div>${inherentRiskMonteCarlo && numberWithCommas(inherentRiskMonteCarlo.toFixed(2))}</div>
      </DataLoader>
    </>
  );
};

export default withOrganizationCheck(InherentRiskMonteCarlo);
