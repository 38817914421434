import { checkArguments } from "@rivial-security/func-utils";
import { createControlEvidenceLink } from "../../ControlEvidenceLinks/functions/createControlEvidenceLink";

/**
 * Performs the operation for linking Control items to an Evidence
 * @param {Control[]} controls - the controls to link to an evidence
 * @param {Evidence} evidence - the evidence to link controls to
 * @param {string} organizationID - the organization ID for the mutation
 * @returns {Promise<void>}
 */
export const handleLinkingControls = async (controls, evidence, organizationID) => {
  checkArguments({
    controls,
    evidence,
    organizationID,
  });

  for (const linkItem of controls) {
    let notAlreadyLinked = true;

    if (evidence?.controls?.items) {
      // loop through control's current evidence links
      for (const controlLink of evidence.controls.items) {
        // if this existing link is broken, skip it
        if (!controlLink?.control?.id) {
          continue;
        }

        // if evidence is not already linked to the control, add the link
        if (controlLink.control.id === linkItem.id) {
          notAlreadyLinked = false;
        }
      }

      if (notAlreadyLinked) {
        // add the link
        await createControlEvidenceLink(linkItem.id, evidence.id, organizationID);
      }
    }
  }
};
