import { modules, resources } from "@rivial-security/role-utils";

import KnowBe4TrainingDetails from "../components/KnowBe4TrainingDetails";
import { TOOL_TYPES } from "@rivial-security/integration-utils";
import { enumSortComparator } from "../../../../../utils/Functions/enumSortComparator";
import { listKnowBe4Trainings } from "../graphql/listKnowBe4Trainings";
import { useDataGrid } from "../../../../../hooks/views/useDataGrid/useDataGrid";

/**
 * @description Display list of KnowBe4 Training Campaigns hook
 * @param {string} organizationID - Organization ID
 * @param {object} cardConfig - Card configuration
 * @param {object} queryConfig - Query configuration
 * @param {object} gridConfig - Grid configuration
 * @param {object} fields - Fields to display in the grid
 * @param {object} props - The props passed to the component
 * @returns {{gridDisplay: JSX.Element, data: unknown, lastSelectedItem: string, setData: (value: unknown) => void, display: JSX.Element, isLoading: boolean, createResourceComponent: JSX, ref: string, setSelectedItems: (value: (((prevState: []) => []) | [])) => void, setIsLoading: (value: (((prevState: boolean) => boolean) | boolean)) => void, setLastSelectedItem: (value: (((prevState: string) => string) | string)) => void, itemsToCheck: unknown, resetFunction: function(): void, fields: Object[], setItemsToCheck: (value: unknown) => void, selectedItems: []}}
 */
export const useKnowBe4TrainingDataGrid = ({
  organizationID,
  cardConfig = {},
  queryConfig = {},
  gridConfig = {},
  fields = [],
  ...props
}) => {
  const module = modules.TOOLS;
  const resource = resources.KNOW_BE_4_TRAINING;
  const toolType = TOOL_TYPES.KNOW_BE_4;
  const route = "#/integrations/know_be_4/training/";

  cardConfig = {
    title: "KnowBe4 Training Campaigns",
    headerIcon: "icon-note",
    ...cardConfig,
  };

  queryConfig = {
    organizationID,
    queryExternalApi: {
      idField: "campaign_id",
      toolType,
      input: {
        query: listKnowBe4Trainings,
        queryInput: {
          page: 1,
          per_page: 500,
        },
      },
    },
    ...queryConfig,
  };

  const roleConfig = {
    module,
    resource,
  };

  fields = [
    {
      name: "name",
      flex: 1,
      minWidth: "200",
    },
    {
      name: "start_date",
      type: "date",
      width: "250",
      sort: {
        direction: "desc",
        priority: 1,
      },
    },
    {
      name: "end_date",
      type: "date",
      width: "250",
    },
    {
      name: "completion_percentage",
      width: "250",
      type: "number",
    },
    {
      name: "status",
      width: "250",
      type: "singleSelect",
      valueOptions: [
        {
          value: "Created",
          label: "Created",
        },
        {
          value: "In Progress",
          label: "In Progress",
        },
        {
          value: "Completed",
          label: "Completed",
        },
      ],
      sortComparator: enumSortComparator(["Created", "In Progress", "Completed"]),
    },
    ...fields,
  ];

  gridConfig = {
    fields,
    route,
    helpCenterRoute: "knowbe4-integration",
    detailsComponent: <KnowBe4TrainingDetails />,
    detailsTitle: "KnowBe4 Training Details",
    typename: "knowBe4Training",
    persistenceUUID: "fec311db-3f5f-495a-8705-952988c2e3dd",
    organizationID,
    ...props,
    ...gridConfig,
  };

  const grid = useDataGrid({
    ...queryConfig,
    ...cardConfig,
    ...roleConfig,
    ...gridConfig,
  });

  return {
    ...grid,
  };
};
