import { Alert, Button } from "@mui/material";
import React, { useState } from "react";

import { DisabledWrapper } from "@utils/GenericComponents/DisabledWrapper";
import { Icon } from "@iconify/react";
import Loader from "@utils/LoadingComponents/Loader";
import ResponseSuccess from "../../../../Compliance/Evidence/components/EvidenceLandingPage/components/ResponseSuccess";
import { TaskStatus } from "../../constants/taskStatus";
import { getMostRecentQuestionnaireSubmission } from "./functions/getMostRecentQuestionnaireSubmission";
import { resources } from "@rivial-security/role-utils";
import { submitQuestionnaireLambda } from "../../functions/submitQuestionnaireLambda";
import { tryFunction } from "@utils/Functions/tryFunction";
import { useCustomResourceEntryForm } from "@views/CustomResources/CustomResourceEntries/hooks/useCustomResourceEntryForm";
import { useHistory } from "react-router-dom";
import { Auth } from "@aws-amplify/auth";

/**
 * Allows user to fill out a response to a questionnaire (powered by the task system)
 * @param {object} task - the task to display the questionnaire for
 * @param {function} resetFunction - function to refresh data of 'task' from backend
 * @param {boolean} enableSubmissions - if TRUE will allow user to submit submittable tasks (such as questionnaires)
 * @param {string} enableLandingPageLink - if TRUE will show a link to a landing page version of the task if the work needs to be completed there
 * @param {function} [toggleModal] - function to show/hide containing modal (if any)
 * @returns {JSX.Element}
 */
const QuestionnaireTaskWork = ({ task, resetFunction, enableSubmissions, enableLandingPageLink, toggleModal }) => {
  const history = useHistory();
  const customResourceTypeID = task?.questionnaireAssignee?.questionnaire?.customResourceType?.id;
  const organizationID = task?.ownerGroup;
  const customResourceEntry = getMostRecentQuestionnaireSubmission({ task });

  const [questionnaireSubmitting, setQuestionnaireSubmitting] = useState(false);

  const form = useCustomResourceEntryForm({
    organizationID,
    customResourceTypeID,
    customResourceType: task?.questionnaireAssignee?.questionnaire?.customResourceType,
    convertLabelToCamelCase: false,
    customResourceEntryID: customResourceEntry?.id,
    disableCustomResourceTypeSelection: true,
    disableSubmitButton: !enableSubmissions,
    disableResetButton: !enableSubmissions,
    submitFunction: async (customResourceEntry) => {
      setQuestionnaireSubmitting(true);

      const credentials = await Auth.currentCredentials();
      await submitQuestionnaireLambda({
        taskID: task?.id,
        questionnaireID: task?.questionnaireAssignee?.questionnaire?.id,
        questionnaireAssigneeID: task?.questionnaireAssignee?.id,
        dataForm: JSON.parse(customResourceEntry?.customFieldData || "{}"),
        isGuest: !credentials?.authenticated,
      });
      setQuestionnaireSubmitting(false);
      tryFunction(resetFunction);
    },
  });

  const getShowLandingPageLink = () => {
    if (!enableLandingPageLink) {
      return false;
    }

    return task?.status !== TaskStatus.DONE;
  };

  if (questionnaireSubmitting) {
    return <Loader text={"Submitting Questionnaire Response"} />;
  }
  const inLandingPage = history?.location?.pathname?.includes("/landing_page/task/");
  const inGuestLandingPage = history?.location?.pathname?.includes("/guest/landing_page/task/");
  const questionnaireComplete = task?.status === TaskStatus.DONE;

  const showMissingQuestionnaireResponseAlert = questionnaireComplete && !inLandingPage && !customResourceEntry;

  let content = (
    <DisabledWrapper
      message={
        task?.status !== TaskStatus.DONE
          ? "Please use the task landing page to complete this task."
          : "This questionnaire is complete and cannot be edited."
      }
      isDisabled={!enableSubmissions}
    >
      {form.display}
    </DisabledWrapper>
  );

  if (questionnaireComplete && inLandingPage) {
    content = (
      <ResponseSuccess
        completionTypename={resources.QUESTIONNAIRE}
        returnTypename={resources.TASK}
        item={task}
        isGuest={inGuestLandingPage}
      />
    );
  }

  if (showMissingQuestionnaireResponseAlert) {
    content = (
      <Alert severity={"warning"}>
        The response for this completed questionnaire task could not be found. It may have been deleted.
      </Alert>
    );
  }

  return (
    <div>
      {content}
      {getShowLandingPageLink() && (
        <div
          style={{
            marginTop: "2em",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Button
            variant={"outlined"}
            startIcon={<Icon icon={"simple-line-icons:share-alt"} />}
            href={`#/landing_page/task/${task?.id}`}
            onClick={() => {
              toggleModal?.();
            }}
          >
            Navigate to Landing Page
          </Button>
        </div>
      )}
    </div>
  );
};

export default QuestionnaireTaskWork;
