import React, { useContext, useEffect } from "react";
import { modules, resources } from "@rivial-security/role-utils";

import { ErrorLogger } from "@utils/EventLogger";
import PermissionsOverlay from "../../../../utils/Overlays/PermissionsOverlay";
import { WorkflowContext } from "../../../../hooks/views/useWorkflow";
import { generateVendorReviewControlsReport } from "../functions/generateVendorReviewControlsReport";
import { getVendorReviewControlsReportData } from "../functions/getVendorReviewControlsReportData";
import { useDocumentEditor } from "../../DocumentEditor/hooks/useDocumentEditor";
import { usePleaseWaitModal } from "../../../../hooks/views/usePleaseWaitModal";

/**
 * @description Generate Vendor Review Controls Report
 * @param {string} organizationID - selected organization
 * @param {string} overrideOrganizationID - override organization id
 * @returns {JSX.Element}
 * @constructor
 */
const VendorReviewControlsReport = ({ organizationID, overrideOrganizationID }) => {
  const context = useContext(WorkflowContext);
  const { ref, display, generateFromTemplate } = useDocumentEditor({
    organizationID: overrideOrganizationID ? overrideOrganizationID : organizationID,
    defaultTabIndex: 2,
  });

  const pleaseWait = usePleaseWaitModal();

  const initiateReportGeneration = async () => {
    pleaseWait.setModalIsOpen(true);

    try {
      //Get all vendor review id information
      const vendorReview = context?.vendorReview;
      const itemId = vendorReview?.id;

      const vendorReviewData = await getVendorReviewControlsReportData({
        input: { config: { itemId } },
      });

      //Generate the report
      await generateFromTemplate(generateVendorReviewControlsReport, {
        ...vendorReviewData,
      });
    } catch (e) {
      ErrorLogger("Error: could not generate Vendor Review Controls report! ", e);
    } finally {
      pleaseWait.setModalIsOpen(false);
    }
  };

  useEffect(() => {
    if (ref && ref.documentEditor) {
      initiateReportGeneration();
    }
  }, [ref.documentEditor]);

  return (
    <PermissionsOverlay module={modules.REPORTS} resource={resources.REPORT} operationType={"create"}>
      <div>
        {pleaseWait.modal}
        {display}
      </div>
    </PermissionsOverlay>
  );
};

export default VendorReviewControlsReport;
