import { useEffect } from "react";
import { useEvidenceDataGrid } from "../../Evidence/hooks/useEvidenceGrid/useEvidenceDataGrid";

/**
 * Populates the a list of all Org's evidences without but removing evidences that are already linked to a control
 *
 * @param {Control} control - the parent control
 * @param {function} setItems - handler for setting the Evidences
 * @param {string} organizationID
 */
export const usePopulateExistingEvidences = (control, setItems, organizationID) => {
  const evidenceList = useEvidenceDataGrid({ organizationID });

  return useEffect(() => {
    const existingEvidences = control?.evidences?.items.map((evidenceLink) => evidenceLink.evidence);

    if (evidenceList.data && existingEvidences) {
      const d = [];

      for (const item of evidenceList.data) {
        if (existingEvidences.some((a) => a.id === item.id)) {
          // do not add to list
        } else {
          d.push(item);
        }
      }

      setItems(d);
      // setNotLinked(d)
    }
  }, [evidenceList.data, control]);
};
