import { useState } from "react";
import SelectAutomationTypeButtons from "../components/SelectAutomationTypeButtons";
import EditAutomation from "../components/EditAutomation";

/**
 * Custom multistep form to create a new automation stem (currently does not perform mutations)
 * @param {string} organizationID - currently selected organization id from the database
 * @param {AutomationType[]} automationTypes - the available types of automations to display
 * @param {object[]} automations - the current list of automation steps (siblings to the created step)
 * @param {function} toggleModal - function to close the enclosing modal (if there is any)
 * @param {object} trigger - information on what starts the execution of the automation which will host the created step
 * @param {boolean} isTemplate - if TRUE will show only templatable fields during creation
 * @param {object[]} ancestorSteps - step configurations from parent nodes (for instance, the siblings of loop steps)
 * @param {function} onSubmit - function to return the created automation after it has been submitted
 * @param {object} automation - the parent automation item, if this is a nested step
 * @param {boolean} showLoopSmartValues - if TRUE will show the smart values related to loop
 * @returns {{display: JSX.Element}}
 */
export const useCreateAutomationStep = ({
  organizationID,
  automationTypes,
  automations,
  toggleModal,
  trigger,
  isTemplate,
  ancestorSteps,
  onSubmit,
  automation,
  showLoopSmartValues,
}) => {
  const [step, setStep] = useState();

  const display = (
    <div>
      {!step && (
        <SelectAutomationTypeButtons
          automationTypes={automationTypes}
          organizationID={organizationID}
          automations={automations}
          onSelect={(step) => {
            setStep(step);
          }}
        />
      )}
      {step && (
        <EditAutomation
          automationStep={step}
          organizationID={organizationID}
          automations={[...automations, step]}
          setAutomations={(newAutomationSteps) => {
            onSubmit(step);
          }}
          toggleModal={toggleModal}
          trigger={trigger}
          isTemplate={isTemplate}
          ancestorSteps={ancestorSteps}
          automation={automation}
          showLoopSmartValues={showLoopSmartValues}
        />
      )}
    </div>
  );

  return {
    display,
  };
};
