import React from "react";
import deleteEvidence from "../../functions/deleteEvidence";
import { generateGraphql } from "@rivial-security/generategraphql";
import CreateEvidence from "../../components/CreateEvidence";
import EvidenceDetails from "../../components/EvidenceDetails";
import EvidenceStatusDropdown from "../../components/EvidenceStatusDropdown/EvidenceStatusDropdown";
import EvidencePointOfContacts from "../../components/EvidencePointOfContacts/EvidencePointOfContacts";
import { modules, resources } from "@rivial-security/role-utils";
import { useFetchEvidenceBy } from "../useFetchEvidenceBy";
import Dashboard from "../../../../../utils/GenericComponents/Dashboard";
import { listEvidenceByOwnerGroupForGrid } from "./graphql/listEvidenceByOwnerGroupForGrid";
import { useDataGrid } from "../../../../../hooks/views/useDataGrid/useDataGrid";
import EvidenceAllowUnauthenticatedSubmissionsSwitch, {
  allowUnauthenticatedSubmissionsTooltip,
} from "../../components/EvidenceAllowUnauthenticatedSubmissionsSwitch";
import EvidenceType from "../../components/EvidenceType";
import { EVIDENCE_TYPES } from "../../../../../typedefs/Compliance/Evidence/Evidence";
import EvidenceFrequency from "../../components/EvidenceFrequency";
import { createEvidenceTagLink } from "../../../../../utils/Tags/functions/createTagLinks/createEvidenceTagLink";
import { formattedName } from "@rivial-security/func-utils";
import EvidenceEnabledSwitch from "../../components/EvidenceEnabledSwitch";
import { mergeAdditionalFields } from "../../../../../hooks/views/useGrid/functions/mergeAdditionalFields";

/**
 * Custom hook for displaying a list of Evidence
 * @param {string} organizationID - The ID of the organization
 * @param {string} gridCardTitle - The title of the grid card
 * @param {object} queryConfig - The query config for the grid
 * @param {object} cardConfig - The card config for the grid
 * @param {object} gridConfig - The grid config for the grid
 * @param {string[]} hiddenFields - The fields to hide from the grid
 * @param {boolean} disablePointOfContactAssign - optional flag for disabling the 'assign a point of contact' button for evidence
 * @param {string} module - module to use for role checking
 * @param {string} resource - resource to use for role checking
 * @param {boolean} disableRoleChecking - optional flag for disabling role checking
 * @param {boolean} isTemplate - optional flag for indicating if the evidence is a template
 * @param {string} route - route to use for the create evidence button
 * @param {object[]} additionalFields - additional field configurations to add to the grid
 * @param {object} sx - styling prop, options: { dashboard: { container: { height: "string" } } }
 * @param {object} props - additional props to pass to the grid
 * @param {object[]} additionalFields - custom field configurations to merge with the default ones (these take precedence)
 * @param {boolean} [componentGridDisplay] - if TRUE will not show the dashboard header and control set selection UI
 * @returns {object} {{paginated, data, setOtherFilters: type, use, objects, your, setSelectionType, that, selectionType, shows, function, options, limit, from, state, if, you, selected, setSortField, needs, new, still, query, want, finished, it, loading, list: type, listPageArray: type, an, isLoading: boolean, contains, field, setLoading, setLimit, nextToken: type, addFilter, reset: type, setFullData, returns, arrays, fields, changed, setQueryFilters, tableDisplay, string, for, clearFilters, fetching, refetch, searchResults, database, current, showSelectBoxes, array, setPaginatedData, and, setItems, get, of, sortFields, paginatedData, after, a, set, setMiniLoading, setData, more, display, perform, miniLoading, tableData, filters, setList: type, token, the, with, setSelectedItems, setShowSelectBoxes, setFilters, fetch, organization, information, to, "'2d'", setOrganizationId, selectedItems}}
 */
export const useEvidenceDataGrid = ({
  organizationID,
  gridCardTitle,
  queryConfig = {},
  cardConfig = {},
  gridConfig = {},
  hiddenFields = [],
  disablePointOfContactAssign = false,
  module = modules.COMPLIANCE,
  resource = resources.EVIDENCE,
  disableRoleChecking = false,
  isTemplate = false,
  route = `#/continuous_compliance/evidence/`,
  additionalFields = [],
  componentGridDisplay,
  sx = {},
  ...props
}) => {
  const typename = "Evidence";

  const queryFields = [
    "itemNumber",
    "name",
    "status",
    "pointOfContacts",
    "frequency",
    "enabled",
    "type",
    "task",
    "tags",
    "allowUnauthenticatedSubmissions",
  ];
  const nestedFields = {
    tags: `(limit: 100) { items { __typename id tag { id name description fontColor backgroundColor } } }`,
    pointOfContacts: `(limit: 100) {
      items {
        id
        pointOfContact {
          id
          firstName
          lastName
          email
          title
          user {
            id
          }
          actions(limit: 100) {
            items {
              id
              name
              status
              createdAt
              data
            }
          }
        }
      }
    }`,
  };
  const { updateMutation } = generateGraphql(typename, queryFields, nestedFields);

  queryConfig = {
    query: listEvidenceByOwnerGroupForGrid,
    variables: {
      ownerGroup: organizationID,
    },
    limit: 500,
    ...queryConfig,
  };

  const togglePointOfContactModal = {
    toggle: () => {},
  };

  const fields = [
    {
      name: "name",
      minWidth: 200,
      flex: 1,
      sort: {
        direction: "asc",
        priority: 1,
      },
      bulkEdit: true,
    },
    {
      name: "status",
      component: <EvidenceStatusDropdown />,
      disablePropagation: true,
      filter: {
        type: "CheckBox",
      },
      width: 150,
      bulkEdit: true,
      type: "singleSelect",
      valueOptions: [
        { value: "expired", label: "Not in Place" },
        { value: "expiringSoon", label: "Expiring Soon" },
        { value: "pendingValidation", label: "Pending Validation" },
        { value: "inPlace", label: "In Place" },
      ],
    },
    {
      name: "pointOfContacts",
      component: (
        <EvidencePointOfContacts
          disablePointOfContactAssign={disablePointOfContactAssign}
          toggleModalRef={togglePointOfContactModal}
        />
      ),
      disablePropagation: true,
      friendlyName: "Points of Contact",
      valueGetter: (_value, row) => {
        const pointOfContactLinks = row?.pointOfContacts?.items;
        const pointOfContactNames = [];

        //Iterate over all point contact links to get just the names delimited by a comma
        if (Array.isArray(pointOfContactLinks)) {
          for (const pointOfContactLink of pointOfContactLinks) {
            const pointOfContact = pointOfContactLink?.pointOfContact;
            if (pointOfContact) {
              const pointOfContactName = formattedName({ pointOfContact });
              if (pointOfContactNames) {
                pointOfContactNames.push(pointOfContactName);
              }
            }
          }
        }

        return pointOfContactNames?.join(", ");
      },
      searchKeys: ["pointOfContacts.items.*.pointOfContact.firstName"],
      visible: !hiddenFields?.includes("pointOfContacts"),
      width: 350,
    },
    {
      name: "type",
      component: <EvidenceType />,
      width: 150,
      bulkEdit: true,
      type: "singleSelect",
      valueOptions: Object.values(EVIDENCE_TYPES),
    },
    {
      name: "frequency",
      component: <EvidenceFrequency />,
      width: 150,
      bulkEdit: true,
    },
    {
      name: "task",
      inputType: "textarea",
      visible: false,
      width: 200,
      bulkEdit: true,
    },
    {
      name: "enabled",
      friendlyName: "Enabled",
      description: "When enabled, Automates data gathering and control validation",
      component: <EvidenceEnabledSwitch />,
      visible: false,
      width: 150,
      bulkEdit: true,
      type: "boolean",
    },
    {
      name: "allowUnauthenticatedSubmissions",
      friendlyName: "Guest Submissions",
      description: allowUnauthenticatedSubmissionsTooltip,
      component: <EvidenceAllowUnauthenticatedSubmissionsSwitch />,
      visible: false,
      width: 150,
      bulkEdit: true,
      type: "boolean",
    },
    {
      field: "tags",
      name: "tags",
      description: "Tags that correspond to this Evidence across the entire Platform",
      createLinkFunction: createEvidenceTagLink,
      width: 200,
      bulkEdit: true,
      //NOTE: some properties provided by default in handleDataGridColumns.js
    },
  ];
  mergeAdditionalFields({ fields, additionalFields });

  gridConfig = {
    organizationID,
    module,
    resource,
    fields,
    typename: "Evidence",
    createItemModalHeader: (
      <div>
        Create a new Evidence
        {/*<span className="float-right">{importEvidence.modalButton}</span>*/}
      </div>
    ),
    options: ["details", "duplicate", "delete", "edit"],
    detailsComponent: <EvidenceDetails organizationID={organizationID} tableDisplay={true} />,
    route,
    updateMutation: updateMutation,
    deleteFunction: deleteEvidence,
    deleteFunctionNote:
      "This will delete all Evidence Activity, Observations, Audit Reviews, and Control Linking associated with this Evidence.",
    createResourceComponent: <CreateEvidence noHeader={true} organizationID={organizationID} />,
    persistenceUUID: "evidence-grid-b355a674-d837-4070-a1d5-c5635ce941de",
    duplicationSettings: {
      description:
        "Duplicates Evidence. Preserves Frequency, Points of Contact, Control Linking, and Notes. Does not copy Evidence Activity or documents.",
      enabled: true,
      fields: [
        "name",
        "itemNumber",
        "frequency",
        "status",
        "enabled",
        "type",
        "pointOfContacts",
        "controls",
        "notes",
        "tags",
      ],
      nestedFields: {
        pointOfContacts: `(limit: 100) { items { id evidenceID pointOfContactID pointOfContact { id } } }`,
        notes: `{ id type ownerGroup author timeStamp content tag observationID }`,
        controls: `(limit: 500) { items { id evidenceID controlID control { id } } }`,
        tags: `(limit: 1000) { items { id ownerGroup evidenceID tagID evidence { id } tag { id } } }`,
      },
      primaryField: "name",
      fieldAdaptor: {
        itemNumber: (item) => `${item.itemNumber}-duplicated`,
      },
      connectionAdaptor: {
        pointOfContacts: {
          connectionTypename: "EvidencePointOfContactLink",
          itemConnectionIDField: "evidenceID",
          childConnectionField: "pointOfContact",
          childConnectionIDField: "pointOfContactID",
        },
        controls: {
          connectionTypename: "ControlEvidenceLink",
          itemConnectionIDField: "evidenceID",
          childConnectionField: "control",
          childConnectionIDField: "controlID",
        },
        tags: {
          connectionTypename: "EvidenceTagLink",
          itemConnectionIDField: "evidenceID",
          childConnectionIDField: "tagID",
          childConnectionField: "tag",
        },
      },
    },
    ...gridConfig,
    ...props,
  };

  const queryCard = useDataGrid({
    ...queryConfig,
    ...gridConfig,
    refreshCallback: () => {
      if (controlSetId && controlSetId !== "all") {
        resetFunction();
        return false; //prevents internal grid query from firing
      } else {
        return true; // permits internal grid query to fire
      }
    },
  });

  /**
   * Get evidence list by a control framework
   */
  const {
    form: controlCategorySelectionForm,
    resetFunction,
    controlSetId,
  } = useFetchEvidenceBy({
    organizationID,
    grid: queryCard,
    setUnfilteredData: queryCard.setData,
  });

  let display = (
    <Dashboard
      id="evidence-dashboard"
      title={"Evidence"}
      icon={"icon-badge"}
      headerButtons={[<span style={{ width: "300px" }}>{controlCategorySelectionForm.display}</span>]}
      sx={{
        ...(sx.dashboard || {}),
      }}
    >
      <div style={{ height: "100%" }}>{queryCard.display}</div>
    </Dashboard>
  );

  if (componentGridDisplay) {
    display = queryCard.display;
  }

  return {
    ...queryCard,
    display,
    setUnfilteredData: queryCard.setData,
  };
};
