import { modules, resources } from "@rivial-security/role-utils";

import React from "react";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useEditIncidentDetection } from "../hooks/useEditIncidentDetection";

/**
 * Custom Field component for Incident.detectionType
 * @param {object} item - incident data that uses the detection type
 * @param {object } detection - detection type data to show, needs at least the 'name' property
 * @param {string} organizationID - currently selected organization identifier
 * @param {function} resetFunction - callback to re-query 'item' and 'classificationTier'
 * @returns {JSX.Element}
 */
const DetectionType = ({ item, detection, organizationID, resetFunction }) => {
  const module = modules.INCIDENT_RESPONSE;
  const resource = resources.INCIDENT;
  const checkPermissionsHook = useCheckPermissions({ module, resource });

  const editDetection = useEditIncidentDetection({
    organizationID,
    item,
    resetFunction,
  });

  return (
    <span>
      {!isNullOrUndefined(detection?.name) ? (
        <span>
          <span style={{ fontWeight: "bold" }}>{detection?.name}</span>
          {detection?.description && ` - ${detection?.description}`}
        </span>
      ) : (
        <span style={{ marginRight: "1em" }}>No classification tier selected</span>
      )}
      {checkPermissionsHook.resource.update && editDetection.modalButton}
    </span>
  );
};

export default DetectionType;
