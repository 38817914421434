import { useEffect, useState } from "react";
import { useDataGrid } from "../../../../../../../../hooks/views/useDataGrid/useDataGrid";
import { generateDataGridFieldConfigFromQuery } from "./functions/generateDataGridFieldConfigFromQuery";
import { resourceDefinitions } from "../../../../../../../../definitions/constants/resourceDefinitions";

/**
 * A generic data grid that is used to display the data retrieved from a custom query
 * @param {object} query - the query configuration object created by the user with query builder
 * @param {object[]} data - the data retrieved after running the query and its transformation functions
 * @param {boolean} isLoading - if true will display loading bar in the grid (used to indicate custom query is updating the data)
 * @param {JSX.Element[]} toolbarOptions - the grid toolbar options to append to the normal options
 * @param {string} title - optional title to display above the grid
 * @param {string} persistenceUUID - the unique id to use for storing the grid state in local storage
 * @param {boolean} disableCustomFields - if true will disable the custom fields option in the grid
 * @param {boolean} disableViews - if true will disable the views options in the grid
 * @param {string} organizationID - the organization ID to use when running the query
 * @param {function} onColumnHeaderClick - the function to call when a column header is clicked
 * @param {object[]} gridFieldsToMerge - the fields to merge into the grid field config
 * @param {boolean} enableQuickDetails - if true will enable the quick details option in the grid
 * @param {string} detailsLink - the link to use for the details page button
 * @return {{display: JSX.Element}}
 */
export const useCustomQueryNestedFieldDataGrid = ({
  query,
  data,
  isLoading,
  toolbarOptions,
  title,
  persistenceUUID,
  disableCustomFields,
  disableViews,
  organizationID,
  onColumnHeaderClick,
  gridFieldsToMerge,
  enableQuickDetails,
  detailsLink,
}) => {
  const [fields, setFields] = useState([]);
  useEffect(() => {
    const newFields = generateDataGridFieldConfigFromQuery({
      disableCustomFields,
      query,
      gridFieldsToMerge,
    });
    setFields(newFields);
  }, [JSON.stringify(query?.fields), JSON.stringify(query?.functions), JSON.stringify(gridFieldsToMerge)]);

  const typename = query?.typename;
  const detailsComponent = (typename && resourceDefinitions[typename]?.detailsComponent) || undefined;
  const module = resourceDefinitions[typename]?.module;
  const resource = resourceDefinitions[typename]?.resource;
  const icon = resourceDefinitions[typename]?.icon || "icon-list";
  const customOptions = [...(toolbarOptions || [])];
  const resetFunction = null;
  const dashboardDisplay = true;

  const grid = useDataGrid({
    fields,
    data,
    isLoading,
    resetFunction,
    query: null,
    customOptions,
    title,
    dashboardDisplay,
    icon,
    persistenceUUID,
    disableViews,
    detailsComponent,
    organizationID,
    module,
    resource,
    typename,
    onColumnHeaderClick,
    enableQuickDetails,
    detailsLink,
  });

  return grid;
};
