import React, { useState, useEffect } from "react";
import { formattedPercent } from "@rivial-security/func-utils";

/**
 * Displays the strength of a control category (min, max, average)
 * @param {object} item - the control category data
 * @returns {JSX.Element}
 */
const ControlCategoryStrength = ({ item }) => {
  const [strength, setStrength] = useState();
  useEffect(() => {
    let minStrength = 10000000;
    let maxStrength = 0;
    let totalStrength = 0;
    let averageStrength = 0;
    const subControls = item?.subControls?.items;
    if (Array.isArray(subControls)) {
      for (const subControl of subControls) {
        const strengthRating = subControl?.strengthRating || 0;

        if (strengthRating < minStrength) {
          minStrength = strengthRating;
        }

        if (strengthRating > maxStrength) {
          maxStrength = strengthRating;
        }

        totalStrength += subControl?.strengthRating || 0;
      }
      averageStrength = totalStrength / subControls.length;
    }

    setStrength({
      min: minStrength,
      max: maxStrength,
      average: averageStrength,
      total: totalStrength,
    });
  }, [item]);

  return (
    <div>
      <b>Lowest:</b> {formattedPercent(strength?.min)}
      {" - "}
      <b>Average</b>: {formattedPercent(strength?.average)}
      {" - "}
      <b>Highest:</b> {formattedPercent(strength?.max)}
      {" - "}
      <b>Total:</b> {formattedPercent(strength?.total)}
    </div>
  );
};

export default ControlCategoryStrength;
