import { useDetailsCard } from "../../../../hooks/views/useDetailsCardV2";
import { generateGraphql } from "@rivial-security/generategraphql";
import React from "react";
import VendorSolutionsTableField from "../customFields/VendorSolutionsTableField";
import { modules, resources } from "@rivial-security/role-utils";
import VendorBlackKiteCompany from "../../../Tools/BlackKite/VendorBlackKiteCompany/components/VendorBlackKiteCompany";
import Dashboard from "../../../../utils/GenericComponents/Dashboard";
import Grid from "@mui/material/Grid";
import { useAccordion } from "../../../../hooks/views/useAccordion/useAccordion";
import { CISSP_ORGANIZATION_ID } from "../../../AdminPanel/CisspReviews/constants/CISSP_ORGANIZATION_ID";
import GroupedRiskChangeDataGrid from "../../../Risk/RiskChanges/components/GroupedRiskChangeDataGrid";

/**
 * @description Hook for displaying all information on one Vendor (name, vendor solutions) in one place
 * @param {object} params
 * @param {string} params.organizationID
 * @param {string} params.itemId
 */
export const useVendorDetails = ({
  organizationID,
  itemId,
  tableDisplay,
  config = {},
  detailsConfig = {},
  vendorSolutionsGridComponent,
  ...props
}) => {
  const module = modules.VENDORS;
  const resource = resources.VENDOR;
  const typename = "Vendor";
  const fields = ["name", "blackKiteCompanyID"];

  const fieldNameDictionary = {
    id: "ID",
    name: "Name",
  };

  const { getQuery, updateMutation } = generateGraphql(typename, fields);

  const queryConfig = {
    fields,
    query: getQuery,
    organizationID,
    itemId,
  };

  detailsConfig = {
    fields,
    fieldNameDictionary,
    updateMutation,
    ...detailsConfig,
  };

  config = {
    header: "Vendor Details",
    route: "#/vendors/vendor/",
    ...config,
  };

  const customFields = [
    {
      field: "blackKiteCompanyID",
      isHidden: true,
    },
  ];

  const details = useDetailsCard({
    fields,
    customFields,
    queryConfig,
    detailsConfig,
    config,
    module,
    resource,
    typename,
    tableDisplay,
    ...props,
  });

  const accordion = useAccordion({
    items: [
      {
        title: "General Information",
        subTitle: "General Vendor Information",
        component: details?.tableDisplay,
        icon: "icon-list",
        defaultExpanded: true,
      },
      {
        title: "Vendor Solutions",
        subTitle: "Vendor Solutions linked to this vendor",
        component: vendorSolutionsGridComponent ? (
          React.cloneElement(vendorSolutionsGridComponent, {
            item: details?.item,
            organizationID,
          })
        ) : (
          <VendorSolutionsTableField item={details?.item} organizationID={organizationID} />
        ),
        icon: "icon-basket-loaded",
      },
      {
        title: "Risk Changes",
        subTitle: "Risk Changes caused by this vendor",
        icon: "icon-graph",
        createResourceComponent: null,
        component: (
          <GroupedRiskChangeDataGrid
            tableDisplay={true}
            height={"50vh"}
            queryConfig={{
              normalizeData: (data) => data.filter((item) => item?.source?.vendorID === itemId),
            }}
          />
        ),
      },
      {
        title: "Black Kite",
        subTitle: "Black Kite Integration",
        component: (
          <VendorBlackKiteCompany
            item={details?.item}
            resetFunction={details?.resetFunction}
            organizationID={organizationID}
          />
        ),
        icon: "icon-wrench",
        hidden: organizationID === CISSP_ORGANIZATION_ID,
      },
    ],
  });

  const display = (
    <Dashboard item={details?.item} resetFunction={details.reset} subTitle={details?.item?.name}>
      <Grid container spacing={2}>
        <Grid item lg={12} xs={12}>
          {accordion.display}
        </Grid>
      </Grid>
    </Dashboard>
  );

  return {
    ...details,
    display,
  };
};
