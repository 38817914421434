import React from "react";
import PropTypes from "prop-types";
import { Table } from "reactstrap";
import GenericEditField from "../../../../../../utils/GenericComponents/GenericEditField";
import SatisfyMaturityIndicatorButtons from "./SatisfyMaturityIndicatorButtons";
import Notes from "../../../../../../utils/Notes/components/Notes";
import { useCheckPermissions } from "../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { modules, resources } from "@rivial-security/role-utils";

/**
 * Author: Jacob Blazina
 * Created At: N/A
 * Edits:
 *  - 3/30/20 JB: Added permissions checking
 * @param props
 * @returns {*}
 * @constructor
 */
const MaturityIndicatorDetails = (props) => {
  const module = modules.GOVERNANCE;
  const resource = resources.BLUEPRINT;

  const checkPermissionsHook = useCheckPermissions({ module, resource });

  return (
    <>
      {props.maturityIndicator ? (
        <Table>
          <tbody>
            <tr>
              <th scope="row">Name:</th>
              <td>
                <GenericEditField
                  item={props.maturityIndicator}
                  field="name"
                  mutation="updateMaturityIndicator"
                  // disableEdits={!props.enableEdits}
                />
              </td>
            </tr>
            <tr>
              <th scope="row">Satisfied:</th>
              <td>
                <SatisfyMaturityIndicatorButtons maturityIndicator={props.maturityIndicator} />
              </td>
            </tr>
            {checkPermissionsHook.CheckFieldPermissionsRead("notes") && (
              <tr>
                <td colSpan={2}>
                  <Notes
                    organizationID={props.maturityIndicator.ownerGroup}
                    item={props.maturityIndicator}
                    module={module}
                    resource={resource}
                  />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      ) : (
        "Error: Maturity Indicator not Found!"
      )}
    </>
  );
};

MaturityIndicatorDetails.propTypes = {
  maturityIndicator: PropTypes.object.isRequired,
};

export default MaturityIndicatorDetails;
