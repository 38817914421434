import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useCheckPermissions } from "../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useMutation } from "../../../../../../hooks/graphql/useMutation/useMutation";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";
import PointOfContactDataGrid from "../../../../../OrganizationManager/PointOfContacts/components/PointOfContactDataGrid";

const { updateMutation: updateImplementationLevel } = generateGraphql("ImplementationLevel");

export const CustomPointOfContactFieldWithoutEvidenceChange = ({
  item,
  module = modules.GOVERNANCE,
  resource = resources.IMPLEMENTATION_LEVEL,
  field = "pointOfContact",
  resetFunction,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [pointOfContact, setPointOfContact] = useState(null);

  useEffect(() => {
    if (item && item.pointOfContact) {
      setPointOfContact(item.pointOfContact);
    } else {
      setPointOfContact(null);
    }
  }, [item]);

  const checkPermissions = useCheckPermissions({ module, resource, field });

  const isDisabled = !checkPermissions.resource.update || !item;

  const updatePOCHook = useMutation({
    mutation: updateImplementationLevel,
    typename: "Implementation Level",
    module,
    resource,
    field: "pointOfContact",
    disableRoleChecking: true,
  });

  const selectContact = async (pointOfContact) => {
    if (item && item.id && pointOfContact) {
      setPointOfContact(pointOfContact);

      updatePOCHook
        .editItem({
          id: item && item.id,
          implementationLevelPointOfContactId: pointOfContact && pointOfContact.id,
        })
        .then((item) => {
          resetFunction && resetFunction();
          setIsOpen(!isOpen);
        });
    }
  };

  return (
    <span key={item && item.id ? item.id : JSON.stringify(item)}>
      {checkPermissions.resource.read ? (
        <>
          {pointOfContact ? (
            <span
              style={{
                cursor: !isDisabled ? "pointer" : undefined,
                color: "green",
              }}
              onClick={() => {
                if (!isDisabled) {
                  setIsOpen(!isOpen);
                }
              }}
            >
              {pointOfContact && pointOfContact.firstName} {pointOfContact && pointOfContact.lastName}
            </span>
          ) : (
            <span
              onClick={() => {
                if (!isDisabled) {
                  setIsOpen(!isOpen);
                }
              }}
              style={{
                color: "red",
                cursor: !isDisabled ? "pointer" : undefined,
              }}
            >
              <i className="icon-user-unfollow" /> Not Assigned
            </span>
          )}
        </>
      ) : (
        <b>Do not have Permissions to view this field</b>
      )}
      <Modal key={item && item.id ? item.id : JSON.stringify(item)} isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
        <ModalHeader toggle={() => setIsOpen(!isOpen)}>
          <strong>Select who is Responsible:</strong>
        </ModalHeader>
        <ModalBody>
          <PointOfContactDataGrid enableSelectButton={true} onSelectCallback={selectContact} />
        </ModalBody>
      </Modal>
    </span>
  );
};
