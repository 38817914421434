import React from "react";
import { useCheckPermissions } from "../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { modules, resources } from "@rivial-security/role-utils";
import { useModal } from "../../../../../../hooks/views/useModal";
import KeyPerformanceIndicatorDetails from "../../../../../Metrics/KeyPerformanceIndicators/components/KeyPerformanceIndicatorDetails";

/**
 * Displays timeline information about a point of contact validating an Evidence
 * @param {PointOfContact} pointOfContact
 * @param {string} validatedBy - name of the person who validated the evidence
 * @returns {JSX.Element}
 * @constructor
 */
const ValidatedActivity = ({ validatedBy, keyPerformanceIndicator }) => {
  //Key performance indicator validation specific
  const checkKPIPermissions = useCheckPermissions({
    module: modules.METRICS,
    resource: resources.KEY_PERFORMANCE_INDICATOR,
  });
  const kpiPreviewModal = useModal(
    "Key Performance Indicator",
    <KeyPerformanceIndicatorDetails item={keyPerformanceIndicator} tableDisplay={true} />,
    null,
    {
      width: "70vw",
    },
  );

  let finalMessage = `Evidence validated`;
  if (keyPerformanceIndicator && !validatedBy) {
    validatedBy = `a KPI`;
  }
  if ((!keyPerformanceIndicator || !checkKPIPermissions.resource.read) && validatedBy) {
    finalMessage += ` by ${validatedBy}`;
  }

  return (
    <span style={{ fontSize: ".9em" }}>
      <span>{finalMessage}</span>
      {keyPerformanceIndicator && checkKPIPermissions.resource.read && (
        <span>
          <span> by </span>
          <span
            onClick={() => {
              kpiPreviewModal.setModalIsOpen(true);
            }}
            style={{
              borderBottom: "2px dashed",
              textDecoration: "none",
              cursor: "pointer",
            }}
          >
            a KPI
          </span>
        </span>
      )}
      {keyPerformanceIndicator && checkKPIPermissions.resource.read && kpiPreviewModal.modal}
    </span>
  );
};

export default ValidatedActivity;
