import { REPORT_JOB_TYPE } from "@rivial-security/report-utils";
import { createJob } from "../../../OrganizationManager/Jobs/functions/createJob";
import { fargateApi } from "../../../../utils/Functions/FargateApi/fargateApi";

export interface GenerateCustomTemplateParams {
  templateId: string;
  config?: object;
  organizationID: string;
  preserveTags: boolean;
  onJobStarted: ({ job }) => void;
  sentryTrace: object;
  operationTeamID?: string;
}

/**
 * Loads in a custom template into the document editor
 *
 * @param templateId - the template to load in
 * @param config - custom report configuration settings
 * @param organizationID - the organization of the template and its widgets
 * @param preserveTags - TRUE if tags do not need to be populated
 * @param onJobStarted - callback to notify the document editor that a server job has started (takes in job object)
 * @param sentryTrace - Sentry trace for tracing calls from frontend to backend
 * @param operationTeamID - the operation team ID for the report
 */
export const generateCustomTemplate = async ({
  templateId,
  config,
  organizationID,
  preserveTags,
  onJobStarted,
  sentryTrace,
  operationTeamID,
}: GenerateCustomTemplateParams): Promise<void> => {
  //Create a job to use for tracking report progress
  const job = await createJob({
    name: `Report Job ${new Date().toLocaleString()}`,
    status: "started",
    ownerGroup: organizationID,
    type: REPORT_JOB_TYPE.GENERATE_TEMPLATE,
  });

  //Custom Template Input
  const input = {
    route: `report/${templateId}`,
    templateId,
    config,
    organizationID,
    preserveTags,
    job,
    sentryTrace,
    operationTeamID,
  };
  onJobStarted && onJobStarted({ job });

  //Call fargate API
  await fargateApi({ input });
};
