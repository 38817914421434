import * as Sentry from "@sentry/react";

import { Alert, Paper, Popover } from "@mui/material";

import { ErrorLogger } from "@utils/EventLogger";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import ViewControlButton from "./ViewControlButton";
import { cloneDeep } from "lodash";
import { listDataGridViews } from "../functions/listDataGridViews";
import { saveDataGridView } from "../functions/saveDataGridView";
import { useForm } from "../../../../useForm";
import { usePreferences } from "../../../../usePreferences/usePreferences";
import { useState } from "react";
import { v4 as uuid } from "uuid";

/**
 * Component allowing user to save the current data grid view as a new view
 * @param {string} gridID - data grid unique identifier under which to save the view
 * @param {object} targetView - the data grid view settings to save
 * @return {JSX.Element}
 */
const SaveAsViewMenu = ({ gridID, targetView, onSaveComplete }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [needsConfirmation, setNeedsConfirmation] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const preferencesHook = usePreferences();

  const form = useForm({
    disableSubmitButton: true,
    disableResetButton: true,
    fieldConfig: {
      name: {
        inputType: "text",
        label: "Name",
        required: true,
        validationFunction: (input) => {
          if (input === "" || input === null || input === undefined) {
            return false;
          }
          if (input.toLowerCase() === "default") {
            return false;
          }
          if (input.length < 3 || input.length > 50) {
            return false;
          }

          return true;
        },
        validationText: "Must be 3 to 50 characters in length and not 'Default'",
      },
      description: {
        inputType: "textarea",
        label: "Description",
        validationFunction: (input) => {
          if (input === "" || input === null || input === undefined) {
            return true;
          }
          if (input.length > 500) {
            return false;
          }

          return true;
        },
        validationText: "Must less than 500 characters in length",
      },
    },
  });

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    if (!gridID) {
      Sentry.captureMessage("User tried saving a custom data grid view for a grid without a persistence id!", {
        level: "warning",
      });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setNeedsConfirmation(false);
    form.getInitialState();
  };

  const handleSave = async () => {
    //Create the view to save to user preferences
    const viewToSave = cloneDeep(targetView);
    viewToSave.name = form?.input?.name;
    viewToSave.description = form?.input?.description;

    //Check for any view with the same name
    const views = await listDataGridViews({ gridID, preferencesHook });
    const existingView = views.find((view) => view.name === viewToSave.name);
    if (existingView && needsConfirmation === false) {
      setNeedsConfirmation(true);
      return;
    }

    //Generate an id for the new view
    const viewID = existingView?.id ?? uuid();
    viewToSave.id = viewID;

    //Save the view
    try {
      setIsSaving(true);
      await saveDataGridView({
        gridID,
        viewID,
        view: viewToSave,
        preferencesHook,
        selectSavedView: true,
      });
    } catch (e) {
      ErrorLogger("Failed to save data grid view", e);
    } finally {
      setIsSaving(false);
      setNeedsConfirmation(false);
      handleClose();
    }
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <ViewControlButton title={"Save the current grid configuration as a new view"} onClick={handleOpen}>
        {"Save As"}
      </ViewControlButton>
      <Popover
        anchorEl={anchorEl}
        onClose={handleClose}
        open={open}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Paper>
          {!gridID ? (
            <Alert severity={"warning"}>Custom views not available for this grid. </Alert>
          ) : (
            <div style={{ padding: "1em", width: "35em" }}>
              {form.display}
              <div style={{ display: "flex", justifyContent: "end" }}>
                {needsConfirmation && <Alert severity={"warning"}>View already exists, press again to overwrite</Alert>}
                <LoadingButton
                  loading={isSaving}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  color={"success"}
                  variant={"contained"}
                  disabled={form?.submitDisabled}
                  onClick={handleSave}
                >
                  Save
                </LoadingButton>
              </div>
            </div>
          )}
        </Paper>
      </Popover>
    </div>
  );
};

export default SaveAsViewMenu;
