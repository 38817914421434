import { useTestingFalsePositivesGrid } from "../../FalsePositives/hooks/useTestingFalsePositivesGrid";
import {
  FalsePositiveLink,
  getAssessmentVulnerabilityLinksFalsePositives,
} from "../functions/getAssessmentVulnerabilityLinksFalsePositives";
import { useSetAsyncData } from "../../../../hooks/functional/useSetAsyncData";
import { Assessment } from "@rivial-security/schema-types";
import { DataGridResult } from "../../../../hooks/views/useDataGrid/types";
import { resources } from "@rivial-security/role-utils";

interface AssessmentFalsePositivesParams {
  item: Assessment;
  organizationID: string;
  module: string;
  resource: string;
}

export const useAssessmentFalsePositives = ({
  item,
  organizationID,
  module,
  resource,
}: AssessmentFalsePositivesParams): DataGridResult<FalsePositiveLink> => {
  const assessmentID: string | undefined = item?.id;
  const grid: DataGridResult<FalsePositiveLink> = useTestingFalsePositivesGrid({
    viewingFrom: resources.ASSESSMENT,
    organizationID,
    query: null,
    module,
    resource,
  });

  useSetAsyncData({
    getData: async () => {
      const assessmentVulnerabilityLinksData = await getAssessmentVulnerabilityLinksFalsePositives(assessmentID);
      grid?.setData?.(assessmentVulnerabilityLinksData);
    },
    setIsLoading: grid.setIsLoading,
  });

  return {
    ...grid,
  };
};
