import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import React from "react";
import GenericEditFieldV3 from "../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";
import { GENERIC_FIELD_TYPES } from "../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

/**
 * Custom component for the 'isComplete' field for the 'Audit' type
 * @param {object} item - the Audit item
 * @param {boolean} item.isComplete - the isComplete field for the Audit item
 * @returns {JSX.Element}
 * @constructor
 */
const AuditIsComplete = ({ item }) => {
  const field = "isComplete";
  const module = modules.COMPLIANCE;
  const resource = resources.AUDIT;

  const { updateMutation } = generateGraphql("Audit", ["isComplete"]);

  return (
    <GenericEditFieldV3
      item={item}
      inputType={GENERIC_FIELD_TYPES.SWITCH}
      module={module}
      resource={resource}
      field={field}
      mutation={updateMutation}
    />
  );
};

export default withOrganizationCheck(AuditIsComplete);
