import React from "react";
import { getWhenSeenVulnerabilityGroup } from "@rivial-security/report-utils";
import { isValidDate } from "@rivial-security/func-utils";

/**
 * Component to display the first or last seen date of a vulnerability inside of vulnerability details page
 * @param {object} item - vulnerability object
 * @param {string} type - firstSeen or lastSeen date
 * @returns {JSX.Element}
 */
const VulnerabilityFirstLastSeenDate = ({ item, type }) => {
  const targetLinks = item?.targets;
  const seenDate = getWhenSeenVulnerabilityGroup({
    targetVulnerabilityLinks: targetLinks,
    whenSeen: type,
  });

  if (!isValidDate(seenDate)) {
    return <span>Not Available</span>;
  }

  return <span>{new Date(seenDate).toLocaleDateString()}</span>;
};
export default VulnerabilityFirstLastSeenDate;
