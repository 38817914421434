import React, { useEffect, useState } from "react";
import { useGridContext } from "../hooks/useGridContext";
import GenericEditFieldV3 from "../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";
import { fieldContexts } from "../../../../enums/fieldContexts";

/**
 * @description Used with Grid Columns to render Custom Field components that have access to Application Context
 * @param {object} props - contains information about columns of the grid
 * @returns {JSX.Element}
 * @constructor
 */
export const GridTemplate = (props) => {
  const gridContext = useGridContext();

  const [el, setEl] = useState(null);

  /**
   * Grabs the component from the Grid fields param in context and renders it
   */
  useEffect(() => {
    const fields = gridContext.fields;
    const column = props?.column?.name;
    const field = fields && fields.find((field) => field?.name === column);

    /**
     * Display only one field with details for password protected items
     */
    if (props?.accessControl?.password && field?.name !== gridContext?.accessControlFieldName && field?.name !== "ℹ") {
      setEl(<i style={{ color: "gray" }} className="icon-lock" />);
    } else if (field?.component) {
      /**
       * Check if field has a custom component
       */
      setEl(field?.component);
    } else {
      /**
       * Check if field has data if so set data
       */
      const GridDisplayColumnDefault = () => {
        return (
          <div key={field?.name + props?.id}>
            <GenericEditFieldV3
              item={props}
              inputType={field?.inputType}
              inputConfig={field?.inputConfig}
              module={gridContext?.module}
              resource={gridContext?.resource}
              field={field?.name}
              disableEdits={field?.disableEdits}
              mutation={gridContext?.updateMutation}
              disableRoleChecking={gridContext?.disableRoleChecking}
              updateItemById={gridContext?.updateItemById}
              friendlyName={field?.friendlyName}
            />
          </div>
        );
      };

      setEl(GridDisplayColumnDefault);
    }
  }, [props]);

  return (
    <>
      {el &&
        React.cloneElement(el, {
          ...props,
          item: { ...props },
          updateItemById: gridContext?.updateItemById,
          fieldContext: fieldContexts.GRID,
        })}
    </>
  );
};
