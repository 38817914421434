import { convertCamelCaseToSentence, isNullOrUndefined } from "@rivial-security/func-utils";

import { Button } from "reactstrap";
import Dashboard from "../../../../../utils/GenericComponents/Dashboard";
import DashboardCard from "../../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import Grid from "@mui/material/Grid";
import React from "react";
import RecommendationMonteCarlo from "./components/RecommendationMonteCarlo";
import SubmitButton from "../../../../../utils/GenericComponents/buttons/SubmitButton";
import { recommendationRatings } from "../../../../Program/recommendationRatings";
import { submitRiskRecommendation } from "./functions/submitRiskRecommendation";
import { useForm } from "../../../../../hooks/views/useForm";
import { useRiskRecommendationData } from "./hooks/useRiskRecommendationData";

/**
 * Used to create a new Risk Recommendation.
 * @description A Risk Recommendation may include a Monte Carlo analysis on a potential Risk Control change for a single system or org-level Risk Control
 * @param {string} organizationID - the database identifier for an organization
 * @param {string} system - the database identifier for a system
 * @param {object} riskControl - the database identifier for a riskControl
 * @param {function} toggleModal - a function to toggle the modal
 * @param {function} resetFunction - a function to reset the form
 * @param {object} item - the existing Risk Recommendation, if using this as an edit form
 * @param {boolean} disableObservation - if true, the observation will not be created
 * @param {string} [observationName] - optional default value for the Observation Name field
 * @param {function} callback - a function to call after the form is submitted
 * @returns {{display: JSX.Element}}
 */
export const useCreateRiskRecommendation = ({
  organizationID,
  system,
  riskControl,
  toggleModal,
  resetFunction,
  item,
  disableObservation = false,
  observationName,
  callback,
}) => {
  /**
   * Handles the custom data for risk recommendations
   */
  const riskRecommendationData = useRiskRecommendationData({
    organizationID,
    system,
    riskControl,
    item,
  });

  const submitFunction = async (input) => {
    return await submitRiskRecommendation({
      input,
      riskRecommendationData,
      organizationID,
      item,
    });
  };

  const showFields = ["recommendation", "rating"];

  // If an item field is passed in, this is an edit form for Risk Recommendations
  if (!disableObservation) {
    showFields.unshift("observation");
  }

  const form = useForm({
    item,
    resetFunction,
    disableResetButton: true,
    toggleModal,
    disableSubmitButton: true,
    submitFunction,
    callback,
    fields: showFields,
    fieldConfig: {
      observation: {
        label: "Observation",
        required: isNullOrUndefined(item) ? true : false,
        inputType: "textarea",
        tooltip: "Will create an Observation with this name",
        defaultValue: observationName || "",
      },
      recommendation: {
        label: "Recommendation",
        required: true,
        inputType: "textarea",
        tooltip: "Will create a Recommendation with this name",
        defaultValue: item?.name,
      },
      rating: {
        label: "Rating",
        tooltip: "How important do you rate this Recommendation?",
        inputType: "dropdown",
        defaultValue: item?.rating || "low",
        dropdownConfig: {
          data: recommendationRatings.map((rating) => {
            return {
              value: rating,
              text: convertCamelCaseToSentence(rating),
            };
          }),
        },
      },
      isFinding: {
        label: "Finding",
        inputType: "switch",
        isHidden: !isNullOrUndefined(item),
      },
    },
  });

  const formDisplay = (
    <RecommendationMonteCarlo
      organizationID={organizationID}
      riskRecommendationData={riskRecommendationData}
      item={item}
    />
  );

  const display = (
    <Dashboard>
      <Grid container spacing={2}>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} width={"100%"}>
          <DashboardCard
            title={"Observation and Recommendation"}
            style={{
              minHeight: "25em",
              maxHeight: "100%",
              overflow: "visible",
            }}
          >
            {form.display}
          </DashboardCard>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <DashboardCard
            title={"Monte Carlo Analysis"}
            style={{
              minHeight: "25em",
              maxHeight: "100%",
              overflow: "visible",
            }}
            wrapperStyle={{ overflow: "visible" }}
          >
            {formDisplay}
          </DashboardCard>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "row",
              margin: "0.5em",
            }}
          >
            <SubmitButton
              disabled={form?.submitDisabled}
              onClick={async () => {
                await submitFunction(form?.input);
                resetFunction && resetFunction();
                toggleModal && toggleModal();
              }}
              style={{ marginRight: "0.5em" }}
              size={""}
            >
              Submit
            </SubmitButton>
            <Button
              onClick={() => {
                form?.getInitialState();
              }}
            >
              Reset
            </Button>
          </div>
        </Grid>
      </Grid>
    </Dashboard>
  );

  return {
    display,
    formDisplay,
  };
};
