import React, { useEffect, useState } from "react";
import { modules, resources } from "@rivial-security/role-utils";

import DashboardCard from "../../../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import ReviewPolicy from "../../../../../Program/Policies/components/ReviewPolicy";
import { listReviewers_PointOfContactPolicys } from "../../../../../Program/Policies/graphql/__policyGQL";
import { useDataCard } from "../../../../../../hooks/views/useDataCard";
import useListQuery from "../../../../../../hooks/graphql/useListQuery";
import { withDetailsPage } from "../../../../../../utils/Context/withDetailsPage";

/**
 * Displays policies for a point of contact
 * @param itemId
 * @param organizationID
 * @returns {JSX.Element}
 * @constructor
 */
const PointOfContactPolicys = ({ itemId, organizationID }) => {
  const module = modules.GOVERNANCE;
  const resource = resources.POLICY;

  const [list, setList] = useState([]);

  const policyReviewersQuery = useListQuery({
    query: listReviewers_PointOfContactPolicys,
    organizationID,
    disableRoleChecking: true,
    otherFiltersInitial: { reviewerPointOfContactId: { eq: itemId } },
  });

  // Combines Reviewer, PolicyVersion, and Policy information into a customized list.
  useEffect(() => {
    const temp = [];
    for (const reviewer of policyReviewersQuery.list) {
      const policyVersion = reviewer.policyVersion || {};
      const policy = policyVersion.policy || {};
      if (reviewer.status === "pending") {
        const policyVersionItem = {
          reviewerId: reviewer.id,
          id: policyVersion.id,
          status: reviewer.status,
          policyName: policy.name,
          versionNumber: policyVersion.version,
          description: policyVersion.description,
        };
        temp.push(policyVersionItem);
      }
    }
    setList([...temp]);
  }, [policyReviewersQuery.list]);

  const fieldNameDictionary = {
    status: "Status",
    policyName: "Policy Name",
    versionNumber: "Policy Version",
    description: "Description",
  };

  const listDataCard = useDataCard({
    data: list,
    header: "Security Policy Acknowledgement",
    fields: ["policyName", "description", "versionNumber"],
    route: "#/governance/review_policy/",
    fieldNameDictionary,
    detailsComponent: <ReviewPolicy style={{ marginLeft: "25px" }} />,
    resetFunction: policyReviewersQuery.reset,
    module,
    resource,
    disableRoleChecking: true,
  });

  useEffect(() => {
    listDataCard.setData(list);
  }, [list]);

  useEffect(() => {
    listDataCard.setMiniLoading(policyReviewersQuery.isLoading);
  }, [policyReviewersQuery.isLoading]);

  return (
    <DashboardCard title={"Policies"} subTitle={"pending review"} icon={"icon-docs"}>
      {listDataCard.tableDisplay}
    </DashboardCard>
  );
};

export default withDetailsPage(PointOfContactPolicys);
