import { Badge, Button, Collapse, ListGroup, ListGroupItem } from "reactstrap";
import React, { useEffect, useState } from "react";
import { modules, resources } from "@rivial-security/role-utils";
import { removeObjectFromArray, updateObjectInArray } from "@rivial-security/func-utils";

import ActionItemDetails from "../components/ActionItemDetails";
import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useCreateAction } from "./useCreateAction/useCreateAction";
import { useModal } from "../../../../hooks/views/useModal";
import { v4 as uuid } from "uuid";

/**
 * Allows the User to Add Action Items to a Recommendation
 * @param organizationID
 * @param onAdd
 * @param onRemove
 * @param onUpdate
 * @param recommendation
 */
export const useAddActionItems = ({ organizationID, onAdd, onRemove, onUpdate, module: formModule }) => {
  const module = modules.GOVERNANCE;
  const resource = resources.ACTION_ITEM;

  const createActionItemPermissions = useCheckPermissions({ module, resource });

  /**
   * The local state for this list
   */
  const [input, setInput] = useState([]);

  /**
   * Removes this action locally from state.
   * Also calls the parent 'onRemove' function to handle other removal logic.
   */
  const onRemoveAction = (action) => {
    setInput((input) => {
      onRemove && onRemove(action);
      removeObjectFromArray(input, action, "id");
      return [...input];
    });
  };

  /**
   * Adds this action locally to state.
   * Also calls the parent 'onAdd' function to handle other addition logic.
   */
  const onAddAction = (action) => {
    setInput((input) => {
      onAdd && onAdd(action);
      return [...input, action];
    });
  };

  /**
   * Updates an action in the local state.
   * Also calls the parent 'onUpdate' function
   * @param action
   */
  const onUpdateAction = (action) => {
    setInput((input) => {
      onUpdate && onUpdate(action);
      updateObjectInArray(input, action, "id");
      return [...input];
    });
  };

  const display = (
    <ListGroup>
      {input?.map((action, index) => (
        <ActionItem
          key={`actionitem${action.id}`}
          action={action}
          organizationID={organizationID}
          onRemove={onRemoveAction}
          onUpdate={onUpdateAction}
        />
      ))}
      {createActionItemPermissions.resource.create && (
        <ActionItem organizationID={organizationID} onAdd={onAddAction} module={module} />
      )}
    </ListGroup>
  );

  return {
    input,
    setInput,
    display,
  };
};

const ActionItem = ({ action, organizationID, onAdd, onRemove, onUpdate, module: formModule }) => {
  const module = modules.GOVERNANCE;
  const resource = resources.RECOMMENDATION;

  const updateActionFieldPermissions = useCheckPermissions({
    module,
    resource,
    field: "actionItems",
  });

  /**
   * Shows the Action Body with editable fields
   */
  const [showEdit, setShowEdit] = useState(false);

  /**
   * The ActionItem object.
   */
  const [actionInput, setActionInput] = useState({});

  /**
   * Bubble this input up, then close the form
   * @param input
   */
  const [id, setId] = useState(action?.id || uuid());

  /**
   * Either calls the onUpdate or onAdd functions.
   */
  const submitFunction = async () => {
    setShowEdit(false);

    /**
     * If there's on onUpdate function, updates the action in local states
     */
    if (onUpdate) {
      onUpdate({
        id,
        ...actionInput,
      });
    } else if (onAdd) {
      onAdd({
        id,
        ...actionInput,
      });
    }

    /**
     * Refreshes the id
     */
    setId(action?.id || uuid());

    createActionModal.setModalIsOpen(false);
  };

  const remove = (e) => {
    e.stopPropagation();
    onRemove({
      id,
      ...actionInput,
    });
  };

  const createActionModal = useModal(
    "Create an Action Item",
    <Form
      organizationID={organizationID}
      submitFunction={submitFunction}
      action={action}
      setVal={setActionInput}
      module={formModule}
    />,
    <span>
      <i className="icon-plus" />
      {"  "}
      Add a new Action Item
    </span>,
  );

  return (
    <ListGroupItem onClick={() => setShowEdit(!showEdit)} color={!action && "success"} style={{ cursor: "pointer" }}>
      {!action?.alreadyExists && action?.name && (
        <Badge color="success" style={{ marginRight: "1em" }}>
          New
        </Badge>
      )}
      {action?.name || createActionModal.modalButton}
      {updateActionFieldPermissions.resource.update && action && (
        <Button onClick={(e) => remove(e)} close className="float-right" title="Remove Action" />
      )}
      <Collapse onClick={(e) => e.stopPropagation()} isOpen={showEdit} style={{ cursor: "initial" }}>
        <hr />
        {showEdit && (
          /**
           * If this is an existing Action Item, show the details component for editing.
           * If this is a new Action Item, show the form
           */
          <>
            {action?.alreadyExists ? (
              <ActionItemDetails item={action} />
            ) : (
              <Form
                organizationID={organizationID}
                submitFunction={submitFunction}
                action={action}
                enableToast={false}
                setVal={setActionInput}
                module={formModule}
              />
            )}
          </>
        )}
      </Collapse>
    </ListGroupItem>
  );
};

const Form = ({ submitFunction, action, setVal, organizationID, disableEdit, module, enableToast }) => {
  const form = useCreateAction({
    organizationID,
    submitFunction,
    item: action,
    disableMutation: true,
    disableEdit,
    disableSubmitButton: disableEdit,
    disableResetButton: disableEdit,
    enableToast,
    module,
  });

  useEffect(() => {
    if (form?.input) {
      setVal(form.input);
    }
  }, [form.input]);

  return form.display;
};
