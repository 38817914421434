import { ErrorLogger } from "@utils/EventLogger";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Calculates the return on investment of a risk change
 * @param {number} change.costChange - the difference in the amount of money spent from the change
 * @param {number} change.residualChange - the residual risk dollar change after the change
 */
export const calculateChangeReturnOnInvestment = ({ change } = {}) => {
  //Check input parameter
  if (!change) {
    ErrorLogger("calculateChangeReturnOnInvestment: change is null or undefined");
    return 0;
  }

  //Check data
  if (isNullOrUndefined(change?.costChange) || isNullOrUndefined(change?.residualChange)) {
    return 0;
  }

  //Get needed variables to calculate the return on investment
  const costChange = change?.costChange;
  const residualChange = change?.residualChange;

  //Check for 0s
  if (costChange === 0) {
    return 0;
  }

  //Calculate the return on investment
  const costToAchieveResidualLossChange = costChange;
  const residualLossChangeProfits = -residualChange;
  const totalProfit = residualLossChangeProfits - costToAchieveResidualLossChange;

  /**
   * Note: taking the absolute value of cost change for negative cost
   * changes to not cancel out negative residual changes in final result
   * during division
   */
  return totalProfit / Math.abs(costChange);
};
