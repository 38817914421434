import { Button, Collapse } from "reactstrap";
import React, { useEffect, useState } from "react";
import { modules, resources } from "@rivial-security/role-utils";

import AddOrRemoveIcon from "../../../../../../../utils/GenericComponents/AddOrRemoveIcon";
import AttachThreatToKeyRiskIndicator from "./AttachThreatToKeyRiskIndicator";
import { ErrorLogger } from "@utils/EventLogger";
import { GetQuery } from "../../../../../../../utils/Functions/Graphql/GetQuery";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useCheckPermissions } from "../../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useModal } from "../../../../../../../hooks/views/useModal";
import { useThreatDataGrid } from "../../../../../RiskConfig/Threats/hooks/useThreatDataGrid";
import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";

/**
 * Displays Threats associated with a SystemRiskLink
 * @param {string} organizationID - ID of the Organization
 * @param {object} systemRiskLink - SystemRiskLink object
 * @param {boolean} disableRoleChecking - Disable role checking
 * @param {string} viewType - View type
 * @returns {JSX.Element}
 */
const KeyRiskIndicatorThreats = ({ organizationID, systemRiskLink, disableRoleChecking, viewType }) => {
  const module = modules.RISK;
  const resource = resources.INFORMATION_SYSTEM;
  const field = "threats";

  const checkPermissionsHook = useCheckPermissions({
    module,
    resource,
    field,
    disableRoleChecking,
  });

  const [threats, setThreats] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [resetKey, setResetKey] = useState(0);

  useEffect(() => {
    const getData = async () => {
      if (isOpen) {
        setIsLoading(true);

        try {
          const { getQuery } = generateGraphql("SystemRiskLink", ["threats"], {
            threats: `
              (limit: 1000) {
                items {
                  id
                  threat {
                    id
                    name
                    techniqueID
                    ownerGroup
                  }
                  ownerGroup
                }
                nextToken
              }
            `,
          });
          // query systemRiskLink to get all threats
          let systemRiskLinkWithThreats = [];
          if (systemRiskLink?.id) {
            systemRiskLinkWithThreats = await GetQuery({
              query: getQuery,
              variables: { id: systemRiskLink?.id },
            });
          }

          const threatItems = systemRiskLinkWithThreats?.threats?.items ? systemRiskLinkWithThreats.threats.items : [];

          const items = [];
          for (const item of threatItems) {
            items.push({ ...item.threat, linkId: item.id });
          }

          setThreats([...items]);
        } catch (e) {
          ErrorLogger(e);
        } finally {
          setIsLoading(false);
        }
      }
    };

    getData();
  }, [systemRiskLink, isOpen, resetKey]);

  const attachThreatModal = useModal(
    "Add or Remove Threats associated with this Risk/System",
    <AttachThreatToKeyRiskIndicator
      organizationID={organizationID}
      currentThreats={threats}
      systemRiskLink={systemRiskLink}
      resetFunction={() => setResetKey((resetKey) => resetKey + 1)}
      disableRoleChecking={disableRoleChecking}
    />,
    <Button
      size="sm"
      color="ghost-secondary"
      className="btn-pill"
      title={
        checkPermissionsHook.resource.update
          ? "Add/Remove Threats"
          : "You don't have Update Permissions on Field: Threats"
      }
      disabled={!checkPermissionsHook.resource.update}
    >
      <AddOrRemoveIcon />
    </Button>,
  );

  const threatsGrid = useThreatDataGrid({
    organizationID,
    query: null,
    allowPaging: false,
    createResourceComponent: null,
    deleteFunction: null,
    hideFooter: true,
    isLoading,
    resetFunction: () => setResetKey((resetKey) => resetKey + 1),
  });

  useEffect(() => {
    threatsGrid.setData([...threats]);
  }, [threats]);

  return (
    <>
      <strong>
        Threats
        {isOpen && attachThreatModal.modalButton}{" "}
        <i
          className={!isOpen ? "fa fa-eye-slash" : "fa fa-eye"}
          title={`${isOpen ? "Hide" : "Show"} Threats`}
          style={{ cursor: "pointer" }}
          onClick={() => setIsOpen(!isOpen)}
        />
      </strong>
      <Collapse isOpen={isOpen}>
        <div style={{ height: "20em" }}>{threatsGrid.gridDisplay}</div>
      </Collapse>
    </>
  );
};

export default withOrganizationCheck(KeyRiskIndicatorThreats);
