import { ErrorLogger } from "@utils/EventLogger";
import { listMitreAttackRelationships } from "../../functions/listMitreAttackRelationships";
import { mitreAttackApiUrl } from "../../../../../env-config";

/**
 * @description Get a Mitre Attack Technique
 * @param {string} id - technique ID
 * @returns {Promise<any>}
 */
export const getTechniqueById = async ({ id }) => {
  return await fetch(`${mitreAttackApiUrl}/api/techniques/${id}`)
    .then((response) => {
      try {
        return response.json().then(async (data) => {
          const firstItem = data[0];
          if (firstItem) {
            firstItem.id = firstItem?.stix?.id;
            if (firstItem?.stix?.x_mitre_is_subtechnique === true) {
              const parent = await listMitreAttackRelationships({
                sourceRef: firstItem?.stix?.id,
                relationshipType: "subtechnique-of",
              });

              if (Array.isArray(parent) && parent?.length > 0) {
                firstItem["parent"] = parent[0]?.target_object;
              }
            }
            return firstItem;
          }
        });
      } catch (e) {
        ErrorLogger(e);
        return [];
      }
    })
    .catch((error) => ErrorLogger(error));
};
