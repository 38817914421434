import { modules, resources } from "@rivial-security/role-utils";

import { CISSP_ORGANIZATION_ID } from "../../../AdminPanel/CisspReviews/constants/CISSP_ORGANIZATION_ID";
import CreateButton from "../../../../utils/GenericComponents/CreateButton";
import { ErrorLogger } from "@utils/EventLogger";
import PermissionsOverlay from "../../../../utils/Overlays/PermissionsOverlay";
import React from "react";
import RequestPermissionButton from "../../../../utils/Permissions/components/RequestPermissionButton";
import VendorReviewStatus from "../../VendorSolutionReviews/components/VendorReviewStatus";
import VendorSolutionReview from "../../VendorSolutionReviews/components/VendorSolutionReview";
import { createVendorSolutionReview } from "../../VendorSolutionReviews/functions/createVendorSolutionReview";
import { deleteVendorSolutionReview } from "../../VendorSolutionReviews/functions/deleteVendorSolutionReview";
import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";
import { useHistory } from "react-router-dom";
import { usePleaseWaitModal } from "../../../../hooks/views/usePleaseWaitModal";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * Custom field for VendorSolution.vendorReviews.
 * Displays a list of vendorReviews for a Vendor Solution
 * @param {object} vendorSolution - The VendorSolution object
 * @param {object} vendorReviews - The vendorReviews for the VendorSolution
 * @param {string} organizationID - The ID of the organization
 * @param {string} route - The route to the VendorSolution reviews
 * @param {JSX} detailsComponent - The component to display when a review is clicked
 * @param {string} module - The module to check permissions for
 * @param {string} resource - The resource to check permissions for
 * @param {boolean} disableRoleChecking - Whether to disable role checking
 * @returns {*}
 * @constructor
 */
const VendorReviews = ({
  item: vendorSolution,
  vendorReviews = {},
  organizationID,
  route = "#/vendors/reviews/",
  detailsComponent,
  module = modules.VENDORS,
  resource = resources.VENDOR_REVIEW,
  disableRoleChecking,
}) => {
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <CreateVendorReviewButton
          module={module}
          resource={resource}
          disableRoleChecking={disableRoleChecking}
          vendorSolution={vendorSolution}
          organizationID={organizationID}
          style={{ marginBottom: "0.5em" }}
          route={route}
        />
        <div style={{ flex: 1 }} />
      </div>

      <div style={{ flex: 1 }}>
        <VendorReviewsTable
          module={module}
          resource={resource}
          disableRoleChecking={disableRoleChecking}
          vendorReviews={vendorReviews?.items || []}
          organizationID={organizationID}
          detailsComponent={detailsComponent}
          route={route}
        />
      </div>
    </div>
  );
};

/**
 * Button for creating a new Vendor Review for this solution
 * @constructor
 */
export const CreateVendorReviewButton = ({
  vendorSolution,
  organizationID,
  style,
  route = "#/vendors/reviews/",
  disableRoleChecking,
  disabled,
}) => {
  const module = modules.VENDORS;
  const resource = resources.VENDOR_REVIEW;

  const pleaseWaitModal = usePleaseWaitModal({
    confirmationText: "Redirecting you to the newly created Vendor Review..",
    steps: [
      {
        text: "Creating new Review",
        status: "waiting",
      },
      {
        text: "Setting up Vendor Controls",
        status: "waiting",
      },
    ],
  });

  const history = useHistory();

  /**
   * Creates a new Vendor Review for this solution,
   * then redirects the user to the new Vendor Review details page
   */
  const handleClick = async () => {
    pleaseWaitModal.setStepStatus(0, "inProgress");
    pleaseWaitModal.setModalIsOpen(true);
    try {
      createVendorSolutionReview(vendorSolution, pleaseWaitModal.setStepStatus, organizationID).then((vendorReview) => {
        pleaseWaitModal.setFinished(true);
        setTimeout(() => {
          pleaseWaitModal.setModalIsOpen(false);
          const r = route.replace("#", "");
          history.push(`${r}${vendorReview.id}`);
        }, 1500);
      });
    } catch (e) {
      ErrorLogger("Error: Could not create Vendor Solution Review", e);
      pleaseWaitModal.setModalIsOpen(false);
    }
  };

  const checkPermissionsHook = useCheckPermissions({
    module,
    resource,
    disableRoleChecking,
  });

  const noPermission = !checkPermissionsHook.resource.create;

  return (
    <>
      {pleaseWaitModal.modalIsOpen && pleaseWaitModal.modal}
      <CreateButton
        style={style}
        data-testid="button-create-vendor-review"
        onClick={async () => await handleClick()}
        text={noPermission ? "No permission to start a new Vendor Review" : "Start a new Vendor Review"}
        icon={noPermission ? "icon-lock" : undefined}
        title={
          noPermission
            ? "You don't have the Create permission on the Vendor Reviews resource"
            : "Create a new Vendor Review"
        }
        disabled={disabled || noPermission}
      />
      {noPermission && <RequestPermissionButton module={module} resource={resource} operation={"create"} />}
    </>
  );
};

/**
 * Displays a list of Vendor Reviews for a particular Vendor Solution
 * @param vendorReviews
 * @param organizationID
 * @param route
 * @param detailsComponent
 * @param module
 * @param resource
 * @param disableRoleChecking
 * @returns {JSX.Element}
 * @constructor
 */
const VendorReviewsTable = ({
  vendorReviews,
  organizationID,
  route,
  detailsComponent,
  module = modules.VENDORS,
  resource = resources.VENDOR_REVIEW,
  disableRoleChecking,
}) => {
  const table = useDataGrid({
    module,
    resource,
    data: vendorReviews,
    fields: [
      {
        name: "name",
        flex: 1,
        minWidth: 200,
        sort: {
          direction: "asc",
          priority: 1,
        },
      },
      { name: "rating", format: "sentence", flex: 1 },
      {
        name: "status",
        component: <VendorReviewStatus />,
        flex: 0.5,
      },
    ],
    deleteFunction: deleteVendorSolutionReview,
    route: route || "#/vendors/reviews/",
    detailsComponent: detailsComponent || (
      <VendorSolutionReview
        organizationID={organizationID}
        route={route}
        module={module}
        resource={resource}
        disableRoleChecking={disableRoleChecking}
      />
    ),
    gridHeight: "100%",
    enableQuickDetails: true,
    disableRoleChecking,
    detailsTitle:
      organizationID === CISSP_ORGANIZATION_ID ? "CISSP Reviews - Vendor Review Details" : "Vendor Review Details",
  });

  return (
    <PermissionsOverlay module={module} resource={resource} disableRoleChecking={disableRoleChecking}>
      <div style={{ height: "50vh" }}>{table.display}</div>
    </PermissionsOverlay>
  );
};

export default withOrganizationCheck(VendorReviews);
