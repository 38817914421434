import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { getIcon } from "../../../../utils/Functions/Icon/getIcon";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import React from "react";
import AuditReport from "../../../Reports/AuditReport/AuditReport";
import { useModal } from "../../../../hooks/views/useModal";
import GenerateReportModal from "../../../../utils/GenericComponents/GenerateReportModal";
import { REPORT_TEMPLATES } from "@rivial-security/report-utils";
import { modules } from "@rivial-security/role-utils";

/**
 * Displays the various Export buttons for an Audit
 * @param {string} itemId - the Audit item ID
 * @param {string} organizationID - selected organization
 * @returns {JSX.Element}
 */
const AuditExportButtons = ({ itemId, organizationID }) => {
  return (
    <ButtonGroup id={"audit-export-buttons"}>
      <AuditReportButton itemId={itemId} organizationID={organizationID} />

      {/* WIP - need to implement spreadsheet export for the audit */}
      {/*<AuditSpreadsheetButton item={item}/>*/}
    </ButtonGroup>
  );
};

const AuditReportButton = ({ itemId, organizationID }) => {
  return (
    <GenerateReportModal
      itemId={itemId}
      organizationID={organizationID}
      type={REPORT_TEMPLATES.AUDIT_TEMPLATE}
      module={modules.COMPLIANCE}
    />
  );
};

const AuditSpreadsheetButton = ({ item }) => {
  const modal = useModal(
    "Generate Audit Spreadsheet",
    <AuditReport auditID={item?.id} />,
    <Button startIcon={getIcon("vscode-icons:file-type-excel2")}>Excel Export</Button>,
    { width: "85vw" },
  );

  return modal.modalButton;
};

export default withOrganizationCheck(AuditExportButtons);
