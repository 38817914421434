import React, { useState } from "react";
import { Card, CardBody, Collapse } from "reactstrap";
import BusinessRisks from "./BusinessRisks";
import { convertCamelCaseToSentence, formattedDollar } from "@rivial-security/func-utils";

const ErmRiskItem = ({ item, greatestEnterpriseRisk }) => {
  const [isOpen, setIsOpen] = useState(false);

  const isGreatestRisk = item.name === greatestEnterpriseRisk.name;

  return (
    <>
      <tr
        onClick={() => setIsOpen(!isOpen)}
        style={{
          cursor: "pointer",
          border: isOpen ? "solid 2px lightBlue" : isGreatestRisk ? "dotted 2px lightGrey" : undefined,
          // border: isOpen ? "solid 2px lightBlue" : undefined,
          padding: ".75rem 1.25rem",
          zIndex: isOpen ? 1000 : undefined,
        }}
        title="View Enterprise Risk Details"
        className={isOpen ? "shadow" : undefined}
      >
        <td>{convertCamelCaseToSentence(item.name)}</td>
        <td>{convertCamelCaseToSentence(item.greatestBusinessRisk && item.greatestBusinessRisk.name)}</td>
        <td>{formattedDollar(item.residualRisk)}</td>
        <td>{convertCamelCaseToSentence(item.riskRating)}</td>
      </tr>
      <tr style={{ display: !isOpen ? "none" : undefined }}>
        <td colSpan={4}>
          <Collapse isOpen={isOpen}>
            <Card className={isOpen ? "shadow" : undefined}>
              <CardBody>
                <BusinessRisks item={item} />
              </CardBody>
            </Card>
          </Collapse>
        </td>
      </tr>
    </>
  );
};

export default ErmRiskItem;
