"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAutomationStepsData = void 0;
const generategraphql_1 = require("@rivial-security/generategraphql");
const appsync_utils_1 = require("@rivial-security/appsync-utils");
/**
 * Populates automation steps referenced by an automation and all of its nested steps
 * @param {object} steps - retrieves all automation steps data including data for nested steps
 * @param {object} cache - cache of already retrieved resources that can be reused instead of performing a new query
 * @param {function} retrieveFromStorage - function that accepts an object key and returns the object's text value in S3
 * @returns {Promise<*[]|{downloadedReportTemplates: {}, allAutomationSteps: any}>}
 */
const getAutomationStepsData = async ({ steps, cache, retrieveFromStorage }) => {
    //Check arguments
    if (!Array.isArray(steps)) {
        return [];
    }
    // clone the automation steps array
    const allAutomationSteps = JSON.parse(JSON.stringify(steps));
    let downloadedReportTemplates = {};
    // parse all new automations and query for all nested resources that may use smart values
    const populatedSteps = [];
    for (const automationStep of allAutomationSteps) {
        let stepConfig = automationStep?.config;
        if (typeof stepConfig === "string") {
            stepConfig = JSON.parse(stepConfig);
        }
        else if (typeof stepConfig !== "object") {
            stepConfig = {};
        }
        switch (automationStep?.type) {
            case "sendNotification":
                const emailTemplateID = stepConfig?.emailTemplateID;
                if (emailTemplateID) {
                    try {
                        const emailTemplate = await (0, appsync_utils_1.GetQuery)({
                            query: getEmailTemplateQuery,
                            variables: { id: emailTemplateID },
                        });
                        automationStep.emailTemplate = emailTemplate;
                    }
                    catch (e) {
                        console.log("Was unable to retrieve the email template when checking automation", e);
                    }
                }
                break;
            case "generateReport":
                const reportTemplateID = stepConfig?.templateId;
                if (reportTemplateID) {
                    try {
                        const reportTemplate = await (0, appsync_utils_1.GetQuery)({
                            query: getReportTemplateQuery,
                            variables: { id: reportTemplateID },
                        });
                        const cacheReportTemplate = cache?.reportTemplates?.[reportTemplateID];
                        if (cacheReportTemplate) {
                            automationStep.reportTemplate = cacheReportTemplate;
                        }
                        else if (typeof retrieveFromStorage === "function") {
                            //Download the template file
                            const reportTemplateUrl = await retrieveFromStorage(reportTemplate?.key);
                            const reportTemplateResponse = await fetch(reportTemplateUrl);
                            const reportTemplateText = await reportTemplateResponse.text();
                            automationStep.reportTemplate = reportTemplateText;
                            downloadedReportTemplates[reportTemplateID] = reportTemplateText;
                        }
                        else {
                            throw Error("No cache or storage function provided to retrieve report template");
                        }
                    }
                    catch (e) {
                        console.log("Was unable to retrieve the report template when checking automation", e);
                    }
                }
                break;
            case "loop":
                //Parse the configs for each step in the loop
                const loopSteps = stepConfig?.automations;
                if (Array.isArray(loopSteps)) {
                    for (const loopStep of loopSteps) {
                        const loopStepConfig = loopStep?.config;
                        if (typeof loopStepConfig === "string") {
                            loopStep.config = JSON.parse(loopStepConfig);
                        }
                    }
                }
                //Retrieve data for each sub step
                const { allAutomationSteps: loopStepsWithData, downloadedReportTemplates: downloadedLoopReportTemplates } = await (0, exports.getAutomationStepsData)({
                    steps: loopSteps,
                    cache,
                    retrieveFromStorage,
                });
                //Update the loop config with the new steps
                stepConfig.automations = loopStepsWithData;
                //Update the list of downloaded report templates
                downloadedReportTemplates = {
                    ...downloadedReportTemplates,
                    ...downloadedLoopReportTemplates,
                };
                break;
            default:
                break;
        }
        populatedSteps.push({ ...automationStep, config: stepConfig });
    }
    return {
        allAutomationSteps: populatedSteps,
        downloadedReportTemplates,
    };
};
exports.getAutomationStepsData = getAutomationStepsData;
const getEmailTemplateQuery = (0, generategraphql_1.generateGraphql)("Email", [
    "name",
    "subject",
    "recipients",
    "replyTo",
    "body",
    "design",
    "status",
    "frequency",
    "dueDate",
    "executionARN",
    "type",
    "module",
]).getQuery;
const getReportTemplateQuery = (0, generategraphql_1.generateGraphql)("ReportTemplate", ["key", "name"]).getQuery;
