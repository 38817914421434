"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateGraphqlFields = exports.generateGraphql = void 0;
const generateListQueryGraphql_1 = require("./generateListQueryGraphql");
const generateListByQueryGraphql_1 = require("./generateListByQueryGraphql");
const generateGraphql = (typename, fields = ["id", "ownerGroup"], nestedFields, config = {}) => {
    const listNestedFields = `
    items {
      ${(0, exports.generateGraphqlFields)(fields, nestedFields, config)}
    }
    nextToken
  `;
    const listQuery = (0, generateListQueryGraphql_1.generateListQueryGraphql)({
        typename,
        nestedFields: listNestedFields,
    });
    const listByQuery = (0, generateListByQueryGraphql_1.generateListByQueryGraphql)({
        typename,
        nestedFields: listNestedFields,
        index: config,
    });
    const getQuery = `
    query Get${typename}($id: ID!) {
    get${typename}(id: $id) {
      ${(0, exports.generateGraphqlFields)(fields, nestedFields, config)}
    }
  }
  `;
    const createMutation = `
    mutation Create${typename}($input: Create${typename}Input!) {
      create${typename}(input: $input) {
        ${(0, exports.generateGraphqlFields)(fields, nestedFields, config)}
      }
    }
  `;
    const updateMutation = `
    mutation Update${typename}($input: Update${typename}Input!) {
      update${typename}(input: $input) {
        ${(0, exports.generateGraphqlFields)(fields, nestedFields, config)}
      }
    }
  `;
    const deleteMutation = `
    mutation Delete${typename}($input: Delete${typename}Input!) {
      delete${typename}(input: $input) {
        ${(0, exports.generateGraphqlFields)(fields, nestedFields, config)}
      }
    }
  `;
    const onCreate = `
    subscription OnCreate${typename} {
      onCreate${typename} {
        ${(0, exports.generateGraphqlFields)(fields, nestedFields, config)}
      }
    }
  `;
    const onUpdate = `
    subscription OnUpdate${typename} {
      onUpdate${typename} {
        ${(0, exports.generateGraphqlFields)(fields, nestedFields, config)}
      }
    }
  `;
    const onDelete = `
    subscription OnDelete${typename} {
      onDelete${typename} {
        ${(0, exports.generateGraphqlFields)(fields, nestedFields, config)}
      }
    }
  `;
    const invokeFunction = (field) => `
    query Get${typename}($id: ID!) {
    get${typename}(id: $id) {
      id
      ownerGroup
      ${field}
    }
  }
  `;
    return {
        listQuery,
        listByQuery,
        getQuery,
        createMutation,
        updateMutation,
        deleteMutation,
        onCreate,
        onUpdate,
        onDelete,
        invokeFunction,
    };
};
exports.generateGraphql = generateGraphql;
const generateGraphqlFields = (fields = [], nestedFields, config = {}) => {
    const { disableOwnerGroup = false } = config;
    const arr = disableOwnerGroup ? [...fields] : ["id", "ownerGroup", ...fields];
    return arr.map((field) => `${field} ${nestedFields?.[field] ?? ""}`).join("\n");
};
exports.generateGraphqlFields = generateGraphqlFields;
