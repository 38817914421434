import { isNonEmptyArray, tryParse } from "@rivial-security/func-utils";

import { GetQuery } from "@rivial-security/appsync-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useSetAsyncData } from "../../../../../../hooks/functional/useSetAsyncData";
import { useState } from "react";

/**
 * Retrieves the currently edited series settings in for a widget table
 * NOTE: only one series is supported for widget tables at the moment therefore grabbing the first entry.
 * @param {object} widget - the widget information with configuration settings
 * @param {*[]} dependencies - data for which  to refresh the edited series when its updated
 * @returns {{queryConfig: unknown, editedSeries: unknown}}
 */
export const useSetEditedSeriesQueryForWidgetTable = ({ widget, dependencies = [] }) => {
  const [editedSeries, setEditedSeries] = useState(null);
  const [queryConfig, setQueryConfig] = useState(undefined);

  useSetAsyncData({
    getData: async () => {
      const currentConfig = tryParse(widget?.config);
      const currentSeries = currentConfig?.series;
      let currentEditedSeries = null;

      let customQueryID;
      if (isNonEmptyArray(currentSeries)) {
        customQueryID = currentSeries[0]?.customQueryId;
        currentEditedSeries = currentSeries[0];
      }

      if (!customQueryID) {
        return null;
      }

      //Skip refresh if edited series is the same as edited series
      let customQuery = null;
      if (editedSeries?.customQueryId !== currentEditedSeries?.customQueryId) {
        //When a custom query does exist try to get its config
        const getCustomQuery = generateGraphql("CustomQuery", ["queryConfig"]).getQuery;

        customQuery = await GetQuery({
          query: getCustomQuery,
          variables: {
            id: customQueryID,
          },
        });
      }

      return {
        currentEditedSeries,
        queryConfig: tryParse(customQuery?.queryConfig),
      };
    },
    setData: ({ currentEditedSeries, queryConfig }) => {
      if (editedSeries?.customQueryId !== currentEditedSeries?.customQueryId) {
        setQueryConfig(queryConfig || null);
      }

      setEditedSeries(currentEditedSeries);
    },
    dependencies,
  });

  return {
    editedSeries,
    queryConfig,
  };
};
