import { modules, resources } from "@rivial-security/role-utils";

import KnowBe4TestRecipientDetails from "../components/KnowBe4TestRecipientDetails";
import RecipientNotes from "../customFields/RecipientNotes";
import RecipientUser from "../customFields/RecipientUser";
import { TOOL_TYPES } from "@rivial-security/integration-utils";
import { listKnowBe4PhishingTestResults } from "../graphql/getKnowBe4PhishingTestResults";
import { useDataGrid } from "../../../../../hooks/views/useDataGrid/useDataGrid";

/**
 * @description Display list of KnowBe4 Phishing Test Recipients
 * @param {string} itemId - Phishing Test ID
 * @param {string} organizationID - Organization ID
 * @param {object} cardConfig - Card configuration
 * @param {object} queryConfig - Query configuration
 * @param {object} gridConfig - Grid configuration
 * @param {object} props - The props passed to the component
 */
export const useKnowBe4RecipientDataGrid = ({
  itemId,
  organizationID,
  cardConfig = {},
  queryConfig = {},
  gridConfig = {},
  ...props
}) => {
  const module = modules.TOOLS;
  const resource = resources.TOOL;
  const toolType = TOOL_TYPES.KNOW_BE_4;

  cardConfig = {
    title: "KnowBe4 Phishing Test Recipients",
    headerIcon: "icon-present",
    ...cardConfig,
  };

  queryConfig = {
    organizationID,
    queryExternalApi: {
      toolType,
      idField: "recipient_id",
      input: {
        queryInput: {
          id: itemId,
          page: 1,
          per_page: 500,
        },
        query: listKnowBe4PhishingTestResults,
      },
    },
    ...queryConfig,
  };

  const roleConfig = {
    module,
    resource,
  };

  const fields = [
    {
      name: "user",
      minWidth: "400",
      flex: 1,
      component: <RecipientUser />,
    },
    {
      name: "opened_at",
      friendlyName: "Notes",
      width: "250",
      component: <RecipientNotes />,
    },
  ];

  gridConfig = {
    fields,
    detailsComponent: <KnowBe4TestRecipientDetails />,
    detailsTitle: "KnowBe4 Phishing Recipient Details",
    typename: "knowBe4Phishing",
    ...props,
    ...gridConfig,
  };

  const grid = useDataGrid({
    ...queryConfig,
    ...cardConfig,
    ...roleConfig,
    ...gridConfig,
  });

  return { ...grid };
};
