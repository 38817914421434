import React from "react";
import PropTypes from "prop-types";
import { useCheckPermissions } from "../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import InfoModal from "../GenericComponents/InfoModal";
import PointOfContactDetails from "../../views/OrganizationManager/PointOfContacts/components/PointOfContactDetails";
import { modules, resources } from "@rivial-security/role-utils";

/**
 * @deprecated - Use the PointOfContactField.tsx component instead
 * @description - This component is used to display a Point of Contact field multiple resources
 * @param {object} props - The props passed to the component.
 * @returns {JSX.Element}
 */
const LegacyPointOfContactField = (props) => {
  const module = modules.ORGANIZATION_MANAGER;
  const resource = resources.POINT_OF_CONTACT;

  const checkPermissionsHook = useCheckPermissions({ module, resource });

  return (
    <div>
      {props.pointOfContact
        ? `${props.pointOfContact.firstName} ${props.pointOfContact.lastName} | ${props.pointOfContact.email}`
        : "No one is assigned to this evidence"}
      {!props.disableInfoModal && checkPermissionsHook.resource.read && (
        <InfoModal body={<PointOfContactDetails item={props.pointOfContact} />} />
      )}
    </div>
  );
};

LegacyPointOfContactField.propTypes = {
  pointOfContact: PropTypes.object,
};

export default LegacyPointOfContactField;
