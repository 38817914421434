import { modules, resources } from "@rivial-security/role-utils";
import PointOfContactField from "@utils/CustomFields/PointOfContactField/PointOfContactField";
import { Assessment } from "@rivial-security/schema-types";

/**
 * Custom field component for the Assessment.pointOfContact field
 */
const AssessmentPointOfContact = ({ item }: { item?: Assessment }) => {
  const module = modules.VULNERABILITIES;
  const resource = resources.ASSESSMENT;
  const typename = "Assessment";
  const field = "pointOfContact";
  const idField = "assessmentPointOfContactId";

  return (
    <PointOfContactField
      item={item}
      module={module}
      resource={resource}
      typename={typename}
      field={field}
      idField={idField}
    />
  );
};

export default AssessmentPointOfContact;
