import React from "react";
import { useCreateSystem } from "../hooks/useCreateSystem";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * Component instance of the useCreate System hook. All props passed to useCreateSystem
 * @param {object} {...props} - props passed to useCreateSystem hook
 * @returns {JSX.Element}
 * @constructor
 */
const CreateSystem = ({ ...props }) => {
  const createSystemHook = useCreateSystem({
    ...props,
  });

  return <>{createSystemHook.display}</>;
};

export default withOrganizationCheck(CreateSystem);
