import { useEffect, useRef, useState } from "react";
import { API } from "@aws-amplify/api";
import { eventType } from "../../views/Notifications/enums/eventType";
import { listUserEvents } from "../../application/Toasts/functions/listUserEvents";

/**
 * Hook for using progress events in GraphQL
 * @param {Object} params - The parameters for the hook
 * @param {string} params.userID - The user ID of the user you want to get the progress events for
 * @param {import('../../application/Toasts/types/LayoutToastsHook')} params.layoutToastsHook - The layoutToastsHook object containing functions to manage toasts
 * @returns {void} This hook doesn't return anything directly, it manages side effects
 * @example
 * useProgressEvents({ userID: 'user123', layoutToastsHook: { addToast, updateToast } });
 */
export const useProgressEvents = ({ userID, layoutToastsHook }) => {
  const { addToast, updateToast } = layoutToastsHook;
  const latestToastMapValue = useRef({});

  const processEvent = ({ newEvent }) => {
    if (!newEvent?.id) {
      return;
    }

    const { id, inProgress, header } = newEvent || {};
    const message = header ?? (inProgress ? "Performing operation..." : "Finished Operation!");
    const toastMap = latestToastMapValue?.current;

    if (toastMap.hasOwnProperty(id)) {
      updateToast &&
        updateToast({
          id: toastMap[id],
          header: message,
          icon: inProgress ? "spinner" : "success",
        });
      return;
    }

    if (!addToast) {
      return;
    }

    const toastId = addToast({
      header: message,
      icon: inProgress ? "spinner" : "success",
    });

    const tempMap = { ...toastMap };
    tempMap[id] = toastId;
    latestToastMapValue.current = tempMap;
  };

  //Query for existing progress events and subscribe to new ones
  const [activeSubscription, setActiveSubscription] = useState(null);
  const getEvents = async () => {
    if (!userID) {
      return;
    }

    if (activeSubscription) {
      activeSubscription.unsubscribe();
      setActiveSubscription(null);
    }

    //Subscribe to any new events
    const newActiveSubscription = await API.graphql({
      query: onModifyEventByUser,
      variables: {
        userID,
      },
    }).subscribe({
      next: (eventData) => {
        const newEvent = eventData?.value?.data?.onModifyEventByUser;
        processEvent({ newEvent });
      },
    });
    setActiveSubscription(newActiveSubscription);

    //Create events for any items currently in progress
    const progressEvents = await listUserEvents({
      userID,
      eventType: eventType.PROGRESS,
      fields: ["id", "header", "inProgress"],
    });
    for (const newEvent of progressEvents) {
      if (newEvent?.inProgress === true) {
        processEvent({ newEvent });
      }
    }
  };
  useEffect(() => {
    getEvents();

    //unsubscribe from getting new events on hook dispose
    return () => {
      if (activeSubscription) {
        activeSubscription?.unsubscribe();
        setActiveSubscription(null);
      }
    };
  }, [userID]);
};

const onModifyEventByUser = /* GraphQL */ `
  subscription OnModifyEventByUser($userID: ID!) {
    onModifyEventByUser(userID: $userID) {
      id
      header
      type
      userID
      data
      inProgress
    }
  }
`;
