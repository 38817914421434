import { generateGraphql } from "@rivial-security/generategraphql";
import { GetQuery } from "../../../../utils/Functions/Graphql/GetQuery";
import { createEvidenceActivity } from "./createEvidenceActivity";
import { EVIDENCE_ACTIVITY_TYPES } from "../../../../typedefs/Compliance/Evidence/Evidence";
import { formattedName } from "@rivial-security/func-utils";

/**
 * Creates an Evidence Activity item when an Evidence frequency changes
 * @param {string} evidenceID - the db identifier of the evidence
 * @param {string} oldFrequency - the previous frequency of the evidence
 * @param {string} newFrequency - the new frequency of the evidence
 * @param {string} organizationID - the owner group of the evidence
 * @param {string} pointOfContactID - the db identifier of the pont of contact
 * @param {string} userID - the db identifier of the user
 * @returns {Promise<void>}
 */
export const onEvidenceFrequencyChange = async ({
  evidenceID,
  oldFrequency,
  newFrequency,
  organizationID,
  pointOfContactID,
  userID,
}) => {
  const { getQuery: getPointOfContact } = generateGraphql("PointOfContact", ["firstName", "lastName"]);
  const pointOfContact = await GetQuery({
    query: getPointOfContact,
    variables: { id: pointOfContactID },
  });

  await createEvidenceActivity(
    {
      type: EVIDENCE_ACTIVITY_TYPES.FREQUENCY_CHANGED,
      data: JSON.stringify({
        userID,
        pointOfContactName: formattedName({ pointOfContact }),
        oldFrequency,
        newFrequency,
      }),
    },
    evidenceID,
    organizationID,
  );
};
