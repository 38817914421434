import BooleanDisplay from "../components/BooleanDisplay";
import CustomCheckboxes from "@hooks/views/useForm/components/CustomCheckboxes";
import CustomColor from "../components/CustomColor";
import CustomColorDisplay from "../components/CustomColorDisplay";
import CustomDate from "../components/CustomDate";
import CustomDropdown from "../components/CustomDropdown";
import CustomSwitch from "../components/CustomSwitch";
import DateDisplay from "../components/DateDisplay";
import ModuleInputField from "../components/ModuleInputField";
import React from "react";
import TextAreaField from "../components/TextAreaField";
import TextDisplay from "../components/TextDisplay";
import TextField from "../components/TextField";

/**
 * The accepted input types for Generic Edit Field.
 * @type {{TEXT: string, TEXT_AREA: string, NUMERIC: string, PERCENT: string, PERCENTAGE: string, DOLLAR: string, DROPDOWN: string, SWITCH: string, DATE: string, SENTENCE: string, SINGLE_SELECT: string, COLOR: string, MODULE: string}}
 */
export const GENERIC_FIELD_TYPES = {
  CHECKBOXES: "checkboxes",
  COLOR: "color",
  DATE: "date",
  DOLLAR: "dollar",
  DROPDOWN: "dropdown",
  MODULE: "module",
  NUMERIC: "numeric",
  PERCENT: "percent", // USED FOR PERCENT INTEGERS
  PERCENTAGE: "percentage", // USED FOR PERCENT DECIMALS
  SENTENCE: "sentence",
  SINGLE_SELECT: "singleSelect",
  SWITCH: "switch",
  TEXT_AREA: "textarea",
  TEXT: "text",
};

/**
 * The available input and display components for each GENERIC_FIELD_TYPE
 */
export const GENERIC_FIELD_INPUTS = {
  [GENERIC_FIELD_TYPES.TEXT]: {
    inputComponent: <TextField />,
    displayComponent: <TextDisplay />,
  },
  [GENERIC_FIELD_TYPES.SENTENCE]: {
    inputComponent: <TextField />,
    displayComponent: <TextDisplay forceSentenceCase={true} />,
  },
  [GENERIC_FIELD_TYPES.NUMERIC]: {
    inputComponent: <TextField type={GENERIC_FIELD_TYPES.NUMERIC} />,
    displayComponent: <TextDisplay />,
  },
  // USED FOR PERCENT INTEGERS
  [GENERIC_FIELD_TYPES.PERCENT]: {
    inputComponent: <TextField type={GENERIC_FIELD_TYPES.PERCENT} />,
    displayComponent: <TextDisplay type={GENERIC_FIELD_TYPES.PERCENT} />,
  },
  // USED FOR PERCENT DECIMALS
  [GENERIC_FIELD_TYPES.PERCENTAGE]: {
    inputComponent: <TextField type={GENERIC_FIELD_TYPES.PERCENTAGE} />,
    displayComponent: <TextDisplay type={GENERIC_FIELD_TYPES.PERCENTAGE} />,
  },
  [GENERIC_FIELD_TYPES.DOLLAR]: {
    inputComponent: <TextField type={GENERIC_FIELD_TYPES.DOLLAR} />,
    displayComponent: <TextDisplay type={GENERIC_FIELD_TYPES.DOLLAR} />,
  },
  [GENERIC_FIELD_TYPES.TEXT_AREA]: {
    inputComponent: <TextAreaField />,
    displayComponent: <TextDisplay />,
  },
  [GENERIC_FIELD_TYPES.DROPDOWN]: {
    inputComponent: <CustomDropdown />,
    displayComponent: <TextDisplay forceSentenceCase={true} />,
  },
  [GENERIC_FIELD_TYPES.SINGLE_SELECT]: {
    inputComponent: <CustomDropdown />,
    displayComponent: <TextDisplay forceSentenceCase={true} />,
  },
  [GENERIC_FIELD_TYPES.SWITCH]: {
    inputComponent: <CustomSwitch />,
    displayComponent: <BooleanDisplay />,
  },
  [GENERIC_FIELD_TYPES.DATE]: {
    inputComponent: <CustomDate />,
    displayComponent: <DateDisplay />,
  },
  [GENERIC_FIELD_TYPES.COLOR]: {
    inputComponent: <CustomColor />,
    displayComponent: <CustomColorDisplay />,
  },
  [GENERIC_FIELD_TYPES.MODULE]: {
    inputComponent: <ModuleInputField />,
    displayComponent: <TextDisplay forceSentenceCase={true} />,
  },
  [GENERIC_FIELD_TYPES.CHECKBOXES]: {
    inputComponent: <CustomCheckboxes />,
    displayComponent: <TextDisplay type={GENERIC_FIELD_TYPES.CHECKBOXES} forceSentenceCase={true} />,
  },
};
