import React from "react";
import { useObservationDataGrid } from "../../Observations/hooks/useObservationDataGrid";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { deleteObservationRecommendationLink } from "../functions/deleteObservationRecommendationLink";
import { createObservationRecommendationLink } from "../../Observations/functions/createObservationRecommendationLink";
import CreateObservation from "../../Observations/components/CreateObservation";
import ObservationsDataGrid from "../../Observations/components/ObservationsDataGrid";
import { useNestedDataCard } from "../../../../hooks/views/useNestedDataCard/useNestedDataCard";
import { resources } from "@rivial-security/role-utils";

/**
 * Displays Observations for a Recommendation
 * @param {object} item - the Recommendation to show Observations for
 * @param {string} organizationID - current organization ID
 * @returns {JSX.Element}
 * @constructor
 */
const RecommendationObservations = ({ item, organizationID }) => {
  const nestedDataCard = useNestedDataCard({
    item,
    organizationID,
    field: "observations",
    connectionField: "observation",
    typename: resources.OBSERVATION,
    parentTypename: resources.RECOMMENDATION,
    grid: <ObservationsDataGrid />,
    gridHook: useObservationDataGrid,
    form: <CreateObservation />,
    createFunction: createObservationRecommendationLink,
    deleteFunction: deleteObservationRecommendationLink,
  });

  return nestedDataCard.gridDisplay;
};

export default withOrganizationCheck(RecommendationObservations);
