import UnderlinedTooltip from "../../../../utils/GenericComponents/Tooltips/UnderlinedTooltip";
import React from "react";
import { convertCamelCaseToSentence, isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Displays the 'greatestKeyRiskIndicator' field for a system with a tooltip
 * @param {object} item - system object
 * @param {number} item.greatestKeyRiskIndicator
 * @returns {JSX.Element}
 * @constructor
 */
const GreatestKeyRiskIndicator = ({ item }) => {
  return (
    <UnderlinedTooltip
      tooltip={
        "The Greatest Key Risk Indicator represents the highest Residual Risk on a single Risk category for this Information System."
      }
    >
      <span>
        {!isNullOrUndefined(item?.greatestKeyRiskIndicator)
          ? convertCamelCaseToSentence(item.greatestKeyRiskIndicator)
          : "N/A"}
      </span>
    </UnderlinedTooltip>
  );
};

export default GreatestKeyRiskIndicator;
