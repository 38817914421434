import {
  Badge,
  Button,
  ButtonGroup,
  Col,
  Collapse,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  Row,
} from "reactstrap";
import { QueryGetItem, useQueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import React, { useContext, useEffect, useState } from "react";
import { modules, resources } from "@rivial-security/role-utils";

import AccordionIcon from "../../../../utils/GenericComponents/AccordionIcon";
import CertificationName from "@views/Vendor/VendorCertification/components/CertificationName";
import { DeleteVendorControlDocumentLink } from "../../VendorSubControls/functions/DeleteVendorControlDocumentLink";
import { DisabledWrapper } from "../../../../utils/GenericComponents/DisabledWrapper";
import { InfoLogger } from "@utils/EventLogger";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { LoadingSpinner } from "../../../../utils/LoadingComponents/LoadingSpinner";
import MuiItemPreviewButton from "../../../../utils/GenericComponents/buttons/MuiItemPreviewButton";
import PermissionsOverlay from "../../../../utils/Overlays/PermissionsOverlay";
import { Storage } from "@aws-amplify/storage";
import VendorControlCategoryNotes from "./VendorControlCategoryNotes";
import { WorkflowContext } from "../../../../hooks/views/useWorkflow";
import { createVendorControlDocumentLink } from "../../VendorSubControls/functions/createVendorControlDocumentLink";
import { generateGraphql } from "@rivial-security/generategraphql";
import { getCategoryCompletion } from "../functions/getCategoryCompletion";
import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useDataCard } from "../../../../hooks/views/useDataCard";
import useDidMountEffect from "../../../../hooks/functional/useDidMountEffect";
import { useForm } from "../../../../hooks/views/useForm/useForm";
import { useStateEffect } from "../../../../hooks/functional/useStateEffect";
import { useVendorCompletionBadges } from "../hooks/useVendorCompletionBadges";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * @typedef {object} VendorControlCategory
 *
 * @property {string} id
 * @property {string} name
 * @property {VendorSubControl[]} subControls
 */

/**
 * @typedef {object} VendorSubControl
 *
 * @property {string} id
 * @property {string} name
 * @property {string} statementNumber
 * @property {string} vendorControlCategoryID
 * @property {VendorControlCategory} vendorControlCategory
 * @property {boolean} inPlace
 * @property {boolean} audited
 *
 */

/**
 * @typedef {object} EvaluateControlsHook
 */

/**
 * Allows the user to evaluate a set of controls for this Vendor Review.
 * @param {object} item - the vendor review data object with at least id property
 * @param {string} module - the platform module for role checking
 * @param {string} resource - the platform resource for role checking
 * @param {boolean} disableRoleChecking - if TRUE will not do a role check
 * @param {number} resetIndex - a number that will reset the component when it changes
 * @param {string} organizationID - the organization the vendor review is associated with
 *
 * @returns {EvaluateControlsHook}
 */
const EvaluateControls = ({
  item: vendorReview,
  module,
  resource,
  disableRoleChecking,
  resetIndex,
  organizationID,
}) => {
  /**
   * Get control categories from Query initially.
   * This object is then handled in state as a single source of truth for the workflow.
   *
   * @type {VendorControlCategory[]}
   */
  const [controlCategories, setControlCategories] = useState([]);

  /**
   * Updates a VendorSubControl 'inPlace' and/or 'audited' fields
   * @param {VendorSubControl} subControl
   * @param {string} subControl.id
   * @param {boolean} subControl.inPlace
   * @param {boolean} subControl.audited
   */
  const updateSubControl = ({ id, inPlace, audited }) => {
    const { updateMutation } = generateGraphql("VendorSubControl", ["inPlace", "audited"]);

    const tempControlCategories = [];

    ItemMutation(updateMutation, { id, inPlace, audited }).then((newItem) => {
      for (const controlCategory of controlCategories) {
        for (const subControl of controlCategory.subControls.items) {
          if (subControl.id === id) {
            if (inPlace !== null && inPlace !== undefined) {
              subControl.inPlace = inPlace;
            }
            if (audited !== null && audited !== undefined) {
              subControl.audited = audited;
            }
          }
        }
        tempControlCategories.push({ ...controlCategory });
      }
      setControlCategories([...tempControlCategories]);
    });
  };

  /**
   * Replaces existing subcontrols of one control category in the state (not the DB)
   * @param {VendorSubControl[]} newSubControls - the sub control items for a particular category (all existing sub controls will be replaced with these)
   * @param {number} categoryIndex - the category to modify in the context
   */
  const updateControlCategory = (newSubControls, categoryIndex) => {
    const tempControlCategories = [...controlCategories];
    if (tempControlCategories.length > categoryIndex) {
      tempControlCategories[categoryIndex].subControls.items = [...newSubControls];
    }

    setControlCategories([...tempControlCategories]);
  };

  const queryHook = useQueryGetItem({
    query: generateGraphql("VendorReview", ["controlCategories"], {
      controlCategories: `(limit: 100) {
        items {
          id
          ownerGroup
          name
            subControls (limit: 100) {
              items {
                id
                name
                statementNumber
                ownerGroup
                inPlace
                audited
                  documentLinks (limit: 100) {
                  items {
                  id
                  document {
                    id
                    name
                    avStatus
                    lastAVCheck
                    createdAt
                    file {
                      bucket
                      region
                      key
                    }
                    vendorCertification {
                      id
                      name
                    }
                  }
                 }
                }
              }
            }
          }
        }`,
    }).getQuery,
    itemId: vendorReview.id,
    disableRoleChecking: true,
  });

  useEffect(() => {
    if (queryHook.item) {
      setControlCategories(queryHook.item.controlCategories.items);
    }
  }, [queryHook.item]);

  const context = useContext(WorkflowContext);

  useEffect(() => {
    if (controlCategories) {
      context.addToContext("controlCategories", controlCategories);
      context.addToContext("updateControlCategory", updateControlCategory);
    }
  }, [controlCategories]);

  const [isOpen, setIsOpen] = useState(null);
  const controlCategoryBadges = useVendorCompletionBadges({
    module,
    resource,
    disableRoleChecking,
  });
  const getBadges = (controlCategory, index) => {
    const auditedStatus = getCategoryCompletion("audited", controlCategory);
    const inPlaceStatus = getCategoryCompletion("inPlace", controlCategory);
    return (
      <>
        {controlCategoryBadges.getBadge(inPlaceStatus, "in Place", index)}{" "}
        {controlCategoryBadges.getBadge(auditedStatus, "Audited", index)}
      </>
    );
  };

  //In case external reset index is triggered get the updated data
  useDidMountEffect(() => {
    if (queryHook?.reset) {
      queryHook?.reset();
    }
  }, [resetIndex]);

  return (
    <div>
      <div className="e-card" id="basic" style={{ overflow: "visible" }}>
        <div className="e-card-content" style={{ overflow: "visible" }}>
          <div className="dashboard-title e-card-title">Evaluate Controls</div>
          <hr />
          {queryHook.isLoading ? (
            <LoadingSpinner loadingMessage=" Loading Vendor Control Categories.. " />
          ) : (
            <ListGroup>
              {controlCategories
                .sort(({ name: nameA }, { name: nameB }) => {
                  const x = nameA.split(" ")[0];
                  const y = nameB.split(" ")[0];

                  return x - y;
                })
                .map((controlCategory, index) => (
                  <ListGroupItem key={`listGroupItem${controlCategory.id}`}>
                    <ListGroupItemHeading
                      onClick={() => {
                        if (isOpen !== controlCategory.id) {
                          setIsOpen(controlCategory.id);
                        } else {
                          setIsOpen(null);
                        }
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {controlCategory.name}{" "}
                      <span style={{ marginLeft: "1.0em" }}>{getBadges(controlCategory, index)}</span>
                      <AccordionIcon isOpen={isOpen === controlCategory.id} />
                    </ListGroupItemHeading>
                    <Collapse isOpen={isOpen === controlCategory.id}>
                      {isOpen === controlCategory.id && (
                        <Content
                          controlCategories={[...controlCategories]}
                          controlCategory={{ ...controlCategory }}
                          updateSubControl={updateSubControl}
                          resetFunction={queryHook.reset}
                          module={module}
                          resource={resource}
                          disableRoleChecking={disableRoleChecking}
                          organizationID={organizationID}
                        />
                      )}
                    </Collapse>
                  </ListGroupItem>
                ))}
            </ListGroup>
          )}
        </div>
      </div>
    </div>
  );
};

/**
 * The content component
 * @param {VendorControlCategory} controlCategory - the Vendor Control category.
 * @param {function} updateSubControl
 * @param {function} resetFunction
 * @param module
 * @param resource
 * @param disableRoleChecking
 * @constructor
 */
const Content = ({
  controlCategory,
  updateSubControl,
  resetFunction,
  module = modules.VENDORS,
  resource = resources.VENDOR_REVIEW,
  disableRoleChecking,
  organizationID,
}) => {
  const checkPermissions = useCheckPermissions({
    module,
    resource,
    disableRoleChecking,
  });

  const [controls] = useStateEffect([], [controlCategory], () => {
    if (controlCategory) {
      return controlCategory.subControls && controlCategory.subControls.items ? controlCategory.subControls.items : [];
    }
  });

  /**
   * Handles the DB update for a VendorSubControl 'inPlace' and/or 'audited' fields
   * @param {VendorSubControl} subControl
   * @param {object} input
   * @returns {Promise<*>}
   */
  const handleMutation = async (subControl, input) => {
    return await ItemMutation(generateGraphql("VendorSubControl", ["inPlace, audited"]), {
      id: subControl.id,
      ...input,
    });
  };

  /**
   * Sets all Controls to 'inPlace'
   */
  const setAllInPlace = () => {
    const promises = [];
    for (const control of controls) {
      updateSubControl({ id: control.id, inPlace: true });
      promises.push(handleMutation(control, { inPlace: true }));
    }

    Promise.allSettled(promises).then(() => {
      InfoLogger("finished setting all controls in place");
    });
  };

  /**
   * Sets all Controls to 'inPlace' and 'audited'
   */
  const setAllAudited = () => {
    const promises = [];
    for (const control of controls) {
      updateSubControl({ id: control.id, inPlace: true, audited: true });
      promises.push(handleMutation(control, { inPlace: true, audited: true }));
    }
    Promise.allSettled(promises).then(() => {
      InfoLogger("finished setting all controls in place and audited");
    });
  };

  /**
   * Sets a single Control to 'inPlace'
   * @param {VendorSubControl} subControl
   * @returns {Promise<*>}
   */
  const setInPlace = async (subControl) => {
    updateSubControl({ id: subControl.id, inPlace: true });
    return await handleMutation(subControl, { inPlace: true });
  };

  /**
   * Sets a single Control to 'audited'
   * @param {VendorSubControl} subControl
   * @returns {Promise<*>}
   */
  const setAudited = async (subControl) => {
    updateSubControl({ id: subControl.id, inPlace: true, audited: true });
    return await handleMutation(subControl, { inPlace: true, audited: true });
  };

  const context = useContext(WorkflowContext);

  const [documentList, setDocumentList] = useState([]);

  useEffect(() => {
    if (context && context.documents && Array.isArray(context.documents)) {
      setDocumentList(context.documents);
    } else {
      if (context && context.vendorReview && context.vendorReview.id) {
        const { getQuery } = generateGraphql("VendorReview", ["documents"], {
          documents: `(limit: 100) {
            items {
              id
              name
              avStatus
              lastAVCheck
              createdAt
              file {
                bucket
                region
                key
              }
              vendorCertification {
                id
                name
              }
            }
            nextToken
          }`,
        });

        QueryGetItem({ query: getQuery, itemId: context.vendorReview.id }).then((item) => {
          const documents = item?.documents?.items;
          if (Array.isArray(documents)) {
            setDocumentList(documents);
          }
        });
      }
    }
  }, [context]);

  /**
   *  Add these functions to the workflow context
   */
  useEffect(() => {
    if (context && context.addToContext) {
      context.addToContext("setInPlace", setInPlace);
      context.addToContext("setAudited", setAudited);
      context.addToContext("setAllInPlace", setAllInPlace);
      context.addToContext("setAllAudited", setAllAudited);
    }
  }, []);

  return (
    <>
      <hr />
      <DisabledWrapper message="" isDisabled={context?.readOnly} greyscalePercentage={0} isInteractable={false}>
        <Row>
          <Col sm={8}>
            <ListGroup>
              {controls
                .sort((a, b) => a.statementNumber - b.statementNumber)
                .map((item) => {
                  return (
                    <SubControlItem
                      key={`subControl${item.id}`}
                      item={{ ...item }}
                      updateSubControl={updateSubControl}
                      documentList={documentList}
                      resetFunction={resetFunction}
                      module={module}
                      resource={resource}
                      disableRoleChecking={disableRoleChecking}
                      readOnly={context?.readOnly}
                      organizationID={organizationID}
                    />
                  );
                })}
            </ListGroup>
          </Col>
          <Col sm={4}>
            {context?.readOnly !== true && (
              <Row>
                <Button
                  disabled={!checkPermissions.resource.update}
                  color="ghost-primary"
                  size="sm"
                  onClick={() => setAllInPlace()}
                >
                  <i className="icon-check" /> All In Place
                </Button>
                <Button
                  disabled={!checkPermissions.resource.update}
                  color="ghost-success"
                  size="sm"
                  onClick={() => setAllAudited()}
                >
                  <i className="icon-check" /> All Audited
                </Button>
              </Row>
            )}
            {context?.readOnly !== true && <hr />}
            <Row>
              <Col>
                <div style={{ width: "100%" }}>
                  <VendorControlCategoryNotes
                    item={controlCategory}
                    module={module}
                    resource={resource}
                    disableRoleChecking={disableRoleChecking}
                    disableEdits={context?.readOnly}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </DisabledWrapper>
    </>
  );
};

/**
 * Displays a single Vendor Control Category Sub Control.
 * Allows the User to toggle the 'inPlace' and 'audited' fields on VendorSubControl.
 *
 * Also displays and allows the User to attach relevant documents to the VendorSubControl.
 *
 * @param {VendorSubControl} item - the VendorSubControl object
 * @param {function} updateSubControl - function to perform the control mutation
 * @param {object[]} documentList - list of documents of VendorReview
 * @param {string} module - the platform resource for role checks
 * @param {string} resource - the platform resource for role checks
 * @param {boolean} disableRoleChecking - if TRUE will not check for proper user permissions on the front-end
 * @param {function} resetFunction - callback to refresh the dat in the item when it has been updated
 * @param {boolean} readOnly - if TRUE control status won't be editable
 * @return {JSX.Element}
 */
const SubControlItem = ({
  item = {},
  updateSubControl,
  documentList = [],
  module = modules.VENDORS,
  resource = resources.VENDOR_REVIEW,
  disableRoleChecking,
  resetFunction,
  readOnly = false,
  organizationID,
}) => {
  const [controlDocumentsList, setControlDocumentsList] = useState([]);

  const checkPermissions = useCheckPermissions({
    module,
    resource,
    disableRoleChecking,
  });

  const inPlaceForm = useForm({
    item,
    module,
    resource,
    disableSubmitButton: true,
    disableResetButton: true,
    fieldConfig: {
      inPlace: {
        inputType: "switch",
        switchConfig: {
          label: "In Place",
        },
        disabled: !checkPermissions.resource.update || readOnly,
        onChangeFunction: (value) => {
          const input = { id: item.id, inPlace: value.inPlace };
          if (value.inPlace === false) {
            input.audited = false;
          }
          updateSubControl({ ...input });
        },
      },
    },
  });

  const auditedForm = useForm({
    item,
    module,
    resource,
    disableSubmitButton: true,
    disableResetButton: true,
    fieldConfig: {
      audited: {
        inputType: "switch",
        switchConfig: {
          label: "Audited",
        },
        style: {
          margin: "0",
        },
        disabled: !checkPermissions.resource.update || readOnly,
        onChangeFunction: (value) => {
          const input = { id: item.id, audited: value.audited };
          if (value.audited === true) {
            input.inPlace = true;
          }
          updateSubControl({ ...input });
        },
      },
    },
  });

  /**
   * Update these switches if the subControl gets updated elsewhere
   */
  useEffect(() => {
    if (item) {
      if (inPlaceForm.input.inPlace !== item.inPlace) {
        inPlaceForm.setInput({ ...inPlaceForm.input, inPlace: item.inPlace });
      }
      if (auditedForm.input.audited !== item.audited) {
        auditedForm.setInput({ ...auditedForm.input, audited: item.audited });
      }
    }
  }, [item]);

  const AttachDocumentModalBody = ({ toggleModal, module, resource, disableRoleChecking }) => {
    const [selectedDocuments, setSelectedDocuments] = useState([]);

    const isSubmitEnabled = selectedDocuments && selectedDocuments.length > 0;

    const tableHook = useDataCard({
      header: "Documents List",
      data: documentList,
      customFields: [
        {
          field: "vendorCertification",
          component: <CertificationName />,
        },
      ],
      fields: ["vendorCertification", "name"],
      fieldNameDictionary: {
        id: "ID",
        name: "Name",
      },
      config: {
        showSelectBoxes: true,
        selectionType: "selectBoxes",
      },
      disableRoleChecking: true,
    });

    useEffect(() => {
      if (documentList) {
        tableHook.setData(documentList);
      }
    }, [documentList]);

    useEffect(() => {
      if (tableHook.selectedItems) {
        setSelectedDocuments(tableHook.selectedItems);
      }
    }, [tableHook.selectedItems]);

    return (
      <>
        <PermissionsOverlay
          module={module}
          resource={resource}
          operationType={"update"}
          disableRoleChecking={disableRoleChecking}
        >
          <div>
            {tableHook.display}
            <ButtonGroup>
              <Button
                disabled={!isSubmitEnabled}
                onClick={() =>
                  createVendorControlDocumentLink({
                    control: item,
                    documents: selectedDocuments,
                    organizationID: "vendor-reviews",
                  }).then(() => {
                    toggleModal?.();
                    resetFunction?.();
                  })
                }
                size="sm"
                color="primary"
              >
                <i className="fa fa-dot-circle-o" />
                Submit
              </Button>
              <Button size="sm" color="danger" onClick={() => toggleModal && toggleModal()}>
                <i className="fa fa-ban" />
                Cancel
              </Button>
            </ButtonGroup>
          </div>
        </PermissionsOverlay>
      </>
    );
  };

  useEffect(() => {
    if (item && item.documentLinks && item.documentLinks.items && Array.isArray(item.documentLinks.items)) {
      const documentListLocal = [];

      for (const link of item.documentLinks.items) {
        if (link && link.document && link.document.id) {
          documentListLocal.push(link.document);
        }
      }
      setControlDocumentsList(documentListLocal);
    }
  }, [item]);

  const documentDeleteFunction = async (document) => {
    if (item && item.documentLinks && item.documentLinks.items && Array.isArray(item.documentLinks.items)) {
      for (const link of item.documentLinks.items) {
        if (link && link.document && link.document.id && document && document.id && link.document.id === document.id)
          await DeleteVendorControlDocumentLink(link);
      }
    }
  };

  const documentsTableHook = useDataCard({
    header: "Documents List",
    data: controlDocumentsList,
    options: ["delete"],
    customFields: [
      {
        field: "vendorCertification",
        component: <CertificationName />,
      },
      {
        field: "preview",
        component: (
          <MuiItemPreviewButton
            organizationID={organizationID}
            downloadFunction={async ({ key }) => {
              return await Storage.get(key, {
                expires: 300,
              });
            }}
          />
        ),
      },
    ],
    fields: ["vendorCertification", "name", "preview"],
    fieldNameDictionary: {
      id: "ID",
      name: "Name",
      vendorCertification: "Cert",
    },
    createResourceComponent: (
      <AttachDocumentModalBody module={module} resource={resource} disableRoleChecking={disableRoleChecking} />
    ),
    deleteFunction: documentDeleteFunction,
    resetFunction,
    disableRoleChecking: true,
  });

  useEffect(() => {
    if (controlDocumentsList) {
      documentsTableHook.setData(controlDocumentsList);
    }
  }, [controlDocumentsList]);

  const [showDocuments, setShowDocuments] = useState(false);

  return (
    <ListGroupItem key={`vendor-sub-control-${item && item.id}`}>
      {`${item.statementNumber} - ${item.name}`}
      <hr />
      <Row>
        <Col>
          <ButtonGroup>
            <span key={`inplaceform${item.statementNumber}`} id={`inplaceform${item.statementNumber}`}>
              {inPlaceForm.display}
            </span>
            <span
              key={`auditedForm${item.statementNumber}`}
              id={`auditedForm${item.statementNumber}`}
              style={{ marginLeft: "1em" }}
            >
              {auditedForm.display}
            </span>
          </ButtonGroup>
        </Col>
        <Col>
          <Button
            color="ghost-link"
            className="btn-pill"
            style={{ marginBottom: "1rem" }}
            onClick={() => {
              setShowDocuments(!showDocuments);
            }}
          >
            Relevant Documents <Badge pill>{controlDocumentsList && controlDocumentsList.length}</Badge>
          </Button>
          <Collapse isOpen={showDocuments}>{documentsTableHook.display}</Collapse>
        </Col>
      </Row>
    </ListGroupItem>
  );
};

export default withOrganizationCheck(EvaluateControls);
