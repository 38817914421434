import { updateMutation } from "../graphql/customDashboardGraphql";
import { v4 as uuid } from "uuid";
import { ItemMutation } from "../../../utils/Functions/Graphql/ItemMutation";
import { getCurrentDashboard } from "./getCurrentDashboard";
import { getCurrentDashboardLayout } from "./getCurrentDashboardLayout";

/**
 * Adds a selected widget to the current dashboard page.
 * The widget is added to the bottom of the page.
 *
 * @param {string} dashboardID - The ID of the dashboard to add the widget to
 * @param {number} [layoutIndex = 0] - The index of the layout to add the widget to
 * @param {string} widgetID - The ID of the widget to add
 * @param {number} [width = 6] - The width of the widget
 * @param {number} [height = 6] - The height of the widget
 * @param {function} setItemQuery - The setItemQuery function from the useItemQuery hook
 * @returns {Promise<void>}
 */
export const addWidgetToPage = async ({
  dashboardID,
  layoutIndex = 0,
  widgetID,
  width = 6,
  height = 6,
  setItemQuery,
}) => {
  const currentDashboard = await getCurrentDashboard({ itemId: dashboardID });
  const currentDashboardLayout = getCurrentDashboardLayout({
    currentDashboard,
    layoutIndex,
  });

  const updatedLayoutElements = [
    ...(currentDashboardLayout?.elements || []),
    {
      id: uuid(),
      x: (currentDashboardLayout?.elements?.length * 2) % 12 || 0,
      y: 5,
      width,
      height,
      widgetID,
    },
  ];

  if (currentDashboard?.layouts?.[layoutIndex]) {
    currentDashboard.layouts[layoutIndex].elements = updatedLayoutElements;
  }

  const updatedItem = await ItemMutation(updateMutation, {
    id: dashboardID,
    layouts: currentDashboard.layouts,
  });

  if (typeof setItemQuery === "function") {
    setItemQuery({ ...updatedItem });
  }
};
