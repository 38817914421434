import { generateGraphql } from "@rivial-security/generategraphql";
import { useDetailsCard } from "../../../../../hooks/views/useDetailsCardV2";
import { modules, resources } from "@rivial-security/role-utils";
import { getProceduresNestedFields } from "../../../PlaybookBuilder/functions/getProceduresNestedFields";
import React from "react";
import PlaybookProcedureTabs from "../components/PlaybookProcedureTabs";

export const usePlaybookDetails = ({ itemId, organizationID, tableDisplay, ...props }) => {
  const module = modules.INCIDENT_RESPONSE;
  const resource = resources.PLAYBOOK;
  const typename = "Playbook";
  const fields = ["name", "description", "procedures"];
  const { updateMutation, getQuery, onUpdate } = generateGraphql(typename, fields, getProceduresNestedFields());

  const detailsConfig = {
    fields,
    customFields: [
      {
        field: "procedures",
        component: (
          <PlaybookProcedureTabs
            typename={"Playbook"}
            orientation="horizontal"
            disableEdits={true}
            disableCheckboxEdits={true}
          />
        ),
      },
    ],
    updateMutation: updateMutation,
  };

  const queryConfig = {
    query: getQuery,
    itemId: itemId,
    onUpdateItemSubscription: onUpdate,
  };

  return useDetailsCard({
    queryConfig,
    detailsConfig,
    config: { header: "Playbook Details" },
    module,
    resource,
    tableDisplay,
    ...props,
  });
};
