"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatTableWidgetData = void 0;
const applyWidgetFieldTransforms_1 = require("./applyWidgetFieldTransforms/applyWidgetFieldTransforms");
const lodash_1 = require("lodash");
const applyTableWidgetFilters_1 = require("./applyTableWidgetFilters");
const applyTableWidgetSorting_1 = require("./applyTableWidgetSorting");
/**
 * Sorts, filters and transforms the data that is going into a table widget in all contexts (email, web and reports)
 * @param {object} seriesItem - settings containing information on how to modify the data
 * @param {object[]} data - the data to be modified
 * @param {object} automationContext - object with context to use for smart values
 * @param {object} automationContext.item - the triggering item, if automation was triggered by a resource create/update
 * @param {object} automationContext.steps - result objects from previous automation steps that are referenced in the email template
 * @param {object} automationContext.loop - the loop object, if this email is being sent from a loop
 * @param {object} automationContext.webhookData - the webhook data from the incoming request, if automation was triggered by a webhook
 * @returns {*[]}
 */
const formatTableWidgetData = ({ seriesItem, data, automationContext }) => {
    let newData = (0, lodash_1.cloneDeep)(data);
    // - sorting
    newData = (0, applyTableWidgetSorting_1.applyTableWidgetSorting)({ data: newData, seriesItem });
    console.log("[formatTableWidgetData] After applyTableWidgetSorting - ", { newData });
    // - field transforms
    newData = (0, applyWidgetFieldTransforms_1.applyWidgetFieldTransforms)({ data: newData, seriesItem });
    console.log("[formatTableWidgetData] After applyWidgetFieldTransforms - ", { newData });
    // - column filter
    newData = (0, applyTableWidgetFilters_1.applyTableWidgetFilters)({ data: newData, seriesItem, automationContext });
    console.log("[formatTableWidgetData] After applyTableWidgetFilters - ", { newData });
    return newData;
};
exports.formatTableWidgetData = formatTableWidgetData;
