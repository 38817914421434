import Loader from "../../../../../utils/LoadingComponents/Loader";
import { Input, UncontrolledTooltip } from "reactstrap";
import React, { useContext } from "react";
import { UIContext } from "../../../../../utils/Context/UIContext";
import { usePreferences } from "../../../../../hooks/views/usePreferences/usePreferences";
import { onChangeLoaderStyle } from "../functions/onChangeLoaderStyle";
import { useCard } from "../../../../../hooks/views/useCard";

/**
 * @description Interface Loader Style Component
 * @return {JSX.Element}
 * @constructor
 */
const InterfaceLoaderStyle = () => {
  const { addToast, loaderStyle, setLoaderStyle } = useContext(UIContext);

  const preferences = usePreferences();

  const card = useCard({
    id: "loader-style",
    title: (
      <>
        <h4 style={{ display: "inline" }}>Loader Style</h4>{" "}
        <i id="loader-style-icon-question" className="icon-question" />
        <UncontrolledTooltip placement="right" target="loader-style-icon-question">
          Select a loader style from the dropdown below
        </UncontrolledTooltip>
        <h6>Current:</h6>
        <Loader />
      </>
    ),
    body: (
      <>
        <Input
          type="select"
          value={loaderStyle}
          onChange={(e) =>
            onChangeLoaderStyle({
              value: e.target.value,
              preferences,
              addToast,
              setLoaderStyle,
            })
          }
        >
          <option value="rivialLoader">Rivial Logo</option>
          <option value="pingPongLoader">Ping Pong</option>
          <option value="spinnerLoader">Spinner</option>
        </Input>
      </>
    ),
  });

  return card?.display;
};

export default InterfaceLoaderStyle;
