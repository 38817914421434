import React, { useEffect, useState } from "react";
import { getLowerLossAmount } from "@rivial-security/risk-calc-utils";
import CustomTooltip from "../../../../../../../utils/GenericComponents/CustomTooltip";
import { formattedDollar } from "@rivial-security/func-utils";
import { useGUID } from "../../../../../../../hooks/functional/useGUID";

/**
 * Gets and displays the lower confidence interval for a KRI
 * @param system
 * @param organizationID
 * @param systemRiskLink
 * @returns {{lowerLossAmount: number, system: *, display: JSX.Element}}
 */
export const useLowerLossAmount = (system, organizationID, systemRiskLink) => {
  const [lowerLossAmount, setLowerLossAmount] = useState(0);

  useEffect(() => {
    if (system && systemRiskLink) {
      const lowerLossAmount = getLowerLossAmount(system, systemRiskLink);
      setLowerLossAmount(lowerLossAmount);
    }
  }, [system, systemRiskLink]);

  const [guid] = useGUID();

  const display = (
    <>
      {formattedDollar(lowerLossAmount)}
      <CustomTooltip
        targetId={guid}
        tooltip={`Lower Risk Amount is automatically calculated from the lowest Impact rating based on Information Assets`}
      />
    </>
  );

  return {
    display,
    lowerLossAmount,
    system,
  };
};
