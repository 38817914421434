import React, { useState } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { handleIcon } from "../../../../../../../../utils/Functions/Icon/getIcon";
import { resourceDefinitions } from "../../../../../../../../definitions/constants/resourceDefinitions";
import { CONTEXT_TYPE } from "@rivial-security/widget-utils";

/**
 * Displays the widget icon and title while allowing to switch the type of preview being shown
 * @param {string} title - table header title for the widget
 * @param {string} typename - the typename of the resources being displayed in the table
 * @param {function} onSelectView - callback for when the user selects a view
 * @param {object} defaultView - the default view to display
 * @param {string} [singleViewType] - optionally sets the one and only view that the preview allows to display
 * @returns {JSX.Element}
 */
const TableWidgetViewSwitchTitle = ({
  title,
  typename,
  onSelectView,
  defaultView = CONTEXT_TYPE.WEB,
  singleViewType,
}) => {
  const [view, setView] = useState(defaultView);

  const icon = resourceDefinitions[typename]?.icon || "icon-list";

  return (
    <div style={{ display: "inline" }}>
      <h4 style={{ display: "inline" }} className="dashboard-title">
        {icon && <span style={{ marginRight: "0.5em" }}>{handleIcon(icon)}</span>}
        {title || "Data Preview"}
      </h4>
      {!singleViewType && (
        <ToggleButtonGroup
          color="primary"
          exclusive
          value={view}
          onChange={(event, contextType) => {
            if (!contextType) return;
            setView(contextType);
            if (typeof onSelectView === "function") {
              onSelectView(contextType);
            }
          }}
          aria-label="View Type Selector"
          style={{ marginLeft: "1em", display: "inline" }}
        >
          {[CONTEXT_TYPE.WEB, CONTEXT_TYPE.REPORT, CONTEXT_TYPE.EMAIL].map((contextType) => {
            return <ToggleButton value={contextType}>{convertCamelCaseToSentence(contextType)}</ToggleButton>;
          })}
        </ToggleButtonGroup>
      )}
    </div>
  );
};

export default TableWidgetViewSwitchTitle;
