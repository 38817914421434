"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkAutomationStepsAreValid = void 0;
const getAutomationSmartValues_1 = require("../preprocess/getAutomationSmartValues");
const filterMappedSmartValues_1 = require("../preprocess/filterMappedSmartValues");
const smartValueFilters_1 = require("../../constants/smartValueFilters");
const getSmartValueAdjacencyList_1 = require("../preprocess/getSmartValueAdjacencyList");
const checkForMissingDependencyInAdjacencyList_1 = require("./checkForMissingDependencyInAdjacencyList");
const checkForLoopInAdjacencyList_1 = require("./checkForLoopInAdjacencyList");
const checkForAutomationTriggeringItself_1 = require("./checkForAutomationTriggeringItself");
const checkForStepNameUniqueness_1 = require("./checkForStepNameUniqueness");
/**
 * Checks if a valid graph is formed from present automation steps
 * @param {object} automation - the automation data with all referenced smart values
 * @param {object[]} ancestorSteps - the preceding step data of the current automation steps (if any)
 * @param {object} log - function to log messages and errors
 * @returns {[{message: string, status: string}]} - status response, can be displayed in mui alert
 */
const checkAutomationStepsAreValid = ({ automation, ancestorSteps, log }) => {
    try {
        if (!automation) {
            throw new Error("Could not check automation steps because the automation data is invalid!");
        }
        //Determine the adjacency list of the automation steps
        const mappedSmartValues = (0, getAutomationSmartValues_1.getAutomationSmartValues)({ automation });
        const filteredSmartValues = (0, filterMappedSmartValues_1.filterMappedSmartValues)({
            mappedSmartValues,
            filters: [smartValueFilters_1.smartValueFilters.onlyAutomationSteps],
        });
        const adjacencyList = (0, getSmartValueAdjacencyList_1.getSmartValueAdjacencyList)({ mappedSmartValues: filteredSmartValues });
        if (!adjacencyList) {
            throw new Error("Could not check automation steps because the adjacency list is invalid!");
        }
        //Perform checks on the built graph
        const results = [];
        results.push((0, checkForMissingDependencyInAdjacencyList_1.checkForMissingDependencyInAdjacencyList)({ adjacencyList, automation, ancestorSteps, log }));
        results.push((0, checkForLoopInAdjacencyList_1.checkForLoopInAdjacencyList)({ adjacencyList }));
        results.push((0, checkForAutomationTriggeringItself_1.checkForAutomationTriggeringItself)({ automation }));
        results.push((0, checkForStepNameUniqueness_1.checkForStepNameUniqueness)({ automation }));
        //Check if any results yielded errors, return the error one at a time for the user to fix them
        const errorResult = results.find((result) => result.status === "error");
        if (errorResult) {
            return [errorResult];
        }
        //If no errors, return the warning and informational results
        return results;
    }
    catch (e) {
        return [
            {
                status: "error",
                message: "An internal error occurred while checking the automation steps! Please submit a bug report.",
                exception: e,
            },
        ];
    }
};
exports.checkAutomationStepsAreValid = checkAutomationStepsAreValid;
