import { ComponentPropsWithoutRef, ReactNode, ReactElement } from "react";

import Spinner from "@components/Ions/motion/components/Spinner";
import cx from "classnames";
import styles from "./Button.module.scss";

export interface ButtonProps extends ComponentPropsWithoutRef<"button"> {
  /** The variant of the button (example: `primary` or `link`). */
  variant?: ButtonVariant;
  /** The SVG icon to display on the left side of the text.  Set this prop for buttons that should only display an icon without text. */
  startIcon?: ReactNode;
  /** The SVG icon to display on the right side of the text. */
  endIcon?: ReactNode;
  /** When true, the button will render with a spinner and block calling the `onClick` event handler function. */
  isLoading?: boolean;
}

export enum ButtonVariant {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  TEXT = "text",
  LINK = "link",
  ALERT = "alert",
}

/**
 * A button component is an interactive UI element used to trigger actions or navigate within an application,
 * providing a clear and intuitive way for users to interact with the system.
 */
const Button = ({
  children,
  disabled,
  variant = ButtonVariant.PRIMARY,
  startIcon,
  endIcon,
  id,
  isLoading = false,
  className,
  ...rest
}: ButtonProps): ReactElement => {
  const classNames = cx(
    styles[variant],
    {
      [styles["icon-with-text-padding"]!]:
        children && [ButtonVariant.PRIMARY, ButtonVariant.SECONDARY, ButtonVariant.ALERT].includes(variant),
    },
    { [styles["button-icon"]!]: startIcon ?? endIcon },
    { [styles["button-text"]!]: !(startIcon ?? endIcon) },
    { [styles.loading!]: isLoading },
    className,
  );

  if (isLoading) {
    if (startIcon) {
      startIcon = <Spinner />;
    } else if (endIcon) {
      endIcon = <Spinner />;
    } else {
      children = (
        <div className={styles["icon-only-spinner-container"]}>
          <div className={styles.hidden}>{children}</div>
          <div className={styles["icon-only-spinner"]}>
            <Spinner />
          </div>
        </div>
      );
    }
  }

  return (
    <button
      className={classNames}
      disabled={disabled ?? isLoading}
      aria-disabled={disabled ?? isLoading}
      aria-busy={isLoading}
      {...rest}
    >
      <div className={styles["button-contents-grid"]}>
        {startIcon}
        {children}
        {endIcon}
      </div>
    </button>
  );
};

export default Button;
