import React from "react";
import { useDetailsCard } from "../../../../hooks/views/useDetailsCardV2";
import VendorSubControls from "../customFields/VendorSubControls";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

/**
 * Custom hook for viewing Vendor Control Category details
 * @param itemId
 * @param organizationID
 * @param tableDisplay
 * @param updateItemById
 * @param customFields
 * @param module
 * @param resource
 * @param props
 */
export const useVendorControlCategoryDetails = ({
  itemId,
  organizationID,
  tableDisplay,
  updateItemById,
  customFields = [],
  module = modules.VENDORS,
  resource: resourceInput, // allowing this to be null so that 'Vendor Sub Controls' CAN be overwritten but not by default
  disableRoleChecking,
  ...props
}) => {
  const resource = resources.VENDOR_CONTROL_CATEGORY;

  const typename = "VendorControlCategory";

  const fields = ["name", "subControls"];

  const fieldNameDictionary = {
    subControls: "Sub Controls",
  };

  const nestedFields = {
    subControls: `(limit: 100) { items { id ownerGroup name statementNumber inPlace audited } }`,
  };

  const { updateMutation } = generateGraphql(typename, fields, nestedFields);

  const queryConfig = {
    itemId,
  };

  const detailsCardHook = useDetailsCard({
    fieldNameDictionary,
    queryConfig,
    module,
    resource,
    fields,
    detailsConfig: {
      header: "Vendor Control Category Details",
      updateMutation,
      customFields: [
        {
          field: "subControls",
          component: <VendorSubControls organizationID={organizationID} disableRoleChecking={disableRoleChecking} />,
        },
        ...customFields,
      ],
    },
    typename,
    nestedFields,
    tableDisplay,
    updateItemById,
    ...props,
  });

  return {
    ...detailsCardHook,
  };
};
