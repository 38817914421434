import { modules, resources } from "@rivial-security/role-utils";

import { GENERIC_FIELD_TYPES } from "../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import GenericEditFieldV3 from "../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";
import React from "react";
import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 * Displays the Rating field for a Vendor Review
 * @param {object} item - the Vendor Review item
 * @param {function} updateItemById - update the Vendor Review by id in the grid
 * @param {object} ...props - additional props to pass to GenericEditFieldV3
 * @returns {JSX.Element}
 * @constructor
 */
const RatingDropdown = ({ item, updateItemById, ...props }) => {
  const module = modules.VENDORS;
  const resource = resources.VENDOR_REVIEW;
  const field = "rating";

  const options = ["low", "lowMedium", "medium", "mediumHigh", "high"].map((rating) => {
    return {
      text: convertCamelCaseToSentence(rating),
      value: rating,
    };
  });

  return (
    <GenericEditFieldV3
      inputType={GENERIC_FIELD_TYPES.DROPDOWN}
      item={item}
      inputConfig={{
        data: options,
      }}
      field={field}
      mutation={generateGraphql("VendorReview", ["rating"]).updateMutation}
      updateItemById={updateItemById}
      resource={resource}
      module={module}
      {...props}
    />
  );
};

export default RatingDropdown;
