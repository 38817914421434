import { generateGraphql } from "@rivial-security/generategraphql";
import { deleteItem } from "../../../../utils/Functions/Graphql/deleteItem";

/**
 * Deletes a single Threat object along with associated Many-To-Many connections
 * @param threat
 */
export const deleteThreat = async (threat) => {
  const { getQuery } = generateGraphql("Threat", ["systemRiskLinks", "incidentLinks", "riskLinks", "__typename"], {
    systemRiskLinks: `(limit: 1000) { items { id __typename } }`,
    incidentLinks: `(limit: 1000) { items { id __typename } }`,
    riskLinks: `(limit: 1000) { items { id __typename } }`,
  });

  return await deleteItem(getQuery, threat);
};
