import { useGUID } from "../../../../hooks/functional/useGUID";
import Button from "@mui/material/Button";
import React from "react";
import { useCreateAutomationStyles } from "../styles/useCreateAutomationStyles";
import { generateAutomationStepName } from "../functions/generateAutomationStepName";

/**
 * A button used to select an Automation type
 * @param {function} toggleModal - function to call to close the modal
 * @param {AutomationType} type - the type of automation to select
 * @param {boolean} type.comingSoon - whether the type is coming soon
 * @param {string} type.icon - the icon to display
 * @param {string} type.text - the text to display
 * @param {string} type.type - the type of automation
 * @param {function} onSelect - function to call when an automation type is selected
 * @param {string} organizationID - the organization ID
 * @returns {JSX.Element}
 * @constructor
 */
const SelectAutomationTypeButton = ({ toggleModal, type, onSelect, automations = [] }) => {
  const classes = useCreateAutomationStyles();

  const [guid] = useGUID();

  const onClick = () => {
    onSelect({
      ...type,
      config: {},
      id: guid,
      name: generateAutomationStepName({ type: type.type, automations }),
    });
    toggleModal && toggleModal();
  };
  return (
    <Button
      className="e-card ribbon-parent"
      onClick={!type?.comingSoon ? onClick : () => alert("This Automation type is not supported yet")}
      classes={{ root: classes.button, label: classes.label }}
    >
      {type.comingSoon && <span className="ribbon">Coming Soon</span>}
      <span className={classes.icon}>{type.icon}</span>
      {type.text}
    </Button>
  );
};

export default SelectAutomationTypeButton;
