import { sortObjectsByField } from "@rivial-security/func-utils";
import { submitRiskComplianceSyncJob } from "../../Evidence/functions/submitRiskComplianceSyncJob";
import { updateControlOverrideType } from "../../Evidence/enums/updateControlOverrideType";
import { useManyLinking } from "../../../../hooks/views/useManyToManyTransfer/hooks/useManyLinking";
import { useRiskControlDataGrid } from "../../../Risk/RiskConfig/ControlCategories/components/SubControls/hooks/useRiskControlDataGrid";

/**
 * Allows the user to link Controls to an Evidence
 * @param props
 * @param {string} organizationID - the org id
 * @param {Evidence} item - the Evidence being linked to
 * @param {string} item.id - the Evidence ID
 * @param {boolean} item.enabled - the Evidence enabled field (used to trigger a risk sync job)
 * @param {function} [resetFunction] - function handler for refreshing data after operation
 * @param {function} [toggleModal] - function handler for toggling modal after operation
 */
export const useEvidenceRiskControlLinking = ({ organizationID, item, resetFunction, toggleModal }) => {
  const riskControls = useRiskControlDataGrid({ organizationID });

  const linking = useManyLinking({
    organizationID,
    resetFunction,
    item,
    toggleModal,
    field: "riskControls",
    nestedField: "riskControl",
    typename: "RiskControlEvidenceLink",
    childTypename: "RiskControl",
    parentTypename: "Evidence",
    parentConnectionIDField: "evidenceID",
    childConnectionIDField: "riskControlID",
    normalizeLinkedItems: (linkedItems) => {
      //Filter out any non-standard control links
      linkedItems = linkedItems?.filter((item) => !item?.link?.system?.id);
      return Array.isArray(linkedItems) ? linkedItems : [];
    },
    handleChangedItems: (changedItems) => {
      // If evidence is enabled, submit a risk sync job
      if (item?.enabled) {
        submitRiskComplianceSyncJob({
          evidenceID: item?.id,
          organizationID,
          riskControlOverrides: changedItems,
          overrideType: updateControlOverrideType.GENERAL_LINKS_UPDATED,
        });
      }
    },
    existingItems: sortObjectsByField(riskControls.data, "statementNumber") || [],
    listTitleAdapter: (control) => `${control?.statementNumber}: ${control?.name}`,
  });

  return {
    ...linking,
  };
};
