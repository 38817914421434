"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.monteCarloCalculation = void 0;
const __1 = require("..");
const calculateNetProfit_1 = require("./functions/calculateNetProfit");
const calculateROI_1 = require("./functions/calculateROI");
const calculateRiskMitigated_1 = require("./functions/calculateRiskMitigated");
const computeSystemStats_1 = require("./functions/computeSystemStats");
const generateReusableRandoms_1 = require("../createMonteCarloSystemInput/functions/generateReusableRandoms");
const getControlStats_1 = require("./functions/getControlStats");
const getLossCurveXPoints_1 = require("./functions/getLossCurveXPoints");
const func_utils_1 = require("@rivial-security/func-utils");
const runSimulationsForRisk_1 = require("./functions/runSimulationsForRisk");
let reusableRandoms = new Map();
let lossCurveXPoints;
/**
 * A function that calculates and returns inherentRisk, residualRisk, ROI, and
 * a lossExceedenceCurve for a System and its individual Risks.
 * scenarios to analyze the potential impact of implementing additional controls.
 *
 * @author Jacob Blazina - 7/29/19
 * @function
 * @async
 * @param {object} event - the event object. in this format to match AWS lambda
 * @param {system} event.system - Required. The system to run monte carlo analysis on
 * @param {risk[]} event.system.risks - Required. Associated Key Risk Indicators for a System
 * @param {object[]} [event.reusableRandoms = null] - The reusable randoms object. If none is supplied this will generate all new ones.
 * @param {number} [event.lossCurveMaxX = 1000000] - The maximum X-value (in $) to be used. Defaults to $1,000,000
 * @param {number} [event.lossCurvePointCount = 100] - How many points of the X axis to plot. Defaults to 100
 * @param {numberOfSimulations} [event.numberOfSimulations] - Deprecated: use event.system.numberOfSimulations instead
 * @param {object} [monteCarloSystemInput] - full monteCarloSystemInput object to use for Risk Ratings
 * @param {object} [monteCarloSystemInput.systemDetailsItem] - full System object for risk ratings (fresh object, not modified by createMonteCarloSystemInput)
 * @returns {Promise<monteCarloCalculationResult>}
 */
const monteCarloCalculation = async (event) => {
    reusableRandoms = new Map();
    const system = event.system;
    const monteCarloSystemInput = event.monteCarloSystemInput;
    const organizationID = monteCarloSystemInput?.organizationID || monteCarloSystemInput?.ownerGroup;
    if (!(system?.risks && system.risks.length > 0)) {
        // If there are no risks, or the risks aren't attached correctly.
        console.log("[monteCarloCalculation] Error: The Input does not contain a System or Risks");
        return defaultFailedInvokeResult;
    }
    const risks = system.risks;
    const numberOfSimulations = system.numberOfSimulations || event.numberOfSimulations || 1000; // Default to 1000 simulations.
    const lossCurveMaxX = event.lossCurveMaxX ? event.lossCurveMaxX : 1000000000; // Defaults to 1 billion.
    const lossCurvePointCount = event.lossCurvePointCount ? event.lossCurvePointCount : 100; // The number of points used to plot the loss curve.
    lossCurveXPoints = (0, getLossCurveXPoints_1.getLossCurveXPoints)(lossCurveMaxX, lossCurvePointCount);
    // If we are trying to measure a change that affects Confidence Intervals,
    // we want to use the same "probability" randoms, but with altered "impact" randoms
    if (event.reusableRandoms && system?.change) {
        // Only generate new "impact" randoms if there IS a change, else keep using the same randoms for a zero'd result
        if ((system.change.assetSizeChange && system.change.assetSizeChange !== 0) ||
            (system.change.numberOfCustomersChange && system.change.numberOfCustomersChange !== 0) ||
            system.change.availabilityChange ||
            system.change.informationAssetsChange) {
            (0, generateReusableRandoms_1.generateReusableRandoms)(risks, numberOfSimulations, event.reusableRandoms);
        }
        else {
            reusableRandoms = new Map(Object.entries(event.reusableRandoms));
        }
    }
    else if (event.reusableRandoms) {
        reusableRandoms = new Map(Object.entries(event.reusableRandoms));
    }
    else if (event.system.reusableRandoms) {
        console.log("[monteCarloCalculation] System Reusable Randoms found");
        reusableRandoms = event.system.reusableRandoms;
    }
    else {
        (0, generateReusableRandoms_1.generateReusableRandoms)(risks, numberOfSimulations);
    }
    const riskEvaluations = [];
    for (const risk of risks) {
        // Running Residual Risk Simulations for Risk
        const controlCategory = risk.controlCategory;
        const subControls = controlCategory.subControls;
        const residualRiskSimulation = (0, runSimulationsForRisk_1.runSimulationsForRisk)(risk, numberOfSimulations, (0, getControlStats_1.getControlStats)(subControls), reusableRandoms, lossCurveXPoints);
        // Running Inherent Risk Simulations for Risk
        const inherentRiskSimulation = (0, runSimulationsForRisk_1.runSimulationsForRisk)(risk, numberOfSimulations, (0, getControlStats_1.getControlStats)([]), reusableRandoms, lossCurveXPoints);
        const lossToleranceCurve = (0, __1.formatLossToleranceCurve)(monteCarloSystemInput.lossToleranceCurve);
        const riskAnalysis = {
            id: risk.id,
            systemRiskLinkId: risk.systemRiskLinkId,
            name: risk.name,
            ownerGroup: risk.name,
            controlCategory: risk.controlCategory,
            annualRateOfOccurrence: risk.annualRateOfOccurrence,
            inherentRisk: inherentRiskSimulation.averageLoss,
            residualRisk: residualRiskSimulation.averageLoss,
            inherentRiskRating: (0, __1.getLossCurveRating)({
                ratingScale: monteCarloSystemInput?.ratingScale,
                lossToleranceCurve,
                riskAmount: inherentRiskSimulation.averageLoss,
            }),
            residualRiskRating: (0, __1.getLossCurveRating)({
                ratingScale: monteCarloSystemInput?.ratingScale,
                lossToleranceCurve,
                riskAmount: residualRiskSimulation.averageLoss,
            }),
            riskMitigated: (0, calculateRiskMitigated_1.calculateRiskMitigated)({
                inherentRisk: inherentRiskSimulation.averageLoss,
                residualRisk: residualRiskSimulation.averageLoss,
            }),
            recommendationResidualLoss: residualRiskSimulation.recommendationAverageLoss,
            currentCostOfControls: residualRiskSimulation.costOfControls,
            currentControlEffectiveness: residualRiskSimulation.controlEffectiveness,
            recommendationControlEffectiveness: residualRiskSimulation.recommendationControlEffectiveness,
            currentNetProfit: (0, calculateNetProfit_1.calculateNetProfit)(inherentRiskSimulation.averageLoss, residualRiskSimulation.averageLoss, residualRiskSimulation.costOfControls),
            currentROI: (0, calculateROI_1.calculateROI)(inherentRiskSimulation.averageLoss, residualRiskSimulation.averageLoss, residualRiskSimulation.costOfControls),
            riskSimulatedInherentLosses: inherentRiskSimulation.simulatedLosses,
            riskSimulatedResidualLosses: residualRiskSimulation.simulatedLosses,
            lossExceedanceCurve: {
                inherentLossCurve: inherentRiskSimulation.lossProbabilityCurve,
                residualLossCurve: residualRiskSimulation.lossProbabilityCurve,
            },
        };
        // @ts-expect-error Fix me, needs better typing
        riskEvaluations.push(riskAnalysis);
    }
    const systemStats = (0, computeSystemStats_1.computeSystemStats)(riskEvaluations, system, lossCurveXPoints);
    for (const riskResult of riskEvaluations) {
        // @ts-expect-error Fix me, needs better typing
        riskResult.riskSimulatedInherentLosses = undefined;
        // @ts-expect-error Fix me, needs better typing
        riskResult.riskSimulatedResidualLosses = undefined;
    }
    const calculation = {
        // reusableRandoms: Object.fromEntries(reusableRandoms),
        systemStats,
        riskStats: riskEvaluations,
        date: new Date(),
        systemID: system.id,
        monteCarloSystemInput: { ...system },
        riskRatings: undefined,
        riskScore: undefined,
    };
    if (calculation?.systemStats) {
        calculation.systemStats.name = system.name;
        calculation.systemStats.ownerGroup = system.ownerGroup;
    }
    // Get Risk Ratings and add to result object
    let riskRatings;
    try {
        console.log("[monteCarloCalculation] Fetching Risk Ratings with inputs: ", JSON.stringify({
            system: monteCarloSystemInput?.systemDetailsItem,
            organizationID,
            monteCarloResults: calculation,
            riskMapping: monteCarloSystemInput?.riskConfig?.riskMapping,
            ratingScale: monteCarloSystemInput?.ratingScale,
            lossToleranceCurve: monteCarloSystemInput?.lossToleranceCurve,
        }));
        // eslint-disable-next-line @typescript-eslint/await-thenable
        riskRatings = await (0, __1.getRiskRatings)({
            // @ts-expect-error Fix me, needs better typing
            systems: [monteCarloSystemInput?.systemDetailsItem],
            organizationID: monteCarloSystemInput?.organizationID || monteCarloSystemInput?.ownerGroup,
            riskMapping: monteCarloSystemInput?.riskConfig?.riskMapping,
            lossToleranceCurve: monteCarloSystemInput?.lossToleranceCurve,
            ratingScale: monteCarloSystemInput?.ratingScale,
            env: process.env,
            monteCarloResults: calculation,
        });
    }
    catch (e) {
        console.log("[monteCarloCalculation] Error: Could not get risk ratings. ", e);
    }
    // Add riskRatings to calculation result, if present
    if (!(0, func_utils_1.isNullOrUndefined)(riskRatings)) {
        calculation.riskRatings = riskRatings;
    }
    return {
        calculation,
        reusableRandoms: Object.fromEntries(reusableRandoms),
    };
};
exports.monteCarloCalculation = monteCarloCalculation;
/**
 * The default object returned the case of a failure.
 *
 * @type {{date: Date, inherentRisk: string, residualRisk: string, lossExceedanceCurve: Array, returnOnInvestment: string}}
 */
const defaultFailedInvokeResult = {
    date: new Date(),
    inherentRisk: "Could not calculate inherent risk",
    residualRisk: "Could not calculate residual risk",
    lossExceedanceCurve: [],
    returnOnInvestment: "Could not calculate ROI",
};
