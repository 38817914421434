import { useSourceDataGrid } from "../hooks/useSourceDataGrid";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * Component instance of the useSourceDataGrid hook
 * @param props
 * @returns {*}
 * @constructor
 */
const SourceDataGrid = (props) => useSourceDataGrid(props).display;

export default withOrganizationCheck(SourceDataGrid);
