import { getPropertiesFromObject, isNullOrUndefined } from "@rivial-security/func-utils";

import { useEffect } from "react";
import { useStateEffect } from "../../../../hooks/functional/useStateEffect";
import { useTable } from "../../../../hooks/views/useTable";

/**
 * Component instance of the useTable hook
 * @param data
 * @param fieldsInit
 * @param customFields
 * @param disableRoleChecking
 * @returns {JSX.Element}
 */
const CustomTable = ({ data, fields: fieldsInit, customFields, disableRoleChecking = false }) => {
  /**
   * If no fields are passed, try to automatically get them from the first object in the array.
   */
  const [fields, setFields] = useStateEffect(fieldsInit, [data, fieldsInit], () => {
    if (fieldsInit) {
      setFields(fieldsInit);
    } else {
      try {
        setFields(getPropertiesFromObject(data[0]));
      } catch (e) {
        setFields([]);
      }
    }
  });

  const table = useTable({ data, fields, disableRoleChecking, customFields });

  /**
   * Update the table data on props change
   */
  useEffect(() => {
    if (!isNullOrUndefined(data)) {
      table.setData([...data]);
    }
  }, [data]);

  /**
   * Update the table fields on props change
   */
  useEffect(() => {
    if (!isNullOrUndefined(fields)) {
      table.setFields([...fields]);
    }
  }, [fields]);

  return table.display;
};

export default CustomTable;
