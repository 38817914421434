import { ErrorLogger, InfoLogger } from "@utils/EventLogger";

import { ItemMutation } from "../../../../../../utils/Functions/Graphql/ItemMutation";
import { generateGraphql } from "@rivial-security/generategraphql";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Saves the Role Configuration changes to the Database
 * @param {Role} item - the Role Object from the database
 * @param {ID} item.id - the ID Role Object from the database
 * @param {RoleConfig} roleConfig - the roleConfig object to use in the mutation
 * @param {function} [addToast] - function for adding a new toast
 * @param {function} [updateToast] - function for updating toasts
 * @param {function} [setIsChanged] - function for setting isChanged to false after the operation completes
 * @returns {Promise<void>}
 */
export const saveRoleConfig = ({ item, roleConfig, addToast, updateToast, setIsChanged }) => {
  const toastId = addToast({
    header: `Saving Role...`,
    icon: "spinner",
    color: "success",
  });

  const { updateMutation } = generateGraphql("Role");

  if (!isNullOrUndefined(item?.id) && !isNullOrUndefined(roleConfig)) {
    return ItemMutation(updateMutation, {
      id: item ? item.id : undefined,
      roleConfig: roleConfig ? JSON.stringify(roleConfig) : undefined,
    })
      .then((updatedItem) => {
        InfoLogger(`Role config was successfully saved: ${updatedItem.id}`);

        updateToast({
          id: toastId,
          header: `Role was successfully saved.`,
          icon: "success",
        });
      })
      .catch((err) => {
        ErrorLogger(`Error: Saving Role config ${JSON.stringify(err)}`);

        updateToast({
          id: toastId,
          header: `Something went wrong! Role was NOT successfully saved!`,
          icon: "danger",
        });
      })
      .finally(() => {
        setIsChanged(false);
      });
  } else {
    ErrorLogger("Could not save Role Changes. Invalid Role item or roleConfig object");
  }
};
