import { useEffect } from "react";
import { normalizeRowIDs } from "../../useDataGrid/functions/normalizeRowIDs";

/**
 * Populates the existing 'Linked Resources' list when the item loads
 *
 * @param {Control} item - the parent item
 * @param {function} setItems - handler for setting the Resources that are linked to the item
 * @param {string} field - the item field that holds the connection object
 * @param {string} nestedField - the connection object field that holds the child resource
 * @param {function} [normalizeLinkedItems] - a function to edit the currently linked items before they are displayed
 */
export const usePopulateItemLinkedResources = (item, setItems, field, nestedField, normalizeLinkedItems) => {
  return useEffect(() => {
    if (item?.[field]?.items) {
      let items = item[field].items.map((link) => {
        return { ...link[nestedField], link };
      });
      if (normalizeLinkedItems) {
        items = normalizeLinkedItems(items);
      }

      // filter out any items with a missing ID field
      const normalizedRowIDsData = normalizeRowIDs({
        items,
      });

      setItems(normalizedRowIDsData);
    }
  }, [item]);
};
