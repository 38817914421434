import { ErrorLogger } from "../../EventLogger";
import { GetQuery } from "../../Functions/Graphql/GetQuery";
import { InvalidParamError } from "../../../analytics/CustomError";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Sends a notification to all Users in that org with a 'Manager' Role type asking
 * to add a module, resource, or function to their current Role.
 * @param {string} user - database user id for whom to write the notification
 * @param {string} module - the module for which to request permission
 * @param {string} resource - the resource name for which to request permission
 * @param {string} function - the function name for which to request permission
 * @param {string} operation - the operation on the provided resource to ask permission for
 * @returns {Promise<void>}
 */
export const requestSinglePermission = async ({ userID, module, resource, func, operation }) => {
  //Check arguments
  if (!userID || (!module && !resource && !func)) {
    ErrorLogger("Invalid arguments in requestSinglePermission", {
      userID,
      module,
      resource,
    });
    return;
  }

  //Call the lambda to request changes
  await requestRoleChange({
    userID,
    input: { module, resource, func, operation },
  });
};

/**
 * Uses the GraphQL lambda function to request a role change for the user, spawns notifications for the admins
 * @param {string} user - database user id for whom to write the notification
 * @param {object} input - the new role config object or the module and resource to ask for
 * INPUT EXAMPLES:
 * const input = {
 *   roleConfig: {
 *     ...
 *   }
 * }
 * OR
 * const input = {
 *   module: "",
 *   resource: ""
 * }
 */
const requestRoleChange = async ({ userID, input }) => {
  if (isNullOrUndefined(userID)) {
    throw InvalidParamError("userID");
  }

  if (isNullOrUndefined(input)) {
    throw InvalidParamError("input");
  }

  if (!input.roleConfig && !input.module && !input.resource && !input.function) {
    throw InvalidParamError("requestRoleChangeInput");
  }

  const getQuery = `
  query GetUser($id: ID!, $input: AWSJSON) {
    getUser(id: $id) {
      id
      requestRoleChange(input: $input)
    }
  }`;

  return await GetQuery({
    query: getQuery,
    variables: { id: userID, input: JSON.stringify(input) },
  });
};
