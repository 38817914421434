import React from "react";
import Grid from "@mui/material/Grid";
import DataLoader from "../../../../utils/LoadingComponents/DataLoader";
import ColumnCard from "@hooks/views/useCsvImporter/components/ColumnCard";

/**
 * Displays cards for mapping CSV headers to existing Resource Fields
 * @param {object} row - first row of the CSV file. used for previews
 * @param {object} columnMap - mapping between CSV file column headers and Resource Fields
 * @param {string[]} headers - the current array of CSV file column headers
 * @param {object[]} fields - the Resource Fields that are currently available to be mapped. Only one field may be mapped at a time
 * @param {function} setColumnMapping - state setter for the current mapping of CSV file headers and Resource Fields
 * @returns {JSX.Element}
 */
const ColumnCards = ({ row, columnMap, headers, fields, setColumnMapping }) => {
  return (
    <DataLoader isEnoughData={true} isLoading={false}>
      <Grid container spacing={2}>
        {headers?.map((header, index) => (
          <Grid item xs={6} sm={6} md={4} lg={4} xl={3} key={header}>
            <ColumnCard
              row={row}
              header={header}
              fields={fields}
              columnMap={columnMap}
              setColumnMapping={setColumnMapping}
            />
          </Grid>
        ))}
      </Grid>
    </DataLoader>
  );
};

export default ColumnCards;
