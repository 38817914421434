import React from "react";
import { useUUID } from "../../../../../../hooks/functional/useUUID";
import { withOrganizationCheck } from "../../../../../../utils/Context/withOrganizationCheck";
import { getFullName } from "../functions/getFullName";

/**
 * Displays timeline information about a point of contact Attesting for an Evidence
 * @param {PointOfContact} pointOfContact
 * @param {boolean} attested - whether the point of contact attested the evidence as in-place (true) or not-in-place (false)
 * @param {string} pointOfContact.firstName - point of contact first name
 * @param {string} pointOfContact.lastName - point of contact last name
 * @returns {JSX.Element}
 */
const AttestedActivity = ({ pointOfContact = {}, attested }) => {
  const [uuid] = useUUID();

  const fullName = getFullName({ pointOfContact });

  return (
    <span id={`attested_activity${uuid}`} style={{ fontSize: ".9em" }}>
      {fullName || "Someone"} Attested that this Evidence is
      {attested === true || attested === "true"
        ? " In Place"
        : attested === false || attested === "false"
          ? " Not In Place "
          : "Not Attested"}
    </span>
  );
};

export default withOrganizationCheck(AttestedActivity);
