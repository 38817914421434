import { generateGraphql } from "@rivial-security/generategraphql";
import { ItemQuery } from "../../../../utils/Functions/Graphql/ItemQuery";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { DeleteVendorSubControls } from "../../VendorSubControls/functions/DeleteVendorSubControls";

/**
 * Deletes a VendorControlCategory.
 * First deletes VendorSubControls.
 * @param {object} vendorControlCategory
 * @param {string} vendorControlCategory.id
 */
export const deleteVendorControlCategory = async (vendorControlCategory) => {
  const { getQuery, deleteMutation } = generateGraphql("VendorControlCategory", ["subControls"], {
    subControls: `(limit: 100) { items { id } }`,
  });

  const fullVendorControlCategory = await ItemQuery(getQuery, vendorControlCategory.id);

  const subControls =
    fullVendorControlCategory && fullVendorControlCategory.subControls && fullVendorControlCategory.subControls.items
      ? fullVendorControlCategory.subControls.items
      : [];

  // delete all associated VendorSubControls
  for (const subControl of subControls) await DeleteVendorSubControls(subControl);

  // then delete VendorControlCategory
  await ItemMutation(deleteMutation, { id: vendorControlCategory.id });
};
