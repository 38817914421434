import { Button } from "reactstrap";
import React from "react";
import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { gridGetNewItem } from "../functions/gridGetNewItem";
import { useModal } from "../../useModal";

/**
 * @description Create new item hook
 * @param {object} ref - reference of the the grid component
 * @param {string} createItemModalHeader - create modal header text
 * @param {JSX} createResourceComponent - create item component
 * @param {function} getNewItem - get created item
 * @param {function} resetFunction - re-fetch data from the database
 * @param {string} createResourceComponentWidth - width of the create modal
 * @param {string} typename - the database resource name for the item being created
 * @return {object} {{createItemModal: {setModalIsOpen: function(*=): void, modalButton: JSX.Element, modalIsOpen: boolean | undefined, modal: JSX.Element}}}
 */
export const useGridCreateItem = ({
  ref,
  createItemModalHeader = "",
  createResourceComponent,
  getNewItem,
  resetFunction,
  createResourceComponentWidth,
  typename = "Item",
}) => {
  /**
   * Create new item modal
   */
  const createItemModal = useModal(
    createItemModalHeader,
    createResourceComponent
      ? React.cloneElement(createResourceComponent, {
          resetFunction,
          getNewItem: (item) => {
            gridGetNewItem({ item, ref });
            getNewItem && getNewItem(item);
          },
        })
      : "Missing Details Component",
    <Button
      id={"button-create-item-grid"}
      data-testid={"button-create-item-grid"}
      size="sm"
      className="btn-pill"
      style={{
        boxShadow: "none",
        color: "white",
        backgroundColor: "transparent",
        border: "none",
      }}
      title={`Create new ${convertCamelCaseToSentence(typename)}`}
    >
      <i className="icon-plus" />
    </Button>,
    {
      width: createResourceComponentWidth || "50vw",
    },
  );

  return {
    createItemModal,
  };
};
