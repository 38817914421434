import { ErrorLogger, WarningLogger } from "@utils/EventLogger";
import React, { useEffect, useState } from "react";

import { ItemMutation } from "../../utils/Functions/Graphql/ItemMutation";
import { ItemQuery } from "../../utils/Functions/Graphql/ItemQuery";
import { ListBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { useStateEffect } from "../functional/useStateEffect";
import { useStepper } from "./useStepper/useStepper";

/**
 * @typedef {object} ProgressTracker
 *
 * @property {JSX.Element} display
 * @property {WorkflowStep[]} steps
 * @property {string} itemId
 * @property {function} setSteps
 * @property {WorkflowStep} currentStep
 * @property {number} percentComplete
 * @property {string} [title = "Progress Tracker"]
 */

/**
 * Displays a list of steps to be completed.
 * Used for workflow or process oriented User Interfaces.
 * @param {string} itemId - the vendor review identifier
 * @param {object} progressGQL - get and update queries to retrieve and update the progress of steps
 * (see RiskAssessment type for an example)
 * @param {WorkflowStep[]} steps - the steps to initialize the progress tracker
 * @param {number} initialStepIndex - the index of the step to start with
 * @param {function} addToContext - function to call to add a value to the workflow context (such as the current step)
 * @param {object} context - the current value of the workflow context
 * @param {function} isReadOnly - function to use to check if based on the current step and context whether the GUI
 * should have editing disabled
 * @return {{currentStep: *, triggerProgressUpdate: ((function(*=, *=, *=): Promise<void>)|*), setCurrentStep: (value: (((prevState: *) => *) | *)) => void, setCurrentStepById: setCurrentStepById, setSteps: (value: (((prevState: *[]) => *[]) | *[])) => void, stepper: {display: JSX.Element}, display: JSX.Element, percentComplete: number, steps: *[]}}
 */
export const useProgressTracker = ({
  itemId = "",
  progressGQL = {},
  steps: initialSteps = [],
  initialStepIndex = 0,
  addToContext,
  context,
  isReadOnly,
} = {}) => {
  /**
   * The progress tracker steps
   */
  const [steps, setSteps] = useState([...initialSteps]);
  useEffect(() => {
    setSteps([...initialSteps]);
  }, [JSON.stringify(initialSteps.map((item) => item?.id))]);

  /**
   * The percentage of steps that are marked 'Complete'
   */
  const [percentComplete, setPercentComplete] = useState(0);

  /**
   * The currently selected Step.
   */
  const [currentStep, setCurrentStep] = useStateEffect({}, [steps], () => {
    const currentStepIndex = steps.findIndex((item) => item.id === currentStep?.id);

    if (currentStepIndex !== -1 && steps && steps[currentStepIndex]) {
      addToContext?.("currentStep", steps[currentStepIndex]);
      return steps[currentStepIndex];
    } else if (steps && steps[initialStepIndex]) {
      return steps[initialStepIndex];
    }
  });

  const getCurrentStepIndex = () => {
    return steps && steps.findIndex((item) => item.id === currentStep?.id);
  };

  /**
   * On new step update context
   */
  useEffect(() => {
    if (typeof isReadOnly === "function") {
      const readOnly = isReadOnly({ context });
      addToContext?.("readOnly", readOnly);
      selectStep({ step: currentStep });
    }
  }, [currentStep, context?.vendorReview?.status]);

  /**
   * Update a single step's progress
   */
  const triggerProgressUpdate = async (stepId = "", stepIsDone = false, stepCompletion = 0.0) => {
    try {
      const stepIndex = steps.findIndex((item) => item.id === stepId);
      if (stepIndex === -1) {
        WarningLogger("No step found to update progress");
        return;
      }

      if (!itemId || !progressGQL || !progressGQL.updateMutation || !progressGQL.getQuery) {
        throw new Error("Cannot update steps' progress in the backend because there is no itemId or graphQL!");
      }

      //Retrieve the newest state of steps from the backend (if multiple people are working on the same report)
      const item = await ItemQuery(progressGQL.getQuery, itemId);

      //Check the structure of received workflow
      if (
        !item ||
        !item.workflow ||
        !item.workflow.steps ||
        !item.workflow.steps ||
        !Array.isArray(item.workflow.steps)
      ) {
        throw new Error("Retrieved old step data doesn't have correct structure");
      }

      //Check for correct number of steps between frontend and backend
      if (!Array.isArray(steps) || item.workflow.steps.length !== steps.length) {
        throw new Error(
          "Amount of frontend steps dont match the amount of backend steps in workflow completion update!",
        );
      }

      //TODO: look for conflicts with the new step info and if info needs to be updated

      //Create the new state of progress for steps
      item.workflow.steps[stepIndex].isDone = stepIsDone !== null && stepIsDone !== undefined ? stepIsDone : false;
      item.workflow.steps[stepIndex].completion =
        stepCompletion !== null && stepCompletion !== undefined ? stepCompletion : 0.0;

      //Upload new steps
      await ItemMutation(progressGQL.updateMutation, item);

      //Update step list in the UI
      await updateProgressUI(item);
    } catch (e) {
      ErrorLogger("Error while uploading progress steps to the database!", e);
    }
  };

  /**
   *  Updates list icons and other progress ui based
   */
  const updateProgressUI = async (progressItem) => {
    try {
      let item = progressItem;
      if (!item && progressGQL?.getQuery) {
        item = await ItemQuery(progressGQL.getQuery, itemId);
      }

      //If the retrieved item is not available don't continue;
      if (!item) {
        return;
      }

      //Check the structure of received workflow
      if (
        !item ||
        !item.workflow ||
        !item.workflow.steps ||
        !item.workflow.steps ||
        !Array.isArray(item.workflow.steps)
      ) {
        throw new Error("Retrieved old step data doesn't have correct structure");
      }

      //Check for correct number of steps
      if (!Array.isArray(steps) || item.workflow.steps.length !== steps.length) {
        throw new Error(
          "Amount of frontend steps dont match the amount of backend steps in workflow completion update!",
        );
      }

      const tempSteps = [...steps];
      for (let i = 0; i < steps.length; i++) {
        tempSteps[i].iconCss = `icon-check ${item.workflow.steps[i].isDone ? "text-success" : ""}`;
      }
      setSteps(tempSteps);
    } catch (e) {
      ErrorLogger("Error while updating progress list item icons!", e);
    }
  };

  /**
   * Sets up initial progress UI
   */
  useEffect(() => {
    updateProgressUI();
  }, []);

  /**
   * Update the percentComplete on every change to the 'steps' object
   */
  useEffect(() => {
    if (steps) {
      setPercentComplete(getPercentComplete(steps));
    }
  }, [steps]);

  /**
   * Selects an item in the list box menu programmatically
   * @param step
   */
  const selectStep = ({ step }) => {
    //Deselect all items
    if (ref?.selectAll) {
      ref.selectAll(false);
    }

    //Select the requested step
    if (ref?.selectItems && step?.text) {
      ref.selectItems([step?.text]);
    }
  };

  /**
   * Allows to modify the currently active step with just the id of the step
   * @param id
   */
  const setCurrentStepById = ({ id }) => {
    const step = steps.find((item) => item.id === id);
    if (step) {
      addToContext?.("currentStep", step);
      setCurrentStep(step);
    }
  };

  /**
   * The onChange handler for the SyncFusion ListBox component.
   * Sets the 'currentStep' to be whichever one is clicked
   * @param e
   */
  const onChange = (e) => {
    const id = e && e.elements && e.elements[0] && e.elements[0].id;
    if (id) {
      setCurrentStepById({ id });
    }
  };

  /**
   * Initializes the listbox selection
   */
  const [ref, setRef] = useState("");
  const onCreate = () => {
    selectStep({ step: steps[initialStepIndex] });
  };

  const stepper = useStepper({ steps, currentStep });

  /**
   * Displays as a ListBox component.
   */
  const display = (
    <div>
      <ListBoxComponent
        id="progress_tracker"
        fields={{ text: "text", iconCss: "iconCss" }}
        dataSource={steps}
        change={(e) => onChange(e)}
        ref={(r) => setRef(r)}
        created={onCreate}
      />
    </div>
  );

  return {
    display,
    steps,
    setSteps,
    percentComplete,
    currentStep,
    setCurrentStep,
    setCurrentStepById,
    triggerProgressUpdate,
    stepper,
    getCurrentStepIndex,
  };
};

/**
 * Gets the percentage of steps that are marked as "complete"
 * @param {WorkflowStep[]} steps
 * @returns {number}
 */
const getPercentComplete = (steps) => {
  if (steps && Array.isArray(steps)) {
    const total = steps.length;
    let current = 0;

    for (const step of steps) {
      if (step.status === "Complete") current++;
    }

    return current / total;
  } else {
    return 0;
  }
};
