import {
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  ContextMenu,
  Edit,
  ExcelExport,
  Filter,
  GridComponent,
  Group,
  InfiniteScroll,
  Inject,
  Page,
  PdfExport,
  Resize,
  Sort,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import { GridTemplate } from "./GridTemplate";
import React from "react";
import { getGridHeaderText } from "../functions/getGridHeaderText";

/**
 * @description Syncfusion Grid component
 * @param {object} gridProperties - contains all grid properties
 * @param {object[]} fields - array of objects of fields for the grid
 * @param {function} getGridID - get grid id
 * @param {boolean} enableCheckBoxes - enables checkboxes for the grid
 * @param {string} key - unique key for the grid
 * @return {JSX.Element}
 * @constructor
 */
const GridSyncfusion = ({ gridProperties = {}, fields = [], getGridID, enableCheckBoxes, key }) => {
  return (
    <GridComponent {...gridProperties} key={getGridID() + key} Height="100%" style={{ minHeight: "20em" }}>
      <ColumnsDirective>
        {enableCheckBoxes && <ColumnDirective type="checkbox" width="40" />}
        {fields &&
          Array.isArray(fields) &&
          fields.length > 0 &&
          fields.map((field) => (
            <ColumnDirective
              key={field?.name + getGridID()}
              field={field?.name}
              headerText={getGridHeaderText(field)}
              width={field?.width}
              textAlign="Left"
              {...field}
              template={GridTemplate}
              component={
                undefined
              } /* FIX: makes sure a custom field React Component doesn't try to get saved to LocalStorage */
            />
          ))}
        <ColumnDirective field={"searchColumn"} visible={false} showInColumnChooser={false} />
        <ColumnDirective
          field={"id"}
          allowSearching={false}
          isPrimaryKey={true}
          visible={false}
          showInColumnChooser={false}
        />
      </ColumnsDirective>
      <Inject
        services={[
          Page,
          Sort,
          Filter,
          Group,
          Resize,
          Toolbar,
          InfiniteScroll,
          ExcelExport,
          PdfExport,
          ContextMenu,
          ColumnChooser,
          Edit,
        ]}
      />
    </GridComponent>
  );
};

export default GridSyncfusion;
