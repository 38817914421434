import { useEffect, useState } from "react";
import { isNonEmptyArray, isNullOrUndefined } from "@rivial-security/func-utils";
import styles from "../css/PDFEmphasizeMask.module.css";

/**
 * A custom svg mask layer spanning each page of the document, used to create an overlay over everything but a single annotation
 * @param {object} emphasizedAnnotation - the annotation to use as a mask
 * @param {object} settings - current pdf viewer display parameters
 * @param {number} pageNumber- since annotations can span multiple pages, this is the page number of the current mask
 * @returns {JSX.Element}
 */
const PDFEmphasizeMask = ({ emphasizedAnnotation, settings, pageNumber }) => {
  const [isActive, setIsActive] = useState(false);
  const [polygonPoints, setPolygonsPoints] = useState([]);

  const isShown = () => {
    const pageBounds = emphasizedAnnotation?.pageBounds;
    return pageBounds?.hasOwnProperty(pageNumber) || false;
  };

  //When emphasized annotation changes update the polygon points of the svg mask
  const getPolygonPoints = () => {
    if (isShown()) {
      const newPolygonPoints = [];
      const polygonBlocks = emphasizedAnnotation?.pageBounds[pageNumber];
      for (const block of polygonBlocks) {
        const { bottom, left, right, top } = block || {};
        if (
          isNullOrUndefined(bottom) ||
          isNullOrUndefined(left) ||
          isNullOrUndefined(right) ||
          isNullOrUndefined(top)
        ) {
          continue;
        }

        newPolygonPoints.push(`
          ${left * 100} ${top * 100},
          ${right * 100} ${top * 100},
          ${right * 100} ${bottom * 100},
          ${left * 100} ${bottom * 100}
        `);
      }

      setPolygonsPoints(newPolygonPoints);
    } else {
      return [];
    }
  };
  useEffect(() => {
    getPolygonPoints();
  }, [emphasizedAnnotation?.pageBounds]);

  //When svg mask changes show the emphasis for 2 seconds (if there are any polygon points)
  useEffect(() => {
    let timer;

    if (isNonEmptyArray(polygonPoints)) {
      setIsActive(true);
      timer = setTimeout(() => {
        setIsActive(false);
      }, 2000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [polygonPoints, emphasizedAnnotation?.emphasisID]);

  return (
    <div className={`${styles["emphasize-overlay"]} ${isActive ? styles.active : ""}`}>
      {isShown() && (
        <svg width={"100%"} height={"100%"} viewBox="0 0 100 100" id="innerbox" preserveAspectRatio="none">
          <defs>
            <mask id="hole">
              <rect width="100%" height="100%" fill="white" />
              {isNonEmptyArray(polygonPoints) &&
                polygonPoints.map((points, index) => <polygon key={index} points={points} fill="black" />)}
            </mask>
          </defs>
          <rect fill="black" width="100%" height="100%" mask="url(#hole)" />
        </svg>
      )}
    </div>
  );
};

export default PDFEmphasizeMask;
