import React, { useEffect, useState } from "react";
import { numberWithCommas } from "@rivial-security/func-utils";

const NumberOfCustomersChange = ({ item }) => {
  const [oldValue, setOldValue] = useState(0);
  const [newValue, setNewValue] = useState(0);

  useEffect(() => {
    const change = item && JSON.parse(item.change);
    const oldValue = change && change.oldNumberOfCustomers ? change.oldNumberOfCustomers : 0;
    const newValue = change && change.newNumberOfCustomers ? change.newNumberOfCustomers : 0;

    setOldValue(oldValue);
    setNewValue(newValue);
  }, []);

  return (
    <td colSpan={2}>
      <h5>Change in Number of Customers/Members</h5>
      <>
        {newValue === oldValue ? (
          `${numberWithCommas(oldValue)} (No Change)`
        ) : (
          <>
            <del title={`Old Number of Customers: ${numberWithCommas(oldValue)}`}>{numberWithCommas(oldValue)}</del>
            <span
              title={`New Number of Customers: ${numberWithCommas(newValue)}`}
              style={{
                color: newValue > oldValue ? "red" : "green",
                marginLeft: "0.5em",
              }}
            >
              {numberWithCommas(newValue)}
              <br />[ {newValue > oldValue && "+"} {(((newValue - oldValue) / oldValue) * 100).toFixed(2)}% ]
            </span>
          </>
        )}
      </>
    </td>
  );
};

export default NumberOfCustomersChange;
