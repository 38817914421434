import { ErrorLogger, InfoLogger } from "@utils/EventLogger";

import { Auth } from "@aws-amplify/auth";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * @description Function setPreferredMFA for Login Setup Mfa Component
 * @param {string} authType - user preferred MFA value
 * @param {object} user - Cognito user object
 * @param {object} challengeAnswer -
 * @param {function} addToast - function for displaying a toast for the user
 */
export const setPreferredMFA = ({ authType, user, challengeAnswer, addToast }) => {
  if (isNullOrUndefined(authType)) {
    throw new Error(`[setPreferredMFA.js] Param: "authType" is null`);
  }

  if (isNullOrUndefined(user)) {
    throw new Error(`[setPreferredMFA.js] Param: "user" is null`);
  }

  if (isNullOrUndefined(challengeAnswer)) {
    throw new Error(`[setPreferredMFA.js] Param: "challengeAnswer" is null`);
  }

  if (isNullOrUndefined(addToast)) {
    throw new Error(`[setPreferredMFA.js] Param: "addToast" is null`);
  }

  if (authType === "SMS") {
    Auth.setPreferredMFA(user, authType).then((data) => {
      InfoLogger(`SMS: ${data}`);
      addToast({
        header: `Preferred MFA changed to SMS`,
        icon: "success",
      });
    });
  } else if (authType === "TOTP") {
    Auth.verifyTotpToken(user, challengeAnswer)
      .then(() => {
        Auth.setPreferredMFA(user, authType).then((data) => {
          InfoLogger(`TOTP: ${data}`);
          addToast({
            header: `Preferred MFA changed to TOTP`,
            icon: "success",
          });
        });
      })
      .catch((error) => {
        ErrorLogger("Error! verifyTotpToken", error);
        addToast({
          header: `Error! ${JSON.stringify(error?.message)}`,
          icon: "danger",
        });
      });
  } else if (authType === "NOMFA") {
    Auth.setPreferredMFA(user, authType).then((data) => {
      InfoLogger(`NOMFA: ${data}`);
      addToast({
        header: `Preferred MFA changed to NONE`,
        icon: "success",
      });
    });
  }
};
