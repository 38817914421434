import { Exercise, Incident, Maybe, ProcedureGroup } from "@rivial-security/schema-types";

import { isNonEmptyArray } from "@rivial-security/func-utils";

export interface SetCheckedItemsParams {
  item: Partial<Exercise> | Partial<Incident>;
  responseTeamGrid?: {
    setSelectedIDs: (ids: string[]) => void;
  };
  systemsGrid?: {
    setSelectedIDs: (ids: string[]) => void;
  };
  playbookGrid?: {
    setSelectedIDs: (ids: string[]) => void;
  };
  setIncludeGeneralProcedures: (value: boolean) => void;
}

export const setCheckedItems = ({
  item,
  responseTeamGrid,
  systemsGrid,
  playbookGrid,
  setIncludeGeneralProcedures,
}: SetCheckedItemsParams): void => {
  const teamsToCheck: string[] = [];
  const systemsToCheck: string[] = [];
  const playbooksToCheck: string[] = [];
  let generalProceduresIncluded = false;

  const procedures: Maybe<Maybe<ProcedureGroup>[]> | undefined = item?.procedures;
  if (isNonEmptyArray(procedures)) {
    for (const procedureGroup of procedures ?? []) {
      const parentID = procedureGroup?.parentID;
      if (!parentID) {
        continue;
      }

      switch (procedureGroup?.parentTypename) {
        case "ResponseTeam":
          teamsToCheck.push(parentID);
          continue;
        case "ResponseSystemLink":
          systemsToCheck.push(parentID);
          continue;
        case "Playbook":
          playbooksToCheck.push(parentID);
          continue;
        case "IncidentResponse":
          generalProceduresIncluded = true;
          continue;
        default:
          continue;
      }
    }
  }

  responseTeamGrid?.setSelectedIDs(teamsToCheck);
  systemsGrid?.setSelectedIDs(systemsToCheck);
  playbookGrid?.setSelectedIDs(playbooksToCheck);
  setIncludeGeneralProcedures(generalProceduresIncluded);
};
