import {
  Category,
  ChartComponent,
  ColumnSeries,
  Crosshair,
  DataLabel,
  Export,
  Legend,
  LineSeries,
  SeriesCollectionDirective,
  SeriesDirective,
  SplineSeries,
  StripLine,
  Tooltip,
  Zoom,
} from "@syncfusion/ej2-react-charts";
import React, { useEffect, useState } from "react";
import { Inject } from "@syncfusion/ej2-react-base";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import DataLoader from "../../../../utils/LoadingComponents/DataLoader";
import { useLossToleranceChartData } from "../hooks/useLossToleranceChartData";
import { useGUID } from "../../../../hooks/functional/useGUID";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useSettings } from "../../../../hooks/views/useSettings";
import { convertCamelCaseToSentence, isNullOrUndefined } from "@rivial-security/func-utils";
import Card from "@mui/material/Card";
import AnnualLossChart from "./AnnualLossChart";
import RiskCurveWarning from "../../Systems/components/RiskCurveWarning";
import RiskCurveWarningAtResidualLoss from "../../Systems/components/RiskCurveWarningAtResidualLoss";
import { usePreferences } from "../../../../hooks/views/usePreferences/usePreferences";

/**
 * Displays Loss Tolerance curve for an Organization.
 *
 * @param {string} organizationID - currently selected organization
 * @param {boolean} inReport - TRUE if this chart is rendered offscreen for a report
 * @param {boolean} [disableSettings= false] - disables curve selection option
 * @param {object} [chartConfig = {}] - chart settings
 * @returns {JSX.Element}
 * @constructor
 */
const LossToleranceChart = ({ organizationID, inReport = false, disableSettings = false, chartConfig = {} }) => {
  const [selectedLines, setSelectedLines] = useState({
    riskToleranceCurve: true,
    riskResidualLoss: !disableSettings,
    systemResidualLoss: false,
    systemInherentLossCurve: false,
    systemResidualLossCurve: false,
    riskInherentLossCurve: !disableSettings,
    riskResidualLossCurve: !disableSettings,
  });
  const [showAnnualLossChart, setShowAnnualLossChart] = useState(false);

  const {
    lossToleranceCurve,
    primaryxAxis,
    primaryyAxis,
    isLoading,
    inherentLossCurve,
    residualLossCurve,
    riskInherentLossCurve,
    riskResidualLossCurve,
    lossToleranceExceedance,
    residualRisk,
  } = useLossToleranceChartData(organizationID, disableSettings, selectedLines, chartConfig);

  const zoomSettings = {
    enableSelectionZooming: true,
    enableScrollbar: true,
    enablePan: true,
  };

  const legendSettings = {
    visible: true,
    position: "Bottom",
  };

  const [id] = useGUID();

  const tooltip = {
    header: "<b>Chance of losing: ${point.x}</b>",
    enable: true,
    shared: true,
    format: "<b>${series.name} : ${point.y}</b>",
  };

  const crosshair = {
    enable: true,
  };

  const margin = {
    right: inReport ? 20 : 50,
  };

  const marker = {
    visible: true,
    width: 2,
    height: 2,
  };

  const handleChange = (event) => {
    setSelectedLines((curr) => {
      return {
        ...curr,
        [event.target.name]: event.target.checked,
      };
    });
  };

  const settings = useSettings(
    [
      <Card style={{ padding: "1em", opacity: 0.8 }}>
        <FormControl component="fieldset" variant="standard">
          <FormGroup>
            {selectedLines &&
              Object.entries(selectedLines).map(([key, value]) => {
                return (
                  <FormControlLabel
                    control={<Switch checked={value} onChange={handleChange} name={key} />}
                    label={convertCamelCaseToSentence(key)}
                  />
                );
              })}
          </FormGroup>
        </FormControl>
      </Card>,
    ],
    "Monte Carlo Chart Settings",
    {
      top: "4em",
    },
  );

  // Check if beta risk calculations are enabled
  const preferences = usePreferences();
  const betaRiskCalculationsEnabled = preferences?.getPreference("general", "beta_risk_calculations") || false;

  //Refresh the chart everytime the axis parameters change
  const [key, setKey] = useState(0);
  useEffect(() => {
    setKey((key) => key + 1);
  }, [primaryxAxis, primaryyAxis]);

  return (
    <div>
      <DataLoader isLoading={isLoading} isEnoughData={!isNullOrUndefined(lossToleranceCurve)}>
        {!disableSettings && !showAnnualLossChart && settings.display}
        {!showAnnualLossChart && (
          <ChartComponent
            id={`risk-tolerance-chart${id}`}
            key={key}
            zoomSettings={zoomSettings}
            legendSettings={legendSettings}
            primaryXAxis={primaryxAxis}
            primaryYAxis={primaryyAxis}
            useGroupingSeparator={true}
            tooltip={tooltip}
            crosshair={crosshair}
            margin={margin}
            selectedDataIndexes={{ series: 0, point: 0 }}
            selectionMode={"Series"}
          >
            <Inject
              services={[
                ColumnSeries,
                Legend,
                Tooltip,
                DataLabel,
                Category,
                StripLine,
                SplineSeries,
                Crosshair,
                Zoom,
                Export,
                LineSeries,
              ]}
            />
            <SeriesCollectionDirective>
              {selectedLines?.riskToleranceCurve && (
                <SeriesDirective
                  name="Risk Tolerance"
                  dataSource={lossToleranceCurve}
                  xName="x"
                  yName="y"
                  type="Line"
                  marker={marker}
                />
              )}
              {inherentLossCurve && selectedLines?.systemInherentLossCurve && (
                <SeriesDirective
                  name="System Inherent"
                  dataSource={inherentLossCurve}
                  xName="x"
                  yName="y"
                  type="Spline"
                />
              )}
              {residualLossCurve && selectedLines?.systemResidualLossCurve && (
                <SeriesDirective
                  name="System Residual"
                  dataSource={residualLossCurve}
                  xName="x"
                  yName="y"
                  type="Spline"
                />
              )}
              {riskInherentLossCurve && selectedLines?.riskInherentLossCurve && (
                <SeriesDirective
                  name="KRI Inherent"
                  dataSource={riskInherentLossCurve}
                  xName="x"
                  yName="y"
                  type="Spline"
                />
              )}
              {riskResidualLossCurve && selectedLines?.riskResidualLossCurve && (
                <SeriesDirective
                  name="KRI Residual"
                  dataSource={riskResidualLossCurve}
                  xName="x"
                  yName="y"
                  type="Spline"
                />
              )}
            </SeriesCollectionDirective>
          </ChartComponent>
        )}
        {showAnnualLossChart && <AnnualLossChart lossToleranceExceedance={lossToleranceExceedance} />}
        <div style={{ margin: "2em" }}>
          {betaRiskCalculationsEnabled ? (
            <RiskCurveWarning
              organizationID={organizationID}
              riskResidualLossCurve={riskResidualLossCurve}
              systemResidualLossCurve={residualLossCurve}
              systemResidualLossShown={selectedLines?.systemResidualLossCurve === true}
              enableChartSwitch={true}
              setShowAnnualLossChart={setShowAnnualLossChart}
              showAnnualLossChart={showAnnualLossChart}
            />
          ) : (
            <RiskCurveWarningAtResidualLoss
              organizationID={organizationID}
              riskResidualLossCurve={riskResidualLossCurve}
              riskResidualLoss={residualRisk}
            />
          )}
        </div>
      </DataLoader>
    </div>
  );
};

export default withOrganizationCheck(LossToleranceChart);
