import React from "react";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useQueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import { ItemQuery } from "../../../../utils/Functions/Graphql/ItemQuery";
import { ratingDictionary } from "../functions/ratingDictionary";
import CompletedBy from "../customFields/CompletedBy";
import Summary from "../customFields/Summary";

/**
 * Custom hook for viewing a Summary of a Vendor Review.
 * @param vendorReview
 * @param fields
 * @param module
 * @param resource
 * @param disableRoleChecking
 * @param getSummaryText
 * @returns {{display: *}}
 */
export const useSummary = ({ vendorReview, fields, module, resource, disableRoleChecking, getSummaryText }) => {
  const display = (
    <Display
      vendorReview={vendorReview}
      fields={fields}
      module={module}
      disableRoleChecking={disableRoleChecking}
      resource={resource}
      getSummaryText={getSummaryText}
    />
  );

  return {
    display,
  };
};

const Display = ({ vendorReview, getSummaryText, module, resource, disableRoleChecking }) => {
  const { getQuery } = generateGraphql("VendorReview", ["summary", "completedBy"]);

  const queryHook = useQueryGetItem({
    query: getQuery,
    itemId: vendorReview.id,
    disableRoleChecking: true,
  });

  return (
    <div className="e-card">
      <div className="e-card-content">
        <div className="dashboard-title e-card-title">Vendor Review Summary</div>
        <hr />
        <div>
          <div style={{ marginBottom: "1em" }}>
            <CompletedBy
              item={queryHook.item || {}}
              resetFunction={queryHook.reset}
              module={module}
              resource={resource}
              disableRoleChecking={disableRoleChecking}
            />
          </div>
          <div style={{ marginBottom: "1em" }}>
            <Summary
              item={queryHook.item || {}}
              resetFunction={queryHook.reset}
              getSummaryText={getSummaryText}
              module={module}
              resource={resource}
              disableRoleChecking={disableRoleChecking}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Gets the initial Summary HTML text for this vendorReview.
 * @param vendorReview
 * @returns {string}
 */
export const getSummary = async (vendorReview) => {
  const { getQuery } = generateGraphql("VendorReview", ["vendorSolution", "rating"], {
    vendorSolution: `{ id name vendor { id name } }`,
  });

  const queryData = await ItemQuery(getQuery, vendorReview.id);

  const vendorSolution = queryData && queryData.vendorSolution;
  const vendorSolutionName = vendorSolution && vendorSolution.name;
  const rating = ratingDictionary(queryData.rating) || "RATING";
  const vendorName = vendorSolution?.vendor?.name || vendorSolution?.name || "VENDOR NAME";

  let str = "";

  str = str.concat(
    `This Vendor Review was performed on ${new Date().toLocaleString()} to review supporting documents provided by ${vendorName} to assess the cybersecurity risk of the vendor’s Information System. \n\n`,
  );
  str = str.concat(`${vendorName} provides ${vendorSolutionName} products and services. \n\n`);
  str = str.concat(
    `The result is a ${rating} rating. The review details take into account the timeframe of testing. \n\n`,
  );

  return str;
};
