import { getListByFieldString } from "./getListByFieldString";
import { generateGraphql } from "@rivial-security/generategraphql";
import { getResourceQueryFields } from "./getResourceQueryFields";
import { getResourceNestedFields } from "./getResourceNestedFields";

/**
 * Creates a graphql queries and mutations taking into account default nested fields of the leaf resource
 * and the location of the query (field context). Useful for getting a list of deeply nested resources.
 * @param {string} startTypename - the root typename of the query
 * @param {string} endTypename - the final typename of the query
 * @param {object[]} path - the fields to traverse to get to the leaf resource from the root resource
 * @param {string} fieldContext - the context of the data that is displayed, modifies nested fields of the leaf resource to adhere to context
 * @returns {{createMutation: string, onCreate: string, onDelete: string, updateMutation: string, invokeFunction: function(*, *): string, onUpdate: string, getQuery: string, deleteMutation: string, listQuery: string, nestedFields: string}|null}
 */
export const generateNestedGraphql = ({ startTypename, endTypename, path, fieldContext }) => {
  // Check arguments
  if (!startTypename || !endTypename || !Array.isArray(path) || path.length === 0 || !fieldContext) {
    return null;
  }

  // Get the leaf resource fields
  const queryFields = getResourceQueryFields({
    typename: endTypename,
    fieldContext,
  });

  if (!Array.isArray(queryFields) || queryFields.length === 0) {
    return null;
  }

  //Build out nested fields
  const nestedFields = getResourceNestedFields({
    typename: endTypename,
    queryFields,
    fieldContext,
  });
  const fieldString = getListByFieldString({ queryFields, nestedFields });
  if (!fieldString) {
    return null;
  }

  const startField = path[0].name;

  let deepNestedField = "";
  let endBracketCount = 0;
  for (let i = 0; i < path.length; i++) {
    const pathItem = path[i];
    const { name, hasMany } = pathItem;

    if (hasMany) {
      if (i === 0) {
        deepNestedField += `(limit: 500) {`;
      } else {
        deepNestedField += `${name} (limit: 500) {`;
      }
    } else {
      if (i === 0) {
        deepNestedField += `{`;
      } else {
        deepNestedField += `${name} {`;
      }
    }
    endBracketCount++;

    if (hasMany) {
      deepNestedField += `items {`;
      endBracketCount++;
    }
  }

  //Add the end typename fields
  deepNestedField += fieldString;

  //Add the end brackets
  for (let i = 0; i < endBracketCount; i++) {
    deepNestedField += "}";
  }

  //Create the query string
  return {
    ...generateGraphql(startTypename, [startField], {
      [startField]: deepNestedField,
    }),
    nestedFields: deepNestedField,
  };
};
