import NumberChange from "../../../../../../../utils/CustomFields/NumberChange";
import { useTable } from "../../../../../../../hooks/views/useTable";
import SystemDetails from "../../../../../Systems/components/SystemDetails";
import KRIChangeImpactsSystemRisk from "./components/KRIChangeImpactsSystemRisk";
import KRIChangeImpactsSystemRiskTooltip from "./components/KRIChangeImpactsSystemRiskTooltip";

/**
 * The KRI residual risk changes table per system
 * @param {object} systemChanges - the system change part of monte carlo calculation results
 * @return {JSX.Element}
 */
const KeyRiskIndicatorRiskChanges = ({ systemChanges }) => {
  const customFields = [
    {
      field: "keyRiskIndicatorResidualChange",
      component: <NumberChange format="dollar" positiveNumber="red" negativeNumber="green" />,
      propName: "value",
      tooltip: (
        <p>
          These values represent the change in residual risk for the modified KRI. Please note that that if the impacted
          KRI does not have the highest residual risk out of all KRI for the shown system, the shown residual risk
          change may not be fully reflected in the system residual risk.`
        </p>
      ),
    },
    {
      field: "impactsSystemRisk",
      propName: "systemRiskValues",
      component: <KRIChangeImpactsSystemRisk />,
      tooltip: <KRIChangeImpactsSystemRiskTooltip />,
    },
  ];

  /**
   * Parses the risk values calculation from every system to retrieve just
   * the impacted KRI change for a system
   * @return {*[]}
   */
  const getData = () => {
    const data = [];
    if (Array.isArray(systemChanges)) {
      for (const systemChange of systemChanges) {
        const { residualRisk, recommendationResidualLoss } =
          systemChange?.systemRiskValues?.modifiedKeyRiskIndicatorData || {};

        const newEntry = {
          id: systemChange?.id,
          name: systemChange?.name,
          impactsSystemRisk: systemChange?.systemRiskValues,
        };

        //Find the residual risk change for the modified KRI
        if (Number.isFinite(residualRisk) && Number.isFinite(recommendationResidualLoss)) {
          newEntry.keyRiskIndicatorResidualChange = recommendationResidualLoss - residualRisk;
        } else {
          newEntry.keyRiskIndicatorResidualChange = null;
        }

        data.push(newEntry);
      }
    }
    return data;
  };

  const tableHook = useTable({
    data: getData(),
    fields: ["name", "keyRiskIndicatorResidualChange", "impactsSystemRisk"],
    fieldNameDictionary: {
      keyRiskIndicatorResidualChange: "Change in Residual Risk for KRI",
    },
    customFields,
    disableRoleChecking: true,
    detailsComponent: <SystemDetails />,
  });

  return <div>{tableHook.display}</div>;
};

export default KeyRiskIndicatorRiskChanges;
