import { useEffect, useState } from "react";
import { generateGraphqlFields } from "@rivial-security/generategraphql";
import { ListQueryBy } from "../../../../utils/Functions/Graphql/ListQueryBy";

/**
 * Queries Vendor Control Category templates and aggregates all the Sub-Controls for those templates
 * @param {string} organizationID - Organization ID
 * @param {function} setIsLoading - Set loading state
 */
export const useVendorSubControlTemplates = (organizationID, setIsLoading) => {
  // get vendor sub control templates
  const [subControls, setSubControls] = useState([]);

  useEffect(() => {
    if (typeof setIsLoading === "function") setIsLoading(true);

    const typename = "VendorControlCategory";
    const queryFields = ["name", "subControls", "isTemplate"];
    const nestedFields = {
      subControls: `(limit: 500) { items { id name statementNumber vendorControlCategoryID } }`,
    };

    const vendorControlCategoriesByOwnerGroup = /* GraphQL */ `
      query VendorControlCategoriesByOwnerGroup(
        $ownerGroup: String
        $sortDirection: ModelSortDirection
        $filter: ModelVendorControlCategoryFilterInput
        $limit: Int
        $nextToken: String
      ) {
        vendorControlCategoriesByOwnerGroup(
          ownerGroup: $ownerGroup
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ${generateGraphqlFields(queryFields, nestedFields)}
          }
          nextToken
        }
      }
    `;

    ListQueryBy({
      query: vendorControlCategoriesByOwnerGroup,
      variables: {
        ownerGroup: organizationID,
      },
      filter: {
        isTemplate: {
          eq: true,
        },
      },
    }).then((controlCategories) => {
      const controls = [];
      for (const category of controlCategories) {
        if (category?.subControls?.items) {
          controls.push(...category.subControls.items);
        }
      }
      setSubControls(controls);
      if (typeof setIsLoading === "function") setIsLoading(false);
    });
  }, [organizationID]);

  return {
    subControls,
    setSubControls,
  };
};
