import { modules, resources } from "@rivial-security/role-utils";
import Dashboard from "../../../utils/GenericComponents/Dashboard";
import Grid from "@mui/material/Grid";
import PageviewOutlinedIcon from "@mui/icons-material/PageviewOutlined";
import ModeEditIcon from "@mui/icons-material/ModeEditOutlined";
import ListAltIcon from "@mui/icons-material/ListAltOutlined";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import SaveAsOutlinedIcon from "@mui/icons-material/SaveAsOutlined";
import React, { useContext, useEffect, useState } from "react";
import { DOCUMENT_EDITOR_MODE, useDocumentEditor } from "../DocumentEditor/hooks/useDocumentEditor";
import Button from "@mui/material/Button";
import { useReportDocumentDrawer } from "./useReportDocumentDrawer";
import { useGetReport } from "./useGetReport";
import PermissionsOverlay from "../../../utils/Overlays/PermissionsOverlay";
import { useCheckPermissions } from "../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useHistory } from "react-router-dom";
import { saveNewReportDocumentVersion } from "../functions/saveNewReportDocumentVersion";
import { OrganizationContext } from "../../../utils/Context/OrganizationContext";
import { UIContext } from "../../../utils/Context/UIContext";
import { generateGraphql } from "@rivial-security/generategraphql";
import GenericEditFieldV3 from "../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";
import { getLatestReportDocumentVersion } from "../functions/getLatestReportDocumentVersion";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import LoadingButton from "@mui/lab/LoadingButton";
import { useReportDetailsTour } from "../DocumentEditor/tours/useDocumentEditorTour";
import { getFileType } from "../../../utils/FilePreviews/useFileViewer";
import FileViewer from "../../../utils/FilePreviews/FileViewer";
import { getSignedObjectUrlV2 } from "../../../utils/Functions/S3Storage/getSignedObjectUrlV2";
import { adaptSFDT } from "../DocumentEditor/functions/validateSFDT/adaptSFDT";
import { openReportDocument } from "../functions/openReportDocument";

/**
 * Report details hook. Renders a Report Document and it's versions.
 * If the report file is a .sfdt or word document file, it will be opened in the Document Editor.
 * If the report is another file type, it will be attempted to be opened in the File Viewer.
 *
 * @param {string} itemId - report id
 * @param {string} organizationID - selected organization
 * @param {object} props - other params
 * @returns {{display: JSX.Element}}
 */
export const useReportDetails = ({ itemId, organizationID, ...props }) => {
  const module = modules.REPORTS;
  const resource = resources.REPORT;

  const history = useHistory();

  const [mode, setMode] = useState(
    history?.location?.state?.editOnStart ? DOCUMENT_EDITOR_MODE.EDIT : DOCUMENT_EDITOR_MODE.VIEW,
  );

  const [selectedDocumentVersion, setSelectedDocumentVersion] = useState(null);

  /**
   * Hooks
   */
  const [isLoading, setIsLoading] = useState(false);
  const details = useGetReport({ itemId, resource, module });
  const documentEditor = useDocumentEditor({
    organizationID,
    mode,
    isExternalLoading: isLoading,
    defaultTabIndex: 2,
  });
  const drawer = useReportDocumentDrawer({
    documentEditor,
    item: details?.item,
    organizationID,
    setSelectedDocumentVersion,
    selectedDocumentVersion,
  });
  const checkPermissions = useCheckPermissions({ module, resource });
  const [sfdt, setSfdt] = useState(null);
  const [isEditMode, setIsEditMode] = useState(true);
  const [url, setUrl] = useState(null);

  const context = useContext(OrganizationContext);
  const { addToast, updateToast } = useContext(UIContext);

  const [isSaving, setIsSaving] = useState(false);

  /**
   * Saves a new version of the report document
   * @returns {Promise<void>}
   */
  const saveReportVersion = async () => {
    setIsSaving(true);

    const toastId = addToast({
      header: `Saving report..`,
      icon: "spinner",
      color: "success",
    });

    await saveNewReportDocumentVersion({
      item: details?.item,
      documentName: selectedDocumentVersion?.name,
      documentEditor,
      organizationID,
      context,
    })
      .then((data) => {
        updateToast({
          id: toastId,
          header: `Version ${data?.version} was successfully saved`,
          icon: "success",
        });
      })
      .catch(() =>
        updateToast({
          id: toastId,
          header: `Report Document version was NOT saved`,
          icon: "danger",
        }),
      )
      .finally(() => {
        details?.resetFunction();
        setIsSaving(false);
      });
  };

  /**
   * Gets the latest version of the report document object and save into state
   */
  useEffect(() => {
    if (details?.item) {
      const latestReportDocument = getLatestReportDocumentVersion({
        report: details?.item,
      });

      if (latestReportDocument) {
        setSelectedDocumentVersion(latestReportDocument);
      }
    }
  }, [details?.item]);

  /**
   * Check if the Report is type SFDT or Word Doc
   */
  const checkIsEditable = ({ selectedDocumentVersion }) => {
    const key = selectedDocumentVersion?.file?.key;
    if (key) {
      const fileType = getFileType(key)?.toLowerCase();
      if (fileType === "sfdt" || fileType === "docx" || fileType === "doc") {
        return true;
      }
    } else {
      return false;
    }
  };

  /**
   * Open latest report document based on the version
   */
  const getReportSFDT = async () => {
    if (selectedDocumentVersion && organizationID) {
      setIsLoading(true);

      setIsEditMode(true);

      const sfdt = await openReportDocument({
        reportDocument: selectedDocumentVersion,
        organizationID,
      });

      //Adapt old sfdt versions to the new one
      adaptSFDT({ sfdt });

      setSfdt(sfdt);
      setIsLoading(false);
    }
  };

  /**
   * Get the signed URL of the report document
   * @param {string} organizationID - selected organization
   * @param {object} selectedDocumentVersion - selected report document version
   * @param {object} selectedDocumentVersion.file - selected report document version file
   * @param {string} selectedDocumentVersion.file.key - selected report document version file key
   * @returns {Promise<void>}
   */
  const getReportURL = async ({ selectedDocumentVersion, organizationID }) => {
    if (selectedDocumentVersion && organizationID) {
      setIsLoading(true);

      setIsEditMode(false);

      const url = await getSignedObjectUrlV2({
        key: selectedDocumentVersion?.file?.key,
        organizationID,
      });

      setUrl(url);
      setIsLoading(false);
    }
  };

  /**
   * Checks if the file is SFDT or a Word doc to open in DocumentEditor,
   * otherwise opens the file in FileViewer using the URL
   */
  useEffect(() => {
    if (selectedDocumentVersion) {
      if (checkIsEditable({ selectedDocumentVersion })) {
        getReportSFDT();
      } else {
        getReportURL({ selectedDocumentVersion, organizationID });
      }
    }
  }, [selectedDocumentVersion && organizationID]);

  /**
   * If there is SFDT, open it in DocumentEditor
   */
  useEffect(() => {
    if (sfdt) {
      documentEditor?.ref?.documentEditor?.open(sfdt);
    }

    return () => {
      documentEditor?.ref?.documentEditor?.destroy();
    };
  }, [sfdt, !isNullOrUndefined(documentEditor?.ref)]);

  const subtitle = (
    <div data-tourid={"report-details-version-name"}>
      {selectedDocumentVersion && (
        <GenericEditFieldV3
          item={selectedDocumentVersion}
          module={module}
          resource={resource}
          field={"name"}
          mutation={generateGraphql("ReportDocument", ["name"]).updateMutation}
          resetFunction={details?.resetFunction}
        />
      )}
      {selectedDocumentVersion?.name && <br />}
      {selectedDocumentVersion?.version && (
        <span>
          <strong>Version:</strong> v{selectedDocumentVersion?.version}
        </span>
      )}
    </div>
  );

  // Welcome tour
  const disable = mode === DOCUMENT_EDITOR_MODE.EDIT;
  useReportDetailsTour({ disable });

  const display = (
    <PermissionsOverlay module={module} resource={resource} operationType={"read"}>
      <div data-tourid={"report-details-container"}>
        {isEditMode &&
        // only use the document editor if the file is SFDT or Word doc, otherwise use file viewer
        checkIsEditable({ selectedDocumentVersion }) ? (
          <div>
            {drawer?.display}
            {
              <Dashboard
                resetFunction={details.resetFunction}
                subTitle={subtitle}
                headerButtons={[
                  ...(checkPermissions?.resource?.read
                    ? [
                        <Button
                          data-tourid={"report-details-download-button"}
                          key={"download"}
                          className={"float-right"}
                          disabled={isLoading}
                          onClick={() =>
                            documentEditor?.ref?.documentEditor?.save(selectedDocumentVersion?.name, "Docx")
                          }
                          startIcon={<CloudDownloadIcon />}
                        >
                          Download
                        </Button>,
                      ]
                    : []),

                  // View and edit modes toggle
                  ...(checkPermissions?.resource?.update && mode === DOCUMENT_EDITOR_MODE.VIEW
                    ? [
                        <Button
                          data-tourid={"report-details-edit-button"}
                          className={"float-right"}
                          onClick={() => setMode(DOCUMENT_EDITOR_MODE.EDIT)}
                          startIcon={<ModeEditIcon />}
                        >
                          Edit Mode
                        </Button>,
                      ]
                    : []),
                  ...(mode === DOCUMENT_EDITOR_MODE.EDIT
                    ? [
                        <Button onClick={() => setMode(DOCUMENT_EDITOR_MODE.VIEW)} startIcon={<PageviewOutlinedIcon />}>
                          View Mode
                        </Button>,
                      ]
                    : []),

                  <Button
                    data-tourid={"report-details-versions-button"}
                    onClick={() => drawer?.setIsOpen(true)}
                    startIcon={<ListAltIcon />}
                  >
                    Versions
                  </Button>,
                  ...(checkPermissions?.resource?.update && mode === DOCUMENT_EDITOR_MODE.EDIT
                    ? [
                        <LoadingButton
                          data-tourid={"report-details-save-button"}
                          onClick={saveReportVersion}
                          startIcon={<SaveAsOutlinedIcon />}
                          loading={isSaving}
                          loadingPosition={"start"}
                        >
                          Save
                        </LoadingButton>,
                      ]
                    : []),
                ]}
              >
                <Grid container spacing={2}>
                  <Grid item lg={12} xs={12}>
                    {documentEditor.display}
                  </Grid>
                </Grid>
              </Dashboard>
            }
          </div>
        ) : (
          /**
           * If the report is not a SFDT, display the report in the FileViewer
           */
          <Dashboard
            headerButtons={[
              ...(checkPermissions?.resource?.read
                ? [
                    <Button
                      data-tourid={"report-details-download-button"}
                      key={"download"}
                      className={"float-right"}
                      disabled={isLoading}
                      onClick={() => {
                        getSignedObjectUrlV2({
                          bucket: selectedDocumentVersion?.file?.bucket,
                          key: selectedDocumentVersion?.file?.key,
                          organizationID,
                        }).then((url) => window.open(url, "_blank"));
                      }}
                      startIcon={<CloudDownloadIcon />}
                    >
                      Download
                    </Button>,
                  ]
                : []),
            ]}
          >
            <FileViewer url={url} />
          </Dashboard>
        )}
      </div>
    </PermissionsOverlay>
  );

  return {
    display,
  };
};
