import { useEffect, useState } from "react";

import { ErrorLogger } from "@utils/EventLogger";
import { GetQuery } from "../../../../../../utils/Functions/Graphql/GetQuery";
import { ListQueryBy } from "../../../../../../utils/Functions/Graphql/ListQueryBy";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useBoolean } from "../../../../../../hooks/functional/useBoolean";

/**
 * Fetches a list of Systems that have one or more Non-Standard Risk Controls
 * @param {string} organizationID - the currently selected organization for which to fetch systems
 * @param {string} [selectedRiskControlID] - if provided will only retrieve systems which have the selected risk control
 * @returns {{systems: *[]}}
 */
export const useAvailableSystems = ({ organizationID, selectedRiskControlID }) => {
  const [systems, setSystems] = useState([]);
  const [isLoading, setIsLoading] = useBoolean(false);

  // Query Systems for the 'Select System Dropdown', filter to only show systems with active Non-Standard Controls
  useEffect(() => {
    const getData = async ({ selectedRiskControlID }) => {
      setIsLoading(true);

      try {
        if (!selectedRiskControlID) {
          const systems = await ListQueryBy({
            query: listSystemsByOwnerGroup,
            limit: 1000,
            variables: {
              ownerGroup: organizationID,
            },
          });

          if (Array.isArray(systems)) {
            setSystems(systems);
          } else {
            setSystems([]);
          }
        } else {
          //Grab the risk control information
          const getRiskControlQuery = generateGraphql("RiskControl", ["controlCategoryID"]).getQuery;
          const riskControl = await GetQuery({
            query: getRiskControlQuery,
            variables: { id: selectedRiskControlID },
          });

          if (!riskControl?.controlCategoryID) {
            setSystems([]);
            throw new Error("Risk Control not found");
          }

          //Get all systems that have the control category attached
          const systemRiskControlCategoryLinks = await ListQueryBy({
            query: systemControlCategoryLinksByControlCategory,
            limit: 1000,
            variables: {
              riskControlCategoryID: riskControl.controlCategoryID,
            },
          });

          let systems = [];
          if (Array.isArray(systemRiskControlCategoryLinks)) {
            for (const systemRiskControlCategoryLink of systemRiskControlCategoryLinks) {
              const system = systemRiskControlCategoryLink?.system;
              if (system) {
                systems.push(system);
              }
            }
          }

          systems = systems.filter(
            (system) =>
              Array.isArray(system?.riskControlOverrides) &&
              system?.riskControlOverrides?.some((item) => item.riskControlId === selectedRiskControlID),
          );

          setSystems(systems);
        }
      } catch (e) {
        ErrorLogger(e);
      }

      setIsLoading(false);
    };

    getData({ selectedRiskControlID });
  }, [selectedRiskControlID]);

  return { systems, isLoading };
};

const nestedSystemFields = `
  id
  ownerGroup
  name
  description
  riskControlOverrides {
    riskControlId
    costOfControl
    enabledFields
    implementationDetails
    implementationRating
  }
`;

const listSystemsByOwnerGroup = /* GraphQL */ `
  query SystemsByOwnerGroup(
    $ownerGroup: String
    $sortDirection: ModelSortDirection
    $filter: ModelSystemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    systemsByOwnerGroup(
      ownerGroup: $ownerGroup
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${nestedSystemFields}
      }
      nextToken
    }
  }
`;

const systemControlCategoryLinksByControlCategory = /* GraphQL */ `
  query SystemControlCategoryLinksByControlCategory(
    $riskControlCategoryID: ID
    $systemID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSystemControlCategoryLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    systemControlCategoryLinksByControlCategory(
      riskControlCategoryID: $riskControlCategoryID
      systemID: $systemID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerGroup
        system {
          ${nestedSystemFields}
        }
      }
      nextToken
    }
  }
`;
