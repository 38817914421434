import { Button, Input, Tooltip } from "reactstrap";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useCheckPermissions } from "../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { OrganizationContext } from "../../Context/OrganizationContext";
import { v4 as uuid } from "uuid";
import { useRichTextEditor } from "../../../hooks/editors/useRichTextEditor";
import Note from "./Note";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { Link } from "@mui/material";
import Loader from "../../LoadingComponents/Loader";

/**
 * @description UI part of the Notes component
 * @param {boolean} isLoading - TRUE when the notes data is still not available in 'notes' (still being retrieved)
 * @param {string} [label] - label to be displayed above notes, next to tooltip, if not present will use "Notes"
 * @param {object[]} notes - the notes data to be displayed
 * @param {function} createNote - callback for creating a new note
 * @param {function} updateNote - callback for updating a existing note
 * @param {function} deleteNote - callback for deleting a note
 * @param {boolean} showHorizontalRule - if TRUE will add a horizontal divider above the notes UI
 * @param {string} module - platform module the note is a part of, for role checking
 * @param {string} resource - platform resource the note is a part of, for role checking
 * @param {string} organizationID - the currently selected organization, becomes the ownerGroup of new notes
 * @param {boolean} disableRoleChecking - TRUE if there's no need to check module or resource access in role checking
 * @param {string} tooltip - help text to display when hovering next to the title
 * @param {boolean} disableTitle - if TRUE will not show the text "Notes" above the notes UI
 * @param {string} observationConnectionField - field to create connection between item and observation
 * @param {JSXElement} observationFormOverride - optional JSX element to override the Observation form
 * @param {object} item - parent object that the Note will be attached to
 * @param {boolean} disableEdits - if TRUE will not show the edit or delete buttons
 * @param {number} maxInitialNotes - maximum number of notes to show before hiding the rest behind a "Show More" button, -1 for no limit
 * @param {function} onCreateObservation - callback for creating an observation from a note
 * @param {string} auditID - for explicitly connecting Observations to an Audit
 * @param {string} evidenceID - for explicitly connecting Observations to an Evidence
 * @param {string} exerciseID - for explicitly connecting Observations to an Exercise
 * @param {string} complianceControlID - for explicitly connecting Observations to a Compliance Control
 * @param {string} riskControlID - for explicitly connecting Observations to a Risk Control
 * @param {string} vulnerabilityID - for explicitly connecting Observations to a Vulnerability
 * @param {string} incidentID - for explicitly connecting Observations to an Incident
 * @param {string} artifactID - for explicitly connecting Observations to an Artifact
 * @param {string} meetingID - for explicitly connecting Observations to a Meeting
 */
const NoteUI = ({
  isLoading,
  label = "Notes",
  notes,
  createNote,
  updateNote,
  deleteNote,
  showHorizontalRule,
  module,
  resource,
  organizationID,
  disableRoleChecking,
  tooltip,
  disableTitle,
  observationConnectionField,
  observationFormOverride,
  item,
  disableEdits = false,
  maxInitialNotes = -1,
  onCreateObservation,
  auditID,
  evidenceID,
  exerciseID,
  complianceControlID,
  riskControlID,
  vulnerabilityID,
  incidentID,
  artifactID,
  meetingID,
}) => {
  const checkPermissionsHook = useCheckPermissions({
    module,
    resource,
    field: "notes",
    disableRoleChecking: disableRoleChecking ? disableRoleChecking : undefined,
  });

  const useRichEditor = useRichTextEditor({
    editorStyle: {
      height: "15em",
    },
  });

  const context = useContext(OrganizationContext);

  const [editNote, setEditNote] = useState(null);
  const [showHiddenNotes, setShowHiddenNotes] = useState(false);
  const [componentID] = useState(uuid());
  const [showRichTextEditor, setShowRichTextEditor] = useState(false);
  const [isTooltipTargetReady, SetIsTooltipTargetReady] = useState(false);
  const [isTooltipOpen, SetIsTooltipOpen] = useState(false);

  //Setting tooltip target when component is rendered
  //Fixes memory leak: https://www.gitmemory.com/issue/reactstrap/reactstrap/773/488689148
  const toolTipTarget = useRef(null);
  useEffect(() => {
    if (toolTipTarget.current) {
      SetIsTooltipTargetReady(true);
    }
  }, [toolTipTarget.current]);

  function toggleTooltip() {
    SetIsTooltipOpen((isTooltipOpen) => !isTooltipOpen);
  }

  const getNotes = () => {
    if (!Array.isArray(notes)) return [];

    let amountOfShownNotes = 9999999;
    if (maxInitialNotes > 0 && showHiddenNotes === false) {
      amountOfShownNotes = maxInitialNotes;
    }

    //sort notes by most recent
    notes = notes.sort((a, b) => new Date(b.timeStamp) - new Date(a.timeStamp));

    if (Array.isArray(notes) && notes.length > 0) {
      let shownNotes = notes;
      if (amountOfShownNotes > 0 && notes.length > amountOfShownNotes) {
        //show the x most recent notes
        shownNotes = notes.slice(0, amountOfShownNotes);
      }

      const showSeeMoreButton = shownNotes.length < notes.length && amountOfShownNotes > 0;
      const amountHidden = notes.length - shownNotes.length;

      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginBottom: "1em",
            gap: "1em",
          }}
        >
          {shownNotes
            .sort((a, b) => new Date(a.timeStamp) - new Date(b.timeStamp))
            .map((note) => (
              <Note
                key={note && note.id}
                note={note}
                updateNote={updateNote}
                deleteNote={deleteNote}
                module={module}
                organizationID={organizationID}
                resource={resource}
                disableRoleChecking={disableRoleChecking}
                useRichEditor={useRichEditor}
                author={context.userEmail}
                observationConnectionField={observationConnectionField}
                observationFormOverride={observationFormOverride}
                item={item}
                disableEdits={disableEdits}
                onCreateObservation={onCreateObservation}
                auditID={auditID}
                evidenceID={evidenceID}
                exerciseID={exerciseID}
                complianceControlID={complianceControlID}
                riskControlID={riskControlID}
                vulnerabilityID={vulnerabilityID}
                incidentID={incidentID}
                artifactID={artifactID}
                meetingID={meetingID}
              />
            ))}
          {showSeeMoreButton && (
            <Link
              component="button"
              variant="body2"
              title={"View all older notes"}
              onClick={() => {
                setShowHiddenNotes(true);
              }}
            >
              {`See ${amountHidden} older ${amountHidden === 1 ? "note" : "notes"}`}
            </Link>
          )}
        </div>
      );
    }
  };

  return (
    <span data-testid="noteui-container">
      {checkPermissionsHook.resource.read && (
        <span data-testid="noteui-container-body">
          {!disableTitle && (
            <>
              {showHorizontalRule && <hr />}
              <strong data-testid="noteui-title">
                {label || "Notes"}
                {tooltip && (
                  <span data-testid="noteui-tooltip">
                    <i
                      style={{
                        marginRight: "1em",
                        marginLeft: "1em",
                        cursor: "pointer",
                      }}
                      ref={toolTipTarget}
                      className="icon-question"
                    />
                    {isTooltipTargetReady && (
                      <Tooltip target={toolTipTarget.current} isOpen={isTooltipOpen} toggle={toggleTooltip}>
                        {tooltip}
                      </Tooltip>
                    )}
                  </span>
                )}
              </strong>
              <br />
              <br />
            </>
          )}
          {isLoading && (
            <Loader
              dataTestId={"noteui-loading"}
              containerStyle={{ paddingTop: "2em", paddingBottom: "2em" }}
              text={"Loading Notes"}
            />
          )}

          {!isLoading && getNotes()}
          {checkPermissionsHook.resource.update && !disableEdits && (
            <div>
              {showRichTextEditor ? (
                useRichEditor.display
              ) : (
                <Input
                  onClick={() => setShowRichTextEditor(true)}
                  placeholder="Start writing a new note..."
                  title={"Click to start writing a new Note"}
                />
              )}
              {showRichTextEditor && (
                <div>
                  <Button
                    size="sm"
                    color="ghost-success"
                    className="btn-pill float-right"
                    onClick={() => {
                      const htmlValue = useRichEditor?.getValue();

                      /**
                       * Check if value is empty
                       */
                      if (isNullOrUndefined(htmlValue) || htmlValue === "" || htmlValue === "<p><br></p>") {
                        alert("Note can not be empty!");
                        return;
                      }

                      if (typeof createNote === "function") {
                        createNote({
                          id: uuid(),
                          content: useRichEditor?.getValue(),
                          author: context?.userEmail,
                          ownerGroup: organizationID,
                          timeStamp: new Date(),
                        });

                        setShowRichTextEditor(false);
                        useRichEditor.setValue("");
                      }
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    color="ghost-danger"
                    className="btn-pill float-right"
                    size="sm"
                    onClick={() => {
                      setShowRichTextEditor(false);
                      useRichEditor.setValue("");
                      setEditNote(null);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              )}
            </div>
          )}
          {(!checkPermissionsHook.resource.update || disableEdits) && (!Array.isArray(notes) || notes.length === 0) && (
            <p style={{ fontSize: ".9em", fontStyle: "italic" }}>
              {isLoading ? "Loading Notes..." : "No Notes to Show"}
            </p>
          )}
        </span>
      )}
    </span>
  );
};

export default NoteUI;
