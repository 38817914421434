import DataLoader from "../../../../../utils/LoadingComponents/DataLoader";
import NoPlaybookAvailable from "../../../PlaybookBuilder/components/NoPlaybookAvailable";
import PermissionsOverlay from "../../../../../utils/Overlays/PermissionsOverlay";
import PlaybookProcedureTabs from "./PlaybookProcedureTabs";
import React from "react";
import a from "indefinite";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useCheckPermissions } from "../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useUIContext } from "@utils/Context/UIContext";
import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";

/**
 * Displays the Playbook for a particular resource with the 'procedures: [ProcedureGroup]' field defined
 * @param {string} module - the app module to use for role checking
 * @param {string} resource - the resource being edited, also used for role checking
 * @param {string} [typename] - the schema model name to use in mutations, only provide if different from 'resource'
 * @param {object} item - the resource for which to display a playbook ('procedures' property)
 * @param {function} resetFunction - function re-fetch data from the database
 * @param {boolean} [disableEdits = true] - if TRUE will prevent changes form being made to the steps content,
 * NOTE: will be automatically set to FALSE when there are no steps available in all groups for a particular procedure
 * @param {function} setTotalCompletion - function callback that takes in an updated playbook item to updated total percentage
 * @param {function} setPlaybookBuilderModalIsOpen - can be used to either open or close the playbook builder
 * @param {string} observationConnectionField - the field for the connection to the observation
 * @param {string} exerciseID - for explicitly connecting Observations to an Exercise
 * @param {string} incidentID - for explicitly connecting Observations to an Incident
 * @returns {JSX.Element}
 */
const GroupPlaybook = ({
  module,
  resource,
  typename,
  item,
  resetFunction,
  disableEdits: disableEditsInit = true,
  setTotalCompletion,
  setPlaybookBuilderModalIsOpen,
  observationConnectionField,
  exerciseID,
  incidentID,
}) => {
  if (!typename) {
    typename = resource;
  }

  const checkPermissionsHook = useCheckPermissions({ module, resource });
  const disableEditsPermissions = !checkPermissionsHook.resource.update;
  const disableEdits = disableEditsInit || disableEditsPermissions;

  const { isMobile } = useUIContext();

  return (
    <div>
      <DataLoader
        isEnoughData={Array.isArray(item?.procedures) && item?.procedures?.length > 0}
        isLoading={isNullOrUndefined(item)}
        notEnoughDataChildren={
          <PermissionsOverlay
            module={module}
            resource={resource}
            operationType={"update"}
            isTooltipWarning={false}
            tooltipText={`This playbook doesn't have any content. You also do not have the necessary permissions to update ${a(
              typename,
            )} playbook.`}
          >
            <NoPlaybookAvailable
              openPlaybookBuilder={() => {
                setPlaybookBuilderModalIsOpen(true);
              }}
              typename={resource}
              item={item}
              resetFunction={resetFunction}
            />
          </PermissionsOverlay>
        }
      >
        <PlaybookProcedureTabs
          typename={typename}
          orientation={isMobile ? "horizontal" : "vertical"}
          item={item}
          enableCheckbox={true}
          disableEdits={disableEdits}
          resetFunction={resetFunction}
          manageEditsSwitch={!disableEditsPermissions} // when no permission to edit hide edits switch
          disableCheckboxEdits={disableEditsPermissions}
          tabContentStyle={{ height: "100%" }}
          setTotalCompletion={setTotalCompletion}
          enableStepNotes={true}
          showUpstreamUpdateSwitch={true}
          notesProps={{
            observationConnectionField,
            exerciseID,
            incidentID,
            module,
            resource,
            disableRoleChecking: false,
          }}
          // NOTE: use below if need notes below each checklist
          // enableProcedureNotes={true}
        />
      </DataLoader>
    </div>
  );
};

export default withOrganizationCheck(GroupPlaybook);
