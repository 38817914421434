import React from "react";
import { useModal } from "../../../../../../../hooks/views/useModal";
import { useCheckPermissions } from "../../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { generateGraphql } from "@rivial-security/generategraphql";
import { ItemMutation } from "../../../../../../../utils/Functions/Graphql/ItemMutation";
import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import { modules, resources } from "@rivial-security/role-utils";
import { updateSystemVendorSolution } from "../functions/updateSystemVendorSolution";
import { useUIContext } from "@utils/Context/UIContext";
import { performToastOperation } from "../../../../../../../utils/Toasts/functions/toastOperation";
import CreateButton from "../../../../../../../utils/GenericComponents/CreateButton";
import { useForm } from "../../../../../../../hooks/views/useForm";
import { SelectItemGrid } from "../../../../../../../utils/GenericComponents/SelectItemGrid";
import SelectOutsourcedControlCategories from "./SelectOutsourcedControlCategories";
import { enableNonStandardRiskControl } from "../../../Overrides/functions/OverrideFunctions";
import { invokeGraphqlFunction } from "../../../../../../../utils/Functions/Graphql/invokeGraphqlFunction";
import { vendorReviewFinalizationActionTypes } from "../constants/vendorReviewFinalizationActionTypes";
import { useVendorSolutionDataGrid } from "../../../../../../Vendor/VendorSolutions/hooks/useVendorSolutionDataGrid";

/**
 * @description Attach a vendor solution to a system
 * @param {object} system -
 * @param {function} reset - reset the state
 * @param {string} organizationID - selected organization
 * @returns {JSX.Element}
 * @constructor
 */
const AddVendorSolutionToSystem = ({ system, reset, organizationID }) => {
  const module = modules.RISK;
  const resource = resources.INFORMATION_SYSTEM;
  const field = "vendorSolution";

  const checkPermissionsHook = useCheckPermissions({ module, resource, field });
  const { addToast, updateToast } = useUIContext();

  const performUpdate = async (input) => {
    const { vendorSolution, controlCategories } = input || {};

    //Check input
    if (!vendorSolution) {
      return;
    }

    await performToastOperation({
      addToast,
      updateToast,
      operation: async () => {
        await updateSystemVendorSolution({
          system,
          vendorSolution,
          toggleModal: () => {
            addVendorSolutionModal.setModalIsOpen(false);
          },
          resetFunction: () => {
            reset && reset();
          },
        });
      },
      inProgressText: "Linking Vendor Solution...",
      failedText: "Failed to link a vendor solution",
      successText: "Successfully linked a new vendor solution",
      iconColor: "success",
    });

    if (Array.isArray(controlCategories) && controlCategories?.length > 0) {
      await performToastOperation({
        addToast,
        updateToast,
        operation: async () => {
          //Iterate over all selected control category controls and create overrides for them marked as outsourced
          const updatedSystem = {
            id: system?.id,
            riskControlOverrides: system?.riskControlOverrides || [],
          };
          for (const controlCategory of controlCategories) {
            const riskControls = controlCategory?.subControls?.items;
            if (Array.isArray(riskControls)) {
              for (const riskControl of riskControls) {
                updatedSystem.riskControlOverrides = enableNonStandardRiskControl(system, riskControl, null, {
                  outsourced: true,
                });
              }
            }
          }

          const { updateMutation } = generateGraphql("System");
          await ItemMutation(updateMutation, { ...updatedSystem });
          reset && reset();

          performToastOperation({
            addToast,
            updateToast,
            operation: async () => {
              invokeGraphqlFunction("Query", "runVendorReviewFinalizationActions", {
                vendorSolutionID: vendorSolution?.id,
                actionsToPerform: [vendorReviewFinalizationActionTypes.RISK_CHANGES],
                riskChangeReason: `Vendor Solution "${vendorSolution?.name || "Untitled Solution"}" from the Vendor "${
                  vendorSolution?.vendor?.name || "Untitled Vendor"
                }" has been linked to the systems.`,
              });
            },
            inProgressText: `Syncing outsourced controls...`,
            successText: `Started checking for risk changes. Please wait a few minutes for resources to update.`,
            failedText: `Failed to begin creating risk changes!`,
            iconColor: "success",
          });
        },
        inProgressText: "Marking Controls as outsourced...",
        failedText: "Failed to mark all selected control categories as outsourced",
        successText: "Successfully marked all selected control categories as outsourced",
        iconColor: "success",
      });
    }
  };

  const vendorSolutionAttachForm = useForm({
    //MUTATION
    typename: resources.INFORMATION_SYSTEM,
    module: modules.RISK,
    resource: resources.INFORMATION_SYSTEM,
    submitFunction: performUpdate,

    //FIELDS
    fieldConfig: {
      vendorSolution: {
        label: "Vendor Solution",
        tooltip: "Select which vendor solution to attach to the System.",
        inputType: "item-select",
        itemSelectConfig: {
          grid: <SelectItemGrid gridHook={useVendorSolutionDataGrid} organizationID={organizationID} />,
          typename: resources.VENDOR_SOLUTION,
          formatSelectedItem: (vendorSolution) => vendorSolution?.name,
          outputAsObject: true,
        },
        required: true,
      },
      controlCategories: {
        label: "Outsourced Control Categories",
        tooltip: "All controls under the selected categories will be marked as outsourced for this system.",
        inputType: "custom",
        customConfig: {
          component: <SelectOutsourcedControlCategories system={system} />,
        },
      },
    },
  });

  const addVendorSolutionModal = useModal(
    "Link a Vendor Solution",
    <div style={{ minHeight: "70vh", height: "70vh" }}>{vendorSolutionAttachForm.display}</div>,
    <CreateButton
      title={
        checkPermissionsHook.resource.update
          ? "Add Vendor Solution for this System"
          : "You don't have Update Permissions on System field: Vendor Solution"
      }
      disabled={!checkPermissionsHook.resource.update}
    />,
    { width: "60vw" },
  );

  return addVendorSolutionModal.modalButton;
};

export default withOrganizationCheck(AddVendorSolutionToSystem);
