import React, { useState } from "react";
import {
  Button,
  ButtonDropdown,
  Card,
  CardBody,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  Table,
} from "reactstrap";
import PropTypes from "prop-types";
import GenericEditField from "../../../../../../../utils/GenericComponents/GenericEditField";
import { GenericDeleteButton } from "../../../../../../../utils/GenericComponents/GenericDeleteButton";
import styled from "styled-components";
import KeyRiskIndicatorThreats from "./KeyRiskIndicatorThreats";
import KeyRiskIndicatorCIA from "./KeyRiskIndicatorCIA";
import RiskOverridesV2 from "../../../Overrides/components/RiskOverridesV2";
import InherentRiskMonteCarlo from "./InherentRiskMonteCarlo";
import ResidualRiskMonteCarlo from "./ResidualRiskMonteCarlo";
import ReturnOnInvestment from "../../ControlCategories/components/ReturnOnInvestment";
import RiskMapping from "../../RiskScorecard/components/RiskMapping";
import AccordionIcon from "../../../../../../../utils/GenericComponents/AccordionIcon";
import AnnualRateOfOccurrenceModifier from "./AnnualRateOfOccurrenceModifier";
import DeleteRisk from "../../../../functions/deleteFunctions/DeleteRisk";
import { removeRiskFromSystem } from "../functions/removeRiskFromSystem";
import KeyRiskIndicatorNotes from "./KeyRiskIndicatorNotes";

// Styling Components
const StyledDropdownMenu = styled(DropdownMenu)`
  min-width: 100px;
`;

/**
 * Author: Jacob Blazina
 * Created At: 08/19/19
 * Modified: Anatoli Railean
 * Edits:
 *
 *
 * Description: A Table Row component.
 *              Displays single Risk information.
 *              Has optional Checkbox field.
 *              Has Fields and Options props.
 *              Has "Highlighted" functionality with parent list component.
 */
const KeyRiskIndicatorItem = (props) => {
  const [optionsButtonIsOpen, setOptionsButtonIsOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const riskLink =
    props.item.ownerGroup === "Admin" ? `#/admin_panel/global_risks/${props.item.id}` : `#/risk/risks/${props.item.id}`;

  const fieldSelector = (field, item) => {
    if (field === "probabilityModifier") {
      return (
        <div onClick={(e) => e.stopPropagation()}>
          <AnnualRateOfOccurrenceModifier
            resetFunction={props.resetFunction}
            systemRiskLink={props.systemRiskLink}
            disableRoleChecking={props.disableRoleChecking}
          />
        </div>
      );
    }

    // If Overrides are enabled
    if (props.enableOverrides === true) {
      if (field === "annualRateOfOccurrence") {
        return (
          <RiskOverridesV2
            isAdmin={props.isAdmin}
            risk={props.item}
            system={props.system}
            field={field}
            resetFunction={props.resetFunction}
          />
        );
      }

      if (field === "confidenceIntervalLower") {
        return (
          <RiskOverridesV2
            isAdmin={props.isAdmin}
            risk={props.item}
            system={props.system}
            field={field}
            resetFunction={props.resetFunction}
          />
        );
      }

      if (field === "confidenceIntervalUpper") {
        return (
          <RiskOverridesV2
            isAdmin={props.isAdmin}
            risk={props.item}
            system={props.system}
            field={field}
            resetFunction={props.resetFunction}
          />
        );
      }
    }

    // Else return field as plain text using GenericEditField component
    return (
      <GenericEditField
        mutation="updateRiskControl"
        item={props.item}
        field={field}
        disableEdits={props.disableEdits || !props.isAdmin}
      />
    );
  };

  return (
    <>
      <tr
        key={props.item.id}
        onClick={() => setIsOpen(!isOpen)}
        style={{
          cursor: "pointer",
          border: isOpen ? "solid 2px lightBlue" : "solid 1px rgba(0,0,0,.125)",
          borderRadius: ".25rem",
          padding: ".75rem 1.25rem",
          zIndex: isOpen ? 1000 : undefined,
        }}
        title="View KRI Details"
        className={isOpen ? "shadow" : undefined}
      >
        {props.fields &&
          props.fields.map((field, index) => (
            <td key={JSON.stringify(field) + index}>{fieldSelector(field, props.item)}</td>
          ))}
        <td>
          <AccordionIcon style={{ className: "float-center" }} isOpen={isOpen} />
        </td>
        {props.isAdmin && props.options && props.options.length > 0 ? (
          <td>
            <>
              <ButtonDropdown
                size="sm"
                direction="down"
                isOpen={optionsButtonIsOpen}
                toggle={() => setOptionsButtonIsOpen(!optionsButtonIsOpen)}
              >
                <DropdownToggle color="ghost-info">
                  <i className="icon-wrench" />
                </DropdownToggle>
                <StyledDropdownMenu>
                  {props.options.includes("details") ? (
                    <Button name="risk-details" color="ghost-primary" size="sm" id={props.item.id} href={riskLink}>
                      <i className="icon-bulb" />
                      {"  "} Risk Details
                    </Button>
                  ) : null}
                  {props.options.includes("remove_system_risk_link") ? (
                    <Button
                      key={`remove_system_risk_link_${props.item.id}`}
                      name="risk-details"
                      color="ghost-danger"
                      size="sm"
                      id={props.item.id}
                      onClick={() => removeRiskFromSystem(props.item, props.system)}
                    >
                      <i className="icon-close" />
                      {"  "} Remove From System
                    </Button>
                  ) : null}
                  {props.isAdmin && props.options.includes("delete") ? (
                    <GenericDeleteButton
                      deleteFunction={DeleteRisk}
                      item={props.item}
                      title="  Delete Risk"
                      className="btn"
                      resetFunction={props.resetFunction}
                    />
                  ) : null}
                </StyledDropdownMenu>
              </ButtonDropdown>
            </>
          </td>
        ) : null}
      </tr>
      <tr style={{ display: !isOpen ? "none" : undefined }}>
        <td colSpan={2}>
          <Collapse isOpen={isOpen}>
            <Card className={isOpen ? "shadow" : undefined}>
              <CardBody>
                <strong>Probability Modifier</strong>
                <div style={{ marginBottom: "1em", marginTop: "0.5em" }}>
                  <AnnualRateOfOccurrenceModifier
                    resetFunction={props.resetFunction}
                    systemRiskLink={props.systemRiskLink}
                    disableRoleChecking={props.disableRoleChecking}
                  />
                </div>
                <hr />
                <strong>
                  Relevant Impact Ratings{" "}
                  <i
                    className="icon-question"
                    title="Select if Confidentiality, Integrity, and/or Availability Impacts are applicable to this KRI"
                  />
                </strong>
                <div style={{ marginTop: "0.5em" }}>
                  <KeyRiskIndicatorCIA
                    systemRiskLink={props.systemRiskLink}
                    resetFunction={props.resetFunction}
                    disableRoleChecking={props.disableRoleChecking}
                  />
                </div>
                <hr />
                {props.viewType === "analysis" && (
                  <>
                    <strong>Risk Amount Interval</strong>
                    <br />
                    <br />
                    <Table>
                      <tbody>
                        <tr>
                          <th scope="row">Lower Amount:</th>
                          <td>
                            <RiskOverridesV2
                              isAdmin={props.isAdmin}
                              risk={props.item}
                              system={props.system}
                              systemRiskLink={props.systemRiskLink}
                              field="confidenceIntervalLower"
                              resetFunction={props.resetFunction}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Upper Amount:</th>
                          <td>
                            <RiskOverridesV2
                              isAdmin={props.isAdmin}
                              risk={props.item}
                              system={props.system}
                              systemRiskLink={props.systemRiskLink}
                              field="confidenceIntervalUpper"
                              resetFunction={props.resetFunction}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <hr />
                    <props.Context>
                      <InherentRiskMonteCarlo
                        system={props.system}
                        risk={props.item}
                        resetFunction={props.resetFunction}
                      />
                      <br />
                      <br />
                      <ResidualRiskMonteCarlo risk={props.item} />
                      <br />
                      <br />
                      <ReturnOnInvestment controlCategory={props.item && props.item.controlCategory} />
                    </props.Context>
                  </>
                )}
                <hr />
                {props.system ? (
                  <KeyRiskIndicatorThreats
                    organizationID={props.organizationID}
                    systemRiskLink={props.systemRiskLink}
                    resetFunction={props.resetFunction}
                    disableRoleChecking={props.disableRoleChecking}
                    viewType={props.viewType}
                  />
                ) : (
                  <div>
                    Loading Threats.. <Spinner size="sm" color="primary" />
                  </div>
                )}
                <hr />
                <RiskMapping
                  systemRiskLink={props.systemRiskLink}
                  resetFunction={props.resetFunction}
                  disableRoleChecking={props.disableRoleChecking}
                />
                <hr />
                <KeyRiskIndicatorNotes
                  item={props.systemRiskLink}
                  disableRoleChecking={props.disableRoleChecking}
                  system={props.system}
                />
              </CardBody>
            </Card>
          </Collapse>
        </td>
      </tr>
    </>
  );
};

KeyRiskIndicatorItem.propTypes = {
  item: PropTypes.object,
  backGround: PropTypes.string,
  setLastSelected: PropTypes.func,
  isAdmin: PropTypes.bool,
  organizationID: PropTypes.string,
  showSelectBox: PropTypes.bool,
  fields: PropTypes.array,
  options: PropTypes.array,
  system: PropTypes.object, // If enabling overrides, pass system graphql object
  disableEdits: PropTypes.bool, // Disables Edit buttons on all resources and Verify Controls buttons from Options
  enableOverrides: PropTypes.bool, // Allows access the System-level overrides UI.
};

export default KeyRiskIndicatorItem;
