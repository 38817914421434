import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useAssessmentFalsePositives } from "../hooks/useAssessmentFalsePositives";
import React from "react";

/**
 * Display FalsePositive Findings list for an Assessment component
 * @param {object} props
 * @returns {JSX.Element}
 */
const AssessmentFalsePositives = (props) => {
  const display = useAssessmentFalsePositives(props).gridDisplay;
  return <div style={{ height: "30em" }}>{display}</div>;
};

export default withOrganizationCheck(AssessmentFalsePositives);
