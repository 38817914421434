import React, { useEffect } from "react";
import { STEP_STATUS, usePleaseWaitModal } from "../../../../hooks/views/usePleaseWaitModal";
import { convertCamelCaseToSentence, isNullOrUndefined } from "@rivial-security/func-utils";

import DepartmentGrid from "../../../OrganizationManager/Departments/components/DepartmentGrid";
import ImportActions from "../../Actions/components/ImportActions";
import ImportButton from "../../../../utils/GenericComponents/ImportButton";
import { arrayIncludes } from "../../../../utils/Functions/Array/arrayIncludes";
import { createFullObservation } from "../functions/createFullObservation";
import { createObservationRecommendationLink } from "../functions/createObservationRecommendationLink";
import { moduleTypes } from "../../moduleTypes";
import { modules } from "@rivial-security/role-utils";
import { recommendationRatings } from "../../recommendationRatings";
import { useAddRecommendation } from "../../Recommendations/hooks/useAddRecommendation";
import { useForm } from "../../../../hooks/views/useForm/useForm";
import { useModal } from "../../../../hooks/views/useModal";

/**
 * Form for creating an Observation with associated Recommendations and Action Items.
 *
 * Note: to disable the option for adding Existing Recommendations:
 *
 * disabledFields={["addExistingRecommendation"]}
 *
 * @param {string} organizationID - selected organization id
 * @param {object} dataField - field with custom data
 * @param {object} fieldNameDictionary - map field name with desired display name
 * @param {string} module - module observation belongs to
 * @param {function} updateInputFunction - function for updating the input
 * @param {function} callback - function called after an observation created
 * @param {function} onSubmit - called on form submit
 * @param {function} toggleModal - toggle the modal
 * @param {function} resetFunction - re-fetch data from DB
 * @param {function} getNewItem - add new observation to the grid
 * @param {string} dataTooltip - tool tip for creating an item
 * @param {boolean} defaultOpenRecommendationForm
 * @param {string} source - the source of this observation (LEGACY FIELD)
 * @param {object} externalSource - optional. pass in an existing 'Source' object to connect to Observation
 * @param {object} recommendation - optional. creates a link to recommendation if passed in
 * @param {string[]} disabledFields - optional. array of field names that will be disabled (but not hidden) in the form
 * @param {object} config - configuration object
 * @param {object} config.complianceControl - connects this Observation with a single compliance control
 * @param {object[]} config.complianceControls - creates this Observation separately for each compliance control, but links to the same recommendations
 */
export const useCreateObservation = ({
  organizationID,
  dataField,
  fieldNameDictionary,
  module,
  updateInputFunction,
  callback,
  onSubmit,
  toggleModal,
  resetFunction,
  getNewItem,
  dataTooltip,
  defaultOpenRecommendationForm,
  source,
  externalSource,
  recommendation,
  disabledFields = [],
  ...config
}) => {
  const addRecommendationForm = useAddRecommendation({
    organizationID,
    disableSubmitButton: true,
    disableResetButton: true,
    dataField,
    module,
    updateInputFunction,
    isOpen: true,
    defaultOpenRecommendationForm,
    dataTooltip,
    disableExistingRecommendations: arrayIncludes(disabledFields, "addExistingRecommendation"),
  });

  const pleaseWaitModal = usePleaseWaitModal({
    subTitle: `Adding Observations to ${config?.complianceControls?.length} Controls..`,
    progressTotal: config?.complianceControls?.length,
    steps: config?.complianceControls?.map((control) => {
      return {
        text: `${control.statementNumber}: ${control.name}`,
        status: STEP_STATUS.WAITING,
      };
    }),
  });

  const submitFunction = async (input) => {
    // departmentID cannot be an empty string or null
    if (input?.departmentID === "") {
      delete input.departmentID;
    }

    if (config?.meeting?.id) {
      input.meetingID = config?.meeting?.id;
    }

    onSubmit && onSubmit(input);
    let newObservation = {};
    if (!config.disableMutation) {
      // Handle multiple Compliance Controls when performing an Audit.
      // This creates one observation for each Control, but then links the singular Recommendations and Action Items to each observation
      if (!isNullOrUndefined(config?.complianceControls)) {
        pleaseWaitModal.setModalIsOpen(true);
        pleaseWaitModal.setStepStatus(0, STEP_STATUS.IN_PROGRESS);

        newObservation = await createFullObservation({
          module: module,
          ownerGroup: organizationID,
          riskControlID: config?.riskControl?.id,
          complianceControlID: config?.control?.id || config?.complianceControls[0]?.id,
          auditID: config?.audit?.id,
          vulnerabilityID: config?.vulnerability?.id,
          incidentID: config.incident?.id,
          exerciseID: config.exercise?.id,
          source: source || undefined,
          evidenceID: config?.evidence?.id,
          departmentID: config?.department?.id,
          artifactID: config?.artifact?.id,
          assessmentID: config?.assessment?.id,
          meetingID: config?.meeting?.id,
          status: "open",
          needsDecision: false,
          decision: "none",
          ...input,
        });

        const recommendations = [];

        if (newObservation?.recommendations?.items && Array.isArray(newObservation.recommendations.items)) {
          for (const recommendation of newObservation.recommendations.items) {
            recommendations.push({
              ...recommendation,
              alreadyExists: true,
            });
          }
        }

        pleaseWaitModal.incrementProgress();
        pleaseWaitModal.setStepStatus(0, STEP_STATUS.COMPLETE);

        // Handle creating duplicate observations for each compliance control, if they are passed in
        for (let i = 1; i < config?.complianceControls.length; i++) {
          pleaseWaitModal.setStepStatus(i, STEP_STATUS.IN_PROGRESS);

          await createFullObservation({
            module: module,
            ownerGroup: organizationID,
            complianceControlID: config?.complianceControls[i]?.id,
            auditID: config?.audit?.id,
            departmentID: config?.department?.id,
            recommendations: recommendations,
            source: source || undefined,
            status: "open",
            needsDecision: false,
            decision: "none",
            ...input,
          });
          pleaseWaitModal.incrementProgress();
          pleaseWaitModal.setStepStatus(i, STEP_STATUS.COMPLETE);
        }
      }

      // If no controls are passed to this component, create a single observation
      else {
        newObservation = await createFullObservation({
          module: module,
          ownerGroup: organizationID,
          riskControlID: config?.riskControl?.id,
          complianceControlID: config?.control?.id || config?.complianceControl?.id,
          vulnerabilityID: config?.vulnerability?.id,
          exerciseID: config?.exercise?.id,
          incidentID: config.incident?.id,
          evidenceID: config?.evidence?.id,
          externalSourceID: externalSource?.id,
          auditID: config?.audit?.id,
          departmentID: config?.department?.id,
          artifactID: config?.artifact?.id,
          assessmentID: config?.assessment?.id,
          status: "open",
          needsDecision: false,
          decision: "none",
          ...input,
        });
      }

      pleaseWaitModal.setModalIsOpen(false);
      callback && callback(newObservation);
    }

    // if recommendation is passed in, create link
    if (!isNullOrUndefined(recommendation)) {
      await createObservationRecommendationLink(newObservation, recommendation);
    }

    toggleModal && toggleModal();
    addRecommendationForm.setInput([]);
    return newObservation;
  };

  const importerModal = useModal(
    "Import Observations, Recommendations, and Action Items",
    <ImportActions source={externalSource} audit={config?.audit} />,
    <ImportButton />,
    { width: "90vw", disableClickToggle: true },
  );

  const form = useForm({
    header: <span>Create an Observation</span>,
    headerButtons: [importerModal.modalButton],
    organizationID,
    getNewItem,
    enableToast: true,
    submitFunction,
    resetCallback: () => {
      //Removes all added recommendations from the list in the form
      addRecommendationForm.setInput([]);
    },
    fieldConfig: {
      name: {
        label: [fieldNameDictionary?.name || "Name"],
        tooltip: "The primary content of this observation.",
        inputType: "textarea",
        required: true,
      },
      description: {
        label: "Description",
        inputType: "textarea",
        tooltip: "Some more descriptive information regarding this Observation",
      },
      module: {
        label: "Software Module",
        tooltip: "What part of the Rivial Software Platform should this be categorized into?",
        inputType: "dropdown",
        required: true,
        defaultValue: module || modules.GOVERNANCE,
        dropdownConfig: {
          data: moduleTypes.map((item) => {
            return {
              value: item,
              text: convertCamelCaseToSentence(item),
            };
          }),
        },
      },
      isFinding: {
        label: "Finding or Observation",
        tooltip: "Is this simply an Observation or a more serious Finding?",
        inputType: "dropdown",
        required: true,
        defaultValue: true,
        dropdownConfig: {
          data: [
            {
              value: true,
              text: "Finding - an Observation that poses a larger threat to the organization's security",
            },
            {
              value: false,
              text: "Observation - an empirical fact that has some influence on the organization's security",
            },
          ],
        },
      },
      risk: {
        label: "Risk Level",
        tooltip: "A relative Risk level for this Observation",
        inputType: "dropdown",
        defaultValue: "info",
        dropdownConfig: {
          data: recommendationRatings.map((item) => {
            return {
              value: item,
              text: convertCamelCaseToSentence(item),
            };
          }),
        },
      },
      status: {
        label: "Status",
        tooltip: "The current status of this Observation",
        inputType: "dropdown",
        defaultValue: "open",
        dropdownConfig: {
          data: ["open", "closed", "pastDue"].map((item) => {
            return {
              value: item,
              text: convertCamelCaseToSentence(item),
            };
          }),
        },
      },
      departmentID: {
        label: "Department",
        tooltip: "The Department that this Observation is associated with",
        inputType: "item-select",
        defaultValue: config?.department?.id,
        itemSelectConfig: {
          grid: <DepartmentGrid organizationID={organizationID} />,
        },
        disabled: arrayIncludes(disabledFields, "departmentID"),
      },
      needsDecision: {
        label: "Needs Decision",
        tooltip: "Does this Observation need a decision?",
        inputType: "switch",
        defaultValue: false,
      },
      decision: {
        label: "Decision",
        tooltip: "The decision made regarding this Observation",
        inputType: "dropdown",
        defaultValue: "none",
        dropdownConfig: {
          data: ["none", "accept", "mitigate", "transfer", "avoid"].map((item) => {
            return {
              value: item,
              text: convertCamelCaseToSentence(item),
            };
          }),
        },
      },
      formalResponse: {
        label: "Management Response",
        inputType: "textarea",
      },
      estimatedCompletionDate: {
        label: "Estimated Completion Date",
        tooltip:
          "The date at which the Observation is expected to be resolved. After this date, open observations will be marked as past due.",
        inputType: "date",
        defaultValue: null,
      },
      recommendations: {
        inputType: "custom",
        label: "Recommendations",
        tooltip: "Recommended response(s) to this Observation",
        customConfig: {
          component: addRecommendationForm.display,
        },
      },
    },
    ...config,
  });
  // if status/decision/isFinding is undefined , set status to open and decision to none and isFinding to true by default
  useEffect(() => {
    const { status, decision, isFinding } = form.input || {};
    if (status === undefined || decision === undefined || isFinding === undefined) {
      form.setInput((input) => {
        return {
          ...input,
          status: status || "open",
          decision: decision || "none",
          isFinding: isFinding || true,
        };
      });
    }
  }, [form.input]);

  useEffect(() => {
    if (addRecommendationForm.input) {
      form.setInput((input) => {
        return { ...input, recommendations: addRecommendationForm.input };
      });
    }
  }, [addRecommendationForm.input]);

  const setRecommendationData = (data) => {
    form.setInput((input) => {
      return {
        ...input,
        data,
      };
    });
  };

  return {
    ...form,
    setRecommendationData,
  };
};
