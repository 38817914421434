import { useModal } from "../../../../hooks/views/useModal";
import MeetingSelectPointOfContact from "../../../Program/Meetings/customFields/MeetingSelectPointOfContact";
import React from "react";
import { useSync } from "../../../../hooks/functional/useSync";
import { createPointOfContactResponseTeamLink } from "../functions/createPointOfContactResponseTeamLink";

/**
 * For attaching Points of Contact to a Response Team
 * @param item
 * @param toggleModal
 * @param organizationID
 * @param props
 * @returns {{setModalIsOpen: function(*=): void, modalButton: JSX.Element, modalIsOpen: boolean, modal: JSX.Element}}
 */
export const useAttachPointOfContacts = ({ item, toggleModal, organizationID, resetFunction, ...props }) => {
  const [contacts, setContacts] = useSync(item, () => {
    return item?.pointOfContactLinks?.items.map((pointOfContactLink) => pointOfContactLink.pointOfContact) || [];
  });

  const submit = async (selectedContacts) => {
    for (const selectedContact of selectedContacts) {
      const index = contacts.findIndex((a) => a.id === selectedContact.id);

      // If contact already attached, don't attach again
      if (index === -1) {
        await createPointOfContactResponseTeamLink(item, selectedContact, organizationID);
      }
    }

    resetFunction && resetFunction();
  };

  const pointOfContactModal = useModal(
    "Add Contacts to this Response Team",
    <MeetingSelectPointOfContact
      organizationID={organizationID}
      setContacts={async (contacts) => await submit(contacts)}
      contacts={Array.isArray(contacts) ? [...contacts] : []}
      toggleModal={toggleModal}
    />,
  );

  return {
    ...pointOfContactModal,
  };
};
