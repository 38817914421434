import { generateGraphql } from "@rivial-security/generategraphql";
import { useDetailsCard } from "../../../../hooks/views/useDetailsCardV2";
import AuditControls from "../components/AuditControls";
import React from "react";
import AuditIsComplete from "../components/AuditIsComplete";
import AuditDate from "../components/AuditDate";
import ControlSetName from "../customFields/ControlSetName";
import { modules, resources } from "@rivial-security/role-utils";
import AutomationsButton from "../../../OrganizationManager/Automations/components/AutomationsButton";

/**
 * Displays Audit Details for a user
 * @param {string} itemId - the audit for which to show details
 * @param {string} organizationID - the currently selected organizations
 * @param {object} queryConfig - settings for retrieving data
 * @param {object} detailsConfig - settings passed down to use details card hook
 */
export const useAuditDetails = ({ itemId, queryConfig = {}, detailsConfig = {} }) => {
  const module = modules.COMPLIANCE;
  const resource = resources.AUDIT;
  const typename = "Audit";
  const queryFields = [
    "name",
    "controlSet",
    "controlSetID",
    "auditDate",
    "completionPercentage",
    "isComplete",
    "controls",
    "notes",
  ];
  const nestedFields = {
    controls: `(limit: 1000) {
      items {
        id
        isCompliant
        auditor
        auditID
        interviewee
        notes { id type ownerGroup author timeStamp content tag observationID }
        control {
          id
          statementNumber
          name
          customFieldData
          tags (limit: 100) { items { __typename id tag { id name description fontColor backgroundColor } } }
        }
        evidenceLinks (limit: 500) {
          items {
            id
            reviewed
            notes { id type ownerGroup author timeStamp content tag observationID }
            evidence { id name ownerGroup }
          }
        }
      }
    }`,
    controlSet: `{id name}`,
    notes: `{ id author timeStamp content observationID }`,
  };

  const fields = ["name", "controlSet", "completionPercentage", "isComplete", "auditDate"];
  const customFields = [
    {
      field: "controls",
      component: <AuditControls />,
    },
    {
      field: "isComplete",
      component: <AuditIsComplete />,
    },
    {
      field: "controlSet",
      component: <ControlSetName />,
      friendlyName: "Control Framework",
    },
    {
      field: "auditDate",
      component: <AuditDate />,
    },
  ];

  const { getQuery, updateMutation } = generateGraphql(typename, queryFields, nestedFields);

  queryConfig = {
    query: getQuery,
    itemId,
    ...queryConfig,
  };

  detailsConfig = {
    fields,
    customFields,
    updateMutation,
    module,
    resource,
    ...detailsConfig,
  };

  const config = {
    enableNotes: true,
    headerButtons: [<AutomationsButton itemId={itemId} typename={"Audit"} />],
    observationConnectionField: "auditID",
  };

  const details = useDetailsCard({
    module,
    resource,
    typename,
    queryConfig,
    detailsConfig,
    config,
  });

  return {
    ...details,
  };
};
