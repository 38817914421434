import React from "react";
import { modules, resources } from "@rivial-security/role-utils";
import { useModal } from "../../hooks/views/useModal";
import { getIcon } from "../Functions/Icon/getIcon";
import { withOrganizationCheck } from "../Context/withOrganizationCheck";
import PermissionsOverlay from "../Overlays/PermissionsOverlay";
import Button from "@mui/material/Button";
import DocumentEditor from "../../views/Reports/DocumentEditor/components/DocumentEditor";
import { Tooltip } from "@mui/material";

/**
 * @description Generic component to generate a report
 * @param {string} itemId - id of an item
 * @param {string} type - report type
 * @param {string} module - report module
 * @param {string} organizationID - selected organization
 * @param {boolean} disableReportButton - disable report button
 * @param {string} buttonTooltipText - tooltip text
 * @returns {JSX.Element}
 */
const GenerateReportModal = ({
  itemId,
  type,
  module,
  organizationID,
  disableReportButton = false,
  buttonTooltipText,
}) => {
  const modal = useModal(
    "Generate Report",
    <div style={{ display: "flex", height: "100%", maxHeight: "100%", flex: 1 }}>
      <DocumentEditor
        organizationID={organizationID}
        type={type}
        module={module}
        inModal={true}
        itemId={itemId}
        defaultTemplate={type}
      />
      ,
    </div>,
    "",
    {
      width: "90vw",
    },
  );

  return (
    <PermissionsOverlay
      module={modules.REPORTS}
      resource={resources.REPORT}
      operationType={"create"}
      isTooltipWarning={true}
      tooltipText={"You do not have the necessary permissions to generate new reports."}
    >
      <div>
        {modal?.modal}
        <Tooltip title={buttonTooltipText}>
          <span>
            <Button
              onClick={() => {
                modal.setModalIsOpen(true);
              }}
              startIcon={getIcon("vscode-icons:file-type-word2")}
              disabled={disableReportButton}
            >
              Generate Report
            </Button>
          </span>
        </Tooltip>
      </div>
    </PermissionsOverlay>
  );
};

export default withOrganizationCheck(GenerateReportModal);
