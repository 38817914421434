import { ErrorLogger } from "../../EventLogger";
import { locationType } from "../../../analytics/constants/locationType";
import { operationType } from "../../../analytics/constants/operationType";
import { reasonType } from "../../../analytics/constants/reasonType";

/**
 * Finds the object difference between old and new array of objects checking by a single field. Optionally performing
 * the unlink and link operations.
 * @param {object[]} oldItems - the previous array of objects
 * @param {object[]} newItems - the new array of items that should be present
 * @param {function} [linkFunction] - function to optionally link all new items that are not in old
 * @param {function} [unlinkFunction] - function to optionally unlink all old items that are not in new
 * @param {string} primaryKeyField - the field that uniquely identifies each object in the old and new arrays
 * @param {boolean} parallelSafe - if TRUE, will perform the link unlink operations in parallel.
 */
export const linkUnlinkItems = async ({
  oldItems,
  newItems,
  linkFunction,
  unlinkFunction,
  primaryKeyField,
  parallelSafe = false,
} = {}) => {
  //Check arguments
  if (!Array.isArray(oldItems) || !Array.isArray(newItems)) {
    ErrorLogger("Invalid parameters into linkUnlink items, oldItems and newItems need to be arrays!", {
      location: locationType.FUNCTION,
      operation: operationType.UPDATE,
      reason: reasonType.INVALID_PARAM,
    });
    return {
      linkItems: [],
      unlinkItems: [],
    };
  }

  //Determines which items to link
  const linkItems = newItems.filter((newItem) => {
    //check if item has the primary key, doesn't add to link if not
    if (!newItem || !newItem.hasOwnProperty(primaryKeyField)) {
      return false;
    }

    return !oldItems.some((oldItem) => {
      if (!oldItem || !oldItem.hasOwnProperty(primaryKeyField)) {
        return false;
      }

      return oldItem[primaryKeyField] === newItem[primaryKeyField];
    });
  });

  //Determines which items to unlink
  const unlinkItems = oldItems.filter((oldItem) => {
    //check if item has the primary key, doesn't add to unlink if not
    if (!oldItem || !oldItem.hasOwnProperty(primaryKeyField)) {
      return false;
    }

    return !newItems.some((newItem) => {
      if (!newItem || !newItem.hasOwnProperty(primaryKeyField)) {
        return false;
      }
      return newItem[primaryKeyField] === oldItem[primaryKeyField];
    });
  });

  //Links items
  if (linkFunction) {
    const linkItemFunction = async (item) => {
      try {
        await linkFunction({ item });
      } catch (e) {
        ErrorLogger(e);
      }
    };

    if (parallelSafe) {
      const allLinkRequests = [];
      linkItems.forEach((linkItem) => {
        allLinkRequests.push(linkItemFunction(linkItem));
      });
      await Promise.allSettled(allLinkRequests);
    } else {
      for (const linkItem of linkItems) {
        await linkItemFunction(linkItem);
      }
    }
  }

  //Unlinks items
  if (unlinkFunction) {
    const unlinkItemFunction = async (item) => {
      try {
        await unlinkFunction({ item });
      } catch (e) {
        ErrorLogger(e);
      }
    };

    if (parallelSafe) {
      const allUnlinkRequests = [];
      unlinkItems.forEach((unlinkItem) => {
        allUnlinkRequests.push(unlinkItemFunction(unlinkItem));
      });
      await Promise.allSettled(allUnlinkRequests);
    } else {
      for (const unlinkItem of unlinkItems) {
        await unlinkItemFunction(unlinkItem);
      }
    }
  }

  //Return items to link and unlink
  return {
    linkItems,
    unlinkItems,
  };
};
