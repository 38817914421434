import { modules, resources } from "@rivial-security/role-utils";

import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { UIContext } from "../../../../utils/Context/UIContext";
import { createToastOperation } from "../../../../utils/Toasts/functions/toastOperation";
import { generateGraphql } from "@rivial-security/generategraphql";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useContext } from "react";
import { useForm } from "../../../../hooks/views/useForm/useForm";
import useListQuery from "../../../../hooks/graphql/useListQuery";
import { useUploadTargets } from "./useUploadTargets";

/**
 * Hook for creating a new Security Testing Target object in the database
 * @param {string} organizationID - selected organization id
 * @param {function} [getNewItem] - callback for the data after creation
 * @param {function} [toggleModal] - a function to close the modal that this component is in
 * @param {object} ...config - additional configuration options to pass to the useForm hook
 * @returns {{display: *, input: {}}}
 */
export const useCreateTarget = ({ organizationID, getNewItem, toggleModal, ...config }) => {
  //Global hooks
  const { addToast, updateToast } = useContext(UIContext);

  //Resource Settings
  const module = modules.VULNERABILITIES;
  const resource = resources.TARGET;
  const typename = resources.TARGET;

  //Create mutation
  const fields = ["ip", "macAddress", "hostName", "targetAssessmentId", "disabled"];
  const { createMutation } = generateGraphql(typename, fields);

  //Existing targets check
  const { listQuery } = generateGraphql("Target", ["hostName", "ip", "macAddress"]);
  const getListTargetsHook = useListQuery({
    query: listQuery,
    organizationID,
    disableRoleChecking: true,
  });
  const onSubmit = async (input) => {
    const { ip, macAddress } = input;

    if (getListTargetsHook.list) {
      //Find if input has unique values, only check mac address uniqueness if its provided
      let checkTarget;
      if (macAddress && macAddress !== "") {
        checkTarget = getListTargetsHook.list.find((data) => data.ip === ip || data.macAddress === macAddress);
      } else {
        checkTarget = getListTargetsHook.list.find((data) => data.ip === ip);
      }

      if (isNullOrUndefined(checkTarget)) {
        return await createToastOperation({
          addToast,
          updateToast,
          typename,
          operation: () =>
            ItemMutation(createMutation, {
              ...input,
              ownerGroup: organizationID,
            }),
        });
      } else {
        alert("Target with this IP address or MAC address Already Exist!");
      }
    }
  };

  const csvImporter = useUploadTargets({
    organizationID,
  });

  const form = useForm({
    typename,
    organizationID,
    getNewItem,
    toggleModal,
    fieldConfig: {
      ip: {
        label: "IP Address",
        tooltip: "The unique Internet Protocol address that identifies this target on the local network.",
        required: true,
        validationFunction: (ip) => {
          const ipRegex = new RegExp(
            "^(?=\\d+\\.\\d+\\.\\d+\\.\\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\\.?){4}$",
          );
          return ipRegex.test(ip);
        },
        validationText: "Please, make sure your IP address is correct. Ex: 192.168.0.1",
      },
      macAddress: {
        label: "MAC Address",
        tooltip: "The unique media access control address of the target.",
        validationFunction: (macAddress) => {
          //allow empty mac address
          if (macAddress === "") return true;

          const macRegex = new RegExp("^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$");
          return macRegex.test(macAddress);
        },
        validationText: "Please, make sure your MAC address is correct. Ex: 3D:F2:C9:A6:B3:4F",
      },
      hostName: {
        label: "Host Name",
        tooltip: "A descriptive name for this target.",
      },
      disabled: {
        defaultValue: "no",
        isHidden: true,
      },
    },
    module,
    resource,
    mutation: createMutation,
    submitFunction: onSubmit,
    header: <span>Create a Target</span>,
    headerButtons: [csvImporter.modalButton],
    ...config,
  });

  return form;
};
