import GenericEditFieldV3 from "../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";
import { modules, resources } from "@rivial-security/role-utils";
import ConfigureTriggerCondition from "./ConfigureTriggerCondition";
import StyledWrapper from "../../../../utils/GenericComponents/StyledWrapper";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 * Component capable of displaying a read only view of the trigger conditions, but also allows to edit them when needed
 * @param {string} organizationID - the id of currently selected organization
 * @param {Automation} item - an existing Automation resource, to be edited using this UI
 * @param {string} itemId - the automation if of the trigger being configured
 * @param {boolean} isTemplate - determines if the form is being used to create a template
 * @param {string} module - platform module for front-end role checking
 * @param {string} resource - platform resource for front-end role checking
 * @param {function} updateItemById - function to update the item by id
 * @returns {JSX.Element} - custom field to display automation conditions
 */
const AutomationTriggerConditionsCustomField = ({
  organizationID,
  item,
  isTemplate,
  module = modules.ORGANIZATION_MANAGER,
  resource = resources.AUTOMATION,
  updateItemById,
}) => {
  //Mutation to update the automation
  const { updateMutation } = generateGraphql("Automation", ["conditions"], {
    conditions: `{
        graphql
        filters
        typename
        fields
        rule
      }`,
  });

  //Input and display GUI for the conditions
  return (
    <GenericEditFieldV3
      field={"conditions"}
      item={item}
      customDisplayComponent={
        <ConfigureTriggerCondition
          organizationID={organizationID}
          item={item}
          isTemplate={isTemplate}
          disableEdits={true}
        />
      }
      customInputComponent={
        <StyledWrapper
          renameProps={{
            setValue: "onChangeCallback",
          }}
        >
          <ConfigureTriggerCondition organizationID={organizationID} item={item} isTemplate={isTemplate} />
        </StyledWrapper>
      }
      mutation={updateMutation}
      updateItemById={updateItemById}
      module={module}
      resource={resource}
      disableEdits={item?.enabled === true}
    />
  );
};

export default AutomationTriggerConditionsCustomField;
