import React, { useEffect } from "react";
import { convertCamelCaseToSentence, isNullOrUndefined } from "@rivial-security/func-utils";

import ImportActions from "../../Actions/components/ImportActions";
import ImportButton from "../../../../utils/GenericComponents/ImportButton";
import { createFullRecommendation } from "../functions/createFullRecommendation";
import { createObservationRecommendationLink } from "../../Observations/functions/createObservationRecommendationLink";
import { createRecommendationActionItemLink } from "../../Actions/functions/createRecommendationActionItemLink";
import { moduleTypes } from "../../moduleTypes";
import { recommendationRatings } from "../../recommendationRatings";
import { useAddActionItems } from "../../Actions/hooks/useAddActionItems";
import { useForm } from "../../../../hooks/views/useForm/useForm";
import { useModal } from "../../../../hooks/views/useModal";

/**
 * Creates a new RecommendationItem
 * @param organizationID
 * @param onAdd
 * @param onRemove
 * @param toggleModal
 * @param dataField
 * @param module
 * @param getNewItem
 * @param dataTooltip
 * @param actionItem
 * @param observation
 * @param props
 */
export const useCreateRecommendation = ({
  organizationID,
  onAdd,
  onRemove,
  toggleModal,
  dataField,
  module,
  getNewItem,
  dataTooltip,
  actionItem,
  observation,
  ...props
}) => {
  const actionForm = useAddActionItems({
    organizationID,
    disableSubmitButton: true,
    disableResetButton: true,
    onAdd,
    onRemove,
    module,
  });

  const submitFunction = async (input) => {
    let recommendation = {};
    if (!props.disableMutation) {
      recommendation = await createFullRecommendation({ ...input, ownerGroup: organizationID }, props.observation);
    }

    if (!isNullOrUndefined(actionItem)) {
      await createRecommendationActionItemLink(recommendation, actionItem);
    }

    if (!isNullOrUndefined(observation)) {
      await createObservationRecommendationLink(observation, recommendation);
    }

    toggleModal && toggleModal();
    actionForm.setInput([]);

    return recommendation;
  };

  const importerModal = useModal(
    "Import Observations, Recommendations, and Action Items",
    <ImportActions />,
    <ImportButton />,
    { width: "90vw", disableClickToggle: true },
  );

  const form = useForm({
    header: <span>Create a Recommendation</span>,
    headerButtons: [importerModal.modalButton],
    submitFunction,
    getNewItem,
    enableToast: true,
    organizationID,
    fieldConfig: {
      name: {
        label: "Recommendation",
        inputType: "textarea",
        tooltip: "Recommended response to this Observation",
        required: true,
      },
      module: {
        label: "Software Module",
        tooltip: "What part of the Rivial Software Platform should this be categorized into?",
        inputType: "dropdown",
        required: true,
        defaultValue: module || "governance",
        dropdownConfig: {
          data: moduleTypes.map((item) => {
            return {
              value: item,
              text: convertCamelCaseToSentence(item),
            };
          }),
        },
      },
      rating: {
        label: "Rating",
        tooltip: "How important do you rate this Recommendation?",
        inputType: "dropdown",
        defaultValue: "low",
        dropdownConfig: {
          data: recommendationRatings.map((rating) => {
            return {
              value: rating,
              text: convertCamelCaseToSentence(rating),
            };
          }),
        },
      },
      difficulty: {
        label: "Difficulty Level",
        tooltip: "A relative Difficulty level for implementing this Recommendation",
        inputType: "dropdown",
        defaultValue: "low",
        dropdownConfig: {
          data: recommendationRatings.map((item) => {
            return {
              value: item,
              text: convertCamelCaseToSentence(item),
            };
          }),
        },
      },
      data: {
        label: "Data",
        inputType: "custom",
        isHidden: !dataField,
        tooltip: dataTooltip,
        customConfig: {
          component: dataField || <div>Nothing to see here..</div>,
        },
      },
      actionItems: {
        inputType: "custom",
        label: "Action Items",
        tooltip: "Action Item(s) to complete to resolve this Recommendation",
        customConfig: {
          component: actionForm.display,
        },
      },
    },
    ...props,
  });

  /**
   * Update this form input when the action items form gets updated
   */
  useEffect(() => {
    if (actionForm.input) {
      form.setInput((input) => {
        return { ...input, actionItems: actionForm.input };
      });
    }
  }, [actionForm.input]);

  /**
   * When this form gets updated externally,
   * update the action items form
   */
  useEffect(() => {
    if (form?.input?.actionItems && JSON.stringify(actionForm.input) !== JSON.stringify(form.input.actionItems)) {
      actionForm.setInput(form.input.actionItems);
    }
  }, [form.input]);

  return {
    ...form,
  };
};
