import React from "react";
import ErmRisksWithAssociatedBusinessRisks from "./ErmRisksWithAssociatedBusinessRisks";
import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import DashboardCardSection from "../../../../../../../utils/GenericComponents/DashboardCard/components/DashboardCardSection";

/**
 * Displays the Risk Scorecard for a particular system.
 * Wraps Enterprise Risk component with Monte Carlo Context
 * @param system
 * @param Context
 * @returns {JSX.Element}
 * @constructor
 */
const RiskScorecardItem = ({ system, Context }) => {
  return (
    <DashboardCardSection>
      <Context>
        <ErmRisksWithAssociatedBusinessRisks system={system} />
      </Context>
    </DashboardCardSection>
  );
};

export default withOrganizationCheck(RiskScorecardItem);
