import { createEvidenceActivity } from "./createEvidenceActivity";
import { generateGraphql } from "@rivial-security/generategraphql";
import { GetQuery } from "../../../../utils/Functions/Graphql/GetQuery";
import { formattedName } from "@rivial-security/func-utils";

/**
 * Creates an Evidence Activity item when an Evidence Point of Contact changes
 * @param {string} type - the type of the activity
 * @param {string} pointOfContactID - the db identifier of the point of contact
 * @param {string} userID - the db identifier of the user
 * @param {string} evidenceID - the db identifier of the evidence
 * @param {string} organizationID - the owner group of the evidence
 * @param {object} changedPointOfContact - the point of contact that was changed
 * @returns {Promise<void>}
 */
export const onEvidencePointOfContactChange = async ({
  type,
  pointOfContactID,
  userID,
  evidenceID,
  organizationID,
  changedPointOfContact,
}) => {
  const { getQuery: getPointOfContact } = generateGraphql("PointOfContact", ["firstName", "lastName"]);
  const pointOfContact = await GetQuery({
    query: getPointOfContact,
    variables: { id: pointOfContactID },
  });

  /**
   * Remove any extra non string keys from the point of contact
   */
  const updatedPointOfContact = { ...changedPointOfContact };
  Object.entries(updatedPointOfContact).forEach(
    ([key, value]) => typeof value !== "string" && delete updatedPointOfContact[key],
  );

  /**
   * Create an activity for the point of contact that was removed
   */
  await createEvidenceActivity(
    {
      type,
      data: JSON.stringify({
        userID,
        pointOfContactName: formattedName({ pointOfContact }),
        changedPointOfContact: updatedPointOfContact,
      }),
    },
    evidenceID,
    organizationID,
  );
};
