import React, { useEffect, useState } from "react";
import { generateGraphql } from "@rivial-security/generategraphql";
import GenericEditFieldV3 from "../../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";
import { GetQuery } from "@utils/Functions/Graphql/GetQuery";
import CreateOrSelectInput from "../../../../../utils/GenericComponents/GenericEditFieldV3/components/CreateOrSelectInput";
import CustomResourceTypeDataGrid from "../../../../CustomResources/CustomResourceTypes/components/CustomResourceTypeDataGrid";
import CustomResourceTypeForm from "../../../../CustomResources/CustomResourceTypes/components/CustomResourceTypeForm";
import CustomResourceTypeDetails from "../../../../CustomResources/CustomResourceTypes/components/CustomResourceTypeDetails";
import { useModal } from "@hooks/views/useModal";
import { isNonEmptyArray, eventLogger } from "@rivial-security/func-utils";
import { getQuestionnaireResponses } from "@views/OrganizationManager/Questionnaires/functions/getQuestionnaireResponses";

/**
 * Custom field for Questionnaire Custom resource Type field
 * @param {object} item - the entire questionnaire data
 * @param {function} updateItemById - function to update parent if item changes
 * @param {object} field - the field that represents the custom resource type
 * @param {object} props - any additional props to pass down to generic edit fields
 * @returns {JSX.Element}
 */
const QuestionnaireCustomResourceType = ({ item, updateItemById, field, ...props }) => {
  const [questionnaireResponses, setQuestionnaireResponses] = useState([]);
  const disableEdits = isNonEmptyArray(questionnaireResponses);
  const { updateMutation } = generateGraphql("Questionnaire", ["customResourceTypeID"]);

  useEffect(() => {
    const getResponses = async () => {
      try {
        const responses = await getQuestionnaireResponses({ questionnaireID: item?.id });
        setQuestionnaireResponses(responses);
      } catch (error) {
        eventLogger("Error getting questionnaire responses:", error);
      }
    };

    getResponses();
  }, [item?.id]);

  return (
    <span>
      <GenericEditFieldV3
        {...props}
        updateItemById={async (item) => {
          const id = item?.customResourceTypeID;
          const getCustomResourceTypeQuery = generateGraphql("CustomResourceType", ["name"]).getQuery;
          const customResourceType = await GetQuery({
            query: getCustomResourceTypeQuery,
            variables: { id },
          });

          if (customResourceType?.id && typeof updateItemById == "function") {
            await updateItemById({
              ...item,
              customResourceType,
            });
          }
        }}
        mutation={updateMutation}
        disableRoleChecking={true}
        item={item}
        field={"customResourceTypeID"}
        customDisplayComponent={<CustomResourceTypeDisplayComponent disableEdits={disableEdits} />}
        customInputComponent={
          <CreateOrSelectInput
            typename={"CustomResourceType"}
            form={<CustomResourceTypeForm />}
            grid={<CustomResourceTypeDataGrid />}
            displayComponent={<CustomResourceTypeDisplayComponent disableEdits={disableEdits} />}
          />
        }
        disableEdits={disableEdits}
      />
    </span>
  );
};

const CustomResourceTypeDisplayComponent = ({ item, disableEdits }) => {
  const underlineStyle = {
    display: "inline",
    padding: 0,
    margin: 0,
    borderBottom: disableEdits ? "none" : "1.2px dashed",
    cursor: "pointer",
  };

  const modal = useModal(
    "Questionnaire Configuration",
    <CustomResourceTypeDetails item={item?.customResourceType} />,
    <p style={{ ...underlineStyle }}>{item?.customResourceType?.name || "Custom Resource Type"}</p>,
    { width: "70vw", isDisabled: disableEdits },
  );

  return <span>{item?.customResourceType ? modal?.modalButton : <i>No Custom Resource Type</i>}</span>;
};

export default QuestionnaireCustomResourceType;
