import { ErrorLogger, InfoLogger } from "@utils/EventLogger";

import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { checkArguments } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 * Link a source to one or more observations
 * @param {object} source - the source item
 * @param {object[]} selectedObservations - the selected observations to link the source to
 * @param {string} organizationID - selected organization
 * @param {string} typename - item type name from the schema
 * @return {Promise<*[]>}
 */
export const linkSourceToObservations = async ({ source, selectedObservations, organizationID, typename }) => {
  try {
    checkArguments(
      { source, selectedObservations, organizationID, typename },
      {
        source: { type: "object", validAsNull: false },
        selectedObservations: { isArray: true },
        organizationID: { type: "string", validAsNull: false },
        typename: { type: "string", validAsNull: false },
      },
    );
  } catch (e) {
    ErrorLogger("[linkSourceToObservations.js] Error: ", e);
    return [];
  }

  const { updateMutation: updateObservation } = generateGraphql("Observation", [
    "id",
    "externalSourceID",
    "ownerGroup",
  ]);

  const observationSourceLinksPromises = selectedObservations.map((observation) =>
    ItemMutation(updateObservation, {
      id: observation?.id,
      externalSourceID: source?.id,
      ownerGroup: organizationID,
    }).then((link) => {
      InfoLogger(`Linked ${typename}: ${source.id} to an observation: ${observation.id}`);
      return link;
    }),
  );

  return Promise.allSettled(observationSourceLinksPromises);
};
