import { Button, FormGroup, Input, InputGroup, Label } from "reactstrap";
import React, { useEffect, useState } from "react";
import { modules, resources } from "@rivial-security/role-utils";

import SelectRating from "./SelectRating";
import { generateGraphql } from "@rivial-security/generategraphql";
import { realTimeRiskGQL } from "@rivial-security/risk-calc-utils";
import { useCheckPermissions } from "../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useMutation } from "../../../../../../hooks/graphql/useMutation/useMutation";
import { useQueryGetItem } from "../../../../../../hooks/graphql/useQueryGetItem";
import { useSelectTemplatesModal } from "../../../../../../hooks/views/useSelectTemplates/hooks/useSelectTemplatesModal";
import { useUploadInformationAssets } from "../../useUploadInformationAssets";
import { withOrganizationCheck } from "../../../../../../utils/Context/withOrganizationCheck";

const { createMutation: createInformationAsset } = generateGraphql("InformationAsset");

const CreateInformationAsset = (props) => {
  const { organizationID, toggleModal, resetFunction } = props;

  const module = modules.RISK;
  const resource = resources.INFORMATION_ASSET;
  const field = "informationAssets";

  const [cRating, setCRating] = useState("high");
  const [iRating, setIRating] = useState("high");
  const [name, setName] = useState("");
  const [ratingScale, setRatingScale] = useState(null);

  const ratingScaleQuery = useQueryGetItem({
    query: realTimeRiskGQL.ratingScaleGQL,
    itemId: props.organizationID,
    module,
    resource,
  });

  const templatesModal = useSelectTemplatesModal({
    resource,
    onStartedDuplication: () => {
      toggleModal && toggleModal();
      templatesModal.setModalIsOpen(false);
    },
    getNewItem: () => {
      resetFunction && resetFunction();
    },
  });

  useEffect(() => {
    const scale =
      ratingScaleQuery && ratingScaleQuery.item && ratingScaleQuery.item.riskSetup
        ? ratingScaleQuery.item.riskSetup
        : null;

    setRatingScale(scale);
  }, [ratingScaleQuery.item]);

  const mutationHook = useMutation({
    mutation: createInformationAsset,
    module,
    resource,
    field,
  });

  const handleSubmit = () => {
    const confidentiality = cRating;
    const integrity = iRating;
    const ownerGroup = props.organizationID;

    const input = {
      name,
      ownerGroup,
      confidentiality,
      integrity,
    };

    mutationHook.editItem(input);
    props.resetFunction && props.resetFunction();
    props.toggleModal && props.toggleModal();
  };

  const submitEnabled = name !== "";

  const checkPermissionsHook = useCheckPermissions({ module, resource, field });

  const uploadInformationAssetsHook = useUploadInformationAssets({
    organizationID,
    toggleModal,
    resetFunction,
  });

  return (
    <>
      {checkPermissionsHook.resource.update ? (
        <>
          {templatesModal.modalButton}
          {uploadInformationAssetsHook.modalButton}
          <FormGroup>
            <Label for="name">Name</Label>
            <InputGroup>
              <Input value={name} onChange={(e) => setName(e.target.value)} />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Label for="confidentiality">Confidentiality Rating (C)</Label>
            <SelectRating rating={cRating} setRating={setCRating} ratingScale={ratingScale} />
          </FormGroup>
          <FormGroup>
            <Label for="confidentiality">Integrity Rating (I)</Label>
            <SelectRating rating={iRating} setRating={setIRating} ratingScale={ratingScale} />
          </FormGroup>
          <Button disabled={!submitEnabled} color="primary" onClick={() => handleSubmit()}>
            Submit
          </Button>
        </>
      ) : (
        <strong>You don't have permissions to create new Information Assets</strong>
      )}
    </>
  );
};

export default withOrganizationCheck(CreateInformationAsset);
