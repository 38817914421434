"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const validateRoleConfig_1 = require("../utils/validateRoleConfig");
const checkPermissionsBaseObjects_1 = require("../checkPermissions/checkPermissionsBaseObjects");
/**
 * General permission checking given role parameters
 * @param {string} module
 * @param {string} resource
 * @param {string} func
 * @param {object} roleConfig
 * @param {boolean} disableRoleChecking
 * @returns {module: *, resource: *, field: *, function: *, roleConfig: *}
 */
const checkPermissions = ({ module, resource, func, roleConfig, disableRoleChecking = false }) => {
    //Check if requesting to not check permissions
    if (disableRoleChecking === true) {
        return { ...checkPermissionsBaseObjects_1.allPermitted };
    }
    //Form the structure of the result object
    const result = { ...checkPermissionsBaseObjects_1.allDenied };
    //[Argument Checks]
    //When no valid role config is passed in
    if (!(0, validateRoleConfig_1.validateRoleConfig)(roleConfig)) {
        console.error("RoleConfig object validation failed in roleConfig - ", { roleConfig });
        return result;
    }
    //[Permission Checks]
    //Check access to the module
    if (module && roleConfig.modules.hasOwnProperty(module) && roleConfig.modules[module] === true) {
        result.module = {
            isEnabled: true,
        };
    }
    //Check access to the resource
    if (resource && roleConfig.resources.hasOwnProperty(resource)) {
        const currentResource = roleConfig.resources[resource];
        let readEnabled = false;
        let updateEnabled = false;
        let deleteEnabled = false;
        let createEnabled = false;
        if (currentResource.read && currentResource.read === true) {
            readEnabled = true;
        }
        if (currentResource.update && currentResource.update === true) {
            updateEnabled = true;
        }
        if (currentResource.delete && currentResource.delete === true) {
            deleteEnabled = true;
        }
        if (currentResource.create && currentResource.create === true) {
            createEnabled = true;
        }
        result.resourceAlone = {
            read: readEnabled,
            update: updateEnabled,
            delete: deleteEnabled,
            create: createEnabled,
        };
        //Grant read access to all fields and include in the result for backwards compatibility
        let fieldReadEnabled = false;
        let fieldUpdateEnabled = false;
        if (currentResource.read && currentResource.read === true) {
            fieldReadEnabled = true;
        }
        if (currentResource.update && currentResource.update === true) {
            fieldUpdateEnabled = true;
        }
        result.fieldAlone = {
            read: fieldReadEnabled,
            update: fieldUpdateEnabled,
        };
        //Check module access before granting access to resource and field levels
        if (result.module.isEnabled) {
            result.resource = result.resourceAlone;
            result.field = result.fieldAlone;
        }
    }
    //Check access to the function
    if (func && roleConfig.functions.hasOwnProperty(func)) {
        const currentFunction = roleConfig.functions[func];
        let functionEnabled = false;
        if (currentFunction.enabled && currentFunction.enabled === true) {
            functionEnabled = true;
        }
        result.functionAlone = {
            isEnabled: functionEnabled,
        };
        //Check module access before granting access to function
        if (result.module.isEnabled) {
            result.function = result.functionAlone;
        }
    }
    //Return resulting permissions object based on the user's role
    return result;
};
exports.default = checkPermissions;
