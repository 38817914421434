import { ACTION_STATUSES } from "../../../../../Program/actionStatuses";
import GoodStanding from "../components/GoodStanding";
import InProgressStanding from "../components/InProgressStanding";
import NoStanding from "../components/NoStanding";
import OnHoldStanding from "../components/OnHoldStanding";
import PastDueStanding from "../components/PastDueStanding";
import React from "react";
import { getActionItemsForEvidence } from "../../../functions/getActionItemsForEvidence";
import { getPointOfContactMostRecentActionItem } from "./getPointOfContactMostRecentActionItem";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Displays if a point of contact has a pastDue or inProgress action item, vs all complete or no action items.
 * @param evidence
 * @param pointOfContact
 * @param returnEnum
 * @returns {string|JSX.Element}
 */
export const getPointOfContactStanding = (evidence, pointOfContact, returnEnum = false) => {
  // get all action items for this evidence
  const evidenceActionItems = getActionItemsForEvidence(evidence);

  if (isNullOrUndefined(evidenceActionItems)) {
    if (returnEnum) {
      return ACTION_STATUSES.COMPLETE;
    }
    return <GoodStanding evidence={evidence} pointOfContact={pointOfContact} />;
  } else {
    const pointOfContactActionItems = pointOfContact?.actions?.items || [];
    const mostRecentActionItem = getPointOfContactMostRecentActionItem({
      actionItems: pointOfContactActionItems,
      evidenceID: evidence?.id,
    });
    const status = mostRecentActionItem?.status;

    // Return a UI component based on status
    switch (status) {
      case ACTION_STATUSES.PAST_DUE:
        return (
          <PastDueStanding actionItem={mostRecentActionItem} evidence={evidence} pointOfContact={pointOfContact} />
        );
      case ACTION_STATUSES.ON_HOLD:
        return <OnHoldStanding actionItem={mostRecentActionItem} evidence={evidence} pointOfContact={pointOfContact} />;
      case ACTION_STATUSES.IN_PROGRESS:
        return (
          <InProgressStanding actionItem={mostRecentActionItem} evidence={evidence} pointOfContact={pointOfContact} />
        );
      case ACTION_STATUSES.COMPLETE:
        return <GoodStanding pointOfContact={pointOfContact} />;
    }

    if (returnEnum) {
      return "No Status";
    }
    return <NoStanding pointOfContact={pointOfContact} />;
  }
};
