import { ErrorLogger } from "@utils/EventLogger";
import { fieldContexts } from "../../../enums/fieldContexts";
import { getResourceAttribute } from "../getResourceAttribute";

/**
 * Retrieves list of fields to query for a particular resource based on defaults configured in resource definitions
 * @param {string[]} overrideQueryFields - the fields to use instead of the ones in the resource definition
 * @param {string} typename - the resource name for the definition to retrieve
 * @param {string} fieldContext - the location of where the query is used (grid, detail, etc) (may return different fields based on context)
 * @returns {*[]|null}
 */
export const getResourceQueryFields = ({ overrideQueryFields, typename, fieldContext }) => {
  //Check arguments
  if (!typename || !fieldContext) {
    ErrorLogger("Invalid arguments passed to getResourceQueryFields", {
      overrideQueryFields,
      typename,
    });
    return null;
  }

  let queryFields = overrideQueryFields;

  if (!queryFields) {
    if (fieldContext === fieldContexts.STANDALONE) {
      const allFields = getResourceAttribute({
        typename,
        attribute: `fields`,
      });

      if (typeof allFields === "object") {
        queryFields = [];
        for (const field in allFields) {
          queryFields.push(field);
        }
      }
    } else {
      queryFields = getResourceAttribute({
        typename,
        attribute: `views.${fieldContext}.queryFields`,
      });
    }
  }

  return queryFields;
};
