import React from "react";
import EvidenceDetails from "../../Evidence/components/EvidenceDetails";
import { useModal } from "../../../../hooks/views/useModal";

/**
 * Custom Name field for Evidence displayed in AuditControls.
 * Uses a tooltip to access the parent Evidence Details, along with quickly accessing all Evidence Documents
 * @param {object} item - the evidence item
 * @param {string} item.id - evidence ID for details page
 * @param {string} item.name - evidence name for tooltip
 * @param style
 * @returns {JSX.Element}
 * @constructor
 */
const EvidenceNameField = ({ item, style = {} }) => {
  const modal = useModal(
    null,
    <div>
      <EvidenceDetails item={item} />
    </div>,
    <span>{item.name}</span>,
    {
      width: "75vw",
    },
  );

  return <div>{modal.modalButton}</div>;
};

export default EvidenceNameField;
