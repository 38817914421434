import React, { useState } from "react";

import { Button } from "reactstrap";
import { ErrorLogger } from "@utils/EventLogger";
import { ListQueryBy } from "../../../../utils/Functions/Graphql/ListQueryBy";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useAssessmentDataGrid } from "../../Assessments/hooks/useAssessmentDataGrid";
import { useModal } from "../../../../hooks/views/useModal";

/**
 * Display Linked assessments stats
 * @param {object} item - Vulnerability group object
 * @param {object} item.assessments - assessments binned by vulnerability
 * @param {string} item.ownerGroup - Organization ID
 * @returns {JSX.Element}
 */
const LinkedAssessmentsStats = ({ item }) => {
  const assessments = item?.assessments || [];
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Assessment grid hook to display linked assessments
   */
  const assessmentDataGrid = useAssessmentDataGrid({
    organizationID: item?.ownerGroup,
    query: null,
    isLoading,
  });

  /**
   * Modal hook to display linked assessments
   */
  const assessmentModal = useModal(
    "Assessments",
    <div style={{ height: "50em" }}>{assessmentDataGrid?.display}</div>,
    "",
    {
      width: "80vw",
    },
  );

  //Lazily load the assessment grid
  const loadAssessments = async () => {
    const assessments = [];
    try {
      setIsLoading(true);
      const assessmentLinks = await ListQueryBy({
        query: listAssessmentVulnerabilityLinksByVulnerabilityID,
        variables: {
          vulnerabilityID: item?.id,
        },
      });

      if (Array.isArray(assessmentLinks)) {
        for (const assessmentLink of assessmentLinks) {
          const foundAssessment = assessments?.find((assessment) => assessment?.id === assessmentLink?.assessmentID);
          if (!foundAssessment) {
            assessments.push(assessmentLink.assessment);
          }
        }
      }
    } catch (error) {
      ErrorLogger("Error loading assessment links: ", error);
    } finally {
      assessmentDataGrid.setData(assessments);
      setIsLoading(false);
    }
  };

  return (
    <Button
      className={"btn-pill"}
      onClick={() => {
        assessmentModal.setModalIsOpen(true);
        loadAssessments();
      }}
    >
      <span style={{ display: "inline" }}>
        Seen {assessments.length} {assessments.length > 1 ? "times" : "time"}
        {assessmentModal.modal}
      </span>
    </Button>
  );
};

export default LinkedAssessmentsStats;

const { listByQuery: listAssessmentVulnerabilityLinksByVulnerabilityID } = generateGraphql(
  "AssessmentVulnerabilityLink",
  ["id", "createdAt", "assessmentID", "assessment", "vulnerabilityID", "ownerGroup"],
  {
    assessment: `{
      id
      name
      scheduledDate
      type
      pointOfContact {
        id
        firstName
        lastName
      }
      penTester
      status
      ownerGroup
    }`,
  },
  {
    indexName: "listAssessmentVulnerabilityLinksByVulnerabilityID",
    partitionKey: "vulnerabilityID",
    partitionKeyType: "ID",
  },
);
