import { Input, InputGroup, InputGroupAddon } from "reactstrap";
import React, { useEffect, useState } from "react";
import useListQuery from "../../../../../hooks/graphql/useListQuery";
import { listControlSets_simple } from "../graphql/__controlSetGQL";
import { handleLoader } from "../../../../../utils/Functions/Views/handleLoader";
import { modules, resources } from "@rivial-security/role-utils";
import ControlGrid from "../../Controls/components/ControlGrid";

/**
 * A custom hook for displaying and selecting a single control set
 * @param {string} organizationID - the organization from which to get a control framework list
 * @param {object[]} [data] - custom list of control frameworks, will be used instead of the hook data if provided
 * @param {boolean} [listGroupItem] - if TRUE adds a card type boarder around the input fields, default TRUE for backwards compatability
 * @param {boolean} [showAddon] - if TRUE will add a small title addon "Control Framework" at the beginning of the input field
 * @param {boolean} [withControls=false] - determines if the Control grid should display below the dropdown
 * @param props
 */
export const useControlSetDropdown = ({
  organizationID,
  data,
  listGroupItem = true,
  showAddon = true,
  withControls = false,
  disabled = false,
  ...props
}) => {
  const controlSetQueryHook = useListQuery({
    query: listControlSets_simple,
    organizationID,
    module: modules.COMPLIANCE,
    resource: resources.CONTROL_FRAMEWORK,
    disableRoleChecking: true,
    enableQuery: !disabled,
  });

  const [selectedControlSetId, setSelectedControlSetId] = useState("");

  const getInitialValue = async () => {
    if (data && Array.isArray(data) && data.length > 0 && data[0].id) {
      setSelectedControlSetId(data[0].id);
      return;
    }

    if (controlSetQueryHook?.list[0]?.id) {
      setSelectedControlSetId(controlSetQueryHook.list[0].id);
    }
  };

  useEffect(() => {
    getInitialValue();
  }, [controlSetQueryHook.list, data]);

  const input = (
    <InputGroup size="sm">
      {showAddon && <InputGroupAddon addonType="prepend">Control Framework:</InputGroupAddon>}
      {handleLoader({
        component: (
          <Input
            type="select"
            bsSize="sm"
            name="select"
            id="controlSet"
            disabled={disabled || data?.length === 0 || (!data && controlSetQueryHook?.list?.length === 0)}
            value={selectedControlSetId}
            onChange={(e) => setSelectedControlSetId(e.target.value)}
          >
            {data
              ? data.map((controlSet, index) => (
                  <option key={index} value={controlSet.id}>
                    {controlSet.name}
                  </option>
                ))
              : controlSetQueryHook &&
                controlSetQueryHook.list &&
                controlSetQueryHook.list.map((controlSet, index) => (
                  <option key={index} value={controlSet.id}>
                    {controlSet.name}
                  </option>
                ))}
          </Input>
        ),
        isLoading: !disabled && !data && controlSetQueryHook.isLoading,
      })}
    </InputGroup>
  );

  const display = listGroupItem ? (
    <span>{input}</span>
  ) : (
    <div>
      {input}
      {withControls && (
        <ControlGrid controlFrameworkID={selectedControlSetId} organizationID={organizationID} {...props} />
      )}
    </div>
  );

  return {
    display,
    controlSetQueryHook,
    selectedControlSetId,
    setSelectedControlSetId,
  };
};
