import { deleteTarget } from "../functions/deleteTarget";
import React from "react";
import { modules, resources } from "@rivial-security/role-utils";
import { isValidIp } from "../functions/isValidIp";
import CreateTarget from "../components/CreateTarget";
import TargetDetails from "../components/TargetDetails";
import { listTargetsByOwnerGroup } from "../graphql/__targetsGQL";
import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";
import { generateGraphql } from "@rivial-security/generategraphql";
import { isValidMacAddress } from "../functions/isValidMacAddress";

/**
 * @description Display Targets grid hook
 * @param {string} organizationID - Organization ID
 * @param {object[]} fields - fields to display in grid
 * @param {object} queryConfig - query config to destructure into useDataGrid
 * @param {ReactElement} createFormFooterPrefix - prefix to add to the create form footer
 * @param {object} props - props to pass to DataGrid
 */
export const useTargetsGrid = ({ organizationID, fields, queryConfig, createFormFooterPrefix, ...props }) => {
  const module = modules.VULNERABILITIES;
  const resource = resources.TARGET;

  const gridFields = fields || [
    {
      name: "ip",
      friendlyName: "IP Address",
      flex: 1,
      inputConfig: isValidIp,
      sort: {
        direction: "desc",
        priority: 1,
      },
    },
    {
      name: "macAddress",
      flex: 1,
      inputConfig: isValidMacAddress,
    },
    {
      name: "hostName",
      flex: 1,
    },
  ];

  const { updateMutation } = generateGraphql("Target", ["ip", "hostName", "macAddress", "vulnerabilities"], {
    vulnerabilities: `{ items { id targetID vulnerabilityID status priority} }`,
  });

  return useDataGrid({
    module,
    resource,
    title: "Targets",
    headerIcon: "icon-target",
    fields: gridFields,
    route: "#/vulnerabilities/targets/",
    detailsComponent: <TargetDetails />,
    createResourceComponent: <CreateTarget footerPrefix={createFormFooterPrefix} />,
    createResourceComponentWidth: "80vw",
    query: listTargetsByOwnerGroup,
    limit: 1000,
    variables: {
      ownerGroup: organizationID,
    },
    updateMutation,
    typename: "Target",
    persistenceUUID: "data-grid-targets",
    deleteFunction: deleteTarget,
    primaryField: "ip",
    organizationID,
    options: ["delete", "edit"],
    ...queryConfig, // added to work with useNestedDataCard
    ...props,
  });
};
