import React, { useEffect, useState } from "react";
import RiskChangeDetails from "../../components/RiskChangeDetails";
import { Alert } from "@mui/material";
import ChangeType from "../../customFields/ChangeType";
import { GENERIC_FIELD_TYPES } from "../../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import { useDetailsCard } from "../../../../../hooks/views/useDetailsCardV2";
import { useAccordion } from "../../../../../hooks/views/useAccordion/useAccordion";
import SystemRiskChanges from "../../../Recommendations/components/RiskChangeTable/components/SystemRiskChanges/SystemRiskChanges";
import Grid from "@mui/material/Grid";
import { OverallEffectOfChangeCard } from "../../customFields/OverallEffectOfChange";
import { useRiskChangeDataGrid } from "../useRiskChangeDataGrid";
import { modules, resources } from "@rivial-security/role-utils";
import { getSystemRiskValuesAcrossMultipleRiskChanges } from "./functions/getSystemRiskValuesAcrossMultipleRiskChanges";

/**
 * Displays details page for multiple risk changes that were grouped together
 * @param {string} organizationID - the organization id of the organization the risk changes belongs to
 * @param {string} module - the platform module to use for role checking
 * @param {string} resource - the platform resource to use for role checking
 * @param {boolean} disableRoleChecking - if TRUE will not check user's role to display the details page
 * @param {object} item - the grouped risk change information to display
 * @param {object} props - additional props to pass to the individual risk change details card
 * @return {{display: JSX.Element, setItem: (value: unknown) => void}}
 */
export const useGroupedRiskChangeDetails = ({
  organizationID,
  module = modules.RISK,
  resource = resources.RISK_CHANGE,
  disableRoleChecking = false,
  item: initialItem = null,
  ...props
}) => {
  const [item, setItem] = useState(initialItem);

  const fields = ["type", "name", "description", "date", "userEmail", "changeOwner", "reason"];

  const fieldNameDictionary = {
    type: "Type of Changes",
    name: "Name for All Changes",
    date: "Date of Changes",
    userEmail: "Person who Entered Changes",
    changeOwner: "Person Responsible for Changes",
    reason: "Reason for Changes",
  };

  const customFields = [
    {
      field: "type",
      component: <ChangeType />,
    },
    {
      field: "date",
      inputType: GENERIC_FIELD_TYPES.DATE,
    },
  ];

  const detailsConfigInitial = {
    fields,
    customFields,
    fieldNameDictionary,
  };

  const detailsCard = useDetailsCard({
    detailsConfig: { ...detailsConfigInitial },
    tableDisplay: true,
    module,
    resource,
    disableRoleChecking,
  });

  const riskChangesGrid = useRiskChangeDataGrid({
    organizationID,
    queryConfig: { query: null },
    createResourceComponent: null,
    additionalFields: [
      {
        name: "name",
        visible: false,
      },
      {
        name: "reason",
        visible: false,
      },
      {
        name: "date",
        visible: false,
      },
      {
        name: "description",
        flex: 1,
        minWidth: 200,
      },
    ],
  });

  useEffect(() => {
    if (item) {
      const newSystemChanges = getSystemRiskValuesAcrossMultipleRiskChanges({
        systemChanges: item?.systemChanges,
        riskChanges: item?.riskChanges,
      });
      item.systemChanges = newSystemChanges;
    }
    detailsCard.setItem({ ...item });
    riskChangesGrid.setData(item?.riskChanges || []);
  }, [item]);

  const accordion = useAccordion({
    item,
    items: [
      {
        title: "General Information",
        subTitle: "Details related to this Risk Change Group",
        icon: "icon-list",
        component: detailsCard.tableDisplay,
        defaultExpanded: true,
      },
      ...(item?.type === "risk_control_change"
        ? [
            {
              title: "Affected Systems",
              subTitle: "Information Systems impacted by this Risk Change Group",
              icon: "icon-screen-desktop",
              component: <SystemRiskChanges systemChanges={item?.systemChanges} />,
            },
          ]
        : []),
      {
        title: "Individual Risk Changes",
        subTitle: "Single Risk Changes associated with this Group",
        icon: "icon-graph",
        component: <div style={{ height: "40vh" }}>{riskChangesGrid.gridDisplay}</div>,
      },
    ],
  });

  // Based on whether the item is a single risk change or a grouped risk change display different details pages
  let display;
  if (item?.totalChanges === 1 && Array.isArray(item?.riskChanges) && item?.riskChanges.length === 1) {
    const riskChange = item?.riskChanges[0];
    display = (
      <div>
        <Alert severity={"info"}>This group contains a single risk change, its details are shown below.</Alert>
        <div style={{ marginBottom: ".5em" }} />
        <RiskChangeDetails item={riskChange} {...props} />
      </div>
    );
  } else {
    display = (
      <Grid container spacing={2}>
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          {accordion.display}
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <OverallEffectOfChangeCard
            item={item}
            residualChangeOverrideField={"totalResidualChange"}
            returnOnInvestmentOverrideField={"totalReturnOnInvestment"}
            additionalFields={[
              { name: "totalChanges", friendlyName: "Amount of Changes" },
              {
                name: "totalSystems",
                friendlyName: "Amount of Affected Systems",
              },
            ]}
          />
        </Grid>
      </Grid>
    );
  }

  return {
    display,
    setItem,
  };
};
