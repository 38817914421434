import React, { useEffect, useState } from "react";
import OrganizationCheck from "../../../../../../../utils/Context/OrganizationCheck/OrganizationCheck";
import { getTotalCostOfControls } from "../functions/getTotalCostOfControls";
import { numberWithCommas } from "@rivial-security/func-utils";

const TotalCostOfControlsBody = (props) => {
  const { controlCategory, system, style } = props;

  const [totalCostOfControls, setTotalCostOfControls] = useState(0);

  useEffect(() => {
    const totalCostOfControls = getTotalCostOfControls(controlCategory, system);
    setTotalCostOfControls(totalCostOfControls);
  }, [controlCategory]);

  return (
    <div style={{ ...style }}>
      <strong style={{ marginRight: "1em" }}>
        Total Cost of Controls <i className="icon-question" title={`Total cost of controls from all Sub-Controls`} />
      </strong>{" "}
      <div>${numberWithCommas(totalCostOfControls)}</div>
    </div>
  );
};

const TotalCostOfControls = (props) => {
  return (
    <OrganizationCheck {...props}>
      <TotalCostOfControlsBody />
    </OrganizationCheck>
  );
};

export default TotalCostOfControls;
