import React, { useState } from "react";
import { Button, Col, Row } from "reactstrap";
import KeyPerformanceIndicators from "../../../Risk/RiskConfig/ControlCategories/components/SubControls/customFields/KeyPerformanceIndicators";

/**
 * A sidebar column with button for showing KPIs that are attached to a Resource.
 *
 * Current supports:
 *  - system
 *  - riskControl
 *  - riskControlCategory
 *  - control
 *
 * @param component
 * @param system
 * @param riskControl
 * @param riskControlCategory
 * @param control
 * @param style
 * @param btnClassName
 * @param allowRead
 * @param allowUpdate
 * @returns {JSX.Element}
 * @constructor
 */
const KPIOverlay = ({
  component,
  system,
  riskControl,
  riskControlCategory,
  control,
  style,
  btnClassName,
  allowRead,
  allowUpdate,
}) => {
  const [showKPIs, setShowKPIs] = useState(false);

  return (
    <Row>
      <Col>
        {!showKPIs && allowRead && (
          <Button
            className={btnClassName}
            style={{ ...style }}
            color="ghost-primary"
            size="sm"
            onClick={() => setShowKPIs(!showKPIs)}
          >
            {showKPIs ? "Hide" : "Show"} KPIs
            <i className="icon-graph" style={{ marginLeft: "0.5em" }} />
          </Button>
        )}
        {component}
      </Col>
      {showKPIs && allowRead && (
        <Col lg={5}>
          {showKPIs && (
            <Button style={{ width: "100%" }} color="ghost-primary" size="sm" onClick={() => setShowKPIs(!showKPIs)}>
              {showKPIs ? "Hide" : "Show"} KPIs
              <i className="icon-graph" style={{ marginLeft: "0.5em" }} />
            </Button>
          )}
          <h6>Key Performance Indicators</h6>
          <KeyPerformanceIndicators
            system={system}
            riskControlCategory={riskControlCategory}
            riskControl={riskControl}
            control={control}
          />
        </Col>
      )}
    </Row>
  );
};

export default KPIOverlay;
