import { InfoLogger } from "../../../../EventLogger";
import { createInitialToast } from "./helperFunctions/createInitialToast";
import { formatResult } from "./helperFunctions/formatResult";
import { handleCallbacks } from "./helperFunctions/handleCallbacks";
import { handleFailFieldToast } from "./helperFunctions/handleFailFieldToast";
import { handleMutationInput } from "./helperFunctions/handleMutationInput";
import { handleResultToast } from "./helperFunctions/handleResultToast";
import { handleUpdate } from "./helperFunctions/handleUpdate";
import { handleUpdateInput } from "./helperFunctions/handleUpdateInput";
import { handleUpdateMutationFunction } from "./helperFunctions/handleUpdateMutationFunction";
import { handleValueUpdates } from "./helperFunctions/handleValueUpdates";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { isValidInput } from "./helperFunctions/isValidInput";
import { revertToOriginalValue } from "./helperFunctions/revertToOriginalValue";

/**
 * Saves the updated field value to the database

 * @param disableToast
 * @param {object} item - the item that is being updated
 * @param {string} item.id - the ID of the item that is being updated
 * @param {string} field - the field that is being updated
 * @param {number|string|boolean} value - the current state value of the field
 * @param {function} setValue - sets the current state value of the field
 * @param {number|string|boolean} originalValue - the original state value of the field
 * @param {function} setOriginalValue - sets the original state value of the field
 * @param {object} updateMutationHook - mutation hook that has the 'editField' function
 * @param {function} updateMutationHook.editField - function that performs the DB operation
 * @param {boolean} [updateInputOnlyForMutation] - if TRUE finalValue will be reverted to the the one before
 * updateInputFunction was executed (after the mutation has finished)
 * @param {function} [updateInputFunction] - a function that updates the field input before the mutation
 * @param {function} [customFormat] - a function that updates the value state for the frontend display
 * @param {boolean} [forceLowercase] - forces the field input to be all lowercase
 * @param {function} [addToast] - used to create a toast, if toasts are enabled
 * @param {object} inputConfig - input configuration
 * @param {string} inputType - the Input type for this field (dropdown, etc
 * @param {function} [updateToast] - used to update the toasts, if toasts are enabled
 * @param {string} [fieldOverride] - overrides the 'field' string
 * @param {function} [updateMutationFunction] - updates the whole mutation object right before the DB operation
 * @param {function} [updateItemById] - callback function that gets the updated item after the operation
 * @param {function} [resetFunction] - callback function that is triggered after the operation
 * @param {boolean} [disableToast] - disables toast notifications
 * @param {function} [mutationFunction] - for custom mutation operations, if present will not use the useMutation hook
 * @param {boolean} [required] - makes this field required, cannot be null or empty
 * @param typename
 * @param friendlyName
 */
export const saveChange = async ({
  disableToast,
  required,
  item,
  field,
  updateInputOnlyForMutation,
  updateInputFunction,
  customFormat,
  forceLowercase = false,
  addToast,
  updateToast,
  value,
  setValue,
  originalValue,
  inputConfig,
  inputType,
  fieldOverride,
  updateMutationFunction,
  updateMutationHook,
  setOriginalValue,
  updateItemById,
  mutationFunction,
  resetFunction,
  typename,
  friendlyName,
}) => {
  /**
   * Create the initial toast
   */
  const toastId = createInitialToast({ disableToast, addToast });

  /**
   * Check for empty field
   */
  if (!isValidInput({ required, value })) {
    handleFailFieldToast({
      disableToast,
      updateToast,
      toastId,
      field,
      friendlyName,
      message: "Field '{field}' cannot be empty.",
    });
    revertToOriginalValue({ setValue, item, field });
    return null;
  }

  /**
   * Update the raw input based on params
   */
  const originalFinalValue = value;
  let finalValue = await handleUpdateInput({
    value,
    setValue,
    customFormat,
    updateInputFunction,
    forceLowercase,
  });

  /**
   * Only proceed with the update if the value has changed from the original and is not null
   */
  try {
    if (isNullOrUndefined(finalValue)) {
      const message = `The value for Field: ${field} is null or undefined.`;
      InfoLogger(message);
      handleFailFieldToast({
        disableToast,
        updateToast,
        toastId,
        field,
        friendlyName,
      });
    } else if (originalValue !== finalValue) {
      /**
       * Setup the mutation input
       */
      let mutationInput = handleMutationInput({
        item,
        field,
        fieldOverride,
        finalValue,
      });

      /**
       * If the updateMutationFunction param is passed in, pipe the mutationInput through it
       */
      mutationInput = handleUpdateMutationFunction({
        mutationInput,
        updateMutationFunction,
      });

      /**
       * Handle the database mutation operation, then perform follow up operations
       */
      let updatedItem;
      if (mutationFunction) {
        updatedItem = await mutationFunction(mutationInput);
        //If the custom function doesnt implement a return value for updated item use the mutation input as result
        if (!updatedItem) {
          updatedItem = mutationInput;
        }
      } else {
        updatedItem = await handleUpdate({
          mutationInput,
          editItem: updateMutationHook.editItem,
        });
      }

      /**
       * If preferred, display the un formatted value in the UI (the one before handleUpdateInput) (eg. customFields in controls)
       */
      if (updateInputOnlyForMutation) {
        finalValue = originalFinalValue;
      }

      /**
       * Formats the mutation result to work nicely with the UI
       */
      const result = formatResult({ customFormat, finalValue });

      /**
       * Handle the resulting toast notification
       */
      handleResultToast({
        field,
        result,
        disableToast,
        updateToast,
        toastId,
        typename,
        item,
        friendlyName,
        inputConfig,
        inputType,
      });

      /**
       * Update the 'value' and 'originalValue' states after the operation
       */
      handleValueUpdates({
        customFormat,
        value,
        setOriginalValue,
        result,
        setValue,
      });

      /**
       * Perform any necessary callbacks after the operation
       */
      handleCallbacks({
        updatedItem,
        resetFunction,
        updateItemById,
        callback: inputConfig.callback,
      });
    } else {
      const message = `The value for Field: ${field} hasn't changed.`;
      InfoLogger(message);
      handleFailFieldToast({
        disableToast,
        updateToast,
        toastId,
        field,
        friendlyName,
      });
    }
  } catch (e) {
    handleFailFieldToast({
      disableToast,
      updateToast,
      toastId,
      field,
      friendlyName,
    });
    throw e.message || e; //this bubbles the error to the outside calling function
  }
};
