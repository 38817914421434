import { modules, resources } from "@rivial-security/role-utils";

import CreateRecommendation from "../../../../../Recommendations/components/CreateRecommendation";
import NotesV2 from "../../../../../../../utils/Notes/components/NotesV2";
import React from "react";
import { useQueryGetItem } from "../../../../../../../hooks/graphql/useQueryGetItem";
import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";

const updateSystemNotes = /* GraphQL */ `
  mutation UpdateSystem($input: UpdateSystemInput!) {
    updateSystem(input: $input) {
      id
      ownerGroup
      notes {
        id
        observationID
      }
    }
  }
`;
const getSystemNotes = /* GraphQL */ `
  query GetSystem($id: ID!) {
    getSystem(id: $id) {
      id
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
        observationID
      }
    }
  }
`;

const SystemNotes = ({ item, organizationID, disableRoleChecking = false }) => {
  const module = modules.RISK;
  const resource = resources.INFORMATION_SYSTEM;

  const systemQuery = useQueryGetItem({
    itemId: item?.id,
    query: getSystemNotes,
    module,
    resource,
    disableRoleChecking,
  });

  return (
    <NotesV2
      item={systemQuery.item}
      module={module}
      resource={resource}
      tooltip="General Notes for this Information System"
      organizationID={organizationID}
      mutation={updateSystemNotes}
      resetFunction={systemQuery.reset}
      disableRoleChecking={disableRoleChecking}
      // observationConnectionField={"systemID"}  // TODO need to add this to the schema
      observationFormOverride={<CreateRecommendation organizationID={organizationID} system={item} />}
    />
  );
};

export default withOrganizationCheck(SystemNotes);
