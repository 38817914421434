import { Badge, Spinner, Toast, ToastBody, ToastHeader } from "reactstrap";

import { eventType } from "../../../views/Notifications/enums/eventType";
import { getNotificationToastBody } from "../../../views/Notifications/functions/getNotificationToastBody";
import parse from "html-react-parser";

/**
 * Component displaying a single toast
 * @param {boolean} [isFirst=false] - TRUE if this toast is the first out of a list of toasts
 * @param {object} item - toast content and appearance information
 * @param {number} index - the location of the toast in the list of toasts
 * @param {boolean} index.isPermanent - TRUE if toast doesn't auto-dismiss, FALSE if it does
 * @param {number} index.timeoutDuration - number of seconds to display an auto dismissed toast
 * @param {function} removeToast - function that removes the toasts from view
 * @param {boolean} isInContainer - determines whether the toast should take up the full width of parent and have styling overrides
 * @param {function} toggleModal - if toast is in container can call this function to close the container if it is a modal
 * @returns {*}
 * @constructor
 */
const LayoutToast = ({ isFirst = false, item, index, removeToast, isInContainer, toggleModal }) => {
  const isLoading = item.icon === "spinner";
  const icon = isLoading ? <Spinner size="sm" color={item.color || "primary"} /> : item.icon;

  let toastStyle = {};
  let headerStyle = {};
  const bodyStyle = {};

  //Determine if no style overrides can be applied (toast is outside a container)
  if (isInContainer) {
    //Declare all default style overrides
    toastStyle = {
      maxWidth: "100%",
      minWidth: "100%",
      boxShadow: "none",
      borderRadius: "0em",
    };

    //Determine if toast needs to be identified as new
    if (item && item.isNew) {
      toastStyle["background"] = "#00ABD2";
      bodyStyle["background"] = "white";
    }

    //Determine which main toast borders to leave
    let borderType = {
      borderLeft: "none",
      borderRight: "none",
      borderBottom: "none",
    };
    if (index === 0) {
      borderType = { border: "none" };
    }
    for (const borderAttribute in borderType) {
      toastStyle[borderAttribute] = borderType[borderAttribute];
    }

    //Determine if need header border (no need if there's no body)
    headerStyle = { border: "none" };
    if (item?.body && item.body !== "" && item.isNew === false) {
      headerStyle = { borderBottom: "1px solid rgba(0, 0, 0, 0.05)" };
    }
    // headerStyle = {...headerStyle, ...(item.isOnScreen !== true && {paddingTop: ".5em"})};
  }

  return (
    <div
      data-testid={`single-toast${isLoading ? " single-loading-toast" : ""}`}
      style={{
        ...(item.isOnScreen === true && { marginBottom: "1em" }),
        ...(item.isOnScreen !== true && {
          borderBottom: "1.5px solid rgba(0, 0, 0, 0.3)",
        }),
      }}
    >
      <Toast style={toastStyle} key={JSON.stringify(item) + index} color={item.color} transition={item.transition}>
        <ToastHeader style={headerStyle} color={item.color} icon={icon} toggle={removeToast}>
          {item.header}
          {"  "}
          {item && item.isNew && isInContainer ? <Badge color="primary">New</Badge> : ""}
          {item?.createdAt && (
            <span style={{ marginLeft: "0.25em" }}>{new Date(item.createdAt).toLocaleDateString()}</span>
          )}
        </ToastHeader>
        {item.body && (
          <ToastBody style={bodyStyle}>
            {(item?.type === eventType.NOTIFICATION &&
              getNotificationToastBody({
                notificationData: item?.data,
                notificationHTML: item?.body,
                toggleModal,
                removeToast,
              })) ||
              (item?.isBodyHTML ? parse(item.body) : item.body)}
          </ToastBody>
        )}
      </Toast>
    </div>
  );
};

export default LayoutToast;
