import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { convertCamelCaseToSentence, isNullOrUndefined } from "@rivial-security/func-utils";
import { modules, resources } from "@rivial-security/role-utils";

import { GenericDeleteButton } from "../../../../../../utils/GenericComponents/GenericDeleteButton";
import React from "react";
import { TargetVulnerabilityHistoryType } from "../../../../../../typedefs/Testing/Vulnerability/Vulnerability";
import Typography from "@mui/material/Typography";
import UnderlinedTooltip from "../../../../../../utils/GenericComponents/Tooltips/UnderlinedTooltip";
import { deleteVulnerabilityTargetHistory } from "../../../../Vulnerabilities/functions/deleteVulnerabilityTargetHistory";
import { getTimelineContent } from "../../../functions/getTimelineContent";
import { getTimelineDate } from "../../../../../Compliance/Evidence/components/EvidenceActivityItem/functions/getTimelineDate";
import { getTimelineDotIcon } from "../../../functions/getTimelineDotIcon";
import { useBoolean } from "../../../../../../hooks/functional/useBoolean";
import { useCheckPermissions } from "../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";

/**
 * Component for displaying a timeline item for target details timeline
 * @param {boolean} isLastItem - is this the last item in the timeline
 * @param {string} date - the date of the timeline item
 * @param {object} item - the TargetVulnerabilityHistory item
 * @param {function} resetFunction - function to reset the data
 * @param {string} type - the type of the timeline item
 * @param {string} comment - the comment for the timeline item
 * @param {boolean} isGrouped - is the timeline grouped by assessmentID
 * @param {object} target - the target data
 * @param {object} vulnerability - the vulnerability data
 * @returns {JSX.Element}
 */
const TargetTimelineItem = ({ isLastItem, date, item, resetFunction, type, comment, isGrouped, target }) => {
  const checkPermissions = useCheckPermissions({
    module: modules.VULNERABILITIES,
    resource: resources.TARGET,
  });
  const [showDelete, setShowDelete] = useBoolean();

  const onHoverOn = () => {
    //Do not show delete button for entries linked to assessments
    if (checkPermissions?.resource?.update && !item?.assessmentID) {
      setShowDelete(true);
    }
  };

  const onHoverOff = () => {
    setShowDelete(false);
  };

  return (
    <TimelineItem onMouseEnter={onHoverOn} onMouseLeave={onHoverOff}>
      <TimelineOppositeContent>
        {type && (
          <Typography color="textSecondary" style={{ fontSize: ".9em", fontWeight: "bold" }}>
            {convertCamelCaseToSentence(type)}
          </Typography>
        )}
        <Typography color="textSecondary">{getTimelineDate(date)}</Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot color={"grey"}>{getTimelineDotIcon({ type })}</TimelineDot>
        {isLastItem === false && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        {getTimelineContent({
          type,
          item,
          isGrouped,
          target,
        })}
        {!isNullOrUndefined(comment) && type === TargetVulnerabilityHistoryType.TAG && (
          <span>
            {" "}
            with a{" "}
            <UnderlinedTooltip tooltip={comment}>
              <span>comment</span>
            </UnderlinedTooltip>
          </span>
        )}
        {showDelete && (
          <span className={"float-right"} style={{ position: "absolute", right: "-1em", top: "0em" }}>
            <GenericDeleteButton
              deleteFunction={deleteVulnerabilityTargetHistory}
              item={item}
              resetFunction={resetFunction}
            />
          </span>
        )}
      </TimelineContent>
    </TimelineItem>
  );
};

export default TargetTimelineItem;
