import React from "react";
import { modules, resources } from "@rivial-security/role-utils";
import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import { convertCamelCaseToSentence, formattedDollar } from "@rivial-security/func-utils";
import GenericFieldDropdown from "../../../../../../../utils/GenericComponents/GenericFieldDropdown";
import { generateGraphql } from "@rivial-security/generategraphql";
import UnderlinedTooltip from "../../../../../../../utils/GenericComponents/Tooltips/UnderlinedTooltip";
import { getAvailability } from "@rivial-security/risk-calc-utils";

/**
 * The 'availability' field to be adjusted for System details
 * @param item - the system
 * @returns {JSX.Element}
 * @constructor
 */
const AvailabilityRating = ({ item, ...props }) => {
  const module = modules.RISK;
  const resource = resources.INFORMATION_SYSTEM;
  const field = "availability";
  const tooltip =
    "Represents the potential impact of this System being down for a period of time. This is calculated as a ratio of Confidentiality or Integrity (whichever is greatest)";

  const ratings = ["high", "mediumHigh", "medium", "lowMedium", "low"];

  const options = ratings.map((rating) => {
    return {
      text: convertCamelCaseToSentence(rating),
      value: rating,
    };
  });

  return (
    <span style={{ width: "100%" }}>
      <>
        <div style={{ display: "inline-block", marginRight: "1em" }}>
          <h7>Availability:</h7>
        </div>
        <div style={{ display: "inline-block" }}>
          <UnderlinedTooltip tooltip={tooltip}>
            <strong>{formattedDollar(getAvailability(item))}</strong>
          </UnderlinedTooltip>
          <span
            style={{
              marginLeft: "1em",
              marginRight: "1em",
            }}
          >
            -
          </span>
          <GenericFieldDropdown
            item={item}
            module={module}
            resource={resource}
            field={field}
            mutation={generateGraphql("System", [field]).updateMutation}
            options={options}
            format="sentence"
            tooltip={tooltip}
            {...props}
          />
        </div>
      </>
    </span>
  );
};

export default withOrganizationCheck(AvailabilityRating);
