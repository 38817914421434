import React, { useState, useEffect } from "react";
import { useTable } from "../../../../hooks/views/useTable";
import { Card, CardBody, Col, Row } from "reactstrap";
import SystemDetails from "../../Systems/components/SystemDetails";
import NumberChange from "../../../../utils/CustomFields/NumberChange";

const SystemsAffectedList = ({ item }) => {
  const [systems, setSystems] = useState([]);

  useEffect(() => {
    const systemLinks = item && item.systemLinks && item.systemLinks.items;
    const temp = [];
    for (const systemLink of systemLinks) {
      systemLink && temp.push(systemLink.system);
    }
    setSystems([...temp]);
  }, [item]);

  const dataCardHook = useTable({
    data: systems,
    fields: ["name"],
    disableRoleChecking: true,
    config: {
      borderless: true,
    },
    //collapseComponent: <AffectedSystemDetails change={item}/>, //TODO: fix UI
    detailsComponent: <SystemDetails />,
  });

  useEffect(() => {
    dataCardHook.setData([...systems]);
  }, [systems]);

  return <>{dataCardHook.display}</>;
};

export default SystemsAffectedList;

const AffectedSystemDetails = ({ change, item }) => {
  const [systemChangeDetails, setSystemChangeDetails] = useState(null);

  useEffect(() => {
    if (change && change.change && item) {
      const changeObject = JSON.parse(change.change);
      const found = changeObject && changeObject.systemCalcs && changeObject.systemCalcs.find((x) => x.id === item.id);
      setSystemChangeDetails(found);
    }
  }, [item]);

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              {systemChangeDetails && (
                <>
                  <Col lg={6}>
                    Change in Residual Risk:{" "}
                    <NumberChange
                      value={
                        systemChangeDetails.changeInResidualRisk ||
                        systemChangeDetails.overallResidualChange ||
                        systemChangeDetails.residualChange
                      }
                      format="dollar"
                      positiveNumber="red"
                      negativeNumber="green"
                    />
                  </Col>
                  <Col lg={6}>
                    Return on Investment:{" "}
                    <NumberChange
                      value={systemChangeDetails.changeInROI || systemChangeDetails.roiChange}
                      format="percent"
                    />
                  </Col>
                </>
              )}
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
