import { getResourceIcon } from "../../../definitions/functions/getResourceIcon";
import { genericResources } from "../../../definitions/constants/genericResources";

/**
 * Finds the iconify icon that represents the field type for the query field
 * @param {string} field - the schema field information with type information
 * @return {string} - the iconify icon name string, fallback if not found
 */
export const getFieldIcon = ({ field }) => {
  //Check if field is a function
  if (field?.isFunction === true) {
    return "mdi:function-variant";
  }

  //Check if type is one of the primitives, string, bool, or a number
  const typename = field?.typename;
  if (typename === genericResources.STRING || typename === genericResources.ID) {
    return "carbon:string-text";
  } else if (typename === genericResources.BOOLEAN) {
    return "charm:binary";
  } else if (typename === genericResources.INT) {
    return "carbon:character-whole-number";
  } else if (typename === genericResources.FLOAT) {
    return "carbon:character-fraction";
  } else if (typename === genericResources.AWS_DATE_TIME) {
    return "fluent-mdl2:date-time";
  }

  //Check if the type is a one of the resources with a defined icon
  const resourceIcon = getResourceIcon({ typename });
  if (resourceIcon) {
    return resourceIcon;
  }

  //Check if the type is a one-to-many, many-to-many, or one-to-one relationship link field
  if (field?.hasMany) {
    if (field?.isNested) {
      return "akar-icons:link-chain";
    } else {
      return "ic:outline-data-object";
    }
  }

  // if not nested and not many-to-many, then it is a enum
  if (!field?.isNested && !field?.hasMany) {
    return "carbon:string-text";
  }

  // Default to a basic resource icon
  return "carbon:software-resource";
};
