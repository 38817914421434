import { ErrorLogger } from "@utils/EventLogger";
import { checkArguments } from "@rivial-security/func-utils";

/**
 * Data pre-processing function (allows to search by controls, and display custom fields)
 * @param {object} control - the current item for which to process data
 * @param {string[]} customFields - list of custom field names to add to the control
 * @return {object} new control object with custom fields added
 */
export const normalizeControlRowData = ({ control, customFields }) => {
  // - check arguments
  try {
    checkArguments(
      { control, customFields },
      {
        item: { type: "object" },
        customFields: { type: "string", isArray: true, deepTypeCheck: true },
      },
    );
  } catch (e) {
    ErrorLogger("Invalid control entry given to preprocess.", e.message);
    return control;
  }

  // - create tagList field - to be able to search by tags
  try {
    if (control?.tags?.items && Array.isArray(control?.tags?.items)) {
      let tagList = "";
      for (const tagLink of control.tags.items) {
        if (tagLink?.tag?.name) {
          tagList += `|${tagLink.tag.name}`;
        }
      }
      control["tagList"] = tagList;
    }
  } catch (e) {
    ErrorLogger("Failed to generate the tagList field for a control.", e);
  }

  // - create entries for each field in each control
  try {
    let customFieldData;
    if (control?.customFieldData) {
      customFieldData = JSON.parse(control?.customFieldData);
      for (const field of customFields) {
        if (customFieldData && customFieldData.hasOwnProperty(field)) {
          control[field] = customFieldData[field];
        }
      }
    }
  } catch (e) {
    ErrorLogger("Failed to generate full custom fields for a control in the controls grid.", e);
  }

  return control;
};
