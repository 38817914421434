import { generateGraphql } from "@rivial-security/generategraphql";
import { deleteItem } from "../../../../../../../utils/Functions/Graphql/deleteItem";

/**
 * Deletes a single SystemTargetLink item
 * @param systemTargetLink
 * @return {Promise<void>}
 */
export const deleteSystemTargetLink = async (systemTargetLink) => {
  const { getQuery } = generateGraphql("SystemTargetLink", ["__typename"]);
  return await deleteItem(getQuery, systemTargetLink);
};
