import { ErrorLogger, InfoLogger } from "@utils/EventLogger";
import React, { useEffect } from "react";
import { modules, resources } from "@rivial-security/role-utils";

import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import MUICreateModalButton from "../../../../utils/GenericComponents/buttons/MUICreateModalButton";
import MeetingDetails from "../../../Program/Meetings/components/MeetingDetails";
import OpenButtonModal from "../../../../utils/GenericComponents/buttons/OpenButtonModal";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useMeetingForm } from "../../../Program/Meetings/hooks/useMeetingForm";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * @description Button that allows either Schedule Meeting, or Open Meeting for an Incident
 * @param {object} item - incident object
 * @param {string} organizationID - selected organization
 * @returns {JSX.Element}
 * @constructor
 */
const IncidentMeetingButton = ({ item, organizationID, resetFunction }) => {
  return (
    <span>
      {item?.meeting?.id ? (
        <OpenMeetingButton item={item.meeting} organizationID={organizationID} />
      ) : (
        <CreateMeetingButton item={item} organizationID={organizationID} resetFunction={resetFunction} />
      )}
    </span>
  );
};

export default withOrganizationCheck(IncidentMeetingButton);

/**
 * @description Button for Opening an existing Incident Meeting
 * @param {object} item - meeting object
 * @returns {JSX.Element}
 * @constructor
 */
const OpenMeetingButton = ({ item }) => {
  return (
    <OpenButtonModal
      text={"Open Meeting"}
      icon={"icon-calendar"}
      color={"primary"}
      typename={"Meeting"}
      config={{ width: "80vw" }}
    >
      <MeetingDetails itemId={item?.id} />
    </OpenButtonModal>
  );
};

/**
 * WIP
 * @description Button for Creating an Incident Meeting
 * @param {string} organizationID - selected organization
 * @param {object} item - incident object
 * @param {function} resetFunction - fetch item from the database
 * @returns {JSX.Element}
 * @constructor
 */
const CreateMeetingButton = ({ organizationID, item, resetFunction }) => {
  const module = modules.GOVERNANCE;
  const resource = resources.MEETING;

  useEffect(() => {
    if (item) {
      /**
       * Create Agenda Items for each Procedure
       */
      const items = ["Detect", "Analyze", "Contain", "Eradicate", "Recover", "Post Incident"];

      /**
       * Add Points of Contact from the Escalation Path contacts
       */
      const pointOfContacts = [];
      const teamsLink = item?.classificationTier?.teams?.items;
      if (teamsLink) {
        for (const teamLink of teamsLink) {
          const pointOfContactsLink = teamLink?.team?.pointOfContactLinks?.items;
          if (pointOfContactsLink) {
            for (const pointOfContactLink of pointOfContactsLink) {
              if (pointOfContactLink?.pointOfContact) {
                pointOfContacts.push(pointOfContactLink?.pointOfContact);
              }
            }
          }
        }
      }

      createMeeting.setInput({
        title: `Incident Response: ${item.what}`,
        pointOfContacts,
        agendaItems: [...items],
      });
    }
  }, [JSON.stringify(item)]);

  const getNewItem = (newMeeting) => {
    const { updateMutation } = generateGraphql("Incident");
    ItemMutation(updateMutation, {
      id: item?.id,
      meetingID: newMeeting?.id,
    })
      .then(() => {
        InfoLogger("Incident Meeting connection success");
        resetFunction?.();
      })
      .catch(() => ErrorLogger("Incident Meeting connection fail"));
  };

  const createMeeting = useMeetingForm({
    organizationID,
    getNewItem,
  });

  const checkMeetingPermissions = useCheckPermissions({ module, resource });

  return (
    <MUICreateModalButton
      text={"Schedule Meeting"}
      typename={"Meeting"}
      icon={"icon-calendar"}
      modalConfig={{ width: "60vw" }}
      disabled={!checkMeetingPermissions.resource.create}
    >
      {createMeeting.display}
    </MUICreateModalButton>
  );
};
