import { Col, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row } from "reactstrap";
import React, { useEffect, useState } from "react";
import ChangeInImplementation from "../../../../Recommendations/customFields/ChangeInImplementation";
import ChangeInCost from "../../../../Recommendations/customFields/ChangeInCost";

export const useAdjustRiskControlFields = ({ selectedRiskControl, propsChangeInCost, propsChangeInImplementation }) => {
  const [changeInCost, setChangeInCost] = useState(propsChangeInCost ? propsChangeInCost : 0);
  const [newCost, setNewCost] = useState(0);

  const [changeInImplementation, setChangeInImplementation] = useState(
    propsChangeInImplementation ? propsChangeInImplementation : 0,
  );
  const [newImplementationRating, setNewImplementationRating] = useState(0);

  // Calculate the change in cost
  useEffect(() => {
    let res = 0;
    if (selectedRiskControl && selectedRiskControl.costOfControl) {
      if (changeInCost) {
        res = parseFloat(selectedRiskControl.costOfControl) + parseFloat(changeInCost);
      } else {
        res = selectedRiskControl.costOfControl;
      }
    }
    setNewCost(res);
  }, [selectedRiskControl, changeInCost]);

  // Calculate the change in implementation
  useEffect(() => {
    let res = 0;
    if (selectedRiskControl && selectedRiskControl.implementationRating) {
      if (changeInImplementation) {
        res = parseFloat(selectedRiskControl.implementationRating) + parseFloat(changeInImplementation / 100);
      } else {
        res = selectedRiskControl.implementationRating;
      }
    }
    setNewImplementationRating(res);
  }, [selectedRiskControl, changeInImplementation]);

  const display = (
    <>
      {selectedRiskControl && (
        <>
          <FormGroup>
            <Label for="costChange">Change In Cost</Label>
            <Row>
              <Col md={4}>
                <ChangeInCost newCost={newCost} oldCost={selectedRiskControl.costOfControl} />
              </Col>
              <Col>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>$</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="number"
                    id="costChange"
                    value={changeInCost}
                    onChange={(e) => setChangeInCost(e.target.value)}
                  />
                </InputGroup>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Label for="implementationChange">Change In Implementation</Label>
            <Row>
              <Col md={4}>
                <ChangeInImplementation
                  newPercent={newImplementationRating}
                  oldPercent={selectedRiskControl.implementationRating}
                />
              </Col>
              <Col>
                <InputGroup>
                  <Input
                    type="number"
                    step={1}
                    id="implementationChange"
                    value={changeInImplementation}
                    onChange={(e) => setChangeInImplementation(e.target.value)}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>%</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
            </Row>
          </FormGroup>
        </>
      )}
    </>
  );

  return {
    display,
    changeInCost,
    newCost,
    changeInImplementation,
    newImplementationRating,
  };
};
