import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { guestRoutes } from "../Routes/GuestRoutes";
import { useWindowResize } from "../Layout/hooks/useWindowResize";
import CenteredLoadingMessage from "../../utils/LoadingComponents/CenteredLoadingMessage";

/**
 * REFERENCES:
 * Q - Why is this not wrapped with amplify's withAuthenticator?
 * A - useAuthenticator.js not expected to wrap the entire app: https://github.com/aws-amplify/amplify-js/issues/2842.
 *     Authenticating the guest user is now managed with the higher order component withGuestAuthentication.
 *
 * Q - What happens when a user enters a non-supported guest route?
 * A - Based on the guest routes array this component redirects user to home "/", which prompts the guest to login
 */

/**
 * THe main wrapper for all routes that allow unauthenticated users (no login required)
 * @param {object} props
 * @return {JSX.Element} - the guest GUI, reroutes to log in screen if no guest routes match requested url
 */
const GuestApp = (props) => {
  const { isMobile } = useWindowResize();

  return (
    <Suspense fallback={<CenteredLoadingMessage message={"Preparing Data..."} />}>
      <div
        style={{
          backgroundColor: "white",
          height: "100vh",
          overflowY: "auto",
          padding: isMobile ? "1em" : "4em",
        }}
      >
        <Switch>
          {guestRoutes?.map((route, idx) => {
            const { path, exact, name, component } = route || {};

            return (
              path &&
              component && (
                <Route
                  key={`guest-route-${idx}`}
                  path={path}
                  exact={exact}
                  name={name}
                  render={(props) => <route.component {...props} />}
                />
              )
            );
          })}
        </Switch>
      </div>
    </Suspense>
  );
};

export default GuestApp;
