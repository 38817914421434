import React, { useEffect, useState } from "react";

import AuditControlIsCompliant from "../../../../Audit/components/AuditControlIsCompliant";
import AuditControlNotes from "../../../../Audit/components/AuditControlNotes";
import AuditDate from "../../../../Audit/components/AuditDate";
import CreateAudit from "../../../../Audit/components/CreateAudit";
import { DETAILS_TYPES } from "../../../../../../hooks/views/useGrid/enums/DETAILS_TYPES";
import { ObjectField } from "../../../../../../utils/GenericComponents/ObjectField";
import { deleteAudit } from "../../../../Audit/functions/deleteAudit";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useAuditDataGrid } from "../../../../Audit/hooks/useAuditDataGrid";

/**
 * Displays a list of Audits for a particular Control
 * @param {Control} item - the Control to display audits for
 */
const ControlAudits = ({ item, optimisticUpdate, resetFunction, isLoading }) => {
  const [audits, setAudits] = useState([]);

  useEffect(() => {
    if (!isNullOrUndefined(item?.audits?.items)) {
      const controlAudits = [];
      // const audits = getMany(item, 'audits', 'audit')

      for (const auditLink of item?.audits?.items ?? []) {
        controlAudits.push({
          ...auditLink.audit,
          isCompliant: auditLink.isCompliant,
          notes: auditLink.notes,
          auditControlLink: auditLink,
        });
      }

      setAudits(controlAudits);
    }
  }, [item]);

  const queryConfig = {
    query: null,
  };

  const fields = [
    {
      name: "name",
      minWidth: 70,
      flex: 1,
    },
    {
      name: "controlSet",
      friendlyName: "Framework",
      component: <ObjectField field="controlSet" subField="name" />,
      width: 200,
      visible: false,
    },
    {
      name: "isCompliant",
      width: 200,
      valueGetter: (_value, row) => {
        return row.isCompliant;
      },
      type: "singleSelect",
      valueOptions: [
        {
          value: true,
          label: "Yes",
        },
        {
          value: false,
          label: "No",
        },
        {
          value: null,
          label: "N/A",
        },
      ],
      component: <AuditControlIsCompliant resetFunction={resetFunction} />,
      valueFormatter: (value) => {
        if (value === true) {
          return "Yes";
        } else if (value === false) {
          return "No";
        } else {
          return "N/A";
        }
      },
    },
    {
      name: "auditDate",
      component: <AuditDate />,
      width: 200,
      friendlyName: "Audit Date",
      visible: false,
      sort: {
        direction: "desc",
        priority: 1,
      },
    },
    {
      name: "notes",
      component: <AuditControlNotes />,
      width: 350,
    },
  ];

  const gridConfig = {
    enableColumnChooser: false,
    createResourceComponent: <CreateAudit />,
    allowPaging: false,
    gridHeight: "100%",
    persistenceUUID: "control-audits-grid",
    detailsType: DETAILS_TYPES.NONE,
    enableQuickDetails: true,
    options: ["details", "delete"],
    deleteFunction: deleteAudit,
    fields,
    resetFunction,
    isLoading,
  };

  const auditGrid = useAuditDataGrid({
    ...queryConfig,
    ...gridConfig,
  });

  useEffect(() => {
    if (!isNullOrUndefined(audits)) {
      auditGrid.setData(audits);
    }
  }, [audits]);

  return <div style={{ height: "30em" }}> {auditGrid.gridDisplay} </div>;
};

export default ControlAudits;
