import { modules, resources } from "@rivial-security/role-utils";

import { ItemMutation } from "../../../../../utils/Functions/Graphql/ItemMutation";
import MitreAttackTechniqueDetails from "../components/MitreAttackTechniqueDetails";
import React from "react";
import { Button as ReactStrapButton } from "reactstrap";
import UnderlinedTooltip from "../../../../../utils/Tooltips/UnderlinedTooltip";
import { emptyPlaceholder } from "../../../../../utils/GenericComponents/Placeholders";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useCheckPermissions } from "../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useMitreAttackTechniquesDataGrid } from "../hooks/useMitreAttackTechniquesDataGrid";
import { useModal } from "../../../../../hooks/views/useModal";
import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";

/**
 * @description Display Mitre Attack Technique details component
 * @param {object} threat - Threat item
 * @param {object} technique - Technique item
 * @param {string} organizationID - id of the organization
 * @param {function} resetFunction - function to reset the state
 * @returns {JSX.Element}
 * @constructor
 */
const ThreatMitreAttackTechniqueDetails = ({ threat, technique, organizationID, resetFunction }) => {
  const checkPermissions = useCheckPermissions({
    module: modules.INCIDENT_RESPONSE,
    resource: resources.THREAT,
  });

  const modal = useModal(
    "Mitre Att&ck Technique Details",
    <MitreAttackTechniqueDetails item={{ id: technique?.id }} />,
    <span>
      <UnderlinedTooltip
        text={technique?.stix?.name || "Technique Details"}
        title={`Click to view full Technique details`}
      >
        {technique?.stix?.name || "Technique Details"}
      </UnderlinedTooltip>
    </span>,
    {
      width: "90vw",
    },
  );

  const selectTechniqueGrid = useMitreAttackTechniquesDataGrid({
    organizationID,
    enableSelectButton: true,
    onSelectCallback: (technique) => {
      ItemMutation(generateGraphql("Threat").updateMutation, {
        id: threat?.id,
        techniqueID: technique?.id,
      }).finally(() => {
        resetFunction && resetFunction();
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        linkingModal.setModalIsOpen(false);
      });
    },
  });

  const linkingModal = useModal(
    "Link MITRE Technique",
    <div style={{ height: "50em" }}>{selectTechniqueGrid.display}</div>,
    <ReactStrapButton color="ghost-warning" className="btn-pill" size="sm">
      <i className="icon-pencil" />
    </ReactStrapButton>,
    {
      width: "90vw",
    },
  );

  return (
    <div>
      {technique?.id ? modal.modalButton : emptyPlaceholder}{" "}
      {checkPermissions?.resource?.update && linkingModal.modalButton}
    </div>
  );
};

export default withOrganizationCheck(ThreatMitreAttackTechniqueDetails);
