import React, { useEffect, useState } from "react";
import { useListRiskControls } from "../../../../RiskConfig/ControlCategories/components/SubControls/hooks/useListRiskControls";
import { updateRiskChange, updateRiskControl } from "../../../../../../graphql/mutations";
import EditButton from "../../../../../../utils/GenericComponents/buttons/EditButton";
import { useAdjustRiskControlFields } from "../hooks/useAdjustRiskControlFields";
import { useMutation } from "../../../../../../hooks/graphql/useMutation/useMutation";
import { gql_createSystemChangeLink } from "../../../graphql/gql_createSystemChangeLink";
import { gql_createRiskControlChangeLink } from "../../../graphql/gql_createRiskControlChangeLink";
import { useLoadingAndOutdated } from "../../../hooks/useLoadingAndOutdated";
import { useOverallChanges } from "../../../hooks/useOverallChanges";
import { useRiskChangeSystemsTable } from "../../../hooks/useRiskChangeSystemsTable";
import { useRiskChangeForm } from "../../../hooks/useRiskChangeForm";
import { useUpdateFormHook } from "../../../hooks/useUpdateFormHook";
import { useUpdateSystemsTable } from "../../../hooks/useUpdateSystemsTable";
import CreateChange from "../../CreateChange";
import { handleRiskChangePromises } from "../../../functions/handleRiskChangePromises";
import { withOrganizationCheck } from "../../../../../../utils/Context/withOrganizationCheck";

const CreateRiskControlChange = ({
  item,
  organizationID,
  resetFunction,
  toggleModal,
  callbackFunction,
  riskControl,
  ...props
}) => {
  const [changeItem, setChangeItem] = useState({
    costChange: item && item.costChange ? item.costChange : parseFloat(0),
    implementationChange: item && item.implementationChange ? item.implementationChange : parseFloat(0),
    riskControl: riskControl ? riskControl : {},
  });

  const loadingAndOutdated = useLoadingAndOutdated(changeItem);

  const overallChanges = useOverallChanges();

  const [selectedRiskControl, setSelectedRiskControl] = useState(riskControl ? riskControl : null);

  const systemsTable = useRiskChangeSystemsTable({
    tooltip:
      "A system is associated with this Control as long as the control is 'standard' for that system. 'Not Applicable' means that this control is Non-Standard for a system.",
  });

  const updateInputFunction = (input) => {
    return {
      ...input,
      type: "risk_control_change",
      change: JSON.stringify({
        ...overallChanges,
        riskControl: selectedRiskControl,
      }),
    };
  };

  const createSystemChangeLink = useMutation({
    mutation: gql_createSystemChangeLink,
    disableRoleChecking: true,
    disableToast: true,
  });

  const createRiskControlChangeLink = useMutation({
    mutation: gql_createRiskControlChangeLink,
    disableRoleChecking: true,
    disableToast: true,
  });

  const updateRiskControlFields = useMutation({
    mutation: updateRiskControl,
    disableRoleChecking: true,
  });

  /**
   * This callback creates risk control links and updates the fields after the RiskChange gets created.
   * @param riskChange
   */
  const callback = async (riskChange) => {
    if (riskChange) {
      if (selectedRiskControl) {
        await createRiskControlChangeLink.createItem({
          ownerGroup: organizationID,
          riskChangeID: riskChange.id,
          riskControlID: selectedRiskControl.id,
          implementationChange: changeItem.implementationChange,
          costChange: changeItem.costChange,
        });

        await updateRiskControlFields.editItem({
          id: selectedRiskControl.id,
          costOfControl:
            selectedRiskControl.costOfControl && changeItem.costChange
              ? selectedRiskControl.costOfControl + changeItem.costChange
              : undefined,
          implementationRating:
            selectedRiskControl.implementationRating && changeItem.implementationChange
              ? selectedRiskControl.implementationRating + changeItem.implementationChange
              : undefined,
        });
      }

      await handleRiskChangePromises(
        overallChanges.systemCalcs,
        createSystemChangeLink.createItem,
        organizationID,
        riskChange,
      );

      callbackFunction && callbackFunction(riskChange);
      !props.getNewItem && resetFunction && resetFunction();
    }
  };

  /**
   * This is used when converting from a Manual Change to a new type of Change
   * @type {{mutateItemLegacy: (function(*): Promise<*|{}>), roleConfig: *, CheckFieldPermissionsRead: (function(*=): boolean), editItem: (function(*=): Promise<*|{}>), resource: *, module: *, mutateItem: (function(*=): Promise<*>), CheckFieldPermissionsUpdate: (function(*=): boolean), field: *, deleteItem: (function(*=): Promise<*|{}>), function: *, createItem: (function(*=): Promise<*|{}>), CheckFuncPermissions: (function(*=): boolean)}}
   */
  const updateRiskChangeHook = useMutation({
    mutation: updateRiskChange,
    disableRoleChecking: true,
  });

  const submitFunction = async (input) => {
    delete input["riskControlLinks"];
    delete input["systemLinks"];

    return await updateRiskChangeHook.editItem({
      id: item && item.id,
      ...input,
    });
  };

  const formHook = useRiskChangeForm({
    item,
    typename: "Risk Control Change",
    organizationID,
    updateInputFunction,
    callback,
    submitFunction,
    toggleModal,
    ...props,
  });

  useUpdateFormHook(item, formHook);
  useUpdateSystemsTable(systemsTable, overallChanges);

  return (
    <CreateChange
      organizationID={organizationID}
      changeItem={changeItem}
      loadingAndOutdated={loadingAndOutdated}
      overallChanges={overallChanges}
      formHook={formHook}
      systemsTable={systemsTable}
      adjustComponent={
        <SelectRiskControl
          organizationID={organizationID}
          selectedRiskControl={selectedRiskControl}
          setSelectedRiskControl={setSelectedRiskControl}
          changeItem={changeItem}
          setChangeItem={setChangeItem}
        />
      }
    />
  );
};

const SelectRiskControl = ({
  organizationID,
  selectedRiskControl,
  setSelectedRiskControl,
  changeItem,
  setChangeItem,
}) => {
  const adjustRiskControlFieldsHook = useAdjustRiskControlFields({
    selectedRiskControl,
    propsChangeInCost: changeItem.costChange,
    propsChangeInImplementation: changeItem.implementationChange * 100,
  });

  // Create the temp item for monte carlo
  useEffect(() => {
    if (selectedRiskControl && selectedRiskControl.id) {
      setChangeItem({
        costChange: parseFloat(adjustRiskControlFieldsHook.changeInCost),
        implementationChange: parseFloat(adjustRiskControlFieldsHook.changeInImplementation / 100),
        riskControl: selectedRiskControl,
      });
    }
  }, [adjustRiskControlFieldsHook.newCost, adjustRiskControlFieldsHook.newImplementationRating, selectedRiskControl]);

  const riskControlListHook = useListRiskControls({
    organizationID,
    dataCardConfig: {
      config: {
        showSelectBoxes: true,
        selectionType: "button",
        buttonCallback: (riskControl) => setSelectedRiskControl(riskControl),
      },
    },
  });

  useEffect(() => {
    riskControlListHook.setSortField("statementNumber", "asc");
  }, []);

  return (
    <div>
      {selectedRiskControl ? (
        <>
          <h5>
            Selected Risk Control
            <EditButton
              color="ghost-warning"
              style={{ marginLeft: "1em" }}
              onClick={() => setSelectedRiskControl(null)}
              title="Select a different Risk Control"
            />
          </h5>
          {selectedRiskControl.statementNumber}: {selectedRiskControl.name}
        </>
      ) : (
        riskControlListHook.display
      )}
      <hr />
      {selectedRiskControl && adjustRiskControlFieldsHook.display}
      <hr />
    </div>
  );
};

export default withOrganizationCheck(CreateRiskControlChange);
