import React, { useEffect } from "react";
import { modules, resources } from "@rivial-security/role-utils";

import AddButton from "@utils/GenericComponents/buttons/AddButton";
import { CustomFieldType } from "@views/Compliance/Controls/ControlSets/constants/CustomFieldType";
import CustomResourceTypeForm from "../../CustomResourceTypes/components/CustomResourceTypeForm";
import { ErrorLogger } from "@utils/EventLogger";
import { GetQuery } from "@rivial-security/appsync-utils";
import { arrayIncludes } from "@utils/Functions/Array/arrayIncludes";
import { customFieldPrefix } from "../../CustomResourceFields/constants/customFieldPrefix";
import { generateGraphql } from "@rivial-security/generategraphql";
import { getCustomFieldName } from "../../CustomResourceFields/functions/getCustomFieldName";
import { getFormCustomFields } from "../../CustomResourceFields/functions/getFormCustomFields";
import { isValidField } from "../../CustomResourceFields/functions/isValidField";
import { useCheckPermissions } from "@hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useForm } from "@hooks/views/useForm";
import useListQuery from "@hooks/graphql/useListQuery";
import { useModal } from "@hooks/views/useModal";
import { useStateEffect } from "@hooks/functional/useStateEffect";
import { useStateSync } from "@hooks/functional/useStateSync";

/**
 * A custom hook for creating a new CustomResourceEntry in the database
 * @param {string} organizationID - the organization/operation team for which to create a CustomResourceEntry
 * @param {string} module - platform module for role checking
 * @param {string} resource - platform resource for role checking
 * @param {boolean} disableRoleChecking - if TRUE will disable role checking
 * @param {boolean} isTemplate - if TRUE will hide non-template resource data
 * @param {string} customResourceTypeID - the CustomResourceType for which to create a CustomResourceEntry
 * @param {string} customResourceEntryID - the CustomResourceEntry to load into the form
 * @param {object} customResourceType - the CustomResourceType object to use for the form (takes priority for custom fields)
 * @param {boolean} disableCustomResourceTypeSelection - if TRUE will disable the CustomResourceType selection
 * @param {function} [toggleModal] - called to close containing modal right as the form is submitted
 * @param {function} [getNewItem] - callback for a new item (after creation)
 * @param {boolean} [convertLabelToCamelCase=true] - if TRUE will convert the label (custom field name) to camel case
 * @param {object} ...props - all props passed to useFormHook
 * @return {{display: JSX.Element}}
 */
export const useCustomResourceEntryForm = ({
  organizationID,
  module = modules.TOOLS,
  resource = resources.CUSTOM_RESOURCE_TYPE,
  disableRoleChecking = false,
  isTemplate = false,
  customResourceTypeID,
  customResourceEntryID,
  customResourceType: customResourceTypeInit,
  disableCustomResourceTypeSelection = false,
  toggleModal,
  getNewItem,
  convertLabelToCamelCase = true,
  disabledFields = [],
  ...props
}) => {
  ///[GENERAL SETUP]
  // - Constants
  const typename = "CustomResourceEntry";
  // - Queries
  const { getQuery: getCustomResourceTypeQuery, listQuery } = generateGraphql(
    "CustomResourceType",
    ["name", "customFields"],
    {
      customFields: `{name description type options { label value } multipleSelect { label value } numberSettings { min max step format} }`,
    },
  );
  const { getQuery: getCustomResourceEntryQuery, createMutation: createCustomResourceEntryMutation } = generateGraphql(
    "CustomResourceEntry",
    ["customFieldData", "customResourceTypeID"],
  );

  const customResourceTypes = useListQuery({
    query: listQuery,
    organizationID,
    disableRoleChecking: true,
    enableQuery: !disableCustomResourceTypeSelection,
  });

  // - Permissions
  const controlSetPermissions = useCheckPermissions({
    module,
    resource,
    disableRoleChecking,
  });

  // - State
  const [selectedCustomResourceTypeId, setSelectedCustomResourceTypeId] = useStateSync(customResourceTypeID);

  /**
   * Fetches the customFields configuration from the selected CustomResourceType and saves into state
   */
  const [customFields, setCustomFields] = useStateEffect([], [selectedCustomResourceTypeId], async () => {
    //Check to see if no CustomResourceType is selected
    if (!selectedCustomResourceTypeId || selectedCustomResourceTypeId === "") {
      setCustomFields([]);
      return;
    }

    //Retrieve the new CustomResourceType custom fields
    try {
      let newCustomFields = customResourceTypeInit?.customFields;
      if (!Array.isArray(newCustomFields)) {
        const customResourceType = await GetQuery({
          query: getCustomResourceTypeQuery,
          variables: { id: selectedCustomResourceTypeId },
        });
        newCustomFields = customResourceType?.customFields;
      }
      if (Array.isArray(newCustomFields)) {
        setCustomFields(newCustomFields);
      }
    } catch (e) {
      ErrorLogger("Could not retrieve CustomResourceType data!", e);
      setCustomFields([]);
    }

    //Retrieve the custom resource entry and set the form to equal its values (if provided)
    try {
      if (customResourceEntryID) {
        const customResourceEntry = await GetQuery({
          query: getCustomResourceEntryQuery,
          variables: { id: customResourceEntryID },
        });

        const parsedCustomFieldData = JSON.parse(customResourceEntry?.customFieldData || "{}");

        const newInput = {};
        for (const customFieldName in parsedCustomFieldData) {
          const newCustomFieldName = getCustomFieldName({
            name: customFieldName,
          });
          newInput[newCustomFieldName] = parsedCustomFieldData[customFieldName];
        }

        formHook.setInput({
          ...newInput,
        });
      }
    } catch (e) {
      ErrorLogger("Could not retrieve CustomResourceEntry data!", e);
    }
  });

  //[UTILITY FUNCTIONS]

  /**
   * A pass through for the create form that converts all custom fields to a JSON that is stored in the database
   * @param {object} form input object - (custom fields prefixed with cus)
   * @return {object} for input object to use in the mutation (all custom fields in a single object)
   */
  const convertInputOnSubmit = (input) => {
    //if invalid input return itself
    if (!input || typeof input !== "object") return input;

    // save all custom fields that are under the currently selected CustomResourceType
    const customFieldData = {};
    for (const fieldName in input) {
      const foundField =
        Array.isArray(customFields) && customFields.find((item) => getCustomFieldName(item) === fieldName);
      if (fieldName.startsWith(customFieldPrefix) && foundField) {
        customFieldData[foundField.name] = input[fieldName];
      }
    }

    // delete all custom field entries in the object
    for (const field in input) {
      if (field.startsWith(customFieldPrefix)) {
        delete input[field];
      }
    }

    // add the saved custom fields into one property
    input.customFieldData = JSON.stringify(customFieldData);

    return input;
  };

  ///[CREATE FORM UI]
  const createCustomResourceTypeModal = useModal(
    `Create a new Custom Resource Type${isTemplate ? " Template" : ""}`,
    <CustomResourceTypeForm organizationID={organizationID} />,
    <AddButton color="ghost-success" style={{ marginLeft: "1em" }} />,
  );

  //Allows to see create CustomFieldEntry custom field state after create modal is closed
  const getNewItemWithCustomFields = (newItem) => {
    if (newItem) {
      //Add all custom framework fields
      try {
        const customFields = JSON.parse(newItem.customFieldData);
        for (const customField in customFields) {
          newItem[customField] = customFields[customField];
        }
      } catch (e) {
        ErrorLogger("Could not parse custom field data upon adding a CustomFieldEntry!", e);
      }

      getNewItem?.(newItem);
    }
  };

  const formHook = useForm({
    organizationID,
    module,
    resource,
    disableRoleChecking,

    fieldConfig: {
      customResourceTypeID: {
        inputType: "dropdown",
        // Include a CustomResourceType Form button,
        // if this field is not disabled,
        // and the user has permissions to create a CustomResourceType
        ...(!arrayIncludes(disabledFields, "customResourceTypeID") &&
          controlSetPermissions.resource.create && {
            createItemComponent: createCustomResourceTypeModal.modalButton,
          }),
        label: "Custom Resource Type",
        required: true,
        onChangeFunction: (value) => setSelectedCustomResourceTypeId(value.customResourceTypeID),
        defaultValue: customResourceTypeID,
        dropdownConfig: {
          data: customResourceTypes?.list?.map(({ id, name }) => {
            return {
              value: id,
              text: name,
            };
          }),
        },
        isHidden: disableCustomResourceTypeSelection,
        disabled: arrayIncludes(disabledFields, "customResourceTypeID"),
      },
      ...getFormCustomFields({ convertLabelToCamelCase, customFields }),
    },
    mutation: createCustomResourceEntryMutation,
    updateInputFunction: convertInputOnSubmit,
    getNewItem: getNewItemWithCustomFields,
    typename,
    toggleModal,
    ...props,
  });

  //[USE EFFECTS]
  //Allows to set default values for newly added custom fields
  useEffect(() => {
    if (!customFields || !Array.isArray(customFields) || customFields.length === 0) {
      return;
    }

    const newInputValues = {};
    for (const field of customFields) {
      if (isValidField(field)) {
        const customFieldName = getCustomFieldName(field);
        if (!customFieldName) continue;

        // For CustomResourceTypes, hydrate with existing custom field data
        let defaultValue = "";

        if (props?.item?.customFieldData) {
          try {
            const customFieldData = JSON.parse(props.item.customFieldData);
            const customFieldValue = customFieldData?.[field.name];
            defaultValue = customFieldValue || "";
          } catch (e) {
            ErrorLogger("Could not hydrate with existing custom field data");
          }
        }

        if (field.type === CustomFieldType.BOOLEAN) {
          newInputValues[customFieldName] = defaultValue || false;
        } else {
          newInputValues[customFieldName] = defaultValue || "";
        }
      }
    }

    formHook.mergeInput(newInputValues, true);
  }, [JSON.stringify(customFields), selectedCustomResourceTypeId]);

  const display = <div>{formHook.display}</div>;

  return { display };
};
