import { ErrorLogger } from "@utils/EventLogger";
import { ItemMutation } from "../../../../../../../utils/Functions/Graphql/ItemMutation";
import { generateGraphql } from "@rivial-security/generategraphql";
import { submitRiskComplianceSyncJob } from "../../../../../../Compliance/Evidence/functions/submitRiskComplianceSyncJob";
import { updateControlOverrideType } from "../../../../../../Compliance/Evidence/enums/updateControlOverrideType";

/**
 * Creates a link between risk control and an evidence in the database
 * @param {object} riskControl - the risk control to attach to evidence with id property
 * @param {object} evidence - the evidence to attach to risk control with id property
 * @return {Promise<void>}
 */
export const createRiskControlEvidenceLink = async (riskControl, evidence) => {
  if (!riskControl?.id || !evidence?.id || (!riskControl?.ownerGroup && !evidence?.ownerGroup)) {
    ErrorLogger(
      `Could not create riskControlEvidence link because required ids are missing${JSON.stringify({
        riskControl,
        evidence,
      })}`,
    );
    return;
  }

  // Find the resource ids
  const evidenceID = evidence?.id;
  const riskControlID = riskControl?.id;
  const organizationID = riskControl?.ownerGroup || evidence?.ownerGroup;

  //Create the link
  const { createMutation } = generateGraphql("RiskControlEvidenceLink");
  const linkCreationResponse = ItemMutation(createMutation, {
    riskControlID,
    evidenceID,
    ownerGroup: organizationID,
  });

  // Check if the Evidence is enabled. If not, skip the riskComplianceSyncJob
  if (evidence?.enabled) {
    //Trigger manual risk control check now that the control has a new evidence attached
    if (riskControlID) {
      submitRiskComplianceSyncJob({
        evidenceID,
        organizationID,
        riskControlOverrideID: riskControlID,
        overrideType: updateControlOverrideType.EVIDENCE_LINKED,
      });
    } else {
      ErrorLogger(
        `Cannot check control upon creating evidence risk control link!${JSON.stringify({
          riskControl,
          evidence,
        })}`,
      );
    }
  }

  return linkCreationResponse;
};
