import AWS from "aws-sdk";
import { Auth } from "@aws-amplify/auth";
import { ErrorLogger } from "@utils/EventLogger";
import { GetOrgIamCredentials } from "./GetOrgIamCredentials";
import { ICredentials } from "@aws-amplify/core";
import { ItemQuery } from "../Graphql/ItemQuery";
import { S3FileType } from "./types/S3FileType";
import { S3_BUCKET_NAME } from "../../../env-config";
import awsmobile from "../../../aws-exports";

/**
 * Get Signed S3 object URL
 */
export const getSignedObjectUrlV2 = async (s3File: S3FileType): Promise<string> => {
  const { key, bucket, organizationID } = s3File;
  let s3BucketName = bucket;

  const getOrganization_s3BucketName = `
    query GetOrganization($id: ID!) {
      getOrganization(id: $id) {
        id
        name
        s3BucketName
      }
    }
  `;

  if (!bucket && organizationID) {
    const org: { s3BucketName?: string } = await ItemQuery(getOrganization_s3BucketName, organizationID);
    s3BucketName = org?.s3BucketName;
  }

  /**
   * Get credentials for S3 bucket
   */
  const isDefaultBucket = s3File.bucket === S3_BUCKET_NAME;
  let credentials: AWS.Credentials | ICredentials | null;
  if (isDefaultBucket) {
    credentials = await Auth.currentCredentials();
  } else {
    credentials = await GetOrgIamCredentials({ organizationID });
  }

  const s3 = new AWS.S3({
    credentials,
    region: awsmobile?.aws_project_region,
  });

  const params = { Bucket: s3BucketName, Key: key, Expires: 300 };

  return new Promise((resolve, reject) => {
    s3.getSignedUrl("getObject", params, (err, url) => {
      if (err) {
        ErrorLogger("Error! ", err);
        reject(err);
      } else {
        resolve(url);
      }
    });
  });
};
