import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useEffect } from "react";

/**
 * Traverse through the parent Controls and format the ControlAuditLinks into an array to be used by the Grid
 * @param {object} item - the up-to-date audit data
 * @param {object} dataGrid - the data grid hook
 * @param {number} resetIndex - reset index, indicates data change need to update grid data
 */
export const useAuditControlsData = ({ item, dataGrid, resetIndex }) => {
  useEffect(() => {
    dataGrid.setIsLoading(true);
    if (!isNullOrUndefined(item?.controls?.items)) {
      const auditControls = [];

      for (const controlLink of item?.controls?.items ?? []) {
        // Exposes custom fields from the 'Control' type so that the CustomFieldEditor can show current values
        let customFieldData = {};
        try {
          customFieldData = JSON.parse(controlLink?.control?.customFieldData);
        } catch (e) {
          //pass if no custom fields to display
        }

        const evidenceNames = (controlLink?.evidenceLinks?.items ?? []).map(
          (evidenceLink) => `${evidenceLink?.evidence?.name}=${!!evidenceLink.reviewed}` || "",
        );

        auditControls.push({
          ...controlLink.control,
          isCompliant: controlLink.isCompliant,
          auditor: controlLink.auditor,
          interviewee: controlLink.interviewee,
          notes: controlLink.notes,
          auditControlLink: controlLink,
          evidenceNames: JSON.stringify(evidenceNames),
          evidenceLinks: controlLink.evidenceLinks,
          ...customFieldData,
        });
      }

      dataGrid.setData(auditControls);
    }
    dataGrid.setIsLoading(false);
  }, [JSON.stringify(item?.controls?.items), resetIndex]);
};
