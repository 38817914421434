import { useRiskControlDataGrid } from "../hooks/useRiskControlDataGrid";
import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";

/**
 * @description - Data grid for risk controls
 * @param {object} props - props
 * @returns {JSX.Element} - component
 */
const RiskControlDataGrid = (props) => useRiskControlDataGrid(props).display;

export default withOrganizationCheck(RiskControlDataGrid);
