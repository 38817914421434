import { modules, resources } from "@rivial-security/role-utils";

import ArtifactTypeCustomField from "../components/ArtifactTypeCustomField";
import ArtifactValidation from "../../ArtifactValidation/ArtifactValidation";
import DateField from "../../../../../utils/CustomFields/DateField";
import { deleteArtifact } from "../functions/deleteArtifact";
import { useDataGrid } from "../../../../../hooks/views/useDataGrid/useDataGrid";

/**
 * Displays list of artifacts with information on who created it and when
 * @param {string} organizationID - the currently selected organization
 * @param {string} module - compliance module for role checking
 * @param {string} resource - artifact resource for role checking
 * @param {object} props - additional props passed through into the useDataGrid hook
 * @returns {{selectedData: *, gridDisplay: *, cardDisplay: *, data: *, setData: *, setSelectedData: *, display: *, dashboardDisplay: *, isLoading: *, createResourceModal: {setModalIsOpen: *, modalButton: JSX.Element, modalIsOpen: *, modal: JSX.Element, toggleModal: function(): void}, setIsLoading: *, resetFunction: function(boolean=): Promise<void>, selectedIDs: *, setCheckboxSelection: *}}
 */
export const useArtifactDataGrid = ({
  organizationID,
  module = modules.COMPLIANCE,
  resource = resources.ARTIFACT,
  ...props
}) => {
  const grid = useDataGrid({
    organizationID,
    typename: "Artifact",
    disableRoleChecking: true,
    query: artifactsByOwnerGroup,
    variables: {
      ownerGroup: organizationID,
    },
    persistenceUUID: "8712c493-9847-4a31-8ffa-59fb52522d54",
    fields: [
      {
        name: "name",
        minWidth: 100,
        flex: 1,
      },
      {
        name: "type",
        component: <ArtifactTypeCustomField />,
        searchKeys: ["evidenceActivity.type"],
        width: 200,
        minWidth: 100,
      },
      {
        name: "createdAt",
        friendlyName: "Upload Date",
        width: 200,
        minWidth: 100,
        component: <DateField fieldName={"createdAt"} />,
        valueGetter: (_value, row) => {
          if (row?.createdAt) {
            return new Date(row?.createdAt).toLocaleString();
          } else {
            return "No Date";
          }
        },
      },
    ],

    options: ["details", "delete"],
    deleteFunction: deleteArtifact,
    detailsComponent: <ArtifactValidation />,
    module,
    resource,
    ...props,
  });

  return {
    ...grid,
  };
};

const artifactsByOwnerGroup = /* GraphQL */ `
  query ArtifactsByOwnerGroup(
    $ownerGroup: String
    $sortDirection: ModelSortDirection
    $filter: ModelArtifactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    artifactsByOwnerGroup(
      ownerGroup: $ownerGroup
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        evidenceActivity {
          id
          ownerGroup
          createdAt
          type
        }
        ownerGroup
      }
      nextToken
    }
  }
`;
