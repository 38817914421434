import { modules, resources } from "@rivial-security/role-utils";

import Dashboard from "../../../../utils/GenericComponents/Dashboard";
import DashboardCard from "../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import DataLoader from "../../../../utils/LoadingComponents/DataLoader";
import { GENERIC_FIELD_TYPES } from "@utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import Grid from "@mui/material/Grid";
import React from "react";
import TagsField from "../../../../utils/Tags/customFields/TagsField";
import { TaskDepartmentCustomField } from "../components/TaskDepartmentCustomField";
import { TaskStatus } from "../constants/taskStatus";
import { TaskWork } from "../components/TaskWork/TaskWork";
import { createTaskTagLink } from "../functions/createTaskTagLink";
import { enumToDropdownData } from "@rivial-security/func-utils";
import { fieldContexts } from "../../../../enums/fieldContexts";
import { getResourceAttribute } from "../../../../definitions/functions/getResourceAttribute";
import { getResourceQueryData } from "../../../../definitions/functions/getResourceQueryData";
import { taskType } from "../constants/taskType";
import { updateTaskThroughGraphql } from "../components/TaskWork/functions/updateTaskThroughGraphql";
import { useDetailsCard } from "@hooks/views/useDetailsCardV2";
import TaskPointOfContact from "@views/Program/Tasks/components/TaskPointOfContact";

/**
 * View showing a single task with all available information on it
 * @param {string} itemId - the id of the task to retrieve
 * @param {string} organizationID - the id of the organization
 * @param {string} moduleInit - the module to use for role checking
 * @param {string} resourceInit - the resource to use for role checking
 * @param {function} [toggleModal] - function to show/hide containing modal (if any)
 * @param {object} props - any additional props to pass down to useDetailsCard
 * @returns {{isLoading: boolean, setInputId: (function(((function(*): *)|*)): void), inputId: *, item: *, setIsLoading: (function(((function(boolean): boolean)|boolean)): void), tableDisplay: (React.DetailedReactHTMLElement<{item: *, resetFunction: ((function(): void)|*), updateItemById: updateItemById, key: string}, HTMLElement>|JSX.Element), resetFunction: ((function(): void)|*), display: JSX.Element, resetIndex: number, reset: (function(): void), setItem: (function(*): void)}}
 */
export const useTaskDetails = ({
  itemId,
  organizationID,
  module: moduleInit,
  resource: resourceInit,
  toggleModal,
  ...props
}) => {
  const module = moduleInit || modules.GOVERNANCE;
  const resource = resourceInit || resources.TASK;
  const typename = resources.TASK;
  const disableRoleChecking = true;

  //SAMPLE FIELD CONFIG
  const displayFields = getResourceAttribute({
    typename,
    attribute: "views.details.displayFields",
  });
  const { queryConfig, updateMutation, nestedFields } = getResourceQueryData({
    fieldContext: fieldContexts.DETAILS,
    typename,
  });

  queryConfig.itemId = itemId;
  const customFields = [
    {
      field: "module",
      inputType: GENERIC_FIELD_TYPES.MODULE,
    },
    {
      field: "status",
      inputType: GENERIC_FIELD_TYPES.DROPDOWN,
      inputConfig: {
        data: enumToDropdownData({
          ENUM: TaskStatus,
          overrides: {
            [TaskStatus.TODO]: "To Do",
          },
        }),
      },
      displayConfig: {
        customFormat: (value) => {
          if (value === "Todo") {
            return "To Do";
          }
          return value;
        },
        forceSentenceCase: true,
      },
    },
    {
      field: "type",
      inputType: GENERIC_FIELD_TYPES.SINGLE_SELECT,
      inputConfig: {
        data: enumToDropdownData({
          ENUM: {
            CHECKLIST: taskType.CHECKLIST,
            CUSTOM: taskType.CUSTOM,
          },
        }),
      },
      disableEdits: ({ item }) => item?.type !== taskType.CUSTOM && item?.type !== taskType.CHECKLIST,
    },
    {
      field: "dueDate",
      inputType: GENERIC_FIELD_TYPES.DATE,
      inputConfig: {
        dateOnly: true,
      },
      displayConfig: {
        dateOnly: true,
      },
    },
    {
      field: "pointOfContact",
      component: <TaskPointOfContact />,
    },
    {
      field: "department",
      component: <TaskDepartmentCustomField />,
    },
    {
      field: "tags",
      component: (
        <TagsField
          module={module}
          resource={resource}
          createLinkFunction={createTaskTagLink}
          organizationID={organizationID}
        />
      ),
    },
  ];
  const detailsCard = useDetailsCard({
    displayFields,
    nestedFields,
    customFields,
    updateMutation,
    queryConfig,
    module,
    resource,
    disableRoleChecking,
    config: {
      enableNotes: true,
      header: "Task Details",
    },
    typename,
    toggleModal,
    ...props,
  });

  const style = { height: "100%" };
  const display = (
    <Dashboard resetFunction={detailsCard?.reset}>
      <Grid container spacing={2}>
        <Grid container spacing={2} item lg={4} md={4} xs={12}>
          <Grid item lg={12} xs={12}>
            <DashboardCard style={style} title={"General Information"} icon={"icon-list"}>
              {detailsCard.tableDisplay}
            </DashboardCard>
          </Grid>
        </Grid>
        <Grid container spacing={2} item lg={8} md={8} xs={12}>
          <Grid item lg={12} md={12} xs={12}>
            <DataLoader
              isLoading={detailsCard?.isLoading}
              isEnoughData={detailsCard?.item?.id}
              dataMessage={"The requested task could not be found."}
            >
              <TaskWork
                task={detailsCard?.item}
                updateTask={async (task) => {
                  await updateTaskThroughGraphql({
                    task,
                    includedFields: ["checklist", "custom"],
                  });
                }}
                enableLandingPageLink={true}
                toggleModal={toggleModal}
              />
            </DataLoader>
          </Grid>
        </Grid>
      </Grid>
    </Dashboard>
  );

  return {
    ...detailsCard,
    display,
  };
};
