import {
  findLossToleranceExceedance,
  getLossToleranceCurveXValues,
  getMaximumLossToleranceValue,
} from "@rivial-security/risk-calc-utils";
import { useEffect, useState } from "react";

import { ErrorLogger } from "@utils/EventLogger";
import { QueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import { getXAxisConfig } from "../functions/getXAxisConfig";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useBoolean } from "../../../../hooks/functional/useBoolean";
import { useMonteCarloLossCurves } from "./useMonteCarloLossCurves";

/**
 * Fetches and handles all state data for the Loss Tolerance Chart.
 * Uses MonteCarloContext to try and pull in System Residual and Inherent risk data as well
 * @param {string} organizationID
 * @param {boolean} [disableResidualLoss = false]
 * @param {object} [selectedLines = {}]
 * @param {object} [chartConfig = {}]
 */
export const useLossToleranceChartData = (
  organizationID,
  disableResidualLoss = false,
  selectedLines = {},
  chartConfig = {},
) => {
  const [isLoading, setIsLoading] = useBoolean(true);

  const [primaryxAxis, setPrimaryxAxis] = useState({});
  const [primaryyAxis, setPrimaryyAxis] = useState({});
  const [lossToleranceExceedance, setLossToleranceExceedance] = useState({});

  const [lossToleranceCurve, setLossToleranceCurve] = useState([]);

  const [ratingMap, setRatingMap] = useState(initialRatingMap);

  const {
    system,
    keyRiskIndicator,
    inherentRisk,
    residualRisk,
    inherentLossCurve,
    residualLossCurve,
    riskInherentLossCurve,
    riskResidualLossCurve,
  } = useMonteCarloLossCurves();

  /**
   * Fetches Rating Scale and Loss Tolerance Curve data from the Organization Object
   */
  useEffect(() => {
    const getOrganization = /* GraphQL */ `
      query GetOrganization($id: ID!) {
        getOrganization(id: $id) {
          id
          config
          riskSetup {
            high
            mediumHigh
            medium
            lowMedium
            low
          }
          lossToleranceCurve {
            amount
            probability
          }
        }
      }
    `;

    if (!isNullOrUndefined(organizationID)) {
      setIsLoading(true);

      QueryGetItem({ query: getOrganization, itemId: organizationID })
        .then((org) => {
          setRatingMap(org.riskSetup);
          setLossToleranceCurve(
            org.lossToleranceCurve
              .sort((a, b) => b.probability - a.probability)
              .map((row) => ({ x: row.amount, y: row.probability })),
          );
        })
        .catch((e) => ErrorLogger(`Error fetching Organization for System calculation${e.message}`))
        .finally(() => setIsLoading(false));
    }
  }, [organizationID]);

  /**
   * Calculates the annual loss curve information
   */
  useEffect(() => {
    const result = findLossToleranceExceedance({
      lossToleranceCurve,
      residualLossCurve: selectedLines?.systemResidualLossCurve === true ? residualLossCurve : riskResidualLossCurve,
    });
    setLossToleranceExceedance(result);
  }, [lossToleranceCurve, residualLossCurve, riskResidualLossCurve, residualRisk, selectedLines]);

  /**
   * Update the chart when the loss tolerance data changes, residual loss changes, or rating map changes
   */
  useEffect(() => {
    if (lossToleranceCurve && selectedLines) {
      //Create x-axis settings
      const lossToleranceValues = getLossToleranceCurveXValues(lossToleranceCurve);
      const max = getMaximumLossToleranceValue(lossToleranceValues);
      const xAxis = getXAxisConfig(
        ratingMap,
        max,
        selectedLines?.riskResidualLoss ? residualRisk : null,
        selectedLines?.systemResidualLoss ? system.residualRisk : null,
        chartConfig,
      ); // only show System residual loss line for now

      setPrimaryxAxis({ ...xAxis });

      //Create y-axis settings
      let yAxis = {
        title: "Probability of Risk (%)",
        minimum: 0,
        maximum: 1.0,
        labelFormat: "p0",
      };

      if (chartConfig?.yAxis) {
        yAxis = { ...yAxis, ...chartConfig.yAxis };
      }
      setPrimaryyAxis({ ...yAxis });
    }
  }, [ratingMap, residualRisk, inherentRisk, lossToleranceCurve, selectedLines]);

  return {
    system,
    keyRiskIndicator,
    lossToleranceCurve,
    ratingMap,
    primaryxAxis,
    primaryyAxis,
    isLoading,
    inherentLossCurve,
    residualLossCurve,
    residualRisk,
    inherentRisk,
    riskInherentLossCurve,
    riskResidualLossCurve,
    lossToleranceExceedance,
  };
};

const initialRatingMap = {
  low: 0.2,
  lowMedium: 0.4,
  medium: 0.6,
  mediumHigh: 0.8,
  high: 1.0,
};
