import { getCurrentDashboard } from "./getCurrentDashboard";
import { ItemMutation } from "../../../utils/Functions/Graphql/ItemMutation";
import { updateMutation } from "../graphql/customDashboardGraphql";

/**
 * Edits root fields on a Dashboard Layout such as Name
 * @param {string} dashboardID - the ID of the dashboard to edit
 * @param {string} pageID - the ID of the page to edit
 * @param {function} setItemQuery - the setItemQuery function from the useItemQuery hook
 * @param {string} name - the new name of the dashboard layout
 * @returns {Promise<void>}
 */
export const editDashboardLayoutDetails = async ({ dashboardID, pageID, setItemQuery, name }) => {
  // first, perform a clean fetch of the CustomDashboardLayouts
  const customDashboardQuery = await getCurrentDashboard({
    itemId: dashboardID,
  });

  // get layouts from CustomDashboard query
  const layouts = customDashboardQuery?.layouts || [];

  // Update layouts array by filtering out this layout based on pageID
  const foundLayout = layouts.find((layout) => layout.id === pageID);

  if (foundLayout) {
    foundLayout.name = name;
  }

  // perform a mutation to update the CustomDashboard object in the db with the new layouts
  const updatedItem = await ItemMutation(updateMutation, {
    id: dashboardID,
    layouts,
  });

  if (typeof setItemQuery === "function") {
    setItemQuery(updatedItem);
  }
};
