import { useState } from "react";
import { generateGraphql } from "@rivial-security/generategraphql";
import useListQuery from "../../../../../hooks/graphql/useListQuery";
import { useForm } from "../../../../../hooks/views/useForm";
import ControlDataGrid from "./ControlDataGrid";
import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";
import DataLoader from "../../../../../utils/LoadingComponents/DataLoader";

/**
 * Component instance of the useSelectControls hook
 * @param {object} props - all props passed to the useSelectControls hook
 * @returns {JSX.Element}
 * @constructor
 */
const SelectControls = (props) => {
  return useSelectControls(props).display;
};

/**
 * Custom hook for being able to single-select a Compliance Control.
 * Adds a Control Framework selection dropdown to a ControlDataGrid component, and queries the Grid once a Control Framework is selected.
 * @param {string} organizationID - selected organization
 * @param {object} props - all props passed to the ControlDataGrid component
 * @returns {{display: JSX.Element}}
 */
const useSelectControls = ({ organizationID, ...props }) => {
  const [controlSetId, setControlSetId] = useState(null);

  const { listQuery } = generateGraphql("ControlSet", ["name"]);

  const controlSets = useListQuery({
    query: listQuery,
    organizationID,
    disableRoleChecking: true,
  });

  const display = (
    <div
      style={{
        height: "100%",
      }}
    >
      <DataLoader isLoading={controlSets.isLoading} isEnoughData={controlSets.list.length > 0}>
        <ControlSetDropdown
          controlSets={controlSets.list}
          setControlSetId={setControlSetId}
          controlSetId={controlSetId}
        />
      </DataLoader>

      <DataLoader
        isLoading={controlSets.isLoading}
        isEnoughData={controlSetId}
        dataMessage={"Select a Control Framework to view Controls"}
      >
        <div style={{ height: "40em" }}>
          <ControlDataGrid controlFrameworkID={controlSetId} style={{ height: "100%" }} {...props} />
        </div>
      </DataLoader>
    </div>
  );

  return {
    display,
  };
};

const ControlSetDropdown = ({ controlSets, setControlSetId, controlSetId }) => {
  const form = useForm({
    id: "evidence-fetchby-form",
    fieldConfig: {
      controlControlSetId: {
        inputType: "dropdown",
        defaultValue: controlSetId,
        onChangeFunction: (e) => setControlSetId(e.controlControlSetId),
        dropdownConfig: {
          data: controlSets.map(({ id, name }) => {
            return {
              value: id,
              text: name,
            };
          }),
        },
      },
    },
    disableRoleChecking: true,
    disableResetButton: true,
    disableSubmitButton: true,
  });

  return form.display;
};

export default withOrganizationCheck(SelectControls);
