import { generateGraphql } from "@rivial-security/generategraphql";
import { ItemMutation } from "@rivial-security/appsync-utils";

/**
 * Function which updates a task using an active graphql connection
 * @param {object} task - the data to write to the task, should contain an id
 * @param {string[]} includedFields - fields to include in the update mutation (besides the id
 * @returns {Promise<*|null>}
 */
export const updateTaskThroughGraphql = async ({ task, includedFields = [] }) => {
  const { updateMutation } = generateGraphql("Task");

  if (!task?.id) {
    return null;
  }

  const taskToSave = {
    id: task.id,
  };

  includedFields.forEach((field) => {
    taskToSave[field] = task[field];
  });

  return await ItemMutation({
    mutation: updateMutation,
    input: {
      ...taskToSave,
    },
  });
};
