import TabCard from "../../../../utils/GenericComponents/TabCard";
import { WIDGET_TYPE } from "@rivial-security/widget-utils";
import React from "react";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import CustomWidgetSelectionTiles from "./CustomWidgetSelectionTiles";
import { HISTORY_CHART } from "../../WidgetRenderer/constants/BUILT_IN_WIDGETS";
import BuiltInWidgetSelectionTiles from "./BuiltInWidgetSelectionTiles";

/**
 * Displays a tabbed card for selecting a history chart widget
 *
 * @param {string} organizationID - The organization ID
 * @param {function} onSelect - The function to call when a widget is selected
 * @returns {JSX.Element}
 * @constructor
 */
const HistoryChartWidgetTabCard = ({ organizationID, onSelect }) => {
  return (
    <div>
      <TabCard
        // tabDisplay
        items={[
          {
            icon: "icon-grid",
            title: "Built In",
            component: (
              <BuiltInWidgetSelectionTiles
                widgets={HISTORY_CHART}
                organizationID={organizationID}
                onSelect={onSelect}
              />
            ),
          },
          {
            icon: "icon-wrench",
            title: "Custom",
            component: (
              <CustomWidgetSelectionTiles
                organizationID={organizationID}
                onSelect={onSelect}
                filteredWidgetType={WIDGET_TYPE.HISTORY_CHART}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default withOrganizationCheck(HistoryChartWidgetTabCard);
