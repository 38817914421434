import { useNestedDataCard } from "../../../../hooks/views/useNestedDataCard/useNestedDataCard";
import { deleteRiskThreatLink } from "../../../Risk/RiskConfig/KeyRiskIndicators/functions/deleteRiskThreatLink";
import { createRiskThreatLink } from "../../../Risk/RiskConfig/KeyRiskIndicators/functions/createRiskThreatLink";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import React from "react";
import { useKeyRiskIndicatorsDataGrid } from "../../../Risk/RiskConfig/KeyRiskIndicators/hooks/useKeyRiskIndicatorsDataGrid";
import CreateNewRisk from "../../../Risk/RiskConfig/KeyRiskIndicators/components/AddRisksToOrganization/CreateNewRisk";
import KeyRiskIndicatorsDataGrid from "../../../Risk/RiskConfig/KeyRiskIndicators/components/KeyRiskIndicatorsDataGrid";

/**
 * Displays a nested data card for Threat KRIs (m-m connection).
 * @param {object} item - the KRI to display threats for
 * @param organizationID
 * @returns {*}
 * @constructor
 */
const ThreatKeyRiskIndicators = ({ item, organizationID }) => {
  const nestedCard = useNestedDataCard({
    item,
    organizationID,
    field: "riskLinks",
    connectionField: "risk",
    deleteFunction: deleteRiskThreatLink,
    typename: "Risk",
    parentTypename: "Threat",
    fields: ["riskLinks"],
    nestedFields: {
      riskLinks: `(limit: 1000) { items { id risk { id name description annualRateOfOccurrence ownerGroup } } }`,
    },
    grid: <KeyRiskIndicatorsDataGrid />,
    gridHook: useKeyRiskIndicatorsDataGrid,
    form: <CreateNewRisk />,
    createFunction: createRiskThreatLink,
  });

  return nestedCard.gridDisplay;
};

export default withOrganizationCheck(ThreatKeyRiskIndicators);
