import { modules, resources } from "@rivial-security/role-utils";

import PointOfContactList from "../../PointOfContacts/components/PointOfContactList/PointOfContactList";
import React from "react";
import { generateGraphql } from "@rivial-security/generategraphql";
import { getMany } from "../../../../utils/Functions/getMany";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useQueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";

/**
 * Hook that displays Users attached to a Role, and allows users to be added to the Role
 * @param organizationID
 * @param {string} itemId - the ID of the Role to get Users for
 */
export const useRoleUsers = ({ organizationID, itemId }) => {
  const assignedUsers = useAssignedUsers({ itemId, organizationID });

  return {
    ...assignedUsers,
  };
};

/**
 * Displays a Grid of Users that are currently Assigned to a Role
 */
const useAssignedUsers = ({ itemId }) => {
  const module = modules.ORGANIZATION_MANAGER;
  const resource = resources.ROLE;
  const queryFields = ["userLinks"];
  const nestedFields = {
    userLinks: `(limit: 500) {
      items {
        id
        userID
        roleID
        ownerGroup
        user {
          id
          name
          email
          phoneNumber
          ownerGroup
        }
        role {
          id
        }
      }
    }`,
  };

  const { getQuery } = generateGraphql(resource, queryFields, nestedFields);

  /**
   * Gets the Role object with User Links
   */
  const queryHook = useQueryGetItem({
    query: getQuery,
    itemId,
    disableRoleChecking: true,
  });

  /**
   * Gets the many-to-many users from the role
   */
  const getUsersFromRole = (role) => {
    if (!isNullOrUndefined(role?.userLinks?.items)) {
      return getMany(role, "userLinks", "user");
    } else {
      return [];
    }
  };

  const display = (
    <PointOfContactList items={getUsersFromRole(queryHook.item)} dataMessage={"No Users are assigned to this Role"} />
  );

  return {
    display,
    ...queryHook,
  };
};
