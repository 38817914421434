import React from "react";
import { useModal } from "../../../hooks/views/useModal";
import OpenButton from "../OpenButton";

/**
 * A utility Button that has a built in modal
 * @param {JSX.Element} children - component displayed in the modal
 * @param {string} typename - typename, used in the modal header
 * @param props
 * @param config
 * @returns {JSX.Element}
 * @constructor
 */
const OpenModalButton = ({ children, typename = "Resource", config = {}, ...props }) => {
  const modal = useModal(`Open a ${typename}`, children, <OpenButton typename={typename} {...props} />, {
    ...config,
  });

  return <>{modal.modalButton}</>;
};

export default OpenModalButton;
