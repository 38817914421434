import { modules, resources } from "@rivial-security/role-utils";

import { INCIDENT_STATUS } from "../../../../typedefs/Response/Incident/Incident";
import { arrayIncludes } from "../../../../utils/Functions/Array/arrayIncludes";
import { enumToDropdownData } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { sortClassificationTiers } from "../../Plan/Classifications/functions/sortClassificationTiers";
import { useAddAccessControl } from "../../../../utils/AccessControl/hooks/useAddAccessControl";
import { useClassificationGrid } from "../../Plan/Classifications/hooks/useClassificationGrid";
import { useDetections } from "../../Plan/Detections/hooks/useDetections";
import { useForm } from "../../../../hooks/views/useForm/useForm";

/**
 * Form for creating a new Incident
 * @param {string} organizationID - selected organization
 * @param {function} getNewItem - callback for the data after creation
 * @param {string[]} disabledFields - optional. array of field names that will be disabled (but not hidden) in the form
 * @param {object} ...props - all other props passed to useForm hook
 * @returns {{input: {}, display: *}}
 */
export const useCreateIncident = ({ organizationID, getNewItem, disabledFields = [], ...props }) => {
  const module = modules.INCIDENT_RESPONSE;
  const resource = resources.INCIDENT;

  const { createMutation } = generateGraphql("Incident", ["what", "who", "when", "where", "why", "status"]);

  const classifications = useClassificationGrid({ organizationID });

  const detections = useDetections({ organizationID });

  /**
   * Add Access Control to an incident
   */
  const addAccessControl = useAddAccessControl({
    typename: "Incident",
    disableFormButtons: true,
    organizationID,
  });
  const callback = (newIncident) => addAccessControl?.submitFunction({ itemToAssign: newIncident });

  const form = useForm({
    typename: "Incident",
    mutation: createMutation,
    callback,
    module,
    resource,
    organizationID,
    getNewItem,
    fieldConfig: {
      status: {
        required: true,
        label: "Initial Status",
        tooltip: "The status of this incident",
        inputType: "dropdown",
        dropdownConfig: {
          data: enumToDropdownData({ ENUM: INCIDENT_STATUS }),
        },
        defaultValue: INCIDENT_STATUS.OPEN,
      },
      what: {
        label: "What Happened?",
        tooltip: "Give a brief description of the Incident",
        inputType: "textarea",
        required: true,
      },
      when: {
        label: "When did this happen?",
        tooltip: "Give an estimated date for when this Incident occurred",
        inputType: "date",
      },
      who: {
        label: "Who was involved?",
        inputType: "text",
      },
      where: {
        label: "Where did it happen?",
        inputType: "text",
      },
      why: {
        label: "Why did it happen?",
        inputType: "text",
      },
      classificationTierID: {
        label: "Classification Tier",
        tooltip: "How severe is this Incident? This determines which Response Teams should be involved",
        inputType: "dropdown",
        dropdownConfig: {
          data: sortClassificationTiers(classifications?.data).map((item) => {
            return {
              text: `${item.level} - ${item.name}`,
              value: item.id,
            };
          }),
        },
        disabled: arrayIncludes(disabledFields, "classificationTierID"),
      },
      detection: {
        removeFromInput: true, // temp until classification is implemented
        label: "Detection Type",
        tooltip: "How was this Incident detected?",
        inputType: "dropdown",
        dropdownConfig: {
          data: detections?.data?.map((item) => {
            return {
              text: `${item.name}`,
              value: item,
            };
          }),
        },
      },
      accessControl: {
        inputType: "custom",
        removeFromInput: true,
        customConfig: {
          component: addAccessControl?.displaySwitch,
        },
      },
    },
    ...props,
  });

  return {
    ...form,
  };
};
