import { useSystemDataGrid } from "../../../Risk/Systems/hooks/useSystemDataGrid";
import { useForm } from "../../../../hooks/views/useForm/useForm";
import React, { useEffect } from "react";

/**
 * A grid and switch for selecting systems to be used in the RA report.
 *
 * @param {string} organizationID - current organization ID
 * @param {function} onChangeCallback - callback accepting the selected item as an object
 */
export const useSelectSystems = ({ organizationID, onChangeCallback }) => {
  const systemGrid = useSystemDataGrid({ organizationID });

  /**
   * Enable checkbox selection on the system grid
   */
  useEffect(() => {
    systemGrid?.setCheckboxSelection(true);
  }, []);

  const switchForm = useForm({
    disableResetButton: true,
    disableSubmitButton: true,
    fieldConfig: {
      allSystems: {
        label: "Include All Systems",
        inputType: "switch",
        defaultValue: true,
      },
    },
  });

  // When systems are selected, update form input
  useEffect(() => {
    if (switchForm?.input?.allSystems === false && Array.isArray(systemGrid?.selectedData)) {
      onChangeCallback &&
        onChangeCallback(
          systemGrid?.selectedData?.map((item) => {
            return {
              id: item.id,
              name: item.name,
              ownerGroup: item.ownerGroup,
            };
          }),
        );
    } else {
      onChangeCallback && onChangeCallback([]);
    }
  }, [systemGrid.selectedData]);

  // Clear selection when switch is "All Systems"
  useEffect(() => {
    if (switchForm?.input?.allSystems === true) {
      systemGrid.setSelectedData([]);
    }
  }, [JSON.stringify(switchForm?.input)]);

  const display = (
    <div>
      {switchForm.display}
      {switchForm?.input?.allSystems === false && <div style={{ height: "30em" }}>{systemGrid.gridDisplay}</div>}
    </div>
  );

  return {
    ...systemGrid,
    switchForm,
    display,
  };
};
