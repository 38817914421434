import { Maybe, Target, TargetFindingLink, Vulnerability } from "@rivial-security/schema-types";

import { ListQueryBy } from "@rivial-security/appsync-utils";
import { isNonEmptyArray } from "@rivial-security/func-utils";

export interface GetTargetsBinnedByVulnerabilityInput {
  organizationID: string;
}

type OnlyTargetsRequiredVulnerability = Partial<Vulnerability> & { targetsCustom: Partial<Target>[] };

export type GetTargetsBinnedByVulnerabilityOutput = Record<string, OnlyTargetsRequiredVulnerability>;

/**
 * A data retrieval function allowing to get all targets in an organization categorized into an object by their vulnerability id
 * @param organizationID - currently selected organization
 */
export const getTargetsBinnedByVulnerability = async ({
  organizationID,
}: GetTargetsBinnedByVulnerabilityInput): Promise<GetTargetsBinnedByVulnerabilityOutput> => {
  const vulnerabilities: GetTargetsBinnedByVulnerabilityOutput = {};

  if (!organizationID) return vulnerabilities;

  //Query all targets
  const targets: Partial<Target>[] = await ListQueryBy({
    query: listTargetsByOwnerGroup,
    limit: 1000,
    variables: { ownerGroup: organizationID },
  });

  if (!isNonEmptyArray(targets)) {
    return vulnerabilities;
  }

  //Bin targets into vulnerabilities object
  for (const target of targets) {
    const vulnerabilityLinks: Maybe<TargetFindingLink>[] = target?.vulnerabilities?.items ?? [];
    if (!isNonEmptyArray(vulnerabilityLinks)) {
      continue;
    }

    for (const vulnerabilityLink of vulnerabilityLinks) {
      const vulnerabilityID = vulnerabilityLink?.vulnerabilityID;
      if (!vulnerabilityID) {
        continue;
      }

      if (!vulnerabilities[vulnerabilityID]) {
        vulnerabilities[vulnerabilityID] = {
          targetsCustom: [target],
        };
      } else {
        vulnerabilities[vulnerabilityID]?.targetsCustom.push(target);
      }
    }
  }

  return vulnerabilities;
};

export const listTargetsByOwnerGroup = `
  query ListTargetsByOwnerGroup(
    $ownerGroup: String
    $sortDirection: ModelSortDirection
    $filter: ModelTargetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTargetsByOwnerGroup(
      ownerGroup: $ownerGroup
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerGroup
        ip
        hostName
        macAddress
        vulnerabilities (limit: 1000){
          items {
            id
            vulnerabilityID
          }
        }
      }
      nextToken
    }
  }
`;
