import { getWhenSeenVulnerabilityGroup, groupTargetVulnerabilityLinkPriority } from "@rivial-security/report-utils";

import { getStatusCounts } from "./getStatusCounts";
import { isNonEmptyArray } from "@rivial-security/func-utils";
import { convertTargetVulnerabilityStatusCountsToVulnerabilityStatus } from "./convertTargetVulnerabilityStatusCountsToVulnerabilityStatus";

/**
 * Calculates status Counts and priority for a single vulnerability
 * @param {object} vulnerability - the object for which to attach the new properties
 * @param {boolean} calculatePriority - if TRUE will also combine the priority based on the algorithm used in the vulnerability grouping function from report utils
 * @returns {object} - original object with the new properties added
 */
export const normalizeVulnerability = ({ vulnerability, calculatePriority }) => {
  if (!vulnerability) return vulnerability;

  const newVulnerability = {
    ...vulnerability,
  };

  const targetVulnerabilityLinks = vulnerability?.targets?.items;
  let statusCounts = {};
  let vulnerabilityStats = {};

  if (Array.isArray(targetVulnerabilityLinks)) {
    statusCounts = getStatusCounts(targetVulnerabilityLinks);
    vulnerabilityStats = convertTargetVulnerabilityStatusCountsToVulnerabilityStatus({ statusCounts });
  }
  newVulnerability.statusCounts = statusCounts;
  newVulnerability.vulnerabilityStats = vulnerabilityStats;

  const firstSeenDate = getWhenSeenVulnerabilityGroup({
    targetVulnerabilityLinks,
    whenSeen: "firstSeen",
  });
  const lastSeenDate = getWhenSeenVulnerabilityGroup({
    targetVulnerabilityLinks,
    whenSeen: "lastSeen",
  });
  newVulnerability.firstSeen = new Date(firstSeenDate).toLocaleDateString();
  newVulnerability.lastSeen = new Date(lastSeenDate).toLocaleDateString();

  if (calculatePriority) {
    const uniqueTargetLinks = {};
    const targetLinks = vulnerability?.targets?.items;
    if (isNonEmptyArray(targetLinks)) {
      targetLinks.forEach((link) => {
        const { id } = link;
        if (id) {
          uniqueTargetLinks[id] = link;
        }
      });
    }

    newVulnerability.priority = groupTargetVulnerabilityLinkPriority({
      uniqueTargetLinks,
    });
  }

  return newVulnerability;
};
