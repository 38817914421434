import React from "react";
import ArtifactDataGrid from "../General/components/ArtifactDataGrid";
import ArtifactValidation from "./ArtifactValidation";

/**
 * A hook which displays either a grid of artifacts to validate or a single
 * artifact validation page (based on the number of artifacts provided)
 * @param {string} organizationID - the currently selected organization ID
 * @param {object} artifacts - the artifacts in need of validation
 * @param {function} resetFunction - a function to refresh the artifacts that need validation
 * @param {boolean} isLoading - TRUE if the artifacts to validate are still loading
 * @param {string} module - the module this artifact validation is taking place for role checking
 * @param {string} resource - the resource this artifact validation is taking place, for role checking
 * @returns {{display: JSX.Element}}
 */
export const useMultipleArtifactValidation = ({
  organizationID,
  artifacts,
  resetFunction,
  isLoading,
  module,
  resource,
}) => {
  const isOne = Array.isArray(artifacts) && artifacts.length === 1;

  const display = isOne ? (
    //Artifact validation page (for one artifact)
    <ArtifactValidation organizationID={organizationID} item={artifacts?.[0]} module={module} resource={resource} />
  ) : (
    //Artifact grid (for multiple artifacts)
    <div style={{ height: "70em" }}>
      <ArtifactDataGrid
        data={artifacts}
        query={null}
        resetFunction={resetFunction}
        isLoading={isLoading}
        module={module}
        resource={resource}
      />
    </div>
  );
  return {
    display,
  };
};
