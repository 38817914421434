import { useState } from "react";
import { useSetAsyncData } from "@hooks/functional/useSetAsyncData";
import { getResourceQueryData } from "../../../../definitions/functions/getResourceQueryData";
import { fieldContexts } from "../../../../enums/fieldContexts";
import { getListByFieldString } from "../../../../definitions/functions/queries/getListByFieldString";
import { generateGraphql } from "@rivial-security/generategraphql";
import { ListQueryBy } from "@rivial-security/appsync-utils";

export interface UseGetTargetVulnerabilityActionsParams {
  itemID: string;
  indexName: string;
  partitionKey: string;
}

export interface UseGetTargetVulnerabilityActionsResult {
  data: Record<string, unknown>[];
  setData: (newData: Record<string, unknown>[]) => void;
  resetFunction: VoidFunction;
  isLoading: boolean;
}

export const useGetTargetVulnerabilityActions = ({
  itemID,
  indexName,
  partitionKey,
}: UseGetTargetVulnerabilityActionsParams): UseGetTargetVulnerabilityActionsResult => {
  const [data, setData] = useState<Record<string, unknown>[]>([]);
  const { resetFunction, isLoading } = useSetAsyncData({
    getData: async () => {
      const { queryFields, nestedFields } =
        getResourceQueryData({
          fieldContext: fieldContexts.GRID,
          typename: "GenericActionItem",
        }) || {};
      // @ts-expect-error - getResourceQueryData is not typed
      const fieldString = getListByFieldString({ queryFields, nestedFields });

      const { listByQuery } = generateGraphql(
        "TargetFindingLink",
        ["actions"],
        {
          actions: `(limit: 500) {
            items {
              ${fieldString}
            }
          }`,
        },
        {
          indexName,
          partitionKey,
          partitionKeyType: "ID",
        },
      );

      return await ListQueryBy({
        query: listByQuery,
        variables: { [partitionKey]: itemID },
        limit: 500,
      });
    },
    setData: (newData) => {
      const actions: Record<string, unknown>[] = [];
      for (const link of newData) {
        actions.push(...(link?.actions?.items || []));
      }
      setData(actions);
    },
  });

  return {
    data,
    setData,
    resetFunction,
    isLoading,
  };
};
