import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { GenericDeleteButton } from "../../../../../../utils/GenericComponents/GenericDeleteButton";
import { convertCamelCaseToSentence, isNullOrUndefined } from "@rivial-security/func-utils";
import { Typography } from "@mui/material";
import { getTimelineDotIcon } from "../../../../Targets/functions/getTimelineDotIcon";
import { getTimelineDate } from "../../../../../Compliance/Evidence/components/EvidenceActivityItem/functions/getTimelineDate";
import { getTimelineContent } from "../../../../Targets/functions/getTimelineContent";
import { modules, resources } from "@rivial-security/role-utils";
import { useCheckPermissions } from "../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useBoolean } from "../../../../../../hooks/functional/useBoolean";
import { TargetVulnerabilityHistoryType } from "../../../../../../typedefs/Testing/Vulnerability/Vulnerability";
import UnderlinedTooltip from "../../../../../../utils/GenericComponents/Tooltips/UnderlinedTooltip";
import { deleteVulnerabilityTargetHistory } from "../../../functions/deleteVulnerabilityTargetHistory";
import React from "react";

/**
 * Displays a single item in the Evidence timeline
 * @param {boolean} [isLastItem = false] - determines if there should be a trailing connector
 * @param {string} type - the TargetVulnerabilityHistory type
 * @param {string} comment - the comment for the activity
 * @param {Date} date - the date of the activity
 * @param {object} item - full evidence activity item
 * @param {function} resetFunction - resets parent query to re-fetch all activity
 * @param {object} vulnerability - the vulnerability item
 * @param {boolean} isGrouped - determines if the timeline is grouped by assessment or not
 * @returns {JSX.Element}
 */
const VulnerabilityTimelineItem = ({
  isLastItem,
  date,
  item,
  resetFunction,
  type,
  comment,
  isGrouped,
  vulnerability,
}) => {
  const checkPermissions = useCheckPermissions({
    module: modules.VULNERABILITIES,
    resource: resources.VULNERABILITY,
  });
  const [showDelete, setShowDelete] = useBoolean();

  const onHoverOn = () => {
    //Do not show delete button for entries linked to assessments
    if (checkPermissions?.resource?.update && !item?.assessmentID) {
      setShowDelete(true);
    }
  };

  const onHoverOff = () => {
    setShowDelete(false);
  };
  return (
    <TimelineItem onMouseEnter={onHoverOn} onMouseLeave={onHoverOff}>
      <TimelineOppositeContent>
        {type && (
          <Typography color="textSecondary" style={{ fontSize: ".9em", fontWeight: "bold" }}>
            {convertCamelCaseToSentence(type)}
          </Typography>
        )}
        <Typography color="textSecondary">{getTimelineDate(date)}</Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot color={"grey"}>{getTimelineDotIcon({ type })}</TimelineDot>
        {isLastItem === false && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        {getTimelineContent({
          type,
          item,
          isGrouped,
          vulnerability,
        })}
        {!isNullOrUndefined(comment) && type === TargetVulnerabilityHistoryType.TAG && (
          <span>
            {" "}
            with a{" "}
            <UnderlinedTooltip tooltip={comment}>
              <span>comment</span>
            </UnderlinedTooltip>
          </span>
        )}
        {showDelete && (
          <span className={"float-right"} style={{ position: "absolute", right: "-1em", top: "0em" }}>
            <GenericDeleteButton
              deleteFunction={deleteVulnerabilityTargetHistory}
              item={item}
              resetFunction={resetFunction}
            />
          </span>
        )}
      </TimelineContent>
    </TimelineItem>
  );
};

export default VulnerabilityTimelineItem;
