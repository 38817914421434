import { ErrorLogger } from "@utils/EventLogger";
import { deepDelete } from "../../../../utils/Functions/deepDelete";

/**
 * Deletes a single RecommendationItem object along with associated Many-To-Many connections
 * @param {object} recommendation - Recommendation to delete
 * @param {object} config - configuration object
 * @param {boolean} config.deleteActionItems - if true, will delete all Action Items associated with this Recommendation
 * @param {boolean} config.deleteObservations - if true, will delete all Observations associated with this Recommendation
 * @param {function} onJobStarted - callback function to get job
 */
export const deleteRecommendation = async (recommendation, config = {}, onJobStarted) => {
  const { deleteActionItems = false, deleteObservations = false } = config;

  if (!recommendation?.id) {
    ErrorLogger("[deleteRecommendation.js] Missing Recommendation ID");
    return;
  }

  /**
   * Delete Recommendation in Fargate
   */
  return await deepDelete({
    typename: "Recommendation",
    item: recommendation,
    config: {
      deleteActionItems,
      deleteObservations,
    },
    onJobStarted,
  });
};
