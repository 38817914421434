"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListQuery = void 0;
const handleListQuery_1 = require("./handleListQuery");
const getEnvironment_1 = require("../getEnvironment");
const handleGraphqlClient_1 = require("../handleGraphqlClient");
/**
 * Performs a list GraphQL query.
 * @param appsyncEndpoint - the endpoint of the AppSync API
 * @param params - params for the function including appsyncEndpoint and other filters, limit, etc.
 */
const ListQuery = async ({ appsyncEndpoint, ...params }) => {
    const env = (0, getEnvironment_1.getEnvironment)({ appsyncEndpoint });
    const queryFunction = (0, handleGraphqlClient_1.handleGraphqlClient)({ env, appsyncEndpoint });
    return await (0, handleListQuery_1.handleListQuery)({ ...params, queryFunction });
};
exports.ListQuery = ListQuery;
