import React, { useState } from "react";
import PropTypes from "prop-types";
import { compose } from "react-apollo";
import { graphqlMutation } from "aws-appsync-react";
import gql from "graphql-tag";
import {
  checkSystemForRiskControlOverride,
  findIndexOfRiskControlOverride,
  isRiskControlOverrideFieldEnabled,
} from "@rivial-security/risk-calc-utils";
import { updateSystemRiskControlOverride } from "../../../../graphql/__systemGQL";
import { getControlEffectiveness } from "@rivial-security/risk-calc-utils";
import { formattedDollar } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

const { getQuery: getSystem } = generateGraphql("System");

const RiskControlOverridesV2 = (props) => {
  const riskControlOverrides = props.system && props.system.riskControlOverrides;

  const indexOfRiskControlOverride = findIndexOfRiskControlOverride(riskControlOverrides, props.riskControl);

  const initialValue =
    props.riskControl &&
    props.system &&
    props.system.riskControlOverrides &&
    props.system.riskControlOverrides.length > 0 &&
    indexOfRiskControlOverride !== -1 &&
    props.system.riskControlOverrides[indexOfRiskControlOverride] &&
    props.system.riskControlOverrides[indexOfRiskControlOverride][props.field];

  const [value, setValue] = useState(initialValue ? initialValue : "");

  const doesOverrideExist =
    props.system &&
    checkSystemForRiskControlOverride(props.system && props.system.riskControlOverrides, props.field) !== false;

  const isOverrideEnabled =
    indexOfRiskControlOverride !== -1 &&
    isRiskControlOverrideFieldEnabled(props.system.riskControlOverrides[indexOfRiskControlOverride], props.field);

  const overrideValue =
    doesOverrideExist &&
    props.system.riskControlOverrides[indexOfRiskControlOverride] &&
    props.system.riskControlOverrides[indexOfRiskControlOverride][props.field];

  const updateSystem = async (input) => {
    const data = props.updateSystem(input);
    setTimeout(() => {
      props.resetFunction && props.resetFunction();
    }, 1000);
    return data;
  };

  const getPercentage = () => {
    if (props.total) {
      if (isOverrideEnabled) {
        return overrideValue / props.total;
      } else {
        return props.riskControl[props.field] / props.total;
      }
    }
  };

  return (
    <>
      {!isOverrideEnabled
        ? (() => {
            switch (props.field) {
              case "costOfControl":
                return `${formattedDollar(props.riskControl[props.field])}`;
              case "controlEffectiveness":
                return `${(getControlEffectiveness(props.riskControl, props.system) * 100).toFixed(2)}%`;
              case "strengthRating":
                return `${props.riskControl[props.field] && (props.riskControl[props.field] * 100).toFixed(2)}%`;
              case "implementationRating":
                return `${props.riskControl[props.field] && (props.riskControl[props.field] * 100).toFixed(2)}%`;
              case "riskReduction":
                return `${props.riskControl[props.field] && (props.riskControl[props.field] * 100).toFixed(2)}%`;
              default:
                return props.riskControl[props.field];
            }
          })()
        : null}
      {props.total && <>[ {(getPercentage() * 100).toFixed(0)}% ]</>}
    </>
  );
};

RiskControlOverridesV2.propTypes = {
  riskControl: PropTypes.object.isRequired,
  system: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
};

export default compose(graphqlMutation(gql(updateSystemRiskControlOverride), gql(getSystem), "System"))(
  RiskControlOverridesV2,
);
