import { useForm } from "../../../../hooks/views/useForm";
import { generateFieldConfig } from "./functions/generateFieldConfig";

/**
 * A form created based from a list of types
 * @param {object} formConfig - additional props to pass to the useForm hook
 * @param {object[]} fields - a list of fields to be used in the form with typename defined
 * @param {string} formID - form id to use the useForm hook
 * @return {*}
 */
const DynamicForm = ({ formConfig, fields, formID }) => {
  //Remove any un anticipated use of formConfig
  if (typeof formConfig === "object") {
    delete formConfig.fieldConfig;
  }

  const form = useForm({
    formID,
    fieldConfig: generateFieldConfig({ fields }),
    ...formConfig,
  });

  return form.display;
};

export default DynamicForm;
