import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { getRiskChange_riskChangeDetails } from "../../../graphql/getRiskChange_riskChangeDetails";
import SystemsAffectedList from "../../../customFields/SystemAffectedList";
import OverallROIChange from "../../../customFields/OverallROIChange";
import OverallResidualChange from "../../../customFields/OverallResidualChange";
import ChangeType from "../../../customFields/ChangeType";
import { useDetailsCard } from "../../../../../../hooks/views/useDetailsCardV2";
import SystemAvailabilityChange from "../../../customFields/SystemAvailabilityChange";
import NumberChange from "../../../../../../utils/CustomFields/NumberChange";

export const useSystemAvailabilityChangeDetails = ({
  itemId,
  queryConfig,
  detailsConfig,
  module,
  resource,
  disableRoleChecking,
  tableDisplay,
}) => {
  const queryConfigInitial = {
    query: getRiskChange_riskChangeDetails,
    itemId,
  };

  const fields = [
    "type",
    "name",
    "description",
    "date",
    "userEmail",
    "changeOwner",
    "reason",
    "assetSize",
    "systemLinks",
    "overallROIChange",
    "overallInherentChange",
    "overallResidualChange",
  ];

  const fieldNameDictionary = {
    type: "Type of Change",
    name: "Name of Change",
    description: "Description of Change",
    date: "Date of Change",
    userEmail: "Person who Entered Change",
    changeOwner: "Person responsible for Change",
    reason: "Reason for Change",
    systemLinks: "Systems affected by Change",
    keyRiskIndicators: "Key Risk Indicators affected by Change",
    overallInherentChange: "Change in Inherent Risk",
    overallResidualChange: "Change in Residual Risk",
    overallROIChange: "Return on Investment",
  };

  const customFields = [
    {
      field: "systemLinks",
      component: <SystemsAffectedList />,
    },
    {
      field: "overallROIChange",
      component: <OverallROIChange />,
    },
    {
      field: "overallResidualChange",
      component: <OverallResidualChange />,
    },
    {
      field: "type",
      component: <ChangeType />,
    },
  ];

  const detailsConfigInitial = {
    fields,
    customFields,
    fieldNameDictionary,
  };

  const detailsCard = useDetailsCard({
    queryConfig: { ...queryConfigInitial, ...queryConfig },
    detailsConfig: { ...detailsConfigInitial, ...detailsConfig },
    config: {
      header: "System Availability Rating Change Details",
      appendComponent: <AppendComponent />,
      otherTableRows: <SystemAvailabilityChange />,
    },
    // module,
    // resource,
    disableRoleChecking: true,
    tableDisplay,
  });

  return { ...detailsCard };
};

const AppendComponent = ({ item }) => {
  return (
    <Card>
      <CardBody>
        <Row>
          <h3>Overall Effect of Change</h3>
        </Row>
        <Row>
          <Col>
            <Row>
              Total Change in Inherent Risk:{" "}
              <NumberChange
                style={{ marginLeft: "1em" }}
                value={item && JSON.parse(item.change).overallInherentChange}
                format="dollar"
                positiveNumber="red"
                negativeNumber="green"
              />
            </Row>
            <Row>
              Total Change in Residual Risk:{" "}
              <NumberChange
                style={{ marginLeft: "1em" }}
                value={item && JSON.parse(item.change).overallResidualChange}
                format="dollar"
                positiveNumber="red"
                negativeNumber="green"
              />
            </Row>
            <Row>
              Total Return on Investment:{" "}
              <NumberChange
                style={{ marginLeft: "1em" }}
                value={item && JSON.parse(item.change).overallROIChange}
                format="percent"
              />
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
