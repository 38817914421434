import { convertCamelCaseToSentence, isNullOrUndefined } from "@rivial-security/func-utils";

import CreateOrSelect from "./CreateOrSelect";
import { ErrorLogger } from "../EventLogger";
import { ItemMutation } from "@rivial-security/appsync-utils";
import React from "react";
import { generateGraphql } from "@rivial-security/generategraphql";
import { locationType } from "../../analytics/constants/locationType";
import { operationType } from "../../analytics/constants/operationType";
import { performToastOperation } from "../Toasts/functions/toastOperation";
import { reasonType } from "../../analytics/constants/reasonType";
import { tryFunction } from "../Functions/tryFunction";
import { useUIContext } from "@utils/Context/UIContext";

/**
 * For creating or selecting an a Child Item to connect to a Parent Item
 * @param {string} typename - the typename of the child resource
 * @param {string} parentTypename - the typename of the parent resource
 * @param {string} linkTypename - for M-M connections the typename of the link between resources
 * @param {string} linkParentConnectionIDField - for M-M connections the field corresponding to the parent ID,
 * e.g. 'observationID' when looking at recommendations in observation details
 * @param {string} linkChildConnectionIDField - for M-M connections the field corresponding to the child ID,
 * e.g. 'recommendationID' when looking at recommendations in observation details
 * @param {function} createFunction - function for creating the link between resources, needed for M-M connections. createFunction(item, itemToLink)
 * @param {object} item - the parent resource being linked to
 * @param {object[]} linkedItems - all items that are already linked to the parent by default will hide them from the grid
 * @param {JSX.Element} form - instance of useForm hook for creating new child resources on the fly
 * @param {JSX.Element} grid - instance of the useGrid hook for selecting existing child resources
 * @param {object} queryConfig - pass custom query configuration for the grid
 * @param {boolean} flipCreateParams - determines if the linking function should have flipped params
 * @param {string} connectionIDField - used in 1-Many connections. ex: 'sourceID'
 * @param {string} organizationID - ownerGroup passed to the grid components
 * @param {string} selectItemAlert - an alert to display when selecting an item
 * @param props - other props
 * @returns {JSX.Element}
 * @constructor
 */
const CreateLinking = ({
  typename = "Child Item",
  parentTypename = "Parent Item",
  linkTypename,
  linkParentConnectionIDField,
  linkChildConnectionIDField,
  createFunction,
  item,
  linkedItems,
  form,
  grid,
  queryConfig,
  flipCreateParams = false,
  connectionIDField,
  organizationID,
  selectItemAlert,
  ...props
}) => {
  const { addToast, updateToast } = useUIContext();

  /**
   * Creates the link after selecting a child item
   * @param itemToLink - the child item to link
   * @returns {Promise<void>}
   */
  const callback = async (itemToLink) => {
    await performToastOperation({
      addToast,
      updateToast,
      inProgressText: `Attaching ${convertCamelCaseToSentence(typename)} to ${convertCamelCaseToSentence(
        parentTypename,
      )}`,
      successText: `Successfully attached ${convertCamelCaseToSentence(typename)} to ${convertCamelCaseToSentence(
        parentTypename,
      )}`,
      failedText: `Failed to attach ${convertCamelCaseToSentence(typename)} to ${convertCamelCaseToSentence(
        parentTypename,
      )}`,
      iconColor: "success",
      operation: async () => {
        tryFunction(props.toggleModal);

        //check if ids are present
        const parentID = item.id;
        const childID = itemToLink.id;
        if (isNullOrUndefined(parentID) || isNullOrUndefined(childID)) {
          const message = `Error: ${parentTypename} ID or ${typename} ID is missing`;
          ErrorLogger(message, {
            location: locationType.FUNCTION,
            operation: operationType.CREATE,
            reason: reasonType.INVALID_PARAM,
          });
          throw Error(message);
        }

        // Create a Many-To-Many link
        if (linkTypename && linkParentConnectionIDField && linkChildConnectionIDField) {
          // get an owner group prefer the parent item's owner group
          const ownerGroup = item.ownerGroup || itemToLink.ownerGroup;
          const { createMutation } = generateGraphql(linkTypename);
          await ItemMutation({
            mutation: createMutation,
            input: {
              [linkParentConnectionIDField]: parentID,
              [linkChildConnectionIDField]: childID,
              ownerGroup,
            },
          });
        } else if (!isNullOrUndefined(createFunction) && typeof createFunction === "function") {
          const newLink = flipCreateParams
            ? await createFunction(item, itemToLink)
            : await createFunction(itemToLink, item);
          props.callback?.(newLink);
        }

        // Create a One-to-Many link automatically using connectionIDField
        if (!isNullOrUndefined(connectionIDField)) {
          const { updateMutation } = generateGraphql(typename, [connectionIDField]);

          const newItem = await ItemMutation({
            mutation: updateMutation,
            input: {
              id: itemToLink.id,
              [connectionIDField]: item.id,
            },
          });

          props.callback?.(newItem);
        }

        tryFunction(props.resetFunction);
      },
    });
  };

  return (
    <CreateOrSelect
      typename={typename}
      form={form && React.cloneElement(form, { ...props })}
      grid={React.cloneElement(grid, { ...props })}
      queryConfig={queryConfig}
      linkedItems={linkedItems}
      callback={callback}
      organizationID={organizationID}
      selectItemAlert={selectItemAlert}
    />
  );
};

export default CreateLinking;
