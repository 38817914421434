import { EVIDENCE_TYPES } from "../../../../../../typedefs/Compliance/Evidence/Evidence";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import Select from "@mui/material/Select";
import { convertCamelCaseToSentence } from "@rivial-security/func-utils";

/**
 * Dropdown input for selecting the type of Evidence Response on the landing page
 * @param {EVIDENCE_TYPES} evidenceType - the currently selected evidence type
 * @param {function} setEvidenceType - setter function from this input
 * @returns {JSX.Element}
 * @constructor
 */
const SelectEvidenceType = ({ evidenceType, setEvidenceType }) => {
  return (
    <FormControl data-testid="dropdown-input-select-evidence-type" style={{ zIndex: 100 }}>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={evidenceType}
        onChange={(e) => setEvidenceType(e.target.value)}
      >
        {Object.values(EVIDENCE_TYPES).map((type, index) => (
          <MenuItem key={`evidenceTypeDropdown${index}`} value={type}>
            {convertCamelCaseToSentence(type)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectEvidenceType;
