import { useDepartmentForm } from "../hooks/useDepartmentForm";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * Displays a form for creating a Department
 * @param {object} props - all props passed to the useDepartmentForm hook
 * @returns {JSX.Element}
 * @constructor
 */
const DepartmentForm = (props) => useDepartmentForm(props).display;

export default withOrganizationCheck(DepartmentForm);
