import { generateGraphql } from "@rivial-security/generategraphql";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";

/**
 * Creates a new Action Item
 * @param input
 * @returns {Promise<*>}
 */
export const createActionItem = (input) => {
  const { createMutation } = generateGraphql("GenericActionItem", ["name", "status", "priority", "pointOfContact"], {
    pointOfContact: `{ firstName lastName }`,
  });

  return ItemMutation(createMutation, { ...input });
};
