import NumberChange from "../../../../utils/CustomFields/NumberChange";
import { useTable } from "../../../../hooks/views/useTable";
import SystemDetails from "../../Systems/components/SystemDetails";
import React from "react";
import CustomTooltip from "../../../../utils/GenericComponents/CustomTooltip";

export const useRiskChangeSystemsTable = ({ tooltip }) => {
  const customFields = [
    {
      field: "inherentChange",
      component: <NumberChange format="dollar" positiveNumber="red" negativeNumber="green" />,
      propName: "value",
    },
    {
      field: "residualChange",
      component: <NumberChange format="dollar" positiveNumber="red" negativeNumber="green" />,
      propName: "value",
    },
    {
      field: "roiChange",
      component: <NumberChange format="percent" />,
      propName: "value",
    },
  ];

  const systemsTable = useTable({
    data: [],
    fields: ["name", "inherentChange", "residualChange", "roiChange"],
    customFields,
    disableRoleChecking: true,
    fieldNameDictionary: {
      inherentChange: "Change in Inherent Risk",
      residualChange: "Change in Residual Risk",
      roiChange: "Return on Investment",
    },
    detailsComponent: <SystemDetails />,
  });

  const display = (
    <>
      <h5>
        Associated Systems
        <CustomTooltip tooltip={tooltip} />
      </h5>
      {systemsTable.display}
    </>
  );

  return {
    ...systemsTable,
    display,
  };
};
