import { useCheckPermissions } from "../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { modules, resources } from "@rivial-security/role-utils";
import { useModal } from "../../../../../../hooks/views/useModal";
import React, { useEffect } from "react";
import RiskChangeDetails from "../../../../../Risk/RiskChanges/components/RiskChangeDetails";
import NumberChange from "../../../../../../utils/CustomFields/NumberChange";
import { useGroupedRiskChangeDetails } from "../../../../../Risk/RiskChanges/hooks/useGroupedRiskChangeDetails/useGroupedRiskChangeDetails";
import { groupRiskChanges } from "../../../../../Risk/RiskChanges/hooks/useGroupedRiskChangeDataGrid/functions/groupRiskChanges";

const RiskChangeActivity = (item) => {
  const riskChange = item?.riskChange;
  const activities = item?.activities;

  //Risk Change Details
  const checkRiskChangePermissions = useCheckPermissions({
    module: modules.RISK,
    resource: resources.RISK_CHANGE,
  });
  const riskChangePreviewModal = useModal("Risk Change Details", <RiskChangeDetails item={riskChange} />, null, {
    width: "70vw",
  });

  //Multiple risk changes (multiple activities)
  const groupedRiskChangeDetails = useGroupedRiskChangeDetails({
    queryConfig: { query: null },
  });
  const riskChangesPreviewModal = useModal(
    "Grouped Risk Changes",
    <div>{groupedRiskChangeDetails.display}</div>,
    null,
    {
      width: "70vw",
    },
  );
  //Parse all activities and add to the grid
  useEffect(() => {
    const riskChanges = [];
    if (Array.isArray(activities)) {
      activities.forEach((activity) => {
        if (activity?.riskChange) {
          riskChanges.push(activity.riskChange);
        }
      });
    }

    //Group the risk changes
    const groupedRiskChanges = groupRiskChanges({ riskChanges });
    if (groupedRiskChanges.length > 0) {
      groupedRiskChangeDetails.setItem(groupedRiskChanges[0]);
    }
  }, [riskChangesPreviewModal.modalIsOpen]);

  //Calculate risk change for multiple activities or just one
  let residualChange;
  if (Array.isArray(activities)) {
    let residualChangeAccumulator = 0;
    for (const activity of activities) {
      const riskChange = activity?.riskChange;
      let change;
      if (riskChange?.change) {
        change = JSON.parse(riskChange.change);
      }
      residualChangeAccumulator += change?.residualChange;
    }
    residualChange = residualChangeAccumulator;
  } else {
    let change;
    if (riskChange?.change) {
      change = JSON.parse(riskChange.change);
    }
    residualChange = change?.residualChange;
  }

  let residualTrend = "increase";
  if (!residualChange) {
    residualTrend = "no change";
  } else if (residualChange < 0) {
    residualTrend = "decrease";
  }

  return (
    <span style={{ fontSize: ".9em" }}>
      <span>Evidence has triggered </span>
      {(riskChange || Array.isArray(activities)) && checkRiskChangePermissions.resource.read ? (
        <span>
          <span
            onClick={() => {
              if (Array.isArray(activities)) {
                riskChangesPreviewModal.setModalIsOpen(true);
              } else {
                riskChangePreviewModal.setModalIsOpen(true);
              }
            }}
            style={{
              borderBottom: "2px dashed",
              textDecoration: "none",
              cursor: "pointer",
            }}
          >
            {Array.isArray(activities) ? "multiple risk changes" : "a risk control change"}
          </span>
          {residualChange ? (
            <span>
              {" "}
              with a total {residualTrend} in residual risk of{" "}
              <NumberChange value={residualChange} format="dollar" positiveNumber="red" negativeNumber="green" />
            </span>
          ) : (
            <span> with no change in residual risk</span>
          )}
        </span>
      ) : (
        <span>a risk control change</span>
      )}
      {riskChangePreviewModal.modal}
      {riskChangesPreviewModal.modal}
    </span>
  );
};

export default RiskChangeActivity;
