"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.keywordSort = void 0;
const isNullOrUndefined_1 = require("../Misc/isNullOrUndefined");
const sortObjectsByField_1 = require("../Object/sortObjectsByField");
const eventLogger_1 = require("../Misc/eventLogger");
/**
 * Sorts an array of objects by a string
 * @param {object[]} items - the array of objects to be sorted
 * @param {string} searchInput - the search input text to sort the objects by
 * @param {string} [field = "name"] - the object field to sort by
 * @param {"asc" | "desc"} [defaultSort] - ascending or descending sorting by default if there's no search string
 * @param {function} [itemAdapter] - a function that takes in a single item of the array and returns an adapted string for sorting purposes
 * @returns {object[]}
 */
const keywordSort = (items, searchInput = "", field = "name", defaultSort, itemAdapter) => {
    if ((0, isNullOrUndefined_1.isNullOrUndefined)(items) || !Array.isArray(items)) {
        (0, eventLogger_1.eventLogger)("Cannot sort items since the argument is not a valid array!");
        return items;
    }
    if (searchInput !== "") {
        return items.sort((a, b) => {
            let aText;
            let bText;
            if (!(0, isNullOrUndefined_1.isNullOrUndefined)(field)) {
                aText = itemAdapter ? itemAdapter(a) : a[field];
                bText = itemAdapter ? itemAdapter(b) : b[field];
            }
            else {
                aText = itemAdapter ? itemAdapter(a) : a;
                bText = itemAdapter ? itemAdapter(b) : b;
            }
            if (!(0, isNullOrUndefined_1.isNullOrUndefined)(searchInput) &&
                searchInput !== "" &&
                !(0, isNullOrUndefined_1.isNullOrUndefined)(aText) &&
                !(0, isNullOrUndefined_1.isNullOrUndefined)(bText)) {
                // Sort results by matching name with keyword position in name
                if (aText.toLowerCase().indexOf(searchInput.toLowerCase()) >
                    bText.toLowerCase().indexOf(searchInput.toLowerCase())) {
                    return -1;
                }
                else if (aText.toLowerCase().indexOf(searchInput.toLowerCase()) <
                    bText.toLowerCase().indexOf(searchInput.toLowerCase())) {
                    return 1;
                }
                else {
                    if (aText > bText)
                        return -1;
                    else
                        return 1;
                }
            }
            return 0;
        });
    }
    else if (!(0, isNullOrUndefined_1.isNullOrUndefined)(defaultSort)) {
        return (0, sortObjectsByField_1.sortObjectsByField)(items, field, defaultSort);
    }
    else {
        return items;
    }
};
exports.keywordSort = keywordSort;
