import { usePlaybookDetails } from "../hooks/usePlaybookDetails";
import { withDetailsPage } from "../../../../../utils/Context/withDetailsPage";
import React from "react";

/**
 * Playbook Details component
 */
const PlaybookDetails = (props): React.ReactElement => usePlaybookDetails(props).display;

export default withDetailsPage(PlaybookDetails);
