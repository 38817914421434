import { ItemMutation } from "../../../Functions/Graphql/ItemMutation";
import { generateGraphql } from "@rivial-security/generategraphql";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Creates a EvidenceTagLink object
 * @param {object} evidence - the evidence to which to attach the tag
 * @param {object} tag - the tag that needs to be attached to the evidence
 * @param {string} organizationID - the organization identifier of the evidence in the database
 * @returns {Promise<*>}
 */
export const createEvidenceTagLink = (evidence, tag, organizationID) => {
  if (isNullOrUndefined(tag) || isNullOrUndefined(evidence?.id)) {
    throw Error("Invalid input!");
  }

  const { createMutation: createEvidenceTagLink } = generateGraphql("EvidenceTagLink", [
    "evidenceID",
    "tagID",
    "__typename",
  ]);

  return ItemMutation(createEvidenceTagLink, {
    evidenceID: evidence.id,
    tagID: tag.id,
    ownerGroup: organizationID,
  });
};
