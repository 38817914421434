import React, { useEffect, useState } from "react";

import { Button } from "reactstrap";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { configurePlaybook } from "../../functions/configurePlaybook/configurePlaybook";
import { performToastOperation } from "../../../../../utils/Toasts/functions/toastOperation";
import { useAccordion } from "../../../../../hooks/views/useAccordion/useAccordion";
import { useResponseSystemsDataGrid } from "../useResponseSystemsGrid/useResponseSystemsDataGrid";
import { useResponseTeamDataGrid } from "../../../ResponseTeams/hooks/useResponseTeamDataGrid";
import { useSelectedItemCounter } from "../useSelectedItemCounter";
import { useUIContext } from "@utils/Context/UIContext";
import { usePlaybookDataGrid } from "../../../Plan/Playbooks/hooks/usePlaybookDataGrid";
import { setCheckedItems } from "./functions/setCheckedItems";

/**
 * Modal button that allows the user to create a custom playbook for an incident or an exercise
 * @param {string} organizationID - selected organization database id
 * @param {object} item - the incident to attach a Playbook to, should also contain the current procedures object
 * @param {string} typename - the schema model name for the resource that hosts the playbook data
 * @param {function} resetFunction - callback to re-query `item` data
 * @param {function} toggleModal - call to close ot open modal after finishing selecting needed response plan procedures
 */
export const usePlaybookBuilder = ({ organizationID, item, typename, resetFunction, toggleModal }) => {
  const { addToast, updateToast } = useUIContext();
  const [includeGeneralProcedures, setIncludeGeneralProcedures] = useState(false);

  //Resource grids
  const gridConfig = {
    createResourceComponent: null,
    enableSelection: true,
  };
  const responseTeamGrid = useResponseTeamDataGrid({ organizationID, gridConfig });
  const systemsGrid = useResponseSystemsDataGrid({ organizationID, gridConfig });
  const playbookGrid = usePlaybookDataGrid({ organizationID, gridConfig });

  //Selected item subtitle logic
  const { resourceAmountStrings } = useSelectedItemCounter({
    grids: {
      teams: responseTeamGrid,
      systems: systemsGrid,
      playbooks: playbookGrid,
    },
  });

  // Mark off all grid items that are checked from a previous config
  useEffect(() => {
    setCheckedItems({
      item,
      responseTeamGrid,
      systemsGrid,
      playbookGrid,
      setIncludeGeneralProcedures,
    });
  }, [item, responseTeamGrid?.data, systemsGrid?.data, playbookGrid?.data]);

  const accordion = useAccordion({
    items: [
      {
        title: "General Procedures",
        subTitle: includeGeneralProcedures
          ? "General Procedures are being included in this Playbook"
          : "General Procedures are not being included in this Playbook",
        component: (
          <CheckBoxComponent
            label={"Include general procedures"}
            checked={includeGeneralProcedures}
            change={({ checked }) => setIncludeGeneralProcedures(checked)}
          />
        ),
        icon: "icon-list",
        defaultExpanded: true,
      },
      {
        title: "Teams",
        component: <div style={{ height: "30em" }}>{responseTeamGrid.display}</div>,
        subTitle: resourceAmountStrings?.teams,
        icon: "icon-people",
      },
      {
        title: "Systems",
        component: <div style={{ height: "30em" }}>{systemsGrid.display}</div>,
        subTitle: resourceAmountStrings?.systems,
        icon: "icon-screen-desktop",
      },
      {
        title: "Playbooks",
        component: <div style={{ height: "30em" }}>{playbookGrid.display}</div>,
        subTitle: resourceAmountStrings?.playbooks,
        icon: "icon-book-open",
      },
    ],
    unmountOnExit: false,
  });

  const display = (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ paddingBottom: "2em" }}>{accordion.display}</div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          className="float-right"
          style={{ marginRight: "1em" }}
          color="success"
          onClick={async () => {
            await performToastOperation({
              addToast,
              updateToast,
              operation: async () =>
                await configurePlaybook({
                  item,
                  typename,
                  includeGeneral: includeGeneralProcedures,
                  teams: responseTeamGrid,
                  systems: systemsGrid,
                  playbooks: playbookGrid,
                  organizationID,
                }),
              inProgressText: "Configuring the Playbook...",
              failedText: "Failed to configure the Playbook!",
              successText: "Successfully configured the Playbook!",
              iconColor: "success",
            });
            toggleModal?.();
            resetFunction?.();
          }}
          title={"Configure the playbook with the selected items"}
          data-testid="useForm-submit-button"
        >
          Update Incident Playbook
        </Button>
        <Button
          data-testid="useForm-reset-button"
          className="float-right"
          color="secondary"
          title={"Keep the current Incident Playbook configuration unchanged"}
          onClick={() => toggleModal?.()}
        >
          Cancel Update
        </Button>
      </div>
    </div>
  );

  return {
    display,
  };
};
