import React from "react";
import CustomTooltip from "../../../../utils/GenericComponents/CustomTooltip.js";

const Frequency = ({ frequency, item }) => {
  const { nextDate } = item;

  return (
    <div style={{ textTransform: "capitalize" }}>
      {frequency}
      <CustomTooltip tooltip={`Next Date that a Metric will be Gathered: ${new Date(nextDate).toLocaleString()}`} />
    </div>
  );
};

export default Frequency;
