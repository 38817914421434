import { generateGraphql } from "@rivial-security/generategraphql";
import React from "react";
import VendorControlCategoryDetails from "../components/VendorControlCategoryDetails";
import { deleteVendorControlCategory } from "../functions/deleteVendorControlCategory";
import CreateVendorControlCategory from "../components/CreateVendorControlCategory";
import { modules, resources } from "@rivial-security/role-utils";
import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";
import { listVendorControlCategoriesByOwnerGroup } from "../constants/listVendorControlCategoriesByOwnergroup";

/**
 * Custom hook to display a grid of Vendor Control Categories.
 *
 * @param {string} organizationID
 * @param {object} [cardConfig] - optional configs to pass to Card container of the useGridCard hook
 * @param {object} [gridConfig] - optional configs to pass to the Grid in the useGridCard hook
 * @param {string} [module = VENDORS] - the role config module, can be overwritten
 * @param {string} [resource = VENDOR_CONTROL_CATEGORY] - the role config resource, can be overwritten
 * @param {boolean} disableRoleChecking - disable role checking
 * @param {object} props - other props, also passed to the Grid in the useGridCard hook
 */
export const useVendorControlCategoryDataGrid = ({
  organizationID,
  cardConfig = {},
  gridConfig = {},
  module = modules.VENDORS,
  resource = resources.VENDOR_CONTROL_CATEGORY,
  disableRoleChecking,
  ...props
}) => {
  const typename = "VendorControlCategory";
  const queryFields = ["name"];

  const { updateMutation } = generateGraphql(typename, queryFields);

  cardConfig = {
    title: "Vendor Control Categories",
    headerIcon: "icon-list",
    enableSticky: true,
    ...cardConfig,
  };

  const roleConfig = {
    module,
    resource,
  };

  /**
   * For this list, we only want to show Control Category Templates.
   * @type {{organizationID: *, query: string}}
   */
  const queryConfig = {
    query: listVendorControlCategoriesByOwnerGroup,
    variables: {
      ownerGroup: organizationID,
    },
    limit: 1000,
    filters: {
      isTemplate: {
        eq: true,
      },
    },
  };

  const fields = [
    {
      name: "name",
      flex: 1,
      sort: {
        direction: "asc",
        priority: 1,
      },
      bulkEdit: true,
    },
  ];

  gridConfig = {
    organizationID,
    typename: "VendorControlCategory",
    fields,
    createResourceComponent: (
      <CreateVendorControlCategory
        organizationID={organizationID}
        isTemplate={true}
        module={module}
        resource={resource}
      />
    ),
    detailsComponent: (
      <VendorControlCategoryDetails
        tableDisplay={true}
        organizationID={organizationID}
        module={module}
        resource={resource}
        disableRoleChecking={disableRoleChecking}
      />
    ),
    deleteFunction: deleteVendorControlCategory,
    updateMutation,
    route: "#/vendors/controls/",
    options: ["details", "delete", "edit"],
    detailsTitle: "Vendor Control Category Details",
    persistenceUUID: "vendor-control-category-grid2",
    ...gridConfig,
    ...props,
  };

  const queryCard = useDataGrid({
    ...queryConfig,
    ...gridConfig,
    ...cardConfig,
    ...roleConfig,
  });

  return {
    ...queryCard,
  };
};
