import { modules, resources } from "@rivial-security/role-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import AuditControlIsCompliant from "./AuditControlIsCompliant";
import AuditControlNotes from "./AuditControlNotes";
import { useDetails } from "../../../../hooks/views/useDetails/hooks/useDetails";
import React from "react";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * Displays AuditControlLink info
 * - Is Compliant
 * - Notes
 * - Auditor
 * - Interviewee
 * @param props
 * @constructor
 */
const AuditControlGeneralInformation = ({ item, optimisticUpdate, audit }) => {
  const module = modules.COMPLIANCE;
  const resource = resources.AUDIT;
  const typename = "AuditControlLink";

  const fields = ["auditor", "interviewee", "isCompliant", "notes"];

  const { updateMutation } = generateGraphql(typename, ["auditor", "interviewee", "isCompliant"]);

  const updateMutationFunction = (input) => {
    optimisticUpdate({
      mutation: false,
      typename: "AuditControlLink",
      id: item?.auditControlLink?.id,
      field: input.fieldName,
      value: input?.input?.[input.fieldName],
    });

    return {
      ...input.input,
      id: item?.auditControlLink?.id,
    };
  };

  const fieldNameDictionary = {
    isCompliant: "Compliant",
  };

  const customFields = [
    {
      field: "isCompliant",
      type: "custom",
      component: <AuditControlIsCompliant orientation="horizontal" optimisticUpdate={optimisticUpdate} />,
    },
    {
      field: "notes",
      type: "custom",
      component: <AuditControlNotes optimisticUpdate={optimisticUpdate} audit={audit} maxInitialNotes={-1} />,
    },
  ];

  const details = useDetails({
    item,
    fields,
    customFields,
    fieldNameDictionary,
    module,
    resource,
    mutation: updateMutation,
    updateMutationFunction,
  });

  return <div>{details.display}</div>;
};

export default withOrganizationCheck(AuditControlGeneralInformation);
