import { useCreateEmailTemplate } from "../hooks/useCreateEmailTemplate";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * @description A component that displays the email body
 * @param {object} item - the email template item
 * @param {string} organizationID - selected organization
 * @param {function} toggleModal - function to toggle the modal
 * @param {boolean} disableResetButton - disable the reset button
 * @returns {*}
 * @constructor
 */
const EmailBody = ({ item, organizationID, toggleModal, disableResetButton }) => {
  const emailEditor = useCreateEmailTemplate({
    item,
    organizationID,
    toggleModal,
    disableResetButton,
  });

  return emailEditor.display;
};

export default withOrganizationCheck(EmailBody);
