import { modules, resources } from "@rivial-security/role-utils";
import { useEffect, useState } from "react";

import { ListQuery } from "../../../../../../utils/Functions/Graphql/ListQuery";
import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useForm } from "../../../../../../hooks/views/useForm/useForm";
import { withOrganizationCheck } from "../../../../../../utils/Context/withOrganizationCheck";

const { createMutation } = generateGraphql("ImplementationLevel", [
  "description",
  "date",
  "level",
  "indicator",
  "frequency",
]);

/**
 *
 * @param organizationID
 * @param toggleModal
 * @param getNewItem
 * @param programSection
 * @param resetFunction
 * @param module
 * @param resource
 * @param disableRoleChecking
 * @return {*}
 * @constructor
 */
const AddSingleImplementationLevel = ({
  organizationID,
  toggleModal,
  getNewItem,
  programSection,
  resetFunction,
  module = modules.GOVERNANCE,
  resource = resources.IMPLEMENTATION_LEVEL,
  disableRoleChecking = false,
}) => {
  const [listProgramSections, setListProgramSections] = useState([]);

  useEffect(() => {
    ListQuery({
      query: generateGraphql("ProgramSection", ["name"]).listQuery,
      organizationID,
    }).then((data) => setListProgramSections(data));
  }, []);

  const form = useForm({
    fieldConfig: {
      level: {
        label: "Level",
        tooltip: "Valid Level Range 1-5",
        inputType: "number",
        defaultValue: 1,
        validationFunction: (input) => {
          const num = parseFloat(input);
          if (!input) {
            return false;
          } else if (num < 1) {
            return false;
          } else if (num > 5) {
            return false;
          }
          return true;
        },
        required: true,
      },
      indicator: {
        label: "Indicator",
        tooltip: "What needs to be done that indicates that this Program Section is operating at this Level?",
        required: true,
      },
      frequency: {
        label: "Frequency",
        inputType: "number",
        defaultValue: 30,
        validationFunction: (input) => {
          const num = parseFloat(input);
          if (!input) {
            return false;
          } else if (num < 1) {
            return false;
          } else if (num > 365) {
            return false;
          }
          return true;
        },
        tooltip: "How often does this need to be addressed? Valid Frequency in days range 1-365",
        required: true,
      },
      date: {
        label: "Date",
        defaultValue: new Date(),
        inputType: "date",
        tooltip: "When does this need to be performed?",
      },
      description: {
        label: "Description",
      },
      programSectionID: {
        label: "Program Section",
        inputType: "dropdown",
        defaultValue: programSection?.id,
        dropdownConfig: {
          data: listProgramSections.map((item) => {
            return {
              value: item?.id,
              text: convertCamelCaseToSentence(item?.name),
            };
          }),
        },
      },
    },
    updateInputFunction: (input) => {
      if (input?.programSectionID === "") input.programSectionID = undefined;
      return input;
    },
    mutation: createMutation,
    organizationID,
    getNewItem,
    resetFunction,
    resource,
    module,
    typename: "Responsibility",
    toggleModal,
    disableRoleChecking,
  });

  return form.display;
};

export default withOrganizationCheck(AddSingleImplementationLevel);
