/**
 * Author: Jacob Blazina
 * Created At: N/A
 * Edits:
 *  - 7/22/19 JB: Added header signature
 *
 * Description: A Function.
 *              Deletes a Point of Contact.
 *
 * TODO: Perform additional security check.
 */

import { GenericDeleteGQL } from "../../../../utils/Functions/Graphql/GenericDeleteGQL";
import { InfoLogger } from "@utils/EventLogger";
import { QueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import { deleteUser } from "../../Users/functions/deleteUser";
import { formattedName } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 * @description Delete POC with all associated resources
 * @param {object} contact - point of contact to delete
 * @returns {object} {Promise<null|void>}
 * @constructor
 */
const DeleteContact = async (contact) => {
  if (!contact || !contact.id) {
    return null;
  }

  if (contact?.user?.id) {
    const callback = () => InfoLogger(`Deleting user - ${contact?.user?.id}`);
    await deleteUser({ item: contact?.user, callback });
  }

  const { getQuery } = generateGraphql(
    "PointOfContact",
    ["__typename", "firstName", "meetings", "evidences", "responseTeamLinks", "questionnaireAssignees"],
    {
      meetings: `(limit: 1000) {
        items {
          id
          __typename
        }
      }`,
      evidences: `(limit: 1000) {
        items {
            id
            __typename
          }
      }`,
      responseTeamLinks: `(limit: 1000) {
        items {
            id
            __typename
          }
      }`,
      questionnaireAssignees: `(limit: 1000) {
        items {
          id
          __typename
        }
      }`,
    },
  );

  return await QueryGetItem({ query: getQuery, itemId: contact.id }).then(async (contact) => {
    await GenericDeleteGQL({ objectToDelete: contact });
    const fullName = formattedName({ pointOfContact: contact });
    InfoLogger(`Point of Contact ${fullName} was Successfully Deleted`);
  });
};

export default DeleteContact;
