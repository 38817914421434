import React, { useEffect } from "react";
import { usePointOfContactDataGrid } from "../../../../OrganizationManager/PointOfContacts/hooks/usePointOfContactDataGrid";

/**
 * @description Select point of contact for a meeting
 * @param {object} item
 * @param {string} organizationID
 * @param {function} onChangeCallback
 * @return {JSX.Element}
 * @constructor
 */
const ScheduleMeetingAddPointOfContact = ({ input, organizationID, onChangeCallback }) => {
  const gridConfig = {
    enableSelection: true,
  };
  const pointOfContactListHook = usePointOfContactDataGrid({
    organizationID: organizationID,
    ...gridConfig,
  });

  useEffect(() => {
    if (input?.pointOfContacts) {
      pointOfContactListHook.setSelectedData(input?.pointOfContacts);
    }
  }, [pointOfContactListHook.data, pointOfContactListHook.ref]);

  useEffect(() => {
    if (
      pointOfContactListHook?.selectedData &&
      Array.isArray(pointOfContactListHook?.selectedData) &&
      pointOfContactListHook?.selectedData.length > 0 &&
      onChangeCallback
    ) {
      onChangeCallback(pointOfContactListHook.selectedData);
    }
  }, [JSON.stringify(pointOfContactListHook.selectedData)]);

  return (
    <div
      style={{
        height: "40em",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {pointOfContactListHook.gridDisplay}
    </div>
  );
};

export default ScheduleMeetingAddPointOfContact;
