import React from "react";
import { useSelectWidget } from "../../../CustomWidgets/WidgetDataGrid/hooks/useSelectWidget";
import { GetQuery } from "../../../../utils/Functions/Graphql/GetQuery";
import { generateGraphql } from "@rivial-security/generategraphql";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * Allows the user to select a widget from the Widget List, propagate the data, and send it to a report
 * @param organizationID
 * @param insertWidgetCallback
 * @param toggleModal
 * @param widgetTag
 */
const InsertWidget = ({ organizationID, insertWidgetCallback, toggleModal, widgetTag = false }) => {
  const fields = ["name", "description", "type", "config", "customQuery"];
  const nestedFields = {
    customQuery: `{ id name description graphql filters }`,
  };

  const { getQuery } = generateGraphql("CustomWidget", fields, nestedFields);

  /**
   * Triggers when the user selects a widget from the list.
   * First queries the full widget object to make sure all necessary data is gathered.
   * Then formats the Widget query data based on whether it is a tag and forwards all the data.
   * @param {object} selectedWidget
   */
  const callback = (selectedWidget) => {
    return GetQuery({
      query: getQuery,
      variables: {
        id: selectedWidget.id,
      },
    }).then((widget) => {
      if (widgetTag == true) {
        insertWidgetCallback({
          name: widget?.name ?? "widget-name-unknown",
          id: widget?.id ?? "widget-id-unknown",
          type: "widgetTag",
        });
      } else {
        insertWidgetCallback(widget);
      }
      toggleModal && toggleModal();
    });
  };

  /**
   * The Widget list to select a widget from
   */
  const widgetList = useSelectWidget({
    organizationID,
    insertWidgetCallback: callback,
    showHeader: false,
  });
  return <div style={{ height: "80vh" }}>{widgetList.gridDisplay}</div>;
};

export default withOrganizationCheck(InsertWidget);
