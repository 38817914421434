"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDefaultWidgetConfig = void 0;
const widgetType_1 = require("../../../constants/widgetType");
const defaultHistoryChartConfig_1 = require("../../../constants/defaultConfigs/defaultHistoryChartConfig");
const defaultPieChartConfig_1 = require("../../../constants/defaultConfigs/defaultPieChartConfig");
const defaultRadarChartConfig_1 = require("../../../constants/defaultConfigs/defaultRadarChartConfig");
const defaultProgressBarConfig_1 = require("../../../constants/defaultConfigs/defaultProgressBarConfig");
const defaultGaugeConfig_1 = require("../../../constants/defaultConfigs/defaultGaugeConfig");
const lodash_1 = require("lodash");
const contextType_1 = require("../../../constants/contextType");
const func_utils_1 = require("@rivial-security/func-utils");
const defaultRiskToleranceConfig_1 = require("../../../constants/defaultConfigs/defaultRiskToleranceConfig");
const defaultBarChartConfig_1 = require("../../../constants/defaultConfigs/defaultBarChartConfig");
/**
 * Returns default parameters (which can be overwritten by the user) for a widget in different contexts
 * NOTE: the default config files are only to be used for context specific default values (e.g. dimensions for report renders)
 * @param {string} widgetType - the widget type that is being displayed
 * @param {string} contextType - the location where the widget is shown
 */
const getDefaultWidgetConfig = ({ widgetType, contextType }) => {
    let defaultConfigData = {};
    switch (widgetType) {
        case widgetType_1.WIDGET_TYPE.HISTORY_CHART:
            defaultConfigData = defaultHistoryChartConfig_1.defaultHistoryChartConfig;
            break;
        case widgetType_1.WIDGET_TYPE.PIE_CHART:
            defaultConfigData = defaultPieChartConfig_1.defaultPieChartConfig;
            break;
        case widgetType_1.WIDGET_TYPE.BAR_CHART:
            defaultConfigData = defaultBarChartConfig_1.defaultBarChartConfig;
            break;
        case widgetType_1.WIDGET_TYPE.RADAR_CHART:
            defaultConfigData = defaultRadarChartConfig_1.defaultRadarChartConfig;
            break;
        case widgetType_1.WIDGET_TYPE.PROGRESS_BAR:
            defaultConfigData = defaultProgressBarConfig_1.defaultProgressBarConfig;
            break;
        case widgetType_1.WIDGET_TYPE.GAUGE:
            defaultConfigData = defaultGaugeConfig_1.defaultGaugeConfig;
            break;
        case widgetType_1.WIDGET_TYPE.RISK_TOLERANCE_CHART:
            defaultConfigData = defaultRiskToleranceConfig_1.defaultRiskToleranceConfig;
            break;
        default:
    }
    if (!defaultConfigData) {
        return {};
    }
    //Find if the widget has default values that are for all contexts
    let defaultConfig = {};
    if (defaultConfigData.hasOwnProperty(contextType_1.CONTEXT_TYPE.ALL)) {
        defaultConfig = (0, lodash_1.cloneDeep)(defaultConfigData[contextType_1.CONTEXT_TYPE.ALL]);
    }
    //Find if the widget has context specific default value and merge into the final default config
    if (defaultConfigData.hasOwnProperty(contextType)) {
        const contextBasedDefaultConfig = (0, lodash_1.cloneDeep)(defaultConfigData[contextType]);
        defaultConfig = (0, func_utils_1.deepMergeTwoObjects)(defaultConfig, contextBasedDefaultConfig);
    }
    return defaultConfig ? (0, lodash_1.cloneDeep)(defaultConfig) : {};
};
exports.getDefaultWidgetConfig = getDefaultWidgetConfig;
