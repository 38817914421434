import { ErrorLogger, InfoLogger } from "@utils/EventLogger";

import { GenericDeleteGQL } from "../../../../utils/Functions/Graphql/GenericDeleteGQL";
import { QueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 * Function handling the delete operation for a task
 * @param {object} task - the task to delete, should have the id property
 * @returns {Promise<null>}
 */
export const deleteTask = async (task) => {
  if (!task?.id || !task?.ownerGroup) {
    ErrorLogger("Invalid input into deleteTask!", JSON.stringify({ task }));
    return null;
  }

  const { getQuery: getTaskQuery } = generateGraphql("Task", ["__typename", "tags"], {
    tags: `(limit: 1000) { items { id __typename } }`,
  });

  return await QueryGetItem({
    query: getTaskQuery,
    itemId: task.id,
  }).then(async (task) => {
    InfoLogger(`Deleting task - ${task?.id}`);
    await GenericDeleteGQL({ objectToDelete: task });
  });
};
