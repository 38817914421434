import { useContext, useEffect } from "react";

import { OrganizationContext } from "../../../../utils/Context/OrganizationContext";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * @description Check Access Control for items against user permissions
 * @param {object[]} data - grid items
 */
export const useGridAccessControl = ({ data }) => {
  const organizationContext = useContext(OrganizationContext);

  useEffect(() => {
    if (
      data &&
      Array.isArray(data) &&
      data.length > 0 &&
      organizationContext?.loggedInPointOfContactId &&
      organizationContext?.role?.id
    ) {
      let i = data.length;
      while (i--) {
        const item = data[i];

        if (!isNullOrUndefined(item)) {
          /**
           * Remove item from the grid if an user don not have right role permissions
           */
          if (
            organizationContext?.role?.id &&
            item?.accessControl?.roles &&
            Array.isArray(item?.accessControl?.roles) &&
            item?.accessControl?.roles.length > 0
          ) {
            const roles = item?.accessControl?.roles;
            const foundRole = roles.find((id) => id === organizationContext?.role?.id);
            if (!foundRole) data.splice(i, 1);
          }

          /**
           * Remove item from the grid if an user don not have right point of contact permissions
           */
          if (
            item?.accessControl?.pointOfContacts &&
            Array.isArray(item?.accessControl?.pointOfContacts) &&
            item?.accessControl?.pointOfContacts.length > 0
          ) {
            const pointOfContacts = item?.accessControl?.pointOfContacts;
            const foundContact = pointOfContacts.find((id) => id === organizationContext?.loggedInPointOfContactId);
            !foundContact && data.splice(i, 1);
          }
        }
      }
    }
  }, [data]);
};
