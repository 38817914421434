import { ErrorLogger, InfoLogger } from "@utils/EventLogger";
import React, { useEffect } from "react";
import { modules, resources } from "@rivial-security/role-utils";

import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import MUICreateModalButton from "../../../../utils/GenericComponents/buttons/MUICreateModalButton";
import MeetingDetails from "../../../Program/Meetings/components/MeetingDetails";
import OpenButtonModal from "../../../../utils/GenericComponents/buttons/OpenButtonModal";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useMeetingForm } from "../../../Program/Meetings/hooks/useMeetingForm";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * @description Button that allows either Schedule Meeting, or Open Meeting for an Incident
 * @param {object} props
 * @constructor
 */
const ExerciseMeetingButton = ({ item, organizationID, resetFunction }) => {
  return (
    <span>
      {item?.meeting?.id ? (
        <OpenMeetingButton item={item.meeting} organizationID={organizationID} />
      ) : (
        <CreateMeetingButton item={item} organizationID={organizationID} resetFunction={resetFunction} />
      )}
    </span>
  );
};

export default withOrganizationCheck(ExerciseMeetingButton);

/**
 * @description Button for Opening an existing Exercise Meeting
 * @param {object} item - meeting object
 * @returns {JSX.Element}
 * @constructor
 */
const OpenMeetingButton = ({ item }) => {
  return (
    <OpenButtonModal text={"Open Meeting"} icon={"icon-calendar"} typename={"Meeting"} config={{ width: "80vw" }}>
      <MeetingDetails itemId={item?.id} />
    </OpenButtonModal>
  );
};

/**
 * @description Button for Creating an Exercise Meeting
 * @param {string} organizationID - selected organization
 * @param {object} item - exercise object
 * @param {function} resetFunction - fetch item from the database
 * @param {string} [module = PROGRAM] - module for role checking, can be overwritten
 * @param {string} [resource = MEETING] - resource for role checking, can be overwritten
 * @returns {JSX.Element}
 * @constructor
 */
const CreateMeetingButton = ({
  organizationID,
  item,
  resetFunction,
  module = modules.GOVERNANCE,
  resource = resources.MEETING,
}) => {
  useEffect(() => {
    const pointOfContacts = [];
    const objectives = [];
    const injections = [];

    const responseTeams = item?.responseTeams?.items;

    /**
     * Get exercise points of contact
     */
    for (const teamLink of responseTeams) {
      const pointOfContactLinks = teamLink?.responseTeam?.pointOfContactLinks?.items;

      for (const pocLink of pointOfContactLinks) {
        if (pocLink?.pointOfContact) {
          const pointOfContactID = pocLink?.pointOfContact?.id;
          const found = pointOfContacts.find((x) => x.id === pointOfContactID);
          if (!found) {
            pointOfContacts.push(pocLink?.pointOfContact);
          }
        }
      }
    }

    /**
     * Get exercise objectives
     */
    const exerciseObjectives = item?.objectives;

    if (Array.isArray(exerciseObjectives)) {
      const objectivesArray = [];
      for (const objective of exerciseObjectives) {
        if (objective?.name) {
          objectivesArray.push(objective?.name);
        }
      }
      objectives.push(...objectivesArray);
    }

    /**
     * Get exercise injections
     */
    const exerciseInjections = item?.injections;

    if (Array.isArray(exerciseInjections)) {
      const injectionsArray = [];
      for (const injection of exerciseInjections) {
        if (injection?.name) {
          injectionsArray.push(injection?.name);
        }
      }
      injections.push(...injectionsArray);
    }

    /**
     * Set meeting input
     */
    createMeeting.setInput({
      title: `Exercise: ${item?.name}`,
      description: item?.scenario,
      pointOfContacts,
      agendaItems: [...injections, ...objectives],
    });
  }, [item]);

  const getNewItem = async (newMeeting) => {
    const { updateMutation } = generateGraphql("Exercise");
    await ItemMutation(updateMutation, {
      id: item?.id,
      meetingID: newMeeting?.id,
    })
      .then(() => {
        InfoLogger("Exercise Meeting connection success");
      })
      .catch(() => ErrorLogger("Exercise Meeting connection fail"));

    resetFunction?.();
  };

  const createMeeting = useMeetingForm({
    organizationID,
    getNewItem,
  });

  const checkMeetingPermissions = useCheckPermissions({ module, resource });

  return (
    <MUICreateModalButton
      text={"Schedule Meeting"}
      typename={"Meeting"}
      icon={"icon-calendar"}
      modalConfig={{ width: "60vw" }}
      disabled={!checkMeetingPermissions.resource.create}
    >
      {createMeeting.display}
    </MUICreateModalButton>
  );
};
