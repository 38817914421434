import React, { useContext } from "react";
import PointOfContactDetails from "./PointOfContactDetails";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { modules, resources } from "@rivial-security/role-utils";
import PermissionsOverlay from "../../../../utils/Overlays/PermissionsOverlay";
import { OrganizationContext } from "../../../../utils/Context/OrganizationContext";

/**
 * A version of Point of Contact details that is accessible by an individual User.
 * This is so that a User can see their own details, even if they don't have Point of Contact permissions
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const AccountManager = (props) => {
  const module = modules.ACCOUNT_MANAGER;
  const resource = resources.ACCOUNT_DASHBOARD;

  //Since this is the route the user goes if they don't have a role (or permissions) we can handle the no role assigned condition here
  const context = useContext(OrganizationContext);
  const roleDefined = context?.role?.id ? true : false;

  return (
    <div>
      {roleDefined ? (
        <PermissionsOverlay module={module} resource={resource}>
          <PointOfContactDetails module={module} resource={resource} {...props} />
        </PermissionsOverlay>
      ) : (
        <span
          style={{ fontSize: 16, fontWeight: 500 }}
        >{`There is no role attached to this user account. Please contact your manager or a Rivial administrator to fix this.`}</span>
      )}
    </div>
  );
};

export default withOrganizationCheck(AccountManager);
