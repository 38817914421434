import useCreateMaturityMatrix from "../hooks/useCreateMaturityMatrix";
import { withOrganizationCheck } from "../../../../../../utils/Context/withOrganizationCheck";

/**
 * Component instance of the useCreateMaturityMatrix hook
 * @param {object} props
 */
const CreateMaturityMatrix = (props) => {
  return useCreateMaturityMatrix(props).display;
};

export default withOrganizationCheck(CreateMaturityMatrix);
