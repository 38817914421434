import { useSubscription } from "../../../../../hooks/graphql/useSubscription";
import { useModal } from "../../../../../hooks/views/useModal";
import { Button } from "reactstrap";
import { useQueryCard } from "../../../../../hooks/views/useQueryCard";
import React from "react";
import { useCheckPermissions } from "../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useUploadThreats } from "./useUploadThreats";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";
import CreateThreat from "../../../../Response/Threats/components/CreateThreat";

const {
  listQuery: listThreats,
  onDelete: onDeleteThreat,
  onUpdate: onUpdateThreat,
  onCreate: onCreateThreat,
} = generateGraphql("Threat", ["name"]);

export const useListThreats = (props) => {
  const module = modules.RISK;
  const resource = resources.THREAT;

  const disableRoleChecking = props.disableRoleChecking;

  const checkPermissionsHook = useCheckPermissions({
    module,
    resource,
    disableRoleChecking,
  });

  const subscriptionHook = useSubscription({
    onCreateSubscription: onCreateThreat,
    onDeleteSubscription: onDeleteThreat,
    onUpdateSubscription: onUpdateThreat,
    organizationID: props.organizationID,
    module,
    resource,
  });

  const queryConfig = {
    query: listThreats,
    organizationID: props.organizationID,
    subscriptionHook: subscriptionHook,
    disableRoleChecking,
  };

  const uploadThreatsHook = useUploadThreats({
    organizationID: props.organizationID,
  });

  const createThreatModal = useModal(
    <div>
      Add a new Threat
      {uploadThreatsHook.modalButton}
    </div>,
    <CreateThreat
      organizationID={props.organizationID}
      resetFunction={() => queryCard.reset()}
      disableResetFunction={false}
    />,
    <Button
      size="sm"
      color="ghost-success"
      className="btn-pill"
      disabled={!checkPermissionsHook.resource.create}
      title={
        checkPermissionsHook.resource.create
          ? "Create a new Threat"
          : "You don't have Create Permissions on Resource: Threats"
      }
    >
      <i className="icon-plus" /> Add New Threat
    </Button>,
  );

  const dataCardConfig = {
    fields: ["name"],
    header: "Threats",
    headerButtons: [createThreatModal.modalButton],
    disableRoleChecking,
  };

  const queryCard = useQueryCard({
    queryConfig,
    dataCardConfig,
    module,
    resource,
    disableRoleChecking,
  });

  return { ...queryCard };
};
