import GenericEditField from "./GenericEditFieldV2";
import React from "react";
import { useForm } from "../../hooks/views/useForm/useForm";
import { withOrganizationCheck } from "../Context/withOrganizationCheck";

/**
 * Combines GenericEditField and useForm dropdown input
 * @param {Object} item - item to edit
 * @param {String} organizationID - selected organization
 * @param {function} resetFunction - function to reset form
 * @param {String} module - module to edit
 * @param {String} resource - resource to edit
 * @param {String} field - field to edit
 * @param {String} updateMutation - mutation to update item
 * @param {String} mutation - mutation to update the field
 * @param {object} fieldOverride - override the field to edit
 * @param {object[]} options - options to display in dropdown
 * @param {function} confirmFunction - function to confirm update
 * @param {function} format - function to format the value
 * @param {function} customFormat - function to format the value
 * @param {function} updateItemById - function to update item by id
 * @param {function} updateInputFunction - function to update input
 * @param {function} updateMutationFunction - function to update mutation
 * @param {boolean} disableToast - disable toast
 * @param {object} props - props to pass to GenericEditField
 * @returns {JSX.Element} - component to display

 */
const GenericFieldDropdown = ({
  item,
  organizationID,
  resetFunction,
  module,
  resource,
  field,
  updateMutation,
  mutation,
  fieldOverride,
  options,
  confirmFunction,
  format,
  customFormat,
  updateItemById,
  updateInputFunction,
  updateMutationFunction,
  disableToast,
  tooltip,
  ...props
}) => {
  return (
    <GenericEditField
      item={item}
      module={module}
      resource={resource}
      field={field}
      fieldOverride={fieldOverride}
      mutation={updateMutation || mutation}
      inputType="dropdown"
      format={format}
      customFormat={customFormat}
      confirmFunction={confirmFunction}
      dropdownInput={<DropdownInput organizationID={organizationID} options={options} />}
      resetFunction={resetFunction}
      updateItemById={updateItemById}
      updateInputFunction={updateInputFunction}
      updateMutationFunction={updateMutationFunction}
      disableToast={disableToast}
      tooltip={tooltip}
      {...props}
    />
  );
};

const DropdownInput = ({ value, onChange, options = [] }) => {
  const form = useForm({
    disableResetButton: true,
    disableSubmitButton: true,
    fieldConfig: {
      value: {
        defaultValue: value,
        inputType: "dropdown",
        onChangeFunction: (input) => onChange({ target: { value: input.value } }),
        dropdownConfig: {
          data: options,
        },
      },
    },
  });

  return form.display;
};

export default withOrganizationCheck(GenericFieldDropdown);
