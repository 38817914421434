import React, { useState } from "react";

import DataLoader from "../../LoadingComponents/DataLoader";
import { InfoLogger } from "../../EventLogger";
import { QueryBuilderComponent } from "@syncfusion/ej2-react-querybuilder";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * @example
 * const EXAMPLE_COLUMN_DATA = [
   { field: 'EmployeeID', label: 'EmployeeID', type: 'number' },
   { field: 'FirstName', label: 'FirstName', type: 'string' },
   { field: 'TitleOfCourtesy', label: 'Title Of Courtesy', type: 'boolean', values: ['Mr.', 'Mrs.'] },
   { field: 'Title', label: 'Title', type: 'string' },
   { field: 'HireDate', label: 'HireDate', type: 'date', format: 'dd/MM/yyyy' },
   { field: 'Country', label: 'Country', type: 'string' },
   { field: 'City', label: 'City', type: 'string' }
  ];
 */

/**
 * A custom hook around SyncFusion's QueryBuilder component.
 * Used to create logical expressions
 *
 * @author Jacob Blazina
 * @class
 * @param {object[]} columnDataInit - the initial data to be used as input options
 * @param {boolean} [readonly=false] - whether or not this component can be interacted with
 * @param enableNotCondition
 * @param maxGroupCount
 * @param groupInsert
 * @param groupDelete
 * @param onChangeCallback - callback function that fires every time the queryBuilder gets updated
 * @param itemId
 * @param initialRuleParam
 * @returns {{setRule: *, readonly: *, getRule: *, display: *, columnData: *, rule: *, reset: *, setIsReadOnly: *, setColumnData: *}}
 */
export const useLogicBuilder = ({
  columnDataInit = [],
  readonly = false,
  enableNotCondition = false,
  maxGroupCount = 0,
  groupInsert = false,
  groupDelete = true,
  onChange: onChangeCallback,
  itemId,
  initialRule: initialRuleParam,
}) => {
  const [columnData, setColumnData] = useState(columnDataInit);
  const [resetKey, setResetKey] = useState(0);
  const [rule, setRule] = useState({});
  const [ref, setRef] = useState("");
  const [isReadOnly, setIsReadOnly] = useState(readonly);

  const reset = () => {
    setResetKey((resetKey) => resetKey + 1);
    setRule({});
    ref.reset();
  };

  const getRule = () => {
    const validRule = ref.getValidRules(ref.rule);

    setRule(validRule);
    return validRule;
  };

  const onChange = (e) => {
    if (ref) {
      onChangeCallback?.(ref.getValidRules(ref.rule));
    }
  };

  const getInitialRule = () => {
    if (!isNullOrUndefined(initialRuleParam)) {
      return initialRuleParam;
    } else if (!isNullOrUndefined(itemId)) {
      return {
        condition: "and",
        rules: [
          {
            field: "id",
            label: "ID",
            operator: "equal",
            type: "string",
            value: itemId,
          },
        ],
      };
    } else {
      return undefined;
    }
  };

  /**
   * Sets the initial rules for the query builder
   */
  const onCreate = () => {
    const initialRules = getInitialRule();
    if (!isNullOrUndefined(initialRules)) {
      InfoLogger("Loading Initial Rules: ");
      InfoLogger(initialRules);
      onChangeCallback?.(initialRules);
      ref.setRules(initialRules);
    }
  };

  const display = (
    <DataLoader isEnoughData={true} isLoading={false}>
      <QueryBuilderComponent
        key={JSON.stringify(columnData)}
        width="100%"
        columns={columnData}
        ref={(ref) => setRef(ref)}
        maxGroupCount={maxGroupCount}
        readonly={isReadOnly}
        showButtons={{
          ruleDelete: true,
          groupInsert: groupInsert,
          groupDelete: groupDelete,
        }}
        enableNotCondition={enableNotCondition}
        change={onChange}
        created={onCreate}
      />
    </DataLoader>
  );

  return {
    columnData,
    setColumnData,
    display,
    rule,
    setRule,
    getRule,
    reset,
    readonly: isReadOnly,
    setIsReadOnly: setIsReadOnly,
    ref,
  };
};
