import { useDetailsCard } from "../../../../hooks/views/useDetailsCardV2";
import { generateGraphql } from "@rivial-security/generategraphql";
import React from "react";
import KPIStatus from "../customFields/KPIStatus";
import MetricTypes from "../customFields/MetricTypes";
import { modules, resources } from "@rivial-security/role-utils";
import AutomationsButton from "../../../OrganizationManager/Automations/components/AutomationsButton";

export const useKeyPerformanceIndicatorDetails = ({
  organizationID,
  queryConfig,
  detailsConfig,
  fields = ["name", "description", "status", "metricTypes"],
  customFields = [
    {
      field: "status",
      component: <KPIStatus />,
    },
    {
      field: "metricTypes",
      component: <MetricTypes />,
    },
  ],
  nestedFields = {
    metricTypes: `{
        items {
          id
          logic { operator data { value format } }
          metricType { id name description format metrics { items { id name date data { value format } } } }
        }
      }`,
  },
  fieldNameDictionary = {
    metricTypes: "Metrics",
  },
  config,
  itemId,
}) => {
  const module = modules.METRICS;
  const resource = resources.KEY_PERFORMANCE_INDICATOR;
  const typename = "KeyPerformanceIndicator";

  const { getQuery, updateMutation } = generateGraphql(typename, fields, nestedFields);

  queryConfig = {
    organizationID,
    itemId,
    query: getQuery,
    ...queryConfig,
  };

  detailsConfig = {
    customFields,
    fieldNameDictionary,
    updateMutation,
    ...detailsConfig,
  };

  config = {
    header: "Key Performance Indicator Details",
    headerButtons: [<AutomationsButton itemId={itemId} typename={"KeyPerformanceIndicator"} />],
    ...config,
  };

  const metricDetailsHook = useDetailsCard({
    queryConfig,
    detailsConfig,
    config,
    module,
    resource,
    fields,
    typename,
    nestedFields,
    customFields,
    fieldNameDictionary,
  });

  return {
    ...metricDetailsHook,
  };
};
