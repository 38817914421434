import { generateGraphql } from "@rivial-security/generategraphql";
import { useForm } from "../../../hooks/views/useForm";
import React from "react";
import Label from "../components/Label";

/**
 * @description Creates a new label
 * @param {string} organizationID - The organization ID to create the label for.
 * @param {object} item - The item to create a label for.
 * @param {function} mutation - The mutation to use.
 * @param {function} resetFunction - Function to be called after label is created, resets the form.
 * @param {function} toggleModal - Function to be called after label is created, closes the model.
 * @param {function} callback - Callback function to be called after tag is created.
 * @param {string} typename - The typename of the item to create a label for.
 * @param {function} getNewItem - Function to be called after label is created, gets the new item.
 * @returns {{input: {}, display: *}}
 */
export const useCreateLabel = ({
  organizationID,
  item,
  mutation,
  resetFunction,
  toggleModal,
  callback,
  typename = "Label",
  getNewItem,
}) => {
  const { createMutation: createLabel } = generateGraphql("Label", [
    "name",
    "description",
    "fontColor",
    "backgroundColor",
  ]);

  const formHook = useForm({
    typename,
    item,
    mutation: mutation || createLabel,
    organizationID,
    getNewItem,
    disableRoleChecking: true,
    fieldConfig: {
      name: {
        label: "Label Name",
        required: true,
      },
      description: {
        label: "Description",
      },
      backgroundColor: {
        label: "Background Color",
        inputType: "color-picker",
      },
      fontColor: {
        label: "Font Color",
        inputType: "color-picker",
      },
    },
    callback: () => {
      toggleModal && toggleModal();
      resetFunction && resetFunction();
      callback && callback();
    },
  });

  const display = (
    <div>
      <h6>Label Preview</h6>
      <Label label={{ id: "label-preview", ...formHook.input }} isSelected={true} />
      <hr />
      {formHook.display}
    </div>
  );

  return {
    ...formHook,
    display,
  };
};
