import { generateGraphql } from "@rivial-security/generategraphql";
import { ItemQuery } from "../../../../../../../utils/Functions/Graphql/ItemQuery";

/**
 * Generates a mapping of Controls to their mapped Risk Controls, using Evidence as the intermediary.
 * @param {string[]} selectedIDs - IDs of selected Controls to generate a mapping for
 * @returns {Promise<object[]>}
 */
export const controlRiskControlMapping = async ({ selectedIDs = [] }) => {
  const { getQuery } = generateGraphql("Control", ["id", "statementNumber", "name", "evidences"], {
    evidences: `(limit: 500)
        {
          items {
            evidence {
              id
              name
              itemNumber
              riskControls (limit: 500) {
                items {
                  riskControl {
                    id
                    statementNumber
                    name
                  }
                }
              }
            }
          }
        }
      `,
  });

  const data = [];

  const promises = [];

  for (const controlID of selectedIDs) {
    const fullControlPromise = ItemQuery(getQuery, controlID);

    promises.push(fullControlPromise);
  }

  const promiseResults = await Promise.allSettled(promises);

  for (const result of promiseResults) {
    if (result.status === "fulfilled") {
      const control = result.value;
      const { evidences } = control;
      for (const evidenceLink of evidences.items) {
        for (const controlLink of evidenceLink.evidence.riskControls.items) {
          const {
            riskControl: { id: riskControlID, statementNumber: riskControlStatementNumber, name: riskControlName },
          } = controlLink;
          data.push({
            controlID: control.id,
            controlName: control.name,
            controlStatementNumber: control.statementNumber,
            riskControlID,
            riskControlStatementNumber,
            riskControlName,
          });
        }
      }
    }
  }

  // de-duplicate entries

  const dedupedData = [];
  const dedupeMap = {};
  for (const entry of data) {
    const { controlID, riskControlID } = entry;
    const key = `${controlID}-${riskControlID}`;
    if (!dedupeMap[key]) {
      dedupeMap[key] = true;
      dedupedData.push(entry);
    }
  }

  return dedupedData;
};
