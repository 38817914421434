import { modules, resources } from "@rivial-security/role-utils";
import GenericEditFieldV3 from "../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import React from "react";
import { GENERIC_FIELD_TYPES } from "../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 * The 'enabled' field for Automations.
 * Must be true for automations to trigger
 * @param {object} item - the automation item
 * @param {string} organizationID - the organization ID
 * @param {string} fieldContext - appended automatically by UI wrappers, tells whether the field is in a details page or a grid
 * @param {object} ...props - all other props passed to generic edit field
 * @returns {JSX.Element}
 * @constructor
 */
const AutomationEnabled = ({ item, organizationID, ...props }) => {
  const { updateMutation } = generateGraphql("Automation", ["enabled"]);

  return (
    <GenericEditFieldV3
      organizationID={organizationID}
      resource={resources.AUTOMATION}
      module={modules.ORGANIZATION_MANAGER}
      typename={"Automation"}
      item={item}
      field="enabled"
      inputType={GENERIC_FIELD_TYPES.SWITCH}
      mutation={updateMutation}
      {...props}
    />
  );
};

export default withOrganizationCheck(AutomationEnabled);
