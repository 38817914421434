import { useForm } from "@hooks/views/useForm";
import { ReactElement, useEffect, useState } from "react";
import { FORM_INPUT_TYPES } from "@hooks/views/useForm/enums/FORM_INPUT_TYPES";
import { SelectItemGrid } from "@utils/GenericComponents/SelectItemGrid";
import { useVendorCertificationDataGrid } from "@views/Vendor/VendorCertification/hooks/useVendorCertificationDataGrid";

export interface UseSelectVendorPreQualifiedCertificationParams {
  organizationID: string;
}

export interface UseSelectVendorPreQualifiedCertificationResult {
  certificationID: string | null;
  display: ReactElement;
}

export const useSelectVendorPreQualifiedCertification = ({
  organizationID,
}: UseSelectVendorPreQualifiedCertificationParams): UseSelectVendorPreQualifiedCertificationResult => {
  const [certificationID, setCertificationID] = useState<string | null>(null);

  const form = useForm({
    organizationID,
    disableSubmitButton: true,
    disableResetButton: true,
    fieldConfig: {
      vendorDocumentVendorCertificationId: {
        inputType: FORM_INPUT_TYPES.ITEM_SELECT,
        label: "Pre-Qualified Certification",
        tooltip:
          "Select a pre-qualified certification for this vendor solution. This will mark related controls as audited.",
        itemSelectConfig: {
          grid: <SelectItemGrid organizationID={organizationID} gridHook={useVendorCertificationDataGrid} />,
        },
      },
    },
  });

  useEffect(() => {
    const input = form?.input as { vendorDocumentVendorCertificationId?: string } | undefined;
    const newCertificationID = input?.vendorDocumentVendorCertificationId;
    setCertificationID(newCertificationID || null);
  }, [form.input]);

  return {
    certificationID,
    display: form.display,
  };
};
