import CustomFieldEditor from "../customFields/CustomFieldEditor/CustomFieldEditor";
import { CustomFieldType } from "@views/Compliance/Controls/ControlSets/constants/CustomFieldType";
import DateFilterMenu from "../../../../../utils/FilterMenus/DateFilterMenu";
import React from "react";
import { isNonEmptyArray } from "@rivial-security/func-utils";

/**
 * Converts a list of custom field descriptions (name and type) to custom fields that can be used in a grid
 * @param {string} module - platform module for role checking
 * @param {string} resource - platform resource for role checking
 * @param {boolean} disableRoleChecking=false - if TRUE will disable role checking
 * @param {object[]} customFields - a list of objects containing the type and name of a custom field
 * @param {boolean} visible - if TRUE will show the custom field in the grid
 * @param {string} typename - the typename of the item being edited, could be Control or CustomResourceEntry
 * @return {object[]} custom fields to use in a grid that correspond to the custom fields setup provided
 */
export const generateGridCustomFields = ({
  module,
  resource,
  disableRoleChecking = false,
  customFields,
  visible = false,
  typename,
}) => {
  if (customFields && Array.isArray(customFields)) {
    const newFields = [];
    for (const customField of customFields) {
      if (customField?.type && customField?.name) {
        let currentField = {
          name: customField.name,
          visible,
          isDynamicCustomField: true,
          component: (
            <CustomFieldEditor
              module={module}
              resource={resource}
              disableRoleChecking={disableRoleChecking}
              config={customField}
              inGrid={true}
              typename={typename}
            />
          ),
          width: 100,
        };

        if (customField.type === CustomFieldType.STRING) {
          currentField = {
            ...currentField,
            filter: { type: "Menu" },
            type: "string", // required for proper filter operations
          };
        } else if (customField.type === CustomFieldType.MULTIPLE_SELECT) {
          currentField = {
            ...currentField,
            type: "string", // required for proper filter operations
            width: 250,
            valueFormatter: (value) => {
              if (!isNonEmptyArray(value) || !isNonEmptyArray(customField?.multipleSelect)) {
                return "";
              }
              return value
                .map((selectedOptionKey) => {
                  const matchingOption = customField.multipleSelect.find(
                    (option) => option.value === selectedOptionKey,
                  );
                  return matchingOption ? matchingOption.label : selectedOptionKey;
                })
                .join(", ");
            },
          };
        } else if (customField.type === CustomFieldType.BOOLEAN) {
          currentField = {
            ...currentField,
            filter: { type: "CheckBox" },
            type: "boolean", // required for proper filter operations
          };
        } else if (customField.type === CustomFieldType.NUMBER) {
          currentField = {
            ...currentField,
            type: "number", // required for proper filter operations
          };
        } else if (customField.type === CustomFieldType.ENUM) {
          currentField = {
            ...currentField,
            type: "enum", // required for proper filter operations
            valueFormatter: (value) => {
              if (value && isNonEmptyArray(customField?.options)) {
                const matchingOption = customField.options.find((option) => option.value === value);
                return matchingOption ? matchingOption.label : value;
              }
            },
          };
        } else if (customField.type === CustomFieldType.DATE) {
          currentField = {
            ...currentField,
            type: "date", // required for proper filter operations
            filterTemplate: (props) => <DateFilterMenu props={props} />,
          };
        } else {
          continue;
        }

        newFields.push(currentField);
      }
    }
    return newFields;
  } else {
    return [];
  }
};
