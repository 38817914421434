import { useManyLinking } from "../../../../hooks/views/useManyToManyTransfer/hooks/useManyLinking";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useUserList } from "../../Users/hooks/useUserList";
import { handleUserRoleLinking } from "../functions/handleUserRoleLinking";
import { handleUserRoleUnlinking } from "../functions/handleUserRoleUnlinking";

/**
 * Hook for linking Users to a Role using a transfer list
 * @param {object} item - the Role being linked to
 * @param {string} organizationID - the organization ID for the new links
 * @param {function} resetFunction - for bubbling up the reset
 * @param {function} toggleModal - for toggling a parent modal
 * @returns {{display: JSX.Element}}
 */
export const useRoleUserLinking = ({ item, organizationID, resetFunction, toggleModal }) => {
  const typename = "UserRoleLink";
  const field = "userLinks";
  const nestedField = "user";

  const { listQuery } = generateGraphql("User", ["name", "email", "phoneNumber"]);

  const queryConfig = {
    query: listQuery,
  };

  const userList = useUserList({ organizationID, queryConfig });

  return useManyLinking({
    organizationID,
    item,
    field,
    nestedField,
    typename,
    existingItems: userList?.data || [],
    parentConnectionIDField: "roleID",
    childConnectionIDField: "userID",
    resetFunction,
    toggleModal,
    childTypename: "User",
    handleLinking: handleUserRoleLinking,
    handleUnlinking: handleUserRoleUnlinking,
  });
};
