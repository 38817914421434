import { useChecklist } from "../../../../hooks/views/useChecklist/useChecklistV2";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { modules, resources } from "@rivial-security/role-utils";

/**
 * CheckList component for displaying injections
 * @param {object} item - exercise object
 * @param {string} typename - parent type name
 * @param {string} field - child type name
 * @param {boolean} enableCheckbox - enable or disable checkboxes
 * @param {function} resetFunction - callback to re fetch item from the database
 * @param {boolean} disableEdits - disable text field edits
 * @param {string} organizationID - selected organization id
 * @returns {JSX.Element}
 */
const ExerciseInjections = ({
  item,
  typename = "Exercise",
  field = "injections",
  enableCheckbox = true,
  resetFunction,
  disableEdits = false,
  organizationID,
}) => {
  const module = modules.INCIDENT_RESPONSE;
  const resource = resources.EXERCISE;

  const nestedFields = {
    [field]: `{ id name met notes { id type ownerGroup author timeStamp content tag observationID }}`,
  };

  const checklist = useChecklist({
    item,
    field,
    typename,
    module,
    resource,
    nestedFields,
    resetFunction,
    enableCheckbox,
    disableEdits,
    checkboxFieldName: "met",
    textFieldName: "name",
    enableNotes: true,
    organizationID,
    stepButtonName: "Injection",
    notesProps: {
      observationConnectionField: "exerciseID",
      exerciseID: item?.id,
    },
  });

  return checklist.display;
};

export default withOrganizationCheck(ExerciseInjections);
