"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOrganizationField = void 0;
const getOrganization_1 = require("./getOrganization");
const appsync_utils_1 = require("@rivial-security/appsync-utils");
/**
 * Retrieves the most up-to date information for an organization
 * @param {string} field - the field to get from the organization query
 * @param {string} organizationID - the organization ID for which to get the data
 * @return {Promise<string>} - the data for the field
 */
const getOrganizationField = async ({ field, organizationID }) => {
    if (!field || !organizationID) {
        console.log("getOrganizationField: Missing field or organizationID");
        return "";
    }
    const organization = await (0, appsync_utils_1.GetQuery)({ query: getOrganization_1.getOrganization, variables: { id: organizationID } });
    let result;
    if (organization?.hasOwnProperty(field)) {
        result = organization[field];
    }
    return result || "";
};
exports.getOrganizationField = getOrganizationField;
