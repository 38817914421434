import { modules, resources } from "@rivial-security/role-utils";
import { useDetails } from "../../../../hooks/views/useDetails/hooks/useDetails";

export const useMetricDetails = ({ item }) => {
  const module = modules.METRICS;
  const resource = resources.METRIC_TYPE;

  const metricDetailsHook = useDetails({
    item,
    module,
    resource,
  });

  return {
    ...metricDetailsHook,
  };
};
