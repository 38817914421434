import React, { useEffect, useState } from "react";
import { modules, resources } from "@rivial-security/role-utils";
import { removeObjectFromArray, updateObjectInArray } from "@rivial-security/func-utils";

import { Button } from "reactstrap";
import PermissionsOverlay from "../../../../utils/Overlays/PermissionsOverlay";
import { createFullRecommendation } from "../../Recommendations/functions/createFullRecommendation";
import { createObservationRecommendationLink } from "../functions/createObservationRecommendationLink";
import { deleteObservationRecommendationLink } from "../../Recommendations/functions/deleteObservationRecommendationLink";
import { useAddRecommendation } from "../../Recommendations/hooks/useAddRecommendation";
import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * Displays RecommendationItems associated with an Observation.
 * Allows the user to add/remove RecommendationItems
 * @param item
 * @param organizationID
 * @param module
 * @param resource
 * @param updateItemById
 * @param resetFunction
 * @returns {JSX.Element}
 * @constructor
 */
const Recommendations = ({
  item,
  organizationID,
  module = modules.GOVERNANCE,
  resource = resources.RECOMMENDATION,
  updateItemById,
  resetFunction,
}) => {
  const checkPermissions = useCheckPermissions({ module, resource });

  const [initialInput, setInitialInput] = useState([]);
  const [input, setInput] = useState([]);

  const [inputChanged, setInputChanged] = useState(false);

  const [recommendationsToAdd, setRecommendationsToAdd] = useState([]);
  const [recommendationsToDelete, setRecommendationsToDelete] = useState([]);

  const handleSave = async () => {
    // handle creations
    for (const recommendationToAdd of recommendationsToAdd) {
      // If the recommendation already exists, just create the link.
      if (recommendationToAdd.alreadyExists) {
        await createObservationRecommendationLink(item, recommendationToAdd);
      }
      // If it's a brand new recommendation, create a new one and create the link
      else {
        await createFullRecommendation(
          {
            ...recommendationToAdd,
            ownerGroup: organizationID,
          },
          item,
        );
      }
    }

    // handle removals
    for (const recommendationToRemove of recommendationsToDelete) {
      // find the corresponding link
      const foundLink = item?.recommendations?.items?.find((recLink) => {
        if (recLink?.recommendation?.id === recommendationToRemove?.id) {
          return true;
        } else {
          return false;
        }
      });

      if (foundLink && foundLink.id) {
        await deleteObservationRecommendationLink(foundLink);
      }
    }

    setRecommendationsToAdd([]);
    setRecommendationsToDelete([]);
    setInitialInput([...input]);
    setInputChanged(false);
    resetFunction && resetFunction();
    // updateItemById && updateItemById()
  };

  const handleCancel = () => {
    addRecommendationForm.setInput([...initialInput]);
    setRecommendationsToAdd([]);
    setRecommendationsToDelete([]);
    setInput([...initialInput]);
    setInputChanged(false);
  };

  const onAdd = (recommendation) => {
    setRecommendationsToAdd((curr) => [...curr, recommendation]);
    setInput([...input, recommendation]);
    setInputChanged(true);
  };

  const onRemove = (recommendation) => {
    setRecommendationsToDelete((curr) => [...curr, recommendation]);
    setInput((input) => {
      const temp = [...input];
      removeObjectFromArray(temp, recommendation);
      setInput([...temp]);
    });
    setInputChanged(true);
  };

  const onUpdate = (action) => {
    setRecommendationsToAdd((items) => {
      updateObjectInArray(items, action, "id");
      return [...items];
    });

    setInput((input) => {
      updateObjectInArray(input, action, "id");
      return [...input];
    });
  };

  /**
   * If an existing observation is passed in, populate the list to start with
   */
  useEffect(() => {
    if (item?.recommendations?.items && Array.isArray(item.recommendations.items)) {
      const res = [];

      for (const recommendationLink of item.recommendations.items) {
        //Allows to handle both recommendation link arrays and plain recommendations arrays
        const recommendation = recommendationLink.recommendation || recommendationLink;

        const actionItems = [];
        if (recommendation?.actionItems?.items) {
          for (const actionItemLink of recommendation.actionItems.items) {
            const actionItem = actionItemLink.action;
            actionItems.push({
              alreadyExists: true,
              ...actionItem,
            });
          }
        }
        if (recommendation) {
          recommendation.actionItems = actionItems;
        }
        res.push({
          ...recommendation,
          alreadyExists: true,
        });
      }
      setInput(res);
      setInitialInput(res);
    }
  }, [item]);

  const addRecommendationForm = useAddRecommendation({
    organizationID,
    module,
    disableSubmitButton: true,
    disableResetButton: true,
    onAdd,
    onRemove,
  });

  /**
   * Update the form when the initial input changes
   */
  useEffect(() => {
    if (initialInput) {
      addRecommendationForm.setInput([...initialInput]);
    }
  }, [initialInput]);

  return (
    <PermissionsOverlay module={module} resource={resource}>
      <div>
        {inputChanged && (
          <span>
            <Button size="sm" color="success" disabled={!checkPermissions.resource.update} onClick={handleSave}>
              Save Changes
            </Button>{" "}
            <Button size="sm" color="danger" disabled={!checkPermissions.resource.update} onClick={handleCancel}>
              Cancel Changes
            </Button>
          </span>
        )}
        {addRecommendationForm.display}
      </div>
    </PermissionsOverlay>
  );
};

export default withOrganizationCheck(Recommendations);
