import { useSaveCustomQuery } from "./useSaveCustomQuery";
import { useModal } from "../../../hooks/views/useModal";
import { Button } from "@mui/material";
import Save from "@mui/icons-material/Save";
import React from "react";
import { useRefState } from "../../../hooks/functional/useRefState";

/**
 * Hook holding everything needed to display a save custom query modal
 * @param {string} organizationID - the id of the currently selected organization
 * @returns {{setModalIsOpen: function(*): void, modalButton: JSX.Element, modalIsOpen: boolean, setQueryConfig: function, setGraphql: function, modal: *, toggleModal: function(): void}}
 */
export const useSaveCustomQueryModal = ({ organizationID, callback }) => {
  const [queryConfig, setQueryConfig, queryConfigRef] = useRefState({});

  const saveCustomQueryHook = useSaveCustomQuery({
    organizationID,
    toggleModal: () => {
      saveCustomQueryModal.setModalIsOpen(false);
    },
    callback,
  });

  const saveCustomQueryModal = useModal("Save Custom Query", saveCustomQueryHook.display, null);

  const saveButton = (
    <Button
      data-testid={"save-custom-query-button"}
      variant={"contained"}
      startIcon={<Save />}
      onClick={() => {
        onClickSave();
      }}
    >
      Save Query
    </Button>
  );

  /**
   * Handles the save button click
   * Sets the saveCustomQueryHook state and then opens the modal
   */
  const onClickSave = () => {
    let newTypename;
    const query = queryConfigRef?.current;
    if (query) {
      const rootTypes = query?.fields;
      if (
        typeof rootTypes === "object" &&
        Object.keys(rootTypes).length === 1 &&
        rootTypes[Object.keys(rootTypes)[0]].isRoot
      ) {
        newTypename = Object.keys(rootTypes)[0];
      }
    }

    saveCustomQueryHook.setInput((input) => ({
      ...input,
      typename: input?.typename || newTypename,
      queryConfig: queryConfigRef?.current,
    }));

    saveCustomQueryModal.setModalIsOpen(true);
  };

  return {
    ...saveCustomQueryModal,
    onClickSave,
    modalButton: saveButton,
    setQueryConfig,
  };
};
