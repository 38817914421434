import React, { useContext } from "react";
import { UIContext } from "../../../../../utils/Context/UIContext";
import { usePreferences } from "../../../../../hooks/views/usePreferences/usePreferences";
import { useForm } from "../../../../../hooks/views/useForm/useForm";
import { useCard } from "../../../../../hooks/views/useCard";
import { UncontrolledTooltip } from "reactstrap";

/**
 * @description Interface Beta Risk Calculations for setting which risk structure to use
 * @return {JSX.Element}
 * @constructor
 */
const InterfaceBetaRiskCalculations = () => {
  const { addToast } = useContext(UIContext);

  const preferences = usePreferences();

  const betaRiskCalculationsForm = useForm({
    disableSubmitButton: true,
    disableResetButton: true,
    fieldConfig: {
      betaRiskCalculations: {
        inputType: "switch",
        label: "Enable Beta Risk Calculations",
        defaultValue: preferences?.getPreference("general", "beta_risk_calculations") || false,
        onChangeFunction: ({ betaRiskCalculations }) => {
          preferences?.setPreference("general", {
            beta_risk_calculations: betaRiskCalculations,
          });
          addToast({
            header: `Beta Risk calculations were changed to ${betaRiskCalculations ? "enabled" : "disabled"}`,
            icon: "success",
          });
        },
      },
    },
  });

  const card = useCard({
    id: "beta-risk-calculations",
    title: (
      <>
        <h4 style={{ display: "inline" }}>Beta Risk Calculations</h4>{" "}
        <i id="data-beta-risk-calculations-icon-question" className="icon-question" />
        <UncontrolledTooltip placement="right" target="data-beta-risk-calculations-icon-question">
          If Enabled, System Risk Tolerance calculations will use an experimental algorithm
        </UncontrolledTooltip>
      </>
    ),
    body: <>{betaRiskCalculationsForm.display}</>,
  });

  return card?.display;
};

export default InterfaceBetaRiskCalculations;
