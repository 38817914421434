import GenericEditFieldV3 from "../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";
import React from "react";
import { modules, resources } from "@rivial-security/role-utils";
import { updateRiskControlOverride } from "../../../Risk/Systems/components/Overrides/functions/OverrideFunctions";
import { generateGraphql } from "@rivial-security/generategraphql";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { GENERIC_FIELD_TYPES } from "../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import { formattedPercent } from "@rivial-security/func-utils";
import { emptyPlaceholder } from "../../../../utils/GenericComponents/Placeholders";

/**
 * Displays the non-standard control implementation rating
 * @param {object} item - non-standard control data
 */
const NonStandardControlImplementationRating = ({ item, implementationRating, updateItemById } = {}) => {
  const module = modules.RISK;
  const resource = resources.INFORMATION_SYSTEM;
  const field = "implementationRating";

  const handleEdit = async (newValue) => {
    //confirmation dialog for the mutation
    const confirm = window.confirm(
      "Please note if there are any enabled evidence items attached to this non-standard risk control, they may override your changes in the future.",
    );
    if (!confirm) {
      return;
    }

    const system = item?.system;
    const riskControl = item?.control;
    const value = newValue?.[field];

    await updateRiskControlOverride(system, riskControl, field, value, async ({ input }) => {
      const { updateMutation } = generateGraphql("System");
      return await ItemMutation(updateMutation, input);
    });

    updateItemById && updateItemById(newValue);
  };

  return (
    <div>
      {item?.system && item?.control ? (
        <GenericEditFieldV3
          mutationFunction={handleEdit}
          item={item}
          field={field}
          module={module}
          resource={resource}
          inputType={GENERIC_FIELD_TYPES.PERCENTAGE}
        />
      ) : implementationRating ? (
        formattedPercent(implementationRating)
      ) : (
        emptyPlaceholder
      )}
    </div>
  );
};

export default NonStandardControlImplementationRating;
