import { handlePercentFormat } from "./handlePercentFormat";
import { IMPORTER_FIELD_FORMATS } from "../enums/IMPORTER_FIELD_FORMATS";
import { handleDollarFormat } from "./handleDollarFormat";
import { handleNotesFormat } from "./handleNotesFormat";
import { handleBooleanFormat } from "./handleBooleanFormat";

/**
 * Converts various importer field types into their respective form, like percentages and dollar amounts
 *
 * @param {string|number} value - the cell value to try and format
 * @param {IMPORTER_FIELD_FORMATS} format - the particular format configuration to use
 * @param {string} organizationID - used when creating a new note
 * @returns {number|string|number|*}
 */
export const handleFieldFormat = ({ value, format, organizationID }) => {
  switch (format) {
    case IMPORTER_FIELD_FORMATS.PERCENT:
      return handlePercentFormat({ value });
    case IMPORTER_FIELD_FORMATS.DOLLAR:
      return handleDollarFormat({ value });
    case IMPORTER_FIELD_FORMATS.NOTES:
      return handleNotesFormat({ value, organizationID });
    case IMPORTER_FIELD_FORMATS.BOOLEAN:
      return handleBooleanFormat({ value });
    default:
      return value;
  }
};
