import React from "react";
import { Badge } from "reactstrap";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import IconButton from "@mui/material/IconButton";
import LockIcon from "@mui/icons-material/Lock";
import isRestricted from "../../../../utils/AccessControl/functions/isRestricted";

/**
 * @description Display Meeting Access Control status
 * @param {object} item - meeting object
 * @returns {JSX.Element}
 * @constructor
 */
const AccessControlStatus = ({ item }) => {
  if (isRestricted(item)) {
    return (
      <>
        <Restricted />
        <Badge color={"warning"} style={{ fontSize: ".6rem" }}>
          Restricted
        </Badge>
      </>
    );
  } else {
    return (
      <>
        <NotRestricted />
        <Badge color={"success"} style={{ fontSize: ".6rem" }}>
          Not Restricted
        </Badge>
      </>
    );
  }
};

const Restricted = () => {
  return (
    <IconButton disabled={true}>
      <LockIcon style={{ color: "#ffc107", fontSize: "1.25rem" }} />
    </IconButton>
  );
};

const NotRestricted = () => {
  return (
    <IconButton disabled={true}>
      <LockOpenIcon color={"success"} style={{ fontSize: "1.25rem" }} />
    </IconButton>
  );
};

export default AccessControlStatus;
