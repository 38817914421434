import { generateGraphql } from "@rivial-security/generategraphql";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { ItemMutation } from "../../../../../../../utils/Functions/Graphql/ItemMutation";

/**
 * Creates a single many-to-many link between system and target
 * @param {object} system - system data with id & ownerGroup fields
 * @param {object} target - target data with id & ownerGroup fields
 */
export const createSystemTargetLink = async (system, target) => {
  const { createMutation } = generateGraphql("SystemTargetLink");

  if (
    !isNullOrUndefined(system?.id) &&
    !isNullOrUndefined(target?.id) &&
    (!isNullOrUndefined(system?.ownerGroup) || !isNullOrUndefined(target?.ownerGroup))
  ) {
    return await ItemMutation(createMutation, {
      systemID: system.id,
      targetID: target.id,
      ownerGroup: system.ownerGroup || target.ownerGroup,
    });
  }
};
