import React, { useContext, useEffect, useState } from "react";
import { modules, resources } from "@rivial-security/role-utils";

import { Alert } from "@mui/material";
import { Button } from "reactstrap";
import CustomTooltip from "../../../../utils/GenericComponents/CustomTooltip";
import { EVIDENCE_TYPES } from "../../../../typedefs/Compliance/Evidence/Evidence";
import { ErrorLogger } from "@utils/EventLogger";
import FrequencyScheduler from "../../../../utils/GenericComponents/FrequencyScheduler/FrequencyScheduler";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { OrganizationContext } from "../../../../utils/Context/OrganizationContext";
import { UIContext } from "../../../../utils/Context/UIContext";
import cronstrue from "cronstrue";
import { fieldContexts } from "../../../../enums/fieldContexts";
import { generateGraphql } from "@rivial-security/generategraphql";
import { isInSelectTemplatesGrid } from "../../../../utils/Functions/Views/grid/isInSelectTemplatesGrid";
import { onEvidenceFrequencyChange } from "../functions/onEvidenceFrequencyChange";
import parser from "cron-parser";
import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useModal } from "../../../../hooks/views/useModal";

/**
 * Custom field for displaying Evidence Frequency data
 * @param {string} module - the module to use for role checking
 * @param {string} resource - the resource to use for role checking
 * @param {boolean} disableRoleChecking - if TRUE will disable role checking
 * @param {function} resetFunction - function to re-fetch data from database
 * @param {function} setActivityResetIndex - triggers a re-fetch of the evidence activities
 * @param {object} item - object with other info pertaining to this evidence
 * @param {object} input - the input object from the useForm hook if field is used as a custom component field
 * @param {string} initValue - cron expression to set by default if item has no value defined
 * @param {function} [onChangeCallback] - callback that gets called when the value changes
 * @param {function} updateItemById - update evidence by id
 * @param {boolean} disabled - if TRUE will not allow to edit the field
 * @param {function} submitFunction - async ({value, field}) bypasses the built-in submit logic
 * @param {string} fieldContext - The context of the field, used to determine if a tooltip should be displayed
 * @param {boolean} [gridPersistenceUUID] - The UUID of the grid, present if the field is in a grid
 * @return {JSX.Element}
 */
const EvidenceFrequency = ({
  module = modules.COMPLIANCE,
  resource = resources.EVIDENCE,
  disableRoleChecking = false,
  resetFunction,
  setActivityResetIndex,
  item: initItem,
  input,
  initValue,
  onChangeCallback,
  updateItemById,
  disabled = false,
  submitFunction,
  fieldContext,
  gridPersistenceUUID,
}) => {
  const { loggedInPointOfContactId, loggedInUserId } = useContext(OrganizationContext);

  const checkPermissionsHook = useCheckPermissions({
    module,
    resource,
    disableRoleChecking,
  });

  const { addToast, updateToast } = useContext(UIContext);
  const [item, setItem] = useState(initItem);
  const [cronExp, setCronExp] = useState("");

  const [isCronValid, setIsCronValid] = useState(false);
  useEffect(() => {
    try {
      new Date(parser.parseExpression(cronExp).next());
      setIsCronValid(true);
    } catch (e) {
      setIsCronValid(false);
    }
  }, [cronExp]);

  /**
   * if input or initItem changes update the state, prefer initItem parameter
   */
  useEffect(() => {
    setItem(initItem || input);
  }, [input, initItem]);

  /**
   * Get frequency from the passed evidence
   */
  useEffect(() => {
    if (item?.frequency) {
      try {
        cronstrue.toString(item?.frequency);
        setCronExp(item?.frequency);
      } catch (e) {
        ErrorLogger("Cannot parse evidence frequency");
      }
    }
  }, [item?.frequency]);

  /**
   * Used to update useForm hook
   */
  useEffect(() => {
    if (cronExp) {
      onChangeCallback?.(cronExp);
    }
  }, [cronExp]);

  /**
   * Used to assign initial cron value
   */
  useEffect(() => {
    if (initValue) setCronExp(initValue);
  }, [initValue]);

  const submit = async () => {
    // If a submitFunction is passed in, use that instead of the default
    if (typeof submitFunction === "function") {
      await submitFunction({ value: cronExp, field: "frequency" });
      modal.setModalIsOpen(false);
      return;
    }

    if (item?.id && cronExp) {
      const toastId = addToast({
        header: `Changing evidence Frequency...`,
        icon: "spinner",
      });
      const { updateMutation } = generateGraphql("Evidence", ["frequency"]);
      await ItemMutation(updateMutation, { id: item.id, frequency: cronExp })
        .then(async (updatedItem) => {
          /**
           * Create evidence activity for frequency change
           */
          await onEvidenceFrequencyChange({
            evidenceID: item?.id,
            oldFrequency: item?.frequency,
            newFrequency: cronExp,
            organizationID: item?.ownerGroup,
            pointOfContactID: loggedInPointOfContactId,
            userID: loggedInUserId,
          }).then(
            () =>
              typeof setActivityResetIndex === "function" &&
              setActivityResetIndex((activityResetIndex) => activityResetIndex + 1),
          );

          /**
           * For updating item in the grid
           */
          if (typeof updateItemById === "function") {
            updateItemById(updatedItem);
          } else if (typeof resetFunction === "function") {
            resetFunction();
          }

          updateToast({
            id: toastId,
            icon: "success",
            header: `Evidence Frequency successfully saved..`,
          });
        })
        .catch(() => {
          updateToast({
            id: toastId,
            icon: "danger",
            header: `Can not save new Evidence Frequency..`,
          });
        });
    }
    modal.setModalIsOpen(false);
  };

  const modal = useModal(
    "Select Frequency",
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FrequencyScheduler initValue={cronExp} value={cronExp} onChangeCallback={setCronExp} />
      </div>
      <div className={"float-right"} style={{ display: "flex", flexDirection: "row" }}>
        {!isCronValid && (
          <Alert severity="error" sx={{ marginRight: ".5em" }}>
            Sorry, these frequency settings are not supported yet.
          </Alert>
        )}
        <Button disabled={!isCronValid} className={"float-right"} color="success" onClick={() => submit()}>
          Submit
        </Button>
      </div>
    </div>,
    <Button size="sm" color="ghost-warning" className="btn-pill" title="Select custom frequency">
      <i className="icon-pencil" />
    </Button>,
    {
      width: "80vw",
    },
  );
  return (
    <div>
      {item?.type === EVIDENCE_TYPES.KPI ? (
        <div>
          Real-Time
          <span className={"float-right"}>
            <CustomTooltip
              tooltip={
                "When the evidence is of the 'Key Performance Indicator' type, it does not expire on a schedule but is automatically updated whenever the attached KPI statuses or linkings change."
              }
            />
          </span>
        </div>
      ) : (
        <div>
          {item?.frequency && cronstrue.toString(item?.frequency)}
          {!disabled &&
            checkPermissionsHook.resource.update &&
            !isInSelectTemplatesGrid({ gridPersistenceUUID }) &&
            modal.modalButton}
          {
            // Don't show tooltip if field is inside grid
            fieldContext !== fieldContexts.GRID && (
              <span className={"float-right"}>
                <CustomTooltip
                  tooltip={
                    "This setting adjusts how often evidence artifacts need to be submitted to keep this evidence in place."
                  }
                />
              </span>
            )
          }
        </div>
      )}
    </div>
  );
};

export default EvidenceFrequency;
