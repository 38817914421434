import { useTargetsGrid } from "../../Targets/hooks/useTargetsGrid";
import { listAssessmentTargetLinksByAssessmentId } from "../graphql/__assessmentGQL";
import CreateLinking from "../../../../utils/GenericComponents/CreateLinking";
import React from "react";
import CreateTarget from "../../Targets/components/CreateTarget";
import TargetsGrid from "../../Targets/components/TargetsGrid";
import { createAssessmentTargetLink } from "../functions/createAssessmentTargetLink";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { generateGraphql } from "@rivial-security/generategraphql";
import TargetDetails from "../../Targets/components/TargetDetails";
import { isNonEmptyArray, isNullOrUndefined } from "@rivial-security/func-utils";
import { useSetAsyncData } from "../../../../hooks/functional/useSetAsyncData";
import { GetQuery, ListQueryBy } from "@rivial-security/appsync-utils";

/**
 * Display Targets list for an Assessment
 * @param {object} item - assessment object
 * @param {string} organizationID - selected organization ID
 * @param {object} filterByVulnerability - a target object to filter the grid by,
 * only Targets that are attached to both the assessment and vulnerability will be shown
 */
export const useAssessmentTargets = ({ item, organizationID, filterByVulnerability }) => {
  const deleteFunction = async (item) => {
    if (item?.connectionIDField) {
      await ItemMutation(generateGraphql("AssessmentTargetLink").deleteMutation, { id: item?.connectionIDField });
    }
  };

  const { isLoading, resetFunction } = useSetAsyncData({
    getData: async () => {
      //Retrieve both the vulnerability links and the assessment links
      let vulnerability = null;
      const vulnerabilityTargetIds = new Set();
      if (!isNullOrUndefined(filterByVulnerability?.id)) {
        vulnerability = await GetQuery({
          query: getVulnerabilityQuery,
          variables: {
            id: filterByVulnerability?.id,
          },
        });

        const vulnerabilityTargetLinks = vulnerability?.targets?.items;
        if (isNonEmptyArray(vulnerabilityTargetLinks)) {
          for (const link of vulnerabilityTargetLinks) {
            vulnerabilityTargetIds.add(link?.target?.id);
          }
        }
      }

      //Retrieve all the assessment targets
      let assessmentTargets = [];
      const assessmentTargetLinks = await ListQueryBy({
        query: listAssessmentTargetLinksByAssessmentId,
        variables: {
          assessmentID: item?.id,
        },
        limit: 1000,
      });
      for (const link of assessmentTargetLinks) {
        if (!link?.target) {
          continue;
        }

        const found = assessmentTargets.find((x) => x.id === link?.target?.id);
        if (!found) {
          Object.assign(link.target, { connectionIDField: link?.id });
          assessmentTargets.push(link.target);
        }
      }

      //If there are vulnerability targets, filter to only show targets that are attached to both the vulnerability and assessment
      if (vulnerabilityTargetIds.size > 0) {
        assessmentTargets = assessmentTargets.filter((target) => {
          return vulnerabilityTargetIds.has(target?.id);
        });
      }

      return assessmentTargets;
    },
    setData: (data) => {
      if (!data) {
        targetsDataGrid.setData([]);
        return;
      }
      targetsDataGrid.setData(data);
    },
    dependencies: [item?.id, filterByVulnerability?.id],
  });

  const targetsDataGrid = useTargetsGrid({
    query: null,
    organizationID,
    deleteFunction,
    deleteFunctionWithoutQueue: true,
    detailsComponent: <TargetDetails assessmentID={item?.id} />,
    createItemModalHeader: "Attach a Target",
    createResourceButtonText: "Link",
    deleteButtonText: "Unlink",
    createResourceComponent: (
      <CreateLinking
        parentTypename={"Assessment"}
        typename={"Target"}
        item={item}
        form={<CreateTarget />}
        grid={<TargetsGrid />}
        queryConfig={{ normalizeData: undefined }}
        createFunction={(itemToLink, item) =>
          createAssessmentTargetLink({
            assessment: item,
            target: itemToLink,
            organizationID,
          })
        }
      />
    ),
    resetFunction,
    isLoading,
  });

  return targetsDataGrid;
};

const { getQuery: getVulnerabilityQuery } = generateGraphql("Vulnerability", ["targets"], {
  targets: `(limit: 1000) {
      items {
        id
        target {
          id
        }
      }
    }`,
});
