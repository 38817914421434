import { modules, resources } from "@rivial-security/role-utils";

import Dashboard from "../../../utils/GenericComponents/Dashboard";
import DashboardCard from "../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import EvidenceAllowUnauthenticatedSubmissionsSwitch from "../../Compliance/Evidence/components/EvidenceAllowUnauthenticatedSubmissionsSwitch";
import EvidenceDueDate from "../../Compliance/Evidence/components/EvidenceDueDate";
import EvidenceFrequency from "../../Compliance/Evidence/components/EvidenceFrequency";
import EvidenceType from "../../Compliance/Evidence/components/EvidenceType";
import Grid from "@mui/material/Grid";
import React from "react";
import TabbedControlsCard from "../../Compliance/Evidence/components/TabbedControlsCard";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useDetailsCard } from "../../../hooks/views/useDetailsCardV2";
import { withDetailsPage } from "../../../utils/Context/withDetailsPage";

/**
 * Custom details component for Evidence Templates.
 *
 * @param {string} organizationID - ownerGroup to use for creating/querying resources
 * @param {string} module - module to use for creating/querying resources
 * @param {string} resource - resource to use for creating/querying resources
 * @param {boolean} disableRoleChecking - if TRUE will disable role checking
 * @param {string} itemId - id of the evidence template
 * @param {object} item - optionally pass an item directly with id instead of itemId
 * @param {object} props - any additional props passed through to the details card
 * @return {JSX.Element}
 */
const EvidenceTemplateDetails = ({
  organizationID,
  module = modules.ADMINISTRATOR,
  resource = resources.EVIDENCE_TEMPLATE,
  disableRoleChecking = false,
  itemId,
  item,
  ...props
}) => {
  const typename = "Evidence";
  const queryFields = [
    "name",
    "frequency",
    "type",
    "task",
    "allowUnauthenticatedSubmissions",
    "createdAt",
    "notes",
    "controls",
    "riskControls",
  ];
  const nestedFields = {
    controls: `(limit: 500) { nextToken items { id control { id name statementNumber inPlace controlControlSetId controlSet { id name } } } }`,
    riskControls: `(limit: 500) { nextToken items { id system { id name riskControlOverrides { enabledFields riskControlId costOfControl strengthRating implementationRating implementationDetails } } riskControl { id name statementNumber controlCategoryID controlCategory { id name } } } }`,
    notes: `{ id type ownerGroup author timeStamp content tag observationID }`,
  };
  const fields = [
    "name",
    "status",
    "frequency",
    "dueDate",
    "type",
    "task",
    "enabled",
    "allowUnauthenticatedSubmissions",
    "tags",
  ];

  const customFields = [
    {
      field: "frequency",
      component: <EvidenceFrequency module={module} resource={resource} disableRoleChecking={disableRoleChecking} />,
    },
    {
      field: "type",
      component: <EvidenceType module={module} resource={resource} disableRoleChecking={disableRoleChecking} />,
    },
    {
      field: "task",
      inputType: "textarea",
    },
    {
      field: "allowUnauthenticatedSubmissions",
      component: <EvidenceAllowUnauthenticatedSubmissionsSwitch />,
    },
    {
      field: "dueDate",
      component: <EvidenceDueDate />,
      isDynamicCustomField: true,
    },
  ];

  const fieldNameDictionary = {
    dueDate: "Expiration Date",
    enabled: "Automations",
    allowUnauthenticatedSubmissions: "Unauthenticated Submissions",
  };

  const { updateMutation, getQuery, onUpdate } = generateGraphql(typename, queryFields, nestedFields);

  const detailsConfig = {
    fields,
    customFields,
    updateMutation: updateMutation,
    fieldNameDictionary,
  };

  const queryConfig = {
    query: getQuery,
    itemId: itemId || item?.id,
    onUpdateItemSubscription: onUpdate,
  };

  const details = useDetailsCard({
    organizationID,
    module,
    resource,
    disableRoleChecking,

    queryConfig,
    detailsConfig,
    config: { enableNotes: true, header: "Evidence Template Details" },
    tableDisplay: true,
    ...props,
  });

  const display = (
    <Dashboard id="evidence-details-dashboard" resetFunction={details.reset}>
      <Grid container spacing={2}>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <DashboardCard icon={"icon-list"} title={"General Information"} style={{ height: "100%" }}>
            {details.display}
          </DashboardCard>
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          {details.item && (
            <TabbedControlsCard
              organizationID={organizationID}
              module={modules.ADMINISTRATOR}
              resource={resources.EVIDENCE_TEMPLATE}
              disableRoleChecking={disableRoleChecking}
              isTemplate={true}
              item={details.item}
              resetFunction={details.reset}
            />
          )}
        </Grid>
      </Grid>
    </Dashboard>
  );

  return display;
};

export default withDetailsPage(EvidenceTemplateDetails);
