import React from "react";
import { convertCamelCaseToSentence } from "@rivial-security/func-utils";

/**
 * @description Display report module field
 * @param {object} item - report
 * @returns {JSX.Element}
 * @constructor
 */
const ReportModule = ({ item }) => <div>{item?.module && convertCamelCaseToSentence(item?.module)}</div>;

export default ReportModule;
