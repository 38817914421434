import { createWidgetTag, REPORT_JOB_TYPE } from "@rivial-security/report-utils";
import { WIDGET_TYPE } from "@rivial-security/widget-utils";
import { fargateApi } from "../../../../utils/Functions/FargateApi/fargateApi";
import { createJob } from "../../../OrganizationManager/Jobs/functions/createJob";

/**
 * Converts the Widget configuration and Query results into an SFDT element
 * and inserts it into the Report at the Cursor location
 * @param {string} organizationID - currently selected organization
 * @param {object} widget - widget database information
 * @param {object} ref - the reference to the document editor
 * @param {function} onJobStarted - callback to track widget creation process
 */
export const createSingleWidget = async ({ organizationID, widget, ref, onJobStarted }) => {
  const config = JSON.parse(widget?.config ?? "{}");
  switch (widget?.type) {
    // Generate any static widget in the frontend
    case WIDGET_TYPE.STATIC_TEXT:
      ref.documentEditor.editor.insertText(config?.text ?? "[Invalid Text Widget]");
      break;
    case WIDGET_TYPE.IMAGE:
      //TODO: Reimplement image widget in fargate (with resizing)
      ref.documentEditor.editor.insertImage(config?.image);
      break;
    case WIDGET_TYPE.TAG:
      ref.documentEditor.editor.insertText(createWidgetTag(widget));
      break;

    //Attempt to create more complex widgets on fargate
    default:
      // - create a job to use for tracking widget creation progress
      const job = await createJob({
        name: `Widget Job ${new Date().toLocaleString()}`,
        status: "started",
        ownerGroup: organizationID,
        type: REPORT_JOB_TYPE.RENDER_WIDGET,
      });

      onJobStarted && onJobStarted({ job });

      // - send widget creation request to fargate
      //TODO: modify fargate api to mark job as failed if cannot connect to fargate
      const response = await fargateApi({
        input: {
          route: "widget",
          widget,
          organizationID,
          job,
        },
      });
  }
};
