import { useState, useEffect } from "react";
import { getDefaultDataGridSortModel } from "./functions/getDefaultDataGridSortModel";

/**
 * Holds the sort info in state for 'controlled' sorting, prioritizes grid view info as the source of truth
 * @param {object} targetView - the data grid view that the user would like to see, either from preferences or adjusted through the UI
 * @param {object[]} fields - fields prop passed through from useDataGrid
 * @return {{sortModel: object}}
 */
export const useDataGridSorting = ({ targetView, fields } = {}) => {
  const [sortModel, setSortModel] = useState([]);

  /**
   * When the target view changes, update the sort model, if no sort model
   * is available, use the default sort model based on fields
   */
  useEffect(() => {
    let newSortModel = targetView?.sortModel;
    if (!newSortModel) {
      newSortModel = getDefaultDataGridSortModel({ fields });
    }

    setSortModel(newSortModel);
  }, [targetView?.sortModel]);

  return {
    sortModel,
  };
};
