import { useKnowBe4PhishingDetails } from "../hooks/useKnowBe4PhishingDetails";
import { withDetailsPage } from "../../../../../utils/Context/withDetailsPage";

/**
 * @description Display KnowBe4 Phishing Test details component
 * @param {object} props
 * @constructor
 */
const KnowBe4PhishingDetails = (props) => useKnowBe4PhishingDetails(props).display;

export default withDetailsPage(KnowBe4PhishingDetails, {
  title: "KnowBe4 Phishing Details",
});
