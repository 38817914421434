import React from "react";
import FormattedText from "./views/FormattedText";

/**
 * Field allowing to display changes between source and incoming content
 * @see made to use as custom field in {@link useTable}
 * @param {object} item - the information for the entire row that the field is in
 * @param {string} fieldInfo.source - the original information
 * @param {string} fieldInfo.incoming - the new information
 * @param {string} fieldInfo.resolution - the action taken on the change (used for proper formatting)
 * @returns {HTMLElement} the field to display based on selected the resolution for the conflict
 */
export const CompareField = ({ item, fieldInfo }) => {
  let sourceHtml, incomingHtml;
  let infoThesame = false;
  let infoMissing = false;

  //Handle cases with missing info
  if (!fieldInfo || (!fieldInfo.source && !fieldInfo.incoming)) {
    //pass - nothing to display if both source and incoming fields are missing
  } else if (fieldInfo.source && !fieldInfo.incoming) {
    sourceHtml = <FormattedText text={fieldInfo.source} />;
    infoMissing = true;
  } else if (!fieldInfo.source && fieldInfo.incoming) {
    incomingHtml = <FormattedText text={fieldInfo.incoming} textType={"added"} />;
    infoMissing = true;
  } else if (fieldInfo.source.toString() === fieldInfo.incoming.toString()) {
    //if info is the same in both instances show the source
    sourceHtml = <FormattedText text={fieldInfo.source} textType={"info"} />;
    incomingHtml = undefined;
    infoThesame = true;
  }

  //Handle by resolution cases
  if (fieldInfo && fieldInfo.resolution && fieldInfo.resolution === "replace") {
    //If field is empty it will be shown as an underscore for the replace operation
    sourceHtml = <FormattedText text={fieldInfo.source} textType={"deleted"} textDefault={"_"} />;
    incomingHtml = <FormattedText text={fieldInfo.incoming} textType={"added"} textDefault={"_"} />;
  } else if (fieldInfo && fieldInfo.resolution && fieldInfo.resolution === "delete") {
    sourceHtml = <FormattedText text={fieldInfo.source} textType={"deleted"} textDefault={"_"} />;
  } else if (fieldInfo && fieldInfo.resolution && fieldInfo.resolution == "update") {
    if (!infoThesame) {
      //Only update if incoming information is present
      if (!fieldInfo.incoming || fieldInfo.incoming === "") {
        sourceHtml = <FormattedText text={fieldInfo.source} textDefault={""} />;
      } else {
        sourceHtml = <FormattedText text={fieldInfo.source} textType={"deleted"} textDefault={""} />;
      }
      incomingHtml = <FormattedText text={fieldInfo.incoming} textType={"added"} textDefault={""} />;
    }
  } else if (fieldInfo && fieldInfo.resolution && fieldInfo.resolution == "ignore") {
    sourceHtml = <FormattedText text={fieldInfo.source} textDefault={""} />;
    incomingHtml = <FormattedText text={fieldInfo.incoming} textType={"deleted"} textDefault={""} />;
  } else {
    if (!infoThesame && !infoMissing) {
      sourceHtml = <FormattedText text={fieldInfo.source} textDefault={""} />;
      incomingHtml = <FormattedText text={fieldInfo.incoming} textType={"added"} textDefault={""} />;
    }
  }

  return (
    <div>
      {sourceHtml ? sourceHtml : ""}
      {incomingHtml ? <br /> : ""}
      {incomingHtml ? incomingHtml : ""}
    </div>
  );
};
