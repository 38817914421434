import React, { PropsWithChildren, ReactElement, useContext, useEffect, useMemo, useState } from "react";

import { DetailsBarState } from "@application/Layout/components/LayoutSidebar/types/useDetailsBar";
import { IAuthenticatorProps } from "aws-amplify-react/lib-esm/Auth/Authenticator";
import { LayoutPreferences } from "@application/Layout/hooks/types/useLayoutPreferences";
import { LayoutToastsHook } from "@application/Toasts/types/LayoutToastsHook";
import { UseHelpCenterReturn } from "@views/HelpCenter/hooks/types/useHelpCenter";
import { UsePreferencesReturn } from "@hooks/views/usePreferences/types/usePreferences";
import { emptyLayoutToastsHook } from "@application/Toasts/emptyLayoutToasts";
import { useDetailsBar } from "@application/Layout/components/LayoutSidebar/hooks/useDetailsBar";
import { useHelpCenter } from "@views/HelpCenter/hooks/useHelpCenter";
import { useLayoutPreferences } from "@application/Layout/hooks/useLayoutPreferences";
import { useLayoutToasts } from "@application/Toasts/LayoutToasts";
import { useWindowResize } from "@application/Layout/hooks/useWindowResize";
export interface UIContextType extends LayoutToastsHook {
  // Existing properties
  layoutToastsHook: LayoutToastsHook;
  safeDelete: boolean;
  setSafeDelete: (value: boolean) => void;
  loaderStyle: string;
  setLoaderStyle: (style: string) => void;
  defaultRoute: string;
  setDefaultRoute: (route: string) => void;
  detailsBar: DetailsBarState | null;
  helpCenter: UseHelpCenterReturn | null;
  isMobile: boolean;
  width: number;
  currentTour: string | null;
  setCurrentTour: (tour: string | null) => void;
  getPreference: UsePreferencesReturn["getPreference"];
  setPreference: UsePreferencesReturn["setPreference"];

  /** this comes from aws-react-amplify */
  onStateChange: IAuthenticatorProps["onStateChange"] | null;
  // New properties from the UIContext
  toasts: LayoutToastsHook["toasts"];
  setToasts: LayoutToastsHook["setToasts"];
  addToast: LayoutToastsHook["addToast"];
  updateToast: LayoutToastsHook["updateToast"];
  markAllToastsAsSeen: LayoutToastsHook["markAllToastsAsSeen"];
  newNotificationAmount: LayoutToastsHook["newNotificationAmount"];
  totalNotificationAmount: LayoutToastsHook["totalNotificationAmount"];
  totalActivityAmount: LayoutToastsHook["totalActivityAmount"];
  newActivityAmount: LayoutToastsHook["newActivityAmount"];
}

export const UIContext = React.createContext<UIContextType>({
  layoutToastsHook: emptyLayoutToastsHook,
  toasts: [],
  display: () => <></>,
  removeToast: () => Promise.resolve(),
  clearToasts: () => Promise.resolve(),
  setToasts: () => undefined,
  addToast: () => undefined,
  updateToast: () => undefined,
  markAllToastsAsSeen: () => Promise.resolve(undefined),
  newNotificationAmount: 0,
  totalNotificationAmount: 0,
  newActivityAmount: 0,
  totalActivityAmount: 0,
  safeDelete: true,
  setSafeDelete: () => undefined,
  defaultRoute: "/",
  isMobile: false,
  loaderStyle: "rivialLoader",
  onStateChange: null,
  setLoaderStyle: function (): void {
    throw new Error("Function not implemented.");
  },
  setDefaultRoute: function (): void {
    throw new Error("Function not implemented.");
  },
  detailsBar: null,
  helpCenter: null,
  width: 0,
  currentTour: null,
  setCurrentTour: () => undefined,
  getPreference: () => undefined,
  setPreference: () => Promise.resolve({}),
});

export type UIContextProviderProps = PropsWithChildren & { onStateChange: IAuthenticatorProps["onStateChange"] };
export const UIContextProvider = ({ onStateChange, children }: UIContextProviderProps): ReactElement => {
  const layoutToastsHook = useLayoutToasts();
  const { isMobile, width } = useWindowResize();
  const detailsBar: DetailsBarState = useDetailsBar();
  const helpCenter = useHelpCenter({ isMobile });
  const {
    safeDelete,
    setSafeDelete,
    loaderStyle,
    setLoaderStyle,
    defaultRoute,
    setDefaultRoute,
    getPreference,
    setPreference,
  }: LayoutPreferences = useLayoutPreferences();
  const [currentTour, setCurrentTour] = useState<string | null>(null);

  useEffect(() => {
    if (isMobile === true) {
      detailsBar.setLockFullWindow(true);
    } else {
      detailsBar.setLockFullWindow(false);
    }
  }, [isMobile, detailsBar]);

  const uiContext = useMemo(() => {
    return {
      ...layoutToastsHook,
      layoutToastsHook,
      safeDelete,
      setSafeDelete,
      loaderStyle,
      setLoaderStyle,
      defaultRoute,
      setDefaultRoute,
      detailsBar,
      helpCenter,
      isMobile,
      width,
      currentTour,
      setCurrentTour,
      getPreference,
      setPreference,
      onStateChange,
    };
  }, [
    layoutToastsHook,
    safeDelete,
    setSafeDelete,
    loaderStyle,
    setLoaderStyle,
    defaultRoute,
    setDefaultRoute,
    detailsBar,
    helpCenter,
    isMobile,
    width,
    currentTour,
    getPreference,
    setPreference,
    onStateChange,
  ]);

  return <UIContext.Provider value={uiContext}>{children}</UIContext.Provider>;
};

export function useUIContext(): UIContextType {
  const uiContext = useContext(UIContext);
  if (uiContext === undefined) throw new Error("UI Context must be used under a UIContextProvider");
  return uiContext;
}
