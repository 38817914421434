import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { useEffect } from "react";
import { useForm } from "../../../../../hooks/views/useForm";

/**
 * @description General information for creating an assessment
 * @param {object} input - input object
 * @return {JSX.Element}
 * @constructor
 */
const AddAssessmentGeneralInfo = ({ input }) => {
  const statusArray = ["scheduled", "in-progress", "done"];
  const typeArray = ["internal", "external"];

  const form = useForm({
    fieldConfig: {
      name: {
        label: "Assessment name",
        tooltip: "The name of the new Assessment",
        required: true,
        defaultValue: input?.name
          ? input.name
          : `Vulnerability Security Testing Q${Math.ceil(
              (new Date().getMonth() + 1) / 3,
            )}, ${new Date().getFullYear()}`,
      },
      assessmentDate: {
        inputType: "date",
        label: "Assessment Date",
        tooltip: "Select a date when the Assessment was performed",
        defaultValue: input?.assessmentDate ? input.assessmentDate : new Date(),
        dateConfig: {
          maxDate: new Date(),
        },
        required: true,
      },
      status: {
        label: "Status",
        tooltip: "Select status for new Assessment",
        inputType: "dropdown",
        defaultValue: input?.status ? input.status : "scheduled",
        dropdownConfig: {
          data: statusArray.map((status) => {
            return {
              value: status,
              text: convertCamelCaseToSentence(status),
            };
          }),
        },
      },
      type: {
        label: "Type",
        tooltip: "Select type for new Assessment",
        inputType: "dropdown",
        defaultValue: input?.type ? input.type : "internal",
        dropdownConfig: {
          data: typeArray.map((type) => {
            return {
              value: type,
              text: convertCamelCaseToSentence(type),
            };
          }),
        },
      },
    },
    disableSubmitButton: true,
  });

  useEffect(() => {
    Object.assign(input, { ...form?.input });
  }, [JSON.stringify(form?.input)]);

  return form.display;
};

export default AddAssessmentGeneralInfo;
