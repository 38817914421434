import { modules, resources } from "@rivial-security/role-utils";

import CreateNewRisk from "../components/AddRisksToOrganization/CreateNewRisk";
import { DETAILS_TYPES } from "../../../../../hooks/views/useGrid/enums/DETAILS_TYPES";
import KeyRiskIndicatorDetails from "../components/KeyRiskIndicatorDetails";
import React from "react";
import { deleteRisk } from "../functions/deleteRisk";
import { generateGraphql } from "@rivial-security/generategraphql";
import { getIcon } from "../../../../../utils/Functions/Icon/getIcon";
import { mergeAdditionalFields } from "../../../../../hooks/views/useGrid/functions/mergeAdditionalFields";
import { realTimeRiskGQL } from "@rivial-security/risk-calc-utils";
import { useGridCard } from "../../../../../hooks/views/useGridCard";

const { updateMutation: updateRisk } = generateGraphql("Risk");

/**
 * @deprecated - use useKeyRiskIndicatorsDataGrid instead
 * Displays a list of Key Risk Indicators for an Organization's Risk Config
 * @param organizationID
 * @param additionalFields
 * @param gridConfig
 * @param queryConfig
 * @param cardConfig
 */
export const useKeyRiskIndicatorsGrid = ({
  organizationID,
  additionalFields = [],
  gridConfig = {},
  queryConfig = {},
  cardConfig = {},
}) => {
  const module = modules.RISK;
  const resource = resources.KEY_RISK_INDICATOR;

  const fieldNameDictionary = {
    id: "ID",
    name: "Name",
    classification: "Classification",
    description: "Description",
    annualRateOfOccurrence: "Annual Rate of Occurrence",
    annualRateOfOccurrenceMax: "ARO Max",
    confidenceIntervalLower: "Confidence Interval Lower",
    confidenceIntervalUpper: "Confidence Interval Upper",
    confidenceInterval: "Confidence Interval",
    costOfControls: "Cost of Controls",
    controlEffectiveness: "Control Effectiveness",
  };

  queryConfig = {
    query: realTimeRiskGQL.risksByOwnerGroup_systemAccordion,
    variables: { ownerGroup: organizationID },
    organizationID,
    ...queryConfig,
  };

  const fields = [
    {
      name: "name",
    },
  ];
  mergeAdditionalFields({ fields, additionalFields });

  gridConfig = {
    header: "Key Risk Indicators",
    fields,
    options: ["details", "delete"],
    deleteFunction: deleteRisk,
    route: "#/risk/risk_config/risks/",
    fieldNameDictionary,
    detailsComponent: (
      <KeyRiskIndicatorDetails organizationID={organizationID} fieldNameDictionary={fieldNameDictionary} />
    ),
    createResourceComponent: <CreateNewRisk organizationID={organizationID} />,
    updateMutation: updateRisk,
    typename: "Key Risk Indicator",
    detailsType: DETAILS_TYPES.MODAL,
    enableContextMenu: true,
    enableSearch: true,
    persistenceUUID: "risk-key-risk-indicator-grid",
    sortSettings: {
      columns: [{ field: "name", direction: "Ascending" }],
    },
    ...gridConfig,
  };

  const riskQueryCard = useGridCard({
    queryConfig,
    gridConfig,
    roleConfig: {
      module,
      resource,
    },
    cardConfig: {
      title: "Key Risk Indicators",
      headerIcon: getIcon("ant-design:fire-outlined"),
      ...cardConfig,
    },
  });

  return { ...riskQueryCard };
};
