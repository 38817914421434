import React from "react";
import { useAuditDetails } from "../../../../views/Compliance/Audit/hooks/useAuditDetails";
import { useAuditDataGrid } from "../../../../views/Compliance/Audit/hooks/useAuditDataGrid";
import { useCreateAudit } from "../../../../views/Compliance/Audit/hooks/useCreateAudit/useCreateAudit";

const CreateAudit = React.lazy(() => import("../../../../views/Compliance/Audit/components/CreateAudit"));

const AuditDataGrid = React.lazy(() => import("../../../../views/Compliance/Audit/components/AuditDataGrid"));

const AuditDetails = React.lazy(() => import("../../../../views/Compliance/Audit/components/AuditDetails"));

export const auditDefinition = {
  routes: {
    grid: "continuous_compliance/audits",
    details: "continuous_compliance/audits",
    helpCenter: "audits",
  },

  //[APPEARANCE]
  icon: "simple-line-icons:film",
  gridHook: useAuditDataGrid,
  gridComponent: <AuditDataGrid />,
  formHook: useCreateAudit,
  formComponent: <CreateAudit />,
  detailsHook: useAuditDetails,
  detailsComponent: <AuditDetails />,
};
