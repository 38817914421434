"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRiskScore = void 0;
const appsync_utils_1 = require("@rivial-security/appsync-utils");
const getRiskScores_enterpriseRisks_1 = require("./getRiskScores_enterpriseRisks");
const _realTimeRiskGQL_1 = require("../graphql/_realTimeRiskGQL");
/**
 * Calculates the Risk Ratings for a single system using monteCarloResults
 *
 * @param {Object} system - Required
 * @param {String} organizationID - Required
 * @param {MonteCarloCalculationResult} monteCarloResults - Results for a single system
 * @param {Object} [riskMapping] - Optional
 * @param {RatingScale} [ratingScale] - Optional
 * @returns {Promise<{isLoading: boolean, systemName: *, greatestEnterpriseRisk, enterpriseRisks: Array}>}
 */
const getRiskScore = async ({ system, organizationID, monteCarloResults, riskMapping, ratingScale }) => {
    let isLoading = true;
    const organizationRiskMapping = riskMapping || (await (0, appsync_utils_1.GetQuery)(_realTimeRiskGQL_1.riskMappingGQL, organizationID).riskMapping);
    const systemName = system && system.name;
    const systemID = system && system.id;
    let greatestEnterpriseRisk = {};
    let enterpriseRisks = [];
    isLoading = false;
    const enterpriseRiskScores = await (0, getRiskScores_enterpriseRisks_1.getRiskScores_enterpriseRisks)({
        system,
        riskMapping: organizationRiskMapping,
        monteCarloResults,
        ratingScale,
    });
    if (enterpriseRiskScores) {
        enterpriseRisks = [...enterpriseRiskScores.enterpriseRisks];
        greatestEnterpriseRisk = enterpriseRiskScores.greatestEnterpriseRisk;
    }
    return {
        isLoading,
        systemName,
        greatestEnterpriseRisk,
        enterpriseRisks,
        systemID,
    };
};
exports.getRiskScore = getRiskScore;
