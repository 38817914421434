import { GenericDeleteGQL } from "../../../../utils/Functions/Graphql/GenericDeleteGQL";
import { InfoLogger } from "@utils/EventLogger";
import { QueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import { generateGraphql } from "@rivial-security/generategraphql";

export const deleteMetric = async (metricType) => {
  if (!metricType?.id) {
    return;
  }

  const { getQuery } = generateGraphql("Metric", ["__typename"]);

  return await QueryGetItem({ query: getQuery, itemId: metricType.id }).then(async (metricType) => {
    await GenericDeleteGQL({ objectToDelete: metricType });
    InfoLogger(`Metric ${metricType?.id} was Successfully Deleted`);
  });
};
