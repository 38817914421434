import { Alert } from "@mui/material";
import AutomationCooldownEnabled from "../../components/AutomationCooldownEnabled";
import AutomationCooldownPeriod from "../../components/AutomationCooldownPeriod";
import AutomationEnabled from "../../components/AutomationEnabled";
import AutomationJobsGrid from "../../components/AutomationJobsGrid";
import AutomationTriggerConditionsCustomField from "../../components/AutomationTriggerConditionsCustomField";
import ConfigureAutomation from "../../components/ConfigureAutomation";
import ConfigureTrigger from "../../components/ConfigureTrigger";
import Dashboard from "../../../../../utils/GenericComponents/Dashboard";
import DashboardCard from "../../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import Grid from "@mui/material/Grid";
import ManualAutomationButton from "../../components/ManualAutomationButton";
import PermissionsOverlay from "../../../../../utils/Overlays/PermissionsOverlay";
import React, { useEffect, useState } from "react";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useDetailsCard } from "../../../../../hooks/views/useDetailsCardV2";
import { useUIContext } from "@utils/Context/UIContext";
import { getAutomationTriggerType } from "./functions/getAutomationTriggerType";
import { AutomationTriggerType } from "@rivial-security/schema-types";
import { modules, resources } from "@rivial-security/role-utils";
import { ItemMutation } from "@rivial-security/appsync-utils";
import { isEqual } from "lodash";

/**
 * Displays details page for a single Automation
 * @param {object} input - the input object
 * @param {string} input.itemId - the automation id to locate it in the database
 * @param {boolean} input.isTemplate - if TRUE will display only templatable fields
 * @param {string} input.organizationID - the organization which holds the automation
 * @param {boolean} [input.disableRoleChecking] - if TRUE will disable the front end role check
 * @returns {{isLoading: *, setInputId: *, inputId: *, item: *, setIsLoading: *, tableDisplay: *, resetFunction: *, display: (*|JSX.Element), resetIndex: *, reset: function(): void, setItem: *}}
 */
export const useAutomationDetails = ({ itemId, isTemplate, organizationID, disableRoleChecking = false }) => {
  const typename = resources.AUTOMATION;
  const resource = resources.AUTOMATION;
  const module = modules.ORGANIZATION_MANAGER;

  const fields = [
    "name",
    "description",
    "enabled",
    "triggers",
    "enableCooldown",
    "cooldownPeriod",
    "conditions",
    "automations",
  ];
  const nestedFields = {
    triggers: `{
      type
      webhookUrl
      schedule
    }`,
    conditions: `{
      typename
      rule
    }`,
    automations: `{
      id
      name
      type
      config
      description
    }`,
  };

  const { addToast } = useUIContext();
  const { getQuery, updateMutation } = generateGraphql(typename, fields, nestedFields);

  const queryConfig = {
    itemId: itemId,
    query: getQuery,
  };

  const [triggerType, setTriggerType] = useState(AutomationTriggerType.Manual);

  /**
   * Will return true if current automation data calls for the cooldown properties to not have affect
   * @returns {boolean} - TRUE if cooldown properties should be hidden
   */
  const shouldHideCooldownInput = () => {
    return (
      triggerType !== AutomationTriggerType.ResourceCreated && triggerType !== AutomationTriggerType.ResourceUpdated
    );
  };

  /**
   * Will return true if current automation data calls for the trigger condition properties to not have affect
   * @returns {boolean} - TRUE if trigger condition properties should be hidden
   */
  const shouldHideConditionsInput = () => {
    return shouldHideCooldownInput();
  };

  /**
   * Callback function for updating automation trigger when its changed
   * @param {boolean} newTrigger - the changed trigger object
   * @return {Promise<void>}
   */
  const onChangeTriggerCallback = async (newTrigger) => {
    const triggers = [newTrigger];
    setTriggerType(getAutomationTriggerType(triggers));

    const oldTrigger = details?.item?.triggers?.[0];
    if (isEqual(oldTrigger, newTrigger)) {
      return;
    }

    const result = await ItemMutation({
      mutation: updateMutation,
      input: {
        id: itemId,
        triggers,
      },
    });
    if (!result?.id) {
      addToast({
        icon: "danger",
        header: "Could not update automation trigger!",
      });
    }
  };

  const details = useDetailsCard({
    queryConfig,
    updateMutation,
    module,
    resource,
    tableDisplay: true,
    fields,
    fieldNameDictionary: {
      automations: "Steps",
      triggers: "Trigger",
    },
    customFields: [
      {
        field: "enabled",
        headerTooltip: "This Automation must be 'Enabled' in order to run",
        component: <AutomationEnabled />,
        isHidden: isTemplate,
      },
      {
        field: "triggers",
        headerTooltip: "Select the method that will Trigger this Automation",
        component: <ConfigureTrigger onChangeCallback={onChangeTriggerCallback} isTemplate={isTemplate} />,
      },
      {
        field: "enableCooldown",
        headerTooltip: "Configure this Automation so that it can only run again after a certain amount of time",
        component: <AutomationCooldownEnabled />,
        isHidden: shouldHideCooldownInput,
      },
      {
        field: "cooldownPeriod",
        headerTooltip:
          "Configure the number of minutes that an Automation will be in cooldown. Note: 1 hour = 60 minutes, 1 day = 1440 minutes, 1 week = 10080 minutes, 1 month = 43800 minutes",
        component: <AutomationCooldownPeriod />,
        isHidden: shouldHideCooldownInput,
      },
      {
        field: "conditions",
        headerTooltip: "Add Conditions that must be satisfied for this Automation to proceed",
        component: (
          <AutomationTriggerConditionsCustomField
            organizationID={organizationID}
            itemId={itemId}
            typename={typename}
            isTemplate={isTemplate}
          />
        ),
        isHidden: shouldHideConditionsInput,
      },
      {
        field: "automations",
        headerTooltip:
          "If all Conditions pass, this may run one or more Automation steps such as sending notifications, generating reports, or dispatching changes",
        component: (
          <ConfigureAutomation organizationID={organizationID} isTemplate={isTemplate} performUpdates={true} />
        ),
      },
    ],
  });

  useEffect(() => {
    setTriggerType(getAutomationTriggerType(details?.item?.triggers));
  }, [details?.item]);

  const display = (
    <PermissionsOverlay module={module} resource={resource} disableRoleChecking={disableRoleChecking}>
      <Dashboard
        title={details?.item?.name}
        headerButtons={
          details?.item?.triggers?.some((t) => t.type === "manual") && !isTemplate
            ? [<ManualAutomationButton key={0} id={itemId} name={details?.item?.name} />]
            : []
        }
        resetFunction={details?.resetFunction}
      >
        <Grid container spacing={2}>
          <Grid item xl={!isTemplate ? 7 : 12} lg={!isTemplate ? 7 : 12} md={12} sm={12} xs={12}>
            <DashboardCard title={"General Information"} style={{ height: "100%" }} icon={"icon-list"}>
              {details?.item?.enabled === true && (
                <Alert
                  severity="info"
                  style={{
                    marginBottom: "1em",
                  }}
                >
                  This automation is currently enabled - its triggers, conditions and steps cannot be edited.
                </Alert>
              )}
              {details.tableDisplay}
            </DashboardCard>
          </Grid>
          {!isTemplate && (
            <Grid item xl={5} lg={5} md={12} sm={12} xs={12}>
              <DashboardCard title={"History"} style={{ height: "75vh" }} icon={"icon-clock"}>
                <AutomationJobsGrid itemId={itemId} module={module} resource={resource} />
              </DashboardCard>
            </Grid>
          )}
        </Grid>
      </Dashboard>
    </PermissionsOverlay>
  );

  return {
    ...details,
    display,
  };
};
