import NoteUI from "../../../../utils/Notes/components/NoteUI";
import React, { useState } from "react";
import { useModal } from "../../../../hooks/views/useModal";
import AuditControlIsCompliant from "../components/AuditControlIsCompliant";
import { useMutation } from "../../../../hooks/graphql/useMutation/useMutation";
import { generateGraphql } from "@rivial-security/generategraphql";
import CreateObservation from "../../../Program/Observations/components/CreateObservation";
import { modules } from "@rivial-security/role-utils";
import Button from "@mui/material/Button";
import Note from "@mui/icons-material/Note";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { STEP_STATUS, usePleaseWaitModal } from "../../../../hooks/views/usePleaseWaitModal";
import { tryFunction } from "../../../../utils/Functions/tryFunction";

/**
 * Hook for bulk editing multiple Audit controls at once.
 * Allows adding Notes, Observations, and setting isCompliant for multiple controls
 * @param resetFunction
 * @param organizationID
 * @param audit
 * @param optimisticUpdate
 */
export const useEditAuditControls = ({ resetFunction, organizationID, audit, optimisticUpdate, auditFindings }) => {
  const [selectedControls, setSelectedControls] = useState([]);

  const notesModal = useModal(
    <span>
      Add a note to <strong>{selectedControls.length}</strong> Controls
    </span>,
    <CreateNote
      resetFunction={resetFunction}
      selectedControls={selectedControls}
      optimisticUpdate={optimisticUpdate}
    />,
    <EditOptionButton icon={<Note />} text={"Add Note"} selectedControls={selectedControls} />,
  );

  const observationModal = useModal(
    <span>
      Add an Observation for <strong>{selectedControls?.length}</strong> Controls
    </span>,
    <CreateObservation
      organizationID={organizationID}
      complianceControls={selectedControls}
      module={modules.COMPLIANCE}
      callback={() => tryFunction(auditFindings.resetFunction)}
      audit={audit}
      optimisticUpdate={optimisticUpdate}
    />,
    <EditOptionButton icon={<RemoveRedEyeIcon />} text={"Add Observation"} selectedControls={selectedControls} />,
  );

  const statusModal = useModal(
    <span>
      Set Compliance for <strong>{selectedControls?.length}</strong> Controls
    </span>,
    <SetCompliance
      resetFunction={resetFunction}
      selectedControls={selectedControls}
      optimisticUpdate={optimisticUpdate}
    />,
    <EditOptionButton icon={<FactCheckIcon />} text={"Update Compliance"} selectedControls={selectedControls} />,
  );

  return {
    notesModal,
    statusModal,
    observationModal,
    selectedControls,
    setSelectedControls,
  };
};

const EditOptionButton = ({ selectedControls = [], className, icon, text, ...props }) => {
  const isVisible = Array.isArray(selectedControls) && selectedControls.length > 0;

  return (
    <Button
      className={className}
      style={{ display: isVisible ? undefined : "none", fontSize: "0.8125rem" }}
      startIcon={icon}
      {...props}
    >
      {text}
    </Button>
  );
};

/**
 * UI for writing a Note that will get automatically attached to multiple AuditControlLinks
 * @param selectedControls
 * @param resetFunction
 * @param disableRoleChecking
 * @param toggleModal
 * @returns {JSX.Element}
 * @constructor
 */
const CreateNote = ({ selectedControls = [], resetFunction, disableRoleChecking, toggleModal }) => {
  const { updateMutation } = generateGraphql("AuditControlLink", ["notes"], {
    notes: "{ id type ownerGroup author timeStamp content tag observationID }",
  });

  const updateNotes = useMutation({
    mutation: updateMutation,
    field: "notes",
    disableRoleChecking: true,
  });

  const pleaseWaitModal = usePleaseWaitModal({
    subTitle: `Adding Notes to ${selectedControls?.length} items..`,
    progressTotal: selectedControls?.length,
    steps: selectedControls.map((control) => {
      return {
        text: `${control.statementNumber}: ${control.name}`,
        status: STEP_STATUS.WAITING,
      };
    }),
  });

  /**
   * Creates a note for all selected controls
   * @param input
   */
  const createNote = async (input) => {
    pleaseWaitModal.setModalIsOpen(true);
    let index = 0;
    for (const selectedControl of selectedControls) {
      pleaseWaitModal.setStepStatus(index, STEP_STATUS.IN_PROGRESS);
      const tempArray = selectedControl.notes;
      tempArray.push(input);

      await updateNotes.editItem({
        id: selectedControl.auditControlLink.id,
        notes: [...tempArray],
      });

      pleaseWaitModal.setStepStatus(index, STEP_STATUS.COMPLETE);
      pleaseWaitModal.incrementProgress();
      index++;
    }

    pleaseWaitModal.setModalIsOpen(false);
    resetFunction && resetFunction();
    toggleModal && toggleModal();
  };

  return (
    <div>
      {pleaseWaitModal.modal}
      <NoteUI disableTitle={true} createNote={createNote} disableRoleChecking={true} />
    </div>
  );
};

/**
 * UI for setting isCompliant field for multiple AuditControlLinks at once
 * @param selectedControls
 * @param resetFunction
 * @param toggleModal
 * @param optimisticUpdate
 * @returns {JSX.Element}
 * @constructor
 */
const SetCompliance = ({ selectedControls, resetFunction, toggleModal, optimisticUpdate }) => {
  return (
    <div>
      <AuditControlIsCompliant
        items={selectedControls}
        resetFunction={resetFunction}
        toggleModal={toggleModal}
        optimisticUpdate={optimisticUpdate}
        isBulkEdit={true}
      />
    </div>
  );
};
