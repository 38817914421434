import React, { useEffect, useState } from "react";

import { ErrorLogger } from "@utils/EventLogger";
import { ListGroupItem } from "reactstrap";
import { LoadingSpinner } from "../../../../../../../utils/LoadingComponents/LoadingSpinner";
import { MITRE_ATTACK_RELATIONSHIP_TYPES } from "../../../../../../Tools/MitreAttack/enums/mitreAttackRelationshipTypes";
import ThreatDetails from "../../../../../../Response/Threats/components/ThreatDetails";
import UnderlinedTooltip from "../../../../../../../utils/Tooltips/UnderlinedTooltip";
import { getTechniqueById } from "../../../../../../Tools/MitreAttack/Techniques/functions/getTechniqueById";
import { listMitreAttackRelationships } from "../../../../../../Tools/MitreAttack/functions/listMitreAttackRelationships";
import { useModal } from "../../../../../../../hooks/views/useModal";

/**
 * @description Display list of Mitre Attack mitigations for a threat component
 * @param {object} item - Mitre Attack Threat item
 * @param {string} organizationID - selected organization ID
 * @returns {JSX.Element}
 * @constructor
 */
const ControlCategoryThreat = ({ item }) => {
  const [technique, setTechnique] = useState(null);
  const [mitigations, setMitigations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const threatName = technique?.stix?.name || item?.name || "Technique Details";

  useEffect(() => {
    try {
      if (item?.techniqueID) {
        setIsLoading(true);
        getTechniqueById({
          id: item?.techniqueID,
        })
          .then((data) => {
            setTechnique(data);
            listMitreAttackRelationships({
              relationshipType: MITRE_ATTACK_RELATIONSHIP_TYPES.MITIGATES,
              targetRef: data?.parent?.stix?.id || data?.stix?.id,
            }).then((data) => {
              if (Array.isArray(data)) setMitigations(data);
            });
          })
          .finally(() => setIsLoading(false));
      }
    } catch (e) {
      ErrorLogger("Cannot get technique", e);
    }
  }, [item?.techniqueID]);

  const displayThreat = useModal(
    "Threat Details",
    <ThreatDetails item={item} />,
    <span>
      <UnderlinedTooltip text={threatName} title={`Click to view full Technique details`}>
        {threatName}
      </UnderlinedTooltip>
    </span>,
    {
      width: "90vw",
    },
  );

  return (
    <ListGroupItem>
      <div style={{ marginBottom: "0.5em" }}>{displayThreat?.modalButton}</div>
      {isLoading ? (
        <div>
          <LoadingSpinner loadingMessage="Loading Mitigations.. " />
        </div>
      ) : (
        <>
          {Array.isArray(mitigations) && mitigations?.length > 0 && (
            <span>
              <ul style={{ display: "flex", flexWrap: "wrap" }}>
                {mitigations?.map((mitigation) => (
                  <li style={{ margin: "0 20px" }}>{mitigation?.source_object?.stix?.name}</li>
                ))}
              </ul>
            </span>
          )}
        </>
      )}
    </ListGroupItem>
  );
};

export default ControlCategoryThreat;
