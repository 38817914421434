import React, { useEffect } from "react";
import OrganizationCheck from "../../../../../../../utils/Context/OrganizationCheck/OrganizationCheck";
import { useTable } from "../../../../../../../hooks/views/useTable";
import BusinessRiskItem from "./BusinessRiskItem";

const BusinessRisksBody = ({ organizationID, item }) => {
  const fieldNameDictionary = {
    riskRating: "Risk Rating",
    keyRiskIndicator: "Greatest Key Risk Indicator",
    residualRisk: "Residual Risk",
  };

  const tableHook = useTable({
    data: [],
    fields: ["name", "keyRiskIndicator", "residualRisk", "riskRating"],
    fieldNameDictionary,
    rowComponent: <BusinessRiskItem />,
    config: { borderless: true },
    disableRoleChecking: true,
  });

  useEffect(() => {
    if (item && item.businessRisks) {
      tableHook.setData(
        [...item.businessRisks].sort((a, b) => {
          return b.residualRisk - a.residualRisk;
        }),
      );
    }
  }, [item]);

  return (
    <div>
      <strong>Business Risks</strong>
      <hr />
      {item && item.businessRisks && item.businessRisks.length > 0
        ? tableHook.display
        : "There are no Business Risks associated with this Enterprise Risk"}
    </div>
  );
};

const BusinessRisks = (props) => {
  return (
    <OrganizationCheck {...props}>
      <BusinessRisksBody />
    </OrganizationCheck>
  );
};

export default BusinessRisks;
