import { modules, resources } from "@rivial-security/role-utils";

import GenericFieldDropdown from "../../../../utils/GenericComponents/GenericFieldDropdown";
import React from "react";
import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { moduleTypes } from "../../moduleTypes";

/**
 * Displays the Module field for an Observation.
 * @param {object} item - The Observation to display the Module field for.
 * @param {function} updateItemById - The function to update the Observation in the parent component.
 * @param {object} ...props - The props to pass to GenericFieldDropdown.
 * @constructor
 */
const Module = ({ item, updateItemById, ...props }) => {
  const module = modules.GOVERNANCE;
  const resource = resources.OBSERVATION;
  const field = "module";

  const options = moduleTypes.map((type) => {
    return {
      text: convertCamelCaseToSentence(type),
      value: type,
    };
  });

  return (
    <GenericFieldDropdown
      item={item}
      resource={resource}
      field={field}
      mutation={generateGraphql("Observation", ["module"]).updateMutation}
      options={options}
      format="sentence"
      updateItemById={updateItemById}
      {...props}
      module={module}
    />
  );
};

export default Module;
