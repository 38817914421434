import { generateGraphql } from "@rivial-security/generategraphql";
import { useForm } from "../../../../../hooks/views/useForm";
import { useHistory } from "react-router-dom";
import useListQuery from "../../../../../hooks/graphql/useListQuery";
import { useOrganizationConfig } from "../../../../AdminPanel/Organizations/hooks/useOrganizationConfig";
import { useJobTracker } from "../../../../../hooks/graphql/useJobTracker/useJobTracker";
import { tryItemFunction } from "../../../../../utils/Functions/tryFunction";
import { isNonEmptyArray, isNullOrUndefined, tryParse } from "@rivial-security/func-utils";
import { useUIContext } from "@utils/Context/UIContext";
import { createAuditSubmitFunction } from "./functions/createAuditSubmitFunction";

/**
 * Form for starting a new Audit
 * @param {string} organizationID - the selected organization id
 * @param {function} [toggleModal] - function to toggle the parent modal if any
 * @param {boolean} [disableRedirectOnCreate = false] - if TRUE will not redirect user to the new Audit when it has been created
 * @param {function} [getNewItem] - callback for the data after creation
 * @returns {{display: *, input: {}}}
 */
export const useCreateAudit = ({ organizationID, toggleModal, getNewItem, disableRedirectOnCreate = false }) => {
  const { updateToast } = useUIContext();

  const { value: includeDisabledControls } = useOrganizationConfig({
    organizationID,
    key: "includeDisabledControlsInAudits",
  });

  const { listQuery } = generateGraphql("ControlSet", ["name"]);
  const controlSets = useListQuery({
    query: listQuery,
    organizationID,
    disableRoleChecking: true,
  });

  const history = useHistory();

  const jobTracker = useJobTracker({
    onStarted: async (job) => {
      return {
        id: job?.id,
        header: "Creating an audit...",
        icon: "spinner",
      };
    },
    onComplete: (job) => {
      const audit = tryParse(job?.result);
      if (!disableRedirectOnCreate) {
        setTimeout(() => {
          if (!isNullOrUndefined(audit)) {
            history.push(`/continuous_compliance/audits/${audit?.id}`);
          }
        }, 750);
      } else {
        tryItemFunction({ item: audit, getNewItem });
      }
      updateToast({
        id: job?.id,
        header: `Audit created successfully!`,
        icon: "success",
      });
    },
    onFailed: (job) => {
      updateToast({
        id: job?.id,
        header: `Failed to create an audit!`,
        icon: "danger",
      });
    },
  });

  return useForm({
    disableResetButton: true,
    toggleModal,
    submitFunction: async (item) => {
      await createAuditSubmitFunction({
        item,
        toggleModal,
        addJob: jobTracker?.addJob,
        includeDisabledControls,
        organizationID,
      });
    },
    organizationID,
    getNewItem,
    fieldConfig: {
      controlSetID: {
        inputType: "dropdown",
        label: "Control Framework",
        tooltip: "Select a Control Framework that you will be auditing",
        required: true,
        dropdownConfig: {
          data: isNonEmptyArray(controlSets?.list)
            ? controlSets.list.map(({ id, name }) => {
                return {
                  value: id,
                  text: name,
                };
              })
            : [],
        },
      },
      name: {
        label: "Name",
        tooltip: "Provide a name for this audit (ex. '2021 Controls Audit')",
        required: true,
      },
      auditDate: {
        label: "Audit Date",
        tooltip: "Provide an audit date",
        inputType: "date",
        defaultValue: new Date(),
      },
      importData: {
        label: "Import Existing Control Data",
        tooltip:
          "Pre-populate the Audit Control 'Compliance' and 'Notes' fields from an existing spreadsheet. Note: this spreadsheet can only populate fields for existing Controls",
        inputType: "spreadsheet",
        spreadsheetConfig: {
          headerObjects: [
            {
              title: "statementNumber",
              fieldTitle: "statementNumber",
              type: "text",
            },
            {
              title: "isCompliant",
              fieldTitle: "isCompliant",
              type: "text",
            },
            {
              title: "notes",
              fieldTitle: "notes",
              type: "text",
            },
          ],
        },
      },
    },
  });
};
