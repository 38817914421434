import { DeleteVendorCertificationControlLink } from "../../VendorCertification/functions/DeleteVendorCertificationControlLink";
import { DeleteVendorControlDocumentLink } from "./DeleteVendorControlDocumentLink";
import { ErrorLogger } from "@utils/EventLogger";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { QueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 *
 * @param control {object} VendorSubControl object
 * @returns {Promise<null>}
 * @constructor
 */

export const DeleteVendorSubControls = async (control) => {
  if (!control || !control.id) {
    ErrorLogger("Error! Invalid Vendor Control input for DeleteVendorSubControls function!");
    return null;
  }

  if (control) {
    const { getQuery } = generateGraphql("VendorSubControl", ["certificationLinks", "documentLinks"], {
      certificationLinks: `(limit: 100) {
        items {
           id
          }
          nextToken
        }`,
      documentLinks: `(limit: 100) {
        items {
           id
          }
          nextToken
        }`,
    });

    const vendorSubControl = await QueryGetItem({
      query: getQuery,
      itemId: control && control.id,
    });

    // Delete all links to certifications
    if (
      vendorSubControl &&
      vendorSubControl.certificationLinks &&
      vendorSubControl.certificationLinks.items &&
      Array.isArray(vendorSubControl.certificationLinks.items)
    ) {
      for (const link of vendorSubControl.certificationLinks.items) {
        await DeleteVendorCertificationControlLink(link);
      }
    }

    // Delete all links to documents
    if (
      vendorSubControl &&
      vendorSubControl.documentLinks &&
      vendorSubControl.documentLinks.items &&
      Array.isArray(vendorSubControl.documentLinks.items)
    ) {
      for (const link of vendorSubControl.documentLinks.items) {
        await DeleteVendorControlDocumentLink(link);
      }
    }

    const { deleteMutation } = generateGraphql("VendorSubControl");

    await ItemMutation(deleteMutation, {
      id: vendorSubControl && vendorSubControl.id,
    });
  }
};
