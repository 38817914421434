import { modules, resources } from "@rivial-security/role-utils";

import Dashboard from "../../../../../utils/GenericComponents/Dashboard";
import DashboardCard from "../../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import DataLoader from "../../../../../utils/LoadingComponents/DataLoader";
import DocumentEditorModalButton from "../../../../Reports/DocumentEditor/components/DocumentEditorModalButton";
import Grid from "@mui/material/Grid";
import KnowBe4PhishingBarChart from "../components/KnowBe4PhishingBarChart";
import KnowBe4PhishingRecipients from "../components/KnowBe4PhishingRecipients";
import { Spinner } from "reactstrap";
import { TOOL_TYPES } from "@rivial-security/integration-utils";
import { getKnowBe4PhishingTest } from "../graphql/getKnowBe4PhishingTest";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useAccordion } from "../../../../../hooks/views/useAccordion/useAccordion";
import { useDetailsCard } from "../../../../../hooks/views/useDetailsCardV2";

/**
 * @description Display a KnowBe4 Phishing Test details hook
 * @param {object} item - Phishing test
 * @param {object} props
 * @returns {object} {{isLoading: boolean, setInputId: (function(((function(*): *)|*)): void), inputId: *, item: null, tableDisplay: *, display: *, resetIndex: number, reset: (function(): void), setItem: (function(((function(null): null)|null)): void)}}
 */
export const useKnowBe4PhishingDetails = ({ item, ...props }) => {
  const module = modules.TOOLS;
  const resource = resources.KNOW_BE_4_PHISHING;
  const toolType = TOOL_TYPES.KNOW_BE_4;
  const route = "#/integrations/know_be_4/phishing/";

  const detailsConfig = {
    fields: [
      "name",
      "status",
      "started_at",
      "duration",
      "delivered_count",
      "opened_count",
      "clicked_count",
      "reported_count",
      "replied_count",
      "attachment_open_count",
    ],
  };

  const configs = {
    header: "KnowBe4 Phishing Test Details",
    route,
  };

  const queryConfig = {
    queryExternalApi: {
      toolType,
      input: {
        query: getKnowBe4PhishingTest,
        queryInput: {
          id: item?.pst_id || item?.id || props?.itemId,
        },
      },
    },
  };

  const StartedAt = ({ item }) => (
    <div>{item?.started_at ? new Date(item?.started_at).toLocaleString() : "No Date"}</div>
  );

  const customFields = [
    {
      field: "started_at",
      component: <StartedAt />,
    },
  ];

  const details = useDetailsCard({
    queryConfig,
    detailsConfig,
    customFields,
    config: configs,
    module,
    resource,
    itemId: item?.pst_id,
    ...props,
  });

  const accordion = useAccordion({
    items: [
      {
        title: "General Information",
        subTitle: "KnowBe4 Phishing Test ",
        component: details.tableDisplay,
        icon: "icon-list",
        defaultExpanded: true,
      },
      {
        title: "Recipients",
        subTitle: "KnowBe4 Phishing Test Recipients",
        component: (
          <div style={{ height: "30em" }}>
            <KnowBe4PhishingRecipients itemId={item?.pst_id} gridDisplay={true} gridConfig={{ gridHeight: "100%" }} />
          </div>
        ),
        icon: "icon-people",
      },
    ],
    unmountOnExit: true,
  });

  const display = details?.item ? (
    <DataLoader isLoading={details?.isLoading} isEnoughData={details?.item}>
      <Dashboard
        resetFunction={details.reset}
        subTitle={details?.item?.name}
        headerButtons={[
          <DocumentEditorModalButton
            itemId={item?.pst_id || details?.item?.id}
            defaultTemplate={"PHISHING_TEMPLATE"}
            type={"Phishing Report"}
            module={"Tools"}
          />,
        ]}
      >
        <div>
          <Grid container spacing={2}>
            <Grid item lg={8} md={8} sm={12} xs={12}>
              {accordion.display}
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <DashboardCard title={"Phishing Results"}>
                <DataLoader
                  isLoading={details.isLoading}
                  isEnoughData={!isNullOrUndefined(details)}
                  dataMessage={"There is not enough Phishing data to generate a chart"}
                >
                  <KnowBe4PhishingBarChart item={details.item} />
                </DataLoader>
              </DashboardCard>
            </Grid>
          </Grid>
        </div>
      </Dashboard>
    </DataLoader>
  ) : (
    <>
      {" "}
      <Spinner style={{ marginRight: "0.5rem" }} color={"primary"} size="sm" />
      Loading Phishing Details...
    </>
  );

  return {
    display,
  };
};
