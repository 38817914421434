import { generateGraphql } from "@rivial-security/generategraphql";
import { deleteItem } from "../../../../../utils/Functions/Graphql/deleteItem";

/**
 * Deletes as riskControlCategory from the database with systemLinks and subControls
 *
 * NOTE: ALSO DELETES SUB CONTROLS, AND ALL LINKS
 *
 * Objects to be deleted:
 *
 *    - Risk Control Category (object)
 *      - SystemLinks (m-m
 *      - RiskControls (object)
 *        - RiskControlChangeLinks (m-m)
 *        - KPIRiskControlLinks (m-m)
 *        - RiskControlEvidenceLink (m-m)
 * @param {string} riskControlCategory - the control category to delete
 * @param {string} riskControlCategory.id - the ID of the control category to delete
 * @returns {Promise<*>}
 */
export const deleteRiskControlCategory = async (riskControlCategory) => {
  const { getQuery } = generateGraphql("RiskControlCategory", ["__typename", "systems", "subControls"], {
    systems: `(limit: 1000) { items { id __typename } }`,
    subControls: `(limit: 1000) {
      items {
        id
        __typename
        evidences (limit: 1000) {
          items {
            id
            __typename
          }
        }
        riskChangeLinks (limit: 1000) {
          items {
            id
            __typename
          }
        }
        keyPerformanceIndicators (limit: 1000) {
          items {
            id
            __typename
          }
        }
      }
    }`,
  });

  return await deleteItem(getQuery, riskControlCategory);
};
