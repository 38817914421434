import NumberChange from "../../../../utils/CustomFields/NumberChange";
import React from "react";

const OverallROIChange = ({ item }) => {
  const value = item && item.change && item.change.overallROIChange ? item.change.overallROIChange : 0;

  return (
    <>
      <NumberChange value={value} format="percent" />
    </>
  );
};

export default OverallROIChange;
