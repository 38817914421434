import { useForm } from "../../../../hooks/views/useForm";
import { useEffect } from "react";
import { listToDropdownData } from "../../../../utils/GenericComponents/GenericEditFieldV3/functions/listToDropdownData";
import { FORM_INPUT_TYPES } from "../../../../hooks/views/useForm/enums/FORM_INPUT_TYPES";

/**
 * Custom form for configuring Metric Automations
 * Exclusions: status = "Closed", status = "Expired"
 * @param {object} fetchConfig
 * @param {function} setFetchConfig'
 * @param {object[]} tools
 * @param {object} item
 * @param {object} props
 * @returns {JSX.Element}
 */
export const AutoFetchForm = ({ fetchConfig, setFetchConfig, tools, item, onInputIsValidChange, ...props }) => {
  const formHook = useForm({
    disableResetButton: true,
    disableSubmitButton: true,
    fieldConfig: {
      name: {
        label: "Name",
        inputType: "text",
        defaultValue: item?.automation?.name || "",
      },
      url: {
        inputType: FORM_INPUT_TYPES.URL,
        label: "API URL",
        tooltip: "The base URL for the API. The 'Path to Value' will be appended onto this during the API request",
        defaultValue: item?.automation?.fetchConfig?.url || "",
      },
      toolID: {
        label: "Tool",
        tooltip: "Select a Tool to use for the API and Authentication configuration",
        inputType: "dropdown",
        defaultValue: item?.toolID || "",
        dropdownConfig: {
          data: listToDropdownData(tools),
        },
      },
      jsonPath: {
        label: "Path to Value",
        tooltip: "A string representing property representing the desired value, or the path to get to the value.",
        defaultValue: item?.automation?.jsonPath || "",
      },
      // inclusions: {
      //   label: "Inclusions",
      //   tooltip: "A comma separated list of Field and Value combinations that will be Included when processing the data from Query Results. Takes precedence over Exclusions. Example: status = 'closed', status = 'expired', name = 'test'"
      // },
      exclusions: {
        label: "Exclusions",
        tooltip:
          "A comma separated list of Field and Value combinations that will be Excluded when processing the data from Query Results. Example: 'status=Closed,status=Error'",
      },
    },
    ...props,
  });

  //Checks if all fields are valid and changes internal valid state when
  useEffect(() => {
    onInputIsValidChange({ isValid: formHook?.submitDisabled !== true });
  }, [formHook?.submitDisabled]);

  useEffect(() => {
    if (formHook.input && JSON.stringify(formHook.input) !== JSON.stringify(fetchConfig)) {
      setFetchConfig({ ...formHook.input });
    }
  }, [formHook.input]);

  return <div>{formHook.display}</div>;
};
