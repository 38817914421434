import { modules, resources } from "@rivial-security/role-utils";

import GenericFieldDropdown from "../../../../utils/GenericComponents/GenericFieldDropdown";
import React from "react";
import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { recommendationRatings } from "../../recommendationRatings";

/**
 * Displays the Rating field for a RecommendationItem.
 * Allows the user to change the Rating
 * @param {object} item - recommendation item
 * @param {function} updateItemById - function to update item by id
 * @param {object} ...props - The props to pass to GenericFieldDropdown.
 * @returns {JSX.Element}
 * @constructor
 */
const Rating = ({ item, updateItemById, ...props }) => {
  const module = modules.GOVERNANCE;
  const resource = resources.RECOMMENDATION;
  const field = "rating";

  const options = recommendationRatings.map((rating) => {
    return {
      text: convertCamelCaseToSentence(rating),
      value: rating,
    };
  });

  return (
    <GenericFieldDropdown
      item={item}
      module={module}
      resource={resource}
      field={field}
      mutation={generateGraphql("RecommendationItem", [field]).updateMutation}
      options={options}
      format="sentence"
      updateItemById={updateItemById}
      {...props}
    />
  );
};

export default Rating;
