import { ErrorLogger, InfoLogger, WarningLogger } from "@utils/EventLogger";

import { DeleteSystemControlCategoryLink } from "./DeleteSystemControlCategoryLink";
import { GetQuery } from "../../../../../utils/Functions/Graphql/GetQuery";
import { ItemMutation } from "../../../../../utils/Functions/Graphql/ItemMutation";
import { deleteRiskControl } from "../../../RiskConfig/ControlCategories/components/SubControls/functions/deleteRiskControl";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 * @author: Jacob Blazina
 * @description: Deletes a RiskControlCategory
 * Edits:
 * - 09/15/2020: Refactor to use getItem and custom query
 *
 * @param riskControlCategory {Object}
 * @constructor
 */
const DeleteRiskControlCategory = async (riskControlCategory) => {
  if (!riskControlCategory || !riskControlCategory.id) {
    ErrorLogger("Could not delete Risk Control Category, invalid input");
    return;
  }

  // get full RiskControlCategory data
  InfoLogger("Querying Risk Control Category data");
  const { getQuery } = generateGraphql("RiskControlCategory", ["systems", "subControls"], {
    systems: `(limit: 100) { items { id } }`,
    subControls: `(limit: 500) { items { id } }`,
  });

  const riskControlCategoryQuery = await GetQuery({
    query: getQuery,
    variables: { id: riskControlCategory.id },
  });

  const systemControlCategoryLinks =
    riskControlCategoryQuery && riskControlCategoryQuery.systems && riskControlCategoryQuery.systems.items;
  const subControls =
    riskControlCategoryQuery && riskControlCategoryQuery.subControls && riskControlCategoryQuery.subControls.items
      ? riskControlCategoryQuery.subControls.items
      : [];

  // Delete Sub-Controls
  if (subControls && subControls.length > 0) {
    for (const subControl of subControls) await deleteRiskControl(subControl);
  }

  // Delete SystemControlCategoryLinks
  if (systemControlCategoryLinks && systemControlCategoryLinks.length > 0) {
    systemControlCategoryLinks.forEach((systemControlCategoryLink) => {
      DeleteSystemControlCategoryLink(systemControlCategoryLink).then(() =>
        InfoLogger(
          `System Control Category Link ${
            systemControlCategoryLink && systemControlCategoryLink.id
          } was Successfully Deleted`,
        ),
      );
    });
  } else {
    WarningLogger("No SystemControlCategoryLinks To Delete");
  }

  // Then delete the Risk Control Category
  const { deleteMutation: riskControlCategoryDelete } = generateGraphql("RiskControlCategory");
  ItemMutation(riskControlCategoryDelete, {
    id: riskControlCategoryQuery.id,
  }).then(() => InfoLogger("Risk Control Category was Successfully Deleted"));
};

export default DeleteRiskControlCategory;
