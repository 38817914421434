import { modules, resources } from "@rivial-security/role-utils";
import GenericEditFieldV3 from "../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";
import { updateEvidence } from "../graphql/updateEvidence";
import React, { useEffect, useState } from "react";
import { fieldContexts } from "../../../../enums/fieldContexts";
import { GENERIC_FIELD_TYPES } from "../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";

/**
 * Custom field controlling whether this evidence allows guest user (no user account) to submit evidence artifacts
 * @param {object} item - the evidence data
 * @param {string} module - the platform module for role checking
 * @param {string} resource - the platform resource for role checking
 * @param {string} fieldContext - appended automatically by UI wrappers, tells whether the field is in a details page or a grid
 * @param {function} updateItemById - function to update the grid once the value has changed
 * @return {JSX.Element} - the switch UI (a pre-configured generic edit field)
 */
const EvidenceAllowUnauthenticatedSubmissionsSwitch = ({
  item,
  module = modules.COMPLIANCE,
  resource = resources.EVIDENCE,
  fieldContext = fieldContexts.STANDALONE,
  updateItemById,
  ...props
}) => {
  // Determine whether to display unauthenticated users warning
  const [warning, setWarning] = useState(null);
  useEffect(() => {
    // Do not display a warning message when unauthenticated submissions are allowed
    const allowedUnauthenticatedSubmissions = item?.allowUnauthenticatedSubmissions ?? false;
    if (allowedUnauthenticatedSubmissions) {
      setWarning(null);
      return;
    }

    // Find any points of contact without user accounts
    const unauthenticatedPointsOfContact = [];
    const pointOfContactLinks = item?.pointOfContacts?.items;
    if (Array.isArray(pointOfContactLinks)) {
      for (const pointOfContactLink of pointOfContactLinks) {
        const pointOfContact = pointOfContactLink?.pointOfContact;
        const hasUserAccount = pointOfContact?.user?.id;
        if (!hasUserAccount) {
          unauthenticatedPointsOfContact.push(pointOfContact);
        }
      }
    }

    // Do not display warning message if there are no unauthenticated points of contact
    const unauthenticatedPointsOfContactExist = unauthenticatedPointsOfContact?.length > 0;
    if (!unauthenticatedPointsOfContactExist) {
      setWarning(null);
      return;
    }

    // Determine what the warning message should be
    const amountOfUnauthenticatedPointsOfContact = unauthenticatedPointsOfContact?.length;
    let message;
    if (amountOfUnauthenticatedPointsOfContact === 1) {
      message = "There is a single point of contact linked to this evidence that does not have a user account.";
    } else {
      message = `There are ${amountOfUnauthenticatedPointsOfContact} points of contact linked to this evidence that do not have a user account.`;
    }
    message += ` Because unauthenticated submissions are not allowed, they will not be able to submit new artifacts and wont receive evidence status update emails.`;

    // Display the warning message
    setWarning({ message });
  }, [item]);

  return (
    <GenericEditFieldV3
      item={item}
      module={module}
      resource={resource}
      inputType={GENERIC_FIELD_TYPES.SWITCH}
      field={"allowUnauthenticatedSubmissions"}
      mutation={updateEvidence}
      tooltip={fieldContext === fieldContexts.GRID ? undefined : allowUnauthenticatedSubmissionsTooltip}
      warning={warning}
      customDisplayComponent={<CustomDisplay />}
      friendlyName={"Unauthenticated Submissions"}
      updateItemById={updateItemById}
      {...props}
    />
  );
};

const CustomDisplay = ({ allowUnauthenticatedSubmissions }) => {
  return <span>{allowUnauthenticatedSubmissions === true ? "Allowed" : "Not Allowed"}</span>;
};

export const allowUnauthenticatedSubmissionsTooltip =
  "When enabled, points of contact without user accounts will be allowed to submit evidence artifacts for review";
export default EvidenceAllowUnauthenticatedSubmissionsSwitch;
