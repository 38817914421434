import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, ButtonGroup, Collapse } from "reactstrap";
import { OrganizationContext } from "../../../../utils/Context/OrganizationContext";
import NotesV2 from "../../../../utils/Notes/components/NotesV2";
import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useQueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import { generateGraphql } from "@rivial-security/generategraphql";
import { nestedFieldsMeetingDetails, updateMeetingNotes } from "../graphql/__meetingsGQL";
import { modules, resources } from "@rivial-security/role-utils";
import MeetingDocuments from "./MeetingDocuments";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Cancel from "@mui/icons-material/Cancel";
import { useLocation } from "react-router-dom";
import MeetingAgendaV2 from "./Agenda/MeetingAgendaV2";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

/**
 * Used to display information about a meeting in a sidebar format so the User can navigate around the application
 */
const MeetingPanel = (props) => {
  const module = modules.GOVERNANCE;
  const resource = resources.MEETING;
  const checkPermissions = useCheckPermissions({ module, resource });

  const context = useContext(OrganizationContext);

  const [meeting, setMeeting] = useState(null);
  const [isOpenDocumentsList, setIsOpenDocumentsList] = useState(false);
  const [isOpenAgendaItemsList, setIsOpenAgendaItemsList] = useState(false);

  const { onUpdate, getQuery } = generateGraphql(
    "Meeting",
    [
      "name",
      "description",
      "status",
      "awsChimeMeeting",
      "organizer",
      "location",
      "isAllDay",
      "startTime",
      "endTime",
      "pointOfContacts",
      "agendaItems",
      "documents",
      "linkedResources",
      "notes",
      "agenda",
    ],
    nestedFieldsMeetingDetails,
  );

  const queryItem = useQueryGetItem({
    query: getQuery,
    itemId: props.meeting && props.meeting.id,
    module,
    resource,
  });

  useEffect(() => {
    if (queryItem?.item?.id) {
      setMeeting(queryItem.item);
    }
  }, [queryItem?.item]);

  const [enableAgendaEdits, setEnableAgendaEdits] = useState(false);

  const location = useLocation();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        maxHeight: "100%",
      }}
    >
      <div style={{ flex: 1, overflowY: "auto", padding: ".5em" }}>
        {/* HEADER */}
        <div>
          <h4 style={{ textAlign: "center", marginTop: "7px" }}>Meeting Panel</h4>
          {props.detailsModalHook && props.detailsModalHook.modalButton}
        </div>
        <hr />

        {/* DOCUMENTS */}
        <div>
          <Button
            size="sm"
            title={"Meeting Documents List"}
            style={{
              marginTop: "5px",
              width: "100%",
              background: "white",
              marginBottom: "1rem",
            }}
            onClick={() => setIsOpenDocumentsList(!isOpenDocumentsList)}
          >
            <i className="icon-docs" /> Meeting Documents
          </Button>
          <Collapse isOpen={isOpenDocumentsList}>
            {checkPermissions.resource.update ? <MeetingDocuments item={meeting} /> : "Dont have permissions"}
          </Collapse>
        </div>
        <hr />

        {/* AGENDA */}
        <div>
          <div style={{ display: "flex", flexDirection: "row", gap: ".5em" }}>
            <Button
              size="sm"
              title={"Agenda List"}
              style={{
                marginTop: "5px",
                width: "100%",
                background: "white",
                marginBottom: "0.5rem",
              }}
              onClick={() => setIsOpenAgendaItemsList(!isOpenAgendaItemsList)}
            >
              <i className="icon-notebook" /> Agenda
            </Button>
            {isOpenAgendaItemsList && checkPermissions.resource.update && (
              <FormControlLabel
                style={{ margin: 0 }}
                control={
                  <Switch
                    size="small"
                    onClick={() => setEnableAgendaEdits((enableEdits) => !enableEdits)}
                    checked={enableAgendaEdits}
                  />
                }
                label="Edits"
              />
            )}
          </div>
          <Collapse isOpen={isOpenAgendaItemsList}>
            <MeetingAgendaV2
              organizationID={context.organizationID}
              meeting={meeting}
              disableEdits={!enableAgendaEdits}
              isMobile={true}
            />
          </Collapse>
        </div>
        <hr />

        {/* NOTES */}
        {checkPermissions.resource.update && (
          <NotesV2
            key={"meeting-notes"}
            item={meeting}
            module={module}
            resource={resource}
            mutation={updateMeetingNotes}
            resetFunction={queryItem.reset}
            observationConnectionField={"meetingID"}
            meetingID={meeting?.id}
          />
        )}
      </div>

      {/* Exit Buttons */}
      <div style={{ width: "100%" }}>
        <ButtonGroup style={{ width: "100%" }}>
          {!location?.pathname?.includes(`/governance/meetings/${props?.meeting?.id}`) && (
            <Button color={"primary"} href={`#/governance/meetings/${props.meeting && props.meeting.id}`}>
              <ArrowBack /> Go to Meeting
            </Button>
          )}

          <Button color={"danger"} onClick={() => context.setSideBar(null)}>
            <Cancel /> Exit Meeting Panel
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

MeetingPanel.propTypes = {
  meeting: PropTypes.object.isRequired,
};

export default MeetingPanel;
