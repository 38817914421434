import useCreateProgramSection from "../hooks/useCreateProgramSection";
import { withOrganizationCheck } from "../../../../../../utils/Context/withOrganizationCheck";

/**
 * Component instance of the useCreateProgramSection hook
 * @param  {object} props
 */
const CreateProgramSection = (props) => {
  return useCreateProgramSection(props).display;
};

export default withOrganizationCheck(CreateProgramSection);
