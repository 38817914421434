import React, { useEffect, useState } from "react";
import OrganizationCheck from "../../../../../../utils/Context/OrganizationCheck/OrganizationCheck";
import { updateRisk, updateRiskChange } from "../../../../../../graphql/mutations";
import EditButton from "../../../../../../utils/GenericComponents/buttons/EditButton";
import { useMutation } from "../../../../../../hooks/graphql/useMutation/useMutation";
import { gql_createSystemChangeLink } from "../../../graphql/gql_createSystemChangeLink";
import { useListKeyRiskIndicators } from "../../../../RiskConfig/KeyRiskIndicators/hooks/useListKeyRiskIndicators";
import { useAdjustKeyRiskIndicatorFields } from "../hooks/useAdjustKeyRiskIndicatorFields";
import { useLoadingAndOutdated } from "../../../hooks/useLoadingAndOutdated";
import { useOverallChanges } from "../../../hooks/useOverallChanges";
import { useRiskChangeSystemsTable } from "../../../hooks/useRiskChangeSystemsTable";
import { handleRiskChangePromises } from "../../../functions/handleRiskChangePromises";
import { useRiskChangeForm } from "../../../hooks/useRiskChangeForm";
import { useUpdateFormHook } from "../../../hooks/useUpdateFormHook";
import { useUpdateSystemsTable } from "../../../hooks/useUpdateSystemsTable";
import CreateChange from "../../CreateChange";

const CreateKeyRiskIndicatorChangeBody = ({ item, organizationID, resetFunction, toggleModal, ...props }) => {
  const [changeItem, setChangeItem] = useState({
    annualRateOfOccurrenceChange: parseFloat(0),
    keyRiskIndicator: {},
  });

  const loadingAndOutdated = useLoadingAndOutdated(changeItem);

  const [selectedKeyRiskIndicator, setSelectedKeyRiskIndicator] = useState(null);

  const overallChanges = useOverallChanges();

  const systemsTable = useRiskChangeSystemsTable({
    tooltip: "Systems that are associated with this Key Risk Indicator",
  });

  const updateInputFunction = (input) => {
    return {
      ...input,
      type: "key_risk_indicator_change",
      change: JSON.stringify({
        ...overallChanges,
        keyRiskIndicator: selectedKeyRiskIndicator,
        annualRateOfOccurrenceChange: changeItem.annualRateOfOccurrenceChange,
      }),
    };
  };

  const createSystemChangeLink = useMutation({
    mutation: gql_createSystemChangeLink,
    disableRoleChecking: true,
    disableToast: true,
  });
  const updateKeyRiskIndicatorFields = useMutation({
    mutation: updateRisk,
    disableRoleChecking: true,
    disableToast: true,
  });

  /**
   * This callback updates the KRI ARO after the RiskChange gets created.
   * @param riskChange
   */
  const callback = async (riskChange) => {
    if (riskChange) {
      if (selectedKeyRiskIndicator && selectedKeyRiskIndicator.id) {
        await updateKeyRiskIndicatorFields.editItem({
          id: selectedKeyRiskIndicator.id,
          annualRateOfOccurrence:
            selectedKeyRiskIndicator.annualRateOfOccurrence && changeItem.annualRateOfOccurrenceChange
              ? selectedKeyRiskIndicator.annualRateOfOccurrence + changeItem.annualRateOfOccurrenceChange
              : undefined,
        });
      }

      await handleRiskChangePromises(
        overallChanges.systemCalcs,
        createSystemChangeLink.createItem,
        organizationID,
        riskChange,
      );

      !props.getNewItem && resetFunction && resetFunction();
    }
  };

  /**
   * This is used when converting from a Manual Change to a new type of Change
   * @type {{mutateItemLegacy: (function(*): Promise<*|{}>), roleConfig: *, CheckFieldPermissionsRead: (function(*=): boolean), editItem: (function(*=): Promise<*|{}>), resource: *, module: *, mutateItem: (function(*=): Promise<*>), CheckFieldPermissionsUpdate: (function(*=): boolean), field: *, deleteItem: (function(*=): Promise<*|{}>), function: *, createItem: (function(*=): Promise<*|{}>), CheckFuncPermissions: (function(*=): boolean)}}
   */
  const updateRiskChangeHook = useMutation({
    mutation: updateRiskChange,
    disableRoleChecking: true,
  });

  const submitFunction = async (input) => {
    delete input["riskControlLinks"];
    delete input["systemLinks"];

    return await updateRiskChangeHook.editItem({
      id: item && item.id,
      ...input,
    });
  };

  const formHook = useRiskChangeForm({
    item,
    typename: "Key Risk Indicator Change",
    organizationID,
    updateInputFunction,
    callback,
    submitFunction,
    toggleModal,
    ...props,
  });

  useUpdateFormHook(item, formHook);
  useUpdateSystemsTable(systemsTable, overallChanges);

  return (
    <CreateChange
      organizationID={organizationID}
      changeItem={changeItem}
      loadingAndOutdated={loadingAndOutdated}
      overallChanges={overallChanges}
      formHook={formHook}
      systemsTable={systemsTable}
      adjustComponent={
        <SelectKeyRiskIndicator
          organizationID={organizationID}
          selectedKeyRiskIndicator={selectedKeyRiskIndicator}
          setSelectedKeyRiskIndicator={setSelectedKeyRiskIndicator}
          changeItem={changeItem}
          setChangeItem={setChangeItem}
        />
      }
    />
  );
};

const SelectKeyRiskIndicator = ({
  organizationID,
  selectedKeyRiskIndicator,
  setSelectedKeyRiskIndicator,
  changeItem,
  setChangeItem,
}) => {
  const adjustKeyRiskIndicatorFieldsHook = useAdjustKeyRiskIndicatorFields({
    selectedKeyRiskIndicator,
  });

  // Create the temp item for monte carlo
  useEffect(() => {
    if (selectedKeyRiskIndicator && selectedKeyRiskIndicator.id) {
      setChangeItem({
        annualRateOfOccurrenceChange: parseFloat(adjustKeyRiskIndicatorFieldsHook.annualRateOfOccurrenceChange / 100),
        keyRiskIndicator: selectedKeyRiskIndicator,
      });
    }
  }, [adjustKeyRiskIndicatorFieldsHook.annualRateOfOccurrenceChange, selectedKeyRiskIndicator]);

  const keyRiskIndicatorList = useListKeyRiskIndicators({
    organizationID,
    dataCardConfig: {
      config: {
        showSelectBoxes: true,
        selectionType: "button",
        buttonCallback: (keyRiskIndicator) => setSelectedKeyRiskIndicator(keyRiskIndicator),
      },
    },
  });

  useEffect(() => {
    keyRiskIndicatorList.setSortField("name", "asc");
  }, []);

  return (
    <div>
      {selectedKeyRiskIndicator ? (
        <>
          <h5>
            Selected Key Risk Indicator
            <EditButton
              color="ghost-warning"
              style={{ marginLeft: "1em" }}
              onClick={() => setSelectedKeyRiskIndicator(null)}
              title="Select a different Key Risk Indicator"
            />
          </h5>
          {selectedKeyRiskIndicator.name}
        </>
      ) : (
        keyRiskIndicatorList.display
      )}
      <hr />
      {selectedKeyRiskIndicator && adjustKeyRiskIndicatorFieldsHook.display}
      <hr />
    </div>
  );
};

const CreateKeyRiskIndicatorChange = (props) => {
  return (
    <OrganizationCheck {...props}>
      <CreateKeyRiskIndicatorChangeBody />
    </OrganizationCheck>
  );
};

export default CreateKeyRiskIndicatorChange;
