import { Badge, Button, Collapse, ListGroup, ListGroupItem, ListGroupItemText } from "reactstrap";
import React, { useEffect, useState } from "react";
import { modules, resources } from "@rivial-security/role-utils";
import { removeObjectFromArray, updateObjectInArray } from "@rivial-security/func-utils";

import { ListQueryBy } from "../../../../utils/Functions/Graphql/ListQueryBy";
import PermissionsOverlay from "../../../../utils/Overlays/PermissionsOverlay";
import RecommendationDetails from "../components/RecommendationDetails";
import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useCreateRecommendation } from "./useCreateRecommendation";
import { useRecommendationsGrid } from "./useRecommendationsGrid";
import { v4 as uuid } from "uuid";

/**
 * Allows the user to add Recommendations to an Observation
 * @param organizationID
 * @param onAdd
 * @param onRemove
 * @param onUpdate
 * @param dataField
 * @param disableSubmitButton
 * @param disableResetButton
 * @param updateInputFunction
 * @param module
 * @param isOpen
 * @param dataTooltip
 */
export const useAddRecommendation = ({
  organizationID,
  onAdd,
  onRemove,
  onUpdate,
  dataField,
  disableSubmitButton,
  disableResetButton,
  updateInputFunction,
  module = modules.GOVERNANCE,
  isOpen = false,
  dataTooltip,
  defaultOpenRecommendationForm = false,
  disableExistingRecommendations = false,
}) => {
  const resource = resources.RECOMMENDATION;

  const OBSERVATION = resources.OBSERVATION;

  const createRecommendationPermissions = useCheckPermissions({
    module,
    resource,
  });
  const updateFieldPermissions = useCheckPermissions({
    module,
    resource: OBSERVATION,
    field: "recommendations",
  });

  /**
   * The local state for this list
   */
  const [input, setInput] = useState([]);

  /**
   * Removes this recommendation locally from state.
   * Also calls the parent 'onRemove' function to handle other removal logic.
   */
  const onRemoveRecommendation = (recommendation) => {
    setInput((input) => {
      onRemove && onRemove(recommendation);
      removeObjectFromArray(input, recommendation, "id");
      return [...input];
    });
  };

  /**
   * Adds this recommendation locally to state.
   * Also calls the parent 'onAdd' function to handle other addition logic.
   */
  const onAddRecommendation = (recommendation) => {
    setInput((input) => {
      let updatedItem;

      if (updateInputFunction) {
        updatedItem = updateInputFunction(recommendation);
      } else {
        updatedItem = recommendation;
      }

      onAdd && onAdd(recommendation);
      return [...input, updatedItem];
    });
  };

  /**
   * Updates a recommendation in the local state.
   * Also calls the parent 'onUpdate' function
   * @param recommendation
   */
  const onUpdateRecommendation = (recommendation) => {
    setInput((input) => {
      let updatedItem;

      if (updateInputFunction) {
        updatedItem = updateInputFunction(recommendation);
      } else {
        updatedItem = recommendation;
      }

      onUpdate && onUpdate(updatedItem);
      updateObjectInArray(input, updatedItem, "id");
      return [...input];
    });
  };

  const [showPanel, setShowPanel] = useState(isOpen);

  const display = (
    <PermissionsOverlay module={module} resource={resource}>
      <ListGroup>
        {input?.map((recommendation, index) => (
          <AddRecommendationItem
            key={`recitem${recommendation.id}`}
            recommendation={recommendation}
            organizationID={organizationID}
            onRemove={onRemoveRecommendation}
            onUpdate={onUpdateRecommendation}
            module={module}
          />
        ))}
        <Collapse isOpen={showPanel}>
          {!disableExistingRecommendations && updateFieldPermissions.field.update && (
            <SelectExistingRecommendation
              addedRecommendations={input || []}
              organizationID={organizationID}
              onAdd={onAddRecommendation}
            />
          )}
          {createRecommendationPermissions.resource.create && updateFieldPermissions.field.update && (
            <AddRecommendationItem
              dataField={dataField}
              organizationID={organizationID}
              onAdd={onAddRecommendation}
              module={module}
              dataTooltip={dataTooltip}
              defaultOpenRecommendationForm={defaultOpenRecommendationForm}
            />
          )}
        </Collapse>
        {updateFieldPermissions.field.update && (
          <ListGroupItem style={{ height: "0.35em", cursor: "pointer" }} onClick={() => setShowPanel(!showPanel)}>
            <ListGroupItemText style={{ textAlign: "center" }}>
              {
                <i
                  style={{ position: "absolute", top: "0.3em" }}
                  className={showPanel ? "icon-arrow-up" : "icon-arrow-down"}
                />
              }
            </ListGroupItemText>
          </ListGroupItem>
        )}
      </ListGroup>
    </PermissionsOverlay>
  );

  return {
    input,
    setInput,
    display,
  };
};

const AddRecommendationItem = ({
  recommendation,
  organizationID,
  onAdd,
  onRemove,
  onUpdate,
  dataField,
  module,
  dataTooltip,
  defaultOpenRecommendationForm = false,
}) => {
  const [showEdit, setShowEdit] = useState(defaultOpenRecommendationForm);

  const [recommendationInput, setRecommendationInput] = useState({});

  /**
   * Bubble this input up, then close the form
   * @param input
   */

  const [id, setId] = useState(recommendation?.id || uuid());

  /**
   * Either calls the onUpdate or onAdd functions.
   */
  const submitFunction = async () => {
    setShowEdit(false);

    /**
     * If there's on onUpdate function, updates the action in local states
     */
    if (onUpdate) {
      onUpdate({
        id,
        ...recommendationInput,
      });
    } else if (onAdd) {
      onAdd({
        id,
        new: true,
        ...recommendationInput,
      });
    }

    setId(recommendation?.id || uuid());
  };

  const remove = (e) => {
    e.stopPropagation();
    onRemove({
      id,
      ...recommendationInput,
    });
  };

  const updateFieldPermissions = useCheckPermissions({
    module: modules.GOVERNANCE,
    resource: resources.OBSERVATION,
    field: "recommendations",
  });

  return (
    <ListGroupItem
      onClick={() => setShowEdit(!showEdit)}
      color={!recommendation && "success"}
      style={{ cursor: "pointer" }}
    >
      {recommendation?.new && recommendation?.name && (
        <Badge color="success" style={{ marginRight: "1em" }}>
          New
        </Badge>
      )}
      {recommendation?.name ? (
        <span>
          {recommendation.name}
          <span style={{ marginLeft: "2em", fontStyle: "italic" }}>
            {recommendation?.actionItems?.length} Action Items
          </span>
        </span>
      ) : (
        <span>
          <i className="icon-plus" style={{ marginRight: "1em" }} />
          Create a new Recommendation
        </span>
      )}
      {updateFieldPermissions.field.update && recommendation && (
        <Button onClick={(e) => remove(e)} close className="float-right" title="Remove Recommendation" />
      )}
      <Collapse onClick={(e) => e.stopPropagation()} isOpen={showEdit} style={{ cursor: "initial" }}>
        <hr />
        {showEdit && (
          <div style={{ paddingBottom: "2em" }}>
            {recommendation?.alreadyExists ? (
              <RecommendationDetails item={recommendation} />
            ) : (
              <Form
                dataField={dataField}
                dataTooltip={dataTooltip}
                organizationID={organizationID}
                submitFunction={submitFunction}
                enableToast={false}
                recommendation={recommendation}
                setVal={setRecommendationInput}
                module={module}
              />
            )}
          </div>
        )}
      </Collapse>
    </ListGroupItem>
  );
};

/**
 * A hook to get all recommendations that have not been added yet
 * @param {string} organizationID
 * @param {object[]} addedRecommendations
 * @return {{data: Object[], setData: function(*=): void, display: *, setFields: (value: (((prevState: *) => *) | *)) => void, setSelectionType: (value: (((prevState: Object.selectionType) => Object.selectionType) | Object.selectionType)) => void, setSelectedItems: (value: (((prevState: []) => []) | [])) => void, selectionType: Object.selectionType, setShowSelectBoxes: (value: unknown) => void, showSelectBoxes: unknown, setItems: function(*=): void, options: string[], setFullData: (value: (((prevState: []) => []) | [])) => void, sortFields: [], fields: *, selectedItems: [], setSortField: function(*=, *=): void}}
 */
const useRecommendationList = ({ organizationID, addedRecommendations }) => {
  const [recommendationList, setRecommendationList] = useState([]);
  const [filteredRecommendationList, setFilteredRecommendationList] = useState(null);

  //Gets the initial set of all recommendations (once throughout lifetime of hook)
  useEffect(() => {
    const recommendationsByOwnerGroup = /* GraphQL */ `
      query RecommendationsByOwnerGroup(
        $ownerGroup: String
        $sortDirection: ModelSortDirection
        $filter: ModelRecommendationItemFilterInput
        $limit: Int
        $nextToken: String
      ) {
        recommendationsByOwnerGroup(
          ownerGroup: $ownerGroup
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            id
            name
            actionItems(limit: 500) {
              items {
                id
                action {
                  id
                  name
                }
              }
            }
            ownerGroup
          }
          nextToken
        }
      }
    `;

    ListQueryBy({
      query: recommendationsByOwnerGroup,
      variables: {
        ownerGroup: organizationID,
      },
      limit: 1000,
    }).then((data) => {
      setRecommendationList(data);
    });
  }, []);

  //Filters the recommendations based on whether they were already added
  useEffect(() => {
    const tempRecommendations = [...recommendationList];
    for (const addedRecommendation of addedRecommendations) {
      removeObjectFromArray(tempRecommendations, addedRecommendation);
    }
    setFilteredRecommendationList(tempRecommendations);
  }, [JSON.stringify(addedRecommendations), recommendationList]);

  return {
    recommendationList: filteredRecommendationList,
  };
};

/**
 * Component for selecting existing recommendations to add to the resource
 * @param {function} onAdd - function to call to add the recommendation in the context of the component
 * @param {string} organizationID - selected organization id
 * @param {object[]} addedRecommendations - list of all recommendations already added and can't be reselected
 */
const SelectExistingRecommendation = ({ onAdd, organizationID, addedRecommendations }) => {
  const [showEdit, setShowEdit] = useState(false);

  const { recommendationList } = useRecommendationList({
    organizationID,
    addedRecommendations,
  });

  const queryConfig = {
    query: null,
  };

  const onSelectRecommendation = (input) => {
    const tempActions = input?.actionItems?.items || [];
    tempActions.forEach((action) => (action.alreadyExists = true));
    onAdd &&
      onAdd({
        ...input,
        alreadyExists: true,
        new: true,
        actionItems: tempActions,
      });
  };

  const gridConfig = {
    deleteFunction: null,
    gridHeight: "100%",
    createResourceComponent: null,
    enableSelectButtons: true,
    enableColumnChooser: false,
    selectButtonCallback: onSelectRecommendation,
    fields: [{ name: "name", width: 600 }],
  };

  const recommendationsGrid = useRecommendationsGrid({
    organizationID,
    queryConfig,
    gridConfig,
  });
  useEffect(() => {
    if (!recommendationList) {
      recommendationsGrid.setIsLoading(true);
    } else {
      recommendationsGrid.setIsLoading(false);
    }

    recommendationsGrid.setData(recommendationList);
  }, [JSON.stringify(recommendationList)]);

  return (
    <ListGroupItem onClick={() => setShowEdit(!showEdit)} color={"secondary"} style={{ cursor: "pointer" }}>
      <span>
        <i className="icon-list" style={{ marginRight: "1em" }} />
        Add an Existing Recommendation
      </span>
      <Collapse onClick={(e) => e.stopPropagation()} isOpen={showEdit} style={{ cursor: "initial" }}>
        <hr />
        {showEdit && recommendationsGrid.gridDisplay}
      </Collapse>
    </ListGroupItem>
  );
};

const Form = ({
  submitFunction,
  recommendation,
  setVal,
  organizationID,
  dataField,
  dataTooltip,
  disableEdit,
  module,
  enableToast,
}) => {
  const form = useCreateRecommendation({
    organizationID,
    submitFunction,
    item: recommendation,
    disableMutation: true,
    enableToast,
    dataField,
    module,
    dataTooltip,
  });

  useEffect(() => {
    if (form?.input) {
      setVal(form.input);
    }
  }, [form.input]);

  useEffect(() => {
    if (recommendation) {
      form.setInput(recommendation);
    }
  }, [recommendation]);

  return <div>{form.display}</div>;
};
