import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { OrganizationContext } from "../OrganizationContext";
import { Alert } from "reactstrap";
import ErrorBoundary from "../../../_errorBoundaries";

/**
 * Author: Jacob Blazina
 * Created At: 8/28/19
 * Edits:
 *  - 9/12/19 JB: Added optional prop to disable the "Select Organization" alert
 *
 * @description: Wrap all Standalone components in this. This will perform a context check for selected organization.
 *  If the component had selectedOrganization passed as an optional prop, it will override context.
 * @param {object} props - The props to pass to the wrapped component
 * @returns {JSX.Element} - The wrapped component
 * @example: <OrganizationCheck selectedOrganization={this.state.selectedOrganization}>
 */
const OrganizationCheck = (props) => {
  const context = useContext(OrganizationContext);

  const { roleConfig, loggedInUserId } = context;

  const [selectedOrganization, setSelectedOrganization] = useState(
    props.selectedOrganization !== "No Organization Selected"
      ? props.selectedOrganization
      : context.selectedOrganization,
  );

  useEffect(() => {
    setSelectedOrganization(
      props.selectedOrganization !== "No Organization Selected"
        ? props.selectedOrganization
        : context.selectedOrganization,
    );
  }, [props.selectedOrganization, context.selectedOrganization]);

  // const route = useLocation().pathname;
  const currentUserEmail = context.userEmail;
  const loggedInPointOfContactId = context.loggedInPointOfContactId;
  const currentOrganization = context.selectedOrganization;

  const info = {
    component: props.component || "",
    // route,
    currentUserEmail,
    loggedInPointOfContactId,
    currentOrganization,
  };

  return (
    <ErrorBoundary info={info}>
      {!props.disableAlert &&
      //  Note: this alert will not be displayed for Operation Team users accessing their own ownerGroup resources
      context.selectedOrganization === "No Organization Selected" &&
      !context.operationTeamID &&
      props.organizationID !== "Admin" ? (
        <Alert color="danger">Please Select an Organization</Alert>
      ) : (
        React.cloneElement(props.children, {
          organizationID: selectedOrganization,
          isAdmin: props.isAdmin || context.isAdmin,
          roleConfig,
          currentUserEmail,
          loggedInPointOfContactId,
          loggedInUserId,
          ...props,
        })
      )}
    </ErrorBoundary>
  );
};

OrganizationCheck.propTypes = {
  selectedOrganization: PropTypes.string,
  organizationID: PropTypes.string,
  disableAlert: PropTypes.bool,
};

OrganizationCheck.defaultProps = {
  selectedOrganization: "No Organization Selected",
  disableAlert: false,
};

export default OrganizationCheck;
