/**
 * Author: Jacob Blazina
 * Created At: 10/1/2019
 * Edits:
 *
 * Description: A Function.
 *              Deletes a Maturity Column from the DB.
 *              First Deletes all Associated Maturity Levels to avoid NULL issues.
 *
 */

import { API, graphqlOperation } from "@aws-amplify/api";

import { InfoLogger } from "@utils/EventLogger";
import { generateGraphql } from "@rivial-security/generategraphql";

const { deleteMutation: deleteMaturityIndicator } = generateGraphql("MaturityIndicator", ["name"]);

const DeleteMaturityIndicator = (maturityIndicator) => {
  API.graphql(
    graphqlOperation(deleteMaturityIndicator, {
      input: {
        id: maturityIndicator.id,
      },
    }),
  ).then(InfoLogger(`maturityIndicator: ${maturityIndicator.id} was Successfully Deleted`));
};

export default DeleteMaturityIndicator;
