import { generateGraphql } from "@rivial-security/generategraphql";
import { useDetailsCard } from "../../../../../hooks/views/useDetailsCardV2";
import PointOfContacts from "../../customFields/PointOfContacts";
import React from "react";
import { modules, resources } from "@rivial-security/role-utils";
import { useAccordion } from "../../../../../hooks/views/useAccordion/useAccordion";
import ResponseTeamProcedureTabs from "./components/ResponseTeamProcedureTabs";
import { getProceduresNestedFields } from "../../../PlaybookBuilder/functions/getProceduresNestedFields";

/**
 * Shows all response team info in an accordion format
 * @param {string} itemId - the response team database id field value
 */
export const useResponseTeamDetails = ({ itemId, ...props }) => {
  const module = modules.INCIDENT_RESPONSE;
  const resource = resources.RESPONSE_TEAM;

  const { getQuery, updateMutation } = generateGraphql(
    "ResponseTeam",
    ["name", "description", "pointOfContactLinks", "procedures"],
    {
      ...getProceduresNestedFields(),
      pointOfContactLinks: `(limit: 1000) { items { id pointOfContact { id firstName lastName email title } } }`,
    },
  );

  const fields = ["name", "description"];

  const queryConfig = {
    query: getQuery,
    itemId,
  };

  const detailsConfig = {
    fields,
    updateMutation,
  };

  const detailsHook = useDetailsCard({
    queryConfig,
    detailsConfig,
    module,
    resource,
    ...props,
  });

  const accordion = useAccordion({
    items: [
      {
        title: "General Information",
        subTitle: "Name, description and other information",
        component: <span>{detailsHook.tableDisplay}</span>,
        icon: "icon-list",
        defaultExpanded: true,
      },
      {
        title: "Team Members",
        subTitle: "List of Points of Contact that are part of this team",
        component: (
          <PointOfContacts
            item={detailsHook?.item}
            pointOfContactLinks={detailsHook?.item?.pointOfContactLinks}
            organizationID={props?.organizationID}
            resetFunction={() => detailsHook?.reset()}
          />
        ),
        icon: "icon-people",
      },
      {
        title: "Responsibilities",
        subTitle: "Steps that this team needs to carry out if an incident they are assigned to has occurred",
        component: (
          <ResponseTeamProcedureTabs
            item={detailsHook?.item}
            resetFunction={() => detailsHook.reset()}
            enableNotes={true}
          />
        ),
        icon: "icon-support",
      },
    ],
  });

  return {
    ...detailsHook,
    display: accordion.display,
  };
};
