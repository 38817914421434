import { isNullOrUndefined } from "@rivial-security/func-utils";
import { updateRiskRecommendation } from "./updateRiskRecommendation";
import { createRiskRecommendation } from "./createRiskRecommendation";
import { formatRecommendationData } from "./formatRecommendationData";
import { formatRiskRecommendationData } from "./formatRiskRecommendationData";

interface SubmitRiskRecommendationInput {
  input: {
    observation: string;
    isFinding: boolean;
    recommendation: string;
    rating: string;
  };
  riskRecommendationData: {
    selectedRiskControl: {
      id: string;
    };
    changeInImplementation: number;
    changeInCost: number;
    selectedSystem: {
      id: string;
      name: string;
    };
    overallResidualChange: number;
    overallROIChange: number;
    systemChanges: {
      id: string;
      name: string;
      residualChange: number;
      returnOnInvestment: number;
    }[];
  };
  organizationID: string;
  item: {
    id: string;
  };
}

/**
 * Submit function for the risk recommendation form.
 * This function will either create or update a risk recommendation based on the 'item' prop.
 */
export const submitRiskRecommendation = async ({
  input,
  riskRecommendationData,
  organizationID,
  item,
}: SubmitRiskRecommendationInput) => {
  // format the unstructured riskRecommendationData for saving
  const formattedRecommendationData = formatRecommendationData({
    riskRecommendationData,
    organizationID,
  });

  // format the structured riskRecommendationData for saving
  const formattedRiskRecommendationData = formatRiskRecommendationData({
    data: formattedRecommendationData,
  });

  // If there's in 'item' prop, this is an update form
  if (!isNullOrUndefined(item?.id)) {
    return await updateRiskRecommendation({
      item,
      input,
      formattedRecommendationData,
      formattedRiskRecommendationData,
    });
  }
  // else it's a create form, proceed as normal
  else {
    return await createRiskRecommendation({
      input,
      organizationID,
      formattedRecommendationData,
      formattedRiskRecommendationData,
      selectedRiskControl: riskRecommendationData.selectedRiskControl,
    });
  }
};
