import { useCreateIncident } from "../hooks/useCreateIncident";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * Component for creating a new incident
 * @param props
 * @returns {*}
 * @constructor
 */
const CreateIncident = (props) => {
  return useCreateIncident(props).display;
};

export default withOrganizationCheck(CreateIncident);
