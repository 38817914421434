import React, { useEffect, useState } from "react";

import DataLoader from "../../../../utils/LoadingComponents/DataLoader";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useBarChart } from "../../../../hooks/charts/useBarChart";

/**
 * Displays a Bar Chart to display Observation Risk Ratings or Recommendation Ratings
 * @param observations
 * @param recommendations
 * @param actionItems
 */
export const useFindingsBarChart = ({ observations = [], recommendations = [], actionItems = [] }) => {
  /**
   * Holds Observations, Recommendations, and Action items data
   */
  const [findingsData, setFindingsData] = useState({
    observations,
    recommendations,
    actionItems,
  });

  /**
   * Determines if it should display observations 'Risk' field or Recommenations 'rating' field counts
   */
  const [view, setView] = useState("observations");

  /**
   * Holds bar chart raw data values
   */
  const [data, setData] = useState({
    high: 0,
    mediumHigh: 0,
    medium: 0,
    lowMedium: 0,
    low: 0,
    info: 0,
  });

  /**
   * Holds formatted chart data for the bar chart
   */
  const [chartData, setChartData] = useState([]);

  const [tickValues, setTickValues] = useState(0);

  const chart = useBarChart(chartData, ["count"], "rating", {
    isCustomColors: true,
    chartMarginRight: 10,
    chartMarginLeft: 30,
    chartMarginTop: 10,
    disableLegends: true,
    axisLeft: {
      tickValues,
    },
  });

  /**
   * Parses the data for the chart ui itself
   */
  useEffect(() => {
    if (!isNullOrUndefined(data)) {
      const { high, mediumHigh, medium, lowMedium, low, info } = data;

      const tempChartData = [];

      tempChartData.push({
        rating: "High",
        count: high || 0,
        color: "#ff0000",
      });

      tempChartData.push({
        rating: "Medium-High",
        count: mediumHigh || 0,
        color: "#f86562",
      });

      tempChartData.push({
        rating: "Medium",
        count: medium || 0,
        color: "#ff8C00",
      });

      tempChartData.push({
        rating: "Low-Medium",
        count: lowMedium || 0,
        color: "#ffab5d",
      });

      tempChartData.push({
        rating: "Low",
        count: low || 0,
        color: "#008000",
      });

      tempChartData.push({
        rating: "Info",
        count: info || 0,
        color: "#add8E6",
      });

      setChartData(tempChartData);
    }
  }, [JSON.stringify(data)]);

  /**
   * Set the chart data when data updates
   */
  useEffect(() => {
    if (chartData) {
      chart.setChartData(chartData);
    }
  }, [JSON.stringify(chartData)]);

  /**
   * Parses the observations to make up the chart
   */
  useEffect(() => {
    if (!isNullOrUndefined(findingsData) && Array.isArray(findingsData.observations)) {
      setData((d) => {
        const data = {};

        if (view === "observations") {
          findingsData.observations.forEach((observation) => {
            const risk = observation.risk;
            if (!isNullOrUndefined(risk)) {
              if (typeof data[risk] === "number") {
                data[risk] = data[risk] + 1;
              } else {
                data[risk] = 1;
              }
            }
          });
        } else if (view === "recommendations") {
          findingsData.recommendations.forEach((recommendation) => {
            const rating = recommendation.rating;
            if (!isNullOrUndefined(rating)) {
              if (typeof data[rating] === "number") {
                data[rating] = data[rating] + 1;
              } else {
                data[rating] = 1;
              }
            }
          });
        }

        setTickValues(Math.max(...Object.values(data)));
        return data;
      });
    }
  }, [findingsData, view]);

  return {
    chart,
    data,
    setData,
    chartData,
    setChartData,
    findingsData,
    setFindingsData,
    view,
    setView,
    display: (
      <DataLoader
        isLoading={false}
        isEnoughData={findingsData?.observations?.length > 0 || findingsData?.recommendations?.length > 0}
        dataMessage={"There are no Observations or Recommendations to display"}
      >
        {chart.display}
      </DataLoader>
    ),
  };
};
