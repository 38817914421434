import AssociatedControl from "../../../../../Recommendations/customFields/AssociatedControl";
import React from "react";
import { useRecommendationsGrid } from "../../../../../Recommendations/hooks/useRecommendationsGrid";
import Resolved from "../../../../../../Program/Recommendations/customFields/Resolved";
import NumberChange from "../../../../../../../utils/CustomFields/NumberChange";
import CreateRecommendation from "../../../../../Recommendations/components/CreateRecommendation";
import { DETAILS_TYPES } from "../../../../../../../hooks/views/useGrid/enums/DETAILS_TYPES";

/**
 * Displays a list of Recommendations that are related directly to a System.
 * @param {string} organizationID - org ID for this system
 * @param {object} item - the system object
 */
export const useSystemRecommendationsGrid = ({ organizationID, item }) => {
  const fields = [
    {
      name: "name",
      width: 200,
    },
    {
      name: "riskControl",
      width: "200",
      component: <AssociatedControl />,
    },
    {
      name: "resolved",
      component: <Resolved />,
      disablePropagation: true,
      width: 90,
    },
    {
      name: "returnOnInvestment",
      component: <NumberChange format="percent" field="returnOnInvestment" />,
      width: 90,
      friendlyName: "ROI",
    },
    {
      name: "residualChange",
      component: <NumberChange format="dollar" positiveNumber="red" negativeNumber="green" field="residualChange" />,
      width: 90,
      visible: false,
    },
  ];

  const cardConfig = {
    enableSticky: false,
  };

  const gridConfig = {
    enableMenu: false,
    allowFiltering: false,
    allowPaging: false,
    fields,
    enablePersistence: false,
    persistenceUUID: "system-recommendations-grid",
    createResourceComponent: <CreateRecommendation organizationID={organizationID} system={item} />,
    detailsType: DETAILS_TYPES.NONE,
    enableQuickDetails: true,
    gridHeight: "100%",
  };

  const recommendationGrid = useRecommendationsGrid({
    cardConfig,
    gridConfig,
    organizationID,
    systemID: item?.id,
  });

  return recommendationGrid;
};
