import Axios from "axios";
import { ErrorLogger } from "@utils/EventLogger";
import { GetQuery } from "../../../../utils/Functions/Graphql/GetQuery";
import { GetSignedObjectUrl } from "../../../../utils/Functions/S3Storage/GetSignedObjectUrl";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 * @description Get url for a document from S3 bucket
 * @param {object} document - document object
 * @param {string} organizationID - selected organization
 * @param {function} setUrl - function for setting an url
 * @param {function} onError - on error this function gets called
 */
export const getUrl = ({ document, organizationID, setUrl, onError }) => {
  try {
    const key = document?.file?.key;
    let bucketName = "";

    if (document?.file?.bucket) {
      bucketName = document.file.bucket;
    } else if (organizationID) {
      GetQuery({
        query: generateGraphql("Organization", ["s3BucketName"]).getQuery,
        variables: { id: organizationID },
      }).then((org) => {
        bucketName = org?.s3BucketName;
      });
    }

    GetSignedObjectUrl({
      objectKey: key,
      bucketName: bucketName,
      organizationID,
      callbackFunction: (url) => {
        Axios.get(url, {
          responseType: "arraybuffer",
        })
          .then(() => setUrl(url))
          .catch(() => onError && onError());
      },
    });
  } catch (err) {
    ErrorLogger("Error! ", err);
    onError?.();
  }
};
