"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNestedItems = void 0;
const getFunctionFieldName_1 = require("../../../getFunctionFieldName");
const applyFilterOnArray_1 = require("../../../../formatData/applyFilterOnArray");
/**
 * Extracts deeply nested info from an object or array using the resource path defined by the user
 * @param {object} element - the element to transform
 * @param {object} params - the parameters for the function, see ResourcePath definition for path property details
 * @return {*[]} - an array of nested items
 */
const getNestedItems = ({ element, params }) => {
    const path = params?.path;
    if (Array.isArray(path) && path.length === 0) {
        return [element];
    }
    else if (Array.isArray(path) && path.length > 0) {
        const nestedElements = [];
        //A path without the name of the current field is invalid
        let currentPathField = path[0]?.name;
        if (path[0]?.isFunction === true) {
            currentPathField = (0, getFunctionFieldName_1.getFunctionFieldName)({ pathElement: path[0] });
        }
        if (!currentPathField) {
            return [];
        }
        const hasMany = path[0]?.hasMany;
        const isModel = path[0]?.isModel;
        const filter = path[0]?.filters;
        //If at current recursion the element is an array iterate over each item
        if (Array.isArray(element)) {
            for (const item of element) {
                //Skip over array items which are not objects when a path is remaining
                if (typeof item !== "object") {
                    continue;
                }
                let nextElement = item?.[currentPathField];
                if (hasMany && isModel) {
                    nextElement = item?.[currentPathField]?.items;
                }
                //Check for any filters and apply them
                let filteredNextElement = nextElement;
                if (filter && Array.isArray(nextElement)) {
                    filteredNextElement = (0, applyFilterOnArray_1.applyFilterOnArray)({
                        items: nextElement,
                        filter,
                    });
                }
                nestedElements.push(...(0, exports.getNestedItems)({
                    element: filteredNextElement,
                    params: {
                        path: path.slice(1),
                    },
                }));
            }
        }
        else if (typeof element === "object") {
            let nextElement = element?.[currentPathField];
            if (hasMany && isModel) {
                nextElement = element?.[currentPathField]?.items;
            }
            //Check for any filters and apply them
            let filteredNextElement = nextElement;
            if (filter && Array.isArray(nextElement)) {
                filteredNextElement = (0, applyFilterOnArray_1.applyFilterOnArray)({
                    items: nextElement,
                    filter,
                });
            }
            nestedElements.push(...(0, exports.getNestedItems)({
                element: filteredNextElement,
                params: {
                    path: path.slice(1),
                },
            }));
        }
        else {
            //A path is still remaining but the current item is not an object or array
            return [];
        }
        return nestedElements;
    }
    else {
        return [];
    }
};
exports.getNestedItems = getNestedItems;
