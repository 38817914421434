import React from "react";
import ArtifactLabels from "./ArtifactLabels";
import ArtifactStatus from "./ArtifactStatus";
import ArtifactComments from "./ArtifactComments";
import ArtifactEvidence from "./ArtifactEvidence";
import { useAccordion } from "../../../../../hooks/views/useAccordion/useAccordion";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import FeatureAlert from "../../../../../utils/GenericComponents/FeatureAlert";
import { ArtifactLabelsHowToUse } from "./ArtifactLabelsHowToUse";
import { Switch, Typography } from "@mui/material";
import useAdminCheck from "../../../../../hooks/permissions/useAdminCheck";
import { useOrganizationConfig } from "../../../../AdminPanel/Organizations/hooks/useOrganizationConfig";

/**
 * Component allowing users to determine whether a particular artifact satisfies the evidence requirements
 * @param {string} organizationID - the currently selected organization ID
 * @param {object} artifact - full artifact information from the database, including attached evidence through evidence activity
 * @param {object} detailsHook - hook for the artifact details panel, used to
 * @param {function} onRequestOldView - callback function when user requests to validate the artifact with the old viewer instead of the new one
 * @param {boolean} forceOldView - TRUE if the old view is selected by the user (hides labels accordion)
 * @param {function} onLabelClick - callback function to handle click on a label
 * @returns {JSX.Element}
 */
const ValidationPanel = ({ organizationID, artifact, detailsHook, onRequestOldView, forceOldView, onLabelClick }) => {
  const aggregatePDFExists = !isNullOrUndefined(artifact?.file?.key);

  // Currently hiding AI switch for any non-admins
  const { isAdmin } = useAdminCheck();

  const organizationConfig = useOrganizationConfig({
    organizationID,
    key: "aiOptIn",
  });

  const accordion = useAccordion({
    details: { artifact },
    items: [
      {
        icon: "icon-docs",
        title: "Artifact",
        component: <div>{detailsHook.display}</div>,
        id: "artifact-details-accordion",
        defaultExpanded: true,
      },
      {
        icon: "icon-badge",
        title: "Evidence",
        component: <ArtifactEvidence />,
        id: "artifact-labels-accordion",
      },
      ...(!forceOldView
        ? [
            {
              icon: "icon-tag",
              title: "Labels",
              headerButtons:
                isAdmin && organizationConfig.value === true
                  ? [
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "1em",
                          marginRight: "1em",
                        }}
                      >
                        <Switch
                          size="small"
                          onClick={(value) => {
                            detailsHook?.setHideAIBookmarks((value) => !value);
                          }}
                          value={detailsHook?.hideAIBookmarks}
                          checked={detailsHook?.hideAIBookmarks}
                          label={"Hide AI Bookmarks"}
                        />
                        <Typography style={{ marginLeft: ".5em" }}>Hide AI</Typography>
                      </div>,
                    ]
                  : [],
              component: (
                <div>
                  <ArtifactLabels
                    artifact={artifact}
                    organizationID={organizationID}
                    disableCreateLabel={true}
                    onLabelLinksUpdated={({ newLabelLinks }) => {
                      detailsHook?.setItem((item) => {
                        return {
                          ...item,
                          labels: { items: newLabelLinks },
                        };
                      });
                    }}
                    showBookmarks={true}
                    onLabelClick={onLabelClick}
                  />
                  <ArtifactLabelsHowToUse />
                </div>
              ),
              defaultExpanded: true,
              id: "artifact-labels-accordion",
            },
          ]
        : []),
      {
        icon: "icon-like",
        title: "Validation",
        component: <ArtifactStatus organizationID={organizationID} resetFunction={detailsHook?.resetFunction} />,
        id: "artifact-status-accordion",
        defaultExpanded: true,
      },
    ],
  });

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {!forceOldView && aggregatePDFExists && (
        <div style={{ marginBottom: ".5em" }}>
          <FeatureAlert
            onClick={onRequestOldView}
            text={`You are viewing an experimental workflow for validating artifacts that is enhanced with AI and annotations.${
              typeof onRequestOldView === "function" ? " Press here if you would like to go back to the old view." : ""
            }`}
          />
        </div>
      )}
      {accordion.display}
      <div style={{ height: "1em" }} />
      <ArtifactComments organizationID={organizationID} artifact={artifact} />
    </div>
  );
};

export default ValidationPanel;
