import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { GenericDeleteButton } from "../../../../utils/GenericComponents/GenericDeleteButton";
import React from "react";
import deleteKeyPerformanceIndicatorLink from "../functions/deleteKeyPerformanceIndicatorLink";

/**
 * A button for deleting a M-M connection item between a KPI and another resource
 * @constructor
 */
const DeleteKPILinkButton = ({ item, typename, resetFunction }) => {
  return (
    <GenericDeleteButton
      stopPropagation={true}
      buttonTitle={"Unlink this Key Performance Indicator"}
      deleteFunction={() => deleteKeyPerformanceIndicatorLink(item, typename)}
      resetFunction={resetFunction}
    />
  );
};

export default withOrganizationCheck(DeleteKPILinkButton);
