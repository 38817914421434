import ProgramReportConfigForm from "../../ProgramReport/components/ProgramReportConfigForm";
import { REPORT_TEMPLATES } from "@rivial-security/report-utils";
import React from "react";
import SelectAssessment from "../../../Testing/Assessments/components/SelectAssessment";
import SelectAudit from "../../../Compliance/Audit/components/SelectAudit";
import SelectControlFramework from "../../../Compliance/Controls/ControlSets/components/SelectControlFramework";
import SelectExercise from "../../../Response/Exercises/components/SelectExercise";
import SelectIncident from "../../../Response/Incidents/components/SelectIncident";
import SelectMeeting from "../../../Program/Meetings/components/SelectMeeting";
import SelectPhishingTest from "../../../Tools/KnowBe4/Phishing/components/SelectPhishingTest";
import SelectSystems from "../../RiskAssessmentReport/components/SelectSystems";
import SelectTraining from "../../../Tools/KnowBe4/Training/components/SelectTraining";
import SelectVendorReview from "../../../Vendor/VendorSolutionReviews/components/SelectVendorReview";
import ShownFieldsSelector from "../../AuditReport/components/ShownFieldsSelector";

/**
 * @description Configurations for the report templates
 * @param {string} templateType - type of template
 * @param {object} config - report configuration object
 * @param {object} additionalFields - additional fields to add to the configuration object
 * @param {boolean} isTemplate - if TRUE will prevent organization resources from being queried
 * @returns {object} - configuration object
 */
export const getTemplateConfiguration = ({ templateType, config, additionalFields = {}, isTemplate = false }) => {
  const templateConfiguration = {
    [REPORT_TEMPLATES.AUDIT_TEMPLATE]: {
      auditID: {
        label: "Audit",
        required: true,
        inputType: "item-select",
        tooltip: "Select which audit to use to generate a report.",
        itemSelectConfig: {
          grid: isTemplate ? <div>Cannot select an Audit when setting up an Automation Template</div> : <SelectAudit />,
        },
        defaultValue: config?.itemId,
        isHidden: config?.itemId,
        disabled: isTemplate,
      },
      shownFields: {
        label: "Shown Control Fields",
        tooltip: "Select which Control Fields to show in the Report tables",
        inputType: "custom",
        defaultValue: [],
        customConfig: {
          component: <ShownFieldsSelector isTemplate={isTemplate} />,
        },
        warningFunction: (value) => {
          if (Array.isArray(value) && value.length > 4) {
            return "Selecting more than 4 fields may cause the controls table to be difficult to read.";
          }
        },
      },
      ...additionalFields,
    },
    [REPORT_TEMPLATES.COMPLIANCE_REPORT_TEMPLATE]: {
      controlFrameworkID: {
        label: "Control Framework",
        required: true,
        inputType: "item-select",
        tooltip: "Select which control framework to use to generate a report.",
        itemSelectConfig: {
          grid: <SelectControlFramework />,
        },
        defaultValue: config?.itemId,
        isHidden: config?.itemId,
        disabled: isTemplate,
      },
      numberOfDays: {
        label: "Date Range - Number of Days",
        tooltip: "How many days in the past should we grab Compliance Changes and Uploaded Evidence from?",
        defaultValue: 30,
        inputType: "number",
        numberConfig: {
          min: 1,
          max: 3650, // ten years
        },
      },
      ...additionalFields,
    },
    [REPORT_TEMPLATES.RA_TEMPLATE]: {
      enableKRINotes: {
        inputType: "switch",
        label: "Include Key Risk Indicator Notes",
      },
      appendixB: {
        inputType: "switch",
        label: "Appendix B: Enterprise Controls",
      },
      appendixC: {
        inputType: "switch",
        label: "Appendix C: Non-Standard Controls by System",
      },
      selectedItems: {
        inputType: "custom",
        customConfig: {
          component: <SelectSystems />,
        },
      },
      ...additionalFields,
    },
    [REPORT_TEMPLATES?.EXECUTIVE_SUMMARY_TEMPLATE]: {
      reportConfig: {
        inputType: "custom",
        customConfig: {
          component: <ProgramReportConfigForm isTemplate={isTemplate} />,
        },
        removeFromInput: true,
      },
      ...additionalFields,
    },
    [REPORT_TEMPLATES.SECURITY_TESTING_TEMPLATE]: {
      assessmentID: {
        label: "Assessment",
        required: true,
        inputType: "item-select",
        tooltip: "Select which assessment to use to generate a report.",
        itemSelectConfig: {
          grid: <SelectAssessment />,
        },
        defaultValue: config?.itemId,
        isHidden: config?.itemId,
      },
      includeInfoVulnerabilities: {
        label: "Include Informational Vulnerabilities",
        tooltip: "Include Informational Vulnerabilities in the report",
        defaultValue: false,
        inputType: "switch",
      },
      sortByPriority: {
        label: "Sort By Priority",
        tooltip:
          "Sort vulnerabilities by priority in the report. If disabled, vulnerabilities will be sorted by severity.",
        defaultValue: true,
        inputType: "switch",
      },
      includeTargetsList: {
        label: "Include Appendix: List of Targets",
        tooltip: "Include Appendix: List of Targets in the report",
        defaultValue: false,
        inputType: "switch",
      },
      includeFalsePositives: {
        label: "Include False Positives",
        tooltip: "Include False Positives in the Appendix and list Affected Assets in the report",
        defaultValue: false,
        inputType: "switch",
      },
      includeExceptions: {
        label: "Include Exceptions",
        tooltip: "Include Exceptions in the Appendix and list Affected Assets in the report",
        defaultValue: false,
        inputType: "switch",
      },
      includeTopXVulnerabilities: {
        label: "Include Top Vulnerabilities List",
        inputType: "number",
        numberConfig: {
          min: 0,
          max: 1000,
          step: 1,
        },
        validationFunction: (input) => {
          if (input === "" || input === null || input === undefined) return false;
          return !(input < 0 || input > 1000);
        },
        defaultValue: 10,
        tooltip:
          "Specify how many top vulnerabilities include in the report. Set to zero to not include this section in the report.",
      },
      ...additionalFields,
    },
    [REPORT_TEMPLATES.VENDOR_REVIEW_TEMPLATE]: {
      vendorReviewID: {
        label: "Vendor Review",
        required: true,
        inputType: "item-select",
        tooltip: "Select which vendor review to use to generate a report.",
        itemSelectConfig: {
          grid: <SelectVendorReview />,
        },
        defaultValue: config?.itemId,
        isHidden: config?.itemId,
      },
      ...additionalFields,
    },
    [REPORT_TEMPLATES.VENDOR_REVIEW_CONTROLS_TEMPLATE]: {
      vendorReviewID: {
        label: "Vendor Review",
        required: true,
        inputType: "item-select",
        tooltip: "Select which vendor review to use to generate a report.",
        itemSelectConfig: {
          grid: <SelectVendorReview />,
        },
        defaultValue: config?.itemId,
        isHidden: config?.itemId,
      },
      ...additionalFields,
    },
    [REPORT_TEMPLATES.INCIDENT_RESPONSE_EXERCISE_TEMPLATE]: {
      exerciseID: {
        label: "Exercise",
        required: true,
        inputType: "item-select",
        tooltip: "Select which exercise to use to generate a report.",
        itemSelectConfig: {
          grid: <SelectExercise />,
          outputAsObject: true,
        },
        defaultValue: config?.itemId,
        isHidden: config?.itemId,
      },
      ...additionalFields,
    },
    [REPORT_TEMPLATES.INCIDENT_RESPONSE_INCIDENT_TEMPLATE]: {
      incidentID: {
        label: "Incident",
        required: true,
        inputType: "item-select",
        tooltip: "Select which incident to use to generate a report.",
        itemSelectConfig: {
          grid: <SelectIncident />,
          formatSelectedItem: (incident) => incident?.what,
          outputAsObject: true,
        },
        defaultValue: config?.itemId,
        isHidden: config?.itemId,
      },
      ...additionalFields,
    },
    [REPORT_TEMPLATES.BLUEPRINT_TEMPLATE]: {
      appendixA: {
        inputType: "switch",
        defaultValue: false,
        label: "Appendix A: RACI Diagram",
        tooltip: "Include Appendix A: RACI Diagram in the report",
      },
      ...additionalFields,
    },
    [REPORT_TEMPLATES.MEETING_TEMPLATE]: {
      meetingID: {
        label: "Meeting",
        required: true,
        inputType: "item-select",
        tooltip: "Select which meeting to use to generate a report.",
        itemSelectConfig: {
          grid: <SelectMeeting />,
        },
        defaultValue: config?.itemId,
        isHidden: config?.itemId,
      },
      ...additionalFields,
    },
    [REPORT_TEMPLATES.FINDINGS_TEMPLATE]: {
      auditID: {
        defaultValue: config?.auditID,
        isHidden: true,
      },
      sourceID: {
        defaultValue: config?.sourceID,
        isHidden: true,
      },
      complianceControlID: {
        defaultValue: config?.complianceControlID,
        isHidden: true,
      },
      evidenceID: {
        defaultValue: config?.evidenceID,
        isHidden: true,
      },
      exerciseID: {
        defaultValue: config?.exerciseID,
        isHidden: true,
      },
      riskControlID: {
        defaultValue: config?.riskControlID,
        isHidden: true,
      },
      incidentID: {
        defaultValue: config?.incidentID,
        isHidden: true,
      },
      vulnerabilityID: {
        defaultValue: config?.vulnerabilityID,
        isHidden: true,
      },
      assessmentID: {
        defaultValue: config?.assessmentID,
        isHidden: true,
      },
      findingsOnly: {
        label: "Findings Only",
        defaultValue: false,
        inputType: "switch",
        tooltip: "Include only Findings, or both Observations and Findings",
      },
      resolvedRecommendations: {
        label: "Include Resolved Recommendations",
        defaultValue: false,
        inputType: "switch",
        tooltip: "Choose to also include any Resolved Recommendations",
      },
      completedActionItems: {
        label: "Include Completed Action Items",
        defaultValue: false,
        inputType: "switch",
        tooltip: "Choose to also include any Completed Action Items",
      },
      includeNotes: {
        label: "Include Notes",
        defaultValue: false,
        inputType: "switch",
        tooltip: "Choose whether to include notes for shown resources",
      },
      ...additionalFields,
    },
    [REPORT_TEMPLATES.PHISHING_TEMPLATE]: {
      itemId: {
        label: "Phishing Test",
        required: true,
        inputType: "item-select",
        tooltip: "Select which phishing test to use to generate a report.",
        itemSelectConfig: {
          grid: <SelectPhishingTest />,
        },
        defaultValue: config?.itemId,
        isHidden: config?.itemId,
      },
      ...additionalFields,
    },
    [REPORT_TEMPLATES.TRAINING_TEMPLATE]: {
      itemId: {
        label: "Training",
        required: true,
        inputType: "item-select",
        tooltip: "Select which training to use to generate a report.",
        itemSelectConfig: {
          grid: <SelectTraining />,
        },
        defaultValue: config?.itemId,
        isHidden: config?.itemId,
      },
      ...additionalFields,
    },
  };

  return templateConfiguration[templateType] || {};
};
