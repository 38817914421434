import { generateGraphql } from "@rivial-security/generategraphql";
import { getProceduresNestedFields } from "../../PlaybookBuilder/functions/getProceduresNestedFields";

export const updateIncidentMutation = generateGraphql(
  "Incident",
  [
    "status",
    "what",
    "who",
    "when",
    "where",
    "why",
    "notes",
    "documents",
    "meeting",
    "playbook",
    "procedures",
    "detection",
    "classificationTier",
    "classificationTierID",
  ],
  {
    playbook: `{id name description}`,
    notes: `{ id type ownerGroup author timeStamp content tag observationID }`,
    ...getProceduresNestedFields({ includeNotes: true }),
    documents: `(limit: 500) { items { id createdAt name avStatus lastAVCheck file { key bucket region } ownerGroup } }`,
    meeting: `{id name}`,
    detection: `{name description}`,
    classificationTier: `
      {
        id name description level
        teams (limit: 100) {
          items {
            id
            team {
              id
              name
              description
              pointOfContactLinks (limit: 100) {
                items {
                  id
                  pointOfContact {
                    id
                    firstName
                    lastName
                    title
                    email
                    phone
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
).updateMutation;
