import { modules, resources } from "@rivial-security/role-utils";

import MitreAttackMitigationDetails from "../components/MitreAttackMitigationDetails";
import { useDataGrid } from "../../../../../hooks/views/useDataGrid/useDataGrid";

/**
 * @description Display list of Mitre Attack mitigations hook
 * @param {string} organizationID - selected organization ID
 * @param {object[]} fields - fields to display
 * @param {object} props - other props for the hook
 */
export const useMitreAttackMitigationsDataGrid = ({ organizationID, fields = [], ...props }) => {
  const module = modules.TOOLS;
  const resource = resources.MITRE_ATTACK_MITIGATION;

  return useDataGrid({
    module,
    resource,
    detailsTitle: "Mitigation Details",
    detailsComponent: <MitreAttackMitigationDetails />,
    title: "Mitre Att&ck Mitigations",
    headerIcon: "icon-wrench",
    persistenceUUID: "a0023a33-4cd0-48ec-924e-1ac59e27a63c",
    fields,
    organizationID,
    ...props,
  });
};
