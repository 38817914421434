import React, { useContext, useEffect, useState } from "react";
import { isNullOrUndefined, removeObjectFromArray } from "@rivial-security/func-utils";

import { Button } from "reactstrap";
import { CreateVendorCertificationControlLink } from "../functions/CreateVendorCertificationControlLink";
import { UIContext } from "../../../../utils/Context/UIContext";
import { getMany } from "../../../../utils/Functions/getMany";
import { useVendorSubControlDataGrid } from "../../VendorSubControls/hooks/useVendorSubControlDataGrid";
import { useVendorSubControlTemplates } from "../hooks/useVendorSubControlTemplates";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * @description Add controls to a vendor certification
 * @param {object} item - Vendor Certification
 * @param {function} toggleModal
 * @param {function} resetFunction
 * @param {string} organizationID
 * @returns {JSX.Element}
 * @constructor
 */
const AddControlsToCertification = ({ item, toggleModal, resetFunction, organizationID }) => {
  const [selectedControls, setSelectedControls] = useState([]);

  const isSubmitEnabled = selectedControls && selectedControls.length > 0;

  // disable query for vendor sub control list - we are setting data manually
  const queryConfig = {
    query: null,
  };

  const vendorSubControlGrid = useVendorSubControlDataGrid({
    organizationID,
    queryConfig,
    enableSelection: true,
    resetFunction,
  });

  // get vendor sub control templates
  const { subControls } = useVendorSubControlTemplates(organizationID, vendorSubControlGrid?.setIsLoading);

  /**
   * Sets the Vendor Sub Control list data and removes all Sub Controls that are already included in this cert
   */
  useEffect(() => {
    vendorSubControlGrid.setIsLoading(true);
    if (!isNullOrUndefined(subControls) && Array.isArray(subControls)) {
      // get existing sub controls for this cert
      const existingControls = getMany(item, "controlLinks", "control");

      // temporary array for removal operation
      let res = [...subControls];

      // go through existing controls and remove them from the sub control template array
      for (const existingControl of existingControls) {
        res = removeObjectFromArray(res, existingControl, "statementNumber");
      }

      // sort data by statement number
      res.sort((a, b) => a.statementNumber - b.statementNumber);

      // set vendor sub control list data
      vendorSubControlGrid.setData(res);
    }
    vendorSubControlGrid.setIsLoading(false);
  }, [subControls]);

  const { addToast, updateToast } = useContext(UIContext);

  useEffect(() => {
    if (Array.isArray(vendorSubControlGrid?.selectedData)) {
      setSelectedControls(vendorSubControlGrid?.selectedData);
    }
  }, [JSON.stringify(vendorSubControlGrid?.selectedData)]);

  const createControlCertificationLink = () => {
    const toastId = addToast({
      header: `Adding Sub Controls...`,
      icon: "spinner",
    });
    CreateVendorCertificationControlLink({
      controls: selectedControls,
      certification: item,
    }).then((controlsFailed) => {
      toggleModal && toggleModal();
      resetFunction && resetFunction();
      if (controlsFailed === 0) {
        updateToast({
          id: toastId,
          header: `Added ${selectedControls.length} Sub Controls`,
          icon: "success",
        });
      } else if (controlsFailed >= 1) {
        updateToast({
          id: toastId,
          header: `Added ${selectedControls.length - controlsFailed} out of ${
            selectedControls.length
          } Sub Controls. Some Sub Controls could not be added.`,
          icon: "warning",
        });
      } else {
        updateToast({
          id: toastId,
          header: `Failed to add all Sub Controls`,
          icon: "danger",
        });
      }
    });
  };

  return (
    <>
      <div style={{ height: "30em" }}>{vendorSubControlGrid.display}</div>
      <div className="float-right">
        <Button
          disabled={!isSubmitEnabled}
          onClick={() => {
            createControlCertificationLink();
          }}
          color="success"
        >
          Submit
        </Button>
        <Button color="secondary" style={{ margin: "1em" }} onClick={() => toggleModal && toggleModal()}>
          Cancel
        </Button>
      </div>
    </>
  );
};

export default withOrganizationCheck(AddControlsToCertification);
