import { generateGraphql } from "@rivial-security/generategraphql";
import {
  Maybe,
  VendorControlCategory,
  VendorDocument,
  VendorReview,
  VendorSubControl,
} from "@rivial-security/schema-types";
import { GetQuery, ItemMutation } from "@rivial-security/appsync-utils";
import { createVendorControlDocumentLink } from "@views/Vendor/VendorSubControls/functions/createVendorControlDocumentLink";

export interface MarkCertificationControlsAsAuditedParams {
  documents: VendorDocument[];
  vendorReviewID: string;
}

export const markCertificationControlsAsAudited = async ({
  documents,
  vendorReviewID,
}: MarkCertificationControlsAsAuditedParams): Promise<void> => {
  for (const document of documents) {
    await processVendorDocument({ documentID: document.id, vendorReviewID });
  }
};

interface ProcessVendorDocumentParams {
  documentID: string;
  vendorReviewID: string;
}

const processVendorDocument = async ({ documentID, vendorReviewID }: ProcessVendorDocumentParams): Promise<void> => {
  const document = await GetQuery<VendorDocument>({
    query: getVendorDocumentQuery,
    variables: { id: documentID },
  });
  const certificationControlLinks = document?.vendorCertification?.controlLinks?.items || [];

  const review = await GetQuery<VendorReview>({
    query: getVendorReviewQuery,
    variables: { id: vendorReviewID },
  });
  const controlCategories = review?.controlCategories?.items || [];

  const subControlUpdateRequests: Promise<void>[] = [];
  for (const link of certificationControlLinks) {
    const { name, statementNumber } = link?.control || {};
    if (!name || !statementNumber) continue;

    const foundSubControl = findSubControl({ statementNumber, name, controlCategories });
    if (!foundSubControl) continue;

    subControlUpdateRequests.push(
      updateSubControl({
        documentID,
        subControlID: foundSubControl.id,
        inPlace: true,
        audited: true,
      }),
    );
  }

  await Promise.allSettled(subControlUpdateRequests);
};

interface UpdateSubControlParams {
  documentID: string;
  subControlID: string;
  inPlace: boolean;
  audited: boolean;
}

const updateSubControl = async ({
  documentID,
  subControlID,
  inPlace,
  audited,
}: UpdateSubControlParams): Promise<void> => {
  const { updateMutation: updateVendorSubControl } = generateGraphql("VendorSubControl", ["inPlace", "audited"]);
  await ItemMutation({
    mutation: updateVendorSubControl,
    input: {
      id: subControlID,
      inPlace,
      audited,
    },
  });

  await createVendorControlDocumentLink({
    document: { id: documentID },
    control: { id: subControlID },
  });
};

interface FindSubControlParams {
  statementNumber: string;
  name: string;
  controlCategories: Maybe<VendorControlCategory>[];
}

const findSubControl = ({
  statementNumber,
  name,
  controlCategories,
}: FindSubControlParams): Maybe<VendorSubControl> | undefined => {
  const controls: Maybe<VendorSubControl>[] = [];

  for (const controlCategory of controlCategories) {
    controls.push(...(controlCategory?.subControls?.items || []));
  }

  return controls.find((control: Maybe<VendorSubControl>) => {
    return control?.statementNumber === statementNumber && control?.name === name;
  });
};

const { getQuery: getVendorDocumentQuery } = generateGraphql("VendorDocument", ["vendorCertification"], {
  vendorCertification: `{
    id
    controlLinks (limit: 100) {
      items {
        control {
          id
          name
          statementNumber
        }
      }
      nextToken
    }
  }`,
});

const { getQuery: getVendorReviewQuery } = generateGraphql("VendorReview", ["controlCategories"], {
  controlCategories: `(limit: 100) {
    items {
      id
      ownerGroup
      name
      subControls (limit: 100) {
       items {
        id
        name
        statementNumber
         ownerGroup
          inPlace
          audited
        }
      }
    }
  }`,
});
