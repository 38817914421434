import React from "react";
import CustomTooltip from "../../CustomTooltip";
import EditButton from "../../buttons/EditButton";
import { useGUID } from "../../../../hooks/functional/useGUID";
import HighlightedText from "../../HighlightedText";

/**
 * Container for a dashboard accordion with a title
 * @param title
 * @param content
 * @param children
 * @param tooltip
 * @param {function} onEdit - pass in this function to show an edit button on the side of the title
 * @param style
 * @param highlightedText
 * @return {*}
 * @constructor
 */
const DashboardCardSection = ({ title, content, children, tooltip, onEdit, style = {}, highlightedText }) => {
  const [id] = useGUID();

  return (
    <div style={{ marginBottom: "1.5em", ...style }}>
      {title && (
        <h5
          style={{
            fontColor: "var(--sidebar-background)",
            fontSize: "0.8em",
            textTransform: "uppercase",
            fontStyle: "bold",
          }}
        >
          <HighlightedText highlight={highlightedText} text={title} style={{ color: "#ff5151" }} />
          {tooltip && <CustomTooltip tooltip={tooltip} targetId={id} />}
          {onEdit && <EditButton style={{ display: "inline" }} onClick={onEdit} color={"link"} typename={title} />}
        </h5>
      )}
      {content || children}
    </div>
  );
};

export default DashboardCardSection;
