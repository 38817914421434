import React, { useContext } from "react";
import { useForm } from "../../../../../hooks/views/useForm/useForm";
import { usePreferences } from "../../../../../hooks/views/usePreferences/usePreferences";
import { UIContext } from "../../../../../utils/Context/UIContext";
import { UncontrolledTooltip } from "reactstrap";
import { useCard } from "../../../../../hooks/views/useCard";

/**
 * @description Interface Grid Persistence Component for saving a grid settings
 * @return {JSX.Element}
 * @constructor
 */
const InterfaceGridPersistence = () => {
  const { addToast } = useContext(UIContext);

  const preferences = usePreferences();

  const gridPersistenceForm = useForm({
    disableSubmitButton: true,
    disableResetButton: true,
    fieldConfig: {
      gridPersistence: {
        inputType: "switch",
        label: "Enable Data Grid Persistence",
        defaultValue: preferences?.getPreference("general", "grid_persistence") || false,
        onChangeFunction: ({ gridPersistence }) => {
          preferences?.setPreference("general", {
            grid_persistence: gridPersistence,
          });
          addToast({
            header: `Grid Persistence was changed to ${gridPersistence ? "enabled" : "disabled"}`,
            icon: "success",
          });
        },
      },
    },
  });

  const card = useCard({
    id: "data-grid-persistence",
    title: (
      <>
        <h4 style={{ display: "inline" }}>Data Grid Persistence</h4>{" "}
        <i id="data-grid-persistence-icon-question" className="icon-question" />
        <UncontrolledTooltip placement="right" target="data-grid-persistence-icon-question">
          If Enabled, View Settings for Data Grids will be saved to your browser storage
        </UncontrolledTooltip>
      </>
    ),
    body: <>{gridPersistenceForm.display}</>,
  });

  return card?.display;
};

export default InterfaceGridPersistence;
