import { ErrorLogger } from "../../EventLogger";
import { ItemMutation } from "./ItemMutation";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 * Allows to perform an item update mutation with a rollback operation if the mutation failed
 * @param {string} typename - the typename that is being modified
 * @param {object} input - all new information for the mutation, must include item id
 * @param {function} [preMutationOperation] - function to perform before mutation happens, such as setting state
 * @param {function} [rollbackOperation] - function to perform if mutation fails, such as resetting state
 * @returns {Promise<void>}
 */
export const mutationWithRollback = async ({ typename, input, preMutationOperation, rollbackOperation }) => {
  //Check arguments
  if (!typename || !input) {
    return;
  }

  //Perform front end update
  if (typeof preMutationOperation === "function") {
    await preMutationOperation();
  }

  //Attempt backend update and rollback if it fails
  try {
    await ItemMutation(generateGraphql(typename).updateMutation, input);
  } catch (e) {
    ErrorLogger(e);
    if (typeof rollbackOperation === "function") {
      await rollbackOperation();
    }
  }
};
