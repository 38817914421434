import * as Sentry from "@sentry/react";

import { ErrorLogger } from "@utils/EventLogger";
import { ItemMutation } from "@utils/Functions/Graphql/ItemMutation";
import { OrganizationContext } from "@utils/Context/OrganizationContext";
import { QueryGetItem } from "@hooks/graphql/useQueryGetItem";
import { generateGraphql } from "@rivial-security/generategraphql";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useContext } from "react";
import { useMutation } from "@hooks/graphql/useMutation/useMutation";

/**
 * Description: Handles UI preferences for a particular User Account.
 *              Preferences are saved as a JSON string in the database.
 *
 *              The JSON object is formatted by component -> preference
 *
 *              Example:
 *              preferences = {
 *                  component1: {
 *                      option1: true,
 *                      option2: "somePreferenceString"
 *                      option3: {someComplexPreference1: true, someComplexPreference2: "foobar"}
 *                  },
 *                  systemDetails: {
                      autoCalc: true,
                      viewType: "analysis"
                    }
 *              }
 *
 * @returns {import('./types/usePreferences').UsePreferencesReturn}
 */
export const usePreferences = () => {
  const { preferences, setPreferences, loggedInUserId } = useContext(OrganizationContext);

  const updatePreferenceHook = useMutation({
    mutation: updateUser_preferences,
    disableRoleChecking: true,
  });

  /**
   * Updates the preferences object in the database and locally
   * @param {string} component - a string indicating the parent "components". Components can have multiple preferences
   * @param {object} option - single Object containing a key value pair
   */
  const setPreference = async (component, option) => {
    try {
      const { getQuery } = generateGraphql("User", ["preferences"]);
      const currentUser = await QueryGetItem({
        query: getQuery,
        itemId: loggedInUserId,
      });

      const currentPreferences = currentUser?.preferences;
      let networkPreferences = {};
      if (currentPreferences) {
        try {
          networkPreferences = JSON.parse(currentPreferences) || {};
        } catch (e) {
          networkPreferences = {};
        }
      }

      const res = {
        ...networkPreferences,
        [component]: {
          ...networkPreferences[component],
          ...option,
        },
      };

      const { updateMutation } = generateGraphql("User", ["preferences"]);
      const newUser = await ItemMutation(updateMutation, {
        id: loggedInUserId,
        preferences: JSON.stringify({ ...res }),
      });

      let newPreferences;
      try {
        newPreferences = JSON.parse(newUser.preferences) || {};
      } catch (e) {
        newPreferences = networkPreferences || {};
      }
      setPreferences({ ...newPreferences });

      return { ...newPreferences };
    } catch (e) {
      ErrorLogger("Cannot set user preferences", e);
      Sentry.captureMessage(`Cannot set user preferences: ${JSON.stringify(e)}`, {
        level: "warning",
      });
    }

    //Returns old preferences if failed to update
    return preferences;
  };

  const getPreference = (component, optionKey) => {
    if (preferences && preferences[component] && preferences[component][optionKey] !== null) {
      return preferences[component][optionKey];
    } else return null;
  };

  const getNetworkPreference = async (component, optionKey) => {
    try {
      const { getQuery } = generateGraphql("User", ["preferences"]);
      const user = await QueryGetItem({
        query: getQuery,
        itemId: loggedInUserId,
      });
      const networkPreferences = JSON.parse(user?.preferences);
      if (!isNullOrUndefined(networkPreferences?.[component]?.[optionKey])) {
        return networkPreferences[component][optionKey];
      } else {
        return null;
      }
    } catch (e) {
      ErrorLogger("Could not retrieve preferences from the data base!", e);
      return null;
    }
  };

  // Clears all preferences for a specific component
  const clearPreference = (component) => {
    const res = {
      ...preferences,
      [component]: {},
    };

    updatePreferenceHook.editItem({
      id: loggedInUserId,
      preferences: JSON.stringify({ ...res }),
    });

    setPreferences({ ...res });

    return { ...res };
  };

  return {
    preferences: preferences ? preferences : {},
    setPreference,
    getPreference,
    clearPreference,
    getNetworkPreference,
  };
};

export const updateUser_preferences = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      name
      email
      ownerGroup
      preferences
    }
  }
`;
