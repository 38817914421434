import React, { useEffect, useState } from "react";

import DashboardCard from "../../../../../../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import EvidenceDetails from "../../../../../../../../Compliance/Evidence/components/EvidenceDetails";
import { GetQuery } from "../../../../../../../../../utils/Functions/Graphql/GetQuery";
import Loader from "../../../../../../../../../utils/LoadingComponents/Loader";
import UnderlinedTooltip from "../../../../../../../../../utils/Tooltips/UnderlinedTooltip";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useHistory } from "react-router-dom";

/**
 * Allows viewing the most important information about attached evidence, both if a control is standard or non-standard
 * @param {object} riskControl - the sub control information from system
 * @param {object} system - the system data where the sub control is being viewed
 * @param {object} style - styles for the headings and content container
 * @param {boolean} isNonStandard - TRUE if the non-standard switch is enabled for this control in this system
 * @param {object} [item] - in some cases the item is a risk control override which contains system information
 * @param {boolean} [includeHeader=true] - TRUE if the header should be included
 * @return {JSX.Element}
 */
const RiskControlEvidenceList = ({
  riskControl,
  system,
  style,
  isNonStandard = false,
  item,
  includeHeader = true,
} = {}) => {
  const [associatedEvidence, setAssociatedEvidence] = useState(null);
  const history = useHistory();

  const getData = async () => {
    const systemId = system?.id || item?.system?.id;

    if (!riskControl?.id || !systemId) {
      setAssociatedEvidence([]);
      return;
    }

    //Query for a risk control and all of its evidence links
    const { getQuery } = generateGraphql("RiskControl", ["evidences"], {
      evidences: `(limit: 1000) {
        items {
          id
          system {
            id
          }
          evidence {
            id
            ownerGroup
            name
            status
            enabled
            status
            pointOfContacts (limit: 100) { items { id pointOfContact { id firstName lastName email title actions(limit: 100) { items { id name status createdAt data } } } } }
            tags (limit: 100) { items { __typename id tag { id name description fontColor backgroundColor } } }
          }
        }
      }`,
    });
    const currentRiskControl = await GetQuery({
      query: getQuery,
      variables: {
        id: riskControl?.id,
      },
    });
    const evidenceLinks = currentRiskControl?.evidences?.items;

    const evidenceItems = [];
    if (Array.isArray(evidenceLinks)) {
      if (isNonStandard) {
        // filter out any evidence that is not associated with the system
        for (const evidenceLink of evidenceLinks) {
          const evidence = evidenceLink.evidence;

          if (evidenceLink?.system?.id !== systemId) {
            continue;
          }

          evidenceItems.push(evidence);
        }
      } else {
        // filter out any evidence that is associated with any system (non-standard controls)
        for (const evidenceLink of evidenceLinks) {
          const evidence = evidenceLink.evidence;

          if (evidenceLink?.system?.id) {
            continue;
          }

          evidenceItems.push(evidence);
        }
      }
    }

    //Set the data
    setAssociatedEvidence(evidenceItems);
  };
  useEffect(() => {
    getData();
  }, [isNonStandard]);

  return (
    <div style={style?.div ?? null}>
      {includeHeader && (
        <strong>
          Attached Evidence
          <i
            style={style?.icon ?? null}
            className="icon-question"
            title="The compliance evidence that determines this control's implementation rating."
          />
        </strong>
      )}
      {!associatedEvidence && (
        <div>
          <Loader />
          {" Loading..."}
        </div>
      )}
      {associatedEvidence && (!Array.isArray(associatedEvidence) || associatedEvidence.length === 0) && (
        <div style={{ fontStyle: "italic", color: "lightGrey" }}>No Evidence Attached</div>
      )}
      {Array.isArray(associatedEvidence) &&
        associatedEvidence.length > 0 &&
        associatedEvidence.map((evidence) => {
          const text = evidence?.name;

          return (
            <div style={{ opacity: evidence?.enabled === true ? 1 : 0.3 }}>
              <UnderlinedTooltip
                text={text}
                onClick={() => {
                  history.push(`/continuous_compliance/evidence/${evidence?.id}`);
                }}
              >
                <DashboardCard>
                  <EvidenceDetails item={evidence} />
                </DashboardCard>
              </UnderlinedTooltip>
              {" - "}
              <span
                style={{
                  color: evidence?.status === "inPlace" ? "green" : "red",
                }}
              >
                {evidence?.status === "inPlace" ? "In Place" : "Not In Place"}
              </span>
              {evidence?.enabled !== true && <span> (Disabled) </span>}
              <br />
            </div>
          );
        })}
    </div>
  );
};

export default RiskControlEvidenceList;
