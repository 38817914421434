import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import React, { useState } from "react";

import DashboardCard from "../../../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import { EVIDENCE_ACTIVITY_TYPES } from "../../../../../../typedefs/Compliance/Evidence/Evidence";
import SubmitButton from "../../../../../../utils/GenericComponents/buttons/SubmitButton";

/**
 * Attestation input for Evidence Landing Page
 * Allows the user to select 'In Place' or 'Not in Place' checkboxes
 * @param {function} onSubmit - calls this function when the submit button is pressed
 * @param {string} submitButtonText - the text to display in form submit button
 */
const AttestationForm = ({ onSubmit, submitButtonText = "Next" } = {}) => {
  const [attested, setAttested] = useState(null);

  /**
   * Generates the activityInput for the onSubmit function callback
   */
  const submit = () => {
    const activityInput = {
      type: EVIDENCE_ACTIVITY_TYPES.ATTESTED,
      attested,
    };
    onSubmit && onSubmit(activityInput);
  };

  return (
    <div>
      <DashboardCard title="Attestation">
        <Row>
          <Col>
            <FormGroup check>
              <>
                <span style={{ marginRight: "4em" }}>
                  <Input
                    checked={attested === true}
                    color="primary"
                    style={{ width: "2rem", height: "2rem" }}
                    type="checkbox"
                    onChange={() => setAttested(attested !== true ? true : null)}
                  />
                  <Label style={{ paddingTop: "0.5rem", marginLeft: "1.5em" }}>This Evidence is in Place</Label>
                </span>
                <span>
                  <Input
                    checked={attested === false}
                    color="primary"
                    style={{ width: "2rem", height: "2rem" }}
                    type="checkbox"
                    onChange={() => setAttested(attested !== false ? false : null)}
                  />
                  <Label style={{ paddingTop: "0.5rem", marginLeft: "1.5em" }}>This Evidence is Not in Place</Label>
                </span>
              </>
            </FormGroup>
            {attested !== null && (
              <SubmitButton
                style={{ width: "100%", marginTop: "3em" }}
                onClick={submit}
                buttonText={submitButtonText}
              />
            )}
          </Col>
        </Row>
      </DashboardCard>
    </div>
  );
};

export default AttestationForm;
