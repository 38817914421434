import { useAuditGeneralInformation } from "../hooks/useAuditGeneralInformation";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * A component instance of the useAuditGeneralInformation.js hook
 * @param {object} props
 */
const AuditGeneralInformation = (props) => {
  return useAuditGeneralInformation(props).display;
};

export default withOrganizationCheck(AuditGeneralInformation);
