import { ErrorLogger } from "@utils/EventLogger";
import { addManualStatusChange } from "./addManualStatusChange";
import { updateManualFixTagConnection } from "./updateManualFixTagConnection";

/**
 * Checks if the status of target vulnerability link should change
 * @param {object} oldItem - item with status and targetFindingLinkID fields of the link before the change
 * @param {object} newItem - item with status and targetFindingLinkID fields of the link after the change
 * @return {Promise<void>}
 */
export const changeTargetLinkStatus = async ({ oldItem, newItem }) => {
  try {
    const changePromises = [];
    changePromises.push(
      addManualStatusChange({
        oldItem,
        newItem,
      }),
    );
    changePromises.push(
      updateManualFixTagConnection({
        oldItem,
        newItem,
      }),
    );
    await Promise.allSettled(changePromises);
  } catch (e) {
    ErrorLogger("Unable to fully process a manual vulnerability target link status change!", e);
  }
};
