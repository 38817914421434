import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Input,
  InputGroup,
  InputGroupAddon,
  Pagination,
  PaginationItem,
  PaginationLink,
  Spinner,
} from "reactstrap";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { convertCamelCaseToSentence, isValidDate } from "@rivial-security/func-utils";

import { GenericModalWithButton } from "../../utils/GenericComponents/GenericModalWithButton";
import Loader from "../../utils/LoadingComponents/Loader";
import { OrganizationContext } from "../../utils/Context/OrganizationContext";
import { remapPermissions } from "../permissions/functions/remapPermissions";
import { useCheckPermissions } from "../permissions/useCheckPermissions/useCheckPermissions";
import { useExport } from "../functional/useExport";
import { useSearchBar } from "./useSearchBar/useSearchBar";
import { useTable } from "./useTable";
import { useTags } from "../../utils/Tags/hooks/useTags";

/**
 * Author: Jacob Blazina
 * Created At: 10/22/19
 *
 * Description: A Custom Hook.
 *              Combines the useTable hook and useSearchBar hook in a Card component with pagination.
 *
 *              Note: If the data array is on a subscription, the parent component MUST
 *                    update this component using the provided `setData` attribute callback.
 *
 * @param {object[]} data -
 *              Pass-through for useTable hook. See [useTable.js] documentation.
 * @param {string} header -
 *              A string or component do display as the Card Header text in the top-left of the DataCard.
 *              Ex. "Item List"
 * @param {string[]} fields -
 *              Pass-through for useTable hook. See [useTable.js] documentation.
 * @param {string[]} options -
 *              Pass-through for useTable hook. See [useTable.js] documentation.
 * @param {React.JSXElement[]}headerButtons -
 *              An array of React components to be used in a ButtonGroup located in the Top-Right of the DataCard.
 *              Ex. [ <Button>Create Item</Button>, <Button>Send Email</Button ]
 * @param {function} deleteFunction -
 *              Pass-through for useTable hook. See [useTable.js] documentation.
 * @param {string} route -
 *              Pass-through for useTable hook. See [useTable.js] documentation.
 * @param {object[]} paginatedData -
 *              Pre-sets the pageData used in case a list is already paginated. Not used often.
 * @param {React.JSXElement} rowComponent -
 *              Pass-through for useTable hook. See [useTable.js] documentation.
 * @param {object} fieldNameDictionary -
 *              Pass-through for useTable hook. See [useTable.js] documentation.
 * @param {React.JSXElement} detailsComponent -
 *              Pass-through for useTable hook. See [useTable.js] documentation.
 * @param {boolean} exportTable -
 *              Boolean, enables functionality to save table content to a CSV file
 * @param {function} resetFunction -
 *              A Javascript Function that gets wired up to a "refresh data" button in the Top-Right of the data card.
 *              The "refresh data" button only shows if this function is present. Used primarily to refresh queries.
 *              Ex. () => listItemsQuery.reset()
 * @param {object} config -
 *              An object representing misc. configuration settings.
 *              Some of these config settings are used directly for this useDataCard hook, and the rest are passed
 *              on to the useTable hook.
 *              See [useTable.js] for it's corresponding config documentation.
 *              Current Settings:
 *              config: {
 *                limit     // pre-sets the limit that is used in the pagination
 *              }
 * @param customFields -
 *              Pass-through for useTable & useSearchBar hook. See [useTable.js] & [useSearchBar.js] documentation.
 * @param popover -
 *              Pass-through for useTable hook. See [useTable.js] documentation.
 * @param enableNotes -
 *              Pass-through for useTable hook. See [useTable.js] documentation.
 * @param roleConfig -
 *              Pass-through for useTable hook. See [useTable.js] documentation.
 * @param module -
 *              Pass-through for useTable hook. See [useTable.js] documentation.
 * @param resource -
 *              Pass-through for useTable hook. See [useTable.js] documentation.
 * @param updateMutation -
 *              Pass-through for useTable hook. See [useTable.js] documentation.
 * @param deleteMutation -
 *              Pass-through for useTable hook. See [useTable.js] documentation.
 * @param createResourceComponent -
 *              Pass-through for useTable hook. See [useTable.js] documentation.
 * @param typename -
 *              Pass-through for useTable hook. See [useTable.js] documentation.
 * @param buttonCallback -
 *              Pass-through for useTable hook. See [useTable.js] documentation.
 * @returns {object}
 * {{
 *    display: Component,                  The ReactStrap Card UI that gets generated by this hook.
 *    setData: function(Array),            Sets the raw data array that is passed as input into the useSearchBar hook
 *    setPaginatedData: function(Array),   Sets the raw pageData array used for pagination
 *    loading: bool,                       The boolean indicating if the large loading UI is showing instead of the useTable display
 *    setLoading: function(bool),          Sets the boolean for the large loading UI that shows instead of the useTable display
 *    miniLoading: bool,                   The boolean indicating if the small loading UI is showing at the top-left corner of the Card
 *    setMiniLoading: function(bool),      Sets the boolean for the small loading UI that shows at the top-left corner of the Card
 *    limit: Integer,                      The currently selected Limit used for pagination
 *    setLimit: function(Integer),         Sets the Integer for the Limit used for pagination
 *    setSortField: (function(field, direction)), Pass-through for useTable hook. See [useTable.js] documentation.
 *    data: Array,                         Pass-through for useSearchBar hook (searchResults). See [useTable.js] documentation.
 *    tableData: Array,                    Pass-through for useTable hook (current table page data). See [useTable.js] documentation.
 *    selectedItems: Array,                Pass-through for useTable hook (current selected items). See [useTable.js] documentation.
 *    setSelectedItems: (function(bool)),  Pass-through for useTable hook (sets selected items). See [useTable.js] documentation.
 *    setItems: (function(Array)),         Pass-through for useTable hook. See [useTable.js] documentation.
 *    showSelectBoxes: boolean,            Pass-through for useTable hook. See [useTable.js] documentation.
 *    setShowSelectBoxes: (function(bool)), Pass-through for useTable hook. See [useTable.js] documentation.
 *    addFilter: function(object),         Pass-through for useTable hook. See [useTable.js] documentation.
 *    filters: Array,                      Pass-through for useTable hook. See [useTable.js] documentation.
 *    setFilters: function(Array)          Pass-through for useTable hook. See [useTable.js] documentation.
 * }}
 */

export const useDataCard = ({
  data,
  header,
  fields,
  hideFields,
  options,
  customOptions,
  headerButtons = [],
  deleteFunction,
  deleteGqlQuery,
  route,
  paginatedData,
  rowComponent,
  fieldNameDictionary,
  detailsComponent,
  resetFunction,
  config = {},
  customFields,
  popover,
  enableNotes,
  exportTable,
  exportTableConfig = {
    transformNotes: false,
  },
  roleConfig,
  module,
  resource,
  customPermissionMapping,
  updateMutation,
  deleteMutation,
  createResourceComponent,
  typename,
  disableRoleChecking,
  collapseComponent,
  genericEditFieldConfig,
  organizationID,
  enableTags = false,
  dataTestId = "data-card",
  // selectionType, deprecated. use config.selectionType instead
  // buttonCallback deprecated. use config.selectionType instead
}) => {
  const ContextRoleConfig = useContext(OrganizationContext);

  roleConfig = roleConfig ? roleConfig : ContextRoleConfig.roleConfig ? ContextRoleConfig.roleConfig : {};

  // This ensures that the config object is never null
  config = config ? config : {};

  const [pageData, setPageData] = useState(paginatedData);
  const [selectedPage, setSelectedPage] = useState(0);
  const [limit, setLimit] = useState(config.limit || 10);
  const [loading, setLoading] = useState(false);
  const [miniLoading, setMiniLoading] = useState(false);
  const [preventPageReset, setPreventPageReset] = useState(false);

  const tags = useTags({ organizationID, enableQuery: enableTags });

  //Parse custom fields for any custom search values
  const searchBar = useSearchBar(data, fields, true, fieldNameDictionary, customFields, tags, dataTestId);

  const dataTable = useTable({
    data: pageData ? pageData[selectedPage] : data,
    updateItemById: (item, resetPage = true) => {
      setPreventPageReset(!resetPage);
      searchBar.updateItemById(item);
    },
    fields,
    customFields,
    hideFields,
    options,
    customOptions,
    deleteFunction,
    deleteGqlQuery,
    route,
    popover,
    enableNotes,
    rowComponent,
    fieldNameDictionary,
    detailsComponent,
    config,
    roleConfig,
    module,
    resource,
    updateMutation,
    deleteMutation,
    resetFunction,
    typename,
    disableRoleChecking,
    collapseComponent,
    genericEditFieldConfig,
  });

  const useExportHook = useExport({
    listItems: pageData ? pageData : data,
    fields: dataTable.fields,
    csvFileName: `list-${resource}`,
    ...exportTableConfig,
  });

  /**
   * Adjusts the available fields in the searchBar hook if they change
   */
  useEffect(() => {
    if (dataTable.fields) {
      searchBar.setFields(dataTable.fields);
      useExportHook.setFields(dataTable.fields);
    }
  }, [dataTable.fields]);

  /**
   * Handles pagination and sorting on the table when the searchbar returns new values
   */
  useEffect(() => {
    const data = sortData(searchBar.searchResults, dataTable.sortFields);
    dataTable.setData(data);
    createPagination(data, limit);
  }, [searchBar.searchResults, limit, dataTable.sortFields]);

  /**
   * Updates the Table data when a new page gets selected
   */
  useEffect(() => {
    if (pageData) {
      dataTable.setData(pageData[selectedPage]);
    }
  }, [selectedPage]);

  const sortData = (data, sortFields) => {
    let sortedData = data;
    for (const sortField of sortFields) {
      sortedData = sortFunction(data, sortField.field, sortField.direction);
    }
    return sortedData;
  };

  const sortFunction = (data, field, direction) => {
    return data.sort((a, b) => {
      const splitField = field.split(".");

      const rootField = splitField[0];

      const nestedField1 = splitField[1];
      const nestedField2 = splitField[2];
      const nestedField3 = splitField[3];

      let stringA = a[rootField];
      let stringB = b[rootField];

      // nesting level 1
      if (a[rootField] && a[rootField][nestedField1]) {
        stringA = a[rootField][nestedField1];

        // nesting level 2
        if (a[rootField][nestedField1][nestedField2]) {
          stringA = a[rootField][nestedField1][nestedField2];

          // nesting level 3
          if (a[rootField][nestedField1][nestedField2][nestedField3]) {
            stringA = a[rootField][nestedField1][nestedField2][nestedField3];
          }
        }
      }

      // nesting level 1
      if (b[rootField] && b[rootField][nestedField1]) {
        stringB = b[rootField][nestedField1];

        // nesting level 2
        if (b[rootField][nestedField1][nestedField2]) {
          stringB = b[rootField][nestedField1][nestedField2];

          // nesting level 3
          if (b[rootField][nestedField1][nestedField2][nestedField3]) {
            stringB = b[rootField][nestedField1][nestedField2][nestedField3];
          }
        }
      }

      if (isValidDate(stringA) && isValidDate(stringB)) {
        if (direction === "asc") {
          return new Date(stringA).getTime() - new Date(stringB).getTime();
        } else {
          return new Date(stringB).getTime() - new Date(stringA).getTime();
        }
      }

      if (typeof stringA === "number" || !Number.isNaN(Number.parseFloat(stringA))) {
        if (direction === "asc") {
          return Number.parseFloat(stringA) - Number.parseFloat(stringB);
        } else {
          return Number.parseFloat(stringB) - Number.parseFloat(stringA);
        }
      }

      if (stringA === null && typeof stringB === "string") {
        return direction === "asc" ? 1 : -1;
      }

      if (stringB === null && typeof stringA === "string") {
        return direction === "asc" ? -1 : 1;
      }

      if (typeof stringA === "string" && typeof stringB === "string") {
        if (direction === "asc") {
          return stringA.localeCompare(stringB);
        } else {
          return stringB.localeCompare(stringA);
        }
      } else {
        return 0;
      }
    });
  };

  const createPagination = (data = [], limit) => {
    const dataLocal = [].concat(data);

    const arrayOfArrays = [];

    for (let i = 0; i < dataLocal.length; i += limit) {
      arrayOfArrays.push(dataLocal.slice(i, i + limit));
    }

    if (preventPageReset) {
      setPreventPageReset(false);
    } else {
      setSelectedPage(0);
    }
    setPageData(arrayOfArrays);
    dataTable.setData(arrayOfArrays[selectedPage]);
    dataTable.setFullData(dataLocal);
    return arrayOfArrays;
  };

  const checkPermissionsHook = useCheckPermissions({
    module: module,
    resource: resource,
    disableRoleChecking,
  });
  const remappedPermissions = remapPermissions({
    permissions: checkPermissionsHook,
    customPermissionMapping,
  });

  const display = (
    <Card data-test={`data-card-${dataTestId}`}>
      <CardHeader>
        {miniLoading && (
          <Loader style={{ marginRight: "0.5rem", width: "2em", height: "2em" }} size="sm" color="primary" />
        )}
        {header}
        {resetFunction && (
          <Button
            size="sm"
            color="ghost-secondary"
            className="btn-pill float-sm-right"
            onClick={() => {
              resetFunction && resetFunction();
            }}
            title="Refresh Card Data"
          >
            <i className="icon-reload" />
          </Button>
        )}
        {headerButtons.length > 0 && (
          <ButtonGroup className="float-right">{headerButtons.map((button, index) => button)}</ButtonGroup>
        )}
        {exportTable && remappedPermissions.resource.read && useExportHook.modalButton}
        {createResourceComponent && remappedPermissions.resource.create && (
          <GenericModalWithButton
            data-test={`modal-create-${dataTestId}`}
            minWidth={config.createResourceComponentWidth}
            disableModalToggle={
              config && config.createResourceComponentConfig && config.createResourceComponentConfig.disableModalToggle
            }
            body={React.cloneElement(createResourceComponent, {
              resetFunction: resetFunction || undefined,
            })}
            title={typename && `Create a ${convertCamelCaseToSentence(typename)}`}
            modalButton={
              <Button
                id={`button-create-${dataTestId}`}
                size="sm"
                color="ghost-success"
                className="float-right btn-pill"
                style={{ boxShadow: "none" }}
                title="Create new Resource"
              >
                <i className="icon-plus" />
              </Button>
            }
          />
        )}
        {header || headerButtons.length > 0 ? <hr /> : null}
        {searchBar.display}
        {enableTags && <>{tags.display}</>}
      </CardHeader>
      <CardBody style={{ height: "100%", overflowY: "auto" }}>
        {loading ? <Spinner color="primary" /> : dataTable.display}
      </CardBody>
      {pageData && pageData.length > 0 && (
        <CardFooter>
          <Pagination style={{ overflow: "auto" }} aria-label="Page navigation" size="sm">
            <PaginationItem>
              <PaginationLink disabled={selectedPage === 0} onClick={() => setSelectedPage(0)}>
                First
              </PaginationLink>
            </PaginationItem>
            <PaginationItem>
              <PaginationLink previous disabled={selectedPage < 1} onClick={() => setSelectedPage(selectedPage - 1)} />
            </PaginationItem>
            {pageData.map((page, index) => {
              if (index > -1) {
                return (
                  <PaginationItem key={index} active={selectedPage === index}>
                    <PaginationLink onClick={() => setSelectedPage(index)}>{index + 1}</PaginationLink>
                  </PaginationItem>
                );
              } else {
                return null;
              }
            })}
            <PaginationItem>
              <PaginationLink
                next
                disabled={selectedPage >= pageData.length - 1}
                onClick={() => setSelectedPage(selectedPage + 1)}
              />
            </PaginationItem>
            <PaginationItem>
              <PaginationLink
                disabled={selectedPage === pageData.length - 1}
                onClick={() => setSelectedPage(pageData.length - 1)}
              >
                Last
              </PaginationLink>
            </PaginationItem>
            <PaginationItem>
              <InputGroup size="sm" style={{ marginLeft: "15px" }}>
                <InputGroupAddon addonType="prepend">Limit:</InputGroupAddon>
                <Input type="select" value={limit} onChange={(e) => setLimit(parseInt(e.target.value, 10))}>
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={250}>250</option>
                  <option value={500}>500</option>
                </Input>
              </InputGroup>
            </PaginationItem>
          </Pagination>
        </CardFooter>
      )}
    </Card>
  );

  return {
    ...dataTable,
    display,
    setData: useCallback((data) => searchBar.setData(data)),
    setPaginatedData: useCallback((data) => setPageData(data)),
    loading,
    setLoading,
    miniLoading,
    setMiniLoading,
    limit,
    setLimit,
    setSortField: dataTable.setSortField,
    data: searchBar.searchResults, //deprecated. use searchResults instead
    searchResults: searchBar.searchResults,
    tableData: dataTable.data,
    selectedItems: dataTable.selectedItems,
    setSelectedItems: dataTable.setSelectedItems,
    setItems: dataTable.setItems,
    showSelectBoxes: dataTable.showSelectBoxes, // bool
    setShowSelectBoxes: dataTable.setShowSelectBoxes, // func
    addFilter: searchBar.addFilter,
    filters: searchBar.filters,
    setFilters: searchBar.setFilters,
    clearFilters: searchBar.clearFilters,
    tableDisplay: dataTable.display,
  };
};
