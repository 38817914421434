import { ReactElement } from "react";
import { TooltipIcon } from "../components/TooltipIcon";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { getIcon, IconName } from "../../../../components/Ions/icons/components/Icons";
import styles from "../GenericEditFieldV3.module.scss";

interface WarningContext {
  warning?: {
    message?: string;
  };
}

/**
 * Displays a custom warning icon with a tooltip when the generic field is supplied with a warning
 */
export const handleWarning = (context: WarningContext): ReactElement | null => {
  const warning = context?.warning;
  const { message } = warning || {};

  if (!isNullOrUndefined(warning)) {
    return (
      <TooltipIcon
        icon={<span className={styles["generic-edit-field__warning-icon"]}>{getIcon(IconName.WARNING)}</span>}
        message={message ?? ""}
      />
    );
  } else {
    return null;
  }
};
