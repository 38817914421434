import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { tryFunction } from "../../../../utils/Functions/tryFunction";
import React, { useEffect, useState } from "react";
import { updateObjectInArray } from "@rivial-security/func-utils";
import { useForm } from "../../../../hooks/views/useForm/useForm";
import { useHTTPAutomationTour } from "../tours/automationTours";

/**
 * Form for configuring an outgoing web request automation
 * @param {string} organizationID - the currently selected organizations
 * @param {function} toggleModal - function to close the modal once done creating/editing the automation step
 * @param {object} automations - the current automation step info for the parent node
 * @param {function} setAutomations - function to update the list of automation steps once editing is done
 * @param {object} fieldConfig - the field config for the form to create a web
 * @param {AutomationStep} automationStep - the current automation step data
 * @param formConfig
 * @returns {JSX.Element} - the edit form for the web request automation
 */
const ConfigureWebRequestAutomation = ({
  organizationID,
  toggleModal,
  automations,
  setAutomations,
  fieldConfig,
  automationStep,
  formConfig,
}) => {
  const onSubmit = async (input) => {
    const newAutomations = [...(automations || [])];

    updateObjectInArray(newAutomations, {
      id: automationStep.id,
      name: input?.name,
      description: input.url,
      config: {
        ...automationStep.config,
        url: input.url,
        method: input.method,
        headers: input.headers,
        bodyType: input.bodyType,
        customBody: input.customBody,
      },
    });

    setAutomations(newAutomations);

    tryFunction(toggleModal);
  };

  const METHODS = [
    {
      text: "POST",
      value: "POST",
    },
    {
      text: "GET",
      value: "GET",
    },
    {
      text: "PUT",
      value: "PUT",
    },
    {
      text: "DELETE",
      value: "DELETE",
    },
    {
      text: "PATCH",
      value: "PATCH",
    },
    {
      text: "HEAD",
      value: "HEAD",
    },
  ];

  const bodyTypes = [
    {
      text: "Default (Triggering Item)",
      value: "default",
    },
    {
      text: "Custom (JSON)",
      value: "custom",
    },
  ];

  const [bodyTypeIsVisible, setBodyTypeIsVisible] = useState(false);

  const form = useForm({
    item: { ...automationStep.config, name: automationStep?.name },
    disableResetButton: true,
    disableRoleChecking: true,
    submitFunction: onSubmit,
    fieldConfig: {
      ...fieldConfig,
      url: {
        label: "Web Request URL",
        tooltip: "The URL to send this request to",
        required: true,
      },
      method: {
        label: "HTTP Method",
        tooltip: "Select the method to use for the Web Request",
        defaultValue: "POST",
        inputType: "dropdown",
        dropdownConfig: {
          data: METHODS,
        },
      },
      bodyType: {
        label: "Request Body Type",
        tooltip:
          "By default the web request will include the information about the Triggering Item as JSON. You can also choose to send a custom body",
        inputType: "dropdown",
        defaultValue: "default",
        dropdownConfig: {
          data: bodyTypes,
        },
      },
      customBody: {
        label: "Custom Body",
        tooltip: "Construct a JSON object to send as the body of an HTTP request automation",
        inputType: "json",
        isHidden: !bodyTypeIsVisible,
        defaultValue: {},
        jsonConfig: {},
      },
      headers: {
        label: "Headers",
        tooltip:
          "Include headers such as Auth tokens, content-type, etc., as key-value pairs for the HTTP request automation",
        inputType: "json",
        jsonConfig: {},
        defaultValue: {},
      },
    },
    ...formConfig,
  });

  useEffect(() => {
    if (form?.input?.bodyType === "custom") {
      setBodyTypeIsVisible(true);
    } else {
      setBodyTypeIsVisible(false);
    }
  }, [form.input]);

  // Welcome tour
  useHTTPAutomationTour();

  return <div data-tourid={"configure-http-automation-container"}>{form.display}</div>;
};

export default withOrganizationCheck(ConfigureWebRequestAutomation);
