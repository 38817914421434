import { ErrorLogger, InfoLogger, WarningLogger } from "@utils/EventLogger";

import { DeleteSystemRiskLink } from "./DeleteSystemRiskLink";
import { GetQuery } from "../../../../../utils/Functions/Graphql/GetQuery";
import { ItemMutation } from "../../../../../utils/Functions/Graphql/ItemMutation";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 * @author: Jacob Blazina
 * @description: Deletes a Risk (aka KeyRiskIndicator).
 * Edits:
 *  - 8/7/19 JB: Added custom graphql
 *  - 7/14/2020: Refactored to use ItemMutation, properly deleting SystemRiskLinks
 *  - 09/15/2020: Refactor to use getItem and custom query
 *
 * @param risk {Object}
 * @constructor
 */
const DeleteRisk = async (risk) => {
  if (!risk || !risk.id) {
    ErrorLogger("Could not delete KeyRiskIndicator, invalid input");
    return;
  }

  // get full KeyRiskIndicator data
  InfoLogger("Querying KeyRiskIndicator data");
  const { getQuery } = generateGraphql("Risk", ["systems"], {
    systems: `(limit: 100) { items { id } }`,
  });

  const riskQuery = await GetQuery({
    query: getQuery,
    variables: { id: risk.id },
  });

  const systemRiskLinks = riskQuery && riskQuery.systems && riskQuery.systems.items;

  // Delete SystemRiskLinks
  if (systemRiskLinks && systemRiskLinks.length > 0) {
    systemRiskLinks.forEach((systemRiskLink) => {
      DeleteSystemRiskLink(systemRiskLink).then(() =>
        InfoLogger(`System Risk Link ${systemRiskLink?.id} was Successfully Deleted`),
      );
    });
  } else {
    WarningLogger("No SystemRiskLinks To Delete");
  }

  // Then delete the Risk
  const { deleteMutation: riskDelete } = generateGraphql("Risk");
  ItemMutation(riskDelete, { id: riskQuery.id }).then(() =>
    InfoLogger(`Risk ${riskQuery.name} was Successfully Deleted`),
  );
};

export default DeleteRisk;
