import { modules, resources } from "@rivial-security/role-utils";

import { GENERIC_FIELD_TYPES } from "../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import ItemPropertyField from "../../../../utils/CustomFields/ItemPropertyField";
import React from "react";
import VendorReviews from "../customFields/VendorReviews";
import { contractStatus } from "../constants/contractStatus";
import { enumToDropdownData } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { mergeAdditionalFields } from "../../../../hooks/views/useGrid/functions/mergeAdditionalFields";
import { useDetailsCard } from "../../../../hooks/views/useDetailsCardV2";
import { useStateEffect } from "../../../../hooks/functional/useStateEffect";

/**
 * Hook for displaying all information on one Vendor Solution in one place
 * Accessible with route /vendors/solution/:id
 */
export const useVendorSolutionDetails = ({
  organizationID,
  itemId,
  tableDisplay,
  updateItemById,
  vendorReviewRoute,
  route,
  header,
  vendorReviewDetailsComponent,
  module = modules.VENDORS,
  resource = resources.VENDOR_SOLUTION,
  disableRoleChecking,
  additionalFields,
  ...props
}) => {
  const [reviewRoute] = useStateEffect(vendorReviewRoute, [vendorReviewRoute], () => vendorReviewRoute);

  const typename = "VendorSolution";
  const fields = [
    "vendor",
    "name",
    "description",
    "vendorReviews",
    "systems",
    "contractDetails",
    "contractStatus",
    "contractExpirationDate",
    "contractExpiringSoonOffset",
  ];
  const nestedFields = {
    vendor: "{ name }",
    vendorReviews: `(limit: 100) { items { id name ownerGroup rating status} }`,
    systems: `(limit: 100) { items {
      id
      name
      description
      pointOfContact { id ownerGroup firstName lastName title email }
      hosting
      adminPointOfContact { id ownerGroup firstName lastName title email }
    } }`,
  };

  const { getQuery, updateMutation } = generateGraphql(typename, fields, nestedFields);

  const customFields = [
    {
      field: "vendor",
      propName: "field",
      component: <ItemPropertyField displayProperty={"name"} />,
    },
    {
      field: "vendorReviews",
      isHidden: true,
      component: (
        <VendorReviews
          organizationID={organizationID}
          route={reviewRoute}
          detailsComponent={vendorReviewDetailsComponent}
          module={module}
          resource={resource}
          disableRoleChecking={disableRoleChecking}
        />
      ),
    },
    {
      field: "contractStatus",
      tooltip:
        "Current status of the contract for this Vendor Solution. Can be used to trigger Automations when the contract is expiring or expired.",
      inputType: GENERIC_FIELD_TYPES.SINGLE_SELECT,
      inputConfig: {
        data: enumToDropdownData({ ENUM: contractStatus }),
      },
    },
    {
      field: "contractExpirationDate",
      tooltip:
        "Date when Contract Status will be marked as 'Expired'. If left blank, Contract Status will never be marked as 'Expired'",
      inputType: GENERIC_FIELD_TYPES.DATE,
      inputConfig: {
        dateOnly: true,
      },
      displayConfig: {
        dateOnly: true,
      },
    },
    {
      field: "contractExpiringSoonOffset",
      friendlyName: "Expiring Soon Offset (days)",
      tooltip: "Number of business days before contract expiration date to mark as 'Expiring Soon'",
      inputType: GENERIC_FIELD_TYPES.NUMERIC,
      inputConfig: {
        min: 0,
        max: 365,
      },
    },
  ];
  mergeAdditionalFields({
    additionalFields,
    fields: customFields,
    propertyName: "field",
  });

  const fieldNameDictionary = {
    id: "ID",
    name: "Solution Name",
    description: "Description",
    contractDetails: "Contract Details",
    vendor: "Vendor Name",
    contractExpiringSoonOffset: "Expiration Reminder (days)",
  };

  const queryConfig = {
    query: getQuery,
    organizationID,
    itemId,
  };

  const detailsConfig = {
    module,
    resource,
    fields,
    fieldNameDictionary,
    updateMutation,
    customFields,
  };
  const config = {
    header: header || "Vendor Solution Details",
    route: route || "#/vendors/solutions/",
  };

  const vendorSolutionDetailsHook = useDetailsCard({
    queryConfig,
    detailsConfig,
    config,
    module,
    resource,
    tableDisplay,
    updateItemById,
    organizationID,
    ...props,
  });

  return {
    ...vendorSolutionDetailsHook,
  };
};
