import React from "react";
import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";
import { generateGraphql } from "@rivial-security/generategraphql";
import DepartmentForm from "../components/DepartmentForm";
import DepartmentDetails from "../components/DepartmentDetails";
import { deleteDepartment } from "../functions/deleteDepartment";
import { DEPARTMENT } from "../constants/DEPARTMENT";

/**
 * Displays a list of Departments for an org.
 *
 * @param {string} organizationID - the ID of the Organization that the Department belongs to
 * @param {object} ...props - all props passed to the useDataGrid hook
 *
 * @see DEPARTMENT
 */
export const useDepartmentGrid = ({ organizationID, ...props }) => {
  const queryFields = [
    DEPARTMENT.fields.name,
    DEPARTMENT.fields.description,
    DEPARTMENT.fields.departmentHead,
    DEPARTMENT.fields.departmentHeadID,
  ];

  const fields = [
    {
      //
      // Note: Showing how we can explicitly set the field config or pull directly from DEPARTMENT constant
      //
      // name: DEPARTMENT.fieldConfig.name.name,
      // description: DEPARTMENT.fieldConfig.name.description,
      // inputType: DEPARTMENT.fieldConfig.name.inputType,

      ...DEPARTMENT.fieldConfig.name,
      width: "300",
      sorting: {
        priority: 1,
      },
    },
    {
      ...DEPARTMENT.fieldConfig.description,
      width: "400",
    },
    {
      ...DEPARTMENT.fieldConfig.departmentHead,
      width: "300",
    },
  ];

  const { listQuery, updateMutation } = generateGraphql(DEPARTMENT.typename, queryFields, {
    [DEPARTMENT.fields.departmentHead]: `
        {
          id
          firstName
          lastName
          email
          title
        }
      `,
  });

  return useDataGrid({
    organizationID,
    fields,
    options: ["details", "duplicate", "delete", "edit"],
    typename: DEPARTMENT.typename,
    module: DEPARTMENT.module,
    resource: DEPARTMENT.resource,
    query: listQuery,
    route: DEPARTMENT.route,
    createResourceComponent: <DepartmentForm />,
    persistenceUUID: "departmentGrid-2657-897535-8878-5555",
    detailsComponent: <DepartmentDetails />,
    deleteFunction: deleteDepartment,
    title: "Departments",
    icon: DEPARTMENT.icon,
    duplicationSettings: DEPARTMENT.duplicationSettings,
    updateMutation,
    ...props,
  });
};
