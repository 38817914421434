import { Button, Form } from "reactstrap";
import React, { useEffect } from "react";
import { modules, resources } from "@rivial-security/role-utils";

import { realTimeRiskGQL } from "@rivial-security/risk-calc-utils";
import { useCheckPermissions } from "@hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useMutation } from "@hooks/graphql/useMutation/useMutation";
import { useQueryCard } from "@hooks/views/useQueryCard";
import { withOrganizationCheck } from "@utils/Context/withOrganizationCheck";

/**
 * Displays selection list for choosing what information assets to add to a System
 * @param module
 * @param resource
 * @param disableRoleChecking
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const AddInformationAssetToSystem = ({
  module = modules.RISK,
  resource = resources.INFORMATION_SYSTEM,
  disableRoleChecking = false,
  ...props
}) => {
  const field = "informationAssets";

  const checkPermissionsHook = useCheckPermissions({
    module,
    resource,
    field,
    disableRoleChecking,
  });

  const { currentAssets, system } = props;

  const queryConfig = {
    query: realTimeRiskGQL.informationAssetByOwnerGroup_systemAccordion,
    variables: { ownerGroup: props.organizationID },
    organizationID: props.organizationID,
  };

  const dataCardConfig = {
    fields: ["name"],
  };

  const informationAssetsList = useQueryCard({
    queryConfig,
    dataCardConfig,
    module,
    resource,
    disableRoleChecking,
  });

  useEffect(() => {
    informationAssetsList.setShowSelectBoxes(true);
    const informationAssetLinks = currentAssets || [];
    const currentInformationAssets = [];
    for (const item of informationAssetLinks) {
      currentInformationAssets.push(item.informationAsset);
    }
    informationAssetsList.setSelectedItems([...currentInformationAssets]);
  }, [currentAssets]);

  const informationAssetLinkMutation = useMutation({
    mutation: realTimeRiskGQL.createSystemInformationAssetLink_minimal,
    module,
    resource,
    field,
    disableRoleChecking,
  });
  const informationAssetLinkDelete = useMutation({
    mutation: realTimeRiskGQL.deleteSystemInformationAssetLink_minimal,
    module,
    resource,
    field,
    disableRoleChecking,
  });

  const handleSubmit = () => {
    const informationAssetLinks = currentAssets || [];
    const currentInformationAssets = [];
    for (const item of informationAssetLinks) {
      currentInformationAssets.push({
        ...item.informationAsset,
        linkId: item.id,
      });
    }

    // Add assets to system
    for (const i of informationAssetsList.selectedItems) {
      const index = currentInformationAssets.findIndex((item) => item.id === i.id);
      if (index === -1) {
        const input = {
          systemID: system.id,
          informationAssetID: i.id,
          numberOfRecords: 0,
          ownerGroup: props.organizationID,
        };
        informationAssetLinkMutation.editItem(input);
      }
    }

    // Remove assets from system
    for (const i of currentInformationAssets) {
      const index = informationAssetsList.selectedItems.findIndex((item) => item.id === i.id);
      if (index === -1) {
        const input = {
          id: i.linkId,
        };
        informationAssetLinkDelete.editItem(input);
      }
    }

    setTimeout(() => {
      props.resetFunction?.();
      props.toggleModal?.();
    }, 1000);
  };

  return (
    <Form>
      System: {system.name}
      <br />
      <Button
        title={
          checkPermissionsHook.resource.update
            ? "Add/Remove Information Assets for this System"
            : "You don't have Update Permissions on System field: Information Assets"
        }
        disabled={!checkPermissionsHook.resource.update}
        style={{ margin: "1em" }}
        color="primary"
        onClick={() => handleSubmit()}
      >
        Submit
      </Button>
      {informationAssetsList.display}
    </Form>
  );
};

AddInformationAssetToSystem.defaultProps = {
  system: {},
};

export default withOrganizationCheck(AddInformationAssetToSystem);
