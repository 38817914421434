import React from "react";
import CustomRichTextEditor from "../../../../../hooks/views/useForm/components/CustomRichTextEditor";

/**
 * Rich text task UI, content can be modified when edits are on
 * @param {object} task - the task data from database
 * @param {function} setTask - function to update the task state (also updates on the backend with debounce)
 * @param {boolean} enableEdits - TRUE if user has selected to edit the task work with a switch
 * @returns {JSX.Element}
 */
const CustomTaskWork = ({ task, setTask, enableEdits }) => {
  return (
    <CustomRichTextEditor
      onChange={(value) => {
        setTask({
          ...(task || {}),
          custom: {
            content: value,
          },
        });
      }}
      value={task?.custom?.content}
      richTextEditorConfig={{
        enableEdits,
        showEditButtons: false,
      }}
    />
  );
};

export default CustomTaskWork;
