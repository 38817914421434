import React, { useState } from "react";
import Dashboard from "../../../../utils/GenericComponents/Dashboard";
import { useAccordion } from "../../../../hooks/views/useAccordion/useAccordion";
import Grid from "@mui/material/Grid";
import { useAuditDetails } from "./useAuditDetails";
import { useAuditFindings } from "./useAuditFindings";
import AuditExportButtons from "../components/AuditExportButtons";
import { useComplianceArtifacts } from "../../Documents/hooks/useComplianceArtifacts";
import { DOCUMENT_EXPORT_BY } from "../../Documents/functions/fetchDocumentsBy";
import ResetButton from "../../../../utils/GenericComponents/buttons/ResetButton";
import { useAuditWelcomeTour } from "./useAuditWelcomeTour";
import AuditControls from "../components/AuditControls";
import AuditGeneralInformation from "../customFields/AuditGeneralInformation";

/**
 * Displays Audit Details for a user
 * @param {string} itemId - the audit for which to show details
 * @param {string} organizationID - the currently selected organization
 */
export const useAudit = ({ itemId, organizationID }) => {
  /**
   * Queries the Audit and handles state for Audit Control Status, Audit Finding Bar Charts, and Audit Controls Table
   */
  const auditDetails = useAuditDetails({ itemId });

  /**
   * Handles logic and state for audit findings
   * @type {{display: JSX.Element}}
   */
  const auditFindings = useAuditFindings({
    item: { ...auditDetails.item, id: itemId },
    resetFunction: auditDetails.reset,
    organizationID,
  });

  /**
   * Handles logic and state for audit documents
   * @type {{display: JSX.Element}}
   */
  const auditDocuments = useComplianceArtifacts({
    organizationID,
    groupBy: DOCUMENT_EXPORT_BY.CONTROL,
    controlFrameworkID: auditDetails?.item?.controlSetID,
  });

  const [controlsResetKey, setControlsResetKey] = useState(0);
  const accordion = useAccordion({
    items: [
      {
        id: "audit-general-information",
        icon: "icon-list",
        title: "General Information",
        subTitle: "Find summary information about this Audit",
        component: (
          <AuditGeneralInformation
            item={auditDetails.item}
            tableDisplay={auditDetails.tableDisplay}
            auditFindings={auditFindings}
          />
        ),
        defaultExpanded: true,
      },
      {
        id: "audit-details-controls",
        icon: "icon-shield",
        title: "Controls",
        subTitle: "Evaluate the compliance for all of the Controls in this Audit",
        component: (
          <AuditControls
            itemId={itemId}
            resetKey={controlsResetKey}
            organizationID={organizationID}
            auditFindings={auditFindings}
            auditDetails={auditDetails}
          />
        ),
        defaultExpanded: false,
        headerButtons: [
          <ResetButton
            resetFunction={() => {
              setControlsResetKey((controlsResetKey) => controlsResetKey + 1);
            }}
          />,
        ],
        unmountOnExit: true,
      },
      {
        id: "audit-details-findings",
        icon: "icon-eye",
        title: "Findings",
        subTitle: "Observations, Recommendations, and Action Items associated with this Audit",
        component: auditFindings.display,
        headerButtons: [
          <ResetButton
            resetFunction={() => {
              auditDetails?.resetFunction?.();
            }}
          />,
        ],
        unmountOnExit: false,
      },
      {
        id: "audit-details-docs",
        icon: "icon-docs",
        title: "Documents",
        subTitle: "Evidence Documents associated with this Audit",
        component: auditDocuments.display,
        headerButtons: [
          <ResetButton
            resetFunction={() => {
              auditDetails?.resetFunction?.();
            }}
          />,
        ],
        unmountOnExit: false,
      },
    ],
  });

  useAuditWelcomeTour();

  const display = (
    <Dashboard
      subTitle={auditDetails?.item?.name}
      headerButtons={[<AuditExportButtons itemId={itemId} item={auditDetails.item} organizationID={organizationID} />]}
    >
      <Grid item lg={12} xs={12}>
        {accordion.display}
      </Grid>
    </Dashboard>
  );

  return {
    display,
  };
};
