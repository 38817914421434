import { Target, TargetFindingLink, TargetVulnerabilityStatus } from "@rivial-security/schema-types";

import { isNonEmptyArray } from "@rivial-security/func-utils";

export interface GetVulnerabilitySeenTargetsParams {
  targets: Partial<Target>[];
  targetLinks: Partial<TargetFindingLink>[];
}

export type SeenTarget = Partial<Target> & {
  notFixedCount: number;
  totalCount: number;
};

export const getVulnerabilitySeenTargets = ({
  targets,
  targetLinks,
}: GetVulnerabilitySeenTargetsParams): Record<string, SeenTarget> => {
  if (!isNonEmptyArray(targetLinks)) {
    return {};
  }

  const seenTargets: Record<string, SeenTarget> = {};

  for (const targetLink of targetLinks) {
    const status = targetLink?.status;
    const targetID = targetLink?.targetID;
    const target = targets?.find((targetToFind) => targetToFind?.id === targetID);
    if (!targetID || !status || !target) {
      continue;
    }

    const seenTarget = seenTargets[targetID];

    if (seenTarget) {
      seenTarget.notFixedCount += status === TargetVulnerabilityStatus.NOT_FIXED ? 1 : 0;
      seenTarget.totalCount += 1;
    } else {
      seenTargets[targetID] = {
        ...target,
        notFixedCount: status === TargetVulnerabilityStatus.NOT_FIXED ? 1 : 0,
        totalCount: 1,
      };
    }
  }

  return seenTargets;
};
