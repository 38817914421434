import { useArtifactValidation } from "./useArtifactValidation";
import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";

/**
 * A component instance of the useArtifactValidation.js hook
 * @param {object} props - props passed down to the useArtifactValidation hook
 * @returns {JSX.Element}
 */
const ArtifactValidation = (props) => {
  return useArtifactValidation(props).display;
};

export default withDetailsPage(ArtifactValidation);
