import React, { useState } from "react";
import { Col, ListGroup, ListGroupItem, Row } from "reactstrap";
import { getIcon } from "../../utils/Functions/Icon/getIcon";

/**
 * A list of pages, that allows the user to navigate between various pages from a navigation menu
 * Icon designing requires an iconfiy string
 * @param pages
 * @param pages[].title
 * @param pages[].component
 * @param pages[].icon
 * @param defaultPageIndex
 * @returns {{setActivePage: React.Dispatch<React.SetStateAction<unknown>>, display: JSX.Element, activePage: unknown}}
 */
export const usePageList = ({ pages, defaultPageIndex = 0, menuSize = 3 }) => {
  const [activePage, setActivePage] = useState(pages[defaultPageIndex]);

  const listGroupItemProps = {
    tag: "button",
    action: true,
  };

  const display = (
    <div style={{ height: "100%" }}>
      <Row style={{ height: "100%" }}>
        <Col lg={menuSize}>
          <ListGroup>
            {pages.map((page) => (
              <ListGroupItem
                {...listGroupItemProps}
                key={page.title}
                active={activePage?.title === page.title}
                onClick={() => setActivePage(page)}
              >
                {page.icon && <span style={{ marginRight: "1em" }}>{getIcon(page.icon)}</span>}
                {page.title}
              </ListGroupItem>
            ))}
          </ListGroup>
        </Col>
        <Col lg={12 - menuSize}>{activePage?.component && React.cloneElement(activePage?.component)}</Col>
      </Row>
    </div>
  );

  return {
    display,
    activePage,
    setActivePage,
  };
};
