import { Button, Input, UncontrolledTooltip } from "reactstrap";
import React, { useContext, useState } from "react";
import { OrganizationContext } from "../../../../../utils/Context/OrganizationContext";
import { usePreferences } from "../../../../../hooks/views/usePreferences/usePreferences";
import { UIContext } from "../../../../../utils/Context/UIContext";
import { submitTimeout } from "../functions/submitTimeout";
import { useCard } from "../../../../../hooks/views/useCard";

/**
 * @description Login Inactivity Logout Component
 * @return {JSX.Element}
 * @constructor
 */
const LoginInactivityLogout = () => {
  const context = useContext(OrganizationContext);
  const { addToast } = useContext(UIContext);

  const preferences = usePreferences();

  const [timeoutDuration, setTimeoutDuration] = useState(context.timeoutDuration);

  const card = useCard({
    id: "inactivity-logout",
    title: (
      <>
        <h4 style={{ display: "inline" }}>Inactivity Logout</h4>{" "}
        <i id="inactivity-logout-icon-question" className="icon-question" />
        <UncontrolledTooltip placement="right" target="inactivity-logout-icon-question">
          Specify time in minutes after which you will be logged out due to inactivity.
        </UncontrolledTooltip>
      </>
    ),
    body: (
      <>
        <h6>Duration ( In Minutes)</h6>
        <Input
          type="number"
          min={1}
          max={480}
          value={timeoutDuration / 60}
          onChange={(e) => {
            setTimeoutDuration(parseInt(e.target.value) * 60);
          }}
        />
        <br />
        <Button
          color="ghost-success"
          className="btn-pill"
          size="sm"
          onClick={() => submitTimeout({ preferences, timeoutDuration, context, addToast })}
          title="This action will update your logged in duration"
        >
          Update Timeout
        </Button>
      </>
    ),
  });

  return card?.display;
};

export default LoginInactivityLogout;
