import { chartDataBuilderActions } from "../constants/chartDataBuilderActions";
import { get } from "lodash";

/**
 * Controls the state of the data builder step for pie and bar charts form
 * @param {object} state - current state of the data builder, see sibling file readme
 * @param {object} action - the parameters of the action being performed on the state
 * @param {string} action.type - the type of action being performed
 * @returns {(*&{fieldSelection: (*&{fieldRequirements: (*&{requiredPath: *[]})})})|(*&{fieldSelection: null})|*}
 */
export const chartDataBuilderReducer = (state, action) => {
  switch (action.type) {
    case chartDataBuilderActions.BEGIN_FIELD_SELECTION:
      const { fieldSelectionInput } = action || {};

      let requiredPath = [];
      const { seriesItem, fieldRequirements } = fieldSelectionInput || {};
      const { requiredSibling } = fieldRequirements || {};

      if (requiredSibling && seriesItem) {
        requiredPath = get(seriesItem, requiredSibling);
      }
      if (Array.isArray(requiredPath) && requiredPath.length > 0) {
        requiredPath = requiredPath.slice(0, -1);
      }

      return {
        ...state,
        fieldSelection: {
          ...fieldSelectionInput,
          fieldRequirements: {
            ...fieldRequirements,
            requiredPath,
          },
        },
      };
    case chartDataBuilderActions.CANCEL_FIELD_SELECTION:
    case chartDataBuilderActions.FINISH_FIELD_SELECTION:
      return {
        ...state,
        fieldSelection: null,
      };
    default:
      return state;
  }
};
