import { modules, resources } from "@rivial-security/role-utils";

import { Button } from "reactstrap";
import React from "react";
import VendorSubControlImporterModalButton from "../components/VendorSubControlImporterModalButton";
import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useForm } from "../../../../hooks/views/useForm/useForm";
import { useModal } from "../../../../hooks/views/useModal";
import { useUploadVendorSubControls } from "./useUploadVendorSubControls";

/**
 * Custom hook for creating a new VendorSubControl
 * @param {string} organizationID - the id of the organization in which to create the VendorSubControl
 * @param {object} vendorControlCategory - the vendor control category to create a vendor sub control for
 * @param {function} resetFunction - function to use to re-query the parent item once a new resource is created
 * @param {function} toggleModal - function to use to toggle the modal
 * @param {string} module=modules.VENDORS - the module of the resource being created
 * @param {string} resource=resources.VENDOR_CONTROL - the resource that is being created
 * @param {boolean} disableRoleChecking - if TRUE won't check role prior to mutation
 * @param {function} getNewItem - callback on submit with new item info
 */
export const useCreateVendorSubControl = ({
  organizationID,
  vendorControlCategory = {},
  toggleModal,
  resetFunction,
  module = modules.VENDORS,
  resource = resources.VENDOR_CONTROL,
  disableRoleChecking,
  getNewItem,
}) => {
  const typename = "VendorSubControl";

  const { createMutation } = generateGraphql(typename, ["name", "statementNumber", "vendorControlCategoryID"]);

  const bulkImport = useUploadVendorSubControls({
    organizationID,
  });

  const uploadModal = useModal(
    "Import Vendor Sub Controls",
    bulkImport.display,
    <Button size="sm" color="link">
      Bulk Import Vendor Sub Controls
    </Button>,
    {
      width: "80vw",
    },
  );

  const formHook = useForm({
    headerButtons: [
      <VendorSubControlImporterModalButton
        organizationID={organizationID}
        vendorControlCategoryID={vendorControlCategory?.id}
      />,
    ],
    mutation: createMutation,
    organizationID,
    resetFunction,
    toggleModal,
    getNewItem,
    module,
    resource,
    disableRoleChecking,
    typename: convertCamelCaseToSentence(typename),
    fieldConfig: {
      statementNumber: {
        label: "Statement Number",
        tooltip: "A Unique ID for this Control",
        required: true,
      },
      name: {
        label: "Name",
        tooltip: "The Name of Description of this Control",
        required: true,
      },
      vendorControlCategoryID: {
        label: "Control Category ID",
        defaultValue: vendorControlCategory?.id,
        disabled: true,
      },
    },
  });

  return {
    ...formHook,
  };
};
