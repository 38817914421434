import { DeletePolicy } from "../functions/DeletePolicy";
import PolicyDetails from "../components/PolicyDetails";
import PolicyForm from "../components/PolicyForm";
import React from "react";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";
import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";
import AIPolicyGenerationButton from "../components/AIPolicyGenerationButton";

const { listQuery: listPolicys, updateMutation } = generateGraphql("Policy", ["name"]);

/**
 * Author: Jacob Blazina
 * Created At: 3/30/2020
 *
 * @param {string} organizationID - selected organization
 * @param {object} queryConfig - query config
 * @param {object} gridConfig - grid config object
 * @param {object} cardConfig - card config object
 * @param {object[]} fields - additional fields
 * @param {object} props - additional props
 * @param {object} formProps - props to pass to the create resource component
 * @returns {object} {{display, paginatedData, setOrganizationId, loading, setLoading, data, tableData, selectedItems, setSelectedItems, setItems, showSelectBoxes, setShowSelectBoxes, addFilter, filters, setFilters, setQueryFilters}}
 */
export const usePolicyDataGrid = ({
  organizationID,
  queryConfig,
  cardConfig,
  gridConfig,
  formProps,
  fields = [],
  ...props
}) => {
  const module = modules.GOVERNANCE;
  const resource = resources.POLICY;

  const roleConfig = {
    module,
    resource,
  };

  queryConfig = {
    organizationID,
    query: listPolicys,
    ...queryConfig,
  };

  cardConfig = {
    title: "Security Policies",
    headerIcon: "icon-docs",
    ...cardConfig,
  };

  fields = [
    {
      name: "name",
      flex: 1,
      sort: {
        direction: "asc",
        priority: 1,
      },
      bulkEdit: true,
    },
    ...fields,
  ];

  gridConfig = {
    fields,
    createItemModalHeader: "Create a Security Policy",
    options: ["details", "delete", "edit"],
    deleteFunction: DeletePolicy,
    route: "#/governance/policies/",
    detailsComponent: <PolicyDetails tableDisplay={true} />,
    updateMutation,
    createResourceComponent: (
      <PolicyForm moduleField={props.moduleField} organizationID={organizationID} {...formProps} />
    ),
    persistenceUUID: "ffb86528-fcfd-41ff-8479-3928b6aec0d3",
    typename: "Policy",
    ...gridConfig,
    organizationID,
    headerButtons: [<AIPolicyGenerationButton organizationID={organizationID} key={"ai-policy-generation-button"} />],
    ...props,
  };

  const queryCard = useDataGrid({
    ...queryConfig,
    ...gridConfig,
    ...cardConfig,
    ...roleConfig,
  });

  return { ...queryCard };
};
