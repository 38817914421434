import { modules, resources } from "@rivial-security/role-utils";

import CreateRecommendation from "../../../../../Recommendations/components/CreateRecommendation";
import NotesV2 from "../../../../../../../utils/Notes/components/NotesV2";
import React from "react";
import { useQueryGetItem } from "../../../../../../../hooks/graphql/useQueryGetItem";
import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";

const updateKeyRiskIndicatorNotes = /* GraphQL */ `
  mutation UpdateSystemRiskLink($input: UpdateSystemRiskLinkInput!) {
    updateSystemRiskLink(input: $input) {
      id
      ownerGroup
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
        observationID
      }
    }
  }
`;

const getKeyRiskIndicatorNotes = /* GraphQL */ `
  query GetSystemRiskLink($id: ID!) {
    getSystemRiskLink(id: $id) {
      id
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
        observationID
      }
    }
  }
`;

const KeyRiskIndicatorNotes = ({ item, organizationID, disableRoleChecking = false, system }) => {
  const module = modules.RISK;
  const resource = resources.KEY_RISK_INDICATOR;

  const query = useQueryGetItem({
    itemId: item.id,
    query: getKeyRiskIndicatorNotes,
    module,
    resource,
    disableRoleChecking,
  });

  return (
    <NotesV2
      item={query.item}
      module={module}
      resource={resource}
      tooltip="These notes apply to this Key Risk Indicator only in the context of this specific Information System"
      organizationID={organizationID}
      mutation={updateKeyRiskIndicatorNotes}
      resetFunction={query.reset}
      disableRoleChecking={disableRoleChecking}
      observationFormOverride={<CreateRecommendation organizationID={organizationID} system={system} />}
    />
  );
};

export default withOrganizationCheck(KeyRiskIndicatorNotes);
