import { checkRouteRequiredModulesEnabled } from "@application/Layout/hooks/useNavigationLinks/functions/checkRouteRequiredModulesEnabled";
import { generateDeprecatedPathRedirects } from "@application/Layout/hooks/useNavigationLinks/functions/generateDeprecatedPathRedirects";
import { NavigationModule, NavigationRoute } from "@application/Navigation/types";
import { Route } from "@application/Layout/hooks/types/Navigation";
import { NavigationBarPage } from "@components/Molecules/NavigationBar/types";

export interface AddNavigationRouteParams {
  route: NavigationRoute;
  roleConfig: Record<string, unknown>;
  newRoutes: Route[];
  navigationBarPages: NavigationBarPage[];
  navigationModule: NavigationModule;
}

export const addNavigationRoute = ({
  route,
  roleConfig,
  newRoutes,
  navigationBarPages,
  navigationModule,
}: AddNavigationRouteParams): void => {
  if (!checkRouteRequiredModulesEnabled({ route, roleConfig })) {
    return;
  }

  const modulePath = navigationModule.path;
  const routePath = route.path;
  const fullPath = `${modulePath}${routePath}`;
  if (!fullPath) {
    return;
  }

  newRoutes.push({
    ...route,
    path: fullPath,
    exact: true,
  });
  generateDeprecatedPathRedirects({ newRoutes, navigationModule, route });

  const routeEndsInID = routePath.endsWith(":id");
  const hideInNavigationBar = route.navigationBar?.hidden || routeEndsInID;
  if (hideInNavigationBar) {
    return;
  }

  if (routePath) {
    navigationBarPages.push({
      name: route?.navigationBar?.name ?? route.name,
      link: fullPath,
    });
  }
};
