import { useAuditControlDetails } from "../hooks/useAuditControlDetails";
import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";

/**
 * WIP - DO NOT USE - stubbed this out for a meeting with operations
 * Custom Control details page specifically for viewing Controls from the viewpoint of an Audit
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const AuditControlDetails = (props) => {
  return useAuditControlDetails(props).display;
};

export default withDetailsPage(AuditControlDetails);
