import { modules, resources } from "@rivial-security/role-utils";

import Dashboard from "../../../../utils/GenericComponents/Dashboard";
import Grid from "@mui/material/Grid";
import React from "react";
import SingleVulnerabilityStatusCustomField from "../components/SingleVulnerabilityStatusCustomField";
import TargetsTabCard from "../components/TargetsTabCard";
import { VulnerabilityAssessments } from "../components/VulnerabilityAssessments";
import VulnerabilityFalsePositives from "../components/VulnerabilityFalsePositives";
import VulnerabilityFirstLastSeenDate from "../customFields/VulnerabilityFirstLastSeenDate";
import VulnerabilityObservation from "../customFields/VulnerabilityObservation";
import { VulnerabilitySeverityLevel } from "../customFields/VulnerabilitySeverityLevel";
import VulnerabilitySystems from "../components/VulnerabilitySystems";
import VulnerabilityTimeline from "../components/VulnerabilityTimelineContent/components/VulnerabilityTimeline";
import { VulnerabilityType } from "../customFields/VulnerabilityType";
import { generateGraphql } from "@rivial-security/generategraphql";
import { getIcon } from "../../../../utils/Functions/Icon/getIcon";
import { normalizeVulnerability } from "../functions/normalizeVulnerability";
import { useAccordion } from "../../../../hooks/views/useAccordion/useAccordion";
import { useDetailsCard } from "../../../../hooks/views/useDetailsCardV2";
import VulnerabilityPriority from "../customFields/VulnerabilityPriority/VulnerabilityPriority";
import ArrayCustomField from "../customFields/ArrayCustomField";
import VulnerabilityRawData from "../customFields/VulnerabilityRawData";
import VulnerabilityActionItems from "@views/Testing/Vulnerabilities/components/VulnerabilityActionItems";
import VulnerabilityPointOfContact from "@views/Testing/Vulnerabilities/components/VulnerabilityPointOfContact";

/**
 * Display Vulnerability details hook
 * @param {string} itemId - vulnerability id
 * @param {string} organizationID - selected organization
 * @param {object} props - props to pass to DetailsCard
 * @returns {object} {{isLoading: boolean | undefined, setInputId: {(value: (((prevState: *) => *) | *)): void, (value: (((prevState: undefined) => undefined) | undefined)): void}, inputId: * | undefined, item: unknown, tableDisplay: *, display: *|JSX.Element, reset: function(): void, setItem: {(value: unknown): void, (value: (((prevState: undefined) => undefined) | undefined)): void}}}
 */
export const useVulnerabilityDetails = ({ itemId, organizationID, ...props }) => {
  const module = modules.VULNERABILITIES;
  const resource = resources.VULNERABILITY;

  const { getQuery, updateMutation } = generateGraphql(
    "Vulnerability",
    [
      "name",
      "type",
      "severityLevel",
      "ports",
      "protocols",
      "cvss",
      "cves",
      "impact",
      "solution",
      "solutionType",
      "exploitAvailable",
      "pluginID",
      "summary",
      "notes",
      "observation",
      "observationID",
      "targets",
      "assessments",
      "manualPriority",
      "pointOfContact",
    ],
    {
      observation: `{ id name }`,
      notes: `{ id type ownerGroup author timeStamp content tag observationID }`,
      targets: `(limit: 1000) {
        items {
          id
          status
          priority
          firstSeen
          lastSeen
          target {
            id
            ip
          }
        }
      }`,
      assessments: `(limit: 1000) {
        items { id assessmentID vulnerabilityID }
      }`,
      pointOfContact: `{ id firstName lastName }`,
    },
  );

  const fields = [
    "name",
    "type",
    "priority",
    "status",
    "severityLevel",
    "ports",
    "protocols",
    "cves",
    "cvss",
    "impact",
    "exploitAvailable",
    "pluginID",
    "summary",
    "solutionType",
    "solution",
    "observation",
    "firstSeen",
    "lastSeen",
    "pointOfContact",
    "rawData",
  ];

  const queryConfig = {
    query: getQuery,
    itemId,
    normalizeData: (data) => {
      return normalizeVulnerability({
        vulnerability: data,
        calculatePriority: true,
      });
    },
  };

  const detailsConfig = {
    fields: [...fields],
    updateMutation,
  };

  const customFields = [
    {
      field: "type",
      component: <VulnerabilityType />,
    },
    {
      field: "status",
      component: <SingleVulnerabilityStatusCustomField />,
      isComputed: true,
    },
    {
      field: "priority",
      component: <VulnerabilityPriority />,
    },
    {
      field: "severityLevel",
      component: <VulnerabilitySeverityLevel />,
    },
    {
      field: "ports",
      component: <ArrayCustomField fieldName={"ports"} />,
    },
    {
      field: "protocols",
      component: <ArrayCustomField fieldName={"protocols"} />,
    },
    {
      field: "cvss",
      inputType: "numeric",
      inputConfig: {
        min: 0,
        max: 10,
        step: 0.1,
      },
    },
    {
      field: "cves",
      component: <ArrayCustomField fieldName={"cves"} />,
    },
    {
      field: "exploitAvailable",
      inputType: "switch",
    },
    {
      field: "observation",
      component: <VulnerabilityObservation />,
    },
    {
      field: "firstSeen",
      component: <VulnerabilityFirstLastSeenDate type="firstSeen" />,
    },
    {
      field: "lastSeen",
      component: <VulnerabilityFirstLastSeenDate type="lastSeen" />,
    },
    {
      field: "pointOfContact",
      component: <VulnerabilityPointOfContact module={module} resource={resource} />,
    },
    {
      field: "rawData",
      component: <VulnerabilityRawData />,
      tooltip:
        "Tabular scan data for each vulnerability containing relational information on individual IP addresses, ports, protocols and CVE entries.",
      isComputed: true,
    },
  ];

  const details = useDetailsCard({
    organizationID,
    typename: "Vulnerability",
    queryConfig,
    detailsConfig,
    customFields,
    module,
    resource,
    config: {
      enableNotes: true,
      observationConnectionField: "vulnerabilityID",
    },
    fieldNameDictionary: {
      pluginID: "Plugin ID",
      pointOfContact: "Responsible",
    },
    ...props,
  });

  const accordion = useAccordion({
    items: [
      {
        title: "General Information",
        subTitle: "Technical details about the scan result",
        component: <span>{details.tableDisplay}</span>,
        icon: "icon-list",
        defaultExpanded: true,
      },
      {
        title: "Targets",
        subTitle: "Devices that actively have this Vulnerability",
        component: <TargetsTabCard organizationID={organizationID} itemId={itemId} item={details.item} {...props} />,
        icon: "icon-fire",
      },
      {
        title: "False Positives",
        subTitle: "Targets associated with the Vulnerability that are not applicable",
        component: <VulnerabilityFalsePositives item={{ id: itemId }} />,
        icon: getIcon("ic:baseline-browser-not-supported"),
      },
      {
        title: "Assessments",
        subTitle: "Security Assessments that this Vulnerability has appeared in",
        component: <VulnerabilityAssessments item={{ id: itemId }} organizationID={organizationID} />,
        icon: "icon-badge",
      },
      {
        title: "Information Systems",
        subTitle: "Risk Assessment systems associated with this Vulnerability",
        component: <VulnerabilitySystems item={{ id: itemId }} />,
        icon: "icon-screen-desktop",
      },
      {
        title: "Action Items",
        subTitle: "Action items associated with this Vulnerability",
        component: <VulnerabilityActionItems item={{ id: itemId }} />,
        icon: "icon-rocket",
      },
    ],
  });

  const display = (
    <Dashboard resetFunction={details.reset} subTitle={details?.item?.name}>
      <div>
        <Grid container spacing={2}>
          <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
            {accordion.display}
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <VulnerabilityTimeline item={details.item} />
          </Grid>
        </Grid>
      </div>
    </Dashboard>
  );

  return {
    display,
  };
};
