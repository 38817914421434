import { generateGraphql } from "@rivial-security/generategraphql";
import { ItemMutation } from "../../../Functions/Graphql/ItemMutation";

/**
 * @description Creates a new ArtifactLabelLink
 * @param {object} artifact - The artifact to create a label link for.
 * @param {object} label - The label to create a link for.
 * @param {string} organizationID - The organization ID to create the label link for.
 * @param {string} props - Additional properties to pass to create mutation.
 * @returns {Promise<*>}
 */
export const createArtifactLabelLink = async ({ artifact, label, organizationID, ...props }) => {
  if (!label?.id) {
    throw Error("[createArtifactLabelLink.js] Error, invalid label input!");
  }

  if (!artifact?.id) {
    throw Error("[createArtifactLabelLink.js] Error, invalid artifact input!");
  }

  if (!organizationID) {
    throw Error("[createArtifactLabelLink.js] Error, invalid organizationID input!");
  }

  const { createMutation: createArtifactLabelLink } = generateGraphql(
    "ArtifactLabelLink",
    ["artifactID", "labelID", "__typename", "label", "bookmarks"],
    {
      label: `{ id name description backgroundColor fontColor }`,
      bookmarks: `{ id createdByAI owner text data }`,
    },
  );

  return await ItemMutation(createArtifactLabelLink, {
    ...props,
    artifactID: artifact?.id,
    labelID: label?.id,
    ownerGroup: organizationID,
  });
};
