import { Badge } from "reactstrap";
import React from "react";
import { precedenceTypes as PRECEDENCE_TYPES } from "@rivial-security/role-utils";
import { getPrecedenceDescription } from "../functions/getPrecedenceDescription";

/**
 *
 * @param item
 * @return {JSX.Element}
 * @constructor
 */
const RolePrecedence = ({ item }) => {
  return (
    <>
      {(() => {
        switch (item?.precedence) {
          case PRECEDENCE_TYPES.DEVELOPER:
            return (
              <Badge color="danger" title={getPrecedenceDescription(item?.precedence)}>
                DEVELOPER
              </Badge>
            );
          case PRECEDENCE_TYPES.ADMIN:
            return (
              <Badge color="warning" title={getPrecedenceDescription(item?.precedence)}>
                ADMIN
              </Badge>
            );
          case PRECEDENCE_TYPES.MANAGER:
            return (
              <Badge color="success" title={getPrecedenceDescription(item?.precedence)}>
                MANAGER
              </Badge>
            );
          case PRECEDENCE_TYPES.SUPERVISOR:
            return (
              <Badge color="primary" title={getPrecedenceDescription(item?.precedence)}>
                SUPERVISOR
              </Badge>
            );
          case PRECEDENCE_TYPES.USER:
            return (
              <Badge color="info" title={getPrecedenceDescription(item?.precedence)}>
                STANDARD
              </Badge>
            );
          default:
            return (
              <Badge color="secondary" title={"The Role Type for this role is invalid."}>
                Unknown
              </Badge>
            );
        }
      })()}
    </>
  );
};

export default RolePrecedence;
