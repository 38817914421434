import React from "react";
import OrganizationCheck from "../../../../../../utils/Context/OrganizationCheck/OrganizationCheck";
import { withRouter } from "react-router-dom";
import { useKeyRiskIndicatorChangeDetails } from "../hooks/useKeyRiskIndicatorChangeDetails";
import { modules, resources } from "@rivial-security/role-utils";

const KeyRiskIndicatorChangeDetailsBody = ({ itemId, tableDisplay }) => {
  const module = modules.RISK;
  const resource = resources.RISK_CHANGE;

  const keyRiskIndicatorChangeDetails = useKeyRiskIndicatorChangeDetails({
    itemId,
    module,
    resource,
    tableDisplay,
  });

  return <>{keyRiskIndicatorChangeDetails.display}</>;
};

const KeyRiskIndicatorChangeDetails = (props) => {
  const itemId = (props.item && props.item.id) || props.match.params.id;
  return (
    <OrganizationCheck {...props}>
      <KeyRiskIndicatorChangeDetailsBody itemId={itemId} />
    </OrganizationCheck>
  );
};

export default withRouter(KeyRiskIndicatorChangeDetails);
