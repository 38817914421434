import {
  createBlankLine,
  createText,
  generateCoverPage,
  generateReport,
  generateSection,
} from "@rivial-security/report-utils";
import { FILTER_FIELDS, generateVendorControlsTable } from "./generateVendorControlsTable";
import { generateControlCategoryTable } from "./generateControlCategoryTable";

/**
 * @description Generate Vendor Review Controls Report
 * @param {object} reportContext - Vendor Review Controls report context
 * @returns {object} {Promise<{defaultTabWidth: number, paragraphFormat: {afterSpacing: number, bidi: boolean, rightIndent: number, lineSpacingType: string, leftIndent: number, textAlignment: string, listFormat: {}, beforeSpacing: number, lineSpacing: number, firstLineIndent: number}, abstractLists: *[], protectionType: string, comments: [], characterFormat: {highlightColor: string, fontFamily: string, underline: string, fontFamilyBidi: string, fontSize: number, bold: boolean, strikethrough: string, fontSizeBidi: number, italic: boolean, fontColor: string, baselineAlignment: string}, enforcement: boolean, sections: *[], hashValue: string, lists: {listId: number, levelOverrides: [], abstractListId: number}[], saltValue: string, dontUseHTMLParagraphAutoSpacing: boolean, styles: *[], formatting: boolean}>}
 */
export const generateVendorReviewControlsReport = async (reportContext) => {
  const reportType = "Vendor Review Controls";
  const headingStyle = {
    italic: true,
    bold: true,
    fontSize: 14,
    fontColor: "#2c4c93FF",
  };

  return generateReport([
    generateCoverPage({
      reportTitle: reportType,
      organizationName: reportContext?.organizationName || "Bank Name",
      reportTitleFontSize: 35,
    }),
    generateSection({
      blocks: [
        createText("Vendor Review", "Heading 2", headingStyle),
        createText(reportContext?.config?.vendorReview?.name),
        createBlankLine(),
        createText("Control Categories", "Heading 2", headingStyle),
        generateControlCategoryTable(reportContext),
        createBlankLine(),
        createText("All Controls", "Heading 2", headingStyle),
        ...generateVendorControlsTable(reportContext, FILTER_FIELDS.all),
        createBlankLine(),
        createText("Controls Not in Place", "Heading 2", headingStyle),
        ...generateVendorControlsTable(reportContext, FILTER_FIELDS.notInPlace),
        createBlankLine(),
        createText("Controls Not Audited", "Heading 2", headingStyle),
        ...generateVendorControlsTable(reportContext, FILTER_FIELDS.notAudited),
      ],
      reportContext,
      reportType,
    }),
  ]);
};
