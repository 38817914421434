import { generateGraphql } from "@rivial-security/generategraphql";
import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";
import React from "react";
import ResponseTeamDetails from "../components/ResponseTeamDetails";
import CreateResponseTeam from "../components/CreateResponseTeam";
import PointOfContacts from "../customFields/PointOfContacts";
import { DETAILS_TYPES } from "../../../../hooks/views/useGrid/enums/DETAILS_TYPES";
import { modules, resources } from "@rivial-security/role-utils";
import { deleteResponseTeam } from "../functions/deleteResponseTeam";

export const useResponseTeamDataGrid = ({
  organizationID,
  cardConfig = {},
  gridConfig = {},
  queryConfig = {},
  ...props
}) => {
  const module = modules.INCIDENT_RESPONSE;
  const resource = resources.RESPONSE_TEAM;
  const route = "#/response/teams/";

  cardConfig = {
    title: "Response Teams",
    headerIcon: "icon-people",
    enableSticky: true,
    ...cardConfig,
  };

  const { listQuery } = generateGraphql("ResponseTeam", ["name", "description", "pointOfContactLinks"], {
    pointOfContactLinks: `(limit: 1000) { items { pointOfContact { id firstName lastName email title } } }`,
  });

  queryConfig = {
    organizationID,
    query: listQuery,
    ...queryConfig,
  };

  const roleConfig = {
    module,
    resource,
  };

  const fields = [
    {
      name: "name",
    },
    {
      name: "description",
      valueFormatter: (value) => value || "No description provided",
    },
    {
      name: "pointOfContactLinks",
      friendlyName: "Team Members",
      visible: false,
      component: <PointOfContacts horizontal={true} orientation={"vertical"} />,
    },
  ];

  gridConfig = {
    typename: "ResponseTeam",
    fields,
    route,
    detailsComponent: <ResponseTeamDetails tableDisplay={true} />,
    detailsType: DETAILS_TYPES.MODAL,
    detailsTitle: "Response Team Details",
    persistenceUUID: "67a487db-bac0-4d66-9cc9-4b0c22b00701",
    createResourceComponent: <CreateResponseTeam />,
    deleteFunction: deleteResponseTeam,
    options: ["details", "delete", "edit"],
    ...gridConfig,
    ...props,
  };

  const grid = useDataGrid({
    ...queryConfig,
    ...cardConfig,
    ...roleConfig,
    ...gridConfig,
  });

  return { ...grid };
};
