import { createActionItem } from "../../Actions/functions/createActionItem";
import { createRecommendationActionItemLink } from "../../Actions/functions/createRecommendationActionItemLink";
import { createRecommendation } from "./createRecommendation";
import { createObservationRecommendationLink } from "../../Observations/functions/createObservationRecommendationLink";

/**
 * Creates a RecommendationItem along with associated ActionItems. Will connect to an observation if passed in.
 * @param input
 * @param observation
 */
export const createFullRecommendation = async (input, observation) => {
  let rec;

  if (!input.alreadyExists) {
    rec = await createRecommendation({
      name: input.name,
      status: "unresolved",
      rating: input.rating || "low",
      module: input.module,
      ownerGroup: input.ownerGroup,
      difficulty: input.difficulty,
    });
  } else {
    rec = input;
  }

  if (observation) {
    await createObservationRecommendationLink(observation, rec);
  }

  // Create Actions

  const actionItems = input?.actionItems || [];

  for (const actionItem of actionItems) {
    if (!actionItem.alreadyExists) {
      const newActionItem = await createActionItem({
        name: actionItem.name,
        status: "proposed",
        priority: actionItem.priority || 0,
        description: actionItem.description,
        dueDate: actionItem.dueDate ? new Date(actionItem.dueDate) : undefined,
        // automation: actionItem.automation,
        genericActionItemPointOfContactId: actionItem.genericActionItemPointOfContactId,
        ownerGroup: input.ownerGroup,
      });

      await createRecommendationActionItemLink(rec, newActionItem);
    }
  }

  return rec;
};
