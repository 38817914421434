import { getEvidenceNonStandardRiskControlLink } from "./getEvidenceNonStandardRiskControlLink";
import { generateGraphql } from "@rivial-security/generategraphql";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";

/**
 * Delete the 3 way link between evidence, risk control and system
 * @param {object} evidence - the evidence that has the link
 * @param {object} item - the risk control data including system
 * @return {Promise<*>}
 */
export const unlinkEvidenceNonStandardRiskControl = async ({ evidence, item } = {}) => {
  //Check arguments return if not truthy
  if (!evidence || !item) {
    return;
  }

  //Get an existing link if it exists
  const existingLink = await getEvidenceNonStandardRiskControlLink({
    evidence,
    riskControlId: item?.control?.id,
    systemId: item?.system?.id,
  });

  //If the link exists, delete it
  if (existingLink?.id) {
    const { deleteMutation } = generateGraphql("RiskControlEvidenceLink");
    return await ItemMutation(deleteMutation, { id: existingLink?.id });
  }

  //If the link doesn't exist, do nothing
};
