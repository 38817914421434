import { Col, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row } from "reactstrap";
import React, { useEffect, useState } from "react";
import ChangeInCost from "../../../../Recommendations/customFields/ChangeInCost";

export const useAdjustAssetSize = ({ assetSize }) => {
  const [assetSizeChange, setAssetSizeChange] = useState(0);
  const [newAssetSize, setNewAssetSize] = useState(0);

  // Calculate the change in asset size
  useEffect(() => {
    let res = 0;
    if (assetSize) {
      if (assetSizeChange) {
        res = parseFloat(assetSize) + parseFloat(assetSizeChange);
      } else {
        res = assetSize;
      }
    }
    setNewAssetSize(res);
  }, [assetSize, assetSizeChange]);

  const display = (
    <>
      {assetSize !== null && assetSize !== undefined && (
        <>
          <FormGroup>
            <Label for="assetSizeChange">Change In Asset Size</Label>
            <Row>
              <Col md={4}>
                <ChangeInCost newCost={newAssetSize} oldCost={assetSize} />
              </Col>
              <Col>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>$</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="number"
                    id="assetSizeChange"
                    value={assetSizeChange}
                    onChange={(e) => setAssetSizeChange(e.target.value)}
                  />
                </InputGroup>
              </Col>
            </Row>
          </FormGroup>
        </>
      )}
    </>
  );

  return {
    display,
    assetSizeChange,
    newAssetSize,
  };
};
