import React, { useEffect, useState } from "react";
import { modules, resources } from "@rivial-security/role-utils";

import Box from "@mui/material/Box";
import CustomFieldEditor from "../../../Compliance/Controls/Controls/customFields/CustomFieldEditor/CustomFieldEditor";
import Dashboard from "../../../../utils/GenericComponents/Dashboard";
import DashboardCard from "../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import { ErrorLogger } from "@utils/EventLogger";
import Grid from "@mui/material/Grid";
import { ItemQuery } from "../../../../utils/Functions/Graphql/ItemQuery";
import UnderlinedTooltip from "../../../../utils/Tooltips/UnderlinedTooltip";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useDetailsCard } from "../../../../hooks/views/useDetailsCardV2";

/**
 * Custom hook for displaying the details of a Custom Resource Entry
 * @param {string} itemId - The id of the CustomResourceEntry to display
 * @param {boolean} tableDisplay - Whether or not the CustomResourceEntry is being displayed in a table
 * @param {object} props - Any other props to pass to the component
 * @returns {{isLoading, setInputId, inputId, item, display: *, reset, setItem}}
 */
export const useCustomResourceEntryDetails = ({ itemId, tableDisplay, ...props }) => {
  ///[GENERAL SETUP]
  // - Constants
  const module = modules.TOOLS;
  const resource = resources.CUSTOM_RESOURCE_TYPE;
  const typename = "CustomResourceEntry";
  // - State
  const [addonFields, setAddonFields] = useState([]);
  const [addonCustomFields, setAddonCustomFields] = useState([]);
  const [addonFieldNameDictionary, setAddonFieldNameDictionary] = useState({});

  // - Queries
  const { getQuery, updateMutation } = generateGraphql(typename, ["customFieldData", "customResourceType"], {
    customResourceType: `{id name customFields { name description type options { label value } multipleSelect { label value } numberSettings { min max step format } } }`,
  });

  ///[DETAILS SETUP]
  const queryConfig = {
    query: getQuery,
    itemId,
  };

  const detailsFields = ["customResourceType"].concat(addonFields);

  const detailsConfig = {
    fields: detailsFields,
    customFields: addonCustomFields,
    fieldNameDictionary: {
      ...addonFieldNameDictionary,
    },
    updateMutation,
  };

  const detailsHook = useDetailsCard({
    module,
    resource,
    tableDisplay,
    queryConfig,
    detailsConfig,
    ...props,
  });

  ///[DETAILS CUSTOM FIELDS]
  // TODO: when loading display a component attachment saying that custom fields are still loading use the null value of state
  const { getQuery: getCustomResourceTypeQuery } = generateGraphql("CustomResourceType", ["name", "customFields"], {
    customFields: `{name description type options { label value } multipleSelect { label value } numberSettings { min max step format }  }`,
  });

  // Handles retrieval of shown custom fields by looking at CustomResourceType field config
  const updateShownAddonFields = async (customResourceEntry) => {
    //Show no fields if no CustomResourceType id is present
    let selectedResourceTypeID = "";
    if (!customResourceEntry?.customResourceType?.id) {
      ErrorLogger("No CustomResourceType found in CustomResourceEntry item to display custom fields");
      return;
    } else {
      selectedResourceTypeID = customResourceEntry.customResourceType.id;
    }

    //Check to see if CustomResourceType of current CustomResourceEntry exist (retrieve all custom field info)
    let customFields = [];
    try {
      const customResourceType = await ItemQuery(getCustomResourceTypeQuery, selectedResourceTypeID);
      if (customResourceType?.customFields && Array.isArray(customResourceType.customFields)) {
        customFields = customResourceType.customFields;
      }
    } catch (e) {
      ErrorLogger("Could not retrieve CustomResourceType data for CustomResourceEntry details!", e);
      return;
    }

    //Check to see if custom fields property exists on this CustomResourceEntry
    if (!customResourceEntry?.customFieldData) {
      return;
    }

    //Parse the custom field json from the CustomResourceEntry
    let customResourceEntryCustomFields = {};
    try {
      customResourceEntryCustomFields = JSON.parse(customResourceEntry.customFieldData);
    } catch (e) {
      ErrorLogger("Could not retrieve CustomResourceType data for CustomResourceEntry details!", e);
      return;
    }

    //Show all of CustomResourceType fields as additional fields for the CustomResourceEntry (update customFields state)
    const newAddonFields = [];
    const newAddonCustomFields = [
      {
        field: "customResourceType",
        component: <CustomResourceTypeField />,
      },
    ];
    const newAddonFieldNameDictionary = {};
    for (const field of customFields) {
      if (field?.name) {
        newAddonFields.push(field.name);
        newAddonCustomFields.push({
          field: field.name,
          isDynamicCustomField: true, //roles not yet implemented (field will be added even if not selected)
          component: (
            <CustomFieldEditor
              typename={typename}
              module={module}
              resource={resource}
              config={field}
              data={
                customResourceEntryCustomFields.hasOwnProperty(field.name)
                  ? customResourceEntryCustomFields[field.name]
                  : undefined
              }
            />
          ),
        });
        // Want to preserve the format of the field that the user typed in
        newAddonFieldNameDictionary[field.name] = field.name;
      }
    }

    setAddonFields(newAddonFields);
    setAddonCustomFields(newAddonCustomFields);
    setAddonFieldNameDictionary(newAddonFieldNameDictionary);
  };

  // Handles the changes in displayed CustomResourceEntry item by readjusting the displayed custom fields
  useEffect(() => {
    if (detailsHook?.item) {
      setAddonFields([]);
      setAddonCustomFields([]);
      setAddonFieldNameDictionary({});
      const item = detailsHook.item;
      updateShownAddonFields(item);
    }
  }, [detailsHook.item]);

  ///[RENDERING]
  const display = (
    <Dashboard resetFunction={detailsHook.reset} id={"custom-resource-type-details-dashboard"}>
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <DashboardCard
            title="General Information"
            style={{ height: "100%" }}
            id={"custom-resource-type-details-general-information"}
          >
            {detailsHook.tableDisplay}
          </DashboardCard>
        </Grid>
      </Grid>
    </Dashboard>
  );

  return {
    ...detailsHook,
    display,
  };
};

/**
 * Displays the CustomResourceType that a CustomResourceEntry it associated with
 * @param props
 * @constructor
 */
const CustomResourceTypeField = ({ item }) => {
  return (
    <UnderlinedTooltip text={item?.customResourceType?.name}>
      <Box>
        <strong>Field Configuration</strong>
      </Box>
    </UnderlinedTooltip>
  );
};
