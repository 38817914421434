import { useForm } from "../../../../hooks/views/useForm/useForm";
import FrequencyScheduler from "../../../../utils/GenericComponents/FrequencyScheduler/FrequencyScheduler";
import React from "react";

/**
 * WIP - need to finish implementing Scheduled trigger
 * @param onChangeCallback
 * @param disableEdits
 * @param defaultValue
 * @returns {*}
 * @constructor
 */
const ConfigureScheduleTrigger = ({ onChangeCallback, disableEdits, defaultValue }) => {
  const form = useForm({
    disableSubmitButton: true,
    disableResetButton: true,
    disableRoleChecking: true,
    fieldConfig: {
      cronSchedule: {
        label: "Schedule",
        onChangeFunction: (e) => {
          if (typeof onChangeCallback === "function") {
            onChangeCallback(e?.cronSchedule);
          }
        },
        inputType: "custom",
        defaultValue,
        disabled: disableEdits,
        customConfig: {
          component: <FrequencyScheduler disabled={disableEdits} initValue={defaultValue} />,
        },
        tooltip: "Construct a Cron Expression that is used to determine the frequency schedule.",
      },
    },
  });

  return form.display;
};

export default ConfigureScheduleTrigger;
