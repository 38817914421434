import { useAuditDataGrid } from "../hooks/useAuditDataGrid";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * @description Component for displaying an Audit Grid
 * @param {object} props - props for the component
 * @returns {JSX.Element} - component markup
 */
const AuditDataGrid = (props) => {
  return useAuditDataGrid(props).display;
};

export default withOrganizationCheck(AuditDataGrid);
