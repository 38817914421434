import { modules, resources } from "@rivial-security/role-utils";
import CreateCustomQuery from "../../CustomQueries/components/CreateCustomQuery";
import StyledWrapper from "../../../utils/GenericComponents/StyledWrapper";
import React from "react";

/**
 * Custom form component for creating CustomQuery Templates
 * @param {string} organizationID - the ownerGroup to use for creating/querying templates
 * @param {string} module - the module to use for role checking
 * @param {string} resource - the resource to use for role checking
 * @param {boolean} disableRoleChecking - if TRUE will disable role checking
 * @param {object} ...props - any other props to pass to the form
 * @returns {JSX.Element}
 */
const CustomQueryTemplateForm = ({
  organizationID,
  module = modules.ORGANIZATION_MANAGER,
  resource = resources.CUSTOM_QUERY,
  disableRoleChecking = false,
  ...props
}) => {
  return (
    <StyledWrapper wrapperStyle={{ height: "80vh" }}>
      <CreateCustomQuery
        organizationID={organizationID}
        module={module}
        resource={resource}
        disableRoleChecking={disableRoleChecking}
        isTemplate={true}
        enableQueryRunner={false}
        enableSubmitButton={true}
        {...props}
      />
    </StyledWrapper>
  );
};

export default CustomQueryTemplateForm;
