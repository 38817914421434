import { get } from "lodash";
import { resourceDefinitions } from "../constants/resourceDefinitions";

export interface GetResourceAttributeParams {
  override?: object;
  typename: string;
  attribute: string;
}

/**
 * Retrieves a part of resource definition if it exists, uses lodash to retrieve deeply nested values
 * @param override - merged with definition at start
 * @param typename - string representing the resource type by which to find the definition
 * @param attribute - path to needed property
 */
export const getResourceAttribute = ({ override, typename, attribute }: GetResourceAttributeParams): string => {
  const resourceDefinition = resourceDefinitions[typename];

  return get({ ...override, ...resourceDefinition }, attribute);
};
