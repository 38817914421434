import React, { useContext, useState } from "react";

import Button from "@mui/material/Button";
import { CustomInput } from "reactstrap";
import DashboardCardSection from "../../GenericComponents/DashboardCard/components/DashboardCardSection";
import { ItemMutation } from "../../Functions/Graphql/ItemMutation";
import NodejsCipher from "../../Functions/NodejsCipher";
import { OrganizationContext } from "../../Context/OrganizationContext";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useAccordion } from "../../../hooks/views/useAccordion/useAccordion";
import { useAddPasswordControl } from "./useAddPasswordControl";
import { useAddPointOfContactControl } from "./useAddPointOfContactControl";
import { useAddRoleControl } from "./useAddRoleControl";
import { useModal } from "../../../hooks/views/useModal";

/**
 * @description Add Access Control to an item
 * @param {object} item - database object with an accessControls field
 * @param {string} typename - schema type name with a capital first letter
 * @param {object} organizationContext - organization context with logged-in user
 * @param {function} resetFunction - reset state of the item
 * @param {boolean} disableFormButtons - disable form buttons
 * @param {boolean} accessControlEnabled - if TRUE will set the default value of the access control switch to enabled
 * @returns {{display: JSX.Element}}
 */
export const useAddAccessControl = ({
  item,
  typename = "item",
  organizationID,
  resetFunction,
  disableFormButtons,
  accessControlEnabled = false,
}) => {
  const context = useContext(OrganizationContext);

  /**
   * State of the switch component
   */
  const [isOpen, setIsOpen] = useState(accessControlEnabled);

  /**
   * Open and close the main modal
   */
  const toggleModal = () => modal?.setModalIsOpen(!modal?.modalIsOpen);

  /**
   * Main submit function
   */
  const submitFunction = async ({ itemToAssign }) => {
    /**
     * Only proceed if the 'Access Control' switch is on
     */
    if (!isOpen) {
      return;
    }

    /**
     * Add password protection
     */
    let encryptedPassword;
    if (passwordForm?.input?.password) {
      encryptedPassword = await NodejsCipher({
        operation: "encrypt",
        dataToEncrypt: passwordForm?.input?.password,
      });
    }

    /**
     * Check if any roles were selected
     */
    const selectedRoles =
      Array.isArray(roleForm?.selectedItems) && roleForm?.selectedItems.length > 0
        ? [...(roleForm?.selectedItems.map((x) => x?.id) ?? {})]
        : [];

    /**
     * If no roles were selected then check if item had roles before
     */
    const oldRoles =
      Array.isArray(item?.accessControl?.roles) && item?.accessControl?.roles.length > 0
        ? item?.accessControl?.roles
        : [];

    /**
     * Check if any pointOfContacts were selected
     */

    const selectedPointOfContacts =
      Array.isArray(pointOfContactForm?.selectedData) && pointOfContactForm?.selectedData.length > 0
        ? [...(pointOfContactForm?.selectedData.map((x) => x?.id) ?? {})]
        : [];

    /**
     * If no pointOfContacts were selected then check if item had pointOfContacts before
     */
    const oldPointOfContacts =
      Array.isArray(item?.accessControl?.pointOfContacts) && item?.accessControl?.pointOfContacts.length > 0
        ? item?.accessControl?.pointOfContacts
        : [];

    //  Make sure there are only unique roles in the array
    const roles = [...new Set([...selectedRoles, ...oldRoles])];
    //   Make sure there are only unique points of contact in the array
    const pointOfContacts = [...new Set([...selectedPointOfContacts, ...oldPointOfContacts])];

    /**
     * Add Access Controls to an item
     */
    await ItemMutation(generateGraphql(typename).updateMutation, {
      id: itemToAssign?.id || item?.id,
      accessControl: {
        ...item?.accessControl,
        ...(encryptedPassword && {
          password: encryptedPassword,
          passwordOwnerEmail: context?.userEmail,
        }),
        roles,
        pointOfContacts,
      },
    });

    roleForm?.setSelectedItems([]);
    roleForm?.setSelectedItemIDs([]);
    pointOfContactForm?.setSelectedItems([]);
    pointOfContactForm?.setSelectedItemIDs([]);
    resetFunction && resetFunction();
    toggleModal && toggleModal();
  };

  /**
   * Add Password Access Control form
   */
  const passwordForm = useAddPasswordControl({
    item,
    typename,
    submitFunction,
    disableFormButtons,
    resetFunction,
    toggleModal,
  });

  /**
   * Add Point of Contact Access Control form
   */
  const pointOfContactForm = useAddPointOfContactControl({
    item,
    organizationID: organizationID || item?.ownerGroup,
    submitFunction,
    disableFormButtons,
    resetFunction,
  });

  /**
   * Add Role Access Control form
   */
  const roleForm = useAddRoleControl({
    item,
    organizationID: organizationID || item?.ownerGroup,
    submitFunction,
    disableFormButtons,
    resetFunction,
  });

  const accordion = useAccordion({
    items: [
      {
        title: "Password Protect",
        subTitle: `Require a password to view the ${typename}`,
        component: passwordForm?.display,
        icon: "icon-lock",
      },
      {
        title: "Roles",
        subTitle: `Only allows the specified Role(s) to view the ${typename}`,
        component: roleForm?.display,
        icon: "icon-user-female",
      },
      {
        title: "Points of Contact",
        subTitle: `Only allows the specified Point of Contact(s) to view the ${typename}`,
        component: pointOfContactForm?.display,
        icon: "icon-people",
      },
    ],
  });

  /**
   * Accordion UI
   */
  const displayAccordion = accordion?.display;

  /**
   * Switch toggle UI
   */
  const displaySwitch = (
    <DashboardCardSection
      title={"Access Control"}
      content={
        <div>
          <CustomInput
            type="switch"
            id="isAccessControl"
            name="isAccessControl"
            checked={isOpen}
            onChange={() => setIsOpen(!isOpen)}
          />
          {isOpen && <div style={{ marginTop: "1em" }}> {displayAccordion} </div>}
        </div>
      }
    />
  );

  /**
   * Modal UI
   */
  const modal = useModal(
    "Manage Access",
    displayAccordion,
    <Button startIcon={<i className={"icon-lock"} />} title={`Manage Access Control for this ${typename}`}>
      Manage Access
    </Button>,
    {
      width: "50vw",
    },
  );

  /**
   * Main hook UI
   */
  const display = <span className="float-right">{modal.modalButton}</span>;

  return {
    display,
    displayAccordion,
    displaySwitch,
    submitFunction,
  };
};
