import React, { useEffect, useState } from "react";
import { useMutation } from "@hooks/graphql/useMutation/useMutation";
import { Input, InputGroup } from "reactstrap";
import UpdateFieldAddon from "../../../../../../../utils/GenericComponents/UpdateFieldAddon";
import { useCheckPermissions } from "@hooks/permissions/useCheckPermissions/useCheckPermissions";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";
import { withOrganizationCheck } from "@utils/Context/withOrganizationCheck";

const { updateMutation: updateSystemInformationAssetLink } = generateGraphql("SystemInformationAssetLink");

/**
 * Displays the Number of Records for an InformationAsset/System combo
 * @param module
 * @param resource
 * @param disableRoleChecking
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const NumberOfRecords = ({
  module = modules.RISK,
  resource = resources.INFORMATION_SYSTEM,
  disableRoleChecking = false,
  ...props
}) => {
  const field = "numberOfRecords";

  const checkPermissionsHook = useCheckPermissions({
    module,
    resource,
    field,
    disableRoleChecking,
  });

  const { informationAssetLink } = props;

  const [numberOfRecords, setNumberOfRecords] = useState(0);
  const [initialNumberOfRecords, setInitialNumberOfRecords] = useState(0);
  const [enableEdit, setEnableEdit] = useState(false);

  useEffect(() => {
    if (informationAssetLink.numberOfRecords) {
      setNumberOfRecords(informationAssetLink.numberOfRecords);
      setInitialNumberOfRecords(informationAssetLink.numberOfRecords);
    }
  }, [informationAssetLink]);

  const numberOfRecordsMutation = useMutation({
    mutation: updateSystemInformationAssetLink,
    module,
    resource,
    field,
    disableRoleChecking,
  });

  const handleSubmit = () => {
    if (numberOfRecords) {
      const input = {
        id: informationAssetLink.id,
        numberOfRecords: parseInt(numberOfRecords),
      };

      setEnableEdit(false);

      numberOfRecordsMutation.editItem(input).then(() => {
        setInitialNumberOfRecords(numberOfRecords);
        props.resetFunction && props.resetFunction();
      });
    }
  };

  const handleCancel = () => {
    setNumberOfRecords(initialNumberOfRecords);
    setEnableEdit(false);
  };

  return (
    <InputGroup>
      <Input
        type="number"
        min={0}
        disabled={!enableEdit}
        value={numberOfRecords}
        onChange={(e) => setNumberOfRecords(e.target.value)}
      />
      <UpdateFieldAddon
        enableEdit={enableEdit}
        setEnableEdit={setEnableEdit}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        checkPermissionsHook={checkPermissionsHook}
        fieldName="Number of Records"
        disableRoleChecking={disableRoleChecking}
      />
    </InputGroup>
  );
};

export default withOrganizationCheck(NumberOfRecords);
