import { useControlDataGrid } from "../hooks/useControlGrid/useControlDataGrid";
import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";

/**
 * Component instance of the useControlDataGrid hook
 * @param props - all props passed to the hook
 * @returns {*}
 * @constructor
 */
const ControlDataGrid = (props) => useControlDataGrid(props).display;

export default withOrganizationCheck(ControlDataGrid);
