/**
 * Author: Jacob Blazina
 * Created At: N/A
 * Edits:
 *  - 7/22/19 JB: Added header signature
 *  - 8/28/19 JB: Refactored for Organization Check pattern
 *  - 2/27/20 BC: Refactored to use the useQueryCardV2 hook.
 *
 * Description: A Card component.
 *              Lists all Points Of Contact for an Organization.
 *              Limit default set to 100.
 *
 */

import React from "react";
import { usePointOfContactList } from "../hooks/usePointOfContactList";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * Displays a simple ListGroup version of points of contact.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const PointOfContactList = (props) => {
  const pointOfContactList = usePointOfContactList({
    organizationID: props.organizationID,
    showSelectBoxes: props.showSelectBoxes,
    buttonCallback: props.buttonCallback,
    selectionType: props.selectionType,
  });

  return <>{pointOfContactList.display}</>;
};

export default withOrganizationCheck(PointOfContactList);
