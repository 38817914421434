import React, { useState } from "react";
import { Typography, Menu, MenuItem, ListItemIcon } from "@mui/material";
import { Icon } from "@iconify/react";
import * as Sentry from "@sentry/react";

/**
 * @typedef {Object} ContextMenuItemData
 * @property {string} label - The text to display in the menu
 * @property {string} icon - The icon to display before the label
 * @property {function} onClick - The function to call when the menu item is clicked
 * @property {JSX.Element} [component] - The component to display in the menu  instead of icon/label combination
 * @property {string} dataTestId - The data-testid to use for the menu item
 * @property {object} props - any additional props to pass through to the mui MenuItem, applies when no component is passed
 */

/**
 * A customizable menu that can be opened by right-clicking on the child component
 * REFERENCE: context menu mui example and API reference - https://mui.com/material-ui/react-menu/
 * @param {ContextMenuItemData} menuItems - an array of objects that define individual menu items
 * @param {JSX.Element} children - the component on which the user needs to right-click to open the menu
 * @param {object} sx - css inline styles to apply to different parts of the menu
 * @param {object} sx.container - any css style attributes to apply to the menu container
 * @param {object} sx.menu - any css style attributes to apply to the mui menu
 * @param {boolean} enableLeftClick - whether to enable opening the menu on left click
 * @param {object[]} props - any additional props to pass through to the mui Menu component
 * @return {{display: JSX.Element}}
 */
export const useContextMenu = ({ enableLeftClick = false, menuItems, children, sx, ...props }) => {
  const [contextMenu, setContextMenu] = useState(null);

  const handleContextMenu = (event) => {
    try {
      //No menu items, no context menu
      if (!Array.isArray(menuItems) || menuItems.length === 0) {
        return;
      }

      event.preventDefault();
      setContextMenu(
        contextMenu === null
          ? {
              mouseX: event.clientX - 2,
              mouseY: event.clientY - 4,
            }
          : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
            // Other native context menus might behave different.
            // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
            null,
      );
    } catch (e) {
      Sentry.captureMessage(e.message, {
        level: "warning",
      });
    }
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const display = (
    <div
      onClick={enableLeftClick ? handleContextMenu : undefined}
      onContextMenu={handleContextMenu}
      style={{ cursor: "context-menu", ...(sx?.container || {}) }}
    >
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
        {...props}
        style={{ zIndex: 999999, ...(sx?.menu || {}) }}
      >
        {Array.isArray(menuItems) &&
          menuItems.length > 0 &&
          menuItems.map((item, index) => {
            const { icon, label, onClick, component, dataTestId, ...props } = item || {};

            const key = `context-menu-item-${index}`;
            if (component) {
              return React.cloneElement(component, { key });
            } else if (typeof onClick === "function") {
              return (
                <MenuItem
                  key={key}
                  onClick={() => {
                    handleClose();
                    onClick();
                  }}
                  data-testid={dataTestId}
                  {...props}
                >
                  {icon && (
                    <ListItemIcon>
                      <Icon icon={icon} width="22" height="22" />
                    </ListItemIcon>
                  )}
                  <Typography variant="inherit">{label || `Option ${index}`}</Typography>
                </MenuItem>
              );
            }
          })}
      </Menu>
      {children}
    </div>
  );

  return { display };
};
