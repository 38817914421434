import RiskScoreGauge from "../../../components/SystemAccordion/RiskScorecard/components/RiskScoreGauge";
import React from "react";

/**
 * Displays System Name, Settings, and Risk Score gauge for system details
 * @param {object} item - the System object
 * @param {string} item.name - the name of the system
 * @param {object} settingsHook - hook that has system settings
 * @param Context
 * @returns {JSX.Element}
 * @constructor
 */
const SystemInfoCard = ({ Context, item }) => {
  return (
    <div>
      <Context>
        <RiskScoreGauge item={item} />
      </Context>
    </div>
  );
};

export default SystemInfoCard;
