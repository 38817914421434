import GenericEditFieldV3 from "../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";
import { generateGraphql } from "@rivial-security/generategraphql";
import React from "react";
import { GENERIC_FIELD_TYPES } from "../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import { modules, resources } from "@rivial-security/role-utils";

const ActionItemEnableNotifications = ({ item }) => {
  const field = "enableNotifications";
  const module = modules.GOVERNANCE;
  const resource = resources.ACTION_ITEM;

  const { updateMutation } = generateGraphql("GenericActionItem", ["enableNotifications"]);

  return (
    <GenericEditFieldV3
      item={item}
      inputType={GENERIC_FIELD_TYPES.SWITCH}
      module={module}
      resource={resource}
      field={field}
      mutation={updateMutation}
    />
  );
};

export default ActionItemEnableNotifications;
