import React from "react";
import { useForm } from "../../../../hooks/views/useForm";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";
import VendorControlCategoryImporterModalButton from "../components/VendorControlCategoryImporterModalButton";

/**
 * Custom hook for creating a Vendor Control Category
 * @param {string} organizationID - the organization ID
 * @param {function} getNewItem - a function to get a new item
 * @param {boolean} isTemplate - whether or not this is a template
 * @param {string} module - the role config module
 * @param {string} resource - the role config resource
 * @param {function} toggleModal - a function to toggle the modal
 * @param {object} props - other props to pass to the useForm hook
 * @returns {{input: {}, display: *}}
 */
export const useCreateVendorControlCategory = ({
  organizationID = "vendor-review-templates",
  getNewItem,
  isTemplate,
  module = modules.VENDORS,
  resource = resources.VENDOR_CONTROL_CATEGORY,
  toggleModal,
  ...props
}) => {
  const typename = "VendorControlCategory";

  const { createMutation } = generateGraphql("VendorControlCategory", ["name", "quantivateRiskCategory", "isTemplate"]);

  const fields = ["name"];

  const formHook = useForm({
    header: "Create a Vendor Control Category",
    toggleModal,
    mutation: createMutation,
    organizationID,
    getNewItem,
    module,
    resource,
    typename,
    fields,
    headerButtons: [<VendorControlCategoryImporterModalButton organizationID={organizationID} />],
    fieldConfig: {
      name: {
        label: "Name",
        required: true,
      },
      quantivateRiskCategory: {
        label: "Quantivate Risk Category",
        tooltip: "The corresponding Risk Category to fill out the 'Gap Analysis by Risk Category' table in Quantivate",
      },
      enableQuantivatePush: {
        label: "Include in Quantivate's Gap Analysis Table",
        inputType: "switch",
        defaultValue: false,
      },
      vendorReviewID: {
        label: "Vendor Review ID",
        defaultValue: "placeholderID",
      },
      isTemplate: {
        defaultValue: isTemplate,
        isHidden: true,
      },
    },
  });

  return {
    ...formHook,
  };
};
