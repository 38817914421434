import { ErrorLogger, InfoLogger } from "@utils/EventLogger";

import { GetQuery } from "../../../utils/Functions/Graphql/GetQuery";
import { convertCamelCaseToSentence } from "@rivial-security/func-utils";

const duplicationStatus = {
  SUCCESS: "success",
  PARTIAL: "partial",
  FAILED: "failed",
};

/**
 * Apply a template (query full data and duplicate into the organization) from the database using a lambda
 * @param {string} input.organizationID - the organization identifier to which to copy the template
 * @param {object[]} input.templatesToApply - the template identifiers to apply, with any overrides (one by one!)
 * @param {string} input.resource - the resource type of the template (so the lambda picks the correct query and duplication strategies)
 * @param {string} resourceName - override for the typename displayed in the toasts and logs
 * @param {function} addToast - UIContext addToast function
 * @param {function} updateToast - UIContext updateToast function
 * @return {Promise<object[]>} a list of items that were created
 */
export const applyTemplates = async ({ input, resourceName, addToast, updateToast }) => {
  //Verify there are templates to duplicate
  if (!input?.templatesToApply || !Array.isArray(input.templatesToApply) || input.templatesToApply.length === 0) {
    return;
  }

  //Generating the duplication target name
  let typename = "";
  if (resourceName || input?.resource) {
    typename = `${convertCamelCaseToSentence(resourceName || input?.resource)} `;
  }
  const templateWord = `Template${input.templatesToApply.length === 1 ? "" : "s"}`;
  const templateSuffixTypename = `${typename || ""}${templateWord}`;

  //Only handle toasts if duplication progress is not going to be tracked globally
  let toastID;
  if (input?.reportProgress !== true) {
    toastID =
      addToast &&
      addToast({
        header: `Duplicating the selected ${templateSuffixTypename}...`,
        icon: "spinner",
        color: "success",
      });
  }

  try {
    //Call the duplication lambda
    const applyTemplatesLambdaGraphql = `
      query applyTemplates($input: AWSJSON) {
        applyTemplates(input: $input)
      }
    `;
    InfoLogger(`Sending request to applyTemplates lambda. Input: ${JSON.stringify(input)}`);
    const resultString = await GetQuery({
      query: applyTemplatesLambdaGraphql,
      variables: {
        input: JSON.stringify(input),
      },
    });

    const result = JSON.parse(resultString);

    //Process results from the lambda
    if (result?.status === duplicationStatus.SUCCESS) {
      if (toastID && updateToast) {
        updateToast({
          id: toastID,
          header: `Finished duplicating the ${templateSuffixTypename} successfully`,
          icon: "success",
        });
      }
      return result?.createdItems || [];
    } else if (result.status === duplicationStatus.PARTIAL) {
      if (toastID && updateToast) {
        updateToast({
          id: toastID,
          header: `Partially duplicated the ${templateSuffixTypename}`,
          icon: "warning",
        });
      }
    } else {
      throw Error(
        `Status code returned from lambda indicates ${templateSuffixTypename} duplication has failed.${JSON.stringify(
          result,
        )}`,
      );
    }
  } catch (e) {
    ErrorLogger(e);

    if (toastID && updateToast) {
      updateToast({
        id: toastID,
        header: `Failed to duplicate the ${templateSuffixTypename}`,
        icon: "danger",
      });
    }
  }

  return [];
};
