import React, { useEffect, useState } from "react";
import { useStateSync } from "../../../functional/useStateSync";
import { Grid } from "../components/Grid";
import { objectListSimilarByField } from "../../../../utils/Functions/objectListSimilarByField";
import { useGetGridPersistenceData } from "./useGetGridPersistenceData";

/**
 * @description Wrapper hook for the Grid component
 * @param {boolean} allowFiltering - if allowFiltering set to true the filter bar will be displayed. If set to false
 * the filter bar will not be displayed. Filter bar allows the user to filter grid records with required criteria.
 * @param {boolean} allowGrouping - if allowGrouping set to true, then it will allow the user to dynamically group or
 * ungroup columns. Grouping can be done by drag and drop columns from column header to group drop area.
 * @param {boolean} allowPaging - If allowPaging is set to true, the pager renders at the footer of the Grid. It is used to handle page navigation in the Grid.
 * @param {number} columnSize - details panel size
 * @param {object} config - grid configuration object
 * @param {object} contextMenuConfig - configurations for the context menu
 * @param {object[]} contextMenuConfig.items - extra items to add to the context menu
 * @param {string} contextMenuConfig.items[].text - text for context menu button
 * @param {string} contextMenuConfig.items[].iconCss - icon css for context menu button
 * @param {function} contextMenuConfig.items[].onClick - onClick handler for context menu button
 * @param {string} createItemModalHeader - create modal header text
 * @param {JSX} createResourceComponent - create item component
 * @param {string} createResourceComponentWidth - width of the create modal
 * @param {object[]} initData - array of objects, data for the grid
 * @param {string} deleteButtonText - alternate text to display instead of 'Delete'
 * @param {string} deleteButtonIcon - alternate delete icon
 * @param {function} deleteFunction - function for deleting an item
 * @param {string} deleteGqlQuery - graphql query for deleting an item. NOTE: should contain "__typename" field
 * @param {string} deleteMessage - message to display when deleting an item
 * @param {string} deleteMutation - graphql mutation for deleting an item.
 * @param {JSX} detailsComponent - jsx component for displaying an item
 * @param {string} detailsTitle - title of the details component
 * @param {DETAILS_TYPES} detailsType - details component display style
 * @param {boolean} disableRoleChecking - disable role checking for the grid
 * @param duplicationSettings
 * @param {boolean} enableCheckBoxes - display checkboxes for each row in the grid
 * @param {boolean} enableColumnChooser - display column chooser for the grid
 * @param {boolean} enableContextMenu - enable context menu for the grid
 * @param {boolean} enableMenu - display menu in the grid toolbar
 * @param {boolean} enableNoteIcon - enables note icon in the grid
 * @param {boolean} enableOptions - enables a column with dropdown with options
 * @param {boolean} enablePersistence - enable persistence functionality for the grid
 * @param {boolean} enablePrint - enable print functionality for the grid
 * @param {boolean} enableQuickDetails - display "info" icon as a first row of the grid, on click opens a modal with item details
 * @param disableQuickDetails
 * @param {boolean} enableSearch - display search bar for the grid
 * @param {boolean} enableSelectButtons - if TRUE will display a column with select buttons by each row
 * @param {function} selectButtonCallback - called if any button shown with `enableSelectButtons` is true (passes selected item)
 * @param {function} actionComplete - called on every grid action can be used to modify the look of filter menus
 * @param {object[]} initFields - mapping configuration for a field in the grid with passed data
 * @param {object} filterSettings - configures the filtering behavior of the Grid.
 * @param {string} gridHeight - change grid height
 * @param {object} groupSettings - initial object which configures the group behavior of the grid
 * @param {string} helpCenterUrl - for navigating to a related page in the Help Center
 * @param {JSX[]} headerButtons - array of JSX buttons for displaying on top of the grid
 * @param {boolean} isLoading - indicator for loading data from database
 * @param {object[]} menuItems - array of object for the menu of the grid
 * @param {string} module - module name of the grid data type
 * @param {string} noteIconColumnName - specify the noteIconColumnName for the grid
 * @param {JSX} noteIconComponent - JSX component for notes
 * @param {function} onToolbarClick - function triggers on tool bar click
 * @param {string} organizationID - selected organization ID
 * @param {object} pageSettings - configures the paging behavior of the grid.
 * @param {string} persistenceUUID - unique grid identifier in UUID format
 * @param {object} customPersistenceData - custom data to be passed to the persistence settings that are saved
 * @param {function} resetFunction - re-fetch data from the database
 * @param {function} getNewItem - get created item
 * @param {string} resource - resource name of the grid data type
 * @param {string} route - route for the grid data type
 * @param {object} selectionSettings - configures the selection behavior of the grid
 * @param {function} setIsLoading - sets the value of isLoading variable
 * @param {object} sortSettings - object with settings for sorting grid data
 * @param {string} title - specify title for the grid
 * @param {object[]} toolbarOptions - array of objects for the grid toolbar
 * @param {string} typename - specify the type name of the grid data
 * @param {function} updateItemById - an external updateItemById function that allows to parent components to hook into
 * the grid's updateItemById function
 * @param updateMutation
 * @param {boolean} allowKeyboard - allow keyboard navigation for a grid
 * @param {string} accessControlFieldName - field name to display for password protected items
 * @param error
 * @param setError
 * @return {object} {{ref: string, setSelectedItems: (value: (((prevState: *[]) => *[]) | *[])) => void, setLastSelectedItem: (value: (((prevState: string) => string) | string)) => void, data: *, lastSelectedItem: string, setData: *, display: JSX.Element, setFields: *, fields: *, selectedItems: *[]}}
 */
export const useGrid = ({
  allowFiltering,
  allowGrouping,
  allowPaging = true,
  columnSize,
  config,
  contextMenuConfig,
  createItemModalHeader,
  createResourceComponent,
  createResourceComponentWidth,
  data: initData = [],
  deleteButtonText,
  deleteButtonIcon,
  deleteFunction,
  primaryField = "name",
  deleteGqlQuery,
  deleteMessage,
  deleteMutation,
  detailsComponent,
  detailsTitle,
  detailsType,
  disableRoleChecking,
  duplicationSettings = {},
  enableCheckBoxes,
  enableColumnChooser,
  enableContextMenu,
  enableMenu,
  enableNoteIcon,
  enableOptions,
  enablePersistence,
  enablePrint,
  enableQuickDetails,
  disableQuickDetails,
  enableSearch,
  enableSelectButtons,
  selectButtonCallback,
  actionComplete,
  fields: initFields = [],
  filterSettings,
  gridHeight,
  groupSettings,
  headerButtons,
  helpCenterUrl,
  isLoading,
  menuItems,
  module,
  noteIconColumnName,
  noteIconComponent,
  onToolbarClick,
  organizationID,
  pageSettings,
  persistenceUUID,
  customPersistenceData,
  resetFunction,
  getNewItem,
  resource,
  route,
  selectionSettings,
  setIsLoading,
  sortSettings,
  title,
  toolbarOptions,
  typename,
  updateItemById,
  updateMutation,
  allowKeyboard,
  accessControlFieldName,
  error,
  setError,
}) => {
  /**
   * Grid component reference object
   */
  const [ref, setRef] = useState("");

  /**
   * Fields for the grid
   */
  const [fields, setFields] = useState(initFields);
  //Prevents infinite loop with custom dynamic fields
  // NOTE: any fields added at this stage need to be added to the disregard list for example (quick details, options, notesIcon...)
  useEffect(() => {
    if (
      !objectListSimilarByField({
        listOne: fields,
        listTwo: initFields,
        disregardList: ["ℹ", noteIconColumnName || "", "options", "select"],
      })
    ) {
      setFields(initFields);
    }
  }, [initFields]);

  /**
   * Data for the grid
   */
  const [data, setData] = useStateSync(initData);

  /**
   * Selected item from the grid
   */
  const [lastSelectedItem, setLastSelectedItem] = useState("");

  /**
   * List of selected items
   */
  const [selectedItems, setSelectedItems] = useState([]);

  /**
   * Allows to check passed in items in the grid
   */
  const [itemsToCheck, setItemsToCheck] = useState(null);

  /**
   * Exposes the grid's persistence data to outside components
   */
  const { persistenceData } = useGetGridPersistenceData({ persistenceUUID });

  /**
   * Forces the broken footer to be hidden
   */
  allowPaging = false;

  const display = (
    <Grid
      allowFiltering={allowFiltering}
      allowGrouping={allowGrouping}
      allowPaging={allowPaging}
      columnSize={columnSize}
      config={config}
      contextMenuConfig={contextMenuConfig}
      createItemModalHeader={createItemModalHeader}
      createResourceComponent={createResourceComponent}
      createResourceComponentWidth={createResourceComponentWidth}
      data={data}
      deleteButtonText={deleteButtonText}
      deleteButtonIcon={deleteButtonIcon}
      deleteFunction={deleteFunction}
      deleteGqlQuery={deleteGqlQuery}
      deleteMessage={deleteMessage}
      deleteMutation={deleteMutation}
      detailsComponent={detailsComponent}
      detailsTitle={detailsTitle}
      detailsType={detailsType}
      disableRoleChecking={disableRoleChecking}
      duplicationSettings={duplicationSettings}
      enableCheckBoxes={enableCheckBoxes}
      helpCenterUrl={helpCenterUrl}
      itemsToCheck={itemsToCheck}
      setItemsToCheck={setItemsToCheck}
      enableColumnChooser={enableColumnChooser}
      enableContextMenu={enableContextMenu}
      enableMenu={enableMenu}
      enableNoteIcon={enableNoteIcon}
      enableOptions={enableOptions}
      enablePersistence={enablePersistence}
      enablePrint={enablePrint}
      enableQuickDetails={enableQuickDetails}
      disableQuickDetails={disableQuickDetails}
      enableSearch={enableSearch}
      enableSelectButtons={enableSelectButtons}
      selectButtonCallback={selectButtonCallback}
      actionComplete={actionComplete}
      fields={fields}
      filterSettings={filterSettings}
      gridHeight={gridHeight}
      groupSettings={groupSettings}
      headerButtons={headerButtons}
      isLoading={isLoading}
      lastSelectedItem={lastSelectedItem}
      menuItems={menuItems}
      module={module}
      noteIconColumnName={noteIconColumnName}
      noteIconComponent={noteIconComponent}
      onToolbarClick={onToolbarClick}
      organizationID={organizationID}
      pageSettings={pageSettings}
      persistenceUUID={persistenceUUID}
      customPersistenceData={customPersistenceData}
      resetFunction={resetFunction}
      getNewItem={getNewItem}
      resource={resource}
      route={route}
      selectedItems={selectedItems}
      setIsLoading={setIsLoading}
      setSelectedItems={setSelectedItems}
      setLastSelectedItem={setLastSelectedItem}
      setRef={setRef}
      selectionSettings={selectionSettings}
      sortSettings={sortSettings}
      title={title}
      toolbarOptions={toolbarOptions}
      typename={typename}
      updateItemById={updateItemById}
      updateMutation={updateMutation}
      allowKeyboard={allowKeyboard}
      accessControlFieldName={accessControlFieldName}
      error={error}
      setError={setError}
      primaryField={primaryField}
    />
  );

  return {
    fields,
    data,
    setData,
    display,
    ref,
    selectedItems,
    setSelectedItems,
    lastSelectedItem,
    setLastSelectedItem,
    itemsToCheck,
    setItemsToCheck,
    createResourceComponent,
    persistenceData,
  };
};
