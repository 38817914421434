import { Card, CardBody, Col, Row } from "reactstrap";
import NumberChange from "../../../../../../utils/CustomFields/NumberChange";
import React from "react";

/**
 * Displays the Total Changes in Residual Loss and Return on Investment for a Risk Recommendation
 * @param overallResidualChange
 * @param overallROIChange
 * @returns {JSX.Element}
 * @constructor
 */
const DisplayMonteCarloTotals = ({ overallResidualChange, overallROIChange }) => {
  return (
    <Card>
      <CardBody>
        <Row>
          <Col lg={6}>
            Total Change in Residual Risk:{" "}
            <NumberChange value={overallResidualChange} format="dollar" positiveNumber="red" negativeNumber="green" />
          </Col>
          <Col lg={6}>
            Total Return on Investment: <NumberChange value={overallROIChange} format="percent" />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default DisplayMonteCarloTotals;
