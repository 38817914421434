import React, { useEffect, useState } from "react";

import DatePicker from "react-datepicker";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import FilterAndClearButtonGroup from "./components/FilterAndClearButtonGroup";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useUUID } from "../../hooks/functional/useUUID";

/**
 * @description This allows to apply a custom filter onto a grid column that has date values
 * @param {object} props - the props passed to the component
 * @returns {JSX.Element}
 * @constructor
 */
const DateFilterMenu = ({ props }) => {
  const dropdownOptions = [
    {
      text: "Past below date",
      value: true,
    },
    {
      text: "Before below date",
      value: false,
    },
  ];

  const [uuid] = useUUID();
  const [isAfter, setIsAfter] = useState(true);
  const [date, setDate] = useState();

  //Set the active filter at the beginning
  useEffect(() => {
    if (props?.column?.name) {
      const columnName = props?.column?.name;
      const activeFilters = props?.column?.parent?.filterModule?.actualPredicate;
      if (activeFilters?.hasOwnProperty(columnName)) {
        const activeColumnFilters = activeFilters[columnName];
        for (const filter of activeColumnFilters) {
          if (filter?.operator === "greaterthanorequal") {
            setIsAfter(true);
            setDate(filter?.value);
          } else if (filter?.operator === "lessthanorequal") {
            setIsAfter(false);
            setDate(filter?.value);
          }
        }
      }
    }
  }, []);

  //Re-filter the data when one of the parameters have changes
  useEffect(() => {
    if (props?.column?.name && date) {
      const columnName = props?.column?.name;
      props?.column?.parent?.filterByColumn(
        columnName,
        isAfter ? "greaterthanorequal" : "lessthanorequal",
        date,
        "and",
      );
    }
  }, [isAfter, date]);

  //On pressing the "Clear" button remove all filters for the column and reset range picker
  const clearFilter = () => {
    if (props?.column?.name && date) {
      const columnName = props?.column?.name;
      setDate(undefined);
      props?.column?.parent?.clearFiltering([columnName]);
    }
  };

  //The filter menu UI
  return (
    <div>
      <DropDownListComponent
        // identity
        id={`filter-menu-dropdown-${uuid}`}
        // style
        popupHeight="250px"
        // choices
        index={isAfter ? 0 : 1}
        dataSource={dropdownOptions}
        // filtering
        allowFiltering={false}
        // events
        change={({ itemData }) => {
          setIsAfter(itemData?.value);
        }}
      />
      <DatePicker
        id={`filter-menu-date-picker-${uuid}`}
        dateFormat="MMMM d, yyyy h:mm aa"
        placeholderText="Select a Date and Time"
        showTimeInput
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        selected={date}
        onChange={(date) => setDate(date)}
      />
      <FilterAndClearButtonGroup onClickClear={clearFilter} clearDisabled={isNullOrUndefined(date)} />
    </div>
  );
};

export default DateFilterMenu;
