import React, { useEffect } from "react";
import { useTable } from "../../../../../../../hooks/views/useTable";
import NumberChange from "../../../../../../../utils/CustomFields/NumberChange";
import SystemDetails from "../../../../../Systems/components/SystemDetails";
import GreatestRiskKRIChange from "./components/GreatestRiskKRIChange";
import GreatestRiskKRIChangeTooltip from "./components/GreatestRiskKRIChangeTooltip";

/**
 * Displays a table of systems and heir change in residual risk, when available
 * also displays the greatest risk KRI change information
 * @param {object} systemChanges - all individual system changes for a single risk change
 * @return {JSX.Element}
 */
const SystemRiskChanges = ({ systemChanges }) => {
  const customFields = [
    {
      field: "residualChange",
      component: <NumberChange format="dollar" positiveNumber="red" negativeNumber="green" />,
      propName: "value",
    },
    {
      field: "systemRiskValues",
      propName: "systemRiskValues",
      component: <GreatestRiskKRIChange />,
      tooltip: <GreatestRiskKRIChangeTooltip />,
    },
  ];

  //Check if can show the change KRI change
  let includeHighestRiskKRI = true;
  if (Array.isArray(systemChanges) && systemChanges.length > 0) {
    for (const systemChange of systemChanges) {
      if (!systemChange.systemRiskValues) {
        includeHighestRiskKRI = false;
        break;
      }
    }
  } else {
    includeHighestRiskKRI = false;
  }

  const tableHook = useTable({
    data: systemChanges,
    fields: ["name", "residualChange", ...(includeHighestRiskKRI ? ["systemRiskValues"] : [])],
    fieldNameDictionary: {
      residualChange: "Change in Residual Risk",
      systemRiskValues: "Change in Highest Risk KRI",
    },
    customFields,
    disableRoleChecking: true,
    detailsComponent: <SystemDetails />,
  });

  useEffect(() => {
    if (Array.isArray(systemChanges)) {
      tableHook.setData(systemChanges);
    }
  }, [JSON.stringify(systemChanges)]);

  return tableHook.display;
};

export default SystemRiskChanges;
