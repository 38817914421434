import { useCustomQueryRunner } from "../../../../CustomQueries/hooks/useCustomQueryRunner/useCustomQueryRunner";
import { QUERY_RUN_TYPE } from "../../../../CustomQueries/hooks/useCustomQueryRunner/hooks/useCustomQueryRunnerExecutionLogic/useCustomQueryRunnerExecutionLogic";
import { applyWidgetFieldTransforms } from "@rivial-security/widget-utils";
import { getGridFieldsToMerge } from "./functions/getGridFieldsToMerge";
import { useEffect } from "react";
import { useSetEditedSeriesQueryForWidgetTable } from "./hooks/useSetEditedSeriesQueryForWidgetTable";

/**
 * Hook responsible for retrieving custom query information for a single widget
 * @param {object} widget - widget information including its configuration
 * @param {string} organizationID - the currently selected organization
 * @param {string} title - title for the display of the widget
 * @param {string} persistenceUUID - persistence UUID for table widgets
 * @param {boolean} autoRender - if TRUE will retrieve widget data automatically at start
 * @returns {{display: JSX.Element}}
 */
export const useCustomQueryWidgetRunner = ({ widget, organizationID, title, persistenceUUID, autoRender }) => {
  const { editedSeries, queryConfig } = useSetEditedSeriesQueryForWidgetTable({
    widget,
    dependencies: [widget?.config],
  });

  const runner = useCustomQueryRunner({
    query: queryConfig,
    organizationID,
    runType: QUERY_RUN_TYPE.LOCAL,
    disableTopLevelFunctions: true,
    normalizeData: (items) => {
      return applyWidgetFieldTransforms({
        data: items,
        seriesItem: editedSeries,
      });
    },
    gridFieldsToMerge: getGridFieldsToMerge({ editedSeries }),
    enableQuickDetails: editedSeries?.tableSettings?.showLinkToResource,
    detailsLink: editedSeries?.tableSettings?.detailsRoutePrefix,
    title,
    persistenceUUID,
    autoRender,
  });

  useEffect(() => {
    const changeTargetView = runner?.grid?.changeTargetView;
    const viewSettings = editedSeries?.viewSettings;
    if (typeof changeTargetView === "function" && typeof viewSettings === "object") {
      changeTargetView(viewSettings);
    }
  }, [editedSeries]);

  return runner;
};
