import { ErrorLogger } from "@utils/EventLogger";
import { getComplianceActivityData } from "./getComplianceActivityData";
import { getDocumentsByControlSets } from "./getDocumentsByControlSets";

/**
 * Retrieves a nested array of Compliance Control Documents for an organization
 *
 * ControlSet -> Control -> Evidence -> Documents
 * @param {string} organizationID - selected organization
 * @param {string} controlSetId - selected control set
 * @param {boolean} includeDisabledControlsInExports - include disabled controls in exports
 * @param {string} rootName - Name of the root folder
 * @param {object} dateRange - Date range for the data filter
 * @param {Date} dateRange.startDate - Start date for the data filter
 * @param {Date} dateRange.endDate - End date for the data filter
 * @returns {Promise<{children: *, name: string, id: string, type: string}>}
 */
export const documentsByControl = async ({
  organizationID,
  controlSetId,
  includeDisabledControlsInExports,
  rootName,
  dateRange,
}) => {
  /**
   * Get all control sets
   */
  const controlSets = await getDocumentsByControlSets({
    organizationID,
    controlSetId,
  });

  if (!Array.isArray(controlSets)) {
    ErrorLogger("[documentsByControl.js] - controlSets is not an array");
    return;
  }

  const controlsFlatten = [];

  if (Array.isArray(controlSets)) {
    for (const controlSet of controlSets) {
      const controlItems = controlSet?.controls?.items;

      if (Array.isArray(controlItems)) {
        for (const control of controlItems) {
          /**
           * Ignore disabled controls
           */
          if (includeDisabledControlsInExports !== true && control?.isDisabled === true) {
            continue;
          }

          controlsFlatten.push({
            ...control,
            name: control?.statementNumber ? control?.statementNumber : control?.name,
            activity: {
              items: control?.evidences?.items?.reduce((acc, link) => acc.concat(link?.evidence?.activity?.items), []),
            },
          });
        }
      }
    }
  }

  return getComplianceActivityData({
    items: controlsFlatten,
    rootName,
    dateRange,
  });
};
