import { Button } from "@mui/material";
import { ReactElement } from "react";
import { WarningLogger } from "@utils/EventLogger";
import { handleGetIcon } from "../../../Functions/Icon/getIcon";
import { useGUID } from "../../../../hooks/functional/useGUID";
import { useSelectionTileStyles } from "../hooks/useSelectionTileStyles";

export interface SelectionTileConfig {
  id: string;
  text: string;
  icon?: string;
  ribbon?: string;
  module?: string;
  resource?: string;
}

export interface SelectionTileProps {
  onSelect?: (item: SelectionTileConfig) => Promise<void> | void;
  item: SelectionTileConfig;
  selected?: boolean;
}

/**
 * Single Selection Tile
 * @param onSelect - function to call when tile is selected
 * @param item - the SelectionTile configuration for a single tile
 * @param item.icon - the icon to display
 * @param item.text - the text to display
 * @param item.ribbon - the optional ribbon to display
 * @param selected - whether the tile is selected or not (changes styling)
 * @returns {JSX.Element}
 */
const SelectionTile = ({ onSelect, item, selected = false }: SelectionTileProps): ReactElement => {
  const classes = useSelectionTileStyles();

  const [guid] = useGUID();

  const onClick = async (): Promise<void> => {
    if (typeof onSelect === "function") {
      await onSelect(item);
    } else {
      WarningLogger("No onSelect function provided to SelectionTile");
    }
  };

  return (
    <Button
      id={guid}
      className="e-card ribbon-parent"
      onClick={onClick}
      classes={{
        root: `${classes?.button} ${selected === true ? classes?.selectedButton : ""}`,
        // @ts-expect-error - label is not typed
        label: classes?.label,
      }}
      data-tourid={`selection-tile-${item?.text}`}
      sx={{
        color: selected === true ? "white" : undefined,
      }}
    >
      {item?.ribbon && (
        <span className="ribbon" style={{ fontSize: "0.8em" }}>
          {item?.ribbon}
        </span>
      )}
      <span className={classes?.icon}>{handleGetIcon(item?.icon)}</span>
      {item?.text}
    </Button>
  );
};

export default SelectionTile;
