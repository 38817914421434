import { useForm } from "../../../../hooks/views/useForm/useForm";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

export const useCreateMetric = ({
  organizationID,
  fieldConfig,
  updateInputFunction,
  module = modules.METRICS,
  resource = resources.METRIC,
  toggleModal,
  resetFunction,
  ...props
}) => {
  const typename = "Metric";
  const fields = ["name", "description"];

  fieldConfig = {
    name: {
      inputType: "string",
      label: "Name",
    },
    description: {
      label: "Description",
    },
    ...fieldConfig,
  };

  const formHook = useForm({
    typename: "Metric",
    organizationID,
    mutation: generateGraphql(typename, fields).createMutation,
    module,
    resource,
    fieldConfig,
    updateInputFunction,
    toggleModal,
    resetFunction,
    ...props,
  });

  return {
    ...formHook,
  };
};
