import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { getResourceAttribute } from "./getResourceAttribute";

export const getResourceDisplayName = ({ typename }) => {
  return (
    getResourceAttribute({
      typename,
      attribute: "displayName",
    }) ||
    convertCamelCaseToSentence(typename) ||
    "Item"
  );
};
