import { generateGraphql } from "@rivial-security/generategraphql";
import { useDetailsCard } from "../../../hooks/views/useDetailsCardV2";

/**
 * @description Get Report hook
 * @param {string} itemId - report id
 * @param {string} module - Reports module
 * @param {string} resource - Report resource
 * @param {object} props
 * @returns {{isLoading: boolean, setInputId: (function(((function(*): *)|*)): void), inputId: *, item: null, tableDisplay: *, display: *, resetIndex: number, reset: (function(): void), setItem: (function(((function(null): null)|null)): void)}}
 */
export const useGetReport = ({ itemId, module, resource, ...props }) => {
  const { getQuery } = generateGraphql(
    "Report",
    ["name", "status", "description", "type", "module", "documents", "createdAt", "updatedAt"],
    {
      documents: `(limit: 100) { items { id name createdAt version ownerGroup file { key bucket } fileWordDoc { key bucket } } }`,
    },
  );

  const queryConfig = {
    query: getQuery,
    itemId,
  };

  return useDetailsCard({
    typename: "Report",
    queryConfig,
    module,
    resource,
    ...props,
  });
};
