import { Button } from "@mui/material";
import ArrowForward from "@mui/icons-material/ArrowForward";
import CloudUpload from "@mui/icons-material/CloudUpload";
import React from "react";
import { handleImport } from "../functions/handleImport";
import { ImporterStep } from "../hooks/useCsvImporter";

const ContinueButton = ({
  startSteps,
  workflow,
  input,
  getData,
  pleaseWaitModal,
  isAsync,
  processRow,
  preProcessRow,
  columnMap,
  organizationID,
  fields,
  nestedFields,
  setResults,
  connectionIDField,
  connectionID,
  typename,
}) => {
  // If 'Continue' button is enabled, for the 'Select CSV' screen
  const isContinueButtonEnabled = () => {
    return (
      workflow?.progressTracker?.currentStep?.id === ImporterStep.CHOOSE_CSV ||
      startSteps?.some((step) => step.id === workflow?.progressTracker?.currentStep?.id)
    );
  };

  // If the 'Import' button is enabled, only if there is a proper input
  const isImportButtonEnabled = () => {
    return Array.isArray(input) && input.length > 0;
  };

  // If the import job is complete

  const canComplete = () => {
    return workflow?.progressTracker?.steps.find((step) => step.id === ImporterStep.FINISH_IMPORT);
  };

  const isComplete = () => {
    return workflow?.progressTracker?.currentStep?.id === ImporterStep.FINISH_IMPORT;
  };

  // Button click that loads the selected CSV file into the input state
  const onClickContinue = async () => {
    const currentStep = workflow?.progressTracker?.currentStep;
    const currentIndex = workflow?.progressTracker.steps.findIndex((step) => step.id === currentStep?.id);
    const nextStep = workflow.progressTracker.steps[currentIndex + 1];
    if (currentStep?.id === ImporterStep.CHOOSE_CSV) {
      const input = await getData();
      if (Array.isArray(input) && input.length > 0) {
        workflow.progressTracker.setCurrentStep(nextStep);
      } else {
        alert("Please select a File that has headers and at least one row of data");
      }
    } else {
      workflow.progressTracker.setCurrentStep(nextStep);
    }
  };

  // Button click for processing the import
  const onImportClick = () => {
    handleImport({
      pleaseWaitModal,
      isAsync,
      processRow,
      preProcessRow,
      columnMap,
      organizationID,
      fields,
      nestedFields,
      workflow,
      input,
      setResults,
      connectionIDField,
      connectionID,
      typename,
    });
  };

  return (
    <span>
      {isContinueButtonEnabled() ? (
        <Button
          title={"Continue to next import step"}
          variant="contained"
          endIcon={<ArrowForward />}
          onClick={onClickContinue}
        >
          Continue
        </Button>
      ) : (
        canComplete() &&
        !isComplete() && (
          <Button
            title={"Import the selected CSV file(s)"}
            variant="contained"
            startIcon={<CloudUpload />}
            disabled={!isImportButtonEnabled()}
            onClick={onImportClick}
          >
            Import
          </Button>
        )
      )}
    </span>
  );
};

export default ContinueButton;
