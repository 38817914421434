import { NavigationModule, NavigationSectionName } from "@application/Navigation/types";
import { modules } from "role-utils";
import { IconName } from "@components/Ions/icons/components/Icons";
import React from "react";

const RealTimeRiskDashboard = React.lazy(() => import("@views/Risk/Dashboard/RiskDashboard"));
const RealTimeRiskSystemDetails = React.lazy(() => import("@views/Risk/Systems/components/SystemDetails"));
const RealTimeRiskRecommendations = React.lazy(
  () => import("@views/Risk/Recommendations/components/RecommendationsDataGrid"),
);
const RealTimeRiskRecommendationsDetails = React.lazy(
  () => import("@views/Risk/Recommendations/components/RecommendationDetails"),
);
const RealTimeRiskChanges = React.lazy(() => import("@views/Risk/RiskChanges/components/GroupedRiskChangeDataGrid"));
const RealTimeCreateRiskChange = React.lazy(
  () => import("@views/Risk/RiskChanges/components/CreateRiskChangeLandingPage"),
);
const RealTimeRiskChangeDetails = React.lazy(() => import("@views/Risk/RiskChanges/components/RiskChangeDetails"));
const RealTimeRiskAssessments = React.lazy(() => import("@views/Risk/RiskAssessment/components/RiskAssessmentList"));
const RealTimeRiskAssessmentDetails = React.lazy(() => import("@views/Risk/RiskAssessment/components/RiskAssessment"));
const RealTimeRiskAssessmentReport = React.lazy(
  () => import("@views/Reports/RiskAssessmentReport/components/RiskAssessmentReport"),
);

// Threats
const ThreatGrid = React.lazy(() => import("@views/Response/Threats/components/ThreatDataGrid"));
const ThreatDetails = React.lazy(() => import("@views/Response/Threats/components/ThreatDetails"));

// Risk Config
const RiskConfig = React.lazy(() => import("@views/Risk/RiskConfig/RiskConfig"));
const RiskConfigAssets = React.lazy(() => import("@views/Risk/RiskConfig/Assets/RiskConfigAssets"));
const RiskConfigThreats = React.lazy(() => import("@views/Risk/RiskConfig/Threats/components/ListThreats"));
const RiskConfigRisks = React.lazy(
  () => import("@views/Risk/RiskConfig/KeyRiskIndicators/components/ListKeyRiskIndicators"),
);
const RiskConfigDetails = React.lazy(
  () => import("@views/Risk/RiskConfig/KeyRiskIndicators/components/KeyRiskIndicatorDetails"),
);
const RiskConfigControlCategories = React.lazy(
  () => import("@views/Risk/RiskConfig/ControlCategories/components/ListControlCategories"),
);
const RiskConfigControlCategoryDetails = React.lazy(
  () => import("@views/Risk/RiskConfig/ControlCategories/components/ControlCategoryDetails"),
);
const RiskControlGrid = React.lazy(
  () => import("@views/Risk/RiskConfig/ControlCategories/components/SubControls/components/RiskControlGrid"),
);
const RiskControlDetails = React.lazy(
  () => import("@views/Risk/RiskConfig/ControlCategories/components/SubControls/components/RiskControlDetails"),
);
const RiskControlCategoryGrid = React.lazy(
  () => import("@views/Risk/RiskConfig/ControlCategories/components/ControlCategoriesDataGrid"),
);
const RiskControlCategoryDetails = React.lazy(
  () => import("@views/Risk/RiskConfig/ControlCategories/components/ControlCategoryDetails"),
);

// SystemDashboard
const SystemDashboard = React.lazy(() => import("@views/Risk/Systems/components/SystemDashboard"));

export const riskNavigation: NavigationModule = {
  moduleName: modules.RISK,
  sectionName: NavigationSectionName.SERVICES,
  name: "Risk",
  path: "/risk",
  defaultRoutePath: "/dashboard",
  icon: IconName.RISK,
  routes: [
    {
      path: "/dashboard",
      name: "Real-Time Risk Dashboard",
      component: RealTimeRiskDashboard,
      navigationBar: {
        name: "Dashboard",
      },
    },
    {
      path: "/systems",
      name: "Information Systems",
      component: SystemDashboard,
      navigationBar: {
        name: "Systems",
      },
    },
    {
      path: "/systems/:id",
      name: "System Details",
      component: RealTimeRiskSystemDetails,
    },
    {
      path: "/recommendations",
      name: "Risk Recommendations",
      component: RealTimeRiskRecommendations,
      navigationBar: {
        name: "Recommendations",
      },
    },
    {
      path: "/recommendations/:id",
      name: "Risk Recommendation Details",
      component: RealTimeRiskRecommendationsDetails,
    },
    {
      path: "/changes",
      name: "Risk Changes",
      component: RealTimeRiskChanges,
      navigationBar: {
        name: "Changes",
      },
    },
    {
      path: "/changes/:id",
      name: "Risk Change Details",
      component: RealTimeRiskChangeDetails,
    },
    {
      path: "/create_risk_change",
      name: "Create Risk Change",
      component: RealTimeCreateRiskChange,
      navigationBar: {
        hidden: true,
      },
    },
    {
      path: "/threats",
      name: "Threats",
      component: ThreatGrid,
    },
    {
      path: "/threats/:id",
      name: "Threat Details",
      component: ThreatDetails,
    },
    {
      path: "/control_categories",
      name: "Risk Control Categories",
      component: RiskControlCategoryGrid,
      navigationBar: {
        name: "Controls",
      },
    },
    {
      path: "/control_categories/:id",
      name: "Risk Control Category Details",
      component: RiskControlCategoryDetails,
    },
    {
      path: "/reports/risk_assessment",
      name: "Risk Assessment Report",
      component: RealTimeRiskAssessmentReport,
      navigationBar: {
        name: "Report",
      },
    },

    // Risk Config
    {
      path: "/risk_config",
      name: "Risk Configuration",
      component: RiskConfig,
      navigationBar: {
        name: "Configuration",
      },
    },
    {
      path: "/risk_config/assets",
      name: "Risk Configuration: Assets",
      component: RiskConfigAssets,
      navigationBar: {
        hidden: true,
      },
    },
    {
      path: "/risk_config/threats",
      name: "Risk Configuration: Threats",
      component: RiskConfigThreats,
      navigationBar: {
        hidden: true,
      },
    },
    {
      path: "/risk_config/risks",
      name: "Risk Configuration: Risks",
      component: RiskConfigRisks,
      navigationBar: {
        hidden: true,
      },
    },
    {
      path: "/risk_config/risks/:id",
      name: "Risk Configuration: Risk Details",
      component: RiskConfigDetails,
      navigationBar: {
        hidden: true,
      },
    },
    {
      path: "/risk_config/control_categories",
      name: "Risk Configuration: Control Categories",
      component: RiskConfigControlCategories,
      navigationBar: {
        hidden: true,
      },
    },
    {
      path: "/risk_config/control_categories/:id",
      name: "Risk Configuration: Control Category Details",
      component: RiskConfigControlCategoryDetails,
    },
    {
      path: "/risk_config/risk_controls",
      name: "Risk Configuration: Risk Controls",
      component: RiskControlGrid,
      navigationBar: {
        hidden: true,
      },
    },
    {
      path: "/risk_config/risk_controls/:id",
      name: "Risk Configuration: Risk Control Details",
      component: RiskControlDetails,
      navigationBar: {
        hidden: true,
      },
    },

    {
      path: "/assessments",
      name: "Risk Assessments",
      component: RealTimeRiskAssessments,
      navigationBar: {
        hidden: true,
      },
    },
    {
      path: "/assessments/:id",
      name: "Risk Assessment Details",
      component: RealTimeRiskAssessmentDetails,
    },
  ],
};
