import { resources } from "role-utils";
import { S3_BUCKET_NAME } from "../../../../env-config";
import UploadDocument from "@hooks/views/useDocumentGrid/components/UploadDocument";
import { ReactElement } from "react";
import { useSelectVendorPreQualifiedCertification } from "@views/Vendor/VendorDocuments/hooks/useSelectVendorPreQualifiedCertification";
import { VendorDocument } from "@rivial-security/schema-types";

export interface UploadVendorDocumentProps {
  organizationID: string;
  isCISSPReview: boolean;
  connectionInput?: Record<string, unknown>;
  onSubmit?: (documents: VendorDocument[]) => Promise<void>;
  props?: Record<string, unknown>;
}

export const UploadVendorDocument = ({
  organizationID,
  isCISSPReview,
  connectionInput,
  onSubmit,
  ...props
}: UploadVendorDocumentProps): ReactElement => {
  const filePath = isCISSPReview ? "public/vendor-solution" : "vendor-documents";
  const customBucket = isCISSPReview ? S3_BUCKET_NAME : undefined;

  const selectCertificationHook = useSelectVendorPreQualifiedCertification({
    organizationID,
  });

  return (
    <>
      <div>{selectCertificationHook.display}</div>
      <UploadDocument
        organizationID={organizationID}
        filePath={filePath}
        documentTypename={resources.VENDOR_DOCUMENT}
        connectionInput={{
          ...connectionInput,
          ...(selectCertificationHook.certificationID && {
            vendorDocumentVendorCertificationId: selectCertificationHook.certificationID,
          }),
        }}
        customBucket={customBucket}
        disableAccessControl={true}
        onSubmit={onSubmit}
        {...props}
      />
    </>
  );
};
