import { useGridCard } from "../../../../hooks/views/useGridCard";
import { modules, resources } from "@rivial-security/role-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import KPIStatus from "../customFields/KPIStatus";
import MetricTypes from "../customFields/MetricTypes";
import React from "react";
import CreateKeyPerformanceIndicator from "../components/CreateKeyPerformanceIndicator";
import { deleteKeyPerformanceIndicator } from "../functions/deleteKeyPerformanceIndicator";
import KeyPerformanceIndicatorDetails from "../components/KeyPerformanceIndicatorDetails";
/*
 * @deprecated use useKeyPerformanceIndicatorDataGrid instead
 * */
export const useKeyPerformanceIndicatorGrid = ({ organizationID, gridDisplay }) => {
  //[GENERAL SETTINGS]
  const module = modules.METRICS;
  const resource = resources.KEY_PERFORMANCE_INDICATOR;
  const roleConfig = { module, resource };
  const typename = "KeyPerformanceIndicator";

  //[QUERY CONFIG]
  const queryFields = ["name", "status", "metricTypes"];
  const nestedFields = {
    metricTypes: `{
      items {
        id
        logic { operator data { value format } }
        metricType { id name description format metrics { items { id name date data { value format } } } }
      }
    }`,
  };
  const { updateMutation, listQuery } = generateGraphql(typename, queryFields, nestedFields);
  const queryConfig = {
    query: listQuery,
    organizationID,
    module,
    resource,
  };

  //[GRID CONFIG]
  const fields = [
    {
      name: "name",
    },
    {
      name: "status",
      component: <KPIStatus />,
      width: 100,
    },
    {
      name: "metricTypes",
      component: <MetricTypes />,
    },
  ];
  const gridConfig = {
    fields,
    options: ["details", "delete"],
    createItemModalHeader: <div>Create a new Key Performance Indicator</div>,
    createResourceComponent: <CreateKeyPerformanceIndicator />,
    deleteFunction: deleteKeyPerformanceIndicator,
    updateMutation,
    route: "#/metrics/keyPerformanceIndicators/",
    detailsComponent: <KeyPerformanceIndicatorDetails />,
    detailsTitle: "Key Performance Indicator Details",
    allowFiltering: true,
    enableSearch: true,
    enableContextMenu: true,
    enablePrint: true,
    enableMenu: true,
    persistenceUUID: "f6e255f7-68y1-4f3c-b122-2brcb4sffge6",
    typename,
    gridHeight: "100%",
    gridDisplay,
  };

  //[CARD CONFIG]
  const cardConfig = {
    title: "Key Performance Indicators",
    headerIcon: "icon-chart",
    enableSticky: true,
  };

  const grid = useGridCard({
    queryConfig,
    gridConfig,
    cardConfig,
    roleConfig,
  });

  return { ...grid };
};
