import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import EvidenceNameField from "./EvidenceNameField";
import React, { useEffect, useState } from "react";
import { mutationWithRollback } from "../../../../utils/Functions/Graphql/mutationWithRollback";
import { cloneDeep } from "lodash";

/**
 * Checkbox field for setting the 'Reviewed' field on AuditControlEvidenceLinks
 * @param {boolean} [readOnly=false] - disables the checkbox
 * @param {object} item - auditControlEvidenceLink
 * @param {string} item.id
 * @param {boolean} item.reviewed
 * @param {string} item.linkId
 * @param {string} auditControlItem - the audit control link this evidence link belongs to
 * @param {function} optimisticUpdate - function to update the parent (used for bulk row updates)
 * @param {function} updateItemById - function to update the parent data grid (used for single row updates)
 * @param {object} audit - parent 'audit' object
 * @param {object} audit.controls
 * @param {object[]} audit.controls.items[]
 * @param {object} audit.controls.items[].evidenceLinks
 * @param {object[]} audit.controls.items[].evidenceLinks.items[]
 * @param {string} audit.controls.items[].evidenceLinks.items[].id
 * @param {object} audit.controls.items[].evidenceLinks.items[].evidence
 * @param {string} audit.controls.items[].evidenceLinks.items[].evidence.id
 * @returns {JSX.Element}
 */
const ReviewedCheckbox = ({ item, auditControlItem, optimisticUpdate, updateItemById, audit, readOnly = false }) => {
  const [reviewed, setReviewed] = useState(item.reviewed);

  useEffect(() => {
    setReviewed(item?.reviewed);
  }, [item?.reviewed]);

  const handleCheck = async (e) => {
    //get value
    const oldValue = reviewed;
    const newValue = e.target.checked;
    // If shift key is pressed during click, updates EvidenceLink reviews across ALL controls
    if (e.shiftKey) {
      handleCheckAll(item.id, newValue);
    }
    // Or just update this one
    else {
      if (typeof updateItemById === "function") {
        const oldEvidenceLinks = cloneDeep(auditControlItem?.evidenceLinks?.items || []);
        const newEvidenceLinks = cloneDeep(auditControlItem?.evidenceLinks?.items || []);
        const matchingEvidenceLink = newEvidenceLinks.find((evidenceLink) => evidenceLink.id === item.linkId);
        if (matchingEvidenceLink) {
          matchingEvidenceLink.reviewed = newValue;
        }

        await mutationWithRollback({
          typename: "AuditControlEvidenceLink",
          input: {
            id: item?.linkId,
            reviewed: newValue,
          },
          preMutationOperation: () => {
            setReviewed(newValue);
            updateItemById({
              id: auditControlItem?.id,
              evidenceLinks: {
                items: newEvidenceLinks,
              },
            });
          },
          rollbackOperation: () => {
            setReviewed(oldValue);
            updateItemById({
              id: auditControlItem?.id,
              evidenceLinks: {
                items: oldEvidenceLinks,
              },
            });
          },
        });
      }
    }
  };

  /**
   * Handles checking all Evidences reviewed
   * @param evidenceID
   * @param bool
   * @returns {Promise<void>}
   */
  const handleCheckAll = async (evidenceID, bool) => {
    const auditControls = audit?.controls?.items || [];

    for (const auditControlLink of auditControls) {
      const evidenceLinks = auditControlLink?.evidenceLinks?.items || [];

      evidenceLinks.forEach((evidenceLink) => {
        if (evidenceLink?.evidence.id === evidenceID) {
          optimisticUpdate({
            typename: "AuditControlEvidenceLink",
            field: "reviewed",
            id: evidenceLink.id,
            value: bool,
          });
        }
      });
    }
  };

  const handleNote = async (item) => {
    optimisticUpdate({
      typename: "AuditControlEvidenceLink",
      value: item.notes,
      field: "notes",
      id: item.id,
    });
  };

  return (
    <FormControlLabel
      disabled={readOnly}
      control={
        <Checkbox
          title={"Shift+Click to mark as Reviewed for All Controls"}
          value={reviewed}
          checked={reviewed}
          onClick={handleCheck}
        />
      }
      label={<EvidenceNameField style={{ pointerEvents: "all" }} item={item} handleNote={handleNote} />}
    />
  );
};

export default ReviewedCheckbox;
