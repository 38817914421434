import { useCreateControl } from "../hooks/useCreateControl/useCreateControl";
import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";

/**
 * A component instance of the useCreateControl.js hook
 * @param {object} props
 */
const CreateControl = (props) => {
  return useCreateControl(props).display;
};

export default withOrganizationCheck(CreateControl);
