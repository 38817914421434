import { InvalidParamError, MutationError } from "../../../analytics/CustomError";
import { ItemMutation as PackageItemMutation } from "@rivial-security/appsync-utils";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * @function
 * @description This performs the mutation logic from the NPM package in the same scope as the frontend project.
 * This utilizes our frontend Auth tokens without exposing them to the NPM package.
 * @deprecated it is recommended to use `appsync-utils` `ItemMutation` directly instead of this function on the front end
 * @param {string} mutation - the graphql for the mutation to perform
 * @param {object} input - the input for the mutation, should contain id of the item being mutated if not a creation mutation
 * @returns {Promise<*>}
 */

export const ItemMutation = async (mutation, input) => {
  if (isNullOrUndefined(mutation)) {
    throw new InvalidParamError("mutation", "mutation param cannot be null or undefined");
  }

  if (typeof mutation !== "string") {
    throw new InvalidParamError("mutation", "mutation param must be a string");
  }

  if (isNullOrUndefined(input)) {
    throw new InvalidParamError("input", "input cannot be null or undefined");
  }

  if (typeof input !== "object") {
    throw new InvalidParamError("mutation", "input param must be an object");
  }

  let res;

  try {
    res = PackageItemMutation({
      mutation,
      input,
    });
  } catch (e) {
    throw new MutationError("Item Mutation failed", e);
  }

  return res;
};
