import { ItemMutation } from "../../Functions/Graphql/ItemMutation";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 * Deletes any tag link with provided typename from the database
 * @param {object} tagLink - the tagLink to delete (object containing the tag link id under the 'id' property)
 */
export const deleteTagLink = (tagLink) => {
  const { deleteMutation } = generateGraphql(tagLink?.__typename);
  return ItemMutation(deleteMutation, { id: tagLink?.id });
};
