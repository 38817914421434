import { BlackKiteResponse } from "../components/VendorBlackKiteCompany";
import CircularGaugeChart from "../../../../../utils/GenericComponents/Charts/CircularGaugeChart";

/**
 * @description Black Kite Cyber Rating component
 */
const BlackKiteCyberRating = ({ CyberRating }: BlackKiteResponse): JSX.Element => (
  <CircularGaugeChart value={CyberRating} title={"Cyber Rating"} />
);

export default BlackKiteCyberRating;
