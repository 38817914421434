"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRiskRatings = void 0;
const appsync_utils_1 = require("@rivial-security/appsync-utils");
const _realTimeRiskGQL_1 = require("../graphql/_realTimeRiskGQL");
const getRiskScore_1 = require("./getRiskScore");
const getSystemRiskResults_1 = require("./getSystemRiskResults");
const getLossCurveRating_1 = require("./getLossCurveRating");
const formatLossToleranceCurve_1 = require("../../createMonteCarloSystemInput/functions/formatLossToleranceCurve");
const func_utils_1 = require("@rivial-security/func-utils");
const getGreatestRiskCurves_1 = require("./getGreatestRiskCurves");
/**
 * Performs monte carlo analysis on an array of systems for an organization,
 * then calculates the Risk Ratings for each of those systems
 *
 * @param {Object[]} systems - Required
 * @param {string} organizationID - Required
 * @param {MonteCarloCalculationResult} [monteCarloResults] - Optional
 * @param {object} riskMapping
 * @param {lossToleranceCurve} lossToleranceCurve
 * @param {RatingScale} ratingScale
 * @param env
 * @returns {{
 *   riskScores: {
 *     low: number,
 *     lowMedium: number,
 *     medium: number,
 *     mediumHigh: number,
 *     high: number
 *   },
 *   systemRiskResults: object[]
 * }}
 */
const getRiskRatings = async ({ systems, organizationID, monteCarloResults, riskMapping: riskMappingInput, lossToleranceCurve: lossToleranceCurveInput, ratingScale: ratingScaleInput, }) => {
    if (!systems || !Array.isArray(systems)) {
        throw Error("[getRiskRatings] Invalid systems input. Must be an Array");
    }
    const riskScores = [];
    // Get Risk Mapping for this Org
    const organizationRiskMapping = riskMappingInput ||
        monteCarloResults?.monteCarloSystemInput?.riskConfig?.riskMapping ||
        (await (0, appsync_utils_1.GetQuery)({
            query: _realTimeRiskGQL_1.riskMappingGQL,
            variables: { id: organizationID },
        }).riskMapping);
    // These can be optional parameters to be more efficient
    const lossToleranceCurve = lossToleranceCurveInput ||
        monteCarloResults?.lossToleranceCurve ||
        (await (0, appsync_utils_1.GetQuery)({
            query: _realTimeRiskGQL_1.ratingScaleGQL,
            variables: { id: organizationID },
        }).lossToleranceCurve);
    const ratingScale = ratingScaleInput ||
        monteCarloResults?.monteCarloSystemInput?.ratingScale ||
        (await (0, appsync_utils_1.GetQuery)({
            query: _realTimeRiskGQL_1.ratingScaleGQL,
            variables: { id: organizationID },
        }).ratingScale);
    // Get the Risk Score for each system
    let results = [];
    // Handle the Lambda Results
    if (monteCarloResults && Array.isArray(monteCarloResults)) {
        results = [...monteCarloResults];
    }
    else if (typeof monteCarloResults === "object") {
        results.push(monteCarloResults);
    }
    else {
        throw new Error("[getRiskRatings] Error: Invalid result object from lambda.");
    }
    const systemRiskResults = [];
    let low = 0;
    let lowMedium = 0;
    let medium = 0;
    let mediumHigh = 0;
    let high = 0;
    // Get Risk Scores for each System Result
    for (const systemCalculationResult of results) {
        let system;
        if (systemCalculationResult && monteCarloResults.monteCarloSystemInput) {
            system = systems.find((item) => item.id === monteCarloResults.systemID);
        }
        else {
            (0, func_utils_1.eventLogger)(`[getRiskRatings] Could not find Monte Carlo Calculation System result for: ${monteCarloResults?.systemID}`);
        }
        const riskScore = await (0, getRiskScore_1.getRiskScore)({
            system,
            organizationID,
            monteCarloResults: {
                lossToleranceCurve, // raw tolerance curve, [{amount, probability}]
                ...systemCalculationResult,
            },
            riskMapping: organizationRiskMapping,
            ratingScale,
        });
        // This is System Level, grab these values from KRIs instead
        // Grab Inherent and Residual Risk from the greatest KRI instead of the system aggregate itself
        const residualLossKRI = riskScore?.greatestEnterpriseRisk?.residualRisk;
        const inherentLossKRI = riskScore?.greatestEnterpriseRisk?.inherentRisk;
        // TEMP: Not using these numbers at the moment
        // const residualRisk = systemCalculationResult && systemCalculationResult.systemStats && systemCalculationResult.systemStats.residualRisk;
        // const inherentRisk = systemCalculationResult && systemCalculationResult.systemStats && systemCalculationResult.systemStats.inherentRisk;
        // Get results from the KRIs
        const risks = await (0, getSystemRiskResults_1.getSystemRiskResults)({
            monteCarloResults: {
                lossToleranceCurve, // raw tolerance curve, [{amount, probability}]
                ...systemCalculationResult,
            },
            organizationID,
            ratingScale,
        });
        // Convert raw loss tolerance curve (from database) into the usable format {amount, probability} => {x, y}
        const formattedLossTolerance = (0, formatLossToleranceCurve_1.formatLossToleranceCurve)(lossToleranceCurve);
        // Determine the rating based on loss tolerance curve and residual loss from the KRI
        const rating = (0, getLossCurveRating_1.getLossCurveRating)({
            ratingScale,
            lossToleranceCurve: formattedLossTolerance,
            riskAmount: residualLossKRI,
        });
        // Get greatest KRI curve data
        const greatestRiskCurves = (0, getGreatestRiskCurves_1.getGreatestRiskCurves)({
            riskScore,
            monteCarloResults: systemCalculationResult,
        });
        system &&
            systemRiskResults.push({
                id: system.id,
                name: system.name,
                residualRisk: residualLossKRI,
                inherentRisk: inherentLossKRI,
                risks,
                rating,
                residualLossCurve: systemCalculationResult?.systemStats?.lossExceedanceCurve?.residualLossCurve,
                riskResidualLossCurve: greatestRiskCurves?.residualLossCurve,
            });
        // Add up the risk scores based on System Rating
        if (rating && riskScore) {
            riskScores.push({ ...riskScore, riskRating: rating });
            switch (rating) {
                case "low":
                    low++;
                    break;
                case "lowMedium":
                    lowMedium++;
                    break;
                case "medium":
                    medium++;
                    break;
                case "mediumHigh":
                    mediumHigh++;
                    break;
                case "high":
                    high++;
                    break;
                case "veryHigh":
                    high++;
                    break;
                default:
                    break;
            }
        }
    }
    return {
        lossToleranceCurve,
        systemRiskResults,
        riskScores: {
            scores: [...riskScores],
            low,
            lowMedium,
            medium,
            mediumHigh,
            high,
        },
    };
};
exports.getRiskRatings = getRiskRatings;
