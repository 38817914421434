import React from "react";
import Typography from "@mui/material/Typography";
import { USER_ACTIVITY_TYPES } from "../enums/USER_ACTIVITY_TYPES";
import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { getIcon } from "../../../../utils/Functions/Icon/getIcon";
import { makeStyles } from "@mui/styles";

/**
 * Displays a line of User Activity
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const UserActivityListItem = ({ ...props }) => {
  return (
    <>
      {(() => {
        switch (props?.data?.type) {
          case USER_ACTIVITY_TYPES.CREATE:
            return <CreateItemActivity {...props} />;
          case USER_ACTIVITY_TYPES.DELETE:
            return <DeleteItemActivity {...props} />;
          case USER_ACTIVITY_TYPES.UPDATE:
            return <UpdateItemActivity {...props} />;
          default:
            return <p style={{ fontSize: 14, color: "black" }}>{props?.header}</p>;
        }
      })()}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapIcon: {
    verticalAlign: "middle",
    display: "inline-flex",
    marginRight: "1em",
  },
}));

/**
 * Displays an Activity event of a Resource being Created
 * @param typename
 * @returns {JSX.Element}
 * @constructor
 */
const CreateItemActivity = ({ data }) => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="subtitle1" className={classes.wrapIcon}>
        {getIcon("akar-icons:circle-plus")}
      </Typography>
      Created a new <TypenameBadge typename={data?.typename} route={data?.route} itemId={data?.itemId} />
    </div>
  );
};

/**
 * Displays an Activity event of a resource being deleted
 * @param typename
 * @returns {JSX.Element}
 * @constructor
 */
const DeleteItemActivity = ({ data }) => {
  return (
    <div>
      Deleted <TypenameBadge typename={data?.typename} route={data?.route} itemId={data?.itemId} />
    </div>
  );
};

/**
 * Displays an Activity event of a resource field being updated
 * @param {object} data - the Event.data json field
 * @param {string} data.typename - the model typename being updated
 * @param {string} data.itemId - the ID of the resource being updated
 * @param {string} data.field - the resource field being updated
 * @param {*} data.value - the value that the field was updated to
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
const UpdateItemActivity = ({ data }) => {
  const classes = useStyles();

  //Object/array values not supported
  let value = data?.value;
  if (typeof value === "object" || Array.isArray(value)) {
    value = null;
  }

  return (
    <div>
      <Typography variant="subtitle1" className={classes.wrapIcon}>
        {getIcon("ic:twotone-create")}
      </Typography>
      Updated <TypenameBadge typename={data?.typename} route={data?.route} itemId={data?.itemId} />{" "}
      <span>{convertCamelCaseToSentence(data?.field)}</span> to: {value ? `'${value}'` : "a new value"}
    </div>
  );
};

/**
 * Displays the Typename of the resource being manipulated.
 * Optionally displays a tooltip that describes the resource and provides a route link
 * @param {string} typename
 * @param {string} itemId
 * @param {string} route
 * @returns {JSX.Element}
 * @constructor
 */
const TypenameBadge = ({ typename, itemId, route }) => {
  const underlineStyle = {
    borderBottom: "2px dashed",
    textDecoration: "none",
    cursor: "pointer",
  };
  return (
    <>
      <span style={underlineStyle} title={`${typename}: ${itemId}`}>
        {convertCamelCaseToSentence(typename)}
      </span>
    </>
  );
};
