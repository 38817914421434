import { checkArguments } from "@rivial-security/func-utils";
import { createControlEvidenceLink } from "./createControlEvidenceLink";

/**
 * Performs the operation for linking Evidence items to a control
 *
 * @param {Evidence[]} evidences - the evidence being linked
 * @param {Control} control - the control to link evidence to
 * @param {string} organizationID - organization for the mutation
 */
export const handleLinkingEvidences = async (evidences, control, organizationID) => {
  checkArguments({
    evidences,
    control,
    organizationID,
  });

  for (const linkItem of evidences) {
    let notAlreadyLinked = true;

    if (control?.evidences?.items) {
      // loop through control's current evidence links
      for (const evidenceLink of control.evidences.items) {
        // if evidence is not already
        if (evidenceLink?.evidence?.id === linkItem?.id) {
          notAlreadyLinked = false;
        }
      }

      if (notAlreadyLinked) {
        // add the link
        await createControlEvidenceLink(control.id, linkItem.id, organizationID);
      }
    }
  }
};
