"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDynamicText = void 0;
const report_utils_1 = require("@rivial-security/report-utils");
const func_utils_1 = require("@rivial-security/func-utils");
const replaceDynamicField_1 = require("./replaceDynamicField");
/**
 * Creates the sfdt version of the dynamic (queried) text widget
 * @param {object} config - settings info for the dynamic text widget
 * @param {object} organization - info on the organization that holds this widget
 * @return {Promise<object>}
 */
const createDynamicText = async ({ config, organization } = {}) => {
    // [CHECK ARGUMENTS]
    // - null checks
    const organizationID = organization?.id;
    if (!config || !organizationID) {
        return (0, report_utils_1.createText)("[Invalid Dynamic Text Widget]");
    }
    // [POPULATE TEXT]
    const textString = config.text;
    const replacedString = await (0, func_utils_1.replaceAsync)(textString, /{{(.*?)}}/g, async (match) => {
        console.log("Found match for dynamic text - ", { match });
        // strip brackets
        match = match.replace(/{{/g, "");
        match = match.replace(/}}/g, "");
        // get the value of the dynamic variable
        return await (0, replaceDynamicField_1.replaceDynamicField)({ field: match, organizationID });
    });
    // [RETURN]
    return (0, report_utils_1.createText)(replacedString);
};
exports.createDynamicText = createDynamicText;
