import React, { useContext } from "react";

import { ActionCenterContext } from "../../../../ActionCenter/context/ActionCenterContext";
import { ErrorLogger } from "@utils/EventLogger";
import ImportActions from "../../components/ImportActions";
import ImportButton from "@utils/GenericComponents/ImportButton";
import { PriorityBadge } from "../../../PriorityActions/CustomFields/PriorityBadge/PriorityBadge";
import { SliderComponent } from "@syncfusion/ej2-react-inputs";
import { createActionItem } from "../../functions/createActionItem";
import { createRecommendationActionItemLink } from "../../functions/createRecommendationActionItemLink";
import { getActionItemFormFields } from "@views/Program/Actions/hooks/useCreateAction/function/getActionItemFormFields";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useForm } from "@hooks/views/useForm";
import { useModal } from "@hooks/views/useModal";
import { usePointOfContactList } from "../../../../OrganizationManager/PointOfContacts/hooks/usePointOfContactList";

/**
 * Allows the user to create a single ActionItem
 * @param {string} organizationID
 * @param {boolean} disableEdit - view the action details in its creation form without being able to edit any fields
 * @param {string} module - the part of the platform (risk, vendor, etc.) that this action pertains to
 * @param {function} getNewItem - returns the item information after it has been created in the database for populating tables
 * @param {object} config - beside below parameters also can store form configurations {...config} is used
 * @param {boolean} config.disableMutation - if TRUE wont allow any changes to the action
 * @param {object} config.recommendation - the recommendation item that this action attempts to resolve
 */
export const useCreateAction = ({
  organizationID,
  disableEdit,
  module,
  getNewItem,
  data,
  toggleModal,
  recommendation,
  ...config
}) => {
  const pointOfContactList = usePointOfContactList({ organizationID });

  const vsicoDashboardContext = useContext(ActionCenterContext);

  const submitFunction = async (input) => {
    if (!config.disableMutation) {
      try {
        const result = await createActionItem(
          {
            ...input,
            ownerGroup: organizationID,
            status: "proposed",
            dueDate: !input.dueDate || input.dueDate === "" ? undefined : new Date(input.dueDate),
          },
          config.recommendation,
        );

        if (!isNullOrUndefined(recommendation?.id)) {
          await createRecommendationActionItemLink(recommendation, result);
        }

        //Handles dashboard tile changes on new action addition
        if (vsicoDashboardContext?.addAction) {
          vsicoDashboardContext.addAction({
            ...input,
            ...result,
            __typename: "GenericActionItem",
          });
        }

        return result;
      } catch (e) {
        ErrorLogger("Failed to fully add the action item!", e);
      }
    }
  };

  //Doesn't allow for duplicate action entries if in VCISO dashboard (context is used instead)
  const itemPassbackFilter = (item) => {
    if (isNullOrUndefined(vsicoDashboardContext)) {
      getNewItem?.(item);
    }
  };

  const importerModal = useModal(
    "Import Observations, Recommendations, and Action Items",
    <ImportActions />,
    <ImportButton />,
    { width: "90vw", disableClickToggle: true },
  );

  const form = useForm({
    header: <span>Create an Action Item</span>,
    headerButtons: [importerModal.modalButton],
    submitFunction,
    getNewItem: itemPassbackFilter,
    toggleModal,
    enableToast: true,
    organizationID,
    module,
    fieldConfig: getActionItemFormFields({ module, pointOfContacts: pointOfContactList.list, disableEdit }),
    updateInputFunction: (input) => {
      /**
       * Added check because default value is an empty string and it throws an gql error
       */
      if (input.genericActionItemPointOfContactId === "") input.genericActionItemPointOfContactId = undefined;

      /**
       * Adding this because the initial state for useForm is an empty string
       * and I can not set defaultValue to undefined as well
       */
      if (input.automationType === "") input.automationType = undefined;

      /**
       * Handle passed in action data
       */
      if (!isNullOrUndefined(data)) {
        input.data = data;
      }
      return input;
    },
    ...config,
  });

  return {
    ...form,
  };
};

/**
 * Adjusts the priority of an action item
 * @param {object} input - input object
 * @returns {ReactElement}
 */
export const AdjustPriority = ({ value, onChangeCallback: onChange, disabled }) => {
  return (
    <div className="e-card" style={{ padding: "0.5em" }}>
      <PriorityBadge priority={value} showValue={true} />
      <span style={{ textAlign: "center" }}>({value})</span>
      <SliderComponent
        id={"priority-slider-form"}
        min={0}
        max={600}
        value={value}
        changed={(e) => onChange(e.value)}
        readonly={disabled}
      />
    </div>
  );
};
