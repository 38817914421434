import { generateGraphql } from "@rivial-security/generategraphql";
import { ItemQuery } from "../../../../../utils/Functions/Graphql/ItemQuery";
import { ItemMutation } from "../../../../../utils/Functions/Graphql/ItemMutation";

/**
 * Deletes a bookmark from an artifact label link
 * @param {string} labelLinkID - the label link to delete the bookmark from
 * @param {string} bookmarkID - the bookmark to delete
 * @returns {Promise<*>}
 */
export const deleteArtifactLabelLinkBookmark = async ({ labelLinkID, bookmarkID }) => {
  //Throw error if one of ids is missing
  if (!labelLinkID || !bookmarkID) {
    throw new Error("Missing label link or bookmark ID");
  }

  //Get the label link
  const { getQuery, updateMutation } = generateGraphql(
    "ArtifactLabelLink",
    ["artifactID", "labelID", "__typename", "label", "bookmarks"],
    {
      label: `{ id name description backgroundColor fontColor }`,
      bookmarks: `{ id createdByAI owner text data }`,
    },
  );

  const labelLink = await ItemQuery(getQuery, labelLinkID);

  if (!labelLink) {
    throw new Error("Label link not found");
  }

  //Filter out the bookmark to be deleted
  const oldBookmarks = labelLink?.bookmarks;
  if (Array.isArray(oldBookmarks)) {
    let newBookmarks = [];

    newBookmarks = labelLink.bookmarks.filter((bookmark) => bookmark.id !== bookmarkID);

    if (newBookmarks.length === oldBookmarks.length) {
      throw new Error("Bookmark not found");
    }

    //Update the label link
    return await ItemMutation(updateMutation, {
      id: labelLinkID,
      bookmarks: newBookmarks,
    });
  } else {
    throw new Error("Label link has no bookmarks");
  }
};
