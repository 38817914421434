import { useMultipleArtifactValidation } from "./useMultipleArtifactValidation";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * A component instance of the useMultipleArtifactValidation.js hook
 * @param {object} props
 */

const MultipleArtifactValidation = (props) => {
  return useMultipleArtifactValidation(props).display;
};

export default withOrganizationCheck(MultipleArtifactValidation);
