"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetQueryItems = void 0;
const handleGetQueryItems_1 = require("./handleGetQueryItems");
const getEnvironment_1 = require("../getEnvironment");
const handleGraphqlClient_1 = require("../handleGraphqlClient");
/**
 * Get query items function is similar to GetQuery, but it returns an array of items.
 * @param [appsyncEndpoint] - appsync endpoint
 * @param query - graphql query
 * @param variables - variables for running a query
 */
const GetQueryItems = async ({ appsyncEndpoint, query, variables }) => {
    const env = (0, getEnvironment_1.getEnvironment)({ appsyncEndpoint });
    const queryFunction = (0, handleGraphqlClient_1.handleGraphqlClient)({ env, appsyncEndpoint });
    return await (0, handleGetQueryItems_1.handleGetQueryItems)({ queryFunction, query, variables });
};
exports.GetQueryItems = GetQueryItems;
