import TagFilterOperatorButtonGroup from "../Tags/components/TagFilterOperatorButtonGroup";
import Tags from "../Tags/components/Tags";
import React, { useContext } from "react";
import { TEMPLATE } from "../../enums/TEMPLATE";
import { OrganizationContext } from "../Context/OrganizationContext";

/**
 * The UI that gets displayed in grids on pressing the filter button for the "Tags" column
 * @param {object} tagsOperatorRef - reference to the current operation selected (used for initial value)
 * @param {object} selectedTagsRef - if provided will use the 'current' property as the initial list of the selected tags
 * @param {function} setTagsOperator - function from useState, used to set a new operator value
 * @param {function} setSelectedTags - function from useState, used to set a new list of selected tags
 * @param {string} organizationID - the database organization identifier that contains the tags that can be used for filtering
 */
const TagFilterMenu = ({ tagsOperatorRef, selectedTagsRef, setTagsOperator, setSelectedTags, organizationID }) => {
  //Use the origin organization for template page
  const context = useContext(OrganizationContext);
  if (organizationID === TEMPLATE) {
    organizationID = context?.role?.ownerGroup;
  }

  return (
    <div>
      <TagFilterOperatorButtonGroup tagsOperatorRef={tagsOperatorRef} setTagsOperator={setTagsOperator} />
      <hr />
      <Tags
        onSelectedTagCallback={(newSelectedTags) => setSelectedTags(newSelectedTags)}
        organizationID={organizationID}
        selectedTagsRef={selectedTagsRef}
        showTagsInitially={true}
        showHideTagsOption={false}
        showCreateTagsOption={false}
        heading={"Select a tag to begin filtering:"}
        showHeader={(selectedTags) => {
          //Only show header when no tags are selected
          if (selectedTags && Array.isArray(selectedTags) && selectedTags.length > 0) {
            return false;
          }
          return true;
        }}
      />
    </div>
  );
};

export default TagFilterMenu;
