import { modules, resources } from "@rivial-security/role-utils";

import { GENERIC_FIELD_TYPES } from "../../../../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import PropTypes from "prop-types";
import React from "react";
import SystemAdmin from "../../../../customFields/SystemAdmin";
import SystemHosting from "../customFields/SystemHosting";
import SystemNotes from "./SystemNotes";
import SystemOwner from "../../../../customFields/SystemOwner";
import TagsField from "../../../../../../../utils/Tags/customFields/TagsField";
import { createSystemTagLink } from "../../../../../../../utils/Tags/functions/createTagLinks/createSystemTagLink";
import { realTimeRiskGQL } from "@rivial-security/risk-calc-utils";
import { useDetails } from "../../../../../../../hooks/views/useDetails/hooks/useDetails";
import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";

/**
 * Author: Jacob Blazina
 * Created At: 9/11/19
 * Edits:
 *   - 10/18/19 JB: Added props.printView
 *
 * Description: A Card component. No header.
 *              Displays basic fields for a system
 *              Must use a System prop to display information.
 */
const SystemOverview = ({
  updateItemById,
  module = modules.RISK,
  resource = resources.INFORMATION_SYSTEM,
  ...props
}) => {
  const typename = resources.INFORMATION_SYSTEM;

  const fields = ["name", "description", "pointOfContact", "adminPointOfContact", "hosting", "tags"];

  const nestedFields = {
    pointOfContact: `{ id firstName lastName title }`,
    adminPointOfContact: `{ id firstName lastName title }`,
    tags: `(limit: 100) { items { __typename id tag { id name description fontColor backgroundColor } } }`,
  };

  const fieldNameDictionary = {
    adminPointOfContact: "System Admin",
    pointOfContact: "System Owner",
  };

  const customFields = [
    {
      field: "hosting",
      component: <SystemHosting />,
    },
    {
      field: "pointOfContact",
      component: <SystemOwner module={module} resource={resource} field="pointOfContact" />,
    },
    {
      field: "adminPointOfContact",
      component: <SystemAdmin module={module} resource={resource} field="adminPointOfContact" />,
    },
    {
      field: "description",
      inputType: GENERIC_FIELD_TYPES.TEXT_AREA,
    },
    {
      field: "tags",
      component: (
        <TagsField module={module} resource={resources.INFORMATION_SYSTEM} createLinkFunction={createSystemTagLink} />
      ),
    },
  ];

  const table = useDetails({
    item: props?.system,
    typename,
    fields,
    nestedFields,
    fieldNameDictionary,
    customFields,
    module,
    resource,
    updateItemById,
    mutation: realTimeRiskGQL.updateSystem_minimal,
  });

  return (
    <div>
      {table.display}
      <hr />
      <SystemNotes item={props.system} />
    </div>
  );
};

SystemOverview.propTypes = {
  system: PropTypes.object.isRequired,
  printView: PropTypes.bool,
};

SystemOverview.defaultProps = {
  system: {},
  printView: false,
};

export default withOrganizationCheck(SystemOverview);
