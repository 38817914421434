import { useModal } from "../../useModal";
import { Button } from "reactstrap";
import React from "react";
import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import SelectTemplates from "../components/SelectTemplates";

/**
 * Shows a modal with a grid inside that allows a user to select a template to copy
 * @param {string} typename - typename string used to display in the UI, if not provided, the resource name will be used
 * @param {string} resource - the resource that is being modified
 * @param {object[]} [parents] - the parent resources (provides additional information on what to link the template to)
 * @param {function} [onStartedDuplication] - function to call once the duplication process is started, useful to close modals
 * @param {function} [getNewItem] - function to call when an item was added (used by grids to populate new items)
 */
export const useSelectTemplatesModal = ({ typename, resource, parents, onStartedDuplication, getNewItem } = {}) => {
  /**
   * Gets the display name for the resource, either the typename or the resource name
   * @returns {string}
   */
  const getResourceDisplayName = () => {
    if (typename) return convertCamelCaseToSentence(typename);
    if (resource) return convertCamelCaseToSentence(resource);
    return "Item";
  };

  //Create from template modal
  const selectTemplateModal = useModal(
    `Select ${getResourceDisplayName()} Templates`,
    <SelectTemplates
      resource={resource}
      parents={parents}
      onSubmitCallback={() => {
        selectTemplateModal.setModalIsOpen(false);
        onStartedDuplication && onStartedDuplication();
      }}
      getNewItem={getNewItem}
    />,
    <Button
      color="primary"
      title={`Select a preconfigured template ${
        getResourceDisplayName() ?? "item"
      } to duplicate into the current organization.`}
    >
      {`Create from ${getResourceDisplayName()} Templates`}
    </Button>,
    { width: "80vw" },
  );

  return {
    ...selectTemplateModal,
  };
};
