import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useQueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import NotesV2 from "../../../../utils/Notes/components/NotesV2";
import React, { useEffect } from "react";
import { modules, resources } from "@rivial-security/role-utils";

export const updateMutation = /* GraphQL */ `
  mutation UpdateObservation($input: UpdateObservationInput!) {
    updateObservation(input: $input) {
      id
      ownerGroup
      notes {
        id
        observationID
      }
    }
  }
`;

export const getQuery = /* GraphQL */ `
  query GetObservation($id: ID!) {
    getObservation(id: $id) {
      id
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
        observationID
      }
    }
  }
`;

/**
 * @description Observations notes component
 * @param {object} item - observation
 * @param {string} organizationID - currently selected organization
 * @param {boolean} disableTitle - enable or disable title
 * @returns {JSX.Element}
 * @constructor
 */
const ObservationNotes = ({ item, organizationID, disableTitle }) => {
  const module = modules.GOVERNANCE;
  const resource = resources.OBSERVATION;

  const query = useQueryGetItem({
    itemId: item?.id,
    query: getQuery,
    module,
    resource,
  });

  /**
   * Reset the query to handle subscriptions
   */
  useEffect(() => {
    if (!query.isLoading) {
      query.reset();
    }
  }, [item]);

  return (
    <NotesV2
      disableTitle={disableTitle}
      item={query?.item}
      module={module}
      resource={resource}
      tooltip="General Notes for this Observation"
      organizationID={organizationID}
      mutation={updateMutation}
      resetFunction={query.reset}
    />
  );
};

export default withOrganizationCheck(ObservationNotes);
