import { usePreferences } from "../../../../usePreferences/usePreferences";
import { useEffect, useState } from "react";

/**
 * Source of truth for data grid view list and currently selected view,
 * when preferences update in organization context the state here updates as well
 * @param {string} gridID - the grid persistence ID
 * @return {{selectedViewID: string, views: object[]}}
 */
export const useDataGridViewsState = ({ gridID }) => {
  const preferencesHook = usePreferences();

  const [views, setViews] = useState([]);
  const [selectedViewID, setSelectedViewID] = useState(null);

  useEffect(() => {
    const dataGridViewSettings = preferencesHook?.preferences?.dataGridViews?.[gridID];
    const { selectedViewID: newSelectedViewID, views: newViews } = dataGridViewSettings || {};

    setSelectedViewID(newSelectedViewID);
    setViews(newViews);
  }, [gridID, JSON.stringify(preferencesHook?.preferences?.dataGridViews?.[gridID])]);

  return {
    views,
    selectedViewID,
  };
};
