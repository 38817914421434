import React from "react";
import OrganizationCheck from "../../../../../../utils/Context/OrganizationCheck/OrganizationCheck";
import { useCreateManualChange } from "../hooks/useCreateManualChange";

const CreateRiskChangeBody = ({ organizationID, resetFunction, toggleModal, ...props }) => {
  const createRiskChangeHook = useCreateManualChange({
    organizationID,
    resetFunction,
    toggleModal,
    ...props,
  });

  return <>{createRiskChangeHook.display}</>;
};

const CreateManualChange = (props) => {
  return (
    <OrganizationCheck {...props}>
      <CreateRiskChangeBody />
    </OrganizationCheck>
  );
};

export default CreateManualChange;
