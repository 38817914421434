import React, { useCallback, useState } from "react";
import { ResponsiveBar, ResponsiveBarCanvas } from "@nivo/bar";

/**
 * @description UseBarChart hook, displays a bar chart
 * @param {object[]} data - The data to display in the chart
 * @param {string[]} keys - The keys to display in the chart
 * @param {string} indexBy - The key to index the data by
 * @param {object} config - The config to use for the chart
 * @param {object} nivoProps - The props to use for the chart
 * @returns {{chartData: unknown, addItem: (function(*): void), removeById: (function(*): void), display: JSX.Element, printView: JSX.Element, setChartData: (function(*): void)}}
 * @example useBarChart(data, ["count"], "severityLevel", { isCustomColors: true });
 */
export const useBarChart = (data, keys, indexBy, config = {}, nivoProps = {}) => {
  const [chartData, setChartData] = useState(data);

  // each item in data array should have a "color" field with color value
  // pass in config {
  //       isCustomColors: true
  // }
  const getBarColor = (bar) => bar.data.color;

  const display = (
    <ResponsiveBar
      data={data}
      keys={keys}
      indexBy={indexBy}
      margin={{
        top: config?.chartMarginTop ?? 50,
        right: config?.disableLegends ? (config?.chartMarginRight ?? 10) : (config?.chartMarginRight ?? 130),
        bottom: config?.chartMarginBottom ?? 50,
        left: config?.chartMarginLeft ?? 60,
      }}
      padding={0.3}
      colors={config?.isCustomColors ? getBarColor : { scheme: "paired" }}
      enableLabel={true}
      axisBottom={config?.axisBottom ?? undefined}
      axisLeft={config?.axisLeft ?? undefined}
      animate={config?.animate ?? true}
      legends={
        !config?.disableLegends
          ? [
              {
                dataFrom: "keys",
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]
          : []
      }
      {...nivoProps}
    />
  );

  const printView = (
    <ResponsiveBarCanvas
      data={data}
      keys={keys}
      indexBy={indexBy}
      margin={{
        top: config?.chartMarginTop ?? 50,
        right: config?.disableLegends ? (config?.chartMarginRight ?? 10) : (config?.chartMarginRight ?? 130),
        bottom: config?.chartMarginBottom ?? 50,
        left: config?.chartMarginLeft ?? 60,
      }}
      padding={0.3}
      colors={config?.isCustomColors ? getBarColor : { scheme: "paired" }}
      enableLabel={true}
      axisBottom={config?.axisBottom ?? undefined}
      axisLeft={config?.axisLeft ?? undefined}
      legends={
        !config?.disableLegends
          ? [
              {
                dataFrom: "keys",
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]
          : []
      }
      {...nivoProps}
    />
  );

  return {
    chartData,
    setChartData: useCallback((data) => setChartData(data)),
    addItem: useCallback((newItem) => setChartData((prev) => [...prev, newItem])),
    removeById: useCallback((id) => setChartData((data) => data.filter((item) => item && item.id !== id))),
    display,
    printView,
  };
};
