import { ErrorLogger, InfoLogger } from "../../EventLogger";

import AWS from "aws-sdk";
import { GetOrgIamCredentials } from "./GetOrgIamCredentials";

/**
 * @function
 * @description Delete a S3 Object
 * @param bucketName {string}
 * @param objectKey {string}
 * @param organizationID {string}
 * @param callbackFunction {function}
 * @returns {Promise<void>}
 * @constructor
 */

export const DeleteS3Object = async ({ bucketName, objectKey, organizationID, callbackFunction }) => {
  /**
   * Get credentials for S3 bucket
   */
  const credentials = await GetOrgIamCredentials({ organizationID });

  const s3 = new AWS.S3({
    credentials,
    region: "us-west-2",
  });

  const params = {
    Bucket: bucketName,
    Key: objectKey,
  };

  s3.deleteObject(params)
    .promise()
    .then((data) => {
      callbackFunction?.(data);

      InfoLogger(data);
    })
    .catch((err) => ErrorLogger("Error! ", err));
};
