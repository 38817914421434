import { listOrganizationRiskControlsMap } from "./listOrganizationRiskControlsMap";
import { listOrganizationRiskOverrides } from "./listOrganizationRiskOverrides";
import { getDetailedRiskControlItem } from "./getDetailedRiskControlItem";

/**
 * Finds all 3-way risk control, evidence, system links for the given organization
 * @param {string} organizationID - the organization for which to find the links
 * @param {string} [riskControlCategoryID] - if provided will filter the list to only show overrides for the specified risk control category
 * @param {string} [riskControlID] - if provided will filter the list to only show overrides for the specified risk control
 * @return {Promise<object[]>} - all the link data
 */
export const listOrganizationNonStandardRiskControlEvidenceLinks = async ({
  organizationID,
  riskControlCategoryID,
  riskControlID,
} = {}) => {
  if (!organizationID) {
    return [];
  }

  // Create a map of all risk controls for the organization
  const organizationRiskControls = await listOrganizationRiskControlsMap({
    organizationID,
  });

  const riskControlToCategoriesMap = {};
  for (const riskControlID in organizationRiskControls) {
    const riskControl = organizationRiskControls[riskControlID];
    if (riskControl?.controlCategoryID) {
      riskControlToCategoriesMap[riskControlID] = riskControl.controlCategoryID;
    }
  }

  // Create a list of all organization risk control overrides
  const organizationRiskControlOverrides = await listOrganizationRiskOverrides({
    organizationID,
  });

  // Parse control override information into the fields shown in the grid
  const data = [];
  for (const riskControlOverride of organizationRiskControlOverrides) {
    const controlID = riskControlOverride.riskControlId;
    let standardRiskControl = null;
    if (organizationRiskControls.hasOwnProperty(controlID)) {
      standardRiskControl = organizationRiskControls[controlID];
    }

    //Filter by risk control category
    if (riskControlCategoryID && standardRiskControl?.controlCategoryID !== riskControlCategoryID) {
      continue;
    }

    //Filter by risk control
    if (riskControlID && controlID !== riskControlID) {
      continue;
    }

    data.push(
      getDetailedRiskControlItem({
        standardRiskControl,
        riskControlOverride,
      }),
    );
  }

  return data;
};
