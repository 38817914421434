import { modules, resources } from "@rivial-security/role-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useDetailsCard } from "../../../hooks/views/useDetailsCardV2";
import React from "react";
import { withDetailsPage } from "../../../utils/Context/withDetailsPage";
import { useAccordion } from "../../../hooks/views/useAccordion/useAccordion";
import ThreatTechnique from "../../Response/Threats/components/ThreatTechnique";

/**
 * Details component for a Threat template
 *
 * @param {string} itemId - ID of the threat template
 * @param {string} organizationID - ID of the organization
 * @param {string} module - Module used for role checking
 * @param {string} resource - Resource used for role checking
 * @param {Object} ...props - props passed to the useDetailsCard hook
 * @returns {JSX.Element}
 * @constructor
 */
const ThreatTemplateDetails = ({
  itemId,
  organizationID,
  module = modules.INCIDENT_RESPONSE,
  resource = resources.THREAT,
  ...props
}) => {
  const { getQuery, updateMutation } = generateGraphql("Threat", ["name", "techniqueID"]);

  const fields = ["name"];

  const queryConfig = {
    query: getQuery,
    itemId,
  };

  const detailsConfig = {
    fields,
    updateMutation,
  };

  const detailsHook = useDetailsCard({
    queryConfig,
    detailsConfig,
    module,
    resource,
    ...props,
  });

  const accordion = useAccordion({
    item: detailsHook?.item,
    items: [
      {
        title: "General Information",
        subTitle: "Top level information about the threat",
        icon: "icon-list",
        component: detailsHook?.tableDisplay,
        defaultExpanded: true,
      },
      {
        title: "Technique",
        subTitle: "Mitre Att&ck Technique",
        component: <ThreatTechnique item={detailsHook?.item} resetFunction={detailsHook?.reset} />,
        icon: "icon-notebook",
        defaultExpanded: true,
      },
    ],
  });

  return accordion?.display;
};

export default withDetailsPage(ThreatTemplateDetails);
