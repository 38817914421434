import { Button, Col, ListGroup, ListGroupItem, Row } from "reactstrap";
import React, { useContext, useState } from "react";
import { findObjectInArray, formattedName } from "@rivial-security/func-utils";
import { modules, resources } from "@rivial-security/role-utils";

import CreateButton from "../../../../../../utils/GenericComponents/CreateButton";
import { DisabledWrapper } from "../../../../../../utils/GenericComponents/DisabledWrapper";
import { EVIDENCE_ACTIVITY_TYPES } from "../../../../../../typedefs/Compliance/Evidence/Evidence";
import { ErrorLogger } from "@utils/EventLogger";
import { ItemMutation } from "../../../../../../utils/Functions/Graphql/ItemMutation";
import NotEnoughData from "../../../../../../utils/GenericComponents/NotEnoughData";
import { OrganizationContext } from "../../../../../../utils/Context/OrganizationContext";
import PermissionsOverlay from "../../../../../../utils/Overlays/PermissionsOverlay";
import PointOfContactDataGrid from "../../../../../OrganizationManager/PointOfContacts/components/PointOfContactDataGrid";
import { TEMPLATE } from "../../../../../../enums/TEMPLATE";
import { UIContext } from "../../../../../../utils/Context/UIContext";
import { deleteEvidencePointOfContactLink } from "../functions/deleteEvidencePointOfContactLink";
import { evidenceOnRemovePointOfContactRemoveActions } from "../../../functions/evidenceOnRemovePointOfContactRemoveActions";
import { evidenceOnSelectPointOfContactCreateActions } from "../../../functions/evidenceOnSelectPointOfContactCreateActions";
import { extractEvidencePointOfContact } from "../../../functions/extractEvidencePointOfContact";
import { generateGraphql } from "@rivial-security/generategraphql";
import { getMany } from "../../../../../../utils/Functions/getMany";
import { getPointOfContactStanding } from "../functions/getPointOfContactStanding";
import { onEvidencePointOfContactChange } from "../../../functions/onEvidencePointOfContactChange";
import { performToastOperation } from "../../../../../../utils/Toasts/functions/toastOperation";
import { useCheckPermissions } from "../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import useDidMountEffect from "@hooks/functional/useDidMountEffect";
import { useEvidencePointOfContactAction } from "./useEvidencePointOfContactActions";
import { useModal } from "../../../../../../hooks/views/useModal";

/**
 * Displays the currently assigned points of contact for an Evidence
 * @param {string} organizationID - the organization ID to get the points of contact from
 * @param {object} item - the evidence item, should have complete list of points of contact
 * @param {function} resetFunction - resets the evidence item when a point of contact gets added or removed
 * @param {function} setActivityResetIndex - triggers a re-fetch of the evidence activities
 * @param {function} updateItemById - passed down from the grid to update a row after a mutation (reset not called if present)
 * @param {object} toggleModalRef - reference to the modal toggle function
 * @param {boolean} disablePointOfContactAssign - optional flag for disabling the 'assign a point of contact' button for evidence
 * @returns {*}
 * @constructor
 */
export const useEvidencePointOfContacts = ({
  organizationID,
  item,
  resetFunction,
  setActivityResetIndex,
  updateItemById,
  disablePointOfContactAssign = false,
}) => {
  const isTemplate = item?.ownerGroup === TEMPLATE;
  const module = isTemplate ? modules.ADMINISTRATOR : modules.COMPLIANCE;
  const resource = isTemplate ? resources.EVIDENCE_TEMPLATE : resources.EVIDENCE;
  const field = "pointOfContacts";

  const getPointOfContacts = () => {
    const databasePointOfContacts = item?.pointOfContacts?.items;
    if (!Array.isArray(databasePointOfContacts)) {
      return [];
    }

    try {
      return getMany(item, "pointOfContacts", "pointOfContact") || [];
    } catch (e) {
      ErrorLogger("Couldn't get Points Of Contact for an Evidence", e);
    }

    return [];
  };
  const [pointOfContacts, setPointOfContacts] = useState(getPointOfContacts());
  useDidMountEffect(() => {
    setPointOfContacts(getPointOfContacts());
  }, [JSON.stringify(item?.pointOfContacts?.items)]);

  const { addToast, updateToast } = useContext(UIContext);
  const { loggedInPointOfContactId, loggedInUserId } = useContext(OrganizationContext);
  const checkPermissionsHook = useCheckPermissions({ module, resource, field });

  const onPointOfContactRemoved = async ({ pointOfContactLinkID }) => {
    await performToastOperation({
      addToast,
      updateToast,
      operation: async () => {
        const currentPointOfContactLinks = item?.pointOfContacts?.items || [];
        const linkIndex = currentPointOfContactLinks.findIndex((link) => link.id === pointOfContactLinkID);

        //If no link to remove is found, return
        if (linkIndex === -1) {
          return;
        }

        /**
         * Save reference to the point of contact that was removed
         */
        const pointOfContactToRemove = currentPointOfContactLinks[linkIndex]?.pointOfContact;

        currentPointOfContactLinks.splice(linkIndex, 1);
        const newItem = {
          ...item,
          pointOfContacts: {
            items: currentPointOfContactLinks,
          },
        };

        /**
         * Check if POC have action items for an evidence assigned to them if so, remove them
         */
        await evidenceOnRemovePointOfContactRemoveActions({
          pointOfContact: pointOfContactToRemove,
          evidence: item,
        });

        /**
         * Create an activity for the point of contact that was removed
         */
        await onEvidencePointOfContactChange({
          type: EVIDENCE_ACTIVITY_TYPES.POINT_OF_CONTACT_UNASSIGNED,
          pointOfContactID: loggedInPointOfContactId,
          userID: loggedInUserId,
          evidenceID: item?.id,
          organizationID,
          changedPointOfContact: pointOfContactToRemove,
        }).then(
          () =>
            typeof setActivityResetIndex === "function" &&
            setActivityResetIndex((activityResetIndex) => activityResetIndex + 1),
        );

        if (typeof updateItemById === "function") {
          updateItemById(newItem);
        } else if (typeof resetFunction === "function") {
          resetFunction();
        }
      },
      inProgressText: "Unlinking Point of Contact...",
      successText: "Point of Contact was successfully unlinked",
      failedText: "Something went wrong when unlinking the Point of Contact",
      iconColor: "success",
    });
  };

  const onPointOfContactSelected = async (selectedPointOfContact) => {
    await performToastOperation({
      addToast,
      updateToast,
      operation: async () => {
        /**
         * Make sure the point of contact is not already assigned to the evidence
         */
        if (findObjectInArray(getMany(item, "pointOfContacts", "pointOfContact"), selectedPointOfContact)) {
          alert(
            `${formattedName({
              pointOfContact: selectedPointOfContact,
            })} is already assigned to this Evidence!`,
          );
          return;
        }

        /**
         * Create a new point of contact link
         */
        const { createMutation } = generateGraphql("EvidencePointOfContactLink", ["pointOfContact"], {
          pointOfContact: `{ id firstName lastName title email phone user { id } }`,
        });

        const newPointOfContactLink = await ItemMutation(createMutation, {
          pointOfContactID: selectedPointOfContact?.id,
          evidenceID: item?.id,
          ownerGroup: organizationID,
        });

        const currentPointOfContactLinks = item?.pointOfContacts?.items || [];
        const newPointOfContactLinks = [...currentPointOfContactLinks, newPointOfContactLink];

        const newItem = {
          ...item,
          pointOfContacts: {
            items: newPointOfContactLinks,
          },
        };

        /**
         * Check if POC don't have action items for an evidence assigned to them if so, create them
         */
        await evidenceOnSelectPointOfContactCreateActions({
          pointOfContact: newPointOfContactLink?.pointOfContact,
          evidence: item,
        });

        /**
         * Create an activity for the point of contact that was assigned
         */
        await onEvidencePointOfContactChange({
          type: EVIDENCE_ACTIVITY_TYPES.POINT_OF_CONTACT_ASSIGNED,
          pointOfContactID: loggedInPointOfContactId,
          userID: loggedInUserId,
          evidenceID: item?.id,
          organizationID,
          changedPointOfContact: selectedPointOfContact,
        }).then(
          () =>
            typeof setActivityResetIndex === "function" &&
            setActivityResetIndex((activityResetIndex) => activityResetIndex + 1),
        );

        if (typeof updateItemById === "function") {
          updateItemById(newItem);
          setPointOfContacts((pointsOfContact) => {
            // Make sure the point of contact is not already in the array
            if (!pointsOfContact.some((poc) => poc.id === selectedPointOfContact.id)) {
              return [...pointsOfContact, selectedPointOfContact];
            } else {
              return pointsOfContact;
            }
          });
        } else if (typeof resetFunction === "function") {
          resetFunction();
        }
        /**
         * Close the modal when a point of contact is selected
         */
        selectPointOfContactModal?.setModalIsOpen(false);
      },
      inProgressText: "Linking Point of Contact...",
      successText: "Point of Contact was successfully linked",
      failedText: "Something went wrong when linking the Point of Contact",
      iconColor: "success",
    });
  };

  const selectPointOfContactModal = useModal(
    "Select a Point Of Contact",
    <div style={{ height: "50em" }}>
      <PointOfContactDataGrid
        organizationID={organizationID}
        enableSelectButton={true}
        onSelectCallback={onPointOfContactSelected}
      />
    </div>,
    null,
    {
      width: "70vw",
    },
  );

  const display = (
    <div>
      {selectPointOfContactModal?.modalIsOpen && selectPointOfContactModal?.modal}
      <PermissionsOverlay module={module} resource={resource} operationType={"read"}>
        <ListGroup>
          {pointOfContacts && pointOfContacts.length > 0 ? (
            pointOfContacts?.map((pointOfContact, index) => {
              const hasUser = pointOfContact?.user?.id;
              const allowUnauthenticatedSubmissions = item?.allowUnauthenticatedSubmissions;
              return (
                <DisabledWrapper
                  isDisabled={!hasUser && !allowUnauthenticatedSubmissions}
                  isInteractable={true}
                  message={`Since this evidence does not allow unauthenticated submissions, and ${
                    formattedName({ pointOfContact }) || "this point of contact"
                  } doesn't have a user account, this individual won't be able to submit evidence artifacts.`}
                  opacityPercentage={50}
                >
                  <PointOfContactItem
                    evidence={item}
                    pointOfContact={pointOfContact}
                    index={index}
                    onRemove={onPointOfContactRemoved}
                  />
                </DisabledWrapper>
              );
            })
          ) : (
            <NotEnoughData message="There are no Points of Contact assigned to this Evidence" />
          )}
        </ListGroup>
      </PermissionsOverlay>

      {!disablePointOfContactAssign && checkPermissionsHook.resource.update && (
        <div>
          <CreateButton
            title="Assign a Point of Contact to this Evidence"
            onClick={() => selectPointOfContactModal?.setModalIsOpen(true)}
            text="Assign a Point of Contact"
            style={{ marginTop: "0.75em" }}
          />
        </div>
      )}
    </div>
  );

  return {
    display,
  };
};

/**
 * Displays a single Point of contact for the Evidence custom field
 * @param {object} evidence - the evidence that this point of contact is a part of
 * @param {object} pointOfContact - the point of contact data to display
 * @param {number} index - the order of this point of contact in the list
 * @param {function} resetFunction - function to refresh evidence data
 * @param {function} onRemove - callback on point of contact removal confirmation, prefer this over resetFunction
 * @param {boolean} displayTitle - if TRUE will show point of contact title in the organization if it is available
 * @returns {JSX.Element}
 */
const PointOfContactItem = ({
  evidence = {},
  pointOfContact = {},
  index,
  resetFunction,
  onRemove,
  displayTitle = false,
}) => {
  const module = modules.COMPLIANCE;
  const resource = resources.EVIDENCE;
  const field = "pointOfContacts";

  const checkPermissions = useCheckPermissions({ module, resource, field });

  /**
   * Onclick handler that calls the delete function for the associated evidence point of contact link
   */
  const handleDelete = async () => {
    if (
      window.confirm(`Are you sure you want to remove ${formattedName({ pointOfContact }) || "this point of contact"}?`)
    ) {
      await deleteEvidencePointOfContactLink(pointOfContact.linkId);
      // check if point of contact id exists first
      if (pointOfContact?.id && typeof onRemove === "function") {
        onRemove({
          pointOfContactID: pointOfContact?.id,
          pointOfContactLinkID: pointOfContact?.linkId,
        });
      }

      if (typeof resetFunction === "function") {
        resetFunction();
      }
    }
  };

  const pointOfContactAction = useEvidencePointOfContactAction(evidence, pointOfContact);

  const actionItemModal = useModal("Evidence Action Item Details", pointOfContactAction.display, null, {
    width: "80vw",
  });

  return (
    <ListGroupItem key={`evidence_contacts${index}${evidence.id}`}>
      <Row
        onClick={() => actionItemModal.setModalIsOpen(true)}
        style={{ cursor: "pointer" }}
        title="Click to show/hide Action Item details"
      >
        <Col xs={1}>
          <span className="float-left" title={null}>
            {getPointOfContactStanding(evidence, pointOfContact)}
          </span>
        </Col>
        <Col>
          <div style={{ textAlign: "center" }}>
            <strong>
              {extractEvidencePointOfContact({ pointOfContact })}
              {displayTitle && pointOfContact?.title && ` | ${pointOfContact?.title}`}
            </strong>
          </div>
        </Col>
        <Col xs={1}>
          {checkPermissions.resource.update && (
            <Button
              style={{ marginTop: "-0.1em" }}
              title="Un-assign this Point of Contact"
              close
              onClick={async (e) => {
                e.stopPropagation();
                await handleDelete();
              }}
            />
          )}
        </Col>
      </Row>
      {actionItemModal.modalIsOpen && actionItemModal.modal}
    </ListGroupItem>
  );
};
