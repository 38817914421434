import EditButton from "../components/EditButton";
import { GENERIC_FIELD_INPUTS } from "../constants/GENERIC_FIELD_TYPES";
import OptionsButtons from "../components/OptionsButtons";
import React from "react";
import { handleWarning } from "./handleWarning";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Determines whether to display the Edit button or the Options buttons
 * @param {GenericEditFieldContext} context
 * @returns {JSX.Element}
 */
export const handleButtons = (context) => {
  if (isNullOrUndefined(context)) {
    throw Error("function must be passed the GenericEditFieldContext as a parameter");
  }

  const hasInput =
    !isNullOrUndefined(GENERIC_FIELD_INPUTS?.[context?.inputType]?.inputComponent) ||
    !isNullOrUndefined(context?.customInputComponent);

  return (
    <span style={{ marginLeft: "5px", marginTop: "3px" }}>
      {(() => {
        if (context.toggleEdit === false) {
          return (
            <span>
              {handleWarning(context)}
              {hasInput && <EditButton {...context} />}
            </span>
          );
        } else {
          return <OptionsButtons {...context} />;
        }
      })()}
    </span>
  );
};
