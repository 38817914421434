import { generateGraphql } from "@rivial-security/generategraphql";
import { useForm } from "../../../../hooks/views/useForm/useForm";
import { modules, resources } from "@rivial-security/role-utils";
import { useAddAccessControl } from "../../../../utils/AccessControl/hooks/useAddAccessControl";

/**
 * @description Create Exercise hook
 * @param {string} organizationID - selected organization
 * @param {function} getNewItem - callback for the data after creation
 * @param {boolean} isTemplate - flag to determine if the exercise is a template
 * @param {object} [fieldConfig = {}] - optional field config overrides
 * @param {object} ...props - all other props passed to useForm hook
 * @returns {{input: {}, display: *}}
 */
export const useCreateExercise = ({
  organizationID,
  getNewItem,
  isTemplate = false,
  disableTemplates = false,
  fieldConfig = {},
  ...props
}) => {
  const module = modules.INCIDENT_RESPONSE;
  const resource = resources.EXERCISE;

  const { createMutation } = generateGraphql("Exercise", ["name", "description", "scenario"]);

  /**
   * Add Access Control to an Exercise
   */
  const addAccessControl = useAddAccessControl({
    typename: "Exercise",
    disableFormButtons: true,
    organizationID,
  });

  const callback = (newExercise) => {
    // Add access control if switch is on
    if (form?.input?.accessControl === true) {
      addAccessControl?.submitFunction({ itemToAssign: newExercise });
    }
  };

  const form = useForm({
    typename: "Exercise",
    enableTemplates: !disableTemplates && !isTemplate,
    mutation: createMutation,
    callback,
    module,
    resource,
    organizationID,
    getNewItem,
    fieldConfig: {
      name: {
        label: "Name",
        tooltip: "Exercise name",
        required: true,
      },
      description: {
        label: "Description",
        tooltip: "Exercise description",
      },
      scenario: {
        label: "Scenario",
        tooltip: "Exercise scenario",
        inputType: "textarea",
      },
      accessControl: {
        inputType: "custom",
        removeFromInput: true,
        customConfig: {
          component: addAccessControl?.displaySwitch,
        },
      },
      ...fieldConfig,
    },
    ...props,
  });

  return {
    ...form,
  };
};
