import React, { useContext, useEffect, useState } from "react";
import { OrganizationContext } from "../../../../../../utils/Context/OrganizationContext";
import { useUIContext } from "@utils/Context/UIContext";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import { eventType } from "../../../../../Notifications/enums/eventType";

const RolePreviewToast = ({ item }) => {
  const context = useContext(OrganizationContext);
  const { updateToast, clearToasts } = useUIContext();

  const history = useHistory();

  const [roleId, setRoleId] = useState(null);

  useEffect(() => {
    setRoleId(item?.id);
  }, [item]);

  const cancelPreview = () => {
    context.resetRoleConfig();

    updateToast({
      toastId: "role-preview-toast",
      icon: "success",
      header: "Role Preview",
      body: "Role Preview Cancelled",
      isPermanent: false,
      isOnScreen: false,
    });

    clearToasts(eventType.ACTIVITY);

    redirect();
  };

  const redirect = () => {
    history.push(`/organization_manager/roles/${roleId}`);
  };

  return (
    <Grid container justifyContent={"center"} alignContent={"center"}>
      <Grid item>
        <Button color={"warning"} style={{ width: "100%" }} onClick={cancelPreview}>
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
};

export default RolePreviewToast;
