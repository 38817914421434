import { getEvidenceNonStandardRiskControlLink } from "./getEvidenceNonStandardRiskControlLink";
import { generateGraphql } from "@rivial-security/generategraphql";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";

/**
 * Create a 3 way link between evidence, risk control and system
 * @param {object} evidence - the evidence data
 * @param {object} item - the risk control data including system
 * @return {Promise<void>}
 */
export const linkEvidenceNonStandardRiskControl = async ({ evidence, item } = {}) => {
  //Check arguments return if not truthy
  if (!evidence || !item) {
    return;
  }

  //Get an existing link if it exists
  const existingLink = await getEvidenceNonStandardRiskControlLink({
    evidence,
    riskControlId: item?.control?.id,
    systemId: item?.system?.id,
  });

  // If there is no link found create a new link
  if (!existingLink) {
    const { createMutation } = generateGraphql("RiskControlEvidenceLink");

    //Check all ids to be present
    if (!evidence?.id || !item?.control?.id || !item?.system?.id) {
      throw Error(
        `Evidence, control, and system must all be present to create a link - ${JSON.stringify({
          riskControlID: item?.control?.id,
          evidenceID: evidence?.id,
          systemID: item?.system?.id,
        })}`,
      );
    }

    //Create the link
    await ItemMutation(createMutation, {
      riskControlID: item?.control?.id,
      evidenceID: evidence?.id,
      systemID: item?.system?.id,
      ownerGroup: evidence?.ownerGroup || item?.control?.ownerGroup || item?.system?.ownerGroup,
    });
  }
};
