import { EVIDENCE_TYPES } from "../../../../../../typedefs/Compliance/Evidence/Evidence";
import DocumentEvidenceForm from "../components/DocumentEvidenceForm";
import ReportEvidenceForm from "../components/ReportEvidenceForm";
import MeetingEvidenceForm from "../components/MeetingEvidenceForm";
import AttestationForm from "../components/AttestationForm";
import React from "react";
import PolicyEvidenceForm from "../components/PolicyEvidenceForm";
import TrainingEvidenceForm from "../components/TrainingEvidenceForm";
import PhishingEvidenceForm from "../components/PhishingEvidenceForm";
import ExternalUrlEvidenceForm from "../components/ExternalUrlEvidenceForm";

/**
 * Displays the correct Evidence Response form for the landing page based on evidenceType
 * @param {function} onSubmitResponse - submit function for the response
 * @param {Evidence} evidence - the evidence that this response is for
 * @param {EVIDENCE_TYPES} evidenceType - the type of evidence response
 * @param {string} organizationID - selected organization ID
 * @param {boolean} isGuest - if TRUE will adjust forms according to unauthenticated user access
 * @returns {JSX.Element}
 */
export const displayEvidenceResponseForm = ({
  onSubmitResponse,
  evidence,
  evidenceType,
  organizationID,
  isGuest = false,
}) => {
  switch (evidenceType) {
    case EVIDENCE_TYPES.DOCUMENT:
      return (
        <DocumentEvidenceForm
          organizationID={organizationID}
          onSubmit={onSubmitResponse}
          evidence={evidence}
          isGuest={isGuest}
        />
      );
    case EVIDENCE_TYPES.REPORT:
      return <ReportEvidenceForm organizationID={organizationID} onSubmit={onSubmitResponse} />;
    case EVIDENCE_TYPES.MEETING:
      return <MeetingEvidenceForm organizationID={organizationID} onSubmit={onSubmitResponse} />;
    case EVIDENCE_TYPES.ATTESTATION:
      return <AttestationForm onSubmit={onSubmitResponse} />;
    case EVIDENCE_TYPES.POLICY:
      return <PolicyEvidenceForm onSubmit={onSubmitResponse} />;
    case EVIDENCE_TYPES.TRAINING:
      return <TrainingEvidenceForm onSubmit={onSubmitResponse} />;
    case EVIDENCE_TYPES.PHISHING:
      return <PhishingEvidenceForm onSubmit={onSubmitResponse} />;
    case EVIDENCE_TYPES.EXTERNAL_URL:
      return <ExternalUrlEvidenceForm onSubmit={onSubmitResponse} />;
    default:
      return (
        <DocumentEvidenceForm
          organizationID={organizationID}
          onSubmit={onSubmitResponse}
          evidence={evidence}
          isGuest={isGuest}
        />
      );
  }
};
