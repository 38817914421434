import { useCustomDashboardStyles } from "../hooks/useCustomDashboardStyles";
import Stack from "@mui/material/Stack";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import React from "react";

/**
 * Displays the buttons for a Custom Dashboard Widget
 *
 * TODO: implement Duplicate and Edit widget buttons RISC-3558
 *
 * @param {string} item - the widget item
 * @param {function} onRemoveWidget - the function to call when the remove button is clicked
 * @param {string} className - the class name to apply to the buttons
 * @returns {JSX.Element}
 * @constructor
 */
const CustomDashboardWidgetButtons = ({ item, onRemoveWidget, className }) => {
  const classes = useCustomDashboardStyles();
  return (
    <Stack
      spacing={2}
      direction={"row"}
      style={{
        position: "absolute",
        right: 0,
        backgroundColor: "white",
      }}
    >
      {/* Remove Widget Button */}
      <RemoveCircleOutlineIcon
        onClick={() => {
          onRemoveWidget(item?.i);
        }}
        color={"error"}
        className={`${classes.customCursor} ${className}`}
        style={{ zIndex: 1000, position: "relative", right: "5px" }}
      />
    </Stack>
  );
};

export default CustomDashboardWidgetButtons;
