import React, { useEffect, useState } from "react";
import Data from "../../Metrics/customFields/Data";
import { useTable } from "../../../../hooks/views/useTable";
import DateField from "../../../../utils/CustomFields/DateField";
import { deleteMetric } from "../../Metrics/functions/deleteMetric";
import MetricDetails from "../../Metrics/components/MetricDetails";
import MetricsDataGrid from "../../Metrics/components/MetricDataGrid";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * Displays a list of Metrics. Used as a CustomField for MetricTypes
 *
 * @author Jacob Blazina
 * @class
 * @param {object} item - The MetricType parent
 * @param {object[]} metrics - The array of "Metric" objects
 * @param {string} [view="simple"] - Shows either a single metric or the full list
 * @param resetFunction
 * @returns {*}
 * @constructor
 */
const Metrics = ({ item, view = "simple", resetFunction }) => {
  const metrics = item?.metrics;
  const [data, setData] = useState([]);

  useEffect(() => {
    if (metrics && metrics.items && Array.isArray(metrics.items)) {
      const d = [...metrics.items];
      d.sort((a, b) => new Date(b.date) - new Date(a.date));

      setData([...d]);
    }
  }, [metrics]);

  const table = useTable({
    resetFunction: resetFunction,
    detailsComponent: view !== "simple" && <MetricDetails />,
    deleteFunction: deleteMetric,
    options: view !== "simple" ? ["delete"] : [],
    hideHeader: view === "simple" ? true : false,
    data,
    fields: ["date", "data"],
    customFields: [
      {
        field: "data",
        component: <Data />,
      },
      {
        field: "date",
        component: <DateField trimTime={true} fieldName={"date"} />,
      },
    ],
    disableRoleChecking: true,
    config: {
      bordered: true,
    },
  });

  useEffect(() => {
    if (data && Array.isArray(data)) {
      if (view === "simple") {
        const d = [];
        data[0] && d.push(data[0]);
        table.setData([...d]);
      } else if (view === "full") {
        table.setData([...data]);
      }
    }
  }, [data]);

  // If simple view, just display the most recent metric
  if (view === "simple") {
    return table.display;
  }
  // If full view, display all metrics in a datagrid
  else {
    return (
      <div
        style={{
          height: "60vh",
        }}
      >
        <MetricsDataGrid item={item} />
      </div>
    );
  }
};

export default withOrganizationCheck(Metrics);
