import React, { useContext, useEffect, useState } from "react";
import { withOrganizationCheck } from "@utils/Context/withOrganizationCheck";
import { VulnerabilityStatus } from "../../Vulnerabilities/constants/VulnerabilityStatus";
import DataLoader from "../../../../utils/LoadingComponents/DataLoader";
import { generateGraphql } from "@rivial-security/generategraphql";
import { ListQueryBy } from "@rivial-security/appsync-utils";
import { cloneDeep } from "lodash";
import { convertTargetVulnerabilityStatusCountsToVulnerabilityStatus } from "../../Vulnerabilities/functions/convertTargetVulnerabilityStatusCountsToVulnerabilityStatus";
import { formattedPercent, isNonEmptyArray } from "@rivial-security/func-utils";
import TestingTotalsChart from "./TestingTotalsChart";
import { normalizeVulnerabilityData } from "../../Vulnerabilities/functions/normalizeVulnerabilityData";
import { VULNERABILITY_SEVERITY_LEVEL } from "../../../../typedefs/Testing/Vulnerability/Vulnerability";
import { OrganizationContext } from "@utils/Context/OrganizationContext";

/**
 * Display findings totals as a pi chart
 * @param {object[]} vulnerabilitiesExternal - array of vulnerabilities to use instead of performing a query
 * @param {string} organizationID - selected organization id
 * @param {boolean} disableAnimations - disable chart animation
 * @param {boolean} isLoading - TRUE if data is loading and should display loading components
 * @returns {JSX.Element}
 */
const VulnerabilityStatusTotalsChart = ({
  vulnerabilities: vulnerabilitiesExternal,
  organizationID,
  disableAnimations = false,
  isLoading = false,
}) => {
  const context = useContext(OrganizationContext);

  const staticGraphData = [
    {
      type: VulnerabilityStatus.UNKNOWN,
      x: "Unknown",
      y: 0,
      fill: "rgb(18,122,234)",
    },
    {
      type: VulnerabilityStatus.NOT_FIXED,
      x: "Not Fixed",
      y: 0,
      fill: "rgb(255,0,0)",
    },
    {
      type: VulnerabilityStatus.PARTIALLY_FIXED,
      x: "Partially Fixed",
      y: 0,
      fill: "hsl(24,97%,57%)",
    },
    {
      type: VulnerabilityStatus.MOSTLY_FIXED,
      x: "Mostly Fixed",
      y: 0,
      fill: "rgb(255,212,0)",
    },
    {
      type: VulnerabilityStatus.FIXED,
      x: "Fixed",
      y: 0,
      fill: "rgb(0,198,89)",
    },
  ];
  const [graphData, setGraphData] = useState(null);

  const getVulnerabilityTotals = async () => {
    let vulnerabilities = vulnerabilitiesExternal;
    if (!vulnerabilities) {
      vulnerabilities = await ListQueryBy({
        query: listVulnerabilitiesByOwnerGroup,
        limit: 1000,
        variables: { ownerGroup: organizationID },
        normalizeData: (data) => {
          return normalizeVulnerabilityData({
            vulnerabilities: data,
            calculatePriority: true,
          });
        },
      });
    }

    if (!context?.preferences?.testing?.showInfoVulnerabilities) {
      vulnerabilities = vulnerabilities.filter((vulnerability) => {
        return vulnerability?.severityLevel !== VULNERABILITY_SEVERITY_LEVEL.INFO;
      });
    }
    const vulnerabilityCounts = Array.isArray(vulnerabilities) ? vulnerabilities.length : 0;
    const vulnerabilityStatusCounts = {
      [VulnerabilityStatus.UNKNOWN]: 0,
      [VulnerabilityStatus.NOT_FIXED]: 0,
      [VulnerabilityStatus.PARTIALLY_FIXED]: 0,
      [VulnerabilityStatus.MOSTLY_FIXED]: 0,
      [VulnerabilityStatus.FIXED]: 0,
    };

    const newGraphData = cloneDeep(staticGraphData);
    if (isNonEmptyArray(vulnerabilities)) {
      for (const vulnerability of vulnerabilities) {
        const statusCounts = vulnerability?.statusCounts;
        if (statusCounts) {
          const { vulnerabilityStatus } = convertTargetVulnerabilityStatusCountsToVulnerabilityStatus(vulnerability);
          vulnerabilityStatusCounts[vulnerabilityStatus] += 1;
        }
      }

      for (const vulnerabilityStatus in vulnerabilityStatusCounts) {
        const foundGraphDataEntry = newGraphData.find((entry) => {
          return entry?.type === vulnerabilityStatus;
        });
        if (foundGraphDataEntry) {
          foundGraphDataEntry.y = vulnerabilityStatusCounts[vulnerabilityStatus];
          foundGraphDataEntry.text = formattedPercent(
            vulnerabilityStatusCounts[vulnerabilityStatus] / vulnerabilityCounts,
            0,
          );
        }
      }
    }

    //Remove any graph entries that are have value of 0
    for (let i = newGraphData.length - 1; i >= 0; i--) {
      const graphEntry = newGraphData[i];
      if (graphEntry?.y === 0) {
        newGraphData.splice(i, 1);
      }
    }
    setGraphData(newGraphData);
  };

  useEffect(() => {
    getVulnerabilityTotals();
  }, [vulnerabilitiesExternal]);

  return (
    <DataLoader isEnoughData={true} isLoading={isLoading}>
      <TestingTotalsChart data={graphData} disableAnimations={disableAnimations} />
    </DataLoader>
  );
};

const { listByQuery: listVulnerabilitiesByOwnerGroup } = generateGraphql(
  "Vulnerability",
  ["name", "severityLevel", "targets"],
  {
    targets: `(limit: 1000) {
      items {
        id
        status
      }
    }`,
  },
  {
    indexName: "vulnerabilitiesByOwnerGroup",
    partitionKey: "ownerGroup",
  },
);

export default withOrganizationCheck(VulnerabilityStatusTotalsChart);
