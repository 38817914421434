import { removeNonStandardCharactersFromString } from "@rivial-security/func-utils";
import React from "react";

export interface ProcessTextPastedIntoInput<T = string> {
  e: React.ClipboardEvent<HTMLInputElement>;
  value?: T;
}

/**
 * Allows to paste in text values stripping out any null characters that may have been added to the clipboard
 */
export const processTextPastedIntoInput = ({ e, value }: ProcessTextPastedIntoInput): string => {
  //When no value is provided assume empty input
  if (!value) {
    value = "";
  }

  //Prevent paste action and get the content that's pasted in
  e.preventDefault();
  const pastedText = e.clipboardData.getData("text/plain");

  //Verify target is an input
  const target = e.target as HTMLInputElement;

  //Add pasted text to cursor position, replace content as necessary
  const start = target.selectionStart ?? 0;
  const end = target.selectionEnd ?? 0;

  let newText = value?.substring(0, start) + pastedText + value?.substring(end);

  newText = removeNonStandardCharactersFromString({
    stringContent: newText,
    removeNullCharacters: true,
  });

  //Set the selection to the end of the pasted text
  target.value = newText;
  target.selectionStart = target.selectionEnd = (start ?? 0) + pastedText.length;
  return newText;
};
