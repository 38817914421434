import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { dequal } from "dequal";
import PropTypes from "prop-types";
import React from "react";

import Context from "./context";

/**
 * @description The props of the Field component.
 * @type {React.NamedExoticComponent<object>}
 */
const Field = React.memo(
  (props) => {
    const context = React.useContext(Context);
    const { field, id, testId, readonly } = props;

    const { dispatch, filtersByValue, flattenedFilters, operatorsByType, freeSoloFieldInput } = context;
    const filter = field ? filtersByValue[field] : null;

    if (freeSoloFieldInput) {
      return (
        <TextField
          fullWidth
          data-testid={`field-${testId}`}
          size={"small"}
          value={field}
          onChange={(event) => {
            dispatch({
              type: "set-field",
              id,
              isFreeSolo: freeSoloFieldInput,
              value: event?.target?.value,
            });
          }}
        />
      );
    }

    return (
      <Autocomplete
        size={"small"}
        fullWidth
        data-testid={`field-${testId}`}
        disableClearable={true}
        groupBy={(option) => option.group}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => {
          return option.value === value.value;
        }}
        options={flattenedFilters}
        renderInput={(params) => <TextField {...params} placeholder="Field" size="small" variant="outlined" />}
        value={filter}
        onChange={(event, selected) => {
          const value = selected ? selected.value : null;
          const { type } = filtersByValue[value];
          const operators = operatorsByType[type];
          const operator = operators?.length > 0 ? operators[0].value : null;
          dispatch({ type: "set-field", id, operator, value });
        }}
        disabled={readonly}
      />
    );
  },
  (prevProps, nextProps) => {
    // Skip re-rendering if the field didn't change.
    return dequal(prevProps, nextProps);
  },
);

Field.propTypes = {
  field: PropTypes.string,
  id: PropTypes.number.isRequired,
  testId: PropTypes.string.isRequired,
  readonly: PropTypes.bool,
};

Field.whyDidYouRender = false;

export default Field;
