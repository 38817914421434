import { gridOnRefresh } from "./gridOnRefresh";
import { gridContextMenuItems } from "./gridContextMenuItems";
import { gridContextMenuClick } from "./gridContextMenuClick";
import { gridToolbarOptionsInit } from "./gridToolbarOptionsInit";
import { gridHandleToolbarClick } from "./gridHandleToolbarClick";
import { gridHandleCustomField } from "./gridHandleCustomField";
import { gridActionHandler } from "./gridActionHandler";
import { gridRowSelecting } from "./gridRowSelecting";
import { gridCheckAriaLabel } from "./gridCheckAriaLabel";
import { gridUpdateItemById } from "./gridUpdateItemById";
import { gridRowSelected } from "./gridRowSelected";
import { gridOnCreate } from "./gridOnCreate";
import { updateSearchColumn } from "./updateSearchColumn";

/**
 * @description Interface for Syncfusion Grid
 * @param {boolean} allowFiltering - if allowFiltering set to true the filter bar will be displayed. If set to false
 * the filter bar will not be displayed. Filter bar allows the user to filter grid records with required criteria.
 * @param {boolean} allowGrouping - if allowGrouping set to true, then it will allow the user to dynamically group or
 * ungroup columns. Grouping can be done by drag and drop columns from column header to group drop area.
 * @param {boolean} allowPaging - If allowPaging is set to true, the pager renders at the footer of the Grid. It is used to handle page navigation in the Grid.
 * @param {boolean} allowTextWrap - if allowTextWrap set to true, then text content will wrap to the next line when
 * its text content exceeds the width of the Column Cells.
 * @param {boolean} allowKeyboard - enables or disables the key board interaction of Grid
 * @param {function} checkPermissionsHook - checks permissions for the grid component
 * @param {object} contextMenuConfig - configurations for the context menu
 * @param organizationContext
 * @param {object[]} contextMenuConfig.items - extra items to add to the context menu
 * @param {string} contextMenuConfig.items[].text - text for context menu button
 * @param {string} contextMenuConfig.items[].iconCss - icon css for context menu button
 * @param {function} contextMenuConfig.items[].onClick - onClick handler for context menu button
 * @param {object} createItemModal - modal for creating a new item
 * @param {JSX} createResourceComponent - create item component
 * @param {object[]} data - array of objects, data for the grid
 * @param {string} deleteButtonText - alternate text to display instead of 'Delete'
 * @param {string} deleteButtonIcon - alternate delete icon
 * @param {function} deleteFunction - function for deleting an item
 * @param {string} deleteGqlQuery - graphql query for deleting an item. NOTE: should contain "__typename" field
 * @param {string} deleteMessage - message to display when deleting an item
 * @param {string} deleteMutation - graphql mutation for deleting an item.
 * @param {JSX} detailsBar - Get details bar for UI context
 * @param {JSX} detailsComponent - jsx component for displaying an item
 * @param {JSX} detailsModal - modal component for displaying details of an object
 * @param {DETAILS_TYPES} detailsType - details component display style
 * @param {object} disableUpdate - used to prevent the details pane from updating when it doesn't need to.
 * @param duplicateItemsHook
 * @param duplicationSettings
 * @param {object} editSettings - configures the edit settings
 * @param {boolean} enableColumnChooser - display column chooser for the grid
 * @param {boolean} enableContextMenu - enable context menu for the grid
 * @param {function} contextMenuOpen - fired when the sync fusion context menu has been shown
 * @param {boolean} enableEdit - enable edit functionality for the grid
 * @param {boolean} enablePers - temporarily enable persistence to reset the grid
 * @param {boolean} enableSearch - display search bar for the grid
 * @param {object[]} fields - mapping configuration for a field in the grid with passed data
 * @param {object} filterSettings - configures the filtering behavior of the Grid.
 * @param {function} getGridID - The unique ID for this grid component
 * @param {string} gridHeight - change grid height
 * @param {object} groupSettings - object which configures the group behavior of the grid
 * @param {object} lastSelectedItem - last selected item from the grid
 * @param {object[]} mappedFields - update the column headers to display fieldNames better
 * @param {function} onToolbarClick - function triggers on tool bar click
 * @param {object} pageSettings - configures the paging behavior of the grid.
 * @param {string} persistenceUUID - unique grid identifier in UUID format
 * @param {object} ref - reference of the the grid component
 * @param {function} rightClick - if right clicking on a row, allows selection but disables opening the details panel
 * @param {string} route - navigation route
 * @param {object[]} selectedItems - array of selected items in the grid
 * @param {object} selectionSettings - configures the selection behavior of the grid
 * @param {function} setEnablePers - sets value for disable persistence to reset the grid
 * @param {function} setGroupSettings - sets the group settings of the grid
 * @param {function} setLastSelectedItem - sets last selected item in the grid
 * @param {function} setRef - sets the reference of the the grid component
 * @param {function} setSelectedItems - sets the selected array of items
 * @param {number} gridScrollPosition - the location of the scroll bar for the grid when its rendered
 * @param {number} dataBoundCount - number of times the grid's databound has been fired
 * @param {function} setDataBoundCount - state change function for dataBoundCount
 * @param {object} sortSettings - object with settings for sorting grid data
 * @param {object} textWrapSettings - configures the text wrap settings of the grid
 * @param {object[]} toolbarOptions - array of objects for the grid toolbar
 * @param {string} title - specify title for the grid
 * @param {boolean} enableCheckBoxes - TRUE if need to display checkboxes that are checked when a row is selected
 * @param {boolean} isCreated - TRUE when the grid is finished rendering
 * @param {function} setIsCreated - state control function for the 'isCreated' variable
 * @param {boolean} isLoading - TRUE when the grid is collecting data to show, sometimes set manually outside of the grid
 * @param {object[]} itemsToCheck - when items are added to this array the grid logic attempts to select them if they are present
 * @param {function} setItemsToCheck - state control function for the 'itemsToCheck' variable
 * @param {function} actionComplete - function callback for controlling the grid after a certain action was finished,
 * one example is for hiding filter menu UI when a column filter menu is opened
 * @param {boolean} isMobile - passed down from UI context to change settings based on mobile view
 * @param {function} destroyed - called on the end of the grid life cycle
 * @return {object} {{allowPaging: boolean, allowTextWrap, filterSettings, dataBound: (function(): void), allowPdfExport: boolean, textWrapSettings, selectionSettings, showColumnChooser, allowSorting: boolean, ref: (function(*=): *), rowDeselecting: (function(*): void), contextMenuClick: (function(*): void), allowFiltering, groupSettings, allowExcelExport: boolean, id, pageSettings, allowCsvExport: boolean, height, editSettings, queryCellInfo: (function(*): void), sortSettings, allowGrouping, actionComplete: (function(*): void), toolbar: *[], destroyed: (function(): void), allowResizing: boolean, enablePersistence: (*|boolean), contextMenuItems: [], toolbarClick: (function(*): void), name: string, dataSource, rowSelecting: (function(*): void)}}
 */
export const gridGetProperties = ({
  allowFiltering,
  allowGrouping,
  allowPaging,
  allowTextWrap,
  allowKeyboard,
  checkPermissionsHook,
  contextMenuConfig,
  organizationContext,
  createItemModal,
  createResourceComponent,
  data,
  deleteButtonText = "Delete",
  deleteButtonIcon,
  deleteFunction,
  deleteGqlQuery,
  deleteMessage,
  deleteMutation,
  deleteConfirmModal,
  detailsBar,
  detailsComponent,
  detailsModal,
  detailsType,
  disableUpdate,
  duplicateItemsHook,
  duplicationSettings,
  editSettings,
  enableColumnChooser,
  enableContextMenu,
  contextMenuOpen,
  enableEdit,
  enablePers,
  enableSearch,
  fields,
  filterSettings,
  getGridID,
  gridHeight,
  groupSettings,
  lastSelectedItem,
  mappedFields,
  onToolbarClick,
  pageSettings,
  persistenceUUID,
  ref,
  rightClick,
  route,
  selectedItems,
  selectionSettings,
  setEnablePers,
  setGroupSettings,
  setLastSelectedItem,
  setRef,
  setSelectedItems,
  gridScrollPosition,
  dataBoundCount,
  setDataBoundCount,
  sortSettings,
  textWrapSettings,
  toolbarOptions,
  title,
  enableCheckBoxes,
  isCreated,
  setIsCreated,
  isLoading,
  itemsToCheck,
  setItemsToCheck,
  actionComplete,
  isMobile,
  destroyed,
}) => {
  /**
   * gridRowSelecting called in 2 places so extracted it for not duplicating the code
   * @param {object} args - object with various properties for gridRowSelecting function
   */
  const rowSelecting = (args) =>
    gridRowSelecting({
      args,
      checkAriaLabel: (target) => gridCheckAriaLabel({ target, fields }),
      setSelectedItems,
      detailsModal,
      rightClick,
      detailsComponent,
      updateItemById: (item) => gridUpdateItemById({ item, ref, disableUpdate }),
      disableUpdate,
      setLastSelectedItem,
      detailsType,
      detailsBar,
    });

  return {
    created: () => {
      setIsCreated(true);
      gridOnCreate({
        ref,
        itemsToCheck,
        setItemsToCheck,
        enableCheckBoxes,
        isLoading,
      });
    },

    // Performed on every shown data update
    dataBound: () => {
      // Increment the number of times data has been updated in the grid
      //setDataBoundCount(dataBoundCount => dataBoundCount + 1);  <--- this causing the column chooser to reset the state to previous on the first render
      // Perform grid refresh actions
      gridOnRefresh({
        ref,
        lastSelectedItem,
        disableUpdate,
        groupSettings,
        gridScrollPosition,
        enableCheckBoxes,
        itemsToCheck,
        setItemsToCheck,
        selectedItems,
        isLoading,
        isCreated,
        dataBoundCount,
      });

      // Perform row search value update
      updateSearchColumn({ ref, data, fields });
    },

    id: getGridID(),
    name: `grid${title}`,
    height: gridHeight,
    dataSource: data,
    contextMenuItems: gridContextMenuItems({
      enableContextMenu,
      deleteGqlQuery,
      deleteFunction,
      deleteMutation,
      checkPermissionsHook,
      contextMenuConfig,
      duplicationSettings,
      isMobile,
      deleteButtonText,
      deleteButtonIcon,
    }),
    contextMenuOpen,
    contextMenuClick: (args) =>
      gridContextMenuClick({
        args,
        ref,
        fields,
        route,
        selectedItems,
        lastSelectedItem,
        setSelectedItems,
        deleteFunction,
        deleteMessage,
        contextMenuConfig,
        duplicateItemsHook,
        duplicationSettings,
        deleteButtonText,
        deleteConfirmModal,
      }),

    pageSettings: pageSettings,
    sortSettings: sortSettings,
    allowSorting: true,
    allowResizing: true,
    allowFiltering: allowFiltering,
    allowKeyboard: allowKeyboard,
    filterSettings: filterSettings,
    allowGrouping: allowGrouping,
    groupSettings: groupSettings,
    toolbar: [
      ...toolbarOptions,
      ...gridToolbarOptionsInit({
        enableEdit,
        createResourceComponent,
        enableSearch,
        enableColumnChooser,
        checkPermissionsHook,
        deleteButtonText,
      }),
    ],
    ref: (r) => setRef(r),
    toolbarClick: (args) => gridHandleToolbarClick({ args, onToolbarClick }),
    queryCellInfo: (args) => gridHandleCustomField({ args, mappedFields }),
    actionComplete: (args) => gridActionHandler({ ref, args, setGroupSettings, actionComplete }),
    rowSelecting: rowSelecting,
    rowDeselecting: rowSelecting,
    rowSelected: (args) => {
      gridRowSelected({
        args,
        detailsBar,
        rightClick,
        detailsComponent,
        disableUpdate,
        detailsModal,
        detailsType,
        setLastSelectedItem,
        setSelectedItems,
        updateItemById: (item) => gridUpdateItemById({ item, ref, disableUpdate }),
      });
    },
    allowExcelExport: true,
    allowPdfExport: true,
    allowCsvExport: true,
    selectionSettings: selectionSettings,
    allowTextWrap: allowTextWrap,
    textWrapSettings: textWrapSettings,
    showColumnChooser: enableColumnChooser,
    editSettings: editSettings,
    enableImmutableMode: true,
    destroyed: () => {
      destroyed && destroyed();
    },
    allowPaging: allowPaging,
  };
};
