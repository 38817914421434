import { useDataGrid } from "@hooks/views/useDataGrid/useDataGrid";
import { modules, resources } from "@rivial-security/role-utils";
import CertificationName from "@views/Vendor/VendorCertification/components/CertificationName";
import { DataGridResult } from "@hooks/views/useDataGrid/types";
import { VendorDocument } from "@rivial-security/schema-types";
import { getFileDataGridFields } from "@hooks/views/useDocumentGrid/functions/getFileDataGridFields";
import { CISSP_ORGANIZATION_ID } from "@views/AdminPanel/CisspReviews/constants/CISSP_ORGANIZATION_ID";
import { UploadVendorDocument } from "@views/Vendor/VendorDocuments/components/UploadVendorDocument";
import { deleteVendorDocument } from "@views/Vendor/VendorDocuments/functions/deleteVendorDocument";

export interface UseVendorDocumentDataGridProps {
  organizationID: string;
  queryConfig?: Record<string, unknown>;
  gridConfig?: Record<string, unknown>;
  module?: string;
  resource?: string;
  disableRoleChecking?: boolean;
  connectionInput?: Record<string, unknown>;
  onSubmit?: (documents: VendorDocument[]) => Promise<void>;
  resetFunction?: VoidFunction;
  isLoading?: boolean;
  props?: Record<string, unknown>;
}

export const useVendorDocumentDataGrid = ({
  organizationID,
  queryConfig = {},
  gridConfig = {},
  module = modules.VENDORS,
  resource = resources.VENDOR_DOCUMENT,
  disableRoleChecking = false,
  connectionInput,
  onSubmit,
  ...props
}: UseVendorDocumentDataGridProps): DataGridResult<VendorDocument> => {
  const roleConfig = {
    module,
    resource,
    disableRoleChecking,
  };

  queryConfig = {
    query: null,
    variables: {
      ownerGroup: organizationID,
    },
    ...queryConfig,
  };

  const isCISSPReview = organizationID === CISSP_ORGANIZATION_ID;

  const fields = [
    {
      name: "vendorCertification",
      friendlyName: "Pre-Qualified Certification",
      flex: 1,
      minWidth: 200,
      component: <CertificationName />,
    },
    {
      name: "name",
      flex: 1,
      minWidth: 200,
      sort: {
        direction: "asc",
        priority: 1,
      },
    },
    ...getFileDataGridFields(),
  ];

  gridConfig = {
    organizationID,
    typename: resources.VENDOR_DOCUMENT,
    fields,
    createResourceComponent: (
      <UploadVendorDocument
        organizationID={organizationID}
        isCISSPReview={isCISSPReview}
        connectionInput={connectionInput}
        onSubmit={onSubmit}
      />
    ),
    createResourceButtonText: "Upload",
    options: ["delete"],
    deleteFunction: deleteVendorDocument,
    persistenceUUID: "cfc3ae74-6032-4b69-a0a6-bf2156ba2704",
    disableRoleChecking,
    ...gridConfig,
  };

  return useDataGrid({
    ...roleConfig,
    ...queryConfig,
    ...gridConfig,
    ...props,
  }) as unknown as DataGridResult<VendorDocument>;
};
