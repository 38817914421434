import { RunDataGridQueryInput } from "./functions/types";
import { runDataGridGraphqlQuery } from "./functions/runDataGridGraphqlQuery";
import { runDataGridToolsQuery } from "./functions/runDataGridToolsQuery";

export const runDataGridQuery = async <T>({
  query,
  queryExternalApi,
  variables,
  filters,
  limit,
  normalizeData,
  normalizeRowData,
  setIntermediateData,
  organizationID,
  refreshCallback,
}: RunDataGridQueryInput<T>): Promise<T[] | undefined> => {
  // Use a custom refresh callback to determine if data should be refreshed internally or just to notify a parent of a refresh
  // NOTE: see useEvidenceDataGrid for example usage
  if (typeof refreshCallback === "function") {
    const shouldRefresh = await refreshCallback();
    if (!shouldRefresh) return;
  }

  if (query && organizationID) {
    return runDataGridGraphqlQuery<T>({
      filters,
      organizationID,
      query,
      variables,
      limit,
      normalizeData,
      normalizeRowData,
      setIntermediateData,
    });
  } else if (queryExternalApi) {
    return runDataGridToolsQuery<T>({
      queryExternalApi,
      organizationID,
      normalizeData,
    });
  }

  return undefined;
};
