import { useForm } from "../../../../hooks/views/useForm/useForm";
import { getCurrentDate } from "@rivial-security/func-utils";
import { useContext } from "react";
import { OrganizationContext } from "../../../../utils/Context/OrganizationContext";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useMutation } from "../../../../hooks/graphql/useMutation/useMutation";

export const useRiskChangeForm = ({
  item,
  typename = "Risk Change",
  organizationID,
  updateInputFunction,
  callback,
  resetFunction,
  toggleModal,
  getNewItem,
}) => {
  const context = useContext(OrganizationContext);

  const { createMutation: createRiskChange, updateMutation: updateRiskChange } = generateGraphql("RiskChange", [
    "name",
    "reason",
    "date",
    "changeOwner",
    "type",
  ]);

  const updateRiskChangeHook = useMutation({
    mutation: updateRiskChange,
    disableRoleChecking: true,
  });

  const submitFunction = async (input) => {
    delete input["riskControlLinks"];
    delete input["systemLinks"];

    return await updateRiskChangeHook.editItem({
      id: item && item.id,
      ...input,
    });
  };

  const formHook = useForm({
    typename,
    organizationID,
    getNewItem,
    mutation: createRiskChange,
    disableRoleChecking: true,
    updateInputFunction,
    callback,
    submitFunction: item ? submitFunction : undefined,
    fieldConfig: {
      name: {
        inputType: "text",
        label: "Name of Change",
        required: true,
        defaultValue: "",
        validationFunction: (input) => {
          if (input === "" || input === null || input === undefined) {
            return false;
          }
          if (input) {
            return input.length > 5;
          } else {
            return false;
          }
        },
        validationText: "Must be more than 5 characters",
      },
      description: {
        inputType: "textarea",
        label: "Description of Change",
        required: true,
      },
      date: {
        inputType: "date",
        label: "Date of Change",
        defaultValue: getCurrentDate(),
        tooltip: "When did this Change happen?",
      },
      userEmail: {
        inputType: "text",
        label: "Person Entering Change",
        defaultValue: context.userEmail,
        disabled: true,
      },
      changeOwner: {
        inputType: "text",
        label: "Change Owner",
        tooltip: "Who in the Organization is responsible for this Change?",
        required: true,
      },
      reason: {
        inputType: "textarea",
        label: "Reason for Change",
        tooltip: "Why did this Change occur?",
        required: true,
      },
    },
    resetFunction,
    toggleModal,
  });

  return {
    ...formHook,
  };
};
