import { ItemMutation } from "../../../../../../utils/Functions/Graphql/ItemMutation";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 * @description Archive a single Evidence Activity item from the database
 * NOTE: this function is archiving the evidence activity item, not deleting it
 * @param {object} item - evidence activity, grouped or ungrouped
 * @returns {Promise<null|*>}
 */
export const deleteEvidenceActivity = async (item) => {
  if (Array.isArray(item.activities)) {
    // in case this is a grouped activity item handle to delete recursively
    for (const activity of item.activities) {
      await deleteEvidenceActivity(activity);
    }
  } else {
    //Archive a single activity item
    const { updateMutation } = generateGraphql("EvidenceActivity");
    return await ItemMutation(updateMutation, { id: item?.id, archived: true });
  }
};
