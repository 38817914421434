import React from "react";
import { useSearchBar } from "../../../hooks/views/useSearchBar/useSearchBar";
import Grid from "@mui/material/Grid";
import SmartValueChip from "./SmartValueChip";

/**
 * Displays a list of clickable Chips for selecting suggested Smart Values
 *
 * Clicking on the Chip either auto-inserts the Smart Value into the input field or copies it to clipboard
 *
 * @param {object[]} smartValues - the smart values to display chips for
 * @constructor
 */
const SmartValueChips = ({ smartValues }) => {
  const searchBar = useSearchBar(
    smartValues.map((value) => ({ value })),
    ["value"],
  );

  return (
    <div className="smart-value-chips">
      <Grid container spacing={2}>
        <Grid item lg={12} xs={12}>
          {searchBar.display}
        </Grid>
        <Grid item lg={12} xs={12} container spacing={1}>
          {searchBar.searchResults.map(({ value }, index) => (
            <Grid item>
              <SmartValueChip key={`smart-value-${index}`} value={value} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default SmartValueChips;
