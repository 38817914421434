import { createWidgetActions } from "./createWidgetActions";
import { cloneDeep, get, set } from "lodash";
import { isNonEmptyArray, isObject } from "@rivial-security/func-utils";

/**
 * Action processing function for the  widget creation UI reducer
 * @param {object} state - the state of the reducer to modify by the action
 * @param {object} action - the action performed by a child UI
 * @returns {(*&{widget: (*&{config: (*&{series: (*|*[])})})})|*|(*&{widget: (*)})}
 */
export const createWidgetReducer = (state, action) => {
  const type = action?.type;
  if (type === createWidgetActions.UPDATE_WIDGET_INPUT) {
    return {
      ...state,
      widget: {
        ...state.widget,
        ...action.newWidget,
      },
    };
    //////////////////////////////////////////////////////////////////
  } else if (type === createWidgetActions.UPSERT_WIDGET_SERIES_INPUT) {
    const { seriesInput } = action || {};
    const seriesId = seriesInput?.id;

    if (!seriesId) {
      return state;
    }

    const existingSeries = cloneDeep(state.widget?.config?.series || []);
    const existingSeriesIndex = existingSeries?.findIndex((series) => series?.id === seriesId);

    if (existingSeriesIndex === -1) {
      existingSeries.push(seriesInput);
    } else {
      existingSeries[existingSeriesIndex] = seriesInput;
    }

    return {
      ...state,
      widget: {
        ...state.widget,
        config: {
          ...state.widget?.config,
          series: existingSeries,
        },
      },
    };
    //////////////////////////////////////////////////////////////////
  } else if (type === createWidgetActions.MERGE_WIDGET_CONFIG_INPUT) {
    const { mergeWidgetConfigInput } = action || {};
    if (!isObject(mergeWidgetConfigInput)) {
      return state;
    }

    return {
      ...state,
      widget: {
        ...state.widget,
        config: {
          ...state.widget?.config,
          ...mergeWidgetConfigInput,
        },
      },
    };
    //////////////////////////////////////////////////////////////////
  } else if (type === createWidgetActions.UPSERT_WIDGET_SOURCE_INPUT) {
    const { sourceInput } = action || {};
    const sourceId = sourceInput?.id;

    if (!sourceId) {
      return state;
    }

    const existingSources = cloneDeep(state.widget?.config?.sources || []);
    const existingSourceIndex = existingSources?.findIndex((source) => source?.id === sourceId);

    if (existingSourceIndex === -1) {
      existingSources.push(sourceInput);
    } else {
      existingSources[existingSourceIndex] = sourceInput;
    }

    return {
      ...state,
      widget: {
        ...state.widget,
        config: {
          ...state.widget?.config,
          sources: existingSources,
        },
      },
    };
    //////////////////////////////////////////////////////////////////
  } else if (type === createWidgetActions.MERGE_SERIES_FIELDS) {
    const { mergeSeriesInput = {} } = action || {};
    const { seriesId, path, value } = mergeSeriesInput || {};
    if (!seriesId || !path) {
      return state;
    }

    const existingSeries = cloneDeep(state.widget?.config?.series || []);
    const existingSeriesIndex = existingSeries?.findIndex((series) => series?.id === seriesId);

    if (existingSeriesIndex === -1) {
      return;
    }

    let newSeries = existingSeries[existingSeriesIndex];
    if (Array.isArray(path)) {
      if (path.length === 0) {
        newSeries = {
          ...newSeries,
          ...value,
        };
      } else {
        if (isObject(value)) {
          const currentObject = get(newSeries, path);
          newSeries = set(newSeries, path, {
            ...(currentObject || {}),
            ...value,
          });
        } else {
          set(newSeries, path, value);
        }
      }
    }
    existingSeries[existingSeriesIndex] = newSeries;

    return {
      ...state,
      widget: {
        ...state.widget,
        config: {
          ...state.widget?.config,
          series: existingSeries,
        },
      },
    };
    //////////////////////////////////////////////////////////////////
  } else if (type === createWidgetActions.DELETE_WIDGET_SERIES) {
    const { deleteSeriesInput = {} } = action || {};
    const { seriesItem } = deleteSeriesInput || {};
    const seriesId = seriesItem?.id;
    if (!seriesId) {
      return state;
    }

    const existingSeries = cloneDeep(state.widget?.config?.series || []);
    const existingSeriesIndex = existingSeries?.findIndex((series) => series?.id === seriesId);

    if (existingSeriesIndex === -1) {
      return state;
    }

    existingSeries.splice(existingSeriesIndex, 1);

    return {
      ...state,
      widget: {
        ...state.widget,
        config: {
          ...state.widget?.config,
          series: existingSeries,
        },
      },
    };
    //////////////////////////////////////////////////////////////////
  } else if (type === createWidgetActions.DELETE_WIDGET_SOURCE) {
    const { deleteSourceInput = {} } = action || {};
    const { source } = deleteSourceInput || {};
    const sourceId = source?.id;
    if (!sourceId) {
      return state;
    }

    //Delete the query from sources
    const existingSources = cloneDeep(state.widget?.config?.sources || []);
    const existingSourceIndex = existingSources?.findIndex((source) => source?.id === sourceId);

    if (existingSourceIndex === -1) {
      return state;
    }
    existingSources.splice(existingSourceIndex, 1);

    //Delete any series info that relies on the deleted source
    const existingSeries = cloneDeep(state.widget?.config?.series || []);
    if (isNonEmptyArray(existingSeries)) {
      for (const seriesItem of existingSeries) {
        const { customQueryId } = seriesItem || {};
        if (customQueryId === sourceId) {
          delete seriesItem.customQueryId;
          delete seriesItem.categoriesField;
          delete seriesItem.valuesField;
        }

        const { categories } = seriesItem || {};
        if (isNonEmptyArray(categories)) {
          for (const categoryItem of categories) {
            const { customQueryId } = categoryItem || {};
            if (customQueryId === sourceId) {
              delete categoryItem.customQueryId;
              delete categoryItem.valueField;
            }
          }
        }
      }
    }

    return {
      ...state,
      widget: {
        ...state.widget,
        config: {
          ...state.widget?.config,
          sources: existingSources,
          series: existingSeries,
        },
      },
    };

    //////////////////////////////////////////////////////////////////
  } else {
    return state;
  }
};
