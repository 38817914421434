import "./SendMeetingSummary.css";

import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import DatePicker from "react-datepicker";
import { Icon } from "@iconify/react";
import { MeetingSummaryContent } from "../../functions/generateMeetingSummaryContent";
import ReactDOMServer from "react-dom/server";
import { genericScheduleEmail } from "../../../../../utils/Functions/genericScheduleEmail";
import { isNonEmptyArray } from "@rivial-security/func-utils";
import { performToastOperation } from "../../../../../utils/Toasts/functions/toastOperation";
import { useCreateEmailTemplate } from "../../../../OrganizationManager/EmailTemplates/hooks/useCreateEmailTemplate";
import { useModal } from "../../../../../hooks/views/useModal";
import { useOrganizationContext } from "../../../../AdminPanel/Organizations/hooks/useOrganizationContext";
import { useUIContext } from "@utils/Context/UIContext";

/**
 * Send meeting summary to participants
 * @param {string} organizationID - selected organization
 * @param {object} item - meeting data
 * @returns {JSX.Element}
 */
const SendMeetingSummary = ({ organizationID, item }) => {
  const { userEmail } = useOrganizationContext();
  const { addToast, updateToast } = useUIContext();

  const [sendDate, setSendDate] = useState(new Date());
  const [emailItem, setEmailItem] = useState(false);
  const [emailContent, setEmailContent] = useState(null);
  const sendSummaryForm = useCreateEmailTemplate({
    organizationID,
    item: emailItem,
    contentHTML: emailContent,
    fields: ["recipients", "replyTo", "subject", "body"],
    enableTemplates: false,
    disableResetButton: true,
    submitButtonText: "Schedule Email",
    submitFunction: async (input) => {
      await performToastOperation({
        addToast,
        updateToast,
        operation: async () => {
          const emailEditor = sendSummaryForm?.emailEditor;
          const htmlBody = await emailEditor?.exportHtml();

          const result = await genericScheduleEmail({
            input: {
              isInitialEmail: true,
              initialEmail: {
                to: [...new Set(input?.recipients)],
                subject: input?.subject,
                htmlBody,
              },
              dueDate: sendDate?.toISOString(),
              ownerGroup: organizationID,
            },
          });

          const res = JSON.parse(result);

          if (res?.statusCode !== 200) {
            throw Error("Unable to schedule email");
          }
        },
        inProgressText: "Scheduling Meeting Summary Email...",
        failedText: "Unable to schedule Meeting Summary Email",
        successText: "Meeting Summary Email Scheduled!",
        iconColor: "success",
      });
      sendSummaryModal.setModalIsOpen(false);
    },
    footerPrefix: (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DatePicker
          className={"dp-full-width"}
          dateFormat={"MMMM d, yyyy h:mm aa"}
          placeholderText={"Select a Date and Time"}
          showTimeInput
          minDate={new Date()}
          maxDate={new Date().setMonth(new Date().getMonth() + 24)}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          selected={new Date(sendDate ? sendDate : new Date())}
          onChange={(date) => {
            setSendDate(new Date(date));
          }}
        />
      </div>
    ),
  });

  const sendSummaryModal = useModal("Send Meeting Summary", <div>{sendSummaryForm.display}</div>, null, {
    width: "80vw",
  });

  useEffect(() => {
    const view = ReactDOMServer.renderToStaticMarkup(<MeetingSummaryContent meeting={item} />);
    setEmailContent(view);

    const participantEmails = [];
    const meetingParticipants = item?.pointOfContacts?.items;
    if (isNonEmptyArray(meetingParticipants)) {
      for (const participant of meetingParticipants) {
        const pointOfContact = participant?.pointOfContact;
        if (pointOfContact?.email) {
          participantEmails.push(pointOfContact.email);
        }
      }
    }
    const subject = item?.name ? `New Meeting: ${item?.name}` : "Rivial Platform - Meeting Summary";

    const emailItem = {
      subject,
      recipients: participantEmails,
      replyTo: [userEmail],
    };
    setEmailItem(emailItem);
    sendSummaryForm.setInput(emailItem);
  }, [item]);

  return (
    <div>
      {sendSummaryModal.modal}
      <Button
        size="sm"
        title="Send a summary of this meeting to each participant"
        onClick={() => {
          sendSummaryModal.setModalIsOpen(true);
        }}
        startIcon={<Icon icon={"simple-line-icons:envolope-letter"} />}
      >
        Send Summary
      </Button>
      <div id={"email-template-content"} style={{ display: "none" }} />
    </div>
  );
};

export default SendMeetingSummary;
