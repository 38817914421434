import { InvalidParamError } from "../../../../analytics/CustomError";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Generates the Name string for a Vendor Review
 * @param {object} vendorSolution - the Vendor Solution that this Review is associated with
 * @returns {string}
 */
export const generateVendorReviewName = (vendorSolution) => {
  if (isNullOrUndefined(vendorSolution)) {
    throw InvalidParamError("vendorSolution", "cannot be null or undefined");
  }

  if (isNullOrUndefined(vendorSolution.name)) {
    throw InvalidParamError("vendorSolution.name", "cannot be null or undefined");
  }

  if (typeof vendorSolution.name !== "string") {
    throw InvalidParamError("vendorSolution.name", "must be a string");
  }

  return `Vendor Review for ${vendorSolution.name} (${new Date().toLocaleString()})`;
};
