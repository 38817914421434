import React, { useEffect } from "react";
import { modules, resources } from "@rivial-security/role-utils";

import CreateButton from "../../../../utils/GenericComponents/CreateButton";
import CreateMetricType from "../../MetricTypes/components/CreateMetricType";
import { convertOperator } from "@rivial-security/metric-utils";
import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useLogicBuilder } from "../../../../utils/LogicBuilder/hooks/useLogicBuilder";
import { useMetricType } from "../../MetricTypes/hooks/useMetricType";
import { useModal } from "../../../../hooks/views/useModal";

/**
 * @author Jacob Blazina
 * @function
 * @description Wrapper around the LogicBuilder hook. Creates a logical expression to evaluate KPI status based on metrics
 * @param {string} organizationID - required
 * @param {boolean} [readonly] - optional. shows the logic in a Read-Only state
 * @param {object} [metricType] - optional. a connected metricType to pre-fill the logicBuilder with
 * @returns {{readonly, getRule, display: *, columnData, rule, reset, setIsReadOnly, setColumnData}}
 */
export const useKPILogicBuilder = ({ organizationID, readonly, metricType }) => {
  const metricTypesHook = useMetricType({ organizationID });
  const logicBuilder = useLogicBuilder({ readonly });

  useEffect(() => {
    if (metricTypesHook.data) {
      const arr = [];
      for (const metricType of metricTypesHook.data) {
        arr.push({
          category: "Organization Metrics",
          field: metricType.id,
          label: metricType.name,
          type: metricType.format || "number",
        });
      }
      logicBuilder.setColumnData([...arr]);
      if (metricType) {
        logicBuilder.addRules && logicBuilder.addRules(convertLogicForLogicBuilder(metricType));
      }
    }
  }, [metricTypesHook.data]);

  const checkMetricTypePermissions = useCheckPermissions({
    module: modules.METRICS,
    resource: resources.METRIC_TYPE,
  });
  const createMetricTypeModal = useModal(
    "Create a new Metric Type",
    <CreateMetricType organizationID={organizationID} callback={() => metricTypesHook.reset()} />,
    <CreateButton typename="Metric Type" text="Create a new Metric Type" />,
  );

  const convertLogicForLogicBuilder = (metricType) => {
    const res = {
      condition: "and",
      rules: [],
    };
    if (metricType?.metricType && metricType?.logic) {
      const { operator, data } = metricType.logic;
      if (data) {
        const { value } = data;
        res.rules.push({
          label: metricType.metricType.name,
          field: metricType.metricType.id,
          operator: convertOperator(operator),
          type: "number",
          value: value,
        });
      }
    }
    return res;
  };

  const display = (
    <div style={{ width: "100%" }}>
      {logicBuilder.display}
      {checkMetricTypePermissions.resource.create && createMetricTypeModal.modalButton}
    </div>
  );

  return {
    ...logicBuilder,
    display,
  };
};
