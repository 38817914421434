import React, { useEffect } from "react";
import { useNonStandardControlsGrid } from "../../../../../RiskControls/hooks/useNonStandardControlsGrid";
import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import { generateGraphql } from "@rivial-security/generategraphql";
import { GetQuery } from "../../../../../../../utils/Functions/Graphql/GetQuery";
import { getDetailedRiskControlItem } from "../../../../../../Compliance/EvidenceRiskControlLinks/functions/getDetailedRiskControlItem";
import { listSystemRiskOverrides } from "../../../../../../Compliance/EvidenceRiskControlLinks/functions/listSystemRiskOverrides";
import TabCard from "../../../../../../../utils/GenericComponents/TabCard";
import { useSystemDataGrid } from "../../../../../Systems/hooks/useSystemDataGrid";
import SystemDetails from "../../../../../Systems/components/SystemDetails";
import RiskControlEvidenceList from "../../../../../Systems/components/SystemAccordion/ControlCategories/components/Controls/components/RiskControlEvidenceList";

/**
 * Displays lists of systems that are associated with a risk control (both standard and non-standard)
 * @param {string} organizationID - the organization identifier for the risk control
 * @param {object} riskControl - risk control for which to show systems (at least the id)
 * @return {JSX.Element}
 */
const RiskControlSystems = ({ organizationID, riskControl } = {}) => {
  const queryConfig = {
    query: null,
  };

  const gridConfig = {
    groupSettings: {
      columns: [],
      showDropArea: false,
    },
    detailsComponent: <SystemDetails organizationID={organizationID} />,
    route: "#/risk/systems/",
  };

  const fields = [
    {
      name: "risk",
      visible: false,
    },
    {
      name: "controlName",
      visible: false,
    },
    {
      name: "evidences",
      visible: true,
      component: <RiskControlEvidenceList riskControl={riskControl} isNonStandard={true} includeHeader={false} />,
    },
  ];

  const nonStandardControlsGrid = useNonStandardControlsGrid({
    organizationID,
    fieldOverrides: fields,
    ...queryConfig,
    ...gridConfig,
    riskControlID: riskControl?.id,
  });

  const standardSystemsGrid = useSystemDataGrid({
    organizationID,
    queryConfig,
  });

  //Query and set data in a use effect (if risk control doesn't have the system data)
  useEffect(() => {
    const getData = async () => {
      //Query for the data
      const { getQuery } = generateGraphql(
        "RiskControl",
        [
          "name",
          "statementNumber",
          "costOfControl",
          "implementationRating",
          "strengthRating",
          "controlCategory",
          "evidences",
        ],
        {
          controlCategory: `{
          id
          systems (limit: 250) {
            items {
              id
              system {
                id
                name
                description
                pointOfContact { id ownerGroup firstName lastName title email }
                hosting
                adminPointOfContact { id ownerGroup firstName lastName title email }
                tags (limit: 100) { items { __typename id tag { id name description fontColor backgroundColor } } },
                ownerGroup
                inherentRisk
                residualRisk
                riskRating
                greatestEnterpriseRisk
                greatestBusinessRisk
                greatestKeyRiskIndicator
              }
            }
          }
        }`,
          evidences: `(limit: 500) {
          nextToken
          items {
            id
            evidence {
              id
              name
              enabled
              status
            }
            system {
              id
              name
              description
              riskControlOverrides {
                enabledFields
                riskControlId
                costOfControl
                strengthRating
                implementationRating
                implementationDetails
              }
              inherentRisk
              residualRisk
              riskRating
              greatestEnterpriseRisk
              greatestBusinessRisk
              greatestKeyRiskIndicator
            }
          }
        }`,
        },
      );
      const item = await GetQuery({
        query: getQuery,
        variables: {
          id: riskControl?.id,
        },
      });

      let riskControlEvidenceLinks = item?.evidences?.items;

      //Filter out any links without a system
      riskControlEvidenceLinks = riskControlEvidenceLinks?.filter((link) => link?.system?.id);

      //For each link with a system create a new object with the system override data
      const evidenceData = {};
      const systemsEncountered = {};
      const dataNonStandard = [];
      riskControlEvidenceLinks?.forEach((link) => {
        if (link?.system?.id) {
          //Non standard control
          const systemWasEncountered = systemsEncountered.hasOwnProperty(link.system.id);
          if (systemWasEncountered) {
            evidenceData[`${link?.system?.id}|${link?.riskControl?.id}`].push(link?.evidence);
            return;
          } else {
            systemsEncountered[link.system.id] = true;
            evidenceData[`${link?.system?.id}|${link?.riskControl?.id}`] = [link?.evidence];
          }

          //Get overrides for the system
          let systemOverrides = listSystemRiskOverrides({
            system: link?.system,
          });

          //Filter out any system overrides that don't have the risk control id
          systemOverrides = systemOverrides?.filter((override) => override?.riskControlId === riskControl?.id);

          //Check there is at least one override for the risk control
          if (systemOverrides.length < 1) {
            return;
          }

          //If found the correct override then use its data in the grid
          dataNonStandard.push({
            ...getDetailedRiskControlItem({
              standardRiskControl: riskControl,
              riskControlOverride: systemOverrides?.[0],
              evidences: evidenceData[`${link?.system?.id}|${link?.riskControl?.id}`] ?? [],
            }),
            id: link?.system?.id,
          });
        }
      });

      //Find all systems with the risk control but no overrides for it
      const dataStandard = [];
      // - get all systems that the control is attached to
      const controlSystemLinks = item?.controlCategory?.systems?.items;
      if (Array.isArray(controlSystemLinks)) {
        for (const link of controlSystemLinks) {
          // - get the system
          const system = link?.system;
          // - check that the system has not been added to the non-standard list, if not added it to the standard list
          if (!systemsEncountered.hasOwnProperty(system?.id)) {
            dataStandard.push(system);
          }
        }
      }

      //Set the data to the grids
      nonStandardControlsGrid.setData(dataNonStandard);
      standardSystemsGrid.setData(dataStandard);
    };

    getData();
  }, [riskControl?.systems]);

  return (
    <TabCard
      items={[
        {
          title: "Standard",
          total: (standardSystemsGrid?.unfilteredData ?? []).length,
          component: <div style={{ height: "30em" }}> {standardSystemsGrid.gridDisplay}</div>,
          icon: <i className={"icon-energy"} />,
        },
        {
          title: "Non-Standard",
          total: (nonStandardControlsGrid?.data ?? []).length,
          component: nonStandardControlsGrid.gridDisplay,
          icon: <i className={"icon-vector"} />,
        },
      ]}
      badgeColor={"primary"}
    />
  );
};

export default withOrganizationCheck(RiskControlSystems);
