"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWidgetType = void 0;
const widgetType_1 = require("../../constants/widgetType");
const func_utils_1 = require("@rivial-security/func-utils");
/**
 * Returns the type of widget
 * @param {object} widget - full widget info from the database
 * @return {string}
 */
const getWidgetType = ({ widget }) => {
    let type = widgetType_1.WIDGET_TYPE.UNSUPPORTED;
    try {
        type = widget?.type;
        //For backwards compatibility also takes into account the chart widget types
        if (type === "chart") {
            const config = (0, func_utils_1.tryParse)(widget?.config);
            type = config?.chartType;
        }
    }
    catch (e) {
        console.log(e);
    }
    return type;
};
exports.getWidgetType = getWidgetType;
