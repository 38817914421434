import { useAssessmentTargets } from "../hooks/useAssessmentTargets";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import React from "react";

/**
 * @description Display Targets list for an Assessment component
 * @param {object} props
 * @returns {JSX.Element}
 * @constructor
 */
const AssessmentTargets = (props) => {
  const display = useAssessmentTargets(props).display;
  return <div style={{ height: "30em" }}>{display}</div>;
};

export default withOrganizationCheck(AssessmentTargets);
