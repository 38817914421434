import { ErrorLogger } from "@utils/EventLogger";
import { cloneDeep } from "lodash";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Function for calculating the systemRiskValues property for systemChanges when there are multiple risk changes in a group
 * @param {object} systemChanges - a grouped system calculation changes object
 * @param {object[]} riskChanges - individual risk change data for the group
 * @return {*}
 */
export const getSystemRiskValuesAcrossMultipleRiskChanges = ({ systemChanges, riskChanges }) => {
  if (!systemChanges || !riskChanges) {
    return systemChanges;
  }

  const riskChangesData = [];
  if (Array.isArray(riskChanges)) {
    for (const riskChange of riskChanges) {
      try {
        const riskChangeData = JSON.parse(riskChange?.change);
        riskChangesData.push(riskChangeData);
      } catch (e) {
        ErrorLogger("Unable to parse risk change data!", e);
      }
    }
  }
  const newSystemChanges = cloneDeep(systemChanges);

  //Calculate the system risk values across multiple risk changes
  const systemsRiskValues = {};
  if (Array.isArray(riskChanges)) {
    for (const riskChangeData of riskChangesData) {
      const systemChanges = riskChangeData?.systemChanges;
      if (Array.isArray(systemChanges)) {
        for (const systemChange of systemChanges) {
          const systemID = systemChange?.id;
          const systemRiskValues = systemChange?.systemRiskValues;

          if (systemRiskValues) {
            // If system has not been seen yet record the change's risk values info as it is
            if (!systemsRiskValues.hasOwnProperty(systemID) || !systemsRiskValues[systemID]) {
              systemsRiskValues[systemID] = systemRiskValues;
              systemsRiskValues[systemID].startEpochTime = systemChange?.epochTime;
              systemsRiskValues[systemID].endEpochTime = systemChange?.epochTime;
            } else {
              // If system has been seen before, update the risk values info with the new risk change's info
              const startEpochTime = systemsRiskValues[systemID]?.startEpochTime;
              const endEpochTime = systemsRiskValues[systemID]?.endEpochTime;

              const {
                inherentRisk,
                residualRisk,
                greatestKeyRiskIndicatorData,
                greatestKeyRiskIndicator,

                recommendationInherentRisk,
                recommendationResidualRisk,
                greatestRecommendationKeyRiskIndicatorData,
                greatestRecommendationKeyRiskIndicator,
              } = systemRiskValues;

              //if this is the most recent change in the group for the system record the greatestKeyRiskIndicatorData
              if (
                !isNullOrUndefined(systemChange?.epochTime) &&
                startEpochTime > systemChange?.epochTime &&
                greatestKeyRiskIndicatorData
              ) {
                systemsRiskValues[systemID].startEpochTime = systemChange?.epochTime;
                systemsRiskValues[systemID].inherentRisk = inherentRisk;
                systemsRiskValues[systemID].residualRisk = residualRisk;
                systemsRiskValues[systemID].greatestKeyRiskIndicatorData = greatestKeyRiskIndicatorData;
                systemsRiskValues[systemID].greatestKeyRiskIndicator = greatestKeyRiskIndicator;
              }

              // if this is the oldest change in the group for the system record the greatestRecommendationKeyRiskIndicatorData
              if (
                !isNullOrUndefined(systemChange?.epochTime) &&
                endEpochTime < systemChange?.epochTime &&
                greatestRecommendationKeyRiskIndicatorData
              ) {
                systemsRiskValues[systemID].endEpochTime = systemChange?.epochTime;
                systemsRiskValues[systemID].recommendationInherentRisk = recommendationInherentRisk;
                systemsRiskValues[systemID].recommendationResidualRisk = recommendationResidualRisk;
                systemsRiskValues[systemID].greatestRecommendationKeyRiskIndicatorData =
                  greatestRecommendationKeyRiskIndicatorData;
                systemsRiskValues[systemID].greatestRecommendationKeyRiskIndicator =
                  greatestRecommendationKeyRiskIndicator;
              }
            }
          }
        }
      }
    }
  }

  //Add the system risk values to the system changes
  for (const systemChange of newSystemChanges) {
    const systemID = systemChange?.id;
    if (systemsRiskValues.hasOwnProperty(systemID)) {
      systemChange.systemRiskValues = systemsRiskValues[systemID];
    }
  }

  return newSystemChanges;
};
