import Dashboard from "../../../../utils/GenericComponents/Dashboard";
import DashboardCard from "../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import Grid from "@mui/material/Grid";
import PointOfContactActions from "../components/PointOfContactDashboard/components/PointOfContactActions";
import PointOfContactEvidence from "../components/PointOfContactDashboard/components/PointOfContactEvidence";
import PointOfContactInfo from "../components/PointOfContactDashboard/components/PointOfContactInfo";
import PointOfContactMeetings from "../components/PointOfContactDashboard/components/PointOfContactMeetings";
import PointOfContactPolicys from "../components/PointOfContactDashboard/components/PointOfContactPolicys";
import PointOfContactSystems from "../components/PointOfContactDashboard/components/PointOfContactSystems";
import PointOfContactTasks from "../components/PointOfContactDashboard/components/PointOfContactTasks";
import React from "react";
import UserActivityAccordion from "../../Users/components/UserActivityAccordion";
import { getPointOfContactName } from "../../../Reports/ComplianceReport/functions/getPointOfContactName";
import { useTileDashboard } from "../../../../hooks/views/useTileDashboard";

/**
 * Displays a Dashboard details page for a Point of Contact
 * Shows relevant information like general info, action items, and other assigned resources
 * @param {object} item - The Point of Contact to display
 * @param {function} resetFunction - A function to reset the Point of Contact
 * @param {function} updateItemById - A function to update the Point of Contact
 * @param {string} module - The module to check permissions for
 * @param {string} resource - The resource to check permissions for
 * @returns {{display: JSX.Element}}
 */
export const usePointOfContactDashboard = ({ item, resetFunction, updateItemById, module, resource }) => {
  const tileConfig = {
    xxl: 2,
    xl: 2,
    lg: 3,
    md: 4,
    sm: 6,
    xs: 12,
  };

  const tiles = [
    {
      id: "general",
      name: "general",
      title: "General Information",
      icon: "icon-list",
      disableTotals: true,
      component: (
        <Grid container spacing={2}>
          <Grid item lg={8} xs={12}>
            <DashboardCard
              dataTestId={"point-of-contact-general-info"}
              title={"General Info"}
              icon={"icon-list"}
              style={{ height: "100%" }}
            >
              <PointOfContactInfo
                item={item}
                resetFunction={resetFunction}
                updateItemById={updateItemById}
                module={module}
                resource={resource}
              />
            </DashboardCard>
          </Grid>
          <Grid item lg={4} xs={12}>
            <DashboardCard
              dataTestId={"point-of-contact-history"}
              title={"History"}
              icon={"icon-list"}
              style={{ height: "100%" }}
            >
              <UserActivityAccordion item={item} />
            </DashboardCard>
          </Grid>
        </Grid>
      ),
      text: "Items",
    },
    {
      id: "tasks",
      name: "tasks",
      title: "Tasks",
      icon: "icon-check",
      disableTotals: true,
      component: <PointOfContactTasks item={item} />,
      text: "Items",
    },
    {
      id: "actions",
      name: "actions",
      title: "Action Items",
      icon: "icon-rocket",
      disableTotals: true,
      component: <PointOfContactActions item={item} />,
      text: "Items",
    },
    {
      id: "evidence",
      name: "evidence",
      title: "Evidence",
      icon: "icon-badge",
      disableTotals: true,
      component: <PointOfContactEvidence item={item} />,
      text: "Items",
    },
    {
      id: "systems",
      name: "systems",
      title: "Information Systems",
      icon: "icon-screen-desktop",
      disableTotals: true,
      component: <PointOfContactSystems item={item} />,
      text: "Items",
    },
    {
      id: "meetings",
      name: "meetings",
      title: "Meetings",
      icon: "icon-people",
      disableTotals: true,
      component: <PointOfContactMeetings item={item} enableTemplates={false} />,
      text: "Items",
    },
    {
      id: "policies",
      name: "policies",
      title: "Policies",
      icon: "icon-docs",
      disableTotals: true,
      component: <PointOfContactPolicys item={item} />,
      text: "Items",
    },
  ];

  const tileDashboard = useTileDashboard({
    tileConfig,
    tiles,
    initial: 0,
  });

  const title = (
    <span>
      Personal Dashboard
      <br />
      <span style={{ textStyle: "italic", fontSize: "0.75em" }}>{item && `${getPointOfContactName(item)}`}</span>
    </span>
  );

  const display = <Dashboard title={title}>{tileDashboard.display}</Dashboard>;

  return {
    display,
  };
};
