import React, { useEffect, useState } from "react";

import Avatar from "@mui/material/Avatar";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import DeleteIcon from "@mui/icons-material/Delete";
import { ErrorLogger } from "../../EventLogger";
import { GetQuery } from "@rivial-security/appsync-utils";
import IconButton from "@mui/material/IconButton";
import { ItemMutation } from "../../Functions/Graphql/ItemMutation";
import List from "@mui/material/List";
import { ListItem } from "@mui/material";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { generateGraphql } from "@rivial-security/generategraphql";
import { isNonEmptyArray } from "@rivial-security/func-utils";
import { useUIContext } from "@utils/Context/UIContext";

/**
 * A list of bookmarks for a particular label
 * @param {object} label - the label link that holds all the bookmarks
 * @param {function} onBookmarksUpdated - callback when the list of bookmarks is modified from this UI
 * @returns {JSX.Element}
 */
const BookmarkList = ({ label, onBookmarksUpdated }) => {
  const { addToast } = useUIContext();
  const [bookmarks, setBookmarks] = useState(null);
  useEffect(() => {
    const newBookmarks = label?.bookmarks;
    if (isNonEmptyArray(newBookmarks)) {
      setBookmarks(newBookmarks);
    }
  }, [label]);

  /**
   * Delete a single bookmark from the label link
   * @param {object} bookmark - the bookmark to delete
   * @returns {Promise<void>}
   */
  const deleteBookmark = async ({ bookmarkToDelete }) => {
    const showFail = (e) => {
      ErrorLogger("Failed to delete bookmark", e);
      addToast({
        header: "Failed to delete bookmark.",
        icon: "error",
      });
    };

    // Check if have bookmarks to delete from
    const previousBookmarks = [...bookmarks];
    if (!isNonEmptyArray(bookmarks)) {
      showFail();
      return;
    }

    // Check if have needed info to retrieve link
    const { labelLinkID, labelLinkTypename } = label;
    if (!labelLinkID || !labelLinkTypename) {
      showFail();
      return;
    }

    // Local delete of bookmarks
    const newBookmarks = bookmarks.filter((bookmark) => bookmark?.id !== bookmarkToDelete?.id);
    setBookmarks(newBookmarks);

    // Retrieve up-to-date information about the label link
    try {
      const { updateMutation: updateLabelLinkMutation, getQuery: getLabelLink } = generateGraphql(
        labelLinkTypename,
        ["bookmarks"],
        {
          bookmarks: `{ id createdByAI owner text data }`,
        },
      );
      const databaseLabelLink = await GetQuery({
        query: getLabelLink,
        variables: { id: labelLinkID },
      });
      const databaseBookmarks = databaseLabelLink?.bookmarks;

      // Filter out deleted bookmark from database data
      const newDatabaseBookmarks = databaseBookmarks?.filter((databaseBookmark) => {
        return databaseBookmark?.id !== bookmarkToDelete?.id;
      });

      await ItemMutation(updateLabelLinkMutation, {
        id: labelLinkID,
        bookmarks: newDatabaseBookmarks,
      });

      // Update state with final bookmark data
      onBookmarksUpdated({ newBookmarks: newDatabaseBookmarks });
      setBookmarks(newDatabaseBookmarks);
    } catch (e) {
      showFail(e);
      setBookmarks(previousBookmarks);
    }
  };

  return (
    <List style={{ width: "100%", maxHeight: "30em", overflow: "auto" }}>
      {Array.isArray(bookmarks) && bookmarks.length > 0
        ? bookmarks?.map((bookmark) => (
            <ListItem
              key={bookmark?.id}
              secondaryAction={
                <div onClick={() => deleteBookmark({ bookmarkToDelete: bookmark })}>
                  <IconButton edge="end" aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </div>
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <BookmarkIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={bookmark?.owner || "Author Unknown"}
                secondary={
                  <React.Fragment>
                    <Typography sx={{ display: "inline" }} component="span" variant="body2" color="text.primary">
                      {label?.name || "No label"}
                    </Typography>
                    {` — ${bookmark?.text || "No text"}`}
                  </React.Fragment>
                }
              />
            </ListItem>
          ))
        : "No bookmarks found."}
    </List>
  );
};

export default BookmarkList;
