import AWS from "aws-sdk";
import { ErrorLogger } from "../../EventLogger";
import iamAPI from "../../../views/AdminPanel/Organizations/functions/iamAPI";

/**
 * Assume Selected Organization Role
 */
export const GetOrgIamCredentials = async ({
  organizationID,
}: {
  organizationID?: string;
}): Promise<AWS.Credentials | null> => {
  if (!organizationID) {
    ErrorLogger("Error! Missing organization id");
    return null;
  }

  let iamAPIResult: AWS.Credentials | null = null;
  try {
    const lambdaResponse = await iamAPI({
      operation: "getOrganizationRoleCredentials",
      organizationID,
    });

    const { accessKeyId, secretAccessKey, sessionToken } = lambdaResponse?.credentials ?? {};

    if (!accessKeyId || !secretAccessKey) {
      ErrorLogger("Error! Can not get credentials from IAM API");
      return null;
    }

    iamAPIResult = new AWS.Credentials(accessKeyId, secretAccessKey, sessionToken);
  } catch (e: unknown) {
    ErrorLogger("IAM Lambda, information was NOT received", e as object);
  }

  if (!iamAPIResult) {
    ErrorLogger("Error! Can not get credentials from IAM API");
    return null;
  }

  return iamAPIResult;
};
