import React from "react";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import _ from "lodash";

/**
 * Component that previews the current column mapping and configuration settings
 * @param {object[]} data - array of data objects used to run the mutations
 * @param {object} columnMap - mapping of CSV Headers to available Field Names ({header: field})
 * @returns {JSX.Element}
 * @constructor
 */
const ColumnMappingPreview = ({ data, columnMap }) => {
  const isDisplayed = () => {
    return !_.isEmpty(columnMap) && Array.isArray(data) && data.length > 0;
  };

  return (
    <div style={{ display: !isDisplayed() ? "none" : undefined }}>
      <h4>Total number of Rows</h4>
      {data?.length}
      <hr />
      <h4>Column Mapping</h4>
      <pre>{JSON.stringify(columnMap, null, 2)}</pre>
    </div>
  );
};

export default withOrganizationCheck(ColumnMappingPreview);
