import { checkParams } from "../../../../utils/Functions/Errors/checkParams";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { prepareDataForExport } from "./prepareDataForExport";

/**
 * @description Handle pressed context menu item on the grid
 * @param {object} args - arguments with clicked item from the context menu
 * @param {object} ref - reference of the Grid component
 * @param {object[]} fields - mapping configuration for a field in the grid with passed data
 * @param {object[]} selectedItems - array of selected items in the grid
 * @param {object} lastSelectedItem - last selected item from the grid
 * @param {function} deleteFunction - function for deleting an item
 * @param {string} deleteMessage - message to display when deleting an item
 * @param {function} setSelectedItems - sets the selected array of items
 * @param {object} contextMenuConfig - configurations for the context menu
 * @param {object} duplicateItemsHook - duplicate items hook instance
 * @param {string} route - navigation route
 * @param {string} deleteButtonText - text to display for a button
 * @param {object} deleteConfirmModal - modal to confirm items to delete
 * @param {object[]} contextMenuConfig.items - extra items to add to the context menu
 * @param {string} contextMenuConfig.items[].text - text for context menu button
 * @param {string} contextMenuConfig.items[].iconCss - icon css for context menu button
 * @param {function} contextMenuConfig.items[].onClick - onClick handler for context menu button
 */
export const gridContextMenuClick = ({
  args,
  ref,
  fields,
  selectedItems,
  lastSelectedItem,
  deleteFunction,
  deleteMessage = "",
  setSelectedItems,
  contextMenuConfig = {},
  duplicateItemsHook,
  route,
  deleteButtonText,
  deleteConfirmModal,
}) => {
  /**
   * Params to be checked for null
   */
  const params = {
    args,
    ref,
    fields,
    selectedItems,
    lastSelectedItem,
    setSelectedItems,
  };

  /**
   * Check params for null or undefined
   */
  checkParams({ params });

  const exportProperties = prepareDataForExport({ ref, selectedItems, fields });

  if (!isNullOrUndefined(contextMenuConfig?.items) && Array.isArray(contextMenuConfig.items)) {
    for (const item of contextMenuConfig.items) {
      if (args?.item?.id === item.id) {
        if (!isNullOrUndefined(item.onClick)) {
          item.onClick(selectedItems);
        }
      }
    }
  }

  switch (args?.item?.id) {
    case "details":
      lastSelectedItem?.id && document.getElementById(`details-page-${route}-${lastSelectedItem?.id}`).click();
      break;
    case "delete":
      deleteConfirmModal?.setModalIsOpen(true);
      break;
    case "csv_export":
      ref.csvExport(exportProperties);
      break;
    case "pdf_export":
      ref.pdfExport(exportProperties);
      break;
    case "duplicate_items":
      duplicateItemsHook.setModalIsOpen(true);
      break;
  }
};
