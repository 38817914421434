import { getSeparatedProcedureGroupResponseSteps } from "../../../PlaybookBuilder/functions/getSeparatedProcedureGroupResponseSteps";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useStateEffect } from "../../../../../hooks/functional/useStateEffect";

/**
 * State holding step information
 */
export const useStepState = (item, typename, procedureName) => {
  return useStateEffect([], [item, typename, procedureName], () => {
    const groups = getSeparatedProcedureGroupResponseSteps({
      item,
      procedureName,
    });
    if (!isNullOrUndefined(groups) && Object.keys(groups).length !== 0) {
      //sort groups by the typename of their parent
      const values = {
        Incident: 0,
        Exercise: 0,
        IncidentResponse: 1,
        ResponseSystemLink: 2,
        ResponseTeam: 3,
        Playbook: 4,
      };
      groups.sort((a, b) => {
        const aValue = a?.parentTypename && values.hasOwnProperty(a.parentTypename) ? values[a.parentTypename] : 100;
        const bValue = b?.parentTypename && values.hasOwnProperty(b.parentTypename) ? values[b.parentTypename] : 100;
        if (aValue < bValue) {
          return -1;
        } else if (a === b) {
          return 0;
        } else {
          return 1;
        }
      });

      return groups;
    } else {
      const parentID = item?.id ? item.id : "general";
      return [
        {
          parentID,
          parentTypename: typename,
          responseSteps: [],
          notes: [],
        },
      ];
    }
  });
};
