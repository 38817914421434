import { ErrorLogger } from "@utils/EventLogger";
import { QueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import { getOrganization_minimal } from "../../../AdminPanel/Organizations/graphql/__organizationGQL";
import { getSignedObjectUrlV2 } from "../../../../utils/Functions/S3Storage/getSignedObjectUrlV2";

/**
 * Get Policy Version file from S3
 * @param {Object} version - Policy Version
 * @param {string} organizationID - Organization ID
 * @return {Promise<string|null>}
 */
export const getPolicyVersionFromS3 = async ({ version, organizationID }) => {
  try {
    const organization = await QueryGetItem({
      query: getOrganization_minimal,
      itemId: organizationID,
    });

    return await getSignedObjectUrlV2({
      bucket: organization?.s3BucketName,
      key: version?.file?.key,
      organizationID,
    });
  } catch (err) {
    ErrorLogger(`[getPolicyVersionFromS3.js]: Error occurred while getting Policy Version from S3! ${err}`);
    return null;
  }
};
