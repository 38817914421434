import { modules, resources } from "@rivial-security/role-utils";
import { useCreateAutomation } from "../../OrganizationManager/Automations/hooks/useCreateAutomation";

/**
 * Custom form component for creating Automation Templates
 * @param {string} organizationID - the ownerGroup to use for creating/querying templates
 * @param {string} module - the module to use for role checking
 * @param {string} resource - the resource to use for role checking
 * @param {boolean} disableRoleChecking - if TRUE will disable role checking
 * @param {object} ...props - any other props to pass to the form
 * @returns {JSX.Element}
 */
const AutomationTemplateForm = ({
  organizationID,
  module = modules.ORGANIZATION_MANAGER,
  resource = resources.AUTOMATION,
  disableRoleChecking = false,
  ...props
}) => {
  const form = useCreateAutomation({
    organizationID,
    module,
    resource,
    disableRoleChecking,
    isTemplate: true,
    ...props,
  });

  return form.display;
};

export default AutomationTemplateForm;
