import { useMeetingForm } from "../hooks/useMeetingForm";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * Wrapper component for the useScheduleMeeting hook
 * @param {object} props - pass through props into useScheduleMeeting hook
 * @returns {JSX.Element}
 */
const MeetingForm = (props) => useMeetingForm({ ...props }).display;

export default withOrganizationCheck(MeetingForm);
