import React, { useState } from "react";
import { convertCamelCaseToSentence, formattedName, isNullOrUndefined } from "@rivial-security/func-utils";
import { modules, resources } from "@rivial-security/role-utils";

import DepartmentGrid from "../../../OrganizationManager/Departments/components/DepartmentGrid";
import { FORM_INPUT_TYPES } from "../../../../hooks/views/useForm/enums/FORM_INPUT_TYPES";
import { GetQuery } from "@rivial-security/appsync-utils";
import MeetingsDataGrid from "../../Meetings/components/MeetingsDataGrid";
import PointOfContactDataGrid from "../../../OrganizationManager/PointOfContacts/components/PointOfContactDataGrid";
import { TaskStatus } from "../constants/taskStatus";
import { fieldContexts } from "../../../../enums/fieldContexts";
import { generateGraphql } from "@rivial-security/generategraphql";
import { getResourceQueryData } from "../../../../definitions/functions/getResourceQueryData";
import { moduleTypes } from "../../moduleTypes";
import { taskType } from "../constants/taskType";
import { useForm } from "../../../../hooks/views/useForm";
import { useSetAsyncData } from "../../../../hooks/functional/useSetAsyncData";

/**
 * Form for creating a new task
 * @param {string} organizationID - the id of the organization for which the task is being created
 * @param {string} module - the module to use for role checking
 * @param {string} resource - the resource to use for role checking
 * @param {object} props - any other props to pass down to the useForm
 * @param {boolean} isAdmin - whether the user is an admin or not
 * @param {string} loggedInPointOfContactId - the id of the logged in point of contact
 * @returns {{display: *, input: {}, setInput: Function}}
 */
export const useCreateTask = ({
  organizationID,
  module: moduleInit,
  resource: resourceInit,
  item: itemInit,
  isAdmin,
  loggedInPointOfContactId,
  ...props
}) => {
  const module = moduleInit || modules.GOVERNANCE;
  const resource = resourceInit || resources.TASK;
  const disableRoleChecking = true;
  const typename = resources.TASK;
  const [selectedPointOfContact, setSelectedPointOfContact] = useState(null);

  const { createMutation } = getResourceQueryData({
    fieldContext: fieldContexts.GRID,
    typename,
  });

  const item = {
    ...(itemInit || {}),
  };
  if (itemInit?.custom?.content) {
    item.customContent = itemInit.custom.content;
  }

  const form = useForm({
    item,
    header: props?.header || null,
    organizationID,
    typename: "Task",
    module,
    resource,
    disableRoleChecking,
    mutation: createMutation,
    updateInputFunction: (input) => {
      //Define a default due date tomorrow if none is provided
      if (!input?.dueDate) {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        input.dueDate = tomorrow.toISOString();
      }

      //Remove empty fields for point of contact and department if empty
      if (!input?.pointOfContactID) {
        delete input.pointOfContactID;
      }

      if (!input?.departmentID) {
        delete input.departmentID;
      }

      //Convert the custom content into the correct nested structure
      if (input?.customContent) {
        input.custom = {
          content: input.customContent,
        };
      }
      delete input.customContent;

      return input;
    },

    //FIELDS
    fieldConfig: {
      name: {
        label: "Name",
        required: true,
      },
      description: {
        label: "Description",
      },
      status: {
        label: "Status",
        inputType: "dropdown",
        defaultValue: TaskStatus.TODO,
        dropdownConfig: {
          data: Object.values(TaskStatus).map((value) => {
            if (value === TaskStatus.TODO) {
              return {
                value,
                text: "To Do",
              };
            }
            return {
              value,
              text: convertCamelCaseToSentence(value),
            };
          }),
        },
      },
      type: {
        label: "Type",
        inputType: "dropdown",
        defaultValue: taskType.CHECKLIST,
        dropdownConfig: {
          data: [taskType.CHECKLIST, taskType.CUSTOM].map((item) => {
            return {
              value: item,
              text: convertCamelCaseToSentence(item),
            };
          }),
        },
      },
      module: {
        label: "Software Module",
        inputType: "dropdown",
        defaultValue: module || undefined,
        dropdownConfig: {
          data: moduleTypes.map((item) => {
            return {
              value: item,
              text: convertCamelCaseToSentence(item),
            };
          }),
        },
      },
      dueDate: {
        label: "Due Date",
        inputType: "date",
      },
      pointOfContactID: {
        label: "Point of Contact",
        inputType: "item-select",
        defaultValue: loggedInPointOfContactId,
        itemSelectConfig: {
          grid: <PointOfContactDataGrid organizationID={organizationID} />,
          formatSelectedItem: (item) => {
            if (isNullOrUndefined(item)) {
              return "";
            }

            if (!isNullOrUndefined(item.firstName) || !isNullOrUndefined(item.lastName)) {
              return formattedName({ pointOfContact: item });
            }

            if (!isNullOrUndefined(item.email)) {
              return item.email;
            }

            return item?.id || "Unknown Contact";
          },
        },
      },
      departmentID: {
        label: "Department",
        inputType: "item-select",
        itemSelectConfig: {
          grid: <DepartmentGrid organizationID={organizationID} />,
        },
        isHidden: () => {
          if (selectedPointOfContact?.id) {
            return true;
          }
        },
      },
      meetingID: {
        label: "Meeting",
        tooltip: "This task will be linked to the selected meeting.",
        removeFromInput: !item?.meetingID,
        inputType: "item-select",
        itemSelectConfig: {
          grid: <MeetingsDataGrid organizationID={organizationID} />,
        },
        isHidden: () => {
          if (!item?.meetingID) {
            return true;
          }
        },
      },
      taskListID: {
        label: "Task List",
        removeFromInput: !item?.taskListID,
        inputType: "text",
        isHidden: true,
        defaultValue: item?.taskListID,
      },

      customContent: {
        label: "Custom Task Content",
        inputType: FORM_INPUT_TYPES.RICH_TEXT_EDITOR,
        richTextEditorConfig: {
          enableEdits: true,
          disableEditButtons: true,
          enableAllFeatures: true,
        },
        isHidden: ({ input }) => {
          return input?.type !== taskType.CUSTOM;
        },
      },
    },
    ...props,
  });

  useSetAsyncData({
    getData: async () => {
      const { pointOfContactID: newPointOfContactID } = form?.input || {};
      if (
        !(!newPointOfContactID && !selectedPointOfContact?.id) &&
        selectedPointOfContact?.id !== newPointOfContactID
      ) {
        const { getQuery } = generateGraphql("PointOfContact", ["firstName", "lastName", "department"], {
          department: "{ id name }",
        });

        const pointOfContact = await GetQuery({
          query: getQuery,
          variables: {
            id: newPointOfContactID,
          },
        });

        return {
          pointOfContact,
          skipUpdate: false,
        };
      } else {
        return {
          skipUpdate: true,
        };
      }
    },
    setData: ({ pointOfContact, skipUpdate }) => {
      if (skipUpdate) {
        return;
      }

      setSelectedPointOfContact(pointOfContact);
      form.mergeInput({
        departmentID: pointOfContact?.department?.id,
      });
    },
    dependencies: [form?.input],
  });

  return {
    ...form,
    setInput: (input) => {
      const customContent = input?.custom?.content;
      form?.setInput({
        ...input,
        customContent,
      });
    },
  };
};
