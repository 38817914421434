import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { checkArguments } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 * @description Link Response Teams to an Exercise
 * @param {object[]} teams - response teams array
 * @param {object} exercise - exercise object
 * @param {string} organizationID - selected organization
 * @returns {object} {Promise<void>}
 */
export const handleLinkResponseTeams = async (teams, exercise, organizationID) => {
  /**
   * Check arguments
   */
  checkArguments(
    {
      teams,
      exercise,
      organizationID,
    },
    {
      teams: { isArray: true },
      exercise: { type: "object" },
      organizationID: { type: "string" },
    },
  );

  const { createMutation } = generateGraphql("ExerciseResponseTeamLink");

  const teamsToLink = [];

  for (const team of teams) {
    let alreadyLinked = false;

    /**
     * Find if a team already linked
     */
    const responseTeams = exercise?.responseTeams?.items;

    if (responseTeams) {
      for (const link of responseTeams) {
        if (link?.responseTeam?.id === team?.id) {
          alreadyLinked = true;
        }
      }
      if (alreadyLinked === false) teamsToLink.push(team);
    }
  }

  /**
   * Create new links
   */
  for (const team of teamsToLink) {
    await ItemMutation(createMutation, {
      responseTeamID: team?.id,
      exerciseID: exercise?.id,
      ownerGroup: organizationID,
    });
  }
};
