import { ErrorLogger } from "@utils/EventLogger";
import { deepDelete } from "../../../../utils/Functions/deepDelete";

/**
 * Deletes a questionnaire and all of its assignees entries from the database
 * @param {object} questionnaire - the questionnaire object with the id property defined
 * @param {object} config - configuration object
 * @param {function} onJobStarted - callback function to get a job
 * @returns {Promise<null>} - result of the delete operation
 */
export const deleteQuestionnaire = async (questionnaire, config = {}, onJobStarted) => {
  const questionnaireID = questionnaire?.id;
  if (!questionnaireID) {
    ErrorLogger("[deleteQuestionnaire]", "Invalid arguments to delete questionnaire");
    return;
  }

  /**
   * Delete Questionnaire in Fargate
   */
  return await deepDelete({
    typename: "Questionnaire",
    item: questionnaire,
    config,
    onJobStarted,
  });
};
