import { Grid, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import { parseISO, startOfDay } from "date-fns";
import React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const useStyles = makeStyles((t) => ({
  clearButton: {
    margin: t.spacing(1),
  },
  clearCell: {
    marginLeft: -t.spacing(0.5),
    marginTop: (props) => (props.label ? t.spacing(1.5) : "none"),
  },
}));

function parseDate(date) {
  if (!date) {
    return null;
  }
  if (typeof date === "string") {
    date = parseISO(date);
  }
  date = startOfDay(date);
  return date;
}
/**
 * @description The props of the DatePicker component.
 * @param {object} props - The props of the DatePicker component.
 */
const DatePicker = (props) => {
  const classes = useStyles(props);

  const [value, setValue] = React.useState(parseDate(props.value));

  React.useEffect(() => {
    const date = parseDate(props.value);
    setValue(date);
  }, [props.value]);

  function handleDateChange(date) {
    date = parseDate(date);
    setValue(date);
    if (props.onChange) {
      props.onChange(date);
    }
  }

  return (
    <Grid container>
      <Grid item>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            disabled={props?.disabled}
            value={value}
            onChange={handleDateChange}
            renderInput={(props) => <TextField label="Date" {...props} />}
          />
        </LocalizationProvider>
      </Grid>
      {!props?.disabled && props.clearable && (
        <Grid item className={classes.clearCell}>
          <IconButton
            aria-label="clear"
            className={classes.clearButton}
            data-testid={`${props["data-testid"]}-clear`}
            size="small"
            onClick={() => handleDateChange(null)}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

export default DatePicker;
