import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";

/**
 * Displays Tabs for the frequency scheduler
 * @param {string[]} tabs - array of tab strings, used for titles and state setting
 * @param {string} value - currently selected tab string
 * @param {function} setValue - sets the currently selected tab
 * @returns {JSX.Element}
 * @constructor
 */
export default function LabTabs({ tabs, value, setValue }) {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <TabList onChange={handleChange} aria-label="frequency scheduler tab list">
          {Array.isArray(tabs) && tabs.map((tab) => <Tab key={tab} label={tab} value={tab} />)}
        </TabList>
      </TabContext>
    </Box>
  );
}
