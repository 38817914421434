import { usePreferences } from "../../usePreferences/usePreferences";
import { useEffect, useState } from "react";

/**
 * Hook to get a single grid's persistence data.
 * @param {string} persistenceUUID - the grids persistence identifier passed in as a prop to useGrid
 * @return {{persistenceData: object}} - single grid's persistence data (mostly used for the 'customSettings' property)
 */
export const useGetGridPersistenceData = ({ persistenceUUID }) => {
  const { getPreference, preferences } = usePreferences();
  const [persistenceData, setPersistenceData] = useState(null);

  useEffect(() => {
    const gridPersistence = getPreference && getPreference("general", "grid_persistence");
    const allGridConfig = getPreference("general", "grid_persistence_config");
    if (gridPersistence && allGridConfig && persistenceUUID && allGridConfig[persistenceUUID]) {
      setPersistenceData(allGridConfig[persistenceUUID]);
    }
  }, [preferences]);

  return {
    persistenceData,
  };
};
