import { ErrorLogger } from "@utils/EventLogger";
import { ListQueryBy } from "../../../../utils/Functions/Graphql/ListQueryBy";
import { combineChildQueryData } from "./combineChildQueryData";
import { generateGraphql } from "@rivial-security/generategraphql";
import { getComplianceActivityData } from "./getComplianceActivityData";
import { listEvidenceActivitiesByOwnerGroupForArtifact } from "../constants/listEvidenceActivitiesByOwnerGroupForArtifact";
import { noItemsRootNode } from "../constants/noItemsRootNode";

/**
 * Retrieves a nested array of Point of Contact Compliance Documents for an organization
 * Tag -> Evidence -> Documents
 * @param {string} organizationID - organization for which to retrieve the mapping
 * @param {string} rootName - Name of the root folder
 * @param {object} dateRange - Date range for the data filter
 * @param {Date} dateRange.startDate - Start date for the data filter
 * @param {Date} dateRange.endDate - End date for the data filter
 * @returns {Promise<{children: *, name: string, id: string, type: string}>}
 */
export const documentsByEvidenceTag = async ({ organizationID, rootName, dateRange }) => {
  // Run the query
  const tagListPromise = ListQueryBy({
    query: tagsByOwnerGroup,
    limit: 1000,
    variables: {
      ownerGroup: organizationID,
    },
  });
  const evidenceActivityListPromise = ListQueryBy({
    query: listEvidenceActivitiesByOwnerGroupForArtifact,
    variables: {
      ownerGroup: organizationID,
    },
  });
  await Promise.allSettled([tagListPromise, evidenceActivityListPromise]);
  const tags = await tagListPromise;
  const evidenceActivityListOnly = await evidenceActivityListPromise;
  if (!Array.isArray(tags) || tags.length === 0) {
    ErrorLogger("[documentsByEvidenceTag.js] - tags is not an array");
    return noItemsRootNode;
  }

  // Combine the data
  for (const tag of tags) {
    const evidenceLinks = tag?.evidences?.items;
    if (!evidenceLinks) {
      continue;
    }
    const evidences = evidenceLinks.map((link) => {
      return {
        id: link?.evidenceID,
      };
    });

    tag.evidences.items = combineChildQueryData({
      parents: evidences,
      children: evidenceActivityListOnly,
      childFieldInParent: "activity",
      parentIDFieldInChild: "evidenceID",
    });
  }

  // Parse over all the retrieved data placing the documents into the correct bins
  const tagsFlatten = [];
  if (Array.isArray(tags)) {
    for (const tag of tags) {
      tagsFlatten.push({
        ...tag,
        activity: {
          items: tag?.evidences?.items?.reduce((acc, evidence) => acc.concat(evidence?.activity?.items), []),
        },
      });
    }
  }

  // Return the list of documents with needed parameters (tag, evidence) exposed
  return getComplianceActivityData({
    items: tagsFlatten,
    rootName,
    dateRange,
  });
};

// Create the list by owner group query
const tagsByOwnerGroup = generateGraphql(
  "Tag",
  ["id", "name", "description", "fontColor", "backgroundColor", "evidences"],
  {
    evidences: `(limit: 1000) {
      items {
        id
        evidenceID
      }
      nextToken
    }`,
  },
  {
    indexName: "tagsByOwnerGroup",
    partitionKey: "ownerGroup",
  },
).listByQuery;
