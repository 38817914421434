import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";
import { useFindingsCard } from "./useFindingsCard";

/**
 * Displays Observations, Recommendations, and Action Items in a TabCard format, component wrapper over useFindingsCard
 * @param {object} props - all props to pass down to useFindingsCard
 */
const FindingsCard = (props) => {
  return useFindingsCard(props).display;
};

export default withOrganizationCheck(FindingsCard);
