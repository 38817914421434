import React from "react";
import { useVendorSolutionDataGrid } from "../../VendorSolutions/hooks/useVendorSolutionDataGrid";

/**
 * Intended to use as a CustomField component in useTable hook, displays all of a single vendor's solutions
 * @param {object} item - the entire vendor item
 * @param organizationID
 */
const VendorSolutionsTableField = ({ item, organizationID }) => {
  const grid = useVendorSolutionDataGrid({
    vendorID: item && item.id ? item.id : undefined,
    organizationID,
  }).gridDisplay;

  return (
    <div
      style={{
        height: "50vh",
      }}
    >
      {item?.id && grid}
    </div>
  );
};

export default VendorSolutionsTableField;
