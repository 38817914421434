import React, { useEffect, useState } from "react";
import { formatMetricData } from "@rivial-security/metric-utils";
import { formattedDollar } from "@rivial-security/func-utils";

/**
 enum MetricDataFormat {
  decimal # ex. "0.1234".
  string  # for non-numerical data
  dollar  # USD form (with or without the commas and/or dollar sign). ex. "$123.50", "$1,234.00", "12356.50"
  percent # if input is already in percentage form (with or without the percent sign). ex. "75%", "36.0"
  json    # JSON.stringified, will be JSON.parsed
}
 * @param data
 * @returns {*}
 * @constructor
 */

const Data = ({ data }) => {
  const [value, setValue] = useState();

  useEffect(() => {
    try {
      if (data.format === "json") {
        setValue(JSON.stringify(formatMetricData(data)));
      } else if (data.format === "dollar") {
        setValue(formattedDollar(data.value));
      } else {
        setValue(formatMetricData(data));
      }
    } catch (e) {
      setValue("");
    }
  }, [data]);

  return <div>{value}</div>;
};

export default Data;
