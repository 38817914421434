import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";

import ButtonGroup from "@mui/material/ButtonGroup";
import IconButton from "@mui/material/IconButton";
import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * @description Next and Previous Buttons for details modal
 *
 * @param {object} previousItem - the previous item object in the grid, for displaying a preview
 * @param {object} nextItem - the next item object in the grid, for displaying a preview
 * @param {boolean} hasPrevious - boolean value if selected grid item has previous item
 * @param {boolean} hasNext - boolean value if selected grid item has next item
 * @param {function} adjustSelectedItemIndex - change selected item index in the grid
 * @return {JSX.Element}
 * @constructor
 */
export const DataGridNextAndPreviousButtons = ({
  previousItem,
  nextItem,
  hasPrevious,
  hasNext,
  adjustSelectedItemIndex,
}) => {
  return (
    <ButtonGroup size="small" aria-label="small button group">
      <IconButton
        aria-label="previous"
        disabled={hasPrevious === false}
        onClick={() => adjustSelectedItemIndex && adjustSelectedItemIndex(-1)}
        size="large"
      >
        <Tooltip
          title={
            !hasPrevious ? (
              "This is the first item in the list"
            ) : (
              <span>
                View the Previous Item in the List
                <br />
                <br />
                {!isNullOrUndefined(previousItem?.model?.name) && <i>{previousItem?.model?.name}</i>}
              </span>
            )
          }
        >
          <ArrowBack />
        </Tooltip>
      </IconButton>
      <IconButton
        aria-label="next"
        disabled={hasNext === false}
        onClick={() => adjustSelectedItemIndex && adjustSelectedItemIndex(1)}
        size="large"
      >
        <Tooltip
          title={
            !hasNext ? (
              "This is the last item in the list"
            ) : (
              <span>
                View the Next Item in the List
                <br />
                <br />
                {!isNullOrUndefined(nextItem?.model?.name) && <i>{nextItem?.model?.name}</i>}
              </span>
            )
          }
        >
          <ArrowForward />
        </Tooltip>
      </IconButton>
    </ButtonGroup>
  );
};
