import { useEffect, useState } from "react";

import { ErrorLogger } from "@utils/EventLogger";
import { getOrganizationLogoData } from "../../../views/OrganizationManager/GeneralConfig/functions/getOrganizationLogoData";

/**
 * Get organization or application logo from S3 bucket\
 * @param {string} organizationID - organization for which to retrieve the logo
 * @return {import('./types/useGetLogo').UseGetLogoReturn}
 */
export const useGetLogo = ({ organizationID }) => {
  const [orgLogo, setOrgLogo] = useState({ isLoading: true });
  const [appLogo, setAppLogo] = useState({ isLoading: true });

  const getLogoData = async ({ s3FileName, setFunction }) => {
    try {
      if (organizationID) {
        const url = await getOrganizationLogoData({
          organizationID,
          s3FileName,
        });

        if (!url) {
          throw Error(`No ${s3FileName} found!`);
        }

        const image = new Image();
        image.src = url;

        image.onload = () => {
          const width = image.width;
          const height = image.height;
          setFunction({
            url,
            dimensions: {
              width,
              height,
            },

            isLoading: false, // source of truth for loading state
          });
        };
      }
    } catch (e) {
      ErrorLogger(`Failed to retrieve ${s3FileName}!`, e);
      return setFunction({ isLoading: false });
    }
  };

  useEffect(() => {
    const fetchLogoData = async () => {
      if (organizationID) {
        getLogoData({
          s3FileName: "organizationLogo",
          setFunction: setOrgLogo,
        });
        getLogoData({
          s3FileName: "applicationLogo",
          setFunction: setAppLogo,
        });
      }
    };

    fetchLogoData();
  }, [organizationID]);

  return {
    orgLogo,
    appLogo,
  };
};
