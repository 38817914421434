import * as Sentry from "@sentry/react";

import { useEffect, useState } from "react";

import { ErrorLogger } from "@utils/EventLogger";
import { GetQuery } from "../../../utils/Functions/Graphql/GetQuery";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 * @description This hook is used to configure Sentry scope
 * @param {string} selectedOrganization - Selected organization ID
 * @param {object} selectedOrganizationObjectMinimal - Selected organization object
 * @param {string} operationTeamID - Operation team ID
 * @param {string} loggedInUserId - Logged in user ID
 * @param {string} userEmail - Logged in user email
 * @param {string} loggedInPointOfContactId - Logged in point of contact ID
 * @returns {{sentryTrace: unknown}}
 */
export const useSentryConfig = ({
  selectedOrganization,
  selectedOrganizationObjectMinimal,
  operationTeamID,
  loggedInUserId,
  userEmail,
  loggedInPointOfContactId,
}) => {
  /**
   * Sentry Scope configuration
   */
  const [sentryTrace, setSentryTrace] = useState(null);

  useEffect(() => {
    try {
      Sentry.configureScope((scope) => {
        const span = scope.getSpan();
        const sentryTraceHeader = span?.toTraceparent();
        if (sentryTraceHeader) setSentryTrace(sentryTraceHeader);
      });
    } catch (e) {
      ErrorLogger("Error! Cannot get Sentry trace", e);
    }
  }, []);

  useEffect(() => {
    try {
      Sentry.configureScope((scope) => {
        scope.setTag("organizationID", selectedOrganization);
        scope.setTag("organizationName", selectedOrganizationObjectMinimal?.name);
        scope.setTag("operationTeamID", operationTeamID);
        scope.setUser({
          id: loggedInUserId,
          email: userEmail,
        });

        if (loggedInPointOfContactId) {
          scope.setTag("pointOfContactID", loggedInPointOfContactId);
          GetQuery({
            query: generateGraphql("PointOfContact", ["firstName", "lastName"]).getQuery,
            variables: { id: loggedInPointOfContactId },
          })
            .then((poc) => {
              scope.setTag("pointOfContactName", `${poc?.firstName} ${poc?.lastName?.charAt(0)}`);
            })
            .catch(() => ErrorLogger("Error! Cannot get PointOfContact name"));
        }
      });
    } catch (e) {
      ErrorLogger("Error! Cannot set Sentry scope", e);
    }
  }, [
    userEmail,
    loggedInUserId,
    loggedInPointOfContactId,
    selectedOrganization,
    operationTeamID,
    selectedOrganizationObjectMinimal,
  ]);

  return {
    sentryTrace,
  };
};
