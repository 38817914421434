import { TreeItem, TreeView } from "@mui/x-tree-view";

import { Icon } from "@iconify/react";
import React from "react";
import { Tooltip } from "@mui/material";
import { cloneDeep } from "lodash";
import { doesHaveActiveFilter } from "../functions/doesHaveActiveFilter";
import { getFieldIcon } from "../functions/getFieldIcon";
import { getFunctionDefinitionResult } from "../../../definitions/functions/getFunctionDefinitionResult";
import { getFunctionFieldName } from "@rivial-security/widget-utils";
import { getUnderlyingFieldType } from "../functions/getUnderlyingFieldType";
/**
 * A directory type viewer for the result object that the current query will return, including functions
 * @param {object} resultFields - root level fields selected for the query
 * @param {object} resultFunctions - root level functions selected for the query
 * @param {function} setPath - callback to modify the currently edited path by the user
 * @param {boolean} defaultAllExpanded - whether all tree nodes should be expanded by default
 * @param {object} sx - custom styling for the tree
 * @param {JSX.Element} CustomTreeItemComponent - a custom component to use for the tree items
 * @param {string} customQueryId - the id of the query being edited
 * @param {string[]} includedTypes - the list of types that are allowed to be selected for the query
 * @return {{display: JSX.Element}}
 */
export const useResultFieldViewer = ({
  resultFields,
  resultFunctions,
  includedTypes,
  defaultAllExpanded = false,
  setPath,
  sx = {},
  CustomTreeItemComponent,
  customQueryId,
}) => {
  if (!CustomTreeItemComponent) {
    CustomTreeItemComponent = TreeItem;
  }

  /**
   * Utility function for checking if the variable is an object that has properties
   * @param {object} obj - the `object` to check
   * @return {boolean} - TRUE if the passed in object has any properties
   */
  const objectHasKeys = (obj) => {
    return typeof obj === "object" && Object.keys(obj).length > 0;
  };

  /**
   * Returns the css style of a single tree item based on the field or function that it represents
   * @param {string} field - the object representing the query element, whether it's a nested resource or a leaf primitive type
   * @return {object}
   */
  const getTreeItemStyle = ({ field }) => {
    let backgroundColor = undefined;
    const { fields, functions, isNested } = field;
    if (isNested && !objectHasKeys(fields) && !objectHasKeys(functions)) {
      backgroundColor = "#ffd9d9";
    }

    return { backgroundColor };
  };

  /**
   * A recursive function returning the component tree of that represents the
   * result fields and functions selected by the user
   * @param {string[]} traversePathInit - the current path of the user is editing
   * @param {object} resultFields - root level fields selected for the query
   * @param {object} resultFunctions - root level functions selected for the query
   * @param {object[]} path - the current path of the user is editing
   * @param {number} level - nesting amount info for generating unique ids
   * @return {JSX.Element|*[]}
   */
  const getTreeItems = ({ traversePath: traversePathInit, resultFields, resultFunctions, path, level }) => {
    if (!objectHasKeys(resultFields) && !objectHasKeys(resultFunctions)) {
      return <CustomTreeItemComponent customQueryId={customQueryId} nodeId={"none"} label={"No Selected Fields"} />;
    }

    //Add all result fields to the tree
    const traversePath = traversePathInit || [];
    const components = [];
    const nodeIds = [];
    if (objectHasKeys(resultFields)) {
      Object.values(resultFields).forEach((field) => {
        const { name, isRoot, fields, hasMany, isNested, functions, filters } = field || {};

        let label = name;
        if (isRoot === true) {
          label += " (Root)";
        }

        //When provided with an inclusion list, only show the fields that are valid or could lead to the valid field
        const underlyingType = getUnderlyingFieldType({ path, field });
        if (Array.isArray(includedTypes) && !includedTypes.includes(underlyingType) && !hasMany && !isNested) {
          return undefined;
        }

        //Add the field tree component
        const nodeId = `${name}_${level}`;
        nodeIds.push(nodeId);
        traversePath.push(name);
        const { components: nestedTreeItems, nodeIds: nestedNodeIds } =
          objectHasKeys(fields) || objectHasKeys(functions)
            ? getTreeItems({
                traversePath: cloneDeep(traversePath),
                resultFields: fields,
                resultFunctions: functions,
                path: [...path, field],
                level: level + 1,
              })
            : {};

        if (Array.isArray(nestedNodeIds)) {
          nodeIds.push(...nestedNodeIds);
        }
        components.push(
          <CustomTreeItemComponent
            sx={getTreeItemStyle({ field, name })}
            key={nodeId}
            customQueryId={customQueryId}
            nodeId={nodeId}
            traversePath={[...traversePath]}
            path={[...path, field]}
            field={field}
            label={
              <div>
                {label}{" "}
                {doesHaveActiveFilter({ query: field }) && (
                  <Tooltip
                    title={JSON.stringify(
                      filters,
                      (key, value) => {
                        if (key === "id") return undefined;
                        return value;
                      },
                      2,
                    )}
                  >
                    <Icon icon={"material-symbols:filter-list-rounded"} />
                  </Tooltip>
                )}
              </div>
            }
            collapseIcon={<Icon icon={getFieldIcon({ field })} />}
            endIcon={<Icon icon={getFieldIcon({ field })} />}
            onClick={() => {
              //Modifying the current edited path on field click
              if (typeof setPath !== "function") return;
              if (Array.isArray(path)) {
                setPath([...path, field]);
              } else {
                setPath([field]);
              }
            }}
          >
            {nestedTreeItems}
          </CustomTreeItemComponent>,
        );
        traversePath.pop();
      });
    }

    //Add all result functions to the tree
    if (objectHasKeys(resultFunctions)) {
      Object.values(resultFunctions).forEach((func) => {
        const functionResult = getFunctionDefinitionResult({ path, func });
        const underlyingType = getUnderlyingFieldType({ path, field: func });
        const functionName = getFunctionFieldName({ pathElement: func });
        const nodeId = `${functionName}_${level}`;
        nodeIds.push(nodeId);
        traversePath.push(functionName);

        if (Array.isArray(includedTypes) && !includedTypes.includes(underlyingType)) {
          return undefined;
        }

        components.push(
          <CustomTreeItemComponent
            key={nodeId}
            customQueryId={customQueryId}
            nodeId={nodeId}
            traversePath={[...traversePath]}
            path={[...path, func]}
            label={getFunctionFieldName({ pathElement: func })}
            field={func}
            collapseIcon={<Icon icon={getFieldIcon({ field: functionResult })} />}
            endIcon={<Icon icon={getFieldIcon({ field: functionResult })} />}
            title={functionResult?.description}
            onClick={() => {
              //Modifying the current edited path on field click
              if (typeof setPath !== "function") return;
              if (Array.isArray(path)) {
                setPath([...path, func]);
              } else {
                setPath([func]);
              }
            }}
          />,
        );
        traversePath.pop();
      });
    }

    return { components, nodeIds };
  };

  const { components: treeItems, nodeIds } = getTreeItems({
    resultFields,
    resultFunctions,
    path: [],
    level: 0,
  });

  let defaultExpanded = [];
  if (defaultAllExpanded) {
    defaultExpanded = nodeIds;
  }

  const display = (
    <TreeView
      aria-label="file system navigator"
      defaultCollapseIcon={<Icon icon="eva:chevron-right-outline" />}
      defaultExpandIcon={<Icon icon="material-symbols:expand-more-rounded" />}
      defaultExpanded={defaultExpanded}
      sx={{ flexGrow: 1, overflowY: "auto", ...sx?.treeView }}
    >
      {treeItems}
    </TreeView>
  );

  return { display };
};
