import { generateGraphql } from "@rivial-security/generategraphql";
import { getRoleDetails } from "../../../graphql/__roleGQL";
import RolePrecedence from "../../../customFields/RolePrecedence";
import { useDetailsCard } from "../../../../../../hooks/views/useDetailsCardV2";
import React from "react";
import { resources } from "@rivial-security/role-utils";

/**
 * Queries Role information and sets up a details table
 * @param itemId
 */
export const useRoleGeneralInfo = ({ itemId }) => {
  const typename = resources.ROLE;
  const queryFields = ["name", "description", "precedence", "roleConfig"];

  const { updateMutation } = generateGraphql(typename, queryFields);

  const queryConfig = {
    query: getRoleDetails,
    itemId,
  };

  const fields = ["name", "description", "precedence"];

  const customFields = [{ field: "precedence", component: <RolePrecedence /> }];

  const fieldNameDictionary = {
    precedence: "Type",
  };

  const detailsConfig = {
    fields,
    customFields,
    fieldNameDictionary,
  };

  return useDetailsCard({
    disableRoleChecking: true,
    queryConfig,
    detailsConfig,
    updateMutation,
  });
};
