import React, { useEffect, useState } from "react";
import { useCustomQueryNestedFieldDataGrid } from "./hooks/useCustomQueryDataGrid/useCustomQueryNestedFieldDataGrid";
import { Allotment } from "allotment";
import { useDetails } from "../../../../../../hooks/views/useDetails/hooks/useDetails";
import TopLevelFunctionResult from "./components/TopLevelFunctionResult";

/**
 * A generic query result display that shows the data that was gathered from an executed custom query
 * @param {object} query - the query configuration object created by the user with query builder
 * @param {object} workflow - outputs from a useCustomQueryRunnerExecutionWorkflow hook
 * @param {string} title - optional title to display above the results grid
 * @param {string} persistenceUUID - the unique id to use for storing the grid state in local storage
 * @param {string} organizationID - the organization for which to run the selected query
 * @param {boolean} disableTopLevelFunctions - if true will disable the top level functions in the query result view
 * @param {boolean} disableViews - if true will disable the views options in the grid
 * @param {function} onColumnHeaderClick - the function to call when a column header is clicked
 * @param {function} normalizeData - the function to call to normalize the data before setting it to the grid
 * @param {object[]} gridFieldsToMerge - the fields to merge into the grid field config
 * @param {boolean} enableQuickDetails - if true will enable the quick details option in the grid
 * @param {string} detailsLink - the link to use for the details page button
 * @return {{display: JSX.Element}}
 */
export const useCustomQueryRunnerResult = ({
  query: rootQuery,
  workflow,
  title,
  persistenceUUID,
  organizationID,
  disableTopLevelFunctions,
  disableViews,
  onColumnHeaderClick,
  normalizeData,
  gridFieldsToMerge,
  enableQuickDetails,
  detailsLink,
}) => {
  const [query, setQuery] = useState({});
  useEffect(() => {
    const rootFields = rootQuery?.fields;
    for (const fieldName in rootFields) {
      const rootField = rootFields[fieldName];
      if (rootField?.isRoot) {
        setQuery(rootField);
      }
    }
  }, [rootQuery]);
  const grid = useCustomQueryNestedFieldDataGrid({
    query,
    title,
    persistenceUUID,
    organizationID,
    disableCustomFields: true,
    disableViews,
    ...workflow,
    data: workflow?.listData || [],
    onColumnHeaderClick,
    gridFieldsToMerge,
    enableQuickDetails,
    detailsLink,
  });

  const details = useDetails({
    item: {
      id: "custom-query-result",
      ...workflow?.rootData,
    },
    fields: Object.keys(rootQuery?.functions || {}),
    customFields: Object.keys(rootQuery?.functions || {}).map((fieldName) => ({
      field: fieldName,
      component: <TopLevelFunctionResult fieldName={fieldName} />,
    })),
    disableRoleChecking: true,
  });

  //Once new data is available set it to the grid
  useEffect(() => {
    if (typeof normalizeData === "function") {
      return grid.setData(normalizeData(workflow?.listData) || []);
    } else {
      return grid.setData(workflow?.listData || []);
    }
  }, [workflow?.listData, query?.typename]);

  const doDisplayTopLevelFunctions = !disableTopLevelFunctions && Object.keys(rootQuery?.functions || {}).length > 0;

  const display = (
    <Allotment horizontal defaultSizes={[700, 300]}>
      <Allotment.Pane minSize={200}>{grid.display}</Allotment.Pane>
      <Allotment.Pane maxSize={doDisplayTopLevelFunctions ? 1000 : 0} minSize={doDisplayTopLevelFunctions ? 300 : 0}>
        <div style={{ padding: ".5em", overflowY: "auto", width: "100%", height: "100%" }}>
          <b>Top Level Functions</b>
          {details.display}
        </div>
      </Allotment.Pane>
    </Allotment>
  );

  return {
    ...grid,
    display,
  };
};
