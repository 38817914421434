import React, { useEffect, useState } from "react";
import { queryTemplates } from "../../../Templates/functions/queryTemplates";
import { resources } from "@rivial-security/role-utils";
import DataLoader from "../../../../utils/LoadingComponents/DataLoader";
import SelectionTiles from "../../../../utils/GenericComponents/SelectionTiles";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * Displays the selection tiles for the template table widgets
 *
 * @returns {JSX.Element}
 * @constructor
 */
const TemplateTableWidgetSelectionTiles = () => {
  const [templateWidgets, setTemplateWidgets] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const onSelect = (item) => {};

  // load in the built-in widget selection
  useEffect(() => {
    setIsLoading(true);

    queryTemplates({
      resource: resources.INFORMATION_SYSTEM,
    })
      .then((templates) => {
        setTemplateWidgets(
          templates.map((template) => ({
            id: template.id,
            text: template.name,
            icon: "simple-line-icons:screen-desktop",
            onSelect,
          })),
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <DataLoader isLoading={isLoading} isEnoughData={templateWidgets.length > 0}>
      <SelectionTiles items={templateWidgets} />
    </DataLoader>
  );
};

export default withOrganizationCheck(TemplateTableWidgetSelectionTiles);
