import { ASSESSMENT_STATUS, ASSESSMENT_TYPE } from "../../../../typedefs/Testing/Assessment/Assessment";

import AddSingleAssessment from "../../Assessments/components/AddSingleAssessment";
import AssessmentGrid from "../../Assessments/components/AssessmentDataGrid";
import CreateLinking from "../../../../utils/GenericComponents/CreateLinking";
import { createTestingAssessmentVulnerabilityLink } from "../../Assessments/functions/CreateTestingAssessmentVulnerabilityLink";
import { GENERIC_FIELD_TYPES } from "../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import React from "react";
import { enumToDropdownData } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useAssessmentDataGrid } from "../../Assessments/hooks/useAssessmentDataGrid";

/**
 * @description Display list assessments for a vulnerability
 * @param {object} item - vulnerability
 * @param {string} organizationID - selected organization
 * @constructor
 */
export const VulnerabilityAssessments = ({ item, organizationID }) => {
  const deleteFunction = async (item) => {
    if (item?.connectionIDField) {
      await ItemMutation(generateGraphql("AssessmentVulnerabilityLink").deleteMutation, {
        id: item?.connectionIDField,
      });
    }
  };

  const fields = [
    {
      name: "name",
      flex: 1,
    },
    {
      name: "type",
      flex: 1,
      filter: {
        type: "CheckBox",
      },
      inputType: GENERIC_FIELD_TYPES.DROPDOWN,
      inputConfig: {
        data: enumToDropdownData({ ENUM: ASSESSMENT_TYPE }),
      },
    },
    {
      name: "status",
      flex: 1,
      filter: {
        type: "CheckBox",
      },
      inputType: GENERIC_FIELD_TYPES.DROPDOWN,
      inputConfig: {
        data: enumToDropdownData({ ENUM: ASSESSMENT_STATUS }),
      },
    },
  ];

  const grid = useAssessmentDataGrid({
    organizationID,
    query: listAssessmentVulnerabilityLinksByVulnerabilityID,
    variables: {
      vulnerabilityID: item?.id,
    },
    normalizeData: (links) => {
      const assessments = [];
      for (const link of links) {
        const assessment = link?.assessment;
        if (assessment) {
          const found = assessments.find((x) => x.id === assessment?.id);
          Object.assign(assessment, { connectionIDField: link?.id });
          !found && assessments.push(link?.assessment);
        }
      }
      return assessments;
    },
    fields,
    deleteFunction,
    createItemModalHeader: "Attach an Assessment",
    createResourceButtonText: "Link",
    deleteButtonText: "Unlink",
    createResourceComponent: (
      <CreateLinking
        organizationID={organizationID}
        typename={"Assessment"}
        parentTypename={"Vulnerability"}
        form={<AddSingleAssessment />}
        grid={<AssessmentGrid fields={fields} />}
        item={item}
        queryConfig={{ normalizeData: undefined }}
        createFunction={(itemToLink, item) =>
          createTestingAssessmentVulnerabilityLink({
            vulnerability: item,
            assessment: itemToLink,
            organizationID,
          })
        }
      />
    ),
  });

  return <div style={{ height: "30em" }}>{grid.display}</div>;
};

const { listByQuery: listAssessmentVulnerabilityLinksByVulnerabilityID } = generateGraphql(
  "AssessmentVulnerabilityLink",
  ["id", "vulnerabilityID", "assessment"],
  {
    assessment: `{
      id
      name
      scheduledDate
      type
      pointOfContact {
        id
        firstName
        lastName
      }
      penTester
      status
      ownerGroup
    }`,
  },
  {
    indexName: "listAssessmentVulnerabilityLinksByVulnerabilityID",
    partitionKey: "vulnerabilityID",
    partitionKeyType: "ID",
  },
);
