/**
 * Returns an array formatted as a string with values separated by a comma and the word 'and' as needed
 * @param {string} fieldName - name of field in item that has the array of string values
 * @param {object} item - the entire db object (must have the 'field') property
 * @param {string} [deepProperty] - use this if the field is an array of objects (not strings)
 *  a list will be create from this property (eg. provided with 'name' -> [{name: "a"}, {name: "}] -> ["a", "b"])
 */
import React from "react";
import { emptyPlaceholder, invalidPlaceholder } from "../GenericComponents/Placeholders";

const StringListField = ({ fieldName, item, deepProperty }) => {
  const validFieldName = item && item.hasOwnProperty(fieldName);

  const getFieldText = (items) => {
    if (!items || !Array.isArray(items) || items.length === 0) {
      return emptyPlaceholder;
    }

    let newItems = [];
    if (deepProperty && deepProperty !== "") {
      for (const item of items) {
        if (item && item.hasOwnProperty(deepProperty)) {
          newItems.push(item[deepProperty]);
        }
      }
    } else {
      newItems = items;
    }

    if (newItems && Array.isArray(newItems) && newItems.length !== 0) {
      const values = [];
      let count = 1;

      for (const field of newItems) {
        if (count === 1) {
          // handle the first word
          values.push(
            <span key={`${values.length}_a`} style={{ fontWeight: "bold" }}>
              {field}
            </span>,
          );
        } else if (count === newItems.length) {
          // ending ends with an `and`
          values.push(<span key={`${values.length}_b`}>{" and "}</span>);
          values.push(
            <span key={`${values.length}_a`} style={{ fontWeight: "bold" }}>
              {field}
            </span>,
          );
        } else {
          // all other cases
          values.push(<span key={`${values.length}_b`}>{", "}</span>);
          values.push(
            <span key={`${values.length}_a`} style={{ fontWeight: "bold" }}>
              {field}
            </span>,
          );
        }
        count++;
      }

      return <span>{values.map((item) => item)}</span>;
    } else {
      return emptyPlaceholder;
    }
  };

  return <>{!validFieldName ? invalidPlaceholder : getFieldText(item[fieldName])}</>;
};

export default StringListField;
