import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useCreateKeyRiskIndicatorWithControlCategory } from "../../hooks/useCreateKeyRiskIndicatorWithControlCategory";
import { modules, resources } from "@rivial-security/role-utils";
import { withOrganizationCheck } from "../../../../../../utils/Context/withOrganizationCheck";
import { useForm } from "../../../../../../hooks/views/useForm";
import { isNullOrUndefined } from "@syncfusion/ej2-base";
import { useKeyRiskIndicatorImporter } from "../../hooks/useKeyRiskIndicatorImporter";

/**
 * Author: Jacob Blazina
 * Created At: 7/18/19
 *
 * Description: A Form Component. The user can Create a New "Risk".
 *              The Risk can optionally be attached to a "System" if `props.system` exists.
 */
const CreateNewRisk = ({
  module = modules.RISK,
  resource = resources.KEY_RISK_INDICATOR,
  disableRoleChecking = false,
  organizationID,
  toggleModal,
  getNewItem,
  isTemplate = false,
  ...props
}) => {
  const createMutationHook = useCreateKeyRiskIndicatorWithControlCategory({
    module,
    resource,
    disableRoleChecking: true,
  });

  const handleSubmit = async (input) => {
    createMutationHook.handleCreate({
      system: props.system,
      ...input,
      organizationID,
      callback: (data) => {
        props.callback && props.callback(data);
        props.resetFunction && props.resetFunction();
        props.toggleModal && props.toggleModal();
      },
    });
  };

  const keyRiskIndicatorImporter = useKeyRiskIndicatorImporter({
    organizationID,
  });

  const fieldConfig = {
    riskName: {
      required: true,
      label: "Risk Name",
      tooltip: "A descriptive name for this Key Risk Indicator that starts with a 2 digit number.",
    },
    description: {
      label: "Description",
      tooltip: "A detailed explanation of this Risk.",
      inputType: "textarea",
    },
    controlCategoryName: {
      label: "Control Category Name",
      tooltip: "A Control Category gets created automatically to correspond with this Key Risk Indicator.",
      disabled: true,
    },
    annualRateOfOccurrence: {
      required: true,
      label: "Annual Rate of Occurrence",
      tooltip:
        "The probability that this Risk may happen in a given year. A value of 1.0 would indicate once per year, 0.5 would indicate once every 2 years.",
      defaultValue: 0.1,
      inputType: "number",
      numberConfig: {
        min: 0,
      },
    },
  };

  const form = useForm({
    fieldConfig,
    organizationID,
    submitFunction: handleSubmit,
    header: "Create a Key Risk Indicator",
    headerButtons: [keyRiskIndicatorImporter.modalButton],
    module,
    toggleModal,
    getNewItem,
    disableRoleChecking: true,
    enableTemplates: !isTemplate, //allow using template if this is not a create template form
    ...props,
    typename: "Risk",
    resource: "Risk",
  });

  /**
   * Update the Control Category name dynamically with the Risk Name
   */
  useEffect(() => {
    if (!isNullOrUndefined(form?.input?.riskName)) {
      form.setInput((input) => {
        return {
          ...input,
          controlCategoryName: `${input?.riskName} Controls`,
        };
      });
    }
  }, [form?.input?.riskName]);

  return <>{form.display}</>;
};

CreateNewRisk.propTypes = {
  system: PropTypes.object,
  organizationID: PropTypes.string.isRequired,
};

export default withOrganizationCheck(CreateNewRisk);
