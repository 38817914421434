import { ErrorLogger, WarningLogger } from "@utils/EventLogger";
import React, { useEffect, useState } from "react";
import { STEP_STATUS, usePleaseWaitModal } from "../../../usePleaseWaitModal";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import Check from "@mui/icons-material/Check";
import DataGridBulkEditSelectedItemsPreview from "./components/DataGridBulkEditSelectedItemsPreview";
import DataGridBulkEditTags from "./components/DataGridBulkEditTags";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ItemMutation } from "../../../../../utils/Functions/Graphql/ItemMutation";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import { deleteTagLink } from "../../../../../utils/Tags/functions/deleteTagLink";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useForm } from "../../../useForm";
import { useModal } from "../../../useModal";

/**
 * Handles Bulk Update of one or multiple items in the DataGrid
 *
 * Shows progress UI, then handles the DataGrid state update to update items
 *
 * Field Config needs a 'bulkEdit: true' param
 *
 * @param {string} primaryField - the field representing each item display name
 * @param {object[]} selectedData - the array of selected data, each item is passed to the deleteFunction
 * @param {object[]} columns - the data grid 'columns' array. Used to check if the 'bulkEdit' option is enabled for each field, and get the 'renderCell' function
 * @param {string} editButtonText - the word used to explain the process, usually is 'Edit' or could be 'Update'
 * @param {function} editFunction - an async function that handles edit of each selectedData item
 * @param {function} resetFunction - for resetting the parent data state when complete
 * @param {string[]} options - the data grid 'options' array. Used to check if the 'edit' option is enabled for this grid
 * @param {string} editFunctionNote - a note to display to the user about the edit process
 * @param {function} onEditCallback - a callback function to run after the edit process is complete
 * @param {string} updateMutation - the graphql mutation to use for the update (if not using 'editFunction' bypass)
 * @param {string} organizationID - the current organizationID
 * @param {object[]} fields - the fields config for the grid
 */
export const useDataGridEditItems = ({
  primaryField,
  selectedData,
  columns,
  editFunction,
  editButtonText = "Edit",
  options = [],
  resetFunction,
  editFunctionNote,
  onEditCallback,
  updateMutation,
  organizationID,
  fields,
}) => {
  const modal = useModal(
    `${editButtonText} Selected Items`,
    <ModalBody
      primaryField={primaryField}
      selectedData={selectedData}
      deleteButtonText={editButtonText}
      deleteFunction={editFunction}
      resetFunction={resetFunction}
      onDeleteCallback={onEditCallback}
      deleteFunctionNote={editFunctionNote}
      columns={columns}
      updateMutation={updateMutation}
      organizationID={organizationID}
      fields={fields}
    />,
    null,
    {
      width: "50vw",
    },
  );

  return {
    modal,
    editFunction,
    options,
    editButtonText,
  };
};

/**
 * Handles the Editing process and UI for selected Items
 * @param {string} primaryField - the field representing each item display name
 * @param {object[]} selectedData - the array of selected data, each item is passed to the editFunction
 * @param {string} editButtonText - the word used to explain the process, usually is 'edit' or could be 'edit'
 * @param {function} deleteFunction - an async function that handles deletion of each selectedData item
 * @param {function} toggleModal - for toggling an external modal when complete
 * @param {function} resetFunction - for resetting the parent data state when complete
 * @param {string} organizationID - the current organizationID
 * @returns {JSX.Element}
 * @constructor
 */
const ModalBody = ({
  primaryField,
  selectedData,
  editButtonText = "Edit",
  editFunction,
  toggleModal,
  resetFunction,
  editFunctionNote,
  onEditCallback,
  columns,
  updateMutation,
  organizationID,
  fields,
}) => {
  const [previewItem, setPreviewItem] = useState({});

  const pleaseWaitModal = usePleaseWaitModal({
    progressTotal: Array.isArray(selectedData) ? selectedData.length : 0,
    steps: [],
    autoClose: true,
    autoCloseInterval: 2,
    subTitle: `Processing Selected Items..`,
    toggleModal,
    resetFunction,
  });

  /**
   * Determines if the confirmation UI displays
   */
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    if (Array.isArray(selectedData)) {
      // Configures the pleaseWaitModal with initial steps
      pleaseWaitModal.setSteps(
        selectedData.map((item) => {
          return {
            id: item?.id,
            text: item?.name,
            status: STEP_STATUS.WAITING,
          };
        }),
      );

      // Sets pleaseWaitModal total progress
      pleaseWaitModal.setTotalProgress(selectedData.length);

      // Set a preview item
      if (selectedData.length > 0) {
        setPreviewItem(selectedData[0]);
      }
    }
  }, [selectedData]);

  const [selectedField, setSelectedField] = useState(null);
  const [newValue, setNewValue] = useState(null);

  /**
   * Handles the mutation of an item, using a specific field and value.
   *
   * If an external 'editFunction' is passed in, uses that exclusively and bypasses built in logic.
   *
   * With no external 'editFunction' and with an 'updateMutation' graphql string, will try and mutate automatically
   *
   * @param {object} item - the current item to be edited
   * @param {string} field - the field to be edited
   * @param {string | number | boolean} value - the new value to update the item field to
   * @returns {Promise<*>}
   */
  const editFunctionInternal = async ({ item, field, value }) => {
    const foundField = fields.find((fieldObject) => fieldObject?.name === field);
    if (typeof foundField.editFunction === "function") {
      await foundField?.editFunction({ item, value, field });
      return;
    }
    // If this is 'tags' field, use custom logic for removing Tag Links and then adding Tag Links
    if (field === "tags") {
      const currentTagLinks = _.cloneDeep(item.tags.items);

      const { tagsToAdd, tagsToRemove } = value;

      // find the createLinkFunction from the columns config
      const field = columns.find((column) => column.field === fieldSelectionForm?.input?.field);

      // add new tag links (prevent duplicates)
      for (const tagToAdd of tagsToAdd) {
        // first check that current tags do not include the tag to add to prevent duplicates
        if (!currentTagLinks.some((tagLink) => tagLink.tag.id === tagToAdd.id)) {
          if (field?.createLinkFunction) {
            await field.createLinkFunction(item, tagToAdd, organizationID);
          }
        }
      }

      // remove tag links
      for (const tagLink of currentTagLinks) {
        // if current tag is included in tagsToRemove, delete the link
        if (tagsToRemove.some((tagToRemove) => tagToRemove.id === tagLink.tag.id)) {
          await deleteTagLink(tagLink);
        }
      }
    }

    // If an explicit edit function is passed in, use it
    else if (typeof editFunction === "function") {
      await editFunction({ item });
    }

    // Otherwise, if an updateMutation is passed in, do it automatically
    else if (typeof updateMutation === "string") {
      try {
        const newItem = await ItemMutation(updateMutation, {
          id: item?.id,
          [field]: value,
        });

        return newItem;
      } catch (e) {
        ErrorLogger("Error: could not edit item: ", e);
      }
    }
  };

  /**
   * Processes the edit function. Handles the please wait modal steps, loops over selected data.
   *
   * If a step throws an error, this function catches it and continues on to the next step.
   * @returns {Promise<void>}
   */
  const handleEdit = async () => {
    if (isNullOrUndefined(selectedField)) {
      WarningLogger("Couldn't edit item, no field selected");
      return;
    }
    const undefinedValueValid = fields?.find((field) => field.name === selectedField)?.undefinedValueValid;
    if (isNullOrUndefined(newValue) && !undefinedValueValid) {
      WarningLogger("Couldn't edit item, no new value for selected field");
      return;
    }

    if (!Array.isArray(selectedData)) {
      WarningLogger("Couldn't edit item, no selected data");
      return;
    }

    pleaseWaitModal.setModalIsOpen(true);

    for (let i = 0; i < selectedData.length; i++) {
      pleaseWaitModal.setStepStatus(i, STEP_STATUS.IN_PROGRESS);

      try {
        await editFunctionInternal({
          item: selectedData[i],
          field: selectedField,
          value: newValue,
        });

        pleaseWaitModal.setStepStatus(i, STEP_STATUS.COMPLETE);
      } catch (e) {
        ErrorLogger("Error: could not edit item: ", e);
        pleaseWaitModal.setStepStatus(i, STEP_STATUS.ERROR);
      } finally {
        pleaseWaitModal.incrementProgress();
      }
    }

    pleaseWaitModal.setFinished(true);

    if (typeof onEditCallback === "function") {
      onEditCallback();
    }
  };

  /**
   * Gets the array of enabled fields from the fieldConfig objects
   * @param {object[]} columns - columns config from the grid
   * @param {boolean} columns[].bulkEdit - determines if a column is enabled for bulk edits
   */
  const getEnabledFields = (columns) => {
    const enabledColumns = [];

    // if columns input is invalid, don't enable any columns
    if (!Array.isArray(columns)) {
      return enabledColumns;
    }

    for (const column of columns) {
      if (column?.bulkEdit) {
        enabledColumns.push(column);
      }
    }

    return enabledColumns;
  };

  const fieldSelectionForm = useForm({
    disableRoleChecking: true,
    disableSubmitButton: true,
    disableResetButton: true,
    fieldConfig: {
      field: {
        label: "Select a Field to Edit",
        inputType: "dropdown",
        dropdownConfig: {
          data: [
            ...(getEnabledFields(columns)?.map((field) => ({
              value: field.field,
              text: field.headerName,
            })) || []),
          ],
        },
      },
    },
  });

  /**
   * On field submit, show confirmation section of the form to proceed
   * @param {object} data - sets the selected data into state and opens the confirmation UI
   * @param {string} data.field - the field that is being edited
   * @param {string | number | boolean} data.value - the new value that will be used to bulk edit items
   * @returns {Promise<void>}
   */
  const submitFunction = async (data) => {
    setShowConfirm(true);
    setSelectedField(data?.field);
    setNewValue(data?.value);

    const field = columns.find((column) => column.field === fieldSelectionForm?.input?.field);
    if (typeof field?.bulkEditPreviewGetter === "function") {
      const previewItem = await field?.bulkEditPreviewGetter({
        input: {
          [data?.field]: data?.value,
        },
      });
      setPreviewItem(previewItem);
    }
  };

  const getBulkEditComponent = ({ field }) => {
    if (field?.field === "tags") {
      return <DataGridBulkEditTags organizationID={organizationID} submitFunction={submitFunction} />;
    }

    if (field?.renderBulkEditInput) {
      return field.renderBulkEditInput({ submitFunction });
    }

    return field.renderCell({
      row: {
        id: "bulk-edit", // to stop the generic edit field from complaining
        [field.field]: previewItem?.[field?.field], // hydrates the field with an example input, HYDRATES TAGS FROM ALL SELECTED ITEMS?
        ...previewItem, // adds in custom computed preview item data to render
      },
      field: field.field,
      submitFunction,
      defaultOpen: true,
      disableToast: true,
    });
  };

  return (
    <div>
      <div>
        {fieldSelectionForm.display}

        {(() => {
          if (fieldSelectionForm?.input?.field) {
            const field = columns.find((field) => field.field === fieldSelectionForm.input.field);

            if (field?.field && typeof field.renderCell === "function") {
              try {
                return (
                  <div
                    style={{
                      border: "2px dashed grey",
                      borderRadius: "8px",
                      padding: "2em",
                    }}
                  >
                    <div>{getBulkEditComponent({ field })}</div>
                  </div>
                );
              } catch (e) {
                return <div>Sorry, something went wrong</div>;
              }
            } else {
              return <div>Could not find field to update</div>;
            }
          }
        })()}
      </div>
      <hr />

      {showConfirm && (
        <div>
          {Array.isArray(selectedData) && selectedData.length > 0 ? (
            <div>
              {editFunctionNote && <h6>{editFunctionNote}</h6>}
              Are you sure you want to {editButtonText} {selectedData.length} items?
              <div style={{ textAlign: "center" }}>
                <Button
                  data-testid={"confirm-grid-edit-button"}
                  onClick={async () => await handleEdit()}
                  style={{ marginTop: "2em" }}
                  startIcon={<Check />}
                  variant={"contained"}
                  color={"success"}
                >
                  Yes
                </Button>
              </div>
              <hr />
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content-edit"
                  id="panel1a-header-edit"
                >
                  <Typography>Preview</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <DataGridBulkEditSelectedItemsPreview
                      primaryField={primaryField}
                      selectedData={selectedData}
                      selectedField={selectedField}
                      customFields={
                        fields?.find((field) => field.name === fieldSelectionForm?.input?.field)?.bulkEditCustomFields
                      }
                      newValue={newValue}
                    />
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          ) : (
            `There are no Selected Items to ${editButtonText}`
          )}
        </div>
      )}

      {pleaseWaitModal.modal}
    </div>
  );
};
