import { useContext, useEffect } from "react";

import { ErrorLogger } from "@utils/EventLogger";
import { ItemMutation } from "../../../../../utils/Functions/Graphql/ItemMutation";
import { ListQuery } from "../../../../../utils/Functions/Graphql/ListQuery";
import { UIContext } from "../../../../../utils/Context/UIContext";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useCsvImporter } from "../../../../CsvImporters/hooks/useCsvImporter";

export const useUploadThreats = ({ organizationID, toggleModal, resetFunction }) => {
  const { addToast, updateToast } = useContext(UIContext);

  const headerObjects = [
    {
      title: "name",
      type: "text",
    },
  ];
  const threatFields = ["id", "name", "ownerGroup", "createdAt"];
  const { listQuery, updateMutation } = generateGraphql("Threat", threatFields);
  const { createMutation } = generateGraphql("Threat", [...threatFields, "createdAt"]);
  const { deleteMutation } = generateGraphql("Threat", ["id"]);
  const csvImporter = useCsvImporter({
    headerObjects,
    title: "Import Threats",
    passThroughDuplicateScanner: true,
  });

  //Load source information into csv uploader for duplicates scanner
  useEffect(() => {
    csvImporter.initSourceQuery(listQuery, organizationID);
  }, []);

  const createItemsFromArray = async (operations) => {
    if (
      operations &&
      operations.add &&
      Array.isArray(operations.add) &&
      operations.update &&
      Array.isArray(operations.update) &&
      operations.delete &&
      Array.isArray(operations.delete)
    ) {
      const totalOperations = operations.add.length + operations.update.length + operations.delete.length;
      addToast({
        id: "csv_toast_upload_threats",
        icon: "spinner",
        header: `CSV Upload for Threats Started (${totalOperations} Operations)`,
      });

      toggleModal?.();

      const existingThreats = await ListQuery({
        query: listQuery,
        organizationID,
      });

      //perform key risk indicator updates
      for (const operationItem of operations.update) {
        //Check if item to mutate exists
        const itemFound = existingThreats.some((originalItem) => {
          return originalItem.id != null && operationItem.id != null && operationItem.id === originalItem.id;
        });

        if (itemFound) {
          await ItemMutation(updateMutation, operationItem);
        }
      }

      //TODO: Correctly perform key risk indicator deletions (by deleting associated links)
      //
      // for (let operationItem of operations.delete) {
      //   const foundItem = existingThreats.find((originalItem) => {
      //     return (originalItem.id != null && operationItem.id != null && originalItem.id === operationItem.id)
      //   });
      //
      //   if (foundItem) {
      //     await ItemMutation(deleteMutation, {id: foundItem.id});
      //   }
      // }

      //perform key risk indicator additions
      for (const operationItem of operations.add) {
        if (operationItem.name) {
          // set up input
          const input = {};
          input.name = operationItem.name;
          input.ownerGroup = organizationID;
          input.responseTeamID = "n/a";

          await ItemMutation(createMutation, input);
        } else {
          ErrorLogger("Error: Threats need a name!");
        }
      }

      updateToast({
        id: "csv_toast_upload_threats",
        icon: "success",
        header: `Successfully uploaded all Threats`,
      });

      resetFunction?.();
    }
  };

  // Perform the creations
  useEffect(() => {
    if (csvImporter.dataJSONReady) {
      createItemsFromArray(csvImporter.dataJSONReady).then(() => {
        updateToast({
          id: "csv_toast_upload_threats",
          icon: "success",
          header: `Threats CSV Upload is Complete!`,
        });
      });
    }
  }, [csvImporter.dataJSONReady]);

  return {
    ...csvImporter,
    createItemsFromArray,
  };
};
