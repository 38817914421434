import { InvalidParamError } from "../../../../analytics/CustomError";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Traverses through an Evidence's observations, recommendations, and action items to find related actions
 * @param {Evidence} evidence
 * @returns {Object[]}
 */
export const getActionItemsForEvidence = (evidence) => {
  if (isNullOrUndefined(evidence)) {
    throw new InvalidParamError("evidence");
  }

  const res = [];

  const evidenceObservations =
    !isNullOrUndefined(evidence?.observations?.items) && Array.isArray(evidence?.observations?.items)
      ? evidence?.observations?.items
      : [];

  for (const observation of evidenceObservations) {
    const recommendationLinks =
      !isNullOrUndefined(observation?.recommendations?.items) && Array.isArray(observation?.recommendations?.items)
        ? observation?.recommendations?.items
        : [];

    for (const recommendationLink of recommendationLinks) {
      const recommendation = recommendationLink.recommendation;

      const actionItemLinks =
        !isNullOrUndefined(recommendation?.actionItems?.items) && Array.isArray(recommendation?.actionItems?.items)
          ? recommendation?.actionItems?.items
          : [];

      for (const actionItemLink of actionItemLinks) {
        //TODO: Need to check this as there is no `actionItem` property in `RecommendationActionLink`
        const actionItem = actionItemLink.actionItem;

        const data = actionItem?.data && JSON.parse(actionItem.data);

        if (data && data.evidenceID === evidence.id) {
          res.push(actionItem);
        }
      }
    }
  }

  return res;
};
