import { InfoLogger } from "@utils/EventLogger";
import { ListQueryBy } from "../../../../utils/Functions/Graphql/ListQueryBy";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { normalizeCustomResourceEntryRowData } from "../functions/normalizeCustomResourceEntryRowData";
import { useEffect } from "react";

/**
 * A custom hook for populating CustomResourceEntry data using Custom Fields and Selected CustomResourceType ID
 *
 * Takes a gridCard reference to automatically pass data to grid.
 *
 * @param {number} resetKey - a key to reset the grid data manually from the parent
 * @param {string} organizationID - org ID for the controls
 * @param {string} customResourceTypeID - the CustomResourceType ID, this is required
 * @param {object[]} customFieldNames
 * @param {ref} gridCard - a useGridCard hook instance reference
 * @param {string} query - the graphql query
 * @param {function} newDataCallback - function that takes in the updated data once it becomes available
 */
export const useCustomResourceEntryGridData = ({
  resetKey,
  organizationID,
  customResourceTypeID,
  customFieldNames,
  gridCard,
  query,
  newDataCallback,
}) => {
  useEffect(() => {
    /**
     * Get CustomResourceEntries for Data Grid
     */
    if (
      !isNullOrUndefined(organizationID) &&
      organizationID !== "" &&
      customResourceTypeID !== "" &&
      !isNullOrUndefined(customResourceTypeID) &&
      !isNullOrUndefined(customFieldNames) &&
      !isNullOrUndefined(gridCard) &&
      !isNullOrUndefined(query)
    ) {
      gridCard?.setIsLoading(true);

      InfoLogger("Running Query for CustomResourceEntry grid");

      // TODO: convert to fetch CustomResourceEntries using CustomResourceType key
      ListQueryBy({
        query,
        limit: 1000,
        filter: {
          customResourceTypeID: {
            eq: customResourceTypeID,
          },
        },
        variables: {
          ownerGroup: organizationID,
        },
      })
        .then((items) => {
          if (!isNullOrUndefined(items) && Array.isArray(items)) {
            const res = [];
            for (const item of items) {
              res.push(
                normalizeCustomResourceEntryRowData({
                  customResourceEntry: item,
                  customFields: customFieldNames,
                }),
              );
            }
            gridCard?.setData(res);
            newDataCallback?.(res);
          }
        })
        .finally(() => {
          gridCard?.setIsLoading(false);
        });
    }
  }, [organizationID, JSON.stringify(customFieldNames), customResourceTypeID, resetKey]);
};
