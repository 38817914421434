import GenericFieldDropdown from "../../../../utils/GenericComponents/GenericFieldDropdown";
import { OBSERVATION } from "../constants/OBSERVATION";
import React from "react";
import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { fieldContexts } from "../../../../enums/fieldContexts";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 * Displays the Status field for an Observation.
 * @param {OBSERVATION} item - The Observation to display the Status field for.
 * @param {function} updateItemById - The function to update the Observation in the parent component.
 * @param {string} fieldContext - The context of the field, used to determine if a tooltip should be displayed
 * @param {object} ...props - The props to pass to GenericFieldDropdown.
 * @returns {JSX.Element}
 * @constructor
 */
const Status = ({ item, updateItemById, fieldContext, ...props }) => {
  const module = OBSERVATION.module;
  const resource = OBSERVATION.resource;
  const field = OBSERVATION.fields.status;

  const options = OBSERVATION?.fieldConfig?.status?.valueOptions?.map((status) => {
    return {
      text: convertCamelCaseToSentence(status),
      value: status,
    };
  });

  return (
    <GenericFieldDropdown
      item={item}
      module={module}
      resource={resource}
      field={field}
      mutation={generateGraphql(OBSERVATION.typename, [field]).updateMutation}
      options={options}
      format="sentence"
      updateItemById={updateItemById}
      tooltip={fieldContext !== fieldContexts.GRID ? OBSERVATION?.fieldConfig?.status?.description : undefined}
      {...props}
    />
  );
};

export default Status;
