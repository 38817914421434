import React from "react";
import { useStack } from "../DataTableOptions/DataTableOptions";
import InterfaceBetaRiskCalculations from "../InterfaceOptions/components/InterfaceBetaRiskCalculations";

const BetaOptions = () => {
  return useStack({
    items: [<InterfaceBetaRiskCalculations />],
  });
};

export default BetaOptions;
