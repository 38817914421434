import { generateGraphql } from "@rivial-security/generategraphql";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { EVIDENCE_STATUS } from "../../../../typedefs/Compliance/Evidence/Evidence";

/**
 * Triggered when an evidence was recorded from the evidence details page by any user
 * @param {object} evidence - the evidence object for which the evidence record was submited, must have at least the id property
 * @return {Promise<void>}
 */
export const onManualEvidenceSubmission = async ({ evidence }) => {
  //Check arguments
  if (!evidence?.id) {
    return;
  }

  //Switch evidence to pending validation
  const { updateMutation } = generateGraphql("Evidence", ["status"]);
  await ItemMutation(updateMutation, {
    id: evidence.id,
    status: EVIDENCE_STATUS.PENDING_VALIDATION,
  });
};
