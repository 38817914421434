import CancelChangesButton from "../../../OrganizationManager/Roles/hooks/useRoleDetails/components/CancelChangesButton";
import Dashboard from "../../../../utils/GenericComponents/Dashboard";
import DashboardCard from "../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import DataLoader from "../../../../utils/LoadingComponents/DataLoader";
import { Grid } from "@mui/material";
import ModuleSections from "../../../OrganizationManager/Roles/hooks/useRoleDetails/components/ModuleSections";
import React from "react";
import RolePreviewButton from "../../../OrganizationManager/Roles/hooks/useRoleDetails/components/RolePreviewButton";
import SaveChangesButton from "../../../OrganizationManager/Roles/hooks/useRoleDetails/components/SaveChangesButton";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useRoleConfig } from "../../../OrganizationManager/Roles/hooks/useRoleDetails/hooks/useRoleConfig";
import { useRoleGeneralInfo } from "../../../OrganizationManager/Roles/hooks/useRoleDetails/hooks/useRoleGeneralInfo";

/**
 * Hook for displaying the details of a role template
 * @param {string} itemId - the database id of a role template
 * @returns {{display: JSX.Element}}
 */
export const useRoleTemplateDetails = ({ itemId }) => {
  // Queries role information and handles the basic details table for name, description, etc.
  const roleDetails = useRoleGeneralInfo({ itemId });

  /**
   * Hook for all RoleConfig utilities
   */
  const {
    roleConfig,
    roleConfigInit,
    isChanged,
    handleResourceChange,
    handleModuleChange,
    key,
    saveChange,
    cancelChange,
  } = useRoleConfig({ item: roleDetails.item });

  /**
   * Header buttons for the card
   * @type {JSX.Element[]}
   */
  const headerButtons = [<RolePreviewButton roleConfig={roleConfig} />];

  const display = (
    <Dashboard item={roleDetails?.item} title={"Role Details"} headerButtons={headerButtons} key={key}>
      <DataLoader isLoading={roleDetails.isLoading} isEnoughData={!isNullOrUndefined(roleDetails?.item?.roleConfig)}>
        <Grid container spacing={2}>
          <Grid item lg={4}>
            <Grid container spacing={2}>
              <Grid item lg={12}>
                <DashboardCard title={"General Info"} style={{ height: "100%" }}>
                  {roleDetails.tableDisplay}
                </DashboardCard>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={8}>
            <DashboardCard
              title={"Role Configuration"}
              style={{ height: "100%" }}
              headerButtons={
                isChanged && [
                  <SaveChangesButton key={0} onClick={saveChange} style={{ marginRight: "1em" }} />,
                  <CancelChangesButton onClick={cancelChange} />,
                ]
              }
            >
              <ModuleSections
                precedence={roleDetails?.item?.precedence}
                roleConfig={roleConfig}
                handleModuleChange={handleModuleChange}
                handleResourceChange={handleResourceChange}
              />
            </DashboardCard>
          </Grid>
        </Grid>
      </DataLoader>
    </Dashboard>
  );

  return {
    display,
  };
};
