import TabCard from "../../../../utils/GenericComponents/TabCard";
import { WIDGET_TYPE } from "@rivial-security/widget-utils";
import React from "react";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import CustomWidgetSelectionTiles from "./CustomWidgetSelectionTiles";
import { TABLE } from "../../WidgetRenderer/constants/BUILT_IN_WIDGETS";
import BuiltInWidgetSelectionTiles from "./BuiltInWidgetSelectionTiles";

/**
 * Displays a tabbed card for selecting a table widget
 *
 * @param {string} organizationID - The organization ID
 * @param {function} onSelect - The function to call when a widget is selected
 * @param {boolean} isTemplate - Whether or not this is a template
 * @returns {JSX.Element}
 * @constructor
 */
const TableWidgetTabCard = ({ organizationID, onSelect, isTemplate }) => {
  return (
    <div>
      <TabCard
        items={[
          {
            icon: "icon-grid",
            title: "Built In",
            component: (
              <BuiltInWidgetSelectionTiles widgets={TABLE} organizationID={organizationID} onSelect={onSelect} />
            ),
          },
          {
            icon: "icon-wrench",
            title: "Custom",
            component: isTemplate ? (
              <h6>Dashboard Templates currently cannot contain Custom Widgets</h6>
            ) : (
              <CustomWidgetSelectionTiles
                organizationID={organizationID}
                onSelect={onSelect}
                filteredWidgetType={WIDGET_TYPE.TABLE}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default withOrganizationCheck(TableWidgetTabCard);
