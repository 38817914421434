import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 * Author: Jacob Blazina
 * Created At: N/A
 * Edits:
 *  - 7/21/19 JB: Added header signature
 *  - 8/7/19 JB: Added custom graphql
 *
 * Description: An Async Function.
 *              Deletes an exiting ControlEvidenceLink item in the Database.
 *
 */
export const deleteControlEvidenceLink = async (controlEvidenceLinkID) => {
  return ItemMutation(deleteMutation, {
    id: controlEvidenceLinkID,
  });
};

const { deleteMutation } = generateGraphql("ControlEvidenceLink", ["controlID"]);
