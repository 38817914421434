import { modules, resources } from "@rivial-security/role-utils";

import KnowBe4PhishingDetails from "../components/KnowBe4PhishingDetails";
import { TOOL_TYPES } from "@rivial-security/integration-utils";
import { enumSortComparator } from "../../../../../utils/Functions/enumSortComparator";
import { listKnowBe4PhishingTests } from "../graphql/listKnowBe4PhishingTests";
import { useDataGrid } from "../../../../../hooks/views/useDataGrid/useDataGrid";

/**
 * @description Display list of KnowBe4 Phishing Tests hook
 * @param {string} organizationID - Organization ID
 * @param {object} cardConfig - Card configuration
 * @param {object} queryConfig - Query configuration
 * @param {object} gridConfig - Grid configuration
 * @param {object} fields - Fields to display in the grid
 * @param {object} props - The props passed to the component
 * @returns {{gridDisplay: JSX.Element, data: unknown, lastSelectedItem: string, setData: (value: unknown) => void, display: JSX.Element, isLoading: boolean, createResourceComponent: JSX, ref: string, setSelectedItems: (value: (((prevState: []) => []) | [])) => void, setIsLoading: (value: (((prevState: boolean) => boolean) | boolean)) => void, setLastSelectedItem: (value: (((prevState: string) => string) | string)) => void, itemsToCheck: unknown, resetFunction: function(): void, fields: Object[], setItemsToCheck: (value: unknown) => void, selectedItems: []}}
 */
export const useKnowBe4PhishingDataGrid = ({
  organizationID,
  cardConfig = {},
  queryConfig = {},
  gridConfig = {},
  fields = [],
  ...props
}) => {
  const module = modules.TOOLS;
  const resource = resources.KNOW_BE_4_PHISHING;
  const toolType = TOOL_TYPES.KNOW_BE_4;
  const route = "#/integrations/know_be_4/phishing/";

  cardConfig = {
    title: "KnowBe4 Phishing Tests",
    headerIcon: "icon-present",
    ...cardConfig,
  };

  queryConfig = {
    organizationID,
    queryExternalApi: {
      idField: "pst_id",
      toolType,
      input: {
        query: listKnowBe4PhishingTests,
        queryInput: {
          page: 1,
          per_page: 500,
        },
      },
    },
    ...queryConfig,
  };

  const roleConfig = {
    module,
    resource,
  };

  fields = [
    {
      name: "name",
      flex: 1,
      minWidth: "200",
    },
    {
      name: "started_at",
      type: "date",
      width: "250",
      sort: {
        direction: "desc",
        priority: 1,
      },
    },
    {
      name: "opened_count",
      width: "250",
      type: "number",
    },
    {
      name: "clicked_count",
      width: "250",
      type: "number",
    },
    {
      name: "status",
      width: "250",
      type: "singleSelect",
      valueOptions: [
        {
          value: "Active",
          label: "Active",
        },
        {
          value: "Closed",
          label: "Closed",
        },
        {
          value: "Error",
          label: "Error",
        },
      ],
      sortComparator: enumSortComparator(["Active", "Closed", "Error"]),
    },
    ...fields,
  ];

  gridConfig = {
    fields,
    route,
    helpCenterRoute: "generating-a-phishing-report",
    detailsComponent: <KnowBe4PhishingDetails />,
    detailsTitle: "KnowBe4 Phishing Details",
    typename: "knowBe4Phishing",
    persistenceUUID: "f498a363-82ae-4523-8c6c-4796fc46c89e",
    organizationID,
    ...props,
    ...gridConfig,
  };

  const grid = useDataGrid({
    ...queryConfig,
    ...cardConfig,
    ...roleConfig,
    ...gridConfig,
  });

  return { ...grid };
};
