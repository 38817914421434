import React from "react";
import { useModal } from "../../../../../../hooks/views/useModal";
import { Button } from "reactstrap";
import { withOrganizationCheck } from "../../../../../../utils/Context/withOrganizationCheck";
import ReportDetails from "../../../../../Reports/ReportDetails";
import { getFullName } from "../functions/getFullName";

/**
 * @description Displays timeline information about a point of contact uploading a Report for an Evidence
 * @param {PointOfContact} pointOfContact - point of contact object
 * @param {object}  report - report object
 * @param {string} organizationID - organization ID
 * @param {string} automationID - automation ID
 * @param {string} pointOfContact.firstName - point of contact first name
 * @param {string} pointOfContact.lastName - point of contact last name
 * @returns {JSX.Element}
 */
const ReportActivity = ({ pointOfContact, report, organizationID, automationID }) => {
  const modal = useModal(
    "Report",
    <ReportDetails item={report} organizationID={organizationID} />,
    <Button color="ghost-success">
      <i className="icon-doc" /> Preview
    </Button>,
    { width: "90vw" },
  );

  const fullName = getFullName({ pointOfContact });

  return (
    <span>
      {modal.modal}
      {(automationID && "Automation") || fullName || "Someone"} uploaded a{" "}
      <span
        style={{
          borderBottom: "2px dashed",
          textDecoration: "none",
          cursor: "pointer",
        }}
        title={"View Report"}
        onClick={() => modal?.setModalIsOpen(true)}
      >
        Report
      </span>
    </span>
  );
};

export default withOrganizationCheck(ReportActivity);
