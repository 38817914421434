import { useObservationDataGrid } from "../hooks/useObservationDataGrid";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * @description Component used in the Findings Dashboard to display Observations
 * @param {object} props - The props for the component
 * @returns {JSX.Element}
 */
const ObservationsDataGrid = (props) => {
  return useObservationDataGrid(props).display;
};

export default withOrganizationCheck(ObservationsDataGrid);
