import React from "react";
import { useForm } from "../../../../../../../hooks/views/useForm/useForm";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";
import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import { decimalFormFieldConfig } from "../../../../../../../hooks/views/useForm/constants/decimalFormFieldConfig";
import { useRiskControlImporter } from "../hooks/useRiskControlsImporter";
import ControlCategoriesDataGrid from "../../ControlCategoriesDataGrid";

/**
 * A component for creating a new Risk Control and attaching it to a Risk Control Category
 * @param {string} organizationID - the ID of the organization
 * @param {string} controlCategoryID - the ID of the Risk Control Category to attach this sub control to
 * @param {function} resetFunction - calls an external reset function after form completion
 * @param {function} toggleModal - calls an external toggleModal function after form completion
 * @param {function} getNewItem - calls an external getNewItem function after form completion
 * @param {string} module - the module used for role checking
 * @param {string} resource - the resource used for role checking
 * @param {boolean} disableRoleChecking - if true, disables role checking
 * @param {boolean} isAutomation - if this is an Automation Template, will disable the Control Category selection grid
 * @param {boolean} isTemplate - if this is an Automation Template, will disable the Control Category selection grid
 * @param {object} ...props - any other props to pass to the form
 * @returns {JSX.Element}
 * @constructor
 */
const CreateControlCategorySubControl = ({
  organizationID,
  controlCategoryID,
  resetFunction,
  toggleModal,
  getNewItem,
  module = modules.RISK,
  resource = resources.RISK_CONTROL_CATEGORY,
  disableRoleChecking = false,
  isAutomation,
  isTemplate,
  ...props
}) => {
  const { createMutation } = generateGraphql("RiskControl", [
    "statementNumber",
    "name",
    "costOfControl",
    "strengthRating",
    "implementationRating",
    "outsourced",
  ]);

  const importer = useRiskControlImporter({
    organizationID,
    controlCategoryID,
  });

  const formHook = useForm({
    getNewItem,
    organizationID,
    mutation: createMutation,
    module,
    resource,
    disableRoleChecking,
    typename: "RiskControl",
    header: "Add a Risk Control",
    resetFunction,
    toggleModal,
    isTemplate,
    fieldConfig: {
      statementNumber: {
        label: "Statement Number",
        tooltip: "A unique Identifier for this control inside this control category",
        required: true,
      },
      name: {
        label: "Name",
        required: true,
      },
      costOfControl: {
        label: "Cost of Control (dollars)",
        inputType: "number",
        defaultValue: 0.0,
        numberConfig: {
          min: 0.0,
          step: 1,
        },
        required: true,
      },
      strengthRating: {
        ...decimalFormFieldConfig,
        label: "Strength Rating (decimal)",
        tooltip:
          "This is the percentage that this control reduces the likelihood of a Risk occurring. Please enter a decimal between 0.0 - 1.0",
        inputType: "number",
        required: true,
      },
      implementationRating: {
        ...decimalFormFieldConfig,
        label: "Implementation Rating (decimal)",
        tooltip:
          "This is the percentage corresponding to how well a Control is being implemented. Please enter a decimal between 0.0 - 1.0",
        inputType: "number",
        required: true,
      },
      outsourced: {
        label: "Is this control outsourced?",
        inputType: "switch",
        defaultValue: false,
      },
      controlCategoryID: {
        label: "Control Category ID",
        defaultValue: controlCategoryID,
        inputType: "item-select",
        itemSelectConfig: {
          grid: (
            <ControlCategoriesDataGrid
              organizationID={organizationID}
              isTemplate={props.isTemplate}
              gridDisplay={true}
            />
          ),
          typename: "RiskControlCategory",
        },
        disabled: isAutomation && isTemplate, // if this is an Automation Template, need to disable this selection
      },
      keyControl: {
        label: "Is this a key control?",
        inputType: "switch",
        defaultValue: false,
      },
    },
    headerButtons: [importer.modalButton],
    ...props,
  });

  return <>{formHook.display}</>;
};

export default withOrganizationCheck(CreateControlCategorySubControl);
