import React, { useContext, useEffect, useState } from "react";
import { MonteCarloContext } from "../../../../../../context/MonteCarloContext";
import { UncontrolledTooltip } from "reactstrap";
import { getControlEffectiveness } from "@rivial-security/risk-calc-utils";
import ReturnOnInvestment from "../../../../../../../utils/components/ReturnOnInvestment";
import { formattedDollar, formattedPercent } from "@rivial-security/func-utils";
import { withOrganizationCheck } from "../../../../../../../../../utils/Context/withOrganizationCheck";

const MonteCarloLossMitigated = ({ item, isAdmin, system, resetFunction, isNonStandard, override, style = {} }) => {
  const monteCarloContext = useContext(MonteCarloContext);

  const [totalEffectiveness, setTotalEffectiveness] = useState(1);
  const [controlEffectiveness, setControlEffectiveness] = useState(0);

  const [percentOfTotalEffectiveness, setPercentOfTotalEffectiveness] = useState(null);

  const [totalMitigated, setTotalMitigated] = useState(0);
  const [lossMitigated, setLossMitigated] = useState(0);

  useEffect(() => {
    if (item && monteCarloContext.monteCarloResults && monteCarloContext.monteCarloInput) {
      // Get System, ControlCategory, and Risk information for this Risk Control
      const controlCategory = item && item.controlCategory;
      const systemRiskItems = system && system.risks && system.risks.items;
      const riskLink = systemRiskItems.find((item) => item.risk.controlCategoryID === controlCategory.id);
      const risk = riskLink && riskLink.risk;

      // Get Risk information from Monte Carlo context
      const riskStats = monteCarloContext.monteCarloResults.riskStats;
      const riskStat = risk && riskStats && riskStats.find((item) => item.id === risk.id);
      const totalInherent = riskStat && riskStat.inherentRisk;
      const totalResidual = riskStat && riskStat.residualRisk;

      const totalEffectiveness = riskStat ? parseFloat(riskStat.currentControlEffectiveness) : 0;
      setTotalEffectiveness(totalEffectiveness);

      const controlEffectiveness = getControlEffectiveness(item, system);
      setControlEffectiveness(controlEffectiveness);

      const percentOfTotalEffectiveness = controlEffectiveness / totalEffectiveness;
      setPercentOfTotalEffectiveness(percentOfTotalEffectiveness);

      const totalMitigated = totalInherent - totalResidual;
      setTotalMitigated(totalMitigated);

      const lossMitigated = totalMitigated * percentOfTotalEffectiveness;
      setLossMitigated(lossMitigated);
    }
  }, [item, monteCarloContext.monteCarloResults, monteCarloContext.monteCarloInput, system]);

  const costOfControl = isNonStandard && override ? override["costOfControl"] : item["costOfControl"];

  return (
    <div style={style.div}>
      <>
        {/*<strong>*/}
        {/*  Percentage of Total Control Effectiveness*/}
        {/*  <i*/}
        {/*    id={"percentOfEffectInfo" + item.id}*/}
        {/*    className="icon-question"*/}
        {/*    style={style.icon}*/}
        {/*  />*/}
        {/*  <UncontrolledTooltip placement="right" target={"percentOfEffectInfo" + item.id}>*/}
        {/*    {` Effectiveness of this Control = [${formattedPercent(controlEffectiveness)}] / Total Control Effectiveness of all Controls [${formattedPercent(totalEffectiveness)}]`}*/}
        {/*  </UncontrolledTooltip>*/}
        {/*</strong>*/}
        {/*<div style={style.field}>*/}
        {/*  {*/}
        {/*    formattedPercent(percentOfTotalEffectiveness)*/}
        {/*  }*/}
        {/*</div>*/}
        <strong>
          Risk Mitigation
          <i id={`lossMitigatedInfo${item.id}`} className="icon-question" style={style.icon} />
          <UncontrolledTooltip placement="right" target={`lossMitigatedInfo${item.id}`}>
            {`The risk mitigated by this control = Percent of Effectiveness [${formattedPercent(
              percentOfTotalEffectiveness,
            )}] x Total Risk Mitigated of all Controls [${formattedDollar(totalMitigated)}]`}
          </UncontrolledTooltip>
        </strong>
        <div style={style.field}>{formattedDollar(lossMitigated)}</div>
        <br />
        <strong>
          Return on Investment
          <i id={`returnOnInvestmentInfo${item.id}`} className="icon-question" style={style.icon} />
          <UncontrolledTooltip placement="right" target={`returnOnInvestmentInfo${item.id}`}>
            {`Return on investment of this Control = Risk Mitigated [${formattedDollar(
              lossMitigated,
            )}] - Cost of this Control [${formattedDollar(costOfControl)}]`}
          </UncontrolledTooltip>
        </strong>
        <div style={style.field}>
          <ReturnOnInvestment amount={lossMitigated} cost={costOfControl} />
        </div>
      </>
    </div>
  );
};

export default withOrganizationCheck(MonteCarloLossMitigated);
