import { InvalidParamError } from "../../../../analytics/CustomError";
import { handleVendorReviewMutation } from "./handleVendorReviewMutation";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { setupControlCategories } from "./setupControlCategories";

/**
 * Creates a Vendor Solution Review.
 *  - creates the item in the database
 *  - duplicates and attaches controlCategories
 */
export const createVendorSolutionReview = async (vendorSolution, setStepStatus, organizationID) => {
  /**
   * Performing this check here so that it doesn't  have to be done each time
   */
  if (isNullOrUndefined(setStepStatus) || typeof setStepStatus !== "function") {
    setStepStatus = () => {};
  }

  if (isNullOrUndefined(organizationID)) {
    throw new InvalidParamError("organizationID");
  }

  if (isNullOrUndefined(vendorSolution)) {
    throw new InvalidParamError("vendorSolution");
  }

  const newVendorReview = await handleVendorReviewMutation(vendorSolution, organizationID);

  setStepStatus(0, "complete");
  setStepStatus(1, "inProgress");

  await setupControlCategories(organizationID, newVendorReview);

  setStepStatus(1, "complete");

  return newVendorReview;
};
