import React from "react";
import { generateGraphql } from "@rivial-security/generategraphql";
import { OBSERVATION } from "../constants/OBSERVATION";
import GenericEditFieldV3 from "../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";
import { GENERIC_FIELD_TYPES } from "../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import { fieldContexts } from "../../../../enums/fieldContexts";

/**
 * Displays the Needs Decision boolean field for an Observation.
 * @param {OBSERVATION} item - The Observation to display the Needs Decision field for.
 * @param {function} updateItemById - The function to update the Observation in the parent component.
 * @param {string} fieldContext - The context of the field, used to determine if a tooltip should be displayed
 * @param {object} ...props - The props to pass to GenericEditFieldV3.
 * @returns {JSX.Element}
 * @constructor
 */
const NeedsDecision = ({ item, updateItemById, fieldContext, ...props }) => {
  const module = OBSERVATION.module;
  const resource = OBSERVATION.resource;
  const field = OBSERVATION.fields.needsDecision;

  return (
    <GenericEditFieldV3
      item={item}
      module={module}
      resource={resource}
      field={field}
      mutation={generateGraphql(OBSERVATION.typename, [field]).updateMutation}
      inputType={GENERIC_FIELD_TYPES.SWITCH}
      updateItemById={updateItemById}
      tooltip={fieldContext !== fieldContexts.GRID ? OBSERVATION.fieldConfig.needsDecision.description : undefined}
      {...props}
    />
  );
};

export default NeedsDecision;
