import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import React, { useContext, useState } from "react";
import { modules, resources } from "@rivial-security/role-utils";

import { InfoLogger } from "@utils/EventLogger";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { WorkflowContext } from "../../../../hooks/views/useWorkflow";
import { generateGraphql } from "@rivial-security/generategraphql";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";

/**
 * Acts as an enum for completion states, values are the prefix to display in something like a badge
 */
export const completionType = {
  NONE: "Not",
  PARTIALLY: "Partially",
  FULLY: "Fully",
};

/**
 * Used to display the completion status of a control category, with a dropdown able to change the status of all associated sub controls
 * @param {string} props.color - one of reactstrap color types to properly style the dropdown button
 * @param {string} props.text - text to display in the badge/button
 * @param {string} props.suffix - completion type description ("in Place" or "Audited"), used to populate the
 * @param {string} props.status - "Not", Partially", or "Fully" depending on the category's level of completion
 * @param {number} props.index - the index of control category in WorkflowContext (-1 if not present: no dropdown will be available)
 */
const CompletionBadge = (props) => {
  const module = props.module || modules.VENDORS;
  const resource = props.resource || resources.VENDOR_REVIEW;
  const checkPermissions = useCheckPermissions({
    module,
    resource,
    disableRoleChecking: props.disableRoleChecking,
  });

  const [isOpen, setIsOpen] = useState(false);
  const context = useContext(WorkflowContext);

  const toggle = () => {
    if (props.index !== null && props.index !== undefined && props.index !== -1) {
      setIsOpen(!isOpen);
    }
  };

  const ableToBulkEdit = () => {
    if (
      context &&
      context.updateControlCategory &&
      props.index !== null &&
      props.index !== undefined &&
      props.index !== -1 &&
      context.controlCategories &&
      Array.isArray(context.controlCategories) &&
      context.controlCategories.length > props.index &&
      context.controlCategories[props.index] &&
      context.controlCategories[props.index].subControls &&
      context.controlCategories[props.index].subControls.items
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleMutation = async (input) => {
    return await ItemMutation(
      generateGraphql("VendorSubControl", ["inPlace, audited", "name", "statementNumber"]).updateMutation,
      input,
    );
  };

  const setInPlace = (isInPlace) => {
    if (ableToBulkEdit()) {
      const promises = [];
      const subControls = [];
      for (const control of context.controlCategories[props.index].subControls.items) {
        const newControl = {
          id: control.id,
          inPlace: isInPlace,
          audited: !isInPlace ? false : control.audited,
          name: control.name,
          statementNumber: control.statementNumber,
        };
        promises.push(handleMutation(newControl));
        subControls.push({
          ...newControl,
          documentLinks: control.documentLinks,
        });
      }

      Promise.allSettled(promises).then(() => {
        context.updateControlCategory(subControls, props.index);
        InfoLogger("Finished updating controls in place field from badge dropdown!");
      });
    }
  };

  const setAudited = (isAudited) => {
    if (ableToBulkEdit()) {
      const promises = [];
      const subControls = [];
      for (const control of context.controlCategories[props.index].subControls.items) {
        const newControl = {
          id: control.id,
          inPlace: isAudited ? true : control.inPlace,
          audited: isAudited,
          name: control.name,
          statementNumber: control.statementNumber,
        };
        promises.push(handleMutation(newControl));
        subControls.push({
          ...newControl,
          documentLinks: control.documentLinks,
        });
      }

      Promise.allSettled(promises).then(() => {
        context.updateControlCategory(subControls, props.index);
        InfoLogger("Finished updating controls audited from badge dropdown!");
      });
    }
  };

  const isInPlaceBadge = props.suffix && props.suffix === "in Place";
  const isAuditedBadge = props.suffix && props.suffix === "Audited";

  return (
    <ButtonDropdown disabled={context?.readOnly} onClick={(e) => e.stopPropagation()} isOpen={isOpen} toggle={toggle}>
      <DropdownToggle color={props && props.color} caret size="sm">
        {props && props.text ? props.text : "Completion"}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          disabled={(props.status && props.status === completionType.FULLY) || !checkPermissions.resource.update}
          onClick={() => {
            if (isInPlaceBadge) {
              setInPlace(true);
            }
            if (isAuditedBadge) {
              setAudited(true);
            }
          }}
        >
          Fully {props.suffix ? props.suffix : "Complete"}
        </DropdownItem>
        <DropdownItem
          disabled={(props.status && props.status === completionType.NONE) || !checkPermissions.resource.update}
          onClick={() => {
            if (isInPlaceBadge) {
              setInPlace(false);
            }
            if (isAuditedBadge) {
              setAudited(false);
            }
          }}
        >
          Not {props.suffix ? props.suffix : "Complete"}
        </DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  );
};

/**
 * Generates a text character based on completion enum inside of completion object
 * @param {object} completion - object with status property that stores completionType enum
 * @return {string} - "X" if status is unknown or NONE, "-" if status if Partially, "✓" if status is FULLY
 */
export const getTextSymbol = (completion) => {
  //determine symbol based of completion amount
  let symbol = "X";
  if (completion && completion.status) {
    if (completion.status === completionType.FULLY) {
      symbol = "✓";
    } else if (completion.status === completionType.PARTIALLY) {
      symbol = "-";
    }
  }

  return symbol;
};

/**
 * Used to display completion status information for control categories in vendor reviews
 */
export const useVendorCompletionBadges = ({ module, resource, disableRoleChecking }) => {
  /**
   * Function to get a badge that fits the completion status of a specific attribute
   * @param {number} completion.percentage - completion percentage, number from 0 to 100
   * @param {string} completion.status - The prefix word to use in the badge (to get proper colors must use completionType "enum" object above)
   * @param {string} suffix - a name for the completion attribute such as "in Place" or "Audited"
   * @returns {*}
   */
  const getBadge = (completion, suffix = "", index = -1) => {
    const initSuffix = suffix;

    //determine badge color (colors are based of reactstrap options)
    let color = "danger";
    if (completion && completion.status) {
      if (completion.status === completionType.FULLY) {
        color = "success";
      } else if (completion.status === completionType.PARTIALLY) {
        color = "warning";
        if (!isNullOrUndefined(completion?.percentage)) {
          //Checks if percentage is given as a formatted string already
          if (Number.isFinite(completion.percentage)) {
            suffix += ` (${Math.round(completion.percentage)}%)`;
          } else {
            suffix += ` (${completion.percentage})`;
          }
        }
      }
    }

    return (
      <CompletionBadge
        color={color}
        text={
          completion && completion.status ? `${completion.status} ${suffix !== "" ? suffix : "Complete"}` : "Completion"
        }
        suffix={initSuffix}
        status={completion && completion.status ? completion.status : completionType.PARTIALLY}
        index={index}
        module={module}
        resource={resource}
        disableRoleChecking={disableRoleChecking}
      />
    );
  };

  return { completionType, getBadge, getTextSymbol };
};
