import React from "react";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { modules, resources } from "@rivial-security/role-utils";
import NestedDocumentsCard from "../../../Compliance/Documents/components/NestedDocumentsCard";

/**
 * Displays Documents for a Source
 * @param {object} item - the source to show documents for
 * @param {string} organizationID - current organization ID
 * @returns {JSX.Element}
 */
const SourceDocuments = ({ item, organizationID }) => {
  return (
    <NestedDocumentsCard
      item={item}
      module={modules.GOVERNANCE}
      resource={resources.SOURCE}
      parentTypename={resources.SOURCE}
      connectionIDField={"sourceID"}
      organizationID={organizationID}
    />
  );
};

export default withOrganizationCheck(SourceDocuments);
