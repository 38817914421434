import { generateGraphql } from "@rivial-security/generategraphql";
import { QueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { deleteVendorSolution } from "../../VendorSolutions/functions/deleteVendorSolution";

/**
 * Deletes all of vendor information from the database, including all of the solutions associated with that vendor
 * @param {object} item
 * @param {string} item.id
 * @returns {Promise<void>}
 */
export const deleteVendor = async (item) => {
  const fields = ["id", "vendorSolutions"];
  const nestedFields = {
    vendorSolutions: `
      (limit: 1000) {
        items
        {
          id
        }
      }
    `,
  };

  const { getQuery, deleteMutation } = generateGraphql("Vendor", fields, nestedFields);

  const vendorItem = await QueryGetItem({ query: getQuery, itemId: item.id });
  if (
    vendorItem &&
    vendorItem.vendorSolutions &&
    vendorItem.vendorSolutions.items &&
    Array.isArray(vendorItem.vendorSolutions.items)
  ) {
    for (const solution of vendorItem.vendorSolutions.items) {
      await deleteVendorSolution(solution);
    }
  }

  await ItemMutation(deleteMutation, { id: item.id });
};
