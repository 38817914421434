import { generateGraphql } from "@rivial-security/generategraphql";
import { ItemQuery } from "../../../../utils/Functions/Graphql/ItemQuery";
import { formatMetricData } from "@rivial-security/metric-utils";

/**
 * @description
 * Converts a MetricType into chart data for SyncFusion's "Charts" component.
 *
 * Supported types: - Line
 *
 * @author Jacob Blazina
 * @function
 * @async
 * @param {object} metricType - The MetricType object
 * @param {string} metricType.id - We only need the ID here, this function performs it's own query
 * @returns {Promise<object[]>}
 *
 * @example
 * const data = await convertMetricData({id: "metricTypeID123"})
 * // returns [{date, value}, ...]
 */
export const convertMetricData = async (metricType) => {
  // get all metrics for this metric type (between date range?)
  const { getQuery } = generateGraphql("MetricType", ["name", "format", "metrics"], {
    metrics: "(limit: 500){ items { id name ownerGroup date level data { format value } } }",
  });

  const metricTypeQuery = await ItemQuery(getQuery, metricType.id);

  const res = [];

  const metrics =
    metricTypeQuery && metricTypeQuery.metrics && metricTypeQuery.metrics.items ? metricTypeQuery.metrics.items : [];

  metrics && metrics.sort((a, b) => new Date(a.date) - new Date(b.date));

  for (const metric of metrics) {
    res.push({
      date: new Date(metric.date).toLocaleString(),
      value: formatMetricData(metric.data),
    });
  }

  return res;
};
