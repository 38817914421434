import { API, graphqlOperation } from "@aws-amplify/api";

import { InfoLogger } from "@utils/EventLogger";
import { PolicyVersionStatus } from "../constants/PolicyVersionStatus";
import { generateGraphql } from "@rivial-security/generategraphql";

const { updateMutation: updatePolicyVersion } = generateGraphql("PolicyVersion");

/**
 * Author: Anatoli Railean
 * Created At: 11/18/19
 * Description: Approves a Policy Version resource in the DB.
 *
 */
export const ApprovePolicyVersion = async (policyVersion, policyVersionList, approvedBy, approvalDate) => {
  const UpdatePolicyVersion = () => {
    API.graphql(
      graphqlOperation(updatePolicyVersion, {
        input: {
          id: policyVersion.id,
          status: "approved",
          approvedBy: approvedBy ? approvedBy : undefined,
          approvalDate: approvalDate ? approvalDate : undefined,
        },
      }),
    ).then(({ data }) => {
      if (data && data.updatePolicyVersion) {
        InfoLogger(`Policy Version ${data.updatePolicyVersion.id} was Successfully Approved`);
      }
    });
  };

  if (window.confirm(`Are you sure you want to Approve ${policyVersion.id} Policy Version?`)) {
    policyVersionList.map((item) => {
      if (item.status === "approved") {
        API.graphql(
          graphqlOperation(updatePolicyVersion, {
            input: {
              id: item.id,
              status: PolicyVersionStatus.OLD,
            },
          }),
        ).then(({ data }) => {
          if (data && data.updatePolicyVersion) {
            InfoLogger(`Policy Version ${data.updatePolicyVersion.id} was Successfully marked as old`);
          }
        });
      }
    });

    UpdatePolicyVersion();
  }
};
