import { modules, resources } from "@rivial-security/role-utils";

import ControlCategoryEffectiveness from "../components/ControlCategoryEffectiveness";
import ControlCategoryStrength from "../components/ControlCategoryStrength";
import ControlCategorySubControls from "../components/ControlCategorySubControls";
import ControlCategorySystems from "../components/ControlCategorySystems";
import KeyPerformanceIndicators from "../components/SubControls/customFields/KeyPerformanceIndicators";
import React from "react";
import { generateGraphql } from "@rivial-security/generategraphql";
import { realTimeRiskGQL } from "@rivial-security/risk-calc-utils";
import { useAccordion } from "../../../../../hooks/views/useAccordion/useAccordion";
import { useDetailsCard } from "../../../../../hooks/views/useDetailsCardV2";

/**
 * Displays the detail information for a specific Risk Control Category
 * aka useRiskControlCategoryDetails
 * @param {string} itemId - the ID of the Risk Control Category
 * @param {string} organizationID - ownerGroup ID of the Organization, Template, or OperationTeam
 */
export const useControlCategoryDetails = ({ itemId, organizationID, updateItemById }) => {
  const module = modules.RISK;
  const resource = resources.RISK_CONTROL_CATEGORY;

  const { updateMutation } = generateGraphql("RiskControlCategory", ["name"]);

  const fields = ["name", "description", "strength", "effectiveness", "controlStrength", "controlEffectiveness"];

  const customFields = [
    {
      field: "controlStrength",
      component: <ControlCategoryStrength />,
      isComputed: true,
      tooltip: "The strength statistics of sub-controls in this control category.",
    },
    {
      field: "controlEffectiveness",
      component: <ControlCategoryEffectiveness />,
      isComputed: true,
      tooltip:
        "The effectiveness statistics of sub-controls in this control category. Effectiveness of each control is calculated by multiplying the strength rating by the implementation rating.",
    },
  ];

  const detailsConfig = {
    fields,
    updateMutation,
    customFields,
  };

  const queryConfig = {
    query: realTimeRiskGQL.getRiskControlCategory_riskConfig,
    itemId: itemId,
  };

  const config = {
    header: "Control Category Details",
    disableRoute: true,
  };

  const detailsCard = useDetailsCard({
    queryConfig,
    detailsConfig,
    config,
    module,
    resource,
    updateItemById,
  });

  const accordion = useAccordion({
    items: [
      {
        title: "General Information",
        subTitle: "Edit Name and Description",
        icon: "icon-list",
        component: detailsCard.tableDisplay,
        defaultExpanded: true,
      },
      {
        title: "Sub-Controls",
        subTitle: "Associated Risk Controls",
        icon: "icon-list",
        component: (
          <ControlCategorySubControls
            item={detailsCard.item}
            subControls={detailsCard?.item?.subControls || []}
            organizationID={organizationID}
            controlCategoryID={itemId}
            resetFunction={detailsCard.resetFunction}
          />
        ),
        defaultExpanded: true,
      },
      {
        title: "Information Systems",
        subTitle: "Associated Information Systems",
        icon: "icon-screen-desktop",
        component: <ControlCategorySystems item={detailsCard.item} organizationID={organizationID} />,
      },
      {
        title: "Key Performance Indicators",
        subTitle: "Metrics and performance thresholds from associated Sub-Controls",
        icon: "icon-energy",
        component: <KeyPerformanceIndicators riskControlCategory={detailsCard.item} organizationID={organizationID} />,
      },
    ],
  });

  const display = accordion.display;

  return {
    ...detailsCard,
    display,
  };
};
