import React from "react";
import RiskControlCategories from "./RiskControlCategories";

/**
 * Displays Risk Control Categories for System Details
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Controls = (props) => {
  return (
    <div>
      <RiskControlCategories
        Context={props.Context}
        viewType={props.viewType}
        isAdmin={true}
        disableEdits={false}
        printView={false}
        system={props.system}
        resetFunction={props.resetFunction}
        organizationID={props.organizationID}
        resetKey={props.resetKey}
        disableRoleChecking={props.disableRoleChecking}
        isTemplate={props.isTemplate}
      />
    </div>
  );
};

export default Controls;
