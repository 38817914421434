import React, { useState } from "react";
import { Input, InputGroup, InputGroupAddon } from "reactstrap";
import useListQuery from "../../../../../hooks/graphql/useListQuery";
import { listControlSets_simple } from "../graphql/__controlSetGQL";

export const useControlSetSelection = (organizationID, allowAllControlsOption = false) => {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedControlSet, setSelectedControlSet] = useState(allowAllControlsOption ? "all_controls" : "none");

  const controlSetQueryHook = useListQuery({
    query: listControlSets_simple,
    organizationID,
    disableRoleChecking: true,
  });

  const handleSelect = (index) => {
    setSelectedIndex(index);
    setSelectedControlSet(controlSetQueryHook.list[index]);
  };

  const display = (
    <InputGroup size="sm">
      <InputGroupAddon addonType="prepend">Control Framework:</InputGroupAddon>
      <Input
        type="select"
        bsSize="sm"
        name="select"
        id="controlSet"
        value={selectedIndex === -1 ? selectedControlSet : selectedIndex}
        onChange={(e) => handleSelect(e.target.value)}
      >
        {allowAllControlsOption ? (
          <option value="all_controls">All Controls</option>
        ) : (
          <option disabled value="none">
            Please select a Control Framework
          </option>
        )}
        {controlSetQueryHook.list.map((controlSet, index) => (
          <option key={index} value={index}>
            {controlSet.name}
          </option>
        ))}
      </Input>
    </InputGroup>
  );

  return {
    display,
    selectedControlSet,
    selectedControlSetId: selectedControlSet.id,
    selectedControlSetName: selectedControlSet.name,
  };
};
