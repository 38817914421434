import { TargetVulnerabilityHistoryType } from "../../../../typedefs/Testing/Vulnerability/Vulnerability";
import InitialStatus from "../../Vulnerabilities/components/VulnerabilityTimelineContent/components/InitialStatus";
import TargetStatus from "../../Vulnerabilities/components/VulnerabilityTimelineContent/components/TargetStatus";
import PriorityChangeStatus from "../../Vulnerabilities/components/VulnerabilityTimelineContent/components/PriorityChangeStatus";
import TagStatus from "../../Vulnerabilities/components/VulnerabilityTimelineContent/components/TagStatus";
import AssessmentStatus from "../../Vulnerabilities/components/VulnerabilityTimelineContent/components/AssessmentStatus";
import React from "react";

/**
 * Renders the timeline content based on the TargetVulnerabilityHistoryType
 * @param {string} type - the TargetVulnerabilityHistory type
 * @param {object} item - the TargetVulnerabilityHistory item
 * @param {boolean} isGrouped - is the timeline grouped by assessment
 * @param {object} [vulnerability] - the vulnerability object (when in target details
 * @param {object} [target] - the target object (when in vulnerability details)
 * @returns {*|JSX.Element}
 */
export const getTimelineContent = ({ type, item, isGrouped, vulnerability, target }) => {
  switch (type) {
    case TargetVulnerabilityHistoryType.INITIAL:
      return <InitialStatus item={item} />;
    case TargetVulnerabilityHistoryType.STATUS:
      return <TargetStatus item={item} />;
    case TargetVulnerabilityHistoryType.PRIORITY:
      return <PriorityChangeStatus item={item} />;
    case TargetVulnerabilityHistoryType.TAG:
      return <TagStatus item={item} />;
    case TargetVulnerabilityHistoryType.ASSESSMENT:
      return <AssessmentStatus item={item} isGrouped={isGrouped} vulnerability={vulnerability} target={target} />;
    default:
      return type;
  }
};
