import { isNullOrUndefined, tryParse } from "@rivial-security/func-utils";

import { Role } from "@rivial-security/schema-types";

export interface AccessResourcesByRoleInput<T> {
  array: T[];
  resource: string;
  role: Partial<Role>;
}

interface AWSRoleResources {
  read: boolean;
}

export const accessResourcesByRole = <T extends { id?: string }>({
  array,
  resource,
  role,
}: AccessResourcesByRoleInput<T>): T[] => {
  // Check arguments
  if (!resource || !role?.resources) {
    return array;
  }

  // Check if current user has a role resources object
  const roleResources = tryParse(role?.resources) as Record<string, AWSRoleResources>;

  // Check if user's role have resource access in role config
  const hasAccess = roleResources?.[resource]?.read;
  if (hasAccess || isNullOrUndefined(hasAccess)) {
    return array;
  }
  return [];
};
