/**
 * Author: Anatoli Railean
 * Last Edit: 07/8/20 AR: Added required prop for the name field to be true
 */

import {
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  UncontrolledCollapse,
  Progress,
  Row,
  Col,
  Button,
} from "reactstrap";
import UncontrolledAccordionIcon from "../../../../../../utils/GenericComponents/UncontrolledAccordionIcon";
import React, { useEffect, useState } from "react";
import ProgramSectionDetails from "./ProgramSectionDetails";
import { round } from "../../../../../../utils/Functions/Number/round";
import GenericEditField from "../../../../../../utils/GenericComponents/GenericEditFieldV2";
import { useCheckPermissions } from "../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { DeleteProgramSection } from "../functions/DeleteProgramSection";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

const { updateMutation: updateProgramSection } = generateGraphql("ProgramSection", ["name"]);

/**
 * Displays Program Section row
 *
 * @param item: ProgramSection object
 * @param resetFunction: Reset parent Program Blueprint
 * @param {boolean} [isTemplate = false] - determines if this UI needs to be trimmed down to display as a Template
 * @returns {*}
 * @constructor
 */

const ProgramSectionRow = ({
  item,
  resetFunction,
  isTemplate = false,
  module = modules.GOVERNANCE,
  resource = resources.PROGRAM_SECTION,
  disableRoleChecking = false,
  ...props
}) => {
  const [currentLevel, setCurrentLevel] = useState(0);
  const [goalLevel, setGoalLevel] = useState(0);

  const checkPermissions = useCheckPermissions({
    module,
    resource,
    disableRoleChecking,
  });

  useEffect(() => {
    if (item && item.currentLevel && item.currentLevel.level) {
      setCurrentLevel(item.currentLevel.level);
    } else {
      setCurrentLevel(0);
    }

    if (item && item.goalLevel && item.goalLevel.level) {
      setGoalLevel(item.goalLevel.level);
    } else {
      setGoalLevel(0);
    }
  }, [item]);

  const calculatePercent = () => {
    if (currentLevel && goalLevel) {
      const res = (currentLevel / goalLevel) * 100;

      if (isNaN(res)) {
        return 0;
      } else {
        return round(res);
      }
    }
  };

  return (
    <ListGroupItem key={item.id ? item.id : JSON.stringify(item)}>
      <ListGroupItemHeading
        id={`programSection${item && item.id}`}
        style={{
          cursor: "pointer",
        }}
      >
        <Row>
          <Col sm={5}>
            <span className="float-left">
              <GenericEditField
                item={item}
                mutation={updateProgramSection}
                module={module}
                resource={resource}
                disableRoleChecking={disableRoleChecking}
                field="name"
                required={true}
                deleteButton={
                  checkPermissions.resource.delete ? (
                    <Button
                      size="sm"
                      color="ghost-danger"
                      className="float-right btn-pill"
                      title="Delete Program Section"
                      onClick={() => {
                        if (window.confirm(`Are you sure you want to remove ${item && item.name}?`)) {
                          DeleteProgramSection(item).then(() => {
                            resetFunction && resetFunction();
                          });
                        }
                      }}
                    >
                      <i className="icon-trash" />
                    </Button>
                  ) : null
                }
              />
            </span>
          </Col>
          <Col sm={6}>
            <span className="float-left" style={{ marginRight: "1em" }}>
              Level: {currentLevel}
            </span>
            <span className="float-right" style={{ marginLeft: "1em" }}>
              Goal: {goalLevel}
            </span>
            <Progress value={calculatePercent()}>{calculatePercent()}%</Progress>
          </Col>
          <Col sm={1}>
            <UncontrolledAccordionIcon toggler={`programSection${item && item.id}`} />
          </Col>
        </Row>
      </ListGroupItemHeading>
      <ListGroupItemText>
        <UncontrolledCollapse toggler={`#programSection${item && item.id}`}>
          <div className="shadow">
            <ProgramSectionDetails
              item={item}
              resetFunction={resetFunction}
              isTemplate={isTemplate}
              module={module}
              resource={resource}
              disableRoleChecking={disableRoleChecking}
            />
            <hr />
          </div>
        </UncontrolledCollapse>
      </ListGroupItemText>
    </ListGroupItem>
  );
};

export default ProgramSectionRow;
