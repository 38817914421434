import React from "react";
import SystemImpact from "./SystemImpact/components/SystemImpact";
import ThreatAssessment from "./ThreatAssessment/components/ThreatAssessment";
import Controls from "./ControlCategories/components/Controls";
import SystemVendorReviews from "./VendorReviews/components/SystemVendorReviews";
import { useAccordion } from "@hooks/views/useAccordion/useAccordion";
import SystemOverview from "./SystemOverview/components/SystemOverview";
import RiskScorecardItem from "./RiskScorecard/components/RiskScorecardItem";
import SystemRecommendationsGrid from "./Recommendations/components/SystemRecommendationsGrid";
import SystemMonteCarloGraph from "./MonteCarlo/components/SystemMonteCarloGraph";
import { getIcon } from "@utils/Functions/Icon/getIcon";
import SystemSecurityTesting from "./SecurityTesting/components/SystemSecurityTesting";
import SystemEvidence from "./Evidence/components/SystemEvidence";
import SystemRiskChangesGrid from "./RiskChanges/components/SystemRiskChangesGrid";
import SystemQuestionnaireGrid from "@views/Risk/Systems/components/SystemAccordion/Questionnaires/Components/SystemQuestionnaireGrid";

/**
 * Displays the different elements of System Details in an Accordion fashion
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SystemAccordion = (props) => {
  const system = props.item;
  const systemQuestionnaires = props.item?.questionnaires?.items ?? [];

  const viewType = props.viewType;

  const passProps = {
    system,
    systemQuestionnaires,
    resetFunction: props.resetSystem,
    ...props,
  };

  const accordion = useAccordion({
    items: [
      {
        icon: "icon-list",
        title: "General Information",
        subTitle: "Name, hosting type, and points of contact",
        component: <SystemOverview {...passProps} />,
      },
      {
        icon: getIcon("bi:grid-3x2"),
        title: "Risk Scorecard",
        subTitle: "Enterprise, Business, and Key Risk Indicator breakout",
        component: <RiskScorecardItem {...passProps} />,
        hidden: viewType !== "analysis",
      },
      {
        icon: getIcon("fluent:convert-range-24-regular"),
        title: "System Impact",
        subTitle: "Information Assets, Records, and Impact configuration",
        component: <SystemImpact {...passProps} />,
      },
      {
        icon: getIcon("simple-line-icons:energy"),
        title: "Threat Assessment",
        subTitle: "Key Risk Indicators and Risk Estimates",
        component: <ThreatAssessment {...passProps} />,
      },
      {
        icon: getIcon("il:controls"),
        title: "Controls",
        subTitle: "Control Categories and Risk Mitigations",
        component: <Controls {...passProps} />,
      },
      {
        icon: "icon-badge",
        title: "Evidence",
        subTitle: "Evidences related to associated Risk Controls",
        component: <SystemEvidence {...passProps} />,
      },
      {
        icon: "icon-check",
        title: "Recommendations",
        subTitle: "Mitigate Risk using Return on Investment estimates",
        component: <SystemRecommendationsGrid {...passProps} />,
        hidden: viewType !== "analysis",
      },
      {
        icon: getIcon("iconoir:graph-up"),
        title: "Monte Carlo",
        subTitle: "View Monte Carlo risk simulation analysis",
        component: <SystemMonteCarloGraph {...passProps} />,
        hidden: viewType !== "analysis",
      },
      {
        icon: getIcon("icon-park-outline:change"),
        title: "Risk Changes",
        subTitle: "Updates to Risk measures",
        component: <SystemRiskChangesGrid {...passProps} />,
        hidden: viewType !== "analysis",
      },
      {
        icon: "icon-book-open",
        title: "Vendor Reviews",
        subTitle: "Security reviews for related Vendors",
        component: <SystemVendorReviews {...passProps} />,
        hidden: viewType !== "analysis",
      },
      {
        icon: getIcon("mdi:target-variant"),
        title: "Security Testing",
        subTitle: "Associated Targets and Vulnerabilities",
        component: <SystemSecurityTesting {...passProps} />,
        hidden: viewType !== "analysis",
      },
      {
        icon: "icon-question",
        title: "Questionnaires",
        subTitle: "Questionnaires related to this System",
        component: <SystemQuestionnaireGrid {...passProps} />,
      },
    ],
  });

  return <div className="shadow">{accordion.display}</div>;
};

export default SystemAccordion;
