"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getReportWidget = void 0;
const func_utils_1 = require("@rivial-security/func-utils");
const widgetType_1 = require("../../constants/widgetType");
const contextType_1 = require("../../constants/contextType");
const getWidgetType_1 = require("../utils/getWidgetType");
const getWidgetConfig_1 = require("../utils/getWidgetConfig/getWidgetConfig");
const createWidgetTable_1 = require("../reportWidgets/createWidgetTable");
const report_utils_1 = require("@rivial-security/report-utils");
const createDynamicText_1 = require("../reportWidgets/createDynamicText");
const createRenderedWidget_1 = require("../reportWidgets/createRenderedWidget");
/**
 * Returns SFDT block for the requested widget
 * @param {object} widget - the widget to generate
 * @param {object} organization - the organization for this widget
 * @param {function} runCustomQuery - function to run custom queries
 * @param {function} renderWidget - function to render the widget (convert from formatted config to base64 image string)
 */
const getReportWidget = async ({ widget, organization, renderWidget, runCustomQuery }) => {
    console.log("[getReportWidget] params - ", JSON.stringify({ widget, organization, renderWidget }));
    const type = (0, getWidgetType_1.getWidgetType)({ widget });
    console.log("[getReportWidget] type - ", { type });
    console.log("[getReportWidget] - ", { tryParse: func_utils_1.tryParse, handleTableWidget: report_utils_1.handleTableWidget });
    let config = (0, func_utils_1.tryParse)(widget?.config);
    console.log("[getReportWidget] config - ", { config });
    switch (type) {
        case widgetType_1.WIDGET_TYPE.STATIC_TEXT:
            return (0, report_utils_1.createText)(config?.text || "[No Widget Text]");
        case widgetType_1.WIDGET_TYPE.DYNAMIC_TEXT:
            return await (0, createDynamicText_1.createDynamicText)({ config, organization });
        case widgetType_1.WIDGET_TYPE.TABLE:
            return await (0, createWidgetTable_1.createWidgetTable)({ widget, handleTableWidget: report_utils_1.handleTableWidget });
        case widgetType_1.WIDGET_TYPE.IMAGE:
            return (0, report_utils_1.createImage)(config?.image);
        case widgetType_1.WIDGET_TYPE.RISK_TOLERANCE_CHART:
        case widgetType_1.WIDGET_TYPE.HISTORY_CHART:
        case widgetType_1.WIDGET_TYPE.PIE_CHART:
        case widgetType_1.WIDGET_TYPE.BAR_CHART:
        case widgetType_1.WIDGET_TYPE.RADAR_CHART:
        case widgetType_1.WIDGET_TYPE.PROGRESS_BAR:
        case widgetType_1.WIDGET_TYPE.GAUGE:
        case widgetType_1.WIDGET_TYPE.UNSUPPORTED:
            config = await (0, getWidgetConfig_1.getWidgetConfig)({ widget, contextType: contextType_1.CONTEXT_TYPE.REPORT, runCustomQuery });
            console.log("[getReportWidget] chart widget config - ", JSON.stringify({ config }));
            return (0, createRenderedWidget_1.createRenderedWidget)({ config, renderWidget });
        default:
            return (0, report_utils_1.createText)(`[Unknown Widget Type]`);
    }
};
exports.getReportWidget = getReportWidget;
