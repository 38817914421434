import React from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";

/**
 * @description A button component styled to perform the start over or cancel action.
 * Can be used to clear a form, or cancel an operation.
 * @param {object} props - The props passed to the component.
 * @returns {JSX.Element}
 * @constructor
 */
const ResetButton = (props) => {
  return (
    <>
      {props.button
        ? React.cloneElement(props.button, {
            onClick: props.resetFunction ? () => props.resetFunction() : undefined,
          })
        : props.resetFunction && (
            <Button
              size="sm"
              color="ghost-secondary"
              className={props.className || "btn-pill float-sm-right"}
              onClick={() => {
                props.resetFunction && props.resetFunction();
              }}
              title={props.title || "Refresh Data"}
              style={
                props.highlightStyle && props.isHighlighted
                  ? { ...props.highlightStyle, ...props.style }
                  : { ...props.style }
              }
            >
              {props.text && `${props.text} `}
              <i className="icon-reload" />
            </Button>
          )}
    </>
  );
};

ResetButton.propTypes = {
  button: PropTypes.object,
  resetFunction: PropTypes.func.isRequired,
};

export default ResetButton;
