import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import CustomTooltip from "../../../../../../../utils/GenericComponents/CustomTooltip";
import React from "react";
import { formattedPercent } from "@rivial-security/func-utils";
import { getControlEffectiveness } from "@rivial-security/risk-calc-utils";

const ControlEffectiveness = ({ item }) => {
  return (
    <span style={{ width: "100%" }}>
      {formattedPercent(getControlEffectiveness(item))}

      <div className={"float-right"}>
        <CustomTooltip tooltip="Control Effectiveness = Strength x Implementation" />
      </div>
    </span>
  );
};

export default withOrganizationCheck(ControlEffectiveness);
