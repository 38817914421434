import CustomDropdown from "./CustomDropdown";
import React from "react";
import { enumToDropdownData } from "@rivial-security/func-utils";
import { moduleTypes } from "../../../../views/Program/moduleTypes";

/**
 * Generic edit field handling the input of a platform module inputType: module
 * @param {string} fieldInit - name of the field being edited on the resource
 * @param {string} value - the current module state value
 * @param {function} setValue - the state update function for value
 * @returns {JSX.Element}
 */
const ModuleInputField = ({ field: fieldInit, value, setValue }) => {
  const inputConfig = {
    data: enumToDropdownData({ ENUM: moduleTypes }),
  };

  const field = fieldInit || "module";

  return <CustomDropdown field={field} value={value} setValue={setValue} inputConfig={inputConfig} />;
};

export default ModuleInputField;
