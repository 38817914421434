import {
  Maybe,
  PointOfContact,
  TargetFindingLink,
  TargetVulnerabilityStatus,
  Vulnerability,
} from "@rivial-security/schema-types";
import { VULNERABILITY_SEVERITY_LEVEL } from "../../../../../../typedefs/Testing/Vulnerability/Vulnerability";

import { ListQueryBy } from "@rivial-security/appsync-utils";
import { OrganizationContextValues } from "../../../../../../utils/Context/OrganizationContext";
import { listAssessmentVulnerabilityLinksByAssessmentID } from "../../../graphql/__targetsGQL";
import { normalizeVulnerabilityData } from "@views/Testing/Vulnerabilities/functions/normalizeVulnerabilityData";

export interface FinalizeTargetsVulnerabilitiesDataInput {
  targetFindingLinks: TargetFindingLink[];
  organizationContext: OrganizationContextValues;
  assessmentID: string;
}

type TargetVulnerability = Partial<Vulnerability> & {
  targetFindingLinkID?: string;
  status?: Maybe<TargetVulnerabilityStatus>;
  priority?: Maybe<number>;
  pointOfContact?: Maybe<PointOfContact>;
};

export const finalizeTargetsVulnerabilitiesData = async ({
  targetFindingLinks,
  organizationContext,
  assessmentID,
}: FinalizeTargetsVulnerabilitiesDataInput): Promise<Partial<TargetVulnerability>[]> => {
  const newVulnerabilities: TargetVulnerability[] = [];
  for (const targetFindingLink of targetFindingLinks) {
    const vulnerability = targetFindingLink?.vulnerability as Partial<Vulnerability>;
    const vulnerabilityID = vulnerability?.id;
    if (!vulnerabilityID) {
      continue;
    }

    const existingVulnerability = newVulnerabilities.find(
      (newVulnerability) => newVulnerability.id === vulnerabilityID,
    );
    if (existingVulnerability) {
      continue;
    }

    if (targetFindingLink?.falsePositive) {
      continue;
    }

    const showInfoVulnerabilities = organizationContext?.preferences?.testing?.showInfoVulnerabilities;
    const isInfoVulnerability = targetFindingLink?.vulnerability?.severityLevel === VULNERABILITY_SEVERITY_LEVEL.INFO;
    if (!showInfoVulnerabilities && isInfoVulnerability) {
      continue;
    }

    newVulnerabilities.push({
      ...vulnerability,
      targetFindingLinkID: targetFindingLink?.id,
      status: targetFindingLink?.status,
      priority: targetFindingLink?.priority,
      pointOfContact: targetFindingLink?.pointOfContact,
    });
  }

  const filteredVulnerabilities = await filterVulnerabilitiesForGivenAssessment(newVulnerabilities, assessmentID);
  return normalizeVulnerabilityData({
    vulnerabilities: filteredVulnerabilities,
    calculatePriority: true,
  });
};

const filterVulnerabilitiesForGivenAssessment = async (
  newVulnerabilities: Partial<Vulnerability>[],
  assessmentID?: string,
): Promise<Partial<Vulnerability>[]> => {
  if (!assessmentID) {
    return newVulnerabilities;
  }

  try {
    const assessments = await ListQueryBy<TargetFindingLink>({
      query: listAssessmentVulnerabilityLinksByAssessmentID,
      variables: { assessmentID },
    });
    return newVulnerabilities.filter((vulnerability: Partial<Vulnerability>) =>
      assessments.find((item) => item?.vulnerabilityID === vulnerability?.id),
    );
  } catch (e) {
    return newVulnerabilities;
  }
};
