import { useEffect, useState } from "react";

import { Auth } from "@aws-amplify/auth";
import { ErrorLogger } from "@utils/EventLogger";

/**
 * Author: Anatoli Railean
 * Created At: 12/06/19
 * @description A front end helper for checking if user is Admin.
 * @param {object} permissions - results from a useCheckPermissionsHook
 * @return {object} this object has the isAdmin property, and the isAdmin property is true if user is in the admin cognito group
 */
const useAdminCheck = () => {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    getUserGroup();
  }, []);

  const getUserGroup = async () => {
    await Auth.currentSession()
      .then((response) => {
        const groups = response.accessToken.payload["cognito:groups"];
        const isAdminFromSession = !!(groups && groups.includes("Admin"));
        setIsAdmin(isAdminFromSession);
      })
      .catch((err) => ErrorLogger(err));
  };

  return {
    isAdmin,
  };
};

export default useAdminCheck;
