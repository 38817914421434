import EvidenceNestedDataGrid, { useEvidenceNestedDataGrid } from "./EvidenceNestedDataGrid";
import MeetingsDataGrid from "../../../Program/Meetings/components/MeetingsDataGrid";
import { useMeetingsDataGrid } from "../../../Program/Meetings/hooks/useMeetingsDataGrid";
import React from "react";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * Displays Meetings associated with an Evidence
 * @param {object} item - evidence object
 * @param {object} recordEvidence - record evidence hook instance
 * @returns {JSX.Element}
 * @constructor
 */
const EvidenceMeetings = ({ item, recordEvidence }) => {
  return (
    <EvidenceNestedDataGrid
      item={item}
      typename={"Meeting"}
      connectionField={"meeting"}
      grid={<MeetingsDataGrid />}
      gridHook={useMeetingsDataGrid}
      recordEvidence={recordEvidence}
    />
  );
};

export const useEvidenceMeetings = ({ item, recordEvidence }) => {
  return useEvidenceNestedDataGrid({
    item,
    typename: "Meeting",
    connectionField: "meeting",
    grid: <MeetingsDataGrid />,
    gridHook: useMeetingsDataGrid,
    recordEvidence,
  });
};

export default withOrganizationCheck(EvidenceMeetings);
