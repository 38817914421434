import { useState, useEffect } from "react";
import { getMostRecentVendorReviewForVendorSolution } from "./functions/getMostRecentVendorReviewForVendorSolution";

/**
 * A hook allowing to utilize a custom key query on the vendor reviews to get the latest finalized review for a specific vendor solution
 * @param {string} vendorSolutionID - the vendor solution for which to grab a vendor review
 * @return {{vendorReview: null|object}}
 */
export const useMostRecentVendorReview = ({ vendorSolutionID } = {}) => {
  const [vendorReview, setVendorReview] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const mostRecentVendorReview = await getMostRecentVendorReviewForVendorSolution({ vendorSolutionID });
      setVendorReview(mostRecentVendorReview);
    };

    if (vendorSolutionID) {
      getData();
    } else {
      setVendorReview(null);
    }
  }, [vendorSolutionID]);

  return { vendorReview };
};
