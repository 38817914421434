import React from "react";
import { useRiskControlChangeDetails } from "../hooks/useRiskControlChangeDetails";
import { modules, resources } from "@rivial-security/role-utils";
import { withDetailsPage } from "../../../../../../utils/Context/withDetailsPage";

const RiskControlChangeDetails = ({ itemId, tableDisplay }) => {
  const module = modules.RISK;
  const resource = resources.RISK_CHANGE;

  const riskControlChangeDetails = useRiskControlChangeDetails({
    itemId,
    module,
    resource,
    tableDisplay,
  });

  return <>{riskControlChangeDetails.display}</>;
};

export default withDetailsPage(RiskControlChangeDetails, {
  title: "Risk Change Details",
});
