import { generateGraphql } from "@rivial-security/generategraphql";
import { deleteItem } from "../../../../utils/Functions/Graphql/deleteItem";

/**
 * Deletes a Custom Widget
 * @param customWidget
 */
export const deleteCustomWidget = (customWidget) => {
  if (!customWidget || !customWidget.id) {
    return null;
  }

  const { getQuery } = generateGraphql("CustomWidget", ["__typename"]);

  return deleteItem(getQuery, customWidget);
};
