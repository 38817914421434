import { Button, ButtonGroup, ClickAwayListener, Grid, Grow, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import Delete from "@mui/icons-material/Delete";
import MoreVert from "@mui/icons-material/MoreVert";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import { getResourceRoute } from "../../../../definitions/functions/getResourceRoute";
import { handleGetIcon } from "../../../../utils/Functions/Icon/getIcon";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { routeType } from "../../../../definitions/constants/routeTypes";
import { useCheckPermissions } from "../../../permissions/useCheckPermissions/useCheckPermissions";
import { useHover } from "../../useHover";
import { useStateEffect } from "../../../functional/useStateEffect";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * Displays the button for various actions related to a row in a datatable
 * - Details modal (main button)
 * - Delete / Remove / Unlink
 * - Duplicate
 * - Open in new tab
 * - Share
 * @constructor
 */
const DataGridActionButton = ({ gridContext, item, detailsModal }) => {
  const {
    guid,
    options = [],
    customActions,
    deleteButtonText = "Delete",
    deleteButtonTitle = "Delete this Resource",
    deleteFunction,
    detailsButtonText = "Open",
    detailsButtonTitle = "Open this Resource in a new Tab",
    duplicateButtonText = "Duplicate",
    duplicateButtonTitle = "Duplicate this Resource",
    duplicateItemsHook,
    deleteItemsModal,
    setSelectedIDs,
    module,
    resource,
    disableRoleChecking,
    route,
    typename,
  } = gridContext;

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleClick = () => {
    detailsModal?.openItemDetails?.(item);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleDelete = () => {
    if (typeof deleteFunction === "function" && !isNullOrUndefined(item?.id)) {
      if (typeof deleteItemsModal?.setModalIsOpen === "function" && typeof setSelectedIDs === "function") {
        setSelectedIDs((existing) => [...existing, item?.id]);
        deleteItemsModal.setModalIsOpen(true);
      }
    }
    setOpen(false);
  };

  const handleDuplicate = () => {
    if (typeof duplicateItemsHook?.setModalIsOpen === "function" && typeof setSelectedIDs === "function") {
      setSelectedIDs((existing) => [...existing, item?.id]);
      duplicateItemsHook.setModalIsOpen(true);
    }
    setOpen(false);
  };

  /**
   * Creates custom option menu items for each row.
   * If a customOption has a 'component' field, clones that,
   * or constructs a menu item dynamically using text, icon, and onClick fields
   *
   * @param {object[]} customActions - custom options passed in to the grid
   * @param {JSX.Element} customActions[].component - if passed in, returns the element directly and passes the item as a prop
   * @param {string} customActions[].title - button title
   * @param {string} customActions[].icon - button icon
   * @param {string} customActions[].text - button text
   * @param {string} customActions[].onClick - button onClick handler, passes 'item' as prop
   * @returns {JSX.Element}
   */
  const handleCustomActions = (customActions) => {
    if (Array.isArray(customActions)) {
      return (
        <span>
          {customActions.map((element, index) => {
            return (
              <MenuItem
                dense={true}
                key={`grid-custom-option-button-${guid}${index}`}
                onClick={() => {
                  if (typeof element.onClick === "function") {
                    element.onClick(item);
                  }
                }}
                title={element.title}
              >
                <ListItemIcon>{handleGetIcon(element.icon)}</ListItemIcon>
                <ListItemText>{element.text}</ListItemText>
              </MenuItem>
            );
          })}
        </span>
      );
    }
  };

  const hovered = useHover();

  const permissions = useCheckPermissions({
    module,
    resource,
    disableRoleChecking,
  });

  const suffix = item?.id;
  const definitionRoute = getResourceRoute({
    item: item,
    override: route,
    routeType: routeType.DETAILS,
    typename,
    prefix: "#/",
    suffix,
    applyPrefixToOverride: false,
    applySuffixToOverride: true,
  });

  const [enabledOptions] = useStateEffect(options, [], () => {
    const res = [];

    for (const option of options) {
      /**
       * Check if the "details" option, route and item id is passed in
       */
      if (option === "details" && definitionRoute && item?.id) {
        if (permissions.resource.read === true) {
          res.push(option);
        }
      }

      if (option === "delete" && typeof deleteFunction === "function") {
        if (permissions.resource.delete === true) {
          res.push(option);
        }
      }

      if (option === "duplicate") {
        if (permissions.resource.create === true) {
          res.push(option);
        }
      }
    }

    return res;
  });

  return (
    <Grid container direction="column" alignItems="left">
      <Grid item xs={12}>
        <ButtonGroup
          size="small"
          variant="outlined"
          color="primary"
          ref={anchorRef}
          aria-label="split button"
          {...hovered}
        >
          <Button
            data-testid={"grid-action-button"}
            size="small"
            onClick={handleClick}
            startIcon={<i className="icon-info" />}
          >
            View
          </Button>
          {(open || hovered.isHovered) && Array.isArray(enabledOptions) && enabledOptions.length > 0 && (
            <Button
              data-testid={"grid-action-button-menu"}
              color="primary"
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
              style={{ maxWidth: "5px", minWidth: "5px" }}
              title={"Click for more actions"}
            >
              <MoreVert />
            </Button>
          )}
        </ButtonGroup>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          style={{ zIndex: 9999 }} //modal z index is 1000
          transition
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" dense>
                    {enabledOptions?.map((option, index) => {
                      switch (option) {
                        case "details":
                          return (
                            <MenuItem
                              dense={true}
                              data-testid={"grid-action-button-menu-details"}
                              key={`grid-details-button-${guid}${index}`}
                              onClick={() => {
                                window.open(definitionRoute, "_blank").focus();
                              }}
                              title={detailsButtonTitle}
                            >
                              <ListItemIcon>
                                <i style={{ marginLeft: "0.25em" }} className={"icon-share-alt"} />
                              </ListItemIcon>
                              <ListItemText>{detailsButtonText}</ListItemText>
                            </MenuItem>
                          );
                        case "delete":
                          return (
                            <MenuItem
                              dense={true}
                              data-testid={"grid-action-button-menu-delete"}
                              key={`grid-delete-button-${guid}${index}`}
                              onClick={handleDelete}
                              title={deleteButtonTitle}
                            >
                              <ListItemIcon>
                                <Delete />
                              </ListItemIcon>
                              <ListItemText>{deleteButtonText}</ListItemText>
                            </MenuItem>
                          );
                        case "duplicate":
                          return (
                            <MenuItem
                              key={`grid-duplicate-button-${guid}${index}`}
                              onClick={handleDuplicate}
                              title={duplicateButtonTitle}
                            >
                              <ListItemIcon>
                                <ContentCopyIcon />
                              </ListItemIcon>
                              <ListItemText>{duplicateButtonText}</ListItemText>
                            </MenuItem>
                          );
                        default:
                          return null;
                      }
                    })}
                    {Array.isArray(customActions) && handleCustomActions(customActions)}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
};

export default withOrganizationCheck(DataGridActionButton);
