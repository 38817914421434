import { updateRiskRecommendation_recommendationList } from "../graphql/__riskRecommendationsGQL";
import CreateRecommendation from "../components/CreateRecommendation";
import React from "react";
import AssociatedControl from "../customFields/AssociatedControl";
import NumberChange from "../../../../utils/CustomFields/NumberChange";
import { generateGraphqlFields } from "@rivial-security/generategraphql";
import RecommendationDetails from "../../../Program/Recommendations/components/RecommendationDetails";
import Resolved from "../../../Program/Recommendations/customFields/Resolved";
import { enumToDropdownData, isNullOrUndefined } from "@rivial-security/func-utils";
import { deleteRecommendation } from "../../../Program/Recommendations/functions/deleteRecommendation";
import { modules, resources } from "@rivial-security/role-utils";
import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";
import { enumSortComparator } from "../../../../utils/Functions/enumSortComparator";
import { RecommendationStatus } from "../../../Program/Recommendations/enums/RecommendationStatus";

/**
 * @description Displays Risk Recommendations for an Organization
 * @param {string} organizationID - The organization ID
 * @param {object} cardConfig - The card configuration
 * @param {object} gridConfig - The grid configuration
 * @param {string} systemID - The system ID
 * @param {object} props - The props
 */
export const useRecommendationsDataGrid = ({
  organizationID,
  cardConfig = {},
  gridConfig = {},
  systemID,
  ...props
}) => {
  const module = modules.RISK;
  const resource = resources.RECOMMENDATION;

  const queryFields = ["name", "status", "resolvedAt", "data", "observations", "module"];
  const nestedFields = {
    observations: `(limit: 1000) { items { observation { module } } }`,
  };

  /**
   * @description Converts the RecommendationItem query data to fit this table
   * @param {object} data -  The data to convert
   */
  const normalizeData = (data) => {
    const res = [];

    for (const item of data) {
      if (!item) {
        continue;
      }

      const dataField = item.data ? JSON.parse(item.data) : {};

      /**
       * If a SystemID is passed in, only show for that system
       */
      if (!isNullOrUndefined(systemID)) {
        if (systemID === dataField?.riskRecommendationSystemId) {
          res.push({
            id: item.id,
            name: item.name,
            status: item.status,
            module: item.module,
            resolvedAt: item.resolvedAt,
            riskControl: dataField?.riskControl,
            residualChange: dataField?.residualChange,
            returnOnInvestment: dataField?.returnOnInvestment,
          });
        }
      } else {
        res.push({
          id: item.id,
          name: item.name,
          status: item.status,
          resolvedAt: item.resolvedAt,
          module: item.module,
          riskControl: dataField?.riskControl,
          residualChange: dataField?.residualChange,
          returnOnInvestment: dataField?.returnOnInvestment,
        });
      }
    }

    return res;
  };

  const recommendationsByOwnerGroupByModule = /* GraphQL */ `
    query RecommendationsByOwnerGroupByModule(
      $ownerGroup: String
      $module: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelRecommendationItemFilterInput
      $limit: Int
      $nextToken: String
    ) {
      recommendationsByOwnerGroupByModule(
        ownerGroup: $ownerGroup
        module: $module
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ${generateGraphqlFields(queryFields, nestedFields)}
        }
        nextToken
      }
    }
  `;

  const queryConfig = {
    query: recommendationsByOwnerGroupByModule,
    variables: {
      ownerGroup: organizationID,
      module: { eq: module },
    },
    normalizeData,
    limit: 1000,
  };

  const fields = [
    {
      name: "name",
      width: 200,
      disableEdits: true,
    },
    {
      name: "riskControl",
      minWidth: 200,
      flex: 1,
      component: <AssociatedControl />,
      valueGetter: (value) => `${value?.statementNumber}: ${value?.name}`,
      searchKeys: ["riskControl.statementNumber", "riskControl.name"],
      sort: {
        direction: "desc",
        priority: 2,
      },
    },
    {
      name: "status",
      component: <Resolved />,
      disablePropagation: true,
      type: "singleSelect",
      valueOptions: enumToDropdownData({
        ENUM: RecommendationStatus,
        textFieldName: "label",
      }),
      sortComparator: enumSortComparator(["Unresolved", "Wont Resolve", "Resolved"]),
      width: 150,
      bulkEdit: true,
    },
    {
      name: "resolvedAt",
      width: 150,
      inputType: "date",
      visible: false,
      disableEdits: true,
    },
    {
      name: "returnOnInvestment",
      component: <NumberChange format="percent" field="returnOnInvestment" />,
      width: 100,
      type: "number",
      sort: {
        direction: "desc",
        priority: 1,
      },
    },
    {
      name: "residualChange",
      component: <NumberChange format="dollar" positiveNumber="red" negativeNumber="green" field="residualChange" />,
      type: "number",
      width: 100,
    },
  ];

  gridConfig = {
    organizationID,
    module,
    resource,
    detailsTitle: "Risk Recommendation Details",
    typename: "Risk Recommendation",
    header: "Risk Recommendations",
    fields: fields,
    options: ["details", "delete", "edit"],
    detailsComponent: <RecommendationDetails tableDisplay={true} />,
    route: "#/governance/recommendations/",
    helpCenterRoute: "risk-recommendations",
    createResourceComponent: <CreateRecommendation />,
    updateMutation: updateRiskRecommendation_recommendationList,
    deleteFunction: deleteRecommendation,
    deleteOptions: {
      deleteActionItems: {
        label: "Delete Action Items",
        tooltip:
          "This will delete all associated Action Items along with this Recommendation. Action Items will not be deleted if they are associated with other Recommendations. ",
        inputType: "switch",
        defaultValue: true,
      },
      deleteObservations: {
        label: "Delete Observations",
        tooltip:
          "This will delete all associated Observations along with this Recommendation. Observations will not be deleted if they are associated with other Recommendations. ",
        inputType: "switch",
        defaultValue: true,
      },
    },
    createResourceComponentWidth: "80vw",
    persistenceUUID: "7aa8ef39-a37a-459c-98d3-7dd08cb4f417",
    genericEditFieldConfig: {
      name: {
        inputType: "textarea",
      },
    },
    ...gridConfig,
    ...props,
  };

  cardConfig = {
    title: "Risk Recommendations",
    headerIcon: "icon-tag",
    enableSticky: true,
    ...cardConfig,
  };

  const roleConfig = {
    module,
    resource,
  };

  const gridCard = useDataGrid({
    ...queryConfig,
    ...cardConfig,
    ...gridConfig,
    ...roleConfig,
  });

  return {
    ...gridCard,
  };
};
