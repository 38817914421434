"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNestedSeriesItems = void 0;
const getNestedItems_1 = require("../customQueries/customQueryFunctions/functions/getNestedField/getNestedItems");
const convertQueryStringPathToObjectPath_1 = require("./convertQueryStringPathToObjectPath");
const func_utils_1 = require("@rivial-security/func-utils");
/**
 * Gets the nested series items from a custom query result
 * @param {object[]} customQueries - custom query config and result data to traverse
 * @param {string} customQueryId - id of the custom query to get the data from
 * @param {string[]} stringPath - string path to the custom query result field
 * @returns {*[]|null}
 */
const getNestedSeriesItems = ({ customQueries, customQueryId, stringPath }) => {
    //Check if custom query data exists
    const result = customQueries?.[customQueryId]?.result;
    const config = customQueries?.[customQueryId]?.config;
    if (!result || !config) {
        console.error("[getNestedSeriesItems] No custom query data available - ", JSON.stringify({ customQueries, customQueryId }));
        return null;
    }
    //Convert string field path to detailed object path
    const objectPath = (0, convertQueryStringPathToObjectPath_1.convertQueryStringPathToObjectPath)({ config, stringPath });
    if (!(0, func_utils_1.isNonEmptyArray)(objectPath)) {
        console.error("[getNestedSeriesItems] object path is not a non empty array - ", JSON.stringify({ objectPath }));
        return null;
    }
    //Get the items using existing get nested items function
    return (0, getNestedItems_1.getNestedItems)({
        element: result,
        params: {
            path: objectPath,
        },
    });
};
exports.getNestedSeriesItems = getNestedSeriesItems;
