import { ErrorLogger } from "@utils/EventLogger";
import { GenericDeleteGQL } from "../../../../utils/Functions/Graphql/GenericDeleteGQL";
import { QueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 * Deletes a CustomResourceEntry resource in DB.
 * @param {object} item - the CustomResourceEntry object to delete
 * @param {string} item.id - the id of the CustomResourceEntry
 * @returns {Promise<void>}
 */
export const deleteCustomResourceEntry = async (item) => {
  if (!item || !item.id) {
    return null;
  }

  // query to gather all resources that need deletion
  const { getQuery: resourcesToDelete } = generateGraphql("CustomResourceEntry", ["__typename"], {});

  return await QueryGetItem({
    query: resourcesToDelete,
    itemId: item.id,
  }).then(async (customResourceEntry) => {
    ErrorLogger(`Deleting CustomResourceEntry - ${item?.id}`);
    await GenericDeleteGQL({ objectToDelete: customResourceEntry });
  });
};
