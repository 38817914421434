import { ErrorLogger, InfoLogger } from "@utils/EventLogger";

import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { SendPolicyEmail } from "../functions/SendPolicyEmail";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useCallback } from "react";

const { createMutation: createReviewer } = generateGraphql("Reviewer", ["pointOfContact", "status"], {
  pointOfContact: `{id firstName lastName email}`,
});

/**
 * Selects a Point of Contact to be a Reviewer for a Policy Version
 * @param {String} organizationID - ID of the Organization that owns this Policy Version
 * @param {Object} policy - Policy that owns this Policy Version
 * @param {Object} policyVersion - Policy Version to be assigned a Reviewer
 * @param {Object} policyVersionReviewerList - List of Reviewers for this Policy Version
 * @return {(function(*): Promise<void>)|*}
 */
export const useSelectContact = (organizationID, policy, policyVersion, policyVersionReviewerList) => {
  return useCallback(
    async (pointOfContact) => {
      const reviewers = policyVersionReviewerList?.data;

      if (reviewers && pointOfContact) {
        if (reviewers.find((reviewer) => reviewer?.pointOfContact?.id === pointOfContact?.id) !== undefined) {
          alert(`${pointOfContact.firstName} ${pointOfContact.lastName} is already assigned to this policy version!`);
          return;
        }
      }

      ItemMutation(createReviewer, {
        status: "pending",
        ownerGroup: organizationID,
        reviewerPointOfContactId: pointOfContact.id,
        policyVersionID: policyVersion && policyVersion.id ? policyVersion.id : undefined,
      })
        .then((newReviewer) => {
          policyVersionReviewerList.setData((data) => [...data, newReviewer]);
          const createReviewerId = newReviewer?.id;
          InfoLogger(`Reviewer ${createReviewerId} was Successfully Created`);
          InfoLogger(`Reviewer ${createReviewerId} was Successfully Linked to ${pointOfContact.id} Point Of Contact`);

          InfoLogger("Sending Email..");
          SendPolicyEmail(policy, policyVersion, pointOfContact).then((res) =>
            InfoLogger(`Email Sending Result: ${res}`),
          );
        })
        .catch((err) => ErrorLogger(`Error occurred while creating Reviewer! ${err}`));
    },
    [organizationID, policy, policyVersion, policyVersionReviewerList],
  );
};
