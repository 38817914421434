import { generateGraphql } from "@rivial-security/generategraphql";
import { deleteItem } from "../../../../utils/Functions/Graphql/deleteItem";
import { DEPARTMENT } from "../constants/DEPARTMENT";

/**
 * Deletes a Department
 * @param {DEPARTMENT} department - the Department to delete
 * @param {string} department.id - the ID of Department to delete
 * @returns {Promise<null|void>}
 */
export const deleteDepartment = async (department) => {
  const { getQuery } = generateGraphql(DEPARTMENT.typename, ["__typename"]);

  return await deleteItem(getQuery, department);
};
