import React, { useEffect } from "react";
import { isNullOrUndefined, removeDuplicateObjects } from "@rivial-security/func-utils";

import DashboardCard from "../../../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import { getPointOfContact_systemList } from "../../../graphql/__pointOfContactGQL";
import { useQueryGetItem } from "../../../../../../hooks/graphql/useQueryGetItem";
import { useSystemDataGrid } from "../../../../../Risk/Systems/hooks/useSystemDataGrid";
import { withDetailsPage } from "../../../../../../utils/Context/withDetailsPage";

/**
 * Displays Systems that a point of contact is the Owner or Admin for
 * @param itemId
 * @returns {JSX.Element}
 * @constructor
 */
const PointOfContactSystems = ({ itemId }) => {
  const queryHook = useQueryGetItem({
    query: getPointOfContact_systemList,
    itemId,
    disableRoleChecking: true,
  });

  const queryConfig = {
    query: null,
  };

  const visibleFields = ["name", "description", "hosting", "riskRating", "residualRisk", "inherentRisk", "tags"];

  const gridConfig = {
    visibleFields,
    resetFunction: queryHook?.reset,
  };

  const systemGrid = useSystemDataGrid({
    queryConfig,
    gridConfig: {
      createResourceComponent: null,
      ...gridConfig,
    },
  });

  /**
   * When the query is done, we need to combine the systems and systemsAdmin lists, and then add them to the grid
   */
  useEffect(() => {
    if (!isNullOrUndefined(queryHook?.item)) {
      const systems_owner = queryHook?.item?.systems?.items || [];

      const systems_admin = queryHook?.item?.systemsAdmin?.items || [];

      const allSystems = [...systems_owner, ...systems_admin];

      const systemsWithDuplicatesRemoved = removeDuplicateObjects(allSystems);

      if (Array.isArray(systemsWithDuplicatesRemoved)) {
        systemGrid.setData(systemsWithDuplicatesRemoved);
      }
    }
  }, [queryHook.item]);

  return (
    <DashboardCard
      title={"Information Systems"}
      subTitle={"owner / admin"}
      isLoading={queryHook.isLoading}
      icon={"icon-screen-desktop"}
      style={{ height: "100%" }}
    >
      <div style={{ height: "30em" }}>{systemGrid.gridDisplay}</div>
    </DashboardCard>
  );
};

export default withDetailsPage(PointOfContactSystems);
