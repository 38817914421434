import React from "react";
import { generateGraphql } from "@rivial-security/generategraphql";
import CreateVendor from "../components/CreateVendor";
import VendorDetails from "../components/VendorDetails";
import { deleteVendor } from "../functions/deleteVendor";
import { modules, resources } from "@rivial-security/role-utils";
import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";
import { listVendorsByOwnerGroup } from "../constants/listVendorsByOwnerGroup";

/**
 * Hook for displaying all Vendors for a specific organization
 *
 * @param {object} organizationID       - ownerGroup filter
 * @param {object} cardConfig           - @deprecated overrides passed to useDataGrid hook
 * @param {string} module               - overrides the default module used for role checking
 * @param {string} resource             - overrides the default resource used for role checking
 * @param {boolean} disableRoleChecking - disables role checking
 * @param {object} ...props             - all other props passed to useDataGrid hook
 */
export const useVendorDataGrid = ({
  organizationID,
  cardConfig = {},
  module = modules.VENDORS,
  resource = resources.VENDOR,
  disableRoleChecking,
  ...props
}) => {
  const typename = "Vendor";

  const roleConfig = {
    module,
    resource,
  };

  const { updateMutation } = generateGraphql("Vendor", ["name"]);

  const queryConfig = {
    query: listVendorsByOwnerGroup,
    limit: 1000,
    variables: {
      ownerGroup: organizationID,
    },
    module,
    resource,
  };

  cardConfig = {
    title: "Vendors",
    headerIcon: "icon-basket",
    enableSticky: true,
    ...cardConfig,
  };

  const fields = [
    {
      name: "name",
      flex: 1,
      sort: {
        direction: "asc",
        priority: 1,
      },
      bulkEdit: true,
    },
  ];

  const gridConfig = {
    organizationID,
    fields,
    options: ["edit", "open", "details", "duplicate", "delete"],
    createItemModalHeader: <div>Create a new Vendor</div>,
    createResourceComponent: (
      <CreateVendor
        organizationID={organizationID}
        module={module}
        resource={resource}
        disableRoleChecking={disableRoleChecking}
      />
    ),
    deleteFunction: deleteVendor,
    updateMutation,
    route: "#/vendors/vendor/",
    detailsComponent: <VendorDetails tableDisplay={true} organizationID={organizationID} />,
    detailsTitle: "Vendor Details",
    persistenceUUID: "f6e2e5f7-687e-423c-b122-2bacb60ff9e62",
    typename,
    duplicationSettings: {
      description: "Duplicates a Vendor and its Vendor Solutions. Does not duplicate Vendor Reviews",
      enabled: true,
      fields: ["name", "vendorSolutions"],
      nestedFields: {
        vendorSolutions: `
          (limit: 1000){
            items {
              id
              name
            }
          }
        `,
      },
      primaryField: "name",
      connectionAdaptor: {
        vendorSolutions: {
          connectionTypename: "VendorSolution",
          itemConnectionIDField: "vendorID",
        },
      },
    },
    ...props,
  };

  const grid = useDataGrid({
    ...queryConfig,
    ...gridConfig,
    ...cardConfig,
    ...roleConfig,
  });

  return { ...grid };
};
