import { withOrganizationCheck } from "../../utils/Context/withOrganizationCheck";
import { useReportDataGrid } from "./hooks/useReportDataGrid";

/**
 * @description Wrapper component for the useReportDataGrid hook
 * @param {object} props - props used in component
 */
const ReportDataGrid = (props) => {
  return useReportDataGrid(props).dashboardDisplay;
};

export default withOrganizationCheck(ReportDataGrid);
