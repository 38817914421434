import React, { useEffect, useState } from "react";
import { Card, CardBody, Collapse } from "reactstrap";
import SubControlItem from "./SubControlItem";
import { useTable } from "../../../../../../../../../hooks/views/useTable";
import { modules, resources } from "@rivial-security/role-utils";
import { withOrganizationCheck } from "../../../../../../../../../utils/Context/withOrganizationCheck";

/**
 * Displays all Sub Controls for a Control Category for Systesm Details
 * @param {string} module - module used for role checking
 * @param {string} resource - resource used for role checking
 * @param {boolean} disableRoleChecking - flag to disable role checking
 * @param {boolean} isTemplate - flag to indicate if the system is a template
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SubControls = ({
  module = modules.RISK,
  resource = resources.RISK_CONTROL_CATEGORY,
  disableRoleChecking = false,
  isTemplate = false,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const { subControls, resetFunction } = props;

  const tableHook = useTable({
    data: subControls,
    rowComponent: (
      <SubControlItem
        viewType={props.viewType}
        organizationID={props.organizationID}
        system={props.system}
        Context={props.Context}
        vendorReview={props.vendorReview}
        disableRoleChecking={disableRoleChecking}
        isTemplate={isTemplate}
      />
    ),
    fields: ["statementNumber", "name"],
    config: {
      borderless: true,
    },
    module,
    resource,
    resetFunction: resetFunction,
    disableRoleChecking,
  });

  useEffect(() => {
    const sorted = [...subControls].sort((a, b) => parseFloat(a.statementNumber) - parseFloat(b.statementNumber));
    tableHook.setData([...sorted]);
  }, [subControls]);

  return (
    <div style={{ marginTop: "1em" }}>
      <strong style={{ marginBottom: "1em" }}>
        Controls
        <i
          className={!isOpen ? "fa fa-eye-slash" : "fa fa-eye"}
          title={`${isOpen ? "Hide" : "Show"} Sub-Controls`}
          style={{ cursor: "pointer", marginLeft: "1em" }}
          onClick={() => setIsOpen(!isOpen)}
        />
      </strong>
      <Collapse isOpen={isOpen}>
        <Card>
          <CardBody>{tableHook.display}</CardBody>
        </Card>
      </Collapse>
    </div>
  );
};

export default withOrganizationCheck(SubControls);
