import { GenericDeleteGQL } from "../../../../../utils/Functions/Graphql/GenericDeleteGQL";
import { InfoLogger } from "@utils/EventLogger";
import { QueryGetItem } from "../../../../../hooks/graphql/useQueryGetItem";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 * Deletes a Control resource in DB.
 * Also deletes:
 * - Control Evidence Links
 * - Control Tag Links
 * - Control Change Links
 * @param {object} control - the control object to delete
 * @param {string} control.id - the id of the control
 * @returns {Promise<void>}
 */
export const deleteControl = async (control) => {
  if (!control || !control.id) {
    return null;
  }

  // query to gather all resources that need deletion
  const { getQuery: resourcesToDelete } = generateGraphql("Control", ["changes", "evidences", "tags", "__typename"], {
    changes: `(limit: 200) {
      items {
        id
        __typename
      }
      nextToken
    }`,
    evidences: `(limit: 200) {
      items {
        id
        __typename
      }
      nextToken
    }`,
    tags: `(limit: 200) {
      items {
        id
        __typename
      }
      nextToken
    }`,
  });

  return await QueryGetItem({
    query: resourcesToDelete,
    itemId: control.id,
  }).then(async (control) => {
    InfoLogger(`Deleting control - ${control?.id}`);
    await GenericDeleteGQL({ objectToDelete: control });
  });
};
