import { ItemMutation } from "../../../Functions/Graphql/ItemMutation";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 * Creates a ControlTagLink object
 * @param {object} control - the control to which to attach the tag
 * @param {object} tag - the tag to attach to the control
 * @param {string} organizationID - the organization id of the control
 * @returns {Promise<*>}
 */
export const createControlTagLink = (control, tag, organizationID) => {
  if (tag === null || tag === undefined) {
    throw Error("Error, invalid tag input!");
  }

  if (control === null || control === undefined || control.id === null || control.id === undefined) {
    throw Error("Error, invalid control input!");
  }

  const { createMutation: createControlTagLink } = generateGraphql("ControlTagLink", [
    "controlID",
    "tagID",
    "__typename",
  ]);

  return ItemMutation(createControlTagLink, {
    controlID: control.id,
    tagID: tag.id,
    ownerGroup: organizationID,
  });
};
