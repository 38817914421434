import { documentsByEvidence } from "./documentsByEvidence";
import { documentsByControl } from "./documentsByControl";
import { documentsByPointOfContact } from "./documentsByPointOfContact";
import { documentsByEvidenceTag } from "./documentsByEvidenceTag";

/**
 * Compliance Document Export grouping types
 * @type {{ALL: string, POINT_OF_CONTACT: string, EVIDENCE: string, CONTROL: string}}
 */
export const DOCUMENT_EXPORT_BY = {
  EVIDENCE: "evidence",
  CONTROL: "control",
  POINT_OF_CONTACT: "pointOfContact",
  EVIDENCE_TAG: "evidenceTag",
};

/**
 * Retrieves Compliance Documents for an organization.
 * @param {string} organizationID - selected organization id
 * @param {string} groupBy - value to filter by
 * @param {string} controlSetId - initial control framework ID
 * @param {boolean} includeDisabledControlsInExports - include disabled controls in exports
 * @param {string} rootName - Name of the root folder
 * @param {object} dateRange - Date range for the data filter
 * @param {Date} dateRange.startDate - Start date for the data filter
 * @param {Date} dateRange.endDate - End date for the data filter
 * @returns {Promise<*[]>}
 */
export const fetchDocumentsBy = async ({
  organizationID,
  groupBy,
  controlSetId,
  includeDisabledControlsInExports,
  rootName,
  dateRange,
}) => {
  switch (groupBy) {
    case DOCUMENT_EXPORT_BY.CONTROL:
      return documentsByControl({
        organizationID,
        controlSetId,
        includeDisabledControlsInExports,
        rootName,
        dateRange,
      });
    case DOCUMENT_EXPORT_BY.POINT_OF_CONTACT:
      return documentsByPointOfContact({ organizationID, rootName, dateRange });
    case DOCUMENT_EXPORT_BY.EVIDENCE_TAG:
      return documentsByEvidenceTag({ organizationID, rootName, dateRange });
    case DOCUMENT_EXPORT_BY.EVIDENCE:
    default:
      return documentsByEvidence({ organizationID, rootName, dateRange });
  }
};
