import { generateGraphql } from "@rivial-security/generategraphql";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";

/**
 * Deletes a single PointOfContactResponseTeamLink m-m connection
 * @param link
 */
export const deletePointOfContactResponseTeamLink = async (link) => {
  const { deleteMutation } = generateGraphql("PointOfContactResponseTeamLink");

  return await ItemMutation(deleteMutation, {
    id: link.id,
  });
};
