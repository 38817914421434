import { useEffect, useState } from "react";
import { useForm } from "./useForm";

/**
 * A hook that provides a form with an Auto Update switch.
 * If the Auto Update switch is enabled, increments the refreshToken every x milliseconds
 * @param label
 * @param timeout
 * @returns {{input: {}, display: *, setRefreshToken: (value: (((prevState: number) => number) | number)) => void, setInput: Function, refreshToken: number}}
 */
export const useAutoUpdateForm = ({ label = "Auto-Update", timeout = 10000 }) => {
  const [refreshToken, setRefreshToken] = useState(0);

  const form = useForm({
    fieldConfig: {
      autoUpdate: {
        enabled: true,
        inputType: "switch",
        defaultValue: true,
        switchConfig: {
          label,
        },
      },
    },
    disableSubmitButton: true,
    disableResetButton: true,
  });

  /**
   * If the Auto Update switch is enabled, increments the refreshToken every x milliseconds
   */
  useEffect(() => {
    if (form?.input?.autoUpdate === true) {
      const timer = setTimeout(() => {
        setRefreshToken((refreshToken) => refreshToken + 1);
      }, timeout);

      return () => clearTimeout(timer);
    }
  }, [refreshToken, form?.input?.autoUpdate]);

  return {
    ...form,
    refreshToken,
    setRefreshToken,
  };
};
