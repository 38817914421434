import React, { useEffect, useState } from "react";
import cronstrue from "cronstrue";
import { useModal } from "../useModal";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { ReUnixCron, Tab } from "@sbzen/re-cron";
import Divider from "@mui/material/Divider";
import LabTabs from "../../../utils/GenericComponents/FrequencyScheduler/components/Tabs";
import useDidMountEffect from "../../functional/useDidMountEffect";

export const useFrequencyBuilder = ({ value, initValue, onChangeCallback, disabled }) => {
  const DEFAULT_ACTIVE_TAB = Tab.MONTH;
  const SUPPORTED_TABS = [Tab.HOURS, Tab.DAY, Tab.MONTH];

  const [cronExp, setCronExp] = useState(initValue || value);

  /**
   * Used to update useForm hook
   */
  useEffect(() => {
    if (cronExp) {
      onChangeCallback && onChangeCallback(cronExp);
    }
  }, [cronExp]);

  /**
   * Used to assign initial cron value
   */
  useDidMountEffect(() => {
    if (initValue) setCronExp(initValue);
  }, [initValue]);

  const [activeTab, setActiveTab] = useState(DEFAULT_ACTIVE_TAB);

  const display = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Divider />
      <Grid container spacing={2}>
        <Grid item lg={12} xs={12}>
          <i>{cronExp && cronstrue.toString(cronExp)} </i>
        </Grid>
        {!disabled && (
          <Grid item container spacing={2} lg={12} xs={12}>
            <Grid item lg={3} md={3} sm={3}>
              <Stack spacing={2}>
                <h5>Simple</h5>
                {SIMPLE_OPTIONS.map((option, index) => (
                  <Button
                    key={index}
                    onClick={() => {
                      setCronExp(option.cron);
                      if (option.activeTab) setActiveTab(option.activeTab);
                    }}
                    color={cronExp === option.cron ? "secondary" : "primary"}
                    variant={"outlined"}
                    disabled={disabled}
                  >
                    {option.label}
                  </Button>
                ))}
              </Stack>
            </Grid>
            <Divider orientation="vertical" flexItem style={{ marginLeft: "1em", marginRight: "1em" }} />
            <Grid item lg={8} md={8} sm={8}>
              <div>
                <h5>Advanced</h5>
                <LabTabs value={activeTab} setValue={setActiveTab} tabs={SUPPORTED_TABS} />
                <span style={{ marginBottom: "1em" }} />
                <ReUnixCron
                  value={cronExp}
                  onChange={setCronExp}
                  tabs={SUPPORTED_TABS}
                  activeTab={activeTab}
                  disabled={disabled}
                  hideTabs={true}
                />
                ({cronExp})
              </div>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );

  const modal = useModal(
    "Select Frequency",
    <div>{display}</div>,
    <Button size="sm" color="ghost-warning" className="btn-pill" title="Select custom frequency">
      <i className="icon-pencil" />
    </Button>,
    {
      width: "50vw",
    },
  );

  return {
    display,
    modal,
    cronExp,
  };
};

const SIMPLE_OPTIONS = [
  {
    label: "Weekly",
    quartz: "0 0 9 ? * MON *",
    cron: "0 9 * * MON",
    activeTab: Tab.DAY,
  },
  {
    label: "Monthly",
    quartz: "0 0 9 1W * ? *",
    cron: "0 9 1 * *",
    activeTab: Tab.MONTH,
  },
  {
    label: "Quarterly",
    quartz: "0 0 1 * 1/3 * *",
    cron: "0 9 1 JAN,APR,JUL,OCT *",
    activeTab: Tab.MONTH,
  },
  {
    label: "Twice a year",
    quartz: "0 0 9 1W 1/6 ? *",
    cron: "0 9 1 JAN,JUL *",
    activeTab: Tab.MONTH,
  },
  {
    label: "Once a year",
    quartz: "0 0 9 1W JUN ? *",
    cron: "0 9 1 JAN *",
    activeTab: Tab.MONTH,
  },
];
