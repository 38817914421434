import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import PropTypes from "prop-types";

/**
 * @description UI for a simple modal, w/o button to close it
 * @param  {object} props
 * @returns {JSX.Element} - React component
 * @constructor
 */
export const GenericModalWithButton = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const onClickHandler = (e) => {
    if (!props.disableEdits) {
      e.stopPropagation();
      setModalIsOpen(!modalIsOpen);
    }
  };

  return (
    <>
      {React.cloneElement(props.modalButton, {
        onClick: (e) => onClickHandler(e),
      })}
      <Modal
        style={{ minWidth: props.minWidth }}
        isOpen={modalIsOpen}
        toggle={() => (props.disableModalToggle ? undefined : setModalIsOpen(!modalIsOpen))}
      >
        <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}>
          {props.title}
          {props.headerButtons}
        </ModalHeader>
        <ModalBody>
          {modalIsOpen && props.body
            ? React.cloneElement(props.body, {
                toggleModal: () => setModalIsOpen(!modalIsOpen),
              })
            : "Nothing to see here.."}
        </ModalBody>
      </Modal>
    </>
  );
};

GenericModalWithButton.propTypes = {
  minWidth: PropTypes.string,
  title: PropTypes.string, // An optional title
  headerButtons: PropTypes.object, // Any buttons that you want present in the header
  body: PropTypes.object, // The body of the modal
  modalButton: PropTypes.object, // The passed in button is just for styling, no need to pass onClick to it.
  disableEdits: PropTypes.bool,
};

GenericModalWithButton.defaultProps = {
  minWidth: "40vw",
  // title: "Generic Modal",
  headerButtons: null,
  body: null,
  modalButton: <Button>Open Modal</Button>,
  disableEdits: false,
};
