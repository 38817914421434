import { ErrorLogger } from "@utils/EventLogger";
import { ItemMutation } from "../../../utils/Functions/Graphql/ItemMutation";
import { PutS3Object } from "../../../utils/Functions/S3Storage/PutS3Object";
import awsmobile from "../../../aws-exports";
import { generateGraphql } from "@rivial-security/generategraphql";
import { getLatestReportDocumentVersion } from "./getLatestReportDocumentVersion";
import { uploadS3Object } from "../../../utils/Functions/S3Storage/uploadS3Object";
import { v4 as uuid } from "uuid";

/**
 * @description Function to save new report document version
 * @param {object} item - report
 * @param {string} documentName - report document name
 * @param {object} documentEditor - document editor hook
 * @param {string} organizationID - selected organization
 * @param {object} context - organization context
 * @param {function} resetFunction - function to re-fetch data
 * @returns {Promise<*>}
 */
export const saveNewReportDocumentVersion = async ({
  item,
  documentName,
  documentEditor,
  organizationID,
  context,
  resetFunction,
}) => {
  const sfdt = documentEditor?.ref?.documentEditor?.serialize();

  const bucket = context?.selectedOrganizationObjectMinimal?.s3BucketName;

  const fileName = `report-${uuid()}`;
  const key = `reports/${fileName}.sfdt`;
  const keyWordDoc = `reports/${fileName}.docx`;

  /**
   * Upload the SFDT version of the report to S3
   */
  await PutS3Object({
    organizationID,
    fileType: "application/json; charset=utf-8",
    objectKey: key,
    bucketName: bucket,
    file: sfdt,
  });

  /**
   * Upload the Word version of the report to S3
   */
  try {
    const wordBlob = await documentEditor?.ref?.documentEditor?.saveAsBlob("Docx");

    const file = new File([wordBlob], "Document1.docx");
    await uploadS3Object({
      organizationID,
      key: keyWordDoc,
      bucket,
      body: file,
      fileType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });
  } catch (error) {
    ErrorLogger(`Cannot upload report Word document: ${JSON.stringify(error)}`);
  }

  /**
   * Create a new report document version
   */
  const { createMutation: createReportDocument } = generateGraphql("ReportDocument", ["name", "version"]);

  const latestReportDocument = getLatestReportDocumentVersion({ report: item });
  const version = latestReportDocument?.version + 1;

  return await ItemMutation(createReportDocument, {
    name: documentName ? `${documentName} v${version}` : `Report Document v${version}`,
    version,
    file: {
      key: key,
      bucket,
      region: awsmobile.aws_project_region,
    },
    fileWordDoc: {
      key: keyWordDoc,
      bucket,
      region: awsmobile.aws_project_region,
    },
    visibility: "private",
    reportDocumentReportId: item?.id,
    ownerGroup: organizationID,
  }).then((data) => {
    resetFunction?.();
    return data;
  });
};
