import { AmplifyEnv } from "../../../env-config";
import { Auth } from "@aws-amplify/auth";
import { InfoLogger } from "../../EventLogger";
import { Lambda } from "aws-sdk";

/**
 * Allows to invoke a lambda function without using graphql, the environment in env-config needs to be set correctly
 * NOTE: this function expects a statusCode property to be present in the response object, code 200 needs to be returned for success
 * @param {object} params - the parameters to be passed to the lambda function
 * @param {string} params.name - the lambda name to invoke (no environment suffix)
 * @param {string} [params.fullName] - the full lambda name to invoke (with environment suffix)
 * @param {string} [params.type = 'RequestResponse'] - the type of invocation (see below) NOTE: RequestResponse vs Event type - https://docs.aws.amazon.com/lambda/latest/dg/API_Invoke.html#API_Invoke_RequestSyntax
 * @param {object} [params.input] - the input arguments into the lambda function
 * @param {string} [params.fullInput] - pre-proccessed input to be passed to the lambda function (takes priority if provided)
 * @return {Promise<*>} - the response from the lambda function
 */
export const sendRequestToLambdaPromise = async ({
  name,
  fullName,
  type = "RequestResponse",
  input = {},
  fullInput,
}) => {
  try {
    const credentials = await Auth.currentCredentials();
    const lambda = new Lambda({
      credentials: Auth.essentialCredentials(credentials),
      region: "us-west-2",
      httpOptions: {
        connectTimeout: 2 * 1000, // time succeed in starting the call
        timeout: 900000, // 15 min
      },
    });

    const params = {
      FunctionName: fullName || `${name}-${AmplifyEnv}`,
      InvocationType: type,
      Payload: fullInput || JSON.stringify({ input }),
    };

    InfoLogger(`Invoking lambda with params - ${JSON.stringify({ params, input })}`);

    const response = await lambda.invoke(params).promise();
    if (!response?.Payload || !response?.StatusCode || response?.StatusCode !== 200) {
      throw Error(`Invalid response payload${JSON.stringify({ response })}`);
    } else {
      const data = JSON.parse(response.Payload);
      if (data?.statusCode && data?.statusCode !== 200) {
        throw Error(`Response with a failing status code returned! - ${JSON.stringify({ data })}`);
      } else {
        return data;
      }
    }
  } catch (e) {
    InfoLogger("Failed to process lambda call!", e);
    throw Error("Failed to process lambda call!");
  }
};
