import React, { useState } from "react";
import "../../views/ActionCenter/css/DashboardTile.css";
import { Badge, Spinner } from "reactstrap";
import { handleIcon } from "../Functions/Icon/getIcon";

const grad1 = "rgb(5,138,239)";
const grad2 = "rgb(31,72,181)";

/**
 * A single Tile to be used in the Tile Dashboard
 * @param {string} title - displayed at the top of the title
 * @param {boolean} isSelected - determines if this tile is currently selected
 * @param {function} setIsSelected - tells the parent to select this tile
 * @param {string} gradient1 - 1st color used for the gradient
 * @param {string} gradient2 - 2nd color used for the gradient
 * @param {string} icon - icon css classname that is displayed in the bottom right corner
 * @param {boolean} isLoadingItemTotal - true if the tile total is loading
 * @param {number} itemTotal - the number to be displayed in the tile
 * @param {boolean} disabled - disables the tile
 * @param {string} text - gets displayed next to the actionItemTotal number
 * @param rightBottomComponent - display component in the right bottom of the tile
 * @param rightTopComponent - display component in the right top of the tile
 * @param {JSX.element} footerComponent - display a component below the Tile
 * @param {boolean} disableTotals - toggles the option to display a total number in the badge
 * @param {string} badgeColor - override the badge color
 * @returns {JSX.Element}
 */
const DashboardTile = ({
  title,
  isSelected,
  setIsSelected,
  gradient1 = grad1,
  gradient2 = grad2,
  icon = "icon-shield",
  isLoadingItemTotal = false,
  itemTotal = 0,
  disabled,
  text,
  rightBottomComponent,
  rightTopComponent,
  footerComponent,
  disableTotals = false,
  badgeColor,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const gradient = `linear-gradient(44deg, ${gradient1} 0%, ${gradient2} 100%)`;

  const selectedStyle = {
    backgroundImage: gradient,
    color: "white",
    boxShadow: `${gradient2} 1px 1px 4px 1px`,
  };

  const getStyle = () => {
    if (disabled) {
      const disabledGradient1 = "rgb(160,158,158, 0.5)";
      const disabledGradient2 = "rgb(184,183,183, 0.5)";
      return {
        borderRadius: "4px",
        minWidth: "10em",
        padding: "1em 0.75em 0em 1em",
        height: "7em",
        backgroundImage: `linear-gradient(44deg, ${disabledGradient1} 0%, ${disabledGradient2} 50%)`,
        opacity: 0.6,
      };
    }

    let style = {
      borderRadius: "4px",
      minWidth: "10em",
      padding: "1em 0.75em 0em 1em",
      height: "7em",
    };

    if (isSelected === true) {
      style = { ...style, ...selectedStyle };
    } else {
      style = { ...style, cursor: "pointer", background: "#e6eef0" };
      delete style.backgroundImage;
      delete style.boxShadow;
    }

    if (isSelected === false)
      if (isHovered && isSelected === false)
        style = {
          ...style,
          color: gradient2,
          boxShadow: `${gradient2} 1px 1px 4px 1px`,
        };

    return style;
  };

  const handleClick = () => {
    !disabled && setIsSelected(!isSelected);
  };

  return (
    <>
      <div
        className="e-card ribbon-parent"
        id={`basic_card${title}`}
        style={getStyle()}
        onClick={handleClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isSelected}
        {disabled && <span className="ribbon">Coming Soon</span>}
        <div className="e-card-header">
          <div className="e-card-header-caption">
            <div className="e-card-title" style={{ fontSize: "1.2em" }}>
              <strong>{title}</strong>
              {rightTopComponent && <span className="float-right">{React.cloneElement(rightTopComponent)}</span>}
            </div>
          </div>
        </div>
        <div
          className="e-card-content"
          style={{
            ...getStyle(),
            backgroundImage: undefined,
            boxShadow: undefined,
          }}
        >
          {!disableTotals && (
            <ItemTotals total={itemTotal} isLoading={isLoadingItemTotal} text={text} badgeColor={badgeColor} />
          )}
          {!rightBottomComponent && (
            <div className={"float-right"} style={{ transform: "scale(2)" }}>
              {handleIcon(icon)}
            </div>
          )}
          {rightBottomComponent && <span className="float-right">{React.cloneElement(rightBottomComponent)}</span>}
        </div>
      </div>
      {footerComponent && <div className="e-card-footer">{footerComponent}</div>}
    </>
  );
};

/**
 * A badge that shows how many items are in a module
 * @param {number} total - the number inside the badge
 * @param {string} [text = "Action Items"] - the text next to the badge
 * @param {string} badgeColor - optional background color for the tile badge
 * @param {boolean} [isLoading=false] - optional loading state for the badge
 * @returns {JSX.Element}
 */
const ItemTotals = ({ total = 0, text, badgeColor, isLoading = false }) => {
  return (
    <span>
      <Badge
        pill
        style={{
          marginRight: "1em",
          display: "inline",
          background: badgeColor || (total === 0 ? "#5cb85c" : "#ff5151"),
          color: "white",
          textAlign: "center",
          verticalAlign: "middle",
        }}
      >
        {isLoading ? (
          <Spinner
            style={{
              height: "1em",
              width: "1em",
            }}
          />
        ) : (
          total
        )}
      </Badge>
      <span>{text || "Items"}</span>
    </span>
  );
};

export default DashboardTile;
