import { Button } from "reactstrap";
import React from "react";

/**
 * A single button allowing to call a function on a particular item selection (ie. a row in a grid)
 * @param {function} onClickItem - callback that executes if button to select this item is pressed
 * @param {object} item - database object selected in a grid (entire row data)
 */
const SelectButtonField = ({ onClickItem, item }) => {
  return (
    <Button
      color="primary"
      onClick={() => {
        onClickItem && onClickItem(item);
      }}
    >
      Select
    </Button>
  );
};

export default SelectButtonField;
