import React from "react";
import SmartValueContext from "../context/SmartValueContext";
import { useContext } from "react";
import SmartValueChips from "../components/SmartValueChips";

/**
 * Hook used to access Smart Value Context,
 * adds a few Helper UIs as well for easy access downstream
 *
 * @returns {{chips: JSX.Element, smartValues: string[], setSmartValues: function}}
 */
export const useSmartValueContext = () => {
  const context = useContext(SmartValueContext);
  if (!context) {
    throw new Error("useSmartValueContext must be used within a SmartValueContextProvider");
  }

  return {
    ...context,
    chips: <SmartValueChips smartValues={context.smartValues} />,
  };
};
