import { useDetailsCard } from "../../../../hooks/views/useDetailsCardV2";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";
import React from "react";
import EmailBody from "../components/EmailBody";

export const useEmailTemplateDetails = ({ organizationID, itemId, toggleDetailsModal }) => {
  const module = modules.ORGANIZATION_MANAGER;
  const resource = resources.EMAIL_TEMPLATE;
  const typename = "Email";

  const { getQuery, updateMutation } = generateGraphql("Email", [
    "name",
    "recipients",
    "replyTo",
    "subject",
    "body",
    "design",
  ]);

  const queryConfig = {
    itemId,
    query: getQuery,
  };

  return useDetailsCard({
    module,
    resource,
    typename,
    queryConfig,
    updateMutation,
    config: {
      detailsComponent: (
        <EmailBody toggleModal={toggleDetailsModal} disableResetButton={true} organizationID={organizationID} />
      ),
    },
  });
};
