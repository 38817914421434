import React, { useContext } from "react";
import { OrganizationContext } from "../../../../utils/Context/OrganizationContext";
import DeleteRole from "../functions/deleteRole";
import RoleDetailsCard from "../components/RoleDetailsCard";
import { generateGraphql } from "@rivial-security/generategraphql";
import RolePrecedence from "../customFields/RolePrecedence";
import CreateRole from "../components/CreateRole";
import { modules, precedenceTypes as PRECEDENCE_TYPES, resources } from "@rivial-security/role-utils";
import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";
import { rolesByOwnerGroupDataGrid } from "../graphql/__roleGQL";
import { mergeAdditionalFields } from "../../../../hooks/views/useGrid/functions/mergeAdditionalFields";

const { updateMutation: updateRole } = generateGraphql("Role", ["name", "precedence", "roleConfig", "resources"]);

/**
 * Displays a list of Roles for an organization.
 *
 * @param {boolean} disableEdits - if true, disables the ability to edit roles
 * @param {string} organizationID - the Organization ID to display the role for
 * @param {object} queryConfig - the query configuration to use for the data grid
 * @param {object} gridConfig - the configuration for the data grid
 * @param {object} cardConfig - the configuration for the data grid card
 * @param {string} module - the module used for the role permissions
 * @param {string} resource - the resource used for the role permissions
 * @param {object} dataGridConfig - the configuration for the data grid
 * @param {int} highestPrecedence - the highest precedence level role to show in the selection grid (can use enum in role-utils)
 * @param {string} height - the height of the data grid
 * @param {object[]} additionalFields - additional fields to add to the grid
 * @param {object} props - additional props
 * @return {{selectedData: [], isLoading: *, setIsLoading: (value: (((prevState: *) => *) | *)) => void, data: *[], setData: (value: (((prevState: *[]) => *[]) | *[])) => void, resetFunction: function(): void, display: JSX.Element, selectedIDs: []}}
 */
export const useRoleDataGrid = ({
  disableEdits = false,
  organizationID,
  queryConfig = {},
  gridConfig = {},
  cardConfig = {},
  module = modules.ORGANIZATION_MANAGER,
  resource = resources.ROLE,
  dataGridConfig = {},
  highestPrecedence,
  height = "85vh",
  additionalFields = [],
  ...props
}) => {
  const roleConfig = {
    module,
    resource,
  };

  const context = useContext(OrganizationContext);

  queryConfig = {
    query: rolesByOwnerGroupDataGrid,
    variables: {
      ownerGroup: organizationID,
    },
    organizationID,
    normalizeData: (data) => {
      if (data && Array.isArray(data)) {
        return data.filter((x) => x.precedence >= context?.role?.precedence);
      }
    },
    ...queryConfig,
  };

  cardConfig = {
    title: "Roles",
    headerIcon: "icon-user-female",
    ...cardConfig,
  };

  const fields = [
    {
      name: "name",
      disableEdits,
      flex: 1,
      minWidth: 200,
      sort: {
        direction: "asc",
        priority: 2,
      },
    },
    {
      name: "precedence",
      friendlyName: "Role Type",
      width: "150",
      component: <RolePrecedence />,
      sort: {
        direction: "asc",
        priority: 1,
      },
      type: "singleSelect",
      valueOptions: Object.entries(PRECEDENCE_TYPES)?.map(([key, value]) => {
        const label = key === "USER" ? "STANDARD" : key;
        return { value, label };
      }),
    },
  ];
  mergeAdditionalFields({ fields, additionalFields });

  gridConfig = {
    typename: "Role",
    fields,
    options: ["details", "duplicate", "delete"],
    deleteFunction: DeleteRole,
    createResourceComponent: (
      <CreateRole
        organizationID={organizationID}
        module={module}
        resource={resource}
        highestPrecedence={highestPrecedence}
      />
    ),
    detailsComponent: <RoleDetailsCard module={module} resource={resource} />,
    route: `#/organization_manager/roles/`,
    updateMutation: updateRole,
    persistenceUUID: "c4e6244a-cea3-4db0-ab10-42d711e05b0b",
    duplicationSettings,
    ...queryConfig,
    ...roleConfig,
    ...gridConfig,
    ...cardConfig,
    ...dataGridConfig,
    ...props,
  };

  const dataGrid = useDataGrid({
    ...gridConfig,
  });

  const display = <div style={{ height: height ?? "85vh" }}>{dataGrid.display}</div>;

  return {
    ...dataGrid,
    display,
  };
};

const duplicationSettings = {
  enabled: true,
  description: "Duplicates Roles. Preserves Role configurations",
  primaryField: "name",
  fields: ["name", "description", "precedence", "roleConfig", "resources"],
};
