import { InfoLogger } from "../../../../../EventLogger";
import { USER_ACTIVITY_TYPES } from "../../../../../../views/OrganizationManager/Users/enums/USER_ACTIVITY_TYPES";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Updates the toast with the result of the operation.
 * Doesn't update the toast if disableToast is true.
 *
 * @param {string} field - the field that was updated
 * @param {string | number | boolean} result - the result value from the update, if an object will not be shown in the toast
 * @param {boolean} disableToast - whether toasts are disabled
 * @param {function} updateToast - function that updates the toast
 * @param {string} toastId - the ID of the toast to update
 * @param {object} item - the original item data that was updated
 * @param {string} typename - the model type name from the schema of the 'item'
 * @param {string} [friendlyName] - an optional field name override for display purposes
 * @param {object} [inputConfig] - input configuration (used for dropdown changes toast display)
 * @param {string} inputType - the Input type for this field (dropdown, etc
 */
export const handleResultToast = ({
  field,
  result,
  disableToast,
  updateToast,
  toastId,
  item,
  typename,
  friendlyName,
  inputConfig,
  inputType,
}) => {
  let fieldString;

  /**
   * If toasts are not disabled but the toastId is invalid, throw error
   */
  if (!disableToast && typeof toastId !== "string") {
    throw Error("toastId param has to be a string, unless toasts are disabled");
  }

  /**
   * Check field input so that toast always looks nice
   */
  if (isNullOrUndefined(field)) {
    fieldString = "The field has been";
  } else {
    fieldString = `Field: '${friendlyName || field}' has been`;
  }

  /**
   * Check if the input config has dropdown data, use the display friendly values instead for the toast
   */
  let finalResult = result;
  if (inputType === "dropdown" && Array.isArray(inputConfig?.data)) {
    for (const entry of inputConfig.data) {
      if (entry.value === result) {
        finalResult = entry.text;
        break;
      }
    }
  }

  /**
   * Construct the result message
   * @type {string}
   */
  let resString = `${friendlyName || fieldString} updated`;
  if (typeof result === "string" || typeof result === "number" || typeof result === "boolean") {
    resString = `${friendlyName || fieldString} updated to: ${finalResult}`;
  }
  InfoLogger(resString);

  /**
   * If not disabled, update the toast
   */
  if (!disableToast) {
    updateToast({
      id: toastId,
      icon: "warning",
      header: resString,
      data: {
        type: USER_ACTIVITY_TYPES.UPDATE,
        itemId: item?.id,
        typename,
        field,
        value: finalResult,
        name: item?.name,
      },
    });
  }
};
