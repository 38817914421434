import React, { useEffect, useState } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { useMutation } from "../../../../../../../hooks/graphql/useMutation/useMutation";
import { useCheckPermissions } from "../../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";
import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";

const { updateMutation: updateSystemRiskLink } = generateGraphql("SystemRiskLink");

const KeyRiskIndicatorCIA = (props) => {
  const module = modules.RISK;
  const resource = resources.INFORMATION_SYSTEM;
  const field = "cia";

  const disableRoleChecking = props.disableRoleChecking;

  const checkPermissionsHook = useCheckPermissions({
    module,
    resource,
    field,
    disableRoleChecking,
  });

  const checkBoxStyle = {
    marginRight: "1em",
    marginLeft: "0.5em",
    height: "1.5em",
    width: "1.5em",
  };

  // let cia = props.risk && props.risk.cia;
  const cia = props.systemRiskLink && props.systemRiskLink.cia;

  const [cIsEnabled, setCIsEnabled] = useState(false);
  const [iIsEnabled, setIIsEnabled] = useState(false);
  const [aIsEnabled, setAIsEnabled] = useState(false);

  useEffect(() => {
    if (cia) {
      setCIsEnabled(cia.confidentiality);
      setIIsEnabled(cia.integrity);
      setAIsEnabled(cia.availability);
    }
  }, [props.risk, cia]);

  const updateCIAMutation = useMutation({
    mutation: updateSystemRiskLink,
    module,
    resource,
    field,
    disableRoleChecking,
  });

  const handleCheck = (field, bool) => {
    const input = {
      id: props.systemRiskLink.id,
      cia: {
        ...cia,
        [field]: bool,
      },
    };

    updateCIAMutation.editItem(input);

    setTimeout(() => props.resetFunction && props.resetFunction(), 1000);
  };

  return (
    <>
      <Form inline>
        <FormGroup check>
          <Label>Confidentiality</Label>
          <Input
            style={checkBoxStyle}
            type="checkbox"
            onChange={() => handleCheck("confidentiality", !cIsEnabled)}
            checked={cIsEnabled}
            disabled={!checkPermissionsHook.resource.update}
            title={checkPermissionsHook.resource.update ? undefined : "You don't have Update Permissions on Field: cia"}
          />
        </FormGroup>
        <FormGroup check>
          <Label>Integrity</Label>
          <Input
            style={checkBoxStyle}
            type="checkbox"
            onChange={() => handleCheck("integrity", !iIsEnabled)}
            checked={iIsEnabled}
            disabled={!checkPermissionsHook.resource.update}
            title={checkPermissionsHook.resource.update ? undefined : "You don't have Update Permissions on Field: cia"}
          />
        </FormGroup>
        <FormGroup check>
          <Label>Availability</Label>
          <Input
            style={checkBoxStyle}
            type="checkbox"
            onChange={() => handleCheck("availability", !aIsEnabled)}
            checked={aIsEnabled}
            disabled={!checkPermissionsHook.resource.update}
            title={checkPermissionsHook.resource.update ? undefined : "You don't have Update Permissions on Field: cia"}
          />
        </FormGroup>
      </Form>
    </>
  );
};

export default withOrganizationCheck(KeyRiskIndicatorCIA);
