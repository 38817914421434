import React, { useEffect } from "react";
import { GENERIC_FIELD_TYPES } from "../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import NonStandardControlSystem from "../../../Compliance/EvidenceRiskControlLinks/components/NonStandardControlSystem";
import NonStandardControlRiskControl from "../../../Compliance/EvidenceRiskControlLinks/components/NonStandardControlRiskControl";
import { processFieldOverrides } from "../../../../utils/Functions/Views/grid/proccessFieldOverrides";
import { modules, resources } from "@rivial-security/role-utils";
import NonStandardControlImplementationDetails from "../../../Compliance/EvidenceRiskControlLinks/components/NonStandardControlImplementationDetails";
import NonStandardControlCostOfControl from "../../../Compliance/EvidenceRiskControlLinks/components/NonStandardControlCostOfControl";
import NonStandardControlImplementationRating from "../../../Compliance/EvidenceRiskControlLinks/components/NonStandardControlImplementationRating";
import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";

/**
 * Displays a custom list of risk control overrides
 * @param {string} organizationID - the organization for the risk control overrides
 * @param {object[]} fieldOverrides - custom grid field definitions to merge with the default definitions
 * @param {object} gridConfig - custom grid settings
 * @return {{gridDisplay: JSX.Element, data: *, lastSelectedItem: string, setData: (value: (((prevState: *) => *) | *)) => void, display: JSX.Element, error: unknown, isLoading: boolean, createResourceComponent: JSX, ref: string, setSelectedItems: (value: (((prevState: []) => []) | [])) => void, setIsLoading: (value: (((prevState: boolean) => boolean) | boolean)) => void, setLastSelectedItem: (value: (((prevState: string) => string) | string)) => void, itemsToCheck: unknown, setError: (value: unknown) => void, resetFunction: function(): void, fields: Object[], setItemsToCheck: (value: unknown) => void, selectedItems: []}}
 */
export const useNonStandardControlsGrid = ({ organizationID, fieldOverrides, gridConfig = {} } = {}) => {
  const module = modules.RISK;
  const resource = resources.INFORMATION_SYSTEM;

  //Define grid hook to display systems with non-standard controls info
  const fields = [
    {
      name: "risk",
    },
    {
      name: "controlName",
      friendlyName: "Control",
    },
    {
      name: "control",
      visible: false,
      component: <NonStandardControlRiskControl />,
    },
    {
      name: "system",
      component: <NonStandardControlSystem />,
      width: 200, // keep this to allow for horizontal scrollbar to appear when little space is available
    },
    {
      name: "implementationRating",
      inputType: GENERIC_FIELD_TYPES.PERCENTAGE,
      component: <NonStandardControlImplementationRating />,
    },
    {
      name: "implementationDetails",
      component: <NonStandardControlImplementationDetails />,
    },
    {
      name: "costOfControl",
      inputType: GENERIC_FIELD_TYPES.DOLLAR,
      component: <NonStandardControlCostOfControl />,
    },
  ];

  //Go through all override fields and see if there are any that need to be added to the grid, match by field name
  useEffect(() => {
    processFieldOverrides({ originalFields: fields, fieldOverrides });
  }, [fieldOverrides]);

  gridConfig = {
    fields,
    helpCenterRoute: "risk-control-categories",
    persistenceUUID: "nonStandardControlGrid-2657-897535-8878-5555",
    enableQuickDetails: false,
    disableQuickDetails: true,
    ...gridConfig,
  };

  const roleConfig = {
    module,
    resource,
  };

  const cardConfig = {
    title: "Information Systems with Non-Standard Risk Controls",
    headerIcon: "icon-screen-desktop",
    enableSticky: false,
  };

  const nonStandardControlSystemsGrid = useDataGrid({
    ...cardConfig,
    ...gridConfig,
    ...roleConfig,
  });
  return {
    ...nonStandardControlSystemsGrid,
  };
};
