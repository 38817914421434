import { modules, resources } from "@rivial-security/role-utils";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import React from "react";
import NestedDocumentsCard from "../../../Compliance/Documents/components/NestedDocumentsCard";

/**
 * Displays Documents for an Observation
 * @param {object} item - the observation to show documents for
 * @param {string} organizationID - current organization ID
 * @returns {JSX.Element}
 */
const ObservationDocuments = ({ item, organizationID }) => {
  return (
    <NestedDocumentsCard
      item={item}
      module={modules.GOVERNANCE}
      resource={resources.OBSERVATION}
      parentTypename={resources.OBSERVATION}
      connectionIDField={"observationID"}
      organizationID={organizationID}
    />
  );
};

export default withOrganizationCheck(ObservationDocuments);
