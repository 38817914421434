import React from "react";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import ConfigureReportAutomation from "./ConfigureReportAutomation";
import { AUTOMATION_TYPES } from "../enums/AUTOMATION_TYPES";
import ConfigureNotificationAutomation from "./ConfigureNotificationAutomation";
import ConfigureMutateResourceAutomation, {
  MUTATE_RESOURCE_AUTOMATION_TYPE,
} from "./ConfigureMutateResourceAutomation";
import ConfigureWebRequestAutomation from "./ConfigureWebRequestAutomation";
import ConfigureLoopAutomation from "./ConfigureLoopAutomation";
import { Grid } from "@mui/material";
import SmartValueHelper from "./SmartValueHelper";
import { useSmartValueContext } from "../../../../utils/SmartValues/hooks/useSmartValueContext";
import ConfigureCustomQueryAutomation from "./ConfigureCustomQueryAutomation";

/**
 * Switch component for displaying each type of automation
 * @param {AutomationStep} automationStep - the automation step to display
 * @param {object} automation - the parent automation automationStep itself, with trigger and condition info
 * @param {object[]} automations - the current automation step info for the parent node
 * @param {function} setAutomations - function to set the automations
 * @param {string} organizationID - the organization ID
 * @param {function} toggleModal - function to toggle the modal
 * @param {object} trigger - the trigger for the automation
 * @param {boolean} isTemplate - if TRUE will create the automation as a template
 * @param {object[]} ancestorSteps - step configurations from parent nodes (for instance, the siblings of loop steps)
 * @param {boolean} readOnly - sets the UI to a read-only mode
 * @param {boolean} showLoopSmartValues - if TRUE will show the smart values for the loop step
 * @returns {JSX.Element}
 */
const EditAutomation = ({
  automationStep,
  automation,
  automations,
  setAutomations,
  organizationID,
  toggleModal,
  trigger,
  isTemplate = false,
  ancestorSteps,
  readOnly,
  showLoopSmartValues,
}) => {
  const allStepsFieldConfig = {
    name: {
      label: "Automation Step ID",
      inputType: "text",
      tooltip:
        "A custom name for this automation step. Defining this value will allows you to use the results of this step in other automation steps by utilizing smart values.",
    },
  };

  const formConfig = {
    ...(readOnly && { disableSubmitButton: true }),
  };

  let configurationComponent;
  switch (automationStep?.type) {
    case AUTOMATION_TYPES.GENERATE_REPORT:
      configurationComponent = (
        <ConfigureReportAutomation
          automationStep={automationStep}
          automations={automations}
          setAutomations={setAutomations}
          organizationID={organizationID}
          toggleModal={toggleModal}
          fieldConfig={allStepsFieldConfig}
          formConfig={formConfig}
          trigger={trigger}
          isTemplate={isTemplate}
        />
      );
      break;
    case AUTOMATION_TYPES.SEND_NOTIFICATION:
      configurationComponent = (
        <ConfigureNotificationAutomation
          automationStep={automationStep}
          automations={automations}
          setAutomations={setAutomations}
          organizationID={organizationID}
          toggleModal={toggleModal}
          fieldConfig={allStepsFieldConfig}
          formConfig={formConfig}
          isTemplate={isTemplate}
        />
      );
      break;
    case AUTOMATION_TYPES.CREATE_RESOURCE:
      configurationComponent = (
        <ConfigureMutateResourceAutomation
          automationStep={automationStep}
          automations={automations}
          setAutomations={setAutomations}
          organizationID={organizationID}
          toggleModal={toggleModal}
          fieldConfig={allStepsFieldConfig}
          formConfig={formConfig}
          type={MUTATE_RESOURCE_AUTOMATION_TYPE.CREATE}
          isTemplate={isTemplate}
        />
      );
      break;

    case AUTOMATION_TYPES.HTTP:
      configurationComponent = (
        <ConfigureWebRequestAutomation
          automationStep={automationStep}
          automations={automations}
          setAutomations={setAutomations}
          organizationID={organizationID}
          toggleModal={toggleModal}
          fieldConfig={allStepsFieldConfig}
          formConfig={formConfig}
          isTemplate={isTemplate}
        />
      );
      break;

    case AUTOMATION_TYPES.UPDATE_RESOURCE:
      configurationComponent = (
        <ConfigureMutateResourceAutomation
          automationStep={automationStep}
          automations={automations}
          setAutomations={setAutomations}
          organizationID={organizationID}
          toggleModal={toggleModal}
          fieldConfig={allStepsFieldConfig}
          formConfig={formConfig}
          type={MUTATE_RESOURCE_AUTOMATION_TYPE.UPDATE}
          isTemplate={isTemplate}
        />
      );
      break;

    case AUTOMATION_TYPES.LOOP:
      configurationComponent = (
        <ConfigureLoopAutomation
          automationStep={automationStep}
          automation={automation}
          automations={automations}
          setAutomations={setAutomations}
          organizationID={organizationID}
          toggleModal={toggleModal}
          fieldConfig={allStepsFieldConfig}
          formConfig={formConfig}
          ancestorSteps={ancestorSteps}
          readOnly={readOnly}
          isTemplate={isTemplate}
        />
      );
      break;

    //  WIP - Not Yet Supported. See: RISC-3271
    case AUTOMATION_TYPES.CUSTOM_QUERY:
      configurationComponent = (
        <ConfigureCustomQueryAutomation
          automationStep={automationStep}
          automation={automation}
          automations={automations}
          setAutomations={setAutomations}
          organizationID={organizationID}
          toggleModal={toggleModal}
          fieldConfig={allStepsFieldConfig}
          formConfig={formConfig}
        />
      );
      break;

    default:
      configurationComponent = <div>Invalid Automation Type Selected</div>;
      break;
  }

  const smartValueContext = useSmartValueContext();

  return (
    <div style={{ width: "100%" }}>
      <Grid container spacing={1}>
        <Grid item xs={smartValueContext?.smartValues?.length > 0 ? 9 : 12}>
          {configurationComponent}
        </Grid>
        <Grid item xs={smartValueContext?.smartValues?.length > 0 ? 3 : 0}>
          {/*
               Displays a list of Smart Value chips with a searchbar.
               Chips can be clicked on to add them to the clipboard
           */}

          <SmartValueHelper
            automationStep={automationStep}
            automation={automation}
            automations={automations}
            showLoopSmartValues={showLoopSmartValues}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default withOrganizationCheck(EditAutomation);
