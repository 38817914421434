import AccordionCard from "../../../../utils/GenericComponents/AccordionCard/components/AccordionCard";
import Grid from "@mui/material/Grid";
import { Editor, JSONTextEditor } from "material-jsoneditor";
import React from "react";

/**
 * A Custom Json Input Component
 * @param {string} value - the value of the input
 * @param {function} onChange - the onChange function to call when the value changes
 * @param {string} field - the field name for the input
 * @param {string} label - the label for the input
 * @param {string} tooltip - the tooltip for the input
 * @return {JSX.Element}
 * @constructor
 */
const CustomJsonInput = ({ value, onChange, field, label, tooltip }) => {
  return (
    <AccordionCard icon={<i className={"icon-pencil"} />} title={label} subTitle={tooltip}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ padding: 1, height: "100%" }}>
          <Editor value={value} onChange={onChange} />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ padding: 1, height: "100%" }}>
          <div style={{ height: "30em" }}>
            <JSONTextEditor value={value} onChange={onChange} style={{ height: "inherit" }} />
          </div>
        </Grid>
      </Grid>
    </AccordionCard>
  );
};

export default CustomJsonInput;
