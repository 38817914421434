import { API, graphqlOperation } from "@aws-amplify/api";
import { ErrorLogger, InfoLogger } from "@utils/EventLogger";

import { generateGraphql } from "@rivial-security/generategraphql";

const { deleteMutation: deleteTagMeetingLink } = generateGraphql("MeetingTagLink");

/**
 * Deletes a Tag Meeting Link from the database
 * @param {object} tagLink
 * @return {Promise<void>}
 * @constructor
 */
export const DeleteTagMeetingLink = async (tagLink) => {
  await API.graphql(
    graphqlOperation(deleteTagMeetingLink, {
      input: {
        id: tagLink.id,
      },
    }),
  )
    .then(() => InfoLogger(`Tag Meeting Link ${tagLink.id} was Successfully Deleted`))
    .catch((err) => ErrorLogger(err));
};
