import { SeenTarget } from "./getVulnerabilitySeenTargets";
import { Target } from "@rivial-security/schema-types";
import { getTargetIdentifier } from "../../../../Targets/functions/getTargetIdentifier";
import { isNonEmptyArray } from "@rivial-security/func-utils";

export interface VulnerabilityTarget {
  name: string;
  color: string;
}

type SeenTargets = Record<string, SeenTarget>;

export interface GetTargetsBasedOnFixedRatioParams {
  seenTargets: SeenTargets;
  targets: Partial<Target>[];
}

export const getTargetsBasedOnFixedRatio = ({
  seenTargets,
  targets,
}: GetTargetsBasedOnFixedRatioParams): VulnerabilityTarget[] => {
  const newTargetList: VulnerabilityTarget[] = [];

  if (Object.keys(seenTargets).length > 0) {
    newTargetList.push(...assignColorsForSeenTargets(seenTargets));
  } else if (isNonEmptyArray(targets)) {
    newTargetList.push(...assignColorsForTargets(targets));
  }

  return newTargetList;
};

const assignColorsForTargets = (targets: Partial<Target>[]): VulnerabilityTarget[] => {
  const newTargetList: VulnerabilityTarget[] = [];

  for (const target of targets) {
    newTargetList.push({
      name: getTargetIdentifier(target),
      color: "black",
    });
  }

  return newTargetList;
};

const assignColorsForSeenTargets = (seenTargets: SeenTargets): VulnerabilityTarget[] => {
  const newTargetList: VulnerabilityTarget[] = [];

  for (const targetID in seenTargets) {
    const target = seenTargets[targetID];

    if (target) {
      newTargetList.push(...assignColorsForSeenTarget(target));
    }
  }

  return newTargetList;
};

const assignColorsForSeenTarget = (target: SeenTarget): VulnerabilityTarget[] => {
  const newTargetList: VulnerabilityTarget[] = [];
  const { totalCount, notFixedCount } = target;

  let color = "black";

  if (totalCount === 0) {
    color = "black";
  } else if (totalCount === notFixedCount) {
    color = "crimson";
  } else if (notFixedCount === 0) {
    color = "green";
  } else if (totalCount > 0 && notFixedCount > totalCount / 2) {
    color = "orangeRed";
  } else if (totalCount > 0) {
    color = "orange";
  }

  newTargetList.push({
    name: getTargetIdentifier(target),
    color,
  });

  return newTargetList;
};
