import i18next from "i18next";
import { initReactI18next, Trans } from "react-i18next";
import resources, { Languages } from "./resources";

i18next
  .use(initReactI18next)
  .init({
    lng: Languages.EN, // if you're using a language detector, do not define the lng option
    resources,
    defaultNS: "common",
    supportedLngs: Object.values(Languages),
    fallbackLng: Languages.EN,
    interpolation: {
      skipOnVariables: false,
    },
  })
  .then(() => void 0)
  .catch((error) => {
    throw error;
  });

export { Trans };
export default i18next;
