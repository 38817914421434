import { getValue } from "@syncfusion/ej2-base";
import {
  convertCamelCaseToSentence,
  formattedDollar,
  formattedPercent,
  isNullOrUndefined,
} from "@rivial-security/func-utils";
import { checkParams } from "../../../../utils/Functions/Errors/checkParams";

/**
 * @description Handles the custom field formatting
 * @param {object} args - syncfusion arguments object for a column
 * @param {object[]} mappedFields - update the column headers to display fieldNames better
 */
export const gridHandleCustomField = ({ args, mappedFields }) => {
  /**
   * Params to be checked for null or undefined
   */
  const params = { args, mappedFields };

  /**
   * Check params for null or undefined
   */
  checkParams({ params });

  if (mappedFields && args?.column?.field) {
    const fieldName = args?.column?.field;
    if (fieldName in mappedFields) {
      const field = mappedFields[fieldName];
      const value = getValue(fieldName, args.data);

      // Handle percent format
      if (field?.format === "percent") {
        args.data[fieldName] = formattedPercent(value);
      }

      // Handle dollar format
      else if (field?.format === "dollar") {
        args.data[fieldName] = formattedDollar(value);
      }

      // Handle sentence
      else if (field?.format === "sentence") {
        args.data[fieldName] = convertCamelCaseToSentence(value);
      }

      // Handle custom functions
      else if (
        field.format === "function" &&
        !isNullOrUndefined(field.function) &&
        typeof field.function === "function"
      ) {
        args.data[fieldName] = field.function(args.data);
      }
    }
    return args;
  }
};
