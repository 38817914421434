import React from "react";
import OrganizationCheck from "../../../../../../utils/Context/OrganizationCheck/OrganizationCheck";
import { withRouter } from "react-router-dom";
import { useSystemAssetChangeDetails } from "../hooks/useSystemAssetChangeDetails";
import { modules, resources } from "@rivial-security/role-utils";

const SystemAssetChangeDetailsBody = ({ itemId, tableDisplay }) => {
  const module = modules.RISK;
  const resource = resources.RISK_CHANGE;

  const systemAssetChangeDetails = useSystemAssetChangeDetails({
    itemId,
    module,
    resource,
    tableDisplay,
  });

  return <>{systemAssetChangeDetails.display}</>;
};

const SystemAssetChangeDetails = (props) => {
  const itemId = (props.item && props.item.id) || props.match.params.id;
  return (
    <OrganizationCheck {...props}>
      <SystemAssetChangeDetailsBody itemId={itemId} />
    </OrganizationCheck>
  );
};

export default withRouter(SystemAssetChangeDetails);
