import {
  AreaSeries,
  Category,
  ChartComponent,
  Crosshair,
  Inject,
  Legend,
  PolarSeries,
  RadarSeries,
  SeriesCollectionDirective,
  SeriesDirective,
} from "@syncfusion/ej2-react-charts";
import React, { useEffect, useRef, useState } from "react";

import { GetQuery } from "../../../../utils/Functions/Graphql/GetQuery";
import { ListQuery } from "../../../../utils/Functions/Graphql/ListQuery";
import { LoadingSpinner } from "../../../../utils/LoadingComponents/LoadingSpinner";
import { generateGraphql } from "@rivial-security/generategraphql";
import { getMaturityLevel } from "../../../Charts/VISCO/functions/getMaturityLevel";
import { getMaturityMatrix } from "../../../Program/VCISOMaturityMatrix/deprecated/VCISOMaturityMatrix";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { round } from "../../../../utils/Functions/Number/round";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * SyncFusion Radar Chart Wrapper for the VCISO Blueprint
 * @param {string} [organizationID] - the organization that this blueprint data belongs to 9provide to auto retrieve organizations main blueprint)
 * @param {object} [blueprintData=null] - can provide any blueprint data to display (so there's no need to retrieve the information from db)
 * @param {boolean} autoAdjustScale=false - if TRUE will try to scale the chart to fill vertical space
 * @param {boolean} disableAnimations=false - if true will load the chart instantly without gradual series loading
 * @return {JSX.Element}
 */
const BlueprintRadarChart = ({
  organizationID,
  blueprintData = null,
  autoAdjustScale = false,
  disableAnimations = false,
}) => {
  const getChartData = (maturityMatrix) => {
    if (!maturityMatrix || !maturityMatrix.programElements || !maturityMatrix.programElements.items) {
      return [[], []];
    }

    const goalData = [];
    const currentData = [];

    for (const element of maturityMatrix.programElements.items) {
      const goalObj = {};
      const currentObj = {};

      const [current, goal] = getMaturityLevel(element);

      goalObj["governance"] = element.name;
      goalObj["Governance Blueprint Level Goal"] = isNaN(goal) ? 0 : round(goal, 2);

      currentObj["governance"] = element.name;
      currentObj["Current Governance Blueprint Level"] = isNaN(current) ? 0 : round(current, 2);

      goalData.push(goalObj);
      currentData.push(currentObj);
    }

    return [goalData, currentData];
  };

  const getBlueprint = async (blueprintId) => {
    return await GetQuery({
      query: getMaturityMatrix,
      variables: {
        id: blueprintId,
      },
    });
  };

  const [goalSeries, setGoalSeries] = useState(null);
  const [currentSeries, setCurrentSeries] = useState(null);

  const [blueprintId, setBlueprintId] = useState(null);

  /**
   * Get the first blueprint ID from the Organization's blueprint list (only if blueprint data wasn't already provided)
   */
  useEffect(() => {
    if (!blueprintData) {
      const { listQuery } = generateGraphql("MaturityMatrix");
      ListQuery({ query: listQuery, organizationID }).then((list) => {
        if (list && list[0] && list[0].id) {
          setBlueprintId(list[0].id);
        }
      });
    }
  }, []);

  useEffect(() => {
    /**
     * Use provided blueprint data from arguments (takes precedence)
     */
    if (!isNullOrUndefined(blueprintData)) {
      const [goal, current] = getChartData(blueprintData);
      setGoalSeries(goal);
      setCurrentSeries(current);
    } else if (!isNullOrUndefined(blueprintId)) {
      /**
       * Get blueprint data for chart from database
       */
      getBlueprint(blueprintId).then((blueprint) => {
        if (!isNullOrUndefined(blueprint)) {
          const [goal, current] = getChartData(blueprint);
          setGoalSeries(goal);
          setCurrentSeries(current);
        }
      });
    } else {
      setGoalSeries([]);
      setCurrentSeries([]);
    }
  }, [blueprintId, blueprintData]);

  const currentFilLColor = "rgb(62,144,231)";
  const currentBorderColor = "rgb(17,45,60)";
  const goalFillColor = "rgb(20,190,203)";
  const goalBorderColor = "rgb(10,84,90)";

  //Setting chart size based on container size
  const [graphHeight, setGraphHeight] = useState(null);
  const containerRef = useRef(null);
  const graphRef = useRef(null);
  useEffect(() => {
    if (containerRef.current && autoAdjustScale) {
      const rect = containerRef.current.getBoundingClientRect();
      setGraphHeight(rect.height.toString());
    }
  }, [containerRef.current]);

  /**
   * Checks if there is enough data for the series to load the chart without an error
   * @returns {boolean}
   */
  const hasValidData = () => {
    return (
      Array.isArray(currentSeries) && currentSeries.length > 0 && Array.isArray(goalSeries) && goalSeries.length > 0
    );
  };

  return (
    <div id="charts" style={{ height: "100%", width: "100%" }} ref={containerRef}>
      {isNullOrUndefined(currentSeries) || isNullOrUndefined(goalSeries) ? (
        <LoadingSpinner loadingMessage="Loading Blueprint Levels.. " />
      ) : hasValidData() ? (
        <ChartComponent
          ref={graphRef}
          height={graphHeight || "100%"}
          id="blueprint-radar-chart"
          data-testid="blueprint-radar-chart"
          crosshair={{ enable: true }}
          marker={{ visible: true }}
          primaryXAxis={{
            valueType: "Category",
            labelPlacement: "OnTicks",
          }}
          primaryYAxis={{
            title: "Maturity Level",
            minimum: 0,
            maximum: 5,
            interval: 1,
          }}
        >
          <Inject services={[AreaSeries, Legend, Category, PolarSeries, RadarSeries, Crosshair]} />
          <SeriesCollectionDirective>
            <SeriesDirective
              dataSource={currentSeries}
              xName="governance"
              yName="Current Governance Blueprint Level"
              name="Current Maturity"
              width={2}
              opacity={0.5}
              type="Polar"
              fill={currentFilLColor}
              drawType="Area"
              border={{ color: currentBorderColor }}
              animation={{ enable: !disableAnimations }}
            />
            <SeriesDirective
              dataSource={goalSeries}
              xName="governance"
              yName="Governance Blueprint Level Goal"
              name="Maturity Goal"
              width={2}
              opacity={0.5}
              type="Polar"
              fill={goalFillColor}
              drawType="Area"
              border={{ color: goalBorderColor }}
              animation={{ enable: !disableAnimations }}
            />
          </SeriesCollectionDirective>
        </ChartComponent>
      ) : (
        <div
          style={{
            height: "100%",
            margin: "auto",
            textAlign: "center",
            width: "50%",
            padding: "10px",
          }}
        >
          <p style={{ position: "relative", top: "50%" }}>Not enough data for a Radar Chart</p>
        </div>
      )}
    </div>
  );
};

export default withOrganizationCheck(BlueprintRadarChart);
