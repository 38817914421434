import React, { useEffect, useState } from "react";

import { Button } from "reactstrap";
import { ErrorLogger } from "@utils/EventLogger";
import EvidenceDetails from "../../../Compliance/Evidence/components/EvidenceDetails";
import FindingDetailsCard from "../../../Testing/Vulnerabilities/components/VulnerabilityDetails";
import IncidentDetails from "../../../Response/Incidents/components/IncidentDetails";
import { QueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useModal } from "../../../../hooks/views/useModal";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * @description Display Action Item linked custom data
 * @param {object} item - generic action item object
 * @returns {JSX.Element}
 * @constructor
 */
const ActionItemCustomData = ({ item }) => {
  const [data, setData] = useState(null);
  const [linkedResource, setLinkedResource] = useState(null);
  const [displayField, setDisplayField] = useState("");

  /**
   * Get custom data from an action item
   */
  useEffect(() => {
    if (item?.data) {
      try {
        setData(JSON.parse(item.data));
      } catch (e) {
        ErrorLogger("Error! Can not parse the ActionItem custom data field");
      }
    }
  }, [item]);

  /**
   * Get linked resource
   */
  useEffect(() => {
    let itemId = "",
      typeName = "",
      field = "";

    /**
     * For new types add 3 params "itemId", "typeName" and "field" (field is what you want to display)
     */
    if (data?.evidenceID) {
      itemId = data?.evidenceID;
      typeName = "Evidence";
      field = "name";
    } else if (data?.vulnerabilityID) {
      itemId = data?.vulnerabilityID;
      typeName = "Finding";
      field = "name";
    } else if (data?.incidentID) {
      itemId = data?.incidentID;
      typeName = "Incident";
      field = "what";
    }

    if (itemId && typeName && field) {
      QueryGetItem({
        query: generateGraphql(typeName, [field, "__typename"]).getQuery,
        itemId,
      }).then((data) => setLinkedResource(data));
      setDisplayField(field);
    }
  }, [data]);

  const modal = useModal(
    `${linkedResource?.["__typename"]}: ${linkedResource?.[displayField]}`,
    <div>
      {(() => {
        /**
         * Details components for linked resources
         * For new types add Details page here
         */
        switch (linkedResource?.["__typename"]) {
          case "Evidence":
            return <EvidenceDetails item={linkedResource} />;
          case "Finding":
            return <FindingDetailsCard item={linkedResource} />;
          case "Incident":
            return <IncidentDetails item={linkedResource} />;
        }
      })()}
    </div>,
    <Button
      size="sm"
      color="ghost-primary"
      className="btn-pill"
      disabled={!linkedResource}
      style={{ boxShadow: "none" }}
      title={`Open a ${linkedResource?.["__typename"]}: ${linkedResource?.[displayField]}`}
    >
      View
    </Button>,
    {
      width: "80vw",
    },
  );

  return (
    <div>
      {linkedResource ? (
        <>
          {linkedResource?.["__typename"]}: {linkedResource?.[displayField]} {modal?.modalButton}
        </>
      ) : (
        <p>No linked resource</p>
      )}
    </div>
  );
};

export default withOrganizationCheck(ActionItemCustomData);
