import React, { useState } from "react";
import DefaultRiskMapping from "../../../../../RiskConfig/KeyRiskIndicators/components/DefaultRiskMapping";
import { Card, CardBody, Collapse } from "reactstrap";
import { generateGraphql } from "@rivial-security/generategraphql";
import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import { modules, resources } from "@rivial-security/role-utils";
import { useCheckPermissions } from "../../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";

/**
 * Displays the KRI/Business Risk mapping for a System
 * @param systemRiskLink
 * @param resetFunction
 * @returns {JSX.Element}
 * @constructor
 */
const RiskMapping = ({ systemRiskLink, resetFunction }) => {
  const module = modules.RISK;
  const resource = resources.INFORMATION_SYSTEM;

  const checkPermissions = useCheckPermissions({ module, resource });

  const [isOpen, setIsOpen] = useState(false);

  //Update GraphQL
  const nestedFields = {
    riskMapping: `
      {
        dataBreach
        systemDisruption
        facilityDisruption
        fraud
        malware
        vendor
        compliance
      }
    `,
  };
  const { updateMutation } = generateGraphql("SystemRiskLink", ["riskMapping"], nestedFields);

  return (
    <div>
      <strong>
        Business Risks
        <i
          className={!isOpen ? "fa fa-eye-slash" : "fa fa-eye"}
          title={`${isOpen ? "Hide" : "Show"} Organization Risks`}
          style={{ cursor: "pointer", marginLeft: "0.5em" }}
          onClick={() => setIsOpen(!isOpen)}
        />
      </strong>
      <Collapse isOpen={isOpen}>
        <Card style={{ marginTop: "1em" }}>
          <CardBody>
            <DefaultRiskMapping
              item={{ ...systemRiskLink }}
              updateMutation={updateMutation}
              fieldName="riskMapping"
              resetFunction={resetFunction}
              isInsideSystem={true}
              disabled={!checkPermissions.resource.update}
            />
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
};

export default withOrganizationCheck(RiskMapping);
