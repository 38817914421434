import React from "react";
import GenericEditField from "../../../../utils/GenericComponents/GenericEditFieldV2";
import { modules, resources } from "@rivial-security/role-utils";

const updateMeetingIsAllDay = /* GraphQL */ `
  mutation UpdateMeeting($input: UpdateMeetingInput!) {
    updateMeeting(input: $input) {
      id
      isAllDay
    }
  }
`;

export const MeetingIsAllDay = (props) => {
  const module = modules.GOVERNANCE;
  const resource = resources.MEETING;

  return (
    <GenericEditField
      inputType={"boolean"}
      item={props.item}
      field={"isAllDay"}
      mutation={updateMeetingIsAllDay}
      resource={resource}
      module={module}
      resetFunction={props.resetFunction}
      disableRoleChecking={props.disableRoleChecking}
      {...props}
    />
  );
};
