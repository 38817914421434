import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";
import { useDepartmentDetails } from "../hooks/useDepartmentDetails";
import { ICON_MAP } from "../../../../enums/ICON_MAP";

/**
 * Displays the details of a single Department
 * @param {object} props - all props passed to the useDetailsCard hook
 * @returns {JSX.Element}
 * @constructor
 */
const DepartmentDetails = (props) => useDepartmentDetails(props).display;

export default withDetailsPage(DepartmentDetails, {
  title: "Department Details",
  icon: ICON_MAP?.RESOURCES?.Department,
});
