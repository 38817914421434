import { useEffect, useState } from "react";
import { useVendorReviewDataGrid } from "../../../../../../Vendor/VendorSolutionReviews/hooks/useVendorReviewDataGrid";

/**
 * @description Display a vendor reviews list of a vendor solution
 * @param {string} organizationID - selected organization
 * @param {object} item - a system
 * @param {object} props - props to pass through to useVendorReviewDataGrid
 * @returns {object} {{gridDisplay: JSX.Element, data: unknown, lastSelectedItem: string, setData: (value: unknown) => void, display: JSX.Element, isLoading: boolean, ref: string, setSelectedItems: (value: (((prevState: *[]) => *[]) | *[])) => void, setIsLoading: (value: (((prevState: boolean) => boolean) | boolean)) => void, setLastSelectedItem: (value: (((prevState: string) => string) | string)) => void, resetFunction: getData, fields: Object[], selectedItems: *[]}}
 */
export const useSystemVendorReviewsGrid = ({ organizationID, item, ...props }) => {
  const [vendorReviews, setVendorReviews] = useState([]);

  const cardConfig = {
    enableSticky: false,
  };

  const queryConfig = {
    query: null,
  };

  const gridConfig = {
    enablePersistence: false,
  };

  const grid = useVendorReviewDataGrid({
    cardConfig,
    organizationID,
    queryConfig,
    data: vendorReviews,
    vendorSolutionID: item?.vendorSolutionID,
    gridConfig,
    ...props,
  });

  useEffect(() => {
    if (item?.vendorSolution?.vendorReviews?.items) {
      setVendorReviews(item?.vendorSolution?.vendorReviews?.items);
    }
  }, [item]);

  useEffect(() => {
    grid.setData(vendorReviews);
  }, [vendorReviews]);

  return {
    ...grid,
    display: grid.gridDisplay,
  };
};
