import FormControlLabel from "@mui/material/FormControlLabel";
import React from "react";
import RequestPermissionButton from "../../../../../../utils/Permissions/components/RequestPermissionButton";
import Switch from "@mui/material/Switch";
import { Tooltip } from "@mui/material";
import { convertCamelCaseToSentence } from "@rivial-security/func-utils";

/**
 * Interface for the Read, Create, Delete, Update switches

 * @param {boolean} checked - whether this switch is in the checked or unchecked state
 * @param {function} handleChange - handler for setting an operation to be true/false
 * @param {string} name - the operation being adjusted
 * @param {boolean} disableEdits - whether this switch is disabled
 * @param {string} module - the module that this switch is for
 * @param {string} resource - the resource that this switch is for
 * @param {string} [color = "primary"] - the color of the switch
 * @constructor
 */
const ResourceOperationSwitch = ({
  checked,
  handleChange,
  name,
  disableEdits,
  module,
  resource,
  color = "primary",
}) => {
  return (
    <FormControlLabel
      control={
        disableEdits ? (
          <DisabledSwitch resource={resource} checked={checked} module={module} name={name} />
        ) : (
          <Switch
            disabled={disableEdits}
            title={`Click to ${checked ? "Disable" : "Enable"}`}
            checked={checked}
            onChange={(e) => handleChange(!checked, name)}
            name={name}
            color={color}
          />
        )
      }
      label={convertCamelCaseToSentence(name)}
    />
  );
};

/**
 * Displays a checkmark icon or a 'request permissions' button for disabled modules or resource permissions
 * @param {boolean} checked - whether this switch is checked or unchecked
 * @param {string} resource - resource that this switch is for
 * @param {string} module - module that this switch is for
 * @param {string} name - the operation name
 * @returns {JSX.Element}
 * @constructor
 */
const DisabledSwitch = ({ checked, resource, module, name }) => {
  return (
    <div style={{ marginLeft: "1em" }}>
      {checked ? (
        <i
          title={
            resource
              ? `The '${name}' operation on the '${resource}' Resource is Enabled`
              : `The '${module}' Module is Enabled`
          }
          className={"icon-check"}
          style={{ marginRight: "0.5em" }}
        />
      ) : (
        <Tooltip
          interactive={true}
          title={<RequestPermissionButton resource={resource} module={module} operation={name} />}
        >
          <i
            title={
              resource
                ? `The '${name}' operation on the '${resource}' Resource is Disabled`
                : `The '${module}' Module is Disabled`
            }
            className={"icon-close"}
            style={{ marginRight: "0.5em" }}
          />
        </Tooltip>
      )}
    </div>
  );
};

export default ResourceOperationSwitch;
