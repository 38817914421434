"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.applyWidgetFieldTransforms = void 0;
const lodash_1 = require("lodash");
const tableFieldTransformType_1 = require("./tableFieldTransformType");
const func_utils_1 = require("@rivial-security/func-utils");
/**
 * Applies widget level transformations on the final fields
 * @param {object[]} data - raw widget data
 * @param {object} seriesItem - series settings
 * @returns {*|*[]}
 */
const applyWidgetFieldTransforms = ({ data, seriesItem }) => {
    const fieldSettings = seriesItem?.fieldSettings;
    //Check arguments
    if (typeof fieldSettings !== "object") {
        return data;
    }
    if (!Array.isArray(data)) {
        return data;
    }
    const newData = [];
    for (const item of data) {
        try {
            const newItem = (0, lodash_1.cloneDeep)(item);
            for (const fieldName in fieldSettings) {
                const field = fieldSettings[fieldName];
                const transformType = field?.transformType;
                const fieldData = newItem[fieldName];
                if (!(0, func_utils_1.isNullOrUndefined)(fieldData) && Object.values(tableFieldTransformType_1.tableFieldTransformType).includes(transformType)) {
                    switch (transformType) {
                        case tableFieldTransformType_1.tableFieldTransformType.DOLLAR:
                            newItem[fieldName] = (0, func_utils_1.formattedDollar)(fieldData);
                            break;
                        case tableFieldTransformType_1.tableFieldTransformType.PERCENT:
                            newItem[fieldName] = (0, func_utils_1.formattedPercent)(fieldData);
                            break;
                        case tableFieldTransformType_1.tableFieldTransformType.LOCALE_DATE:
                            newItem[fieldName] = new Date(fieldData).toLocaleDateString();
                            break;
                        case tableFieldTransformType_1.tableFieldTransformType.SENTENCE_CASE:
                            newItem[fieldName] = (0, func_utils_1.convertCamelCaseToSentence)(fieldData);
                            break;
                        default:
                    }
                }
            }
            newData.push(newItem);
        }
        catch (e) {
            console.log(e);
            newData.push(item);
        }
    }
    return newData;
};
exports.applyWidgetFieldTransforms = applyWidgetFieldTransforms;
