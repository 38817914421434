import React, { useEffect, useState } from "react";
import { TreeItem, TreeView } from "@mui/x-tree-view";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Icon } from "@iconify/react";
import Loader from "../../../../utils/LoadingComponents/Loader";
import { Tooltip } from "@mui/material";
import { deRootifyQueryObject } from "@rivial-security/widget-utils";
import { getFieldIcon } from "../../../../views/CustomQueries/functions/getFieldIcon";
import { getFunctionDefinitionResult } from "../../../../definitions/functions/getFunctionDefinitionResult";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Tree component displaying the current state of the data grid view and all fields in the table,
 * reflects all aspects of the modified view for a custom query table.
 * @param {JSX.Element} prefixTreeItems - tree item component to place above the normal column items
 * @param {object} queryConfig - the query config object that contains the fields and functions
 * @param {object} targetView - the view object of the data grid that shows query data
 * @param {function} onSelectedField - function to call when a tree item is clicked
 * @returns {JSX.Element} - the tree view component
 */
const GridViewTree = ({ prefixTreeItems, queryConfig, targetView, onSelectedField }) => {
  //State information
  const [isLoading, setIsLoading] = useState(true);

  //Loading state before query config and schema are loaded
  useEffect(() => {
    if (queryConfig !== undefined) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [queryConfig]);

  //Function that takes in query config and displays the fields returned by the query with correct icons
  const getColumnTreeItems = () => {
    let namedTreeItems = [];

    // retrieve the root columns returned by the query
    const cleanedQuery = deRootifyQueryObject({ query: queryConfig });
    const fields = cleanedQuery?.fields;
    const functions = cleanedQuery?.functions;

    // for each column create a TreeItem
    if (typeof fields === "object") {
      for (const fieldName in fields) {
        const field = fields[fieldName];
        // retrieve the icon that is appropriate for the field
        const icon = getFieldIcon({ field });

        namedTreeItems.push({
          name: fieldName,
          itemProps: {
            nodeId: `${fieldName}-column-settings-node`,
            label: fieldName,
            collapseIcon: <Icon icon={icon} />,
            endIcon: <Icon icon={icon} />,
          },
        });
      }
    }

    const rootFields = Object.keys(queryConfig?.fields || {});
    const rootFieldName = Array.isArray(rootFields) && rootFields.length > 0 && rootFields[0];

    if (typeof functions === "object") {
      for (const functionName in functions) {
        const func = functions[functionName];
        const functionResult = getFunctionDefinitionResult({
          path: [queryConfig.fields[rootFieldName]],
          func,
        });

        const icon = getFieldIcon({ field: functionResult });

        namedTreeItems.push({
          name: functionName,
          itemProps: {
            nodeId: `${functionName}-column-settings-node`,
            label: functionName,
            collapseIcon: <Icon icon={icon} />,
            endIcon: <Icon icon={icon} />,
          },
        });
      }
    }

    //Modify order of columns to match the one in target view
    const columns = targetView?.columns;
    if (Array.isArray(columns) && columns.length > 0) {
      //Sort the named tree items based on the order of items in the columns array
      namedTreeItems = namedTreeItems.sort((a, b) => {
        const aIndex = columns.findIndex((column) => column.name === a.name);
        const bIndex = columns.findIndex((column) => column.name === b.name);
        return aIndex - bIndex;
      });
    }

    //Append trailing icons to the label depending on whether there is settings applied in the grid
    const sortModel = targetView?.sortModel;
    for (const namedTreeItem of namedTreeItems) {
      const trailingIcons = [];
      const name = namedTreeItem.name;

      // - visibility settings
      const columnVisibilityModel = targetView?.columnVisibilityModel;
      if (columnVisibilityModel?.[name] === false) {
        namedTreeItem.itemProps.disabled = true;
      }

      // - filter settings
      const filterModelItems = targetView?.filterModel?.items;
      if (Array.isArray(filterModelItems)) {
        const filterModelItem = filterModelItems.find((filterModelItem) => filterModelItem.field === name);
        const { operator, value } = filterModelItem || {};
        if (!isNullOrUndefined(operator) && !isNullOrUndefined(value)) {
          trailingIcons.push(
            <Tooltip title={`Filter set to "${operator}: ${value}"`}>
              <Icon icon={"material-symbols:filter-list-rounded"} />
            </Tooltip>,
          );
        }
      }
      // - sorting settings
      if (Array.isArray(sortModel)) {
        const sortModelItem = sortModel.find((sortModelItem) => sortModelItem.field === name);
        const sortType = sortModelItem?.sort;
        if (sortType === "asc") {
          trailingIcons.push(
            <Tooltip title={"Sorted in ascending order"}>
              <Icon icon={"octicon:sort-asc-24"} />
            </Tooltip>,
          );
        } else if (sortType === "desc") {
          trailingIcons.push(
            <Tooltip title={"Sorted in descending order"}>
              <Icon icon={"octicon:sort-desc-24"} />
            </Tooltip>,
          );
        }
      }

      //Modify label to contain the trailing icons
      namedTreeItem.itemProps.label = (
        <div style={{ display: "flex", alignItems: "center", gap: ".5em" }}>
          {namedTreeItem.itemProps.label}
          {trailingIcons}
        </div>
      );
    }

    return namedTreeItems.map((namedTreeItem) => (
      <TreeItem
        {...namedTreeItem.itemProps}
        onClick={() => {
          if (typeof onSelectedField === "function") {
            onSelectedField({ field: namedTreeItem.name });
          }
        }}
      />
    ));
  };

  if (isLoading) {
    return (
      <div style={{ height: "100px" }}>
        <Loader text={"Loading Tree"} />
      </div>
    );
  } else {
    return (
      <TreeView
        aria-label="grid view tree"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        defaultExpanded={["column-setting-node"]}
      >
        {prefixTreeItems}
        <TreeItem
          nodeId={"column-setting-node"}
          label={"Column Settings"}
          onClick={() => {
            if (typeof onSelectedField === "function") {
              onSelectedField({ field: undefined });
            }
          }}
        >
          {getColumnTreeItems()}
        </TreeItem>
      </TreeView>
    );
  }
};

export default GridViewTree;
