import { Button, UncontrolledTooltip } from "reactstrap";
import { setDeviceStatusRemembered } from "../functions/setDeviceStatusRemembered";
import { setDeviceStatusNotRemembered } from "../functions/setDeviceStatusNotRemembered";
import React, { useContext, useEffect, useState } from "react";
import { getRememberedDeviceStatus } from "../functions/getRememberedDeviceStatus";
import { Auth } from "@aws-amplify/auth";
import { UIContext } from "../../../../../utils/Context/UIContext";
import { useCard } from "../../../../../hooks/views/useCard";

/**
 * @description Login Remember My Device Component
 * @return {JSX.Element}
 * @constructor
 */
const LoginRememberMyDevice = () => {
  const { addToast } = useContext(UIContext);

  const [user, setUser] = useState(null);
  const [userDevice, setUserDevice] = useState("");

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      // for getting User Device Key
      user?.getCachedDeviceKeyAndPassword();
      setUser(user);
    });
  }, []);

  useEffect(() => {
    if (user) {
      getRememberedDeviceStatus({ user, callback: setUserDevice });
    }
  }, [user]);

  const card = useCard({
    id: "remember-my-device",
    title: (
      <>
        <h4 style={{ display: "inline" }}>Remember My Device</h4>{" "}
        <i id="remember-my-device-icon-question" className="icon-question" />
        <UncontrolledTooltip placement="right" target="remember-my-device-icon-question">
          Setup Remember My Device functionality
        </UncontrolledTooltip>
      </>
    ),
    body: (
      <>
        <div style={{ marginBottom: "1em" }}>
          <h6>Current value:</h6>
          {userDevice}
        </div>
        <div>
          <Button
            color="ghost-success"
            className="btn-pill"
            style={{ marginRight: "1em" }}
            size="sm"
            onClick={() =>
              setDeviceStatusRemembered({
                user,
                callback: setUserDevice,
                addToast,
              })
            }
            title="This action will save your current device"
          >
            Remember
          </Button>
          <Button
            color="ghost-danger"
            className="btn-pill"
            size="sm"
            onClick={() =>
              setDeviceStatusNotRemembered({
                user,
                callback: setUserDevice,
                addToast,
              })
            }
            title="This action will reset remembered device"
          >
            Reset
          </Button>
        </div>
      </>
    ),
  });

  return card?.display;
};

export default LoginRememberMyDevice;
