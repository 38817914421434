import { aggregationFunctions } from "@rivial-security/widget-utils";
import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { genericResources } from "../../genericResources";
import { schemaEntities } from "../../schemaEntities";
import { getNestedFieldFunctionResult } from "./functions/getNestedFieldFunctionResult";

export const objectDefinition = {
  //[TYPE]
  underlyingType: "Object",
  schemaEntity: schemaEntities.GENERIC,

  //[QUERIES]
  queries: {
    functions: [
      {
        name: "combineNotes",
        description: "Combines all available notes associated with the resource into a single field.",
        result: {
          type: genericResources.STRING,
          name: "fullName",
          description: "All resource notes combined into a single collection of sentences.",
        },
      },
      {
        name: "getNestedField",
        description: "Traverses the chosen path inside result object to expose as a simpler, non-nested property.",
        initializeParameters: ({ path, config }) => {
          if (Array.isArray(path) && path.length > 1) {
            return {
              rootTypename: path[path.length - 2].typename,
              ...config,
            };
          }

          return {
            rootTypename: null,
            ...config,
          };
        },
        parameters: [
          {
            typename: genericResources.RESOURCE,
            name: "rootTypename",
            description: "The resource type of the initial element.",
            hidden: {
              inForms: true,
            },
          },
          {
            typename: genericResources.RESOURCE_PATH,
            name: "path",
            description:
              "The sequential order of nested fields representing the path from one schema type to the result field.",
          },
          {
            typename: genericResources.ENUM,
            name: "aggregationFunction",
            description:
              "Choose how the nested elements are to be combined to form a single value, if nothing is selected aggregation will be skipped.",
            data: {
              options: Object.keys(aggregationFunctions).map((aggregationFunction) => {
                return {
                  text: convertCamelCaseToSentence(aggregationFunction),
                  value: aggregationFunction,
                };
              }),
            },
            hidden: {
              //hide when path doesn't contain a hasMany field
              inForms: ({ input }) => {
                const currentPath = input?.path || [];
                return !currentPath?.some((pathElement) => pathElement?.hasMany);
              },
            },
          },
        ],
        result: getNestedFieldFunctionResult,
      },
      {
        name: "daysUntilDate",
        description: "Calculates the date difference between entered date field value and today.",
        parameters: [
          {
            typename: genericResources.STRING,
            name: "dateField",
            description: "A queried date field to use when calculating the amount of days from today.",
          },
        ],
        result: {
          typename: genericResources.INT,
          name: "numberOfDays",
          description: "The difference in days between today and the selected date (rounded up).",
        },
      },
    ],
  },
};
