import React, { useEffect } from "react";
import { modules, resources } from "@rivial-security/role-utils";

import DateField from "../../../../utils/CustomFields/DateField";
import { GetQuery } from "../../../../utils/Functions/Graphql/GetQuery";
import LegacyPointOfContactField from "@utils/CustomFields/LegacyPointOfContactField";
import { generateGraphql } from "@rivial-security/generategraphql";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useDataCard } from "../../../../hooks/views/useDataCard";

/**
 * Lists Reviewers for a PolicyVersion
 * @param policyVersion
 */
export const usePolicyVersionReviewerList = ({ policyVersion }) => {
  const module = modules.GOVERNANCE;
  const resource = resources.POLICY;

  const checkPermissionsHook = useCheckPermissions({ module, resource });

  const { getQuery: getPolicyVersion } = generateGraphql("PolicyVersion", ["reviewers"], {
    reviewers: `(limit: 250) { items { id reviewedDate status pointOfContact { id firstName lastName email } } }`,
  });

  const updateList = () => {
    if (!isNullOrUndefined(policyVersion)) {
      return GetQuery({
        query: getPolicyVersion,
        variables: {
          id: policyVersion.id,
        },
      }).then((policyVersion) => {
        const reviewers = policyVersion?.reviewers?.items || [];
        reviewersListDataCardHook.setData(reviewers);
      });
    }
  };

  const reviewersListDataCardHook = useDataCard({
    header: "List of Assignees",
    fields: ["pointOfContact", "status", "reviewedDate"],
    customFields: [
      {
        field: "pointOfContact",
        component: <LegacyPointOfContactField disableInfoModal={true} />,
      },
      {
        field: "reviewedDate",
        component: <DateField fieldName={"reviewedDate"} />,
      },
    ],
    options: checkPermissionsHook.CheckFieldPermissionsUpdate("reviewers") && ["delete"],
    deleteMutation: generateGraphql("Reviewer").deleteMutation,
    typename: "Reviewer",
    resetFunction: updateList,
    disableRoleChecking: true,
  });

  /**
   * Update the PolicyReviewer list when the policyVersion changes
   */
  useEffect(() => {
    updateList();
  }, [policyVersion]);

  return {
    ...reviewersListDataCardHook,
  };
};
