import { useEffect, useState } from "react";

import { fetchEvidenceBy } from "../functions/fetchEvidenceBy";
import { generateGraphql } from "@rivial-security/generategraphql";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useForm } from "../../../../hooks/views/useForm/useForm";
import useListQuery from "../../../../hooks/graphql/useListQuery";

/**
 * @description Sort Evidence by Control Framework
 * @param {string} organizationID - selected organization
 * @param {object} grid - evidence grid instance
 * @param {string} groupByControlSetId - group evidence by all or a specific framework
 * @param {function} [setUnfilteredData] - alternative function to set data for the grid (takes priority if present
 */
export const useFetchEvidenceBy = ({ organizationID, grid, groupByControlSetId = "", setUnfilteredData }) => {
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [controlSetId, setControlSetId] = useState(groupByControlSetId);
  const [resetKey, setResetKey] = useState(0);

  const { listQuery } = generateGraphql("ControlSet", ["name"]);
  const controlSets = useListQuery({
    query: listQuery,
    organizationID,
    disableRoleChecking: true,
  });

  const form = useForm({
    id: "evidence-fetchby-form",
    fieldConfig: {
      controlControlSetId: {
        inputType: "dropdown",
        defaultValue: "all",
        onChangeFunction: (e) => setControlSetId(e.controlControlSetId),
        dropdownConfig: {
          data: [{ id: "all", name: "All Controls" }].concat(controlSets?.list).map(({ id, name }) => {
            return {
              value: id,
              text: name,
            };
          }),
        },
      },
    },
    disableRoleChecking: true,
    disableResetButton: true,
    disableSubmitButton: true,
  });

  useEffect(() => {
    //Do not perform updates if control set id is not set
    if (controlSetId === "" || isNullOrUndefined(controlSetId)) return;

    if (controlSetId === "all") {
      grid?.resetFunction();
    } else {
      grid?.setIsLoading(true);
      fetchEvidenceBy({
        controlSetId,
        setIsLoading,
      })
        .then((items) => {
          setItems(items);
          if (setUnfilteredData) {
            setUnfilteredData(items);
          } else {
            grid?.setData(items);
          }
        })
        .finally(() => {
          grid?.setIsLoading(false);
        });
    }
  }, [controlSetId, resetKey]);

  return {
    form,
    items,
    setItems,
    isLoading,
    controlSetId,
    resetFunction: () => setResetKey((resetKey) => resetKey + 1),
  };
};
