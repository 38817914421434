import { useAddAccessControl } from "../hooks/useAddAccessControl";
import { withOrganizationCheck } from "../../Context/withOrganizationCheck";

/**
 * @description Wrapper component for the useAddAccessControl hook
 * @param {object} props
 * @constructor
 */
const AddAccessControl = (props) => useAddAccessControl(props).display;

export default withOrganizationCheck(AddAccessControl);
