import React from "react";
import { modules, resources } from "@rivial-security/role-utils";
import EmailTemplateDetails from "./EmailTemplateDetails";
import { useEmailTemplateGrid } from "../../OrganizationManager/EmailTemplates/hooks/useEmailTemplateGrid";
import EmailTemplateForm from "./EmailTemplateForm";

/**
 * Email Templates Grid
 * @param {string} organizationID - the ownerGroup to use for creating/querying templates
 * @param {string} module - the module to use for role checking
 * @param {string} resource - the resource to use for role checking
 * @param {boolean} disableRoleChecking - if TRUE will disable role checking
 * @param {object} ...props - any other props to pass to the grid
 * @returns {JSX.Element}
 */
const EmailTemplateGrid = ({
  organizationID,
  module = modules.ORGANIZATION_MANAGER,
  resource = resources.EMAIL_TEMPLATE,
  disableRoleChecking = false,
  queryConfig = {},
  gridConfig = {},
  cardConfig = {},
  additionalFields = [],
  ...props
}) => {
  const grid = useEmailTemplateGrid({
    organizationID,
    gridConfig: {
      persistenceUUID: "b9f903a6-3762-4bcd-bde4-123445h4121212",
      detailsTitle: "Email Template Details",
      detailsComponent: <EmailTemplateDetails organizationID={organizationID} module={module} />,
      createResourceComponent: <EmailTemplateForm organizationID={organizationID} module={module} />,
      createItemModalHeader: "Create an Email Template",
      route: null,
    },
    resource,
    module,
    disableRoleChecking,
    ...gridConfig,
    ...props,
  });

  return <div style={{ height: "75vh" }}>{grid.display}</div>;
};

export default EmailTemplateGrid;
