import React from "react";
import { useUUID } from "../../../../../../hooks/functional/useUUID";
import { withOrganizationCheck } from "../../../../../../utils/Context/withOrganizationCheck";

/**
 * Displays timeline information about a point of contact Attesting for an Evidence
 * @returns {JSX.Element}
 */
const CreatedAtActivity = () => {
  const [uuid] = useUUID();

  return (
    <span id={`createdat_activity${uuid}`} style={{ fontSize: ".9em" }}>
      Evidence Created
    </span>
  );
};

export default withOrganizationCheck(CreatedAtActivity);
