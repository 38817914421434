import { concatNotes } from "@rivial-security/func-utils";
import { createTable } from "@rivial-security/report-utils";
import { getCategoryCompletion } from "../../VendorReviewReport/functions/getCategoryCompletion";

/**
 * @description Generate control category table
 * @param {object} input - params to get report data
 * @returns {object}
 */
export const generateControlCategoryTable = (input) => {
  const vendorControlCategories = input?.vendorControlCategories || input?.config?.vendorControlCategories || [];

  const formattedCategories = vendorControlCategories.map((category) => {
    const inPlace = getCategoryCompletion({ property: "inPlace", category });
    const audited = getCategoryCompletion({ property: "audited", category });

    return {
      name: category.name,
      inPlace: `${inPlace.status} in Place (${inPlace.percentage})`,
      audited: `${audited.status} Audited (${audited.percentage})`,
      notes: concatNotes(category?.notes || []),
    };
  });

  const sortedCategories = formattedCategories.sort((a, b) => parseInt(a.name) - parseInt(b.name));

  return createTable({
    fields: ["name", "inPlace", "audited", "notes"],
    fieldNameDictionary: {
      name: "Control Category",
      inPlace: "In Place",
      audited: "Audited",
      notes: "Notes",
    },
    items: sortedCategories,
    headerConfig: {
      textAlignment: "Center",
      bold: true,
    },
  });
};
