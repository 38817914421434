import { modules, resources } from "@rivial-security/role-utils";

import ControlCategoryDetails from "../../ControlCategories/components/ControlCategoryDetails";
import DefaultRiskMapping from "./DefaultRiskMapping";
import KeyRiskIndicatorThreats from "./KeyRiskIndicatorThreats";
import React from "react";
import { generateGraphql } from "@rivial-security/generategraphql";
import { realTimeRiskGQL } from "@rivial-security/risk-calc-utils";
import { useAccordion } from "../../../../../hooks/views/useAccordion/useAccordion";
import { useCheckPermissions } from "../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useDetailsCard } from "../../../../../hooks/views/useDetailsCardV2";
import { withDetailsPage } from "../../../../../utils/Context/withDetailsPage";

/**
 * Author: Jacob Blazina
 * Created At: 7/18/19
 * Edits:
 *  - 9/12/19 JB: Added new AddRiskControlsToRisk component in Legacy Overlay
 *  - 9/17/19 JB: Added Systems links
 *
 * Description: A Table component that shows Risk Details
 */
const KeyRiskIndicatorDetails = ({ itemId, organizationID, updateItemById }) => {
  const module = modules.RISK;
  const resource = resources.KEY_RISK_INDICATOR;

  const checkPermissions = useCheckPermissions({ module, resource });

  const fieldNameDictionary = {
    id: "ID",
    name: "Name",
    classification: "Classification",
    description: "Description",
    annualRateOfOccurrence: "Annual Rate of Occurrence",
    annualRateOfOccurrenceMax: "ARO Max",
    confidenceIntervalLower: "Confidence Interval Lower",
    confidenceIntervalUpper: "Confidence Interval Upper",
    confidenceInterval: "Confidence Interval",
    costOfControls: "Cost of Controls",
    controlEffectiveness: "Control Effectiveness",
    controlCategory: "Associated Control Category",
    defaultRiskMapping: "KRI / Business Risk Mapping",
  };

  const queryConfig = {
    query: realTimeRiskGQL.getRisk_riskConfigDetails,
    itemId,
    organizationID,
  };

  //Update GraphQL
  const nestedFields = {
    defaultRiskMapping: `
      {
        dataBreach
        systemDisruption
        facilityDisruption
        fraud
        malware
        vendor
        compliance
      }
    `,
  };
  const { updateMutation } = generateGraphql("Risk", ["defaultRiskMapping"], nestedFields);

  const detailsConfig = {
    fields: ["name", "description", "annualRateOfOccurrence"],
    customFields: [
      {
        field: "defaultRiskMapping",
        component: <DefaultRiskMapping updateMutation={updateMutation} disabled={!checkPermissions.resource.update} />,
      },
      {
        field: "controlCategory",
        component: <ControlCategoryDetails organizationID={organizationID} />,
        propName: "item",
      },
    ],
    fieldNameDictionary,
    updateMutation: realTimeRiskGQL.updateRisk_minimal,
  };

  const detailsCard = useDetailsCard({
    queryConfig,
    detailsConfig,
    module,
    resource,
    updateItemById,
  });

  const accordion = useAccordion({
    items: [
      {
        title: "General Information",
        subTitle: "Name, Description, and Annual Rate of Occurrence",
        component: detailsCard.tableDisplay,
        icon: "icon-list",
        defaultExpanded: true,
      },
      {
        title: "Control Category",
        subTitle: "Associated Control Category and Sub-Controls",
        component: (
          <ControlCategoryDetails
            organizationID={organizationID}
            item={detailsCard?.item?.controlCategory}
            tableDisplay={true}
          />
        ),
        icon: "icon-shield",
      },
      {
        title: "Business Risk Mapping",
        subTitle: "Configure the roll-up to Business and Enterprise level Risks",
        component: (
          <DefaultRiskMapping
            updateMutation={updateMutation}
            disabled={!checkPermissions.resource.update}
            item={detailsCard?.item}
          />
        ),
        icon: "icon-map",
      },
      {
        title: "Threats",
        subTitle:
          "Configure the association between Threats and KRIs, that will be filled in by default when adding KRIs to Systems",
        component: <KeyRiskIndicatorThreats item={detailsCard?.item} />,
        icon: "icon-target",
      },
    ],
  });

  return <>{accordion.display}</>;
};

export default withDetailsPage(KeyRiskIndicatorDetails);
