import { useMeetingsDataGrid } from "../hooks/useMeetingsDataGrid";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * Component allowing to choose a single meeting from a list of meetings that's visible to the user
 * @param {string} organizationID - current organization ID (from withOrganizationCheck)
 * @param {function} selectButtonCallback - callback accepting the selected item as an object
 * @return {JSX.Element}
 */
const SelectMeeting = ({ organizationID, selectButtonCallback }) => {
  const grid = useMeetingsDataGrid({
    organizationID,
    gridConfig: {
      createItemComponent: null,
      enableSelectButton: true,
      onSelectCallback: selectButtonCallback,
    },
  });

  return grid.gridDisplay;
};

export default withOrganizationCheck(SelectMeeting);
