import DateField from "../../../../../utils/CustomFields/DateField";
import { formattedMemorySize } from "@rivial-security/func-utils";

/**
 * Displays the file metadata for an artifact
 * @param {object} item - artifact object from the database, must have the artifact file property
 * @returns {JSX.Element}
 */
const ArtifactFileCustomField = ({ item }) => {
  const file = item?.file;

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div>
        <span style={{ fontWeight: "bold" }}>Size: </span>
        {/*TODO RISC-3380: In pdf convert lambda record this data in the data base? */}
        {formattedMemorySize({ bytes: file?.size })}
      </div>
      <div>
        <span style={{ fontWeight: "bold" }}>Created At: </span>
        {/*TODO RISC-3380: In pdf convert lambda forward this metadata, record in the data base? */}{" "}
        <DateField item={file} fieldName={"createdAt"} />
      </div>
      <div>
        <span style={{ fontWeight: "bold" }}>Modified At: </span>
        {/*TODO RISC-3380: In pdf convert lambda forward this metadata, record in the data base */}
        <DateField item={file} fieldName={"updatedAt"} />
      </div>
    </div>
  );
};

export default ArtifactFileCustomField;
