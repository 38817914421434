/**
 * Author: Jacob Blazina
 * Created At: 10/1/2019
 * Edits:
 *
 * Description: A Function.
 *              Deletes a Maturity Column from the DB.
 *              First Deletes all Associated Maturity Levels to avoid NULL issues.
 *
 */

import { API, graphqlOperation } from "@aws-amplify/api";

import DeleteMaturityColumn from "../../../functions/DeleteMaturityColumn";
import { DeleteProgramElement } from "../../ProgramElement/functions/DeleteProgramElement";
import { InfoLogger } from "@utils/EventLogger";
import { generateGraphql } from "@rivial-security/generategraphql";

export const DeleteMaturityMatrix = async (item) => {
  const { getQuery: getMaturityMatrix } = generateGraphql("MaturityMatrix", ["name", "programElements"], {
    programElements: `(limit: 100) {
    items {
      id
      name
      programSections (limit: 100) {
         items {
            id
            name
            implementationLevels (limit: 100) {
              items {
                id
                description
                date
                level
                indicator
                frequency
                ownerGroup
                programSectionID
               }
              }
             }
           }
         }
      }`,
  });

  return await API.graphql(
    graphqlOperation(getMaturityMatrix, {
      id: item.id,
    }),
  ).then(async ({ data }) => {
    const maturityMatrix = data && data.getMaturityMatrix;

    if (
      maturityMatrix.programElements &&
      maturityMatrix.programElements.items &&
      maturityMatrix.programElements.items.length > 0
    ) {
      maturityMatrix.programElements.items.forEach((programElement) => {
        DeleteProgramElement(programElement);
      });
    }

    const { deleteMutation: deleteMaturityMatrix } = generateGraphql("MaturityMatrix");

    return await API.graphql(
      graphqlOperation(deleteMaturityMatrix, {
        input: {
          id: maturityMatrix.id,
        },
      }),
    ).then(() => InfoLogger(`Program Blueprint: ${maturityMatrix.id} was Successfully Deleted`));
  });
};

export default DeleteMaturityColumn;
