import { ErrorLogger } from "@utils/EventLogger";
import { getListByFieldString } from "./getListByFieldString";

/**
 * Generates a listBy query based on dynamoDB indexNames
 * @param {string} typename - the root typename of the query, what are you getting a list of?
 * @param {string} indexName - the dynamoDB indexName to query
 * @param {string} indexQueryField - the query field of the index "byWHAT"
 * @param {string} indexQueryFieldTypename - the typename of the indexQueryField
 * @param {string[]} queryFields - the fields to include in the query response
 * @param {object} nestedFields - nested fields for all query fields that require sub selection in the query
 * @returns {string|null}
 */
export const generateListByQuery = ({
  typename,
  indexName,
  indexQueryField = "ownerGroup",
  indexQueryFieldTypename = "String",
  queryFields,
  nestedFields,
}) => {
  //Check arguments
  if (
    typeof typename !== "string" ||
    typeof indexName !== "string" ||
    indexName.length === 0 ||
    !Array.isArray(queryFields) ||
    typeof nestedFields !== "object"
  ) {
    ErrorLogger("Invalid arguments passed to generateListByQuery", {
      typename,
      indexName,
      queryFields,
      nestedFields,
    });
    return null;
  }

  //Create field strings for the query
  const fieldString = getListByFieldString({ queryFields, nestedFields });

  //Create the query string
  const indexWithFirstLetterCapitalized = indexName.charAt(0).toUpperCase() + indexName.slice(1);
  return `
query ${indexWithFirstLetterCapitalized}(
  $${indexQueryField}: ${indexQueryFieldTypename}
  $sortDirection: ModelSortDirection
  $filter: Model${typename}FilterInput
  $limit: Int
  $nextToken: String
) {
  ${indexName}(
    ${indexQueryField}: $${indexQueryField}
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      ${fieldString}
    }
    nextToken
  }
}
  `;
};
