"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createQueryTable = void 0;
const createTable_1 = require("./createTable");
const appsync_utils_1 = require("@rivial-security/appsync-utils");
const createQueryTable = async ({ query, organizationID, fields, fieldNameDictionary, postQueryFilterFunction, headerConfig, tableConfig, sortBy, customFields, otherFilters, }) => {
    let items = await (0, appsync_utils_1.ListQuery)({
        query,
        organizationID,
        otherFilters,
        env: process.env,
    });
    if (postQueryFilterFunction) {
        const newItems = [];
        for (const item of items) {
            if (postQueryFilterFunction(item)) {
                newItems.push(item);
            }
        }
        items = newItems;
    }
    if (sortBy && typeof sortBy === "string") {
        items = items.sort((a, b) => a[sortBy] - b[sortBy]);
    }
    return (0, createTable_1.createTable)({
        customFields,
        fields,
        fieldNameDictionary,
        items,
        headerConfig,
        tableConfig,
    });
};
exports.createQueryTable = createQueryTable;
