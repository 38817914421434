import { useEffect, useState } from "react";
import { calculateChangeReturnOnInvestment } from "../../../../RiskChanges/customFields/functions/calculateChangeReturnOnInvestment";

export const useOverallROIChange = (overallResidualChange, changeInCost) => {
  const [overallROIChange, setOverallROIChange] = useState(0);

  useEffect(() => {
    if (overallResidualChange !== null && overallResidualChange !== undefined) {
      const overallReturnOnInvestment = calculateChangeReturnOnInvestment({
        change: {
          costChange: parseFloat(changeInCost),
          residualChange: overallResidualChange,
        },
      });
      setOverallROIChange(overallReturnOnInvestment);
    }
  }, [overallResidualChange, changeInCost]);

  return {
    overallROIChange,
  };
};
