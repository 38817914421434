import React from "react";
import { useQueryGetItem } from "../../../../../../../../../hooks/graphql/useQueryGetItem";
import NotesV2 from "../../../../../../../../../utils/Notes/components/NotesV2";
import { modules, resources } from "@rivial-security/role-utils";
import { withOrganizationCheck } from "../../../../../../../../../utils/Context/withOrganizationCheck";
import CreateRecommendation from "../../../../../../../Recommendations/components/CreateRecommendation";
import { getNotes } from "../../../graphql/getNotes";
import { updateNotes } from "../../../graphql/updateNotes";

/**
 * Sub Control Notes
 * @param {Object} item - the control
 * @param {String} organizationID - current organization ID
 * @param {Boolean} disableTitle - boolean indicating if the title should be disabled
 * @param {Object} system - the System that this Control is associated with
 * @param {Boolean} isNonStandard - boolean indicating if this Control is Non-Standard
 * @return {JSX.Element}
 * @constructor
 */
const SubControlNotes = ({ item, organizationID, disableTitle, system, isNonStandard }) => {
  const module = modules.RISK;
  const resource = resources.RISK_CONTROL;

  const query = useQueryGetItem({
    itemId: item?.id,
    query: getNotes,
    module,
    resource,
  });

  return (
    <NotesV2
      item={query.item}
      isLoading={query?.isLoading}
      module={module}
      resource={resource}
      tooltip="These are Organization-Level notes for this sub control in general. These notes will be visible to all Information Systems associated with this Sub-Control."
      organizationID={organizationID}
      mutation={updateNotes}
      resetFunction={query.reset}
      maxInitialNotes={1}
      disableTitle={disableTitle}
      observationConnectionField={"riskControlID"}
      observationFormOverride={
        <CreateRecommendation
          organizationID={organizationID}
          system={isNonStandard ? system : undefined} // only pre-set the 'system' if this Control is Non-Standard
          riskControl={item}
        />
      }
    />
  );
};

export default withOrganizationCheck(SubControlNotes);
