import { PropsWithChildren, ReactElement, ReactNode, useState } from "react";
import Text, { TextVariant } from "@components/Atoms/Text/Text";

import cx from "classnames";
import styles from "./Tooltip.module.scss";
import { useTranslation } from "react-i18next";

export enum TooltipPlacement {
  TOP = "top",
  RIGHT = "right",
  BOTTOM = "bottom",
  LEFT = "left",
}

export enum ArrowPosition {
  START = "start",
  MIDDLE = "middle",
  END = "end",
}

export enum TooltipVisibility {
  ON_HOVER = "hover",
  ALWAYS = "always",
}

export interface TooltipProps extends PropsWithChildren {
  /** The content of the tooltip */
  tooltipContent: ReactNode;
  /** The placement of the tooltip (top, right, bottom, left) */
  placement?: TooltipPlacement;
  /** The position of the arrow in the tooltip (start, middle, end) */
  arrowPosition?: ArrowPosition;
  /** Whether the tooltip is disabled */
  disabled?: boolean;
  /** The aria label for the tooltip */
  ariaLabel?: string;
  /** A custom class name for the tooltip */
  containerClassname?: string;
  /** Whether the tooltip controls its own visibility, use `ALWAYS` to keep the tooltip visible all the time */
  visibility?: TooltipVisibility;
}

/**
 * Tooltips are useful for showing extra information about something. They can be used to help guide the user, explain what something is, or show information that may have been truncated due to its length.
 */
const Tooltip = ({
  children,
  tooltipContent,
  placement = TooltipPlacement.TOP,
  arrowPosition = ArrowPosition.MIDDLE,
  disabled = false,
  ariaLabel,
  containerClassname,
  visibility = TooltipVisibility.ON_HOVER,
}: TooltipProps): ReactElement => {
  visibility = visibility ?? TooltipVisibility.ON_HOVER;
  const [isVisible, setIsVisible] = useState(visibility === TooltipVisibility.ALWAYS);
  const { t } = useTranslation();

  let timeoutId: NodeJS.Timeout | null = null;

  const handleMouseEnter = (): void => {
    if (visibility === TooltipVisibility.ALWAYS) {
      return;
    }

    if (!disabled) {
      timeoutId = setTimeout(() => {
        setIsVisible(true);
      }, 250);
    }
  };

  const handleMouseLeave = (): void => {
    if (visibility === TooltipVisibility.ALWAYS) {
      return;
    }

    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setIsVisible(false);
  };

  const tooltipClasses = cx(
    styles.tooltip,
    styles[`tooltip--${placement}`],
    styles[`tooltip--${placement}--${arrowPosition}`],
    {
      [styles["tooltip--visible"]!]: isVisible,
    },
  );

  const tooltipContainerClasses = cx(styles["tooltip-container"], containerClassname);

  const arrowClasses = cx(
    styles.tooltip__arrow,
    styles[`tooltip__arrow--${placement}`],
    styles[`arrow--${arrowPosition}`],
  );

  const showTooltip = !disabled && isVisible && tooltipContent;

  return (
    <div
      className={tooltipContainerClasses}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      role="none"
    >
      {children}
      {showTooltip && (
        <div className={tooltipClasses} role="tooltip" aria-label={t("tooltip", { title: ariaLabel }) ?? "tooltip"}>
          <Text variant={TextVariant.CAPTION} htmlElement="span" aria-hidden={!isVisible}>
            {tooltipContent}
          </Text>
          <div className={arrowClasses} />
        </div>
      )}
    </div>
  );
};

export default Tooltip;
