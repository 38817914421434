import { generateGraphql } from "@rivial-security/generategraphql";
import { GENERIC_FIELD_TYPES } from "../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";
import { listFalsePositives } from "../graphql/__falsePositiveGQL";
import { resources } from "@rivial-security/role-utils";
import FalsePositiveDetails from "@views/Testing/FalsePositives/components/FalsePositiveDetails";

/**
 * Display False Positives for Testing module hook
 * @param {object} props - props to pass to DataGrid
 * @param {string} organizationID - selected organization
 * @param {string} viewingFrom - the resource the grid is being viewed from
 * @param {string} module - module
 * @param {string} resource - resource
 * @param {boolean} disableRoleChecking - disable role checking
 * @param {boolean} isLoading - loading state of the grid
 * @param {function} resetFunction - reset function for the grid
 * @returns {object} {{gridDisplay: *, data: * | undefined, lastSelectedItem: string | undefined, setData: {(value: (((prevState: *) => *) | *)): void, (value: (((prevState: undefined) => undefined) | undefined)): void}, display: JSX.Element, isLoading: boolean | undefined, createResourceComponent: JSX, ref: string | undefined, setSelectedItems: {(value: (((prevState: []) => []) | [])): void, (value: (((prevState: undefined) => undefined) | undefined)): void}, setIsLoading: {(value: (((prevState: boolean) => boolean) | boolean)): void, (value: (((prevState: undefined) => undefined) | undefined)): void}, setLastSelectedItem: {(value: (((prevState: string) => string) | string)): void, (value: (((prevState: undefined) => undefined) | undefined)): void}, resetFunction: function(): void, fields: Object[] | undefined, setItemsToCheck: {(value: unknown): void, (value: (((prevState: undefined) => undefined) | undefined)): void}, selectedItems: [] | undefined}}
 */
export const useTestingFalsePositivesGrid = ({
  organizationID,
  viewingFrom,
  module,
  resource,
  disableRoleChecking = false,
  isLoading,
  resetFunction,
  ...props
}) => {
  const { updateMutation } = generateGraphql("TargetFindingLink", ["falsePositive"]);

  const fields = [
    {
      name: "vulnerabilityName",
      flex: 1,
      friendlyName: "Vulnerability",
      disableEdits: true,
      hidden: viewingFrom === resources.VULNERABILITY,
    },
    {
      name: "targetIp",
      flex: 1,
      friendlyName: "IP Address",
      disableEdits: true,
      hidden: viewingFrom === resources.TARGET,
    },
    {
      name: "targetHostName",
      flex: 1,
      friendlyName: "Host Name",
      disableEdits: true,
      hidden: viewingFrom === resources.TARGET,
    },
    {
      name: "falsePositive",
      flex: 1,
      inputType: GENERIC_FIELD_TYPES.SWITCH,
      sort: {
        direction: "desc",
        priority: 1,
      },
      bulkEdit: true,
    },
  ];

  return useDataGrid({
    query: listFalsePositives,
    limit: 1000,
    variables: {
      ownerGroup: organizationID,
    },
    normalizeData: (links) => {
      const falsePositiveArray = [];
      for (const link of links) {
        const foundLink = falsePositiveArray.find((existingLinks) => existingLinks.id === link?.id);

        if (foundLink) {
          return;
        }

        falsePositiveArray.push({
          targetIp: link?.target?.ip,
          vulnerabilityName: link?.vulnerability?.name,
          ...link,
        });
      }
      return falsePositiveArray;
    },
    disableRoleChecking,
    fields,
    detailsComponent: <FalsePositiveDetails viewingFrom={viewingFrom} />,
    updateMutation,
    enableMenu: true,
    enableSearch: true,
    persistenceUUID: `testing-false-positive-grid-${viewingFrom}`,
    enableBulkEdit: true,
    module,
    options: ["edit"],
    resetFunction,
    isLoading,
    resource,
    organizationID,
    ...props,
  });
};
