import { connectionFilters } from "../constants/connectionFilters";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { parseSchemaTypes } from "./parseSchemaTypes";
import schema from "../../../graphql/schema.json";

/**
 * Gets all fields for a typename in the schema
 * @param {string} typename - the type for which to get all fields
 * @param {string} connectionFilters - based on the filter  will exclude a subset of fields
 * @param {boolean} onlyFieldNames - if TRUE, will only return the field names, otherwise will return the full field object
 * @returns {string[]|object[]} - the list of fields for the given typename
 */
export const getCurrentTypeFields = ({
  typename,
  connectionFilter = connectionFilters.ALL,
  onlyFieldNames = false,
}) => {
  // Check arguments
  if (!typename) {
    return [];
  }

  // Find type info in the schema
  const allTypes = parseSchemaTypes({ schema, showConnections: true });
  if (Array.isArray(allTypes)) {
    for (const type of allTypes) {
      const fields = type?.fields;
      if (typeof fields === "object") {
        for (const fieldName in fields) {
          const field = fields[fieldName];
          const typename = field.typename;
          if (typename.startsWith("Model") && typename.endsWith("Connection")) {
            field.typename = typename.substring(5, typename.length - 10);
          }
        }
      }
    }
  }
  const currentType = allTypes.find((type) => type.typename === typename);
  const allFields = currentType?.fields;

  // Filter the fields
  const filteredFields = [];
  if (allFields) {
    for (const fieldName in allFields) {
      const field = allFields[fieldName];

      // CHECK IF FIELD IS NOT A CONNECTION
      // - check if field is valid and not null
      if (isNullOrUndefined(field)) {
        continue;
      }

      // - do not add any of the default fields
      if (fieldName === "id" || fieldName === "ownerGroup") {
        continue;
      }

      // - add all non nested fields
      if (!field.isNested) {
        filteredFields.push(field);
        continue;
      }

      // FILTER CONNECTION FIELDS BASED ON CONNECTION TYPE
      // - if no connections allowed skip filed if it is nested
      if (connectionFilter === connectionFilters.NO_CONNECTIONS && field.isNested) {
        continue;
      }

      // - if no many-to-many allowed skip field if it is hasMany
      if (connectionFilter === connectionFilters.HAS_ONE && field.hasMany) {
        continue;
      }

      // - if no has-one connections allowed skip field if it is not hasMany
      if (connectionFilter === connectionFilters.HAS_MANY && !field.hasMany) {
        continue;
      }

      // - if passed checks ad connection fields to filtered fields
      filteredFields.push(field);
    }
  }

  // If user only wants field names return them
  if (onlyFieldNames) {
    return filteredFields.filter((field) => !isNullOrUndefined(field?.name)).map((field) => field?.name);
  }

  // Otherwise, return full data about the fields
  return filteredFields;
};
