import React, { useEffect, useState } from "react";
import { isEmptyArray, isNonEmptyArray } from "@rivial-security/func-utils";

import { Alert } from "@mui/material";
import { ErrorLogger } from "@utils/EventLogger";
import { ItemMutation } from "../../../../../utils/Functions/Graphql/ItemMutation";
import LabelList from "../../../../../utils/Labels/components/LabelList";
import Loader from "../../../../../utils/LoadingComponents/Loader";
import NotEnoughData from "../../../../../utils/GenericComponents/NotEnoughData";
import { generateGraphql } from "@rivial-security/generategraphql";
import { retrieveUpToDateLabelLinks } from "../functions/retrieveUpToDateLabelLinks";
import { useUIContext } from "@utils/Context/UIContext";

/**
 * Displays all labels associated with a single artifact
 * @param {object} artifact - the artifact to display labels for
 * @param {string} organizationID - the currently selected organization ID
 * @param {function} onLabelClick - callback function to handle click on the label tag
 * @param {function} onCreateLabel - callback function to handle creation of a new label
 * @param {function} onLabelLinksUpdated - callback when the list of labels is modified from this UI
 * @param {boolean} disableCreateLabel - if TRUE user cannot create new labels and artifact label links from this component
 * @param {boolean} disableDeleteLabel - if TRUE user cannot delete labels and artifact label links from this component
 * @param {boolean} showBookmarks - if TURE will show bookmark info next to each label in the list
 * @returns {JSX.Element}
 */
const ArtifactLabels = ({
  artifact,
  organizationID,
  onLabelClick,
  onCreateLabel,
  onLabelLinksUpdated,
  disableCreateLabel = false,
  disableDeleteLabel = false,
  showBookmarks = false,
}) => {
  /// [CONTEXT]
  const { addToast } = useUIContext();

  /// [UTILITIES]
  // Convert artifact label links to label entries
  const convertArtifactLabelLinks = ({ artifact }) => {
    const artifactLabelLinks = artifact?.labels?.items;
    if (!isNonEmptyArray(artifactLabelLinks)) {
      return [];
    }

    const newLabels = [];
    for (const artifactLabelLink of artifactLabelLinks) {
      newLabels.push({
        ...artifactLabelLink?.label,
        labelLinkID: artifactLabelLink?.id,
        bookmarks: artifactLabelLink?.bookmarks,
        labelLinkTypename: "ArtifactLabelLink",
      });
    }

    return newLabels;
  };

  /// [STATE]
  // Update the local state of labels when the artifact labels change
  const [labels, setLabels] = useState(null);
  useEffect(() => {
    try {
      const newLabels = convertArtifactLabelLinks({ artifact });
      setLabels([...newLabels]);
    } catch (e) {
      setLabels(undefined);
    }
  }, [JSON.stringify(artifact?.labels?.items)]);
  // Determine whether current props allow to create new labels
  const showCreateOptions = !disableCreateLabel && typeof onCreateLabel === "function";

  /// [EVENTS]
  /**
   * Updates local state of labels immediately and attempts to delete the link from the backend
   * @param {object} label - the deleted label
   * @returns {Promise<void>} - promise that finishes when remove operation is finished
   */
  const onLabelRemoved = async ({ label }) => {
    const showFailure = (e) => {
      ErrorLogger("Failed to remove label link after applying change!", e);
      addToast({
        header: `Failed to unlink the label from the artifact`,
        icon: "danger",
      });
    };

    // check arguments
    if (!label?.labelLinkID) {
      showFailure();
      return;
    }

    // update local state immediately with existing labels
    const previousLabels = [...labels];
    const newLabels = labels.filter((localLabel) => {
      return localLabel?.labelLinkID !== label?.labelLinkID;
    });
    setLabels([...newLabels]);

    // retrieve current list of labels
    const databaseLabelLinks = await retrieveUpToDateLabelLinks({
      artifactID: artifact?.id,
    });
    // - if failed to retrieve, rollback local state
    if (databaseLabelLinks === null) {
      showFailure();
      setLabels([...previousLabels]);
      return;
    }
    // - if no labels, update local state to empty array
    if (isEmptyArray(databaseLabelLinks)) {
      setLabels([]);
      onLabelLinksUpdated?.({ newLabelLinks: [] });
      return;
    }
    const databaseLabelLinksBeforeChange = [...databaseLabelLinks];
    const databaseLabelsBeforeChange = convertArtifactLabelLinks({
      artifact: { labels: { items: databaseLabelLinks } },
    });

    // remove the matching label links by link ID (for another local update)
    const newLabelLinks = databaseLabelLinks.filter((newLabelLink) => {
      return newLabelLink?.id !== label?.labelLinkID;
    });

    // update the backend, rollback if failed
    try {
      // - update backend state
      await ItemMutation(deleteArtifactLabelLinkMutation, {
        id: label?.labelLinkID,
      });

      // - update local state to the newest labels if mutation was successful
      const newLabels = convertArtifactLabelLinks({
        artifact: { labels: { items: newLabelLinks } },
      });
      setLabels([...newLabels]);
      onLabelLinksUpdated?.({ newLabelLinks });
    } catch (e) {
      // - rollback local state to the newest version of labels but without the change
      showFailure(e);
      setLabels([...databaseLabelsBeforeChange]);
      onLabelLinksUpdated?.({ newLabelLinks: databaseLabelLinksBeforeChange });
    }
  };

  const onUpdatedLabelLink = async ({ newLabelLink }) => {
    let databaseLabelLinks = await retrieveUpToDateLabelLinks({
      artifactID: artifact?.id,
    });

    databaseLabelLinks = databaseLabelLinks.map((labelLink) => {
      if (labelLink?.id === newLabelLink?.id) {
        return { ...labelLink, ...newLabelLink };
      }
      return labelLink;
    });

    onLabelLinksUpdated?.({ newLabelLinks: databaseLabelLinks });
  };

  /// [DISPLAY]
  // - initial loading labels
  if (labels === null) {
    return <Loader text={"Loading..."} />;
  }
  // - zero labels
  if (isEmptyArray(labels)) {
    return (
      <NotEnoughData
        message={"This artifact doesn't have any associated labels."}
        sx={{
          button: {
            padding: 0,
            margin: 0,
            paddingRight: "0",
            paddingBottom: ".2em",
            marginLeft: "0",
          },
        }}
        {...(showCreateOptions && {
          callToAction: {
            message: " to link a new one!",
            size: "sm",
            placeOnNewLine: false,
            function: onCreateLabel,
          },
        })}
      />
    );
  }
  // - one or more labels
  if (isNonEmptyArray(labels)) {
    return (
      <LabelList
        organizationID={organizationID}
        labels={labels}
        onLabelClick={onLabelClick}
        onLabelRemoved={!disableDeleteLabel && onLabelRemoved}
        showBookmarks={showBookmarks}
        onUpdatedLabelLink={onUpdatedLabelLink}
      />
    );
  }

  // - error loading labels
  return <Alert severity={"warning"}>Could not load labels for this artifact. Please try again later... </Alert>;
};

const { deleteMutation: deleteArtifactLabelLinkMutation } = generateGraphql("ArtifactLabelLink", ["id"]);
export default ArtifactLabels;
