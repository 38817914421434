/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type Artifact = {
  __typename?: 'Artifact';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  ownerGroup: Scalars['ID']['output'];
};

export type Audit = {
  __typename?: 'Audit';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  ownerGroup: Scalars['ID']['output'];
};

export type ComplianceStatistics = {
  __typename?: 'ComplianceStatistics';
  artifactsUploaded: Scalars['Int']['output'];
  auditsPerformed: Scalars['Int']['output'];
  compliancePercentage: Scalars['Float']['output'];
  controlsNotInPlace: Scalars['Int']['output'];
  evidence: EvidenceCount;
  frameworksAdded?: Maybe<Scalars['Int']['output']>;
  numberOfControls: Scalars['Int']['output'];
};

export type Control = {
  __typename?: 'Control';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  ownerGroup: Scalars['ID']['output'];
};

export type ControlFramework = {
  __typename?: 'ControlFramework';
  compliancePercentage: Scalars['Float']['output'];
  customName?: Maybe<Scalars['String']['output']>;
  dashboardLogo?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isTemplateEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  numberOfControls: Scalars['Int']['output'];
  numberOfControlsInPlace: Scalars['Int']['output'];
  ownerGroup: Scalars['ID']['output'];
};

export type ControlFrameworkDashboard = {
  __typename?: 'ControlFrameworkDashboard';
  controlFramework: ControlFramework;
  controlFrameworkID: Scalars['ID']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  ownerGroup: Scalars['ID']['output'];
  type: CustomDashboardType;
};

export type ControlFrameworkDashboardPaginatedResponse = {
  __typename?: 'ControlFrameworkDashboardPaginatedResponse';
  items: Array<ControlFrameworkDashboard>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

/** The type of custom dashboard */
export enum CustomDashboardType {
  ControlFramework = 'CONTROL_FRAMEWORK'
}

export type Evidence = {
  __typename?: 'Evidence';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  ownerGroup: Scalars['ID']['output'];
  status: EvidenceStatus;
};

export type EvidenceCount = {
  __typename?: 'EvidenceCount';
  expired: Scalars['Int']['output'];
  expiringSoon: Scalars['Int']['output'];
  inPlace: Scalars['Int']['output'];
  pendingValidation: Scalars['Int']['output'];
};

/** The status indicating the validity of an evidence */
export enum EvidenceStatus {
  Expired = 'EXPIRED',
  ExpiringSoon = 'EXPIRING_SOON',
  InPlace = 'IN_PLACE',
  PendingValidation = 'PENDING_VALIDATION'
}

export type Mutation = {
  __typename?: 'Mutation';
  createControlFrameworkDashboard?: Maybe<Scalars['ID']['output']>;
};


export type MutationCreateControlFrameworkDashboardArgs = {
  controlFrameworkId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgType: Scalars['String']['output'];
  s3BucketName?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  artifact: Artifact;
  audit: Audit;
  complianceStatistics: ComplianceStatistics;
  control: Control;
  controlFramework: ControlFramework;
  controlFrameworkDashboards?: Maybe<ControlFrameworkDashboardPaginatedResponse>;
  controlsNotInPlace: Scalars['Int']['output'];
  countArtifacts: Scalars['Int']['output'];
  countAudits: Scalars['Int']['output'];
  countControlFrameworks: Scalars['Int']['output'];
  countControls: Scalars['Int']['output'];
  evidence: Evidence;
  getCustomDashboardById: ControlFramework;
  organization: Organization;
  userCreateableFrameworkTemplates: Array<ControlFramework>;
};


export type QueryArtifactArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAuditArgs = {
  id: Scalars['ID']['input'];
};


export type QueryComplianceStatisticsArgs = {
  dashboardID?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryControlArgs = {
  id: Scalars['ID']['input'];
};


export type QueryControlFrameworkArgs = {
  id: Scalars['ID']['input'];
};


export type QueryControlFrameworkDashboardsArgs = {
  limit?: InputMaybe<Scalars['Float']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryEvidenceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetCustomDashboardByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrganizationArgs = {
  id: Scalars['ID']['input'];
};

export type ControlFrameworkDashboardsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Float']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type ControlFrameworkDashboardsQuery = { __typename?: 'Query', controlFrameworkDashboards?: { __typename?: 'ControlFrameworkDashboardPaginatedResponse', nextToken?: string | null, items: Array<{ __typename?: 'ControlFrameworkDashboard', id: string, name: string, description?: string | null, controlFrameworkID: string, type: CustomDashboardType, controlFramework: { __typename?: 'ControlFramework', id: string, name: string, customName?: string | null, dashboardLogo?: string | null } }> } | null };

export type CreateControlFrameworkDashboardMutationVariables = Exact<{
  name: Scalars['String']['input'];
  controlFrameworkId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateControlFrameworkDashboardMutation = { __typename?: 'Mutation', createControlFrameworkDashboard?: string | null };

export type GetCustomDashboardByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetCustomDashboardByIdQuery = { __typename?: 'Query', getCustomDashboardById: { __typename?: 'ControlFramework', name: string } };

export type ComplianceStatisticsQueryVariables = Exact<{
  dashboardID?: InputMaybe<Scalars['ID']['input']>;
}>;


export type ComplianceStatisticsQuery = { __typename?: 'Query', complianceStatistics: { __typename?: 'ComplianceStatistics', artifactsUploaded: number, auditsPerformed: number, compliancePercentage: number, controlsNotInPlace: number, frameworksAdded?: number | null, numberOfControls: number, evidence: { __typename?: 'EvidenceCount', expired: number, expiringSoon: number, inPlace: number, pendingValidation: number } } };

export type UserCreateableFrameworkTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type UserCreateableFrameworkTemplatesQuery = { __typename?: 'Query', userCreateableFrameworkTemplates: Array<{ __typename?: 'ControlFramework', id: string, name: string }> };


export const ControlFrameworkDashboardsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"controlFrameworkDashboards"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"limit"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Float"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"nextToken"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"controlFrameworkDashboards"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"limit"},"value":{"kind":"Variable","name":{"kind":"Name","value":"limit"}}},{"kind":"Argument","name":{"kind":"Name","value":"nextToken"},"value":{"kind":"Variable","name":{"kind":"Name","value":"nextToken"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"items"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"controlFrameworkID"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"controlFramework"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"customName"}},{"kind":"Field","name":{"kind":"Name","value":"dashboardLogo"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"nextToken"}}]}}]}}]} as unknown as DocumentNode<ControlFrameworkDashboardsQuery, ControlFrameworkDashboardsQueryVariables>;
export const CreateControlFrameworkDashboardDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"createControlFrameworkDashboard"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"name"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"controlFrameworkId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"description"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createControlFrameworkDashboard"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"name"}}},{"kind":"Argument","name":{"kind":"Name","value":"controlFrameworkId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"controlFrameworkId"}}},{"kind":"Argument","name":{"kind":"Name","value":"description"},"value":{"kind":"Variable","name":{"kind":"Name","value":"description"}}}]}]}}]} as unknown as DocumentNode<CreateControlFrameworkDashboardMutation, CreateControlFrameworkDashboardMutationVariables>;
export const GetCustomDashboardByIdDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCustomDashboardById"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getCustomDashboardById"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<GetCustomDashboardByIdQuery, GetCustomDashboardByIdQueryVariables>;
export const ComplianceStatisticsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ComplianceStatistics"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"dashboardID"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"complianceStatistics"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"dashboardID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"dashboardID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"artifactsUploaded"}},{"kind":"Field","name":{"kind":"Name","value":"auditsPerformed"}},{"kind":"Field","name":{"kind":"Name","value":"compliancePercentage"}},{"kind":"Field","name":{"kind":"Name","value":"controlsNotInPlace"}},{"kind":"Field","name":{"kind":"Name","value":"frameworksAdded"}},{"kind":"Field","name":{"kind":"Name","value":"numberOfControls"}},{"kind":"Field","name":{"kind":"Name","value":"evidence"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"expired"}},{"kind":"Field","name":{"kind":"Name","value":"expiringSoon"}},{"kind":"Field","name":{"kind":"Name","value":"inPlace"}},{"kind":"Field","name":{"kind":"Name","value":"pendingValidation"}}]}}]}}]}}]} as unknown as DocumentNode<ComplianceStatisticsQuery, ComplianceStatisticsQueryVariables>;
export const UserCreateableFrameworkTemplatesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"userCreateableFrameworkTemplates"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userCreateableFrameworkTemplates"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<UserCreateableFrameworkTemplatesQuery, UserCreateableFrameworkTemplatesQueryVariables>;