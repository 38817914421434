import React from "react";
import { useSmartValueContext } from "../../../../utils/SmartValues/hooks/useSmartValueContext";
import { useAutomationStepSmartValues } from "../hooks/useAutomationStepSmartValues";

/**
 * Component that displays Smart Values related to a single Automation Step
 *
 * @param {object} automationStep - the automation step to find smart values for
 * @param {object[]} automations - step configurations from parent nodes (for instance, the siblings of loop steps)
 * @param {object} automation - the parent automation itself, with trigger and condition info
 * @param {boolean} showLoopSmartValues - if TRUE will show the smart values related to loop
 * @returns {JSX.Element}
 * @constructor
 */
const SmartValueHelper = ({ automationStep, automation, automations, showLoopSmartValues }) => {
  // Smart value context, for setting the smart value context for the step
  const smartValueContext = useSmartValueContext();

  // Set smart value suggestions for this automation step
  useAutomationStepSmartValues({
    automationStep,
    automation,
    automations,
    setSmartValues: smartValueContext.setSmartValues,
    showLoopSmartValues,
  });

  return (
    <span
      key={JSON.stringify(smartValueContext?.smartValues)}
      style={{
        display: smartValueContext?.smartValues?.length > 0 ? undefined : "none",
      }}
    >
      <h4>Smart Value Suggestions</h4>
      {smartValueContext?.chips}
    </span>
  );
};

export default SmartValueHelper;
