import DataLoader from "../../../utils/LoadingComponents/DataLoader";
import Grid from "@mui/material/Grid";
import MultipleLayoutDisplay from "./MultipleLayoutDisplay";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useCustomDashboardButtons } from "../hooks/useCustomDashboardButtons";
import { useCustomDashboardData } from "../hooks/useCustomDashboardData";
import { useState } from "react";
import { withOrganizationCheck } from "../../../utils/Context/withOrganizationCheck";

/**
 * The viewport for the custom dashboard. Fetches the data for a single Dashboard and handles the state of the layout.
 *
 * If there are no layouts, displays a Getting Started UI
 *
 * If there is only one layout, displays it directly
 *
 * If there are multiple layouts, displays them as a Tile Dashboard
 *
 * @param {object} item - the CustomDashboard object from the database
 * @param {string} organizationID - the ID of the organization
 * @param {function} onCreateNewDashboard - callback to be called when a new dashboard is created
 * @param {function} onDeleteDashboard - callback to be called when a dashboard is deleted
 * @param {function} onEditDashboard - callback to be called when a dashboard is edited
 * @param {boolean} isTemplate - whether or not the dashboard is a template
 * @returns {JSX.Element}
 * @constructor
 */
const CustomDashboardDisplay = ({
  item,
  organizationID,
  onCreateNewDashboard,
  onDeleteDashboard,
  onEditDashboard,
  isTemplate,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [layoutIndex, setLayoutIndex] = useState(0);

  // custom hook that handles data fetching, state handling, and mutations
  const customDashboardData = useCustomDashboardData({
    itemId: item?.id,
    organizationID,
    layoutIndex,
    onCreateNewDashboard,
    onDeleteDashboard,
    onEditDashboard,
  });

  // custom hook that handles all of the logic and UI for the toolbar buttons
  const customDashboardButtons = useCustomDashboardButtons({
    deleteDashboard: customDashboardData.deleteDashboard,
    saveAs: customDashboardData.saveAs,
    addPage: customDashboardData.addPage,
    removePage: customDashboardData.removePage,
    editDashboardDetails: customDashboardData.editDashboardDetails,
    addWidget: customDashboardData.addWidget,
    removeWidget: customDashboardData.removeWidget,
    organizationID,
    isEditMode,
    setIsEditMode,
    layouts: customDashboardData?.item?.layouts,
    layoutIndex,
    item: customDashboardData?.item,
    isTemplate,
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={12} style={{ height: "50px" }}>
        <span className={"float-right"} style={{ height: "50px" }}>
          {customDashboardButtons.display}
        </span>
      </Grid>
      <Grid item xs={12} lg={12}>
        <DataLoader
          isLoading={customDashboardData.isLoading}
          isEnoughData={!isNullOrUndefined(customDashboardData?.item)}
          dataMessage={"This Dashboard has nothing to display. Turn edit switch on to add a page"}
        >
          {(() => {
            // Handle multiple pages with the MultipleLayoutDisplay
            if (Array.isArray(customDashboardData?.item?.layouts) && customDashboardData?.item?.layouts?.length > 0) {
              return (
                <MultipleLayoutDisplay
                  layouts={customDashboardData?.item?.layouts}
                  onLayoutChange={(input) => setLayoutIndex(input?.index)}
                  isEditMode={isEditMode}
                  organizationID={organizationID}
                  item={customDashboardData?.item}
                  addWidgetModal={customDashboardButtons.addWidgetModal}
                  setShowSaveButton={customDashboardButtons.setShowSaveButton}
                  save={customDashboardData.save}
                  customDashboardButtons={customDashboardButtons}
                  removePage={customDashboardData.removePage}
                  removeWidget={customDashboardData.removeWidget}
                  editPageDetails={customDashboardData.editPageDetails}
                  isTemplate={isTemplate}
                />
              );
            }
          })()}
        </DataLoader>
      </Grid>
    </Grid>
  );
};

export default withOrganizationCheck(CustomDashboardDisplay);
