import { emptyPlaceholder } from "../../../../../../../utils/GenericComponents/Placeholders";
import React from "react";

/**
 * Component used to display the output of a top-level function in a custom query inside the query runner.
 * @param fieldName - the name of the function currently being displayed
 * @param item - the object containing the function results of all top level functions
 */
const TopLevelFunctionResult = ({ fieldName, item }: { fieldName: string; item: object }): React.ReactElement => {
  const value = item[fieldName];
  let child: React.ReactElement;
  if (typeof value === "string" || typeof value === "number" || typeof value === "boolean") {
    child = <span>{value}</span>;
  } else if (typeof value === "object" && value !== null) {
    child = <pre>{JSON.stringify(value, null, 2)}</pre>;
  } else {
    child = emptyPlaceholder;
  }

  return (
    <div
      style={{
        width: "100%",
        overflow: "auto",
      }}
    >
      {child}
    </div>
  );
};

export default TopLevelFunctionResult;
