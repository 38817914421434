import SelectionTiles from "../../../../../../../utils/GenericComponents/SelectionTiles";
import React from "react";

/**
 * Selection Tiles for selecting the type of Control Mapping to export
 * @param {string} selectedMappingType
 * @param {function} setSelectedMappingType
 * @returns {JSX.Element}
 * @constructor
 */
const ControlMappingExportSelectionTiles = ({ selectedMappingType, setSelectedMappingType }) => {
  return (
    <SelectionTiles
      items={[
        {
          id: "controlToEvidenceMapping",
          icon: "icon-list",
          text: "Control to Evidence Mapping",
        },
        {
          id: "controlToControlMapping",
          icon: "icon-list",
          text: "Control to Control Mapping",
        },
        {
          id: "controlToRiskControlMapping",
          icon: "icon-list",
          text: "Control to Risk Control Mapping",
        },
      ]}
      onSelect={(item) => setSelectedMappingType(item.id)}
      checkIsSelected={(item) => item.id === selectedMappingType}
    />
  );
};

export default ControlMappingExportSelectionTiles;
