import { Button, Input } from "reactstrap";
import React, { useContext, useState } from "react";

import { ErrorLogger } from "@utils/EventLogger";
import { GenericDeleteGQL } from "../../utils/Functions/Graphql/GenericDeleteGQL";
import { QueryGetItem } from "./useQueryGetItem";
import { UIContext } from "../../utils/Context/UIContext";
import { handleDeleteClick } from "../../utils/GenericComponents/GenericDeleteButton";
import { useModal } from "../views/useModal";
import { useMutation } from "./useMutation/useMutation";

/**
 * @description Hook to use the delete mutation
 * @param {string} module - The module name
 * @param {string} resource - The resource name
 * @param {function} mutation - The mutation function
 * @param {object} modalButton - The modal button
 * @param {function} resetFunction - Function to reset the form
 * @param {function} toggleModal - Function to toggle the modal
 * @param {function} deleteFunction - Function to delete the item
 * @param {string} deleteGqlQuery - The GraphQL query to delete the item
 * @param {string} keyword - The keyword to search for
 * @param {string} typename - The type of the item
 * @returns {{setModalIsOpen: function(*): void, modalButton: JSX.Element, setSelectedItems: (value: (((prevState: *[]) => *[]) | *[])) => void, modalIsOpen: boolean, modal: JSX.Element, selectedItems: *[], toggleModal: function(): void}}
 */
export const useDeleteMutationBulk = ({
  module,
  resource,
  mutation,
  resetFunction,
  deleteFunction,
  deleteGqlQuery,
  keyword = "delete all",
  typename = "item",
}) => {
  const [verifyText, setVerifyText] = useState("");

  const mutationHook = useMutation({
    module,
    resource,
    mutation,
  });

  const { addToast, updateToast, safeDelete } = useContext(UIContext);

  const [selectedItems, setSelectedItems] = useState([]);

  const handleBulk = async () => {
    const handleUpdate = () => {
      progressCurrent++;
      if (progressCurrent === progressTotal) {
        setTimeout(() => {
          updateToast({
            id: toastId,
            header: `Successfully deleted ${progressTotal} ${typename}s!`,
            icon: "danger",
          });
          resetFunction?.();
          modalHook.setModalIsOpen(false);
        }, 1000);
      } else {
        updateToast({
          id: toastId,
          header: `Deleting ${typename}s.. ${progressCurrent}/${progressTotal}`,
          icon: "spinner",
          color: "danger",
          transition: { appear: false },
        });
      }
    };

    const progressTotal = selectedItems && selectedItems.length;
    let progressCurrent = 0;

    const toastId = addToast({
      header: `Deleting ${typename}s.. ${progressCurrent}/${progressTotal}`,
      icon: "spinner",
      color: "danger",
      transition: { exit: false },
    });

    const arrayToDelete = [];

    for (const item of selectedItems) {
      if (mutation) {
        mutationHook.deleteItem({ id: item.id }).then(() => {
          handleUpdate();
        });
      } else if (deleteFunction) {
        handleDeleteClick(item, deleteFunction, true).then(() => {
          handleUpdate();
        });
      } else if (deleteGqlQuery) {
        arrayToDelete.push(await QueryGetItem({ query: deleteGqlQuery, itemId: item.id }));
      } else {
        ErrorLogger("Error: No Delete Function or Mutation available.s");
      }
    }

    if (deleteGqlQuery && arrayToDelete.length > 0) {
      updateToast({
        id: toastId,
        header: `Deleting selected items, please wait!`,
        icon: "spinner",
        color: "danger",
      });

      GenericDeleteGQL({ objectToDelete: { arrayToDelete } }).then(() => {
        resetFunction?.();
        updateToast({
          id: toastId,
          header: `${typename || "Items"} Deleted!`,
          icon: "danger",
        });
      });

      modalHook.setModalIsOpen(false);
    }
  };

  const handleDelete = async () => {
    await handleBulk();
  };

  const isDisabled = () => {
    if (safeDelete) {
      return verifyText !== keyword;
    } else {
      return false;
    }
  };

  const modalHook = useModal(
    `Are you sure you want to delete ${selectedItems.length} ${typename}s?`,
    <div>
      <h5>{safeDelete ? `Please type "${keyword}" to permanently delete these items.` : "Safe Delete is Disabled"}</h5>
      {safeDelete && <Input value={verifyText} onChange={(e) => setVerifyText(e.target.value)} />}
      <Button color="danger" disabled={isDisabled()} onClick={() => handleDelete()} style={{ marginTop: "1em" }}>
        <i className="icon-trash" /> Delete All
      </Button>
    </div>,
    <Button color="danger" size="sm">
      Delete Selected
    </Button>,
  );

  return {
    ...modalHook,
    selectedItems,
    setSelectedItems,
  };
};
