import React, { useEffect, useRef, useState } from "react";

import { Alert } from "@mui/material";
import { ErrorLogger } from "@utils/EventLogger";
import Loader from "../../../../utils/LoadingComponents/Loader";
import PDFAnnotation from "./PDFAnnotation";
import PDFEmphasizeMask from "./PDFEmphasizeMask";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import styles from "../css/PDFPage.module.css";

// REFERENCES:
// 1) good discussion on getting manual page bounds
// https://github.com/mozilla/pdf.js/issues/5643

// 2) pdf.js viewer text layer implementation (was useful for calculating bounds from transform matrix)
// https://github.com/mozilla/pdf.js/blob/b1e0253f29176751c9762f88b5b9765fcf9fc07c/src/display/text_layer.js#L157

/**
 * A single page of the PDF document within a PDFViewer
 * @param {object} pdfJS - pdf.js library instance with worker already loaded
 * @param {object} pdfDocument - pdf.js opened document object
 * @param {object} settings - pdf viewer display parameters
 * @param {object[]} annotations - all annotations part of this page
 * @param {number} pageNumber - the page number of the current page
 * @param {function} onTextSelected - callback when user selects text in the text layer
 * @param {JSX.Element} annotationComponent - the component to use when rendering annotations
 * @param {object} emphasizedAnnotation - single annotation out of the annotations array to emphasize
 * @returns {JSX.Element}
 */
const PDFPage = ({
  pdfJS,
  pdfDocument,
  settings,
  annotations,
  pageNumber,
  onTextSelected,
  annotationComponent,
  emphasizedAnnotation,
}) => {
  const scaleFactor = 2; // larger number improves quality but increases memory usage

  const canvasRef = useRef(null);
  const textLayerRef = useRef(null);
  const annotationsLayerRef = useRef(null);
  const [renderError, setRenderError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pageDimensions, setPageDimensions] = useState(null);

  //When data becomes available render the page
  useEffect(() => {
    const canvas = canvasRef?.current;

    if (!canvas || !pdfDocument) {
      return;
    }

    let renderedPage = null;
    let renderedViewport = null;
    (async function () {
      try {
        setIsLoading(true);
        const page = await pdfDocument.getPage(pageNumber + 1);
        const viewport = page.getViewport({
          scale: settings.scale * scaleFactor,
        });
        const { pageWidth, pageHeight, pageX, pageY } = viewport.rawDims;

        // Prepare canvas using PDF page dimensions.
        const canvasContext = canvas.getContext("2d");
        canvasContext.clearRect(0, 0, canvas.width, canvas.height);
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        setPageDimensions(viewport);

        // Render PDF page into canvas context.
        const renderContext = { canvasContext, viewport };
        await page.render(renderContext);

        renderedPage = page;
        renderedViewport = viewport;
      } catch (e) {
        ErrorLogger("Error rendering a pdf page", e);
        setRenderError(e);
      } finally {
        setIsLoading(false);
      }

      //After rendering the page, attempt to render the text layer
      try {
        const textLayer = textLayerRef?.current;
        if (pdfJS && textLayer && renderedPage && renderedViewport) {
          const textContent = await renderedPage.getTextContent();

          // Clear all children from the container before rendering the text layer
          textLayer.innerHTML = "";

          // Determine bounds of the text chunks
          const textBounds = [];
          const textChunks = textContent.items;
          const styles = textContent.styles;
          if (Array.isArray(textChunks)) {
            for (const textChunk of textChunks) {
              const { transform: textChunkTransform, width, height, fontName, str, hasEOL } = textChunk;
              const style = styles[fontName];

              const { pageWidth, pageHeight, pageX, pageY } = renderedViewport.rawDims;
              const _transform = [1, 0, 0, -1, -pageX, pageY + pageHeight];

              const tx = pdfJS.Util.transform(_transform, textChunkTransform);
              const fontHeight = Math.hypot(tx[2], tx[3]) * settings.scale;
              const fontAscent = (style?.ascent ?? 0) * settings.scale;
              const fontVerticalOffset = fontAscent * fontHeight;

              const left = tx[4] * settings.scale;
              const top = tx[5] * settings.scale - fontHeight;
              const right = left + width * settings.scale;
              const bottom = top + height * settings.scale;
              textBounds.push({ left, top, right, bottom });

              const textSpan = document.createElement("span");
              textSpan.style.position = "absolute";
              textSpan.style.left = `${left}px`;
              textSpan.style.top = `${top}px`;
              textSpan.style.width = `${right - left}px`;
              textSpan.style.height = `${bottom - top}px`;
              textSpan.style.fontSize = `${fontHeight * 0.98}px`;
              textSpan.style.lineHeight = `${fontHeight * 0.98}px`;
              textSpan.style.fontFamily = style.fontFamily;
              textSpan.style.verticalAlign = "center";
              textSpan.style.padding = "0px";
              textSpan.style.margin = "0px";
              textSpan.style.color = "transparent";

              const finalString = str;
              textSpan.innerText = finalString;

              //DEBUG: text bounds (NOTE: rendered text currently can go outside bounds)
              // textSpan.style.border = "1px solid red";

              textLayer.appendChild(textSpan);
            }
          }
        }
      } catch (e) {
        ErrorLogger("Error rendering text layer on a pdf layer", e);
      }
    })();
  }, [pdfDocument, settings?.scale]);

  const drawDimensions = {
    width: pageDimensions?.width / scaleFactor,
    height: pageDimensions?.height / scaleFactor,
  };

  return (
    <div
      className={styles["page-container"]}
      style={{
        width: drawDimensions?.width,
        height: drawDimensions?.height,
        margin: settings?.pageMargin,
        marginRight: (settings?.pageMargin ?? 0) + (settings?.documentMarginRight ?? 0),
        marginBottom: 0,
      }}
    >
      {renderError && <Alert severity={"error"}>Can not load this page!</Alert>}
      {!renderError && isLoading && (
        <span>
          <Loader style={{ marginRight: ".5em" }} /> Loading...
        </span>
      )}
      <canvas ref={canvasRef} style={{ height: "100%", width: "100%" }} />
      {Array.isArray(annotations) && annotations.length > 0 && (
        <div ref={annotationsLayerRef} className={styles["annotations-layer"]}>
          {annotations.map((annotation) => {
            let isEmphasized = annotation?.id === emphasizedAnnotation?.id;
            if (isNullOrUndefined(emphasizedAnnotation?.id)) {
              isEmphasized = false;
            }

            return annotationComponent ? (
              React.cloneElement(annotationComponent, {
                annotation,
                drawDimensions,
                pageNumber,
                isEmphasized,
              })
            ) : (
              <PDFAnnotation
                annotation={annotation}
                drawDimensions={drawDimensions}
                pageNumber={pageNumber}
                isEmphasized={isEmphasized}
              />
            );
          })}
          <PDFEmphasizeMask emphasizedAnnotation={emphasizedAnnotation} settings={settings} pageNumber={pageNumber} />
        </div>
      )}
      <div
        ref={textLayerRef}
        className={styles["text-layer"]}
        onMouseUp={(e) => {
          //Get the currently selected text info pass it up to viewer
          const selection = window.getSelection();
          if (selection?.rangeCount > 0) {
            const range = selection.getRangeAt(0);

            //Get all text nodes in the selection they are all inside spans
            const nodeIterator = document.createNodeIterator(
              range.commonAncestorContainer,
              NodeFilter.SHOW_ALL, // pre-filter
              {
                // custom filter
                acceptNode: function (node) {
                  return NodeFilter.FILTER_ACCEPT;
                },
              },
            );

            let text = "";
            let rects = [];
            const textNodeRange = document.createRange();
            while (nodeIterator.nextNode()) {
              //skip all nodes that are not just text
              if (nodeIterator.referenceNode.nodeType !== Node.TEXT_NODE) {
                continue;
              }

              if (rects.length === 0 && nodeIterator.referenceNode !== range.startContainer) {
                continue;
              }

              const currentNode = nodeIterator.referenceNode;

              //if start or end node select only part of the text
              textNodeRange.selectNode(currentNode);
              //if start node copy the startOffset
              if (currentNode === range.startContainer) {
                textNodeRange.setStart(currentNode, range.startOffset);
              }
              //if end node copy the end offset
              if (currentNode === range.endContainer) {
                textNodeRange.setEnd(currentNode, range.endOffset);
              }

              rects.push(textNodeRange.getBoundingClientRect());
              text += `${textNodeRange.toString()} `;

              if (nodeIterator.referenceNode === range.endContainer) {
                break;
              }
            }
            text = text.trim();

            //NOTE: Above is experimental text only selection, switch to all rects selection if results in invalid highlight annotations
            // let rects = range.getClientRects();
            // rects = Array.from(rects);
            // const text = range.toString();

            //Filter out any rects with 0 height or 0 width
            if (!Array.isArray(rects) || rects.length === 0) {
              return;
            }

            rects = rects.filter((rect) => {
              if (rect.height > 0 && rect.width > 0) {
                return true;
              }
              return false;
            });

            //Get the current top and left positions of the canvas
            const canvas = canvasRef?.current;
            const canvasRect = canvas?.getBoundingClientRect();

            //Filter out any rects that are outside the canvas
            rects = rects.filter((rect) => {
              if (
                rect.top >= canvasRect.top &&
                rect.left >= canvasRect.left &&
                rect.bottom <= canvasRect.bottom &&
                rect.right <= canvasRect.right
              ) {
                return true;
              }
              return false;
            });

            //Subtract the canvas top and left positions from the rects
            rects = rects.map((rect) => {
              return {
                top: rect.top - canvasRect.top,
                left: rect.left - canvasRect.left,
                bottom: rect.bottom - canvasRect.top,
                right: rect.right - canvasRect.left,
              };
            });

            //Convert the bounds to decimal values based on the canvas size
            rects = rects.map((rect) => {
              return {
                top: rect.top / canvasRect.height,
                left: rect.left / canvasRect.width,
                bottom: rect.bottom / canvasRect.height,
                right: rect.right / canvasRect.width,
              };
            });

            if (rects.length > 0) {
              onTextSelected({ canvasRect, text, rects, pageNumber });
            }
          }
        }}
      />
    </div>
  );
};

export default PDFPage;
