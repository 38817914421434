import { modules, resources } from "@rivial-security/role-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import React from "react";
import VendorSubControlDetails from "../components/VendorSubControlDetails";
import { DeleteVendorSubControls } from "../functions/DeleteVendorSubControls";
import CreateVendorSubControl from "../components/CreateVendorSubControl";
import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";

/**
 * Custom hook for displaying a list of VendorSubControls
 *
 * @param {string} organizationID         - ownerGroup filter
 * @param {function} resetFunction        - calls external resetFunction
 * @param {object} cardConfig             - @deprecated overrides passed to useDataGrid hook
 * @param {object} queryConfig            - @deprecated overrides passed to useDataGrid hook
 * @param {object} config                 - @deprecated overrides passed to useDataGrid hook
 * @param {object[]} fields               - overrides to pass to the 'fields' config param of useDataGrid hook
 * @param {object} vendorControlCategory  - vendorControlCategory filter to only show controls for a single category
 * @param {string} vendorControlCategory.id  - vendorControlCategory.id filter to only show controls for a single category
 * @param {boolean} disableRoleChecking   - disables role checking
 * @param {string} typename               - typename to use in the graphql query
 * @param {object} ...props               - all other props passed to useDataGrid hook
 */
export const useVendorSubControlDataGrid = ({
  organizationID,
  resetFunction,
  cardConfig = {},
  queryConfig = {},
  config = {},
  fields = [],
  vendorControlCategory,
  disableRoleChecking,
  typename = "VendorSubControl",
  ...props
}) => {
  const module = modules.VENDORS;
  const resource = resources.VENDOR_CONTROL;

  const { listQuery, updateMutation } = generateGraphql(typename, ["statementNumber", "name"]);

  queryConfig = {
    query: listQuery,
    organizationID: organizationID,
    module,
    resource,
    disableRoleChecking,
    ...queryConfig,
  };

  const roleConfig = {
    module,
    resource,
    disableRoleChecking,
  };

  cardConfig = {
    title: "Vendor Controls",
    headerIcon: "icon-list",
    ...cardConfig,
  };

  fields = [
    {
      name: "statementNumber",
      width: 150,
      sortComparator: (a, b, paramA, paramB) => {
        return a.localeCompare(b, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      },
      sortable: true,
      sort: {
        direction: "asc",
        priority: 1,
      },
      bulkEdit: true,
    },
    {
      name: "name",
      flex: 1,
      bulkEdit: true,
    },
    ...fields,
  ];

  const gridConfig = {
    fields,
    options: ["details", "delete", "edit"],
    createResourceComponent: (
      <CreateVendorSubControl
        vendorControlCategory={vendorControlCategory}
        disableRoleChecking={disableRoleChecking}
        organizationID={organizationID}
      />
    ),
    createItemModalHeader: "Create a new Vendor Control",
    deleteFunction: DeleteVendorSubControls,
    updateMutation,
    route: "#/vendors/subControls/",
    detailsComponent: <VendorSubControlDetails tableDisplay={true} />,
    detailsTitle: "Vendor Control Details",
    typename,
    persistenceUUID: "e9e1c7d7-9698-435b-bd76-395edbe1r30e2",
    disableRoleChecking,
    resetFunction,
    ...props,
  };

  const grid = useDataGrid({
    ...queryConfig,
    ...gridConfig,
    ...cardConfig,
    ...roleConfig,
  });

  return { ...grid };
};
