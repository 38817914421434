import React, { useEffect, useState } from "react";

import Switch from "@mui/material/Switch";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { usePreferences } from "../../../../hooks/views/usePreferences/usePreferences";

/**
 * Controls wether to group or not the evidence activities
 * @param {boolean} [initState] - if provided the preferences wil not be used
 * @return {{display: JSX.Element, isGrouped: (*|boolean)}}
 */
export const useGroupEvidenceActivitySwitch = ({ initState } = {}) => {
  const preferences = usePreferences();
  const [isGrouped, setIsGrouped] = useState(
    initState ?? preferences?.getPreference("interfaceOptions", "groupEvidenceActivity") ?? false,
  );

  useEffect(() => {
    if (isNullOrUndefined(initState)) {
      preferences.setPreference("interfaceOptions", {
        groupEvidenceActivity: isGrouped,
      });
    }
  }, [isGrouped]);

  const display = (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "end",
        alignItems: "center",
      }}
    >
      <Switch
        size="small"
        onClick={() => setIsGrouped(!isGrouped)}
        value={isGrouped}
        checked={isGrouped}
        label="Group Similar Evidence Activities"
      />
      <span style={{ marginLeft: ".5em", marginRight: ".5em" }}>Group Similar</span>
    </div>
  );

  return {
    isGrouped,
    display,
  };
};
