import { EVIDENCE_ACTIVITY_TYPES, EVIDENCE_STATUS } from "../../../../typedefs/Compliance/Evidence/Evidence";

import { Badge } from "reactstrap";
import React from "react";
import StatusBadge from "../../../../utils/GenericComponents/Badges/StatusBadge";
import { getDotIcon } from "./EvidenceActivityItem/functions/getDotIcon";

/**
 * Component that displays the evidence status
 * @param {Evidence} props.item
 * @returns {*}
 * @constructor
 */
const EvidenceStatusBadge = (props) => {
  return getEvidenceStatusBadge(props.item);
};

/**
 * Returns the correct badge based on the item
 * @param {Evidence} item
 * @returns {JSX.Element}
 */
export const getEvidenceStatusBadge = (item = {}) => {
  switch (item.status) {
    case EVIDENCE_STATUS.AUDITED:
      return <AuditedBadge />;
    case EVIDENCE_STATUS.IN_PLACE:
      return <InPlaceBadge />;
    case EVIDENCE_STATUS.EXPIRING_SOON:
      return <ExpiringSoonBadge />;
    case EVIDENCE_STATUS.EXPIRED:
      return <ExpiredBadge />;
    case EVIDENCE_STATUS.PENDING_VALIDATION:
      return <PendingValidationBadge />;
    default:
      return <Badge>N/A</Badge>;
  }
};

export const AuditedBadge = () => (
  <StatusBadge
    icon={getDotIcon(EVIDENCE_ACTIVITY_TYPES.VALIDATED)}
    text={"Audited"}
    tooltip={"This evidence has been audited and verified to be satisfactory for Control validation"}
  />
);

export const InPlaceBadge = () => (
  <StatusBadge
    icon={getDotIcon(EVIDENCE_ACTIVITY_TYPES.VALIDATED)}
    text={"In Place"}
    tooltip={"This evidence has been reviewed by a Security person and has been validated as In Place"}
  />
);

export const PendingValidationBadge = () => (
  <StatusBadge
    icon={getDotIcon(EVIDENCE_ACTIVITY_TYPES.PENDING_VALIDATION)}
    text={"Pending Validation"}
    tooltip={"This evidence is currently waiting to be reviewed by a Security person"}
  />
);

export const ExpiringSoonBadge = () => (
  <StatusBadge
    icon={getDotIcon(EVIDENCE_ACTIVITY_TYPES.EXPIRING_SOON)}
    text={"Expiring Soon"}
    tooltip={"This evidence is going to expire soon and needs to be updated"}
  />
);

export const ExpiredBadge = () => (
  <StatusBadge
    icon={getDotIcon(EVIDENCE_ACTIVITY_TYPES.EXPIRED)}
    text={"Not in Place"}
    tooltip={"This evidence is not in place and needs to be updated"}
  />
);

export default EvidenceStatusBadge;
