import { modules, resources } from "@rivial-security/role-utils";

import { Badge } from "reactstrap";
import { GENERIC_FIELD_TYPES } from "../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import GenericEditFieldV3 from "../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";
import { INCIDENT_STATUS } from "../../../../typedefs/Response/Incident/Incident";
import React from "react";
import { enumToDropdownData } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useAccessControl } from "../../../../utils/AccessControl/hooks/useAccessControl";

/**
 * @description Display and edit Incident status
 * @param {object} item - incident object
 * @param {string} module - incident response module
 * @param {string} resource - incident resource
 * @param {string} field - status field
 * @returns {JSX.Element}
 * @constructor
 */
const IncidentStatus = ({
  item,
  module = modules.INCIDENT_RESPONSE,
  resource = resources.INCIDENT,
  field = "status",
  resetFunction,
}) => {
  const { accessDenied } = useAccessControl({ item });
  return (
    <div key={`incident-status-${item.id}`}>
      <GenericEditFieldV3
        item={item}
        module={module}
        resource={resource}
        field={field}
        inputType={GENERIC_FIELD_TYPES.DROPDOWN}
        inputConfig={{ data: enumToDropdownData({ ENUM: INCIDENT_STATUS }) }}
        mutation={generateGraphql("Incident").updateMutation}
        customDisplayComponent={<Status />}
        resetFunction={resetFunction}
        disableEdits={accessDenied}
      />
    </div>
  );
};

const Status = ({ item }) => {
  if (item.status === INCIDENT_STATUS.OPEN) return <OpenStatus />;
  else if (item.status === INCIDENT_STATUS.CLOSED) return <ClosedStatus />;
  else return <span>n/a</span>;
};

const OpenStatus = () => {
  return <Badge color={"success"}>Open</Badge>;
};

const ClosedStatus = () => {
  return <Badge color={"secondary"}>Closed</Badge>;
};

export default IncidentStatus;
