import { modules, resources } from "@rivial-security/role-utils";

import ActionItems from "../customFields/ActionItems";
import AutomationsButton from "../../../OrganizationManager/Automations/components/AutomationsButton";
import CustomDateField from "../../Email/customFields/CustomDateField";
import Dashboard from "../../../../utils/GenericComponents/Dashboard";
import DashboardCard from "../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import Data from "../customFields/Data";
import DataLoader from "../../../../utils/LoadingComponents/DataLoader";
import Difficulty from "../customFields/Difficulty";
import Grid from "@mui/material/Grid";
import Module from "../customFields/Module";
import NestedRiskChangesCard from "../components/NestedRiskChangesCard";
import Rating from "../customFields/Rating";
import React from "react";
import RecommendationActionItems from "../components/RecommendationActionItems";
import RecommendationNotes from "../customFields/RecommendationNotes";
import RecommendationObservations from "../components/RecommendationObservations";
import Resolved from "../customFields/Resolved";
import TagsField from "../../../../utils/Tags/customFields/TagsField";
import { createRecommendationTagLink } from "../functions/createRecommendationsTagLink";
import { fieldContexts } from "../../../../enums/fieldContexts";
import { getResourceAttribute } from "../../../../definitions/functions/getResourceAttribute";
import { getResourceQueryData } from "../../../../definitions/functions/getResourceQueryData";
import { useAccordion } from "../../../../hooks/views/useAccordion/useAccordion";
import { useDetailsCard } from "../../../../hooks/views/useDetailsCardV2";

/**
 * Displays the details for a single recommendation
 * @param {string} itemId - the id of the recommendation
 * @param {function} updateItemById - the function to update the recommendation
 * @param {string}  organizationID - the id of the organization
 * @param {object} config - config overrides
 */
export const useRecommendationDetailsAccordion = ({ itemId, updateItemById, organizationID, ...config }) => {
  const typename = "RecommendationItem";
  const module = modules.GOVERNANCE;
  const resource = resources.RECOMMENDATION;
  const route = "#/governance/recommendations/";

  const displayFields = getResourceAttribute({
    typename,
    attribute: "views.details.displayFields",
  });
  const { queryConfig, updateMutation, nestedFields } = getResourceQueryData({
    fieldContext: fieldContexts.DETAILS,
    typename,
  });
  queryConfig.itemId = itemId;

  const customFields = [
    {
      field: "status",
      component: <Resolved />,
    },
    {
      field: "resolvedAt",
      inputType: "date",
    },
    {
      field: "module",
      component: <Module />,
    },
    {
      field: "rating",
      component: <Rating />,
    },
    {
      field: "actionItems",
      component: <ActionItems />,
    },
    {
      field: "data",
      component: <Data />,
    },
    {
      field: "createdAt",
      component: <CustomDateField />,
    },
    {
      field: "notes",
      component: <RecommendationNotes />,
    },
    {
      field: "name",
      inputType: "textarea",
    },
    {
      field: "description",
      inputType: "textarea",
    },
    {
      field: "difficulty",
      component: <Difficulty />,
    },
    {
      field: "tags",
      component: (
        <TagsField
          module={module}
          resource={resource}
          createLinkFunction={createRecommendationTagLink}
          organizationID={organizationID}
        />
      ),
    },
  ];

  const detailsConfig = {
    fields: displayFields,
    nestedFields,
    customFields,
    updateMutation,
  };

  const cardConfig = {
    route,
    header: "Recommendation Details",
  };

  const detailsCard = useDetailsCard({
    queryConfig,
    detailsConfig,
    module,
    resource,
    config: cardConfig,
    updateItemById,
    ...config,
  });

  const accordion = useAccordion({
    details: detailsCard,
    items: [
      {
        title: "Observations",
        icon: "icon-eye",
        component: (
          <div style={{ height: "30em" }}>
            <RecommendationObservations item={detailsCard?.item} />
          </div>
        ),
        defaultExpanded: true,
      },
      {
        title: "Action Items",
        icon: "icon-rocket",
        defaultExpanded: true,
        component: (
          <div style={{ height: "30em" }}>
            <RecommendationActionItems item={detailsCard?.item} />
          </div>
        ),
      },
      {
        title: "Proposed Changes",
        icon: "icon-energy",
        component: (
          <DataLoader
            isEnoughData={detailsCard?.item?.data}
            dataMessage={"This Recommendation has no proposed changes"}
          >
            <Data data={detailsCard?.item?.data} item={detailsCard?.item} resetFunction={detailsCard?.reset} />
          </DataLoader>
        ),
      },
      {
        title: "Risk Changes",
        icon: "icon-graph",
        component: <NestedRiskChangesCard />,
      },
    ],
  });

  const display = (
    <Dashboard
      resetFunction={detailsCard?.reset}
      headerButtons={[<AutomationsButton key={0} itemId={itemId} typename={"RecommendationItem"} />]}
    >
      <Grid container spacing={2}>
        <Grid container spacing={2} item lg={4} md={4} xs={12}>
          <Grid item lg={12} xs={12}>
            <DashboardCard
              title={"General Information"}
              style={{ height: "100%", marginBottom: "1em" }}
              icon={"icon-list"}
            >
              {detailsCard?.tableDisplay}
            </DashboardCard>
          </Grid>
          <Grid item lg={12} xs={12}>
            <DashboardCard title={"Notes"} icon={"icon-note"} style={{ height: "100%" }}>
              <RecommendationNotes item={detailsCard?.item} disableTitle={true} />
            </DashboardCard>
          </Grid>
        </Grid>
        <Grid container spacing={2} item lg={8} md={8} xs={12}>
          <Grid item lg={12} xs={12}>
            {accordion.display}
          </Grid>
        </Grid>
      </Grid>
    </Dashboard>
  );

  return {
    ...detailsCard,
    display,
  };
};
