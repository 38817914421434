import React, { useEffect } from "react";
import { modules, resources } from "@rivial-security/role-utils";

import CreateObservation from "../components/CreateObservation";
import { DETAILS_TYPES } from "../../../../hooks/views/useGrid/enums/DETAILS_TYPES";
import Decision from "../customFields/Decision";
import IsFinding from "../customFields/IsFinding";
import Module from "../customFields/Module";
import NeedsDecision from "../customFields/NeedsDecision";
import ObservationDetails from "../components/ObservationDetails";
import Risk from "../customFields/Risk";
import Source from "../customFields/Source";
import Status from "../customFields/Status";
import { deleteObservation } from "../functions/deleteObservation";
import { generateGraphql } from "@rivial-security/generategraphql";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { observationsByOwnerGroup } from "../graphql/observationsByOwnerGroup";
import { useGridCard } from "../../../../hooks/views/useGridCard";

/**
 * List of Observations for an Org
 * @param organizationID
 * @param resetFunction
 * @param gridConfig
 * @param cardConfig
 * @param queryConfig
 * @param queryFields
 * @param hiddenFields
 * @param vulnerability
 * @param incident
 * @param exercise
 * @param evidence
 * @param control
 * @param audit
 * @param department
 * @param moduleInput
 * @param readOnly
 * @param {object} ...props - additional props to pass to the grid
 */
export const useObservationsGrid = ({
  organizationID,
  resetFunction,
  gridConfig = {},
  cardConfig = {},
  queryConfig = {},
  queryFields,
  hiddenFields = [],
  vulnerability,
  incident,
  exercise,
  evidence,
  control,
  audit,
  department,
  module: moduleInput,
  readOnly = false,
  ...props
}) => {
  const onlyShowFindings = true;
  const typename = "Observation";
  const module = modules.GOVERNANCE;
  const resource = resources.OBSERVATION;
  const route = "#/governance/observations/";

  queryFields = queryFields || [
    "name",
    "description",
    "module",
    "isFinding",
    "source",
    "externalSource",
    "formalResponse",
    "risk",
    "status",
    "decision",
    "needsDecision",
    "insurancePolicyValidated",
  ];

  const nestedFields = {
    externalSource: `{
      id
      name
      description
    }`,
    recommendations: `(limit: 100) {
      items {
        id
        recommendation {
          id
          name
          status
          rating
          actionItems (limit: 100) {
            items {
              id
              action {
                id
                name
              }
            }
          }
        }
      }
    }`,
  };

  const fields = [
    {
      name: "name",
    },
    {
      name: "isFinding",
      component: <IsFinding />,
      disablePropagation: true,
      searchNormalizer: (value) => {
        if (value == true) {
          return "finding";
        } else {
          return "observation";
        }
      },
      friendlyName: "Finding / Observation",
      filter: {
        type: "CheckBox",
      },
      width: 75,
    },
    {
      name: "module",
      component: <Module />,
      disablePropagation: true,
      filter: {
        type: "CheckBox",
      },
      width: 75,
      visible: !hiddenFields?.includes("module"),
    },
    {
      name: "externalSource",
      friendlyName: "Source",
      component: <Source />,
      disablePropagation: true,
      visible: !hiddenFields?.includes("externalSource"),
      width: 75,
    },
    {
      name: "risk",
      component: <Risk />,
      disablePropagation: true,
      filter: {
        type: "CheckBox",
      },
      width: 75,
      visible: true,
    },
    {
      name: "formalResponse",
      visible: false,
      friendlyName: "Management Response",
    },
    {
      name: "status",
      component: <Status />,
      disablePropagation: true,
      filter: {
        type: "CheckBox",
      },
      width: 75,
      visible: false,
    },
    {
      name: "decision",
      component: <Decision />,
      disablePropagation: true,
      filter: {
        type: "CheckBox",
      },
      width: 75,
      visible: false,
    },
    {
      name: "needsDecision",
      component: <NeedsDecision />,
      disablePropagation: true,
      filter: {
        type: "CheckBox",
      },
      width: 75,
      visible: false,
    },
  ];

  const { updateMutation } = generateGraphql("Observation", queryFields, nestedFields);

  queryConfig = {
    query: observationsByOwnerGroup,
    variables: { ownerGroup: organizationID },
    limit: 1000,
    ...queryConfig,
  };

  const roleConfig = {
    module,
    resource,
  };

  cardConfig = {
    title: onlyShowFindings === true ? "Findings" : "Findings and Observations",
    headerIcon: "icon-eye",
    ...cardConfig,
  };

  gridConfig = {
    resetFunction,
    fields,
    typename,
    createResourceComponent: !readOnly ? (
      <CreateObservation
        module={moduleInput}
        vulnerability={vulnerability}
        incident={incident}
        exercise={exercise}
        evidence={evidence}
        control={control}
        audit={audit}
        department={department}
      />
    ) : undefined,
    options: ["details", "delete"],
    deleteFunction: deleteObservation,
    detailsComponent: <ObservationDetails tableDisplay={true} />,
    route,
    updateMutation,
    allowFiltering: true,
    enableSearch: true,
    enableContextMenu: true,
    enablePrint: true,
    enableMenu: true,
    detailsTitle: "Observation Details",
    columnSize: 6,
    detailsType: DETAILS_TYPES.PANEL,
    persistenceUUID: "398889f8-0872-4a65-a572-5073066f7718",
    enablePersistence: true,
    ...gridConfig,
    ...props,
  };

  const gridCard = useGridCard({
    queryConfig,
    gridConfig,
    cardConfig,
    roleConfig,
  });

  /**
   * Handle isFinding filtering from switch
   */
  useEffect(() => {
    if (!isNullOrUndefined(onlyShowFindings) && !isNullOrUndefined(gridCard?.ref?.dataSource)) {
      if (onlyShowFindings === true) {
        gridCard.ref.dataSource = gridCard.data.filter((item) => item.isFinding === true);
      } else if (onlyShowFindings === false) {
        gridCard.ref.dataSource = gridCard.data;
      }
    }
  }, [onlyShowFindings, gridCard.data]);

  return {
    ...gridCard,
  };
};
