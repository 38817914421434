import { useVendorSolutionDetails } from "./useVendorSolutionDetails";
import { modules, resources } from "@rivial-security/role-utils";
import React from "react";
import Grid from "@mui/material/Grid";
import Dashboard from "../../../../utils/GenericComponents/Dashboard";
import { useAccordion } from "../../../../hooks/views/useAccordion/useAccordion";
import VendorReviews from "../customFields/VendorReviews";
import StyledWrapper from "../../../../utils/GenericComponents/StyledWrapper";
import VendorSolutionInformationSystems from "../customFields/VendorSolutionInformationSystems";
import GroupedRiskChangeDataGrid from "../../../Risk/RiskChanges/components/GroupedRiskChangeDataGrid";
import VendorSolutionDocuments from "../components/VendorSolutionDocuments";

/**
 * A detailed view of a single vendor solution
 * @param {string} organizationID - the database identifier of the currently selected organization
 * @param {string} itemId - the vendor solution database identifier
 * @param {boolean} tableDisplay - flag to indicate wet
 * @param {function} updateItemById - callback to update an item in the parent when its updated
 * @param {string} vendorReviewRoute - the url suffix for viewing vendor reviews
 * @param {string} route - the url suffix for viewing vendor solutions
 * @param {string} header - the header for the vendor solution general information (unused
 * @param {object} vendorReviewDetailsComponent - the component to use to display vendor solution reviews detailed views
 * @param {string} module - the platform module for which to check permissions
 * @param {string} resource - the platform resource for which to check permissions
 * @param {boolean} [disableRoleChecking] - if true, will not check permissions o nthe front end
 * @param {boolean} disableSystems - of true won't show the system accordion
 * @param {object} props - any other props passed down to the details comp
 * @return {{display: JSX.Element}}
 */
export const useVendorSolutionDetailsAccordion = ({
  organizationID,
  itemId,
  tableDisplay,
  updateItemById,
  vendorReviewRoute,
  route,
  header,
  vendorReviewDetailsComponent,
  module = modules.VENDORS,
  resource = resources.VENDOR_SOLUTION,
  disableRoleChecking,
  disableSystems = false,
  ...props
}) => {
  const vendorSolutionDetails = useVendorSolutionDetails({
    organizationID,
    itemId,
    tableDisplay: true,
    updateItemById,
    vendorReviewRoute,
    route,
    header,
    vendorReviewDetailsComponent,
    module,
    resource,
    disableRoleChecking,
    additionalFields: [
      {
        field: "vendorReviews",
        isHidden: true,
      },
      {
        field: "systems",
        isHidden: true,
      },
    ],
    ...props,
  });

  const accordionItems = [
    {
      id: "vendor-solution-general-information",
      title: "General Information",
      component: vendorSolutionDetails.display,
      icon: "icon-list",
      subTitle: "Vendor, Name, and Description",
      defaultExpanded: true,
    },
    {
      id: "vendor-solution-vendor-reviews",
      title: "Vendor Reviews",
      icon: "icon-book-open",
      component: (
        <StyledWrapper
          wrapperStyle={{
            height: "100%",
          }}
        >
          <VendorReviews
            organizationID={organizationID}
            route={vendorReviewRoute}
            detailsComponent={vendorReviewDetailsComponent}
            module={module}
            resource={resource}
            disableRoleChecking={disableRoleChecking}
            vendorReviews={vendorSolutionDetails?.item?.vendorReviews}
          />
        </StyledWrapper>
      ),
      subTitle: "Risk rating assessments performed for this vendor solution",
    },
    {
      title: "Risk Changes",
      subTitle: "Risk Changes caused by this vendor solution",
      icon: "icon-graph",
      createResourceComponent: null,
      component: (
        <GroupedRiskChangeDataGrid
          tableDisplay={true}
          height={"50vh"}
          queryConfig={{
            normalizeData: (data) => data.filter((item) => item?.source?.vendorSolutionID === itemId),
          }}
        />
      ),
    },
    {
      title: "Documents",
      subTitle: "Documents associated with this Vendor Solution",
      icon: "icon-docs",
      component: (
        <div style={{ height: "30em" }}>
          <VendorSolutionDocuments
            item={vendorSolutionDetails?.item}
            disableTitle={true}
            resetFunction={vendorSolutionDetails?.reset}
          />
        </div>
      ),
    },
  ];
  if (!disableSystems) {
    accordionItems.push({
      id: "vendor-solution-information-systems",
      title: "Information Systems",
      icon: "icon-screen-desktop",
      component: (
        <VendorSolutionInformationSystems
          item={vendorSolutionDetails?.item}
          resetFunction={vendorSolutionDetails?.resetFunction}
        />
      ),
      subTitle: "Information systems associated with this vendor solution",
    });
  }

  const accordion = useAccordion({
    details: vendorSolutionDetails,
    items: accordionItems,
  });

  //Dashboard display
  const display = (
    <Dashboard
      id="vendor-details-dashboard"
      title={!tableDisplay && "Vendor Details"}
      subTitle={vendorSolutionDetails?.item?.name}
      resetFunction={vendorSolutionDetails?.reset}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          {accordion.display}
        </Grid>
      </Grid>
    </Dashboard>
  );

  return {
    display,
  };
};
