import { useEffect, useState } from "react";

import _ from "lodash";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useBoolean } from "../../../../../../hooks/functional/useBoolean";
import { useHandleChanges } from "./useHandleChanges";
import { useSaveRoleConfig } from "./useSaveRoleConfig";

/**
 * Holds all roleConfig state and utility functions for viewing/saving/cancelling role changes
 * @param {Role} item - the Role item from the database
 * @param {ID} item.id - the ID of the Role item from the database
 */
export const useRoleConfig = ({ item }) => {
  /**
   * Holds the current state of the Role Configuration UI.
   */
  const [roleConfig, setRoleConfig] = useState({});

  /**
   * Holds the initial role config before any changes. When changes are cancelled, reverts back to this state
   */
  const [roleConfigInit, setRoleConfigInit] = useState({});

  /**
   * Determines if the UI has changed from the roleConfig database object.
   * When true, 'Save' and 'Cancel' buttons appear
   */
  const [isChanged, setIsChanged] = useBoolean();

  /**
   * State handler functions for Module / Resource switches
   */
  const { handleModuleChange, handleResourceChange } = useHandleChanges({
    setRoleConfig,
    setIsChanged,
  });

  /**
   * Used as a manual state reset key
   */
  const [key, setKey] = useState(1);

  /**
   * Hook handles saveChange function with toasts
   */
  const { saveChange } = useSaveRoleConfig({ item, roleConfig, setIsChanged });

  /**
   * Cancels all changes and resets the UI to match the roleConfig database object
   */
  const cancelChange = () => {
    setRoleConfig(_.cloneDeep(roleConfigInit));
    setIsChanged(false);
    setKey((key) => key + 1);
  };

  /**
   * When the roleDetails item query updates, replaces the current roleConfig and roleConfigInit state with the updated query object
   */
  useEffect(() => {
    if (!isNullOrUndefined(item?.roleConfig)) {
      setRoleConfigInit(_.cloneDeep(JSON.parse(item?.roleConfig)));
      setRoleConfig(_.cloneDeep(JSON.parse(item?.roleConfig)));
    }
  }, [item]);

  return {
    saveChange,
    cancelChange,
    isChanged,
    setIsChanged,
    handleModuleChange,
    handleResourceChange,
    key,
    setKey,
    roleConfig,
    roleConfigInit,
  };
};
