"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.monteCarloChangeCalculation = void 0;
const handleAssetSizeChange_1 = require("./functions/assetSizeChange/handleAssetSizeChange");
const handleKeyRiskIndicatorChange_1 = require("./functions/annualRateOfOccurrenceChange/handleKeyRiskIndicatorChange");
const handleNumberOfCustomersChange_1 = require("./functions/numberOfCustomersChange/handleNumberOfCustomersChange");
const handleRiskControlChange_1 = require("./functions/riskControlChange/handleRiskControlChange");
/**
 *
 * Calculates the effect of a Risk Change.
 * Currently supported Risk Changes:
 *  - Organization Risk Control Change
 *  - Organization Asset Size Change
 *  - Organization Number of Customers Change
 *  - System Risk Control Change (not yet implemented)
 *  - System Information Assets Change
 *  - System KRI Annual Rate of Occurrence Change
 *  - System Availability Rating Change
 *  - Cost of Control Change (not yet implemented)
 *
 * @param MonteCarloChangeCalculationInput
 */
const monteCarloChangeCalculation = async ({ organizationID, riskControl, keyRiskIndicator, costChange = 0, implementationChange = 0, annualRateOfOccurrenceChange, numberOfCustomersChange, assetSizeChange, queryConfig, }) => {
    /**
     * Handle invalid inputs
     */
    if (organizationID === null || organizationID === undefined) {
        throw Error("Error: Improper OrganizationID input!");
    }
    /**
     * Default Values
     */
    let inherentChange = 0;
    let residualChange = 0;
    let roiChange = 0;
    const systems = [];
    /**
     * Helper function for summing up change values
     * @param change
     */
    const handleChange = (change) => {
        inherentChange += change.inherentChange;
        residualChange += change.residualChange;
        roiChange += change.roiChange;
        systems.push(...change.systems);
    };
    /**
     * Handle Risk Control Change
     */
    if (riskControl?.id) {
        const riskControlChangeInput = {
            riskControl,
            costChange,
            implementationChange,
        };
        handleChange(await (0, handleRiskControlChange_1.handleRiskControlChange)(riskControlChangeInput, organizationID, queryConfig));
    }
    /**
     * Handle Asset Size Change
     */
    if (assetSizeChange !== null && assetSizeChange !== undefined && assetSizeChange !== 0) {
        const assetSizeChangeInput = { assetSizeChange };
        handleChange(await (0, handleAssetSizeChange_1.handleAssetSizeChange)(assetSizeChangeInput, organizationID, queryConfig));
    }
    /**
     * Handle Number of Customers Change
     */
    if (numberOfCustomersChange !== null && numberOfCustomersChange !== undefined && numberOfCustomersChange !== 0) {
        const numberOfCustomersChangeInput = { numberOfCustomersChange };
        handleChange(await (0, handleNumberOfCustomersChange_1.handleNumberOfCustomersChange)(numberOfCustomersChangeInput, organizationID, queryConfig));
    }
    /**
     * Handle Key Risk Indicator Change
     */
    if (keyRiskIndicator?.id) {
        const keyRiskIndicatorChangeInput = {
            keyRiskIndicator,
            annualRateOfOccurrenceChange,
        };
        handleChange(await (0, handleKeyRiskIndicatorChange_1.handleKeyRiskIndicatorChange)(keyRiskIndicatorChangeInput, organizationID, queryConfig));
    }
    return {
        organizationID,
        inherentChange,
        residualChange,
        roiChange,
        systems,
    };
};
exports.monteCarloChangeCalculation = monteCarloChangeCalculation;
