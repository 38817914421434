import React from "react";
import { useTable } from "../../../../../hooks/views/useTable";

/**
 * Displays the name and email of a particular Phishing Test user
 * @param item
 * @param item.user
 * @param item.user.first_name
 * @param item.user.last_name
 * @param item.user.email
 * @returns {JSX.Element}
 * @constructor
 */
const RecipientUser = ({ item }) => {
  const table = useTable({
    data: [item?.user],
    fields: ["first_name", "last_name", "email"],
    disableRoleChecking: true,
    hideHeader: true,
    config: {
      striped: true,
    },
  });

  return <span>{table.display}</span>;
};

export default RecipientUser;
