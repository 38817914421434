import { generateGraphql } from "@rivial-security/generategraphql";
import { GenericDeleteGQL } from "../../../../utils/Functions/Graphql/GenericDeleteGQL";

/**
 * Deletes a Key Performance Indicator
 * @author Jacob Blazina
 * @function
 * @async
 * @param {Object} item - The KRI to delete.
 * @param {string} item.id - The Appsync ID of the KRI
 * @returns {Promise<*>}
 */
export const deleteKeyPerformanceIndicator = async (item) => {
  // full query to get KPIMetricLinks
  const fields = ["id", "metricTypes", "riskControls", "complianceControls", "complianceEvidences", "__typename"];
  const nestedFields = {
    metricTypes: "(limit: 500) { items { __typename id } }",
    riskControls: `(limit: 500) { items { __typename id } }`,
    complianceControls: `(limit: 500) { items { __typename id } }`,
    complianceEvidences: `(limit: 500) { items { __typename id } }`,
  };

  const { getQuery } = generateGraphql("KeyPerformanceIndicator", fields, nestedFields);

  return GenericDeleteGQL({
    id: item.id,
    query: getQuery,
  });
};
