import { Badge } from "reactstrap";
import { Tooltip } from "@mui/material";
import React from "react";

/**
 * A generic badge with tooltip, text, and icon
 * @param {JSX.Element | String} icon - the icon to put in the badge
 * @param {string} tooltip - the text that displays in the tooltip on badge hover
 * @param {string} text - the badge text itself
 * @returns {JSX.Element}
 * @constructor
 */
const StatusBadge = ({ icon, tooltip, text }) => {
  return (
    <Badge color="ghost-secondary">
      <Tooltip title={tooltip} interactive arrow>
        <span>
          {icon} {text}
        </span>
      </Tooltip>
    </Badge>
  );
};

export default StatusBadge;
