import React, { useEffect, useState } from "react";

import CustomLabel from "../../../../hooks/views/useForm/components/CustomLabel";
import { ErrorLogger } from "@utils/EventLogger";
import { GetQuery } from "../../../../utils/Functions/Graphql/GetQuery";
import { generateGraphql } from "@rivial-security/generategraphql";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { priorityMap } from "../../../ActionCenter/functions/priorityMap";
import { useControlSetDropdown } from "../../../Compliance/Controls/ControlSets/hooks/useControlSetDropdown";
import { useForm } from "../../../../hooks/views/useForm";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * @description Configuration UI for Program Report
 * @param {string} organizationID - selected organization
 * @param {function} setInput - set data for the form
 * @returns {JSX.Element}
 * @constructor
 */
const ProgramReportConfigForm = ({ organizationID, setInput, isTemplate = false }) => {
  //Configuration input visibility flags
  const [hideReportingPeriodInput, setHideReportingPeriodInput] = useState(true);
  const [hideMonthInput, setHideMonthInput] = useState(true);
  const [hideQuarterInput, setHideQuarterInput] = useState(true);

  //Calculated configurations
  const [enablePriorityLimit, setEnablePriorityLimit] = useState(false);

  /** Form changes handlers **/
  const onFormSpanChange = (form) => {
    switch (form.span) {
      case "yearly":
        setHideMonthInput(true);
        setHideQuarterInput(true);
        break;
      case "quarterly":
        setHideMonthInput(true);
        setHideQuarterInput(false);
        break;
      case "monthly":
        setHideMonthInput(false);
        setHideQuarterInput(true);
        break;
    }
  };

  /**
   * Finds the start and end dates for the reporting period
   */
  const onFormTimeChange = (form) => {
    if (form.enableReportingPeriod) {
      //Find starting/ending dates
      let startingDate;
      let endingDate;
      switch (form.span) {
        case "yearly":
          startingDate = new Date(form.timeYear, 0);
          endingDate = new Date(form.timeYear + 1, 0);
          break;
        case "quarterly":
          startingDate = new Date(form.timeYear, form.timeQuarter * 3);
          endingDate = new Date(form.timeYear, (form.timeQuarter + 1) * 3);
          break;
        case "monthly":
          startingDate = new Date(form.timeYear, form.timeMonth);
          endingDate = new Date(form.timeYear, form.timeMonth + 1);
          break;
        default:
          ErrorLogger(`Error: ${form.span} - reporting period type is unsupported`);
          return;
      }

      setInput?.((input) =>
        Object.assign(input, {
          reportingPeriodConstraints: {
            enabled: true,
            timespan: form.span,
            startingDate,
            endingDate,
          },
        }),
      );
    } else {
      setInput?.((input) =>
        Object.assign(input, {
          reportingPeriodConstraints: { enabled: false },
        }),
      );
    }
  };

  /** Configuration Form Generation Functions **/
  /** Generates a list of years starting from current year
   * @param {number} amount - how many years to go back to
   */
  const generateYearOptions = (amount) => {
    const date = new Date();
    const curYear = date.getFullYear();
    const years = [];
    for (let i = 0; i < amount; i++) {
      years.push({ text: (curYear - i).toString(), value: curYear - i });
    }
    return years;
  };

  const generateMonthOptions = () => {
    const monthsText = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const months = [];
    for (const [index, month] of monthsText.entries()) {
      months.push({
        text: month,
        value: index,
      });
    }
    return months;
  };

  const controlFrameworkSelection = useControlSetDropdown({
    organizationID,
    listGroupItem: false,
    showAddon: false,
    disabled: isTemplate,
  });

  //Updates the available selections for compliance graph categorization field
  const [availableFields, setAvailableFields] = useState([]);
  const [curControlFrameworkData, setCurControlFrameworkData] = useState({});
  const getAvailableFields = async () => {
    if (!isNullOrUndefined(controlFrameworkSelection?.selectedControlSetId)) {
      const { getQuery } = generateGraphql("ControlSet", ["customFields", "totalComplianceMetric"], {
        customFields: "{name}",
        totalComplianceMetric: "{id}",
      });
      const controlFrameworkData = await GetQuery({
        query: getQuery,
        variables: { id: controlFrameworkSelection.selectedControlSetId },
      });

      const newAvailableFields = [];
      if (controlFrameworkData?.customFields && Array.isArray(controlFrameworkData.customFields)) {
        for (const customField of controlFrameworkData.customFields) {
          if (customField?.name) {
            newAvailableFields.push(customField.name);
          }
        }
      }
      setAvailableFields(newAvailableFields);
      setCurControlFrameworkData(controlFrameworkData);
    }
  };
  useEffect(() => {
    if (controlFrameworkSelection?.selectedControlSetId) {
      setInput?.((input) =>
        Object.assign(input, {
          controlFrameworkID: controlFrameworkSelection?.selectedControlSetId,
        }),
      );
      getAvailableFields();
    }
  }, [controlFrameworkSelection?.selectedControlSetId]);

  const selectedComplianceByFieldForm = useForm({
    disableSubmitButton: true,
    disableResetButton: true,
    fieldConfig: {
      complianceCategorizationField: {
        tooltip: "The field by which to separate controls in the compliance graph.",
        label: "Compliance Categorization Field",
        inputType: "dropdown",
        dropdownConfig: {
          data: !isNullOrUndefined(availableFields)
            ? availableFields.map((fieldName) => {
                return { text: fieldName, value: fieldName };
              })
            : [],
        },
        isHidden: isNullOrUndefined(controlFrameworkSelection.selectedControlSetId),
        onChangeFunction: (val) => {
          setInput?.((input) =>
            Object.assign(input, {
              complianceCategorizationField: val?.complianceCategorizationField,
            }),
          );
        },
      },
    },
  });

  const reportingPeriod = useForm({
    disableSubmitButton: true,
    disableResetButton: true,
    fieldConfig: {
      enableReportingPeriod: {
        inputType: "switch",
        label: "Limit Reporting Period",
        tooltip: "Specify the point in time when report data should be collected.",
        onChangeFunction: (val) => {
          onFormTimeChange(val);
          setHideReportingPeriodInput((val) => !val);
        },
      },
      span: {
        label: "Time Span",
        tooltip: "The length of time the report is going to cover.",
        inputType: "dropdown",
        defaultValue: "yearly",
        dropdownConfig: {
          data: [
            { text: "Yearly", value: "yearly" },
            { text: "Quarterly", value: "quarterly" },
            { text: "Monthly", value: "monthly" },
          ],
        },
        isHidden: hideReportingPeriodInput,
        onChangeFunction: (val) => {
          onFormSpanChange(val);
          onFormTimeChange(val);
        },
      },
      timeYear: {
        label: "Year",
        inputType: "dropdown",
        defaultValue: new Date().getFullYear(),
        dropdownConfig: {
          data: generateYearOptions(10),
        },
        isHidden: hideReportingPeriodInput,
        onChangeFunction: (val) => {
          onFormTimeChange(val);
        },
      },
      timeQuarter: {
        label: "Quarter",
        inputType: "dropdown",
        defaultValue: 0,
        dropdownConfig: {
          data: [
            { text: "Q1", value: 0 },
            { text: "Q2", value: 1 },
            { text: "Q3", value: 2 },
            { text: "Q4", value: 3 },
          ],
        },
        isHidden: hideReportingPeriodInput || hideQuarterInput,
        onChangeFunction: (val) => {
          onFormTimeChange(val);
        },
      },
      timeMonth: {
        label: "Month",
        inputType: "dropdown",
        defaultValue: 0,
        dropdownConfig: {
          data: generateMonthOptions(),
        },
        isHidden: hideReportingPeriodInput || hideMonthInput,
        onChangeFunction: (val) => {
          onFormTimeChange(val);
        },
      },
    },
  });

  const findingFilterForm = useForm({
    disableSubmitButton: true,
    disableResetButton: true,
    fieldConfig: {
      findingsOnly: {
        inputType: "switch",
        label: "Only Show Findings",
        defaultValue: false,
        tooltip: "Enable this option to filter out only the most important observations.",
        onChangeFunction: (val) => {
          setInput?.((input) => Object.assign(input, { findingsOnly: val.findingsOnly }));
        },
      },
    },
  });

  const updatePriorityLimit = (form) => {
    setEnablePriorityLimit(form.enablePriorityLimit);
    if (form.enablePriorityLimit) {
      const lowestPriority = priorityMap({ string: form.lowestPriority });
      setInput?.((input) => Object.assign(input, { lowestPriorityLevel: lowestPriority }));
    } else {
      setInput?.((input) => Object.assign(input, { lowestPriorityLevel: 0 }));
    }
  };

  const priorityLevelForm = useForm({
    disableSubmitButton: true,
    disableResetButton: true,
    fieldConfig: {
      enablePriorityLimit: {
        inputType: "switch",
        label: "Limit Lowest Priority Shown",
        tooltip: "Enable this option to filter out lower priority items from the report.",
        onChangeFunction: (val) => updatePriorityLimit(val),
      },
      lowestPriority: {
        label: "Lowest Priority",
        inputType: "dropdown",
        tooltip:
          "Select the lowest priority level that can appear in the report, everything with a lower priority will be omitted.",
        defaultValue: "medium",
        dropdownConfig: {
          data: [
            { text: "Low", value: "low" },
            { text: "Low - Medium", value: "lowMedium" },
            { text: "Medium", value: "medium" },
            { text: "Medium - High", value: "mediumHigh" },
            { text: "High", value: "high" },
            { text: "Very High", value: "veryHigh" },
          ],
        },
        isHidden: !enablePriorityLimit,
        onChangeFunction: (val) => updatePriorityLimit(val),
      },
    },
  });

  return (
    <div>
      <CustomLabel
        label={"Control Framework"}
        tooltip={"Select which control framework from which to grab compliance data."}
      />
      {controlFrameworkSelection.display}
      <div style={{ height: "1em" }}></div>
      {selectedComplianceByFieldForm.display}
      {reportingPeriod.display}
      {findingFilterForm.display}
      {priorityLevelForm.display}
    </div>
  );
};

export default withOrganizationCheck(ProgramReportConfigForm);
