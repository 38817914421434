import { ErrorLogger } from "@utils/EventLogger";
import { checkArguments } from "@rivial-security/func-utils";
import { cloneDeep } from "lodash";
import { locationType } from "../../../../analytics/constants/locationType";
import { operationType } from "../../../../analytics/constants/operationType";
import { reasonType } from "../../../../analytics/constants/reasonType";

/**
 * @description Save grid persist data
 * @param {object} ref - grid reference
 * @param {string} persistenceUUID - get persistence id
 * @param {function} getPreference - get user preference
 * @param {function} setPreference - set user preference
 * @param {boolean} enablePersistence - grid level switch to enable persistence
 * @param {object} customPersistenceData - custom data to be passed to the persistence settings that are saved
 */
export const gridSavePersistData = ({
  ref,
  persistenceUUID,
  getPreference,
  setPreference,
  enablePersistence,
  customPersistenceData,
}) => {
  checkArguments({
    ref,
    getPreference,
    setPreference,
  });

  const pref = getPreference && getPreference("general", "grid_persistence");

  /**
   * Check if persistence is enabled
   */
  if (pref && enablePersistence && persistenceUUID) {
    /**
     * Get grid properties
     */
    let model = null;
    if (ref?.getPersistData) {
      try {
        model = JSON.parse(ref?.getPersistData());
      } catch (e) {
        ErrorLogger(`Invalid persistence data passed to json parser - ${e.toString()}`, {
          location: locationType.FUNCTION,
          operation: operationType.GENERIC,
          reason: reasonType.INVALID_PARAM,
        });
      }
    }

    if (setPreference && persistenceUUID && model) {
      let allGridConfig = {};

      /**
       * Get config for the grid
       */
      const config = getPreference("general", "grid_persistence_config");
      if (config) {
        allGridConfig = config;
      }

      let savedConfig = {};

      if (allGridConfig && allGridConfig[persistenceUUID]) {
        savedConfig = {
          columns: allGridConfig[persistenceUUID].columns,
          groupSettings: allGridConfig[persistenceUUID].groupSettings,
          pageSettings: allGridConfig[persistenceUUID].pageSettings,
          sortSettings: allGridConfig[persistenceUUID].sortSettings,
          customSettings: allGridConfig[persistenceUUID].customSettings,
        };
      }

      /**
       * Clean the columns data from properties that do not need to be saved
       */
      const savedColumnProperties = ["name", "sortDirection", "width", "visible"];
      const tempColumns = model?.columns ? cloneDeep(model?.columns) : null;
      const columns = [];
      if (Array.isArray(columns)) {
        for (const column of tempColumns) {
          Object.keys(column).forEach((key) => savedColumnProperties.includes(key) || delete column[key]);
          if (column.hasOwnProperty("name")) {
            columns.push(column);
          }
        }
      }

      /**
       * Merge the passed in custom sertings object into existing settings
       */
      const customSettings = {
        ...savedConfig.customSettings,
        ...customPersistenceData,
      };

      /**
       * Grid config to save
       */
      let input = {
        groupSettings: model.groupSettings,
        pageSettings: model.pageSettings,
        sortSettings: model.sortSettings,
        customSettings,
      };

      if (columns !== null) {
        input = Object.assign(input, { columns });
      }

      /**
       * Check if input changed
       */
      if (JSON.stringify(savedConfig) !== JSON.stringify(input)) {
        /**
         * Assign new value
         */
        const newAllGridConfig = Object.assign(allGridConfig, {
          [persistenceUUID]: input,
        });
        setPreference("general", { grid_persistence_config: newAllGridConfig });
      }
    }
  }
};
