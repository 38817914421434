import React, { useEffect } from "react";

import DashboardCard from "../../../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import { getPointOfContact_meetingList } from "../../../graphql/__pointOfContactGQL";
import { useMeetingsDataGrid } from "../../../../../Program/Meetings/hooks/useMeetingsDataGrid";
import { useQueryGetItem } from "../../../../../../hooks/graphql/useQueryGetItem";
import { withDetailsPage } from "../../../../../../utils/Context/withDetailsPage";

/**
 * Displays meetings for a Point of Contact
 * @param {string} itemId - point of contact id
 * @param {string} organizationID - selected organization
 * @param enableTemplates - enable being able to select templates
 * @returns {JSX.Element}
 * @constructor
 */
const PointOfContactMeetings = ({ itemId, organizationID, enableTemplates }) => {
  const queryHook = useQueryGetItem({
    query: getPointOfContact_meetingList,
    itemId,
    disableRoleChecking: true,
  });

  const queryConfig = {
    query: null,
  };

  const gridConfig = {
    enableTemplates: enableTemplates,
  };

  const meetingsGrid = useMeetingsDataGrid({
    organizationID,
    queryConfig,
    gridConfig,
  });

  useEffect(() => {
    const item = queryHook.item;

    const meetingLinks = item && item.meetings ? item.meetings.items : [];

    const temp = [];

    for (const meetingLink of meetingLinks) {
      if (meetingLink.meeting) {
        temp.push(meetingLink.meeting);
      }
    }

    meetingsGrid.setData([...temp]);
  }, [queryHook.item]);

  return (
    <DashboardCard title={"Meetings"} isLoading={queryHook.isLoading} style={{ height: "100%" }} icon="icon-people">
      <div style={{ height: "30em" }}>{meetingsGrid.gridDisplay}</div>
    </DashboardCard>
  );
};

export default withDetailsPage(PointOfContactMeetings);
