import { useMitreAttackTechniqueDetails } from "../hooks/useMitreAttackTechniqueDetails";
import { withDetailsPage } from "../../../../../utils/Context/withDetailsPage";

/**
 * @description Display Mitre Attack Technique details component
 * @param {object} props - React props for the component
 * @returns {JSX.Element}
 * @constructor
 */
const MitreAttackTechniqueDetails = (props) => useMitreAttackTechniqueDetails(props).display;

export default withDetailsPage(MitreAttackTechniqueDetails, {
  title: "Technique Details",
});
