import { modules, resources } from "@rivial-security/role-utils";

import Dashboard from "../../../../utils/GenericComponents/Dashboard";
import DashboardCard from "../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import FindingsReportButton from "../../../Reports/FindingsReport/components/FindingsReportButton";
import Grid from "@mui/material/Grid";
import React from "react";
import SourceActionItems from "../components/SourceActionItems";
import SourceDocuments from "../components/SourceDocuments";
import SourceNotes from "../components/SourceNotes";
import SourceObservations from "../components/SourceObservations";
import SourceRecommendations from "../components/SourceRecommendations";
import { fieldContexts } from "../../../../enums/fieldContexts";
import { getResourceAttribute } from "../../../../definitions/functions/getResourceAttribute";
import { getResourceQueryData } from "../../../../definitions/functions/getResourceQueryData";
import { useAccordion } from "../../../../hooks/views/useAccordion/useAccordion";
import { useDetailsCard } from "../../../../hooks/views/useDetailsCardV2";

/**
 * Displays the details info of a single source
 * @param {string} itemId - the id of the source to display
 * @param {object} config - additional props to pass down to the detail card
 */
export const useSourceDetailsAccordion = ({ itemId, ...config }) => {
  const typename = resources.SOURCE;
  const module = modules.GOVERNANCE;
  const resource = resources.SOURCE;
  const route = "#/governance/sources/";

  const displayFields = getResourceAttribute({
    typename,
    attribute: "views.details.displayFields",
  });
  const { queryConfig, updateMutation, nestedFields } = getResourceQueryData({
    fieldContext: fieldContexts.DETAILS,
    typename,
  });
  queryConfig.itemId = itemId;

  const customFields = [
    {
      field: "name",
      inputType: "textarea",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "description",
      inputType: "textarea",
      width: 200,
    },
    {
      field: "date",
      inputType: "date",
      width: 200,
    },
  ];

  const detailsConfig = {
    fields: displayFields,
    nestedFields,
    customFields,
    updateMutation,
    typename,
  };

  const cardConfig = {
    route,
    header: "Source Details",
  };

  const detailsCard = useDetailsCard({
    typename,
    queryConfig,
    detailsConfig,
    module,
    resource,
    config: cardConfig,
    ...config,
  });

  const style = { height: "100%" };

  const accordion = useAccordion({
    details: detailsCard,
    items: [
      {
        title: "Observations",
        icon: "icon-eye",
        component: (
          <div style={{ height: "30em" }}>
            <SourceObservations item={detailsCard?.item} />
          </div>
        ),
        defaultExpanded: true,
      },
      {
        title: "Recommendations",
        icon: "icon-check",
        component: (
          <div style={{ height: "30em" }}>
            <SourceRecommendations item={detailsCard?.item} />
          </div>
        ),
      },
      {
        title: "Action Items",
        icon: "icon-rocket",
        component: (
          <div style={{ height: "30em" }}>
            <SourceActionItems item={detailsCard?.item} />
          </div>
        ),
      },
      {
        title: "Documents",
        icon: "icon-docs",
        component: (
          <div style={{ height: "30em" }}>
            <SourceDocuments item={detailsCard?.item} disableTitle={true} resetFunction={detailsCard?.reset} />
          </div>
        ),
      },
    ],
  });

  const display = (
    <Dashboard resetFunction={detailsCard?.reset} headerButtons={[<FindingsReportButton key={0} sourceID={itemId} />]}>
      <Grid container spacing={2}>
        <Grid container spacing={2} item md={4} lg={4} sm={12} xs={12}>
          <Grid item lg={12} xs={12}>
            <DashboardCard title={"General Information"} style={style} icon={"icon-list"}>
              {detailsCard.tableDisplay}
            </DashboardCard>
          </Grid>
          <Grid item lg={12} xs={12}>
            <DashboardCard title={"Notes"} style={style} icon={"icon-note"}>
              <SourceNotes item={detailsCard?.item} disableTitle={true} />
            </DashboardCard>
          </Grid>
        </Grid>
        <Grid container spacing={2} item md={8} lg={8} sm={12} xs={12}>
          <Grid item xl={12} xs={12}>
            {accordion.display}
          </Grid>
        </Grid>
      </Grid>
    </Dashboard>
  );

  return {
    ...detailsCard,
    display,
  };
};
