import GenericEditFieldV3 from "../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";
import { updateEvidence } from "../graphql/updateEvidence";
import React from "react";
import { modules, resources } from "@rivial-security/role-utils";
import { TEMPLATE } from "../../../../enums/TEMPLATE";
import { fieldContexts } from "../../../../enums/fieldContexts";

/**
 * @description Custom field for displaying Evidence enabled data
 * @param {object} item - object with info pertaining to this evidence
 * @param {string} fieldContext - The context of the field, used to determine if a tooltip should be displayed
 * @param {object} ...props - all other props passed to generic edit field
 */
const EvidenceEnabledSwitch = ({ item, fieldContext, ...props }) => {
  const isTemplate = item?.ownerGroup === TEMPLATE;
  const module = isTemplate ? modules.ADMINISTRATOR : modules.COMPLIANCE;
  const resource = isTemplate ? resources.EVIDENCE_TEMPLATE : resources.EVIDENCE;

  return (
    <GenericEditFieldV3
      {...props}
      item={item}
      module={module}
      resource={resource}
      inputType={"switch"}
      field={"enabled"}
      confirmFunction={(value) => {
        if (!value) {
          return window.confirm(
            "Are you sure you want to disable this evidence? This will delete all associated observations, recommendation and action items.",
          );
        }
      }}
      mutation={updateEvidence}
      tooltip={
        fieldContext !== fieldContexts.GRID
          ? "When enabled, automatically send out Evidence gathering emails and automate Control statuses based on current Evidence status"
          : undefined
      }
      customDisplayComponent={<CustomDisplay />}
      friendlyName={"Enabled"}
    />
  );
};

const CustomDisplay = ({ enabled }) => {
  return <span>{enabled === true ? "Yes" : "No"}</span>;
};

export default EvidenceEnabledSwitch;
