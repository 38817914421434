import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { generateGraphql } from "@rivial-security/generategraphql";
import { isNullOrUndefined } from "@rivial-security/func-utils";
/**
 * Simple mutation for creating the Many-To-Many connection between an Observation and RecommendationItem
 * @param observation
 * @param recommendation
 */
export const createObservationRecommendationLink = async (observation, recommendation) => {
  const { createMutation } = generateGraphql("ObservationRecommendationLink");

  if (
    !isNullOrUndefined(observation?.id) &&
    !isNullOrUndefined(recommendation?.id) &&
    (!isNullOrUndefined(observation?.ownerGroup) || !isNullOrUndefined(recommendation?.ownerGroup))
  ) {
    return await ItemMutation(createMutation, {
      observationID: observation.id,
      recommendationID: recommendation.id,
      ownerGroup: observation.ownerGroup || recommendation.ownerGroup,
    });
  }
};
