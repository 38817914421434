import DashboardCard from "../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import React from "react";
import SystemDetails from "../../../Risk/Systems/components/SystemDetails";
import UnderlinedTooltip from "../../../../utils/Tooltips/UnderlinedTooltip";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useHistory } from "react-router-dom";

/**
 * Displays system information in non-standard controls grid
 * @param {object} system - system data, atleast the id needs to be present
 * @return {JSX.Element}
 */
const NonStandardControlSystem = ({ item, system }) => {
  const history = useHistory();

  const systemName = system?.name ?? "Unknown System";

  return (
    <div>
      {!isNullOrUndefined(system?.name) ? (
        <UnderlinedTooltip
          text={systemName}
          onClick={() => {
            history.push(`/risk/systems/${system?.id}`);
          }}
        >
          <DashboardCard>
            <SystemDetails item={system} />
          </DashboardCard>
        </UnderlinedTooltip>
      ) : (
        <span>{systemName}</span>
      )}
    </div>
  );
};

export default NonStandardControlSystem;
