import React, { useContext } from "react";
import { useModal } from "../../../../../../../hooks/views/useModal";
import MonteCarloSummary from "./MonteCarloSummary";
import { MonteCarloContext } from "../../../../context/MonteCarloContext";

export const useMonteCarloSummary = (modalConfig = {}) => {
  const monteCarloContext = useContext(MonteCarloContext);

  const display = (
    <MonteCarloSummary
      monteCarloInput={monteCarloContext.monteCarloInput}
      monteCarloResults={monteCarloContext.monteCarloResults}
    />
  );

  const monteCarloSummaryModal = useModal(
    modalConfig.header || "Monte Carlo Summary",
    modalConfig.body || display,
    modalConfig.button || null,
    {
      width: modalConfig.width || "80vw",
    },
  );

  return {
    ...monteCarloSummaryModal,
    display,
  };
};
