import { useEffect, useState } from "react";
import { getEvidenceActivities } from "../functions/getEvidenceActivities";
import { groupEvidenceActivity } from "../functions/groupEvidenceActivity";

/**
 * @description useEvidenceActivity hook gets activities for an evidence
 * @param {object} item - evidence object
 * @param {function} setItem - setter function for an evidence
 * @param {boolean} isGrouped - if TRUE some evidence activity that occurred at the same time will be grouped together under one
 */
export const useEvidenceActivity = ({ item, setItem, key, isGrouped = false }) => {
  const [queriedActivity, setQueriedActivity] = useState([]);
  const [sortedActivity, setSortedActivity] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getEvidenceActivities({
      item,
      setSortedActivity: setQueriedActivity,
      setIsLoading,
    });
  }, [item?.id, key]);

  //Combine all evidence activities with the same groupID into one activity with array of activities
  useEffect(() => {
    setSortedActivity(groupEvidenceActivity({ activities: queriedActivity, isGrouped }));
  }, [isGrouped, queriedActivity]);

  useEffect(() => {
    item &&
      setItem &&
      sortedActivity &&
      setItem((data) => {
        Object.assign(data, { activity: { items: sortedActivity } });
        return { ...data };
      });
  }, [sortedActivity]);

  return {
    sortedActivity,
    isLoading,
  };
};
