import { REPORT_TEMPLATES, generateGeneralTemplate, updateTableOfContents } from "@rivial-security/report-utils";
import { useContext, useEffect, useState } from "react";

import { DocumentEditorChipEnums } from "../constants/documentEditorChipEnums";
import { OrganizationContext } from "../../../../utils/Context/OrganizationContext";
import { generateCustomTemplate } from "../functions/generateCustomTemplate";
import { getIcon } from "../../../../utils/Functions/Icon/getIcon";
import { getTemplateConfiguration } from "../functions/getTemplateConfiguration";

/**
 * The drawer items for all rivial report templates
 * @param {string} organizationID - currently selected organization
 * @param {function} handleTemplateClick - callback for creating a template with a form configuration
 * @param {function} generateFromTemplate - function callback to load in the SFDT into the document editor
 * @param {function} onJobStarted - callback to notify the document editor that a server job has started (takes in job object)
 */
export const useRivialReportTemplatesMapping = ({
  organizationID,
  handleTemplateClick,
  generateFromTemplate,
  onJobStarted,
} = {}) => {
  const { orgLogo, sentryTrace, selectedOrganizationObjectMinimal, operationTeamID } = useContext(OrganizationContext);
  // [ALL RIVIAL REPORT TEMPLATES]
  const getMapping = ({ orgLogo }) => {
    const newMapping = [
      {
        id: "GENERAL_TEMPLATE",
        headline: "Generic Rivial Template",
        subtitle: "A minimal Rivial branded document with a cover page to get you started.",
        icon: getIcon("simple-line-icons:rocket"),
        onClick: async () => {
          await generateFromTemplate(async () => {
            const result = await generateGeneralTemplate({
              input: {
                organization: {
                  orgLogo,
                  ...selectedOrganizationObjectMinimal,
                },
                operationTeamID,
              },
            });
            const sfdt = updateTableOfContents({ sfdt: result?.sfdt });
            return sfdt;
          });
        },
      },

      {
        id: REPORT_TEMPLATES.AUDIT_TEMPLATE,
        headline: "IT Audit Report",
        subtitle: "A comprehensive IT Audit summary with information on checked controls.",
        icon: getIcon("simple-line-icons:film"),
        onClick: async (config) => {
          await handleTemplateClick(
            (formInput) => {
              generateCustomTemplate({
                templateId: REPORT_TEMPLATES.AUDIT_TEMPLATE,
                organizationID,
                config: formInput.config,
                preserveTags: false,
                onJobStarted,
                sentryTrace,
                operationTeamID,
              });
            },
            getTemplateConfiguration({ templateType: REPORT_TEMPLATES.AUDIT_TEMPLATE, config }),
          );
        },
      },

      {
        id: REPORT_TEMPLATES.COMPLIANCE_REPORT_TEMPLATE,
        headline: "Continuous Compliance Report",
        subtitle:
          "A point in time compliance report with information on security controls for a single control framework.",
        icon: getIcon("simple-line-icons:list"),
        onClick: async (config = {}) => {
          await handleTemplateClick(
            (formInput) => {
              generateCustomTemplate({
                templateId: REPORT_TEMPLATES.COMPLIANCE_REPORT_TEMPLATE,
                organizationID,
                config: formInput.config,
                preserveTags: false,
                onJobStarted,
                sentryTrace,
                operationTeamID,
              });
            },
            getTemplateConfiguration({ templateType: REPORT_TEMPLATES.COMPLIANCE_REPORT_TEMPLATE, config }),
          );
        },
      },

      {
        id: REPORT_TEMPLATES.RA_TEMPLATE,
        headline: "Risk Assessment Report",
        subtitle:
          "An overview of the organization's systems and the financial risks that they bring due to their security control status.",
        icon: getIcon("simple-line-icons:screen-desktop"),
        onClick: async (config = {}) => {
          await handleTemplateClick(
            (formInput) => {
              generateCustomTemplate({
                templateId: REPORT_TEMPLATES.RA_TEMPLATE,
                organizationID,
                config: formInput.config,
                preserveTags: false,
                onJobStarted,
                sentryTrace,
                operationTeamID,
              });
            },
            getTemplateConfiguration({ templateType: REPORT_TEMPLATES.RA_TEMPLATE, config }),
          );
        },
      },

      {
        id: REPORT_TEMPLATES?.EXECUTIVE_SUMMARY_TEMPLATE,
        headline: "Executive Summary Report",
        subtitle: "An overarching report across all platform modules on the organization's state of security.",
        icon: getIcon("simple-line-icons:book-open"),
        onClick: async (config) => {
          await handleTemplateClick(
            (formInput) => {
              generateCustomTemplate({
                templateId: REPORT_TEMPLATES.EXECUTIVE_SUMMARY_TEMPLATE,
                organizationID,
                config: formInput.config,
                preserveTags: false,
                onJobStarted,
                sentryTrace,
                operationTeamID,
              });
            },
            getTemplateConfiguration({ templateType: REPORT_TEMPLATES.EXECUTIVE_SUMMARY_TEMPLATE, config }),
          );
        },
      },

      {
        id: REPORT_TEMPLATES.SECURITY_TESTING_TEMPLATE,
        headline: "Security Testing Report",
        subtitle: "A document describing the results of a single testing assessment.",
        icon: getIcon("simple-line-icons:badge"),
        onClick: async (config) => {
          await handleTemplateClick(
            (formInput) => {
              generateCustomTemplate({
                templateId: REPORT_TEMPLATES.SECURITY_TESTING_TEMPLATE,
                organizationID,
                config: formInput.config,
                preserveTags: false,
                onJobStarted,
                sentryTrace,
                operationTeamID,
              });
            },
            getTemplateConfiguration({ templateType: REPORT_TEMPLATES.SECURITY_TESTING_TEMPLATE, config }),
          );
        },
      },

      {
        id: REPORT_TEMPLATES.VENDOR_REVIEW_TEMPLATE,
        headline: "Vendor Review Report",
        subtitle: "A document describing the results of a single vendor review.",
        icon: getIcon("simple-line-icons:basket-loaded"),
        onClick: async (config) => {
          await handleTemplateClick(
            (formInput) => {
              generateCustomTemplate({
                templateId: REPORT_TEMPLATES.VENDOR_REVIEW_TEMPLATE,
                organizationID,
                config: formInput.config,
                preserveTags: false,
                onJobStarted,
                sentryTrace,
                operationTeamID,
              });
            },
            getTemplateConfiguration({ templateType: REPORT_TEMPLATES.VENDOR_REVIEW_TEMPLATE, config }),
          );
        },
      },

      {
        id: REPORT_TEMPLATES.VENDOR_REVIEW_CONTROLS_TEMPLATE,
        headline: "Vendor Review Controls Report",
        subtitle: "A simplified version of the vendor review report focusing on the checked security controls.",
        icon: getIcon("simple-line-icons:basket-loaded"),
        onClick: async (config) => {
          await handleTemplateClick(
            (formInput) => {
              generateCustomTemplate({
                templateId: REPORT_TEMPLATES.VENDOR_REVIEW_CONTROLS_TEMPLATE,
                organizationID,
                config: formInput.config,
                preserveTags: false,
                onJobStarted,
                sentryTrace,
                operationTeamID,
              });
            },
            getTemplateConfiguration({ templateType: REPORT_TEMPLATES.VENDOR_REVIEW_CONTROLS_TEMPLATE, config }),
          );
        },
      },

      {
        id: REPORT_TEMPLATES.INCIDENT_RESPONSE_PLAN_TEMPLATE,
        headline: "Incident Response Plan",
        subtitle: "A policy document describing the steps the organization will take in case of an incident.",
        icon: getIcon("simple-line-icons:map"),
        chips: [DocumentEditorChipEnums.POLICY],
        onClick: async () => {
          await generateCustomTemplate({
            templateId: REPORT_TEMPLATES.INCIDENT_RESPONSE_PLAN_TEMPLATE,
            organizationID,
            preserveTags: false,
            onJobStarted,
            sentryTrace,
            operationTeamID,
          });
        },
      },

      {
        id: REPORT_TEMPLATES.INCIDENT_RESPONSE_EXERCISE_TEMPLATE,
        headline: "Incident Response Exercise Report",
        subtitle: "A document describing the results of a single incident response exercise.",
        icon: getIcon("simple-line-icons:note"),
        onClick: async (config) => {
          await handleTemplateClick(
            (formInput) => {
              generateCustomTemplate({
                templateId: REPORT_TEMPLATES.INCIDENT_RESPONSE_EXERCISE_TEMPLATE,
                organizationID,
                config: formInput.config,
                preserveTags: false,
                onJobStarted,
                sentryTrace,
                operationTeamID,
              });
            },
            getTemplateConfiguration({ templateType: REPORT_TEMPLATES.INCIDENT_RESPONSE_EXERCISE_TEMPLATE, config }),
          );
        },
      },

      {
        id: REPORT_TEMPLATES.INCIDENT_RESPONSE_INCIDENT_TEMPLATE,
        headline: "Incident Report",
        subtitle: "A document describing the results of a single incident.",
        icon: getIcon("simple-line-icons:flag"),
        onClick: async (config) => {
          await handleTemplateClick(
            (formInput) => {
              generateCustomTemplate({
                templateId: REPORT_TEMPLATES.INCIDENT_RESPONSE_INCIDENT_TEMPLATE,
                organizationID,
                config: formInput.config,
                preserveTags: false,
                onJobStarted,
                sentryTrace,
                operationTeamID,
              });
            },
            getTemplateConfiguration({ templateType: REPORT_TEMPLATES.INCIDENT_RESPONSE_INCIDENT_TEMPLATE, config }),
          );
        },
      },

      {
        id: REPORT_TEMPLATES.BLUEPRINT_TEMPLATE,
        headline: "Cybersecurity Blueprint Report",
        subtitle:
          "A short report providing an outline of the cybersecurity status of the organization based on the blueprint from the program module.",
        icon: getIcon("simple-line-icons:chart"),
        onClick: async (config = {}) => {
          await handleTemplateClick(
            (formInput) => {
              generateCustomTemplate({
                templateId: REPORT_TEMPLATES.BLUEPRINT_TEMPLATE,
                organizationID,
                config: formInput.config,
                preserveTags: false,
                onJobStarted,
                sentryTrace,
                operationTeamID,
              });
            },
            getTemplateConfiguration({ templateType: REPORT_TEMPLATES.BLUEPRINT_TEMPLATE, config }),
          );
        },
      },

      {
        id: REPORT_TEMPLATES.MEETING_TEMPLATE,
        headline: "Meeting Report",
        subtitle: "A document describing the results of a single meeting.",
        icon: getIcon("simple-line-icons:note"),
        onClick: async (config = {}) => {
          await handleTemplateClick(
            (formInput) => {
              generateCustomTemplate({
                templateId: REPORT_TEMPLATES.MEETING_TEMPLATE,
                organizationID,
                config: formInput.config,
                preserveTags: false,
                onJobStarted,
                sentryTrace,
                operationTeamID,
              });
            },
            getTemplateConfiguration({ templateType: REPORT_TEMPLATES.MEETING_TEMPLATE, config }),
          );
        },
      },

      {
        id: REPORT_TEMPLATES.FINDINGS_TEMPLATE,
        headline: "Findings Report",
        subtitle: "An in depth report focusing on findings from a particular source or all sources together.",
        icon: getIcon("simple-line-icons:eye"),
        onClick: async (config) => {
          await handleTemplateClick(
            (formInput) => {
              generateCustomTemplate({
                templateId: REPORT_TEMPLATES.FINDINGS_TEMPLATE,
                organizationID,
                config: formInput.config,
                preserveTags: false,
                onJobStarted,
                sentryTrace,
                operationTeamID,
              });
            },
            getTemplateConfiguration({
              templateType: REPORT_TEMPLATES.FINDINGS_TEMPLATE,
              config,
            }),
          );
        },
      },

      {
        id: REPORT_TEMPLATES.PHISHING_TEMPLATE,
        headline: "Phishing Report",
        subtitle: "A document describing the results of a single phishing exercise.",
        icon: getIcon("simple-line-icons:present"),
        onClick: async (config) => {
          await handleTemplateClick(
            (formInput) => {
              generateCustomTemplate({
                templateId: REPORT_TEMPLATES.PHISHING_TEMPLATE,
                organizationID,
                config: formInput.config,
                preserveTags: false,
                onJobStarted,
                sentryTrace,
                operationTeamID,
              });
            },
            getTemplateConfiguration({ templateType: REPORT_TEMPLATES.PHISHING_TEMPLATE, config }),
          );
        },
      },

      {
        id: REPORT_TEMPLATES.TRAINING_TEMPLATE,
        headline: "Training Report",
        subtitle: "A document describing the results of a single training.",
        icon: getIcon("simple-line-icons:note"),
        onClick: async (config) => {
          await handleTemplateClick(
            (formInput) => {
              generateCustomTemplate({
                templateId: REPORT_TEMPLATES.TRAINING_TEMPLATE,
                organizationID,
                config: formInput.config,
                preserveTags: false,
                onJobStarted,
                sentryTrace,
                operationTeamID,
              });
            },
            getTemplateConfiguration({ templateType: REPORT_TEMPLATES.TRAINING_TEMPLATE, config }),
          );
        },
      },
    ];

    //For each item in the mapping set the property "showBadge" to true
    for (const item of newMapping) {
      item.showBadge = true;
    }

    //For each item in the mapping add the 'rivial template' chips option
    for (const item of newMapping) {
      if (Array.isArray(item.chips)) {
        item.chips.push(DocumentEditorChipEnums.RIVIAL_TEMPLATE);
      } else {
        item.chips = [DocumentEditorChipEnums.RIVIAL_TEMPLATE];
      }
    }

    return newMapping;
  };

  const [mapping, setMapping] = useState(getMapping({ orgLogo: orgLogo }));
  useEffect(() => {
    setMapping(getMapping({ orgLogo: orgLogo }));
  }, [orgLogo]);

  return { mapping };
};
