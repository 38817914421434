import { useEffect, useState } from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useAvailableSystems } from "./useAvailableSystems";
import { useSystemNonStandardControls } from "./useSystemNonStandardControls";

/**
 * Central data and state handler for the Risk Recommendation Monte Carlo component
 * @param {string} organizationID - currently selected organization
 * @param {object} system - system data to check for changes to refresh selection options
 * @param {object} riskControl - the riskControl data to check for changes to refresh selection options
 * @param {object} item - the risk recommendation object
 * @param {function} filterRiskControls - custom filter function through which to pass through risk controls
 * that are available for selection
 */
export const useRiskRecommendationData = ({ organizationID, system, riskControl, item, filterRiskControls }) => {
  const data = item && JSON.parse(item?.data);

  const [systemChanges, setSystemChanges] = useState(data?.systemChanges || []);
  const [selectedRiskControl, setSelectedRiskControl] = useState(null);
  const [selectedSystem, setSelectedSystem] = useState(null);
  const [overallResidualChange, setOverallResidualChange] = useState(data?.residualChange || 0);
  const [overallROIChange, setOverallROIChange] = useState(data?.returnOnInvestment || 0);
  const [changeInCost, setChangeInCost] = useState(data?.costChange || 0);
  const [changeInImplementation, setChangeInImplementation] = useState(data?.implementationChange || 0);
  const [selectedSystemID, setSelectedSystemID] = useState(system?.id || data?.system?.id || null);
  const [selectedRiskControlID, setSelectedRiskControlID] = useState(riskControl?.id || data?.riskControlID || null);
  const [calculationResults, setCalculationResults] = useState(null);

  const {
    systems,
    isLoading: isLoadingSystems,
    total: totalSystems,
  } = useAvailableSystems({
    organizationID,
    selectedRiskControlID,
  });

  const { riskControls, isLoading: isLoadingRiskControls } = useSystemNonStandardControls({
    filterRiskControls,
    selectedSystemID,
    systems,
    organizationID,
    system,
  });

  /**
   * When a system ID is selected, finds the system from the list and set full system in state
   */
  useEffect(() => {
    if (!isNullOrUndefined(selectedSystemID) && !isNullOrUndefined(systems)) {
      const foundSystem = systems.find((item) => item.id === selectedSystemID);
      setSelectedSystem(foundSystem);
    }
  }, [selectedSystemID, system, item, systems]);

  /**
   * When a Risk control ID is selected, find risk control from list and set full risk control in state
   */
  useEffect(() => {
    if (!isNullOrUndefined(selectedRiskControlID) && !isNullOrUndefined(riskControls)) {
      const foundRiskControl = riskControls.find((item) => item.id === selectedRiskControlID);
      setSelectedRiskControl(foundRiskControl);
    }
  }, [selectedRiskControlID, system, item, riskControls]);

  return {
    systemChanges,
    setSystemChanges,
    selectedRiskControl,
    setSelectedRiskControl,
    selectedSystem,
    setSelectedSystem,
    overallResidualChange,
    setOverallResidualChange,
    overallROIChange,
    setOverallROIChange,
    changeInCost,
    setChangeInCost,
    changeInImplementation,
    setChangeInImplementation,
    selectedSystemID,
    setSelectedSystemID,
    selectedRiskControlID,
    setSelectedRiskControlID,
    calculationResults,
    setCalculationResults,
    riskControls,
    systems,
    isLoadingRiskControls,
    isLoadingSystems,
    totalSystems,
  };
};
