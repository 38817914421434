import {
  AxesDirective,
  AxisDirective,
  CircularGaugeComponent,
  PointerDirective,
  PointersDirective,
  RangeDirective,
  RangesDirective,
} from "@syncfusion/ej2-react-circulargauge";

import { ChartParams } from "./types";
import { useUUID } from "../../../hooks/functional/useUUID";

/**
 * @description Generic Circular Gauge Chart component
 */
const CircularGaugeChart = ({
  title,
  value = 0,
  valueFormat,
  height,
  width,
  minimum = 0,
  maximum = 100,
  animate = true,
  resetKey,
}: ChartParams): JSX.Element => {
  const [uuid] = useUUID();

  return (
    <div>
      {title && (
        <h5
          style={{
            color: "#657581",
            marginTop: "1em",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          {title}
        </h5>
      )}
      <CircularGaugeComponent
        id={`gauge-${uuid}`}
        data-testid={`gauge-${uuid}`}
        key={`gauge-${uuid}-container`}
        height={height ?? "300em"}
        width={width ?? "100%"}
      >
        <AxesDirective>
          <AxisDirective
            minimum={minimum}
            maximum={maximum}
            labelStyle={{
              format: valueFormat ?? "{value}",
            }}
          >
            <PointersDirective>
              <PointerDirective
                key={resetKey}
                animation={{
                  enable: animate,
                  duration: 1500,
                }}
                value={value}
                radius="75%"
                cap={{
                  radius: 8,
                  color: "white",
                  border: {
                    color: "#007DD1",
                    width: 5,
                  },
                }}
                color="black"
                pointerWidth={10}
              />
            </PointersDirective>
            <RangesDirective>
              <RangeDirective start={80} end={100} color="green" />
              <RangeDirective start={60} end={80} color="yellow" />
              <RangeDirective start={40} end={60} color={"#fabd43"} />
              <RangeDirective start={20} end={40} color="darkOrange" />
              <RangeDirective start={0} end={20} color="red" />
            </RangesDirective>
          </AxisDirective>
        </AxesDirective>
      </CircularGaugeComponent>
    </div>
  );
};

export default CircularGaugeChart;
