import { useOpenArticle } from "../hooks/useOpenArticle";
import Button from "@mui/material/Button";
import Help from "@mui/icons-material/Help";

/**
 * Button that opens the help center to a specific help center article.
 *
 * @param {string} helpCenterUrl - a full URL to use for the button link, overrides helpCenterRoute
 * @param {string} helpCenterRoute - a route to use for the button link, appends to the base help center url. e.g. 'automation-steps'
 * @param {object} props - all other props passed to the Button component
 * @returns {JSX.Element}
 * @constructor
 */
const OpenArticleButton = ({ helpCenterUrl, helpCenterRoute, ...props }) => {
  const openArticle = useOpenArticle({
    helpCenterRoute,
    helpCenterUrl,
  });

  return (
    <Button
      onClick={openArticle}
      startIcon={<Help />}
      title={`Open Help Article: ${helpCenterRoute || helpCenterUrl}`}
      {...props}
    >
      Help
    </Button>
  );
};

export default OpenArticleButton;
