import { useModal } from "./useModal";
import { Button } from "@mui/material";
import { getResourceDisplayName } from "../../definitions/functions/getResourceDisplayName";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { getResourceConfirmDeleteNote } from "../../definitions/functions/getResourceConfirmDeleteNote";
import { OperationQuantity } from "../../definitions/constants/operationQuantity";
import pluralize from "pluralize";
import ModalBodyWrapper from "../../utils/GenericComponents/Modal/ModalBodyWrapper";

/**
 * A modal confirming the deletion of a resource
 * @param {string} typename - the name of the resource being deleted
 * @param {string} operationQuantity - the amount of resources being deleted (OperationQuantity value)
 * @param {function} onConfirmDelete - function callback if user decides to proceed with the deletion
 * @return {{setModalIsOpen: function(*): void, modalButton: JSX.Element, modalIsOpen: boolean, show: show, modal: JSX.Element, toggleModal: function(): void}}
 */
export const useConfirmDeleteItemModal = ({
  typename,
  operationQuantity = OperationQuantity.SINGLE,
  onConfirmDelete,
}) => {
  let displayTypename = getResourceDisplayName({ typename });
  if (operationQuantity === OperationQuantity.BULK) {
    displayTypename = pluralize(displayTypename);
  }

  const confirmDeleteNote = getResourceConfirmDeleteNote({
    typename,
    operationQuantity,
  });

  //Create a grey button theme
  const theme = createTheme({
    palette: {
      grey: {
        main: "#d9d9d9",
      },
    },
    typography: {
      button: {
        textTransform: "none",
      },
    },
  });

  let modalHeader = `Delete this ${displayTypename}?`;
  if (operationQuantity === OperationQuantity.BULK) {
    modalHeader = `Delete these ${displayTypename}?`;
  }

  const modal = useModal(
    modalHeader,
    <ModalBodyWrapper>{confirmDeleteNote}</ModalBodyWrapper>,
    null,
    { width: "40vw" },
    <div style={{ display: "flex", flexDirection: "row", gap: ".5em" }}>
      <ThemeProvider theme={theme}>
        <Button variant={"outlined"} color={"grey"} style={{ color: "black" }}>
          Cancel
        </Button>
        <Button
          variant={"outlined"}
          color={"error"}
          onClick={() => {
            if (typeof onConfirmDelete === "function") {
              onConfirmDelete();
            }
          }}
        >
          {`Delete ${displayTypename}`}
        </Button>
      </ThemeProvider>
    </div>,
  );

  return {
    ...modal,
    show: () => {
      modal.setModalIsOpen(true);
    },
  };
};
