import { useEffect } from "react";
import { DETAILS_TYPES } from "../enums/DETAILS_TYPES";

/**
 * @description Get configuration for the details bar
 * @param {JSX} detailsBar - Get details bar for UI context
 * @param {function} resetFunction - re-fetch data from the database
 * @param {DETAILS_TYPES} detailsType - details component display style
 * @param {string} detailsTitle - title of the details component
 */
export const useGridConfDetailsBar = ({ detailsBar, resetFunction, detailsType, detailsTitle }) => {
  useEffect(() => {
    detailsBar?.setConfig((config) => {
      const newConfig = {
        ...config,
      };

      if (resetFunction) {
        newConfig.resetFunction = resetFunction;
      }

      if (detailsType && detailsType === DETAILS_TYPES.PANEL && !detailsBar.pinned && detailsTitle) {
        newConfig.title = detailsTitle;
      }

      return {
        ...newConfig,
      };
    });
  }, [detailsBar?.pinned]);
};
