import { Button, ButtonGroup, InputGroupAddon } from "reactstrap";
import React from "react";

/**
 * @description handling changes/updates to a field
 * @param {function} handleSubmit - the function to call when the user clicks the submit button
 * @param {function} handleCancel - the function to call when the user clicks the cancel button
 * @param {function} checkPermissionsHook - the function to call to check if the user has permissions to update the field
 * @param {string} fieldName - the name of the field to update
 * @param {boolean} enableEdit - button whether to enable/or not the edit button
 * @param {function} setEnableEdit - the function to call to enable/disable the edit button
 * @returns {JSX.Element} - the component
 * @constructor - the component
 */
const UpdateFieldAddon = ({
  handleSubmit,
  handleCancel,
  checkPermissionsHook,
  fieldName,
  enableEdit,
  setEnableEdit,
}) => {
  return (
    <InputGroupAddon addonType="append">
      {enableEdit ? (
        <ButtonGroup>
          <Button color="success" title="Submit Changes" onClick={() => handleSubmit()}>
            <i className="icon-check" />
          </Button>
          <Button color="danger" title="Cancel Changes" onClick={() => handleCancel()}>
            <i className="icon-close" />
          </Button>
        </ButtonGroup>
      ) : (
        <Button
          title={
            checkPermissionsHook.resource.update
              ? `Edit Field: ${fieldName}`
              : `You do not have Update Permissions for Field: ${fieldName}`
          }
          onClick={() => setEnableEdit(true)}
          disabled={!checkPermissionsHook.resource.update}
        >
          <i className="icon-pencil" />
        </Button>
      )}
    </InputGroupAddon>
  );
};

export default UpdateFieldAddon;
