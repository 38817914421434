import React from "react";
import { isNonEmptyArray } from "@rivial-security/func-utils";
import { Alert } from "@mui/material";
import FormStep from "./FormStep";

/**
 * Collection of form steps that together represent all the inputs into a useForm hook
 * @param {object[]} fieldConfig - all settings for each input inside the form
 * @param {object} stepper - instance of the stepper hook that the form hook is using
 * @param {object[]} steps - the form steps that the stepper is using
 * @param {object} stepProps - any parameters to pass through to each FormStep component
 * @returns {JSX.Element}
 */
const FormSteps = ({ fieldConfig, stepper, steps, stepProps }) => {
  if (!isNonEmptyArray(steps)) {
    return <Alert severity="error">This form doesn't contain any steps. Please report this as a bug.</Alert>;
  }

  //Only show id field when on step 1
  const showIDField = stepProps?.showIDField && stepper?.activeStep === 0;

  if (steps?.length === 1) {
    return <FormStep id={steps?.id} fieldConfig={fieldConfig} {...{ ...stepProps, showIDField }} />;
  }

  if (steps?.length > 1) {
    return (
      <>
        {steps.map((step, index) => {
          return (
            <FormStep
              id={step?.id}
              isShown={index === stepper?.activeStep}
              fieldConfig={step.fields}
              {...{ ...stepProps, showIDField }}
            />
          );
        })}
      </>
    );
  }
};

export default FormSteps;
