import { generateGraphql } from "@rivial-security/generategraphql";
import { ListQueryBy } from "../../../../utils/Functions/Graphql/ListQueryBy";

const { listQuery: listControls } = generateGraphql("Control", ["statementNumber"]);

/**
 * Author: Jacob Blazina
 * Created At: N/A
 * Edits:
 *  - 7/21/19 JB: Added header signature.
 *
 * Description: An Async Function
 *              Pulls first all Admin controls (100 at a time) from database.
 *              Creates a Map of key(statementNumber) : value(id).
 *              To be able to perform fast lookups on Controls based on Statement Number alone.
 *
 */
export const MapControlStatementNumbersToIds = async (organizationID) => {
  const dictionary = new Map();

  const controls = await ListQueryBy({
    query: listControls,
    variables: {
      ownerGroup: organizationID,
    },
  });

  for (const control of controls) {
    dictionary.set(control.statementNumber, control.id);
  }

  return dictionary;
};
