import { ListQuery } from "../../../../utils/Functions/Graphql/ListQuery";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 * Author: Jacob Blazina
 * Created At: 7/6/2020
 * Description: This function queries all users and checks for an existing user object
 *              based on email or phone number
 *
 * @param {string} userEmail
 * @param {string} userPhoneNumber
 * @param {string} organizationID
 * @returns {Promise<*>}  // returns user object if found, returns false if not found
 */
export const checkForExistingUser = async (userEmail, userPhoneNumber, organizationID) => {
  /**
   *   query all users for email, phone number
   */
  if (organizationID) {
    const { listQuery } = generateGraphql("User", ["email", "phoneNumber"]);

    const users = await ListQuery({
      organizationID,
      query: listQuery,
    });

    /**
     * if user exists, return user object
     */
    for (const user of users) {
      if (user?.email === userEmail) return true;
      if (user?.phoneNumber === userPhoneNumber) return true;
    }
  }

  /**
   * if user does not exist, return false
   */
  return false;
};
