import React from "react";
import SystemTemplateAccordion from "../components/SystemTemplateAccordion";
import { useSystemDetailsQuery } from "../../../Risk/Systems/hooks/useSystemDetails/hooks/useSystemDetailsQuery";
import { modules, resources } from "@rivial-security/role-utils";
import PermissionsOverlay from "../../../../utils/Overlays/PermissionsOverlay";

/**
 * Displays details for a particular System Template item
 * @param itemId
 * @param module
 * @param resource
 * @param disableRoleChecking
 * @param organizationID
 * @returns {{display: JSX.Element}}
 */
export const useSystemTemplateDetails = ({
  itemId,
  module = modules.ADMINISTRATOR,
  resource = resources.SYSTEM_TEMPLATE,
  disableRoleChecking = false,
  organizationID,
}) => {
  /**
   * Fetches System Template Details information
   */
  const { systemDetails, resetFunction, updateItemById } = useSystemDetailsQuery({
    itemId,
    module,
    resource,
    disableRoleChecking,
  });

  const display = (
    <>
      <PermissionsOverlay module={module} resource={resource}>
        <SystemTemplateAccordion
          updateItemById={updateItemById}
          organizationID={organizationID}
          item={systemDetails?.item}
          resetFunction={resetFunction}
          disableRoleChecking={true}
          module={module}
          resource={resource}
        />
      </PermissionsOverlay>
    </>
  );

  return {
    display,
  };
};
