import React, { useState } from "react";
import ControlMappingExportSelectionTiles from "./ControlMappingExportSelectionTiles";
import ControlMappingExportDataGrid from "./ControlMappingExportDataGrid";
import { Alert } from "@mui/material";
import { CONTROL_MAPPING_CONFIG } from "../constants/CONTROL_MAPPING_CONFIG";

/**
 * Displays Selection Tiles and Data Grid for exporting Control Mappings
 *
 * @param {string[]} selectedIDs - IDs of selected Controls to generate a mapping for
 * @returns {JSX.Element}
 * @constructor
 */
const ControlMappingExportBody = ({ selectedIDs = [] }) => {
  const [selectedMappingType, setSelectedMappingType] = useState(null);

  return (
    <div>
      <ControlMappingExportSelectionTiles
        selectedMappingType={selectedMappingType}
        setSelectedMappingType={setSelectedMappingType}
      />
      <hr />
      {selectedMappingType && (
        <Alert
          severity="info"
          style={{
            marginBottom: "1rem",
          }}
        >
          {`${CONTROL_MAPPING_CONFIG[selectedMappingType]?.tooltip} Press the 'Export' button to download a CSV file.`}
        </Alert>
      )}
      <ControlMappingExportDataGrid mappingType={selectedMappingType} selectedIDs={selectedIDs} />
    </div>
  );
};

export default ControlMappingExportBody;
