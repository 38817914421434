import CronDisplay from "../../../../utils/GenericComponents/CronDisplay";
import React, { useEffect, useState } from "react";
import { evidenceExpiringSoonDate } from "../functions/evidenceExpiringSoonDate";
import { useOrganizationConfig } from "../../../AdminPanel/Organizations/hooks/useOrganizationConfig";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { EVIDENCE_TYPES } from "../../../../typedefs/Compliance/Evidence/Evidence";
import CustomTooltip from "../../../../utils/GenericComponents/CustomTooltip";
import parser from "cron-parser";

const EvidenceDueDate = ({ item, organizationID }) => {
  const { value } = useOrganizationConfig({
    organizationID,
    key: "evidenceExpirationThreshold",
  });

  const [frequency, setFrequency] = useState(null);
  useEffect(() => {
    //Test if the new frequency is parsable by cron-parser
    try {
      const newFrequency = item?.frequency;
      new Date(parser.parseExpression(newFrequency).next());
      setFrequency(newFrequency);
    } catch (e) {
      setFrequency(null);
    }
  }, [item?.frequency]);

  const dueDate = evidenceExpiringSoonDate({
    evidence: item,
    evidenceExpirationThreshold: value,
  });

  let actionItemString;
  if (!dueDate) {
    actionItemString = " An Action Item will not be dispatched";
    if (dueDate === false) {
      actionItemString += " because this is a daily evidence.";
    } else if (!dueDate) {
      actionItemString += ".";
    }
  } else {
    actionItemString = ` An Action Item will be dispatched on ${dueDate.toLocaleDateString()} for each
      point of contact to upload evidence before the expiration date.`;
  }

  if (item?.type === EVIDENCE_TYPES.KPI) {
    return (
      <div>
        Not Applicable
        <span className={"float-right"}>
          <CustomTooltip
            tooltip={
              "When the evidence is of the 'Key Performance Indicator' type, it does not expire on a schedule but is automatically updated whenever the attached KPI statuses or linkings change."
            }
          />
        </span>
      </div>
    );
  } else {
    if (frequency) {
      return (
        <div>
          <CronDisplay cron={frequency} />{" "}
          <span className={"float-right"}>
            <CustomTooltip
              tooltip={
                <div>
                  This evidence will switch to 'Not in Place' on {<CronDisplay cron={frequency} />}.{actionItemString}
                </div>
              }
            />
          </span>
        </div>
      );
    } else {
      return <div>Invalid Frequency</div>;
    }
  }
};

export default withOrganizationCheck(EvidenceDueDate);
