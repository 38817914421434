import { useContext, useEffect, useState } from "react";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { MonteCarloContext } from "../../Systems/context/MonteCarloContext";
import { getGreatestRiskCurves } from "@rivial-security/risk-calc-utils";

/**
 * Grabs System Monte Carlo Context and holds the state of inherent loss, residual loss, and the loss curves.
 * @returns {{inherentRisk: number, residualLossCurve: *[], residualRisk: number, inherentLossCurve: *[]}}
 */
export const useMonteCarloLossCurves = () => {
  const monteCarloContext = useContext(MonteCarloContext);

  const [inherentRisk, setInherentLoss] = useState(0);
  const [residualRisk, setResidualLoss] = useState(0);

  const [inherentLossCurve, setInherentLossCurve] = useState([]);
  const [residualLossCurve, setResidualLossCurve] = useState([]);

  const [riskInherentLossCurve, setRiskInherentLossCurve] = useState([]);
  const [riskResidualLossCurve, setRiskResidualLossCurve] = useState([]);

  const [greatestRisk, setGreatestRisk] = useState(null);

  const [systemInherentLoss, setSystemInherentLoss] = useState(0);
  const [systemResidualLoss, setSystemResidualLoss] = useState(0);

  useEffect(() => {
    const systemStats = monteCarloContext?.monteCarloResults?.systemStats;
    if (!isNullOrUndefined(systemStats)) {
      setInherentLoss(systemStats?.inherentRisk);

      // If we want to switch to using average Residual Risk for the Risk Score
      setSystemResidualLoss(systemStats?.residualRisk);
      setSystemInherentLoss(systemStats?.inherentRisk);

      // Set Risk Residual Losses
      setResidualLoss(monteCarloContext?.riskScore?.greatestEnterpriseRisk?.residualRisk);
      setInherentLossCurve(systemStats?.lossExceedanceCurve?.inherentLossCurve);
      setResidualLossCurve(systemStats?.lossExceedanceCurve?.residualLossCurve);
    }
    getRiskCurves();
  }, [monteCarloContext.monteCarloResults]);

  /**
   * Gets loss curve data for the greatest risk KRI
   */
  const getRiskCurves = () => {
    const curves = getGreatestRiskCurves({
      riskScore: monteCarloContext?.riskScore,
      monteCarloResults: monteCarloContext?.monteCarloResults,
    });

    if (curves) {
      setGreatestRisk(curves.greatestKeyRiskIndicator);
      setRiskInherentLossCurve(curves.inherentLossCurve);
      setRiskResidualLossCurve(curves.residualLossCurve);
    }
  };

  return {
    system: {
      inherentLossCurve,
      residualLossCurve,
      inherentRisk: systemInherentLoss,
      residualRisk: systemResidualLoss,
    },
    keyRiskIndicator: {
      ...greatestRisk,
      // inherentRisk: riskInherentLoss,
      residualRisk,
      inherentLossCurve: riskInherentLossCurve,
      residualLossCurve: riskResidualLossCurve,
    },
    inherentRisk,
    residualRisk,
    inherentLossCurve,
    residualLossCurve,
    riskScore: monteCarloContext.riskScore,
    riskInherentLossCurve,
    riskResidualLossCurve,
  };
};
