import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { dequal } from "dequal";
import PropTypes from "prop-types";
import React from "react";

import Context from "./context";
import { freeSoloOperators } from "./freeSoloOperators";

/**
 * @description The props of the Field component.
 * @type {React.NamedExoticComponent<object>}
 */
const Operator = React.memo(
  (props) => {
    const context = React.useContext(Context);
    const { field, id, operator, testId, readonly } = props;

    const { dispatch, filtersByValue, operatorsByType, operatorsByValue, freeSoloFieldInput } = context;

    const filter = field ? filtersByValue[field] : null;
    let options = filter ? operatorsByType[filter.type] : [];
    let value = operator ? operatorsByValue[operator] : null;

    if (freeSoloFieldInput) {
      options = freeSoloOperators;
      if (operator) {
        value = freeSoloOperators.find((option) => option.value === operator);
      }
    }

    return (
      <Autocomplete
        fullWidth
        data-testid={`operator-${testId}`}
        disableClearable={true}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.value === value?.value}
        options={options}
        renderInput={(params) => <TextField {...params} placeholder="Operator" size="small" variant="outlined" />}
        value={value}
        onChange={(event, selected) => {
          const { value } = selected;
          dispatch({ type: "set-operator", id, value });
        }}
        disabled={readonly}
      />
    );
  },
  (prevProps, nextProps) => {
    // Skip re-rendering if the operator didn't change.
    return dequal(prevProps, nextProps);
  },
);

Operator.propTypes = {
  field: PropTypes.string,
  id: PropTypes.number.isRequired,
  operator: PropTypes.string,
  testId: PropTypes.string.isRequired,
  readonly: PropTypes.bool,
};

Operator.whyDidYouRender = false;

export default Operator;
