import DashboardCard from "../../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import React from "react";
import { mitreAttackApiUrl } from "../../../../../env-config";

const countStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#448ab8",
  height: "100%",
  marginTop: "-1em",
};

/**
 * @description Displays Mitre Attack Technique details
 * @param {object} item - Mitre Attack Technique item
 * @returns {{display: JSX.Element}}
 */
export const useMitreAttackTechniqueDetails = ({ item }) => {
  const display = (
    <DashboardCard style={countStyle}>
      <iframe
        title="Mitre Attack Technique Details"
        sandbox="allow-same-origin allow-scripts"
        referrerPolicy="no-referrer"
        style={{ minWidth: "100%", height: "80vh", border: 0 }}
        src={`${mitreAttackApiUrl}/technique/${item?.id}`}
      />
    </DashboardCard>
  );

  return { display };
};
