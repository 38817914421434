import React, { useEffect, useState } from "react";
import { convertCamelCaseToSentence, formattedPercent } from "@rivial-security/func-utils";

const SystemAvailabilityChange = ({ item }) => {
  const [oldAvailability, setOldAvailability] = useState("");
  const [newAvailability, setNewAvailability] = useState("");
  const [ratingScale, setRatingScale] = useState({});

  useEffect(() => {
    const change = item && JSON.parse(item.change);
    const oldAvailability = change && change.oldAvailability ? change.oldAvailability : 0;
    const newAvailability = change && change.newAvailability ? change.newAvailability : 0;

    if (change && change.ratingScale) {
      setRatingScale(change.ratingScale);
    }

    setOldAvailability(oldAvailability);
    setNewAvailability(newAvailability);
  }, []);

  return (
    <td colSpan={2}>
      <h5>Change in Availability Rating</h5>
      <>
        <del>
          {convertCamelCaseToSentence(oldAvailability)}{" "}
          {ratingScale && `(${formattedPercent(ratingScale[oldAvailability], 6)})`}
        </del>{" "}
        <p>
          {convertCamelCaseToSentence(newAvailability)}{" "}
          {ratingScale && `(${formattedPercent(ratingScale[newAvailability], 6)})`}
        </p>
      </>
    </td>
  );
};

export default SystemAvailabilityChange;
