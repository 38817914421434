import React, { useEffect, useState } from "react";
import { convertCamelCaseToSentence, isNullOrUndefined } from "@rivial-security/func-utils";

import CreateEvidence from "../../../Compliance/Evidence/components/CreateEvidence";
import CreateIncident from "../../../Response/Incidents/components/CreateIncident";
import CreateVulnerability from "../../../Testing/Vulnerabilities/components/CreateVulnerability";
import DataLoader from "../../../../utils/LoadingComponents/DataLoader";
import EvidenceDataGrid from "../../../Compliance/Evidence/components/EvidenceDataGrid";
import EvidenceDetails from "../../../Compliance/Evidence/components/EvidenceDetails";
import IncidentDetails from "../../../Response/Incidents/components/IncidentDetails";
import IncidentGrid from "../../../Response/Incidents/components/IncidentGrid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import NestedField from "../../../../utils/GenericComponents/NestedField/NestedField";
import { QueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import VulnerabilityDataGrid from "../../../Testing/Vulnerabilities/components/VulnerabilityDataGrid";
import VulnerabilityDetails from "../../../Testing/Vulnerabilities/components/VulnerabilityDetails";
import { generateGraphql } from "@rivial-security/generategraphql";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

const ActionItemAssociatedResources = ({ item }) => {
  const fields = [
    {
      icon: "icon-badge",
      typename: "Evidence",
      field: "evidence",
      idField: "evidenceID",
      form: <CreateEvidence />,
      grid: <EvidenceDataGrid />,
      detailsComponent: <EvidenceDetails />,
    },
    {
      icon: "icon-fire",
      typename: "Vulnerability",
      field: "vulnerability",
      idField: "vulnerabilityID",
      form: <CreateVulnerability />,
      grid: <VulnerabilityDataGrid />,
      detailsComponent: <VulnerabilityDetails />,
    },
    {
      icon: "icon-flag",
      typename: "Incident",
      field: "incident",
      idField: "incidentID",
      displayField: "what",
      form: <CreateIncident />,
      grid: <IncidentGrid />,
      detailsComponent: <IncidentDetails />,
    },
  ];

  const [hydratedItem, setHydratedItem] = useState(null);

  const [isEnoughData, setIsEnoughData] = useState(false);

  /**
   * Get linked resource
   */
  useEffect(() => {
    const hydrateItem = async () => {
      if (!isNullOrUndefined(item?.data)) {
        const data = JSON.parse(item?.data);

        const res = {
          ...item,
        };

        /**
         * For new types add 3 params "itemId", "typeName" and "field" (field is what you want to display)
         */
        if (data?.evidenceID) {
          const evidence = await QueryGetItem({
            query: generateGraphql("Evidence", ["name", "__typename"]).getQuery,
            itemId: data.evidenceID,
          });

          res.evidenceID = data?.evidenceID;
          res.evidence = evidence;

          evidence && setIsEnoughData(true);
        }

        if (data?.vulnerabilityID) {
          const vulnerability = await QueryGetItem({
            query: generateGraphql("Finding", ["name", "__typename"]).getQuery,
            itemId: data.vulnerabilityID,
          });

          res.vulnerabilityID = data?.vulnerabilityID;
          res.vulnerability = vulnerability;

          vulnerability && setIsEnoughData(true);
        }

        if (data?.incidentID) {
          const incident = await QueryGetItem({
            query: generateGraphql("Incident", ["what", "__typename"]).getQuery,
            itemId: data.incidentID,
          });

          res.incidentID = data?.incidentID;
          res.incident = incident;

          incident && setIsEnoughData(true);
        }

        setHydratedItem(res);
      }
    };

    hydrateItem();
  }, [item]);

  return (
    <div>
      <DataLoader
        isLoading={false}
        isEnoughData={isEnoughData}
        dataMessage={"There are no resources associated with this Action Item"}
      >
        <List>
          {fields.map((field) => {
            if (!isNullOrUndefined(hydratedItem?.[field?.field])) {
              return (
                <ListItem style={{ display: "block" }}>
                  <strong>
                    <i className={field.icon} style={{ marginRight: "1em" }} />
                    {convertCamelCaseToSentence(field.typename)}
                  </strong>
                  <div>
                    <NestedField
                      item={hydratedItem}
                      parentTypename={"GenericActionItem"}
                      disableEdits={true}
                      {...field}
                    />
                  </div>
                </ListItem>
              );
            }
          })}
        </List>
      </DataLoader>
    </div>
  );
};

export default withOrganizationCheck(ActionItemAssociatedResources);
