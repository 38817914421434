"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWidgetMetricData = void 0;
const getWidgetMetricTypes_1 = require("../../metrics/getWidgetMetricTypes");
const getMetricsForMetricType_1 = require("../../metrics/getMetricsForMetricType");
const generategraphql_1 = require("@rivial-security/generategraphql");
const appsync_utils_1 = require("@rivial-security/appsync-utils");
/**
 * Gets all metrics that are listed inside the widget's settings
 * @param {object} config - widget settings (contains id references to metricTypes)
 * @param {object} [metricTypesToIgnore] - optionally provide metrics to not retrieve even if they show up in the settings
 * @return {Promise<object>} - object with metric data with the format of ->  metricTypeId: metrics
 */
const getWidgetMetricData = async ({ config, metricTypesToIgnore = {} } = {}) => {
    console.log("[getWidgetMetricData] at start - ", { config, metricTypesToIgnore });
    //Check arguments
    if (!config) {
        return null;
    }
    //Get the metric types for the widget
    const metricTypesIds = (0, getWidgetMetricTypes_1.getWidgetMetricTypes)({ config });
    console.log("[getWidgetMetricData] after getWidgetMetricTypes - ", { metricTypesIds });
    //Retrieve metrics for each metric type not in the ignored list
    const metricData = {};
    for (const metricTypeId of metricTypesIds) {
        if (metricTypeId) {
            //Check if this metric info needs to be ignored
            if (typeof metricTypesToIgnore === "object" && metricTypesToIgnore[metricTypeId]) {
                continue;
            }
            const { getQuery: getMetricType } = (0, generategraphql_1.generateGraphql)("MetricType", [
                "id",
                "name",
                "description",
                "enabled",
                "frequency",
                "format",
                "calculationType",
                "maxAge",
            ]);
            const metricType = await (0, appsync_utils_1.GetQuery)({
                query: getMetricType,
                variables: {
                    id: metricTypeId,
                },
            });
            metricData[metricTypeId] = {
                id: metricTypeId,
                ...metricType,
                metrics: await (0, getMetricsForMetricType_1.getMetricsForMetricType)({ metricTypeId }),
            };
        }
    }
    console.log("[getWidgetMetricData] at end - ", { metricData });
    return metricData;
};
exports.getWidgetMetricData = getWidgetMetricData;
