import React, { useContext, useEffect, useState } from "react";
import { MonteCarloContext } from "../../../../context/MonteCarloContext";
import RiskCalculatorROIResults from "../customFields/RiskCalculatorROIResults";
import { Card, CardBody, Collapse } from "reactstrap";
import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import DataLoader from "../../../../../../../utils/LoadingComponents/DataLoader";
import { isNullOrUndefined } from "@rivial-security/func-utils";

const ReturnOnInvestment = (props) => {
  const { controlCategory, style } = props;

  const monteCarloContext = useContext(MonteCarloContext);

  const [isOpen, setIsOpen] = useState(true);
  const [riskResult, setRiskResult] = useState(null);

  useEffect(() => {
    if (monteCarloContext.monteCarloResults) {
      const riskStats = monteCarloContext.monteCarloResults.riskStats;

      if (riskStats && controlCategory) {
        const findRiskStat = riskStats.find(
          (item) => item.controlCategory && item.controlCategory.name === controlCategory.name,
        );
        setRiskResult(findRiskStat);
      }
    }
  }, [monteCarloContext.monteCarloResults, monteCarloContext.monteCarloInput]);

  return (
    <div style={{ ...style }}>
      <strong style={{ marginRight: "1em" }}>
        Return On Investment <i className="icon-question" title="ROI of all Sub-Controls" />
        <i
          className={!isOpen ? "fa fa-eye-slash" : "fa fa-eye"}
          title={`${isOpen ? "Hide" : "Show"} ROI Information`}
          style={{ cursor: "pointer", marginLeft: "1em" }}
          onClick={() => setIsOpen(!isOpen)}
        />
      </strong>
      <Collapse isOpen={isOpen}>
        <DataLoader
          isLoading={monteCarloContext?.isLoading}
          isEnoughData={!isNullOrUndefined(riskResult)}
          dataMessage={"Could not calculate Monte Carlo Return on Investment"}
        >
          <Card>
            <CardBody>
              <RiskCalculatorROIResults item={riskResult} />
            </CardBody>
          </Card>
        </DataLoader>
      </Collapse>
    </div>
  );
};

export default withOrganizationCheck(ReturnOnInvestment);
