import React from "react";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useQueryCard } from "../../../../hooks/views/useQueryCard";
import { useCreateMetricType } from "./useCreateMetricType";
import Metrics from "../customFields/Metrics";
import MetricTypeDetails from "../components/MetricTypeDetails";
import MetricAutomation from "../customFields/MetricAutomation";
import { modules, resources } from "@rivial-security/role-utils";

export const useMetricType = ({ organizationID, dataCardConfig, queryConfig, config }) => {
  const module = modules.METRICS;
  const resource = resources.METRIC_TYPE;
  const typename = "MetricType";

  const fields = ["name", "metrics", "automation"];

  const nestedFields = {
    metrics: "(limit: 200) {items {id name description date data { value format }}}",
    automation: `{ name description jsonPath config fetchConfig { url apiKey } }`,
  };

  const customFields = [
    {
      field: "metrics",
      component: <Metrics />,
    },
    {
      field: "automation",
      component: <MetricAutomation />,
    },
  ];

  const createMetricTypeForm = useCreateMetricType({
    organizationID,
    fields,
    nestedFields,
  });

  const { deleteMutation } = generateGraphql(typename, fields, nestedFields);

  queryConfig = {
    organizationID,
    fields: ["name", "metrics", "automation", "nextDate", "enabled", "frequency"],
    ...queryConfig,
  };

  dataCardConfig = {
    customFields,
    options: ["details", "delete"],
    module,
    resource,
    deleteMutation: deleteMutation,
    createResourceComponent: createMetricTypeForm.display,
    detailsComponent: <MetricTypeDetails />,
    header: "Organization Metrics",
    route: "#/metrics/metrics/",
    ...dataCardConfig,
  };

  const queryCard = useQueryCard({
    queryConfig,
    dataCardConfig,
    config,
    module,
    resource,
    fields,
    typename,
    nestedFields,
  });

  return {
    ...queryCard,
  };
};
