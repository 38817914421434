import React, { useEffect, useState } from "react";

import { gridExpandedSortedRowEntriesSelector } from "@mui/x-data-grid-pro";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Handles the state and handlers for DataGrid selected items
 * Also for the 'Next' and 'Previous' functions used for the details modal
 * @param {apiRef} apiRef - the DataGridPro apiRef
 * @param {boolean} [ enableSelection = false ] - if true will enable checkbox selection by default
 * @param {function} onSelectedDataChange - callback function for when the selected data changes
 */
export const useDataGridSelectedItems = ({ apiRef, enableSelection = false, onSelectedDataChange = () => {} }) => {
  // Holds current selection state for the grid
  // Note, this is an array of IDs
  const [selectedIDs, setSelectedIDs] = React.useState([]);

  // Holds the full objects that are selected, based on ID array
  const [selectedData, setSelectedData] = useState([]);

  const [hasNext, setHasNext] = useState(false);
  const [hasPrevious, setHasPrevious] = useState(false);

  const [detailsItem, setDetailsItem] = useState(null);

  // Determines if the grid displays select boxes based on naturally selecting more than one item, or deselecting all items
  const [checkboxSelection, setCheckboxSelection] = useState(enableSelection);

  // Sets the Previous and Next buttons for the details modal when the selected item changes
  useEffect(() => {
    if (!isNullOrUndefined(detailsItem)) {
      // note: if pagination is enabled this needs to be 'gridPaginatedVisibleSortedGridRowEntriesSelector'
      // https://mui.com/components/data-grid/state/#catalog-of-selectors
      const visibleRows = gridExpandedSortedRowEntriesSelector(apiRef);

      if (visibleRows.length === 0) {
        return;
      }

      const foundIndex = visibleRows.findIndex((row) => row.id === detailsItem?.id);

      if (!isNullOrUndefined(visibleRows[foundIndex - 1])) {
        setHasPrevious(true);
      } else {
        setHasPrevious(false);
      }

      if (!isNullOrUndefined(visibleRows[foundIndex + 1])) {
        setHasNext(true);
      } else {
        setHasNext(false);
      }
    }
  }, [detailsItem]);

  /**
   * Adjusts the selected item index by a certain amount,
   * and sets the currently saved 'detailsItem' state for the details Modal functionality
   * @param diff
   */
  const adjustSelectedItemIndex = (diff = 1) => {
    // note: if pagination is enabled this needs to be 'gridPaginatedVisibleSortedGridRowEntriesSelector'
    // https://mui.com/components/data-grid/state/#catalog-of-selectors
    const visibleRows = gridExpandedSortedRowEntriesSelector(apiRef);

    if (visibleRows.length === 0) {
      return;
    }

    const foundIndex = visibleRows.findIndex((row) => row.id === detailsItem?.id);

    if (!isNullOrUndefined(visibleRows[foundIndex + diff])) {
      apiRef.current.selectRow(visibleRows[foundIndex + diff].id, true);
      apiRef.current.selectRow(visibleRows[foundIndex].id, false);
      setDetailsItem(visibleRows[foundIndex + diff]?.model);
    }
  };

  /**
   * Handles updating the 'selectedData' array when the selectedIDs change
   */
  useEffect(() => {
    if (!isNullOrUndefined(selectedIDs) && Array.isArray(selectedIDs)) {
      if (selectedIDs.length === 0) {
        setSelectedData([]);
        return;
      }

      // get data straight from API ref to make sure it's valid.
      const apiRefDataMap = apiRef.current.getRowModels();

      // convert the map object to an array.
      const internalData = Array.from(apiRefDataMap.values());

      if (Array.isArray(internalData) && Array.isArray(selectedIDs)) {
        // get an array of selected rows based on the selected IDs
        const resultData = internalData.filter((item) => selectedIDs.some((id) => id === item.id));
        setSelectedData(resultData);
      }
    }
  }, [selectedIDs]);

  /**
   * Calls selected data callback function when selected data changes
   */
  useEffect(() => {
    if (typeof onSelectedDataChange === "function") {
      onSelectedDataChange(selectedData);
    }
  }, [selectedData]);

  return {
    selectedIDs,
    setSelectedIDs,
    selectedData,
    setSelectedData,
    hasNext,
    hasPrevious,
    adjustSelectedItemIndex,
    detailsItem,
    setDetailsItem,
    checkboxSelection,
    setCheckboxSelection,
  };
};
