import { InvalidParamError } from "../../../../analytics/CustomError";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { generateGraphql } from "@rivial-security/generategraphql";
import { generateVendorReviewName } from "./generateVendorReviewName";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Creates a Vendor Review in the database.
 * A Vendor Review must be attached to a Vendor Solution
 * @param {object} vendorSolution - the VendorSolution to attach this Vendor Review to.
 * @param {string} organizationID - the organizationID for this vendor review
 * @param {object} [input] - optional inputs to pass to the mutation
 * @returns {Promise<*>}
 */
export const handleVendorReviewMutation = async (vendorSolution, organizationID, input = {}) => {
  if (isNullOrUndefined(organizationID)) {
    throw new InvalidParamError("organizationID", "cannot be null or undefined");
  }

  if (isNullOrUndefined(vendorSolution)) {
    throw new InvalidParamError("vendorSolution", "cannot be null or undefined");
  }

  if (isNullOrUndefined(vendorSolution.id)) {
    throw new InvalidParamError("vendorSolution.id", "cannot be null or undefined");
  }

  if (isNullOrUndefined(vendorSolution.name)) {
    throw new InvalidParamError("vendorSolution.name", "cannot be null or undefined");
  }

  const { createMutation } = generateGraphql("VendorReview");

  return await ItemMutation(createMutation, {
    name: generateVendorReviewName(vendorSolution),
    vendorSolutionID: vendorSolution.id,
    status: "draft",
    allowPushToQuantivate: false,
    ownerGroup: organizationID,
    ...input,
  });
};
