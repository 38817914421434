import { useSubscription } from "../../../../hooks/graphql/useSubscription";
import React from "react";
import AddSinglePointOfContact from "../components/AddSinglePointOfContact";
import PointOfContactDetails from "../components/PointOfContactDetails";
import DeleteContact from "../functions/DeleteContact";
import { useQueryCard } from "../../../../hooks/views/useQueryCard";
import { CustomPointOfContactEmailField } from "../customFields/CustomPointOfContactEmailField";
import { CustomPointOfContactPhoneField } from "../customFields/CustomPointOfContactPhoneField";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

const {
  listQuery: listPointOfContacts,
  onDelete: onDeletePointOfContact,
  onUpdate: onUpdatePointOfContact,
  onCreate: onCreatePointOfContact,
  updateMutation: updatePointOfContact,
} = generateGraphql("PointOfContact", ["firstName", "lastName", "title", "email", "phone", "meetings", "user"], {
  meetings: `(limit: 50) { items { id } } `,
  user: `{ id username name roleLinks { items { id } } }`,
});

/**
 * @deprecated see 'usePointOfContactGrid.js'
 * @param organizationID
 * @param showSelectBoxes
 * @param selectionType
 * @param buttonCallback
 * @param fields
 * @param options
 * @param header
 * @returns {{setQueryFilters: function(*=): void, tableDisplay: JSX.Element, data: Object[], setOtherFilters: function(*=): void, clearFilters: function(): void, searchResults: Object[], setSelectionType: (value: (((prevState: Object.selectionType) => Object.selectionType) | Object.selectionType)) => void, setEnableQuery: (value: (((prevState: boolean) => boolean) | boolean)) => void, selectionType: Object.selectionType, showSelectBoxes: unknown, enableQuery: boolean, setPaginatedData: function(*=): void, resetFunction: function(*=): void, setItems: function(*=): void, options: string[], limit: unknown, sortFields: [], paginatedData: [], setSortField: function(*=, *=): void, setMiniLoading: (value: (((prevState: boolean) => boolean) | boolean)) => void, setData: function(*=): void, display: JSX.Element, miniLoading: boolean, setFields: (value: (((prevState: *) => *) | *)) => void, tableData: Object[], filters: [], loading: boolean, list: [], setList: (value: (((prevState: []) => []) | [])) => void, listPageArray: [], isLoading: boolean, setSelectedItems: (value: (((prevState: []) => []) | [])) => void, setShowSelectBoxes: (value: unknown) => void, setLoading: (value: (((prevState: boolean) => boolean) | boolean)) => void, setLimit: (value: unknown) => void, setFilters: (value: (((prevState: []) => []) | [])) => void, nextToken: null, addFilter: function(*=): void, reset: function(*=): void, setFullData: (value: (((prevState: []) => []) | [])) => void, fields: *, setOrganizationId: function(*=): void, selectedItems: []}}
 */
export const usePointOfContactList = ({
  organizationID,
  showSelectBoxes,
  selectionType,
  buttonCallback,
  fields,
  options,
  header,
}) => {
  const module = modules.ORGANIZATION_MANAGER;
  const resource = resources.POINT_OF_CONTACT;

  const pointsOfContactSubscriptionHook = useSubscription({
    onCreateSubscription: onCreatePointOfContact,
    onUpdateSubscription: onUpdatePointOfContact,
    onDeleteSubscription: onDeletePointOfContact,
    module,
    resource,
  });

  const queryConfig = {
    query: listPointOfContacts,
    organizationID: organizationID,
    subscriptionHook: pointsOfContactSubscriptionHook,
  };

  const customFields = [
    {
      field: "email",
      component: <CustomPointOfContactEmailField module={module} resource={resource} />,
    },
    {
      field: "phone",
      component: <CustomPointOfContactPhoneField module={module} resource={resource} />,
    },
  ];

  const dataCardConfig = {
    data: [],
    fields: fields || ["firstName", "lastName", "title", "email", "phone"],
    customFields,
    exportTable: true,
    options: options || ["details", "delete"],
    header: header || "Points of Contact",
    fieldNameDictionary: {
      id: "ID",
      firstName: "First Name",
      lastName: "Last Name",
      title: "Title",
      email: "Email",
      phone: "Phone #",
    },
    createResourceComponent: <AddSinglePointOfContact organizationID={organizationID} />,
    detailsComponent: <PointOfContactDetails />,
    route: "#/organization_manager/contacts/",
    config: {
      showSelectBoxes: showSelectBoxes,
      selectionType: selectionType,
      buttonCallback: buttonCallback,
    },
    updateMutation: updatePointOfContact,
    deleteFunction: DeleteContact,
  };

  const queryCard = useQueryCard({
    queryConfig,
    dataCardConfig,
    module,
    resource,
  });

  return { ...queryCard };
};
