"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.applyRuleOnArray = void 0;
const lodash_1 = require("lodash");
const func_utils_1 = require("@rivial-security/func-utils");
/**
 * Applies a filter rule object from MUI builder onto array of objects
 * @param {object[]} items - the items to filter
 * @param {string} field - a field or nested field to filter on
 * @param {string} operator - an operator to use for the filtering
 * @param {string} filterValue - the value to filter by
 * @returns {*|*[]}
 */
const applyRuleOnArray = ({ items, field, operator, filterValue }) => {
    if (!operator || !filterValue || !field) {
        console.log("[applyRuleOnArray] - Invalid filter object - ", { filterValue });
        return items;
    }
    if (!Array.isArray(items)) {
        console.log("[applyRuleOnArray] - Invalid data array - ", { items });
        return items;
    }
    const newItems = [];
    const numericOperators = ["=", "!=", ">", ">=", "<", "<="];
    const isNumericOperation = numericOperators.includes(operator);
    for (const item of items) {
        let rowValue = (0, lodash_1.get)(item, field);
        let caseInsensitiveFilterValue;
        if (isNumericOperation) {
            try {
                rowValue = parseFloat(rowValue);
            }
            catch (e) {
                console.log("[applyRuleOnArray] - Error parsing row value to float - ", { rowValue });
            }
            try {
                filterValue = parseFloat(filterValue);
            }
            catch (e) {
                console.log("[applyRuleOnArray] - Error parsing filter value to float - ", { filterValue });
            }
        }
        else {
            rowValue = !(0, func_utils_1.isNullOrUndefined)(rowValue) ? String(rowValue).toLowerCase() : "";
            caseInsensitiveFilterValue = String(filterValue).toLowerCase();
        }
        console.log("[applyRuleOnArray] -", { operator, filterValue, rowValue, item });
        switch (operator) {
            case "equals":
                if (rowValue === caseInsensitiveFilterValue)
                    newItems.push(item);
                break;
            case "notEquals":
                if (rowValue !== caseInsensitiveFilterValue)
                    newItems.push(item);
                break;
            case "contains":
                if (rowValue.includes(caseInsensitiveFilterValue))
                    newItems.push(item);
                break;
            case "notContains":
                if (!rowValue.includes(caseInsensitiveFilterValue))
                    newItems.push(item);
                break;
            case "startsWith":
                if (rowValue.startsWith(caseInsensitiveFilterValue))
                    newItems.push(item);
                break;
            case "endsWith":
                if (rowValue.endsWith(caseInsensitiveFilterValue))
                    newItems.push(item);
                break;
            case "isEmpty":
                if (!rowValue)
                    newItems.push(item);
                break;
            case "isNotEmpty":
                if (rowValue)
                    newItems.push(item);
                break;
            case "isAnyOf":
                if (Array.isArray(filterValue) && filterValue.some((v) => String(v).toLowerCase() === rowValue)) {
                    newItems.push(item);
                }
                break;
            case "=":
                if (rowValue === filterValue) {
                    newItems.push(item);
                }
                break;
            case "!=":
                if (rowValue !== filterValue) {
                    newItems.push(item);
                }
                break;
            case ">":
                if (rowValue > filterValue) {
                    newItems.push(item);
                }
                break;
            case ">=":
                if (rowValue >= filterValue) {
                    newItems.push(item);
                }
                break;
            case "<":
                if (rowValue < filterValue) {
                    newItems.push(item);
                }
                break;
            case "<=":
                if (rowValue <= filterValue) {
                    newItems.push(item);
                }
                break;
            default:
                newItems.push(item);
        }
    }
    return newItems;
};
exports.applyRuleOnArray = applyRuleOnArray;
