import { ErrorLogger } from "@utils/EventLogger";
import { ItemMutation } from "../../../../../utils/Functions/Graphql/ItemMutation";
import { deleteSystemControlCategoryLink_minimal } from "./deleteFunctionGQL";

export const DeleteSystemControlCategoryLink = async (systemControlCategoryLink) => {
  if (!systemControlCategoryLink) {
    ErrorLogger("[DeleteSystemControlCategoryLink.js] Error: No systemControlCategoryLink was passed in");
    return null;
  }

  // Delete the systemControlCategoryLink
  return await ItemMutation(deleteSystemControlCategoryLink_minimal, {
    id: systemControlCategoryLink.id,
  });
};
