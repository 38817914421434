import { FormFeedback, FormText, Input, InputGroup, InputGroupAddon } from "reactstrap";
import React, { useState } from "react";

import CustomCheckboxes from "./CustomCheckboxes";
import CustomColorPicker from "./CustomColorPicker";
import CustomDatePicker from "./CustomDatePicker";
import CustomDateRangeInput from "./CustomDateRangeInput/CustomDateRangeInput";
import CustomDropdown from "./CustomDropdown";
import CustomEmailInput from "./CustomEmailInput";
import CustomFileInput from "./CustomFileInput";
import CustomImagePicker from "./CustomImagePicker";
import CustomItemSelect from "./CustomItemSelect";
import CustomJsonInput from "./CustomJsonInput";
import CustomMultiSelect from "./CustomMultiSelect";
import CustomPasswordInput from "./CustomPasswordInput";
import CustomPhoneNumberInput from "./CustomPhoneNumberInput";
import CustomRadioButtonGroup from "./CustomRadioButtonGroup";
import CustomRichTextEditor from "./CustomRichTextEditor";
import CustomSpreadsheetInput from "./CustomSpreadsheetInput";
import CustomStringArrayInput from "./CustomStringArrayInput";
import CustomSwitchInput from "./CustomSwitchInput";
import CustomURLInput from "./CustomURLInput";
import { FORM_INPUT_TYPES } from "../enums/FORM_INPUT_TYPES";
import { Icon } from "@iconify/react";
import OverridesButton from "./OverridesButton";
import OverridesInput from "./OverridesInput";
import { processTextPastedIntoInput } from "../../../../utils/Functions/String/processTextPastedIntoInput";
import { v4 as uuid } from "uuid";

/**
 * Custom Input component for use in the useForm hook to display an input
 * @param idParam
 * @param input
 * @param setInput
 * @param fieldName
 * @param inputType
 * @param required
 * @param validationFunction
 * @param validationText
 * @param warningFunction
 * @param numberConfig
 * @param dropdownConfig
 * @param fileInputConfig
 * @param itemSelectConfig
 * @param multiSelectConfig
 * @param dateRangeConfig
 * @param customConfig
 * @param passwordConfig
 * @param spreadsheetConfig
 * @param jsonConfig
 * @param placeholder
 * @param disabled
 * @param defaultValue
 * @param isValid
 * @param isInvalid
 * @param inputGroupAddon
 * @param switchConfig
 * @param dateConfig
 * @param onChangeFunction
 * @param richTextEditorConfig
 * @param rows
 * @param onKeyPress
 * @param label
 * @param tooltip
 * @param typename
 * @param showOverridesButton
 * @param onInputIsValidChange
 * @param options
 * @return {JSX.Element}
 */
const CustomInput = ({
  componentId: idParam,
  input,
  setInput,
  fieldName,
  inputType = "text",
  required,
  validationFunction,
  validationText,
  warningFunction,
  numberConfig,
  dropdownConfig,
  fileInputConfig,
  itemSelectConfig = {},
  multiSelectConfig = {},
  customConfig = {},
  passwordConfig = {},
  spreadsheetConfig = {},
  jsonConfig = {},
  inputConfig = {},
  placeholder,
  disabled,
  defaultValue,
  isValid,
  isInvalid,
  inputGroupAddon,
  switchConfig,
  dateConfig,
  dateRangeConfig,
  onChangeFunction,
  richTextEditorConfig = {},
  rows,
  onKeyPress,
  label,
  tooltip,
  typename,
  showOverridesButton,
  options,
  onInputIsValidChange,
}) => {
  const onChange = (value) => {
    const newInput = { ...input };
    newInput[fieldName] = value;
    setInput({ ...newInput });
    onChangeFunction && onChangeFunction(newInput);
    return newInput;
  };

  const [componentId] = useState(idParam || uuid());

  const [showOverrideInput, setShowOverrideInput] = useState(false);

  const supportedOverrideTypes = [
    "dropdown",
    "custom",
    "item-select",
    "multi-select",
    "switch",
    "color-picker",
    "date",
    "json",
    "password",
    "phoneNumber",
    "email",
    "number",
    "rich-text-editor",
  ];

  return (
    <>
      {showOverridesButton && (
        <OverridesButton
          showOverrideInput={showOverrideInput}
          setShowOverrideInput={setShowOverrideInput}
          input={input}
          fieldName={fieldName}
          supportedOverrideTypes={supportedOverrideTypes}
          inputType={inputType}
          required={required}
          validationFunction={validationFunction}
        />
      )}

      {showOverrideInput && <OverridesInput input={input} onChange={onChange} fieldName={fieldName} />}

      {!showOverrideInput &&
        (() => {
          switch (inputType) {
            case FORM_INPUT_TYPES.CHECKBOXES:
              return (
                <CustomCheckboxes
                  onChange={(value) => onChange(value)}
                  value={input[fieldName]}
                  inputConfig={inputConfig}
                />
              );
            case FORM_INPUT_TYPES.CUSTOM:
              return React.cloneElement(customConfig.component, {
                id: `customField${fieldName}`,
                fieldName,
                placeholder,
                input,
                setInput,
                value: input[fieldName],
                onChangeCallback: onChange,
                disabled,
                valid: isValid,
                invalid: isInvalid,
                "data-testid": `useForm-custom-input-${fieldName}`,
                onInputIsValidChange,
              });
            case FORM_INPUT_TYPES.ITEM_SELECT:
              return (
                <CustomItemSelect
                  value={input[fieldName]}
                  disabled={disabled}
                  onChange={(value) => onChange(value)}
                  itemSelectConfig={itemSelectConfig}
                  typename={typename}
                />
              );
            case FORM_INPUT_TYPES.MULTI_SELECT:
              return (
                <CustomMultiSelect
                  value={input[fieldName]}
                  disabled={disabled}
                  onChange={(value) => onChange(value)}
                  multiSelectConfig={multiSelectConfig}
                  typename={typename}
                />
              );
            case FORM_INPUT_TYPES.RADIO_BUTTON_GROUP:
              return (
                <CustomRadioButtonGroup
                  value={input[fieldName]}
                  onChange={(value) => onChange(value)}
                  options={options}
                  defaultValue={defaultValue}
                />
              );
            case FORM_INPUT_TYPES.RICH_TEXT_EDITOR:
              return (
                <CustomRichTextEditor
                  placeholder={placeholder}
                  value={input[fieldName]}
                  onChange={(value) => onChange(value)}
                  disabled={disabled}
                  valid={isValid}
                  invalid={isInvalid}
                  autoComplete="new-password"
                  richTextEditorConfig={{
                    enableEdits: true,
                    disableEditButtons: true,
                    ...(richTextEditorConfig || {}),
                  }}
                />
              );
            case FORM_INPUT_TYPES.SWITCH:
              return (
                <CustomSwitchInput
                  value={input[fieldName]}
                  onChange={(value) => onChange(value)}
                  disabled={disabled}
                  switchConfig={switchConfig}
                  style={switchConfig?.style}
                />
              );
            case FORM_INPUT_TYPES.DROPDOWN:
              return (
                <CustomDropdown
                  fieldName={fieldName}
                  type={inputType}
                  placeholder={placeholder}
                  value={input[fieldName]}
                  defaultValue={input[fieldName]}
                  onChange={(value) => onChange(value)}
                  dropdownConfig={dropdownConfig}
                  disabled={disabled}
                  valid={isValid}
                  invalid={isInvalid}
                  autoComplete="new-password"
                  showOverridesButton={showOverridesButton}
                  input={input}
                />
              );
            case FORM_INPUT_TYPES.COLOR_PICKER:
              return <CustomColorPicker value={input[fieldName]} onChange={(value) => onChange(value)} />;
            case FORM_INPUT_TYPES.IMAGE:
              return <CustomImagePicker value={input[fieldName]} onChange={(value) => onChange(value)} />;
            case FORM_INPUT_TYPES.DATE:
              return (
                <CustomDatePicker
                  defaultValue={defaultValue}
                  value={input[fieldName]}
                  onChange={(value) => onChange(value)}
                  dateConfig={dateConfig}
                  fieldName={fieldName}
                />
              );
            case FORM_INPUT_TYPES.FILE:
              return (
                <CustomFileInput
                  value={input[fieldName]}
                  onChange={(value) => onChange(value)}
                  required={required}
                  fileInputConfig={fileInputConfig}
                />
              );
            case FORM_INPUT_TYPES.SPREADSHEET:
              return (
                <CustomSpreadsheetInput
                  value={input[fieldName]}
                  onChange={(value) => onChange(value)}
                  required={required}
                  spreadsheetConfig={spreadsheetConfig}
                />
              );
            case FORM_INPUT_TYPES.JSON:
              return (
                <CustomJsonInput
                  value={input[fieldName]}
                  onChange={onChange}
                  jsonConfig={jsonConfig}
                  field={fieldName}
                  label={label}
                  tooltip={tooltip}
                />
              );
            case FORM_INPUT_TYPES.PASSWORD:
              return (
                <CustomPasswordInput
                  value={input[fieldName]}
                  defaultValue={defaultValue}
                  onChange={onChange}
                  field={fieldName}
                  label={label}
                  tooltip={tooltip}
                  passwordConfig={passwordConfig}
                />
              );
            case FORM_INPUT_TYPES.PHONE_NUMBER:
              return (
                <CustomPhoneNumberInput
                  value={input[fieldName]}
                  onChange={onChange}
                  fieldName={fieldName}
                  label={label}
                  tooltip={tooltip}
                  required={required}
                />
              );
            case FORM_INPUT_TYPES.EMAIL:
              return (
                <CustomEmailInput
                  value={input[fieldName]}
                  onChange={onChange}
                  fieldName={fieldName}
                  label={label}
                  tooltip={tooltip}
                  required={required}
                  validationFunction={validationFunction}
                />
              );
            case FORM_INPUT_TYPES.URL:
              return (
                <CustomURLInput
                  value={input[fieldName]}
                  onChange={onChange}
                  fieldName={fieldName}
                  required={required}
                  onInputIsValidChange={onInputIsValidChange}
                />
              );
            case FORM_INPUT_TYPES.DATE_RANGE:
              return (
                <CustomDateRangeInput
                  value={input[fieldName]}
                  onChange={onChange}
                  fieldName={fieldName}
                  label={label}
                  tooltip={tooltip}
                  required={required}
                  dateRangeConfig={dateRangeConfig}
                />
              );
            case FORM_INPUT_TYPES.STRING_ARRAY:
              return (
                <CustomStringArrayInput
                  value={input[fieldName]}
                  onChange={onChange}
                  fieldName={fieldName}
                  label={label}
                  tooltip={tooltip}
                  required={required}
                  placeholder={placeholder}
                />
              );
            default: {
              const inputComponent = (
                <Input
                  id={fieldName + componentId}
                  type={inputType}
                  placeholder={placeholder}
                  value={input[fieldName]}
                  onChange={(e) => onChange(e.target.value)}
                  onPaste={(e) => {
                    const resultText = processTextPastedIntoInput({
                      e,
                      value: input[fieldName],
                    });
                    onChange(resultText);
                  }}
                  disabled={disabled}
                  valid={isValid}
                  invalid={isInvalid}
                  autoComplete="none"
                  data-testid={`useForm-input-${fieldName}`}
                  rows={rows}
                  onKeyPress={onKeyPress}
                  min={numberConfig?.min}
                  max={numberConfig?.max}
                  step={numberConfig?.step}
                />
              );

              return (
                <>
                  {(() => {
                    if (inputGroupAddon) {
                      return (
                        <InputGroup>
                          {inputGroupAddon.addonType === "prepend" && (
                            <InputGroupAddon addonType="prepend">{inputGroupAddon.addonComponent}</InputGroupAddon>
                          )}
                          {inputComponent}
                          {inputGroupAddon.addonType === "append" && (
                            <InputGroupAddon addonType="append">{inputGroupAddon.addonComponent}</InputGroupAddon>
                          )}
                        </InputGroup>
                      );
                    } else {
                      return inputComponent;
                    }
                  })()}
                </>
              );
            }
          }
        })()}

      {typeof warningFunction === "function" && warningFunction(input[fieldName]) && (
        <FormText color="warning" style={{ fontSize: "1em" }}>
          <Icon icon="ant-design:warning-outlined" width="15" height="15" /> {warningFunction(input[fieldName])}
        </FormText>
      )}

      {isInvalid && (
        <FormFeedback className={"d-block"}>
          {required && (input[fieldName] === "" || input[fieldName] === null || input[fieldName] === undefined)
            ? "This is a required field"
            : validationText}
        </FormFeedback>
      )}
    </>
  );
};

export default CustomInput;
