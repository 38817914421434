import React, { useContext, useState, useEffect } from "react";
import { Button } from "reactstrap";
import { OrganizationContext } from "../../../../utils/Context/OrganizationContext";
import { useSubscription } from "../../../../hooks/graphql/useSubscription";
import AddSingleRole from "../components/AddSingleRole";
import DeleteRole from "../functions/deleteRole";
import RoleDetailsCard from "../components/RoleDetailsCard";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { useQueryCard } from "../../../../hooks/views/useQueryCard";
import { generateGraphql } from "@rivial-security/generategraphql";

const {
  listQuery: listRoles,
  onDelete: onDeleteRole,
  onUpdate: onUpdateRole,
  onCreate: onCreateRole,
  createMutation: createRole,
  updateMutation: updateRole,
} = generateGraphql("Role", ["name", "roleConfig", "__typename"]);

/**
 * Displays a list of Roles for an organization.
 *
 * @param {string} organizationID - the Organization ID to display the role for
 * @param {boolean} [isAdminRole=false] - boolean indicating that this is an Admin-Level role (e.g. used for Role Templates)
 * @param {object} config - configuration object for the dataCardConfig.config param
 * @returns {{display, paginatedData, setOrganizationId, loading, setLoading, data, tableData, selectedItems, setSelectedItems, setItems, showSelectBoxes, setShowSelectBoxes, addFilter, filters, setFilters, setQueryFilters}}
 */
export const useRoleList = ({ organizationID, isAdminRole = false, config = {} }) => {
  const module = "organizationManager";
  const resource = "role";

  const [enableDuplicate, setEnableDuplicate] = useState(false);

  const context = useContext(OrganizationContext);

  const getSubscription = useSubscription({
    onCreateSubscription: onCreateRole,
    onUpdateSubscription: onUpdateRole,
    onDeleteSubscription: onDeleteRole,
  });

  const queryConfig = {
    query: listRoles,
    organizationID: isAdminRole ? "Admin" : organizationID,
    subscriptionHook: getSubscription,
    module: module,
    resource: resource,
  };

  const dataCardConfig = {
    header: isAdminRole ? "Admin Roles List" : "Roles List",
    fields: ["name"],
    options: ["details", "delete"],
    headerButtons: [
      <>
        <Button
          size="sm"
          color={enableDuplicate ? "success" : "ghost-primary"}
          className="btn-pill"
          onClick={() => {
            if (enableDuplicate) {
              duplicateRole();
            } else {
              setEnableDuplicate(true);
            }
          }}
        >
          <i className="icon-people" /> {enableDuplicate ? "Duplicate Selected Roles" : "Duplicate Roles"}
        </Button>
        {enableDuplicate && (
          <Button color="danger" onClick={() => setEnableDuplicate(false)}>
            Cancel
          </Button>
        )}
      </>,
    ],
    deleteFunction: DeleteRole,
    fieldNameDictionary: {
      id: "ID",
      name: "Name",
    },
    createResourceComponent: <AddSingleRole isAdminRole={isAdminRole} />,
    detailsComponent: <RoleDetailsCard />,
    route: organizationID === "Admin" ? `#/admin_panel/roles/` : `#/organization_manager/roles/`,
    module,
    resource,
    updateMutation: updateRole,
    config: {
      showSelectBoxes: true,
      ...config,
    },
  };

  const roleQueryCard = useQueryCard({
    queryConfig,
    dataCardConfig,
    module,
    resource,
  });

  useEffect(() => {
    if (enableDuplicate) {
      roleQueryCard.setShowSelectBoxes(true);
    } else {
      roleQueryCard.setShowSelectBoxes(false);
    }
  }, [enableDuplicate]);

  const duplicateRole = () => {
    if (roleQueryCard.selectedItems && roleQueryCard.selectedItems.length > 0) {
      if (window.confirm(`Are you sure you want to duplicate selected Roles?`)) {
        for (const role of roleQueryCard.selectedItems) {
          ItemMutation(createRole, {
            name: role && role.name ? `${role.name} Duplicated` : "New Role",
            roleConfig: role && role.roleConfig ? role.roleConfig : undefined,
            ownerGroup: context && context.selectedOrganization ? context.selectedOrganization : undefined,
          });
        }
        alert("Roles Successfully Duplicated");
      }
    } else {
      alert("Please select Roles from the list!");
    }
  };

  return { ...roleQueryCard };
};
