import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useEffect } from "react";

/**
 * @description Set item details route
 * @param {JSX} detailsBar - Get details bar for UI context
 * @param {string} route - route for the grid data type
 * @param {object} detailsItem - item to show in the details modal
 */
export const useGridSetDetailsRoute = ({ detailsBar, route, detailsItem }) => {
  useEffect(() => {
    if (!detailsBar?.pinned) {
      if (!isNullOrUndefined(route) && !isNullOrUndefined(detailsItem?.id) && route !== "" && route !== "/") {
        detailsBar?.setRoute(route + detailsItem.id);
      } else {
        detailsBar?.setRoute(null);
      }
    }
  }, [detailsItem]);
};
