import { InfoLogger } from "@utils/EventLogger";
import { ItemQuery } from "../../../../utils/Functions/Graphql/ItemQuery";
import { deleteItem } from "../../../../utils/Functions/Graphql/deleteItem";
import { deleteObservation } from "../../Observations/functions/deleteObservation";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 * Deletes a Source object.
 * @param {object} source - the Source object to delete
 * @param {string} source.id - the id of the Source object to delete
 * @param {object} config - configuration object
 * @param {boolean} deleteObservations - if true, will delete all Observations associated with this Source
 * @param {boolean} deleteRecommendations - passes through to deleteObservation
 * @param {boolean} deleteActionItems - passes through to deleteObservation
 * @returns {Promise<*>}
 */
export const deleteSource = async (
  source,
  { deleteObservations = false, deleteRecommendations = false, deleteActionItems = false },
) => {
  InfoLogger(`Deleting Source ${source.id}`);

  if (deleteObservations) {
    InfoLogger(`Deleting Observations for Source ${source.id}`);

    // fetch Observations for this Source, run the deleteObservation function for each one
    const { getQuery } = generateGraphql("Source", ["observations"], {
      observations: `(limit: 1000) { items { id __typename } }`,
    });

    const sourceWithObservations = await ItemQuery(getQuery, source.id);

    const observations = sourceWithObservations?.observations?.items;

    InfoLogger(`Fetched ${observations?.length} Observations for Source ${source.id}`);

    if (Array.isArray(observations)) {
      const promises = [];
      for (const observation of observations) {
        promises.push(
          deleteObservation(observation, {
            deleteRecommendations,
            deleteActionItems,
          }),
        );
      }
      await Promise.allSettled(promises);
    }
  }

  const { getQuery } = generateGraphql("Source", ["__typename"]);
  return await deleteItem(getQuery, source);
};
