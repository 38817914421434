import React, { useContext } from "react";

import CreateLinking from "../../../../../utils/GenericComponents/CreateLinking";
import CreateVulnerability from "../../../Vulnerabilities/components/CreateVulnerability";
import { ItemMutation } from "../../../../../utils/Functions/Graphql/ItemMutation";
import { OrganizationContext } from "../../../../../utils/Context/OrganizationContext";
import { TargetVulnerabilityStatus } from "../../../Vulnerabilities/components/TargetVulnerabilityStatus";
import { changeTargetLinkStatus } from "../../../Vulnerabilities/functions/changeTargetLinkStatusOperations/changeTargetLinkStatus";
import { createTargetFindingLink } from "../../../Vulnerabilities/functions/createTargetFindingLink";
import { finalizeTargetsVulnerabilitiesData } from "./functions/finalizeTargetsVulnerabilitiesData";
import { generateGraphql } from "@rivial-security/generategraphql";
import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";
import { useVulnerabilityDataGrid } from "../../../Vulnerabilities/hooks/useVulnerabilityDataGrid";
import VulnerabilityDataGrid from "../../../Vulnerabilities/components/VulnerabilityDataGrid";
import { TargetVulnerabilityStatus as TargetVulnerabilityStatusEnum } from "@rivial-security/schema-types";
import { listTargetFindingLinksByTargetId } from "@views/Testing/Targets/graphql/__targetsGQL";
import { getTargetVulnerabilityLinkPointOfContactField } from "@views/Testing/Vulnerabilities/constants/getTargetVulnerabilityLinkPointOfContactField";
import { modules } from "@rivial-security/role-utils";
import moment from "moment";

/**
 * Display Vulnerabilities for a Target
 * @param {object} item - target
 * @param {string} organizationID - selected organization
 * @param {string} assessmentID - filter vulnerabilities by assessment ID
 * @returns {object} {{gridDisplay: *, data: * | undefined, lastSelectedItem: string | undefined, setData: {(value: (((prevState: *) => *) | *)): void, (value: (((prevState: undefined) => undefined) | undefined)): void}, display: JSX.Element, isLoading: boolean | undefined, createResourceComponent: JSX, ref: string | undefined, setSelectedItems: {(value: (((prevState: []) => []) | [])): void, (value: (((prevState: undefined) => undefined) | undefined)): void}, setIsLoading: {(value: (((prevState: boolean) => boolean) | boolean)): void, (value: (((prevState: undefined) => undefined) | undefined)): void}, setLastSelectedItem: {(value: (((prevState: string) => string) | string)): void, (value: (((prevState: undefined) => undefined) | undefined)): void}, resetFunction: function(): void, fields: Object[] | undefined, setItemsToCheck: {(value: unknown): void, (value: (((prevState: undefined) => undefined) | undefined)): void}, selectedItems: [] | undefined}}
 */
const TargetVulnerabilities = ({ item, organizationID, assessmentID }) => {
  const context = useContext(OrganizationContext);
  const deleteFunction = async (item) => {
    if (item?.connectionIDField) {
      await ItemMutation(generateGraphql("TargetFindingLink").deleteMutation, {
        id: item?.connectionIDField,
      });
    }
  };

  const additionalFields = [
    {
      name: "status",
      flex: 1,
      disablePropagation: true,
      filter: {
        type: "CheckBox",
      },
      component: <TargetVulnerabilityStatus />,
      bulkEdit: true,
      valueOptions: Object.values(TargetVulnerabilityStatusEnum),
      editFunction: async ({ item, value, field }) => {
        await changeTargetLinkStatus({
          oldItem: item,
          newItem: { ...item, [field]: value },
        });
      },
    },
    getTargetVulnerabilityLinkPointOfContactField({
      getMutationID: ({ item }) => {
        return item?.targetFindingLinkID;
      },
      getDefaultActionItemValues: ({ row }) => {
        return {
          name: `Fix ${row?.name || "Vulnerability"} on ${item?.ip || "Target"}`,
          description: row?.solution || "",
          module: modules.VULNERABILITIES,
          priority: row?.priority || 0,
          dueDate: moment().add(1, "months").toISOString(),
          enableNotifications: true,
        };
      },
    }),
  ];

  const grid = useVulnerabilityDataGrid({
    additionalFields,
    organizationID,
    query: listTargetFindingLinksByTargetId,
    limit: 1000,
    variables: {
      targetID: item?.id,
    },
    options: ["edit"],
    persistenceUUID: "eded7a03-2b5a-43d5-8efc-5f5d38e0d3b8",
    normalizeData: null,
    finalizeData: async (targetFindingLinks) => {
      return await finalizeTargetsVulnerabilitiesData({
        targetFindingLinks,
        organizationContext: context,
        assessmentID,
      });
    },
    /**
     * Configuration for the Vulnerability Grid
     */
    vulnerabilityGridConfig: {
      deleteFunction,
      createItemModalHeader: "Attach a Vulnerability",
      createResourceButtonText: "Link",
      createResourceComponent: (
        <CreateLinking
          typename="Vulnerability"
          parentTypename="Target"
          item={item}
          form={<CreateVulnerability target={item} organizationID={organizationID} isTargetDetailsView={true} />}
          grid={<VulnerabilityDataGrid />}
          queryConfig={{ normalizeData: undefined }}
          organizationID={organizationID}
          createFunction={(itemToLink, item) => {
            return createTargetFindingLink({
              target: item,
              vulnerability: itemToLink,
              organizationID,
            });
          }}
        />
      ),
    },
  });

  return <div style={{ height: "30em" }}>{grid?.display}</div>;
};

export default withOrganizationCheck(TargetVulnerabilities);
