import React, { useState } from "react";

import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import { LoadingButton } from "@mui/lab";

/**
 * Generic submit button that uses the mui loading button to indicate loading state, used by form
 * @param {function} onClick - function to perform when the button is pressed
 * @param {string} dataTestId - the data test id for locating the button in e2e tests
 * @param {string} text - the buttons text, by default submit
 * @param {object} props - any additional props to pass down to the button component
 * @returns {JSX.Element}
 */
export const MuiSubmitButton = ({ onClick, dataTestId, text, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <LoadingButton
      color={"success"}
      variant={"contained"}
      loading={isLoading}
      loadingPosition={"start"}
      startIcon={<ArrowUpwardOutlinedIcon />}
      onClick={async () => {
        setIsLoading(true);
        try {
          if (typeof onClick === "function") {
            await onClick();
          }
        } finally {
          setIsLoading(false);
        }
      }}
      title={"Submit your inputs"}
      data-testid={dataTestId}
      {...props}
    >
      {text || "Submit"}
    </LoadingButton>
  );
};
