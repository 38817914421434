import { AmplifyEnv } from "../../../../../env-config";
import { Auth } from "@aws-amplify/auth";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * @description Function setupMFA for Login Setup Mfa Component
 * @param {object} user - Cognito user object
 * @param {function} setQRCode - set the QR code value
 * @param {function} setQrCodePure - set the QR code value as a code
 * @param {object} context - organization context
 */
export const setupMFA = ({ user, setQRCode, setQrCodePure, context }) => {
  if (isNullOrUndefined(user)) {
    throw new Error(`[setupMFA.js] Param: "user" is null`);
  }

  if (isNullOrUndefined(setQRCode)) {
    throw new Error(`[setupMFA.js] Param: "setQRCode" is null`);
  }

  if (isNullOrUndefined(setQrCodePure)) {
    throw new Error(`[setupMFA.js] Param: "setQrCodePure" is null`);
  }

  if (isNullOrUndefined(context)) {
    throw new Error(`[setupMFA.js] Param: "context" is null`);
  }

  Auth.setupTOTP(user).then((code) => {
    const authCode = `otpauth://totp/RISC:${AmplifyEnv !== "prod" ? `${AmplifyEnv}:` : ""}${
      context?.userEmail || user?.attributes?.email
    }?secret=${code}&issuer=RISC`;
    setQRCode(authCode);
    setQrCodePure(code);
  });
};
