import React, { useState } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Divider } from "@mui/material";
import Procedure from "../../Procedure/components/Procedure";
import { formattedPercent } from "@rivial-security/func-utils";
import { commonProcedures } from "../../../PlaybookBuilder/functions/getProceduresNestedFields";
import { getAllProcedureGroupResponseSteps } from "../../../PlaybookBuilder/functions/getAllProcedureGroupResponseSteps";
import NotEnoughData from "../../../../../utils/GenericComponents/NotEnoughData";
import { useUIContext } from "@utils/Context/UIContext";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  const { isMobile } = useUIContext();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={isMobile ? 0 : 3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    flexGrow: 1,
    display: "flex",
  },
}));

/**
 * Displays Playbook Procedures as tabs in a Vertical or Horizontal fashion
 * @param {object} item - the database object that has Procedure fields
 * @param {object[]} prependTabs - used to add customized tabs to the front
 * @param {object[]} appendTabs - used to add customized tabs to the rear
 * @param {string} orientation - "vertical" or "horizontal"
 * @param {boolean} [enableCheckbox = false] - enables checkboxes for the procedures
 * @param {boolean} [disableEdits = false] - doesn't allow the checklist to be edited
 * @param {boolean} [manageEditsSwitch = false] - true if procedures should show and manage their own edits switch (used in response plan)
 * @param {function} [resetFunction] - function to call to re-query item data
 * @param {string} [typename = "Playbook"] - the database model type that has the Procedures fields. Needs to correspond with the 'item' param
 * @param {boolean} [enableStepNotes = false] - enables the Notes UI for the procedures within a procedure group
 * @param {boolean} [enableProcedureNotes = false] - enables the Notes UI for the individual steps
 * @param {object} [tabContentStyle] - if provided will be added to the tab content container
 * @param {boolean} [disableCheckboxEdits=false] - if provided will change all text boxes and their to not be editable
 * @param {function} setTotalCompletion - used to adjust the completion percentage for the parent after a change
 * @param {boolean} showUpstreamUpdateSwitch - shows a UI option to copy changes in the checklist upstream to a parent item
 * @param {object} notesProps - props to pass to the notes components
 * @returns {JSX.Element}
 */
export default function PlaybookProcedureTabs({
  item,
  prependTabs = [],
  appendTabs = [],
  orientation,
  enableCheckbox = false,
  disableEdits = false,
  manageEditsSwitch = false,
  resetFunction,
  typename = "Playbook",
  enableStepNotes = false,
  enableProcedureNotes = false,
  tabContentStyle,
  disableCheckboxEdits = false,
  setTotalCompletion,
  showUpstreamUpdateSwitch,
  notesProps,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  //[COMPLETION TRACKING]
  //Track completion percent across all playbooks
  const [completionMap, setCompletionMap] = useState({});

  //Sets the completion state based on the passed in item
  const setCompletion = (item) => {
    if (!item) {
      setCompletionMap({});
    }
    const playbook = item;

    const currentCompletionMap = {};
    for (const procedureName of commonProcedures) {
      const responseSteps = getAllProcedureGroupResponseSteps({
        item: playbook,
        procedureName,
      });
      if (Array.isArray(responseSteps)) {
        let countCompleted = 0;
        for (const step of responseSteps) {
          if (step?.completed === true) {
            countCompleted++;
          }
        }

        if (responseSteps.length > 0) {
          currentCompletionMap[procedureName] = countCompleted / responseSteps.length;
        } else {
          currentCompletionMap[procedureName] = 0;
        }
      }
    }

    setCompletionMap(currentCompletionMap);
    setTotalCompletion && setTotalCompletion(item);
  };

  //Returns TRUE for playbook procedures that are all done
  const isCompleteProcedure = (field) => {
    if (enableCheckbox === true && field && completionMap.hasOwnProperty(field) && completionMap[field] === 1) {
      return true;
    } else {
      return false;
    }
  };

  //Returns string with tab label based on completion map
  const getTabLabel = (field, label) => {
    if (enableCheckbox === true && field && completionMap.hasOwnProperty(field)) {
      let tabLabel = label;
      if (!isCompleteProcedure(field)) {
        tabLabel += ` (${formattedPercent(completionMap[field], 0)})`;
      }
      return tabLabel;
    } else {
      return label;
    }
  };

  const tabs = [
    ...prependTabs,
    {
      field: "detect",
      icon: <i className={"icon-eyeglass"} />,
      label: "Detect",
      component: (
        <Procedure
          procedureName={"detect"}
          item={item}
          typename={typename}
          setCompletion={setCompletion}
          enableCheckbox={enableCheckbox}
          manageEditsSwitch={manageEditsSwitch}
          disableEdits={disableEdits}
          enableStepNotes={enableStepNotes}
          enableProcedureNotes={enableProcedureNotes}
          orientation={orientation}
          resetFunction={resetFunction}
          containerStyle={tabContentStyle}
          disableCheckboxEdits={disableCheckboxEdits}
          showUpstreamUpdateSwitch={showUpstreamUpdateSwitch}
          notesProps={notesProps}
        />
      ),
    },
    {
      field: "analyze",
      icon: <i className={"icon-magnifier"} />,
      label: "Analyze",
      component: (
        <Procedure
          procedureName={"analyze"}
          item={item}
          typename={typename}
          setCompletion={setCompletion}
          enableCheckbox={enableCheckbox}
          manageEditsSwitch={manageEditsSwitch}
          disableEdits={disableEdits}
          enableStepNotes={enableStepNotes}
          enableProcedureNotes={enableProcedureNotes}
          orientation={orientation}
          resetFunction={resetFunction}
          containerStyle={tabContentStyle}
          disableCheckboxEdits={disableCheckboxEdits}
          showUpstreamUpdateSwitch={showUpstreamUpdateSwitch}
          notesProps={notesProps}
        />
      ),
    },
    {
      field: "contain",
      icon: <i className={"icon-briefcase"} />,
      label: "Contain",
      component: (
        <Procedure
          procedureName={"contain"}
          item={item}
          typename={typename}
          setCompletion={setCompletion}
          enableCheckbox={enableCheckbox}
          manageEditsSwitch={manageEditsSwitch}
          disableEdits={disableEdits}
          enableStepNotes={enableStepNotes}
          enableProcedureNotes={enableProcedureNotes}
          orientation={orientation}
          resetFunction={resetFunction}
          containerStyle={tabContentStyle}
          disableCheckboxEdits={disableCheckboxEdits}
          showUpstreamUpdateSwitch={showUpstreamUpdateSwitch}
          notesProps={notesProps}
        />
      ),
    },
    {
      field: "eradicate",
      icon: <i className={"icon-fire"} />,
      label: "Eradicate",
      component: (
        <Procedure
          procedureName={"eradicate"}
          item={item}
          typename={typename}
          setCompletion={setCompletion}
          enableCheckbox={enableCheckbox}
          manageEditsSwitch={manageEditsSwitch}
          disableEdits={disableEdits}
          enableStepNotes={enableStepNotes}
          enableProcedureNotes={enableProcedureNotes}
          orientation={orientation}
          resetFunction={resetFunction}
          containerStyle={tabContentStyle}
          disableCheckboxEdits={disableCheckboxEdits}
          showUpstreamUpdateSwitch={showUpstreamUpdateSwitch}
          notesProps={notesProps}
        />
      ),
    },
    {
      field: "recover",
      icon: <i className={"icon-wrench"} />,
      label: "Recover",
      component: (
        <Procedure
          procedureName={"recover"}
          item={item}
          typename={typename}
          setCompletion={setCompletion}
          enableCheckbox={enableCheckbox}
          manageEditsSwitch={manageEditsSwitch}
          disableEdits={disableEdits}
          enableStepNotes={enableStepNotes}
          enableProcedureNotes={enableProcedureNotes}
          orientation={orientation}
          resetFunction={resetFunction}
          containerStyle={tabContentStyle}
          disableCheckboxEdits={disableCheckboxEdits}
          showUpstreamUpdateSwitch={showUpstreamUpdateSwitch}
          notesProps={notesProps}
        />
      ),
    },
    {
      field: "postIncident",
      icon: <i className={"icon-loop"} />,
      label: "Post-Incident",
      component: (
        <Procedure
          procedureName={"postIncident"}
          item={item}
          typename={typename}
          setCompletion={setCompletion}
          enableCheckbox={enableCheckbox}
          manageEditsSwitch={manageEditsSwitch}
          disableEdits={disableEdits}
          enableStepNotes={enableStepNotes}
          enableProcedureNotes={enableProcedureNotes}
          orientation={orientation}
          resetFunction={resetFunction}
          containerStyle={tabContentStyle}
          disableCheckboxEdits={disableCheckboxEdits}
          showUpstreamUpdateSwitch={showUpstreamUpdateSwitch}
          notesProps={notesProps}
        />
      ),
    },
    ...appendTabs,
  ];

  const display = (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: orientation === "vertical" ? "row" : "column",
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        orientation={orientation}
      >
        {Array.isArray(tabs) ? (
          tabs.map((tab, index) =>
            tab.divider === true ? (
              <Divider orientation={"vertical"} flexItem />
            ) : (
              <Tab
                style={{
                  ...(isCompleteProcedure(tab.field) && {
                    backgroundColor: "#CCFFDD",
                  }),
                }}
                icon={isCompleteProcedure(tab.field) ? <i className={"icon-check"} /> : tab.icon}
                label={getTabLabel(tab.field, tab.label)}
                {...a11yProps(index)}
              />
            ),
          )
        ) : (
          <NotEnoughData />
        )}
      </Tabs>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
        style={{ ...(orientation === "vertical" && { width: "100%" }) }}
      >
        {Array.isArray(tabs) ? (
          tabs.map((tab, index) => (
            <TabPanel
              key={tab.label + index}
              value={value}
              index={index}
              dir={theme.direction}
              style={{ ...(orientation !== "vertical" && { height: "100%" }) }}
            >
              <div>{tab.component}</div>
            </TabPanel>
          ))
        ) : (
          <NotEnoughData />
        )}
      </SwipeableViews>
    </div>
  );

  if (orientation === "vertical") {
    return (
      <div className={classes.root} style={{ zIndex: 998 }}>
        {display}
      </div>
    );
  } else {
    return (
      <div className={classes.root}>
        <AppBar position="relative" color="default" style={{ zIndex: 998 }}>
          {display}
        </AppBar>
      </div>
    );
  }
}
