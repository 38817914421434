import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useModal } from "../../../../hooks/views/useModal";
import Button from "@mui/material/Button";
import React from "react";
import PublishOutlinedIcon from "@mui/icons-material/PublishOutlined";
import UploadDocument from "../../../../hooks/views/useDocumentGrid/components/UploadDocument";

/**
 * Modal button for uploading documents to an incident
 * @param item
 * @param resetFunction
 * @param organizationID
 * @returns {JSX.Element}
 * @constructor
 */
const UploadIncidentDocumentButton = ({ item, resetFunction, organizationID }) => {
  const modal = useModal(
    "Upload Documents for this Incident",
    <UploadDocument
      organizationID={organizationID}
      connectionInput={{ incidentID: item?.id }}
      resetFunction={resetFunction}
    />,
    <Button title={"Upload Documents for this Incident"}>
      <PublishOutlinedIcon />
    </Button>,
  );

  return modal.modalButton;
};

export default withOrganizationCheck(UploadIncidentDocumentButton);
