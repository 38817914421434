import { ItemMutation } from "../../../Functions/Graphql/ItemMutation";
import { generateGraphql } from "@rivial-security/generategraphql";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Creates a SystemTagLink object
 * @param {object} system - the system to which to attach the tag
 * @param {object} tag - the tag that needs to be attached to the system
 * @param {string} organizationID - the organization identifier of the system in the database
 * @returns {Promise<*>}
 */
export const createSystemTagLink = (system, tag, organizationID) => {
  if (isNullOrUndefined(tag) || isNullOrUndefined(system?.id)) {
    throw Error("Invalid input!");
  }

  const { createMutation: createSystemTagLink } = generateGraphql("SystemTagLink", ["systemID", "tagID", "__typename"]);

  return ItemMutation(createSystemTagLink, {
    systemID: system.id,
    tagID: tag.id,
    ownerGroup: organizationID,
  });
};
