import React, { useRef } from "react";
import { useOutsideAlerter } from "../hooks/useOutsideAlerter";

/**
 * @description Component that alerts if you click outside it
 * REFERENCE: https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
 * @param {React.JSXElement[]} children - the components to wrap
 * @param {function} onClickOutside - function to call when clicked outside
 */
const OutsideAlerter = ({ onClickOutside, children } = {}) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter({ ref: wrapperRef, onClickOutside });

  return <div ref={wrapperRef}>{children}</div>;
};

export default OutsideAlerter;
