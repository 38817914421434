import React from "react";
import { useStandardLossAmount } from "../functions/useStandardLossAmount";
import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";

/**
 * Displays upper confidence interval for a KRI
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const StandardLossAmount = (props) => {
  const { system } = props;

  const standardLossAmountHook = useStandardLossAmount(system, props.organizationID, props.systemRiskLink);

  return <>{standardLossAmountHook.display}</>;
};

export default withOrganizationCheck(StandardLossAmount);
