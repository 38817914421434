import CancelChangesButton from "./components/CancelChangesButton";
import Dashboard from "../../../../../utils/GenericComponents/Dashboard";
import DashboardCard from "../../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import DataLoader from "../../../../../utils/LoadingComponents/DataLoader";
import Grid from "@mui/material/Grid";
import ModuleSections from "./components/ModuleSections";
import React from "react";
import RolePreviewButton from "./components/RolePreviewButton";
import RoleUserLinkingButton from "../../components/RoleUserLinkingButton";
import SaveChangesButton from "./components/SaveChangesButton";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useGetOperationTeamsForOrg } from "../useGetOperationTeamsForOrg";
import { useRoleConfig } from "./hooks/useRoleConfig";
import { useRoleGeneralInfo } from "./hooks/useRoleGeneralInfo";
import { useRoleUsers } from "../useRoleUsers";

/**
 * Displays Role information and allows the user to change role settings
 * @param itemId
 * @param organizationID
 * @returns {{display: JSX.Element}}
 */
export const useRoleDetails = ({ itemId, organizationID }) => {
  /**
   * Queries role information and handles the basic details table for name, description, etc.
   */
  const roleDetails = useRoleGeneralInfo({ itemId });

  /**
   * Gets Users that are assigned to this Role
   */
  const roleUsers = useRoleUsers({ organizationID, itemId });

  /**
   * Gets Operation Teams that are assigned to this Role
   */
  const roleOperationTeams = useGetOperationTeamsForOrg({
    organizationID,
    roleID: itemId,
    getBy: "role",
  });

  /**
   * Hook for all RoleConfig utilities
   */
  const {
    roleConfig,
    roleConfigInit,
    isChanged,
    handleResourceChange,
    handleModuleChange,
    key,
    saveChange,
    cancelChange,
  } = useRoleConfig({ item: roleDetails.item });

  /**
   * Header buttons for the card
   * @type {JSX.Element[]}
   */
  const headerButtons = [<RolePreviewButton roleConfig={roleConfig} />];

  const display = (
    <Dashboard item={roleDetails?.item} title={"Role Details"} headerButtons={headerButtons} key={key}>
      <DataLoader isLoading={roleDetails.isLoading} isEnoughData={!isNullOrUndefined(roleDetails?.item?.roleConfig)}>
        <Grid container spacing={2}>
          <Grid item lg={4}>
            <Grid container spacing={2}>
              <Grid item lg={12}>
                <DashboardCard title={"General Info"} style={{ height: "100%" }}>
                  {roleDetails.tableDisplay}
                </DashboardCard>
              </Grid>
              <Grid item lg={12}>
                <DashboardCard
                  title={"Assigned Users"}
                  headerButtons={[
                    <RoleUserLinkingButton
                      item={roleUsers?.item}
                      organizationID={organizationID}
                      resetFunction={roleUsers?.reset}
                    />,
                  ]}
                >
                  {roleUsers.display}
                </DashboardCard>
              </Grid>
              <Grid item lg={12}>
                <DashboardCard
                  title={"Operation Teams"}
                  tooltip="External Teams that have been granted access to this Organization"
                >
                  {roleOperationTeams.display}
                </DashboardCard>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={8}>
            <DashboardCard
              title={"Role Configuration"}
              style={{ height: "100%" }}
              headerButtons={
                isChanged && [
                  <SaveChangesButton key={0} onClick={saveChange} style={{ marginRight: "1em" }} />,
                  <CancelChangesButton onClick={cancelChange} />,
                ]
              }
            >
              <ModuleSections
                precedence={roleDetails?.item?.precedence}
                roleConfig={roleConfig}
                handleModuleChange={handleModuleChange}
                handleResourceChange={handleResourceChange}
              />
            </DashboardCard>
          </Grid>
        </Grid>
      </DataLoader>
    </Dashboard>
  );

  return {
    display,
  };
};
