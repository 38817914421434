import { InfoLogger } from "@utils/EventLogger";
import { isNullOrUndefined } from "@rivial-security/func-utils";

export const getCurrentHeaders = (input) => {
  const res = [];
  if (!isNullOrUndefined(input[0])) {
    for (const key in input[0]) {
      res.push(key);
    }
  }
  InfoLogger(res);
  return [...res];
};
