import { InvalidParamError } from "../../../analytics/CustomError";
import { ItemMutation } from "./ItemMutation";
import { generateGraphql } from "@rivial-security/generategraphql";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Duplicates an item in the database.
 * Uses the __typename item field or typename parameter to construct the mutation.
 *
 * Only duplicates scalar fields. Object fields and Array fields are skipped.
 *
 * @param {object} item - the item being updated
 * @param {string} organizationID - the organizationID for the duplicated item
 * @param {object} [input] - optional input fields to add or overwrite
 * @param {string} [typename] - the typename used to construct the mutation
 * @param {string} [item.__typename] - the typename being updated
 * @param {string[]} [connectionFields] - fields to be preserved from the many-to-many model itself
 *
 * @example with typename included in item object
 * const newItem = await duplicateItem( {item: {id: "123", name: "Bob", typename: "BobType"}, organizationID: "orgA"} )
 *
 * @example with typename passed as a param
 * const newItem = await duplicateItem( {item: {id: "123", name: "Bob"}, organizationID: "orgA", typename: "BobType} )
 */
export const duplicateItem = async ({
  item,
  organizationID,
  input = {},
  typename: typenameInput,
  connectionFields = [],
}) => {
  if (isNullOrUndefined(organizationID)) {
    throw new InvalidParamError("organizationID", "cannot be null or undefined");
  }

  if (isNullOrUndefined(item)) {
    throw new InvalidParamError("item", "cannot be null or undefined");
  }

  if (isNullOrUndefined(typenameInput) && isNullOrUndefined(item.__typename)) {
    throw Error("must have a 'typename' param or an item.__typename param");
  }

  /**
   * Construct the Mutation Input
   * @type {{__typename: string, ownerGroup: (string|undefined)}}
   */

  const finalInput = {
    ...item,
    ownerGroup: organizationID || undefined,
    ...input,
  };

  /**
   * Dynamically creates the Fields for the Mutation based on the input
   */
  const fields = [];

  /**
   * Removes ID property, typename, and Array and Object types.
   */
  for (const property in finalInput) {
    if (finalInput.hasOwnProperty(property)) {
      if (
        property === "id" ||
        property === "__typename" ||
        (!Array.isArray(finalInput[property]) &&
          !connectionFields.includes(property) &&
          typeof finalInput[property] === "object") || // we now support array fields, but not object fields yet
        typeof finalInput[property] === "undefined" ||
        typeof finalInput[property] === "function"
      ) {
        delete finalInput[property];
      }
    }
  }

  const typename = typenameInput || item.__typename;

  const { createMutation } = generateGraphql(typename, fields);

  return await ItemMutation(createMutation, finalInput);
};
