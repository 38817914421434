import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import React from "react";
import { Button } from "@mui/material";
import Save from "@mui/icons-material/Save";

/**
 * Calls the 'createRiskChange' lambda, either with a monteCarloResult object or without one.
 * The lambda will save the change verbatim using the monteCarloResult object, or it will run a fresh simulation
 *
 * @param systemChanges - an array of Systems with monteCarloResults. This is used to dispatch the changes
 * @constructor
 */
const DispatchChangeButton = ({ isDisabled, onClick, isLoading }) => {
  return (
    <>
      <Button
        startIcon={<Save />}
        style={{ backgroundColor: "#5cb85c" }}
        loading={isLoading}
        loadingIndicator="Saving Risk Change..."
        variant="contained"
        size={"large"}
        disabled={isDisabled}
        onClick={onClick}
        color="success"
        title={"Save the details of this change and automatically update resources"}
      >
        Save Change
      </Button>
    </>
  );
};

export default withOrganizationCheck(DispatchChangeButton);
