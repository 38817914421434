import { modules, resources } from "@rivial-security/role-utils";
import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { useNestedDataCard } from "../../../../hooks/views/useNestedDataCard/useNestedDataCard";
import UploadDocument from "../../../../hooks/views/useDocumentGrid/components/UploadDocument";
import { useDocumentDataGrid } from "../../../../hooks/views/useDocumentGrid/hooks/useDocumentDataGrid";
import React from "react";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import DocumentDataGrid from "../../../../hooks/views/useDocumentGrid/components/DocumentDataGrid";

/**
 * Displays Documents for a Resource that has a 1-M relationship with Documents. For example, Observations, Action Items, etc.
 * @param {string} parentTypename - the typename of the parent resource that Documents are being displayed for
 * @param {object} item - the parent resource that Documents are being displayed for
 * @param {string} module - the module of the parent resource used for Role Checking
 * @param {string} resource - the resource of the parent resource used for Role Checking (usually same as parentTypename)
 * @param {string} connectionIDField - the field on the Document schema model that contains the ID of the parent resource
 * @param {string} organizationID - current organization ID
 * @returns {JSX.Element}
 * @constructor
 */
const NestedDocumentsCard = ({
  parentTypename,
  item,
  module = modules.ORGANIZATION_MANAGER,
  resource = resources.DOCUMENT,
  connectionIDField,
  organizationID,
}) => {
  const prettyTypename = convertCamelCaseToSentence(parentTypename);

  const dataGrid = useNestedDataCard({
    item,
    typename: resources.DOCUMENT,
    parentTypename,
    field: "documents",
    connectionIDField,
    grid: <DocumentDataGrid module={module} resource={resource} />,
    form: <UploadDocument connectionInput={{ [connectionIDField]: item?.id }} />,
    gridHook: useDocumentDataGrid,
    organizationID,
    selectItemAlert: `Warning: a Document may only be associated with one ${prettyTypename} at a time. If you select a Document that is already associated with another ${prettyTypename}, it will be removed from the previous ${prettyTypename} and added to this one.`,
  });

  return <div style={{ height: "30em" }}>{dataGrid.gridDisplay}</div>;
};

export default withOrganizationCheck(NestedDocumentsCard);
