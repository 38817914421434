import React, { useContext } from "react";
import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import { CreateWidgetContext } from "../../useCreateWidget";
import { WIDGET_TYPE } from "@rivial-security/widget-utils";
import { Alert } from "@mui/material";
import CustomizeTableWidgetVisuals from "./components/CustomizeTableWidgetVisuals";
import WidgetPreview from "../../../../../WidgetRenderer/components/ChartWidgetPreview";
import CustomizeBarChartWidgetVisuals from "./CustomizeBarChartWidgetVisuals";

/**
 * UI for configuring how the widget will look like in differnt contexts (email, web or report)
 * @param {string} organizationID - the currently selected organization ID
 * @returns {JSX.Element}
 */
const CustomizeVisualsStep = ({ organizationID }) => {
  const { widget, dispatch } = useContext(CreateWidgetContext);

  switch (widget?.type) {
    case WIDGET_TYPE.TABLE:
      return <CustomizeTableWidgetVisuals organizationID={organizationID} />;
    case WIDGET_TYPE.HISTORY_CHART:
    case WIDGET_TYPE.PIE_CHART:
      return (
        <div
          style={{
            height: "70vh",
          }}
        >
          <WidgetPreview
            organizationID={organizationID}
            widget={{
              ...widget,
            }}
          />
        </div>
      );
    case WIDGET_TYPE.BAR_CHART:
      return <CustomizeBarChartWidgetVisuals organizationID={organizationID} />;
    default:
      return <Alert>This widget type is not supported yet!</Alert>;
  }
};

export default withOrganizationCheck(CustomizeVisualsStep);
