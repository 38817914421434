import React, { useEffect, useState } from "react";
import { removeObjectFromArray, updateObjectInArray } from "@rivial-security/func-utils";

import { Button } from "reactstrap";
import { createActionItem } from "../../Actions/functions/createActionItem";
import { createRecommendationActionItemLink } from "../../Actions/functions/createRecommendationActionItemLink";
import { deleteRecommendationActionLink } from "../functions/deleteRecommendationActionLink";
import { useAddActionItems } from "../../Actions/hooks/useAddActionItems";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * Displays ActionItems associated with a Recommendation.
 * Allows the user to add/remove action items
 * @param item
 * @param updateItemById
 * @param organizationID
 * @returns {JSX.Element}
 * @constructor
 */
const ActionItems = ({ item: recommendation, updateItemById, organizationID, resetFunction }) => {
  const [initialInput, setInitialInput] = useState([]);
  const [input, setInput] = useState([]);

  const [inputChanged, setInputChanged] = useState(false);

  const [actionsToAdd, setActionsToAdd] = useState([]);
  const [actionsToRemove, setActionsToRemove] = useState([]);

  const handleSave = async () => {
    // handle creations
    for (const actionToAdd of actionsToAdd) {
      // If the Action Item already exists, just create the link.
      if (actionToAdd.alreadyExists) {
        // await createObservationRecommendationLink(item, recommendationToAdd)
      }
      // If it's a brand new Action Item, create a new one and create the link
      else {
        const newAction = await createActionItem(
          {
            ...actionToAdd,
            dueDate: (actionToAdd.dueDate && new Date(actionToAdd.dueDate)) || undefined,
            ownerGroup: organizationID,
          },
          recommendation,
        );

        await createRecommendationActionItemLink(recommendation, newAction);
      }
    }

    // handle removals
    for (const actionToRemove of actionsToRemove) {
      // find the corresponding link
      const foundLink = recommendation?.actionItems?.items?.find((actionLink) => {
        if (actionLink?.action?.id === actionToRemove?.id) {
          return true;
        } else {
          return false;
        }
      });

      if (foundLink && foundLink.id) {
        await deleteRecommendationActionLink(foundLink);
      }
    }

    setActionsToRemove([]);
    setActionsToAdd([]);
    setInitialInput([...input]);
    setInputChanged(false);
    resetFunction && resetFunction();
    // updateItemById && updateItemById()
  };

  const handleCancel = () => {
    addActionsForm.setInput([...initialInput]);
    setActionsToAdd([]);
    setActionsToRemove([]);
    setInput([...initialInput]);
    setInputChanged(false);
  };

  const onAdd = (item) => {
    setActionsToAdd((curr) => [...curr, item]);
    setInput([...input, item]);
    setInputChanged(true);
  };

  const onRemove = (item) => {
    setActionsToRemove((curr) => [...curr, item]);
    setInput((input) => {
      const temp = [...input];
      removeObjectFromArray(temp, item);
      setInput([...temp]);
    });
    setInputChanged(true);
  };

  const onUpdate = (action) => {
    setActionsToAdd((items) => {
      updateObjectInArray(items, action, "id");
      return [...items];
    });

    setInput((input) => {
      updateObjectInArray(input, action, "id");
      return [...input];
    });
  };

  /**
   * If an existing Recommendation is passed in, populate the list to start with
   */
  useEffect(() => {
    const res = [];
    if (recommendation?.actionItems?.items) {
      for (const actionItemLink of recommendation.actionItems.items) {
        const actionItem = actionItemLink.action;
        res.push({
          alreadyExists: true,
          ...actionItem,
        });
      }
    }

    setInitialInput(res);
  }, [recommendation]);

  const addActionsForm = useAddActionItems({
    organizationID,
    onAdd,
    onRemove,
    onUpdate,
    recommendation,
  });

  /**
   * Update the form when the initial input changes
   */
  useEffect(() => {
    if (initialInput) {
      addActionsForm.setInput([...initialInput]);
    }
  }, [initialInput]);

  return (
    <>
      {inputChanged && (
        <span>
          <Button size="sm" color="success" onClick={handleSave}>
            Save Changes
          </Button>{" "}
          <Button size="sm" color="danger" onClick={handleCancel}>
            Cancel Changes
          </Button>
        </span>
      )}
      <span>{addActionsForm.display}</span>
    </>
  );
};

export default withOrganizationCheck(ActionItems);
