import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import ClassificationTier from "../customFields/ClassificationTier";
import DetectionType from "../customFields/DetectionType";
import React from "react";
import { modules, resources } from "@rivial-security/role-utils";
import { DetailsTable } from "../../../../hooks/views/useDetails/hooks/useDetails";

/**
 * Displays General Information for Incident Details
 * @param {object} item - incident data from database
 * @param {function} resetFunction - function to re-query 'item'
 * @param {string} organizationID - currently selected organization identifier
 * @returns {JSX.Element}
 */
const IncidentEscalation = ({ item, resetFunction, organizationID }) => {
  const module = modules.INCIDENT_RESPONSE;
  const resource = resources.INCIDENT;

  const customFields = [
    {
      field: "classificationTier",
      component: <ClassificationTier organizationID={organizationID} />,
    },
    {
      field: "detection",
      component: <DetectionType organizationID={organizationID} />,
    },
  ];

  const fieldNameDictionary = {
    classificationTier: "Classification Tier",
    detection: "Detection Type",
  };

  return (
    <DetailsTable
      item={item}
      fields={["classificationTier", "detection"]}
      customFields={customFields}
      fieldNameDictionary={fieldNameDictionary}
      module={module}
      resource={resource}
      disableRoleChecking={true}
      resetFunction={resetFunction}
    />
  );
};

export default withOrganizationCheck(IncidentEscalation);
