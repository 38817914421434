import { DETAILS_TYPES } from "../enums/DETAILS_TYPES";
import { checkParams } from "../../../../utils/Functions/Errors/checkParams";
import { gridCustomFieldColumn } from "./gridCustomFieldColumn";
import { gridRowDeselected } from "./gridRowDeselected";
import { gridRowSelected } from "./gridRowSelected";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * @description Handles logic when a row is being selected.
 * If a customField in a row is selected, cancels the selection.
 * @param {object} args - object with arguments of the selecting item
 * @param {function} checkAriaLabel - checks if an element has area label
 * @param {object} disableUpdate - used to prevent the details pane from updating when it doesn't need to.
 * @param {JSX} detailsBar - Get details bar for UI context
 * @param {DETAILS_TYPES} detailsType - details component display style
 * @param {function} setSelectedItems - sets the selected array of items
 * @param {function} setLastSelectedItem - sets last selected item in the grid
 * @param {JSX} detailsModal - modal component for displaying details of an object
 * @param {function} updateItemById - update an item by id in the grid
 * @param {JSX} detailsComponent - jsx component for displaying an item
 * @param {function} rightClick - if right clicking on a row, allows selection but disables opening the details panel
 */
export const gridRowSelecting = ({
  args,
  checkAriaLabel,
  disableUpdate,
  detailsBar,
  detailsType,
  setSelectedItems,
  setLastSelectedItem,
  detailsModal,
  updateItemById,
  detailsComponent,
  rightClick,
}) => {
  /**
   * Params to be checked for null or undefined
   */
  const params = {
    args,
    checkAriaLabel,
    disableUpdate,
    detailsBar,
    detailsType,
    setSelectedItems,
    setLastSelectedItem,
    detailsModal,
    updateItemById,
    ...(detailsType !== DETAILS_TYPES.NONE && { detailsComponent }),
    rightClick,
  };

  /**
   * Check params for null or undefined
   */
  checkParams({ params });

  if (gridCustomFieldColumn({ target: args.target, checkAriaLabel })) {
    args.cancel = true;
  } else {
    if (args.name === "rowSelecting") {
      /**
       * Only continue selection if the data is valid
       */
      if (Array.isArray(args?.data)) {
        const foundNullItem = args.data.findIndex((item) => isNullOrUndefined(item)) !== -1;
        if (foundNullItem) {
          return;
        }
      } else {
        return;
      }

      /**
       * Set last selected item on grid Row Selecting
       */
      setLastSelectedItem(args.data);

      disableUpdate.current === false &&
        gridRowSelected({
          args,
          detailsBar,
          detailsType,
          setSelectedItems,
          setLastSelectedItem,
          detailsModal,
          disableUpdate,
          updateItemById,
          detailsComponent,
          rightClick,
        });
    }
    if (args.name === "rowDeselecting") {
      disableUpdate.current === false &&
        gridRowDeselected({
          args,
          detailsBar,
          detailsType,
          disableUpdate,
          detailsComponent,
          setSelectedItems,
        });
    }
  }
};
