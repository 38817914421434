import { useForm } from "../../../hooks/views/useForm";
import React, { useContext } from "react";
import { Alert } from "@mui/material";
import { useResetAccessControlPassword } from "./useResetAccessControlPassword";
import { OrganizationContext } from "../../Context/OrganizationContext";

/**
 * @description Add Password Protection UI hook
 * @param {object} item - database object with an accessControl field
 * @param {function} submitFunction - function to submit the form
 * @param {boolean} disableFormButtons - disable form buttons
 * @param {string} typename - schema type name with a capital first letter
 * @param {function} resetFunction - reset state of the item
 * @param {function} toggleModal - close modal function
 * @returns {{display: *, input: {}}}
 */
export const useAddPasswordControl = ({
  item,
  disableFormButtons,
  submitFunction,
  typename = "item",
  resetFunction,
  toggleModal,
}) => {
  const context = useContext(OrganizationContext);

  /**
   * Reset password form
   */
  const resetPasswordForm = useResetAccessControlPassword({
    item,
    typename,
    resetFunction,
    toggleModal,
  });

  const form = useForm({
    fieldConfig: {
      password: {
        required: true,
        inputType: "password",
        label: "Enter Password: ",
        tooltip: `Add a password to protect this ${typename}`,
        passwordConfig: {
          confirmPassword: true,
        },
      },
    },
    ...(disableFormButtons && {
      disableSubmitButton: true,
      disableResetButton: true,
    }),
    submitFunction,
  });

  const display = (
    <>
      {(() => {
        /**
         * Check if user the password owner
         */
        if (item?.accessControl?.passwordOwnerEmail === context?.userEmail || !item?.accessControl?.password) {
          /**
           * Check if password already exists for this item
           */
          return item?.accessControl?.password ? resetPasswordForm?.display : form?.display;
        } else {
          return <Alert color="info">Sorry, you are not the owner of this {typename}</Alert>;
        }
      })()}
    </>
  );

  return {
    ...form,
    display,
  };
};
