import { getResourceAttribute } from "../../../../functions/getResourceAttribute";
import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { genericResources } from "../../../../constants/genericResources";

/**
 * Generates field config for dynamic forms based on their type definitions
 * @param {object[]} fields - fields to display in the form
 * @return {{object} - field config for the useForm hook
 */
export const generateFieldConfig = ({ fields }) => {
  const fieldConfig = {};
  for (const field of fields) {
    const fieldTypename = field?.typename || genericResources?.STRING;
    if (!fieldTypename) continue;
    const TypenameInputComponent = getResourceAttribute({
      attribute: "InputComponent",
      typename: fieldTypename,
    });

    //If no custom form component is defined try to pass in the input type directly
    const inputType = TypenameInputComponent ? "custom" : "text";

    //Check if field has a tooltip defined
    let tooltip = null;
    const typenameDescription = getResourceAttribute({
      override: field,
      attribute: "description",
      typename: fieldTypename,
    });
    tooltip = typenameDescription;

    //Compute the fallback label by checking other available attributes
    let label = fieldTypename;
    if (field?.friendlyName) {
      label = field.friendlyName;
    } else if (field?.name) {
      label = convertCamelCaseToSentence(field?.name);
    }

    //Compute whether to show the field in the form
    let isHidden = field?.isHidden || false;
    const typenameIsHiddenInForms = getResourceAttribute({
      override: field,
      attribute: "hidden.inForms",
      typename: fieldTypename,
    });
    if (typeof typenameIsHiddenInForms === "function") {
      isHidden = ({ input }) => typenameIsHiddenInForms({ input });
    } else if (typeof typenameIsHiddenInForms === "boolean") {
      isHidden = ({ input }) => {
        return typenameIsHiddenInForms;
      };
    }

    fieldConfig[field.name] = {
      inputType,
      tooltip,
      label,
      isHidden,
    };

    if (inputType === "custom") {
      fieldConfig[field.name].customConfig = {
        component: <TypenameInputComponent data={field?.data || {}} />,
      };
    }
  }
  return fieldConfig;
};
