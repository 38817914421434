import React, { useState } from "react";
import { handleRoleTypes, roleConfigDefault } from "@rivial-security/role-utils";

import { Input } from "reactstrap";
import ModuleSection from "./ModuleSection";
import { keywordSort } from "@rivial-security/func-utils";

/**
 * Displays a list of Module Sections for a Role.
 * Includes a searchbar for keyword sort
 * @param {object} roleConfig - the roleConfig object from the database
 * @param {function} handleModuleChange - handler for enabled or disabling a module
 * @param {function} handleResourceChange - handler for enabled or disabling certain operations for a resource
 * @param {number} precedence - current precedence of the role
 * @param {boolean} disableEdits - whether this section is disabled for editing
 * @returns {JSX.Element}
 * @constructor
 */
const ModuleSections = ({ handleModuleChange, handleResourceChange, roleConfig, precedence, disableEdits }) => {
  const [searchValue, setSearchValue] = useState("");

  const modules = Object.keys(handleRoleTypes(roleConfigDefault, precedence).modules);

  return (
    <div>
      <Input
        data-testid={"text-input-search-modules"}
        onChange={(e) => setSearchValue(e.target.value)}
        value={searchValue}
        placeholder="Search Role Config.."
        style={{ marginBottom: "1em" }}
      />
      {keywordSort(modules, searchValue, null).map((module, index) => (
        <ModuleSection
          key={`module-section-${index}`}
          disableEdits={disableEdits}
          searchValue={searchValue}
          precedence={precedence}
          roleConfig={roleConfig}
          module={module}
          handleModuleChange={handleModuleChange}
          handleResourceChange={handleResourceChange}
        />
      ))}
    </div>
  );
};

export default ModuleSections;
