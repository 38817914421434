import { generateGraphql } from "@rivial-security/generategraphql";
import React from "react";
import CreateObservation from "../components/CreateObservation";
import { deleteObservation } from "../functions/deleteObservation";
import IsFinding from "../customFields/IsFinding";
import Module from "../customFields/Module";
import ObservationDetails from "../components/ObservationDetails";
import { modules, resources } from "@rivial-security/role-utils";
import Source from "../customFields/Source";
import Risk from "../customFields/Risk";
import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";
import Decision from "../customFields/Decision";
import Status from "../customFields/Status";
import NeedsDecision from "../customFields/NeedsDecision";
import { OBSERVATION } from "../constants/OBSERVATION";
import { GetQuery } from "../../../../utils/Functions/Graphql/GetQuery";
import EstimatedCompletionDate from "../customFields/EstimatedCompletionDate";
import { getResourceQueryData } from "../../../../definitions/functions/getResourceQueryData";
import { fieldContexts } from "../../../../enums/fieldContexts";
import { createObservationTagLink } from "../functions/createObservationTagLink";

/**
 * List of Observations for an Org
 *
 * @param {string} organizationID - the ownerGroup of the observation
 * @param {function} resetFunction - function to reset the grid
 * @param {object} gridConfig - configuration overrides for the grid UI
 * @param {object} cardConfig - configuration overrides for the card UI
 * @param {object} queryConfig - configuration overrides for the grid query
 * @param {string[]} queryFields - fields to limit the query to
 * @param {string[]} hiddenFields - fields to hide from the grid
 * @param {object} vulnerability - vulnerability to filter by
 * @param {object} incident - incident to filter by
 * @param {object} exercise - exercise to filter by
 * @param {object} evidence - evidence to filter by
 * @param {object} control - control to filter by
 * @param {object} audit - audit to filter by
 * @param {object} meeting - meeting to filter by
 * @param {string} module - module to filter by
 * @param {string} department - department to filter by
 * @param {string} assessment - assessment to filter by
 * @param {boolean} readOnly - whether the grid is read only
 * @param {function} onCreate - function to call when an observation is created
 * @param {object} props - additional props to pass to the grid
 */
export const useObservationDataGrid = ({
  organizationID,
  resetFunction,
  gridConfig = {},
  cardConfig = {},
  queryConfig: initQueryConfig = {},
  queryFields: initQueryFields,
  hiddenFields = [],
  vulnerability,
  incident,
  exercise,
  evidence,
  control,
  audit,
  meeting,
  department,
  module = module || modules.GOVERNANCE,
  assessment,
  readOnly = false,
  onCreate,
  ...props
}) => {
  const typename = "Observation";
  const resource = resources.OBSERVATION;

  initQueryConfig = {
    indexName: "observationsByOwnerGroup",
    variables: { ownerGroup: organizationID },
    limit: 1000,
    ...initQueryConfig,
  };

  const { queryConfig, updateMutation } = getResourceQueryData({
    fieldContext: fieldContexts.GRID,
    overrideQueryFields: initQueryFields,
    overrideQueryConfig: initQueryConfig,
    typename,
  });

  const fields = [
    {
      name: "name",
      description: OBSERVATION?.fieldConfig?.name?.description,
      width: 250,
      minWidth: 200,
      sort: {
        direction: "asc",
        priority: 1,
      },
    },
    {
      name: "description",
      description: OBSERVATION?.fieldConfig?.description?.description,
      width: 250,
      minWidth: 200,
      sort: {
        direction: "asc",
        priority: 1,
      },
      visible: false,
    },
    {
      name: "status",
      description: OBSERVATION?.fieldConfig?.status?.description,
      width: 200,
      component: <Status />,
      plainText: true,
      type: "singleSelect",
      valueOptions: [
        {
          value: "open",
          label: "Open",
        },
        {
          value: "closed",
          label: "Closed",
        },
        {
          value: "error",
          label: "Error",
        },
        {
          value: "pastDue",
          label: "Past Due",
        },
      ],
      bulkEdit: true,
    },
    {
      name: "isFinding",
      component: <IsFinding />,
      description: OBSERVATION?.fieldConfig?.isFinding?.description,
      disablePropagation: true,
      searchNormalizer: (value) => {
        if (value == true) {
          return "finding";
        } else {
          return "observation";
        }
      },
      friendlyName: "Finding / Observation",
      filter: {
        type: "CheckBox",
      },
      width: 150,
      bulkEdit: true,
    },
    {
      name: "risk",
      description: OBSERVATION.fieldConfig.risk.description,
      component: <Risk />,
      disablePropagation: true,
      filter: {
        type: "CheckBox",
      },
      width: 100,
      visible: true,
      bulkEdit: true,
    },
    {
      name: "needsDecision",
      description: OBSERVATION.fieldConfig.needsDecision.description,
      width: 150,
      component: <NeedsDecision />,
      type: "boolean",
      valueOptions: [
        {
          value: "yes",
          label: "Yes",
        },
        {
          value: "no",
          label: "No",
        },
      ],
      bulkEdit: true,
    },
    {
      name: "decision",
      description: OBSERVATION?.fieldConfig?.decision?.description,
      width: 200,
      component: <Decision />,
      type: "singleSelect",
      valueOptions: [
        { value: "accept", label: "Accept" },
        { value: "transfer", label: "Transfer" },
        { value: "avoid", label: "Avoid" },
        { value: "mitigate", label: "Mitigate" },
      ],
      bulkEdit: true,
    },
    {
      name: "estimatedCompletionDate",
      component: <EstimatedCompletionDate />,
      disablePropagation: true,
      width: 200,
      type: "date",
      bulkEdit: true,
    },
    {
      name: "module",
      component: <Module />,
      disablePropagation: true,
      filter: {
        type: "CheckBox",
      },
      width: 100,
      visible: !hiddenFields?.includes("module"),
    },
    {
      name: "externalSource",
      friendlyName: "Source",
      component: <Source module={module} resource={resource} />,
      disablePropagation: true,
      bulkEdit: true,
      bulkEditPreviewGetter: async ({ input }) => {
        //if available query for the the source object when it is selected
        const id = input?.externalSourceID;
        if (id) {
          const getSourceQuery = generateGraphql("Source", ["name"]).getQuery;
          const externalSource = await GetQuery({
            query: getSourceQuery,
            variables: { id },
          });
          return {
            ...input,
            externalSource,
          };
        } else {
          return input;
        }
      },
      visible: !hiddenFields?.includes("externalSource"),
      width: 250,
      valueGetter: (value) => {
        return value?.name;
      },
      searchKeys: ["externalSource.name"],
    },
    {
      name: "formalResponse",
      friendlyName: "Management Response",
      disablePropagation: true,
      visible: false,
      width: 100,
      bulkEdit: true,
    },
    {
      name: "department",
      width: 200,
      plainText: true,
      valueGetter: (_value, row) => {
        return row?.department?.name;
      },
      searchKeys: ["department.name"],
    },
    {
      field: "tags",
      name: "tags",
      minWidth: 100,
      createLinkFunction: createObservationTagLink,
      width: 200,
      bulkEdit: true,
    },
  ];

  const roleConfig = {
    module,
    resource,
  };

  cardConfig = {
    title: "Observations",
    headerIcon: "icon-eye",
    ...cardConfig,
  };

  gridConfig = {
    resetFunction,
    fields,
    typename,
    createResourceComponent: !readOnly ? (
      <CreateObservation
        module={module}
        vulnerability={vulnerability}
        incident={incident}
        exercise={exercise}
        evidence={evidence}
        control={control}
        audit={audit}
        department={department}
        assessment={assessment}
        meeting={meeting}
        callback={onCreate}
      />
    ) : undefined,
    options: ["details", "delete", "edit"],
    deleteFunction: deleteObservation,
    detailsComponent: <ObservationDetails tableDisplay={true} organizationID={organizationID} />,
    deleteOptions: {
      deleteRecommendations: {
        defaultValue: true,
        label: "Delete Recommendations",
        tooltip:
          "This will delete all Recommendations associated with this Observation. Recommendations that are associated with other Observations will not be deleted.",
        inputType: "switch",
      },
      deleteActionItems: {
        defaultValue: true,
        label: "Delete Action Items",
        tooltip:
          "This will delete Action Items associated with the Recommendations that are deleted with this Observation. Action Items that are associated with other Recommendations will not be deleted.",
        inputType: "switch",
        isHidden: ({ input }) => input.deleteRecommendations !== true,
      },
    },
    updateMutation,
    detailsTitle: "Observation Details",
    persistenceUUID: "398889f8-0872-4a65-a572-5073066f7718",
    enablePersistence: true,
    organizationID,
    ...gridConfig,
  };

  const gridCard = useDataGrid({
    ...queryConfig,
    ...gridConfig,
    ...cardConfig,
    ...roleConfig,
    ...props,
  });

  return {
    ...gridCard,
  };
};
