"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleRiskControlChange = void 0;
const appsync_utils_1 = require("@rivial-security/appsync-utils");
const generategraphql_1 = require("@rivial-security/generategraphql");
const getAllSystemsFromRiskControl_1 = require("./getAllSystemsFromRiskControl");
const handleChange_1 = require("../handleChange");
/**
 * Handles all the logic for calculating a Risk Control Change
 * @param change
 * @param organizationID
 * @param queryConfig
 */
const handleRiskControlChange = async (change, organizationID, queryConfig) => {
    const { riskControl, costChange, implementationChange } = change;
    /**
     * Handle Risk Control Change
     */
    if (riskControl?.id) {
        const changeItem = {
            riskControl,
            costChange,
            implementationChange,
        };
        /**
         * Query full RiskControl object with associated systems
         */
        const { getQuery } = (0, generategraphql_1.generateGraphql)("RiskControl", ["statementNumber", "name", "controlCategory"], {
            controlCategory: `
        {
          id
          name
          systems (limit: 250) {
            items {
              id
              system {
                id
                name
                riskControlOverrides {
                  enabledFields
                  riskControlId
                  costOfControl
                  implementationRating
                  outsourced
                }
              }
            }
          }
        }
      `,
        });
        const fullRiskControl = await (0, appsync_utils_1.GetQuery)({
            query: getQuery,
            variables: { id: riskControl.id },
            appsyncEndpoint: process.env.API_RISC_GRAPHQLAPIENDPOINTOUTPUT,
        });
        const associatedSystems = (0, getAllSystemsFromRiskControl_1.getAllSystemsFromRiskControl)(fullRiskControl);
        return await (0, handleChange_1.handleChange)(associatedSystems, changeItem, organizationID, queryConfig);
    }
    throw new Error("handleRiskControlChange has no riskControl ID");
};
exports.handleRiskControlChange = handleRiskControlChange;
