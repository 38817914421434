import React from "react";
import { useEvidenceDataGrid } from "../../../../views/Compliance/Evidence/hooks/useEvidenceGrid/useEvidenceDataGrid";

const EvidenceStatusDropdown = React.lazy(
  () => import("../../../../views/Compliance/Evidence/components/EvidenceStatusDropdown/EvidenceStatusDropdown"),
);

export const evidenceDefinition = {
  routes: {
    grid: "continuous_compliance/evidence",
    details: "continuous_compliance/evidence",
    helpCenter: "evidence",
  },

  //[APPEARANCE]
  icon: "simple-line-icons:badge",
  gridHook: useEvidenceDataGrid,

  fields: {
    itemNumber: {},
    name: {},
    status: {
      default: {
        DisplayComponent: EvidenceStatusDropdown,
      },
    },
    pointOfContacts: {
      default: {
        nestedFields: `(limit: 100) {
          items {
            id
            pointOfContact {
              id
              firstName
              lastName
              email
              title
              user {
                id
              }
              actions(limit: 100) {
                items {
                  id
                  name
                  status
                  createdAt
                  data
                }
              }
            }
          }
        }`,
      },
    },
    frequency: {},
    enabled: {},
    type: {},
    task: {},
    tags: {
      default: {
        nestedFields: `(limit: 100) { items { __typename id tag { id name description fontColor backgroundColor } } }`,
      },
    },
    allowUnauthenticatedSubmissions: {},
  },

  views: {
    grid: {
      queryFields: [
        "itemNumber",
        "name",
        "status",
        "pointOfContacts",
        "frequency",
        "enabled",
        "type",
        "task",
        "tags",
        "allowUnauthenticatedSubmissions",
      ],
    },
  },
};
