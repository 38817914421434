import { useTileDashboard } from "../../../../hooks/views/useTileDashboard";
import ToolAutomations from "./ToolAutomations";
import React from "react";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import ToolCustomResourcePageList from "./ToolCustomResourcePageList";

/**
 * The Default Dashboard displayed for an Integration. This is different from the configuration page with useToolDetails
 *
 * @param {object} toolDetails - a reference from a useToolDetails hook
 * @param {boolean} isTemplate - whether the tool is a template
 * @param {string} organizationID - the organization ID
 * @returns {JSX.Element}
 * @constructor
 */
const ToolDefaultDashboard = ({ toolDetails, isTemplate, organizationID }) => {
  const tileDashboard = useTileDashboard({
    expandTiles: true,
    tiles: [
      {
        id: "custom-resources",
        name: "Resources",
        title: "Resources",
        disableTotals: true,
        icon: "icon-list",
        component: (
          <div key={`tool-custom-resource-types-${toolDetails?.item?.id}`}>
            <ToolCustomResourcePageList
              organizationID={organizationID}
              isTemplate={isTemplate}
              toolID={toolDetails?.item?.id}
              item={toolDetails?.item}
            />
          </div>
        ),
      },
      {
        id: "automations",
        name: "Automations",
        title: "Automations",
        disableTotals: true,
        icon: "icon-energy",
        component: (
          <div style={{ height: "65vh" }}>
            <ToolAutomations item={toolDetails?.item} isTemplate={isTemplate} organizationID={organizationID} />
          </div>
        ),
      },
      {
        id: "configuration",
        name: "configuration",
        title: "Configuration",
        disableTotals: true,
        icon: "icon-settings",
        component: <div>{toolDetails?.display}</div>,
      },
    ],
  });

  return <div>{tileDashboard.display}</div>;
};

export default withOrganizationCheck(ToolDefaultDashboard);
