import { modules, resources } from "@rivial-security/role-utils";
import CreateSource from "../components/CreateSource";
import SourceDetails from "../components/SourceDetails";
import React from "react";
import { deleteSource } from "../functions/deleteSource";
import FindingsReportButton from "../../../Reports/FindingsReport/components/FindingsReportButton";
import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";
import { getResourceQueryData } from "../../../../definitions/functions/getResourceQueryData";
import { fieldContexts } from "../../../../enums/fieldContexts";

/**
 * Displays a list of Sources
 * @param {object} gridConfig
 * @param {object} cardConfig
 * @param {object} initQueryConfig
 * @param {string} organizationID
 * @param {boolean} disableReportButton
 * @param {object} props
 * @returns {{selectedData: [], gridDisplay: JSX.Element, cardDisplay: JSX.Element, data: Object[]|Object, setData: function(*): void, setSelectedData: (value: (((prevState: []) => []) | [])) => void, display: JSX.Element, dashboardDisplay: JSX.Element, changeTargetView: changeTargetView, isLoading: *, createResourceModal: {setModalIsOpen: function(*): void, modalButton: *, modalIsOpen: boolean, modal: *, toggleModal: function(): void}, setIsLoading: (value: (((prevState: *) => *) | *)) => void, resetFunction: *, targetView: *, apiRef: React.MutableRefObject<GridApiPro>, selectedIDs: [], setSelectedIDs: (value: (((prevState: []) => []) | [])) => void, setCheckboxSelection: (value: (((prevState: boolean) => boolean) | boolean)) => void}}
 */
export const useSourceDataGrid = ({
  gridConfig = {},
  cardConfig = {},
  queryConfig: initQueryConfig = {},
  organizationID,
  disableReportButton,
  ...props
}) => {
  const module = modules.GOVERNANCE;
  const resource = resources.SOURCE;
  const typename = resources.SOURCE;

  const fields = [
    {
      name: "name",
      flex: 1,
      sort: {
        direction: "asc",
        priority: 1,
      },
      minWidth: 100,
    },
    {
      name: "description",
      flex: 1,
      minWidth: 150,
    },
  ];

  initQueryConfig = {
    variables: { ownerGroup: organizationID },
    ...initQueryConfig,
  };
  const { updateMutation, queryConfig } = getResourceQueryData({
    fieldContext: fieldContexts.GRID,
    overrideQueryConfig: initQueryConfig,
    typename,
  });

  gridConfig = {
    fields,
    createResourceComponent: <CreateSource />,
    detailsComponent: <SourceDetails />,
    typename,
    module,
    resource,
    deleteFunction: deleteSource,
    deleteOptions: {
      deleteObservations: {
        label: "Delete Observations",
        defaultValue: false,
        inputType: "switch",
        tooltip: "Delete all observations associated with this Source.",
      },
      deleteRecommendations: {
        label: "Delete Recommendations",
        defaultValue: false,
        inputType: "switch",
        tooltip:
          "When deleting Observations, delete recommendations associated with them. If a recommendation is associated with multiple Observations, it will not be deleted.",
        isHidden: ({ input }) => !input.deleteObservations,
      },
      deleteActionItems: {
        label: "Delete Action Items",
        defaultValue: false,
        inputType: "switch",
        tooltip:
          "When deleting Recommendations, delete action items associated with them. If an action item is associated with multiple Recommendations, it will not be deleted.",
        isHidden: ({ input }) => !input.deleteRecommendations,
      },
    },
    persistenceUUID: "source-grid-12345",
    enableContextMenu: true,
    enableMenu: true,
    organizationID,
    options: ["details", "delete"],
    updateMutation,
    ...gridConfig,
    ...props,
  };

  cardConfig = {
    title: "Sources",
    headerIcon: "icon-login",
    headerButtons: disableReportButton === true ? [] : [<FindingsReportButton />],
    ...cardConfig,
  };

  const roleConfig = {
    module,
    resource,
  };

  return useDataGrid({
    ...cardConfig,
    ...gridConfig,
    ...queryConfig,
    ...roleConfig,
    typename,
  });
};
