import { generateGraphql } from "@rivial-security/generategraphql";
import React from "react";
import { DeleteVendorCertification } from "../functions/DeleteVendorCertification";
import AddVendorCertification from "../components/AddVendorCertification";
import VendorCertificationDetails from "../components/VendorCertificationDetails";
import { modules, resources } from "@rivial-security/role-utils";
import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";
import { getResourceAttribute } from "../../../../definitions/functions/getResourceAttribute";

/**
 * Displays a list of Vendor Certifications for an Organization
 * @param {string} organizationID - ownerGroup filter
 * @param {object} ...props       - all other props passed to useDataGrid hook
 */
export const useVendorCertificationDataGrid = ({ organizationID, ...props }) => {
  const module = modules.VENDORS;
  const resource = resources.VENDOR_CERTIFICATION;

  const { listQuery, updateMutation } = generateGraphql("VendorCertification", ["name", "controls"]);

  const { helpCenter: helpCenterRoute } = getResourceAttribute({
    typename: "VendorCertification",
    attribute: "routes",
  });

  const roleConfig = {
    module,
    resource,
  };

  const queryConfig = {
    query: listQuery,
    organizationID: organizationID,
  };

  const cardConfig = {
    title: "Pre-Qualified Certifications",
    headerIcon: "icon-badge",
  };

  const fields = [
    {
      name: "name",
      flex: 1,
      sort: {
        direction: "asc",
        priority: 1,
      },
    },
  ];

  const gridConfig = {
    typename: "Pre-Qualified Certification",
    fields,
    options: ["details", "delete"],
    createResourceComponent: <AddVendorCertification organizationID={organizationID} />,
    deleteFunction: DeleteVendorCertification,
    route: "#/vendors/certification/",
    helpCenterRoute,
    detailsComponent: <VendorCertificationDetails tableDisplay={true} />,
    updateMutation: updateMutation,
    detailsTitle: "Pre-Qualified Certification Details",
    persistenceUUID: "e3911c76-ef4b-4cf8-b2bd-735b3c0932922",
    ...props,
  };

  const queryCard = useDataGrid({
    ...queryConfig,
    ...gridConfig,
    ...cardConfig,
    ...roleConfig,
  });

  return { ...queryCard };
};
