import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { shortenLongText } from "../shortenLongText";

/**
 * Display text with an ellipsis and a tooltip if the text is too long
 * @param {string} text - the text to display
 * @param {number} maxLength - the max length of the text before it is shortened
 * @returns {JSX.Element}
 */
const EllipsisText = ({ text, maxLength = 100 }) => {
  const displayText = text
    ? shortenLongText({
        text: text,
        length: maxLength,
      })
    : "Unknown";
  const isTruncated = text && text.length > maxLength;

  return (
    <Tooltip title={text} placement="top" arrow disableHoverListener={!isTruncated}>
      <span>{displayText}</span>
    </Tooltip>
  );
};

export default EllipsisText;
