import React, { useRef } from "react";
import { UncontrolledTooltip } from "reactstrap";
import PropTypes from "prop-types";

/**
 * An icon with tooltip. Usually used for informational purposes.
 * @param {string} targetId - a unique ID used for this tooltip. If this tooltip is being used multiple times, this is necessary to avoid collisions
 * @param {string} tooltip - the tooltip text to display when hovering over the icon
 * @param {string} [iconClassName = "icon-question"] - changes the icon class
 * @returns {JSX.Element}
 */
const CustomTooltip = ({ targetId, tooltip, iconClassName = "icon-question" }) => {
  const ref = useRef(null);

  return (
    <>
      <i style={{ marginRight: "1em", marginLeft: "1em", cursor: "pointer" }} ref={ref} className={iconClassName} />
      <UncontrolledTooltip placement="auto" target={ref}>
        {typeof tooltip === "string" ? tooltip : "Please access the help center for more information."}
      </UncontrolledTooltip>
    </>
  );
};

CustomTooltip.propTypes = {
  targetId: PropTypes.string, // pass in a unique target ID if multiple tooltips will be rendered at once (e.g. in a list)
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export default CustomTooltip;
