import { ListGroup, ListGroupItem, Progress } from "reactstrap";
import React, { useContext, useReducer, useState } from "react";
import { capitalizeFirstLetter, isNonEmptyArray } from "@rivial-security/func-utils";
import { modules, resources } from "@rivial-security/role-utils";

import Button from "@mui/material/Button";
import DragAndDrop from "../../../../utils/Tools/DragAndDrop";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { OrganizationContext } from "../../../../utils/Context/OrganizationContext";
import PermissionsOverlay from "../../../../utils/Overlays/PermissionsOverlay";
import { UIContext } from "../../../../utils/Context/UIContext";
import { handleFileUploads } from "../../../../utils/GenericComponents/Validation/FileValidation/FileUploadValidation/functions/handleFileUploads";
import { uploadVulnerabilities } from "../functions/uploadVulnerabilities";
import { useAssessmentReportSettings } from "../../Assessments/hooks/useAssessmentReportSettings";
import { useForm } from "../../../../hooks/views/useForm";
import { useModal } from "../../../../hooks/views/useModal";
import { usePleaseWaitModal } from "../../../../hooks/views/usePleaseWaitModal";
import { Scanner } from "@rivial-security/integration-utils";

/**
 * @typedef UseUploadVulnerabilitiesParams
 * @param {string} organizationID - selected organization
 * @param {object} assessment - assessment type object
 * @param {function} toggleModal - function to close the parent modal
 * @param {object[]} filesInit - files objects of input type "file"
 * @param {string} scannerInit - csv scanner name
 * @param {boolean} disableSubmitButtons - disable submit buttons
 * @param {boolean} disableProgressBar - disable progress bar
 * @param {string} module - module this hook belong to
 * @param {string} resource - resource this hook belong to
 */

/**
 * Upload vulnerability scan results
 * @param {UseUploadVulnerabilitiesParams} input
 * @returns {object} {{setModalIsOpen: function(*=): void, modalButton: JSX.Element, modalIsOpen: boolean, modal: JSX.Element}}
 */
export const useUploadVulnerabilities = ({
  organizationID,
  assessment,
  toggleModal,
  files: filesInit,
  scanner: scannerInit,
  disableSubmitButtons,
  disableProgressBar,
  module = modules.VULNERABILITIES,
  resource = resources.VULNERABILITY,
}) => {
  const { addToast, updateToast } = useContext(UIContext);
  const { sentryTrace } = useContext(OrganizationContext);
  const pleaseWait = usePleaseWaitModal();
  const reportSettings = useAssessmentReportSettings();

  const [progress, setProgress] = useState(0);
  const [files, setFiles] = useState(filesInit);
  const [scanner, setScanner] = useState(scannerInit || Scanner.NESSUS);
  const validFiles = handleFileUploads(files, ["csv"]);

  /**
   * Upload scan result
   */
  const uploadCSV = async () => {
    pleaseWait.setModalIsOpen(true);

    setProgress(0);

    const toastId = addToast({
      header: `Importing Findings for ${assessment?.name} Assessment`,
      icon: "spinner",
      color: "success",
    });

    if (assessment && validFiles && isNonEmptyArray(validFiles)) {
      await uploadVulnerabilities({
        files: validFiles,
        organizationID,
        assessmentId: assessment?.id,
        scanner: form?.input?.scanner,
        onprogress: setProgress,
        reportConfig: reportSettings?.reportConfig,
        sentryTrace,
        addToast,
      })
        .then(() => {
          updateToast({
            id: toastId,
            header: (
              <span>
                Uploading Findings ...
                <a href={`#/vulnerabilities/assessments/${assessment?.id}`}>Assessment Details</a>
              </span>
            ),
            icon: "success",
          });
        })
        .finally(() => {
          setProgress(0);
          pleaseWait.setModalIsOpen(false);
          csvImporterModel.setModalIsOpen(false);
          toggleModal && toggleModal();
        });
    } else {
      updateToast({
        id: toastId,
        header: "Something went wrong, can not upload vulnerabilities!",
        icon: "danger",
      });
      pleaseWait.setModalIsOpen(false);
    }
  };

  const getDragAndDropFiles = (files) => {
    if (files && Array.isArray(files)) {
      setFiles(files);
    }
  };

  const form = useForm({
    fieldConfig: {
      scanner: {
        label: "Scanner Name",
        tooltip: "Select a scanner name",
        inputType: "dropdown",
        defaultValue: scannerInit || Scanner.NESSUS,
        onChangeFunction: (e) => setScanner(e.scanner),
        required: true,
        dropdownConfig: {
          data: Object.entries(Scanner).map(([type, value]) => {
            return {
              value: value,
              text: capitalizeFirstLetter(value),
            };
          }),
        },
      },
      files: {
        label: "Select CSV file(s)",
        tooltip: "Select one or multiple CSV file for the selected scanner",
        inputType: "custom",
        customConfig: {
          component: (
            <DragAndDrop
              key={"drag-and-drop-legacy-vulnerability-importer"}
              acceptFileFormat="csv"
              callback={getDragAndDropFiles}
              sizeLimit={314572800} // 300mb
              sizeLimitMessage={
                "This is a very large file, and may take some time to upload. " +
                "If you are experiencing issues with uploading, please try removing " +
                "all 'Informational' Vulnerabilities, and / or split the file " +
                "into multiple smaller chunks and upload one at a time."
              }
            />
          ),
        },
      },
      fileList: {
        label: "CSV files List",
        tooltip: "List on selected CSV files",
        inputType: "custom",
        customConfig: {
          component: <CsvFilesList files={files} setFiles={setFiles} />,
        },
      },
      ...(!disableProgressBar && {
        progressBar: {
          inputType: "custom",
          customConfig: {
            component: (
              <div>
                <div className="text-center">{progress}%</div>
                <Progress value={progress} />
              </div>
            ),
          },
        },
      }),
    },
    ...(disableSubmitButtons && {
      disableSubmitButton: true,
      disableResetButton: true,
    }),
    submitFunction: uploadCSV,
  });

  const display = (
    <PermissionsOverlay module={module} resource={resource} operationType={"update"}>
      <div>
        {form.display}
        {pleaseWait.modal}
      </div>
    </PermissionsOverlay>
  );

  const csvImporterModel = useModal(
    "Import Vulnerabilities",
    display,
    <Button style={{ marginRight: "1em" }} startIcon={<ImportExportIcon />}>
      Import Vulnerabilities
    </Button>,
    { width: "50vw" },
  );

  return {
    ...csvImporterModel,
    files,
    scanner,
    display,
    reportSettings,
  };
};

/**
 * @description Helper component to show a list of selected files
 * @param {object[]} files
 * @param {function} setFiles
 * @param disableRemoveItems
 * @returns {JSX.Element}
 * @constructor
 */
export const CsvFilesList = ({ files, setFiles, disableRemoveItems }) => {
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  return (
    <ListGroup>
      {files &&
        isNonEmptyArray(files) &&
        files.map((file, index) => (
          <ListGroupItem key={`csv-file-${index}`}>
            {file?.name}
            {!disableRemoveItems && (
              <Button
                close
                className="float-right"
                onClick={() => {
                  setFiles((list) => {
                    list.splice(index, 1);
                    return list;
                  });
                  forceUpdate();
                }}
              />
            )}
          </ListGroupItem>
        ))}
    </ListGroup>
  );
};
