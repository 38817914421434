import React, { useState } from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import CreateRiskControlChange from "./RiskControlChange/components/CreateRiskControlChange";
import CreateKeyRiskIndicatorChange from "./KeyRiskIndicatorChange/components/CreateKeyRiskIndicatorChange";
import CreateAssetSizeChange from "./AssetSizeChange/components/CreateAssetSizeChange";
import CreateNumberOfCustomersChange from "./NumberOfCustomersChange/components/CreateNumberOfCustomersChange";
import CreateSystemAvailabilityChange from "./SystemAvailabilityChange/components/CreateSystemAvailabilityChange";
import CreateSystemAssetsChange from "./SystemAssetsChange/components/CreateSystemAssetsChange";
import CreateManualChange from "./ManualChange/components/CreateManualChange";

/**
 * Allows the user to select a specific type of risk change.
 * Each type of risk change has it's own Inputs.
 * @param {object} props
 * @param {object} [props.item] - Optional. If an item prop is passed in, the 'Manual Change' option is disabled
 * @returns {*}
 * @constructor
 */
const SelectChangeType = (props) => {
  const [selectedType, setSelectedType] = useState(null);

  const selectionTypes = [
    !props.item ? { name: "Manual Change" } : null,
    { name: "Risk Control Change" },
    { name: "Key Risk Indicator Change" },
    // {name: "System Change - Assets"},
    // {name: "System Change - Availability Rating"},
    { name: "Organization Change - Asset Size" },
    { name: "Organization Change - Number of Customers/Members" },
  ];

  /**
   * If this is already a manual change, don't include it as an option
   */
  if (!selectionTypes[0]) {
    selectionTypes.shift();
  }

  return (
    <>
      {selectedType ? (
        <ListGroupItem action tag="button" onClick={() => setSelectedType(null)} style={{ marginBottom: "1em" }}>
          <h3>{selectedType}</h3>
        </ListGroupItem>
      ) : (
        <ListGroup>
          <h3>What type of change is this?</h3>
          {selectionTypes.map((item, index) => (
            <ListGroupItem key={index} action tag="button" onClick={() => setSelectedType(item && item.name)}>
              {item && item.name}
              {item && item.description && <small style={{ marginLeft: "2em" }}>{item.description}</small>}
            </ListGroupItem>
          ))}
        </ListGroup>
      )}
      {(() => {
        switch (selectedType) {
          case "Manual Change":
            return <CreateManualChange {...props} />;
          case "Risk Control Change":
            return <CreateRiskControlChange {...props} />;
          case "Key Risk Indicator Change":
            return <CreateKeyRiskIndicatorChange {...props} />;
          case "Organization Change - Asset Size":
            return <CreateAssetSizeChange {...props} />;
          case "Organization Change - Number of Customers/Members":
            return <CreateNumberOfCustomersChange {...props} />;
          case "System Change - Availability Rating":
            return <CreateSystemAvailabilityChange {...props} />;
          case "System Change - Assets":
            return <CreateSystemAssetsChange {...props} />;
          default:
            return null;
        }
      })()}
    </>
  );
};

export default SelectChangeType;
