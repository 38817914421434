import React from "react";
import OrganizationCheck from "./OrganizationCheck/OrganizationCheck";
/**
 * @description A HOC for more easily using the OrganizationCheck logic.
 * @param {React.Component || JSX.Element || function(Object): JSX.Element} Component - The component to wrap.
 * @returns {function(*)}
 * @example - basic usage
 const SomeList = ({organizationID}) => {
    return (<div>{organizationID}</div>)
   };

 export default withOrganizationCheck(SomeList);
 */
export const withOrganizationCheck = (Component) => (props) => {
  return (
    <OrganizationCheck {...props}>
      <Component />
    </OrganizationCheck>
  );
};
