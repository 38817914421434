import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/styles";

/**
 * @description A basic tooltip where the text is underlined
 * @param {JSX.Element} children - the content to display in the popup tooltip
 * @param {function} onClick - the function to call when the tooltip is clicked
 * @param {string} text - the text that is underlined and used as the tooltip target
 * @param {string} title - html title attribute
 * @param {object} style - style object to apply to the underlined text
 */
const UnderlinedTooltip = ({ children, text, title, onClick = () => {}, style = {} }) => {
  const underlineStyle = {
    borderBottom: "1.2px dashed",
    textDecoration: "none",
    cursor: "pointer",
    ...style,
  };

  return (
    <span title={title}>
      <NoMaxWidthTooltip title={children} interactive={true} PopperProps={{ maxWidth: "none" }}>
        <span
          style={underlineStyle}
          onClick={() => {
            onClick && onClick();
          }}
        >
          {text}
        </span>
      </NoMaxWidthTooltip>
    </span>
  );
};

const NoMaxWidthTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .MuiTooltip-tooltip`]: {
    maxWidth: "60vw",
    overflow: "auto",
    maxHeight: "40em",
  },
});

export default UnderlinedTooltip;
