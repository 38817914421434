import React, { useEffect, useState } from "react";

import { getPointOfContactMostRecentActionItem } from "../functions/getPointOfContactMostRecentActionItem";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useActionItemDetails } from "../../../../../Program/Actions/hooks/useActionItemDetails";

/**
 * Displays a list of Action Items for an Evidence for a Point of Contact
 * @param {object} evidence - the evidence for which to filter action items
 * @param {object} pointOfContact - data on the point of contact attached to the evidence
 */
export const useEvidencePointOfContactAction = (evidence, pointOfContact) => {
  const [actionItem, setActionItem] = useState(null);

  useEffect(() => {
    const pointOfContactActionItems = pointOfContact?.actions?.items || [];

    // get the most recent action item
    const mostRecentActionItem = getPointOfContactMostRecentActionItem({
      actionItems: pointOfContactActionItems,
      evidenceID: evidence?.id,
    });

    setActionItem(mostRecentActionItem);
  }, [pointOfContact, evidence]);

  const details = useActionItemDetails({ itemId: actionItem?.id });

  return {
    ...details,
    display: !isNullOrUndefined(actionItem) ? (
      details.display
    ) : (
      <div>
        {pointOfContact?.firstName || "This point of contact"} has no outstanding action items for this Evidence
      </div>
    ),
    tableDisplay: !isNullOrUndefined(actionItem) ? (
      details.tableDisplay
    ) : (
      <div>
        {pointOfContact?.firstName || "This point of contact"} has no outstanding action items for this Evidence
      </div>
    ),
  };
};
