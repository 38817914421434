import Axios from "axios";
import { ErrorLogger } from "@utils/EventLogger";
import { platformSettings } from "../../../../platformSettings";

/**
 * Pulls document from URL and loads it to the document editor
 * @param {string} url - URL of the document to be loaded
 * @param {boolean} isSFDT - TRUE if the url is of a file that's already an sfdt
 * @param {string} - the SFDT data to load into a document editor
 */
export const generateSFDTFromURL = async ({ url, isSFDT = false }) => {
  if (url) {
    const getFileToDownload = async (apiUrl) => {
      return Axios.get(apiUrl, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
        },
      }).catch((err) => {
        ErrorLogger("Error! Can not get document! ", err);
      });
    };

    try {
      const response = await getFileToDownload(url);

      const type = response.headers["content-type"];
      const blob = new Blob([response.data], { type: type, encoding: "UTF-8" });

      if (isSFDT === true) {
        // Read the file contents
        const file = new File([blob], `document.sfdt`);
        const reader = new FileReader();
        const readerPromise = new Promise((resolve, reject) => {
          reader.onload = (e) => {
            const fileText = e?.target?.result;
            const sfdt = JSON.parse(fileText);
            resolve(sfdt);
          };
          reader.onerror = (err) => {
            reject(err);
          };
        });
        reader.readAsText(file);

        // Load file contents into the document editor
        const loadResult = await readerPromise;
        return loadResult;
      } else {
        const file = new File([blob], `document.docx`);
        const data = new FormData();
        data.append("file", file);
        const url = `${platformSettings.syncfusionServiceUrl}/api/documenteditor/Import`;
        try {
          const res = await Axios.post(url, data);
          const responseText = res?.request?.responseText;
          if (responseText) {
            return JSON.parse(responseText);
          }
        } catch (e) {
          ErrorLogger("Error! Failed post request to Syncfusion API!", e);
        }
      }
    } catch (err) {
      ErrorLogger("Error! generateSFDTFromURL function", err);
    }
  }
};
