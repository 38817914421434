import React from "react";
import { useExerciseDataGrid } from "../../../../views/Response/Exercises/hooks/useExerciseDataGrid";
import { modules, resources } from "@rivial-security/role-utils";
import { useExerciseDetails } from "../../../../views/Response/Exercises/hooks/useExerciseDetails";
import { useCreateExercise } from "../../../../views/Response/Exercises/hooks/useCreateExercise";
import { generatedNestedFieldsForDefinition } from "../../../functions/queries/generatedNestedFieldsForDefinition";
import { noteDefinition } from "../common/note";

const CreateExercise = React.lazy(() => import("../../../../views/Response/Exercises/components/CreateExercise"));
const ExerciseDetails = React.lazy(() => import("../../../../views/Response/Exercises/components/ExerciseDetails"));
const ExerciseGrid = React.lazy(() => import("../../../../views/Response/Exercises/components/ExerciseGrid"));

export const exerciseDefinition = {
  typename: resources.EXERCISE,
  module: modules.INCIDENT_RESPONSE,
  resource: resources.EXERCISE,
  routes: {
    grid: "response/exercises",
    details: "response/exercises",
    helpCenter: "incident-response-exercises",
  },

  //[APPEARANCE]
  icon: "simple-line-icons:note",

  //[COMPONENTS]
  gridComponent: <ExerciseGrid />,
  formComponent: <CreateExercise />,
  detailsComponent: <ExerciseDetails tableDisplay={true} />,

  //[HOOKS]
  gridHook: useExerciseDataGrid,
  formHook: useCreateExercise,
  detailsHook: useExerciseDetails,

  //[FIELDS]
  fields: {
    name: {},
    description: {},
    scenario: {},
    meeting: {
      default: {
        nestedFields: `(limit: 100) { items { id name description status } }`,
      },
    },
    meetingID: {
      default: {
        description: "The meeting that this exercise is associated with",
      },
    },
    accessControl: {
      default: {
        nestedFields: `{ password passwordOwnerEmail roles pointOfContacts }`,
      },
    },
    tags: {
      default: {
        nestedFields: `(limit: 100) { items { __typename id tag { id name description fontColor backgroundColor } } }`,
      },
    },
    observations: {
      default: {
        nestedFields: `(limit: 100) { items { __typename id observation { id name description } } }`,
      },
    },
    documents: {
      default: {
        nestedFields: `(limit: 100) { items { __typename id document { id name description } } }`,
      },
    },
    templateID: {},
    notes: {
      default: {
        nestedFields: generatedNestedFieldsForDefinition({
          definition: noteDefinition,
        }),
      },
    },
  },

  views: {
    grid: {
      queryFields: ["name", "description", "scenario", "accessControl"],
    },
  },
};
