import { modules, resources } from "@rivial-security/role-utils";

import PermissionsOverlay from "../../../../../utils/Overlays/PermissionsOverlay";
import Settings from "../../../../../views/Settings/Settings";
import { useModal } from "../../../../../hooks/views/useModal";

/**
 * Modal used to show user settings
 * @return {import('@hooks/views/useModal').UseModalReturnType}
 */
export const useUserSettingsModal = () => {
  const settingsModal = useModal(
    <h4 className={"dashboard-title"}>User Settings</h4>,
    <PermissionsOverlay module={modules.ACCOUNT_MANAGER} resource={resources.PREFERENCES}>
      <Settings />
    </PermissionsOverlay>,
    null,
    {
      width: "70vw",
    },
  );

  return settingsModal;
};
