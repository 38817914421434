import NumberChange from "../../../../utils/CustomFields/NumberChange";
import React from "react";

const OverallResidualChange = ({ item }) => {
  const value = item && item.change && item.change.overallROIChange ? item.change.overallResidualChange : 0;

  return (
    <>
      <NumberChange value={value} format="dollar" positiveNumber="red" negativeNumber="green" />
    </>
  );
};

export default OverallResidualChange;
