import React, { useEffect } from "react";

import ControlTemplateDetails from "../../../../Templates/ControlTemplates/ControlTemplateDetails";
import { ErrorLogger } from "@utils/EventLogger";
import NotEnoughData from "../../../../../utils/GenericComponents/NotEnoughData";
import { deleteControlEvidenceLink } from "../../../ControlEvidenceLinks/functions/deleteControlEvidenceLink";
import { normalizeRowIDs } from "../../../../../hooks/views/useDataGrid/functions/normalizeRowIDs";
import { submitRiskComplianceSyncJob } from "../../functions/submitRiskComplianceSyncJob";
import { updateControlOverrideType } from "../../enums/updateControlOverrideType";
import { useControlDataGrid } from "../../../Controls/Controls/hooks/useControlGrid/useControlDataGrid";
import { useOrganizationConfig } from "../../../../AdminPanel/Organizations/hooks/useOrganizationConfig";
import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";

/**
 * Displays a grid of Associated Controls for an evidence item
 * @param {string} organizationID - the organization/operation team for which to show controls under this evidence
 * @param {boolean} disableRoleChecking=false - if TRUE will disable role checking
 * @param {boolean} isTemplate - if TRUE will hide non-template resource data
 * @param {Evidence} item - the Evidence Item
 * @param {function} [callToAction] - a call to action function if no controls exist
 * @param {function} resetFunction - reset state of the parent
 * @returns {JSX.Element}
 */
const AssociatedControls = ({
  organizationID,
  disableRoleChecking = false,
  isTemplate = false,

  item,
  callToAction,
  resetFunction,
}) => {
  const groupSettings = {
    columns: ["Control Set"],
    showDropArea: false,
    captionTemplate: "${key} - ${count} Items",
    defaultCollapsed: true,
  };

  // used to add the control set field
  const otherFields = [
    {
      name: "Control Set",
      flex: 1,
      minWidth: 200,
    },
  ];

  const nestedFields = {
    controlSet: `{id name}`,
  };

  const cardConfig = {
    enableSticky: false,
    title: "Associated Controls",
  };

  const gridConfig = {
    gridHeight: "100%",
    allowPaging: false,
    enableMenu: false,
    ...(isTemplate && {
      detailsComponent: <ControlTemplateDetails disableRoleChecking={disableRoleChecking} />,
    }),
    enableQuickDetails: true,
    createResourceComponent: null,
    deleteFunction: async (linkToDelete) => {
      const removedLink = await deleteControlEvidenceLink(linkToDelete?.controlLinkID);
      //trigger a control check since one evidence was essentially put into place (no need to wait for result)
      if (linkToDelete?.controlID && !isTemplate) {
        submitRiskComplianceSyncJob({
          evidenceID: item?.id,
          organizationID,
          controlOverrideID: linkToDelete?.controlID,
          overrideType: updateControlOverrideType.EVIDENCE_UNLINKED,
        });
      } else {
        ErrorLogger(
          `Cannot check control upon deleting evidence - control link!${JSON.stringify({
            linkToDelete,
            item,
          })}`,
        );
      }
      resetFunction?.();
      return removedLink;
    },
    deleteFunctionNote: "This will unlink this Control from the Evidence. This will not delete the Control itself.",
    deleteButtonText: "Unlink",
    deleteButtonTitle: "Unlink",
    groupSettings,
    allowGrouping: true,
    enablePersistence: false,
    persistenceUUID: `associated-control-grid-${item?.id}`,
  };

  const queryConfig = {
    query: null,
  };

  const controlGrid = useControlDataGrid({
    organizationID,
    queryConfig,
    cardConfig,
    gridConfig,
    otherFields,
    nestedFields,
    disableRoleChecking,
    isTemplate,
    isInAudit: true, //reusing this param to perform no query inside the grid
  });

  const { value: includeDisabledControlsInDataGrids } = useOrganizationConfig({
    organizationID,
    key: "includeDisabledControlsInDataGrids",
  });

  // updates the evidence grid to match control
  useEffect(() => {
    controlGrid.setIsLoading(true);
    const controlLinks = item?.controls?.items;
    if (Array.isArray(controlLinks)) {
      const controls = controlLinks
        .filter((controlLink) => {
          if (!includeDisabledControlsInDataGrids) {
            return controlLink?.control?.isDisabled !== true;
          } else {
            return true;
          }
        })
        .map((controlLink) => {
          return {
            ...controlLink.control,
            controlLinkID: controlLink?.id,
            "Control Set": controlLink?.control?.controlSet?.name,
          };
        });

      // strips any rows that don't have an ID (indicating broken links)
      const normalizedControls = normalizeRowIDs({
        items: controls,
      });

      controlGrid.setData([...normalizedControls]);
    }

    controlGrid.setIsLoading(false);
  }, [item, includeDisabledControlsInDataGrids]);

  return (
    <span>
      {controlGrid.data.length > 0 || controlGrid?.tagFilterApplied === true ? (
        <div style={{ height: "45em" }}>{controlGrid.gridDisplay}</div>
      ) : (
        <NotEnoughData
          message={"This Evidence is not currently linked to any Controls."}
          callToAction={
            callToAction && {
              function: callToAction,
              message: " to get started",
            }
          }
        />
      )}
    </span>
  );
};

export default withOrganizationCheck(AssociatedControls);
