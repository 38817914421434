import DatePicker from "react-datepicker";
import React from "react";

/**
 * Custom Date Picker Component
 * @param {function} onChange - function to call when date is changed
 * @param {string} defaultValue - default value of the date picker
 * @param {string} value - value of the date picker
 * @param {object} dateConfig - configuration for the date picker
 * @param {string} fieldName - name of the field
 * @return {JSX.Element}
 * @constructor
 */
const CustomDatePicker = ({ onChange, defaultValue, value = defaultValue, dateConfig, fieldName }) => {
  return (
    <div data-testid={`custom_input_date${fieldName}`}>
      <DatePicker
        id={`custom_input_date${fieldName}`}
        dateFormat="MMMM d, yyyy h:mm aa"
        placeholderText="Select a Date and Time"
        showTimeInput
        minDate={dateConfig?.minDate}
        maxDate={dateConfig?.maxDate}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        selected={!isNaN(new Date(value).getTime()) ? new Date(value) : undefined}
        onChange={(date) => {
          // makes sure the input is actually blank instead of showing 1969.
          // this component displays a blank input when the value is "" but will display 1969 if the value is null
          // the submitFunction in the useForm hook itself will handle removing the "" value from the mutation input to avoid the graphql error
          if (date === null) {
            onChange && onChange("");
          } else {
            onChange && onChange(date);
          }
        }}
      />
    </div>
  );
};

export default CustomDatePicker;
