import React from "react";
import { modules, resources } from "@rivial-security/role-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useDetailsCard } from "../../../hooks/views/useDetailsCardV2";
import { withDetailsPage } from "../../../utils/Context/withDetailsPage";
import EmailBody from "../../OrganizationManager/EmailTemplates/components/EmailBody";

/**
 * Custom details component for Email Templates.
 *
 * @param {string} organizationID - ownerGroup to use for creating/querying resources
 * @param {string} module - module to use for creating/querying resources
 * @param {string} resource - resource to use for creating/querying resources
 * @param {boolean} disableRoleChecking - if TRUE will disable role checking
 * @param {string} itemId - id of the evidence template
 * @param {object} item - optionally pass an item directly with id instead of itemId
 * @param {object} props - any additional props passed through to the details card
 * @return {JSX.Element}
 */
const EmailTemplateDetails = ({
  organizationID,
  module = modules.ORGANIZATION_MANAGER,
  resource = resources.EMAIL_TEMPLATE,
  disableRoleChecking = false,
  itemId,
  item,
  ...props
}) => {
  const typename = "Email";

  const { getQuery, updateMutation } = generateGraphql("Email", [
    "name",
    "recipients",
    "replyTo",
    "subject",
    "body",
    "design",
  ]);

  const queryConfig = {
    itemId,
    query: getQuery,
  };

  return useDetailsCard({
    organizationID,
    module,
    resource,
    typename,
    queryConfig,
    updateMutation,
    config: {
      detailsComponent: <EmailBody organizationID={organizationID} />,
    },
  });
};

export default withDetailsPage(EmailTemplateDetails);
