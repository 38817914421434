import { Button, Col, ListGroupItem, Row } from "reactstrap";
import GenericEditField from "../../../../../utils/GenericComponents/GenericEditFieldV2";
import { updateAgendaItemMeeting } from "../../graphql/__meetingsGQL";
import { deleteAgendaItem } from "../../functions/deleteAgendaItem";
import NotesV2 from "../../../../../utils/Notes/components/NotesV2";
import React from "react";
import { modules, resources } from "@rivial-security/role-utils";

/**
 * @deprecated use MeetingAgendaV2
 *
 * @description Display meeting agenda component
 * @param {object} item - meeting
 * @param {function} resetFunction - reset state function
 * @returns {JSX.Element}
 */
const MeetingAgenda = ({ item, resetFunction }) => {
  const module = modules.GOVERNANCE;
  const resource = resources.MEETING;

  return (
    <div>
      {item?.agendaItems?.items?.length > 0 ? (
        item.agendaItems.items.map((item, index) => (
          <ListGroupItem key={`meeting_agenda-items${index}`}>
            <Row>
              <Col>
                <div>
                  <h5>
                    <strong>
                      <span>&#8226;</span>{" "}
                      {
                        <GenericEditField
                          item={item}
                          field={"content"}
                          mutation={updateAgendaItemMeeting}
                          resource={resource}
                          module={module}
                          resetFunction={resetFunction}
                          disableRoleChecking={true}
                        />
                      }
                    </strong>
                    {
                      <Button
                        className="float-right"
                        close
                        onClick={async () => {
                          if (window.confirm(`Are you sure you want to delete this agenda item?`)) {
                            await deleteAgendaItem(item);
                            resetFunction && resetFunction();
                          }
                        }}
                      />
                    }
                  </h5>
                  <div style={{ paddingLeft: "2em" }}>
                    <NotesV2
                      key={`agenda-item-notes-${index}`}
                      item={item}
                      module={module}
                      resource={resource}
                      mutation={updateAgendaItemMeeting}
                      resetFunction={resetFunction}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </ListGroupItem>
        ))
      ) : (
        <h5>No Agenda Items</h5>
      )}
    </div>
  );
};

export default MeetingAgenda;
