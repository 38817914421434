import { Button } from "reactstrap";
import React from "react";
import { useModal } from "../../../../hooks/views/useModal";

/**
 * Buttons that allow the user to Confirm or Cancel an Edit
 *
 * @param {string} field - the field being updated
 * @param {function} cancel - cancels the update
 * @param {function} toggle - saves the update and toggles back to display state
 * @param {object} inputConfig - configuration object for the input
 * @param {*} value - current value
 * @param {JSX.Element} editSettingsComponent - component to show for editing settings of mutation
 * @returns {JSX.Element}
 */
const OptionsButtons = ({ field, cancel, toggle, inputConfig, value, editSettingsComponent }) => {
  const settingsModal = useModal(
    "Edit Settings",
    editSettingsComponent,
    <Button
      style={{ color: "white", marginLeft: "5px" }}
      data-testid={`button-edit-settings-field-${field}`}
      size="sm"
      color="info"
      className="btn-pill"
      onClick={(e) => cancel(e)}
      title="Open Edit Settings"
    >
      <i className="icon-settings" />
    </Button>,
    {
      width: "80vw",
    },
  );

  return (
    <span>
      <Button
        data-testid={`button-submit-field-${field}`}
        size="sm"
        color="success"
        className="btn-pill"
        onClick={async (e) => await toggle(e)}
        title="Submit this change"
        disabled={inputConfig?.isValidInput && !inputConfig.isValidInput(value)}
        style={{ marginRight: "5px" }}
      >
        <i className="icon-check" />
      </Button>
      <Button
        data-testid={`button-cancel-field-${field}`}
        size="sm"
        color="danger"
        className="btn-pill"
        onClick={(e) => cancel(e)}
        title="Cancel this change"
      >
        <i className="icon-close" />
      </Button>
      {editSettingsComponent ? settingsModal.modalButton : <> </>}
    </span>
  );
};

export default OptionsButtons;
