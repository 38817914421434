import { ErrorLogger } from "@utils/EventLogger";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { generateGraphql } from "@rivial-security/generategraphql";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Creates a RecommendationTagLink object
 * @param {object} recommendation - the recommendation that needs to be attached to the tag
 * @param {object} tag - the tag that needs to be attached to the recommendation
 * @param {string} organizationID - the organization identifier of the recommendation in the database
 * @return {Promise<*>}
 */
export const createRecommendationTagLink = (recommendation, tag, organizationID) => {
  if (isNullOrUndefined(tag) || isNullOrUndefined(recommendation?.id)) {
    ErrorLogger("Invalid input!");
  }

  const { createMutation: createRecommendationTagLink } = generateGraphql("RecommendationTagLink", [
    "recommendationID",
    "tagID",
    "__typename",
  ]);

  return ItemMutation(createRecommendationTagLink, {
    recommendationID: recommendation.id,
    tagID: tag.id,
    ownerGroup: organizationID,
  });
};
