import { useSourceDataGrid } from "../../../../views/Program/Sources/hooks/useSourceDataGrid";
import { noteDefinition } from "../common/note";
import { generatedNestedFieldsForDefinition } from "../../../functions/queries/generatedNestedFieldsForDefinition";

export const sourceDefinition = {
  routes: {
    grid: "governance/sources",
    details: "governance/sources",
  },

  schema: {
    indexNames: {
      byOwnerGroup: "sourcesByOwnerGroup",
    },
  },

  //[APPEARANCE]
  displayName: "Source",
  icon: "simple-line-icons:login",

  //[COMPONENTS]
  gridHook: useSourceDataGrid,

  fields: {
    name: {
      default: {},
    },
    description: {
      default: {},
    },
    organization: {
      default: {},
    },
    framework: {
      default: {},
    },
    date: {
      default: {},
    },
    notes: {
      default: {
        nestedFields: generatedNestedFieldsForDefinition({
          definition: noteDefinition,
        }),
      },
    },
  },

  views: {
    grid: {
      queryFields: ["name", "description"],
    },
    details: {
      queryFields: ["name", "description", "organization", "framework", "date", "notes"],
      displayFields: ["name", "description", "organization", "framework", "date"],
    },
  },
};
