import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";
import { modules, resources } from "@rivial-security/role-utils";
import JobDetails from "../components/JobDetails";
import React from "react";
import { generateGraphql } from "@rivial-security/generategraphql";
import { mergeAdditionalFields } from "../../../../hooks/views/useGrid/functions/mergeAdditionalFields";

/**
 * Displays a list of Jobs.
 * - Can be a full list of jobs for an org using byOwnerGroup
 * - Can be a list of jobs for an org by a certain resource using bySourceID
 * @param {string} organizationID - the ID of the organization
 * @param {string} itemId - the source id to use in the filter for jobs
 * @param {object[]} additionalFields - field configurations to merge into the default fields
 * @param {string} module - the module name (e.g. "organizationManager")
 * @param {string} resource - the resource name (e.g. "Job")
 * @param {object} props - additional props passed down to useDataGrid
 * @returns {{selectedData: *, gridDisplay: JSX.Element, cardDisplay: JSX.Element, data: *, setData: *, setSelectedData: *, display: JSX.Element, dashboardDisplay: JSX.Element, isLoading: *, createResourceModal: {setModalIsOpen: *, modalButton: JSX.Element, modalIsOpen: *, modal: JSX.Element, toggleModal: function(): void}, setIsLoading: *, resetFunction: function(boolean=): Promise<void>, selectedIDs: *, setCheckboxSelection: *}}
 */
export const useJobsGrid = ({
  organizationID,
  itemId,
  additionalFields,
  module = module || modules.ORGANIZATION_MANAGER,
  resource = resource || resources.JOB,
  ...props
}) => {
  const typename = "Job";
  const route = "#/organization_manager/jobs/";

  const jobsByOwnerGroupQuery = ``;
  const jobsByOwnerGroupBySourceQuery = "";
  const jobsBySourceIDQuery = "";

  const { listQuery } = generateGraphql("Job", ["status", "name", "createdAt", "completedAt", "source"]);

  const fields = [
    {
      name: "status",
      flex: 0.5,
      format: "sentence",
      type: "singleSelect",
      valueOptions: ["completed", "started", "error"],
    },
    {
      name: "name",
      flex: 1.5,
    },
    {
      name: "createdAt",
      flex: 0.75,
      type: "date",
      visible: false,
      sort: {
        direction: "desc",
        priority: 1,
      },
    },
    {
      name: "completedAt",
      flex: 0.75,
      type: "date",
      visible: false,
    },
    {
      name: "source",
      flex: 0.75,
    },
  ];
  mergeAdditionalFields({ additionalFields, fields });

  const dataGrid = useDataGrid({
    module,
    resource,
    typename,
    fields,
    detailsComponent: <JobDetails module={module} resource={resource} />,
    organizationID,
    persistenceUUID: "jobGrid-2657-897535-8878-5555",
    query: listQuery,
    filters: itemId ? { sourceID: { eq: itemId } } : undefined,
    route,
    ...props,
  });

  return dataGrid;
};
