import { ErrorLogger } from "@utils/EventLogger";
import { ListQueryBy } from "../../../../utils/Functions/Graphql/ListQueryBy";
import { combineChildQueryData } from "./combineChildQueryData";
import { formattedName } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { getComplianceActivityData } from "./getComplianceActivityData";
import { listEvidenceActivitiesByOwnerGroupForArtifact } from "../constants/listEvidenceActivitiesByOwnerGroupForArtifact";
import { noItemsRootNode } from "../constants/noItemsRootNode";

/**
 * Retrieves a nested array of Point of Contact Compliance Documents for an organization
 *
 * Point of Contact -> Evidence -> Documents
 *
 * @param {string} organizationID - organization ID to retrieve documents for
 * @param {string} rootName - Name of the root folder
 * @param {object} dateRange - Date range for the data filter
 * @param {Date} dateRange.startDate - Start date for the data filter
 * @param {Date} dateRange.endDate - End date for the data filter
 * @returns {Promise<{children: *, name: string, id: string, type: string}>}
 */
export const documentsByPointOfContact = async ({ organizationID, rootName, dateRange }) => {
  const contactListPromise = ListQueryBy({
    query: contactsByOwnerGroup,
    variables: {
      ownerGroup: organizationID,
    },
  });
  const evidenceActivityListPromise = ListQueryBy({
    query: listEvidenceActivitiesByOwnerGroupForArtifact,
    variables: {
      ownerGroup: organizationID,
    },
  });
  await Promise.allSettled([contactListPromise, evidenceActivityListPromise]);

  const contactList = await contactListPromise;
  const evidenceActivityListOnly = await evidenceActivityListPromise;
  if (!Array.isArray(contactList) || contactList.length === 0) {
    ErrorLogger("[documentsByPointOfContact.js] - contacts is not an array");
    return noItemsRootNode;
  }

  for (const contact of contactList) {
    const evidenceLinks = contact?.evidences?.items;
    if (!evidenceLinks) {
      continue;
    }
    const evidences = evidenceLinks.map((link) => {
      return {
        id: link?.evidenceID,
      };
    });

    contact.evidences.items = combineChildQueryData({
      parents: evidences,
      children: evidenceActivityListOnly,
      childFieldInParent: "activity",
      parentIDFieldInChild: "evidenceID",
    });
  }

  // Parse over all the retrieved data placing the documents into the correct bins
  const contactsFlatten = [];
  if (Array.isArray(contactList)) {
    for (const contact of contactList) {
      contactsFlatten.push({
        ...contact,
        name: formattedName({ pointOfContact: contact }),
        activity: {
          items: contact?.evidences?.items?.reduce((acc, evidence) => {
            return acc.concat(
              evidence?.activity?.items?.filter(
                (item) => item?.ownerGroup === organizationID, // Skip Evidence Activity if its from other organization
              ),
            );
          }, []),
        },
      });
    }
  }

  return getComplianceActivityData({
    items: contactsFlatten,
    rootName,
    dateRange,
  });
};

const contactsByOwnerGroup = generateGraphql(
  "PointOfContact",
  ["id", "firstName", "lastName", "email", "ownerGroup", "evidences"],
  {
    evidences: `(limit: 950) {
      items {
        id
        evidenceID
      }
    }`,
  },
  { indexName: "contactsByOwnerGroup", partitionKey: "ownerGroup" },
).listByQuery;
