import { ListQueryBy } from "../../../../utils/Functions/Graphql/ListQueryBy";
import { listSystemOverridesByOwnerGroup } from "../graphql/listSystemOverridesByOwnerGroup";
import { listSystemRiskOverrides } from "./listSystemRiskOverrides";

/**
 * Queries for all overrides inside an organization, includes risk control information with each override
 * @param {string} organizationID - the organization for which to get overrides
 * @return {Promise<object[]>} - list of risk control overrides for an organization
 */
export const listOrganizationRiskOverrides = async ({ organizationID } = {}) => {
  //Check arguments
  if (!organizationID) {
    return [];
  }

  //Query all systems risk control overrides and KRIs within the organization
  const systems = await ListQueryBy({
    query: listSystemOverridesByOwnerGroup,
    variables: {
      ownerGroup: organizationID,
    },
    limit: 200,
  });

  //parse all active override from system's info
  const organizationRiskControlOverrides = [];
  if (Array.isArray(systems)) {
    for (const system of systems) {
      const systemRiskControlOverrides = await listSystemRiskOverrides({
        system,
      });
      organizationRiskControlOverrides.push(...systemRiskControlOverrides);
    }
  }

  return organizationRiskControlOverrides;
};
