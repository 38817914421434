import React from "react";
import UnderlinedTooltip from "../../../../../../utils/GenericComponents/Tooltips/UnderlinedTooltip";
import VulnerabilityDetails from "../../VulnerabilityDetails";
import AssessmentDetails from "../../../../Assessments/components/AssessmentDetails";
import TargetDetails from "../../../../Targets/components/TargetDetails";
import { useModal } from "../../../../../../hooks/views/useModal";
import { resources } from "@rivial-security/role-utils";

/**
 * Component to display underlined details in the timeline content which opens a details modal when clicked
 * @param {string} tooltipText - the text to display in the tooltip
 * @param {JSX.Element} children - the children to display
 * @param {object} item - the TargetVulnerabilityHistory item
 * @param {string} modalType - the type of modal to display
 * @param {function} onClick - the onClick function to run when the children are clicked (optional)
 * @returns {JSX.Element}
 */
const TimelineUnderlinedDetails = ({ tooltipText, children, item, modalType, onClick }) => {
  let details, modalTitle;

  switch (modalType) {
    case resources.TARGET:
      details = item?.targetID && <TargetDetails item={{ id: item?.targetID }} assessmentID={item?.assessmentID} />;
      modalTitle = "Target Details";
      break;
    case resources.ASSESSMENT:
      details = <AssessmentDetails item={{ id: item?.assessmentID }} />;
      modalTitle = "Assessment Details";
      break;
    case resources.VULNERABILITY:
      details = item?.vulnerabilityID && (
        <VulnerabilityDetails
          item={{ id: item?.vulnerabilityID }}
          assessmentID={item?.assessmentID}
          organizationID={item?.ownerGroup}
        />
      );
      modalTitle = "Vulnerability Details";
      break;
    default:
      details = <div>Details not available</div>;
      modalTitle = "Details not available";
  }

  const { modal, setModalIsOpen } = useModal(modalTitle, details, null, {
    width: "80vw",
  });

  const handleClick = () => {
    setModalIsOpen(true);
  };

  return (
    <UnderlinedTooltip
      tooltip={tooltipText}
      style={{
        borderBottom: details ? "2px dashed" : "none",
        cursor: details ? "pointer" : "default",
      }}
    >
      <span onClick={details ? onClick || handleClick : null}>{children}</span>
      {modal}
    </UnderlinedTooltip>
  );
};

export default TimelineUnderlinedDetails;
