import { ErrorLogger } from "@utils/EventLogger";
import { GetS3Object } from "../../../utils/Functions/S3Storage/GetS3Object";
import axios from "axios";
import { generateSFDTFromURL } from "../DocumentEditor/functions/generateSFDTFromURL";
import { getSignedObjectUrlV2 } from "../../../utils/Functions/S3Storage/getSignedObjectUrlV2";
import { platformSettings } from "../../../platformSettings";

/**
 * @description Open a Report Document in the Document Editor
 * @param {object} reportDocument - report document object
 * @param {string} organizationID - selected organization
 * @returns {Promise<object|null>}
 */
export const openReportDocument = async ({ reportDocument, organizationID }) => {
  // Check arguments
  if (!reportDocument || !organizationID) {
    ErrorLogger("Cannot open report document, please check params");
    return null;
  }

  const key = reportDocument?.file?.key;
  const bucket = reportDocument?.file?.bucket;

  try {
    if (key) {
      const url = await getSignedObjectUrlV2({
        key: key,
        bucket: bucket,
        organizationID,
      });
      const isSFDT = key?.toLowerCase()?.includes(".sfdt");
      return await generateSFDTFromURL({ url, isSFDT });
    } else {
      ErrorLogger("Cannot open report document, missing key");
    }
  } catch (error) {
    ErrorLogger(`Cannot open report document: ${JSON.stringify(error)}`);

    try {
      const keyWordDoc = reportDocument?.fileWordDoc?.key;
      const bucketWordDoc = reportDocument?.fileWordDoc?.bucket;

      if (keyWordDoc) {
        /**
         * Get the S3 object stream of the Word document
         */
        const s3Stream = await GetS3Object({
          organizationID,
          objectKey: keyWordDoc,
          bucketName: bucketWordDoc,
        });

        if (!s3Stream) {
          throw new Error("Cannot get Word document object stream");
        }

        /**
         * Convert word document to SFDT
         */
        const blob = new Blob([s3Stream?.Body]);
        const form = new FormData();
        form.append("fileName", blob, "Document1.docx");
        return await axios
          .post(`${platformSettings.syncfusionServiceUrl}/api/documenteditor/Import`, form, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => response?.data);
      } else {
        ErrorLogger("Cannot open report Word document, missing key");
      }
    } catch (error) {
      ErrorLogger(`Cannot open report Word document: ${JSON.stringify(error)}`);
    }
  }
};
