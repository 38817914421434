import { Button } from "reactstrap";
import React from "react";
import { fieldContexts } from "../../../../enums/fieldContexts";
import { useDebounceState } from "../../../../hooks/functional/useDebounceState";
import { usePreferences } from "../../../../hooks/views/usePreferences/usePreferences";
import { useStateEffect } from "../../../../hooks/functional/useStateEffect";
import { convertCamelCaseToSentence } from "@rivial-security/func-utils";

/**
 * A button that will enable the 'EDIT' state of a field
 * @param {object} item - the item being edited
 * @param {string} field - the field for this button
 * @param {string} fieldContext - the placement of the field (grid, details, etc.)
 * @param {function} toggle - allows this button to toggle the editing state
 * @param {string} mutation - if no mutation or mutationFunction is present, hide the button
 * @param {string} mutationFunction - takes priority over the built-in mutation, for custom mutation behavior (see LossExceedanceTolerance.js)
 * @param {boolean} updatePermission - must be true to display the button
 * @param {boolean} disableEdits - must be false to display the button
 * @param {boolean} isHovered - for showing the edit button when the field is mouse hovered
 * @param {JSX.Element} deleteButton - optionally render a delete button next to the edit button
 * @returns {JSX.Element}
 * @constructor
 */
const EditButton = ({
  item,
  field,
  fieldContext,
  toggle,
  mutation,
  mutationFunction,
  updatePermission,
  disableEdits: disableEditsInit,
  isHovered,
  deleteButton,
}) => {
  const { getPreference, preferences } = usePreferences();
  const [preferenceEnabled] = useStateEffect(false, [preferences], () => getPreference("editButton", "autoHide"));

  //Button hover effect timeout
  const showButton = useDebounceState({ value: isHovered, delay: 250 });

  const getIconTitle = () => {
    if (!updatePermission) {
      return "Your Role does not have permission to Update this Resource";
    }

    if (!mutationFunction && !mutation) {
      return "This field can not be edited";
    }
  };

  let disableEdits = false;
  if (typeof disableEditsInit === "boolean") {
    disableEdits = disableEditsInit;
  } else if (typeof disableEditsInit === "function") {
    disableEdits = disableEditsInit({ item });
  }

  return (
    <span
      style={{
        visibility: !preferenceEnabled || showButton || isHovered ? "visible" : "hidden",
      }}
    >
      {(mutation || mutationFunction) && updatePermission && !disableEdits ? (
        <>
          <Button
            data-testid={`button-edit-field-${field}-${fieldContext || fieldContexts.STANDALONE}`}
            size="sm"
            color="ghost-warning"
            className="btn-pill"
            onClick={(e) => toggle(e)}
            title={`Edit Field: ${convertCamelCaseToSentence(field)}`}
            disabled={disableEdits}
          >
            <i className="icon-pencil" />
          </Button>
          {deleteButton}
        </>
      ) : preferenceEnabled ? ( // Only display the lock icon when the 'Auto-Hide' preference is enabled, to not clutter up the UI
        <span style={{ color: "grey" }} title={getIconTitle()}>
          <i className={"icon-lock"} />
        </span>
      ) : null}
    </span>
  );
};

export default EditButton;
