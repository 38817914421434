import { generateGraphql } from "@rivial-security/generategraphql";
import { deleteItem } from "../../../utils/Functions/Graphql/deleteItem";

/**
 * Deletes a CustomQuery
 * @param customQuery
 */
export const deleteCustomQuery = (customQuery) => {
  if (!customQuery || !customQuery.id) {
    return null;
  }

  const { getQuery } = generateGraphql("CustomQuery", ["__typename"]);

  return deleteItem(getQuery, customQuery);
};
