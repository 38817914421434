import { Badge, IconButton, ListItem, ListItemText } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import EditIcon from "@mui/icons-material/Edit";
import React, { useEffect, useState } from "react";
import { getItemStyle } from "../functions/getItemStyle";
import { useBoolean } from "../../../functional/useBoolean";
import { useForm } from "../../useForm/useForm";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import { useModal } from "../../useModal";
import NotesV2 from "../../../../utils/Notes/components/NotesV2";
import { convertCamelCaseToSentence, isNullOrUndefined } from "@rivial-security/func-utils";
import SpeakerNotesOutlinedIcon from "@mui/icons-material/SpeakerNotesOutlined";
import { useUIContext } from "@utils/Context/UIContext";
import { modules } from "@rivial-security/role-utils";

/**
 * A single Checklist item for the useChecklist hook
 * @param provided - from react drag and drop library
 * @param {object} item - parent item for the type from schema.
 * @example {id: a02a9d77-c22c-4102-9164-1783021ff33f, __typename: "Exercise", injections: [injection]}
 * @param {string} field - child type name. Ex: "injections"
 * @param {object} snapshot - from react drag and drop library
 * @param {function} handleEdit - handle when a checklist item gets changed
 * @param {function} handleDelete - handle when a checklist item gets deleted
 * @param {boolean} enableCheckbox - enable or disable checkboxes
 * @param {boolean} disableEdits - disable text field edits
 * @param {boolean} disableCheckboxEdits - disable changing the checkbox checked state
 * @param {string} checkboxFieldName - field name that holds boolean for the item
 * @param {string} textFieldName - field name that holds text for the item
 * @param {boolean} enableNotes - enable or disable notes ui
 * @param {string} organizationID - selected organization id
 * @param {string} stepButtonName - text to show in the checklist buttons
 * @param {string} module - platform module used for role checking
 * @param {string} resource - platform resource used for role checking
 * @param {boolean} disableRoleChecking - disable role checking
 * @param {boolean} isMobile - if TRUE will show mobile view
 * @param {object} notesProps - props to pass to the notes component
 * @returns {JSX.Element}
 */
const ChecklistItem = ({
  provided,
  item,
  field,
  snapshot,
  handleEdit,
  handleDelete,
  enableCheckbox = false,
  disableEdits = false,
  disableCheckboxEdits = false,
  checkboxFieldName,
  textFieldName,
  enableNotes = false,
  organizationID,
  stepButtonName = "Step",
  module,
  resource,
  disableRoleChecking,
  isMobile: isMobileInit,
  notesProps,
}) => {
  const [checkbox, setCheckbox] = useState(item[checkboxFieldName] || false);
  const [isEditing, setIsEditing] = useBoolean(false);
  const { isMobile: isMobileScreen } = useUIContext();

  //Based on screen size and outside override, determine if mobile view should be shown
  const isMobile = !isNullOrUndefined(isMobileInit) ? isMobileInit : isMobileScreen;

  const updateStepForm = useForm({
    disableResetButton: true,
    disableSubmitButton: true,
    fieldConfig: {
      step: {
        inputType: "textarea",
      },
    },
  });

  useEffect(() => {
    if (item?.[textFieldName]) {
      updateStepForm?.setInput({ step: item?.[textFieldName] });
    }
  }, [item?.[textFieldName]]);

  /**
   * Cancels the edit operation and resets the form
   */
  const cancelEdit = () => {
    updateStepForm?.setInput({ step: item[textFieldName] });
    setIsEditing(false);
  };

  /**
   * Saves the edit for the step
   */
  const save = (checkboxValue, textAreaValue, type = CHECKLIST_ITEM_UPDATE_TYPE.TEXT) => {
    handleEdit &&
      handleEdit(
        {
          id: item.id,
          ...item,
          [checkboxFieldName]: checkboxValue,
          [textFieldName]: textAreaValue,
        },
        type,
      );
    setIsEditing(false);
    updateStepForm?.setInput({ step: "" });
  };

  /**
   * Deletes a step
   */
  const deleteStep = () => {
    if (window.confirm("Are you sure you want to delete this step?")) {
      handleDelete && handleDelete(item);
    }
  };

  const notesModal = useModal(
    `Notes for ${convertCamelCaseToSentence(field)}`,
    item && enableNotes && (
      <NotesV2
        item={item}
        disableRoleChecking={disableRoleChecking}
        organizationID={organizationID || item?.ownerGroup}
        onUpdate={(notes) => handleEdit({ id: item.id, notes })}
        module={module || modules.INCIDENT_RESPONSE}
        resource={resource}
        {...notesProps}
      />
    ),
    <IconButton title={`Open Notes for ${convertCamelCaseToSentence(field)}`} size="large">
      <Badge
        badgeContent={(item?.notes && Array.isArray(item?.notes) && item?.notes.length) || 0}
        color="primary"
        showZero
      >
        <SpeakerNotesOutlinedIcon />
      </Badge>
    </IconButton>,
    {
      width: "50vw",
    },
  );

  //Disables dragging when disable edits flag is true
  const draggableProps = !disableEdits && { ...provided.draggableProps };
  const dragHandleProps = !disableEdits && { ...provided.dragHandleProps };

  return (
    <ListItem
      key={`list-item${item?.id}`}
      ContainerComponent="li"
      ContainerProps={{ ref: provided.innerRef }}
      {...draggableProps}
      {...dragHandleProps}
      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
    >
      {/*Step Checkbox*/}
      <span style={{ width: "100%", display: "flex" }}>
        {enableCheckbox && (
          <ListItemIcon>
            <Checkbox
              disabled={disableCheckboxEdits}
              edge="start"
              checked={checkbox}
              tabIndex={-1}
              disableRipple
              inputProps={{ "aria-labelledby": item?.id }}
              onClick={(e) => {
                setCheckbox(!checkbox);
                save(!checkbox, item[textFieldName], CHECKLIST_ITEM_UPDATE_TYPE.CHECKBOX);
              }}
            />
          </ListItemIcon>
        )}

        {/*Step Text*/}
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {isEditing ? updateStepForm.display : <CenteredListItemText primary={item?.[textFieldName]} />}
        </div>

        {/*Right Side Buttons */}
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          {isEditing ? (
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              <IconButton
                onClick={() => save(checkbox, updateStepForm?.input?.step, CHECKLIST_ITEM_UPDATE_TYPE.TEXT)}
                title={`Save ${stepButtonName}`}
                size="large"
              >
                <CheckCircleOutlineOutlinedIcon />
              </IconButton>
              <IconButton onClick={cancelEdit} title={`Cancel Edit for ${stepButtonName}`} size="large">
                <CancelOutlinedIcon />
              </IconButton>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              {item && enableNotes && notesModal.modalButton}
              {!disableEdits && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                  }}
                >
                  <IconButton onClick={() => setIsEditing(true)} title={`Edit ${stepButtonName}`} size="large">
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={deleteStep} title={`Delete ${stepButtonName}`} size="large">
                    <DeleteOutlineOutlinedIcon />
                  </IconButton>
                </div>
              )}
            </div>
          )}
        </div>
      </span>
    </ListItem>
  );
};

//Vertically align text by the checkmark
const CenteredListItemText = withStyles({
  root: {
    display: "flex",
    alignItems: "center",
  },
})(ListItemText);

export const CHECKLIST_ITEM_UPDATE_TYPE = {
  TEXT: "text",
  CHECKBOX: "checkbox",
};

export default ChecklistItem;
