import {
  AxesDirective,
  AxisDirective,
  CircularGaugeComponent,
  PointerDirective,
  PointersDirective,
  RangeDirective,
  RangesDirective,
} from "@syncfusion/ej2-react-circulargauge";
import React, { useContext, useEffect, useState } from "react";
import { convertCamelCaseToSentence, formattedDollar, isNullOrUndefined } from "@rivial-security/func-utils";

import DataLoader from "../../../../../../../utils/LoadingComponents/DataLoader";
import GetLossTolerance from "../../../../../LossTolerance/functions/getLossTolerance";
import Grid from "@mui/material/Grid";
import { LoadingSpinner } from "../../../../../../../utils/LoadingComponents/LoadingSpinner";
import { MonteCarloContext } from "../../../../context/MonteCarloContext";
import NotEnoughData from "../../../../../../../utils/GenericComponents/NotEnoughData";
import Paper from "@mui/material/Paper";
import { getLossCurveValue } from "@rivial-security/risk-calc-utils";
import { styled } from "@mui/styles";
import { useEventListener } from "../../../../../../../hooks/functional/useEventListener";
import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";

const RiskScoreGauge = (props) => {
  const { width, height } = props;

  const [animate] = useState(false);

  const { riskScore, monteCarloInput, isLoadingMonteCarlo } = useContext(MonteCarloContext);

  const [rangeScale, setRangeScale] = useState({});

  /**
   * Calculates and sets the ranges for the Gauge
   */
  const getRange = async () => {
    if (monteCarloInput && monteCarloInput.ratingScale) {
      const lossToleranceCurve = await GetLossTolerance(props.organizationID);

      const ratingScale = monteCarloInput && monteCarloInput.ratingScale;

      const highLoss = getLossCurveValue({
        ratingScale,
        lossToleranceCurve,
        rating: "high",
      });
      const mediumHighLoss = getLossCurveValue({
        ratingScale,
        lossToleranceCurve,
        rating: "mediumHigh",
      });
      const mediumLoss = getLossCurveValue({
        ratingScale,
        lossToleranceCurve,
        rating: "medium",
      });
      const lowMediumLoss = getLossCurveValue({
        ratingScale,
        lossToleranceCurve,
        rating: "lowMedium",
      });
      const lowLoss = getLossCurveValue({
        ratingScale,
        lossToleranceCurve,
        rating: "low",
      });

      setRangeScale({
        highLoss,
        mediumHighLoss,
        mediumLoss,
        lowMediumLoss,
        lowLoss,
      });
    }
  };

  useEffect(() => {
    getRange();
  }, [monteCarloInput, riskScore]);

  /**
   * Resets the Gauge on Window resize.
   * This fixes the Syncfusion bug of the Gauge being unresponsive to window changes.
   */
  const [k, setK] = useState(0);

  useEventListener("resize", () => setK((k) => k + 1));

  return (
    <div style={{ textAlign: "center" }}>
      {isLoadingMonteCarlo ? (
        <LoadingSpinner data-testid="loading-spinner" loadingMessage="Calculating Risk Score.. " />
      ) : props?.item?.riskRating ? (
        <>
          <Grid container spacing={2} style={{ marginBottom: "1em" }}>
            <Grid item lg={6} sm={12}>
              <Item style={{ height: "100%" }}>
                <h4 data-testid={"label-current-risk"}>
                  {props?.item?.riskRating === "No Risk Risk"
                    ? "No Risk"
                    : convertCamelCaseToSentence(props?.item?.riskRating, true, "-")}{" "}
                  Risk
                </h4>
                <i>{convertCamelCaseToSentence(props?.item?.greatestEnterpriseRisk)}</i>
                <br />
                <i>{convertCamelCaseToSentence(props?.item?.greatestBusinessRisk)}</i>
              </Item>
            </Grid>
            <Grid item lg={6} sm={12}>
              <Item>
                <h4 data-testid={"label-current-risk"}>{formattedDollar(props?.item?.residualRisk)}</h4>
                <i>{convertCamelCaseToSentence(props?.item?.greatestKeyRiskIndicator)}</i>
              </Item>
            </Grid>
          </Grid>
          <Item>
            <DataLoader isEnoughData={true} isLoading={isNullOrUndefined(rangeScale)}>
              <CircularGaugeComponent
                id="risk_score_gauge"
                data-testid={"risk-score-gauge"}
                key={`risk-gauge-container${k}`}
                padding={{
                  top: 20,
                }}
                height={height || "300em"}
                width={width || "100%"}
              >
                <AxesDirective>
                  <AxisDirective
                    minimum={0}
                    maximum={rangeScale.highLoss}
                    labelStyle={{
                      format: "${value}",
                    }}
                  >
                    <PointersDirective>
                      <PointerDirective
                        key={props.resetKey}
                        animation={{
                          enable: animate,
                          duration: 1500,
                        }}
                        value={props?.item?.residualRisk}
                        radius="75%"
                        cap={{
                          radius: 8,
                          color: "white",
                          border: {
                            color: "#007DD1",
                            width: 5,
                          },
                        }}
                        color="black"
                        pointerWidth={10}
                      />
                    </PointersDirective>
                    <RangesDirective>
                      <RangeDirective start={0} end={rangeScale.lowLoss} color="green" />
                      <RangeDirective start={rangeScale.lowLoss} end={rangeScale.lowMediumLoss} color="yellow" />
                      <RangeDirective start={rangeScale.lowMediumLoss} end={rangeScale.mediumLoss} color={"#fabd43"} />
                      <RangeDirective
                        start={rangeScale.mediumLoss}
                        end={rangeScale.mediumHighLoss}
                        color="darkOrange"
                      />
                      <RangeDirective start={rangeScale.mediumHighLoss} end={rangeScale.highLoss} color="red" />
                      {props?.item?.residualRisk >= rangeScale.highLoss / 2 && (
                        <RangeDirective
                          start={rangeScale.highLoss}
                          end={props?.item?.residualRisk * 1.2}
                          endWidth={17}
                          color="red"
                        />
                      )}
                    </RangesDirective>
                  </AxisDirective>
                </AxesDirective>
              </CircularGaugeComponent>
            </DataLoader>
          </Item>
        </>
      ) : (
        <NotEnoughData />
      )}
    </div>
  );
};

const Item = styled(Paper)(() => ({
  padding: "1px",
  textAlign: "center",
}));

export default withOrganizationCheck(RiskScoreGauge);
