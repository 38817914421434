import { useGridCard } from "../../../../../hooks/views/useGridCard";
import { generateGraphql } from "@rivial-security/generategraphql";
import React from "react";
import CreatePlaybook from "../components/CreatePlaybook";
import { modules, resources } from "@rivial-security/role-utils";
import { getProceduresNestedFields } from "../../../PlaybookBuilder/functions/getProceduresNestedFields";
import { DETAILS_TYPES } from "../../../../../hooks/views/useGrid/enums/DETAILS_TYPES";
import PlaybookDetails from "../components/PlaybookDetails";

/**
 * Gets and displays a list of Playbooks for an Organization
 * @param organizationID
 * @param gridConfig
 * @param queryConfig
 * @param cardConfig
 */
export const usePlaybookGrid = ({ organizationID, gridConfig = {}, queryConfig = {}, cardConfig }) => {
  const module = modules.INCIDENT_RESPONSE;
  const resource = resources.PLAYBOOK;
  const typename = "Playbook";
  const queryFields = ["name", "description", "procedures"];
  const { listQuery } = generateGraphql(typename, queryFields, getProceduresNestedFields());

  const fields = [
    {
      name: "name",
    },
    {
      name: "description",
    },
  ];

  cardConfig = {
    title: "Playbooks",
    headerIcon: "icon-book-open",
    ...cardConfig,
  };

  gridConfig = {
    typename: "Playbook",
    fields,

    enableMenu: true,
    enableSearch: true,

    createResourceComponent: <CreatePlaybook />,
    persistenceUUID: "7b359bcf-a71b-45e8-aa6e-bde7782ad87d",

    //weirdly a details component is required to be able to pre-select items
    route: "#/response/playbooks/",
    detailsComponent: <PlaybookDetails tableDisplay={true} />,
    detailsType: DETAILS_TYPES.MODAL,
    detailsTitle: "Playbook Details",

    ...gridConfig,
  };

  queryConfig = {
    query: listQuery,
    organizationID,
    ...queryConfig,
  };

  const roleConfig = {
    module,
    resource,
  };

  const gridCard = useGridCard({
    cardConfig,
    gridConfig,
    queryConfig,
    roleConfig,
  });

  return {
    ...gridCard,
  };
};
