import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import InformationAssets from "./InformationAssets";
import Confidentiality from "./Confidentiality";
import Integrity from "./Integrity";
import { modules, resources } from "@rivial-security/role-utils";
import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";
import AvailabilityRating from "./AvailabilityRating";

/**
 * System Impact consists of Information Assets, Number of Records, and CIA ratings
 * @param module
 * @param resource
 * @param disableRoleChecking
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SystemImpact = ({
  module = modules.RISK,
  resource = resources.INFORMATION_SYSTEM,
  disableRoleChecking = false,
  ...props
}) => {
  const { item, system, viewType } = props;

  const [informationAssets, setInformationAssets] = useState([]);

  useEffect(() => {
    const informationAssets =
      item && item.informationAssets && item.informationAssets.items ? item.informationAssets.items : [];

    setInformationAssets([...informationAssets]);
  }, [item]);

  return (
    <div>
      {system ? (
        <InformationAssets
          module={module}
          resource={resource}
          disableRoleChecking={disableRoleChecking}
          organizationID={props.organizationID}
          items={informationAssets}
          system={system}
          resetSystem={props.resetSystem}
          resetFunction={props.resetFunction}
        />
      ) : (
        <div>
          Loading Information Assets.. <Spinner size="sm" color="primary" />
        </div>
      )}
      {viewType === "analysis" && (
        <>
          <hr />
          <Confidentiality
            system={system}
            module={module}
            resource={resource}
            disableRoleChecking={disableRoleChecking}
          />
          <br />
          <br />
          <Integrity system={system} module={module} resource={resource} disableRoleChecking={disableRoleChecking} />
          <br />
        </>
      )}
      <br />
      <AvailabilityRating
        item={system}
        resetFunction={props.resetFunction}
        module={module}
        resource={resource}
        disableRoleChecking={disableRoleChecking}
      />
    </div>
  );
};

export default withOrganizationCheck(SystemImpact);
