import React from "react";
import { useForm } from "../../../../hooks/views/useForm/useForm";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

const { createMutation: createSystem } = generateGraphql("System", ["name", "description"]);

/**
 * Custom form hook for creating a new System Template
 *
 * @param {string} organizationID - ownerGroup for the system template
 * @param {function} toggleModal - function to toggle a parent modal
 * @param {function} resetFunction - function to call an external reset function
 * @param {object} formHookConfig - configs passed to the useForm hook
 * @param {function} getNewItem - callback function to pass the new item after creation
 * @param {string} module - module used for role checking
 * @param {string} resource - resource used for role checking
 * @param {boolean} disableRoleChecking - disable role checking
 * @returns {{display: JSX.Element}}
 */
export const useCreateSystemTemplate = ({
  organizationID,
  toggleModal,
  resetFunction,
  formHookConfig,
  getNewItem,
  module = modules.RISK,
  resource = resources.INFORMATION_SYSTEM,
  disableRoleChecking = false,
}) => {
  const formHook = useForm({
    organizationID,
    getNewItem,
    typename: "Information System Template",
    module,
    resource,
    disableRoleChecking,
    resetFunction,
    toggleModal,
    mutation: createSystem,
    fieldConfig: {
      name: {
        required: true,
        inputType: "text",
        label: "Name",
      },
      description: {
        inputType: "textarea",
        label: "Description",
      },
    },
    ...formHookConfig,
  });

  const display = <>{formHook.display}</>;

  return {
    display,
  };
};
