import { convertTargetVulnerabilityStatusCountsToVulnerabilityStatus } from "../functions/convertTargetVulnerabilityStatusCountsToVulnerabilityStatus";
import { VulnerabilityStatus as VulnerabilityStatusEnum } from "../constants/VulnerabilityStatus";
import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import React from "react";

/**
 * Shows the combined link status for a single vulnerability in details view
 * @param {object} item - the vulnerability item with statusCounts property computed
 * @returns {JSX.Element}
 */
const SingleVulnerabilityStatusCustomField = ({ item }) => {
  const { vulnerabilityStatus, fixedPercent, exceptionPercent } =
    convertTargetVulnerabilityStatusCountsToVulnerabilityStatus({
      statusCounts: item?.statusCounts,
    });
  const combinedFixedPercent = fixedPercent + exceptionPercent;

  return (
    <div>
      {(() => {
        switch (vulnerabilityStatus) {
          case VulnerabilityStatusEnum.FIXED:
          case VulnerabilityStatusEnum.NOT_FIXED:
            return <p>{convertCamelCaseToSentence(vulnerabilityStatus)}</p>;
          case VulnerabilityStatusEnum.PARTIALLY_FIXED:
          case VulnerabilityStatusEnum.MOSTLY_FIXED:
            return (
              <p>
                {convertCamelCaseToSentence(vulnerabilityStatus)} ({combinedFixedPercent}%)
              </p>
            );
          default:
            return <p>{convertCamelCaseToSentence(VulnerabilityStatusEnum.UNKNOWN)}</p>;
        }
      })()}
    </div>
  );
};

export default SingleVulnerabilityStatusCustomField;
