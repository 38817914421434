import React, { useEffect, useState } from "react";
import { formattedPercent, isNullOrUndefined } from "@rivial-security/func-utils";
import DataLoader from "../../../../utils/LoadingComponents/DataLoader";
import {
  AccumulationChartComponent,
  AccumulationDataLabel,
  AccumulationLegend,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  PieSeries,
} from "@syncfusion/ej2-react-charts";
import { v4 as uuid } from "uuid";
import { Inject } from "@syncfusion/ej2-react-grids";
import { LoadingSpinner } from "../../../../utils/LoadingComponents/LoadingSpinner";

/**
 * Displays a Chart of evidences reviewed for an audit
 * @param item
 * @returns {{fetchData: fetchData, data: null, setData: React.Dispatch<React.SetStateAction<null>>, display: JSX.Element}}
 */
export const useAuditEvidenceReviewedChart = ({ item }) => {
  const [data, setData] = useState(null);

  const [isEnoughData, setIsEnoughData] = useState(true);

  const fetchData = () => {
    if (!isNullOrUndefined(item) && Array.isArray(item?.controls?.items)) {
      const res = [];

      let numReviewed = 0;
      let numNotReviewed = 0;

      let total = 0;

      for (const control of item.controls.items) {
        const evidenceLinks = control?.evidenceLinks?.items || [];

        for (const evidenceLink of evidenceLinks) {
          total++;

          if (evidenceLink.reviewed === true) {
            numReviewed++;
          } else {
            numNotReviewed++;
          }
        }
      }

      if (total > 0) {
        setIsEnoughData(true);
      } else {
        setIsEnoughData(false);
      }

      const series1 = {
        id: "number_reviewed",
        x: `Reviewed`,
        y: numReviewed,
        text: `${formattedPercent(numReviewed / total)}`,
        fill: "lightBlue",
      };

      const series3 = {
        id: "number_not_reviewed",
        x: `Not Reviewed`,
        y: numNotReviewed,
        text: `${formattedPercent(numNotReviewed / total)}`,
        fill: "gold",
      };

      res.push(series1);
      res.push(series3);

      setData(res);
    }
  };

  /**
   * Fetches the data for the pie chart.
   * Note: Only fetch the data if the Evidence Reviewed info has changed, to prevent re-renders related to other fields
   */
  useEffect(() => {
    if (!isNullOrUndefined(item?.controls?.items)) fetchData();
  }, [JSON.stringify(item?.controls?.items)]);

  const display = (
    <>
      <DataLoader
        isEnoughData={isEnoughData}
        isLoading={false}
        dataMessage={"There are no Evidences attached to Controls in this Audit"}
      >
        {data ? (
          <AccumulationChartComponent
            id={`testing-pie-chart-${uuid()}`}
            legendSettings={{
              visible: true,
              position: "Top",
              enabled: false,
              toggleVisibility: false,
            }}
            enableSmartLabels={true}
            tooltip={{ enable: true }}
            enableAnimation={true}
            enableBorderOnMouseMove={true}
            highlightPattern="pacman"
          >
            <Inject services={[AccumulationLegend, PieSeries, AccumulationDataLabel]} />
            <AccumulationSeriesCollectionDirective>
              <AccumulationSeriesDirective
                name="Project"
                dataSource={data}
                xName="x"
                yName="y"
                innerRadius="40%"
                startAngle={0}
                endAngle={360}
                radius="70%"
                explode={true}
                explodeOffset="10%"
                explodeIndex={3}
                pointColorMapping="fill"
                dataLabel={{
                  visible: true,
                  name: "text",
                  position: "Inside",
                  font: {
                    fontWeight: "600",
                    color: "#ffffff",
                  },
                }}
                animation={{ enable: false }}
              ></AccumulationSeriesDirective>
            </AccumulationSeriesCollectionDirective>
          </AccumulationChartComponent>
        ) : (
          <LoadingSpinner loadingMessage="Loading Data.. " />
        )}
      </DataLoader>
    </>
  );

  return {
    display,
    data,
    setData,
    fetchData,
  };
};
