import { useSelectControlTemplates } from "../hooks/useSelectControlTemplates";
import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";

/**
 * A component instance of the useSelectControlTemplates.js hook
 * @param {object} props
 */
const SelectControlTemplates = (props) => {
  return useSelectControlTemplates(props).display;
};

export default withOrganizationCheck(SelectControlTemplates);
