import { generateGraphql } from "@rivial-security/generategraphql";
import { GetQuery } from "../../../../utils/Functions/Graphql/GetQuery";
import { getMany } from "../../../../utils/Functions/getMany";

/**
 * @description Get evidence risk and compliance controls from DB
 * @param {string} evidenceID - evidence id to get
 * @returns {object} - the passed in evidence's controls and
 */
export const getEvidenceControls = async ({ evidenceID }) => {
  //[ARGUMENT CHECKS]
  if (!evidenceID || typeof evidenceID !== "string") {
    throw Error(`Evidence id is not string type ${evidenceID}`);
  }

  //[CREATE QUERIES]
  const { getQuery: getEvidenceControls } = generateGraphql("Evidence", ["controls", "enabled", "status", "name"], {
    controls: `(limit: 500) {
        items {
          id
          control {
            id
            inPlace
            evidences (limit: 500) {
              items {
                id
                evidence {
                  id
                  enabled
                  status
                }
              }
            }
          }
        }
      }`,
  });

  const { getQuery: getEvidenceRiskControls } = generateGraphql("Evidence", ["riskControls", "enabled", "name"], {
    riskControls: `(limit: 500) {
        items {
          id
          riskControl {
            id
            implementationRating
            costOfControl
            evidences (limit: 500) {
              items {
                id
                evidence {
                  id
                  enabled
                  status
                }
              }
            }
          }
        }
      }`,
  });

  //[RUN QUERIES]
  const evidence = await GetQuery({
    query: getEvidenceControls,
    variables: { id: evidenceID },
  });

  const evidenceWithRiskControls = await GetQuery({
    query: getEvidenceRiskControls,
    variables: { id: evidenceID },
  });

  const complianceControls = getMany(evidence, "controls", "control") ?? [];
  const riskControls = getMany(evidenceWithRiskControls, "riskControls", "riskControl") ?? [];

  return {
    complianceControls,
    riskControls,
  };
};
