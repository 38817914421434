"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.customQueryFunctions = void 0;
const getFullName_1 = require("../functions/getFullName");
const getNestedField_1 = require("../functions/getNestedField/getNestedField");
const combineNotes_1 = require("../functions/combineNotes");
const daysUntilDate_1 = require("../functions/daysUntilDate");
/**
 * Functions which are dynamically selected to be available to various fields of a custom query
 * @type {{combineNotes: ((function({element: *, params: *}): (string|string))|*), getFullName: ((function(Object): string)|*), getNestedField: ((function({element: Object, params: Object}): *)|*)}}
 */
exports.customQueryFunctions = {
    //Point of Contact
    getFullName: getFullName_1.getFullName,
    //Object
    getNestedField: getNestedField_1.getNestedField,
    combineNotes: combineNotes_1.combineNotes,
    daysUntilDate: daysUntilDate_1.daysUntilDate,
};
