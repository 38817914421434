import { API, graphqlOperation } from "@aws-amplify/api";
import { ErrorLogger, InfoLogger } from "@utils/EventLogger";

import { deleteSystemRiskLink_custom } from "../../../../../graphql/__risksGQL";

const findSystemRiskLink = (risk, system) => {
  if (risk && system) {
    const systemRiskLink =
      system &&
      system.risks &&
      system.risks.items &&
      system.risks.items.find((riskLink) => riskLink.risk && riskLink.risk.id === risk.id);

    if (systemRiskLink && systemRiskLink !== undefined) {
      return systemRiskLink;
    } else {
      ErrorLogger("System Risk Link not found");
    }
  }
};

export const removeRiskFromSystem = (risk, system) => {
  if (window.confirm(`Are you sure you want to remove Risk: ${risk.name} from System: ${system.name}?`)) {
    const systemRiskLink = findSystemRiskLink(risk, system);

    if (risk && system && systemRiskLink) {
      API.graphql(
        graphqlOperation(deleteSystemRiskLink_custom, {
          input: {
            id: systemRiskLink.id,
          },
        }),
      ).then(({ data }) => {
        InfoLogger(JSON.stringify(data));
      });
    }
  }
};
