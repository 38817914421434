import { useNestedDataCard } from "../../../../hooks/views/useNestedDataCard/useNestedDataCard";
import { resources } from "@rivial-security/role-utils";
import { createSystemTargetLink } from "../../../Risk/Systems/components/SystemAccordion/SecurityTesting/function/createSystemTargetLink";
import { deleteSystemTargetLink } from "../../../Risk/Systems/components/SystemAccordion/SecurityTesting/function/deleteSystemTargetLink";
import React from "react";
import SystemGrid from "../../../Risk/Systems/components/SystemGrid";
import { useSystemDataGrid } from "../../../Risk/Systems/hooks/useSystemDataGrid";
import CreateSystem from "../../../Risk/Systems/components/CreateSystem";

const TargetSystems = ({ item, organizationID }) => {
  const fields = ["systems"];
  const nestedFields = {
    systems: `(limit: 500) {
      items {
        id
        system {
          id
          name
          description
          pointOfContact { id firstName lastName title }
          adminPointOfContact { id firstName lastName title }
          hosting
        }
      }
    }`,
  };

  const nestedDataCard = useNestedDataCard({
    item,
    field: "systems",
    connectionField: "system",
    typename: resources.INFORMATION_SYSTEM,
    fields,
    nestedFields,
    parentTypename: resources.TARGET,
    grid: <SystemGrid />,
    gridHook: useSystemDataGrid,
    gridHookGridConfig: { enableColumnChooser: false, enableMenu: false },
    form: <CreateSystem />,
    createFunction: createSystemTargetLink,
    deleteFunction: deleteSystemTargetLink,

    //since create function parameters are (system, target) and input is (child, parent) when false
    flipCreateParams: false,
    organizationID,
  });

  return <div style={{ height: "30em" }}>{nestedDataCard.gridDisplay}</div>;
};

export default TargetSystems;
