import { useDetailsCard } from "../../../../hooks/views/useDetailsCardV2";
import { generateGraphql } from "@rivial-security/generategraphql";
import EvidenceFrequency from "../components/EvidenceFrequency";
import EvidenceStatusDropdown from "../components/EvidenceStatusDropdown/EvidenceStatusDropdown";
import EvidenceType from "../components/EvidenceType";
import EvidenceEnabledSwitch from "../components/EvidenceEnabledSwitch";
import EvidenceDueDate from "../components/EvidenceDueDate";
import EvidenceAllowUnauthenticatedSubmissionsSwitch from "../components/EvidenceAllowUnauthenticatedSubmissionsSwitch";
import TagsField from "../../../../utils/Tags/customFields/TagsField";
import { createEvidenceTagLink } from "../../../../utils/Tags/functions/createTagLinks/createEvidenceTagLink";
import { modules, resources } from "@rivial-security/role-utils";

/**
 * Displays the general details of a single evidence in a table
 * @param {string} itemId - the database id of the evidence to display
 * @param {string} module - the platform module to use for frontend permission checking
 * @param {string} resource - the platform resource to use for frontend permission checking
 * @param {string} organizationID - the organization id of the evidence
 * @param {function} getUpdatedItem - call back on item update due to user edits
 * @param {function} [setActivityResetIndex] - reset callback for the activity timeline
 * @param {boolean} [disableEdits] - disable all user edits
 * @param {object} [detailsConfig] - any additional config to pass down to useDetailsCard (or overrides)
 * @param {object} props - any additional props to pass down to useDetailsCard
 * @returns {{isLoading: boolean, setInputId: (function(((function(*): *)|*)): void), inputId: *, item: *, setIsLoading: (function(((function(boolean): boolean)|boolean)): void), tableDisplay: (React.DetailedReactHTMLElement<{item: *, resetFunction: ((function(): void)|*), updateItemById: updateItemById, key: string}, HTMLElement>|JSX.Element), resetFunction: ((function(): void)|*), display: (React.DetailedReactHTMLElement<{item: *, resetFunction: ((function(): void)|*), updateItemById: updateItemById, key: string}, HTMLElement>|JSX.Element), resetIndex: number, reset: (function(): void), setItem: (function(*): void)}}
 */
export const useEvidenceGeneralDetails = ({
  itemId,
  setActivityResetIndex,
  module = modules.COMPLIANCE,
  resource = resources.EVIDENCE,
  organizationID,
  getUpdatedItem,
  detailsConfig,
  ...props
}) => {
  const typename = "Evidence";

  const queryFields = [
    "name",
    "status",
    "frequency",
    "type",
    "enabled",
    "task",
    "pointOfContacts",
    "createdAt",
    "observations",
    "notes",
    "controls",
    "riskControls",
    "tags",
    "allowUnauthenticatedSubmissions",
    "keyPerformanceIndicators",
  ];
  const nestedFields = {
    controls: `(limit: 500) { nextToken items { id control { id name statementNumber inPlace controlControlSetId controlSet { id name } } } }`,
    riskControls: `(limit: 500) { nextToken items { id system { id name riskControlOverrides { enabledFields riskControlId costOfControl strengthRating implementationRating implementationDetails } } riskControl { id name statementNumber controlCategoryID controlCategory { id name } } } }`,
    notes: `{ id type ownerGroup author timeStamp content tag observationID }`,
    pointOfContacts: `(limit: 100) { nextToken items { id pointOfContact { id firstName lastName email title user { id } actions(limit: 100) { items { id name status createdAt data } } } } }`,
    observations: `(limit: 100) { nextToken items { id recommendations (limit: 100) { items { recommendation { actionItems(limit: 100) { items { action { id createdAt status data pointOfContact { id firstName lastName title } } } } } } } } }`,
    tags: `(limit: 100) { items { __typename id tag { id name description fontColor backgroundColor } } }`,
    keyPerformanceIndicators: `(limit: 100) { items { id keyPerformanceIndicator { id name description status metricTypes(limit: 100) { items { id ownerGroup logic { operator data { value format } } metricType { id ownerGroup name description } } } } } }`,
  };

  const { updateMutation, getQuery, onUpdate } = generateGraphql(typename, queryFields, nestedFields);

  const fields = [
    "name",
    "status",
    "frequency",
    "dueDate",
    "type",
    "task",
    "enabled",
    "tags",
    "allow",
    "allowUnauthenticatedSubmissions",
  ];

  const customFields = [
    {
      field: "frequency",
      component: <EvidenceFrequency setActivityResetIndex={setActivityResetIndex} />,
    },
    {
      field: "status",
      component: <EvidenceStatusDropdown setActivityResetIndex={setActivityResetIndex} />,
    },
    {
      field: "type",
      component: <EvidenceType setActivityResetIndex={setActivityResetIndex} />,
    },
    {
      field: "task",
      inputType: "textarea",
    },
    {
      field: "enabled",
      component: <EvidenceEnabledSwitch />,
    },
    {
      field: "dueDate",
      component: <EvidenceDueDate />,
      isDynamicCustomField: true,
    },
    {
      field: "allowUnauthenticatedSubmissions",
      component: <EvidenceAllowUnauthenticatedSubmissionsSwitch />,
    },
    {
      field: "tags",
      component: (
        <TagsField
          module={module}
          resource={resources.EVIDENCE}
          createLinkFunction={createEvidenceTagLink}
          organizationID={organizationID}
        />
      ),
    },
  ];

  const fieldNameDictionary = {
    dueDate: "Expiration Date",
    allowUnauthenticatedSubmissions: "Guest Submissions",
  };

  detailsConfig = {
    fields,
    customFields,
    updateMutation,
    fieldNameDictionary,
    ...detailsConfig,
  };

  const queryConfig = {
    query: getQuery,
    itemId: itemId,
    onUpdateItemSubscription: onUpdate,
  };

  const details = useDetailsCard({
    queryConfig,
    detailsConfig,
    config: {
      enableNotes: true,
      header: "Evidence Details",
      observationConnectionField: "evidenceID",
    },
    module,
    resource,
    updateItemById: getUpdatedItem,
    tableDisplay: true,
    ...props,
  });

  return details;
};
