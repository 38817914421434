import React from "react";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import CreateObservation from "../../Observations/components/CreateObservation";
import ObservationsDataGrid from "../../Observations/components/ObservationsDataGrid";
import { useNestedDataCard } from "../../../../hooks/views/useNestedDataCard/useNestedDataCard";
import { useObservationDataGrid } from "../../Observations/hooks/useObservationDataGrid";
import { resources } from "@rivial-security/role-utils";

/**
 * Displays Observations for a Source
 * @param {object} item - the Source to show Observations for
 * @param {string} organizationID - current organization ID
 * @returns {JSX.Element}
 */
const SourceObservations = ({ item, organizationID }) => {
  const dataCard = useNestedDataCard({
    item,
    organizationID,
    field: "observations",
    connectionIDField: "externalSourceID",
    typename: resources.OBSERVATION,
    parentTypename: resources.SOURCE,
    grid: <ObservationsDataGrid />,
    gridHook: useObservationDataGrid,
    form: <CreateObservation externalSource={item} module={item?.module} />,
  });

  return dataCard.gridDisplay;
};

export default withOrganizationCheck(SourceObservations);
