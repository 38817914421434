import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { getRiskChange_riskChangeDetails } from "../../../graphql/getRiskChange_riskChangeDetails";
import { useModal } from "../../../../../../hooks/views/useModal";
import SelectChangeType from "../../SelectChangeType";
import { useDetailsCard } from "../../../../../../hooks/views/useDetailsCardV2";
import ChangeType from "../../../customFields/ChangeType";
import SystemsAffectedList from "../../../customFields/SystemAffectedList";

/**
 * Displays a detail page for the manual risk change
 * @param {string} itemId - the risk change id from database
 * @param {object} queryConfig - query settings to pass to useDetails
 * @param {object} detailsConfig - details card settings pass-through
 * @param {string} module - platform module for role checking
 * @param {string } resource - platform resource for role checking
 * @param {boolean} disableRoleChecking - true if front end role check unneeded
 * @param {function} resetFunction - function that refreshes passed in data
 * @param {boolean} tableDisplay - if true will display the details as a table instead of a card
 * @return {{isLoading: boolean, setInputId: (function(((function(*): *)|*)): void), inputId: *, item: null, tableDisplay: *, display: *, resetIndex: number, reset: (function(): void), setItem: (function(((function(null): null)|null)): void)}}
 */
export const useManualChangeDetails = ({
  itemId,
  queryConfig,
  detailsConfig,
  module,
  resource,
  disableRoleChecking,
  resetFunction,
  tableDisplay,
}) => {
  const [riskChange, setRiskChange] = useState(null);

  const queryConfigInitial = {
    query: getRiskChange_riskChangeDetails,
    itemId,
  };

  const fields = ["type", "name", "description", "date", "userEmail", "changeOwner", "reason", "systemLinks"];

  const fieldNameDictionary = {
    type: "Type of Change",
    name: "Name of Change",
    description: "Description of Change",
    date: "Date of Change",
    userEmail: "Person who Entered Change",
    changeOwner: "Person responsible for Change",
    reason: "Reason for Change",
    systemLinks: "Systems affected by Change",
    riskControlLinks: "Risk Controls affected by Change",
    overallResidualChange: "Change in Residual Risk",
    overallROIChange: "Return on Investment",
  };

  const customFields = [
    {
      field: "systemLinks",
      component: <SystemsAffectedList />,
    },
    {
      field: "type",
      component: <ChangeType />,
    },
  ];

  const detailsConfigInitial = {
    fields,
    customFields,
    fieldNameDictionary,
  };

  const selectRiskChangeTypeModal = useModal(
    "Select Risk Change Type",
    <SelectChangeType
      item={riskChange}
      organizationID={riskChange && riskChange.ownerGroup}
      resetFunction={resetFunction}
    />,
    <Button
      color="success"
      className="btn-pill float-sm-right"
      title="Convert this Manual Change to one of the automated Change types"
    >
      Auto Convert
    </Button>,
    {
      width: "80vw",
    },
  );

  const detailsCard = useDetailsCard({
    queryConfig: { ...queryConfigInitial, ...queryConfig },
    detailsConfig: { ...detailsConfigInitial, ...detailsConfig },
    config: {
      header: "Manual Risk Change Details",
      headerButtons: [selectRiskChangeTypeModal.modalButton],
    },
    module,
    resource,
    disableRoleChecking,
    tableDisplay,
  });

  useEffect(() => {
    if (detailsCard && detailsCard.item) {
      setRiskChange(detailsCard.item);
    }
  }, [detailsCard && detailsCard.item]);

  return {
    ...detailsCard,
  };
};
