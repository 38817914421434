import GenericEditField from "../../../../utils/GenericComponents/GenericEditFieldV2";
import React from "react";
import { generateGraphql } from "@rivial-security/generategraphql";
import CustomTooltip from "../../../../utils/GenericComponents/CustomTooltip";
import { modules, resources } from "@rivial-security/role-utils";

/**
 * The Summary field for a Vendor Review
 * @param item
 * @param module
 * @param resource
 * @param disableRoleChecking
 * @param field
 * @returns {JSX.Element}
 * @constructor
 */
const CompletedBy = ({
  item,
  module = modules.VENDORS,
  resource = resources.VENDOR_REVIEW,
  disableRoleChecking,
  field = "completedBy",
}) => {
  const { updateMutation } = generateGraphql("VendorReview", ["completedBy"]);

  return (
    <>
      <h6>
        Completed By
        <CustomTooltip
          targetId="completed-by-tooltip"
          tooltip={"The name of the person who is performing this Vendor Review"}
        />
      </h6>
      <GenericEditField
        item={item}
        module={module}
        resource={resource}
        disableRoleChecking={disableRoleChecking}
        field={field}
        mutation={updateMutation}
      />
    </>
  );
};

export default CompletedBy;
