import { resources } from "@rivial-security/role-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { getControlNestedFields } from "../../../../views/Compliance/Controls/Controls/hooks/useControlGrid/constants";

/**
 * Provides get queries to retrieve full created item details when passing back items created from templates
 * @param {string} resource - the typename of the template that was copied
 * @return {string} graphql get query
 */
export const getItemForGridQuery = ({ resource }) => {
  switch (resource) {
    case resources.RISK_CONTROL:
      return generateGraphql(
        resources.RISK_CONTROL,
        ["statementNumber", "name", "implementationRating", "keyControl", "notes"],
        {
          notes: `{ id type ownerGroup author timeStamp content tag observationID }`,
        },
      ).getQuery;
    case resources.THREAT:
      return generateGraphql(resources.THREAT, ["name"]).getQuery;
    case resources.KEY_RISK_INDICATOR:
      return generateGraphql(resources.KEY_RISK_INDICATOR, ["name"]).getQuery;
    case resources.INFORMATION_ASSET:
      return generateGraphql(resources.INFORMATION_ASSET, ["name"]).getQuery;
    case resources.RISK_CONTROL_CATEGORY:
      return generateGraphql(resources.RISK_CONTROL_CATEGORY, ["name"]).getQuery;
    case resources.CONTROL:
      return generateGraphql(
        resources.CONTROL,
        [
          "statementNumber",
          "name",
          "inPlace",
          "isDisabled",
          "tags",
          "notes",
          "controlControlSetId",
          "controlSet",
          "customFieldData",
        ],
        getControlNestedFields(),
      ).getQuery;
    case resources.EXERCISE:
      return generateGraphql(resources.EXERCISE, ["name", "description", "scenario"]).getQuery;
    case resources.MEETING:
      return generateGraphql(resources.MEETING, ["name", "description", "status", "startTime", "endTime", "isAllDay"])
        .getQuery;
    default:
  }
};
