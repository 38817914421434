"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findLossToleranceExceedanceAtResidualLoss = void 0;
const findLossToleranceHeightAtPoint_1 = require("./findLossToleranceHeightAtPoint");
const func_utils_1 = require("@rivial-security/func-utils");
/**
 * Calculates the difference between the loss tolerance and the residual risk curves at the average residual loss line
 * @param {object[]} lossToleranceCurve - the loss tolerance points (objects with x, y values) defined by the % of chance
 * an organization are willing to accept of a monetary loss of x or more
 * @param {object[]} residualLossCurve - the residual loss points (objects with x, y values) defined % of chance a
 * simulation resulted in a monetary loss of x or more
 * @param {number} residualRisk - dollar amount representing the average loss after controls annually
 * @return {null|{lossToleranceDelta: null}}
 */
const findLossToleranceExceedanceAtResidualLoss = ({ lossToleranceCurve, residualLossCurve, residualRisk }) => {
    //Check arguments
    if (!lossToleranceCurve || !residualLossCurve || (0, func_utils_1.isNullOrUndefined)(residualRisk)) {
        return null;
    }
    //Sort the loss tolerance curve to properly calculate intercept (probability descending)
    lossToleranceCurve = lossToleranceCurve.sort((a, b) => b.y - a.y);
    //Find the loss tolerance and risk curve heights at the residual loss
    const riskPoint = { y: 0, x: residualRisk };
    const residualLossCurveHeight = (0, findLossToleranceHeightAtPoint_1.findLossToleranceHeightAtPoint)({
        lossToleranceCurve: residualLossCurve,
        riskPoint,
    }).lossToleranceProbabilityAtRiskValue;
    const lossExceedanceCurveHeight = (0, findLossToleranceHeightAtPoint_1.findLossToleranceHeightAtPoint)({
        lossToleranceCurve,
        riskPoint,
    }).lossToleranceProbabilityAtRiskValue;
    //Calculate the difference between the loss tolerance curve and the residual loss curve
    let lossToleranceDelta = null;
    if (!(0, func_utils_1.isNullOrUndefined)(residualLossCurveHeight) && !(0, func_utils_1.isNullOrUndefined)(lossExceedanceCurveHeight)) {
        lossToleranceDelta = residualLossCurveHeight - lossExceedanceCurveHeight;
    }
    //Calculate annual los instead of percentage
    if (!(0, func_utils_1.isNullOrUndefined)(lossToleranceDelta)) {
        lossToleranceDelta = lossToleranceDelta * residualRisk;
    }
    return { lossToleranceDelta };
};
exports.findLossToleranceExceedanceAtResidualLoss = findLossToleranceExceedanceAtResidualLoss;
