import { Button } from "reactstrap";
import PropTypes from "prop-types";
import React from "react";
import { Route } from "react-router-dom";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * @description The GenericDeleteButton component is a generic button that deletes a resource from the DB.
 * @param {object} item - the item to be displayed
 * @param {function} deleteFunction - the function to be called when the delete button is clicked
 * @param {boolean} skipConfirmation - if true, the delete button will be clicked without confirmation
 * @returns {Promise<*>} - a promise that resolves when the delete button is clicked
 */
export const handleDeleteClick = async (item, deleteFunction, skipConfirmation = false) => {
  if (skipConfirmation || window.confirm(`Are you sure you want to delete ${item.__typename}: ${item.id}?`)) {
    return await deleteFunction(item);
  }
};
/**
 * @description the alert that notifies users whether or not they want to delete a resource
 * @param {object} item - the item to be deleted
 * @param {boolean} skipConfirmation - if true, the delete button will be clicked without confirmation
 * @returns {boolean|*}
 */
export const confirmDeleteAlert = (item, skipConfirmation = false) => {
  return (
    skipConfirmation ||
    window.confirm(`Are you sure you want to delete ${item && item.__typename}: ${item && item.id}?`)
  );
};
/**
 * @description The GenericDeleteButton is a basic delete button
 * @param {object} props - the props to be passed to the component
 * @returns {JSX.Element}
 * @constructor
 */
export const GenericDeleteButton = (props) => {
  const getConfirmationMessage = () => {
    const prefix = "Are you sure you want to delete the";
    const itemType = props.typename || props.item.__typename || "item";
    const itemID = !isNullOrUndefined(props?.item?.id) ? `: ${props?.item?.id}` : "";

    return `${prefix} ${itemType}${itemID}?`;
  };
  /**
   * @description the alert that notifies users whether or not they want to delete a resource
   * @param {object} history - the history object
   * @returns {Promise<void>}
   */
  const handleDeleteClick = async (history) => {
    if (window.confirm(props?.confirmationMessage || getConfirmationMessage())) {
      if (props.deleteFunction) {
        await props.deleteFunction(props.item);
      }

      if (props.reroute) {
        history.push(props.reroute);
      }

      props.resetFunction && props.resetFunction();
    }
  };

  const isDisabled = props.disabled || !props.deleteFunction;

  const title = props.deleteFunction ? props.deleteFunction.name : "No Delete Function available";

  return (
    <Route
      render={({ history }) => (
        <Button
          disabled={isDisabled}
          data-test="button-delete-generic"
          id={`button-delete-for-${props.item.id}`}
          color="ghost-danger"
          className={props.className || "btn-pill"}
          onClick={(e) => {
            handleDeleteClick(history);
            if (props?.stopPropagation === true) {
              e.stopPropagation();
            }
          }}
          size="sm"
          title={isDisabled ? "Delete is disabled for this resource" : props.buttonTitle || title}
        >
          <i className="icon-trash" />
          {props.title || null}
        </Button>
      )}
    />
  );
};

GenericDeleteButton.propTypes = {
  deleteFunction: PropTypes.func.isRequired, //the function that will take in an obect and delete it
  item: PropTypes.object.isRequired, // the object to be deleted
  className: PropTypes.string, // the styling for the button, defaults to "btn-pill"
  title: PropTypes.string, // the text to appear next to the trash icon on the button, optional
  reroute: PropTypes.string, // the routes URL to reroute the user to after the delete, optional
};

GenericDeleteButton.defaultProps = {
  item: {},
};
