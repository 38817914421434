import moment, { Moment } from "moment/moment";
import { isNonEmptyArray } from "@rivial-security/func-utils";
import { MomentDateRange } from "@hooks/views/useForm/components/CustomDateRangeInput/types";

export interface CalculateMinMaxDateRangeLimits {
  value: MomentDateRange;
  maxIntervalInDays: number | undefined;
}

export interface MinMaxDateRangeLimits {
  minDate: Moment | undefined;
  maxDate: Moment | undefined;
}

export const calculateMinMaxDateRangeLimits = ({
  value,
  maxIntervalInDays,
}: CalculateMinMaxDateRangeLimits): MinMaxDateRangeLimits => {
  let minDate: Moment | undefined;
  let maxDate: Moment | undefined;

  if (!maxIntervalInDays || !isNonEmptyArray(value) || value.length !== 2) {
    return { minDate, maxDate };
  }

  const startDate = value?.[0];
  if (startDate) {
    maxDate = moment(startDate).clone().add(maxIntervalInDays, "days");
  }

  const endDate = value?.[1];
  if (endDate) {
    minDate = moment(endDate).clone().subtract(maxIntervalInDays, "days");
  }

  return { minDate, maxDate };
};
