import UnderlinedTooltip from "../../../../utils/GenericComponents/Tooltips/UnderlinedTooltip";
import React from "react";
import { convertCamelCaseToSentence, isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Displays the 'greatestEnterpriseRisk' field for a system with a tooltip
 * @param {object} item - system object
 * @param {number} item.greatestEnterpriseRisk
 * @returns {JSX.Element}
 * @constructor
 */
const GreatestEnterpriseRisk = ({ item }) => {
  return (
    <UnderlinedTooltip
      tooltip={
        "The Greatest Enterprise Risk is calculated based on the Risk Mapping from Key Risk Indicators and Business Risks"
      }
    >
      <span>
        {!isNullOrUndefined(item?.greatestEnterpriseRisk)
          ? convertCamelCaseToSentence(item.greatestEnterpriseRisk)
          : "N/A"}
      </span>
    </UnderlinedTooltip>
  );
};

export default GreatestEnterpriseRisk;
