import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useNestedItems } from "../../../../hooks/graphql/useNestedItems";
import { useRecommendationsDataGrid } from "../../Recommendations/hooks/useRecommendationsDataGrid";
import { useResetEffect } from "../../../../hooks/functional/useResetEffect";
import { generateNestedGraphql } from "../../../../definitions/functions/queries/generateNestedGraphql";
import { fieldContexts } from "../../../../enums/fieldContexts";
import { resources } from "@rivial-security/role-utils";

/**
 * Displays Recommendations for a Source
 * @param {object} item - the Source to show Recommendations for
 * @returns {JSX.Element}
 */
const SourceRecommendations = ({ item }) => {
  const queryPath = [
    { name: "observations", hasMany: true },
    { name: "recommendations", hasMany: true },
    { name: "recommendation", hasMany: false },
  ];

  const { getQuery } = generateNestedGraphql({
    startTypename: resources.SOURCE,
    endTypename: resources.RECOMMENDATION,
    path: queryPath,
    fieldContext: fieldContexts.GRID,
  });

  const { items, resetFunction, isLoading } = useNestedItems({
    item,
    field: "observations",
    connectionField: "observation",
    queryPath,
    deduplicationField: "id",
    query: getQuery,
  });

  const handleData = () => {
    recommendations.setData(items);
  };

  useResetEffect(handleData, [items]);

  const queryConfig = {
    query: null,
    resetFunction,
  };

  const gridConfig = {
    deleteFunction: null,
    gridHeight: "100%",
    allowPaging: false,
    createResourceComponent: null,
  };

  const recommendations = useRecommendationsDataGrid({
    queryConfig,
    gridConfig,
    isLoading,
  });

  return recommendations.gridDisplay;
};

export default withOrganizationCheck(SourceRecommendations);
