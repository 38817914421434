import { checkArguments } from "@rivial-security/func-utils";
import { deleteLink } from "./deleteLink";

/**
 * Performs the operation for unlinking child Resources items from a parent Resource
 *
 * @param {object} item - the item that resources are being unlinked from
 * @param {object[]} items - the child items to unlink from the parent item
 * @param {string} field - the item field that holds the connection object
 * @param {string} nestedField - the connection object field that holds the child object
 * @param {string} typename - the many-to-many connection model typename
 * @param {function} onUnlinkCallback - a function to call after each unlinking operation
 */
export const handleUnlinking = async (item, items, field, nestedField, typename, onUnlinkCallback) => {
  checkArguments({
    item,
    items,
  });

  for (const unlinkItem of items) {
    if (item?.[field]?.items) {
      // loop through item's current resource links
      for (const link of item[field].items) {
        // if the child resource is already linked to this item, unlink
        if (link?.[nestedField]?.id === unlinkItem.id) {
          const deletedLink = await deleteLink(link.id, typename);

          if (typeof onUnlinkCallback === "function") {
            onUnlinkCallback({
              id: deletedLink.id,
              [nestedField]: unlinkItem,
              [field]: item,
            });
          }
        }
      }
    }
  }
};
