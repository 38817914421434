import { useVendorDetails } from "../hooks/useVendorDetails";
import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";

/**
 * Component instance of the useVendorDetails hook
 * @param {object} props
 * @returns {JSX.Element}
 */
const VendorDetails = (props) => {
  return useVendorDetails(props).display;
};

export default withDetailsPage(VendorDetails);
