import client from "../../../apolloClient";
import { GET_CUSTOM_DASHBOARD_BY_ID } from "@compliance/api/getCustomDashboardByID";

export interface GetComplianceFrameworkOverviewPageTitleParams {
  resourceID?: string;
}

export const getComplianceFrameworkDashboardPageTitle = async ({
  resourceID,
}: GetComplianceFrameworkOverviewPageTitleParams): Promise<string | null> => {
  if (!resourceID) {
    return null;
  }

  const dashboardResult = await client.query({
    query: GET_CUSTOM_DASHBOARD_BY_ID,
    variables: { id: resourceID },
  });
  return dashboardResult?.data?.getCustomDashboardById?.name ?? null;
};
