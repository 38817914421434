import { checkArguments, isNullOrUndefined } from "@rivial-security/func-utils";
import { emptyPlaceholder, invalidPlaceholder } from "../../../../utils/GenericComponents/Placeholders";

import CustomTable from "../../../../views/CustomWidgets/WidgetDetails/components/CustomTable";
import { DetailsTable } from "../hooks/useDetails";
import { ErrorLogger } from "@utils/EventLogger";
import { GENERIC_FIELD_TYPES } from "../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import GenericEditFieldV3 from "../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";
import React from "react";
import { fieldContexts } from "../../../../enums/fieldContexts";

/**
 * Chooses how to display a field listed in a details card
 * See useDetails hook for parameter descriptions
 * @param {string} fieldName
 * @param {number} fieldIndex
 * @param {object} item
 * @param {string} typename
 * @param {object[]} customFields - array of field override configurations
 * @param {string} customFields[].field - the name of the field to be overridden
 * @param {JSXElement} customFields[].component - a react component that will override the field entirely
 * @param {string} customFields[].inputType - matches GENERIC_FIELD_TYPES enums
 * @param {string} module
 * @param {string} resource
 * @param {string} mutation
 * @param {function} resetFunction
 * @param {function} updateItembyId
 * @param {function} updateMutationFunction
 */
export const showFieldComponent = ({
  fieldName,
  fieldIndex,
  item,
  typename,
  customFields,
  module,
  resource,
  mutation,
  resetFunction,
  updateItemById,
  updateMutationFunction,
  disableRoleChecking,
  fieldNameDictionary,
}) => {
  let inputType = GENERIC_FIELD_TYPES.TEXT;

  //Check required arguments
  try {
    checkArguments(
      { fieldName, item },
      {
        fieldName: { type: "string" },
        item: { type: "object" },
      },
    );
  } catch (e) {
    ErrorLogger(e);
    return invalidPlaceholder;
  }

  //Check for custom field component
  let customField;
  let customFieldInputType;
  if (Array.isArray(customFields)) {
    customField = customFields.find((item) => item.field === fieldName);
  }
  if (!isNullOrUndefined(customField)) {
    if (customField?.component) {
      return React.cloneElement(customField.component, {
        item: !customField.disableItemPropagation ? item : undefined,
        [customField.propName || customField.field]: item[customField.field],
        resetFunction,
        updateItemById, // used to be compatible with list customFields
        fieldContext: fieldContexts.DETAILS,
      });
    } else if (customField?.inputType) {
      inputType = customField.inputType;
      customFieldInputType = customField.inputType;
    }
  }

  //NOTE: the rest of field types depend on the field to be present in item object,
  //check if such given fieldName is a property of the item object
  let value = null;
  if (!item.hasOwnProperty(fieldName)) {
    return emptyPlaceholder;
  } else {
    value = item[fieldName];
  }

  //When input type is not provided try to display nested data as nested tables
  if (!isNullOrUndefined(value) && isNullOrUndefined(customFieldInputType)) {
    //Check if field value is an array
    if (typeof value === "object") {
      let items = null;
      if (value.items && Array.isArray(value.items)) {
        items = value.items;
      } else if (Array.isArray(value)) {
        items = value;
      }

      if (items) {
        return <CustomTable disableRoleChecking={true} data={items} />;
      }
    }

    //Check if field value is an object (nested details)
    if (typeof value === "object" && !Array.isArray(value)) {
      return <DetailsTable disableRoleChecking={true} item={value} />;
    }
  }

  //Check if a non-object/array field with a mutation
  if (mutation) {
    return (
      <GenericEditFieldV3
        item={item}
        typename={typename}
        field={fieldName}
        module={module}
        resource={resource}
        mutation={mutation}
        updateItemById={updateItemById}
        updateMutationFunction={(input) => {
          if (updateMutationFunction) {
            return updateMutationFunction({ input, fieldName, fieldIndex });
          } else {
            return input;
          }
        }}
        disableRoleChecking={disableRoleChecking}
        inputType={inputType}
        friendlyName={fieldNameDictionary?.[fieldName]}
        fieldContext={fieldContexts.DETAILS}
        inputConfig={customField?.inputConfig}
        displayConfig={customField?.displayConfig}
      />
    );
  }

  //Return normal string if non-object/array field without a mutation
  return value || emptyPlaceholder;
};
