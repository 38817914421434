import { modules, resources } from "@rivial-security/role-utils";
import DocumentDetails from "../components/DocumentDetails";
import DocumentUploadDate from "../customFields/DocumentUploadDate";
import React from "react";
import UploadDocument from "../components/UploadDocument";
import { deleteDocument } from "../functions/deleteDocument";
import { documentsByOwnerGroup } from "../graphql/documentsByOwnerGroup";
import { useDataGrid } from "../../useDataGrid/useDataGrid";
import { getFileDataGridFields } from "@hooks/views/useDocumentGrid/functions/getFileDataGridFields";

/**
 * @description Display documents grid hook
 * NOTE: If you pass data from a parent component your document query required to have this fields:
 * { items { id createdAt name encrypted encryptionKey file { key bucket region } ownerGroup } }
 * @param {string} organizationID - selected organization
 * @param {object} queryConfig - configuration object for the query
 * @param {object} gridConfig - configuration object for the grid
 * @param {object[]} fields - custom fields for the grid
 * @param {string} module - module name
 * @param {string} resource - resource name
 * @param {boolean} disableRoleChecking - enable/disable role check
 * @param {object} connectionInput - connection fields for a new document
 * @param {object} props
 * @returns {object} {{gridDisplay: *, data: * | undefined, lastSelectedItem: string | undefined, setData: {(value: (((prevState: *) => *) | *)): void, (value: (((prevState: undefined) => undefined) | undefined)): void}, display: JSX.Element, isLoading: boolean | undefined, ref: string | undefined, setSelectedItems: {(value: (((prevState: []) => []) | [])): void, (value: (((prevState: undefined) => undefined) | undefined)): void}, setIsLoading: {(value: (((prevState: boolean) => boolean) | boolean)): void, (value: (((prevState: undefined) => undefined) | undefined)): void}, setLastSelectedItem: {(value: (((prevState: string) => string) | string)): void, (value: (((prevState: undefined) => undefined) | undefined)): void}, resetFunction: function(): void, fields: Object[] | undefined, selectedItems: [] | undefined}}
 */
export const useDocumentDataGrid = ({
  organizationID,
  queryConfig = {},
  gridConfig = {},
  fields = [],
  module = modules.GOVERNANCE,
  resource = resources.DOCUMENT,
  disableRoleChecking = false,
  connectionInput,
  ...props
}) => {
  const roleConfig = {
    module,
    resource,
    disableRoleChecking,
  };

  const cardConfig = {
    title: "Documents",
    headerIcon: "icon-docs",
  };

  queryConfig = {
    query: documentsByOwnerGroup,
    variables: {
      ownerGroup: organizationID,
    },
    disableRoleChecking,
    ...queryConfig,
  };

  fields = [
    {
      name: "name",
      flex: 1,
      minWidth: 350,
    },
    {
      name: "createdAt",
      component: <DocumentUploadDate />,
      friendlyName: "Upload Date",
      width: 200,
      type: "date",
    },
    ...getFileDataGridFields({}),
    ...fields,
  ];

  gridConfig = {
    typename: "Document",
    fields,
    createResourceComponent: <UploadDocument connectionInput={connectionInput} />,
    detailsComponent: <DocumentDetails />,
    options: ["details", "delete"],
    deleteFunction: deleteDocument,
    route: "#/documents/document/",
    persistenceUUID: "documentGrid-2657-897535-8878-5555",
    disableRoleChecking,
    organizationID,
    ...gridConfig,
    ...props,
  };

  const grid = useDataGrid({
    ...queryConfig,
    ...gridConfig,
    ...cardConfig,
    ...roleConfig,
  });

  return { ...grid };
};
