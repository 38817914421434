import TimelineUnderlinedDetails from "./TimelineUnderlinedDetails";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { resources } from "@rivial-security/role-utils";

/**
 * Displays the initial status text with the timeline details
 * @param {string} initialStatusText - the initial status text
 * @param {object} item - the TargetVulnerabilityHistory item
 * @returns {JSX.Element}
 */
const InitialStatusTextWithTimelineDetails = ({ initialStatusText, item }) => {
  return (
    <>
      {initialStatusText}
      {!isNullOrUndefined(item?.assessmentID) && (
        <TimelineUnderlinedDetails
          tooltipText="Click to view Assessment Details"
          modalType={resources.ASSESSMENT}
          item={item}
        >
          Assessment
        </TimelineUnderlinedDetails>
      )}
    </>
  );
};
export default InitialStatusTextWithTimelineDetails;
