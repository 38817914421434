import React from "react";
import { Spinner } from "reactstrap";
import { modules, resources } from "@rivial-security/role-utils";
import KeyRiskIndicators from "../../../Risk/Systems/components/SystemAccordion/ThreatAssessment/components/KeyRiskIndicators";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * Displays Key Risk Indicators for System Template Details
 * @param module
 * @param resource
 * @param disableRoleChecking
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SystemTemplateThreatAssessment = ({
  module = modules.ADMINISTRATOR,
  resource = resources.SYSTEM_TEMPLATE,
  disableRoleChecking = false,
  ...props
}) => {
  const fields = ["name", "description"];

  const fieldNameDictionary = {
    annualRateOfOccurrence: "Probability",
    name: "Name",
    probabilityModifier: "Probability Modifier",
    description: "Description",
  };

  return (
    <div>
      {props.system ? (
        <KeyRiskIndicators
          Context={props.Context}
          viewType={props.viewType}
          organizationID={props.organizationID}
          system={props.system}
          isAdmin={props.isAdmin}
          printView={props.printView}
          resetFunction={props.resetFunction}
          fields={fields}
          fieldNameDictionary={fieldNameDictionary}
          module={module}
          resource={resource}
          disableRoleChecking={disableRoleChecking}
        />
      ) : (
        <div>
          Loading Key Risk Indicators.. <Spinner size="sm" color="primary" />
        </div>
      )}
    </div>
  );
};

export default withOrganizationCheck(SystemTemplateThreatAssessment);
