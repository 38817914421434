import { usePointOfContactDataGrid } from "../../../OrganizationManager/PointOfContacts/hooks/usePointOfContactDataGrid";
import { Button } from "reactstrap";
import React, { useEffect } from "react";

/**
 *
 * @param organizationID {string}
 * @param setContacts {function}
 * @param toggleModal {function}
 * @returns {JSX.Element}
 * @constructor
 */

const MeetingSelectPointOfContact = ({ organizationID, setContacts, contacts, toggleModal }) => {
  const pointOfContactListHook = usePointOfContactDataGrid({
    organizationID: organizationID,
    enableSelection: true,
  });

  useEffect(() => {
    pointOfContactListHook.setSelectedData(contacts);
  }, []);

  return (
    <div style={{ height: "40rem" }}>
      {pointOfContactListHook.dashboardDisplay}
      <br />
      <Button
        color="success"
        className="float-right"
        onClick={() => {
          setContacts(pointOfContactListHook.selectedData);
          toggleModal?.();
        }}
      >
        Done
      </Button>
    </div>
  );
};

export default MeetingSelectPointOfContact;
