import { ErrorLogger } from "@utils/EventLogger";
import { GetQuery } from "@rivial-security/appsync-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 * Utility function to get a single artifact from the database by its ID
 * @param {string} id - the ID of the artifact to get
 * @returns {Promise<*|null>} - null on error, otherwise the artifact object
 */
export const getArtifact = async ({ id }) => {
  try {
    const { getQuery } = generateGraphql(
      "Artifact",
      ["name", "evidenceActivity", "evidence", "status", "labels", "reason", "notes", "evidence", "createdAt", "file"],
      {
        evidenceActivity: `{
          id
          ownerGroup
          createdAt
          type
          description
          attested
          validatedBy
          pointOfContact {
            firstName
            lastName
            email
          }
          comment
          externalUrl
          reportID
          meetingID
          policyID
          trainingID
          phishingID
          documents {
            items {
              id
              name
              owner
              visibility
              createdAt
              file {
                bucket
                region
                key
              }
              ownerGroup
            }
            nextToken
          }
        }`,
        evidence:
          "{ id ownerGroup name status enabled artifacts(limit: 500) { items { id labels(limit: 500) { items { id label { id name description backgroundColor fontColor } } } } } }",
        file: "{ bucket region key }",
        labels:
          "(limit: 500) { items { id bookmarks { id owner createdByAI text data } label { id name description backgroundColor fontColor } } }",
        notes: "{ id type ownerGroup author timeStamp content tag observationID }",
      },
    );

    const artifact = await GetQuery({
      query: getQuery,
      variables: { id },
    });

    return artifact;
  } catch (e) {
    ErrorLogger(e);
    return null;
  }
};
