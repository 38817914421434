import { useRiskChangeDetails } from "../hooks/useRiskChangeDetails";
import { modules, resources } from "@rivial-security/role-utils";
import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";

/**
 * Details component that displays RiskChange information.
 * Component instance of the useRiskChangeDetails hook
 * @param props
 * @returns {*}
 * @constructor
 */
const RiskChangeDetails = (props) => {
  const itemId = (props.item && props.item.id) || props.match.params.id;
  const module = modules.RISK;
  const resource = resources.RISK_CHANGE;

  return useRiskChangeDetails({
    itemId,
    module,
    resource,
    ...props,
  }).display;
};

export default withDetailsPage(RiskChangeDetails);
