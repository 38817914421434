import React from "react";
import { useAccordion } from "../../../../hooks/views/useAccordion/useAccordion";
import { getIcon } from "../../../../utils/Functions/Icon/getIcon";
import SystemImpact from "../../../Risk/Systems/components/SystemAccordion/SystemImpact/components/SystemImpact";
import Controls from "../../../Risk/Systems/components/SystemAccordion/ControlCategories/components/Controls";
import SystemTemplateOverview from "./SystemTemplateOverview";
import SystemTemplateThreatAssessment from "./SystemTemplateThreatAssessment";

/**
 * ADMIN ONLY
 * Displays the different elements of System Template Details in an Accordion fashion.
 * Matches the normal SystemAccordion but doesn't include sections that are inherently Organization-specific
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SystemTemplateAccordion = (props) => {
  const system = props.item;

  const passProps = {
    system,
    resetFunction: props.resetSystem,
    isTemplate: true,
    ...props,
  };

  const accordion = useAccordion({
    items: [
      {
        icon: "icon-list",
        title: "General Information",
        subTitle: "Name, hosting type, and points of contact",
        component: <SystemTemplateOverview {...passProps} />,
      },
      {
        icon: getIcon("fluent:convert-range-24-regular"),
        title: "System Impact",
        subTitle: "Information Assets, Records, and Impact configuration",
        component: <SystemImpact {...passProps} />,
      },
      {
        icon: getIcon("simple-line-icons:energy"),
        title: "Threat Assessment",
        subTitle: "Key Risk Indicators and Risk Estimates",
        component: <SystemTemplateThreatAssessment {...passProps} />,
      },
      {
        icon: getIcon("il:controls"),
        title: "Controls",
        subTitle: "Control Categories and Risk Mitigations",
        component: <Controls {...passProps} />,
      },
    ],
  });

  return <div className="shadow">{accordion.display}</div>;
};

export default SystemTemplateAccordion;
