import TabCard from "../../../../utils/GenericComponents/TabCard";
import React from "react";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { GAUGE } from "../../WidgetRenderer/constants/BUILT_IN_WIDGETS";
import BuiltInWidgetSelectionTiles from "./BuiltInWidgetSelectionTiles";

/**
 * Displays a tabbed card for selecting a gauge widget
 *
 * @param {string} organizationID - The organization ID
 * @param {function} onSelect - The function to call when a widget is selected
 * @returns {JSX.Element}
 * @constructor
 */
const GaugeWidgetTabCard = ({ organizationID, onSelect }) => {
  return (
    <div>
      <TabCard
        // tabDisplay
        items={[
          {
            icon: "icon-grid",
            title: "Built In",
            component: (
              <BuiltInWidgetSelectionTiles widgets={GAUGE} organizationID={organizationID} onSelect={onSelect} />
            ),
          },
        ]}
      />
    </div>
  );
};

export default withOrganizationCheck(GaugeWidgetTabCard);
