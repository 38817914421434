/**
 * Author: Jacob Blazina
 * Created At: 10/3/19
 * Edits:
 *  *  - 10/18/19 JB: Added better styling
 *     - 10/18/19 JB: Added Context Menu and Print options (WIP)
 *
 * Description: Displays a Program Blueprint.
 *              Edits are turned off by default, but may be switched on via the `enableEdits` prop.
 *              This component displays a group of Maturity Columns as its children.
 *              This component is UI only (no query), it MUST receive a "maturityMatrix" object as a prop to function.
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import MaturityColumnHorizontal from "../../deprecated/MaturityColumn/MaturityColumnHorizontal";
import { GenericModalWithButton } from "../../../../../../utils/GenericComponents/GenericModalWithButton";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import CreateMaturityColumn from "../../deprecated/MaturityColumn/CreateMaturityColumn";
import GenericEditField from "../../../../../../utils/GenericComponents/GenericEditField";
import MaturityLevelRadarChart from "../../../../../Charts/VISCO/MaturityLevelRadarChart";

const MaturityMatrixHorizontal = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalBody, setModalBody] = useState(null);
  const [matrixSettings, setMatrixSettings] = useState({});
  const [filename, setFilename] = useState("untitled_matrix");
  const [pdf, setPdf] = useState("");
  const [showChart, setShowChart] = useState(false);

  const setModal = (body) => {
    body && setModalIsOpen(!modalIsOpen);
    setModalBody(body);
  };

  return (
    <Row>
      {props.maturityMatrix ? (
        <>
          {props.settings.showRadarChart && (
            <Col lg={4}>
              <div style={{ height: "500px", background: "white" }}>
                <MaturityLevelRadarChart maturityMatrix={props.maturityMatrix} />
              </div>
            </Col>
          )}
          <Col lg={props.settings.showRadarChart ? 8 : 12}>
            <Row>
              <h4 style={{ marginBot: "20px", textAlign: "center" }}>
                <GenericEditField
                  item={props.maturityMatrix}
                  field="name"
                  mutation="updateMaturityMatrix"
                  disableEdits={!props.enableEdits}
                />
              </h4>
              {props.enableEdits ? (
                <>
                  <GenericModalWithButton
                    title={`Create Maturity Column for: ${props.maturityMatrix.name}`}
                    body={<CreateMaturityColumn maturityMatrix={props.maturityMatrix} />}
                    modalButton={
                      <Button style={{ marginBottom: "15px" }} color="success">
                        Create New Maturity Column
                      </Button>
                    }
                  />
                </>
              ) : null}
            </Row>
            {/*<PDFExport*/}
            {/*  scale={0.6}*/}
            {/*  margin="1in"*/}
            {/*  landscape*/}
            {/*  paperSize="Letter"*/}
            {/*  fileName={filename + ".pdf"}*/}
            {/*  title=""*/}
            {/*  subject=""*/}
            {/*  keywords=""*/}
            {/*  ref={(r) => setPdf(r)}*/}
            {/*>*/}
            <div style={{ overflowX: "auto", float: "none", whiteSpace: "nowrap" }}>
              {props.maturityMatrix &&
              props.maturityMatrix.maturityColumns &&
              props.maturityMatrix.maturityColumns.items &&
              props.maturityMatrix.maturityColumns.items.length > 0
                ? props.maturityMatrix.maturityColumns.items.map((column, index) => (
                    <MaturityColumnHorizontal
                      matrixSettings={matrixSettings}
                      enableEdits={props.enableEdits}
                      key={`maturityColumn${column.id}${index}`}
                      maturityColumn={column}
                      setModalBody={setModal}
                    />
                  ))
                : "No Maturity Columns"}
            </div>
            {/*</PDFExport>*/}
          </Col>
        </>
      ) : null}
      <Modal isOpen={modalIsOpen} toggle={() => setModalIsOpen(!modalIsOpen)}>
        <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}>Maturity Indicator Details</ModalHeader>
        <ModalBody>{modalBody && React.cloneElement(modalBody)}</ModalBody>
      </Modal>
    </Row>
  );
};

MaturityMatrixHorizontal.propTypes = {
  maturityMatrix: PropTypes.object.isRequired,
  enableEdits: PropTypes.bool,
};

MaturityMatrixHorizontal.defaultProps = {
  enableEdits: false,
};

export default MaturityMatrixHorizontal;
