import { useModal } from "../../../../hooks/views/useModal";
import { Button as MuiButton } from "@mui/material";
import AddOrRemoveIcon from "../../../../utils/GenericComponents/AddOrRemoveIcon";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import React from "react";
import RoleUserLinking from "./RoleUserLinking";

/**
 * Modal Button for for linking/unlinking Users from an Role
 * @param {object} item - the Role to link Users to
 * @param organizationID
 * @param resetFunction
 * @returns {JSX.Element}
 * @constructor
 */
const RoleUserLinkingButton = ({ item, organizationID, resetFunction }) => {
  const linkingModal = useModal(
    <>Link / Unlink Users for this Role</>,
    <RoleUserLinking {...{ item, organizationID, resetFunction }} />,
    <MuiButton className="float-right" title="Link / Unlink Users to this Role">
      <AddOrRemoveIcon />
    </MuiButton>,
    {
      width: "80vw",
    },
  );

  return linkingModal.modalButton;
};

export default withOrganizationCheck(RoleUserLinkingButton);
