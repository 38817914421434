import MODULE_TYPES from "../enums/modules";
import PRECEDENCE_TYPES from "../enums/precedenceTypes";
import RESOURCES from "../enums/resources";
import _ from "lodash";

/**
 * Filters a roleConfig object based on the Role's precedence (Role Type)
 * @param roleConfig
 * @param roleType
 * @returns {*}
 */
export const handleRoleTypes = (roleConfig, roleType) => {
  switch (roleType) {
    case PRECEDENCE_TYPES.USER:
      return handleRoleType_USER(roleConfig);
    case PRECEDENCE_TYPES.SUPERVISOR:
      return handleRoleType_SUPERVISOR(roleConfig);
    case PRECEDENCE_TYPES.MANAGER:
      return handleRoleType_MANAGER(roleConfig);
    case PRECEDENCE_TYPES.ADMIN:
      return handleRoleType_ADMIN(roleConfig);
    case PRECEDENCE_TYPES.DEVELOPER:
      return handleRoleType_DEVELOPER(roleConfig);
    default:
      throw Error(`Can't create a role due to invalid Role Type: ${roleType}`);
  }
};

/**
 * Filters roleConfig based on the USER role type
 * @param roleConfig
 * @returns {*}
 */
const handleRoleType_USER = (roleConfig) => {
  const blacklistModules = [MODULE_TYPES.ADMINISTRATOR, MODULE_TYPES.DEVELOPER, MODULE_TYPES.ORGANIZATION_MANAGER];
  const blacklistResources = [];

  return handleBlacklists(roleConfig, blacklistModules, blacklistResources);
};

/**
 * Filters the roleConfig based on the SUPERVISOR role type
 * @param roleConfig
 * @returns {*}
 */
const handleRoleType_SUPERVISOR = (roleConfig) => {
  const blacklistModules = [MODULE_TYPES.ADMINISTRATOR, MODULE_TYPES.DEVELOPER];
  const blacklistResources = [RESOURCES.ROLE, RESOURCES.AUTOMATION];

  return handleBlacklists(roleConfig, blacklistModules, blacklistResources);
};

/**
 * Filters the roleConfig based on the MANAGER role type
 * @param roleConfig
 * @returns {*}
 */
const handleRoleType_MANAGER = (roleConfig) => {
  const blacklistModules = [MODULE_TYPES.ADMINISTRATOR, MODULE_TYPES.DEVELOPER];
  const blacklistResources = [];

  return handleBlacklists(roleConfig, blacklistModules, blacklistResources);
};

/**
 * Filters the roleConfig based on the ADMIN role type
 * @param roleConfig
 * @returns {*}
 */
const handleRoleType_ADMIN = (roleConfig) => {
  const blacklistModules = [MODULE_TYPES.DEVELOPER];
  const blacklistResources = [];

  return handleBlacklists(roleConfig, blacklistModules, blacklistResources);
};

/**
 * Filters the roleConfig based on the DEVELOPER role type
 * @param roleConfig
 * @returns {*}
 */
const handleRoleType_DEVELOPER = (roleConfig) => {
  return roleConfig;
};

/**
 * Removes module keys from roleConfig.modules
 * Removes resource keys from roleConfig.resources
 * Removes function keys from roleConfig.functions
 * @param roleConfig
 * @param modules
 * @param resources
 * @returns {*}
 */
const handleBlacklists = (roleConfig, modules, resources) => {
  const result = _.cloneDeep(roleConfig);

  for (const module of modules) {
    delete result.modules[module];
  }

  for (const resource of resources) {
    delete result.resources[resource];
  }

  return result;
};

/**
 * Sets default Resource CRUD permissions and module enabled permissions based on precedence
 * @param roleConfig
 * @param precedence
 * @returns {*}
 */
export const handleDefaultOperations = (roleConfig, precedence) => {
  switch (precedence) {
    case PRECEDENCE_TYPES.USER:
      return handleDefaults(roleConfig, defaultOperations_USER);
    case PRECEDENCE_TYPES.SUPERVISOR:
      return handleDefaults(roleConfig, defaultOperations_SUPERVISOR);
    case PRECEDENCE_TYPES.MANAGER:
      return handleDefaults(roleConfig, defaultOperations_MANAGER, true);
    case PRECEDENCE_TYPES.ADMIN:
      return handleDefaults(roleConfig, defaultOperations_MANAGER, true);
    case PRECEDENCE_TYPES.DEVELOPER:
      return handleDefaults(roleConfig, defaultOperations_MANAGER, true);
    default:
      throw Error(`Could not set default operations, invalid role type: ${precedence}`);
  }
};

/**
 * Handles the object operation for setting default Resource and Module permissions
 * @param roleConfig
 * @param operations
 * @param enableAllModules
 * @returns {*}
 */
const handleDefaults = (roleConfig, operations, enableAllModules = false) => {
  const result = _.cloneDeep(roleConfig);
  for (const resourceName in result.resources) {
    result.resources[resourceName] = {
      ...result.resources[resourceName],
      ...operations,
    };
  }

  if (enableAllModules === true) {
    for (const moduleName in result.modules) {
      result.modules[moduleName] = true;
    }
  }
  return result;
};

/**
 * Default resource operation permissions for USER roles
 * @type {{read: boolean, create: boolean, update: boolean, delete: boolean}}
 */
const defaultOperations_USER = {
  create: false,
  read: true,
  update: false,
  delete: false,
};

/**
 * Default resource operation permissions for SUPERVISOR roles
 * @type {{read: boolean, create: boolean, update: boolean, delete: boolean}}
 */
const defaultOperations_SUPERVISOR = {
  create: false,
  read: true,
  update: true,
  delete: false,
};

/**
 * Default resource operation permissions for MANAGER, ADMIN, and DEVELOPER roles
 * @type {{read: boolean, create: boolean, update: boolean, delete: boolean}}
 */
const defaultOperations_MANAGER = {
  create: true,
  read: true,
  update: true,
  delete: true,
};
