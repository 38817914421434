import { ErrorLogger } from "@utils/EventLogger";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { generateGraphql } from "@rivial-security/generategraphql";
import { getResourceFields } from "./getResourceFields";
import { handleFieldFormat } from "./handleFieldFormat";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { preProcessSingleConnectionRow } from "./preProcessSingleConnectionRow";

/**
 * Function to automatically processing CSV rows based on the field configs
 *
 * @param {object} row - single row entry to process
 * @param {object} columnMap - column mapping object
 * @param {string} organizationID - ownerGroup field for mutation
 * @param {object[]} fields - graphql fields used to generate mutation graphql
 * @param {object} nestedFields - graphql nested fields used to generate mutation graphql so that the result grid can be populated correctly
 * @param {string} connectionIDField - graphql connection ID field
 * @param {string} connectionID - graphql connection ID
 * @param {string} typename - graphql model typename used to generate mutation graphql
 * @param {function} preProcessRow - function to run before row is processed (async)
 * @returns {Promise<*>}
 */
export const processRow = async (
  row,
  columnMap,
  organizationID,
  fields,
  nestedFields = {},
  connectionIDField,
  connectionID,
  typename,
  preProcessRow,
) => {
  const resourceFields = getResourceFields(fields);

  const { createMutation } = generateGraphql(typename || fields?.[0]?.typename, resourceFields, {
    notes: `{ id type ownerGroup author timeStamp content tag }`,
    ...nestedFields,
  });

  const res = {
    ownerGroup: organizationID,
  };

  for (const csvHeader in row) {
    // Get the Column Name from the mapping file
    const columnName = columnMap[csvHeader];

    // Skip csv column if there is no mapped import field
    if (!columnName || columnName === "n/a") continue;

    // Get the corresponding Field Config object
    const { name, field, typename, options, defaultValue, interpreter, removeFromMutation, required, format } =
      fields.find((config) => config.name === columnName) || {};

    // First, check if we need to ignore this field completely
    if (removeFromMutation) {
      continue;
    }

    // Get the current cell value
    const value = row[csvHeader];

    // The optional interpreter function gets first precedence
    if (typeof interpreter === "function") {
      res[field] = interpreter(value);
    }

    // Then, check if this field has restricted options
    else if (Array.isArray(options)) {
      // If value is found in options, use it
      if (options.includes(value)) {
        res[field] = value;
      }

      // Else use default value
      else if (isNullOrUndefined(value) && !isNullOrUndefined(defaultValue)) {
        res[field] = defaultValue;
      }
    }

    // Handle custom formatting
    if (format) {
      res[field] = handleFieldFormat({ value, format, organizationID });
    }

    // Finally, try and use value directly. Note: mutation will throw an error if the value is improper
    else {
      res[field] = value;
    }

    // If value is blank Handle default value
    if (!isNullOrUndefined(defaultValue)) {
      // If value is null, undefined, or empty string add default value
      if (isNullOrUndefined(res[field]) || res[field] === "") {
        res[field] = defaultValue; // ?? need to pass empty string, or undefined?
      }
    }

    // Check if field is required and has value
    if (required) {
      if (res[field] === null || res[field] === undefined || res[field] === "") {
        ErrorLogger("Error: Could not process row because it's missing a required field");

        // Returning out of the loop to stop row processing
        return;
      }
    }
  }

  // Find field configuration for row preprocessing
  const foundFieldConfigs = fields.filter((field) => {
    if (field.connectionItemList && field.connectionItemField) {
      return true;
    }
    return false;
  });

  let preProcessedRow;

  // First, handle preprocess if configured
  if (typeof preProcessRow === "function") {
    try {
      preProcessedRow = await preProcessRow(res);
    } catch (e) {
      ErrorLogger("Error: could not pre-process row! ", row);
    }
  }
  // If no preprocess function, and connection field configs, automatically apply connection processing
  else if (Array.isArray(foundFieldConfigs) && foundFieldConfigs.length > 0) {
    for (const config of foundFieldConfigs) {
      try {
        preProcessedRow = await preProcessSingleConnectionRow({
          input: res,
          connectionItemID: config.connectionItemID,
          connectionItemList: config.connectionItemList,
          connectionItemField: config.connectionItemField,
        });
      } catch (e) {
        ErrorLogger("Could not pre-process row! ", e);
      }
    }
  }

  return await ItemMutation(createMutation, preProcessedRow || res);
};
