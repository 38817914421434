import { useEffect, useState } from "react";

import { ErrorLogger } from "@utils/EventLogger";
import { ItemQuery } from "../../../../utils/Functions/Graphql/ItemQuery";
import { generateGraphql } from "@rivial-security/generategraphql";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Handles getting custom field configurations from a control framework
 * @param controlFrameworkID
 * @returns {{customFields: *[]}}
 */
export const useControlFrameworkCustomFields = ({ controlFrameworkID }) => {
  const { getQuery: getControlFrameworkQuery } = generateGraphql("ControlSet", ["customFields"], {
    customFields: `{name type options { label value } multipleSelect { label value } numberSettings { min max step format }}`,
  });

  const [customFields, setCustomFields] = useState([]);

  // - updates custom fields configuration for selected control set
  const updateCustomFields = async () => {
    //Retrieve custom fields for the new control framework
    const controlSetCustomFieldNames = [];
    let newCustomFields = [];
    try {
      // - retrieve control framework custom fields from db
      let controlFramework;
      if (controlFrameworkID) {
        controlFramework = await ItemQuery(getControlFrameworkQuery, controlFrameworkID);
      }

      // - check for custom fields property to be present
      if (controlFramework?.customFields) {
        newCustomFields = controlFramework.customFields;
      }

      // - get all control set custom field names
      if (newCustomFields && Array.isArray(newCustomFields)) {
        for (const customField of newCustomFields) {
          if (customField?.name) {
            controlSetCustomFieldNames.push(customField.name);
          }
        }
      }
    } catch (e) {
      ErrorLogger("Failed to get custom control framework fields in control grid!", e);
    }
    setCustomFields(newCustomFields);
  };

  useEffect(() => {
    if (!isNullOrUndefined(controlFrameworkID)) {
      updateCustomFields();
    }
  }, [controlFrameworkID]);

  return {
    customFields,
  };
};
