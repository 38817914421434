import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";
import Audit from "./Audit";

/**
 * Component for displaying Audit Details page
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default withDetailsPage(Audit);
