import { Button, ButtonGroup } from "reactstrap";
import React, { useContext, useEffect, useState } from "react";
import { modules, resources } from "@rivial-security/role-utils";

import { ErrorLogger } from "@utils/EventLogger";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { ItemQuery } from "../../../../utils/Functions/Graphql/ItemQuery";
import { LoadingSpinner } from "../../../../utils/LoadingComponents/LoadingSpinner";
import { WorkflowContext } from "../../../../hooks/views/useWorkflow";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";

/**
 * Displays the current Rating in context, and allows the user to change the Rating
 * @param {VendorSolutionReview} vendorReview
 * @param module
 * @param resource
 * @param disableRoleChecking
 * @returns {*}
 * @constructor
 */
const Rating = ({
  vendorReview = {},
  module = modules.VENDORS,
  resource = resources.VENDOR_REVIEW,
  disableRoleChecking,
}) => {
  const checkPermissions = useCheckPermissions({
    module,
    resource,
    disableRoleChecking,
  });

  const disabled = !checkPermissions.resource.update;

  const { rating, addToContext } = useContext(WorkflowContext);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // If 'rating' is not already in context, query and add it
    if (!rating) {
      ItemQuery(generateGraphql("VendorReview", ["rating"]).getQuery, vendorReview.id).then((item) => {
        if (item && item.rating) {
          addToContext("rating", item.rating);
        }
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, [rating]);

  /**
   * The onClick handler for the set rating buttons
   * @param rating
   * @returns {Promise<void>}
   */
  const handleRating = async (rating) => {
    const { updateMutation } = generateGraphql("VendorReview", ["rating"]);
    if (rating) {
      ItemMutation(updateMutation, { id: vendorReview.id, rating })
        .then(() => {
          addToContext("rating", rating);
        })
        .catch((e) => {
          ErrorLogger("Could not set the Rating. Something went wrong!", e);
        });
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner loadingMessage="Loading Vendor Review Rating.. " />
      ) : (
        <ButtonGroup size="sm">
          <Button
            color={rating === "low" ? "success" : "ghost-success"}
            disabled={disabled}
            active={rating === "low"}
            onClick={async () => await handleRating("low")}
          >
            Low
          </Button>
          <Button
            color={rating === "lowMedium" ? "primary" : "ghost-primary"}
            disabled={disabled}
            active={rating === "lowMedium"}
            onClick={() => handleRating("lowMedium")}
          >
            Low-Medium
          </Button>
          <Button
            color={rating === "medium" ? "secondary" : "ghost-secondary"}
            disabled={disabled}
            active={rating === "medium"}
            onClick={() => handleRating("medium")}
          >
            Medium
          </Button>
          <Button
            color={rating === "mediumHigh" ? "warning" : "ghost-warning"}
            disabled={disabled}
            active={rating === "mediumHigh"}
            onClick={() => handleRating("mediumHigh")}
          >
            Medium-High
          </Button>
          <Button
            color={rating === "high" ? "danger" : "ghost-danger"}
            disabled={disabled}
            active={rating === "high"}
            onClick={() => handleRating("high")}
          >
            High
          </Button>
        </ButtonGroup>
      )}
    </>
  );
};

export default Rating;
