import { REPORT_TEMPLATES } from "@rivial-security/report-utils";
import React from "react";
import PermissionsOverlay from "../../../../../utils/Overlays/PermissionsOverlay";
import { modules, resources } from "@rivial-security/role-utils";
import { getIcon } from "../../../../../utils/Functions/Icon/getIcon";
import Button from "@mui/material/Button";
import { useModal } from "../../../../../hooks/views/useModal";
import { useDocumentEditor } from "../../../../Reports/DocumentEditor/hooks/useDocumentEditor";
import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";

/**
 * @description Generate Control Framework Report component
 * @param {string} organizationID - selected organization
 * @param {string} itemId - control framework id
 * @returns {JSX.Element}
 */
const GenerateControlFrameworkReport = ({ organizationID, itemId }) => {
  const documentEditor = useDocumentEditor({
    organizationID,
    type: REPORT_TEMPLATES.COMPLIANCE_REPORT_TEMPLATE,
    module: modules.COMPLIANCE,
    inModal: true,
  });

  const modal = useModal(
    "Continues Compliance Report",
    <div style={{ display: "flex", height: "100%", maxHeight: "100%", flex: 1 }}>{documentEditor.display}</div>,
    "",
    {
      width: "90vw",
    },
  );

  return (
    <PermissionsOverlay module={modules.REPORTS} resource={resources.REPORT} operationType={"create"}>
      <div>
        {modal?.modal}
        <Button
          onClick={() => {
            modal.setModalIsOpen(true);
            documentEditor.menuOnClick(REPORT_TEMPLATES.COMPLIANCE_REPORT_TEMPLATE, { itemId });
          }}
          startIcon={getIcon("vscode-icons:file-type-word2")}
        >
          Generate Report
        </Button>
      </div>
    </PermissionsOverlay>
  );
};

export default withOrganizationCheck(GenerateControlFrameworkReport);
