import { useCreateVendorControlCategory } from "../hooks/useCreateVendorControlCategory";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 *
 * @param organizationID
 * @param props
 * @constructor
 */
const CreateVendorControlCategory = ({ organizationID, ...props }) => {
  return useCreateVendorControlCategory({ organizationID, ...props }).display;
};

export default withOrganizationCheck(CreateVendorControlCategory);
