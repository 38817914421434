import { GetQuery } from "../../../../utils/Functions/Graphql/GetQuery";
import { InfoLogger } from "@utils/EventLogger";

/**
 * Helper function for calling the getVulnerabilityTargetHistory lambda
 * @param {object} input - input into the lambda function, see the lambda readme for accepted parameters
 * @returns {Promise<[]>}
 */
export const getVulnerabilityTargetHistory = async (input) => {
  const lambdaGraphql = `
    query getVulnerabilityTargetHistory($input: AWSJSON) {
      getVulnerabilityTargetHistory(input: $input)
    }
  `;

  InfoLogger(`Sending request to getVulnerabilityTargetHistory. Input: ${JSON.stringify(input)}`);

  const result = await GetQuery({
    query: lambdaGraphql,
    variables: {
      input: JSON.stringify(input),
    },
  });

  return JSON.parse(result);
};
