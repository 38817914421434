import React, { useState } from "react";
import OrganizationCheck from "../../../../../utils/Context/OrganizationCheck/OrganizationCheck";
import { useMutation } from "../../../../../hooks/graphql/useMutation/useMutation";
import { Form, Label, Input, Button } from "reactstrap";
import { useCheckPermissions } from "../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";
import { useSelectTemplatesModal } from "../../../../../hooks/views/useSelectTemplates/hooks/useSelectTemplatesModal";

const { createMutation: createThreat } = generateGraphql("Threat", ["name"]);

const CreateThreatBody = (props) => {
  const [name, setName] = useState("");

  const module = modules.RISK;
  const resource = resources.THREAT;

  const checkPermissionsHook = useCheckPermissions({ module, resource });

  const createThreatMutation = useMutation({
    mutation: createThreat,
    module,
    resource,
  });

  const handleSubmit = async () => {
    const input = {
      ownerGroup: props.organizationID,
      name: name,
      responseTeamID: "n/a",
    };

    const result = await createThreatMutation.createItem(input);
    props.toggleModal && props.toggleModal();
    props.getNewItem && props.getNewItem(result);
    props.callback && props.callback(result);
  };

  const selectTemplateModal = useSelectTemplatesModal({
    resource,
    onStartedDuplication: () => {
      props.toggleModal && props.toggleModal();
    },
    getNewItem: (item) => {
      props.getNewItem && props.getNewItem(item);
      props.callback && props.callback(item);
    },
  });

  return (
    <Form>
      {selectTemplateModal.modalButton}
      <br />
      <Label for="name">Name</Label>
      <Input value={name} onChange={(e) => setName(e.target.value)} />
      <br />
      <br />
      <Button
        disabled={!checkPermissionsHook.resource.create}
        color="success"
        onClick={() => handleSubmit()}
        title={
          checkPermissionsHook.resource.create ? "Submit" : "You don't have Create Permissions on Resource: Threats"
        }
      >
        Submit
      </Button>
    </Form>
  );
};

const CreateThreat = (props) => {
  return (
    <OrganizationCheck {...props}>
      <CreateThreatBody />
    </OrganizationCheck>
  );
};

export default CreateThreat;
