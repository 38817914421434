import { modules, resources } from "@rivial-security/role-utils";

import DeleteReport from "../functions/DeleteReport";
import React from "react";
import ReportDetails from "../ReportDetails";
import ReportModule from "../customFields/ReportModule";
import UploadReport from "../UploadReport";
import { generateGraphql } from "@rivial-security/generategraphql";
import { reportsByOwnerGroup } from "../graphql/__reportGQL";
import { useDataGrid } from "../../../hooks/views/useDataGrid/useDataGrid";

/**
 * @description Report Grid hook
 * @param {string} organizationID - id of the selected organization
 * @param {object[]} fields - array of fields for the grid
 * @param {object} gridConfigProps - use this to configure grid
 * @param {object} cardConfig - use this to configure card
 * @param {object} queryConfig - use this to configure query
 * @return {object} {{ref: string, setSelectedItems: (value: (((prevState: *[]) => *[]) | *[])) => void, setLastSelectedItem: (value: (((prevState: string) => string) | string)) => void, data: *, lastSelectedItem: string, setData: {(value: (((prevState: *) => *) | *)): void, (value: (((prevState: undefined) => undefined) | undefined)): void}, display: JSX.Element, setFields: {(value: (((prevState: *) => *) | *)): void, (value: (((prevState: undefined) => undefined) | undefined)): void}, fields: *, selectedItems: *[]}}
 */
export const useReportDataGrid = ({
  organizationID,
  fields = [],
  gridConfig: gridConfigProps = {},
  cardConfig: cardConfigProps = {},
  queryConfig: queryConfigProps = {},
}) => {
  const module = modules.REPORTS;
  const resource = resources.REPORT;

  const { updateMutation } = generateGraphql("Report", ["name", "createdAt", "type", "module"]);

  const queryConfig = {
    query: reportsByOwnerGroup,
    variables: {
      ownerGroup: organizationID,
    },
    organizationID,
    ...queryConfigProps,
  };

  const roleConfig = {
    module,
    resource,
  };

  const cardConfig = {
    title: "Reports",
    headerIcon: "icon-badge",
    enableSticky: true,
    ...cardConfigProps,
  };

  fields = [
    {
      name: "name",
      flex: 1,
      minWidth: 400,
      bulkEdit: true,
    },
    {
      name: "createdAt",
      inputType: "date",
      friendlyName: "Upload Date",
      width: "200",
      sort: {
        direction: "desc",
        priority: 1,
      },
      type: "date",
    },
    {
      name: "module",
      width: "500",
      component: <ReportModule />,
    },
    ...fields,
  ];

  const gridConfig = {
    typename: "Report",
    fields,
    options: ["details", "delete", "edit"],
    detailsComponent: <ReportDetails tableDisplay={true} />,
    route: "#/reports/list/",
    deleteFunction: DeleteReport,
    createResourceComponent: <UploadReport />,
    createItemModalHeader: "Upload Report",
    persistenceUUID: "175dc04c-f03c-44b2-9bda-868b892cd819",
    organizationID,
    updateMutation,
    ...gridConfigProps,
  };

  const queryCard = useDataGrid({
    ...queryConfig,
    ...gridConfig,
    ...cardConfig,
    ...roleConfig,
  });

  return { ...queryCard };
};
