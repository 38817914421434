import { useQuestionnaireDataGrid } from "../hooks/useQuestionnaireDataGrid";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * A component instance of the useQuestionnaireDataGrid.js hook
 * @param {object} props
 */
const QuestionnaireDataGrid = (props) => {
  return useQuestionnaireDataGrid(props).display;
};

export default withOrganizationCheck(QuestionnaireDataGrid);
