import { GetQuery } from "../../../../utils/Functions/Graphql/GetQuery";
import { InvalidParamError } from "../../../../analytics/CustomError";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Creates an EvidenceActivity item for an Evidence.
 * Calls the lambda through graphql
 * @param {object} activityInput - input for the activity
 * @param {string} evidenceID - id of the evidence
 * @param {string} organizationID - id of the organization
 * @param {object} pointOfContact - point of contact for the activity
 * @returns {Promise<Array>}
 */
export const createEvidenceActivity = async (activityInput, evidenceID, organizationID, pointOfContact) => {
  if (isNullOrUndefined(evidenceID)) {
    throw InvalidParamError("evidenceID");
  }

  if (isNullOrUndefined(activityInput)) {
    throw InvalidParamError("activityInput");
  }

  if (isNullOrUndefined(organizationID)) {
    throw InvalidParamError("organizationID");
  }

  const getQuery = `
  query GetEvidence($id: ID!, $input: AWSJSON) {
    getEvidence(id: $id) {
      id
      createEvidenceActivity(input: $input)
    }
  }`;

  const input = {
    date: new Date(),
    ...activityInput,
    evidenceID,
    organizationID,
    pointOfContact: pointOfContact ? pointOfContact : undefined,
  };

  return await GetQuery({
    query: getQuery,
    variables: { id: evidenceID, input: JSON.stringify(input) },
  });
};
