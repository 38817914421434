import React, { useState } from "react";

import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import Button from "@mui/material/Button";
import FileCopy from "@mui/icons-material/FileCopy";
import Grid from "@mui/material/Grid";
import { WarningLogger } from "@utils/EventLogger";

/**
 * A button that allows the user to save some data to the clipboard
 * @param data - the data to be copied to the clipboard
 * @param {string} [tooltip] - optional for a more detailed tooltip
 * @returns {JSX.Element}
 * @constructor
 */
const ClipboardButton = ({ data, tooltip = "Copy to clipboard", preview }) => {
  const [copied, setCopied] = useState(false);

  const copy = () => navigator.clipboard.writeText(data).then(() => setCopied(true));

  return (
    <Grid container spacing={1}>
      {preview === true && (
        <Grid item lg={11} md={10} sm={8} xs={2}>
          <pre>{data}</pre>
        </Grid>
      )}
      <Grid item lg={preview ? 1 : 12} xs={preview ? 1 : 12}>
        <Button style={{ height: "0.75em", width: "0.75em" }} className={"float-right"} title={tooltip} onClick={copy}>
          {copied ? <AssignmentTurnedInIcon /> : <FileCopy />}
        </Button>
      </Grid>
    </Grid>
  );
};

// See: https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript/33928558#33928558
// Copies a string to the clipboard. Must be called from within an
// event handler such as click. May return false if it failed, but
// this is not always possible. Browser support for Chrome 43+,
// Firefox 42+, Safari 10+, Edge and Internet Explorer 10+.
// Internet Explorer: The clipboard feature may be disabled by
// an administrator. By default a prompt is shown the first
// time the clipboard is used (per session).
export const copyToClipboard = (text) => {
  if (window.clipboardData && window.clipboardData.setData) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData("Text", text);
  } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
    const textarea = document.createElement("textarea");
    textarea.textContent = text;
    textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
      return document.execCommand("copy"); // Security exception may be thrown by some browsers.
    } catch (ex) {
      WarningLogger("Copy to clipboard failed.", ex);
      return prompt("Copy to clipboard: Ctrl+C, Enter", text);
    } finally {
      document.body.removeChild(textarea);
    }
  }
};

export default ClipboardButton;
