import { useSubscription } from "../../../../hooks/graphql/useSubscription";
import React from "react";
import { deleteUser } from "../functions/deleteUser";
import { useQueryCard } from "../../../../hooks/views/useQueryCard";
import AddSingleUser from "../components/AddSingleUser";
import UserDetails from "../components/UserDetails";
import { generateGraphql } from "@rivial-security/generategraphql";
import { resources, modules } from "@rivial-security/role-utils";

const {
  listQuery: listUsers,
  onCreate: onCreateUser,
  onDelete: onDeleteUser,
  onUpdate: onUpdateUser,
} = generateGraphql("User", ["name", "username", "email", "phoneNumber", "accountType", "roleLinks"], {
  roleLinks: `{ items { id role { id ownerGroup name } } }`,
});

export const useUserList = ({ organizationID, config, queryConfig = {} }) => {
  const module = modules.ORGANIZATION_MANAGER;
  const resource = resources.USER;

  const getSubscription = useSubscription({
    onCreateSubscription: onCreateUser,
    onUpdateSubscription: onUpdateUser,
    onDeleteSubscription: onDeleteUser,
    organizationID: organizationID,
    module,
    resource,
  });

  const RoleField = (props) => {
    return (
      <>
        {props.item &&
        props.item.roleLinks &&
        props.item.roleLinks.items &&
        props.item.roleLinks.items[0] &&
        props.item.roleLinks.items[0].role &&
        props.item.roleLinks.items[0].role.name
          ? props.item.roleLinks.items[0].role.name
          : null}
      </>
    );
  };

  const customFields = [
    {
      field: "role",
      component: <RoleField />,
    },
  ];

  queryConfig = {
    query: listUsers,
    organizationID: organizationID,
    subscriptionHook: getSubscription,
    module,
    resource,
    ...queryConfig,
  };

  const dataCardConfig = {
    header: "User Accounts List",
    fields: ["name", "username", "phoneNumber", "accountType", "role"],
    options: ["details", "delete"],
    customFields: customFields,
    createResourceComponent: <AddSingleUser organizationID={organizationID} />,
    deleteFunction: async (itemToDelete) => await deleteUser({ item: itemToDelete }),
    fieldNameDictionary: {
      id: "ID",
      name: "Name",
      username: "Username",
      phoneNumber: "Phone Number",
      accountType: "Account Type",
      role: "Role",
    },
    route: "#/admin_panel/users/",
    detailsComponent: <UserDetails />,
    config: config && config.showSelectBoxes ? { showSelectBoxes: true } : undefined,
    module,
    resource,
  };

  const usersQueryCard = useQueryCard({
    queryConfig,
    dataCardConfig,
    module,
    resource,
  });

  return { ...usersQueryCard };
};
