import { generateGraphql } from "@rivial-security/generategraphql";
import React, { useEffect } from "react";
import { deleteCustomWidget } from "../functions/deleteCustomWidget";
import CustomWidgetDetails from "../../WidgetDetails/components/CustomWidgetDetails";
import { modules, resources } from "@rivial-security/role-utils";
import { customWidgetsByOwnerGroup } from "../graphql/customWidgetsByOwnerGroup";
import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";
import CreateWidget from "../../WidgetBuilder/components/CreateWidget";

/**
 * Lists all Widgets
 * @param {string} organizationID - currently selected organization ID
 * @param {boolean} isInAdminTab - true if the widget is being created in the admin tab
 * @param {string} showHeader - true if need to show header for the grid
 * @param {boolean} enableSelectButtons - true if need to show select buttons for the grid
 * @param {function} selectButtonCallback - function upon selecting a widget with a select button
 * @param {string} type - the type of widget to filter by
 * @param {string} module - the module to use for role checking
 * @param {string} resource - the resource to use for role checking
 * @param {object} props - all props passed to the useDataGrid hook
 */
export const useCustomWidgetDataGrid = ({
  organizationID,
  isInAdminTab = false,
  showHeader = true,
  enableSelectButtons = false,
  selectButtonCallback,
  type,
  module = modules.ORGANIZATION_MANAGER,
  resource = resources.CUSTOM_WIDGET,
  ...props
} = {}) => {
  const typename = "CustomWidget";
  const queryFields = ["name", "description", "type", "customQuery"];
  const nestedFields = {
    customQuery: `{ name description }`,
  };
  const { updateMutation, listQuery } = generateGraphql("CustomWidget", queryFields, nestedFields);

  const fields = [
    {
      name: "name",
      width: "300",
    },
    {
      name: "description",
      width: "300",
    },
  ];

  const gridConfig = {
    title: "Custom Widgets",
    headerIcon: "icon-chemistry",
    fields,
    typename,
    createResourceComponent: <CreateWidget isInAdminTab={isInAdminTab} type={type} />,
    createResourceComponentWidth: "80vw",
    options: ["details", "delete"],
    deleteFunction: deleteCustomWidget,
    detailsTitle: "Custom Widget Details",
    detailsComponent: <CustomWidgetDetails organizationID={organizationID} tableDisplay={true} />,
    updateMutation,

    enableSelectButtons,
    selectButtonCallback,
    query: customWidgetsByOwnerGroup,

    persistenceUUID: `custom_widget_data_grid-${type}`,
    module,
    resource,
    organizationID,
    duplicationSettings: {},
    variables: {
      ownerGroup: organizationID,
    },
    ...(type && {
      filters: {
        type: {
          eq: type,
        },
      },
    }),
  };

  const gridCard = useDataGrid({
    ...gridConfig,
    ...props,
  });

  // Folds in the Built in Widgets
  useEffect(() => {}, []);

  return {
    ...gridCard,
  };
};
