import React from "react";
import PropTypes from "prop-types";

/**
 * Takes the date information form a field in an item and displays a formatted date
 * @param {object} item - object containing all info fo a single record including the date field
 * @param {string} fieldName - the property name in the item object to use as date value
 * @param {boolean} trimTime - TRUE if need to only show the date part (ie 12/31/2020), FALSE if need to include time
 * @returns {string} - the formatted date
 * @constructor
 */
const DateField = ({ item, fieldName = "dateCreated", trimTime = false }) => {
  return (
    <>
      {item?.[fieldName]
        ? trimTime
          ? new Date(item[fieldName]).toLocaleDateString()
          : new Date(item[fieldName]).toLocaleString()
        : "No Date"}
    </>
  );
};

DateField.propTypes = {
  item: PropTypes.object,
};

export default DateField;
