import GenericEditField from "../../../../utils/GenericComponents/GenericEditFieldV2";
import React, { useEffect, useState } from "react";
import { useForm } from "../../../../hooks/views/useForm/useForm";
import { generateGraphql } from "@rivial-security/generategraphql";
import { ListQuery } from "../../../../utils/Functions/Graphql/ListQuery";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { modules, resources } from "@rivial-security/role-utils";

const ResponseTeam = ({ item, organizationID, resetFunction, ...props }) => {
  const module = modules.INCIDENT_RESPONSE;
  const resource = resources.THREAT;
  const field = "responseTeam";

  const { updateMutation } = generateGraphql("Threat", ["responseTeam", "responseTeamID"], {
    responseTeam: `{id name description}`,
  });

  return (
    <GenericEditField
      item={item}
      module={module}
      resource={resource}
      field={field}
      fieldOverride="responseTeamID"
      mutation={updateMutation}
      inputType="dropdown"
      dropdownInput={<DropdownInput organizationID={organizationID} />}
      customFormat={(input) => input?.name || input}
      resetFunction={resetFunction}
    />
  );
};

const DropdownInput = ({ value, onChange, organizationID }) => {
  const [responseTeams, setResponseTeams] = useState([]);

  useEffect(() => {
    const { listQuery } = generateGraphql("ResponseTeam", ["name", "description"]);
    ListQuery({
      query: listQuery,
      organizationID,
    }).then((items) =>
      setResponseTeams([
        {
          name: "None",
          id: "n/a",
        },
        ...items,
      ]),
    );
  }, []);

  const form = useForm({
    disableResetButton: true,
    disableSubmitButton: true,
    fieldConfig: {
      responseTeam: {
        defaultValue: value,
        inputType: "dropdown",
        onChangeFunction: (input) => onChange({ target: { value: input.responseTeam } }),
        dropdownConfig: {
          width: "200px",
          data: responseTeams.map((team) => {
            return {
              text: team.name,
              value: team.id,
            };
          }),
        },
      },
    },
  });

  return form.display;
};

export default withOrganizationCheck(ResponseTeam);
