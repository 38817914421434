import { resources } from "@rivial-security/role-utils";
import { TEMPLATE } from "../../../enums/TEMPLATE";

/**
 * Checks if an organizationID represents a template item
 * @param {string} organizationID - the current organization identifier for a db item
 * @return {boolean} TRUE if any of the template organization ids, FALSE if isn't (normal organization)
 */
export const isTemplateOrganizationID = (organizationID) => {
  if (organizationID && typeof organizationID === "string") {
    if (
      organizationID === "admin" ||
      organizationID === TEMPLATE ||
      organizationID === resources.BLUEPRINT_TEMPLATE ||
      organizationID === resources.ROLE_TEMPLATE ||
      organizationID === resources.SYSTEM_TEMPLATE ||
      organizationID === resources.RISK_CONTROL_CATEGORY_TEMPLATE ||
      organizationID === resources.RISK_CONTROL_TEMPLATE ||
      organizationID === resources.KEY_RISK_INDICATOR_TEMPLATE ||
      organizationID === resources.THREAT_TEMPLATE ||
      organizationID === resources.INFORMATION_ASSET_TEMPLATE ||
      organizationID === resources.CUSTOM_WIDGET_TEMPLATE ||
      organizationID === resources.CUSTOM_QUERY_TEMPLATE ||
      organizationID === resources.REPORT_TEMPLATE
    ) {
      return true;
    }
  }

  return false;
};
