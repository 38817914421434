import React from "react";
import Label from "./Label";

/**
 * Displays multiple labels in a vertical list
 * @param {object[]} labels - array of label information from the database, see Label model in schema
 * @param {function} onLabelClick - callback function to handle click on the label tag
 * @param {function} onLabelRemoved - callback when label is deleted
 * @param {function} onUpdatedLabelLink - callback when label is updated
 * @param {boolean} showBookmarks - TRUE when need to show bookmark info for each label
 * @returns {JSX.Element}
 */
const LabelList = ({ labels, onLabelClick, onLabelRemoved, onUpdatedLabelLink, showBookmarks = false }) => {
  return (
    <div>
      {Array.isArray(labels) &&
        labels?.map((label, index) => {
          return (
            <Label
              key={index}
              isSelected={true}
              label={label}
              onClick={onLabelClick}
              onRemoved={onLabelRemoved}
              showBookmarks={showBookmarks}
              onUpdatedLabelLink={onUpdatedLabelLink}
            />
          );
        })}
    </div>
  );
};
export default LabelList;
