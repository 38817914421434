"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleEmailTableWidget = void 0;
const createHtmlTableHeader_1 = require("./createHtmlTableHeader");
const createHtmlTableBody_1 = require("./createHtmlTableBody");
/**
 * Generates html string for a table widget that is part of an email
 * @param {object} tableSettings - settings for the table see widget structure README in widget utils
 * @param {object} fieldSettings - settings for the individual table columns, see widget structure README in widget utils
 * @param {object} context - the widget container settings
 * @param {string[]} fields - the fields to show and in what order
 * @param {object} fieldNameDictionary - optional renaming of fields for the header row
 * @param {object[]} data - the data with which to populate the table
 * @returns {string}
 */
const handleEmailTableWidget = ({ tableSettings, fieldSettings, context, fields = [], fieldNameDictionary = {}, data = [], }) => {
    const { showLinkToResource = false, detailsRoutePrefix } = tableSettings || {};
    const detailsFieldWidth = 55;
    const showDetailsColumn = showLinkToResource === true && detailsRoutePrefix;
    //Based on the context and table settings determine the width of each column
    const { contentWidth = 500 } = context || {};
    let remainingWidth = contentWidth - (showDetailsColumn ? detailsFieldWidth : 0);
    //Determine if the table has space remaining after all preset widths have been assigned
    let widthOfFieldsWithPresetWidths = 0;
    const fieldsWithoutPresetWidths = [];
    const fieldsWithPresetWidths = {};
    for (const field of fields) {
        let fieldWidth = fieldSettings?.[field]?.emailColumnWidth;
        try {
            fieldWidth = parseInt(fieldWidth, 10);
        }
        catch (e) {
            continue;
        }
        if (fieldWidth) {
            widthOfFieldsWithPresetWidths += fieldWidth;
            fieldsWithPresetWidths[field] = fieldWidth;
        }
        else {
            fieldsWithoutPresetWidths.push(field);
        }
    }
    const minimumFieldWidth = 20;
    const remainingFieldsWidthRequired = fieldsWithoutPresetWidths.length * minimumFieldWidth;
    const fieldConfig = {};
    if (remainingWidth - widthOfFieldsWithPresetWidths > remainingFieldsWidthRequired) {
        //There is enough space to assign requested widths to all fields
        // - assign preset widths to all fields with preset widths
        for (const fieldWithPresetWidth in fieldsWithPresetWidths) {
            const field = fieldWithPresetWidth;
            const width = fieldsWithPresetWidths[field];
            fieldConfig[field] = { width: `${width}px` };
        }
        // - recalculate the remaining width and distribute proportionally
        remainingWidth = remainingWidth - widthOfFieldsWithPresetWidths;
        for (const field of fieldsWithoutPresetWidths) {
            const proportionalWidth = Math.floor(remainingWidth / fieldsWithoutPresetWidths.length);
            fieldConfig[field] = { width: `${proportionalWidth}px` };
        }
    }
    else {
        //There is not enough space to assign requested widths to all fields
        // - assign minimum width to all fields without preset widths
        for (const field of fieldsWithoutPresetWidths) {
            fieldConfig[field] = { width: `${minimumFieldWidth}px` };
        }
        // - recalculate the remaining width and distribute proportionally
        remainingWidth = remainingWidth - remainingFieldsWidthRequired;
        for (const fieldWithPresetWidth in fieldsWithPresetWidths) {
            const field = fieldWithPresetWidth;
            const width = fieldsWithPresetWidths[field];
            const proportionalWidth = Math.floor((width / widthOfFieldsWithPresetWidths) * remainingWidth);
            fieldConfig[field] = { width: `${proportionalWidth}px` };
        }
    }
    //Determine if the table should contain details links
    if (showDetailsColumn) {
        fields = ["details", ...fields];
        fieldConfig["details"] = { width: `${detailsFieldWidth}px` };
        for (const item of data) {
            if (item?.id) {
                item["details"] = `<a href="${detailsRoutePrefix}/${item.id}">View</a>`;
            }
            else {
                item["details"] = ``;
            }
        }
    }
    return `
    <table className="table table-hover" style="border: 1px solid black;" cellpadding="5">
      ${(0, createHtmlTableHeader_1.createHtmlTableHeader)({ fields, fieldConfig, fieldNameDictionary })}
      ${(0, createHtmlTableBody_1.createHtmlTableBody)({ fields, fieldConfig, data })}
    </table>
  `;
};
exports.handleEmailTableWidget = handleEmailTableWidget;
