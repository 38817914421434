import { useDataGridDeleteItems } from "../../useDataGrid/hooks/useDataGridDeleteItems";
import { checkParams } from "../../../../utils/Functions/Errors/checkParams";

/**
 * @description Modal to display item to delete for a user
 * Note: deleteFunction is required
 * @param {object} ref - reference of the grid component
 * @param {object[]} selectedItems - items to delete
 * @param {function} setSelectedItems - sets the selected array of items
 * @param {function} deleteFunction - delete items function
 * @param {function} resetFunction - reset state function
 * @param {string} deleteButtonText - text to display for a button
 * @returns {object} {{setModalIsOpen: function(*): void, modalButton: *, modalIsOpen: boolean, modal: *, toggleModal: function(): void}}
 */
export const useGridDeleteConfirm = ({
  ref,
  selectedItems,
  setSelectedItems,
  deleteFunction,
  primaryField = "name",
  resetFunction,
  deleteButtonText,
}) => {
  /**
   * Remove items from the grid after delete from the database
   */
  const onDeleteCallback = () => {
    /**
     * Params to be checked for null
     */
    const params = {
      ref,
      selectedItems,
      setSelectedItems,
    };

    /**
     * Check params for null or undefined
     */
    checkParams({ params });

    if (Array.isArray(selectedItems) && selectedItems.length > 0) {
      for (const item of selectedItems) {
        const index = ref?.dataSource?.findIndex((x) => x.id === item.id);
        if (index > -1) {
          ref?.dataSource?.splice(index, 1);
          deleteFunction && deleteFunction(item);
        }
      }
    }

    ref?.refresh && ref.refresh();
    setSelectedItems([]);
  };

  const { modal } = useDataGridDeleteItems({
    selectedData: selectedItems,
    deleteButtonText,
    deleteFunction,
    onDeleteCallback,
    primaryField,
  });

  return {
    ...modal,
  };
};
