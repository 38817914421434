import { modules, resources } from "@rivial-security/role-utils";

import Button from "@mui/material/Button";
import { InfoLogger } from "@utils/EventLogger";
import React from "react";
import { fireAutomation } from "../functions/fireAutomation";
import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useOrganizationContext } from "../../../AdminPanel/Organizations/hooks/useOrganizationContext";
import { usePleaseWaitModal } from "../../../../hooks/views/usePleaseWaitModal";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * Button that allows the user to manually trigger an Automation
 * @param id
 * @param organizationID
 * @param name
 * @returns {JSX.Element}
 * @constructor
 */
const ManualAutomationButton = ({ id, organizationID, name }) => {
  const module = modules.ORGANIZATION_MANAGER;
  const resource = resources.AUTOMATION;

  const permissions = useCheckPermissions({ module, resource });

  const { userEmail, sentryTrace } = useOrganizationContext();

  const pleaseWait = usePleaseWaitModal({});

  const handleClick = async () => {
    pleaseWait.setModalIsOpen(true);
    fireAutomation({
      id,
      organizationID,
      userEmail,
      name,
      sentryTrace,
    })
      .then((r) => {
        InfoLogger(r);
      })
      .finally(() => {
        pleaseWait.setModalIsOpen(false);
      });
  };

  return (
    <Button
      startIcon={<i className={"icon-energy"} />}
      onClick={async () => await handleClick()}
      title={"Manually fires the steps in this Automation"}
      disabled={!permissions.resource.read || !permissions.resource.update}
    >
      Run Automation Now
      {pleaseWait.modal}
    </Button>
  );
};

export default withOrganizationCheck(ManualAutomationButton);
