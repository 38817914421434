import { ErrorLogger, InfoLogger } from "@utils/EventLogger";

import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { checkArguments } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 * Link a meeting to one or more items (Tasks/Observations/etc.)
 * @param {object} meeting - the meeting item
 * @param {object[]} selectedItems - the selected items (tasks/observations/etc.) to link the meeting to
 * @param {string} organizationID - selected organization
 * @param {string} typename - item typename from the schema that is being linked to the meeting (Task/Observation/etc.)
 * @return {Promise<*[]>}
 */
export const linkItemToMeeting = async ({ meeting, selectedItems, organizationID, typename }) => {
  try {
    checkArguments(
      { meeting, selectedItems, organizationID, typename },
      {
        meeting: { type: "object", validAsNull: false },
        selectedItems: { isArray: true },
        organizationID: { type: "string", validAsNull: false },
        typename: { type: "string", validAsNull: false },
      },
    );
  } catch (e) {
    ErrorLogger(`[linkItemToMeeting.js] Error: `, e);
    return [];
  }

  const { updateMutation: updateItem } = generateGraphql(typename, ["id", "meetingID", "ownerGroup"]);

  const itemMeetingLinksPromises = selectedItems.map((item) =>
    ItemMutation(updateItem, {
      id: item?.id,
      meetingID: meeting?.id,
      ownerGroup: organizationID,
    }).then((link) => {
      InfoLogger(`Linked ${typename}: ${item?.id} to Meeting: ${meeting.id}`);
      return link;
    }),
  );

  return Promise.allSettled(itemMeetingLinksPromises);
};
